import { getPayments } from '@/features/referrals/slice';
import { GridPayload } from '@/features/referrals/types';
import { DataGrid, GridPaginationModel, useGridApiRef } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getMyRewardsColumns } from './ReferralsGridColumns';


const MyRewardGrid = () => {
    const [totalRows, setTotalRows] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [list, setList] = useState<any[]>([]);
    const apiRef = useGridApiRef();


    const payload: GridPayload = useMemo(() => {
        return {
            skip: 0,
            take: 5,
        };
    }, []);

    const fetch = useCallback(async (newPayload: GridPayload) => {
        try {
            setLoading(true);
            const result = await getPayments(newPayload);
            setList(result?.list);
            setTotalRows(result.count);
        }
        catch (e) {
            setList([]);
        }
        finally { setLoading(false); }
    }, []);

    useEffect(() => {
        fetch(payload);
    }, [fetch, payload]);

    const onPaginationModelChange = async (model: GridPaginationModel) => {
        try {
            //call page from grid model 
            const newPayload = { ...payload, take: model.pageSize, skip: model.page };
            fetch(newPayload);
        }
        catch (e) {
            setList([]);
        }
    };

    const columns = useMemo(() => getMyRewardsColumns(), []);

    return (
        <>
            <DataGrid
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 5, page: 0 },
                    },
                }}
                autoPageSize={false}
                pagination
                autoHeight={true}
                apiRef={apiRef}
                rows={list}
                rowCount={totalRows}
                loading={loading}
                getRowId={(row) => `${row.id}`}
                onPaginationModelChange={onPaginationModelChange}
                paginationMode="server"
                filterMode="server"
                sortingMode="server"
                disableRowSelectionOnClick={true}
                columns={columns}
                sx={{
                    // disable cell selection style
                    '.MuiDataGrid-cell:focus': {
                        outline: 'none'
                    },
                }}
            />
        </>
    );

};

export default MyRewardGrid;
