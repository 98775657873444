import MuiTextAlert from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAlert';
import { Alert } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface ExchangeNoteProps {
	isAutomated: boolean;
}

const ExchangeNote = ({ isAutomated }: ExchangeNoteProps): React.ReactElement => {
	const { t } = useTranslation('translations');

	return (
		<Alert severity="warning">
			{isAutomated ?
				<MuiTextAlert className='highlight-strong' pt={1} >
					{t('sendmoneyTranslation.data.automatedExchangeDisclaimer')}
				</MuiTextAlert>
				: <MuiTextAlert className='highlight-strong' pt={1} >
					<Trans i18nKey='sendmoneyTranslation.data.exchangeFeeInfo'
						t={t}
						components={{
							termlink: <Link to='/legal/terms' />
						}}
					/>
				</MuiTextAlert>}
		</Alert>
	);
};

export default ExchangeNote;
