import React from 'react';

import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import CardProductBox from '@/componentsMui/Shared/Widgets/ProductBox/CardProductBox';
import { CoinType } from '@features/coins/types';
import MuiTextLabel from '@shared/Widgets/MuiText/MuiTextLabel';
import { setCoin } from '@features/tabs/slice';
import TokenAvatar, { TokenAvatarSizeEnum } from '@shared/Widgets/Avatars/TokenAvatar';

interface TokenChooserProps {
    tokens: CoinType[]
}

const TokenChooser = ({ tokens }: TokenChooserProps): React.ReactElement => {

    const { user } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    const { currentCoin } = useSelector((state: RootState) => state.tabs);

    const onClickSetAccount = (token) => {
        dispatch(setCoin(token));
    };

    return (
        <>
            {tokens.map((token, index) => (
                <TokenChooserItem
                    key={'account-' + index}
                    selected={token.info.symbol === currentCoin?.info?.symbol}
                    token={token}
                    hideValues={user.hideValues}
                    onClick={onClickSetAccount}
                />
            ))}
        </>
    );
};


interface TokenChooserItemProps {
    token: CoinType,
    hideValues?: boolean,
    selected?: boolean,
    onClick?: (token: CoinType) => void
}

const TokenChooserItem = ({ token, selected, onClick }: TokenChooserItemProps) => {
    return (
        <CardProductBox
            id='account-item'
            selected={selected}
            onClick={() => onClick && onClick(token)}
        >
            <Grid container alignItems='center' p='15px' flexWrap='nowrap' height={'100%'} display={'flex'}>
                <Grid item>
                    <TokenAvatar symbol={token.info.symbol} logoSource={token.info.logo} variant={TokenAvatarSizeEnum.medium} />
                </Grid>
                <Grid item pl={1} alignItems='center' justifyContent='center' display='flex' flexDirection='row'>
                    <MuiTextCaption fontSize='1rem'>{token.info.symbol}</MuiTextCaption>
                    <MuiTextLabel fontWeight='400' ml={1} noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}>{token.info.displayName}</MuiTextLabel>
                </Grid>
            </Grid>
        </CardProductBox>
    );
};


export default TokenChooser;

