
import * as React from 'react';
import { Box, BoxProps, Theme } from '@mui/material';
import { FC } from 'react';
import './CoverBox.css';
import { useTheme } from '@mui/styles';
import Cover from './Covers/Cover';

interface Props extends BoxProps {
	selected?: boolean,
	mainClassName?: string,
	itemClassName?: string,
	bgIndex?: number,
	priority?: number;
}

/*Unselect items are grey*/
const PlansBgBox: FC<Props> = (props: Props) => {

	const { selected, priority, sx, ...others } = props;
	const theme = useTheme() as Theme;

	const getSecondBg = () => {
		switch (priority) {
			case 0:
				return theme.covers['productCover'].bg[0].secondBg;
			  case 1:
				return 'linear-gradient(112.26deg, #FFFFFF 10.81%, #7A7A7A 160.09%)';
			  case 2:
				return 'linear-gradient(112.26deg, #FCBA3A 10.81%, #5E3F04 160.09%)';
			  default:
				return 'linear-gradient(2.88deg, #0A46D5 -10.83%, #F7F4F3 106.36%)';
		}
	};
	
	const getFirstBg = () => {
		switch (priority) {
			case 0:
				return theme.covers['productCover'].bg[0].firstBg;
			  case 1:
				return 'linear-gradient(112.26deg, #FFFFFF 10.81%, #4F5357 160.09%)';
			  case 2:
				return 'linear-gradient(112.26deg, #FF9900 10.81%, #FFC700 160.09%)';
			  default:
				return 'linear-gradient(2.88deg, #0A46D5 -10.83%, #F7F4F3 106.36%)';
		}
	};

	return (
		<div id='option-cover-box' className={props.mainClassName}>
			{selected ?
				<Cover baseBg={theme.covers['productCover'].baseBg}
					firstBg={getFirstBg()}
					secondBg={getSecondBg()}
				>

					<Box
						height='inherit'
						width='inherit'
						sx={{
							...sx,
							color: theme.palette.text.primary, 
						}}
						{...others}>
						<div className={'option-cover-box__item-wrap ' + props.itemClassName}  >
							{props.children}
						</div>
					</Box>
				</Cover> :
				<Box
					sx={{
						...sx,
						borderRadius: theme.mainBorderRadius, 
						background: theme.form.bgDefault,
						color: theme.palette.text.secondary,
						width: '100%',
						height: '100%'
					}}
					{...others}>
					<div className={'option-cover-box__item-wrap ' + props.itemClassName}  >
						{props.children}
					</div>
				</Box>
			}
		</div >);
};

export default PlansBgBox;


