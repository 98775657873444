import TooltipBox from '@/componentsMui/Shared/Widgets/Chart/TooltipBox';
import TooltipLine from '@/componentsMui/Shared/Widgets/Chart/TooltipLine';
import { format } from 'date-fns';
import React from 'react';

const CryptoChartTooltip = (tdata: any): React.ReactElement => {


    const payload = tdata?.payload?.length > 0 ? tdata?.payload[0]?.payload : null;

    if (!payload) {
        return <></>;
    }
    const date = payload?.time ? new Date(payload.time) : null;
    const dateFormatted = date ? format(date, 'dd MMM yyyy HH:mm') : '';

    return (<TooltipBox>
        <TooltipLine title={dateFormatted} amount={payload.price} prefix=' ' />
    </TooltipBox>
    );
};

export default CryptoChartTooltip;
