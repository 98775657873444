import * as React from 'react';

import '@/componentsMui/Shared/Shared.css';
import { Button, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { addContact, getContacts, getDataByIban } from '@/features/contacts/slice';
import { useDispatch } from 'react-redux';
import { showException, showSuccess } from '@/features/swal/slice';
import { ContactPaymentTypes } from '@/features/contacts/types';
import { isEmpty } from 'lodash';
import MuiTextTrx from '@/componentsMui/Shared/Widgets/MuiText/MuiTextTrx';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import FormContentBox from '@/componentsMui/Shared/Widgets/Box/FormContentBox';
import { BeneficiaryType } from '@/helpers/globalTypes';
import { useState } from 'react';
import ButtonStyleTab from '@/componentsMui/Shared/Widgets/Tab/ButtonStyleTab';
import ButtonStyleTabs from '@/componentsMui/Shared/Widgets/Tab/ButtonStyleTabs';
import { electronicFormatIBAN, isValidIBAN } from 'ibantools';
import { getNames } from 'i18n-iso-countries';

interface Props {
    onBack: () => void;
}

const CreateSepaContact = ({ onBack }: Props) => {

    const { t } = useTranslation('translations');
    const contriesList = getNames('en');
    const dispatch = useDispatch();
    const closeModal = useCloseDialogNoEvent();
    const [beneficiaryType, setBeneficiaryType] = useState<BeneficiaryType>(BeneficiaryType.INDIVIDUAL);
    const [ibanIsValid, setIbanIsValid] = useState<boolean>(true);
    const [iban, setIban] = useState<string>('');
    const [beneficiaryData, setBenificiaryData] = useState<any>(null);

    const validationSchema = Yup.object({
        name: Yup.string().required(t('form.validator.required')),
        iban: Yup.string().required(t('form.validator.required')).test('iban',
            t('sendmoneyTranslation.data.invalidIban'),
            () => ibanIsValid),
        identification: Yup.string().when([], {
            is: () => beneficiaryType === BeneficiaryType.BUSINESS,
            then: Yup.string().required(t('form.validator.required')),
            otherwise: Yup.string().notRequired()
        }),
        country: Yup.string().test('country',
            t('form.validator.required'),
            () => !!beneficiaryData?.country),
        bicswift: Yup.string().test('bicswift',
            t('form.validator.required'),
            () => !!beneficiaryData?.bic)
    });

    const submit = async (formData, formikProps) => {
        const { setSubmitting } = formikProps;
        const { name, iban, identification } = formData;
        try {
            await dispatch(addContact({
                name,
                paymentType: ContactPaymentTypes.SEPA,
                identification,
                isBusiness: beneficiaryType === BeneficiaryType.BUSINESS,
                business: beneficiaryType === BeneficiaryType.BUSINESS,
                country: beneficiaryData.country,
                bic: beneficiaryData.bic,
                account: iban
            }));
            showSuccess({ title: 'contacts.create.success', mui: true });
            dispatch(getContacts());
            closeModal();
        } catch (e) {
            showException(e);
            closeModal();
        }
        finally {
            setSubmitting(false);
        }
    };

    const initialValues = {
        name: '',
        iban: '',
        bicswift: '',
        country: '',
        identification: '',
    };

    const handleBeneficiaryTypeChange = (
        newValue,
        setFieldTouched
    ) => {
        const value = newValue;
        if (value != null) {
            setBeneficiaryType(value);
            //revalidate
            setTimeout(() => setFieldTouched('identification', true));
        }
    };

    const handleIbanChange = (event: React.ChangeEvent<any>, setFieldValue) => {
        const iban = event.target.value.replaceAll(' ', '');
        setFieldValue('iban', iban);
        setIban(iban);
    };

    const handleIbanOnBlur = (setFieldValue, setFieldTouched) => {
        const formatedIban = electronicFormatIBAN(iban).replace(/\s/g, '');
        setFieldValue('iban', formatedIban);
        setIban(formatedIban);
        handleBicSwift(formatedIban, setFieldTouched);
    };

    const handleBicSwift = (
        iban: string,
        setFieldTouched?: any,
    ) => {
        if (!isValidIBAN(iban)) {
            setIbanIsValid(false);
        } else {
            setIbanIsValid(true);
            const getIbanData = async () => {
                const beneficiaryData = await getDataByIban(
                    iban.replace(/\s/g, '')
                );
                setBenificiaryData(beneficiaryData);
                console.log('bene', JSON.stringify(beneficiaryData));
                setTimeout(() => setFieldTouched('iban', true));
            };
            getIbanData();
        }

    };


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submit}

        >{({ errors, isSubmitting, dirty, setFieldTouched, setFieldValue }) => {
            return (
                <Form className='form-content__wrap__bb'>
                    <FormContentBox noBackground={true} className='form-content_main-area wp'>
                        <Grid container sx={{ height: '100%' }} >
                            <Grid id="contentWrap"
                                item
                                spacing={2}
                                container
                                alignSelf="flex-start" >
                                <Grid item xs={12}  >
                                    <MuiTextTrx marginTop="0.5rem" >{t('contacts.create.subTitles.SEPA')}</MuiTextTrx>
                                </Grid>
                                <Grid item xs={12} justifyContent={'start'}>
                                    <ButtonStyleTabs
                                        value={beneficiaryType}
                                        onChange={(event, newValue) => handleBeneficiaryTypeChange(newValue, setFieldTouched)}
                                    >
                                        <ButtonStyleTab value={BeneficiaryType.INDIVIDUAL} label={t('sendmoneyTranslation.data.individual')} />
                                        <ButtonStyleTab value={BeneficiaryType.BUSINESS} label={t('sendmoneyTranslation.data.business')} />
                                    </ButtonStyleTabs>
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        name="name"
                                        fullWidth
                                        label={beneficiaryType === BeneficiaryType.BUSINESS ? t('sendmoneyTranslation.data.businessBeneficiary') : t('contacts.create.name')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        name="identification"
                                        fullWidth
                                        inputProps={{ autoComplete: 'off' }}
                                        label={beneficiaryType === BeneficiaryType.BUSINESS ? t('sendmoneyTranslation.data.registrationNumber') : 'Identification number (optional)'} />
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        name="iban"
                                        fullWidth
                                        value={iban}
                                        onChange={(event) => handleIbanChange(event, setFieldValue)}
                                        onBlur={() => handleIbanOnBlur(setFieldValue, setFieldTouched)}
                                        label={t('sendmoneyTranslation.data.iban')} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="bicswift"
                                        fullWidth
                                        inputProps={{ autoComplete: 'off' }}
                                        value={beneficiaryData ? beneficiaryData.bic : ''}
                                        label={t('sendmoneyTranslation.data.bicswift')}
                                        disabled />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="country"
                                        fullWidth
                                        inputProps={{ autoComplete: 'off' }}
                                        value={beneficiaryData ? contriesList[beneficiaryData.country] : ''}
                                        label={t('sendmoneyTranslation.data.country')}
                                        disabled />
                                </Grid>
                            </Grid>
                            <Grid container mt='auto' pt='2rem' columnSpacing={2}>
                                <Grid item xs={6} >
                                    <Button
                                        variant='customOutlined'
                                        color='secondary'
                                        onClick={onBack}
                                        sx={{ width: { xs: '100%', md: 'auto' } }}
                                    >  {t('form.buttons.back')}
                                    </Button>
                                </Grid>
                                <Grid item xs={6} container justifyContent='flex-end'>
                                    <Button disabled={isSubmitting || !isEmpty(errors || !dirty)}
                                        key="buttonNext"
                                        variant='contained'
                                        color='primary'
                                        type="submit" >
                                        {t('contacts.create.save')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </FormContentBox>
                </Form>
            );
        }}
        </Formik >
    );
};

export default CreateSepaContact;
