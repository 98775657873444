import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@/store';
import request from '@services/request';
import {
	AssetType,
	CoinPayinfo,
	CoinPayinfoPayload,
	CoinTradePayload,
	CoinType,
} from '../coins/types';
import {
	TradeHistoryFilterParams,
	TradeHistoryLineType,
	TradeHistoryType,
	TradeHistoryTypes
} from '../transactions/types';
import { addDays } from 'date-fns';
import { SocketService } from '@/services/socketService';
import { Subscription } from 'rxjs';
import { updateWalletTransactionsWithInitial } from '../walletHistory/slice';
import { isEmpty } from 'lodash';
import { WalletTransactionType } from '@features/walletHistory/types';
import { CryptoTransferPayload, TokenStore, TokenTransferPayload } from '@features/tokens/types';
import { transformAccountToAssets, transformTokensToAssets } from '@features/coins/slice';
import { getErrorWithParams } from '@features/swal/slice';

const initialState: TokenStore = {
	list: [],
	recentOrders: [],
	loading: false,
	error: null,
	fromAsset: null,
	toAsset: null
};

const tokensSlice = createSlice({
	name: 'tokens',
	initialState,
	reducers: {
		setTokens(state, action: PayloadAction<CoinType[]>) {
			state.list = action.payload;
		},
		setTokensLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<any>) => {
			state.error = payload;
		},
		setRecentOrders(state, action: PayloadAction<Array<TradeHistoryLineType>>) {
			state.recentOrders = action.payload;
		},
		setFromAsset(state, action: PayloadAction<AssetType>) {
			state.fromAsset = action.payload;
		},
		setToAsset(state, action: PayloadAction<AssetType>) {
			state.toAsset = action.payload;
		},
	}
});

export const { 
	setTokens,
	setTokensLoading,
	setError,
	setRecentOrders,
	setFromAsset,
	setToAsset,
} = tokensSlice.actions;


export const getTokens = (silentReload = false): AppThunk => {
	return async (dispatch) => {
		setError(null);
		if (!silentReload) {
			dispatch(setTokensLoading(true));
		}

		try {
			const response = await request.get('/api/v3/tokens/token');
			// const response = await request.get('/api/v3/tokens');
			if (response === null) {
				setError('coin loading error');
			} else {
				const { data } = response;
				dispatch(setTokens(data.list));
				return data;
			}
		} catch (e) {
			setError('coin loading error');
			console.log(e);
		} finally {
			dispatch(setTokensLoading(false));
		}
	};
};

export const updateTokens = (ids: any): AppThunk => {
	return async (dispatch, state) => {
		if (!ids || isEmpty(ids) || ids.toString() === '') return;

		const { tokens } = state();
		const { list, fromAsset, toAsset } = tokens;
		setError(null);
		const currentCoins = JSON.parse(JSON.stringify(list));
		const idArray = ids.split(';');
		const numArray = idArray.map(id => { return parseInt(id); });

		try {
			const response = await request.get('/api/v3/tokens/token');

			const { data } = response;
			numArray.map(id => {
				const coin = data.list.find(c => c.account?.accountId === id);
				const coinIndex = list.findIndex(c => c.account?.accountId === id);

				if (coin && coinIndex >= 0) {
					currentCoins[coinIndex] = coin;
				}
			});


			data.list.forEach(coin => {
				const id = list.findIndex(p => p.type.concat(p.info.symbol) === coin.type.concat(coin.info.symbol));
				if (id < 0) {
					currentCoins.push(coin);
				}

				if (fromAsset && coin.info.symbol === fromAsset?.symbol && coin.type === fromAsset?.type) {
					const assetList = fromAsset?.type === 'WALLET' ? transformAccountToAssets([coin]) : transformTokensToAssets([coin]);
					const asset = assetList[0] || null;
					if (asset) {
						dispatch(setFromAsset(asset));
					}
				}

				if (toAsset && coin.info.symbol === toAsset?.symbol && coin.type === toAsset?.type) {
					const assetList = toAsset?.type === 'WALLET' ? transformAccountToAssets([coin]) : transformTokensToAssets([coin]);

					const asset = assetList[0] || null;
					if (asset) {
						dispatch(setToAsset(asset));
					}
				}

			});
			dispatch(setTokens(currentCoins));

			return data;
		} catch (e) {
			setError('coin loading error');
			console.log(e);
		} finally {
			dispatch(setTokensLoading(false));
		}
	};
};

export const postTokenOrder = async (payload: CoinTradePayload) => {
	const response = await request.post('/api/tokens/custom-token/trade', payload);
	const { data } = response;
	return data;
};

export const postTokenPayinfo = async (payload: CoinPayinfoPayload): Promise<CoinPayinfo> => {
	const response = await request.post('/api/tokens/v3/payinfo', payload);
	const { data } = response;
	return data;
};

export const getTokensRecentOrders = (): AppThunk => {
	return async dispatch => {
		try {
			const today = new Date();

			const recentOrderPayload: TradeHistoryFilterParams = {
				type: TradeHistoryTypes.TOKEN_TRADE,
				trades: true,
				skip: 0,
				take: 50,
				from: addDays(today, -1).getTime(),
				to: today.getTime(),
			};

			const response = await getTradeHistory(recentOrderPayload);
			dispatch(setRecentOrders(response?.list ?? [])) || [];
			return response;
		} catch (e) {
			dispatch(setError('Recent Order loading error'));
		}
	};
};

export const getTradeHistory = async (payload: TradeHistoryFilterParams): Promise<TradeHistoryType> => {
	const response = await request.get('/api/documents', { params: payload });
	const { data } = response;
	return data;
};

export const postTokenTransfer = async (payload: TokenTransferPayload) => {
	const response = await request.post('/api/tokens/transfer', { ...payload });
	const { data } = response;
	return data;
};

export const postCryptoInternalTransfer = async (payload: CryptoTransferPayload) => {
	const response = await request.post('/api/v3/tokens/crypto/transfer', { ...payload });
	const { data } = response;
	return data;
};

export const lookupTokenWallet = async (walletId: string) => {
	const { data } = await request.get(`/api/tokens/lookup?walletId=${walletId}`
	);
	return data;
};

export const isValidTokenWallet = async (walletId: string) => {
	try {
		await lookupTokenWallet(walletId);
		return true;
	} catch (e) {
		const err = getErrorWithParams(e);
		if (err && err.error === 'notFound' && err.error_param === 'walletId') {
			return false;
		} else {
			return false;
		}
	}
};


let socketService: SocketService;
let walletsSubscriber: Subscription;
let tradeSubscriber: Subscription;
let depositSubscriber: Subscription;
let transactionsSubscriber: Subscription;
let withdrawalSubscriber: Subscription;


export const connectTokenSocket = (): void => {
	if (!socketService) {
		socketService = new SocketService('wallet');
	}
};

export const subscribeTokensSocket = (): AppThunk => {
	return async dispatch => {
		try {
			walletsSubscriber = socketService.listen('wallet.accountsId', {}).subscribe((data) => {
				dispatch(updateTokens(data));
				dispatch(getTokensRecentOrders());
			});
			tradeSubscriber = socketService.listen('wallet.transactions', {}).subscribe((data) => {
				if (data === 'updated') {
					dispatch(updateWalletTransactionsWithInitial(WalletTransactionType.TRANSACTIONS));
				}
			});
			depositSubscriber = socketService.listen('wallet.deposit', {}).subscribe((data) => {
				if (data === 'updated') {
					dispatch(updateWalletTransactionsWithInitial(WalletTransactionType.TOKEN_DEPOSITS));
				}
			});
			transactionsSubscriber = socketService.listen('wallet.trade', {}).subscribe((data) => {
				if (data === 'updated') {
					dispatch(updateWalletTransactionsWithInitial(WalletTransactionType.TOKEN_TRADES));
				}
			});
			withdrawalSubscriber = socketService.listen('wallet.withdrawal', {}).subscribe((data) => {
				if (data === 'updated') {
					dispatch(updateWalletTransactionsWithInitial(WalletTransactionType.TOKEN_WITHDRAWALS));
				}
			});
		} catch (e) {
			console.log(e);
			dispatch(setError(e));
		}
	};
};

export const unsubscribeTokensSocket = (): void => {
	walletsSubscriber.unsubscribe();
	tradeSubscriber.unsubscribe();
	depositSubscriber.unsubscribe();
	transactionsSubscriber.unsubscribe();
	withdrawalSubscriber.unsubscribe();
};


export default tokensSlice.reducer;
