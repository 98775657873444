import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import MuiFormText from '../../Shared/Widgets/MuiText/MuiFormText';
import CopyBox from '../../Shared/Widgets/Box/CopyBox';
import BackButtonMobileLanding from '../components/BackButtonMobileLanding';
import MobileHeader from '../components/MobileHeader';
import { useDispatch, useSelector } from 'react-redux';
import { generate2FACode } from '@/features/user/userSlice';
import { showException } from '@/features/swal/slice';
import { RootState } from '@/rootReducer';

interface Props {
	next: () => void,
	previous: () => void
}

const AuthCodeScan = ({ next, previous }: Props): React.ReactElement => {

	const { twoFactorCode, backupKey } = useSelector(
		(state: RootState) => state.user
	);

	const dispatch = useDispatch();

	const fetch2FACode = useCallback(() => {

		const getCode = async () => {
			try {
				await dispatch(generate2FACode());
			} catch (e) {
				showException(e?.message);
			}
		};

		open && getCode();
	}, [dispatch]);

	useEffect(() => {
		fetch2FACode();
	}, [fetch2FACode]);


	const { t } = useTranslation('translations');
	return (
		<Grid container id='scan-code-step'>
			<BackButtonMobileLanding onClick={previous} />
			<MobileHeader text={t('landing.authStep.title')} />
			<Grid container>
				<Grid item xs={12} container sx={{ rowGap: { xs: '22px', sm: '42px' } }}>
					<Grid item xs={12} >
						<MuiFormText sx={{ display: { xs: 'block', sm: 'none' } }}>
							{t('landing.authCodeStep.info0')}
						</MuiFormText>
						<MuiFormText sx={{ display: { xs: 'none', sm: 'block' } }}>
							{t('landing.authCodeStep.info1')}
						</MuiFormText>
					</Grid>
					<Grid item xs={12} container justifyContent='space-between' height='168px'>
						<Grid item xs={4} container sx={{ display: { xs: 'none', sm: 'block' } }}>
							<img style={{ borderRadius: '10px', width: '168px' }} src={twoFactorCode} alt='QR code' />
						</Grid>
						<Grid item xs={12} sm={8} container
							alignContent='space-between' sx={{ rowGap: { xs: '22px', sm: '8px' } }}
							height='100%'>
							<Grid item xs={12}>
								<MuiFormText >
									{t('landing.authCodeStep.info2')}
								</MuiFormText>
							</Grid>
							<Grid item xs={12}  >
								<CopyBox value={backupKey} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}  >
						<Button
							onClick={next}
							variant='contained'
							fullWidth
						>
							{t('form.buttons.continue')}
						</Button>
					</Grid>
				</Grid >
			</Grid >

		</Grid >);
};

export default AuthCodeScan;
