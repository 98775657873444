import * as React from 'react';
import { FormControl, FormHelperText, InputLabel,   MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StyledSelect from '@/componentsMui/Shared/Widgets/StyledSelect';
import { BankAccount } from '@/features/account/types';
import '@/componentsMui/Shared/Shared.css';
import { getCurrencySign } from '@/features/fees/slice';

interface Props {
    accountList: BankAccount[],
    activeAccount: BankAccount,
    insuficientFunds: boolean,
    onAccountChange: (newAccount: BankAccount) => void
}
const PaymentAccount = ({ accountList, insuficientFunds, activeAccount, onAccountChange }: Props) => {

    const { t } = useTranslation('translations');
    
    const handleAccountChange = (event: any) => {
        const newAccount = accountList.find(p => p.accountId === event.target.value);
        onAccountChange(newAccount);
    };

    return (<FormControl id="from" fullWidth>
        <InputLabel>{t('form.fields.from')}</InputLabel>
        <StyledSelect
            id="from"
            name="from"
            value={activeAccount?.accountId ?? ''}
            onChange={handleAccountChange}
            renderValue={() => <div> {`${activeAccount.name} (${getCurrencySign(activeAccount.currency)}${activeAccount.availableBalance})`}</div>}
        >
            {accountList.length > 0 &&
                accountList.map((account) => {
                    return (
                        <MenuItem
                            key={account.accountId}
                            value={account.accountId}>
                            <div>
                                <div className='select-primary-Item'>{account.name} </div>
                                <div className='select-hide-Item'>{getCurrencySign(account.currency)} {account.availableBalance} </div>
                            </div>
                        </MenuItem>
                    );
                })}
        </StyledSelect>
        {insuficientFunds && <FormHelperText>{t('form.error.insufficientFund')}</FormHelperText >}
    </FormControl>);

};

export default PaymentAccount;
