import React from 'react'; 
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Transaction } from '../TransactionStatements.spec';
import DetailLine from '@shared/Widgets/DetailLine';


interface Props {
	details: Transaction
}

const OwnAccounts = ({ details }: Props) => {
	const { t } = useTranslation('translations');

	return (
		<Grid container>
			<DetailLine
				label={t('transactiondetails.main.status')}
				value={t('status.' + details.status)}
			/>
			<DetailLine
				label={t('transactiondetails.remitter.fromAccount')}
				value={details.remitterAccount}
			/>
			<DetailLine
				label={t('transactiondetails.beneficiary.toAccount')}
				value={details.beneficiaryAccount}
			/>
			{/* {details.details && <DetailLine
                label={t('transactiondetails.details.reference')}
				value={details.details}
			/>} */}
		</Grid>
	);
};

export default OwnAccounts;
