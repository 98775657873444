import { Box, Theme } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface Props {
	status?: 'NEW' | 'SUCCESS' | 'WARNING'
}

const FileStatus = ({ status }: Props) => {
	const theme = useTheme() as Theme;

	const borderColor = () => {
		if (status === 'NEW')
			return theme.palette.text.primary;
		if (status === 'SUCCESS')
			return theme.selectedItemBg.default;
		if (status === 'WARNING')
			return theme.statusColor.error;

		return theme.palette.text.primary;
	};

	const highlightStyle = {
		height: '3rem',
		width: '3rem',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%',
		border: '2px solid transparent',
		background: borderColor(),
		WebkitBackgroundClip: 'text',
		backgroundClip: 'text',
		WebkitTextFillColor: theme.palette.text.primary,
		color: 'transparent',
		position: 'relative',
		zIndex: 0,
		textDecoration: 'none',
		'::before': {
			content: '""',
			position: 'absolute',
			zIndex: -1,
			inset: 0,
			border: '2px solid transparent',
			borderRadius: '50%',
			background: 'inherit',
			backgroundOrigin: 'border-box',
			backgroundClip: 'border-box',
			'WebkitMask': ' linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
			'WebkitMaskComposite': 'xor',
			'MaskComposite': 'exclude',
			'WebkitMaskRepeat': 'no-repeat'
		}
	};

	return (
		<Box sx={{ ...highlightStyle }}>
			{status === 'SUCCESS' && <CheckIcon style={{ fill: theme.palette.text.primary }} />}
			{status === 'WARNING' && <WarningAmberIcon style={{ fill: theme.statusColor.error, paddingBottom: '1px' }} />}
		</Box>
	);
};

export default FileStatus;
