import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import MuiTitle from '@/componentsMui/Shared/Widgets/MuiText/MuiTitle';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './PairTransactions.css';
import More from '@/componentsMui/Shared/Widgets/More';
import TradeHistoryHeaders from '../../TradeHistory/TradeHistoryHeaders';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import { WalletHistoryPairPayload, WalletTransaction } from '@features/walletHistory/types';
import TradeDetailsForm from '@/componentsMui/Wallet/TradeHistory/TradeDetailsForm';
import { getWalletHistoryPair } from '@features/walletHistory/slice';
import { getTime } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import WalletBox from '@/componentsMui/Shared/Widgets/Box/WalletBox';
import TradeHistoryLine from '../../TradeHistory/TradeHistoryLine';
import WalletHistoryLineMobile from '../../TradeHistory/WalletHistoryLineMobile';
import TrxGenericError from '@/componentsMui/TransactionsGrid/Components/TrxGenericError';
import { isEmpty } from 'lodash';

interface Props {
	accountId?: string
	toAccountId?: string
}
const PairTransactionsCrypto = ({ accountId, toAccountId }: Props) => {
	const smallWidth = useMediaQuery('(max-width:600px)');
	const smallHeight = useMediaQuery('(max-height: 600px)');
	const { t } = useTranslation('translations');

	const take = 4;
	const [list, setList] = useState([]);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showLoadMore, setShowLoadMore] = useState<boolean>(true);
	const lastTransactionRef = React.useRef(null);
	const [openTradeDetailsForm, setOpenTradeDetailsForm] = React.useState<boolean>(false);
	const [selectedTrx, setSelectedTrx] = React.useState<WalletTransaction>();
	const { user } = useSelector((state: RootState) => state.user);
	const accountRef = React.useRef(null);
	const { cryptoTradeUpdate } = useSelector((state: RootState) => state.coins);

	const fetchData = useCallback(async (currentListLength) => {
		try {

			if (!accountId || !toAccountId) return;
			setError(false);
			setLoading(true);
			setShowLoadMore(true);

			let isPairChange = false;

			if (accountRef?.current?.accountId !== accountId || accountRef?.current?.toAccountId !== toAccountId || accountRef?.current?.skip === 0) {
				isPairChange = true;
				accountRef.current = { accountId, toAccountId, skip: 0 };
			}

			const from = getTime(new Date(user.createdDate));

			const payload: WalletHistoryPairPayload = {
				skip: accountRef.current.skip,
				take: take,
				accountId: accountRef.current.accountId,
				from: `${from}`,
				// to: `${today.getTime()}`,
				toAccountId: accountRef.current.toAccountId,
			};

			const result = await getWalletHistoryPair(payload);
			if (isPairChange) {
				setList(result.list);
				if (result.count <= take) {
					setShowLoadMore(false);
				}
			}
			else {
				setList(l => [...l, ...result.list]);
				if (result.count <= currentListLength + take) {
					setShowLoadMore(false);
				}
			}
			if (accountRef.current.skip !== 0) { lastTransactionRef.current?.scrollIntoView({ behavior: 'smooth' }); }
		}
		catch (e) { setError(true); setList([]); }
		finally {
			setLoading(false);
		}
	}, [accountId, toAccountId, user?.createdDate]);



	useEffect(() => {
		fetchData(list.length);
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [fetchData]);

	useEffect(() => {
		if (!isEmpty(cryptoTradeUpdate)) { 
			if (accountRef?.current?.skip) {
				accountRef.current.skip = 0;
			}
			fetchData(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [cryptoTradeUpdate]);


	const loadMore = async () => {
		accountRef.current.skip = accountRef.current.skip + take;
		fetchData(list.length);
	};

	return (list.length === 0 && !error ? <></> :
		<WalletBox>
			<TradeDetailsForm
				open={openTradeDetailsForm}
				onClose={() => setOpenTradeDetailsForm(false)}
				item={selectedTrx}
			/>
			<Grid container id='pair-transactions' className='trx-content-wrap'>
				<Grid item pb={1}>
					<MuiTitle>{t('tokens.tradeHistory.history')}</MuiTitle>
				</Grid>
				{error ?
					<TrxGenericError onButtonClick={() => fetchData(0)} /> :
					<>
						{(smallWidth || smallHeight) ?
							<div className='scrollable-content'>
								{list?.length > 0 && list?.map((trx, index) => {
									return (
										<Box sx={{ py: '0.5rem' }} key={trx.documentId} ref={list.length === index + 1 ? lastTransactionRef : null}>
											<WalletHistoryLineMobile item={trx} onClick={() => { setOpenTradeDetailsForm(true); setSelectedTrx(trx); }} />
											{index + 1 < list.length && <StyledDivider />}
										</Box>
									);
								})}
							</div>
							:
							<Grid item className='trx-scroll'>
								<Box mr='1rem'>
									<TradeHistoryHeaders />
									{list?.length > 0 && list?.map((trx, index) => {
										return (
											<div key={trx.documentId} ref={list.length === index + 1 ? lastTransactionRef : null}>
												<TradeHistoryLine item={trx} />
												{index + 1 < list?.length && <StyledDivider />}
											</div>
										);
									})}
								</Box>
							</Grid>
						}
					</>
				}
			</Grid>
			{!error && <More show={showLoadMore || loading} onClick={loadMore} />}
		</WalletBox >);
};



export default PairTransactionsCrypto;
