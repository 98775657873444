import React from 'react';
import { useTranslation } from 'react-i18next';
import { DepositInstructions } from '@/features/account/types';
import DetailsBox from '@/componentsMui/Shared/Widgets/DetailsBox';
import DetailLine from '@/componentsMui/Shared/Widgets/DetailLine';

interface Props {
	depositInstructions?: DepositInstructions,
}

const DepositDetails = ({ depositInstructions }: Props): React.ReactElement => {

	const { t } = useTranslation('translations');
	return (
		<DetailsBox>
			<DetailLine label={t('wallet.beneficiary.accountNumber')} value={depositInstructions?.accountNumber} first/>
			<DetailLine label={t('wallet.beneficiary.bankPhone')} value={depositInstructions?.bankPhone} />
			<DetailLine label={t('wallet.beneficiary.bankAddress')} value={depositInstructions?.bankAddress} />
			<DetailLine label={t('wallet.beneficiary.beneficiaryAddress')} value={depositInstructions?.beneficiaryAddress} />
			<DetailLine label={t('wallet.beneficiary.creditTo')} value={depositInstructions?.creditTo} />
			<DetailLine label={t('wallet.beneficiary.depositoryBankName')} value={depositInstructions?.depositoryBankName} />
			<DetailLine label={t('wallet.beneficiary.routingNumber')} value={depositInstructions?.routingNumber} />
			{depositInstructions?.swiftCode &&
				<DetailLine label={t('wallet.beneficiary.swift')} value={depositInstructions?.swiftCode}/>
			}
			{depositInstructions?.intermediaryBankAccountNumber &&
				<DetailLine label={t('wallet.beneficiary.intermediaryBankAccountNumber')} value={depositInstructions?.intermediaryBankAccountNumber}/>
			}
			{depositInstructions?.intermediaryBankAddress &&
				<DetailLine label={t('wallet.beneficiary.intermediaryBankAddress')} value={depositInstructions?.intermediaryBankAddress}/>
			}
			{depositInstructions?.intermediaryBankName &&
				<DetailLine label={t('wallet.beneficiary.intermediaryBankName')} value={depositInstructions?.intermediaryBankName}/>
			}
			{depositInstructions?.intermediaryBankSwiftBic &&
				<DetailLine label={t('wallet.beneficiary.intermediaryBankSwiftBic')} value={depositInstructions?.intermediaryBankSwiftBic}/>
			}
			{depositInstructions?.intermediaryBsbCode &&
				<DetailLine label={t('wallet.beneficiary.intermediaryBsbCode')} value={depositInstructions?.intermediaryBsbCode}/>
			}
			{depositInstructions?.intermediaryIban &&
				<DetailLine label={t('wallet.beneficiary.intermediaryIban')} value={depositInstructions?.intermediaryIban}/>
			}
			{depositInstructions?.intermediarySortCode &&
				<DetailLine label={t('wallet.beneficiary.intermediarySortCode')} value={depositInstructions?.intermediarySortCode}/>
			}
			<DetailLine label={t('wallet.beneficiary.reference')} value={depositInstructions?.reference} />
			{depositInstructions?.notes &&
                <DetailLine label={t('wallet.beneficiary.notes')} value={depositInstructions?.notes}/>
			}
		</DetailsBox>
	);
};

export default DepositDetails;
