import { PaymentLine } from '@/features/bulkpayments/bulkPaymentsTypes';
import { Grid, Tooltip, useTheme } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import TypographyWithEllipsis from '@/componentsMui/Shared/Widgets/MuiText/TypographyWithEllipsis';
import { useTranslation } from 'react-i18next';

const PaymentLineCell = (params: GridRenderCellParams, fieldName: string) => {
    const { t } = useTranslation('translations');
    const theme = useTheme();
    const payment: PaymentLine = params.row;
    const errors = payment.errors?.filter(e => e.field === fieldName) || [];

    if ((params.value === null || params.value === undefined || params.value === '') && errors.length === 0) {
        return '-';
    }


    const getFieldErrorMessages = () => {
        let errorMessage = t(`bulkpayments.errors.${errors[0].errorCode}`);
        if (errors[0].errorCode === 'COP_VALIDATION_FAILED' && errors[0].errorCode) {
            errorMessage = t(`bulkpayments.errors.${errors[0].errorCode}`).concat(' ').concat(errors[0].errorMessage ?? '');
        }

        return errors.length === 1 ? <div>{errorMessage}</div> : <ul style={{ paddingLeft: '8px' }}>
            {errors.map((error, i) => {
                let errorMessage = t(`bulkpayments.errors.${error.errorCode}`);
                if (error.errorCode === 'COP_VALIDATION_FAILED' && error.errorMessage) {
                    errorMessage = t(`bulkpayments.errors.${error.errorCode}`).concat(' ').concat(error.errorMessage ?? '');
                }
                return (<li key={`${i}`}>{errorMessage}</li>);
            })}
        </ul>;
    };

    const hasErrors = errors.length > 0;

    return (<Grid container flexWrap='nowrap' flexDirection='row' alignItems='center'>
        {hasErrors && <Tooltip placement="top-start" title={getFieldErrorMessages()} ><ErrorIcon sx={{ color: theme.palette.error.dark, cursor: 'pointer' }} fontSize='small' /></Tooltip>}
        <TypographyWithEllipsis ml={hasErrors ? 0.5 : 0}>
            {params.value}
        </TypographyWithEllipsis>
    </ Grid>);
};

export default PaymentLineCell;
