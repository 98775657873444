import React, { useCallback } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import DocumentBox from '../../Widgets/Box/DocumentBox';
import { ReactComponent as MuiDocument } from '@/assets/MuiDocument.svg';
import { ReactComponent as MuiBigPlus } from '@/assets/MuiBigPlus.svg';
import MuiTextLabel from '../../Widgets/MuiText/MuiTextLabel';
interface Props {
	setFile: (value: any) => void,
	fileTypes?: string,
}

const defaultFileTypes = 'application/pdf';


const FileDrop = ({ setFile, fileTypes }: Props) => {

	const { t } = useTranslation('translations');

	const onDrop = useCallback((acceptedFiles) => {
		acceptedFiles.forEach((file) => {
			setFile(file);
		});
	}, [setFile]);

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop, accept: fileTypes ?? defaultFileTypes, maxSize: 10485760 });

	return (
		<Grid container justifyContent="center"  >
			<Grid item xs={12} mt='1.5rem' container justifyContent="center">
				<div {...getRootProps()}    >
					<input {...getInputProps()} />
					<DocumentBox >  {acceptedFiles.length > 0 ? <MuiDocument /> : <MuiBigPlus />}
					</DocumentBox>
				</div>
			</Grid>
			<Grid item xs={12} container justifyContent="center">
				{acceptedFiles.length > 0 ?
					<Grid item xs={12} container justifyContent="center">
						<MuiTextLabel > {acceptedFiles[0].name} </MuiTextLabel>
					</Grid>
					: <MuiTextLabel> {t('inbox.fileMax')}</MuiTextLabel>
				}
			</Grid>
		</Grid>);
};

export default FileDrop;
