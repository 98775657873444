import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import { Transaction } from '../TransactionStatements.spec';
import { isFiat } from '@/helpers/currency';
import { useTranslation } from 'react-i18next';
import { timeSince } from '@/helpers/date';
import { roundFlexible } from '@/helpers/round';
import MuiTextTrx from '@/componentsMui/Shared/Widgets/MuiText/MuiTextTrx';
import { getCurrencySign } from '@/features/fees/slice';
import { TransactionStatus, TransactionType } from '@/features/transactions/types'; 
import { showErrorNotification } from '@/features/swal/slice';
import TransactionStatusBox from '@/componentsMui/Shared/Widgets/Box/TransactionStatusBox';
import MuiTextTrxSmall from '@/componentsMui/Shared/Widgets/MuiText/MuiTextTrxSmall';
import TransactionIcon from '@/componentsMui/Transactions/Details/TransactionIcon';
import { cancelFxDocument } from '@/features/fxDocuments/slice';

interface Props {
	transaction: Transaction,
	type?: string,
	openTransactionDetailsForm: (trx: Transaction) => void,
	fetchData: () => void
}

const TransactionMobileLine = ({ transaction, openTransactionDetailsForm, fetchData, type }: Props) => {
	const { t } = useTranslation('translations');
	const [sumbitting, setSubmitting] = React.useState<boolean>(false);
	const isFiatAsset = isFiat(transaction.asset) || type === 'CARD';
	const isFxCancel = transaction.activity === TransactionType.FX_TRANSACTION && transaction.status === TransactionStatus.PENDING;

	const cancelExchangeOrder = async (documentId: number) => {
		try {
			setSubmitting(true);
			await cancelFxDocument(documentId);
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
		finally { setSubmitting(false); }
	};


	return (
		<Box
			onClick={() => openTransactionDetailsForm(transaction)}
			style={{ cursor: 'pointer' }}
			pb='1rem'
			// sx={{ backgroundColor: theme.bgSecondaryButton }}
			borderRadius='10px'
			width='100%'
		>
			<Grid item xs={12} container alignItems='center' columnSpacing={1} wrap='nowrap'>
				<Grid item justifyContent='flex-start' alignItems='center'>
					{transaction?.activity &&
						<TransactionIcon activity={transaction.activity} beneficiaryName={transaction.beneficiary} />
					}
				</Grid>
				<Grid item
					sx={{
						display: 'flex',
						flexDirection: 'column',
					}}
					justifyContent='space-evenly'
					maxWidth='100%'
					minWidth='0'
				>
					<Grid item xs={12}>
						<Typography color="text.primary" noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}>
							{type === 'TOKEN' && transaction.activity === 'OWN_TRANSFER' ?
								transaction.amount !== null && transaction.amount > 0 ?
									t('activity.tokens.BUY_PURCHASE') :
									t('activity.tokens.SELL_PURCHASE') :
								transaction?.messageCode ? t('activity.' + transaction.messageCode) :
									t('activity.' + transaction.activity)}
						</Typography>
					</Grid>
					<Grid item xs={12} justifyContent={'flex-end'}>
						<MuiTextTrxSmall color='text.secondary'>
							{timeSince(transaction.date)}
						</MuiTextTrxSmall>
					</Grid>
				</Grid>
				<Grid item ml='auto' direction='column'
					display='flex'
					justifyContent='space-evenly'
				>
					<Grid>
						<MuiTextTrx noWrap textAlign='right' fontWeight={700} sx={theme => ({ color: transaction.amount < 0 ? theme.palette.text.secondary : theme.palette.text.primary, whiteSpace: 'nowrap' })}>
							{transaction.amount && (transaction.amount < 0 ? '- ' + (transaction?.currencySymbol ?? getCurrencySign(transaction.asset)) + ' ' : (transaction?.currencySymbol ?? getCurrencySign(transaction.asset)) + ' ')}
							{isFiatAsset ?
								<NumberFormat displayType={'text'} decimalScale={2}
									fixedDecimalScale={true} thousandsGroupStyle='thousand'
									thousandSeparator={true} value={Math.abs(transaction.amount)} /> :
								roundFlexible(Math.abs(transaction.amount))
							}
						</MuiTextTrx>
					</Grid>
					<Grid container justifyContent='flex-end'>
						<TransactionStatusBox
							sumbitting={sumbitting}
							isFxCancel={isFxCancel}
							status={transaction.status}
							{...(isFxCancel && { onClick: () => !sumbitting && cancelExchangeOrder(transaction.documentId) })}>
							{t('status.' + (isFxCancel ? 'CANCEL' : transaction.status))}
						</TransactionStatusBox>
					</Grid>
				</Grid>
			</Grid>
		</Box >);
};

export default TransactionMobileLine;

