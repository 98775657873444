import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import { KYC, KycAddress, KycAddressPayload, ProfilePayload } from './types';
import request from '../../services/request';

const initialState: KYC = {
	token: null,
	error: null,
	loading: false,
	kycAddressList: [],
	kycAddressLoading: false,
};

const kycSlice = createSlice({
	name: 'status',
	initialState,
	reducers: {
		setToken(state, action: PayloadAction<string>) {
			state.token = action.payload;
		},
		setError(state, action: PayloadAction<string>) {
			state.error = action.payload;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		setKycAddressList(state, action: PayloadAction<KycAddress[]>) {
			state.kycAddressList = action.payload;
		},
		setKycAddressLoading(state, action: PayloadAction<boolean>) {
			state.kycAddressLoading = action.payload;
		},
	}
});

export const { setToken, setError, setLoading, setKycAddressList, setKycAddressLoading } = kycSlice.actions;

export const kycSelector = (state: { kycSelector: KYC }) =>
	state.kycSelector;

export const getToken = (kycService: string): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			dispatch(setError(null));
			const response = await request.get(`/api/identity/${kycService}/accesstoken`);
			const { data } = response;

			dispatch(setToken(data.token));
			setLoading(false);
		}
		catch (e) {
			dispatch(setError('Access token generation failed'));
		}
		dispatch(setLoading(false));
	};
};

export const getKycAddressList = (): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setKycAddressLoading(true));
			const response = await request.get('/api/kyc-address');
			dispatch(setKycAddressList(response?.data?.list || []));
			setLoading(false);
		}
		catch (e) {
			dispatch(setKycAddressList([]));
		}
		finally {
			dispatch(setKycAddressLoading(false));
		}
	};
};

export const getRefreshedToken = async () => {
	const response = await request.get('/api/identity/sumsub/accesstoken');
	const { data } = response;
	return data.token;
};

export const requestFinalizeReviewStatus = async () => {
	return async () => {
		await request.get('/api/identity/sumsub/finalizeReviewStatus');
	};
};

export const profileUpdate = (payload: ProfilePayload): AppThunk => {
	return async () => {
		await request.post('/api/identity/profile', payload);
	};
};

export const createOnfidoCheck = (): AppThunk => {
	return async () => {
		await request.get('/api/identity/onfido/check');
	};
};


export const createKycAddress = async (payload: KycAddressPayload) => {
	const response = await request.post('/api/kyc-address', payload);
	const { data } = response;
	return data;
};


export default kycSlice.reducer;
