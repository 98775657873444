import { useTheme } from '@mui/material';
import React from 'react';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, YAxisProps } from 'recharts';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';


interface Props {
    chartData: any[],
    loading: boolean,
    dataKey?: string,
    strokeWidth?: number,
    yAxisProps?: YAxisProps,
    chartTooltip: (tdata: any, ...others) => React.ReactElement
}

const TotalLineChart = ({ chartData, loading, dataKey, yAxisProps, strokeWidth, chartTooltip }: Props): React.ReactElement => {

    const theme = useTheme();

    if (loading) return <Spinner />;

    return (
        <ResponsiveContainer width='99%' >
            <LineChart
                width={730}
                height={250}
                data={chartData}
                margin={{
                    top: 3,
                    right: 0,
                    left: 0,
                    bottom: 0,
                }}
            >
                <XAxis hide={true} />
                <YAxis hide={true} {...yAxisProps}
                />
                <Tooltip content={chartTooltip}
                    cursor={{ stroke: '#959799', strokeWidth: 1, strokeDasharray: '5,5' }}

                />
                <Line
                    dot={false}
                    dataKey={dataKey ? dataKey : 'total'}
                    strokeWidth={strokeWidth ? strokeWidth : 2}
                    stroke={theme.highlight}
                >
                </Line>
            </LineChart>
        </ResponsiveContainer>
    );
};

export default TotalLineChart;
