import * as React from 'react';
import { SvgIcon } from '@mui/material';

const MenuWalletIcon = (props) => (
    <SvgIcon {...props} >
        <svg width="22" height="22" viewBox="0 0 22 22" fill="current" stroke="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.326 8.844c.346-2.311-1.414-3.554-3.82-4.383l.78-3.131L14.38.855l-.76 3.049a78.953 78.953 0 00-1.527-.36l.765-3.069L10.954 0l-.78 3.13a63.051 63.051 0 01-1.218-.286l.002-.01-2.628-.656-.507 2.036s1.414.324 1.384.344c.772.193.912.704.888 1.109l-.889 3.567c.053.014.122.033.198.064l-.201-.05-1.247 4.997c-.094.235-.334.587-.873.453.019.028-1.386-.346-1.386-.346l-.946 2.182 2.48.619c.462.115.914.236 1.36.35l-.79 3.168 1.905.475.78-3.134c.521.141 1.026.271 1.52.394l-.779 3.119 1.907.475.788-3.161c3.25.615 5.695.367 6.724-2.573.829-2.367-.042-3.733-1.752-4.623 1.246-.287 2.184-1.106 2.434-2.799zm-4.355 6.107c-.589 2.367-4.574 1.088-5.866.767l1.046-4.196c1.293.322 5.436.96 4.82 3.43zm.59-6.141c-.538 2.153-3.855 1.06-4.93.79l.948-3.805c1.076.268 4.542.77 3.982 3.015z"
                fill="current"
            />
        </svg>
    </SvgIcon>
);

export default MenuWalletIcon;
