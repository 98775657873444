
import * as React from 'react';
import './BankingProductBox.css';
import { BoxProps } from '@mui/material';
import { FC } from 'react';
import ProductBox from '../Box/CoverBox/MonoBgBox';

interface Props extends BoxProps {
	selected: boolean
}

/*  selected item in product scroll, product card
Defines box width, heigth and content aligment*/
const CardProductBox: FC<Props> = (props: Props) => {

	const { selected, sx, ...others } = props;


	return (
		<ProductBox selected={selected}
			mainClassName='card-product-box'
			itemClassName='card-product-box__item-wrap'
			sx={sx}
			{...others}>
			{props.children}
		</ProductBox>);


};


export default CardProductBox;


