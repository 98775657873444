import React from 'react';
import { AccountType } from '@/features/operations/types';
import { Box, Grid } from '@mui/material';
import BankAccountDetails from './BankAccountDetails';
import { BankAccount } from '@/features/account/types';
import TransactionGridShort from '@/componentsMui/TransactionsGrid/TransactionGridShort';


interface Props {
	bankAccount: BankAccount,
	accountTypeIsValid: boolean,
}

const AccountView = ({ bankAccount, accountTypeIsValid }: Props): React.ReactElement => {
	return (<Grid container >
		<Grid item xs={12} >
			<BankAccountDetails bankAccount={bankAccount} />
		</Grid>
		<Grid item xs={12}>
			<Box id='banking-account-view'
				sx={(theme) => ({
					background: {
						xs: 'none', sm: theme.backgroundBoxLevel1
					},
					borderRadius: theme.mainBorderRadius,
					p: 0,
					width: '100%',
					mb: { xs: 3, md: 0 },
				})}>
				{accountTypeIsValid &&
					<TransactionGridShort
						key={`${bankAccount.balance}`}
						type={bankAccount.type as AccountType}
						account={bankAccount}
					/>}
			</Box>
		</Grid>
	</Grid >);
};

export default AccountView;

