import * as React from 'react';
import { useTranslation } from 'react-i18next';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import { Grid } from '@mui/material';
import MuiTextConfirmationLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextConfirmationLabel';
import HelpTopics, { HelpTopicsEnum } from '../Components/HelpTopics';
import './HelpTopicSteps.css';
interface Props {
	onClose: () => void,
	onSelect: (value: HelpTopicsEnum) => void
}

const HelpTopicSteps = ({ onClose, onSelect }: Props) => {
	const { t } = useTranslation('translations');

	return (
		<Grid id='help-topic-step' container>
			<Grid item xs={12}>
				<FormHeader title={t('faq.createNewTicket')} showBack={true} onBack={onClose} />
			</Grid>
			<Grid item xs={12}>
				<MuiTextConfirmationLabel color='text.secondary'>{t('faq.chooseTopic')}</MuiTextConfirmationLabel>
			</Grid>
			<Grid item xs={12} pt='1.875rem'>
				<HelpTopics iconWidth='1.4rem' onClick={onSelect} />
			</Grid>
		</Grid>);
};

export default HelpTopicSteps;
