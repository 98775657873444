import * as React from 'react';

import { Dialog, DialogActions, DialogContent, Grid, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { openForm } from '@/features/forms/slice';
import { useEffect, useState } from 'react';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import BarChart from '@/componentsMui/Shared/Widgets/BarChart/BarChart';
import DiplayBigNumber from '@/componentsMui/Shared/Components/DiplayBigNumber';
import { getCurrencySign } from '@/features/fees/slice';
import FormPaperEarnings from '@/componentsMui/Shared/Widgets/Dialog/FormPaperEarnings';
import { GraphData } from '@/features/earn/types';
import { getEarningGraphData } from '@/features/earn/earningsSlice';
import { formatDateWithPattern } from '@/helpers/date';
import TokenAvatar, { TokenAvatarSizeEnum } from '@shared/Widgets/Avatars/TokenAvatar';

const VaultEarningsForm = () => {

    const { t } = useTranslation('translations');
    const closeModal = useCloseDialogNoEvent();
    const dispatch = useDispatch();
    const { loading, totalEarningsAmount, list } = useSelector((state: RootState) => state.vaults);
    const [graphData, setGraphData] = useState<GraphData[]>([]);
    const [dataset, setDataset] = useState<any[]>([]);
    const [filteredPlan, setFilteredPlan] = useState<any>('ALL');

    useEffect(() => {
        dispatch(openForm());
    }, [dispatch]);


    useEffect(() => {
        const fetchGraphData = async () => {
            let data = [];
            if (filteredPlan === 'ALL') {
                data = await getEarningGraphData('EUR');
            } else {
                data = await getEarningGraphData('EUR', filteredPlan);
            }
            setGraphData(data);
        };
        fetchGraphData();
    }, [filteredPlan]);


    useEffect(() => {
        if (graphData.length === 0) return;
        const ds = [];
        graphData.map(item => {
            ds.push({ legend: formatDateWithPattern(`01-${item.period}`, 'MMM yy', 'dd-MM-yyyy'), amount: item.amount, tooltipSecondary: formatDateWithPattern(`01-${item.period}`, 'MMMM, yyyy', 'dd-MM-yyyy') });
        });


        setDataset(ds);

    }, [graphData]);

    const handlePlanChange = (event: any) => {
        const val = event.target.value;
        setFilteredPlan(val);
    };

    return (
        <Dialog
            id="earn-deposit-form"
            open={true}
            onClose={closeModal}
            fullWidth
            maxWidth="xl"
            PaperComponent={FormPaperEarnings}
        >
            <DialogActions>
                <Grid container justifyContent='space-between' alignItems='flex-start'>
                    <Grid>
                        <MuiHeader>{t('vaults.statistics.yourTotalEarnings')}</MuiHeader>
                        <DiplayBigNumber
                            prefix={<Typography variant='highlight' fontSize='1.625rem'>
                                {getCurrencySign('EUR')}
                            </Typography>}
                            amount={totalEarningsAmount} />

                        <Select
                            variant='outlined'
                            disableUnderline
                            value={filteredPlan}
                            onChange={handlePlanChange}
                            sx={{ width: '100%', marginTop: '0.5rem', height: '2rem !important' }}
                        >
                            <MenuItem
                                key='ALL'
                                value='ALL'
                            >{t('vaults.plans.allPlans')}</MenuItem>
                            {list.map(v => {
                                return <MenuItem
                                    key={v.proc}
                                    value={v.proc}>
                                    <Grid item flexWrap='nowrap' display='flex'>
                                        <Grid item sx={{mr: '1rem'}}>
                                            <TokenAvatar symbol={v?.processProperties?.ccy}
                                                         logoSource={v?.currencyLogo}
                                                         variant={TokenAvatarSizeEnum.medium}/>
                                        </Grid>
                                        {v.name}
                                    </Grid>
                                </MenuItem>;
                            })}
                        </Select>
                    </Grid>
                    <IconButton onClick={closeModal}  >
                        <MuiCloseIcon />
                    </IconButton>
                </Grid>


            </DialogActions>
            <DialogContent>
                {loading ? <Spinner />
                    :
                    <BarChart currency='EUR' dataset={dataset} />
                }
            </DialogContent>
        </Dialog >
    );
};

export default VaultEarningsForm;
