import { Paper } from '@mui/material';
import { withStyles } from '@mui/styles';

/* Fixed width, scroll inside form */
const FormPaperLarge = withStyles(() => ({
	root: {
		//maxHeight: '90vh',
		//minHeight: '80vh',
		minWidth: '672px',
		maxWidth: '672px',
		width: '672px',
		padding: '40px',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		//remove scroll bar
		'-ms-overflow-style': 'none',
		'scrollbar-width': 'none',
		'&::-webkit-scrollbar': {
			display: 'none'
		},
		['@media only screen and (max-height: 700px)']: {
			maxHeight: 'unset',
			minHeight: 'unset',
			minWidth: 'unset',
			maxWidth: '100%',
			width: '100%',
			height: '100%',
			padding: '1rem',
			margin: 0,
			borderRadius: 0,
		},
		['@media only screen and (max-width:600px)']: {
			maxHeight: 'unset',
			minHeight: 'unset',
			minWidth: 'unset',
			maxWidth: '100%',
			width: '100%',
			height: '100%',
			padding: '1rem',
			margin: 0,
			borderRadius: 0,
		},
	}
}))(Paper);

export default FormPaperLarge;
