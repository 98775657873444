import * as React from 'react';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import MyFees, { FeeSection } from '@/componentsMui/Dashboard/MyAccount/MyFees/MyFees';

type Props = {
    currency: string,
    process: string,
    onBack?: () => void,
    onClose?: () => void,
}

const ShowCardFeeStep = ({ currency, process, onBack, onClose }: Props) => {
    const { t } = useTranslation('translations');
    const userLevelId = useSelector((state: RootState) => state.user?.user?.userLevelId);
    return (
        <Grid container alignContent='space-between' flex={1} >
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <FormHeader title={t('cards.cardSettings.menu.showFees')} showBack={!!onBack} onBack={onBack} />
                </Grid>
                <Grid item xs={12} pb={2}>
                    <MyFees userLevelId={userLevelId}
                        showFeeSection={FeeSection.CARD}
                        showOneProduct={`${currency}-${process}`}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={12}>
                <Grid item xs={6}>
                    <Button
                        variant='customOutlined'
                        onClick={onClose}
                    >
                        {t('form.buttons.close')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );

};

export default ShowCardFeeStep;
