import { GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import { TransactionType } from '@/features/transactions/types';
import MuiTextCaption from '@shared/Widgets/MuiText/MuiTextCaption';
import { Tooltip } from '@mui/material';


const TrxDetailsCell = (params: GridRenderCellParams) => {
    const transaction = params.row;
    const isSell = transaction.amount < 0;
    return (<>
        {transaction.activity === TransactionType.TRADE ?
            <MuiTextCaption variant='gradient' fontWeight={400}>
                {isSell ? `${transaction.currency}-${transaction.conversionCurrency}` : `${transaction.conversionCurrency}-${transaction.currency}`}
            </MuiTextCaption>
            :
            <Tooltip title={transaction.details} arrow>
                <div>
                <MuiTextCaption variant='gradient' fontWeight={400}>
                    {transaction.details}
                </MuiTextCaption>
                </div>
            </Tooltip>
        }
    </>);
};

export default TrxDetailsCell;
