import request from '@services/request';
import { Fee, FeeInfo, FeeType, FeesFimitsType, TransferFeePayloadType, FeeDetails } from '@features/fees/types';
import { isEmpty } from 'lodash';
import { Operations } from '../operations/types';

export const getProcessFees = async () => {
	const response = await request.get('/api/fees');
	const { data } = response;
	return data;
};

export const getTurnoverLimits = async () => {
	const response = await request.get('/api/fees/limits/BANK');
	const { data } = response;
	return data;
};

export const getTiers = async () => {
	const response = await request.get('/api/fees/levels');
	const { data } = response;
	return data;
};

export const getFeesByCategory = async (type: Operations) => {
	const response = await request.get(`/api/fees/byType/${type}`);
	const { data } = response;
	return data;
};

export const postTransferFee = async (payload: TransferFeePayloadType): Promise<FeeInfo> => {
	const response = await request.post('/api/fees/payinfo', payload);
	const { data } = response;
	return data;
};


export const getCurrencySign = (currency: string): string => {
	switch (currency) {
		case 'EUR': return '€';
		case 'GBP': return '£';
		case 'USD': return '$';
		default: return currency;
	}
};


export const getFeeDetails = (fee: FeeDetails, t: any) => {
	let feeText = '';
	let montlyText = '';
	let depositText = '';

	switch (fee.type) {
		case FeeType.FIXED:
			feeText =
				fee.feeAmount === 0
					? t('fees.myFees.free')
					: `${fee.feeAmount.toFixed(2)} ${fee.ccy}`;
			break;
		case FeeType.FIXED_MONTH:
			feeText =
				fee.feeAmount === 0
					? t('fees.myFees.free')
					: `${fee.feeAmount.toFixed(2)} ${fee.ccy}`;
			montlyText =
				fee.feeMonth === 0
					? t('fees.myFees.free')
					: `${fee.feeMonth.toFixed(2)} ${fee.ccy}`;
			depositText = `${fee.feePercent}% ${fee.minFee > 0 ? `(${t('fees.myFees.min')}. ${fee.minFee} ${fee.ccy})` : ''
				}`;
			break;
		case FeeType.VOLUME:
		case FeeType.PAYMENT:
			if (fee.feePercent === 0 && fee.minFee === 0) {
				feeText = t('fees.myFees.free');
			} else {
				feeText = `${fee.feePercent}% ${fee.minFee > 0 ? `(${t('fees.myFees.min')}. ${fee.minFee} ${fee.ccy})` : ''
					}`;
			}
			break;
		default:
			feeText = '';
			montlyText = '';
			break;
	}
	return { feeText, montlyText, depositText };
};


const getFeePerType = (fee: Fee, amount: number) => {

	let feeAmount: number;

	switch (fee.type) {
		case FeeType.FIXED:
			feeAmount = fee.feeAmount;
			break;
		case FeeType.FIXED_MONTH:
			feeAmount = parseFloat(fee.feeMonth);
			break;
		case FeeType.VOLUME:
			feeAmount = Math.max(fee.minFee, amount * (fee.feePercent / 100));
			break;
		case FeeType.PAYMENT:
			feeAmount = Math.max(fee.minFee, amount * (fee.feePercent / 100));
			break;
		default:
			feeAmount = 0;
	}
	return feeAmount;
};

export const sumFees = (fees: Fee[], amount: number, feeType?: FeeType): number => {
	const _fees = feeType ? fees.filter(fee => fee.type === feeType) : fees;

	if (isEmpty(_fees)) {
		return 0;
	}
	const totalFee = _fees.reduce((acc, curval) => acc + getFeePerType(curval, amount), 0);
	return totalFee;
};

export const sumMonthlyFees = (fees: Fee[]): number => {
	const _fees = fees.filter(fee => fee.type === FeeType.FIXED_MONTH);

	if (isEmpty(_fees)) {
		return 0;
	}

	let monthlyFee = 0;
	_fees.map(fee => {
		monthlyFee += parseFloat(fee.feeMonth);
	});

	return monthlyFee;
};

export const sumCreationFees = (fees: Fee[]): number => {
	const _fees = fees.filter(fee => fee.type === FeeType.FIXED_MONTH || fee.type === FeeType.FIXED);

	if (isEmpty(_fees)) {
		return 0;
	}

	let creationFee = 0;
	_fees.map(fee => {
		creationFee += fee.feeAmount;
	});

	return creationFee;
};

export const getFeesByUserId = async (): Promise<FeesFimitsType[]> => {
	const response = await request.get('/api/fees/userid');
	const { data } = response;
	return data;
};

export const getFeesByUserIdAnsUserLevel = async (
	userLevelId: number
): Promise<FeesFimitsType[]> => {
	const response = await request.get(
		`/api/fees/userid/subscription/${userLevelId}`
	);
	const { data } = response;
	return data;
};

