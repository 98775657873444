import React, { useEffect } from 'react';
import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import ServiceLoading from '@/componentsMui/ServiceLoading';
import TrustList from './TrustList';
import { connect as connectTrust, subscribe as subscribeTrust, unsubscribe as unsubscribeTrust } from '@features/trust/slice';
import { AccountType } from '@/features/operations/types';
import { Grid } from '@mui/material';
import StatusCard from '@/componentsMui/Dashboard/HomeSummary/Components/StatusCard';


const Trust = (): React.ReactElement => {

	const accounts = useSelector((state: RootState) => state.trust.accounts);
	const error = useSelector((state: RootState) => state.trust.error);
	const dispatch = useDispatch();

	useEffect(() => {
		connectTrust();
		dispatch(subscribeTrust());
		return () => {
			unsubscribeTrust();
		};

	}, [dispatch]);

	return (
		<Grid container
			width='100%'
			flexDirection='column'
			flex={1} >
			<Grid item xs>
				<Grid item xs={12} justifyContent='center' >
					<StatusCard />
				</Grid>
			<ServiceLoading
				type={AccountType.TRUST}
				loading={false}
				error={error}
				checkList={true}
				list={accounts}>
				<TrustList accounts={accounts} />
			</ServiceLoading>
			</Grid>
		</Grid>);
};

export default Trust;
