import { Paper } from '@mui/material';
import { withStyles } from '@mui/styles';


const StyledPaper = withStyles((theme) => ({
	root: {
		background: theme.palette.background.paper,
		borderRadius: '16px',
		'& .MuiAutocomplete-listbox': {
			'&-ms-overflow-style': 'none',
			'&scrollbar-width': 'none',
			'&::-webkit-scrollbar': {
				display: 'none'
			},
			'& .MuiAutocomplete-option ': {
				opacity: 0.4,
			},
			'& .MuiAutocomplete-option.Mui-focused': {
				background: theme.palette.background.paper,
				opacity: 1,
			}
		},
	}
}))(Paper);

export default StyledPaper;
