import * as React from 'react';

import { Alert, Button, Dialog, Grid, IconButton } from '@mui/material';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import DialogContentNoScrollbar from '@/componentsMui/Shared/Widgets/Dialog/DialogContentNoScrollbar';
import { useTranslation } from 'react-i18next';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { postSupportEmail } from '@/features/tickets/slice';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import MuiTextAlert from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAlert';
import { useEmail } from '@/helpers/customHook/useValidations';

interface Props {
	open: boolean,
	onClose: () => void,
}

const ContactSupportForm = ({ open, onClose }: Props) => {
	const { t } = useTranslation('translations');

	const emailValidation = useEmail();

	const initialValues = {
		description: '',
		email: '',
	};

	const validationSchema = Yup.object({
		email: emailValidation,
		description: Yup.string()
			.min(
				4,
				t('form.validator.minChars', {
					field: t('login.message'),
					chars: '4',
				})
			)
			.max(
				512,
				t('form.validator.maxChars', {
					field:t('login.message'),
					chars: '512',
				})
			)
			.required(t('form.validator.required')),
	});

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		const { email, description } = formData;

		try {
			setSubmitting(true);

			const formData = new FormData();
			formData.append('description', description);
			formData.append('email', email);

			await postSupportEmail(formData);
			showSuccessNotification(t('login.supportSuccess'));

		} catch (err) {
			console.log(err);
			showErrorNotification(err);
		} finally {
			setSubmitting(false);
			onClose();
		}
	};

	return (
		<Dialog
			id="switch-account-form"
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
		>
			<DialogContentNoScrollbar>
				<Grid
					container
					sx={{ p: { xs: '1rem', md: '2rem' } }}
				>
					<Grid item container justifyContent="space-between" alignItems="center" pb={4}>
						<Grid item>
							<MuiHeader>{t('login.contactSupport')}</MuiHeader>
						</Grid>
						<Grid item>
							<IconButton onClick={onClose}  >
								<MuiCloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item container spacing={1}>
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={submit}
						>
							{({ errors, isSubmitting }) => (
								<Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
									<Grid container spacing={1} >
										<Grid item xs={12} alignItems='center'>
											<Alert severity="warning" >
												<MuiTextAlert >{t('login.supportInfoText')}</MuiTextAlert>
											</Alert>
										</Grid>
										<Grid item xs={12}>
											<MuiTextField
												name="email"
												label={t('login.emailAddress')}
												fullWidth />
										</Grid>
										<Grid item xs={12}>
											<MuiTextField
												name="description"
												label={t('login.message')}
												multiline
												rows={4}
												fullWidth />
										</Grid>
									</Grid>
									<Grid container flex={1} alignItems='flex-end' justifyContent='space-between' pt={'3rem'}>
										<Button
											key='buttonCancel'
											variant='customOutlined'
											onClick={onClose}
										>   	{t('form.buttons.close')}
										</Button>
										<Button
											key='prieview'
											type='submit'
											disabled={!isEmpty(errors) || isSubmitting}
											variant='contained'> 	{t('form.buttons.send')}
										</Button>
									</Grid>
								</Form>
							)}
						</Formik >
					</Grid>
				</Grid>
			</DialogContentNoScrollbar>
		</Dialog >
	);
};

export default ContactSupportForm; 
