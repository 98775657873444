export interface Statistics {
	activeFriends: {
		count: number;
		countLastWeek: number;
	},
	friends: {
		count: number;
		countLastWeek: number;
	},
	totalEarnings: {
		amount: number;
		changeAmount: number;
		currency: string;
	},
	topReferrer: {
		amount: number;
		ccy: string;
		userEmailMask: string;
		userPhoneMask: string;
	},
	plannedReferralsStatistic: {
		amount: number;
		count: number;
		currency: string;
	}
}

export interface Program {
	code: RewardTypes;
	rewardAmount: number;
	rewardCcy: string;
	paymentType: RewardPaymentType;
	application: RewardApplicationType;
	targetAmount: number;
	targetCcy: string;
}

export enum RewardPaymentType {
	PERCENT = 'PERCENT',
	AMOUNT = 'AMOUNT',
}

export enum RewardApplicationType {
	IMMEDIATE = 'IMMEDIATE',
	MONTHLY = 'MONTHLY',
}

export enum RewardTypes {
	FEES = 'FEES',
	SIGN_UP_BONUS = 'SIGN_UP_BONUS',
	TRADE_TURNOVER = 'TRADE_TURNOVER',
	SIGN_UP_BONUS_TARGET = 'SIGN_UP_BONUS_TARGET',
	ADVANCED_FEES = 'ADVANCED_FEES',
}

export interface Referees {
	count: number;
	list: Referrer[];
}

export interface Payments {
	count: number;
	list: Payment[];
}

export interface Referrer {
	referee: string;
	registrationDate: string;
	totalEarn: number;
	verified: boolean;
}

export interface Payment {
	id: number;
	userId: number;
	ccy: string;
	amount: number;
	transferDate: string;
	loyaltyType: RewardTypes;
	status: PaymentStatus;
}

export enum GridTab {
    MY_REFERRALS, MY_REWARDS
}

export interface GridPayload {
	skip: number;
	take: number;
}

export enum PaymentStatus {
	PLANNED = 'PLANNED', 
	COMPLETED = 'COMPLETED'
}
