import * as React from 'react';
import { useTheme } from '@mui/styles';
import { Box as MuiBox, BoxProps, Theme } from '@mui/material';
import { FC } from 'react';

interface Props extends BoxProps {
	selected?: boolean,
}

/* Used in account scrolls */
const GradientBorderBox: FC<Props> = (props: Props) => {

	const { sx, ...others } = props;

	const theme = useTheme() as Theme;

	const highlightStyle = {
		background: theme.selectedItemBg.default,
		WebkitBackgroundClip: 'text',
		backgroundClip: 'text',
		//WebkitTextFillColor: 'white',
		color: 'transparent',
		borderRadius: theme.inputField.borderRadius,
		position: 'relative',
		zIndex: 0,
		textDecoration: 'none',
		'::before': {
			content: '""',
			position: 'absolute',
			zIndex: -1,
			inset: 0,
			border: '1px solid transparent',
			borderRadius: theme.inputField.borderRadius,
			background: 'inherit',
			backgroundOrigin: 'border-box',
			backgroundClip: 'border-box',
			'WebkitMask': ' linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
			'WebkitMaskComposite': 'xor',
			'MaskComposite': 'exclude',
			'WebkitMaskRepeat': 'no-repeat'
		}
	};
	return (
		<MuiBox
			sx={{
				...sx,
				...highlightStyle
			}}
			{...others}> {props.children}
		</MuiBox >
	);
};
export default GradientBorderBox;


