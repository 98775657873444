import React from 'react';

interface Props {
	countryCodeAlpha2?: string
}

const CountryImage = ({ countryCodeAlpha2 }: Props) => {
	return (countryCodeAlpha2 ? <img style={{ borderRadius: '50%' }}
		loading="lazy"
		width="20"
		height="20"
		src={`https://flagcdn.com/w20/${countryCodeAlpha2.toLowerCase()}.png`}
		srcSet={`https://flagcdn.com/w40/${countryCodeAlpha2.toLowerCase()}.png 2x`}
		alt=""
	/> : <></>
	);
};

export default CountryImage;
