
import { getTransactionsLazy } from '@/features/transactions/slice';
import { Period, TransactionsLazyPayload, TransactionStatus, TransactionType } from '@/features/transactions/types';
import { Theme } from '@mui/material';
import { addDays, addMonths, addWeeks, endOfDay, getTime, startOfDay } from 'date-fns';
import { AccountType } from '@/features/operations/types';


export const getEndDate = (today: Date, period: Period, selectedRangeEndDate: Date) => getTime(endOfDay(period === Period.CUSTOM ? selectedRangeEndDate : today));

export const getStartDate = (today: Date, period: Period, selectedRangeStartDate: Date, userCreatedDate: string) => {
	let from = today;
	switch (period) {
		case Period.DAY:
			from = addDays(from, -1);
			break;
		case Period.WEEK:
			from = addWeeks(from, -1);
			break;
		case Period.MONTH:
			from = addMonths(from, -2);
			break;
		case Period.CUSTOM:
			from = selectedRangeStartDate;
			break;
		case Period.ALL:
			from = new Date(userCreatedDate);
			break;
	}
	return getTime(startOfDay(from));
};


export const transactionDetailsEnabled = (transactionType: string) => {
	return [TransactionType.PAYMENT,
	//TransactionType.CHARGEBACK,
	//TransactionType.WITHDRAW currently no template availabe for them
	TransactionType.SYSTEM_DEPOSIT,
	TransactionType.OWN_TRANSFER,
	TransactionType.EXCHANGE,
	TransactionType.TRANSFER,
	TransactionType.PURCHASE,
	TransactionType.CARD_TOP_UP,
	TransactionType.CARD_PULL_OUT,
	TransactionType.DEPOSIT,
	TransactionType.VAULT_DEPOSIT,
	// TransactionType.DEPOSIT_REPAYMENT,
	TransactionType.FX_TRANSACTION,
	TransactionType.SYSTEM_FEE,
	TransactionType.CURRENCY_FEE,
	TransactionType.MONTHLY_FEE,
	TransactionType.SERVICE_FEE,
	TransactionType.COMMISSION,
	TransactionType.TRADE,
	TransactionType.CARD_OPERATION_FEE,
	TransactionType.EXT_TRANSACTION_FEE,
	].includes(transactionType as TransactionType);
};

export const getThemeStatusColor = (value: TransactionStatus, theme: Theme) => {
	let statusColor: string;
	switch (value) {
		case TransactionStatus.CANCELED:
		case TransactionStatus.FAILED:
		case TransactionStatus.ERROR:
		case TransactionStatus.REJECTED:
		case TransactionStatus.REVERTED:
			statusColor = theme.statusColor.lightRed;
			break;
		case TransactionStatus.COMPLETED:
		case TransactionStatus.APPROVED:
		case TransactionStatus.PAID:
			statusColor = theme.statusColor.success;
			break;
		case TransactionStatus.PENDING:
		case TransactionStatus.NEW:
		case TransactionStatus.UNPAID:
		default:
			statusColor = theme.statusColor.warning;
			break;
	}
	return statusColor;
};

export const getVaultTransactionStatus = (status: string) => {
	switch (status) {
		case 'ACTIVE':
			return TransactionStatus.NEW;
		case 'PENDING':
			return TransactionStatus.PENDING;
		case 'APPROVAL':
			return TransactionStatus.APPROVAL;
		case 'APPROVED':
			return TransactionStatus.APPROVED;
		case 'REJECTED':
			return TransactionStatus.REJECTED;
		case 'CANCELED':
			return TransactionStatus.CANCELED;
		case 'ERROR':
			return TransactionStatus.ERROR;
		case 'FAILED':
			return TransactionStatus.FAILED;
		case 'REVERTED':
			return TransactionStatus.REVERTED;
		case 'TERMINATED':
			return TransactionStatus.COMPLETED;
		default:
			return TransactionStatus.NEW;
	}
};

export const getMoreCardTrx = async (fromTime: number, userCreationTime: number, search: string, accountNumber: string) => {

	const newFromTime = getTime(startOfDay(addMonths(fromTime, -1)));

	const payload: TransactionsLazyPayload = {
		search: search,
		from: `${newFromTime}`,
		to: `${fromTime}`,
		accountId: accountNumber,
		initial: false
	};

	const result = await getTransactionsLazy(payload);


	if (result.count === 0 && (userCreationTime < newFromTime) && result?.from) {
		return await getMoreCardTrx(result?.from, userCreationTime, search, accountNumber);
	}
	else {
		return result;
	}
};


export const getSingleTransactionPdfPayload = (type: AccountType, trxActivity: string, trxDocNumber?: string,
	trxTransactionNumber?: string,
	walletId?: string) => {

	let payload;
	let _walletId;

	if (type === AccountType.WALLET || type === AccountType.CARD) {
		_walletId = walletId;
	}


	if (trxActivity === TransactionType.FX_TRANSACTION) {
		payload = {
			transactionNumber: trxDocNumber,
			accountType: type,
			walletId: _walletId,
		};

	} else {
		payload = {
			transactionNumber: trxTransactionNumber,
			accountType: type,
			walletId: _walletId,
		};
	}

	return payload;
};

