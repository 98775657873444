import { getCurrencySign } from '@/features/fees/slice';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MuiFormText from '../Widgets/MuiText/MuiFormText';
import { UserLimitType } from '@/features/limits/types';
import { limitNumberFormat } from '@/helpers/number';
import { FormHelperText } from '@mui/material';
import _ from 'lodash';

interface Props {
    userLimits: UserLimitType
    limitReached: boolean,
}

const UserLimitMessage = ({ userLimits, limitReached = false }: Props) => {
    const { t } = useTranslation('translations');

    const ccySymbol = getCurrencySign(userLimits.currencySymbol) ?? `${userLimits.ccy} `;

    return (<>
        <MuiFormText
            className='primary-highlight-regular'
            color='text.secondary'
            fontSize={'0.7rem'}
            dangerouslySetInnerHTML={{
                __html: t('sendmoneyTranslation.data.accountLimit', {
                    current: [_.isNil(userLimits?.availableBeforeLimitCcy) ? undefined : ccySymbol, limitNumberFormat(userLimits?.availableBeforeLimitCcy)].join(' '),
                    month: [_.isNil(userLimits?.monthLimitCcy) ? undefined : ccySymbol, limitNumberFormat(userLimits?.monthLimitCcy)].join(' ')
                })
            }}
        />

        {limitReached ? <>
            <FormHelperText> {t('sendmoneyTranslation.data.limitApproval')}</FormHelperText>
        </> : null}
    </>
    );
};

export default UserLimitMessage;
