import * as React from 'react';
import MuiTextConfirmationLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextConfirmationLabel';
import { Process } from '@/features/fees/types';
import Grid from '@mui/material/Grid';
import MuiTextConfirmation from '@/componentsMui/Shared/Widgets/MuiText/MuiFormField';
import { Address } from '@/features/card/types';
import { CardType } from '@/features/card/types';
import { useTranslation } from 'react-i18next';
import { FormHelperText, Typography } from '@mui/material';
import DiplayBigNumber from '@/componentsMui/Shared/Components/DiplayBigNumber';
import { getCurrencySign } from '@/features/fees/slice';
import { RootState } from '@/rootReducer';
import { UserType } from '@/features/user/types';
import { useSelector } from 'react-redux';
import ConfirmationBox from '@/componentsMui/Shared/Components/ConfirmationBox';

interface Props {
    option: Process,
    deliveryAddress?: Address,
    totalPrice: number,
    cardType: CardType,
    error?: string,
}

const ConfirmationScreen = ({ option, deliveryAddress, totalPrice, cardType, error }: Props) => {
    const { t } = useTranslation('translations');
    const { user } = useSelector((state: RootState) => state.user);

    return (
        <Grid container rowSpacing={2}>
            {deliveryAddress && <Grid item xs={12}>
                <ConfirmationBox>
                    <Grid container className='confirmationInfo' sx={{ px: { xs: 0, sm: 3 } }} rowSpacing={1}>
                        <Grid item container xs={12}>
                            <Grid item xs={4} pr={4} display='flex' alignItems='center'>
                                <MuiTextConfirmationLabel variant='body2'> {t('cards.newCard.confirmation.yourDeliveryAddress')}</MuiTextConfirmationLabel>
                            </Grid>
                            <Grid item container xs={8}>
                                <Grid item xs={12}>
                                    <MuiTextConfirmation>{user.type === UserType.BUSINESS ? user.companyName.toUpperCase() : `${user.firstName.toUpperCase()} ${user.lastName.toUpperCase()}`}</MuiTextConfirmation>
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextConfirmation>{`${deliveryAddress?.address || ''}`}</MuiTextConfirmation>
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextConfirmation>{`${deliveryAddress?.city || ''}, ${deliveryAddress?.postalCode || ''}`}</MuiTextConfirmation>
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextConfirmation>{deliveryAddress?.country || ''}</MuiTextConfirmation>
                                </Grid>
                                {error && <FormHelperText> {error} </FormHelperText>}
                            </Grid>
                        </Grid>

                    </Grid>
                </ConfirmationBox>
            </Grid>}
            <Grid item xs={12}>
                <ConfirmationBox>
                    <Grid container className='confirmationInfo' sx={{ px: { xs: 0, sm: 3 } }} rowSpacing={1}>
                        <Grid item container xs={12}>
                            <Grid item xs={4} pr={4} display='flex' alignItems='center'>
                                <MuiTextConfirmationLabel variant='body2'> {t('cards.cardSettingForm.cardName')}</MuiTextConfirmationLabel>
                            </Grid>
                            <Grid item xs={8} display='flex' alignItems='center'>
                                <MuiTextConfirmation> {option?.name} </MuiTextConfirmation>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                            <Grid item xs={4} pr={4} display='flex' alignItems='center'>
                                <MuiTextConfirmationLabel variant='body2'> {t('cards.newCard.confirmation.cardType')}</MuiTextConfirmationLabel>
                            </Grid>
                            <Grid item xs={8} display='flex' alignItems='center'>
                                <MuiTextConfirmation> {cardType === CardType.VIRTUAL ? t('cards.newCard.virt') : t('cards.newCard.phys')} </MuiTextConfirmation>
                            </Grid>
                        </Grid>
                    </Grid>
                </ConfirmationBox>
            </Grid>
            <Grid item xs={12}>
                <ConfirmationBox>
                    <Grid container className='confirmationInfo' sx={{ px: { xs: 0, sm: 3 } }} rowSpacing={1}>
                        <Grid item container xs={12}>
                            <Grid item xs={4} pr={4} display='flex' alignItems='center'>
                                <MuiTextConfirmationLabel variant='body2'> {cardType === CardType.PHYSICAL ? t('cards.newCard.confirmation.cardPriceInfoPhysical') : t('cards.newCard.confirmation.cardPriceInfo')}</MuiTextConfirmationLabel>
                            </Grid>
                            <Grid item xs={8} display='flex' alignItems='center'>
                                <DiplayBigNumber
                                    prefix={<Typography variant='highlight' fontSize='1.5rem'>
                                        {`${getCurrencySign(option.processProperties.ccy)}`}
                                    </Typography>}
                                    amount={totalPrice}
                                    alwaysGradient={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </ConfirmationBox>
            </Grid>
        </Grid>
    );
};


export default ConfirmationScreen;


