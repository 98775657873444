import React, { useState } from 'react';
import { Grid, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DeviceInfo } from '@/features/user/types';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import LoginQR from './LoginQR';
import LoginQRNeural from './LoginQRNeural';

interface Props {
	deviceInfo: DeviceInfo,
	isOauthLogin?: boolean,
}

export enum QRTypeEnum {
	OWN = 'OWN',
	NEURAL = 'NEURAL'
}


const LoginQRTabs = ({ deviceInfo, isOauthLogin }: Props): React.ReactElement => {

	const { t } = useTranslation('translations');
	const [currentTab, setCurrentTab] = useState(QRTypeEnum.OWN);
	const appName = process.env.REACT_APP_TITLE;
	const theme = useTheme();
	const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
	const neuralIdEnabled = useSelector((state: RootState) => state.status.neuralIdEnabled);

	const handleChange = (event: React.SyntheticEvent, newValue: QRTypeEnum) => {
		setCurrentTab(newValue);
	};


	return (
		<Grid container maxWidth='360px'>
			<Grid item xs={12} mt={{ xs: '20px', md: '0px' }}>
				{matchDownSm ?
					<MuiHeader textAlign='center'>{`${t('login.qrLogin')}`}</MuiHeader> :
					<Typography textAlign='center' fontSize='1.625rem' fontWeight={700}>
						{`${t('login.qrLogin')}`}
					</Typography>
				}
			</Grid>
			<Grid item xs={12} mt={{ xs: '20px', md: '40px' }} >
				<Tabs value={currentTab}
					onChange={handleChange}
					centered
				>
					<Tab value={QRTypeEnum.OWN}
						label={`${appName} App`}
						sx={{ width: '50%', fontWeight: 400 }}
					/>
					{neuralIdEnabled &&
						<Tab value={QRTypeEnum.NEURAL}
							label='Neural ID'
							sx={{ width: '50%', fontWeight: 400 }}
						/>
					}
				</Tabs>
			</Grid>
			<Grid item xs={12} container mt={{
				xs: '15px',
				md: '65px'
			}}>
				{currentTab === QRTypeEnum.OWN && <LoginQR deviceInfo={deviceInfo} isOauthLogin={isOauthLogin} />}
				{currentTab === QRTypeEnum.NEURAL && !isOauthLogin && <LoginQRNeural deviceInfo={deviceInfo} />}
			</Grid>
		</Grid>
	);
};

export default LoginQRTabs;
