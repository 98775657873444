import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Chat, ChatStatusEnum, ChatType, GetSupportChatPayload, Message, SendMessagePayload, SupportChatStore } from './types';
import request from '@services/request';
import { Subscription } from 'rxjs';
import { isEmpty } from 'lodash';
import { AppThunk } from '@/store';
import { RoomSocketService } from '@/services/roomSocketService';

const initialState: SupportChatStore = {
  data: {
    chat: null,
    messages: [],
    count: 0,
  },
  loading: false,
  isExist: null,
  isInvalidCode: false,
};

const anonSupportChatSlice = createSlice({
  name: 'annonSupportChat',
  initialState,
  reducers: {
    setData: (state, { payload }: PayloadAction<ChatType>) => {
      state.data = payload;
    },
    pushMessage: (state, { payload }: PayloadAction<Message>) => {
      const exists = state.data.messages?.some((existingMessage) => existingMessage.id === payload.id);
      if (!exists) {
        state.data = {
          ...state.data,
          messages: state.data.messages ? [...state.data.messages, payload] : [payload],
        };
      }
    },
    pushMessages: (state, { payload }: PayloadAction<ChatType>) => {
      state.data = {
        ...state.data,
        messages: [...state.data.messages, ...payload.messages],
      };
    },
    editMessage: (state, { payload }: PayloadAction<Message>) => {
      const index = state.data.messages.findIndex(message => message.id === payload.id);
      if (index !== -1) {
        state.data.messages[index] = payload;
      }
    },
    deleteMessage: (state, { payload }: PayloadAction<string>) => {
      state.data.messages = state.data.messages.filter(message => message.id !== payload);
    },
    setStatus: (state, { payload }: PayloadAction<ChatStatusEnum>) => {
      if (state.data.chat) {
        state.data.chat.status = payload;
      }
    },
    setChat: (state, { payload }: PayloadAction<Chat | null>) => {
      state.data = {
        ...state.data,
        chat: payload,
      };
    },
    deleteChat: (state, { payload }: PayloadAction<string>) => {
      if (state.data.chat?.id === payload) {
        state.data = initialState.data;
      }
      state.isExist = false;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setIsExist: (state, { payload }: PayloadAction<boolean>) => {
      state.isExist = payload;
    },
    setIsInvalidCode: (state, { payload }: PayloadAction<boolean>) => {
      state.isInvalidCode = payload;
    },
  },
});

export const getSupportChat = (key: string, otpCode: string, payload?: GetSupportChatPayload): AppThunk => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const response = await request.get(`/api/anon/support/${key}/${otpCode}`, {
        params:
          payload,
      });
      const { data } = response;
      if (!payload?.skip) {
        dispatch(setData(data));
      } else {
        dispatch(pushMessages(data));
      }
      dispatch(setIsInvalidCode(false));
      dispatch(setIsExist(true));
    } catch (error: any) {
      console.log(error);
      const { data } = error;
      const invalidOtpCode = data?.errors?.find(error => error.error === 'invalid' && error.error_param === 'otpCode') || false;
      if (invalidOtpCode) {
        dispatch(setIsInvalidCode(true));
      } else {
        dispatch(setIsExist(false));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const sendMessage = (payload: SendMessagePayload): AppThunk => {
  return async dispatch => {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        formData.append(key, value as string | Blob);
      }
    });
    try {
      const response = await request.post('/api/anon/support/message', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const { message, chat } = response.data;
      dispatch(setChat(chat));
      dispatch(pushMessage(message));
    } catch (error) {
      //show error message
    }
  };
};

export const generateOtpCode = (key: string): AppThunk => {
  return async dispatch => {
    try {
      await request.get(`/api/anon/support/generate/${key}/otp`);
    } catch (error) {
      dispatch(setIsExist(false));
    }
  };
};

export const getAttachmentAnonymously = async (key: string, name: string) => {
  const response = await request.get(`/api/anon/support/message/attachment/${key}`,
    {
      responseType: 'blob',
      params: {
        cacheBustTimestamp: Date.now(),
      },
    });
  const url = window.URL.createObjectURL(response.data);
  const link = document.createElement('a');
  link.download = name;
  link.href = url;
  link.className = 'hidden';
  document.body.appendChild(link);

  link.onclick = function () {
    requestAnimationFrame(function () {
      URL.revokeObjectURL(url);
      setTimeout(() => link.remove(), 300);
    });
  };
  link.click();
};

const markAsRead = async (payload: { chatId: number }) => {
  try {
    await request.post('/api/anon/support/message/mark-as-read', payload);
  } catch (error) {
    console.log('markAsRead error');
  }
};

let socketService: RoomSocketService;
let messegeSubscriber: Subscription;
let deletedMsgSubscriber: Subscription;
let deletedChatSubscriber: Subscription;
let editMsgSubscriber: Subscription;

export const connectChat = (room: string): void => {
  if (socketService) {
    unsubscribeChat();
  }
  socketService = new RoomSocketService('anon-chat', room);
};

export const subscribeChat = (): AppThunk => {
  return async dispatch => {
    try {
      messegeSubscriber = socketService
        .listen('chat.message', {})
        .subscribe((data) => {
          if (isEmpty(data)) {
            return;
          }
          dispatch(pushMessage(data.message));
          markAsRead({ chatId: Number(data.message.chatId) });
        });
      editMsgSubscriber = socketService
        .listen('chat.message.edit', {})
        .subscribe((data) => {
          if (isEmpty(data)) {
            return;
          }
          dispatch(editMessage(data));
        });
      deletedMsgSubscriber = socketService
        .listen('chat.message.deleted', {})
        .subscribe((data) => {
          if (isEmpty(data)) {
            return;
          }
          dispatch(deleteMessage(data.messageId));
        });
      deletedChatSubscriber = socketService
        .listen('chat.deleted', {})
        .subscribe((data) => {
          if (isEmpty(data)) {
            return;
          }
          dispatch(deleteChat(data.chatId));
        });
      socketService.send('data');
    } catch (error) {
      // const errorMsg = i18n.t('errors.chatConnect');
      // showSuccessMessage(i18n.t('errors.error'), errorMsg);
    }
  };
};

export const unsubscribeChat = (): void => {
  messegeSubscriber.unsubscribe();
  deletedMsgSubscriber.unsubscribe();
  editMsgSubscriber.unsubscribe();
  deletedChatSubscriber.unsubscribe();
};

export const { setData, pushMessage, setChat, deleteMessage, deleteChat, editMessage, pushMessages, setStatus, setLoading, setIsExist, setIsInvalidCode } = anonSupportChatSlice.actions;

export default anonSupportChatSlice.reducer;



