import * as React from 'react';
import { useTheme } from '@mui/styles';

import { MenuItem, MenuItemProps, Theme } from '@mui/material';

/* Menu item for NavBar */
const NavbarMenuItemNavLink = <C extends React.ElementType>(
	props: MenuItemProps<C, { component?: C; ref?: React.Ref<HTMLButtonElement>, desktopDrawer?: boolean }>
) => {
	const theme = useTheme() as Theme;

	const { children, desktopDrawer, buttonRef, sx, ...rest } = props;
	return (
		<MenuItem ref={buttonRef} sx={{
			height: '35px',
			paddingTop: '0px',
			paddingBottom: '0px',
			margin: desktopDrawer ? '2px' : { xs: '2px 0', md: '2px 20px' },
			borderColor: 'transparent',
			borderRadius: theme.mainBorderRadius,
			'&:hover ': {
				'span': {
					color: theme.palette.text.primary,
				},
				'& .MuiSvgIcon-root': {
					fill: 'url(#svgIconGradient) ' + theme.highlight,
				},
				'& .MuiSvgIcon-root.stroke': {
					stroke: 'url(#svgIconGradient) ' + theme.highlight
				}

			},
			'&.active': {
				background: theme.backgroundBoxLevel1,
				'& .MuiSvgIcon-root': {
					fill: 'url(#svgIconGradient) ' + theme.highlight,
				},
				'& .MuiSvgIcon-root.stroke': {
					stroke: 'url(#svgIconGradient) ' + theme.highlight
				},
				'& .MuiTypography-root': {
					color: theme.palette.text.primary,
				},

			},
			'&.Mui-disabled': {
				opacity: 1,
			},
			'& .MuiSvgIcon-root': {
				color: theme.form.textSecondary,
				fontSize: desktopDrawer ? '22px' : '18px'
			},
			'& .MuiSvgIcon-root.stroke': {
				stroke: theme.form.textSecondary,
			},
			'& .MuiListItemIcon-root': {
				width: desktopDrawer ? '22px' : '18px',
				minWidth: desktopDrawer ? '22px' : '18px'
			},
			'& .MuiListItemText-root': {
				color: theme.form.textSecondary,
				'& .MuiTypography-root': {
					fontSize: '13px',
					fontWeight: 400,
					padding: '8px 11px'
				}
			},
			...sx
		}}{...rest}>
			{children}
		</MenuItem>
	);
};

export default NavbarMenuItemNavLink;

