
import * as React from 'react';
import { useTheme } from '@mui/styles';


import { Box as MuiBox, BoxProps, Theme } from '@mui/material';
import { FC } from 'react';

interface Props extends BoxProps {
	noBackground?: boolean
}

/* Placed on base background*/
const FormContentBox: FC<Props> = (props: Props) => {

	const theme = useTheme() as Theme;

	const { noBackground, sx, ...other } = props;

	return (<MuiBox id='form-content-box'
		sx={{
			...sx,
			color: theme.palette.text.secondary,
			borderRadius: theme.mainBorderRadius,
			background: noBackground ? 'transparent' : theme.form.bgDefault,
			border: theme.palette.mode === 'dark' ? 'unset' : theme.inputField.border
		}}
		{...other}> {props.children}
	</MuiBox >);
};


export default FormContentBox;
