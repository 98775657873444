import * as React from 'react';
import { SvgIcon } from '@mui/material';

const BitcoinIcon = (props) => (
	<SvgIcon {...props}>
		<svg width="24" height="24" viewBox="0 0 24 24" fill="current" xmlns="http://www.w3.org/2000/svg">
			<path d="M21.0834 9.64822C21.4606 7.12638 19.5406 5.7708 16.9152 4.86649L17.7669 1.45043L15.6875 0.932283L14.8584 4.2584C14.3117 4.12205 13.7503 3.99358 13.1923 3.86622L14.0274 0.518151L11.9493 0L11.0971 3.41494C10.6447 3.31194 10.2004 3.21015 9.76931 3.10288L9.77172 3.09214L6.9041 2.37605L6.35094 4.59704C6.35094 4.59704 7.89372 4.95068 7.86121 4.97245C8.70327 5.18262 8.85555 5.74004 8.83026 6.18187L7.8601 10.0736C7.91808 10.0883 7.99329 10.1096 8.07629 10.1429C8.00691 10.1257 7.93309 10.1069 7.85648 10.0886L6.49664 15.5403C6.39373 15.7961 6.13252 16.18 5.54379 16.0342C5.56463 16.0644 4.03241 15.6571 4.03241 15.6571L3 18.0374L5.70607 18.712C6.2095 18.8382 6.70283 18.9703 7.18865 19.0945L6.32815 22.5497L8.4052 23.0679L9.25736 19.6493C9.82479 19.8034 10.3755 19.9455 10.9145 20.0794L10.0652 23.4818L12.1448 24L13.0052 20.5512C16.5511 21.2223 19.2174 20.9518 20.3396 17.7445C21.2439 15.1623 20.2946 13.6728 18.4291 12.7015C19.7878 12.3882 20.8113 11.4945 21.0842 9.6485L21.0835 9.64804L21.0834 9.64822ZM16.3323 16.3104C15.6897 18.8927 11.342 17.4968 9.93242 17.1468L11.0743 12.5692C12.4838 12.9211 17.004 13.6174 16.3324 16.3104H16.3323ZM16.9754 9.6108C16.3892 11.9596 12.7706 10.7663 11.5967 10.4737L12.632 6.32211C13.8059 6.61471 17.5861 7.16084 16.9756 9.6108H16.9754Z" fill="current" />
		</svg>
	</SvgIcon>
);

export default BitcoinIcon;
