export enum ContactPaymentTypes {
	LOCAL = 'LOCAL',
	CRYPTO = 'CRYPTO',
	SEPA = 'SEPA_STEP2',
	SEPA_INSTANT = 'SEPA_INSTANT',
	SWIFT = 'SWIFT',
	UK_CHAPS = 'UK_CHAPS',
	UK_FPS = 'UK_FPS',
	US_WIRE = 'US_WIRE',
	INTERNATIONAL_WIRE = 'INTERNATIONAL_WIRE',
	ACH = 'ACH',
	SAME_DAY_ACH = 'SAME_DAY_ACH',
	SCAN = 'SCAN',
}

export enum ContactTabEnum {
	ALL = 'ALL', BANK = 'BANK', INTERNAL = 'LOCAL', CRYPTO = 'CRYPTO',
}

export interface Contact {
	ccy: string;
	beneficiaryAccountId: number,
	name: string,
	walletId: string,
	account: string,
	address: string,
	bank: string,
	bic: string,
	identity: string,
	country: string,
	paymentType: string,
	business: boolean,
	identification?: string,
	cryptoNetwork?: string,
	currencySymbol?: string,
	type?: string,
	accountNumber?: string,
	street?: string,
	region?: string,
	city?: string,
	postalCode?: string,
	sortCode?: string,
	routingNumber?: string,
	clientId?: string,
	supportedPayments: string[],
}

export interface ContactStore {
	loading: boolean,
	contacts: Array<Contact>,
	error: string,
	tab: ContactTabEnum,
}

export interface ContactBody {
	name: string,
	identification?: string,
	paymentType?: string,
	account?: string,
	walletId?: string,
	bic?: string,
	bank?: string,
	address?: string,
	country?: string,
	ccy?: string,
	isBusiness?: boolean,
	business?: boolean,
	sortCode?: string,
	accountNumber?: string,
	routingNumber?: string,
	cryptoNetwork?: string,
	userTag?: string,
	clientId?: string,
}

export interface ContactUpdateBody {
	beneficiaryAccountId: number,
	name: string,
	identification?: string,
	paymentType: string,
	account: string,
	walletId: string,
	bic: string,
	bank: string,
	address: string,
	country: string,
	ccy: string;
	business: boolean,
	cryptoNetwork?: string,
	currencySymbol?: string,
	type?: string
}



export interface ContactUser {
	accountId: number,
	accountType?: string,
	companyName?: string,
	currency?: string,
	currencySymbol?: string,
	firstName?: string,
	lastName?: string,
	userId: number,
	proc?: string
}
