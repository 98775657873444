export enum KybStatus {
	CREATED = 'CREATED',
	SUBMITTED = 'SUBMITTED',
	WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
	VERIFIED = 'VERIFIED',
	REJECTED = 'REJECTED',
	IN_REVIEW = 'IN_REVIEW',
}

export enum SectionStatus {
	PENDING = 'PENDING',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED'
}

export enum KybSectionEnum {
	BUSINESS_INFORMATION = 'BUSINESS_INFORMATION',
	DOCUMENTS = 'DOCUMENTS',
	DIRECTORS = 'DIRECTORS',
	SHAREHOLDERS = 'SHAREHOLDERS',
	PARTNERS = 'PARTNERS',
	TRANSFERS = 'TRANSFERS',
	SUBMIT = 'SUBMIT'
}

export enum PartnerOptions {
	INCOMING_PARTNER = 'INCOMING_PARTNER',
	OUTGOING_PARTNER = 'OUTGOING_PARTNER'
}

export enum TransactionNumberOptions {
	LEVEL1 = '1 - 10',
	LEVEL2 = '10 - 25',
	LEVEL3 = '25 - 50',
	LEVEL4 = '50+',
}

export enum TransactionVolumneOptions {
	LEVEL1 = '0 to 10 000',
	LEVEL2 = '10 000 to 25 000',
	LEVEL3 = '25 000 to 50 000',
	LEVEL4 = '50 000 to 250 000',
	LEVEL5 = '250 000',
}



export interface BusinessStore {
	list: Array<BusinessType>,
	loading: boolean,
	error: any,
}



export interface BusinessType {
	clientId: string,
	name: string,
	kybTier: number,
	kybStatus: KybStatus,
	kybFinal: boolean,
	askKybAgain: boolean,
	form: SectionType[],
	disabled: boolean,
	detained: boolean,
}


export interface SectionType {
	name: string,
	status: SectionStatus,
	rejectionReason: string,
	values: InformationType | DocumentsType | DirectorsArrayType | ShareholdersArrayType | PartnerArrayType
}

export interface InformationType {
	companyName: string,
	companyAddress: string,
	companyCountry: string,
	companyWebsite: string,
	companyRegNumber: string,
	incorporationDate: string,
	companyDescription: string,
	companyPhoneNumber: string,
	companyEmailAddress: string,
	companyCity?: string,
	companyPostalCode?: string,
	industryCode?: string,
	industry?: string,
	typeOfCompany?: string,
	typeOfCompanyCode?: string,
}

export interface InformationPayloadType extends InformationType {
	clientId?: string
}

export interface DocumentsType {
	certificateOfIncorporation?: string,
	ubo?: string,
	directors?: string,
	poaBusiness?: string,
}

export interface DocumentsPayloadType extends DocumentsType {
	clientId: string
}

export interface DirectorType {
	directorFirstName: string,
	directorLastName: string,
	directorBirthDate: string,
	directorPep: boolean,
	directorUsCitizen: boolean
}


export interface DirectorsArrayType {
	directors: Array<DirectorType>
}

export interface DirectorsPayloadType {
	directors: DirectorType[],
	clientId: string
}

export interface ShareholderType {
	shareHolderFirstName?: string,
	shareHolderLastName?: string,
	shareHolderBirthDate?: string,
	shareHolderPercentageOfOwnerShip: number,
	shareHolderPOA?: string,
	shareHolderPep?: boolean,
	shareHolderUsCitizen?: boolean,
	shareHolderDocumentType?: string,
    shareHolderDocumentFrontSide?: string,
    shareHolderDocumentBackSide?: string,
    shareHolderCountry: string,
    shareHolderAddress: string,
    shareHolderCity: string,
    shareHolderPostalCode: string,
    shareHolderPhonenumber: string,
    shareHolderEmail: string,
	shareHolderType?: string,
    shareHolderCompanyName?: string,
    shareHolderRegistrationNumber?: string,
    shareHolderIncorporationDate?: string,
    shareHolderCompanyType?: string,
    shareHolderCertificateOfIncorporationDocument?: string,
    shareHolderDisclosingShareholdersDocument?: string,
    shareHolderDisclosingDirectorsDocument?: string,
    shareHolderIdOfDirectorsDocument?: string,
}


export interface ShareholdersPayloadType {
	shareholders: ShareholderType[],
	clientId: string
}


export interface ShareholdersArrayType {
	shareholders: Array<ShareholderType>
}

export interface PartnerType {
	partnerCompanyName: string,
	partnerIncorporationCountry: string,
	plannedNumberOfMonthlyTransactions: number,
	plannedVolumeOfMonthlyTransactons: number,
	partnerType: string,
	basisOfPartnership?: string,
}

export interface PartnersPayloadType {
	partners: PartnerType[],
	clientId: string
}

export interface PartnerArrayType {
	partners: Array<PartnerType>
}

export interface TransferType {
	plannedNumberOfIncomingTransactions?: number,
	plannedTurnoverOfIncomingTransactions?: number,
	plannedNumberOfOutgoingTransactions?: number,
	plannedTurnoverOfOutgoingTransactions?: number,
	additionalInformation?: string
}

export interface TransferTypePayloadType extends TransferType {
	clientId?: string
}


export interface SubmitFormPayloadType {
	clientId: string
}

export interface PaymentDetails {
	amount: string,
	bankAddress: string,
	bankName: string,
	beneficiaryName: string,
	ccy: string,
	iban: string,
	reference: string,
	swift: string,
	proofOfPayment?: string
}
