import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { useTranslation } from 'react-i18next';

const RefferalRewardTypeCell = (params: GridRenderCellParams) => {
    const { t } = useTranslation('translations');
    
    if (params.value === null || params.value === undefined) {
        return '';
    }
    
    return (
        <MuiFormText>
            {t(`referral.rewardType.${params.value}`)}
        </MuiFormText>
    );
};

export default RefferalRewardTypeCell;
