import { Agents } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import { Grid, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import React, { ReactElement, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import MuiTextAccountBoxHeader from '../Widgets/MuiText/MuiTextAccountBoxHeader';
import MuiTextAccountBoxMoney from '../Widgets/MuiText/MuiTextAccountBoxMoney';

interface Props {
	amount: number,
	prefix: string | ReactElement,
	suffix?: string,
	alwaysGradient?: boolean,
	prefixFontSize?: string
}

const DiplayBigNumber = ({ amount, prefix, suffix, alwaysGradient, prefixFontSize }: Props) => {
	const { agent } = useSelector(
		(state: RootState) => state.status
	);
	const theme = useTheme() as Theme;
	const [variantName, setVariantName] = useState<'gradient' | 'inherit'>(alwaysGradient ? 'gradient' : theme.palette.mode === 'light' ? 'gradient' : 'inherit');

	useEffect(() => {
		if (agent === Agents.YESSY) {
			setVariantName('inherit');
		}
	}, [agent]);

	return (
		<Grid item container alignItems='baseline' width='unset' >
			<Typography fontWeight='700' fontSize={prefixFontSize??'1rem'} lineHeight='1.125rem' pr='0.2rem'
				variant={variantName}>
				{prefix}
			</Typography>
			<MuiTextAccountBoxMoney variant={variantName} sx={{fontSize: {xs: '1.2rem', md: '1.625rem' }}}>
				<NumberFormat
					displayType={'text'}
					decimalScale={2}
					fixedDecimalScale={true}
					thousandsGroupStyle='thousand'
					thousandSeparator={true}
					value={amount} />
			</MuiTextAccountBoxMoney>
			{suffix &&
				<MuiTextAccountBoxHeader variant={variantName}>
					&nbsp;{suffix}
				</MuiTextAccountBoxHeader>
			}
		</Grid>
	);
};

export default DiplayBigNumber;
