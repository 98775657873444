import React, { useEffect, useState } from 'react'; 
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, Button as MuiButton, DialogActions, Theme, Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/styles';

interface Props {
	open: boolean;
	onClose: (co?: boolean) => void;
}


const InactiveModal = ({ open, onClose }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [timeRemaing, setTimeRemaing] = useState<number>(60);

	useEffect(() => {
		if (!open) return;
		let timeLeft;
		const getTimeLeft = () => {
			timeLeft && clearTimeout();
			timeLeft = setTimeout(() => {
				setTimeRemaing(timeRemaing - 1);
			}, 1 * 1000);
		};
		getTimeLeft();
		if (timeRemaing === 0) onClose(false);
		return () => {
			timeLeft && clearTimeout(timeLeft);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [timeRemaing, open]);

	const theme = useTheme() as Theme;

	return (
		<Dialog
			open={open}
			onClose={() => onClose(false)}
			fullWidth
			maxWidth='xs'
			PaperProps={{ sx: { bgcolor: theme.palette.background.default } }}
		>
			<DialogContent>
				<Grid container direction='column' alignItems='center' spacing={3} mt={1}>
					<Grid item >
						<Typography fontWeight='600' >
							{t('session.modal.header')}
						</Typography>
					</Grid>
					<Grid item fontWeight='600'>
						<Typography >
							<p>{t('session.modal.text_part1')} <strong style={{ fontSize: '1.2em' }}>0:{timeRemaing.toString().length === 1 ? `0${timeRemaing}` : timeRemaing}</strong>{t('session.modal.text_part2')}</p>
						</Typography>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<MuiButton color='primary' variant='contained' onClick={() => { setTimeRemaing(60); onClose(true); }}>{t('session.modal.continueBtn')}</MuiButton>
				<MuiButton color='secondary' variant='contained' onClick={() => onClose(false)}>{t('session.modal.logoutBtn')}</MuiButton>
			</DialogActions>

		</Dialog>

	);
};

export default InactiveModal;
