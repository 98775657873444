import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { useMemo } from 'react';
import { Chip, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { normalizeString } from '@/helpers/normalizeString';
import { InvoiceStatus } from '@/features/invoices/invoicesTypes';


const InvoiceStatusCell = (params: GridRenderCellParams) => {
    const theme = useTheme();
    const { t } = useTranslation('translations');
    const statusValue = t('invoice.status.' + params.row.status, normalizeString(params.row.status));

    const color = useMemo(() => {
        switch (params.row.status) {
            case InvoiceStatus.PAID:
            case InvoiceStatus.MANUALLY_PAID:
                return { backgroundColor: theme.statusColor.success };
            case InvoiceStatus.DRAFT:
                return {
                    backgroundColor: theme.palette.text.secondary
                };
            case InvoiceStatus.CANCELLED:
                return {
                    backgroundColor: theme.statusColor.lightRed
                };
            case InvoiceStatus.ISSUED:
                return {
                    backgroundColor: theme.palette.info.main,
                };
            case InvoiceStatus.PARTIALLY_PAID:
                return {
                    backgroundColor: theme.statusColor.warning,
                };
            default: return { backgroundColor: theme.palette.text.secondary };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.row.status]);

    return (
        <Chip
            label={statusValue}
            sx={{
                borderRadius: '10px',
                minWidth: '70px',
                minHeight: '24px',
                '& .MuiChip-label:hover': {
                    color: 'inherit',

                },
                '& .MuiChip-label': {
                    background: 'unset',
                    color: theme.palette.text.primary,
                    'WebkitTextFillColor': theme.palette.text.primary,
                },
                ...color,
            }}
        />
    );
};

export default InvoiceStatusCell;
