
import * as React from 'react';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import { Card } from '@/features/card/types';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CardImage from '../CardImage';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';

interface Props {
    card: Card,
}



const CardActivating = ({ card }: Props) => {
    const { t } = useTranslation('translations');
  

    return (
        <Grid container sx={{width: {xs: '100%', sm: '50%', md: '30%'}}} rowSpacing={2} marginLeft='auto' marginRight='auto'>

            <Grid item xs={12} justifyContent='center' display='flex' mb={2}>
                <MuiTextCaption fontWeight='400' fontSize='1.25rem'>{t('cards.cardActivating')}</MuiTextCaption>
            </Grid>

            <Grid item xs={12}>
                <Spinner />
            </Grid>

            <Grid item xs={12}>
                <CardImage card={card} />
            </Grid>

        </Grid>


    );
};

export default CardActivating;

