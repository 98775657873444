
import * as React from 'react';
import { Box as MuiBox,  Theme, Typography } from '@mui/material'; 
import { useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';

 

const UnreadStatus= ( ) => {
 
	const { t } = useTranslation('translations');
	const theme = useTheme() as Theme;

	return (<MuiBox
		sx={{
			borderRadius: '10px',
			background:  `${theme.highlight}14`,
			padding: '0px 10px',
			width: 'fit-content',
			minWidth: '4rem',
			height: '1.5rem', 
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		}} >
		<Typography
			fontWeight={700}
			fontSize='0.563rem'
			variant='gradient'>
			{t('nofification.unread')}
		</Typography>
	</MuiBox>);
};


export default UnreadStatus;
