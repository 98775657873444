import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid as MuiGrid } from '@mui/material';
import MuiSubHeader from './Shared/Widgets/MuiText/MuiSubHeader';
import Spinner from './Shared/Widgets/Spinner/Spinner'; 
import { AccountType } from '@/features/operations/types';

interface Props {
	type: AccountType,
	loadingMessage?: string,
	kyc?: boolean
}

const AccountsPlaceholder = ({ type, kyc, loadingMessage }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	return (
		<MuiGrid mt={2} container justifyContent='center'>
			{loadingMessage && <Spinner />}
			{kyc && <MuiSubHeader pb={2}>{`${t(`kyc.label.${type}`)} `} </MuiSubHeader>}
		</MuiGrid>
	);
};

export default AccountsPlaceholder;
