import React, { useEffect } from 'react';
import { Alert, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SectionStatus, SectionType, ShareholdersArrayType, ShareholdersPayloadType, ShareholderType } from '@/features/business/types';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import BusinessShareholderItem from './BusinessShareholderItem';
import { postShareholders } from '@/features/business/slice';
import { postFile } from '@/features/kyb/slice';
import { useFormikForms } from '../../formHooks';
import MuiTextAlert from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAlert';
import { showException } from '@/features/swal/slice';
import { BeneficiaryType } from '@/helpers/globalTypes';
import { formatDateWithPattern } from '@/helpers/date';

const initShareholder: ShareholderType = {
	shareHolderFirstName: '',
	shareHolderLastName: '',
	shareHolderBirthDate: '',
	shareHolderPercentageOfOwnerShip: null,
	shareHolderPOA: '',
	shareHolderPep: true,
	shareHolderUsCitizen: true,
	shareHolderDocumentType: '',
	shareHolderDocumentFrontSide: '',
	shareHolderDocumentBackSide: '',
	shareHolderCountry: '',
	shareHolderAddress: '',
	shareHolderCity: '',
	shareHolderPostalCode: '',
	shareHolderPhonenumber: '',
	shareHolderEmail: '',
};


interface Props {
	clientId: string,
	section?: SectionType,
	onNext: () => void,
	onBack: () => void
}

const BusinessShareholders = ({ clientId, section, onNext, onBack }: Props): React.ReactElement => {

	const { t } = useTranslation('translations');
	const sectionValues = section?.values as ShareholdersArrayType;

	const { forms, allowPostData, disableSubmit, handleNext, nextStep, save, setSave, addForm, updateForm, removeForm } =
		useFormikForms(sectionValues?.shareholders, initShareholder);


	useEffect(() => {
		const postData = async () => {
			let error = false;
			if (allowPostData) {
				try {
					const promises = forms.filter(p => !!p.values.shareHolderPOA).map(async item => {
						const payload = new FormData();
						payload.append('file', item.values.shareHolderPOA);
						const response = await postFile(payload);
						return {
							fileKey: response?.key,
							formId: item.id
						};
					});
					const keys = await Promise.all(promises);
					await Promise.all(promises);

					const docFPromises = forms.filter(p => !!p.values.shareHolderDocumentFrontSide).map(async item => {
						const payload = new FormData();
						payload.append('file', item.values.shareHolderDocumentFrontSide);
						const response = await postFile(payload);
						return {
							fileKey: response?.key,
							formId: item.id
						};
					});
					const docFKeys = await Promise.all(docFPromises);
					await Promise.all(docFPromises);

					const docBPromises = forms.filter(p => !!p.values.shareHolderDocumentBackSide).map(async item => {
						const payload = new FormData();
						payload.append('file', item.values.shareHolderDocumentBackSide);
						const response = await postFile(payload);
						return {
							fileKey: response?.key,
							formId: item.id
						};
					});
					const docBKeys = await Promise.all(docBPromises);
					await Promise.all(docBPromises);

					const docDisclosingShareholdersDocumentPromises = forms.filter(p => !!p.values.shareHolderDisclosingShareholdersDocument).map(async item => {
						const payload = new FormData();
						payload.append('file', item.values.shareHolderDisclosingShareholdersDocument);
						const response = await postFile(payload);
						return {
							fileKey: response?.key,
							formId: item.id
						};
					});
					const docDisclosingShareholdersDocumentKeys = await Promise.all(docDisclosingShareholdersDocumentPromises);
					await Promise.all(docDisclosingShareholdersDocumentPromises);

					const docCertificatePromises = forms.filter(p => !!p.values.shareHolderCertificateOfIncorporationDocument).map(async item => {
						const payload = new FormData();
						payload.append('file', item.values.shareHolderCertificateOfIncorporationDocument);
						const response = await postFile(payload);
						return {
							fileKey: response?.key,
							formId: item.id
						};
					});
					const docCertificateKeys = await Promise.all(docCertificatePromises);
					await Promise.all(docCertificatePromises);

					const docDisclosingDirectorsDocumentPromises = forms.filter(p => !!p.values.shareHolderDisclosingDirectorsDocument).map(async item => {
						const payload = new FormData();
						payload.append('file', item.values.shareHolderDisclosingDirectorsDocument);
						const response = await postFile(payload);
						return {
							fileKey: response?.key,
							formId: item.id
						};
					});
					const docDisclosingDirectorsDocumentKeys = await Promise.all(docDisclosingDirectorsDocumentPromises);
					await Promise.all(docDisclosingDirectorsDocumentPromises);

					const docIdOfDirectorsDocumentPromises = forms.filter(p => !!p.values.shareHolderIdOfDirectorsDocument).map(async item => {
						const payload = new FormData();
						payload.append('file', item.values.shareHolderIdOfDirectorsDocument);
						const response = await postFile(payload);
						return {
							fileKey: response?.key,
							formId: item.id
						};
					});
					const docIdOfDirectorsDocumentKeys = await Promise.all(docIdOfDirectorsDocumentPromises);
					await Promise.all(docIdOfDirectorsDocumentPromises);

					const payload: ShareholdersPayloadType = {
						clientId: clientId,
						shareholders: forms.map(p => {
							return {
								...(p.values.shareHolderType === BeneficiaryType.INDIVIDUAL ? {
									shareHolderPep: p.values.shareHolderPep,
									shareHolderUsCitizen: p.values.shareHolderUsCitizen,
									shareHolderPOA: keys.find(k => k.formId === p.id)?.fileKey,
								} : {
									shareHolderCompanyName: p.values.shareHolderCompanyName,
									shareHolderRegistrationNumber: p.values.shareHolderRegistrationNumber,
									shareHolderIncorporationDate: p.values.shareHolderIncorporationDate ? formatDateWithPattern(p.values.shareHolderIncorporationDate, 'dd/MM/yyyy') : p.values.shareHolderIncorporationDate,
									shareHolderCompanyType: p.values.shareHolderCompanyType,
									shareHolderDisclosingShareholdersDocument: docDisclosingShareholdersDocumentKeys.find(k => k.formId === p.id)?.fileKey,
									shareHolderCertificateOfIncorporationDocument: docCertificateKeys.find(k => k.formId === p.id)?.fileKey,
									shareHolderDisclosingDirectorsDocument: docDisclosingDirectorsDocumentKeys.find(k => k.formId === p.id)?.fileKey,
									shareHolderIdOfDirectorsDocument: docIdOfDirectorsDocumentKeys.find(k => k.formId === p.id)?.fileKey
								}),
								shareHolderDocumentType: p.values.shareHolderDocumentType,
								shareHolderDocumentFrontSide: docFKeys.find(k => k.formId === p.id)?.fileKey,
								shareHolderDocumentBackSide: docBKeys.find(k => k.formId === p.id)?.fileKey,
								shareHolderFirstName: p.values.shareHolderFirstName,
								shareHolderLastName: p.values.shareHolderLastName,
								shareHolderBirthDate: p.values.shareHolderBirthDate ? formatDateWithPattern(p.values.shareHolderBirthDate, 'dd/MM/yyyy') : p.values.shareHolderBirthDate,
								shareHolderType: p.values.shareHolderType,
								shareHolderPercentageOfOwnerShip: p.values.shareHolderPercentageOfOwnerShip,
								shareHolderCountry: p.values.shareHolderCountry,
								shareHolderCity: p.values.shareHolderPostTown,
								shareHolderPostalCode: p.values.shareHolderPostCode,
								shareHolderAddress: p.values.shareHolderAddress,
								shareHolderPhonenumber: p.values.shareHolderPhoneNumber,
								shareHolderEmail: p.values.shareHolderEmail,
							};
						})
					};

					await postShareholders(payload);
				}
				catch (e) {
					error = true;
					showException(e);
				}
			}
			if (nextStep && !error) { onNext && onNext(); }

		};
		postData();
		setSave(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allowPostData, clientId, save, nextStep]);

	const doNotAllowUpdate = section?.status === SectionStatus.APPROVED;

	return (
		<div id='business-shareholders' className='form-content_main-area-nopadding'>
			<Grid container rowGap={4}>
				{doNotAllowUpdate &&
					<Grid item xs={12} >
						<Alert severity="success">
							<MuiTextAlert> {t('createbusiness.information.approved')}</MuiTextAlert>
						</Alert>
					</Grid>
				}
				{section?.status === SectionStatus.REJECTED && section?.rejectionReason &&
					<Grid item xs={12} >
						<Alert severity="warning">
							<MuiTextAlert>{section?.rejectionReason}</MuiTextAlert>
						</Alert>
					</Grid>
				}
				<Grid item xs={12}>
					<MuiHeader className='business-header'> {t('createbusiness.shareholders.title')}</MuiHeader>
				</Grid>
				{forms?.length > 0 && forms?.map((p, idx) =>
					<Grid key={p.id} item xs={12}>
						<BusinessShareholderItem id={p.id}
							allowRemove={idx > 0 && !doNotAllowUpdate}
							shareholder={p.values}
							updateForm={updateForm}
							removeForm={removeForm}
							doNotAllowUpdate={doNotAllowUpdate} />
					</Grid>)}
				<Grid item xs={12}>
					<Button
						variant='customOutlined'
						disabled={doNotAllowUpdate}
						onClick={addForm}
						fullWidth
					>  {t('createbusiness.shareholders.add')}
					</Button>
				</Grid>
			</Grid>
			<Grid container className='form-content_botton-area'>
				<Grid item xs={6}>
					<Button
						variant='customOutlined'
						onClick={onBack}
					>  {t('form.buttons.back')}
					</Button>
				</Grid>
				<Grid item xs={6} container justifyContent='flex-end' columnGap={3}>
					<Button disabled={disableSubmit}
						variant='contained'
						onClick={handleNext}
					>
						{t('form.buttons.continue')}
					</Button>
				</Grid>
			</Grid>
		</div>
	);
};

export default BusinessShareholders;
