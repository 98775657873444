
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { ComuneType, ItalyTerritory } from './italyTerritoryTypes';


const initialState: ItalyTerritory = {
    comunes: [],
};


const italyTerritorySlice = createSlice({
    name: 'italyTerritorySlice',
    initialState,
    reducers: {
        setComunes(state, action: PayloadAction<ComuneType[]>) {
            state.comunes = action.payload;
        },

    }
});

export const { setComunes } = italyTerritorySlice.actions;

export const getComuneList = (): AppThunk => {
    return async dispatch => {
        try {
            const response = await request.get('/api/fnd/comunes');
            dispatch(setComunes(response?.data || []));
        }
        catch (e) {
            dispatch(setComunes([]));

        }
    };
};

export default italyTerritorySlice.reducer;
