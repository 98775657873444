import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '@services/request';
import { LimitStore, UserLimitType } from './types';
import { AppDispatch } from '@/store';

const initialState: LimitStore = {
    loading: false,
    list: [],
    error: null,
};

const limitsSlice = createSlice({
    name: 'userLimits',
    initialState,
    reducers: {
        setLimits(state, action: PayloadAction<Array<UserLimitType>>) {
            state.list = action.payload;
        },
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.error = payload;
        },
    }
});

export const { setLimits, setLoading, setError } = limitsSlice.actions;


export const getUserAccountsLimitsByUserLevelId = (ccy: string, userLevelId: number) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get(`/api/limits/userLevelId/${userLevelId}/${ccy}`);
            if (!response.data) dispatch(setLimits([]));
            else dispatch(setLimits([response.data] || []));
        } catch (e) {
            dispatch(setLimits([]));
        }
        finally { dispatch(setLoading(false)); }
    };
};

export const getUserAccountsLimits = (ccy: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await getUserAccountsLimitsData(ccy);
            dispatch(setLimits([response] || []));
        } catch (e) {
            dispatch(setLimits([]));
        }
        finally { dispatch(setLoading(false)); }
    };
};


export const getUserAccountsLimitsData = async (ccy: string): Promise<UserLimitType> => {
    const response = await request.get(`/api/limits/${ccy}`);
    return response?.data;
};

export default limitsSlice.reducer;
