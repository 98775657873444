import { Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { CoinOhlcType, CryptoChartPeriodEnum, TradeSummary24h } from '@/features/coins/types';
import { getPairOhlc, getPairSummary } from '@/features/coins/slice';
import PeriodButtonGroupCrypto from '@/componentsMui/Shared/Widgets/Chart/PeriodButtonGroupYear';
import CryptoChartTooltip from './CryptoChartTooltip';
import TotalLineChart from '@/componentsMui/Shared/Components/TotalLineChart';
import BigSymbol from './BigSymbol';
import MuiBigHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiBigHeader';

interface Props {
	pair: string,
	symbol: string,
	toLogo: string,
	name: string,
	fromAssetSymbol: string,
	hideChart: boolean,
}


const CryptoChart = ({ pair, symbol, toLogo, name, fromAssetSymbol, hideChart = false }: Props): React.ReactElement => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<CoinOhlcType[]>([]);
	const [period, setPeriod] = useState(CryptoChartPeriodEnum.DAY);
	const [price, setPrice] = useState<number>();
	const [priceLoading, setPriceLoading] = useState<boolean>(true);


	const getChartData = useCallback(async () => {
		if (hideChart) return;
		if (period && pair) {
			setLoading(true);
			try {
				const data = await getPairOhlc(pair, period);
				setData(data);
				setLoading(false);
			}
			catch (e) {
				setLoading(false);
			}
		}
	}, [period, pair, hideChart]);

	const getPrice = useCallback(() => {
		const get = async () => {
			if (pair) {
				const res: TradeSummary24h = await getPairSummary(
					pair
				);

				setPrice(res.price);
				setPriceLoading(false);
			}
			else { setPrice(null); }
		};

		get();
	}, [pair]);

	useEffect(() => {
		getChartData();
	}, [getChartData]);




	useEffect(() => {
		getPrice();
	}, [getPrice]);

	const handlePeriodChange = async (
		event: React.MouseEvent<HTMLElement>,
		value: string,
	) => {
		if (value) {
			const newPeriod = value as CryptoChartPeriodEnum;
			setPeriod(newPeriod);
		}
	};

	return (<Grid container id='wallet-summary-chart' height='100%'>
		{!hideChart && <Grid item display={{ xs: 'auto', md: 'none' }} mb='10px'>
			<PeriodButtonGroupCrypto
				value={period}
				onChange={handlePeriodChange} />
		</Grid>}

		<Grid container
			border='1px solid #FFFFFF0F'
			borderRadius='10px'
			pb='5px'

		>
			<Grid item xs={12} container
				justifyContent='space-between'
				alignItems='center'
				pt='18px'
				pl='18px'>
				<Grid item xs >
					<BigSymbol symbol={symbol} logo={toLogo} name={name} />
				</Grid>
				{!hideChart && <Grid item
					pr='22px' display={{ xs: 'none', md: 'auto' }}>
					<PeriodButtonGroupCrypto
						value={period}
						onChange={handlePeriodChange} />
				</Grid>}
			</Grid>
			<Grid item xs={12} container mt={{ xs: '5px', sm: '19px' }} alignItems='baseline' pl='22px'>
				{price && !priceLoading && <>
					<MuiBigHeader fontWeight={400} fontSize={{ xs: '1rem', sm: '2rem' }} lineHeight={{ xs: '1.2rem', sm: '1.75rem' }} >
						{price}
					</MuiBigHeader>
					<Typography pl='0.5rem' fontSize={{ xs: '0.6rem', sm: '0.875rem' }} fontWeight={400} color='text.primary' lineHeight='1.75rem'>{fromAssetSymbol}</Typography>

				</>}
			</Grid>
			<Grid item xs={12} container width='100%' height={{ xs: 100, sm: 200 }} >
				{!hideChart && <TotalLineChart chartData={data}
					loading={loading}
					chartTooltip={CryptoChartTooltip}
					dataKey='price'
					yAxisProps={{ domain: ['dataMin', 'dataMax'] }} />}
			</Grid>
		</Grid>
	</Grid>);
};

export default CryptoChart;
