import { Theme, Typography, TypographyProps } from '@mui/material';
import { useTheme } from '@mui/styles';
import React, { FC } from 'react';

const MuiTextFieldInfo: FC<TypographyProps> = (props: TypographyProps) => {

	const theme = useTheme() as Theme;

	return (
		<Typography
			fontSize="0.625rem"
			fontWeight='700'
			lineHeight='0.875rem'
			color={theme.highlight}
			whiteSpace='pre-line' //this wrap text
			{...props}>
			{props.children}
		</Typography >
	);
};

export default MuiTextFieldInfo;
