import React from 'react';
import * as Yup from 'yup';
import { Alert, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { SectionStatus, SectionType, TransferType, TransferTypePayloadType } from '@/features/business/types';
import { postTransfers } from '@/features/business/slice';
import NumberFormat from 'react-number-format';
import MuiTextAlert from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAlert';
import { showException } from '@/features/swal/slice';

interface Props {
	clientId?: string,
	section?: SectionType,
	onNext: () => void,
	onBack: () => void
}

const BusinessTransfers = ({ section, clientId, onNext, onBack }: Props): React.ReactElement => {

	const values = section?.values as TransferType;
	const { t } = useTranslation('translations');


	const initialValues = {
		plannedNumberOfIncomingTransactions: values?.plannedNumberOfIncomingTransactions,
		plannedTurnoverOfIncomingTransactions: values?.plannedTurnoverOfIncomingTransactions,
		plannedNumberOfOutgoingTransactions: values?.plannedNumberOfOutgoingTransactions,
		plannedTurnoverOfOutgoingTransactions: values?.plannedTurnoverOfOutgoingTransactions,
		additionalInformation: values?.additionalInformation ?? ''
	};

	const validationSchema = Yup.object({
		plannedNumberOfIncomingTransactions: Yup.string().required(t('form.validator.required')),
		plannedTurnoverOfIncomingTransactions: Yup.string().required(t('form.validator.required')),
		plannedNumberOfOutgoingTransactions: Yup.string().required(t('form.validator.required')),
		plannedTurnoverOfOutgoingTransactions: Yup.string().required(t('form.validator.required'))
	});

	const submit = async (formData) => {
		const { plannedNumberOfIncomingTransactions,
			plannedTurnoverOfIncomingTransactions,
			plannedNumberOfOutgoingTransactions,
			plannedTurnoverOfOutgoingTransactions,
			additionalInformation } = formData;

		try {

			const payload: TransferTypePayloadType = {
				plannedNumberOfIncomingTransactions,
				plannedTurnoverOfIncomingTransactions,
				plannedNumberOfOutgoingTransactions,
				plannedTurnoverOfOutgoingTransactions,
				additionalInformation,
				clientId: clientId
			};

			await postTransfers(payload);
			onNext && onNext();
		}
		catch (e) {
			showException(e);
		}
	};

	const doNotAllowUpdate = section?.status === SectionStatus.APPROVED;
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={submit}
		>
			{({ errors, isSubmitting, values, dirty, handleSubmit, handleChange }) => {
				return (
					<Form id='business-transfers' className='form-content_main-area-nopadding'>
						<Grid container spacing={2}>
							{doNotAllowUpdate &&
								<Grid item xs={12} >
									<Alert severity="success">
										<MuiTextAlert> {t('createbusiness.information.approved')}</MuiTextAlert>
									</Alert>
								</Grid>
							}
							{section?.status === SectionStatus.REJECTED && section?.rejectionReason &&
								<Grid item xs={12} >
									<Alert severity="warning">
										<MuiTextAlert>{section?.rejectionReason}</MuiTextAlert>
									</Alert>
								</Grid>
							}
							<Grid item xs={12}>
								<MuiHeader className='business-header'>   {t('createbusiness.transfers.title')}</MuiHeader>
							</Grid>
							<Grid item xs={6}>
								<NumberFormat
									name="plannedNumberOfIncomingTransactions"
									customInput={MuiTextField}
									value={values.plannedNumberOfIncomingTransactions}
									onChange={handleChange}
									allowNegative={false}
									decimalScale={0}
									displayType={'input'}
									placeholder={'0'}
									fullWidth
									label={t('createbusiness.transfers.intrx')}
									inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }} />
							</Grid>
							<Grid item xs={6}>
								<NumberFormat
									name="plannedTurnoverOfIncomingTransactions"
									customInput={MuiTextField}
									value={values.plannedTurnoverOfIncomingTransactions}
									onChange={handleChange}
									allowNegative={false}
									decimalScale={2}
									displayType={'input'}
									placeholder={'0.00'}
									fullWidth
									label={t('createbusiness.transfers.inturnover')}
									inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }} />
							</Grid>
							<Grid item xs={6}>
								<NumberFormat
									name="plannedNumberOfOutgoingTransactions"
									customInput={MuiTextField}
									value={values.plannedNumberOfOutgoingTransactions}
									onChange={handleChange}
									allowNegative={false}
									decimalScale={0}
									displayType={'input'}
									placeholder={'0'}
									fullWidth
									label={t('createbusiness.transfers.outtrx')}
									inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }} />
							</Grid>
							<Grid item xs={6}>
								<NumberFormat
									name="plannedTurnoverOfOutgoingTransactions"
									customInput={MuiTextField}
									value={values.plannedTurnoverOfOutgoingTransactions}
									onChange={handleChange}
									allowNegative={false}
									decimalScale={0}
									displayType={'input'}
									placeholder={'0'}
									fullWidth
									label={t('createbusiness.transfers.outturnover')}
									inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }} />
							</Grid>
							<Grid item xs={12}>
								<MuiTextField
									name="additionalInformation"
									fullWidth
									multiline
									inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
									label={t('createbusiness.transfers.info')} />
							</Grid>
						</Grid>
						<Grid container mt='auto' pt='2rem' columnSpacing={2}>
							<Grid item xs={6}>
								<Button
									variant='customOutlined'
									color='secondary'
									onClick={onBack}
									sx={{width: {xs: '100%', md: 'auto'}}}
								>  {t('form.buttons.back')}
								</Button>
							</Grid>
							<Grid item xs={6} container justifyContent='flex-end'>
								{doNotAllowUpdate ? <Button
									variant='contained'
									type="button"
									sx={{width: {xs: '100%', md: 'auto'}}}
									onClick={() => onNext && onNext()}
								>
									{t('form.buttons.continue')}
								</Button> : <Button disabled={isSubmitting || !isEmpty(errors) || doNotAllowUpdate}
													variant='contained'
													type="button"
													sx={{width: {xs: '100%', md: 'auto'}}}
													onClick={() => { dirty ? handleSubmit() : onNext && onNext(); }}
								>
									{t('form.buttons.continue')}
								</Button>}
							</Grid>
						</Grid>
					</Form>);
			}}
		</Formik>
	);
};

export default BusinessTransfers;
