export enum Operations {
	CREATE_ACCOUNT = 'CREATE_ACCOUNT',
	CREATE_CARD = 'CREATE_CARD',
	CREATE_STOCK = 'CREATE_STOCK',
	CREATE_TRUST = 'CREATE_TRUST',
	CREATE_CRYPTO = 'CREATE_CRYPTO_ACCOUNT',
	CREATE_TOKENS = 'CREATE_TOKENS',
	CREATE_VAULT = 'CREATE_VAULT',
	CREATE_WALLET = 'CREATE_WALLET'
}

export enum AccountProviderType {
	POOLING = 'POOLING',
	POOLING_IBAN = 'POOLING_IBAN',
	IBAN = 'IBAN',
	CARD = 'CARD',
	CRYPTO = 'CRYPTO'
}

export enum AccountType {
	BANK = 'BANK',
	WALLET = 'WALLET',
	TOKEN = 'TOKEN',
	CRYPTO = 'CRYPTO',
	CARD = 'CARD',
	TRUST = 'TRUST',
	VAULT = 'VAULT',
	BANK_AND_CARD = 'BANK_AND_CARD'
}

export enum IntegrationEnum {
	TRUST = 'TRUST',
	STOCKS = 'STOCKS',
	BANKINGCIRCLE = 'BANKINGCIRCLE',
	FIREBLOCKS = 'FIREBLOCKS',
	SYSTEM = 'SYSTEM',
	VAULT = 'VAULT',
	COINBASE = 'COINBASE',
	ONBOARDING = 'ONBOARDING',
	INTERCASH_LATAM = 'INTERCASH_LATAM',
	DECTA = 'DECTA',
	MODULR = 'MODULR',
	DUMMY_INTEGRATION = 'DUMMY_INTEGRATION',
	CARDAQ = 'CARDAQ',
	CLEARJUNCTION_POOL = 'CLEARJUNCTION_POOL',
	INTERCASH = 'INTERCASH',
	CLEARJUNCTION_WALLET = 'CLEARJUNCTION_WALLET',
	IXOPAY = 'IXOPAY',
	TOKENS = 'TOKENS',
	KYRREX = 'KYRREX',
	CLEARJUNCTION_IBAN_WALLET = 'CLEARJUNCTION_IBAN_WALLET',
	INTERCASH_EU = 'INTERCASH_EU',
	CAMBRIDGE = 'CAMBRIDGE',
	PRIMETRUST = 'PRIMETRUST',
	RAILSBANK = 'RAILSBANK',
}
export interface CardaqBuyCryptoByCardRegisteredPayload {
	successUrl: string,
	cancelUrl: string,
	errorUrl: string,
	amount: number,
	//currency for incoming money
	cardCcy: string,
	//optional account id for incoming money (eur)
	cardTargetAccountId?: number,

	//if account id for incoming money is null,
	//search accounts in this integration
	//if this one is also null, then search in CLEARJUNCTION_WALLET by default
	cardTargetIntegration?: IntegrationEnum,
	targetAccountId: number
	//this is optional, but please provide it like you do for all other trades
	//please do not fill from amount as it will be taken from amount field
	currencyExchangeOrderId?: number;

}


/* Products like Wallet produts: crypto or tokens */
/* export interface OperationProduct {
	name: string,
	type: 'crypto' | 'tokens',
	walletId?: string,
	accountId?: number
}
 */

