import React from 'react';
import FileIcon from '@/componentsMui/Shared/CustomIcons/FileIcon';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { getAttachment } from '@/features/supportChat/slice';
import { Attachment } from '@/features/supportChat/types';
import { Message as MessageType } from '@/features/supportChat/types';
import { convertDate } from '@/helpers/date';
import { Grid, styled, useTheme } from '@mui/material';
import { getAttachmentAnonymously } from '@/features/anonSupportChat/slice';

type Props = {
  clientMessage: boolean;
  message?: MessageType;
  attachment?: Attachment;
  msgDate: Date;
  anonymous?: boolean;
}

const MessageItem = ({ msgDate, message, clientMessage, attachment, anonymous }: Props) => {
  const theme = useTheme();
  const handleDownload = (key: string, name: string) => {
    anonymous ? getAttachmentAnonymously(key, name) : getAttachment(key, name);
  };

  const HoverableGrid = styled(Grid)({
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      cursor: 'pointer',
    },
  });

  return (
    <Grid
      item
      marginBottom={2}
      onClick={attachment ? () => handleDownload(attachment.key, attachment.name) : null}
      sx={(theme) => ({
        padding: '1.25rem',
        background: clientMessage ? theme.selectedItemBg.default : '#31353D',
        borderRadius: theme.mainBorderRadius,
        borderBottomRightRadius: clientMessage ? 0 : theme.mainBorderRadius,
        borderBottomLeftRadius: clientMessage ? theme.mainBorderRadius : 0,
        maxWidth: { xs: '80%', md: '60%' }
      })} >
      <Grid item xs={12}>
        {message
          ? <MuiFormText dangerouslySetInnerHTML={{ __html: message.message }} />
          : null
        }
        {attachment
          ? <HoverableGrid sx={{
            padding: 1,
            borderRadius: theme.mainBorderRadius,
            alignItems: 'center'
          }}
            display={'flex'} flexDirection={'row'} >
            <FileIcon className="stroke" sx={{ stroke: theme.palette.text.secondary, margin: 1 }} />
            <MuiFormText
              style={{ textDecoration: 'underline' }}
              dangerouslySetInnerHTML={{ __html: attachment.name }} />
          </HoverableGrid>
          : null
        }
      </Grid>
      <Grid item xs={12} container justifyContent='flex-end' >
        <MuiFormText sx={{ color: 'rgba(255, 255, 255, 0.4)' }}>
          {convertDate(msgDate, 'HH:mm')}
        </MuiFormText>
      </Grid>
    </Grid>
  );
};

export default MessageItem;
