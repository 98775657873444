import React, { useEffect, useState } from 'react';
import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid } from '@mui/material';
import { Card } from '@features/card/types';
import ServiceLoading from '../ServiceLoading';
import { setAccount } from '@/features/tabs/slice';
import { getAccount } from './utils';
import './CardServiceTab.css';
import '@/componentsMui/Shared/Shared.css';
import { AccountType } from '@/features/operations/types';
import { useHistory } from 'react-router-dom';
import SearchField from '../Shared/Widgets/SearchField';
import FormHeader from '../Wallet/Balances/Deposit/Components/FormHeader';
import BusinessCardsOverview from './Card/CardDetails/BusinessCardsOverview';
import BusinessCardDetails from './Card/CardDetails/BusinessCardDetails';
import { useTranslation } from 'react-i18next';
import EmptyProductCard from '../Shared/Widgets/EmptyProductCard';
import LoadingPageMui from '@/pages/LoadingPageMui';

interface Props {
	loading: boolean,
	list: Array<Card>,
	error?: string
}

const BusinessCardServiceTab = ({ loading, list, error }: Props): React.ReactElement => {
	const searchParams = new URLSearchParams(location.search);
	const [selectedCardId] = useState<string>(searchParams.get('cardId'));

	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const history = useHistory();
	const [search, setSearch] = React.useState<string>('');
	const [overview, setOverview] = React.useState<boolean>(true);
	const [defaultAccount, setDefaultAccount] = useState(null);

	const { currentAccount } = useSelector(
		(state: RootState) => state.tabs
	);

	const { user } = useSelector((state: RootState) => state.user);

	const handleCreateCard = () => { history.push('/wallet/home/cards/create'); };
	const handleAssignCard = () => { history.push('/wallet/home/cards/assign'); };

	const handleSearchRequest = (e) => {
		setSearch(e.target.value);
	};

	const filteredCards = search === '' ? list : list.filter(c => c?.name?.toUpperCase().includes(search.toUpperCase()));

	const onClickSetAccount = (account) => {
		setOverview(false);
		dispatch(setAccount(account));
	};

	useEffect(() => {
		const newAccount = getAccount(list, currentAccount?.accountId);
		dispatch(setAccount(newAccount));
		setDefaultAccount(newAccount);
		//	history.push('/wallet/home/cards/');
	}, [dispatch, currentAccount?.accountId, list, history]);

	useEffect(() => {
		const selectedCard = list.find(c => c.cardId === Number(selectedCardId));
		if (selectedCard) {
			setOverview(false);
			dispatch(setAccount(selectedCard));
		}
	}, [dispatch, list, selectedCardId]);

	const handleBack = () => {
		dispatch(setAccount(null));
		setDefaultAccount(null);
		setOverview(true);
	};
	/* 
		const handlePhoneVerification = () => {
			history.push('/wallet/home/cards/verify-phone');
		};
		const handleEmailVerification = () => {
			history.push('/wallet/home/cards/verify-email');
		};
	 */
	const mobileNotConfirmedConfirmed = user.confirmedMobileNumber === null || user.confirmedMobileNumber === undefined;


	if (mobileNotConfirmedConfirmed && !list.length) {
		return (<EmptyProductCard
			title={t('cards.verifyPhone.title')}
			subTitle={t('cards.verifyPhone.subTitleBusiness')}
		/>);
	}

	if (!user.emailConfirmed && !list.length) {
		return (<EmptyProductCard
			title={t('cards.verifyEmail.title')}
			subTitle={t('cards.verifyEmail.subTitleBusiness')}
		/>);
	}
	const disableCreateBtn = mobileNotConfirmedConfirmed || !user.emailConfirmed;

	if (loading && list?.length === 0) {
		return <LoadingPageMui />;
	}

	return (
		<ServiceLoading
			type={AccountType.CARD}
			//	loading={loading}
			error={error}
			checkList={true}
			list={list}>
			<Grid id='business-card-tab-container'
				container
			>
				{
					mobileNotConfirmedConfirmed &&
					<Grid style={{ marginBottom: 20 }}>
						<EmptyProductCard
							title={t('cards.verifyPhone.title')}
							subTitle={t('cards.verifyPhone.subTitleBusiness')}
						/>
					</Grid>
				}
				{
					!user.emailConfirmed &&
					<Grid style={{ marginBottom: 20 }}>
						<EmptyProductCard
							title={t('cards.verifyEmail.title')}
							subTitle={t('cards.verifyEmail.subTitleBusiness')}
						/>
					</Grid>
				}
				<Grid container item xs={12} alignItems='center' flexDirection='row' mb={3} >
					<Grid item xs={12} sm={4}>
						<FormHeader title={overview ? t('cards.businessCards.createCard') : t('cards.businessCards.backToOverview')} showBack={!overview} onBack={handleBack} mb='0' />
					</Grid>
					<Grid sx={{ display: { xs: 'none', sm: 'flex' } }} item xs={8} columnGap={2} justifyContent='flex-end'>

						{overview && <SearchField value={search}
							onChange={(e) => handleSearchRequest(e)} />}
						<Button
							variant='simple'
							onClick={handleCreateCard}
							disabled={disableCreateBtn}
						>
							{t('cards.businessCards.createCard')}
						</Button>
						<Button
							variant='simple'
							onClick={handleAssignCard}
						>
							{t('cards.businessCards.assignCard')}
						</Button>
					</Grid>
					{overview && <Grid sx={{ display: { xs: 'flex', sm: 'none' } }} container item xs={12} columnSpacing={2} rowSpacing={2} mt={2}>
						<Grid item xs={6}>
							<Button
								variant='simple'
								onClick={handleCreateCard}
								disabled={disableCreateBtn}
								sx={{ width: '100%' }}
							>
								{t('cards.businessCards.createCard')}
							</Button>
						</Grid>
						<Grid item xs={6}>
							<Button
								variant='simple'
								onClick={handleAssignCard}
								sx={{ width: '100%' }}
							>
								{t('cards.businessCards.assignCard')}
							</Button>
						</Grid>
						<Grid item xs={12}>
							<SearchField
								value={search}
								sx={{ width: '100%' }}
								onChange={(e) => handleSearchRequest(e)} />
						</Grid>

					</Grid>}
				</Grid>

				{overview && <Grid item xs={12}>
					<BusinessCardsOverview list={filteredCards} onCardSelect={onClickSetAccount} />
				</Grid>}
				{!overview && defaultAccount && <Grid item xs={12}>
					<BusinessCardDetails key={defaultAccount?.cardId} card={defaultAccount as Card} list={list} />
				</Grid>}

			</Grid>
		</ServiceLoading >
	);
};

export default BusinessCardServiceTab;
