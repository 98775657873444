import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { useTranslation } from 'react-i18next';
import { HOME_SUMMARY, ONBORADING } from '@/pages/navigationConstants';
import { canGoToPortal } from '@/helpers/kyc';
import { UserType } from '@/features/user/types';
import KYBNotCompleted from './Business/KYBNotCompleted';
import { refreshUser } from '@/features/user/userSlice';



const Home = (): React.ReactElement => {

	const { user, status } = useSelector(
		(state: RootState) => state.user
	);

	const { i18n } = useTranslation('translations');
	const preferredLang = useSelector((state: RootState) => state.user?.user?.preferredLanguage);
	const currentLang = i18n.language;
	//const userOnboarded = !isOnboardingRequired(user, status);
	const dispatch = useDispatch();

	useEffect(() => {
		if (preferredLang && preferredLang !== currentLang) {
			i18n.changeLanguage(preferredLang);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [preferredLang]);

	useEffect(() => {
		const get = async () => {
			await dispatch(refreshUser());
		};
		get();
	}, [dispatch]);


	const goHomeAllowed = canGoToPortal(user, status);

	if (goHomeAllowed) {
		return <Redirect to={HOME_SUMMARY} />;
	}
	else {
		return user?.type === UserType.BUSINESS ? <KYBNotCompleted /> : <Redirect to={ONBORADING} />;
	}

	/* 	if (user?.type === UserType.EMPLOYEE) {
			return <Redirect to={HOME_SUMMARY} />;
		}
	
		if (user?.type === UserType.BUSINESS) {
			const isUserVerified = user && isKycTierVerified(user);
			return isUserVerified ? <Redirect to={HOME_SUMMARY} /> : <KYBNotCompleted />;
		}
	
		else {
			return userOnboarded ? <Redirect to={HOME_SUMMARY} /> : <Redirect to={ONBORADING} />;
		}   */

};

export default Home;
