import * as React from 'react';

import '@/componentsMui/Shared/Shared.css';

import {
	Button,
	Grid,
	InputAdornment, TextField
} from '@mui/material';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { BankAccount } from '@/features/account/types';
import { showException } from '@/features/swal/slice';
import FormContentBox from '@/componentsMui/Shared/Widgets/Box/FormContentBox';
import AccountDropdown from '@/componentsMui/Shared/FormikComponents/AccountDropdown';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { maxTwoDecimals } from '@/helpers/number';
import NumberFormat from 'react-number-format';
import { getCurrencySign, postTransferFee } from '@/features/fees/slice';
import useFeeDebounce from '@/helpers/customHook/useFeeDebounce';
import { findProcessByType } from '@/features/operations/slice';
import { TicketNotification } from '@/features/tickets/types';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import useCurrencyLimits from '@/helpers/customHook/useCurrencyLimits';
import LoadingPageMui from '@/pages/LoadingPageMui';
import UserLimitMessage from '@/componentsMui/Shared/Components/UserLimitMessage';
import bigDecimal from 'js-big-decimal';
import { NEW_LINE_REGEX } from '@/helpers/customHook/useValidations';

export interface SendMoneyPayloadType {
	account: BankAccount,
	amount: number,
	accountToId: number,
	fee: number,
	youpay: number,
	reference?: string
	processProcedure: string,
	firstName?: string,
	lastName?: string
}

export const initSendMoneyPayload: SendMoneyPayloadType =
{
	account: null,
	amount: null,
	accountToId: null,
	fee: null,
	youpay: 0,
	reference: '',
	processProcedure: null
};

type Props = {
	paymentAccountList?: BankAccount[],
	sendMoneyPayload: SendMoneyPayloadType,
	notification: TicketNotification,
	setSendMoneyPayload: (value: SendMoneyPayloadType) => void,
	nextStep: () => void,
	onCancel: () => void
}


const SendMoneyDetails = ({ paymentAccountList,
	notification,
	sendMoneyPayload,
	setSendMoneyPayload,
	nextStep,
	onCancel }: Props) => {

	const { t } = useTranslation('translations');

	const meta = JSON.parse(notification.requestmeta) || null;
	const fromAccountList = paymentAccountList.filter(p => p.currency === meta?.account?.currency);

	const [selectedAccount, setSelectedAccount] = useState<BankAccount>(sendMoneyPayload.account ?? fromAccountList[0] ?? null);

	const [amount, setAmount] = useState<string>(sendMoneyPayload?.amount?.toString() ?? meta?.amount ?? '');

	const [amountforFee, setAmountForFee] = useState<number>(sendMoneyPayload?.amount ?? parseFloat(meta?.amount) ?? 0);

	const process = findProcessByType(selectedAccount, 'TRANSFER');
	const { fee, callGetFee } = useFeeDebounce(sendMoneyPayload?.fee ?? 0);
	const youpay = amountforFee + fee;


	const currency = getCurrencySign(selectedAccount?.currency);
	const { userLimits,
		loadingLimits,
		isLimitSet, isLimitExceeded } = useCurrencyLimits(selectedAccount?.currency);

	const limitReached = isLimitExceeded(amount);

	useEffect(() => {
		callGetFee(selectedAccount?.accountId, process?.proc, amountforFee);
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, []);

	const initialValues = {
		from: selectedAccount?.accountId ?? '',
		amount: meta?.amount ?? '',
		reference: meta?.reference ?? '',
	};

	const validationSchema = Yup.object({
		from: Yup.string().required(t('form.validator.required')),
		amount: Yup.number().typeError(t('form.validator.required')).required(t('form.validator.required')).nullable()
			.moreThan(0, t('sendmoneyTranslation.data.moreThanZero'))
			.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value))
			.test('amount',
				t('sendmoneyTranslation.data.insufficient').toString(),
				() => bigDecimal.compareTo(youpay, selectedAccount?.availableBalance) <= 0),
		reference: Yup.string().trim().required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-./\s])+$/, t('form.validator.latinOnlyReference'))
			.min(6, t('form.validator.minChars', { field: t('sendmoneyTranslation.data.reference'), chars: '6' }))
			.max(140, t('sendmoneyTranslation.data.canNotExceedHundred'))
			.test('reference', t('form.validator.newLine'), (value) => value ? value.search(NEW_LINE_REGEX) === -1 : true)
			.test('reference', t('form.validator.minChars', { field: t('sendmoneyTranslation.data.reference'), chars: '6' }), (value) => value ? value.replace(/ /g, '').length >= 6 : true),
	});

	const submit = async (formData, formikProps) => {
		const { setFieldError } = formikProps;
		const { amount, reference } = formData;

		if (process === null || process.proc === null) {
			setFieldError('from', t('sendmoneyTranslation.data.missingProcess'));
		}
		try {
			const paymentAmount = parseFloat(amount);
			const feeResult = await postTransferFee({ accountId: selectedAccount.accountId, proc: process?.proc, amount: paymentAmount });
			const payload: SendMoneyPayloadType = {
				...{
					amount: paymentAmount,
					account: selectedAccount,
					accountToId: meta.account.accountId,
					reference: reference,
					fee: feeResult.fee,
					youpay: feeResult.fee + paymentAmount,
					processProcedure: process.proc,
					firstName: meta?.user?.firstName,
					lastName: meta?.user?.lastName
				}
			};

			setSendMoneyPayload(payload);
			nextStep();

		}
		catch (e) {
			await showException(e);
		}
	};


	const handleAmountChange = (event: React.ChangeEvent<any>, setFieldValue, setFieldTouched) => {
		setFieldValue('amount', event.target.value);
		setAmount(event.target.value);
		const parsed = parseFloat(event.target.value);
		const newAmount = isNaN(parsed) ? 0 : parsed;
		setAmountForFee(newAmount);
		callGetFee(selectedAccount.accountId, process?.proc, newAmount);
		setTimeout(() => setFieldTouched('youpay', true));
		setTimeout(() => setFieldTouched('amount', true));
	};

	const handleAccountChange = (value: BankAccount, setFieldTouched) => {
		setSelectedAccount(value);
		const newProcess = findProcessByType(value, 'TRANSFER');
		callGetFee(value.accountId, newProcess?.proc, amountforFee);
		setTimeout(() => setFieldTouched('youpay', true));
	};

	if (loadingLimits) { return <LoadingPageMui />; }


	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={submit}
		>
			{({ errors, isSubmitting, setFieldValue, setFieldTouched }) => {
				return (
					<Form className='form-content__wrap__bb'>
						<FormContentBox noBackground={true} className='form-content_main-area wp'>
							<Grid container columnSpacing={3} rowSpacing={1}>
								<Grid item xs={12}>
									<FormHeader title={t('sendmoneyTranslation.data.cospayuserheader')}
										showBack={false} />
								</Grid>

								<Grid item xs={12}>
									<AccountDropdown
										fieldlabel={t('form.fields.from')}
										fieldName='from'
										accountList={fromAccountList}
										selectedAccount={selectedAccount}
										onAccountChange={(value: BankAccount) => handleAccountChange(value, setFieldTouched)} />
								</Grid>
								<Grid item xs={12}>
									<TextField
										name="reply"
										label={t('inbox.paymentRequestLabel')}
										variant="outlined"
										value={meta?.amount ?? ''}
										disabled={true}
										fullWidth
									/>
								</Grid>
								{isLimitSet &&
									<Grid item xs={12} sx={{ mt: 2 }}>
										<UserLimitMessage userLimits={userLimits} limitReached={limitReached} />
									</Grid>
								}
								<Grid item container xs={6}>
									<NumberFormat
										name="amount"
										decimalScale={2}
										customInput={MuiTextField}
										onChange={(event) => handleAmountChange(event, setFieldValue, setFieldTouched)}
										allowNegative={false}
										value={amount}
										displayType={'input'}
										placeholder={'0.00'}
										fullWidth
										label={t('sendmoneyTranslation.data.transferamount')}
										inputProps={{ autoComplete: 'off' }}
										InputProps={{
											startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<NumberFormat
										id="youpay"
										name="youpay"
										displayType={'input'}
										customInput={MuiTextField}
										decimalScale={2}
										isAllowed={() => false}
										disabled
										fullWidth
										value={youpay}
										label={t('sendmoney.data.youpay')}
										placeholder={'0.00'}
										InputProps={{
											startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
										}}
									/>
								</Grid>
								<Grid item xs={12} mt={1}>
									<MuiTextField
										name="reference"
										multiline
										fullWidth
										inputProps={{ autoComplete: 'off', maxLength: 140 }}
										label={t('sendmoneyTranslation.data.reference')}
										rows={3}
									/>
								</Grid>
							</Grid >
							<Grid container mt='auto' pt='2rem' columnSpacing={2}>
								<Grid item xs={6}>
									<Button
										variant='customOutlined'
										color='secondary'
										onClick={onCancel}
										sx={{ width: { xs: '100%', md: 'auto' } }}
									>  {t('form.buttons.close')}
									</Button>
								</Grid>
								<Grid item xs={6} container justifyContent='flex-end'>
									<Button
										disabled={isSubmitting || !isEmpty(errors) || process?.maintenance}
										variant="contained"
										color="primary"
										sx={{ width: { xs: '100%', md: 'auto' } }}
										type="submit"
									>
										{t(process?.maintenance ? 'dashboard.maintenance.maintenance' : 'form.buttons.next')}
									</Button>
								</Grid>
							</Grid>
						</FormContentBox>
					</Form>
				);
			}}
		</Formik >);
};

export default SendMoneyDetails;
