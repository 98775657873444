import { Box, Grid } from '@mui/material';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import { Transaction } from '../TransactionStatements.spec';
import { isFiat } from '@/helpers/currency';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@/helpers/date';
import { roundFlexible } from '@/helpers/round';
import MuiTextTrx from '@/componentsMui/Shared/Widgets/MuiText/MuiTextTrx';
import { getCurrencySign } from '@/features/fees/slice';
import { TransactionStatus, TransactionType } from '@/features/transactions/types';
import { showErrorNotification } from '@/features/swal/slice';
import TransactionStatusBox from '@/componentsMui/Shared/Widgets/Box/TransactionStatusBox';
import MuiTextTrxSmall from '@/componentsMui/Shared/Widgets/MuiText/MuiTextTrxSmall';
import { cancelFxDocument } from '@/features/fxDocuments/slice';

interface Props {
	transaction: Transaction,
	type?: string,
	openTransactionDetailsForm: (trx: Transaction) => void,
	fetchData: () => void
}

const TransactionListLine = ({ transaction, openTransactionDetailsForm, fetchData, type }: Props) => {
	const { t } = useTranslation('translations');
	const [sumbitting, setSubmitting] = React.useState<boolean>(false);
	const isFiatAsset = isFiat(transaction.asset) || type === 'CARD';
	const isFxCancel = transaction.activity === TransactionType.FX_TRANSACTION && transaction.status === TransactionStatus.PENDING;

	const fee = transaction?.fees?.length > 0 ? transaction.fees[0] : null;
	const feeAmount = fee ? fee.amount : '';
	const feePrefix = fee ? getCurrencySign(fee.ccy) : '';

	const cancelExchangeOrder = async (documentId: number) => {
		try {
			setSubmitting(true);
			await cancelFxDocument(documentId);
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
		finally { setSubmitting(false); }
	};


	return (
		<Box
			onClick={() => openTransactionDetailsForm(transaction)}
			style={{ cursor: 'pointer' }}
			pb='1rem' pt='1rem'
		>
			<Grid container alignItems='center' >
				<Grid item xs={3} container alignItems='center'>
					<MuiTextTrxSmall>
						{type === 'TOKEN' && transaction.activity === 'OWN_TRANSFER' ?
							transaction.amount !== null && transaction.amount > 0 ?
								t('activity.tokens.BUY_PURCHASE') :
								t('activity.tokens.SELL_PURCHASE') :
							transaction?.messageCode ? t('activity.' + transaction.messageCode) :
								t('activity.' + transaction.activity)}
					</MuiTextTrxSmall>
					{isFxCancel ? <MuiTextTrx pl='0.3rem' sx={theme => ({ color: theme.statusColor.warning })}> - {t('status.' + transaction.status)} </MuiTextTrx> : ''}
				</Grid>
				<Grid item xs={3}>
					<MuiTextTrxSmall color='text.secondary'>
						{formatDate(transaction.date, 'T')}
					</MuiTextTrxSmall>
				</Grid>
				<Grid item xs={2}>
					<MuiTextTrxSmall textAlign='right' sx={theme => ({ color: transaction.amount < 0 ? theme.statusColor.error : theme.statusColor.success })}>
						{transaction.amount && (transaction.amount < 0 ? '- ' + (transaction?.currencySymbol ?? getCurrencySign(transaction.asset)) + ' ' : (transaction?.currencySymbol ?? getCurrencySign(transaction.asset)) + ' ')}
						{isFiatAsset ?
							<NumberFormat displayType={'text'} decimalScale={2}
								fixedDecimalScale={true} thousandsGroupStyle='thousand'
								thousandSeparator={true} value={Math.abs(transaction.amount)} /> :
							roundFlexible(Math.abs(transaction.amount))
						}
					</MuiTextTrxSmall>
				</Grid>
				<Grid item xs={2}>
					<MuiTextTrxSmall textAlign='right' sx={theme => ({ color: theme.statusColor.error })}>
						<NumberFormat displayType={'text'}
							decimalScale={2}
							fixedDecimalScale={true} thousandsGroupStyle='thousand'
							thousandSeparator={true}
							value={feeAmount}
							prefix={`- ${feePrefix} `} />
					</MuiTextTrxSmall>
				</Grid>
				<Grid item xs={2} display='flex' justifyContent='flex-end'>
					<TransactionStatusBox
						sumbitting={sumbitting}
						isFxCancel={isFxCancel}
						status={transaction.status}
						{...(isFxCancel && { onClick: () => !sumbitting && cancelExchangeOrder(transaction.documentId) })}>
						{t('status.' + (isFxCancel ? 'CANCEL' : transaction.status))}
					</TransactionStatusBox>
				</Grid>
			</Grid>
		</Box >);
};

export default TransactionListLine;

