import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { makeStyles } from '@mui/styles';
import { Grid, IconButton } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
};

const useStyles = makeStyles(() =>
({
    document: {
        overflowX: 'hidden',
    },
})
);

interface IProps {
    file: any;
}

const PDFViewer: React.FC<IProps> = ({
    file,
}: IProps) => {
    const ref = useRef(null);
    const scrollRef = useRef(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [width, setWidth] = useState(0);
    const classes = useStyles();

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const goToPrevPage = () => {
        setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
        scrollRef.current.scrollTo(0, 0);
    };

    const goToNextPage = () => {
        setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);
        scrollRef.current.scrollTo(0, 0);
    };

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    });

    useLayoutEffect(() => {
        setWidth(ref.current.offsetWidth);
    }, []);

    return (
        <Grid container className='mainContainer' ref={scrollRef}>

            <Grid item xs={12} display='flex' justifyContent='center' alignItems='center' className='navContainer'>
                <Grid item >
                    <IconButton onClick={goToPrevPage}>
                        <ArrowBackIosNewSharpIcon />
                    </IconButton>
                </Grid>
                <Grid item >{pageNumber} from {numPages}</Grid>
                <Grid item >
                    <IconButton onClick={goToNextPage}>
                        <ArrowForwardIosSharpIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item xs={12} display='flex' justifyContent='center' className='documentContainer' ref={ref}>
                <Document
                    file={file}
                    options={options}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className={classes.document}
                >
                    <Page pageNumber={pageNumber} width={Math.min(width * 0.75)} />
                </Document>
            </Grid>
            <Grid item xs={12} display='flex' justifyContent='center' alignItems='center' className='navContainer'>
                <Grid item >
                    <IconButton onClick={goToPrevPage}>
                        <ArrowBackIosNewSharpIcon />
                    </IconButton>
                </Grid>
                <Grid item >{pageNumber} from {numPages}</Grid>
                <Grid item >
                    <IconButton onClick={goToNextPage}>
                        <ArrowForwardIosSharpIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PDFViewer;
