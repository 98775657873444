import * as React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useField } from 'formik';
import StyledPaper from '@/componentsMui/Shared/Widgets/StyledPaper';

export interface FormikAutocompleteOptionType {
	code: string,
	label: string
}

interface Props {
	fieldName?: string,
	label?: string,
	options: FormikAutocompleteOptionType[],
	[x: string]: any;
}

const FormikAutocompleteOnlyValue = ({ fieldName, label, options, ...others }: Props) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField(fieldName);
	const { setValue } = helpers;
	const { value } = meta;

	const handleChange = (event: any, newValue: FormikAutocompleteOptionType | string) => {
		others?.onChange && others.onChange(typeof newValue === 'string' ? newValue : newValue?.code);
		setValue(typeof newValue === 'string' ? newValue : newValue?.code);
	};

	React.useEffect(() => {
		console.log('val', value);
	}, [value]);

	const configTextfield = {
		...field,
		...others,
		error: false,
		helperText: null
	};

	if (meta && meta.touched && meta.error) {
		configTextfield.error = true;
		configTextfield.helperText = meta.error;
	}

	const isExistInOptions = (value: string) => {
		return options.filter(option => option.code === value).length > 0;
	};

	return (<Autocomplete
		{...others}
		options={options}
		value={options?.find((option) => option.code === value) || null}
		onChange={handleChange}
		disableClearable
		getOptionLabel={(option) => option?.label ?? ''}
		onInputChange={(e, v) => {
			if (isExistInOptions(v)) {
				others?.onChange && others.onChange(v);
				setValue(v);
			}
		}}
		onBlur={() => {
			if (!isExistInOptions(value)) {
				others?.onChange && others.onChange('');
				setValue('');
			}
		}}
		PaperComponent={({ children }) => (
			<StyledPaper>{children}</StyledPaper>
		)}
		isOptionEqualToValue={(option, v) => v === undefined || option.code === v.code || v?.code === '' || option?.code === value}
		renderInput={(params) => <TextField
			{...configTextfield}
			name={fieldName}
			error={configTextfield.error}
			helperText={configTextfield.helperText ?? ''}
			{...params}
			label={label ? label : ''}
		/>}

	/>);
};


export default FormikAutocompleteOnlyValue;
