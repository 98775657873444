import React, { useCallback, useEffect, useState } from 'react';
import {
	Grid, Tab, Tabs, Theme, useTheme, Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Contact, ContactPaymentTypes, ContactTabEnum } from '@features/contacts/types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { deleteContact, getContacts, searchContact, setContactTab } from '@/features/contacts/slice';
import { useDispatch, useSelector } from 'react-redux';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { RootState } from '@/rootReducer';
import { getAccounts } from '@/features/account/slice';
import { useConfirm } from 'material-ui-confirm';
import SearchField from '@/componentsMui/Shared/Widgets/SearchField';
import ContactLine from './ContactLine';
import _, { isEmpty } from 'lodash';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import { showException } from '@/features/swal/slice';
import { Operations } from '@/features/operations/types';
import { ReactComponent as MuiPlusIcon } from '@/assets/MuiPlusIcon.svg';



const MuiMyContacts = () => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const theme = useTheme() as Theme;
	const { path } = useRouteMatch();
	const history = useHistory();
	const [contactList, setContacts] = useState<Array<Contact>>([]);
	const [search, setSearch] = useState<string>('');
	const { contacts, tab: currentTab } = useSelector(
		(state: RootState) => state.contacts
	);

	const { operations } = useSelector(
		(state: RootState) => state.operations
	);

	const { onlySkipKycUsers } = useSelector(
		(state: RootState) => state.user.user
	);

	const confirm = useConfirm();

	const filterContactsByTab = useCallback((contacts: Contact[], tab: ContactTabEnum): Contact[] => {

		if (tab === ContactTabEnum.ALL) {
			return contacts;
		}
		else if (tab === ContactTabEnum.BANK) {
			const tabContacts = contacts.filter(p => p.type === tab && p.paymentType !== ContactPaymentTypes.LOCAL);
			return tabContacts;
		}
		else if (tab === ContactTabEnum.INTERNAL) {
			const tabContacts = contacts.filter(p => p.type === ContactTabEnum.BANK && p.paymentType === ContactPaymentTypes.LOCAL);
			return tabContacts;
		}
		else {
			const tabContacts = contacts.filter(p => p.type === tab);
			return tabContacts;
		}
	}, []);


	useEffect(() => {

		dispatch(getContacts()); dispatch(getAccounts());
	}, [dispatch]);

	useEffect(() => {
		if (contacts) {
			const c = filterContactsByTab(contacts, currentTab);
			setContacts(c);
		}
	}, [contacts, currentTab, filterContactsByTab]);



	const groupedContacts = _(contactList)
		.sortBy(item => item.name.toLocaleUpperCase())
		.groupBy((b) => b.name.charAt(0).toUpperCase())
		.value();

	const handleSearchChange = async (event) => {
		const search = event.target.value;
		setSearch(search);

		if (!search.length) {
			dispatch(getContacts());
			return;
		}
		if (search?.length >= 3) {
			const list = await searchContact(search);
			const c = filterContactsByTab(list, currentTab);
			setContacts(c);
		}
	};

	const onSend = (beneficiaryAccountId: number, type: string) => {
		history.push(`/wallet/contacts/account/transfers/send/request?contact=${beneficiaryAccountId}&type=${type}`);
	};


	const onRequest = (beneficiaryAccountId: number) => {
		history.push(`/wallet/contacts/account/transfers/request?contact=${beneficiaryAccountId}`);
	};

	const onEdit = (beneficiaryAccountId: number) => {
		history.push(`/wallet/contacts/account/edit/?contact=${beneficiaryAccountId}&from=` + path);
	};

	const handleCreateContact = () => {
		history.push('/wallet/contacts/account/create');
	};

	const remove = useCallback(async (beneficiaryAccountId: number) => {
		try {
			await dispatch(deleteContact(beneficiaryAccountId));
			await dispatch(getContacts());
		}
		catch (e) {
			showException(e);
		}
	}, [dispatch]);

	const handleDelete = (id: number) => {
		confirm({
			title: t('contacts.confirmDelete.confirmTitle'),
			description: t('contacts.confirmDelete.confirmDescription'),
			confirmationText: t('contacts.confirmDelete.confirmConfirmationText'),
			cancellationText: t('contacts.confirmDelete.confirmCancellationText')
		})
			.then(() => {
				remove(id);
			});
	};

	const handleTabChange = (event: React.SyntheticEvent, newValue: ContactTabEnum) => {
		dispatch(setContactTab(newValue));

	};

	return (
		<Grid container>
			<Grid container item alignItems="center" >
				<Grid item xs={7}>
					<MuiHeader>{t('contacts.my.headerContacts')}</MuiHeader>
				</Grid>

				<Grid item xs={5} columnSpacing={2} container >
					<Grid item xs={7}>
						<SearchField value={search} fullWidth sx={{
							'.MuiOutlinedInput-root': {
								height: '3rem !important'
							}
						}}
							onChange={(e) => handleSearchChange(e)} />
					</Grid>
					<Grid item xs={5}>
						<Button variant='contained' onClick={handleCreateContact}>
							<MuiPlusIcon style={{ fill: theme.palette.primary.contrastText, width: '1.5rem', height: '1.5rem' }} />
							<MuiTextCaption color={theme.palette.primary.contrastText} pl='0.5rem'>Create contact</MuiTextCaption>
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} mb='30px' >
				<Tabs value={currentTab}
					onChange={handleTabChange}
				>
					<Tab value={ContactTabEnum.ALL}
						label={t('contacts.tabs.all')}
						sx={{ width: '10.5rem' }}
					/>
					{!onlySkipKycUsers && operations.includes(Operations.CREATE_ACCOUNT) && <Tab value={ContactTabEnum.BANK}
						label={t('contacts.tabs.bank')}
						sx={{ width: '10.5rem' }}
					/>}
					{!onlySkipKycUsers && <Tab value={ContactTabEnum.INTERNAL}
						label={t('contacts.tabs.internal')}
						sx={{ width: '10.5rem' }}
					/>}
					{!onlySkipKycUsers && operations.includes(Operations.CREATE_CRYPTO) && <Tab value={ContactTabEnum.CRYPTO}
						label={t('contacts.tabs.crypto')}
						sx={{ width: '10.5rem' }}
					/>}
				</Tabs>
			</Grid>
			{!isEmpty(groupedContacts) &&
				<Grid item container bgcolor='#1D2024' p='15px 30px' borderRadius='10px'>
					{_.keys(groupedContacts).map(key => {
						return <Grid key={key} container >
							<Grid item xs={12} py='15px'>
								<MuiTextCaption fontSize='1.125rem'>{key}</MuiTextCaption></Grid>
							<Grid item xs={12} container>
								{groupedContacts[key].map((contact) => {
									return (
										<ContactLine key={contact.beneficiaryAccountId}
											contact={contact}
											onDelete={handleDelete}
											onSend={onSend}
											onRequest={onRequest}
											onEdit={onEdit} />);
								})}
							</Grid>
						</Grid>;

					})
					}
				</Grid>}
		</Grid>
	);
};

export default MuiMyContacts;
