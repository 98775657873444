
import { Button, Grid, Link, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import WelcomeCardThemed from '@/componentsMui/Shared/Widgets/WelcomeCardThemed';
import { DeviceInfo } from '@/features/user/types';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { LoginSteps } from '@/helpers/globalTypes';
import ContactSupportForm from './ContactSupportForm';

interface Props {
	deviceInfo: DeviceInfo,
	setStep: (step: LoginSteps) => void,
}


const LoginMobile = ({ setStep }: Props) => {
	const { t } = useTranslation(['translations']);
	const appName = process.env.REACT_APP_TITLE;
	const [openContactSupportForm, setOpenContactSupportForm] = useState<boolean>(false);

	return (
		<Grid>
			{openContactSupportForm && <ContactSupportForm
				open={openContactSupportForm}
				onClose={() => setOpenContactSupportForm(false)}
			/>}
			<Grid item xs={12} borderRadius='10px' pb='1rem'>
				<Grid item container p={2} rowSpacing={2} alignContent='flex-start' textAlign='center'>
					<Grid item xs={12} alignContent='space-between' textAlign='center'>
						<WelcomeCardThemed />
					</Grid>
					<Grid item xs={12} mt={4}>
						<MuiHeader>{t('landing.welcomeStep.title')} {appName}</MuiHeader>
					</Grid>
					<Grid item xs={12} mt={4}>
						<Button
							variant='contained'
							onClick={() => setStep(LoginSteps.LOGIN)}
							sx={{ width: { xs: '100%', sm: '230px' } }}
						>
							{t('login.v3.login')}
						</Button>
					</Grid>
				</Grid>
				<Grid item xs={12} display='flex' flexDirection='row' justifyContent='center'>
					<Typography variant='body2'>{t('login.v3.dontHaveAccount')}</Typography>
					<Link ml={1} onClick={() => setStep(LoginSteps.REGISTRATION)}>{t('login.v3.signup')}</Link>
				</Grid>
				<Grid item xs={12} display='flex' flexDirection='row' justifyContent='center'>
					<Typography variant='body2'>{t('login.cantLogin')}</Typography>
					<Link ml={1} onClick={() => setOpenContactSupportForm(true)}>{t('login.contactSupport')}</Link>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default LoginMobile;
