import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { User, UserType } from '@/features/user/types';
import { isEmpty } from 'lodash';
import Profile from './Profile/Profile';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import PersonalDetails from './Profile/PersonalDetails';
import UserDetails from './Profile/UserDetails';
import LoadingPageMui from '@/pages/LoadingPageMui';
import { connect, subscribe, unsubscribe } from '@features/account/slice';
import { useDispatch } from 'react-redux';
import StatusCard from '../HomeSummary/Components/StatusCard'; 

interface Props {
	user: User,
}

const MuiMyAccount = ({ user }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();

	useEffect(() => {
		connect();
		dispatch(subscribe());
		return () => {
			unsubscribe();
		};
	}, [dispatch]);


	return (isEmpty(user) ? <LoadingPageMui /> :
		<Grid container display='flex' flexDirection='column' height='100%'>
			{user.type !== UserType.EMPLOYEE &&
				<Grid container>
					<Grid item xs={12} >
						<StatusCard />
					</Grid>
				</Grid>}
			<Grid container sx={{ pr: { xs: 0, lg: '2rem' } }}>
				<Grid item xs={12} lg={6} sx={{ pr: { xs: 0, lg: '2.625rem' } }}>

					<MuiHeader pb='30px'>{t('navbar.account.title')}</MuiHeader>
					<Grid item xs={12} mb='1.875rem' >
						<Profile user={user} />
					</Grid>
					<Grid item xs={12} mb='1.875rem' >
						<UserDetails user={user} />
					</Grid>
					{/* {currentPlan && isKycAndPoaCompleted(user) && user?.plan?.upgradeAllowed &&
						<Grid item xs={12}   >
							<PlanOption plan={currentPlan} />
						</Grid>
					} */}
				</Grid>
				<Grid item xs={12} lg={6} >
					{!user?.onlySkipKycUsers && <>
						<MuiHeader pb='30px'>{t('profile.data.personalDetails')}</MuiHeader>
						<PersonalDetails user={user} />
					</>}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default MuiMyAccount;
