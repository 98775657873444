export interface Vault {
	name: string,
	description: string,
	proc: string,
	config: {
		minDepositAmount: number,
	}
	fees: any[],
	processProperties: {
		yearlyInterestRate: string,
		depositPeriodMonths: string,
		minimalTransferableAmount: string,
		depositTrade?: boolean,
		depositDisabled?: boolean,
		logoLink?: string,
		interestPaymentPeriod?: string,
		dynamicVault?: boolean,
		ccy: string,
		currencySymbol: string,
		interestType: VaultInterestType,
		maxDynamicInterestRate: string,

	},
	totalBalance: number,
	currencyLogo?: string,
}

export interface VaultsStore {
	loading: boolean,
	list: Array<Vault>,
	activeDepositsAmount: number,
	totalEarningsAmount: number,
	error: string
}

export enum DepositStatus {
	TERMINATED = 'TERMINATED',
	ACTIVE = 'ACTIVE'
}

export enum VaultInterestType {
    PERIODIC_INTEREST = 'PERIODIC_INTEREST',
    DYNAMIC = 'DYNAMIC',
    PERIODIC_INTEREST_PLUS_DYNAMIC = 'PERIODIC_INTEREST_PLUS_DYNAMIC'
}


export interface VaulDeposit {
	vaultId: number,
	accountId: number,
	walletId: string,
	proc: string,
	name: string,
	status: DepositStatus,
	balance: number,
	balanceOnHold: number,
	availableBalance: number,
	currency: string,
	currencySymbol?: string,
	providerType: string,
	repaymentDate: string,
	yearlyInterestRate: number,
	depositPeriodMonths: number,
	createdDate: string,
}

export interface EarningsStore {
	earnings: Array<Earning>,
	skip: number,
	take: number,
	count: number,
	loading: boolean
}

export interface EarningsPayload {
	skip: number,
	take: number,
	filter?: string,
}

export interface Earning {
	vaultName: string,
	transferDate: string,
	amount: number,
	currency: string
}

export interface AllocationsStore {
	allocations: Array<any>,
	skip: number,
	take: number,
	count: number,
	loading: boolean,
}

export interface AllDepositsStore {
	skip: number,
	take: number,
	count: number,
	depositsLoading: boolean,
	allActiveDeposits: Array<any>,
	filter: string
}

export interface AllocationsPayload {
	skip: number,
	take: number,
	filter?: string,
}

export interface GraphData {
	amount: number,
	period: string
}

export interface VaultEarningStatementPayload {
	from: string,
	to: string,
}

export interface PayoutsStore {
	list: Array<any>,
	skip: number,
	take: number,
	count: number,
	loading: boolean
}

export interface PayoutsPayload {
	skip: number,
	take: number,
	filter?: string,
}
