import { BankAccount } from '@/features/account/types';
import { Contact, ContactUser } from '@/features/contacts/types';
import * as React from 'react';
import { useState } from 'react';
import UserPaymentConfirmation from './UserPaymentConfirmation';
import UserPaymentAmountStep from './UserPaymentAmountStep';
import UserPaymentContactStep from './UserPaymentContactStep';

export interface Props {
	step: number,
	fromAccountList?: BankAccount[],
	defaultAccount?: BankAccount,
	defaultContact?: Contact,
	prevStep?: () => void;
}

export interface UserTransferPayloadType {
	account?: BankAccount,
	amount?: number,
	fee?: number,
	feeString?: string,
	youpay?: number,
	walletId?: string,
	reference?: string,
	addToContacts?: boolean,
	user?: ContactUser,
	contact?: Contact,
	processProcedure?: string,
	isCustomTokenTransfer?: boolean,
	feeInAmount?: boolean,
}

export const initUserPayload: UserTransferPayloadType =
{
	account: null,
	amount: null,
	fee: null,
	youpay: 0,
	walletId: null,
	reference: '',
	addToContacts: false,
	user: null,
	contact: null,
	processProcedure: null
};

const UserPaymentSteps = ({ fromAccountList, step, defaultAccount, defaultContact, prevStep }: Props) => {

	const [userPaymentPayload, setUserPaymentPayload] = useState<UserTransferPayloadType>(initUserPayload);

	const [stepLocal, setStepLocal] = React.useState<number>(step);
	const nextStepLocal = () => setStepLocal(previousState => (previousState + 1));
	const prevStepLocal = () => setStepLocal(previousState => (previousState - 1));

	return (< >
		{stepLocal === 1 && <UserPaymentAmountStep
			fromAccountList={fromAccountList}
			defaultAccount={defaultAccount}
			defaultContact={defaultContact}
			userPaymentPayload={userPaymentPayload}
			setUserPaymentPayload={setUserPaymentPayload}
			nextStep={nextStepLocal}
			prevStepMain={prevStep}
		/>}
		{stepLocal === 2 && <UserPaymentContactStep
			fromAccountList={fromAccountList}
			defaultAccount={defaultAccount}
			defaultContact={defaultContact}
			userPaymentPayload={userPaymentPayload}
			setUserPaymentPayload={setUserPaymentPayload}
			nextStep={nextStepLocal}
			prevStep={prevStepLocal}
			prevStepMain={prevStep}
		/>}
		{stepLocal === 3 && <UserPaymentConfirmation
			userPaymentPayload={userPaymentPayload}
			prevStep={prevStepLocal} />
		}
	</>);
};

export default UserPaymentSteps;
