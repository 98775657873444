import React, { useEffect } from 'react';
import { Alert, Dialog, DialogActions, Grid, IconButton, Link, Paper, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { openForm } from '@/features/forms/slice';
import { useTranslation } from 'react-i18next';
import '@/componentsMui/Shared/Shared.css';
import '@/componentsMui/Shared/SharedFormLayout.css';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import { withStyles } from '@mui/styles';
import MuiTextAlert from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAlert';
import { useHistory } from 'react-router-dom';


const BusinessFormPaper = withStyles((theme) => ({
	root: {
		minWidth: '50rem',
		maxWidth: '50rem',
		padding: '21px',
		background: theme.form.bgLight
	}
}))(Paper);


const FinalRejectedBusinessModal = (): React.ReactElement => {

	const closeModal = useCloseDialogNoEvent();
	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const appName = process.env.REACT_APP_TITLE;
	const history = useHistory();




	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);


	const handleClose = () => {
		closeModal();
	};

	const handleClickOnSupport = () => {
		history.push('/wallet/support');
	};


	return (
		<Dialog
			id="create-business-form"
			open={true}
			onClose={handleClose}
			PaperComponent={BusinessFormPaper}
			maxWidth="md"
		>
			<DialogActions >
				<IconButton onClick={handleClose}  >
					<MuiCloseIcon />
				</IconButton>
			</DialogActions>
			<Grid container justifyContent='center'>
				<Grid item xs={12} >
					<Alert severity="warning">
						<MuiTextAlert>{t('createbusiness.finalRejection.dearClient')}</MuiTextAlert>
						<br />
						<MuiTextAlert>{t('createbusiness.finalRejection.textP1')}</MuiTextAlert>
						<br />
						<MuiTextAlert>{t('createbusiness.finalRejection.textP2')}</MuiTextAlert>
						<br />
						<MuiTextAlert>{t('createbusiness.finalRejection.br')}</MuiTextAlert>
						<MuiTextAlert>{appName}{t('createbusiness.finalRejection.team')}</MuiTextAlert>
					</Alert>
				</Grid>
				<Grid item xs={12} display='flex' flexDirection='row' justifyContent='center' pt={2}>
					<Typography variant='body2'>{t('createbusiness.finalRejection.disagree')}</Typography>
					<Link ml={1} onClick={handleClickOnSupport}>{t('createbusiness.finalRejection.contactSupport')}</Link>
				</Grid>
			</Grid>
		</Dialog >
	);
};

export default FinalRejectedBusinessModal;
