import request from '@/services/request';
import { AppThunk } from '../../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PayoutsPayload, PayoutsStore } from './types';



const initialState: PayoutsStore = {
    loading: true,
    list: [],
    skip: 0,
    take: 10,
    count: 0
};

const slice = createSlice({
    name: 'payouts',
    initialState,
    reducers: {

        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setSkip: (state, { payload }: PayloadAction<number>) => {
            state.skip = payload;
        },
        setTake: (state, { payload }: PayloadAction<number>) => {
            state.take = payload;
        },
        setCount: (state, { payload }: PayloadAction<number>) => {
            state.count = payload;
        },
        setPayout: (state, { payload }: PayloadAction<Array<any>>) => {
            state.list = payload;
        }

    }
});

export const { setPayout, setLoading, setSkip, setTake, setCount } = slice.actions;


export const getPayoutsHistory = (proc: string, payload: PayoutsPayload): AppThunk => {
    return async (dispatch, state) => {
        const { payouts } = state();
        try {
            const response = await request.post(`/api/v3/vaults/payout/${proc}/history`, payload);
            const { data } = response;
            dispatch(setCount(data.count));
            dispatch(setPayout([...payouts.list, ...data.list]));
            // dispatch(setTake(payload.take));
            dispatch(setSkip(payouts.skip + 1));

            return data;
        } catch (e) {
            dispatch(setPayout([]));
            dispatch(setCount(0));
            dispatch(setSkip(0));
            console.log(e);
        } finally {
            dispatch(setLoading(false));
        }
    };
};


export default slice.reducer;
