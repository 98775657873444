import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { WalletType } from '@/features/walletHistory/types';
import CryptoTrade from './Trade/CryptoTrade';
import { CoinTypeEnum } from '@/features/coins/types';
import CoinChart from '../Components/CryptoChart';
import { connectCryptoSocket, getCoins, setFromAsset, setToAsset, setTradeSwapped, subscribeCryptoSocket, transformTokensToAssets, unsubscribeCryptoSocket } from '@features/coins/slice';
import { connect, subscribe, unsubscribe } from '@features/account/slice';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';
import BackButton from '../Components/BackButton';
import PairTransactionsCrypto from './History/PairTransactionsCrypto';


const CryptoDetails = (): React.ReactElement => {
	const dispatch = useDispatch();
	const searchParams = new URLSearchParams(location.search);
	const [requestedToken] = React.useState<string>(searchParams.get('token'));

	const { fromAsset, toAsset, swaped } = useSelector((state: RootState) => state.coins);
	const { list, loading } = useSelector((state: RootState) => state.coins);

	const fiatAssets = list?.filter(p => p.type === 'WALLET' && p.account.status === 'ACTIVE') || [];
	const coinAssets = list?.filter(p => p.account === null || p.type !== 'WALLET') || [];

	const toToken = list.find(p => p.type.concat(p.info.symbol) === requestedToken);
	const fromToken = toToken?.account.type === 'CRYPTO_TOKEN'
		? list?.find(p => toToken?.info?.supportedCcy?.map(a => a.ccy).includes(p.account.currency)) || null
		: list?.find(p => p.type === CoinTypeEnum.WALLET && toToken?.info?.supportedCcy?.map(a => a.ccy).includes(p.account.currency)) || null;

	const defaultToAsset = transformTokensToAssets([toToken])[0] || null;
	const defaultFromAsset = transformTokensToAssets([fromToken])[0] || null;

	const pairObject = fromAsset?.supportedCcy?.find(p => p.ccy === toAsset?.symbol);
	const toAccountId = toAsset?.accountId ? toAsset?.accountId.toString() : null;
	const fromAccountId = fromAsset?.accountId ? fromAsset?.accountId.toString() : null;

	useEffect(() => {
		dispatch(getCoins());
		return () => {
			dispatch(setToAsset(null));
			dispatch(setFromAsset(null));
			dispatch(setTradeSwapped(false));
		};
	}, [dispatch]);

	//subscribe to wallet socket
	useEffect(() => {
		connectCryptoSocket();
		dispatch(subscribeCryptoSocket());
		return () => {
			unsubscribeCryptoSocket();
		};
	}, [dispatch]);


	useEffect(() => {
		connect();
		dispatch(subscribe());
		return () => {
			unsubscribe();
		};
	}, [dispatch]);


	//default asset
	useEffect(() => {
		if (defaultToAsset?.id) {
			dispatch(setToAsset(defaultToAsset));
		}
		if (defaultFromAsset?.id) {
			dispatch(setFromAsset(defaultFromAsset));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [dispatch, defaultFromAsset?.id, defaultToAsset?.id]);

	return (
		<Grid container
			flexDirection='column'
			direction="column"
		>
			<Grid item width='100%' mb={{ xs: '10px', sm: '37px' }} >
				<BackButton />
			</Grid>
			<Grid item width='100%' container >
				<Grid item
					width={{ sm: '100%', md: 'calc(100% - 357px - 14px)' }}
					mr={{ sm: 0, md: '14px' }}
				>
					<CoinChart pair={pairObject?.pair}
						symbol={swaped ? fromAsset?.symbol : toAsset?.symbol}
						name={swaped ? fromAsset?.displayName : toAsset?.displayName}
						toLogo={swaped ? fromAsset?.logo : toAsset?.logo}
						fromAssetSymbol={swaped ? toAsset?.symbol : fromAsset?.symbol}
						hideChart={toAsset?.defiToken || fromAsset?.defiToken}
					/>
				</Grid>
				{loading ? <Spinner /> :
					<Grid item
						width={{ sm: '100%', md: '357px' }}
						marginTop={{ sm: '15px', md: 0 }}
						justifyContent='flex-end'
						container
						border='1px solid #FFFFFF0F'
						borderRadius='10px'
						padding='20px'>
						<CryptoTrade
							walletType={WalletType.CRYPTO}
							coinHoldings={coinAssets}
							fiatHoldings={fiatAssets}
							fromDetails={true}
						/>

					</Grid>}
			</Grid>
			<Grid item width='100%'  >
				<PairTransactionsCrypto accountId={fromAccountId} toAccountId={toAccountId} />
			</Grid>
		</Grid >
	);
};

export default CryptoDetails;
