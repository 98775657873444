import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import { getCurrencySign } from '@/features/fees/slice';
import { AvailablePlan, NextPlan } from '@/features/user/types';
import { Chip, Grid, Theme, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import { formatDateWithPattern } from '@/helpers/date';
import IntroCard from '@/assets/IntroCard.png';
import PlanPriority1 from '@/assets/images/plan_priority_1.png';
import PlanPriority2 from '@/assets/images/plan_priority_2.png';
import PlanPriority3 from '@/assets/images/plan_priority_3.png';
import PlansBgBox from '@/componentsMui/Shared/Widgets/Box/CoverBox/PlansBgBox';
import { useTranslation } from 'react-i18next';

interface Props {
    plan: AvailablePlan;
    selected?: boolean;
    currentPlan?: boolean;
    onClick?: () => void;
    nextPlan?: NextPlan | null;
    hideButton?: boolean;
}


const useStyles = makeStyles((theme: Theme) => ({
    planName: {
        fontSize: '1.25rem',
        fontWeight: 700,
        marginBottom: '2px'
    },
    planPrice: {
        fontSize: '0.875rem',
        fontWeight: 400,
        color: theme.palette.text.primary
    },
    chip: {
        background: 'rgba(255, 255, 255, 0.06)',
        height: '35px',
        '& .MuiChip-label': {
            fontSize: '0.688rem',
            color: theme.palette.text.primary,
            background: 'unset',
            WebkitTextFillColor: 'unset'
        }
    }
}));

const PlanCard = ({ plan, selected, currentPlan, onClick, nextPlan, hideButton }: Props): React.ReactElement => {
    const { t } = useTranslation('translations');
    const theme = useTheme() as Theme;
    const classes = useStyles(theme);

    const isNextPlan = !_.isEmpty(nextPlan) && nextPlan.userLevelId === plan.userLevelId;

    const getImageSource = () => {
        switch (plan.priority) {
            case 0:
                return IntroCard;
            case 1:
                return PlanPriority1;
            case 2:
                return PlanPriority2;
            default:
                return PlanPriority3;
        }
    };


    return (<PlansBgBox p='20px'
        bgIndex={0}
        priority={plan.priority}
        selected={true}
        mainClassName={`planCard ${selected ? 'selected' : '' } ${hideButton ? 'hiddenButton' : ''}`}
        onClick={onClick}
        sx={{ maxHeight: '150px', minHeight: '150px', marginRight: '1.25rem' }}
    >
        <Grid container sx={{ height: '100%' }} >
            <Grid container item xs={4} justifyContent='space-between'>
                <Grid item xs={12}>
                    <MuiTextCaption className={classes.planName}>{plan.name}</MuiTextCaption>
                    <MuiTextCaption className={classes.planPrice}>{plan.price === 0 ? t('plans.v3.free') :
                        <NumberFormat
                            displayType={'text'}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandsGroupStyle='thousand'
                            thousandSeparator={true}
                            value={plan.price}
                            prefix={getCurrencySign(plan.ccy)}
                            suffix='/mo'
                        />}
                    </MuiTextCaption>
                </Grid>
                {!hideButton && <Grid item xs={12}>
                    {isNextPlan ? (
                        <MuiTextCaption fontSize='0.5rem'>{t('plans.v3.nextPlan', { date: formatDateWithPattern(nextPlan.startTime, 'dd-MM-yy') })}</MuiTextCaption>
                    ) : <Chip label={currentPlan ? t('plans.v3.currentPlan') : t('plans.v3.change')} color="primary" className={classes.chip} />}
                </Grid>}
            </Grid>
            <Grid item xs={8} height='inherit' display='flex' justifyContent='flex-end'>
                <img alt="Plan card"
                    height="80%"
                    src={getImageSource()}
                />
            </Grid>
        </Grid>
    </PlansBgBox>);
};

export default PlanCard;
