import React from 'react';
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import StyledIconButtonSmall from './StyledIconButtonSmall';

interface Props {
	onClick?: () => void
}

const ExpandButton = ({ onClick }: Props) => {
	return (
		<StyledIconButtonSmall
			onClick={onClick}>
			<OpenInFullOutlinedIcon sx={{ fontSize: '17px' }} />
		</StyledIconButtonSmall>
	);
};

export default ExpandButton;
