
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import MuiTextLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextLabel';
import * as React from 'react'; 
import { useTranslation } from 'react-i18next'; 
import CardStatusBox from '../../../../Shared/Widgets/Box/CardStatusBox'; 

const CardFrozen = () => {
	const { t } = useTranslation('translations'); 

	return (
		<CardStatusBox id="cardFrozen">
			<MuiTextCaption variant='body2' color='text.primary' lineHeight='26px'>{t('cards.cardStatus.frozen')}</MuiTextCaption>
			<MuiTextLabel variant='body2' lineHeight='16px'>{t('cards.cardStatus.frozenMessage')}</MuiTextLabel>
		</CardStatusBox>);
};

export default CardFrozen;
