import { IconButton, Theme } from '@mui/material';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as MuiCopyIcon } from '@/assets/MuiCopyIcon.svg';
import { SxProps } from '@mui/system';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

interface Props {
	value: any,
	className?: string,
	sx?: SxProps<Theme>,
}
const MuiCopyToClipboard = ({ value, className, sx }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');

	const notify = () => {
		toast.success(t('swal.COPIED'), {
			position: 'top-center',
			autoClose: 2000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'colored',
			toastId: 'copied',
			closeButton: false
		});
	};

	return (
		<CopyToClipboard text={value}>
			<IconButton
				sx={sx}
				onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
					notify();
				}}><MuiCopyIcon className={className ? className : 'svg-icon-stroke'} />
			</IconButton>
		</CopyToClipboard >
	);
};

export default MuiCopyToClipboard;
