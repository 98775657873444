import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import ReferralsDateCell from './ReferralsDateCell';
import ReferralsTextCell from './ReferralsTextCell';
import ReferralsAmountCell from './ReferralsAmountCell';
import { t } from '@/helpers/translate';
import ReferralsReferrerStatusCell from './ReferralsReferrerStatusCell';
import ReferralsRewardsStatusCell from './ReferralsRewardsStatusCell';
import RefferalRewardTypeCell from './RefferalRewardTypeCell';

const widthV = 100;
export const getMyReferralColumns = (): GridColDef[] => [
    {
        field: 'referee',
        headerName: t('referral.myReferralsGrid.id'),
        minWidth: widthV,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => ReferralsTextCell(params),
        sortable: false,
    },
    {
        field: 'registrationDate',
        headerName: t('referral.myReferralsGrid.registrationDate'),
        minWidth: widthV,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => ReferralsDateCell(params),
        sortable: false,
    },
    {
        field: 'verified',
        headerName: t('referral.myReferralsGrid.status'),
        minWidth: widthV,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => ReferralsReferrerStatusCell(params),
        sortable: false,
    },
    {
        field: 'totalEarn',
        headerName: t('referral.myReferralsGrid.yourEarnings'),
        minWidth: widthV,
        flex: 1,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params: GridRenderCellParams) => ReferralsAmountCell(params),
        sortable: false,
    },
];

export const getMyRewardsColumns = (): GridColDef[] => [
    {
        field: 'loyaltyType',
        headerName: t('referral.myRewardsGrid.type'),
        minWidth: widthV,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => RefferalRewardTypeCell(params),
        sortable: false,
    },
    {
        field: 'transferDate',
        headerName: t('referral.myRewardsGrid.distributionDate'),
        minWidth: widthV,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => ReferralsDateCell(params),
        sortable: false,
    },
    {
        field: 'amount',
        headerName: t('referral.myRewardsGrid.bonus'),
        minWidth: widthV,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => ReferralsAmountCell(params),
        sortable: false,
    },
    {
        field: 'status',
        headerName: t('referral.myRewardsGrid.status'),
        minWidth: widthV,
        flex: 1,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params: GridRenderCellParams) => ReferralsRewardsStatusCell(params),
        sortable: false,
    },
];
