import React from 'react';
import { Dialog, DialogContent, Grid, IconButton, Paper } from '@mui/material';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import { withStyles } from '@mui/styles';
import ShCardTopupTransactionGrid from '@/componentsMui/TransactionsGrid/ShCardTopupTransactionGrid';

const TransactionFormPaper = withStyles(() => ({
	root: {
		maxHeight: '90vh',
		minHeight: '90vh',
		padding: '1rem'
	}
}))(Paper);

interface Props {
	open: boolean,
	onClose: () => void
}

const CardTopupTransactionForm = ({ open, onClose }: Props): React.ReactElement => {


	return (<>
		<Dialog
			id="transaction-form"
			open={open}
			fullWidth
			PaperComponent={TransactionFormPaper}
			maxWidth='lg'
		>
			<DialogContent style={{ height: '100vh' }}>
				<Grid container direction='column' height='100%' flexWrap='nowrap'>
					<Grid item width='100%' textAlign='right'>
						<IconButton onClick={onClose}  >
							<MuiCloseIcon />
						</IconButton>
					</Grid>
					<Grid item xs={11} >
						<ShCardTopupTransactionGrid />
					</Grid>
				</Grid>

			</DialogContent>
		</Dialog >
	</>
	);
};

export default CardTopupTransactionForm;
