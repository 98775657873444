import * as React from 'react';
import { SvgIcon } from '@mui/material';

const CardIcon = (props) => (
	<SvgIcon {...props}>
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M20 5H4C3.44772 5 3 5.44772 3 6V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V6C21 5.44772 20.5523 5 20 5Z" stroke="current" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M7 15H11" stroke="current" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M3 11H21" stroke="current" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M3 9H21" stroke="current" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
		</svg>

	</SvgIcon>
);

export default CardIcon;
