import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { Dialog, Grid, IconButton, ListItemIcon, ListItemText, MenuList } from '@mui/material';
import StyledMenuItemModal from '@/componentsMui/Shared/Widgets/MenuItem/StyledMenuItemModal';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SystemType, TransferType, systemTypesUINames } from '@/helpers/globalTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { openForm } from '@/features/forms/slice';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import { findPaymentTypes, findProcessByType, hasSubprocessWithType } from '@/features/operations/slice';
import StyledListItemIcon from '@/componentsMui/Shared/Widgets/StyledListItemIcon';
import { AccountType } from '@/features/operations/types';
import UserPaymentSteps from '../../../Shared/Components/SendMoney/PaymentToOtherUser/UserPaymentSteps';
import BetweenAccountsSteps from '@/componentsMui/Shared/Components/SendMoney/BetweenAccounts/BetweenAccountsSteps';
import TrustPaymentSteps from '../../../Shared/Components/SendMoney/TrustAccount/TrustPaymentSteps';
import { isEmpty } from 'lodash';
import NewPaymentSteps from '@shared/Components/SendMoney/NewPayment/NewPaymentSteps';
import { ReactComponent as MuiBigContacts } from '@/assets/MuiBigContacts.svg';
import { ReactComponent as MuiBigWallet } from '@/assets/MuiBigWallet.svg';
import { ReactComponent as MuiAccountFolderCollection } from '@/assets/MuiAccountFolderCollection.svg';
import '@/componentsMui/Shared/Shared.css';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import FormCloseButton from '@/componentsMui/Wallet/Balances/Deposit/Components/FormCloseButton';
import FormPaperLargeFixed from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeFixed';
import MaintenanceChip from '@/componentsMui/Shared/Widgets/MaintenanceChip';
import BulkPaymentForm from '@/componentsMui/Shared/Components/SendMoney/BulkPayment/BulkPaymentForm';


export const supportedSystemType = [
	SystemType.SEPA,
	SystemType.SEPA_INSTANT,
	SystemType.SWIFT,
	SystemType.UK_CHAPS,
	SystemType.UK_FPS,
	SystemType.US_WIRE,
	SystemType.INTERNATIONAL_WIRE,
	SystemType.ACH,
	SystemType.SAME_DAY_ACH,
	SystemType.SCAN,
];

const SendMoneyForm = () => {
	const { t } = useTranslation('translations');
	const location = useLocation();

	const searchParams = new URLSearchParams(location.search);
	const [accountId] = useState<string>(searchParams.get('account'));

	const dispatch = useDispatch();

	const bankAccounts = useSelector((state: RootState) => state.accounts.list);
	const trustAccounts = useSelector((state: RootState) => state.trust.accounts);
	const allAccounts = bankAccounts.concat(trustAccounts);

	const [transferType, setTransferType] = useState<TransferType>();
	const [systemType, setSystemType] = useState<SystemType>();
	const [process, setProcess] = useState<any>();


	const [step, setStep] = React.useState<number>(0);
	const nextStep = React.useCallback(() => setStep(previousState => (previousState + 1)), []);
	const prevStep = React.useCallback(() => setStep(previousState => (previousState - 1)), []);

	const currentAccount = allAccounts.find(p => p.accountId === parseInt(accountId));

	const betweenAccountsList = allAccounts
		.filter(a => a.type === AccountType.WALLET && a.currency === currentAccount?.currency)
		.filter(accounts => hasSubprocessWithType('OWN_TRANSFER', accounts.subProcesses)) || [];


	const paymentAccountList = process?.integration && allAccounts && process?.integration === 'TRUST' ? [currentAccount] :
		allAccounts.filter(a => a.type === (process?.integration === 'TRUST' ? AccountType.TRUST : AccountType.BANK)
			&& a.currency === currentAccount?.currency)
			.filter(accounts => hasSubprocessWithType('PAYMENT', accounts.subProcesses)) || [];


	const closeModal = useCloseDialogNoEvent();

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);


	const transferProcess = useMemo(() => findProcessByType(currentAccount, 'TRANSFER') || [], [currentAccount]);
	const ownTransferProcess = useMemo(() => findProcessByType(currentAccount, 'OWN_TRANSFER'), [currentAccount]);
	const bulkPaymentProcess = useMemo(() => findProcessByType(currentAccount, 'BULK_PAYMENT'), [currentAccount]);
	const paymentProcessList = useMemo(() => findPaymentTypes(currentAccount).filter(p => supportedSystemType.includes(p.processProperties?.paymentType)) || [], [currentAccount]);

	const transferDown = transferProcess?.maintenance;
	const ownTransferDown = ownTransferProcess?.maintenance;

	const isPaymentEnabled = paymentProcessList?.length > 0;
	const isTransferEnabled = !isEmpty(transferProcess) && currentAccount.type !== AccountType.WALLET && currentAccount.type !== AccountType.TRUST;
	const isTrustTransferEnabled = !isEmpty(transferProcess) && currentAccount.type == AccountType.TRUST;
	const isOwnTransferEnabled = !isEmpty(ownTransferProcess) && allAccounts.length > 1;
	const isBulkPaymentEnabled = !isEmpty(bulkPaymentProcess);

	useEffect(() => {
		if (isPaymentEnabled) {
			setTransferType(TransferType.EXTERNAL);
			if (paymentProcessList[0]?.processProperties?.paymentType) {
				setSystemType(paymentProcessList[0].processProperties.paymentType);
				setProcess(paymentProcessList[0]);
			}
			return;
		} else if (isTransferEnabled) {
			setTransferType(TransferType.CONTACT);
			return;
		} else if (isOwnTransferEnabled) {
			setTransferType(TransferType.INTERNAL);
			return;
		} else if (isTrustTransferEnabled) {
			setTransferType(TransferType.TRUST);
		} else if (isBulkPaymentEnabled) {
			setTransferType(TransferType.BULK);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, []);


	return (
		<Dialog
			id="send-money-form"
			open={true}
			PaperComponent={FormPaperLargeFixed}
		>
			{step === 0 &&
				<>
					<Grid item container justifyContent='space-between'>
						<Grid item>
							<MuiHeader> {t('banking.sendMoneyForm.title')}
							</MuiHeader>
						</Grid>
						<Grid item>
							<IconButton onClick={closeModal}  >
								<MuiCloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item >
						<MuiFormText>{t('banking.sendMoneyForm.subTitle')}</MuiFormText>
					</Grid>
					<Grid item>
						<MenuList role='menu' className='form-menu__list'>
							{isPaymentEnabled && paymentProcessList?.map(p => {
								const down = p?.maintenance;
								return (
									<StyledMenuItemModal
										unsetMarginBottom={true}
										id={p.proc}
										disabled={down}
										key={p.proc + '-' + p.processProperties?.paymentType}
										onClick={() => { setStep(1); setTransferType(TransferType.EXTERNAL); setSystemType(p.processProperties?.paymentType); setProcess(p); }}
									> 	<ListItemIcon sx={{ mr: '1.25rem' }}> <img className='img-icon-size' alt='currency logo' src={currentAccount?.currencyLogo} /> </ListItemIcon>
										<ListItemText className='money-form-list-item-text'>{`${systemTypesUINames[p.processProperties?.paymentType] ?? ''} ${t('sendmoneyTranslation.data.transfer')}`} ({currentAccount.currency})</ListItemText>
										{down && <MaintenanceChip />}
									</StyledMenuItemModal>);
							})
							}
							{isOwnTransferEnabled && betweenAccountsList.length > 0 &&
								<StyledMenuItemModal
									unsetMarginBottom={true}
									key='ownAccount'
									disabled={ownTransferDown}
									onClick={() => { setStep(1); setTransferType(TransferType.INTERNAL); }}
								>
									<StyledListItemIcon><MuiBigWallet /></StyledListItemIcon>
									<ListItemText className='money-form-list-item-text'>{t('sendmoneyTranslation.data.sendToWallet')} ({currentAccount?.currency ?? ''})</ListItemText>
									{ownTransferDown && <MaintenanceChip />}
								</StyledMenuItemModal>
							}
							{isTrustTransferEnabled &&
								<StyledMenuItemModal
									unsetMarginBottom={true}
									key='trustAccount'
									disabled={transferDown}
									onClick={() => { setStep(1); setTransferType(TransferType.TRUST); }}
								>
									<StyledListItemIcon> <MuiBigWallet />  </StyledListItemIcon>
									<ListItemText className='money-form-list-item-text'>{t('sendmoneyTranslation.data.trustHeader')}</ListItemText>
									{transferDown && <MaintenanceChip />}
								</StyledMenuItemModal>
							}
							{isTransferEnabled &&
								<StyledMenuItemModal
									unsetMarginBottom={true}
									id='otherUser'
									onClick={() => { setStep(1); setTransferType(TransferType.CONTACT); }}
									disabled={transferDown}
								>
									<StyledListItemIcon> <MuiBigContacts />  </StyledListItemIcon>
									<ListItemText className='money-form-list-item-text'>{t('sendmoneyTranslation.data.cospayuserheader')}</ListItemText>
									{transferDown && <MaintenanceChip />}
								</StyledMenuItemModal>
							}
							{isBulkPaymentEnabled &&
								<StyledMenuItemModal
									unsetMarginBottom={true}
									id='otherUser'
									onClick={() => { setStep(1); setTransferType(TransferType.BULK); }}
									disabled={transferDown}
								>
									<StyledListItemIcon> <MuiAccountFolderCollection />  </StyledListItemIcon>
									<ListItemText className='money-form-list-item-text'>{t('bulkpayments.title')}</ListItemText>
									{transferDown && <MaintenanceChip />}
								</StyledMenuItemModal>
							}
						</MenuList>
					</Grid>
					<FormCloseButton onClose={closeModal} />
				</>
			}


			{transferType === TransferType.EXTERNAL && step !== 0 &&
				<NewPaymentSteps
					key={systemType}
					step={step}
					accountList={paymentAccountList}
					defaultAccount={currentAccount}
					paymentName={`${systemTypesUINames[process?.processProperties?.paymentType] ?? ''} ${t('sendmoneyTranslation.data.transfer')}`}
					integration={process?.integration}
					paymentType={systemType}
					nextStep={nextStep}
					prevStep={prevStep}
				/>
			}
			{transferType === TransferType.CONTACT && step !== 0 &&
				step === 1 &&
				<UserPaymentSteps
					step={step}
					defaultAccount={currentAccount}
					prevStep={prevStep}
				/>}
			{transferType === TransferType.INTERNAL && step !== 0 &&
				<BetweenAccountsSteps
					step={step}
					title={t('sendmoneyTranslation.data.sendToWallet')}
					fromDefaultAccount={currentAccount}
					fromAccountList={[currentAccount]}
					toAccountList={betweenAccountsList}
					toDefaultAccount={betweenAccountsList[0]}
					nextStep={nextStep}
					prevStep={prevStep}
					noBackground={true}
				/>
			}
			{transferType === TransferType.TRUST && step !== 0 &&
				<TrustPaymentSteps
					step={step}
					defaultAccount={currentAccount}
					prevStep={prevStep}
				/>
			}

			{transferType === TransferType.BULK && step !== 0 && <BulkPaymentForm onBack={prevStep} proc={bulkPaymentProcess}/>}

		</Dialog >
	);
};
export default SendMoneyForm;

