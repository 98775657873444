import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Grid, Link, useMediaQuery, Typography, Container } from '@mui/material';
import { NavLink } from 'react-router-dom';
import './Footer.css';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { PolicyType } from '@/features/settings/type';
import { showPolicy } from '../Legal/Legal';
import MuiFormText from '../Shared/Widgets/MuiText/MuiFormText';

interface Props {
	authorized: boolean
}


const MuiFooter = ({ authorized }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const { agent } = useSelector(
		(state: RootState) => state.status
	);
	const brandName = t('brandName.brand');
	const d = new Date();
	const year = d.getFullYear();

	const smallScreen = useMediaQuery('(max-width:600px)');

	const baseLink = authorized ? '/wallet' : '/legal';

	const showAml = showPolicy(agent, PolicyType.AML_POLICY);
	const showCookies = showPolicy(agent, PolicyType.COOKIES_POLICY);
	const showTermsOfUse = showPolicy(agent, PolicyType.TERMS_OF_USE);
	const showTerms = showPolicy(agent, PolicyType.TERMS_AND_CONDITIONS);
	const showPrivacyPolicy = showPolicy(agent, PolicyType.PRIVACY_POLICY);
	const showContacts = showPolicy(agent, PolicyType.CONTACTS);
	const showComplaints = showPolicy(agent, PolicyType.COMPLAINTS);
	const showCrypto = showPolicy(agent, PolicyType.CRYPTO);

	const extraFooter = ['NEURAL', 'NEURALID', 'IDEALOOP'].includes(agent);

	return (<>
		<Grid container
			//minHeight={`${footerHeight}px`}
			alignItems='center'
			justifyContent='flex-end'
			id='portal-footer-id'>

			<Grid container item xs={12} sx={(theme) => ({ background: theme.bgSecondaryButton })}>
				<Container component='main'
					disableGutters={true}>
					<Grid container
						className={`${smallScreen ? 'mui-footer-mobile-auth' : 'mui-footer'}`} style={{
							flexDirection: smallScreen ? 'column' : 'row',
							flexGrow: 1
						}}>
						<Grid item xs='auto' container columnSpacing={smallScreen ? 0 : 4} alignItems='center'>
							{showPrivacyPolicy && <Grid item>
								<Link
									underline='none'
									variant='plainLink'
									component={NavLink}
									to={`${baseLink}/privacypolicy`}> {t('footer.privacy')}</Link>
							</Grid>}
							{showTerms && <Grid item>
								<Link underline="none"
									variant='plainLink'
									component={NavLink}
									to={`${baseLink}/terms`}>{t('footer.terms')}</Link>
							</Grid>}
							{showAml &&
								<Grid item>
									<Link underline="none"
										variant='plainLink'
										component={NavLink}
										to={`${baseLink}/amlpolicy`}>{t('footer.aml')}</Link>
								</Grid>}
							{showCookies &&
								<Grid item>
									<Link underline="none"
										variant='plainLink'
										component={NavLink}
										to={`${baseLink}/cookiespolicy`}>{t('footer.cookies')}</Link>
								</Grid>}
							{showTermsOfUse &&
								<Grid item>
									<Link underline="none"
										variant='plainLink'
										component={NavLink}
										to={`${baseLink}/termsofuse`}>{t('footer.termsOfUse')}</Link>
								</Grid>}
							{showCrypto &&
								<Grid item>
									<Link underline="none"
										variant='plainLink'
										component={NavLink}
										to={`${baseLink}/termsofcrypto`}>{t('footer.termsOfUseCrypto')}</Link>
								</Grid>}
							{/* {showVaultPolicy &&
							<Grid item>
								<Link underline="none"
									variant='plainLink'
									component={NavLink}
									to={`${baseLink}/termsofvault`}>{t('footer.termsOfVault')}</Link>
							</Grid>} */}
							{showContacts &&
								<Grid item>
									<Link underline="none"
										variant='plainLink'
										component={NavLink}
										to={`${baseLink}/complaints`}>{t('footer.complaints')}</Link>
								</Grid>}
							{showComplaints &&
								<Grid item>
									<Link underline="none"
										variant='plainLink'
										component={NavLink}
										to={`${baseLink}/contacts`}>{t('footer.contacts')}</Link>
								</Grid>}
							{smallScreen &&
								<Grid item >
									<Typography
										variant='plainLink'
									>{brandName} copyright notice {year}
									</Typography>
								</Grid>
							}
						</Grid>
						{
							!smallScreen &&
							<Grid item xs='auto'>
								<Typography
									variant='plainLink'
								>{brandName} copyright notice {year}
								</Typography>
							</Grid>
						}

					</Grid>
				</Container>
			</Grid >
			{extraFooter &&
				<Grid container item xs={12}>
					<Container component='main'
						disableGutters={true}>
						<Grid container p={3}>
							<MuiFormText fontSize='0.8rem' color='text.secondary' className='highlight-regular'>
								<Trans i18nKey='footer.neuralText'
									t={t}
									components={{
										termlink: <a
											rel="noreferrer"
											target="_blank"
											href='https://www.modulrfinance.com/hubfs/Public%20one%20pagers/How%20we%20keep%20your%20money%20safe.pdf?utm_campaign=CS%20Newsletter&utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz-8GGUjvcSIumPYzdQZepftCuGJ6TYzh3IPcsG1gPqbq96LuNARG8ERWJHfA50Dk5GpjbW17'
										/>,
									}}
								/>
							</MuiFormText>
						</Grid>

					</Container>
				</Grid>
			}
		</Grid >
	</>
	);
};
export default MuiFooter;
