export interface Card {
	name: string,
	walletId: string,
	accountId: number,
	cardId: number,
	cardNumberMask: string,
	number: string,
	balance: number,
	balanceOnHold: number,
	availableBalance?: number,
	availableAmount?: number,
	currency: string,
	currencySymbol?: string,
	status: CardStatuses,
	trackingNumber?: string,
	expirationDate?: string,
	detail?: any,
	providerType?: string,
	logo: string,
	logoTextColor?: string,
	cardholder?: string
	deliveryAddress: {
		country: string,
		city: string,
		address: string,
		zip: string
	}
	assignedTo?: {
		employeeId: number,
		firstName: string,
		lastName: string,
		businessId: number,
		company: string
	},
	phone: string,
	virtual: boolean,
	process?: Process,
	balanceInEur: string,
	balanceInUsd: string,
	balanceInGbp: string,
	precision: number,
	eligibleForReplacement?: boolean,
}

export interface Process {
	config: any,
	proc: any,
	subProcesses: any,
	type: string,
	integration: string
	processProperties: any
}

export interface CardsStore {
	loading: boolean,
	list: Array<Card>,
	error: string
}

export enum CardType {
	PHYSICAL, VIRTUAL
}

export enum CardStatuses {
	REQUESTED = 'REQUESTED',
	PENDING = 'PENDING',
	ORDERED = 'ORDERED',
	ORDER_COMPLETED = 'ORDER_COMPLETED',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
	SUSPENDED = 'SUSPENDED', //supended by user
	ACTIVE = 'ACTIVE',
	ACTIVATING = 'ACTIVATING',
	INACTIVE = 'INACTIVE',
	LOST = 'LOST',
	STOLEN = 'STOLEN',
	EXPIRED = 'EXPIRED',
	FROZEN = 'FROZEN', //suspended by system .e.g. not enough money to cover monthly fee
	UNKNOWN = 'UNKNOWN'
}

export enum CardSubProcesses {
	CARD_ACTIVATE = 'CARD_ACTIVATE',
	CARD_GET_CVV = 'CARD_GET_CVV',
	CARD_GET_PIN = 'CARD_GET_PIN',
	CARD_PULL_OUT = 'CARD_PULL_OUT',
	CARD_SUSPEND = 'CARD_SUSPEND',
	CARD_TOP_UP = 'CARD_TOP_UP',
	CARD_UPDATE_PASSPHRASE = 'CARD_UPDATE_PASSPHRASE',
	CARD_UPDATE_PHONE = 'CARD_UPDATE_PHONE',
	CARD_BLOCK_AND_PULL_OUT = 'CARD_BLOCK_AND_PULL_OUT',
	CARD_BLOCK = 'CARD_BLOCK', //SH card block, pullout not possible
	REPLACE_CARD = 'REPLACE_CARD'
}

export enum CardFeeType {
	CREATE_CARD = 'CREATE_CARD',
	CARD_TOP_UP = 'CARD_TOP_UP',
	ATM_WITHDRAWAL_EU = 'ATM_WITHDRAWAL_EU',
	ATM_WITHDRAWAL_WORLD = 'ATM_WITHDRAWAL_WORLD',
	PURCHASE = 'PURCHASE',
	PURCHASE_ONLINE = 'PURCHASE_ONLINE',
	REPLACE_CARD = 'REPLACE_CARD',
}


export interface BulkEntry {
	id: number,
	amount: number,
	employeeName: string,
	employeeRef: string,
	cardName: string,
	sessionId: number,
	userID: number,
	selectedCardId: number,
	cardSelection: {
		id: number,
		cardName: string
	}[]
	error: BulkError
}

export enum BulkError {
	INACTIVE_USER = 'INACTIVE_USER',
	INACTIVE_CARD = 'INACTIVE_CARD',
	NO_CARD = 'NO_CARD',
	MULTIPLE_CARDS = 'MULTIPLE_CARDS',
	DONT_BELONG_TO_COMPANY = 'DONT_BELONG_TO_COMPANY',
	TECHNICAL = 'TECHNICAL'
}

export interface DectaCardPinResponse {
	pin: string
}

export interface DectaCardPanResponse {
	pan: string,
	cvv: string,
}


export interface ICCardPinResponse {
	cardId: number,
	pin: string
}



export interface CardCvvResponse {
	cardId: number,
	expiry: string,
	cvv: string
}

export interface Address {
	country?: string,
	city?: string,
	address?: string,
	address2?: string,
	postalCode?: string,
	fullAddress?: string,
	sublocality?: string,
}

export interface LatLongCoordinates {
	lat: number,
	lng: number
}

export interface PotentialAddress {
	address: Address,
	coordinates: LatLongCoordinates
}

export enum CardIntegrationEnum {
	SH_FINANCE = 'SH_FINANCE',
	DECTA = 'DECTA',
}
