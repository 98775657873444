
import * as React from 'react';
import { BoxProps } from '@mui/material';
import { FC } from 'react';
import TopBox from './TopBox';

/* Used to dispay card status */
const CardStatusBox: FC<BoxProps> = (props: BoxProps) => {


	return (<TopBox id="card-status-box"
		padding='16px 24px 16px 24px'
		marginTop='16px'
		textAlign='center'
		
		{...props}>
		{props.children}
	</TopBox>);
};


export default CardStatusBox;
