import SearchField from '@/componentsMui/Shared/Widgets/SearchField';
import TrxDateCell from '@/componentsMui/TransactionsGrid/Components/TrxDateCell';
import { getInvoicesList, initialInvoicesPagination } from '@/features/invoices/invoicesSlice';
import { showErrorNotification } from '@/features/swal/slice';
import { RootState } from '@/rootReducer';
import { Grid } from '@mui/material';
import { DataGrid, GridColDef, GridPaginationModel, GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import InvoiceAmountCell from './cells/InvoiceAmountCell';
import InvoiceStatusCell from './cells/InvoiceStatusCell';
import InvoiceOrderDateCell from './cells/InvoiceOrderDateCell';
import useCustomBreakpoints from '@/helpers/customHook/useCustomBreakpoints';
import InvoiceAmountStatusCell from './cells/InvoiceAmountStatusCell';
import InvoiceTextCell from './cells/InvoiceTextCell';
import { useHistory } from 'react-router-dom';

const InvoiceList = () => {
    const { t } = useTranslation('translations');
    const history = useHistory();
    const apiRef = useGridApiRef();
    const dispatch = useDispatch();
    const [search, setSearch] = useState<string>('');
    const { list, loading, count } = useSelector((state: RootState) => state.invoices);
    const { isDesktop } = useCustomBreakpoints();

    const getInvoices = useCallback(async (paginatationParams: any) => {
        await dispatch(getInvoicesList({
            ...initialInvoicesPagination,
            ...paginatationParams,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getInvoices(initialInvoicesPagination);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearchRequest = (e) => {
        const regex = /^[ A-Za-z0-9-.,&/_?!]*$/;
        if (regex.test(e.target.value)) {
            setSearch(e.target.value);
            const payload = { filter: `any=${e.target.value}` };
            const currentPage = apiRef.current.state.pagination.paginationModel.page;

            if (currentPage === 0) {
                getInvoices(payload);
            } else {
                // This triggers call onPaginationModelChange
                setTimeout(() => apiRef.current.setPage(0));
            }
        }
    };

    const handleOnCellClick = (params) => {
        const trx = params.row;
        history.push(`/wallet/home/invoices/details/${trx.id}`);
    };

    const onPaginationModelChange = async (model: GridPaginationModel) => {
        try {
            //call page from grid model  
            const newPayload = { filter: `any=${search}`, take: model.pageSize, skip: model.page };
            getInvoices(newPayload);
        }
        catch (e) {
            showErrorNotification(e);
        }
    };

    const columns = useMemo(() => isDesktop ? getColumns(t) : getMobileColumns(t), [isDesktop, t]);

    return (
        <Grid container>
            <Grid item xs={12} container justifyContent='flex-end'>
                <SearchField
                    id='search'
                    name='search'
                    value={search}
                    onChange={(e) => handleSearchRequest(e)}
                />
            </Grid>
            <Grid item xs={12}>
                <DataGrid
                    autoPageSize={false}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 10, page: 0 },
                        },
                    }}
                    autoHeight={true}
                    pageSizeOptions={[10]}
                    pagination
                    apiRef={apiRef}
                    rows={list}
                    rowCount={count}
                    loading={loading}
                    getRowId={(row) => row.id}
                    onPaginationModelChange={onPaginationModelChange}
                    onCellClick={handleOnCellClick}
                    paginationMode="server"
                    filterMode="server"
                    sortingMode="server"
                    disableRowSelectionOnClick={true}
                    columns={columns}
                    sx={{
                        // pointer cursor on ALL rows
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer'
                        }
                    }}
                />
            </Grid>
        </Grid>
    );
};


export const getColumns = (t: any): GridColDef[] => [
    {
        field: 'createdDate',
        headerName: t('invoice.date'),
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => TrxDateCell(params),
    },
    {
        field: 'orderNumber',
        headerName: t('invoice.orderNumber'),
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => InvoiceTextCell(params),
    },
    {
        field: 'description',
        headerName: t('invoice.details'),
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => InvoiceTextCell(params),
    },
    {
        field: 'totalAmount',
        headerName: t('invoice.amount'),
        flex: 1,
        sortable: false,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params: GridRenderCellParams) => InvoiceAmountCell(params)
    },
    {
        field: 'status',
        headerName: t('invoice.invStatus'),
        flex: 1,
        sortable: false,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params: GridRenderCellParams) => InvoiceStatusCell(params)
    }
];


export const getMobileColumns = (t: any): GridColDef[] => [
    {
        field: 'orderNumber',
        headerName: t('invoice.orderNumber'),
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => InvoiceOrderDateCell(params),
    },
    {
        field: 'totalAmount',
        headerName: t('invoice.amount'),
        flex: 1,
        sortable: false,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params: GridRenderCellParams) => InvoiceAmountStatusCell(params)
    },
];

export default InvoiceList;
