
import * as React from 'react';
import './ButtonContent.css';


interface Props {
	children?: JSX.Element | JSX.Element[];
}

/* Applies css to position buttons */
const ButtonContent = (props: Props) => {
	return (
		<div id={ React.Children.count(props.children)>1 ? 'multipleButtonContent' :  'oneButtonContent'}
		>
			{props.children}
		</div>
	);
};

export default ButtonContent;
