import * as React from 'react';

import { Dialog, Grid } from '@mui/material';
import DialogContentNoScrollbar from '@/componentsMui/Shared/Widgets/Dialog/DialogContentNoScrollbar';
import { Employee } from '@/features/employee/types';
import { useEffect, useState } from 'react';
import ActiveEmployeeMainStep from './ActiveEmployeeMainStep';
import ActiveEmployeeUnlinkStep from './ActiveEmployeeUnlinkStep';
import { getAvailableCards } from '@/features/employee/slice';
import ActiveEmployeeAddCardStep from './ActiveEmployeeAddCardStep';

interface Props {
	open: boolean,
	onClose: () => void,
	employee: Employee;
	fetchData: () => void,
}

export enum EmployeeDetailsStep {
	MAIN = 'MAIN', UNLINK = 'UNLINK', ADD_CARD = 'ADD_CARD'
}

const ActiveEmployeeForm = ({ open, onClose, employee, fetchData }: Props) => {
	const [step, setStep] = useState<EmployeeDetailsStep>(EmployeeDetailsStep.MAIN);
	const [availableCards, setAvailableCards] = useState([]);
	
	const handleClose = () => {
		onClose();
		setStep(EmployeeDetailsStep.MAIN);
	};

	useEffect(() => {
		const fetchAvalableCards = async () => {
			const available = await getAvailableCards();
			setAvailableCards(available);
		};
		fetchAvalableCards();
	}, [fetchData]);

	return (
		<Dialog
			id="employee-details"
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth="xs"
		>
			<DialogContentNoScrollbar>
				<Grid
					container
					sx={{p: { xs: '1rem', md: '2rem' }}}
				>
						{step === EmployeeDetailsStep.MAIN && <ActiveEmployeeMainStep employee={employee} onClose={handleClose} setStep={setStep} fetchData={fetchData} availableCards={availableCards}/>}
						{step === EmployeeDetailsStep.UNLINK && <ActiveEmployeeUnlinkStep employee={employee} onClose={handleClose} setStep={setStep}/>}
						{step === EmployeeDetailsStep.ADD_CARD && <ActiveEmployeeAddCardStep employee={employee} onClose={handleClose} setStep={setStep} fetchData={fetchData} availableCards={availableCards}/>}
				</Grid>
			</DialogContentNoScrollbar>
		</Dialog >
	);
};

export default ActiveEmployeeForm; 
