import { Button, Dialog, DialogContent, Grid, IconButton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { isEmpty } from 'lodash';
import { showException, showSuccess } from '@/features/swal/slice';
import { sendPdfByEmail } from '@/features/transactions/slice';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { Transaction } from '../TransactionStatements.spec';
import { getSingleTransactionPdfPayload } from '@/helpers/transactionUtils';
import { AccountType } from '@/features/operations/types';
import { useEmail } from '@/helpers/customHook/useValidations';

interface Props {
	open: boolean,
	transaction: Transaction,
	type: AccountType,
	walletId?: string,
	onSendStart: () => void,
	onSendEnd: () => void
}

const SendForm = ({ open, transaction, type, walletId, onSendStart, onSendEnd }: Props) => {
	const { t } = useTranslation('translations');

	const emailValidation = useEmail();

	const initialValues = {
		email: ''
	};
	const validationSchema = Yup.object({
		email: emailValidation
	});

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		onSendStart();
		try {
			const { email } = formData;
			const payload = getSingleTransactionPdfPayload(type,
				transaction.activity,
				transaction.docNumber,
				transaction.transactionNumber,
				walletId);

			await sendPdfByEmail(email, transaction, payload, transaction?.activity);
			showSuccess({ title: t('sharetransaction.modal.sent'), mui: true, confirmBTNText: t('form.buttons.close') });
		} catch (error) {
			setSubmitting(false);
			showException(error);
		}
		finally {
			onSendEnd();
		}
	};

	return (
		<Dialog
			id="send-trx-details"
			open={open}
			fullWidth
			maxWidth="xs"
		>
			<DialogContent>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={submit}
				>{({ errors, isSubmitting, dirty, touched }) => {

					return (
						<Form >
							<Grid container padding='40px'>
								<Grid item container justifyContent="space-between" alignItems="center" >
									<Grid item>
										<MuiHeader>{t('sharetransaction.modal.header')}</MuiHeader>
									</Grid>
									<Grid item>
										<IconButton onClick={onSendEnd}  >
											<MuiCloseIcon />
										</IconButton>
									</Grid>
								</Grid>
								<Grid item container mt='1.5rem' justifyContent='center'>
									<Grid item xs={12}>
										<MuiTextField
											id="email"
											name="email"
											label={t('sharetransaction.modal.label')}
											fullWidth
											error={errors.email && touched.email}
										/>
									</Grid>
									<Grid item xs={12} pt={3} >
										<Button
											type="submit"
											variant="contained"
											color='primary'
											fullWidth
											disabled={isSubmitting || !isEmpty(errors) || !dirty}
										>
											{t('form.buttons.send')}
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Form>
					);
				}}
				</Formik>
			</DialogContent>
		</Dialog >);
};

export default SendForm;
