
import * as React from 'react';
import { useTheme } from '@mui/styles';
import { Box, BoxProps, Theme } from '@mui/material';
import { FC } from 'react';

const WalletBox: FC<BoxProps> = (props: BoxProps) => {

	const theme = useTheme() as Theme;

	const { sx, ...other } = props;

	return (<Box
		sx={{
			color: theme.palette.text.secondary,
			borderRadius: theme.mainBorderRadius,
			background: { xs: 'none', sm: '#2A2E32' },
			p: { xs: 0, sm: '1.5rem' },
			width: '100%',
			mb: { xs: 3, md: 0 },
			mt: { sm: '15px', md: '30px' },
			...sx,
		}}
		{...other}>{props.children}
	</Box>);
};


export default WalletBox;
