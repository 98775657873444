import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import Poa from './Poa';
import GoogleAuth from './GoogleAuth/GoogleAuth';
import DocumentVerification from './DocumentVerification';
import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { get2FAStatus, getChooseToVerify, getIdentityStatus, getKycOnboardingStatus, getPoaOnboardingStatus } from '@/helpers/userStatuses';
import { LandingStepEnum, LandingStepType } from '@/features/landingStep/type';
import { setLandingStep, setLandingStepCount } from '@/features/landingStep/slice';
import ChooseIdentityVerification from './ChooseIdentityVerification';
import Identity from './Identity';
import MinimalLayout from '../Layout/MinimalLayout';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { HOME, HOME_SUMMARY } from '@/pages/navigationConstants';
import { isBirthInfoRequired, isOnboardingRequired } from '@/helpers/kyc';
import AccountsKycPlaceholder from '../AccountsKycPlaceholder';
import { refreshUser } from '@/features/user/userSlice';
import ProfileDetails from './ProfileDetails';
import ShareNeuralIdStep from './ShareNeuralIdStep';
import { UserType } from '@/features/user/types';
import LoadingPageMui from '@/pages/LoadingPageMui';
import ProfileDetailsModulR from './ProfileDetailsModulR';
import ProfileDetailsPlaceOfBirth from './ProfileDetailsPlaceOfBirth';
import { getKycAddressList } from '@/features/kyc/slice';
import OnboardingSupport from './OnboardingSupport';

export enum ActionsStatus {
	COMPLETED = 'COMPLETED', FAILED = 'FAILED', NEW = 'NEW', INPROGRESS = 'INPROGRESS'
}

const Onboarding = (): React.ReactElement => {
	const theme = useTheme();
	const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
	const [showSupport, setShowSupport] = useState(false);
	const paddingTop = matchDownSm ? 10 : 60;

	const location = useLocation();
	const history = useHistory();
	const data = location.state as unknown as any;
	const [redo] = useState(data?.redo ?? 'N');

	const dispatch = useDispatch();
	const { user, status } = useSelector((state: RootState) => state.user);
	const { step: landingStep } = useSelector((state: RootState) => state.landingStep);
	const kycAddressList = useSelector((state: RootState) => state.kyc.kycAddressList);
	//const neuralIdEnabled = useSelector((state: RootState) => state.status.neuralIdEnabled);
	const skipAllowed = status.skipKycAllowed;
	const kycService = status?.kycProvider;
	const bankProvider = status?.bankProvider;

	const poaStatus = getPoaOnboardingStatus(user);
	const kycStatus = getKycOnboardingStatus(user);
	const profileStatus = (user?.occupationCode || user?.type === UserType.EMPLOYEE) ? ActionsStatus.COMPLETED : ActionsStatus.NEW;
	const identityStatus = getIdentityStatus(user, kycService);
	const twoFAStatus = get2FAStatus(user, status);
	const chooseToVerify = getChooseToVerify(kycStatus, poaStatus, user?.type);
	const userOnboarded = !isOnboardingRequired(user, status);
	const birthInfoStatus = isBirthInfoRequired(user, status, kycAddressList) ? ActionsStatus.NEW : ActionsStatus.COMPLETED;

	const stepArray: Array<LandingStepType> = useMemo(() =>
		!user?.onlySkipKycUsers ?
			[
				{ stepName: LandingStepEnum.CREATE_ACCOUNT, stepOrder: 1, status: user?.id ? ActionsStatus.COMPLETED : ActionsStatus.NEW },
				{ stepName: LandingStepEnum.TWOFA, stepOrder: 2, status: twoFAStatus },
				...(skipAllowed ? [{ stepName: LandingStepEnum.CHOOSE_TO_VERIFY, stepOrder: 3, status: chooseToVerify }] : []),
				{ stepName: LandingStepEnum.IDENTITY, stepOrder: 4, status: identityStatus },
				{ stepName: LandingStepEnum.PLACE_OF_BIRTH, stepOrder: 5, status: birthInfoStatus },
				{ stepName: LandingStepEnum.PROFILE, stepOrder: 6, status: profileStatus },
				{ stepName: LandingStepEnum.POA, stepOrder: 7, status: poaStatus },
				{ stepName: LandingStepEnum.KYC, stepOrder: 8, status: kycStatus },
			] :
			[
				{ stepName: LandingStepEnum.CREATE_ACCOUNT, stepOrder: 1, status: user?.id ? ActionsStatus.COMPLETED : ActionsStatus.NEW },
				{ stepName: LandingStepEnum.TWOFA, stepOrder: 2, status: twoFAStatus },
			],
		/* [
			{ stepName: LandingStepEnum.CREATE_ACCOUNT, stepOrder: 1, status: user?.id ? ActionsStatus.COMPLETED : ActionsStatus.NEW },
			{ stepName: LandingStepEnum.TWOFA, stepOrder: 2, status: twoFAStatus },
			...(skipAllowed ? [{ stepName: LandingStepEnum.CHOOSE_TO_VERIFY, stepOrder: 3, status: chooseToVerify }] : []),
			{ stepName: LandingStepEnum.IDENTITY, stepOrder: 4, status: identityStatus },
			{ stepName: LandingStepEnum.PROFILE, stepOrder: 5, status: profileStatus },
			{ stepName: LandingStepEnum.POA, stepOrder: 6, status: poaStatus },
			{ stepName: LandingStepEnum.KYC, stepOrder: 7, status: kycStatus },
		] */
		[user?.onlySkipKycUsers, user?.id, twoFAStatus, skipAllowed, chooseToVerify, identityStatus, birthInfoStatus, profileStatus, poaStatus, kycStatus]);

	const initStep = stepArray.find(p => p.status !== ActionsStatus.COMPLETED);
	const currentStep = stepArray.find(p => p.stepName == landingStep.stepName);
	const renderStep = initStep ? (initStep.stepOrder > currentStep?.stepOrder ? initStep : currentStep) : null;
	const stepCount = Object.keys(stepArray).length;


	const getNextStep = useCallback(() => {
		const nextStep = stepArray.find(p => p.status !== ActionsStatus.COMPLETED && p.stepOrder > renderStep.stepOrder);
		if (nextStep?.stepName) {
			dispatch(setLandingStep(nextStep));
		}

		else if (userOnboarded) {
			history.push(HOME);
		}
	}, [stepArray, userOnboarded, renderStep?.stepOrder, dispatch, history]);


	useEffect(() => {
		dispatch(setLandingStepCount(stepCount + 1));
	}, [dispatch, stepCount]);


	useEffect(() => {
		dispatch(getKycAddressList());
	}, [dispatch]);


	const handlePoaComplete = useCallback(() => {
		if (kycService === 'onfido') {
			history.push(HOME_SUMMARY);

		}
		else {
			dispatch(setLandingStep({ stepName: LandingStepEnum.KYC, stepOrder: 8 }));
			dispatch(refreshUser());
		}

	}, [dispatch, kycService, history]);


	const handleNerualIdComplete = useCallback(() => {
		history.push(HOME_SUMMARY);

	}, [history]);

	const handleTwaNext = useCallback(() => {

		if (user?.onlySkipKycUsers) {
			status?.twoFARequired ? undefined : history.push(HOME_SUMMARY);
		}
		else {
			getNextStep();
		}

	}, [getNextStep, history, status.twoFARequired, user?.onlySkipKycUsers]);

	const goBacktoStart = useCallback(() => dispatch(setLandingStep(
		skipAllowed ? { stepName: LandingStepEnum.CHOOSE_TO_VERIFY, stepOrder: 3 } :
			{ stepName: LandingStepEnum.TWOFA, stepOrder: 2 })),
		[dispatch, skipAllowed]
	);

	const allowToGoBack = skipAllowed ? true : twoFAStatus !== ActionsStatus.COMPLETED;

	if (!user) return <><LoadingPageMui /></>;

	if (user?.type == UserType.EMPLOYEE) {
		return <Redirect to={HOME} />;
	}

	if (showSupport) return <OnboardingSupport paddingTop={paddingTop} goBack={() => setShowSupport(false)} />;


	return (
		<>
			{renderStep?.stepName === LandingStepEnum.TWOFA &&
				<GoogleAuth
					paddingTop={paddingTop}
					next={handleTwaNext} />}
			{renderStep?.stepName === LandingStepEnum.CHOOSE_TO_VERIFY &&
				<MinimalLayout paddingTop={paddingTop} width='70%' allowGoHome={false} rightPanelGridProps={{
					display: { xs: 'none', sm: 'flex' }
				}}>
					<ChooseIdentityVerification next={getNextStep} />
				</MinimalLayout >}
			{renderStep?.stepName === LandingStepEnum.IDENTITY &&
				<Identity paddingTop={paddingTop}
					kycService={kycService}
					onComplete={getNextStep}
					goBack={goBacktoStart}
					allowToGoBack={allowToGoBack} />}
			{renderStep?.stepName === LandingStepEnum.PLACE_OF_BIRTH &&
				<ProfileDetailsPlaceOfBirth paddingTop={paddingTop}
					next={getNextStep}
					goBack={goBacktoStart} />}
			{renderStep?.stepName === LandingStepEnum.KYC &&
				<DocumentVerification
					skipAllowed={skipAllowed}
					kycStatus={kycStatus}
					paddingTop={paddingTop}
					stepStatus={kycStatus}
					poaStatus={poaStatus}
					next={getNextStep}
					goBack={goBacktoStart}
					allowToGoBack={allowToGoBack}
					onContactSupport={() => setShowSupport(true)} />
			}
			{renderStep?.stepName === LandingStepEnum.PROFILE &&
				(bankProvider === 'modulr' ?
					<ProfileDetailsModulR
						paddingTop={paddingTop}
						next={getNextStep} /> :
					<ProfileDetails
						paddingTop={paddingTop}
						next={getNextStep} />)
			}
			{renderStep?.stepName === LandingStepEnum.POA &&
				<Poa stepStatus={poaStatus}
					paddingTop={paddingTop}
					onComplete={handlePoaComplete} />
			}
			{renderStep?.stepName === LandingStepEnum.NEURAL_ID &&
				<ShareNeuralIdStep
					paddingTop={paddingTop}
					onSuccess={handleNerualIdComplete}
					goBack={goBacktoStart} />
			}
			{userOnboarded && redo !== 'Y' &&
				< Redirect to={HOME_SUMMARY} />
			}
			{userOnboarded && redo === 'Y' && !renderStep &&
				< Redirect to={HOME_SUMMARY} />
			}
			{
				!renderStep && !userOnboarded &&
				< AccountsKycPlaceholder />
			}
		</>
	);
};

export default Onboarding;
