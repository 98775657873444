import request from '@/services/request';
import { AppThunk } from '../../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Vault, VaultEarningStatementPayload, VaultsStore } from './types';
import { SocketService } from '@/services/socketService';
import { Subscription } from 'rxjs';


const initialState: VaultsStore = {
	loading: true,
	list: [],
	error: null,
	totalEarningsAmount: 0,
	activeDepositsAmount: 0
};

const slice = createSlice({
	name: 'vaults',
	initialState,
	reducers: {
		setVaults(state, action: PayloadAction<Array<Vault>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setTotalEarningsAmount(state, action: PayloadAction<number>) {
			state.totalEarningsAmount = action.payload;
		},
		setActiveDepositsAmount(state, action: PayloadAction<number>) {
			state.activeDepositsAmount = action.payload;
		},
	}
});

export const { setVaults, setLoading, setError, setTotalEarningsAmount, setActiveDepositsAmount } = slice.actions;


export const getVaults = (): AppThunk => {
	return async (dispatch) => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/v3/vaults/summary');
			const { data } = response;

			const vaultList: Vault[] = data?.map(p => ({ ...p.process, currencyLogo: p?.logo, totalBalance: p?.depositAmount })) || [];

			dispatch(setVaults(vaultList));
			return data;
		} catch (e) {
			console.log(e);
		} finally {
			dispatch(setLoading(false));
		}
	};
};


export const getDepositFromAccounts = async () => {
	const response = await request.get('/api/v3/vaults/from-accounts');
	const data = response?.data || [];
	const filtered = data?.filter(p => p?.account?.status === 'ACTIVE') || [];
	return filtered;
};

export const postVaultDeposit = async (payload: any) => {
	const response = await request.post('/api/v3/vaults/deposit', payload);
	const { data } = response;
	return data;
};

export const postVaultDepositPayInfo = async (payload: any) => {
	const response = await request.post('/api/v3/vaults/pay-info', payload);
	const { data } = response;
	return data;
};

export const getVaultEarningStatementPdf = async (payload: VaultEarningStatementPayload) => {
	const file = await request.post('/api/notifications/earning/statements',
		payload,
		{ responseType: 'arraybuffer' }).then((response) => {
			const blob = new Blob([response.data], { type: 'application/pdf' });
			return blob;
		});

	return file;
};


let socketService: SocketService;
let vaultSubscriber: Subscription;

export const connectVaultSocket = (): void => {
	if (!socketService) {
		socketService = new SocketService('vault');
	}
};

export const subscribeVaultSocket = (): AppThunk => {
	return async dispatch => {
		try {
			vaultSubscriber = socketService.listen('vault', {}).subscribe((data) => {
				if (data === 'updated') {
					dispatch(getVaults());
				}
			});
		} catch (e) {
			console.log(e);
			dispatch(setError(e));
		}
	};
};

export const unsubscribeVaultSocket = (): void => {
	vaultSubscriber.unsubscribe();
};


export default slice.reducer;
