import * as React from 'react';
import { Autocomplete, Box, InputAdornment } from '@mui/material';
import { alpha2ToAlpha3, alpha3ToAlpha2, getNames } from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik'; 
import { CountryCodeType } from '../Widgets/CountryCodesAlpha3';
import MuiTextField from './MuiTextField';
import _ from 'lodash';
import { useState } from 'react';

interface Props {
    fieldName: string,
    fieldlabel?: string,
    countryList?: CountryCodeType[],
    onChange?: (value: string) => void,
}

// SET formik value to coutry code
const CountryCodesAlpha3 = ({ fieldName, fieldlabel, countryList, onChange, ...otherProps }: Props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [field, meta, helpers] = useField(fieldName);
    const { setValue } = helpers;
    const { value } = meta;

    const { t } = useTranslation(['translations']);
    const countries = getNames('en');
    const countryOptions = countryList ? countryList : Object.keys(countries)?.map(p => { return ({ label: countries[p], code: alpha2ToAlpha3(p) }); });
    const sortedList = _.sortBy(countryOptions, ['label', 'code']);
    const configTextfield = {
        ...field,
        ...otherProps,
        error: false,
        helperText: null
    };

    const [activeOption, setActiveOption] = useState<CountryCodeType>(sortedList.find(p => p.code === value));

    if (meta && meta.touched && meta.error) {
        configTextfield.error = true;
        configTextfield.helperText = meta.error;
    }

    const handleChange = (value: CountryCodeType | string) => {
        setActiveOption(typeof value === 'object' ? value : undefined);
        const newVal = typeof value === 'object' ? (value?.code ?? '') : (value ?? '');
        setValue(newVal);
        onChange && onChange(newVal);
    };


    return (
        <Autocomplete
            key='country-codes-alpha2-formik'
            options={sortedList}
            autoComplete={false}
            value={activeOption || null}
            onChange={(e, v) => handleChange(v)}
            fullWidth
            sx={{ width: '100%' }}
            isOptionEqualToValue={(option, value) => value === undefined ||
                (typeof option === 'object' && typeof value === 'object' && option?.code === value?.code)}

            renderInput={(params) => {

                const alpha2 = activeOption?.code ? alpha3ToAlpha2(activeOption?.code) : undefined;

                return (<MuiTextField
                    fullWidth {...configTextfield}
                    name={fieldName}
                    {...params}
                    inputProps={{ ...params.inputProps, autoComplete: 'off' }}
                    label={fieldlabel ?? t('form.fields.country')}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (alpha2 ?
                            <InputAdornment position="start" sx={{ height: '100%' }}>
                                <img
                                    loading="lazy"
                                    src={`https://flagcdn.com/w20/${alpha2.toLowerCase()}.png`}
                                    alt=""
                                />
                            </InputAdornment> : <></>
                        ),
                    }} />);
            }}
            renderOption={(props, option) => {
                const alpha2 = alpha3ToAlpha2(option?.code);
                const src = alpha2 ? `https://flagcdn.com/w20/${alpha2.toLowerCase()}.png` : '';
                return (<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option?.code}>
                    <img
                        loading="lazy"
                        width="20"
                        src={src}
                        srcSet={`${src} 2x`}
                        alt=""
                    />
                    {option.label}
                </Box>);
            }

            }

        />);
};


export default CountryCodesAlpha3;
