
import * as React from 'react';
import { Box, BoxProps, Theme } from '@mui/material';
import { FC } from 'react';
import './CoverBox.css';
import { useTheme } from '@mui/styles';
import Cover from './Covers/Cover';

interface Props extends BoxProps {
	selected?: boolean,
	mainClassName?: string,
	itemClassName?: string,
	bgIndex?: number,
	coverName?: string,
}

/*Unselect items are grey*/
const MonoBgBox: FC<Props> = (props: Props) => {

	const { selected, sx, ...others } = props;
	const theme = useTheme() as Theme;

	return (
		<div id='option-cover-box' className={props.mainClassName}>
			{selected ?
				<Cover baseBg={theme.covers[props.coverName ?? 'productCover'].baseBg}
					firstBg={theme.covers[props.coverName ?? 'productCover'].bg[props.bgIndex ?? 0].firstBg}
					secondBg={theme.covers[props.coverName ?? 'productCover'].bg[props.bgIndex ?? 0].secondBg}
				>

					<Box
						height='inherit'
						width='inherit'
						sx={{
							...sx,
							color: theme.palette.text.primary, 
						}}
						{...others}>
						<div className={'option-cover-box__item-wrap ' + props.itemClassName}  >
							{props.children}
						</div>
					</Box>
				</Cover> :
				<Box
					sx={{
						...sx,
						borderRadius: theme.mainBorderRadius, 
						background: theme.form.bgDefault,
						color: theme.palette.text.secondary,
						width: '100%',
						height: '100%'
					}}
					{...others}>
					<div className={'option-cover-box__item-wrap ' + props.itemClassName}  >
						{props.children}
					</div>
				</Box>
			}
		</div >);
};

export default MonoBgBox;


