import * as React from 'react';

import { Dialog, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import TopUpDetails, { CardTopupPayloadType, initCardTopupPayload } from './TopUpDetails';
import TopUpConfirmation from './TopUpConfirmation';
import useCloseDialog from '@/helpers/customHook/useCloseDialog';
import { openForm } from '@/features/forms/slice';
import { useDispatch, useSelector } from 'react-redux';
import FormPaperLargeFixed from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeFixed';
import { getCards } from '@/features/card/slice';
import { RootState } from '@/rootReducer';
import LoadingPageMui from '@/pages/LoadingPageMui';

const TopUpForm = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const [cardId] = useState<string>(searchParams.get('card'));
	const [topupPayload, setTopupPayload] = useState<CardTopupPayloadType>(initCardTopupPayload);
	const dispatch = useDispatch();
	const allCards = useSelector((state: RootState) => state.cards.list);
	const card = allCards.find(p => `${p.cardId}` === cardId);

	const closeModal = useCloseDialog();

	useEffect(() => {
		dispatch(getCards());
		dispatch(openForm());
	}, [dispatch]);


	const [step, setStep] = React.useState(1);
	const nextStep = () => setStep(step + 1);
	const prevStep = () => setStep(step - 1);

	return (
		<Dialog
			id="topUpForm"
			open={true}
			onClose={closeModal}
			PaperComponent={FormPaperLargeFixed}
		>
			{card ? <Grid
				container
				direction="row"
				justifyContent="center"
				flex={1}
			>
				{step === 1 &&
					<TopUpDetails
						card={card}
						payload={topupPayload}
						setPayload={setTopupPayload}
						nextStep={nextStep} />
				}
				{step === 2 &&
					<TopUpConfirmation
						payload={topupPayload}
						prevStep={prevStep} />
				}
			</Grid> : <LoadingPageMui />}

		</Dialog >
	);
};


export default TopUpForm; 
