export interface BulkPaymentsStore {
    uploadsList: BulkPaymentsUpload[];
    uploadsCount: number;
    uploadsLoading: boolean;
    paymentsList: PaymentLine[];
    paymentsListExecutable: boolean;
    paymentsCount: number;
    paymentsLoading: boolean;
    paymentsSkip: number;
    paymentsFilter: string;
}


export interface BulkPaymentsUpload {
    sessionId: string;
    status: BulkPaymentsUploadStatus;
    filename: string;
    uploadDate: string;
    executionDate: string;
}

export enum BulkPaymentsUploadStatus {
    VALID = 'VALID',
    ERROR = 'ERROR',
    PROCESSING = 'PROCESSING',
    PARTIALLY_COMPLETED = 'PARTIALLY_COMPLETED',
    COMPLETED = 'COMPLETED'
}

export enum BulkPaymentLineStatus {
    VALID = 'VALID',
    INVALID = 'INVALID',
    ERROR = 'ERROR',
    PROCESSING = 'PROCESSING',
    COMPLETED = 'COMPLETED',
    REJECTED = 'REJECTED',
    SUBMITTED = 'SUBMITTED'
}


export interface PaymentLine {
    id: number;
    sourceAccount: string;
    amount: number;
    ccy: string;
    paymentType: string;
    paymentReason: string;
    beneficiaryName: string;
    beneficiaryCompanyRegistrationNr: string;
    beneficiaryIban: string;
    beneficiarySortCode: string;
    beneficiaryAccountNumber: string;
    errors: PaymentLineError[];
    sessionId: string;
    status: string;
}
export interface PaymentLinePayload {
    id: number;
    sourceAccount: string;
    amount: number;
    ccy: string;
    paymentType: string;
    paymentReason: string;
    beneficiaryName: string;
    beneficiaryCompanyRegistrationNr?: string;
    beneficiaryIban?: string;
    beneficiarySortCode?: string;
    beneficiaryAccountNumber?: string;
    sessionId: string;
}

export interface PaymentLineError {
    field: string;
    errorCode: string;
    errorMessage: string;
    generalErrorCode: string;
}
