import * as React from 'react';
import { Alert, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiTextAlert from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAlert';
import useCloseDialog from '@/helpers/customHook/useCloseDialog';
import { useHistory } from 'react-router';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import FormCloseButton from '@/componentsMui/Wallet/Balances/Deposit/Components/FormCloseButton';

type Props = {
    onBack?: () => void,
    onClose?: () => void,
}

const ChangePhoneNumberStep = ({ onBack, onClose }: Props) => {
    const { t } = useTranslation('translations');
    const closeModal = useCloseDialog();
    const history = useHistory();


    const navigateToMyAccount = () => {
        closeModal;
        history.push('/wallet/account');
    };


    return (
        <Grid id='paymentDetails' container alignContent='space-between' flex={1}>
            <Grid item container xs={12}>

                <Grid item xs={12} pb={4}>
                    <FormHeader title={t('cards.cardSettings.menu.changePhoneNumber')} showBack={!!onBack} onBack={onBack} />
                </Grid>
                <Grid item xs={12} className='sepa-warning '>
                    <Alert severity="warning"  >
                        <MuiTextAlert  >
                            {t('cards.cardSettingForm.changePhoneNumberWarningPart1')}<strong onClick={navigateToMyAccount} style={{ textDecoration: 'underline', cursor: 'pointer' }}>{t('cards.cardSettingForm.changePhoneNumberWarningPart2')}</strong>{t('cards.cardSettingForm.changePhoneNumberWarningPart3')}
                        </MuiTextAlert>
                    </Alert>
                </Grid>
            </Grid>
            <FormCloseButton onClose={onClose} />
        </Grid>
    );

};

export default ChangePhoneNumberStep;
