import React from 'react';

import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { setAccount } from '@/features/tabs/slice';
import { Card, CardIntegrationEnum } from '@/features/card/types';
import { Grid, Theme, useTheme } from '@mui/material';
import { getCurrencySign } from '@/features/fees/slice';
import { hiddenBabalance } from '@/helpers/placeholders';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import { BankAccount } from '@/features/account/types';
import NumberFormat from 'react-number-format';
import CardProductBox from '@/componentsMui/Shared/Widgets/ProductBox/CardProductBox';
import { useTranslation } from 'react-i18next';

interface AccountMenuProps {
    accounts: Card[]
}

const BusinessCardsChooser = ({ accounts }: AccountMenuProps): React.ReactElement => {
    const { user } = useSelector((state: RootState) => state.user);

    const dispatch = useDispatch();

    const { currentAccount } = useSelector((state: RootState) => state.tabs);

    const onClickSetAccount = (account) => {
        dispatch(setAccount(account));
    };

    const card = currentAccount as Card;

    return (
        <>
            {accounts.map((account, index) => (
                <CardChooserItem
                    key={'account-' + index} 
                    selected={account.cardId === card?.cardId} //for sh cards account id is same
                    account={account}
                    hideValues={user.hideValues}
                    onClick={onClickSetAccount}
                />
            ))}
        </>
    );
};


interface AccountCardItemProps {
    account: Card,
    hideValues?: boolean,
    selected?: boolean,
    onClick?: (account: BankAccount) => void
}

const CardChooserItem = ({ account, selected, hideValues, onClick }: AccountCardItemProps) => {
    const { t } = useTranslation('translations');
    const theme = useTheme() as Theme;
    const lightSelected = theme.palette.mode === 'light' && selected;
    const numberMask = account.cardNumberMask ? `•• ${account.cardNumberMask?.slice(-4)}` : '••••';

    return (
        <CardProductBox
            id='account-item'
            selected={selected}
            onClick={() => onClick && onClick(account)}
        >
            <Grid container alignItems='center' p='15px'>
                <Grid item xs={8}>
                    {account.process.integration !== CardIntegrationEnum.SH_FINANCE ? <>
                        {hideValues ?
                            <MuiTextCaption variant={lightSelected ? 'gradient' : 'inherit'}>{getCurrencySign(account.currency)} {hiddenBabalance}</MuiTextCaption>
                            : <NumberFormat displayType={'text'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandsGroupStyle='thousand'
                                thousandSeparator={true}
                                prefix={getCurrencySign(account.currency)}
                                value={account.availableAmount}
                            />
                        }
                    </> : <MuiTextCaption variant={lightSelected ? 'gradient' : 'inherit'}>{account.name}</MuiTextCaption>}
                    <MuiTextCaption color='text.secondary' fontWeight={400} fontSize='0.688rem' lineHeight='1.063rem'>
                        {account?.virtual ? t('cards.businessCards.details.virtual') : t('cards.businessCards.details.physical')}
                    </MuiTextCaption>
                </Grid>
                <Grid item xs={4} textAlign='right'>
                    <MuiTextCaption fontWeight={400} fontSize='0.688rem' lineHeight='0.688rem'>
                        {numberMask}
                    </MuiTextCaption>
                </Grid>
            </Grid>
        </CardProductBox>
    );
};


export default BusinessCardsChooser;

