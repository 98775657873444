
import { getUserAccountsLimitsData } from '@/features/limits/slice';
import { UserLimitType } from '@/features/limits/types';
import bigDecimal from 'js-big-decimal';
import { useCallback, useEffect, useState } from 'react';

export const getFormattedValue = (value: number) => {
  if (value >= 1) { return 2; }
  if (value >= 0.01) { return 4; }
  if (value >= 0.0001) { return 6; }
  if (value >= 0.000001) { return 8; }
  if (value === 0) { return 2; }
  return 10;
};

const useCurrencyLimits = (currency: string | null | undefined) => {

  const [userLimits, setUserLimits] = useState<UserLimitType | null>();
  const [loadingLimits, setLoadingLimits] = useState<boolean>(true);

  const availableBeforeLimitCcy = userLimits ? userLimits.availableBeforeLimitCcy : undefined;
  const isLimitSet = availableBeforeLimitCcy !== undefined && availableBeforeLimitCcy !== null;

  const isLimitExceeded = (youpay: number | string) => {
    return bigDecimal.compareTo(youpay, (availableBeforeLimitCcy ?? youpay)) > 0;
  };

  const fetchLimits = useCallback(() => {
    const get = async () => {
      setLoadingLimits(true);
      try {
        if (currency) {
          const data = await getUserAccountsLimitsData(currency);
          setUserLimits(data);
        } else {
          setUserLimits(undefined);
        }
      } catch (e) {
        setUserLimits(undefined);
      }
      finally { setLoadingLimits(false); }
    };
    get();
  }, [currency]);

  useEffect(() => {
    fetchLimits();
  }, [fetchLimits]);




  return {
    isLimitExceeded,
    userLimits,
    loadingLimits,
    isLimitSet,
  };

};

export default useCurrencyLimits;
