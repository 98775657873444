import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import React from 'react';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import LoadingButton from '@/componentsMui/Shared/Components/LoadingButton';
import { sendInvoiceEmail } from '@/features/invoices/invoicesSlice';
import { showErrorNotification, showSuccess } from '@/features/swal/slice';
import { useEmail } from '@/helpers/customHook/useValidations';

interface Props {
    open: boolean;
    onClose: () => void;
    recipientEmail: string | null;
    orderId: number,
}

const SendInvoiceEmailDialog = ({ open, onClose, recipientEmail, orderId }: Props) => {
    const { t } = useTranslation('translations');

    const emailValidation = useEmail();

    const initialValues = {
        email: recipientEmail ?? '',
    };

    const validationScheme = Yup.object({
        email: emailValidation,
    });


    const submit = async (formData: any, formikProps: any) => {
        const { setSubmitting } = formikProps;
        const { email } = formData;

        setSubmitting(true);

        try {
            await sendInvoiceEmail(orderId, email);
            showSuccess({ text: t('invoice.orderEmailSent', { email }) });
        } catch (err) {
            showErrorNotification(err);
        } finally {
            setSubmitting(false);
            onClose();
        }
    };




    return (
        <Dialog
            open={open}
            maxWidth='xs'
        >
            <DialogTitle>
                <FormHeader
                    title={t('invoice.sendEmail')}
                    onBack={onClose}
                />
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationScheme}
                    onSubmit={submit}
                    enableReinitialize
                >
                    {({ isSubmitting, errors }) => (
                        <Form>
                            <Grid container gap={1}>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        name='email'
                                        label={t('invoice.email')}
                                        fullWidth
                                        multiline
                                        maxRows={3}
                                    />
                                </Grid>

                                <Grid item xs={12} mt={2}>
                                    <LoadingButton
                                        disabled={isSubmitting || !isEmpty(errors)}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting}
                                    >
                                        {t('invoice.send')}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default SendInvoiceEmailDialog;
