import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Alert, Button, FormHelperText, Grid, IconButton, Theme, useMediaQuery } from '@mui/material';
import { closeCodeConfirm, nextV3Confim, rejectCodeConfirm } from '@/features/2fa/slice';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import LogoThemed from './Shared/Widgets/LogoThemed';
import MuiBigHeader from './Shared/Widgets/MuiText/MuiBigHeader';
import MuiFormText from './Shared/Widgets/MuiText/MuiFormText';
import { Form, Formik, useFormikContext } from 'formik';
import MuiTextField from './Shared/FormikComponents/MuiTextField';
import DialogWithBg from './Shared/Widgets/Dialog/DialogWithBg';
import { ConfirmType } from '@/features/2fa/types';
import { showException } from '@/features/swal/slice';
import MuiTextAlert from './Shared/Widgets/MuiText/MuiTextAlert';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import './ConfirmCodes.css';
import Spinner from './Shared/Widgets/Spinner/Spinner';
import { resendOtp } from '@/features/user/userSlice';
import { getRecaptureToken } from '@/helpers/recaptureUtils';
import { useTheme } from '@mui/styles';
import BackMobileButton from '@shared/Widgets/BackMobileButton';
import { NUMBERS_ONLY_REGEX } from '@/helpers/customHook/useValidations';

const AutoSubmit = () => {
	const { values, submitForm } = useFormikContext<{
		code: string
	}>();
	React.useEffect(() => {
		if (values?.code?.length === 6) {
			submitForm();
		}
	}, [values, submitForm]);
	return null;
};

const ConfirmCodes = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const theme = useTheme() as Theme;
	const history = useHistory();
	const [loading, setLoading] = useState<boolean>(false);
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


	const { codeConfirm, message, alert, phoneNumber, email } = useSelector(
		(state: RootState) => state.twoFA
	);

	const resendTimout = codeConfirm === ConfirmType.EMAIL ? 60 : 90;
	const { user } = useSelector(
		(state: RootState) => state.user
	);


	const [counter, setCounter] = useState<number>(60);

	const initialValues = {
		code: ''
	};
	const validationSchema = Yup.object({});


	const handleCancel = () => {
		dispatch(rejectCodeConfirm());
	};

	const confirm = async (formData, formikProps) => {
		const { setSubmitting, resetForm } = formikProps;
		setLoading(true);
		setSubmitting(true);
		try {
			const { code } = formData;
			dispatch(nextV3Confim(code, codeConfirm, history, alert ? true : false));

		}
		catch (e) {
			dispatch(closeCodeConfirm());
			showException(e);
		} finally {
			setSubmitting(false);
			setTimeout(() => setLoading(false), 2000);
			resetForm({});
		}
	};


	useEffect(() => {
		let timer;
		if (counter > 0) {
			timer = setTimeout(() => setCounter(counter - 1), 1000);
		}

		return () => {
			clearTimeout(timer);
		};
	}, [counter]);


	useEffect(() => {
		if (codeConfirm === ConfirmType.NONE) return;
		setCounter(resendTimout);
	}, [codeConfirm, resendTimout]);

	const resendCode = async () => {
		let number = phoneNumber;
		let mailAddress = email;
		let clientId = null;
		if (user) {
			number = phoneNumber ? phoneNumber : (user.mobileNumber ?? null);
			mailAddress = email ? email : (user.email ?? null);
			clientId = user.clientId;
		}

		try {
			const recaptchaToken = await getRecaptureToken();
			if (number || mailAddress) {
				await resendOtp(codeConfirm === ConfirmType.EMAIL ? 'email' : 'sms', phoneNumber ?? null, mailAddress ?? null, clientId, recaptchaToken);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setCounter(resendTimout);
		}
	};

	const handleChange = (event, setFieldValue) => {
		const value = event.target.value.replace(NUMBERS_ONLY_REGEX, '');
		setFieldValue('code', value);
	};


	return (
		<DialogWithBg open={codeConfirm !== ConfirmType.NONE} maxWidth='xs' fullScreen={fullScreen} className={`dialog ${loading ? 'loading' : ''}`} >
			{loading && <div className={'loading-spinner'}><Spinner dialog={false} /></div>}
			<Grid item sx={{ display: { xs: 'block', sm: 'none' }, p: 2 }}>
				<BackMobileButton onClick={handleCancel} />
			</Grid>
			<Grid container justifyContent='center' position='relative' pt={1} sx={{ display: { xs: 'none', sm: 'flex' } }}>
				<LogoThemed style={{
					height: '3.5rem',
					width: 'auto',
					maxWidth: '120px',
					marginTop: '2rem'
				}} />
				<IconButton disabled={loading} onClick={handleCancel} className='closeIcon' >
					<MuiCloseIcon />
				</IconButton>
			</Grid>
			<Grid container
				spacing={2}
				alignItems='center'
				justifyContent='center'
				rowGap={3}
				sx={{ p: { xs: 2, sm: '1rem 3rem 2rem 3rem' } }}
			>
				<Grid item xs={12} >
					<MuiBigHeader textAlign='center' fontSize='1.625rem'>{t(codeConfirm === ConfirmType.EMAIL ? 'login.confirmCodeDialog.emailTitle' : 'login.confirmCodeDialog.smsTitle')} </MuiBigHeader>
				</Grid>
				<Grid item xs={12} container justifyContent='center'>
					{codeConfirm === ConfirmType.EMAIL ? <MailOutlineIcon className='mainIcon' sx={{ color: 'text.primary' }} /> : <PhoneIcon className='mainIcon' sx={{ color: 'text.primary' }} />}
				</Grid>
				<Grid item xs={12} >
					<MuiFormText textAlign='center'>{t(codeConfirm === ConfirmType.EMAIL ? 'login.authenticationEmail' : 'login.authenticationPhone')} </MuiFormText>
				</Grid>
				<Grid item xs={12}>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={confirm}
					>{({ setFieldValue }) => {
						return (
							<Form style={{ width: '100%' }} >
								<AutoSubmit />
								<Grid item xs={12} container rowGap={1} sx={{ backgroundColor: { xs: theme.bgSecondaryButton, sm: 'inherit' }, p: { xs: 3, sm: 0 }, borderRadius: { xs: '10px', sm: 'none' } }}>
									<Grid item xs={12}>
										<MuiTextField
											name="code"
											fullWidth
											autoFocus={true}
											onChange={(event) => handleChange(event, setFieldValue)}
											placeholder={t('form.confirmCodes.placeholder')}
											disabled={loading}
											inputProps={{ autoComplete: 'off' }}
											label={codeConfirm === ConfirmType.EMAIL ?
												<div className='labelWrap'><MailOutlineIcon className='labelIcon' sx={{ color: 'text.primary' }} />{t('login.emailCode')}</div> :
												<div className='labelWrap'><PhoneIcon className='labelIcon' sx={{ color: 'text.primary' }} />{t('login.phoneCode')}</div>}
										/>
									</Grid>
									{message &&
										<Grid item xs={12}>
											<FormHelperText  >
												{message}
											</FormHelperText>
										</Grid>}
								</Grid>
							</Form>
						);
					}}
					</Formik>
				</Grid>
				{alert &&
					<Grid item xs={12}>
						<Alert severity="warning"  >
							<MuiTextAlert  >
								{alert}
							</MuiTextAlert>
						</Alert>
					</Grid>
				}
				{(phoneNumber || email || user?.mobileNumber || user?.email) && <Grid item xs={12}>
					<Button
						variant='customOutlined'
						fullWidth
						disabled={loading || counter > 0}
						onClick={resendCode}  >
						{counter > 0 ? counter : t('form.confirmCodes.resendBtn')}
					</Button>
				</Grid>}
			</Grid >
		</DialogWithBg >
	);
};

export default ConfirmCodes;
