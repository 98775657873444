import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import AuthAppDownload from './AuthAppDownload';
import AuthCodeScan from './AuthCodeScan';
import AuthEnable from './AuthEnable';
import { setLandingTitle } from '@/features/landingStep/slice';
import { useTranslation } from 'react-i18next';
import MinimalLayout from '@/componentsMui/Layout/MinimalLayout';
import BackButtonWebLanding from '../components/BackButtonWebLanding';

interface Props {
	paddingTop?: number,
	next: () => void
}

const GoogleAuth = ({ paddingTop, next }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const title = t('landing.authStep.title');

	const [step, setStep] = useState<number>(0);
	const { status } = useSelector(
		(state: RootState) => state.user
	);

	const nextAuth = () => setStep(p => p + 1);
	const previousAuth = () => setStep(p => p - 1);




	useEffect(() => {
		dispatch(setLandingTitle(title));
	}, [dispatch, title]);


	const skip2FA = status?.twoFARequired ? undefined : next;


	return (
		<>
			{step === 0 &&
				<MinimalLayout paddingTop={paddingTop} allowGoHome={false}>
					<AuthAppDownload next={nextAuth} skip2FA={skip2FA} />
				</MinimalLayout>}
			{step === 1 &&
				<MinimalLayout
					allowGoHome={false}
					paddingTop={paddingTop} leftPanel={<BackButtonWebLanding onClick={previousAuth} />} >
					<AuthCodeScan
						next={nextAuth}
						previous={previousAuth} />
				</MinimalLayout>
			}
			{step === 2 &&
				<MinimalLayout
					allowGoHome={false}
					paddingTop={paddingTop}
					leftPanel={<BackButtonWebLanding onClick={previousAuth} />} >
					<AuthEnable next={next} previous={previousAuth} />
				</MinimalLayout >}

		</>);
};

export default GoogleAuth;
