import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogActions, Grid, IconButton, ListItemText, MenuList } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { openForm } from '@/features/forms/slice';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import StyledMenuItemModal from '@/componentsMui/Shared/Widgets/MenuItem/StyledMenuItemModal';
import FormPaperLarge from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLarge';
import StyledListItemIcon from '@/componentsMui/Shared/Widgets/StyledListItemIcon';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import FormCloseButton from '@/componentsMui/Wallet/Balances/Deposit/Components/FormCloseButton';
import EmployeeInviteStep from './CreateEmployeeSteps/EmployeeInviteStep';
import AdminInviteStep from './CreateEmployeeSteps/AdminInviteStep';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import { RootState } from '@/rootReducer';
import { RootProcessType } from '@/helpers/globalTypes';

export enum CreateEmployeeAction {
	ADMIN = 'ADMIN', EMPLOYEE = 'EMPLOYEE'
}

const CreateEmployeeForm = (): React.ReactElement => {

	const closeModal = useCloseDialogNoEvent();
	const dispatch = useDispatch();
	const { t } = useTranslation('translations');

	const [action, setAction] = useState<CreateEmployeeAction>(null);
	const [step, setStep] = useState<number>(0);
	const { operations } = useSelector((state: RootState) => state.operations);
	const cardsAllowed = operations.includes(RootProcessType.CREATE_CARD);

	const prevStep = () => setStep(step - 1);



	const handleMenuClick = (action: CreateEmployeeAction) => {
		setAction(action);
		setStep(1);
	};


	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);


	return (
		<Dialog
			id="create-employee-form"
			open={true}
			onClose={closeModal}
			PaperComponent={FormPaperLarge}
		>
			{step === 0 &&
				<>
					<DialogContent style={{ padding: 0 }}>
						<Grid item container justifyContent='space-between'>
							<Grid item>
								<MuiHeader> {t('employee.create.title')} </MuiHeader>
							</Grid>
							<Grid item>
								<IconButton onClick={closeModal}  >
									<MuiCloseIcon />
								</IconButton>
							</Grid>
						</Grid>
						{cardsAllowed &&
							<Grid item xs={12} pt={1}>
								<MuiFormText> {t('employee.create.subTitle')} </MuiFormText>
							</Grid>
						}
						<Grid item xs={12} pt={6}  >
							<MenuList role='menu'>
								<StyledMenuItemModal
									id='admin'
									selected={action === CreateEmployeeAction.ADMIN}
									onClick={() => handleMenuClick(CreateEmployeeAction.ADMIN)}
								> 	<StyledListItemIcon> <PersonIcon /> </StyledListItemIcon>
									<ListItemText primary={t('employee.create.adminPrimary')} secondary={t('employee.create.adminSecondary')} />
								</StyledMenuItemModal>
								{cardsAllowed && <StyledMenuItemModal
									id='employee'
									selected={action === CreateEmployeeAction.EMPLOYEE}
									onClick={() => handleMenuClick(CreateEmployeeAction.EMPLOYEE)}
								> 	<StyledListItemIcon> <GroupIcon /> </StyledListItemIcon>
									<ListItemText primary={t('employee.create.employeePrimary')} secondary={t('employee.create.employeeSecondary')} />
								</StyledMenuItemModal>
								}
							</MenuList>
						</Grid>
					</DialogContent>
					<DialogActions>
						<FormCloseButton onClose={closeModal} />
					</DialogActions>
				</>
			}
			{step === 1 && <>
				{action === CreateEmployeeAction.EMPLOYEE && <EmployeeInviteStep onBack={prevStep} />}
				{action === CreateEmployeeAction.ADMIN && <AdminInviteStep onBack={prevStep} />}
			</>}
		</Dialog >
	);
};

export default CreateEmployeeForm;
