import * as React from 'react';

import '@shared/Shared.css';
import '@shared/SharedFormLayout.css';

import {
	Alert,
	Button,
	Grid,
	TextField
} from '@mui/material';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { alpha3ToAlpha2, getName, getNames } from 'i18n-iso-countries';
import * as Yup from 'yup';
import { electronicFormatIBAN, isValidIBAN } from 'ibantools';
import { RootState } from '@/rootReducer';

import { AccountIntegration, BankAccount } from '@features/account/types';
import { getDataByIban } from '@features/contacts/slice';
import { Contact } from '@features/contacts/types';
import { BeneficiaryType, SystemType } from '@helpers/globalTypes';
import FormContentBox from '@shared/Widgets/Box/FormContentBox';
import MuiTextField from '@shared/FormikComponents/MuiTextField';
import ContactDropdown from '@shared/FormikComponents/ContactDropdown';
import ButtonStyleTabs from '@shared/Widgets/Tab/ButtonStyleTabs';
import ButtonStyleTab from '@shared/Widgets/Tab/ButtonStyleTab';
import NumberFormat from 'react-number-format';
import StyledDivider from '@shared/Widgets/StyledDivider';
import _ from 'lodash';
import { PaymentPayloadType, PaymentTargetType, UkPaymentValidationType, ValidationPayloadType } from '@/componentsMui/Type/PaymentPayloadType';
import CountryCodes from '@shared/FormikComponents/CountryCodes';
import { AccountType } from '@features/operations/types';
import useCloseDialog from '@helpers/customHook/useCloseDialog';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import { getUkPaymentValidation } from '@features/account/slice';
import FormLabel from '@shared/Components/SendMoney/PaymentForm/FormLabel';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { NEW_LINE_REGEX } from '@/helpers/customHook/useValidations';


type Props = {
	paymentType: SystemType,
	accountList: BankAccount[],
	defaultAccount?: BankAccount,
	defaultContact?: Contact,
	paymentPayload: PaymentPayloadType,
	paymentName: string,
	setPaymentPayload: (value: PaymentPayloadType) => void,
	nextStep?: () => void,
	prevStep?: () => void,
	prevStepMain?: () => void
	nextStepMain?: () => void
}

const getBeneficiaryType = (isBusiness: any) => isBusiness ? BeneficiaryType.BUSINESS : BeneficiaryType.INDIVIDUAL;
const allCharactersSame = (s) => { return s ? s.split('').every(c => c === s[0]) : false; };


const PaymentDetails = ({ paymentName,
	accountList,
	paymentType,
	defaultAccount,
	defaultContact,
	paymentPayload,
	setPaymentPayload,
	nextStep,
	prevStepMain,
	nextStepMain,
}: Props) => {

	const procType = 'PAYMENT';
	const { t } = useTranslation('translations');

	const allContacts = useSelector((state: RootState) => state.contacts.contacts);

	const contriesList = getNames('en');

	//accounts with same payment type
	const paymentAccounts = accountList.filter(acc => {
		if (acc.subProcesses) {

			return _.keys(acc.subProcesses).filter(k => acc?.subProcesses[k]?.processProperties?.paymentType === paymentType).length > 0;

		}
		else {
			return false;
		}
	});
	const paymentContacts = allContacts.filter(contact => contact.supportedPayments.includes(paymentType));

	const initAccount = paymentPayload.account ?? defaultAccount ?? paymentAccounts[0] ?? null;

	const [selectedAccount] = useState<BankAccount>(initAccount);
	const [contact, setContact] = useState<Contact>(paymentPayload.contact ?? defaultContact ?? null);
	const [beneficiaryData, setBenificiaryData] = useState<any>(paymentPayload.beneficiaryData ?? '');
	const [beneficiaryType, setBeneficiaryType] = useState<BeneficiaryType>(paymentPayload.contact ? getBeneficiaryType(paymentPayload?.isToBusiness) : getBeneficiaryType(contact?.business));
	const [iban, setIban] = useState<string>(paymentPayload?.iban ?? defaultContact?.account ?? '');
	const [ibanIsValid, setIbanIsValid] = useState<boolean>(true);
	const [ukPaymentValidation, setUkPaymentValidation] = useState<UkPaymentValidationType | null>(null);

	const [paymentTarget] = useState<PaymentTargetType>(paymentType === SystemType.UK_CHAPS || paymentType === SystemType.UK_FPS || paymentType === SystemType.SCAN ? PaymentTargetType.SORT_CODE : PaymentTargetType.IBAN);
	const systems = selectedAccount && Object.keys(selectedAccount?.subProcesses).filter(key => selectedAccount?.subProcesses[key].type === procType).map(key => selectedAccount?.subProcesses[key]);
	const process = systems?.find(s => s?.processProperties?.paymentType === paymentType);

	const isUsSwift = process?.proc === 'cj-pool-payment-swift' || process?.proc === 'cj-wallet-payment-swift';
	const showContact = selectedAccount?.type !== AccountType.TRUST;

	const initContactCountry = contact?.country ? { label: contriesList[alpha3ToAlpha2(contact?.country)], code: alpha3ToAlpha2(contact?.country) } : '';
	const closeModal = useCloseDialog();

	useEffect(() => {
		if (!beneficiaryData && contact) {
			handleBicSwift(contact, isUsSwift, contact.account, null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);




	const initialValues = {
		to: paymentPayload.to ?? contact?.name ?? '',
		iban: paymentPayload.iban ?? contact?.account ?? '',
		reference: paymentPayload.reference ?? '',
		bicswift: paymentPayload.beneficiaryData?.bic ?? contact?.bic ?? '',
		country: paymentPayload.usSwiftCountry ? paymentPayload.usSwiftCountry ?? '' : paymentPayload.country ?? initContactCountry ?? '',
		identification: paymentPayload.identification === '' ? (contact?.identification ?? '') : paymentPayload.identification,
		sortCode: paymentPayload.sortCode ?? contact?.sortCode ?? '',
		accountNumber: paymentPayload.accountNumber ?? contact?.accountNumber ?? ''
	};
	const isReferenceCharsValid = (value?: string) => {
		if (!value) {
			return false;
		}
		const regex = /^[ A-Za-z0-9-.&/]*$/;
		if (process.integration === AccountIntegration.MODULR) {
			return regex.test(value);
		}
		else { return true; }
	};


	const isModulrSepa = process.integration === AccountIntegration.MODULR && [SystemType.SEPA, SystemType.SEPA_INSTANT].includes(paymentType);
	const isModulrScan = process.integration === AccountIntegration.MODULR && [SystemType.SCAN].includes(paymentType);
	const maxRefLenght = isModulrScan ? 18 : 140;

	const validationSchema = Yup.object({
		...(paymentTarget === PaymentTargetType.IBAN ? {
			iban: Yup.string().required(t('form.validator.required')).test('iban',
				t('sendmoneyTranslation.data.invalidIban'),
				() => isUsSwift ? true : ibanIsValid),
			bicswift: Yup.string().test('bicswift',
				t('form.validator.required'),
				() => isUsSwift ? !!beneficiaryData?.bic : true)
		} :
			{
				sortCode: Yup.string().trim()
					.required(t('form.validator.required'))
					.matches(/^\d{6}$/, {
						message: _.capitalize(t('form.validator.minChars', { chars: '6' }).toString().trim()),
						excludeEmptyString: true
					}),

				accountNumber: Yup.string().trim()
					.required(t('form.validator.required'))
					.matches(/^\d{8}$/, {
						message: _.capitalize(t('form.validator.minChars', { chars: '8' }).toString().trim()),
						excludeEmptyString: true
					}),
			}),
		// to: Yup.string().required(t('form.validator.required'))
		// 	.matches(/^[A-Za-z\s]+$/, t('form.validator.latinOnly')),
		to: Yup.string()
			.required(t('form.validator.required'))
			.matches(
				beneficiaryType === BeneficiaryType.INDIVIDUAL ? /^([a-zA-Z-.' ])+$/ : /^([0-9a-zA-Z-.,()' ])+$/,
				t(beneficiaryType === BeneficiaryType.INDIVIDUAL ? 'form.validator.beneficiaryNamePrivate' : 'form.validator.beneficiaryNameBusiness')
			),
		reference: Yup.string().trim()
			.required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-./\s])+$/, t('form.validator.latinOnlyReference'))
			.test('reference', t('form.validator.newLine'), (value) => value ? value.search(NEW_LINE_REGEX) === -1 : true)
			.min(6, t('form.validator.minChars', { field: t('sendmoneyTranslation.data.reference'), chars: '6' }))
			.max(maxRefLenght, t('form.validator.maxAllowChars', { chars: maxRefLenght }))
			.test('reference', t('form.validator.minChars', { field: t('sendmoneyTranslation.data.reference'), chars: '6' }), (value) => value ? value.replace(/ /g, '').length >= 6 : true)
			.test('reference', t('form.validator.modulrReference').toString(), (value) => isReferenceCharsValid(value))
			.test('reference', t('form.validator.sameChar'), (value) => (isModulrSepa || isModulrScan) ? !allCharactersSame(value) : true),
		identification: Yup.string().
			test('identification',
				t('form.validator.required'),
				(value) => !(beneficiaryType === BeneficiaryType.BUSINESS && isEmpty(value))),
		country: Yup.object().nullable().test('country', t('form.validator.required'), (value) => isUsSwift ? !!value : true)
			.test('country', t('form.validator.countryNotSupported'), (value) => isUsSwift ? value?.code === 'US' : true)
	});

	const submit = async (formData, formikProps) => {
		const { setFieldError } = formikProps;
		try {
			const { to, iban, reference, identification, sortCode, accountNumber, country } = formData;

			if (!process?.proc) {
				setFieldError('to', t('sendmoneyTranslation.data.missingProcess'));
				return;
			}

			if (sortCode && accountNumber) {
				const validationPayload: ValidationPayloadType = {
					name: to,
					business: beneficiaryType === BeneficiaryType.BUSINESS,
					sortCode: sortCode,
					accountNumber: accountNumber,
					identification: identification,
				};
				const ukPaymentValidationResult = await getUkPaymentValidation(selectedAccount?.accountId, validationPayload);
				if (ukPaymentValidationResult) {
					if (!ukPaymentValidationResult.matched) {
						setUkPaymentValidation(ukPaymentValidationResult);
						return;
					}
				}
			}

			const payload: PaymentPayloadType = {
				...{
					amount: paymentPayload.amount,
					fee: paymentPayload.fee,
					youpay: paymentPayload.youpay,
				},
				...{
					to,
					iban,
					reference,
					identification,
					sortCode,
					accountNumber,
					contact, beneficiaryData,
				},
				...{
					proc: process?.proc,
					account: selectedAccount,
					countryCode: paymentTarget === PaymentTargetType.IBAN ? beneficiaryData.country : null,
					country: paymentTarget === PaymentTargetType.IBAN ? getName(beneficiaryData.country, 'en') : null,
					usSwiftCountry: paymentTarget === PaymentTargetType.IBAN ? country : null,
					isToBusiness: beneficiaryType === BeneficiaryType.BUSINESS,
					paymentTarget,
					isUsSwift
				}
			};

			setPaymentPayload(payload);
			if (parseFloat(`${payload.amount}`) >= paymentPayload.mandatoryAttachementAmount) {
				nextStep();
			} else {
				nextStepMain();
			}
			// nextStep();
		}
		catch (e) {
			console.log(e);
		}
	};

	const handleBicSwift = (
		contact: Contact,
		isUsSwift: boolean,
		iban: string,
		setFieldTouched?: any
	) => {
		if (!isUsSwift && paymentTarget === PaymentTargetType.IBAN) {


			if (!isValidIBAN(iban)) {
				setIbanIsValid(false);
				setBenificiaryData(null);
			} else {
				setIbanIsValid(true);
				const getIbanData = async () => {
					const beneficiaryData = await getDataByIban(
						iban.replace(/\s/g, '')
					);
					setBenificiaryData(beneficiaryData);

				};
				getIbanData();
			}
			if (setFieldTouched) {
				setTimeout(() => setFieldTouched('iban', true));
			}
		}
		else {
			setIbanIsValid(true);
			if (contact?.bic) {
				setBenificiaryData({ ...beneficiaryData, ...{ bic: contact?.bic ?? '' } });
			}
		}
	};

	const handleSelectFromContacts = (value: Contact, setFieldValue, setFieldTouched) => {
		const contact = paymentContacts.find(p => p.beneficiaryAccountId === value.beneficiaryAccountId);
		setContact(contact);

		if (paymentType === SystemType.UK_FPS || paymentType === SystemType.UK_CHAPS || paymentType === SystemType.SCAN) {
			const newSortCode = contact.sortCode ?? '';
			setFieldValue('sortCode', newSortCode);
			const newAccountNumber = contact.accountNumber ?? '';
			setFieldValue('accountNumber', newAccountNumber);
		} else {
			const newIban = contact.account.replace(/\s/g, '');
			setIban(newIban);
			setFieldValue('iban', newIban);
			handleBicSwift(contact, isUsSwift, newIban, setFieldTouched);
		}
		setFieldValue('to', contact.name);
		setFieldValue('identification', contact?.identification ?? '');
		setBeneficiaryType(contact.business ? BeneficiaryType.BUSINESS : BeneficiaryType.INDIVIDUAL);

		if (contact?.country) {
			const contactCountry = { label: contriesList[alpha3ToAlpha2(contact?.country)], code: alpha3ToAlpha2(contact?.country) };
			setFieldValue('country', contactCountry);
		}
		else {
			setFieldValue('country', '');
		}
		setTimeout(() => setFieldTouched('country', true));
	};

	const cleanContact = (setFieldValue) => {
		if (contact !== null) { setFieldValue('contact', null); setContact(null); }
	};

	const handleSortCodeChange = (event: React.ChangeEvent<any>, setFieldValue, setFieldTouched) => {
		setFieldValue('sortCode', event.target.value);
		setTimeout(() => setFieldTouched('sortCode', true));
	};

	const handleAccountNumberChange = (event: React.ChangeEvent<any>, setFieldValue, setFieldTouched) => {
		setFieldValue('accountNumber', event.target.value);
		setTimeout(() => setFieldTouched('accountNumber', true));
	};

	const handleNameChange = (event: React.ChangeEvent<any>, setFieldValue) => {
		cleanContact(setFieldValue);
		setFieldValue('to', event.target.value);
	};

	const handleIbanChange = (event: React.ChangeEvent<any>, setFieldValue) => {
		cleanContact(setFieldValue);
		const iban = event.target.value.replaceAll(' ', '');
		setFieldValue('iban', iban);
		setIban(iban);
	};

	const handleIbanOnBlur = (setFieldValue, setFieldTouched) => {
		const formatedIban = electronicFormatIBAN(iban).replace(/\s/g, '');
		setFieldValue('iban', formatedIban);
		setIban(formatedIban);
		handleBicSwift(contact, isUsSwift, formatedIban, setFieldTouched);
	};

	const handleBeneficiaryTypeChange = (
		newValue,
		setFieldTouched
	) => {
		const value = newValue;
		if (value != null) {
			setBeneficiaryType(value);
			//revalidate
			setTimeout(() => setFieldTouched('identification', true));
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={submit}
		>
			{({ errors, isSubmitting, values, setFieldValue, setFieldTouched }) => {
				return (
					<Form className='form-content__wrap__bb'>
						<FormContentBox noBackground={true} className='form-content_main-area wp'>
							<Grid container columnSpacing={3} rowSpacing={1}>
								<Grid item xs={12}>
									<FormHeader title={paymentName} onBack={prevStepMain} showBack={!!prevStepMain} mb={'0px'} />
								</Grid>
								{showContact && <Grid item xs={12} sm={6}>
									<ContactDropdown
										fieldName='contact'
										contactList={paymentContacts}
										selectedContact={contact}
										fieldlabel={t('sendmoneyTranslation.data.contact')}
										onContactChange={(value: Contact) => handleSelectFromContacts(value, setFieldValue, setFieldTouched)} />
								</Grid>}
								<Grid item xs={12}>
									<StyledDivider />
								</Grid>
								<Grid item xs={12}>
									<FormLabel>{t('contacts.create.beneficiaryType')}</FormLabel>
								</Grid>
								<Grid item xs={12} justifyContent={'start'}>
									<ButtonStyleTabs
										value={beneficiaryType}
										onChange={(event, newValue) => handleBeneficiaryTypeChange(newValue, setFieldTouched)}
									>
										<ButtonStyleTab value={BeneficiaryType.INDIVIDUAL} label={t('sendmoneyTranslation.data.individual')} />
										<ButtonStyleTab value={BeneficiaryType.BUSINESS} label={t('sendmoneyTranslation.data.business')} />
									</ButtonStyleTabs>
								</Grid>
								<Grid item xs={beneficiaryType === BeneficiaryType.BUSINESS ? 6 : 12}>
									<MuiTextField
										name="to"
										fullWidth
										inputProps={{ autoComplete: 'off' }}
										onChange={(event) => handleNameChange(event, setFieldValue)}
										label={t(beneficiaryType === BeneficiaryType.BUSINESS ? 'sendmoneyTranslation.data.businessBeneficiary' : 'sendmoneyTranslation.data.beneficiary')} />
								</Grid>
								{beneficiaryType === BeneficiaryType.BUSINESS && <Grid item xs={6}>
									<MuiTextField
										name="identification"
										fullWidth
										inputProps={{ autoComplete: 'off' }}
										label={t('sendmoneyTranslation.data.registrationNumber')} />
								</Grid>}
								{/* Enable when chaps will work via IBAN */}
								{/* {paymentType === SystemType.UK_CHAPS &&
								<Grid item xs={12}>
									<Tabs
										centered
										value={paymentTarget}
										onChange={(event, newValue) => handleToIbanChange(newValue, validateForm)}
									>
										<Tab sx={{ width: '50%' }} value={PaymentTargetType.IBAN} label={t('sendmoneyTranslation.data.iban')} />
										<Tab sx={{ width: '50%' }} value={PaymentTargetType.SORT_CODE} label={t('sendmoneyTranslation.data.sortCode')} />
									</Tabs>
								</Grid>} */}
								{paymentTarget === PaymentTargetType.IBAN ?
									<>
										<Grid item xs={12}>
											<MuiTextField
												name="iban"
												fullWidth
												value={iban}
												onChange={(event) => handleIbanChange(event, setFieldValue)}
												onBlur={() => handleIbanOnBlur(setFieldValue, setFieldTouched)}
												label={isUsSwift ? t('sendmoneyTranslation.data.accountNumber') : t('sendmoneyTranslation.data.iban')} />
										</Grid>
										<Grid item xs={12} sm={6}>
											<MuiTextField
												name="bicswift"
												fullWidth
												inputProps={{ autoComplete: 'off' }}
												value={beneficiaryData ? beneficiaryData.bic : ''}
												onChange={(e) => { setBenificiaryData({ ...beneficiaryData, ...{ bic: e.target.value } }); }}
												label={t('sendmoneyTranslation.data.bicswift')}
												disabled={!isUsSwift} />
										</Grid>
										<Grid item xs={12} sm={6}>
											{!isUsSwift ?
												<TextField
													name="country"
													fullWidth
													inputProps={{ autoComplete: 'off' }}
													value={beneficiaryData ? contriesList[beneficiaryData.country] : ''}
													label={t('sendmoneyTranslation.data.country')}
													disabled={true} /> :
												<CountryCodes
													fieldName='country'
													fieldlabel={t('sendmoneyTranslation.data.country')} />
											}
										</Grid>
									</> :
									<>
										<Grid item xs={12} sm={6}>
											<NumberFormat
												name="sortCode"
												customInput={MuiTextField}
												onChange={(event) => handleSortCodeChange(event, setFieldValue, setFieldTouched)}
												value={values.sortCode}
												decimalScale={0}
												displayType={'input'}
												fullWidth
												allowNegative={false}
												label={t('sendmoneyTranslation.data.sortCode')}
												inputProps={{ autoComplete: 'off', maxLength: 6 }}
												allowLeadingZeros={true}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<NumberFormat
												name="accountNumber"
												customInput={MuiTextField}
												onChange={(event) => handleAccountNumberChange(event, setFieldValue, setFieldTouched)}
												value={values.accountNumber}
												decimalScale={0}
												displayType={'input'}
												fullWidth
												allowNegative={false}
												label={t('sendmoneyTranslation.data.accountNumber')}
												inputProps={{ autoComplete: 'off', maxLength: 8 }}
												allowLeadingZeros={true}
											/>
										</Grid>
									</>}
								<Grid item xs={12} mt={1}>
									<MuiTextField
										name="reference"
										multiline
										fullWidth
										inputProps={{ autoComplete: 'off', maxLength: maxRefLenght }}
										label={t('sendmoneyTranslation.data.referenceWithMax', { max: `${maxRefLenght}` })}
										rows={2} />
								</Grid>
								{ukPaymentValidation &&
									<Grid item xs={12}>
										<Alert severity="warning"  >
											<MuiFormText >{t('errors.ukPaymentValidationErrors.' + ukPaymentValidation.falseMatchDetails.code, { name: ukPaymentValidation.falseMatchDetails.name } || t('errors.ukPaymentValidationErrors.OTHER_ERROR'))}</MuiFormText >
										</Alert>
									</Grid>
								}
							</Grid>
							<Grid container mt='auto' pt='2rem' columnSpacing={2}>
								<Grid item xs={6}>
									<Button
										variant='customOutlined'
										color='secondary'
										onClick={closeModal}
										sx={{ width: { xs: '100%', md: 'auto' } }}
									>  {t('form.buttons.close')}
									</Button>
								</Grid>
								<Grid item xs={6} container justifyContent='flex-end'>
									<Button disabled={isSubmitting || !isEmpty(errors) || !ibanIsValid}
										variant='contained'
										color='primary'
										type="submit"
										sx={{ width: { xs: '100%', md: 'auto' } }}
									>
										{t('form.buttons.next')}
									</Button>
								</Grid>
							</Grid>
						</FormContentBox>
					</Form>
				);
			}}
		</Formik >);
};

export default PaymentDetails;
