import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { Statistics, StatsStore } from './statisticsTypes';
import { Subscription } from 'rxjs';
import { AppDispatch } from '@/store';
import { SocketService } from '@/services/socketService';

const initialState: StatsStore = {
  loading: false,
  error: null,
  statistics: null,
};

const slice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setStatistics(state, action: PayloadAction<Statistics>) {
      state.statistics = action.payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state, { payload }: PayloadAction<string | null>) => {
      state.error = payload;
    },
  },
});

export const { setStatistics, setLoading, setError } = slice.actions;

export const getStatistics = (ccy: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(setError(null));
      const response = await request.get(`/api-mobile/stats/all/${ccy}`);
      const { data } = response;
      dispatch(setStatistics(data.statistics));
      return data;
    } catch (e) {
      dispatch(setError('failed'));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

let socketService: SocketService | null;
let statsSubscriber: Subscription;

export const connectStatistics = (): void => {
  if (!socketService) {
    socketService = new SocketService('stats');
  }
};

export const disconnectStatistics = (): void => {
  socketService = null;
};

export const subscribeStatistics = () => {
  return async (dispatch: AppDispatch) => {
    if (!socketService) {
      return;
    }
    try {
      statsSubscriber = socketService
        .listen('stats', {})
        .subscribe((data: any) => {
          if (data === 'updated') {
            dispatch(getStatistics('EUR'));
          }
        });
    } catch (e: any) {
      console.log(e);
      dispatch(setError(e));
    }
  };
};

export const unsubscribeStatistics = (): void => {
  statsSubscriber.unsubscribe();
};

export default slice.reducer;
