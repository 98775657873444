import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Button, Divider, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import BackButton from '../Wallet/Components/BackButton';
import { Trans, useTranslation } from 'react-i18next';
import { CreateInvoicePayload, InvoiceProduct, InvoiceRecipient, PaymentTypes } from '@/features/invoices/invoicesTypes';
import InvoiceRecipientBox from './components/InvoiceRecipientBox';
import PaymentOptionsBox from './components/PaymentOptionsBox';
import ProductsBox from './components/ProductsBox';
import { roundHalfDown } from '@/helpers/amount';
import NumberFormat from 'react-number-format';
import { getCurrencySign } from '@/helpers/currency';
import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoicesConfig, saveInvoice } from '@/features/invoices/invoicesSlice';
import SaveInvoiceDialog from './components/SaveInvoiceDialog';
import { useHistory } from 'react-router-dom';
import { showErrorNotification, showSuccess } from '@/features/swal/slice';

const CreateInvoice = () => {
    const { t } = useTranslation('translations');
    const dispatch = useDispatch();
    const history = useHistory();
    const { config } = useSelector((state: RootState) => state.invoices);
    const [saveType, setSaveType] = useState<'draft' | 'issue'>('draft');
    const [openSaveDialog, setOpenSaveDialog] = useState<boolean>(false);
    const [invoiceRecipient, setInvoiceRecipient] = useState<InvoiceRecipient | null>(null);
    const [paymentOptions, setPaymentOptions] = useState<PaymentTypes[]>([]);
    const [invoiceProducts, setInvoiceProducts] = useState<InvoiceProduct[]>([]);
    const [payload, setPayload] = useState<CreateInvoicePayload>();

    useEffect(() => {
        dispatch(getInvoicesConfig());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isPaymentOptionsSelected = paymentOptions.length > 0;
    const isProductAdded = invoiceProducts.length > 0;

    const { totalWithoutTax, totalTax, totalAmount } = useMemo(() => {
        return invoiceProducts.reduce(
            (totals, item) => {
                const tax = item.taxRate ? item.price * item.taxRate : 0;
                const itemTotalWithoutTax = item.price * item.quantity;
                const itemTotalTax = tax * item.quantity;
                const itemTotalWithTax = itemTotalWithoutTax + itemTotalTax;

                totals.totalWithoutTax += roundHalfDown(itemTotalWithoutTax);
                totals.totalTax += roundHalfDown(itemTotalTax);
                totals.totalAmount += roundHalfDown(itemTotalWithTax);

                return totals;
            },
            { totalWithoutTax: 0, totalTax: 0, totalAmount: 0 }
        );
    }, [invoiceProducts]);

    const isTotalMoreThanMinOrderAmount = totalAmount >= config?.minOrderAmount ?? 0;
    const isErrorsExist = !isPaymentOptionsSelected || !isProductAdded || !isTotalMoreThanMinOrderAmount;

    const handleSaveInvoice = async (type: 'draft' | 'issue') => {
        const _payload: CreateInvoicePayload = {
            paymentTypes: paymentOptions,
            products: invoiceProducts,
            recipient: invoiceRecipient,
        };

        if (type === 'draft') {
            try {
                const orderResponse = await saveInvoice(type, _payload);
                onSave(orderResponse);
            } catch (err) {
                showErrorNotification(err);
            }
        } else {
            setPayload(_payload);
            setSaveType(type);
            setOpenSaveDialog(true);
        }

    };

    const onSave = async (response: any) => {
        const isDraft = saveType === 'draft';
        setOpenSaveDialog(false);
        await showSuccess({
            title: isDraft ? 'invoice.orderCreated' : 'invoice.orderSubmitted',
            text: t(`invoice.${isDraft ? 'orderCreatedText' : 'orderSubmittedText'}`, {
                number: response.orderNumber,
            }), confirmBTNText: t('invoice.returnToList'), mui: true
        });
        history.goBack();
    };

    return (
        <Grid container spacing={2}>
            {openSaveDialog && <SaveInvoiceDialog
                open={openSaveDialog}
                onClose={() => setOpenSaveDialog(false)}
                onSave={onSave}
                payload={payload}
                type={saveType}
            />}
            <Grid item xs={12}>
                <Grid item>
                    <BackButton />
                </Grid>
            </Grid>

            {isErrorsExist ? <Grid item xs={12}>
                <Alert severity="warning" icon={false} sx={{ flexDirection: 'row' }}>
                    <List disablePadding>
                        {!isPaymentOptionsSelected && <ListItem disablePadding>
                            <ListItemText primary={t('invoice.atLeastOnePaymentOptionSelected')} />
                        </ListItem>}
                        {!isProductAdded && <ListItem disablePadding>
                            <ListItemText primary={t('invoice.atLeastOneProductAdded')} />
                        </ListItem>}
                        {!isTotalMoreThanMinOrderAmount && <ListItem disablePadding>
                            <ListItemText primary={<Trans i18nKey='invoice.minOrderAmount'
                                t={t}
                                components={{
                                    amount: <NumberFormat
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        thousandsGroupStyle='thousand'
                                        thousandSeparator
                                        prefix={getCurrencySign('EUR')}
                                        value={config?.minOrderAmount ?? 0} />
                                }}
                            />} />
                        </ListItem>}
                    </List>
                </Alert>
            </Grid> : <Grid item xs={12} container justifyContent='flex-end'>
                <Button variant="simple" sx={{ mr: 0.5 }} onClick={() => handleSaveInvoice('draft')}>
                    {t('invoice.saveAsDraft')}
                </Button>
                <Button variant="contained" onClick={() => handleSaveInvoice('issue')}>
                    {t('invoice.submit')}
                </Button>
            </Grid>}
            <Grid item xs={12} md={6}>
                <InvoiceRecipientBox
                    onChange={(recipient) => setInvoiceRecipient(recipient)}
                    recipient={invoiceRecipient}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <PaymentOptionsBox
                    onChange={(value) => setPaymentOptions(value)}
                    selectedPayments={paymentOptions}
                />
            </Grid>
            <Grid item xs={12}>
                <ProductsBox
                    onAdd={(product) => setInvoiceProducts(prev => [...prev, product])}
                    onEdit={(product) => setInvoiceProducts(prev => prev.map((item) => (item.uuid === product.uuid ? product : item)))}
                    onRemove={(product) => setInvoiceProducts(prev => prev.filter((item) => item.uuid !== product.uuid))}
                    products={invoiceProducts}
                    productsEnabled={true}
                />
            </Grid>

            <Grid item xs={12} container sx={theme => ({
                position: 'sticky',
                bottom: 0,
                background: theme.palette.background.paper,
                padding: '20px',
                marginLeft: '16px',
                marginTop: '16px',
                borderRadius: '16px',
            })}
                gap={1}
            >
                <Grid item xs={12} container justifyContent='space-between'>
                    <Typography variant="body2" mr={1}>{t('invoice.subTotal')}</Typography>
                    <Typography variant="body2" color='text.secondary'>
                        <NumberFormat
                            displayType={'text'}
                            decimalScale={2}
                            fixedDecimalScale
                            thousandsGroupStyle='thousand'
                            thousandSeparator
                            prefix={getCurrencySign('EUR')}
                            value={totalWithoutTax} />
                    </Typography>
                </Grid>
                <Grid item xs={12} container justifyContent='space-between'>
                    <Typography variant="body2" mr={1}>{t('invoice.tax')}</Typography>
                    <Typography variant="body2" color='text.secondary'>
                        <NumberFormat
                            displayType={'text'}
                            decimalScale={2}
                            fixedDecimalScale
                            thousandsGroupStyle='thousand'
                            thousandSeparator
                            prefix={getCurrencySign('EUR')}
                            value={totalTax} />
                    </Typography>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={12} container justifyContent='space-between'>
                    <Typography variant="h5" mr={1}>{t('invoice.totalAmount')}</Typography>
                    <Typography variant="h5" color='primary'>
                        <NumberFormat
                            displayType={'text'}
                            decimalScale={2}
                            fixedDecimalScale
                            thousandsGroupStyle='thousand'
                            thousandSeparator
                            prefix={getCurrencySign('EUR')}
                            value={totalAmount} />
                    </Typography>
                </Grid>
            </Grid>

        </Grid >
    );
};

export default CreateInvoice;

