import * as React from 'react';

import { Button, Dialog, Grid, IconButton, Theme, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import DialogContentNoScrollbar from '@/componentsMui/Shared/Widgets/Dialog/DialogContentNoScrollbar';
import { useTranslation } from 'react-i18next';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { BankAccount } from '@/features/account/types';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { AccountType } from '@/features/operations/types';
import { getAccountName } from '@/helpers/getAccountName';
import { Fiats } from '@/componentsMui/Type/Fiat';
import FiatAvatar from '@/componentsMui/Shared/Widgets/Avatars/FiatAvatar';
import { getCurrencySign } from '@/features/fees/slice';
import NumberFormat from 'react-number-format';
import MuiTextLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextLabel';
import { hiddenBabalance } from '@/helpers/placeholders';

interface Props {
	open: boolean,
	type: AccountType,
	onClose: () => void,
	onChooseAccount: (account: BankAccount) => void,
}

const SwitchAccountForm = ({ open, type, onClose, onChooseAccount }: Props) => {
	const { t } = useTranslation('translations');
	const smallScreen = useMediaQuery('(max-width:600px)');
	const theme = useTheme() as Theme;
	const lightSelected = theme.palette.mode === 'light';
	const { list } = useSelector((state: RootState) => state.accounts);
	const { user } = useSelector((state: RootState) => state.user);

	const { currentAccount } = useSelector(
		(state: RootState) => state.tabs
	);
	const accounts = list.filter(p => p.type.includes(type));

	const getName = (bankAccount: BankAccount) => {
		return getAccountName(t, bankAccount);
	};

	const getFiatAvatar = (currency: string) => {
		return Fiats.find(f => f.currency === currency);
	};


	return (
		<Dialog
			id="switch-account-form"
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
		>
			<DialogContentNoScrollbar>
				<Grid
					container
					sx={{p: { xs: '1rem', md: '2rem' }}}
				>
					<Grid item container justifyContent="space-between" alignItems="center" pb={4}>
						<Grid item>
							<MuiHeader>{t('banking.data.accounts')}</MuiHeader>
						</Grid>
						<Grid item>
							<IconButton onClick={onClose}  >
								<MuiCloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item container spacing={1}>
						{accounts.map(account => (
							<Grid item key={account.accountId} xs={12} >
								<Button variant={account.accountId === currentAccount.accountId ? 'contained' : 'simple'} sx={{p: { xs: 1, sm: 2 }}} onClick={() => onChooseAccount(account)} style={{ width: '100%' }}>
									<Grid container alignItems='center' sx={{py: { xs: 1, sm: 0 }}}>
										<Grid item xs={2}>
											<FiatAvatar fiat={getFiatAvatar(account.currency)} variant={smallScreen ? 'medium' : 'large'} showOnlyAvatar={true} logoSource={account.currencyLogo} />
										</Grid>
										<Grid item xs={5} md={7} display='flex' alignItems='flex-start' flexDirection='column'>
											<div key={account.accountId}>{getName(account)}</div>
											<MuiTextLabel style={{ opacity: lightSelected ? 0.6 : 0.4 }} color='text.primary'>{account.currency}</MuiTextLabel>
										</Grid>
										<Grid item xs={5} md={3}>
											{user.hideValues ? hiddenBabalance :
												<NumberFormat
													displayType={'text'}
													decimalScale={2}
													fixedDecimalScale={true}
													thousandsGroupStyle='thousand'
													thousandSeparator={true}
													prefix={`${getCurrencySign(account.currency)} `}
													value={account.availableBalance}
												/>}
										</Grid>
									</Grid>

								</Button>
							</Grid>
						))}
					</Grid>
				</Grid>
			</DialogContentNoScrollbar>
		</Dialog >
	);
};

export default SwitchAccountForm; 
