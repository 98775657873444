import * as React from 'react';

import { Button, Dialog, Grid, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCloseDialogGoCards } from '@/helpers/customHook/useCloseDialog';
import DialogContentNoScrollbar from '@/componentsMui/Shared/Widgets/Dialog/DialogContentNoScrollbar';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { isEmpty } from 'lodash';
import { runOperation } from '@/features/operations/slice';
import { getErrorWithParams, showException, showSuccess } from '@/features/swal/slice';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { requestVfCode } from '@/features/card/slice';
import MuiTextLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextLabel';

interface Props {
    cardId: string,
    isVirtual: boolean,
    operation: string,
    cardStatus: string
}

const InactiveVfCodeForm = ({ cardId, isVirtual, operation, cardStatus }: Props) => {

    const { t } = useTranslation('translations');
    const closeModal = useCloseDialogGoCards();

    const [VfCode, setVfCode] = React.useState<string>('');
    const [sendCode, setSendCode] = React.useState<boolean>(false);
    const WAIT_TIME = 10;
    const [countDown, setCountDown] = React.useState(WAIT_TIME);
    const countDownRef = React.useRef(countDown);
    const initialValues = { activationCode: '' };
    const validationSchema = isVirtual ?
        Yup.object({
            activationCode: Yup.string().required(t('form.validator.required')),
        }) :
        Yup.object({
            activationCode: Yup.string().required(t('form.validator.required')),
            cvc2: Yup.string().trim().required(t('form.validator.required')).min(3, t('form.validator.exactDigits', { number: 3 })).max(3, t('form.validator.exactDigits', { number: 3 }))
        });



    const executeCardVfCode = async () => {
        try {
            await requestVfCode(cardId);
            countDownRef.current = WAIT_TIME;
            setCountDown(WAIT_TIME);
            setSendCode(true);
        }
        catch (e) {
            await showException(e);
        }
    };

    React.useEffect(() => {

        const timerId = setInterval(() => {
            if (!sendCode) { return; }
            countDownRef.current -= 1;
            if (countDownRef.current <= 0) {
                clearInterval(timerId);
                setSendCode(false);

            }
            else {
                setCountDown(countDownRef.current);
            }
        }, 1000);
        return () => { clearInterval(timerId); };
    }, [sendCode]);

    const activate = async (activationCode: string, setFieldError: any, setFieldValue: any, setFieldTouched: any, cvc2?: string) => {
        try {
            const payload = { cardId, verificationCode: activationCode, cvv: cvc2 };
            await runOperation(operation, payload);
            await showSuccess({
                title: 'cards.cardActivation.activated', mui: true
            });
            closeModal();
        } catch (e: any) {
            const err = getErrorWithParams(e);
            if (err && ['verificationCode', 'USER_INV_VERIFICATION_CODE'].includes(err.error_param)) {
                setFieldError('activationCode', t('errors.VERIFICATION_CODE'));
                return;
            }

            if (err && [ 'USER_INV_CVC2_CODE'].includes(err.error_param)) {
                setFieldError('cvc2', t('errors.USER_INV_CVC2_CODE'));
                return;
            }

            await showException(e);
        }
    };

    const onSubmit = async (formData, formikProps) => {
        try {
            const { activationCode, cvc2 } = formData;
            const { setFieldError, setFieldValue, setFieldTouched, setSubmitting } = formikProps;
            setSubmitting(true);
            await activate(activationCode, setFieldError, setFieldValue, setFieldTouched, cvc2), setFieldValue;
            setSubmitting(false);
        }
        catch (e) {
            console.log('hmm');
            console.log(e);
        }
    };

    const handleChange = (e, setFieldValue) => {
        const newValue: string = e.target.value;
        const re = /^[0-9]+$/;
        if (newValue === '' || re.test(newValue)) {
            setVfCode(newValue);
            setFieldValue('activationCode', newValue);
        }
    };


    return (
        <Dialog
            id="inactive-card-form"
            open={true}
            onClose={closeModal}
            fullWidth
            maxWidth='sm'>
            <DialogContentNoScrollbar>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ errors, isSubmitting, dirty, setFieldValue, setFieldTouched }) => {
                        return (
                            <Form>
                                <Grid
                                    container
                                    padding='2.5rem'
                                    spacing={3}
                                >
                                    <Grid item xs={12} container justifyContent='space-between' alignItems='center' >
                                        <Grid item>
                                            <MuiHeader pb={2}>{t('cards.inactive.modal.header')}</MuiHeader>
                                        </Grid>
                                        <Grid item>
                                            <IconButton onClick={closeModal}  >
                                                <MuiCloseIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    {!isVirtual ? <>
                                        <Grid item xs={12} >
                                            <MuiFormText display='inline'>
                                                {t('cards.inactive.modal.activatePhysicalCVV')}
                                            </MuiFormText>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MuiTextField
                                                name="cvc2"
                                                inputProps={{ autoComplete: 'off', maxLength: 3 }}
                                                fullWidth
                                            />
                                        </Grid>
                                    </> : null}
                                    {cardStatus === 'INACTIVE' ?
                                        <>
                                            <Grid item xs={12} >
                                                <MuiFormText display='inline'>
                                                    {t('cards.inactive.modal.activateVfCode')}
                                                </MuiFormText>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <MuiTextField
                                                    name="activationCode"
                                                    inputProps={{ autoComplete: 'off', maxLength: 6 }}
                                                    label={t('cards.inactive.modal.verificationCode')}
                                                    fullWidth
                                                    onChange={(e) => { handleChange(e, setFieldValue); setFieldTouched('activationCode'); }}
                                                    value={VfCode}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid item container alignItems='center' xs={12} columnGap={3}>
                                                    <Button
                                                        key='activate'
                                                        type='button'
                                                        onClick={executeCardVfCode}
                                                        variant='contained'
                                                        disabled={sendCode}>{t('cards.cardInfo.getVfCode')}
                                                    </Button>
                                                    {sendCode && <MuiTextLabel>{t('cards.resendVfCode', { sec: countDown })}</MuiTextLabel>}
                                                </Grid>
                                            </Grid>

                                        </> : null}

                                    <Grid item xs={12}>
                                        <Button
                                            fullWidth
                                            key='activate'
                                            type='submit'
                                            disabled={isSubmitting || !isEmpty(errors) || !dirty}
                                            variant='contained'> 	{t('form.buttons.submit')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik >
            </DialogContentNoScrollbar>
        </Dialog >
    );
};


export default InactiveVfCodeForm;

