export interface TwoFAPayload {
	open: boolean,
	originalRequest: any
}

export interface LoginTokenPayload {
	code: number,
	deviceInfo: {
		browser: string,
		platform: string,
		hash?: string
	},
	grantType?: string
}



export interface QrDataPayload {
	code: number,
	expired: string,
	timeZone: string
}

export enum TokenErrorType {
	NOT_FOUND = 'notFound',
	EXPIRED = 'expired',
	NOT_APPROVED = 'notApproved',
	INVALID_USER = 'invalid',
	INVALID_DEVICE = 'device_info_invalid'
}


export enum ConfirmType {
	SMS = 'SMS',
	EMAIL = 'EMAIL',
	NONE = ''
}

export const CHANGE_2FA = 'CHANGE_2FA';
export const CANCEL_2FA = 'Operation canceled by the user.';
