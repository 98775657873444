import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import { Operations } from './types';
import request from '../../services/request';
import _ from 'lodash';
import { BankAccount } from '../account/types';
import { SystemType } from '@/helpers/globalTypes';

const slice = createSlice({
	name: 'operations',
	initialState: { operations: [], loading: false },
	reducers: {
		setOperations(state, action: PayloadAction<Array<Operations>>) {
			state.operations = action.payload;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		}
	}
});

export const { setOperations, setLoading } = slice.actions;

export const operationsSelector = (state: { operationsSelector: Array<Operations> }) =>
	state;

export const getOperations = (): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/operations');
			const { data } = response;
			dispatch(setOperations(data));
			return data;
		} catch (e) {
			// TODO catch error
			console.log(e);
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const getOperationsByType = async (type: string) => {
	const response = await request.get('/api/operations/' + type);
	const { data } = response;
	return data;
};

export const runOperation = async (operation: string, payload: any) => {
	const response = await request.post('/api/operations/', { operation, data: payload });
	const { data } = response;
	return data;
};

export const ixopayComplete = async (activityId: string) => {
	const response = await request.patch(`/api/operations/ixopay/complete/${activityId}`);
	const { data } = response;
	return data;
};

/* returns supbrocess of ceratin type, usage exmaple :
const subProcess = tranformSubProcessByType(payload?.token?.subProcesses)['TRANSFER'];  */
export const tranformSubProcessByType = (subProcesses: any) => {
	return _.mapKeys(subProcesses, (value) => {
		return value.type;
	}) || {};
};

//check if suprocesses conatines supbrocess with certain type
export const hasSubprocessWithType = (procType: string, subProcesses: any) => {
	if (subProcesses) {
		const tmp = _.keys(subProcesses).filter(key => subProcesses[key].type === procType);
		return tmp.length > 0;
	}
	else return false;
};
//check if suprocesses contains Payment type  
export const hasSubprocessWithPaymentType = (paymentType: string, subProcesses: any) => {
	if (subProcesses) {
		return _.keys(subProcesses).find(key => subProcesses[key].processProperties?.paymentType === paymentType);
	}
	else return false;
};

export const findPaymentProcess = (account: BankAccount, paymentType: SystemType) => {
	return account && Object.keys(account?.subProcesses)
		.filter(key => account?.subProcesses[key].type === 'PAYMENT')
		.map(key => account?.subProcesses[key])?.find(s => s?.processProperties?.paymentType === paymentType) || null;
};

//Returns first subprocces  with  passed TYPE 
export const findProcessByType = (account: any, processType: string) => {
	return account?.subProcesses ? _.keys(account.subProcesses)
		.filter(key => account.subProcesses[key].type === processType)
		.map(key => account.subProcesses[key])[0] : null;
};

//Returns  ALL subprocesses with  type='PAYMENT'
export const findPaymentTypes = (account: any) => {
	return account?.subProcesses ? _.keys(account.subProcesses)
		.filter(key => account.subProcesses[key].type === 'PAYMENT')
		.map(key => account.subProcesses[key]) : [];
};

export default slice.reducer;
