import { styled } from '@mui/styles';
import { Tab, TabProps } from '@mui/material';
import * as React from 'react';

const TabStyled = styled((props: TabProps) => <Tab disableRipple {...props} />)(
	() => ({
		backgroundColor: 'transparent',
		borderRadius: '10px',
		padding: '10px',
		lineHeight: '9px',
		fontSize: '11px',
		'&.MuiButtonBase-root.Mui-selected': {
			backgroundColor: 'rgba(255, 255, 255, 0.06)',
			minHeight: '29px',
			minWidth: 'unset'
		},
		'&.MuiButtonBase-root': {
			minHeight: '29px',
			minWidth: 'unset'
		},
	}),
);

export default TabStyled;
