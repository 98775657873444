import * as React from 'react';
import { SvgIcon } from '@mui/material';

const MenuMyAccount = (props) => (
    <SvgIcon {...props} >
        <svg width="22" height="22" viewBox="0 0 22 22" fill="current" stroke="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 0C7.35 0 4.39 2.74 4.39 6.118c0 3.38 2.96 6.119 6.61 6.119 3.65 0 6.61-2.74 6.61-6.119C17.61 2.74 14.65 0 11 0zM6.36 6.118c0-2.372 2.077-4.296 4.64-4.296 2.563 0 4.64 1.924 4.64 4.296 0 2.373-2.077 4.296-4.64 4.296-2.563 0-4.64-1.923-4.64-4.296zM11 13.669c-4.62 0-8.789 1.745-10.603 4.477-.65.98-.446 2.02.208 2.75.619.691 1.617 1.104 2.66 1.104h15.47c1.043 0 2.041-.413 2.66-1.103.654-.731.858-1.771.208-2.751-1.814-2.732-5.983-4.477-10.603-4.477zm-8.925 5.43C3.41 17.089 6.799 15.492 11 15.492c4.201 0 7.59 1.597 8.925 3.609.1.15.112.259.104.335a.54.54 0 01-.15.299c-.212.236-.634.443-1.144.443H3.265c-.51 0-.932-.207-1.144-.443a.541.541 0 01-.15-.299c-.008-.076.005-.186.104-.335z"
                fill="current"
            />
        </svg>
    </SvgIcon>
);

export default MenuMyAccount;
