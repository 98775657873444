import { useState } from 'react';
import * as Yup from 'yup';


const schema = Yup.object().shape({
	passwordLength: Yup.string().min(8, 'len'),
	passwordUpper: Yup.string().matches(
		/^(?=.*[a-z])(?=.*[A-Z])/,
		'upper'
	),
	passwordSpecial: Yup.string().matches(
		/^(?=.*[0-9])(?=.*[@$!%*#?&])/,
		'spec'
	)
});

export const usePasswordValidation = () => {
	const [errors, setErrors] = useState<Array<string>>([]);

	const validatePassword = async (value: string) => {
		const error = [];
		try {
			await schema.validate({ passwordLength: value });
		}
		catch (err) {
			error.push('len');
		}
		try {
			await schema.validate({ passwordUpper: value });
		}
		catch (err) {
			error.push('upper');
		}
		try {
			await schema.validate({ passwordSpecial: value });
		}
		catch (err) {
			error.push('spec');
		}
		setErrors(error);
	};

	return [errors, validatePassword, setErrors] as const;
};


