import * as React from 'react';

import '@/componentsMui/Shared/Shared.css';

import {
	Alert,
	Button, FormControl, Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	TextField,
	Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useState } from 'react';
import { BankAccount } from '@/features/account/types';
import { Card, CardIntegrationEnum, CardSubProcesses } from '@/features/card/types';
import './TopUpDetails.css';
import useCloseDialog from '@/helpers/customHook/useCloseDialog';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import useAllBankAccounts from '@/helpers/customHook/accounts/useAllBankAccounts';
import useTrustAccounts from '@/helpers/customHook/accounts/useTrustAccounts';
import { maxTwoDecimals } from '@/helpers/number';
import StyledSelect from '@/componentsMui/Shared/Widgets/StyledSelect';
import { getCurrencySign } from '@/features/fees/slice';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import NumberFormat from 'react-number-format';
import FormDisplayField from '@/componentsMui/Shared/Widgets/FormDisplayField';
import useFeeDebounce from '@/helpers/customHook/useFeeDebounce';
import CardImage from '../CardDetails/CardImage';
import { findProcessByType } from '@/features/operations/slice';
import MuiTextAlert from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAlert';

export interface CardTopupPayloadType {
	fromAccount?: BankAccount,
	toCard?: Card,
	amount?: number,
	fee?: number,
	operation?: string
}

export const initCardTopupPayload: CardTopupPayloadType =
{
	fromAccount: null,
	toCard: null,
	amount: 1,
	fee: 0,
	operation: null
};

type Props = {
	card: Card,
	payload?: CardTopupPayloadType,
	setPayload: (value: CardTopupPayloadType) => void,
	nextStep: () => void
}

const TopUpDetails = ({ card, payload, setPayload, nextStep }: Props) => {

	const { t } = useTranslation('translations');
	const onClose = useCloseDialog();

	const { accountList: bankAccountList } = useAllBankAccounts(card?.currency);
	const { accountList: trustAccountList } = useTrustAccounts(card?.currency);
	const allAccounts = [...bankAccountList, ...trustAccountList];

	const filteredAccounts = allAccounts.filter(p => p.currency === card?.currency && p.status === 'ACTIVE');
	const [fromAccount, setFromAccount] = useState<BankAccount>(payload.fromAccount ??
		(filteredAccounts.length > 0 ? filteredAccounts[0] : null));
	const [amount, setAmount] = useState<number>(payload.amount ?? 1);

	const { fee, callGetFee } = useFeeDebounce(0);
	const process = findProcessByType(card?.process, CardSubProcesses.CARD_TOP_UP);

	const operation = process?.proc;
	const minAmount = process?.config?.minTransactionAmount ?? 0.01;
	const maxAmount = process?.config?.maxTransactionAmount || undefined;
	const maxTopupCount = process?.config?.maxTopupCount;


	const youpay = amount + fee;

	const isShCard = card?.process?.integration === CardIntegrationEnum.SH_FINANCE;
	const currencySign = getCurrencySign(fromAccount.currency) || '';

	const initialValues = {
		amount: payload.amount ?? 1
	};

	const validationSchema = Yup.object({
		amount: Yup.number()
			.typeError(t('form.validator.required'))
			.label(t('sendmoneyTranslation.data.transferamount'))
			.required(t('form.validator.required'))
			.min(minAmount, t('form.validator.minNumber', { field: t('cards.topUpCard.topUp'), amount: `${currencySign} ${minAmount}` }))
			.test(
				'amount',
				t('cards.maxTopupAmount', { amount: `${currencySign} ${maxAmount}` }),
				(value) => (value || 0) <= (maxAmount || value)
			)

			.test('amount', t('sendmoneyTranslation.data.insufficient'), (value) => value <= (fromAccount?.availableBalance ?? 0))
			.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value)),
	});

	const submit = (formData) => {
		const { amount } = formData;
		const payload: CardTopupPayloadType = { amount: parseFloat(amount as any), fromAccount, toCard: card, operation, fee };
		setPayload(payload);
		nextStep();
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: submit,
	});

	const handleFromAccountChange = (event: any) => {
		setFromAccount(filteredAccounts.find(p => p.accountId === event.target.value));
		setTimeout(() => formik.setFieldTouched('amount', true));
	};

	React.useEffect(() => {
		callGetFee(fromAccount.accountId, operation, amount);
	}, [amount, operation, fromAccount, callGetFee]);

	React.useEffect(() => {
		if (formik.values.amount > 0) {
			const parsed = parseFloat(formik.values.amount as any);
			const newAmount = isNaN(parsed) ? 0 : parsed;
			setAmount(newAmount);
		}
	}, [formik.values]);

	const mainContent = card ?
		<Grid id='paymentDetails' container spacing={2}>
			<Grid item xs={12}>
				<MuiHeader>{t('cards.topUpCard.topUp')}</MuiHeader>
			</Grid>
			<Grid item container xs={12}>
				<Grid item xs={8} sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
					<CardImage card={card} hideText={isShCard} />
				</Grid>
			</Grid>
			<Grid item xs={12} sm={6}>
				<FormControl id="from" fullWidth>
					<InputLabel>{t('form.fields.from')}</InputLabel>
					<StyledSelect
						id="from"
						name="from"
						value={fromAccount?.accountId ?? ''}
						onChange={handleFromAccountChange}
						renderValue={() => <div> {`${fromAccount.name} (${currencySign}${fromAccount.availableBalance})`}</div>}
					>
						{filteredAccounts.length > 0 &&
							filteredAccounts.map((account) => {
								return (
									<MenuItem
										key={account.accountId}
										value={account.accountId}>
										<div>
											<div className='select-primary-Item'>{account.name} </div>
											<div className='select-hide-Item'>{currencySign} {account.availableBalance} </div>
										</div>
									</MenuItem>
								);
							})}
					</StyledSelect>
				</FormControl>
			</Grid>
			<Grid item xs={12} sm={6} >

				{!isShCard && <FormControl id="from" fullWidth>
					<InputLabel>{t('form.fields.to')}</InputLabel>
					<StyledSelect
						id="to"
						name="to"
						value={card.cardId}
						readOnly={true}
						renderValue={() => <Typography style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}> {`${card.name} (${getCurrencySign(card.currency)}${card.availableBalance})`}</Typography>}
					>

					</StyledSelect>
				</FormControl>}

			</Grid>
			<Grid item xs={12} sm={6}>
				<NumberFormat
					name="amount"
					customInput={TextField}
					displayType={'input'}
					placeholder={'0.00'}
					value={formik.values.amount}
					onChange={formik.handleChange}
					inputProps={{ autoComplete: 'off' }}
					label={t('cards.topUpCard.amount')}
					allowedDecimalSeparators={['.']}
					allowNegative={false}
					decimalScale={2}
					fullWidth
					error={formik.errors.amount && Boolean(formik.touched.amount)}
					helperText={formik.errors.amount}
					InputProps={{
						startAdornment: <InputAdornment position="start">{card?.currencySymbol}</InputAdornment>,
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<FormDisplayField label={t('sendmoney.data.youpay')}>
					<NumberFormat
						displayType={'text'}
						decimalScale={2}
						fixedDecimalScale={true}
						thousandsGroupStyle='thousand'
						thousandSeparator={true}
						prefix={`${card?.currencySymbol} `}
						value={youpay} />
				</FormDisplayField>
			</Grid>
		</Grid > :
		<MuiTextCaption> {t('cards.notAssigned')}</MuiTextCaption>;

	const buttonContent = [
		<Button
			key="buttonCancel"
			className='customDialogButton'
			variant='customOutlined'
			onClick={onClose}
		>  {t('form.buttons.close')}
		</Button>,
		<Button disabled={formik.isSubmitting || !isEmpty(formik.errors) || process?.maintenance}
			key="buttonNext"
			className='customDialogButton'
			variant='contained'
			color='primary'
			type="submit"
		>
			{t(process?.maintenance ? 'dashboard.maintenance.maintenance' : 'form.buttons.confirm')}
		</Button>];

	return (


		<React.Fragment>
			<form
				id="paymentDetailsStep"
				className="portal-dialog-form-template"
				onSubmit={formik.handleSubmit}
				style={{ height: 'inherit', display: 'flex', flexDirection: 'column' }}
			>
				<Grid container>
					{mainContent}
				</Grid>
				{maxTopupCount &&

					<Alert severity="info"  >
						<MuiTextAlert  >
							{t('cards.topupCountAlert', { count: maxTopupCount })}
						</MuiTextAlert>
					</Alert>}
				<Grid container className='form-content_botton-area' justifyContent='space-between'>
					{buttonContent}
				</Grid>
			</form>
		</React.Fragment >
	);
};

export default TopUpDetails;

