import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransactionLazyLoadStore } from './types';


const initialState: TransactionLazyLoadStore = {
	transactions: [],
	skip: 0,
	take: 10,
	search: '',
	accountNumber: null,
	type: null,
	from: null,
	to: null,
	totalCount: 0
};

const transactionLazyLoadSlice = createSlice({
	name: 'transactionsLazyLoad',
	initialState,
	reducers: {

		setTransactions(state, action: PayloadAction<any[]>) {
			state.transactions = action.payload;
		},
		setSkip: (state, { payload }: PayloadAction<number>) => {
			state.skip = payload;
		},
		setTake: (state, { payload }: PayloadAction<number>) => {
			state.take = payload;
		},
		setSearch: (state, { payload }: PayloadAction<string>) => {
			state.search = payload;
		},
		setType: (state, { payload }: PayloadAction<string>) => {
			if (state.type !== payload) {
				state.search = '';
				state.skip = 0;
				state.transactions = [];
				state.type = payload;
			}
		},
		setAccountNumber: (state, { payload }: PayloadAction<number>) => {
			if (state.accountNumber !== payload) {
				state.search = '';
				state.skip = 0;
				state.transactions = [];
				state.accountNumber = payload;
			}
		},
		setFromTo: (state, { payload }: PayloadAction<{ from: number, to: number }>) => {
			state.from = payload.from;
			state.to = payload.to;
		},
		setTotalCount: (state, { payload }: PayloadAction<number>) => {
			state.totalCount = payload;
		},
	}
});

export const { setTransactions,
	setSkip,
	setSearch,
	setTake,
	setAccountNumber,
	setType,
	setFromTo,
	setTotalCount } = transactionLazyLoadSlice.actions;

export default transactionLazyLoadSlice.reducer;
