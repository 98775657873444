import request from '@/services/request';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BulkPaymentsStore, BulkPaymentsUpload, PaymentLine } from './bulkPaymentsTypes';
import { AppThunk } from '@/store';

const URL_PREFIX = '/api/bulk-payment';

const initialState: BulkPaymentsStore = {
  uploadsCount: 0,
  uploadsList: [],
  uploadsLoading: false,
  paymentsCount: 0,
  paymentsList: [],
  paymentsLoading: false,
  paymentsSkip: 0,
  paymentsFilter: '',
  paymentsListExecutable: false,
};

const slice = createSlice({
  name: 'bulkPayments',
  initialState,
  reducers: {
    setUploads(state, action: PayloadAction<Array<BulkPaymentsUpload>>) {
      state.uploadsList = action.payload;
    },
    setUploadsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.uploadsLoading = payload;
    },
    setUploadsCount: (state, { payload }: PayloadAction<number>) => {
      state.uploadsCount = payload;
    },
    setPayments(state, action: PayloadAction<Array<PaymentLine>>) {
      state.paymentsList = action.payload;
    },
    setPaymentsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.paymentsLoading = payload;
    },
    setPaymentsListExecutable: (state, { payload }: PayloadAction<boolean>) => {
      state.paymentsListExecutable = payload;
    },
    setPaymentsCount: (state, { payload }: PayloadAction<number>) => {
      state.paymentsCount = payload;
    },
    setPaymentsSkip: (state, { payload }: PayloadAction<number>) => {
      state.paymentsSkip = payload;
    },
    setPaymentsFilter: (state, { payload }: PayloadAction<string>) => {
      state.paymentsFilter = payload;
    },
    updatePaymentLine(state, action: PayloadAction<PaymentLine>) {
      const newArr = state.paymentsList.map((pl) => pl.id === action.payload.id ? action.payload : pl);
      state.paymentsList = newArr;
    },
  }
});

export const { setUploads, setPaymentsListExecutable, setUploadsLoading, setUploadsCount, setPayments, setPaymentsCount, setPaymentsLoading, updatePaymentLine, setPaymentsFilter, setPaymentsSkip } = slice.actions;


export const postBulkPaymnetFile = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await request.post(`${URL_PREFIX}/file`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  const { data } = response;
  return data;
};

export const downloadExampleFile = async (fileUrl: string, name: string) => {
  const response = await request.get(fileUrl,
    {
      responseType: 'blob',
      params: {
        isAwsDownload: true,
      },
    });
  const url = window.URL.createObjectURL(response.data);
  const link = document.createElement('a');
  link.download = name;
  link.href = url;
  link.className = 'hidden';
  document.body.appendChild(link);

  link.onclick = function () {
    requestAnimationFrame(function () {
      URL.revokeObjectURL(url);
      setTimeout(() => link.remove(), 300);
    });
  };
  link.click();
};


export const getUploadsList = (skip: number, filter?: string): AppThunk => {
  return async dispatch => {
    try {
      dispatch(setUploadsLoading(true));
      const response = await request.get(`${URL_PREFIX}/uploads`, {
        params: {
          filter: filter ?? '',
          orderBy: 'uploadDate',
          sort: 'DESC',
          skip,
          take: 10
        }
      });
      const { data } = response;
      dispatch(setUploads(data.headers));
      dispatch(setUploadsCount(data.count));
      return data;
    } catch (e) {
      dispatch(setUploads([]));
    }
    finally { dispatch(setUploadsLoading(false)); }
  };
};

export const getPaymentsList = (sessionId: string, skip: number, filter?: string): AppThunk => {
  return async dispatch => {
    try {
      dispatch(setPaymentsLoading(true));
      const response = await request.get(`${URL_PREFIX}/uploads/details/${sessionId}`, {
        params: {
          filter: filter ?? '',
          orderBy: 'id',
          sort: 'DESC',
          skip,
          take: 10
        }
      });
      const { data } = response;
      dispatch(setPayments(data.bulkPayments));
      dispatch(setPaymentsListExecutable(data.canBeExecuted));
      dispatch(setPaymentsCount(data.count));
      dispatch(setPaymentsSkip(skip));
      if (filter) {
        dispatch(setPaymentsFilter(filter));
      }
      return data;
    } catch (e) {
      dispatch(setPayments([]));
    }
    finally { dispatch(setPaymentsLoading(false)); }
  };
};

export const putPaymentLine = (paymentLine: any) => {
  return async dispatch => {
    try {
      dispatch(setPaymentsLoading(true));
      const response = await request.put(`${URL_PREFIX}/payment`, paymentLine);
      const { data } = response;
      dispatch(setPaymentsListExecutable(data.canBeExecuted));
      dispatch(updatePaymentLine(data));
      return data;
    } catch (e) {
      console.error(e);
    }
    finally { dispatch(setPaymentsLoading(false)); }
  };
};

export const deletePaymentLine = (sessionId: string, id: number): AppThunk => {
  return async (dispatch, state) => {
    const { bulkPayments } = state();
    const { paymentsSkip, paymentsFilter } = bulkPayments;

    try {
      const response = await request.delete(`${URL_PREFIX}/payment/${sessionId}/${id}`);
      const { data } = response;
      dispatch(getPaymentsList(sessionId, paymentsSkip, paymentsFilter));
      return data;
    } catch (e) {
      console.error('deletePaymentLine', e);
    }
  };
};

export const executePayments = (sessionId: string): AppThunk => {
  return async (dispatch, state) => {
    const { bulkPayments } = state();
    const { paymentsSkip, paymentsFilter } = bulkPayments;

    try {
      const response = await request.post(`${URL_PREFIX}/execute`, { sessionId });
      const { data } = response;
      dispatch(getPaymentsList(sessionId, paymentsSkip, paymentsFilter));
      return data;
    } catch (e) {
      console.error('executePayments', e);
    }
  };
};

export default slice.reducer;
