import React, { useEffect, useState } from 'react';
import '@/componentsMui/Shared/Shared.css'; 
import { getPolicyByType } from '@/features/settings/slice';
import { PolicyType } from '@/features/settings/type';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';
import { Typography, useMediaQuery, Box } from '@mui/material';
import MuiHeader from '../Shared/Widgets/MuiText/MuiHeader';
import { useTranslation } from 'react-i18next';

interface Props {
	type: PolicyType
}

const Policy = ({ type }: Props): React.ReactElement => {

	const { t } = useTranslation('translations');

	const smallScreen = useMediaQuery('(max-width:600px)');
	
	const [policy, setPolicy] = useState<any>();
	const [loading, setLoading] = useState<boolean>(false);
	const [, setError] = useState<any>();

	useEffect(() => {
		const get = async () => {
			setLoading(true);
			try {
				const response = await getPolicyByType(type);
				[PolicyType.COMPLAINTS, PolicyType.CONTACTS].includes(type) ?
					window.location.replace(response) : null;
				setPolicy(response);
			} catch (e) {
				setError(e);
			} finally {
				setLoading(false);
			}
		};
		get();
	}, [type, policy]);

	return (
		loading ?
			<Spinner /> :
			<Box  id='policy' className={`${smallScreen ? 'policy-sroll-box-mobile' : 'policy-sroll-box'} dashboard-content-wrap`} >
				<MuiHeader>{t('policy.headers.'+type)}</MuiHeader>
				<Typography dangerouslySetInnerHTML={{ __html: [PolicyType.COMPLAINTS, PolicyType.CONTACTS].includes(type) ? t('policy.headers.REDIRECTING') : '' + policy }} />
			</Box>
	);
};

export default Policy;
