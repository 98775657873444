import * as React from 'react';
import { Grid } from '@mui/material';
import TopicCard from './TopicCard';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';

export enum HelpTopicsEnum {
	ACCOUNT = 'ACCOUNT', CARD = 'CARD', CRYPTO = 'CRYPTO',
	ADD_MONEY = 'ADD_MONEY', SECURITY = 'SECURITY', TRANSFERS = 'TRANSFERS'
}


interface Props {
	iconWidth?: string,
	onClick?: (value: HelpTopicsEnum) => void
}

const HelpTopics = ({ iconWidth, onClick }: Props) => {

	const { agent } = useSelector((state: RootState) => state.status);
	const enabledHelpTopics =
		[{
			id: 1,
			topic: HelpTopicsEnum.ACCOUNT,
			enabled: true,
		},
		{
			id: 2,
			topic: HelpTopicsEnum.CARD,
			enabled: !['SLP'].includes(agent),
		},
		{
			id: 3,
			topic: HelpTopicsEnum.CRYPTO,
			enabled: true,
		},
		{
			id: 4,
			topic: HelpTopicsEnum.ADD_MONEY,
			enabled: !['SLP'].includes(agent),
		},
		{
			id: 5,
			topic: HelpTopicsEnum.SECURITY,
			enabled: true,
		},
		{
			id: 6,
			topic: HelpTopicsEnum.TRANSFERS,
			enabled: !['SLP'].includes(agent),
		}
		];

	return (
		<Grid container spacing={3}  >
			{
				enabledHelpTopics.filter(p => p.enabled).map(t => {
					return (
						<Grid key={t.id} item xs={12} md={4}>
							<TopicCard
								iconWidth={iconWidth}
								onSelect={onClick}
								topic={t.topic} />
						</Grid>);
				}
				)
			}
		</Grid>);
};

export default HelpTopics;
