
import { Alert, Button, FormControlLabel, FormLabel, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DeviceInfo, LoginTypeEnum, V3SignupBody } from '@/features/user/types';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { showErrornMessage, skipError, showException } from '@/features/swal/slice';
import { useEmail, usePasswordConfirmation, usePasswordValidation } from '@/helpers/customHook/useValidations';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import IOSSwitch from '@/componentsMui/Shared/Widgets/IOSSwitch';
import { v3Signup } from '@/features/user/userSlice';
import { isValidPhoneNumber } from 'react-phone-number-input';
import ReChallange from '@/componentsMui/Shared/Widgets/ReChallange';
import MuiTextAlert from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAlert';
import { CountryPhoneCodesType } from '@/features/fnd/fndTypes';
import { ONBORADING } from '@/pages/navigationConstants';
import { RootState } from '@/rootReducer';
import SmsCountryDropdown from '../components/SmsCountryDropdown';
import RegistrationDisclaimer from './RegistrationDisclaimer';

interface Props {
	deviceInfo: DeviceInfo,
	isFreeLancer: boolean,
	smsCountries: CountryPhoneCodesType[]
}


function useQuery() {
	return new URLSearchParams(useLocation().search);
}
const Registration = ({ deviceInfo, isFreeLancer, smsCountries }: Props) => {
	const { t } = useTranslation(['translations']);
	const [pep, setPep] = useState<boolean>(false);
	const [usPerson, setUsPerson] = useState<boolean>(false);
	const [soleBeneficial, setSoleBeneficial] = useState<boolean>(false);
	const [agreeTerms, setAgreeTerms] = useState<boolean>(false);
	const [open, setOpen] = useState(false);
	const appName = t('brandName.brand');
	const dispatch = useDispatch();
	const history = useHistory();
	const passwordValidation = usePasswordValidation();
	const passwordConfirmation = usePasswordConfirmation();
	const [payload, setPayload] = useState<V3SignupBody | null>(null);
	const [invalidFreelancerCodeError, setInvalidFreelancerCodeError] = useState<boolean>(false);
	const [requiredFreelancerCodeError, setRequiredFreelancerCodeError] = useState<boolean>(false);
	const { invitationEnabled, hideReferalId, invitationCodeEnabled, mandatoryReferalId } = useSelector((state: RootState) => state.status);
	const { agent } = useSelector((state: RootState) => state.status);

	const query = useQuery();
	const freelancerCode = query.get('code');
	const referralCode = query.get('referralCode');

	const showAdditionalCheck = ['ESCROWFY', 'IDEALOOP', 'NEURALID'].includes(agent);

	const initialValues = {
		referralId: referralCode ?? '',
		password: '',
		passwordConfirm: '',
		phoneCode: '',
		phoneNumber: '',
		email: '',
		countryCodeAlpha2: '',
		firstname: '',
		lastname: '',
	};


	useEffect(() => {
		if (isFreeLancer) {
			if (!freelancerCode) {
				setRequiredFreelancerCodeError(true);
			} else {
				setRequiredFreelancerCodeError(false);
			}
		}
	}, [freelancerCode, isFreeLancer]);

	const emailValidation = useEmail();


	const validatePhone = (phone: number | undefined) => {
		return Yup.number().integer().positive().test(
			(phone) => {
				return isValidPhoneNumber(`+${phone}`);
			}
		).isValidSync(phone);
	};

	const validationSchema = Yup.object({
		password: passwordValidation,
		passwordConfirm: passwordConfirmation,
		phoneCode: Yup.string().required(t('form.validator.required')),
		phoneNumber: Yup.string()
			.required(t('form.validator.required'))
			.test('userName', t('form.validator.invalidValue'), (value, ctx) => {
				return validatePhone(parseInt(ctx.parent.phoneCode + '' + value ?? '0'));
			}),
		email: emailValidation,
		referralId: invitationEnabled || mandatoryReferalId ?
			Yup.string().trim().required(t('form.validator.required')).max(14, t('form.validator.maxChars', { chars: 14, field: t('form.fields.referralId') }))
			: Yup.string().max(14, t('form.validator.maxChars', { chars: 14, field: t('form.fields.referralId') })),
		invitationCode: invitationCodeEnabled ? Yup.string().required(t('form.validator.required')).max(40, t('form.validator.maxChars', { chars: 40, field: t('form.fields.invitationCode') })) : Yup.string(),
		firstname: Yup.string().when([], {
			is: () => isFreeLancer,
			then: Yup.string().required(t('form.validator.required')),
			otherwise: Yup.string().notRequired(),
		}),
		lastname: Yup.string().when([], {
			is: () => isFreeLancer,
			then: Yup.string().required(t('form.validator.required')),
			otherwise: Yup.string().notRequired(),
		})
	});


	const handleClose = async (recaptchaValue: string) => {

		if (recaptchaValue) {
			try {
				await dispatch(v3Signup(LoginTypeEnum.BOTH, { ...payload, recaptchaToken: recaptchaValue }));
				history.push(ONBORADING);

			} catch (err: any) {
				setOpen(false);
				const skip = skipError(err);
				const data = err?.data;
				const invalid = data?.errors?.find(error => error.error === 'already' && error.error_param === 'registered') || false;
				const invalidCode = data?.errors?.find(error => error.error === 'invalid' && error.error_param === 'freelancerCode') || false;

				if (invalid) {
					await showErrornMessage('ACCOUNT_EXISTS');
				}
				else if (invalidCode) {
					setInvalidFreelancerCodeError(true);
				}
				else if (!skip) { showException(err); }
			}

		}
		else {
			setOpen(false);
		}


	};


	const submit = (formData, formikProps) => {

		const { setSubmitting } = formikProps;

		setInvalidFreelancerCodeError(false);
		setRequiredFreelancerCodeError(false);

		if (isFreeLancer && !freelancerCode) {
			setSubmitting(false);
			setRequiredFreelancerCodeError(true);
			return;
		}

		setSubmitting(true);
		const { email, phoneCode, phoneNumber, referralId, password, firstname, lastname } = formData;
		const signupBody: V3SignupBody = {
			type: LoginTypeEnum.BOTH,
			email: email,
			countryCode: phoneCode,
			phoneNumber: phoneNumber,
			password: password,
			politicalExposed: !pep,
			deviceInfo,
			freelancerCode: freelancerCode,
			freelancer: isFreeLancer,
			referalCode: referralId,
			recaptchaToken: '',
			firstName: firstname ?? null,
			lastName: lastname ?? null
		};

		setPayload(signupBody);
		setOpen(true);
		setSubmitting(false);
	};


	const additionalChecksFilled = () => {
		if (showAdditionalCheck) {
			if (usPerson && soleBeneficial) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	};

	const showRefferalId = (!hideReferalId || mandatoryReferalId) && !isFreeLancer;


	return (
		<Grid container>
			<ReChallange open={open} onClose={handleClose} />
			{invalidFreelancerCodeError && <Grid item xs={12}>
				<Alert severity="warning"  >
					<MuiTextAlert  >
						<Typography variant='body2'>{t('login.v3.invalidEmployeeCode')}</Typography>
					</MuiTextAlert>
				</Alert>
			</Grid>}
			{requiredFreelancerCodeError && <Grid item xs={12}>
				<Alert severity="warning"  >
					<MuiTextAlert  >
						<Typography variant='body2'>{t('login.v3.requiredEmployeeCode')}</Typography>
					</MuiTextAlert>
				</Alert>
			</Grid>}
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
				validateOnChange={true}
				validateOnBlur={true}
				enableReinitialize={true}
			>
				{({ errors, isSubmitting, dirty }) => {
					return (
						<Form style={{ width: '100%' }} autoComplete='off'>
							<Grid id="registrationMui" container rowSpacing={1}>
								<Grid item xs={12}>
									<MuiTextField
										className='registration-input'
										label={t('login.v3.signUpFields.yourEmail')}
										name="email"
										fullWidth
										inputProps={{ maxLength: 255 }}
										InputProps={{
											autoComplete: 'new-email'
										}}
									/>
								</Grid>
								<Grid item xs={12} container alignItems='flex-end'>
									<Grid item xs={3} >
										<FormLabel>	{t('login.v3.signUpFields.yourPhoneNumber')}
										</FormLabel>
										<SmsCountryDropdown name='phoneCode' smsCountries={smsCountries} />
									</Grid>
									<Grid item xs={9}>
										<MuiTextField
											className='registration-input'
											name="phoneNumber"
											hideError={true}
											fullWidth
											inputProps={{ maxLength: 255 }}
											InputProps={{
												autoComplete: 'new-phone',
											}}
										/>
									</Grid>
									{errors?.phoneNumber && <Grid item xs={12} textAlign='right'>
										<Typography variant='gradient' fontSize='0.75rem' fontWeight={400}>{errors?.phoneNumber}</Typography> </Grid>}

								</Grid>
								<Grid item xs={12}>
									<MuiTextField
										className='registration-input password'
										name="password"
										label="Password"
										type="password"
										InputProps={{
											autoComplete: 'new-password'
										}}
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<MuiTextField
										className='registration-input password'
										name="passwordConfirm"
										label={t('form.fields.confirmpassword')}
										type="password"
										InputProps={{
											autoComplete: 'new-password'
										}}
										fullWidth
									/>
								</Grid>
								{isFreeLancer && <>
									<Grid item xs={12}>
										<MuiTextField
											className='registration-input'
											name="firstname"
											label={`${t('form.fields.firstname')}`}
											fullWidth
										/>
									</Grid>
									<Grid item xs={12}>
										<MuiTextField
											className='registration-input'
											name="lastname"
											label={`${t('form.fields.lastname')}`}
											fullWidth
										/>
									</Grid>
								</>}
								{showRefferalId ?
									<Grid item xs={12}>
										<MuiTextField
											className='registration-input'
											name="referralId"
											label={mandatoryReferalId ? t('form.fields.referralId') : `${t('form.fields.referralId')} (${t('form.fields.optional')})`}
											fullWidth
										/>
									</Grid> : null}
								<Grid item xs={12} mt={{ xs: 2, sm: 5 }}>
									<FormControlLabel
										name='pep'
										className='registration-input'
										control={<IOSSwitch name='pep'
											onClick={() => setPep(!pep)}
											checked={pep}
										/>}
										label={<Typography fontSize='0.813rem' variant='body2'>{t('login.v3.pepCbx')}</Typography>}
									/>
								</Grid>
								{showAdditionalCheck ?
									<>
										<Grid item xs={12} mt={{ xs: 2, sm: 1 }}>
											<FormControlLabel
												name='usPerson'
												className='registration-input'
												control={<IOSSwitch name='usPerson'
													onClick={() => setUsPerson(!usPerson)}
													checked={usPerson}
												/>}
												label={<Typography fontSize='0.813rem' variant='body2'>{t('login.v3.usPersonCbx')}</Typography>}
											/>
										</Grid>
										<Grid item xs={12} mt={{ xs: 2, sm: 1 }}>
											<FormControlLabel
												name='soleBeneficial'
												className='registration-input'
												control={<IOSSwitch name='soleBeneficial'
													onClick={() => setSoleBeneficial(!soleBeneficial)}
													checked={soleBeneficial}
												/>}
												label={<Typography fontSize='0.813rem' variant='body2'>{t('login.v3.soleBeneficiaryCbx')}</Typography>}
											/>
										</Grid>
									</>
									: null}
								<Grid item xs={12} mt={1}>
									<FormControlLabel
										className='registration-input'
										control={
											<IOSSwitch name='toc'
												onClick={() => setAgreeTerms(!agreeTerms)}
												checked={agreeTerms}
											/>}
										label={<Typography textAlign='left' fontSize='0.813rem' variant='body2' className='highlight-regular'>
											<Trans i18nKey={agent.substring(0, 6) === 'NEURAL' ? 'login.v3.tocCbxModulR' : (['CUSTODYFY', 'SALAZAR', 'CACTUS', 'DTS'].includes(agent) ? 'login.v3.termsCustodify' : 'login.v3.tocCbx')}
												t={t}
												components={{
													appName: appName,
													termlink: <RouterLink to='/legal/terms' target='_blank' />,
													policylink: <RouterLink to='/legal/privacypolicy' target='_blank' />,
													cryptolink: <RouterLink to='/legal/termsofcrypto' target='_blank' />
												}}
											/>
										</Typography>}
									/>
								</Grid>
								<RegistrationDisclaimer />
								<Grid item xs={12} marginY={'30px'} >
									<Button
										type="submit"
										fullWidth
										variant="contained"
										disabled={!isEmpty(errors) || isSubmitting || !agreeTerms || !pep || !dirty || !additionalChecksFilled()}
									>
										{t('login.v3.signup')}
									</Button>
								</Grid>

							</Grid>
						</Form>
					);
				}}
			</Formik>
		</Grid >
	);
};

export default Registration;
