import request from '../../services/request';
import { AuthorizePayload, AuthorizeResponse } from './openIdType';

/* export const verifyTrxInitialization = async (ppan: string, state: string): Promise<VerifyReponse> => {
	const response = await request.get(`/api-openid/card/verify/${ppan}/${state}`);
	const { data } = response;
	return data;
};
 */

export const confirmTransaction = async (payload: AuthorizePayload): Promise<AuthorizeResponse> => {
	const response = await request.post('/api/cards-tds/confirm', payload);
	const { data } = response;
	return data;
};


export const rejectTransaction = async (payload: AuthorizePayload): Promise<AuthorizeResponse> => {
	const response = await request.post('/api/cards-tds/reject', payload);
	const { data } = response;
	return data;
};

export const verifyTransaction = async (state: string): Promise<{ valid: boolean }> => {
	const response = await request.get(`/api/cards-tds/verify/${state}`);
	const { data } = response;
	return data;
};

/*
export const getRedirectUrl = async (ppan: string): Promise<string> => {
	const response = await request.get(`/api-openid/card/location/${ppan}`);
	const { data } = response;
	return data;
};
*/


/*
export const postAuthorizeApproval = async (ppan: string, state: string): Promise<any> => {
	await request.get(`/api-openid/card/approve/${ppan}/${state}`);
};
 */

/*
export const getOathToken = async (payload: TokenOathPayload): Promise<any> => {
	const formData = new URLSearchParams();
	formData.append('code', payload.code);
	formData.append('grant_type', payload.grant_type);
	formData.append('redirect_uri', payload.redirect_uri);

	console.log('call tone');
	const response = await request.post('/api-openid/oauth2/token', formData);
	const { data } = response;
	console.log(data);
	return data;
};

export const getUserInfo = async (token: string): Promise<any> => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};
	const response = await request.get('/api-openid/oauth2/userinfo', config);
	const { data } = response;
	return data;
};


*/

