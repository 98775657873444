import React, { useEffect, useState } from 'react';
import { Dialog, Grid, IconButton, ListItemIcon, ListItemText, MenuList } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { openForm } from '@/features/forms/slice';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import StyledMenuItemModal from '@/componentsMui/Shared/Widgets/MenuItem/StyledMenuItemModal';
import FormCloseButton from './Components/FormCloseButton';
import CryptoDepositFlow from './Crypto/CryptoDepositFlow';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import BetweenAccountsSteps from '@/componentsMui/Shared/Components/SendMoney/BetweenAccounts/BetweenAccountsSteps';
import { RootState } from '@/rootReducer';
import { hasSubprocessWithType } from '@/features/operations/slice';
import { AccountType } from '@/features/operations/types';
import { ReactComponent as IconBitcoin } from '@/assets/CurrencyIcons/Bitcoin.svg';
import FiatDepositFlow from './Fiat/FiatDepositFlow';
import '@/componentsMui/Shared/Shared.css';
import { ReactComponent as MuiBigSync } from '@/assets/MuiBigSync.svg';
import StyledListItemIcon from '@/componentsMui/Shared/Widgets/StyledListItemIcon';
import FormPaperLargeFixed from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeFixed';
import { useLocation } from 'react-router-dom';
import { WalletDepositAction } from '@/features/coins/types';

const DepositForm = (): React.ReactElement => {

	const closeModal = useCloseDialogNoEvent();
	const dispatch = useDispatch();
	const { t } = useTranslation('translations');

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const [ccy] = useState<string>(searchParams.get('ccy'));
	const [symbol] = React.useState<string>(searchParams.get('symbol'));

	const [action, setAction] = useState<WalletDepositAction>(WalletDepositAction.TRANSFER);
	const [step, setStep] = useState<number>(0);
	const allAccounts = useSelector((state: RootState) => state.accounts.list);
	const walletAccounts = allAccounts.filter(p => p.type === AccountType.WALLET);

	const prevStep = () => setStep(step - 1);

	const toAccountList = walletAccounts.filter(accounts => hasSubprocessWithType('OWN_TRANSFER', accounts.subProcesses)) || [];
	const fromAccountList = allAccounts.filter(accounts => hasSubprocessWithType('OWN_TRANSFER', accounts.subProcesses))
		.filter(accounts => toAccountList.some(p => p.accountId === accounts.accountId) === false) || [];

	const defaultFromAccount = ccy ? fromAccountList.find(p => p.currency === ccy) : fromAccountList[0];
	const defaultToAccount = toAccountList.find(p => p.currency === defaultFromAccount?.currency);

	const handleMenuClick = (action: WalletDepositAction) => {
		setAction(action);
		setStep(1);
	};

	const eurPolling = walletAccounts.find(p => p.status === 'ACTIVE' && p.providerType === 'POOLING' && p.currency === 'EUR');
	const usdPolling = walletAccounts.find(p => p.status === 'ACTIVE' && p.providerType === 'POOLING' && p.currency === 'USD');
	const gbpPolling = walletAccounts.find(p => p.status === 'ACTIVE' && p.providerType === 'POOLING' && p.currency === 'GBP');


	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);


	return (
		<Dialog
			id="deposit-form"
			open={true}
			PaperComponent={FormPaperLargeFixed}
		>
			{step === 0 &&
				<>
					<Grid item container justifyContent='space-between'>
						<Grid item>
							<MuiHeader> {t('wallet.deposit.deposit')}
							</MuiHeader>
						</Grid>
						<Grid item>
							<IconButton onClick={closeModal}  >
								<MuiCloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item >
						<MuiFormText> {t('wallet.deposit.chooseDeposit')}</MuiFormText>
					</Grid>
					{step === 0 &&
						<Grid item >
							<MenuList role='menu' className='form-menu__list'>
								<StyledMenuItemModal
									unsetMarginBottom={true}
									id='deposit-transfer'
									onClick={() => handleMenuClick(WalletDepositAction.TRANSFER)}
								> 	<StyledListItemIcon>  <MuiBigSync />  </StyledListItemIcon>
									<ListItemText className='money-form-list-item-text'>{t('wallet.deposit.transer')}</ListItemText>
								</StyledMenuItemModal>
								{eurPolling && <>
									<StyledMenuItemModal
										unsetMarginBottom={true}
										id='deposit-sepa'
										onClick={() => handleMenuClick(WalletDepositAction.SEPA)}
									> 	<ListItemIcon sx={{ mr: '1.25rem' }}> <img className='img-icon-size' src={eurPolling?.currencyLogo} /> </ListItemIcon>
										<ListItemText className='money-form-list-item-text'>{t('wallet.deposit.sepa')}</ListItemText>
									</StyledMenuItemModal>
								</>}
								{usdPolling &&
									<StyledMenuItemModal
										unsetMarginBottom={true}
										id='deposit-swift'
										onClick={() => handleMenuClick(WalletDepositAction.SWIFT)}
									> 	<ListItemIcon sx={{ mr: '1.25rem' }}> <img className='img-icon-size' src={usdPolling?.currencyLogo} /> </ListItemIcon>
										<ListItemText className='money-form-list-item-text'>{t('wallet.deposit.swift')}</ListItemText>
									</StyledMenuItemModal>
								}
								{gbpPolling &&
									<StyledMenuItemModal
										unsetMarginBottom={true}
										id='deposit-chaps'
										onClick={() => handleMenuClick(WalletDepositAction.CHAPS)}
									> 	<ListItemIcon sx={{ mr: '1.25rem' }}> <img className='img-icon-size' src={gbpPolling?.currencyLogo} /> </ListItemIcon>
										<ListItemText className='money-form-list-item-text'>{t('wallet.deposit.chaps')}</ListItemText>
									</StyledMenuItemModal>
								}
								<StyledMenuItemModal
									unsetMarginBottom={true}
									id='deposit-crypto'
									onClick={() => handleMenuClick(WalletDepositAction.CRYPTO)}
								> 	<ListItemIcon sx={{ mr: '1.25rem' }}> <IconBitcoin className='img-icon-size' /> </ListItemIcon>
									<ListItemText className='money-form-list-item-text'>{t('wallet.deposit.crypto')}</ListItemText>
								</StyledMenuItemModal>
							</MenuList>
						</Grid>
					}
					<FormCloseButton onClose={closeModal} />
				</>

			}
			{action === WalletDepositAction.CRYPTO && step !== 0 && <CryptoDepositFlow symbol={symbol} includeWallet={false}  onBack={prevStep} />}
			{(action === WalletDepositAction.SEPA || action === WalletDepositAction.SEPA_INSTANT) && step !== 0 && <FiatDepositFlow account={eurPolling} depositType={action} onBack={prevStep} title={t(`wallet.deposit.${action.toLowerCase()}`)} />}
			{action === WalletDepositAction.SWIFT && step !== 0 && <FiatDepositFlow account={usdPolling} depositType={action} onBack={prevStep} title={t('wallet.deposit.swift')} />}
			{action === WalletDepositAction.CHAPS && step !== 0 && <FiatDepositFlow account={gbpPolling} depositType={action} onBack={prevStep} title={t('wallet.deposit.chaps')} />}
			{action === WalletDepositAction.TRANSFER && step !== 0 && <BetweenAccountsSteps
				onBack={prevStep}
				prevStep={prevStep}
				noBackground={true}
				step={step}
				title={t('wallet.deposit.transer')}
				toAccountList={toAccountList}
				fromAccountList={fromAccountList}
				fromDefaultAccount={defaultFromAccount}
				toDefaultAccount={defaultToAccount} />}
		</Dialog >
	);
};

export default DepositForm;
