import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import { Transaction } from '@/componentsMui/Transactions/TransactionStatements.spec';
import { isFiat } from '@/helpers/currency';
import { useTranslation } from 'react-i18next';
import { formatDate, timeSince } from '@/helpers/date';
import { roundFlexible } from '@/helpers/round';
import { getCurrencySign } from '@/features/fees/slice';
import { TransactionStatus } from '@/features/transactions/types';
import TransactionStatusBox from '@/componentsMui/Shared/Widgets/Box/TransactionStatusBox';
import MuiTextTrx from '@/componentsMui/Shared/Widgets/MuiText/MuiTextTrx';
import MuiTextTrxSmall from '@/componentsMui/Shared/Widgets/MuiText/MuiTextTrxSmall';
import TransactionIcon from '@/componentsMui/Transactions/Details/TransactionIcon';

interface Props {
	item: Transaction,
	openTransactionDetailsForm: (trx: Transaction) => void,
	enablePointer: (trx: Transaction) => boolean
}

const TransactionMobileLine = ({ item, openTransactionDetailsForm, enablePointer }: Props) => {

	const { t } = useTranslation('translations');
	const isFiatAsset = isFiat(item.asset);

	return (
		<Box
			onClick={() => openTransactionDetailsForm(item)}
			style={{ cursor: enablePointer(item) ? 'pointer' : 'default' }}
			pb='1rem'
			// sx={{ backgroundColor: theme.bgSecondaryButton }}
			borderRadius='10px'
			width='100%'
		>
			<Grid item xs={12} container alignItems='center' columnSpacing={1} wrap='nowrap'>
				<Grid item justifyContent='flex-start' alignItems='center'>
					{item?.activity &&
						<TransactionIcon activity={item.activity} beneficiaryName={item.beneficiary} />
					}
				</Grid>
				<Grid item
					sx={{
						display: 'flex',
						flexDirection: 'column',
					}}
					justifyContent='space-evenly'
					maxWidth={'100%'}
					minWidth='0'
				>
					<Grid item xs={12}>
						<Typography color="text.primary" noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}>
							{item?.messageCode ? t('activity.' + item.messageCode) :
								t('activity.' + item.activity)}
						</Typography>
					</Grid>
					<Grid item xs={12} justifyContent={'flex-end'}>
						<MuiTextTrxSmall color='text.secondary'>
							{timeSince(formatDate(item.date, 'T'))}
						</MuiTextTrxSmall>
					</Grid>
				</Grid>
				<Grid item ml='auto' direction='column'
					display='flex'
					justifyContent='space-evenly'
				>
					<Grid>
						<MuiTextTrx textAlign='right'
							fontWeight={700} sx={theme => ({ color: item.amount < 0 ? theme.palette.text.secondary : theme.palette.text.primary })}>
							{item.amount && (item.amount < 0 ? '- ' + (item?.currencySymbol ?? getCurrencySign(item.asset)) : item?.currencySymbol ?? getCurrencySign(item.asset) + ' ')}

							{isFiatAsset ?
								<NumberFormat displayType={'text'}
									decimalScale={2}
									fixedDecimalScale={true}
									thousandsGroupStyle='thousand'
									thousandSeparator={true}
									value={Math.abs(item.amount)} /> :
								roundFlexible(Math.abs(item.amount))
							}

						</MuiTextTrx>
					</Grid>
					<Grid container justifyContent='flex-end'>
						<TransactionStatusBox
							status={item.status as TransactionStatus}>
							{t('status.' + item.status)}
						</TransactionStatusBox>
					</Grid>
				</Grid>
			</Grid>
		</Box >);
};

export default TransactionMobileLine;

