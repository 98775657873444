import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';
import { Box } from '@mui/material';
import * as React from 'react';

interface Props {
	url: string
}

const CardTransferRedirect = ({ url }: Props) => {
	React.useEffect(() => {
		window.location.replace(url);
	}, [url]);

	return <Box mt='30px'><Spinner /></Box>;
};

export default CardTransferRedirect;
