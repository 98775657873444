import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Link, useMediaQuery } from '@mui/material';
import './Footer.css';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { PolicyType } from '@/features/settings/type';
import { showPolicy } from '../Legal/Legal';
import { Link as RouterLink } from 'react-router-dom';
import useFooterHeight from '@/helpers/customHook/useFooterHeight';

const EarnFooter = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const { agent } = useSelector(
		(state: RootState) => state.status
	);
	const smallScreen = useMediaQuery('(max-width:600px)');
	const showVaultPolicy = showPolicy(agent, PolicyType.VAULT);
	const footerHeight = useFooterHeight();

	if (!showVaultPolicy) return <></>;
	return (<>
		<Grid container
			minHeight={footerHeight}
			alignItems='center'
		//sx={(theme) => ({ background: theme.bgSecondaryButton })}
		>
			<Grid container
				className={`${smallScreen ? 'mui-footer-mobile-auth' : 'mui-footer'}`} style={{
					flexDirection: smallScreen ? 'column' : 'row',
					flexGrow: 1
				}}>
				<Grid item xs='auto' container columnSpacing={smallScreen ? 0 : 4} alignItems='center'>
					<Grid item>
						<Link underline="none"
							variant='plainLink'
							component={RouterLink}
							target='_blank'
							to='/legal/termsofvault'>{t('footer.termsOfVault')}</Link>
					</Grid>
				</Grid>

			</Grid>
		</Grid >
	</>
	);
};
export default EarnFooter;
