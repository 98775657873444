import * as React from 'react';

import { Dialog, Grid, IconButton, Box, Button } from '@mui/material';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import DialogContentNoScrollbar from '@/componentsMui/Shared/Widgets/Dialog/DialogContentNoScrollbar';
import { useTranslation } from 'react-i18next';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import DetailLine from '@shared/Widgets/DetailLine';
import { convertDate } from '@helpers/date';
import NumberFormat from 'react-number-format';
import MuiTextCaption from '@shared/Widgets/MuiText/MuiTextCaption';
import TransactionStatusBox from '@shared/Widgets/Box/TransactionStatusBox';
import StyledDivider from '@shared/Widgets/StyledDivider';
import MuiFormLabel from '@shared/Widgets/MuiText/MuiFormLabel';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { toggleDeposit } from '@features/earn/depositsSlice';
import { isFiat } from '@helpers/currency';
import { getVaultTransactionStatus } from '@helpers/transactionUtils';
import IOSSwitch from '@shared/Widgets/IOSSwitch';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChangePlanForm from './ChangePlanForm';

interface Props {
	open: boolean,
	onClose: () => void,
	allocation: any,
	hideAutoExtend?: boolean
}

const VaultDepositDetailsForm = ({ open, onClose, allocation, hideAutoExtend }: Props) => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const time = allocation?.createdDate && new Date(allocation.createdDate);
	const [toggleLoading, setToggleLoading] = useState<boolean>(false);
	const [openChangePlanDialog, setOpenChangePlanDialog] = useState<boolean>(false);
	const confirm = useConfirm();

	const handleAutoExtend = () => {
		setToggleLoading(true);
		if (allocation.autoExtendStatus === 'ON') {
			confirm({
				description: t('vaults.allDeposits.confirmDescription'),
				title: t('vaults.allDeposits.confirmTitle'),
				confirmationText: t('vaults.allDeposits.confrimConfirmationText'),
				cancellationText: t('vaults.allDeposits.confirmCancellationText')
			})
				.then(() => {
					dispatch(toggleDeposit(allocation.vaultId, false));
				});
		} else {
			dispatch(toggleDeposit(allocation.vaultId, true));
		}
		setToggleLoading(false);

	};


	if (!allocation) {
		return <></>;
	}

	return (
		<Dialog
			id="account-details-form"
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
		>
			{openChangePlanDialog && <ChangePlanForm
				open={openChangePlanDialog}
				onClose={() => setOpenChangePlanDialog(false)}
				allocation={allocation}
			/>}
			<DialogContentNoScrollbar>
				<Grid
					container
					sx={{ p: { xs: '1rem', md: '2rem' } }}
				>
					<Grid item container justifyContent="space-between" alignItems="center" pb={6}>
						<Grid item>
							<MuiHeader>{t('tokens.header.details')}</MuiHeader>
						</Grid>
						<Grid item>
							<IconButton onClick={onClose}  >
								<MuiCloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item container alignItems='center' spacing={2}>
						<Grid item xs={12} >
							<Box>
								<DetailLine label={t('vaults.allDeposits.time')} value={convertDate(time, 'dd/MM/yyyy HH:mm:ss')} first />
								<DetailLine label={t('vaults.allDeposits.plan')} value={allocation.vaultName} />
								<DetailLine label={t('vaults.allDeposits.apr')} value={`${allocation.yearlyInterestRate}`}>
									<MuiTextCaption>
										<NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} suffix={'%'} value={parseFloat(allocation.yearlyInterestRate) * 100} />
									</MuiTextCaption>
								</DetailLine>
								<DetailLine label={t('vaults.allDeposits.duration')} value={`${parseInt(allocation.depositPeriodMonths) * 30} ${t('vaults.subsribe.days')}`} />
								<DetailLine label={t('vaults.allDeposits.asset')} value={allocation.currency} />
								<DetailLine label={t('vaults.allDeposits.amount')} value={`${allocation.vaultAmount}`}>
									<MuiTextCaption>
										<NumberFormat
											displayType={'text'}
											decimalScale={isFiat(allocation.currency) ? 2 : 5}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											value={allocation.vaultAmount} />
									</MuiTextCaption>
								</DetailLine>
								<DetailLine label={t('vaults.allDeposits.repaymentDate')} value={convertDate(allocation.repaymentDate, 'dd MMM yyyy')} />


								{hideAutoExtend ? null : <><Grid item xs={12}> <StyledDivider /> </Grid>

									<Grid container
										minHeight='3.2rem'
										py={1}
										alignItems='center'>
										<Grid item xs={12} container>
											<Grid item xs={4}>
												<MuiFormLabel color='text.secondary'>
													{t('vaults.allDeposits.autoSubscribe')}
												</MuiFormLabel>
											</Grid>
											<Grid container item xs={8} justifyContent='flex-end'>
												{allocation?.autoExtendStatus === 'PENDING_OFF' ? <AccessTimeIcon /> :
													<IOSSwitch checked={allocation.autoExtendStatus === 'ON'} onChange={handleAutoExtend} disabled={allocation.status !== 'ACTIVE' || toggleLoading || allocation?.allowAutoExtendSwitchOff === false || allocation.autoExtendStatus !== 'ON'} />}
											</Grid>
										</Grid>
									</Grid></>}

								<Grid item xs={12}> <StyledDivider /> </Grid>

								<Grid container
									minHeight='3.2rem'
									py={1}
									alignItems='center'>
									<Grid item xs={12} container>
										<Grid item xs={4}>
											<MuiFormLabel color='text.secondary'>
												{t('vaults.allDeposits.planChange')}
											</MuiFormLabel>
										</Grid>
										<Grid container item xs={8} justifyContent='flex-end'>
											<Button
												variant='customOutlined'
												sx={{
													padding: '8px',
													fontSize: '0.563rem'
												}}
												disabled={allocation.status !== 'ACTIVE' || allocation.planChangeRequested}
												onClick={() => setOpenChangePlanDialog(true)}
											>{allocation.planChangeRequested ? t('vaults.planChange.requested') : t('vaults.planChange.request')}</Button>
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12}> <StyledDivider /> </Grid>

								<Grid container
									minHeight='3.2rem'
									py={1}
									alignItems='center'>
									<Grid item xs={12} container>
										<Grid item xs={4}>
											<MuiFormLabel color='text.secondary'>
												{t('vaults.allDeposits.status')}
											</MuiFormLabel>
										</Grid>
										<Grid container item xs={8} justifyContent='flex-end'>
											<TransactionStatusBox
												status={getVaultTransactionStatus(allocation.status)}>
												{t('status.vaults.allDeposits.' + allocation.status)}
											</TransactionStatusBox>
										</Grid>
									</Grid>
								</Grid>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</DialogContentNoScrollbar>
		</Dialog >
	);
};

export default VaultDepositDetailsForm;
