import { Alert, Button, Grid, Theme, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import * as Yup from 'yup';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { v3GenerateEmployeeCode } from '@/features/employee/slice';
import { showException } from '@/features/swal/slice';
import { useEmail } from '@/helpers/customHook/useValidations';

interface Props {
	onBack: () => void,
}

const EmployeeInviteStep = ({ onBack }: Props): React.ReactElement => {

	const closeModal = useCloseDialogNoEvent();
	const { t } = useTranslation('translations');
	const theme = useTheme() as Theme;
	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const emailValidation = useEmail();

	const initialValues = {
		email: '',
	};

	const validationSchema = Yup.object({
		email: emailValidation
	});

	const submit = async (formData, formikProps) => {
		const { onBehalfOfCompany, companyName } = user;
		const { setSubmitting } = formikProps;
		const { email } = formData;

		setSubmitting(false);
		try {
			await v3GenerateEmployeeCode('FREELANCER', onBehalfOfCompany ?? companyName, email);
		} catch (e) {
			showException(e);
			return;
		}
		closeModal();
	};

	return (
		< Grid id='invite-employee-step'
			container
			flex={1}
			direction='column'
			minHeight='0'
			flexWrap='nowrap'
			rowSpacing={2}>
			<Grid item >
				<FormHeader title={t('employee.invite.employeeTitle')} onBack={onBack} />
			</Grid>
			<Grid item mt='1rem' >
				<Alert severity="warning"  >
					<MuiFormText >{t('employee.invite.employeeWarningText')}</ MuiFormText>
				</Alert>
			</Grid>
			<Grid item flex={1} >
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={submit}
				>
					{({ errors, isSubmitting, touched, dirty }) => (
						<Form style={{ width: '100%', height: '100%' }}>
							<Grid container style={{ height: '100%' }} >
								<Grid item xs={12}>
									<MuiTextField
										name="email"
										label={t('employee.invite.emailLabel')}
										InputLabelProps={{ style: { color: theme.palette.text.primary, textAlign: 'left' } }}
										fullWidth
										placeholder={t('employee.invite.emailPlaceholder')}
										inputProps={{
											autoComplete: 'off',
										}}
										error={errors.email && touched.email}
									/>
								</Grid>
								<Grid item xs={12} flex={1} alignItems='flex-end' justifyContent='space-between' mt='auto' pt='2rem' display='flex'>
									<Button
										onClick={closeModal}
										variant='customOutlined'
									>{t('form.buttons.close')}</Button>
									<Button
										type="submit"
										variant="contained"
										disabled={!isEmpty(errors) || isSubmitting || !dirty}
									>
										{t('employee.invite.employeeBtn')}
									</Button>
								</Grid>
							</Grid>

						</Form>
					)}
				</Formik>
			</Grid>
		</ Grid >
	);
};

export default EmployeeInviteStep;
