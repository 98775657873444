import React from 'react';

import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { Grid, Typography } from '@mui/material';
import ProductCard from '@/componentsMui/Shared/Widgets/ProductCard/ProductCard';
import { getCurrencySign } from '@/features/fees/slice';
import DiplayBigNumber from '@/componentsMui/Shared/Components/DiplayBigNumber';
import { getAccountName } from '@/helpers/getAccountName';
import { BankAccount } from '@/features/account/types';
import MuiTextAccountBoxHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAccountBoxHeader';
import MuiTextLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextLabel';
import { useDispatch, useSelector } from 'react-redux';
import { closeForm, openForm } from '@/features/forms/slice';
import SwitchAccountForm from './SwitchAccountForm';
import { setAccount } from '@/features/tabs/slice';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { RootState } from '@/rootReducer';
import { AccountType } from '@/features/operations/types';
import TokenAvatar, { TokenAvatarSizeEnum } from '@/componentsMui/Shared/Widgets/Avatars/TokenAvatar';
import { hiddenBabalance } from '@/helpers/placeholders';

interface Props {
	bankAccount: BankAccount,
	selected?: boolean,
	type?: AccountType
}

const AccountCard = ({ bankAccount, selected, type = AccountType.BANK }: Props) => {

	const { t } = useTranslation('translations');
	const bankAccountName = getAccountName(t, bankAccount);
	const [openSwitchForm, setOpenSwitchForm] = React.useState(false);
	const dispatch = useDispatch();
	const { user } = useSelector((state: RootState) => state.user);

	const allowToSwitchAccount = bankAccount.type === AccountType.BANK || bankAccount.type === AccountType.BANK_AND_CARD;
	const handleSwitchForm = () => {
		if (allowToSwitchAccount) {
			dispatch(openForm());
			setOpenSwitchForm(true);
		}
	};

	const closeSwitchForm = () => {
		dispatch(closeForm());
		setOpenSwitchForm(false);
	};

	const handleAccountChange = (account: BankAccount) => {
		dispatch(setAccount(account));
		closeSwitchForm();
	};

	return (
		<>
			<SwitchAccountForm
				open={openSwitchForm}
				onClose={closeSwitchForm}
				onChooseAccount={handleAccountChange}
				type={type}
			/>
			<ProductCard
				contentSpaceAround={false}
				sizesClass='account'
				title={
					<Grid container flexDirection='column' flex={1} id='account-box-details'>
						<Grid item container>
							<Grid item container xs={9}>
								<Grid item xs={12}>
									<MuiTextAccountBoxHeader fontSize='1.125rem' lineHeight='1.625rem'>
										{bankAccountName}
									</MuiTextAccountBoxHeader>
								</Grid>
								<Grid item xs={12} pb={5} display='flex' alignItems='center'>
									{user.hideValues ? hiddenBabalance :
										<DiplayBigNumber
											prefix={<Typography variant='highlight' sx={{fontSize: {xs: '1.2rem', md: '1.625rem' }}}>
												{`${bankAccount.currencySymbol ?? getCurrencySign(bankAccount.currency)}`}
											</Typography>}
											amount={bankAccount.availableBalance}
										/>}
									{allowToSwitchAccount && <KeyboardArrowDownIcon sx={(theme) => ({ color: theme.palette.text.primary })} />}
								</Grid>
							</Grid>
							<Grid item xs={3} justifyContent='flex-end' container>
								<Grid item>
									<TokenAvatar logoSource={bankAccount.currencyLogo} variant={TokenAvatarSizeEnum.large} />
								</Grid>
							</Grid>
						</Grid>
						<Grid item alignItems='flex-end' >
							<MuiTextLabel color='text.primary' fontWeight='700' sx={{ opacity: 0.7 }}>{t('cards.balanceOnHold')}
								{user.hideValues ? hiddenBabalance : 
								<NumberFormat displayType={'text'} 
								    decimalScale={2} 
								    fixedDecimalScale={true} 
									thousandsGroupStyle='thousand' 
									thousandSeparator={true} 
									prefix={`${getCurrencySign(bankAccount.currency)} `}
									value={Math.abs(bankAccount.balanceOnHold)} />
								}
							</MuiTextLabel>
						</Grid>
					</Grid>}
				selected={selected}
				actionButtons={[]}
				onClick={handleSwitchForm}
			/>
		</>
	);
};

export default AccountCard;
