 import * as React from 'react';
import FormContentBox from '../Box/FormContentBox';
import './PanelContent.css';


interface Props {
	children?: JSX.Element | JSX.Element[];
}

const PanelContent = (props: Props) => { 

	return (
		<FormContentBox id='panelContent' className='dialogPanelContent' >
			{props.children}
		</FormContentBox>
	);
};

export default PanelContent;
