import React, { FC } from 'react';
import { Checkbox, CheckboxProps, Theme, useTheme, } from '@mui/material';


const StyledCheckbox: FC<CheckboxProps> = (props: CheckboxProps) => {

	const theme = useTheme() as Theme;

	return (<Checkbox
		disableRipple
		sx={{
			padding: '0px 9px',
			color: theme.highlight,
			'&.Mui-checked': {
				color: theme.highlight,
			},
			'.MuiSvgIcon-root': {
				color: 'unset',
			}
		}}
		{...props}
	/>
	);
};

export default StyledCheckbox;
