
import { Autocomplete, Box, Button, Grid, TextField, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { useLatinName } from '@/helpers/customHook/useValidations';
import { alpha2ToAlpha3, getNames } from 'i18n-iso-countries';
import MuiFormText from '../Shared/Widgets/MuiText/MuiFormText';
import { saveUserInformation } from '@/features/user/userSlice';
import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getAllowedCountries } from '@/features/countries/slice';

interface Props {
	onComplete: () => void,
}

const IdentityOnfido = ({ onComplete }: Props) => {

	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const theme = useTheme();

	const allCountriesList: any = getNames('en'); 
	const allowedCountries = useSelector(
		(state: RootState) => state.allowedCountries
	);
	const countriesList = Object.keys(allCountriesList).reduce((obj, key) => { if (allowedCountries.list.includes(key)) { obj[key] = allCountriesList[key]; } return obj; }, {});

	const countryOptions =
		Object.keys(countriesList)
			.map($code => ({
				code: $code,
				label: countriesList[$code],
			}));
 

	useEffect(() => {
		dispatch(getAllowedCountries());
	}, [dispatch]); 


	const initialValues = {
		firstName: '',
		lastName: '',
		country: '',
	};
 

	const validationSchema = Yup.object({
		firstName: useLatinName(t('login.v3.signUpFields.namePlaceholder')),
		lastName: useLatinName(t('login.v3.signUpFields.lastName')),
		country: Yup.string()
			.required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin')),
	});


	const handleComplete = () => {
		onComplete();
	};


	const submit = async (formData) => {
		const { firstName, lastName, country } = formData;
		const countryAbrr = alpha2ToAlpha3(country);
		await saveUserInformation(
			firstName,
			lastName,
			countryAbrr
		);
		handleComplete();
	};


	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={submit}
		>
			{({ errors, isSubmitting, dirty, setFieldValue, touched }) => (
				<Form style={{ width: '100%' }} autoComplete='off'>
					<Grid container rowSpacing={1}>
						<Grid item xs={12}>
							<MuiFormText mb='30px'>{t('landing.identityStep.details')}</MuiFormText>
						</Grid>
						<Grid item xs={12}>
							<MuiTextField
								className='registration-input'
								name="firstName"
								label={t('login.v3.signUpFields.namePlaceholder')}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<MuiTextField
								className='registration-input'
								name="lastName"
								label={t('login.v3.signUpFields.lastName')}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Autocomplete
								id="country-autocomplete"
								options={countryOptions}
								onChange={(e, value) => setFieldValue('country', typeof value === 'object' ? value.code : '')}
								disableClearable
								fullWidth
								sx={{ width: '100%', display: 'flex', alignContent: 'center' }}
								isOptionEqualToValue={(option, value) => value === undefined || option.code === value.code}
								renderOption={(props, option) => (
									<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
										<img
											loading="lazy"
											width="20"
											src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
											srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
											alt=""
										/>
										{option.label}
									</Box>
								)}
								renderInput={(params) => (
									<TextField
										className='registration-input country-selection'
										fullWidth
										name="country"
										label={t('landing.identityStep.countryOfIssue')}
										{...params}
										inputProps={{
											...params.inputProps,
											autoComplete: 'off',
										}}
										InputLabelProps={{ style: { color: theme.palette.text.primary, textAlign: 'left' } }}
										error={errors.country && touched.country}
									/>
								)}

							/>
						</Grid>
					</Grid>
					<Grid item xs={12} mt={'50px'} >
						<Button
							type="submit"
							fullWidth
							variant="contained"
							disabled={!isEmpty(errors) || !dirty || isSubmitting}
						>
							{t('form.buttons.next')}
						</Button>
					</Grid>

				</Form>
			)}
		</Formik>
	);
};

export default IdentityOnfido;
