import * as React from 'react';
import { SvgIcon } from '@mui/material';

const MenuTokenIcon = (props) => (
    <SvgIcon {...props} >
        <svg width="22" height="22" viewBox="0 0 22 22" fill="current" stroke="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.028.52a3.882 3.882 0 00-3.883 0L2.941 4.102A3.882 3.882 0 001 7.464v7.165c0 1.386.74 2.668 1.941 3.362l6.204 3.582a3.882 3.882 0 003.883 0l6.204-3.582a3.882 3.882 0 001.941-3.362V7.464c0-1.387-.74-2.668-1.94-3.362L13.027.52zm5.234 5.263l-6.205-3.582a1.941 1.941 0 00-1.94 0L3.911 5.783a1.95 1.95 0 00-.07.043l4.363 2.619a3.872 3.872 0 012.882-1.28c1.143 0 2.17.493 2.881 1.28l4.364-2.619a1.98 1.98 0 00-.07-.043zM2.942 14.63v-7.08l4.362 2.619a3.884 3.884 0 002.812 4.639v5.085L3.912 16.31c-.6-.347-.97-.988-.97-1.681zm9.115 5.263l6.205-3.582c.6-.347.97-.988.97-1.681v-7.08l-4.363 2.619a3.884 3.884 0 01-2.812 4.639v5.085zm-.97-6.904a1.941 1.941 0 100-3.883 1.941 1.941 0 000 3.883z"
                fill="current"
            />
        </svg>
    </SvgIcon>
);

export default MenuTokenIcon;
