/* eslint-disable react/prop-types */
import { Typography, TypographyProps } from '@mui/material';
import React, { FC } from 'react';

const MuiSubHeader: FC<TypographyProps> = (props) => {

	return (
		<Typography
			fontSize='1rem'
			fontWeight='700'
			lineHeight='1.5rem'
			color='text.primary'
			style={{ wordWrap: 'break-word' }}
			whiteSpace='pre-line' //this wrap text 
			{...props}>
			{props.children}
		</Typography >
	);
};

export default MuiSubHeader;
