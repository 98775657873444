import { Typography, TypographyProps } from '@mui/material';
import React, { FC } from 'react';

const MuiTextAccountBoxHeader: FC<TypographyProps> = (props: TypographyProps) => {
	return (
		<Typography  
			fontSize='0.875rem' 
			fontWeight='700'
			lineHeight='1.125rem' 
			textAlign='start' 
			{...props}>
			{props.children}
		</Typography>
	);
};

export default MuiTextAccountBoxHeader;
