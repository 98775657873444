import { Alert, Button, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import BaseBox from '@/componentsMui/Shared/Widgets/Box/BaseBox';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface Props {
	onBack: () => void,
	adminCode: string,
}

const AdminInviteSecondStep = ({ onBack, adminCode }: Props): React.ReactElement => {

	const closeModal = useCloseDialogNoEvent();
	const { t } = useTranslation('translations');


	return (
		< Grid id='deposit-select-asset'
			container
			flex={1}
			direction='column'
			minHeight='0'
			flexWrap='nowrap'
			rowSpacing={2}>
			<Grid item >
				<FormHeader title={t('employee.invite.adminTitle')} onBack={onBack} />
			</Grid>
			<Grid item xs={12} mt='1rem' >
				<Alert severity="warning"  >
					<MuiFormText >{t('employee.invite.adminWarningTextSecondStep')}</ MuiFormText>
				</Alert>
			</Grid>
			<Grid item xs={12} mt={2}>
				<BaseBox padding='0.625rem 1.25rem'>
					<Grid container justifyContent='space-between'>
						<Grid item xs={8} display='flex' alignItems='center' >
							<MuiFormText>{adminCode}</MuiFormText>
						</Grid>
						<Grid item >
							<CopyToClipboard text={adminCode}>
								<Button variant='contained'
									color='secondary'> {t('form.buttons.copy')}
								</Button>
							</CopyToClipboard >
						</Grid>
					</Grid>
				</BaseBox>
			</Grid>

			<Grid item xs={12} flex={1} alignItems='flex-end' justifyContent='space-between' mt={8} display='flex'>
				<Button
					onClick={closeModal}
					variant='customOutlined'
				>{t('form.buttons.close')}</Button>
			</Grid>

		</ Grid >
	);
};

export default AdminInviteSecondStep;
