import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import TrxStatus from './TrxStatus';
import { TransactionType } from '@/features/transactions/types';

const TrxStatusCell = (params: GridRenderCellParams,
    accountSubProcesses: any) => {

    const transaction = params.row;
 
    if (!transaction?.status || (!transaction?.documentId && transaction?.activity === TransactionType.FX_TRANSACTION) || !transaction?.activity) {
        return '';
    }

    return (
        <TrxStatus
            transactionStatus={transaction.status as string}
            documentId={transaction.documentId as number}
            activity={transaction.activity as string}
            accountSubProcesses={accountSubProcesses} />
    );
};

export default TrxStatusCell;
