import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { AvailablePlan } from '@/features/user/types';
import { RootState } from '@/rootReducer';
import { Button, Chip, Grid, IconButton, Theme, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MyFees from '../MyFees/MyFees';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import NumberFormat from 'react-number-format';
import { getCurrencySign } from '@/features/fees/slice';
import { formatDateWithPattern } from '@/helpers/date';
import PlanPaymentForm from '../Profile/Plans/PlanPaymentForm';
import PlanCard from './PlanCard';
import { useTranslation } from 'react-i18next';
import BackIcon from '@/componentsMui/Shared/CustomIcons/BackIcon';
import _ from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        backgroundColor: '#1D2024',
        padding: '30px',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px'
    },
    planName: {
        fontSize: '1.625rem',
        fontWeight: 700,
        marginBottom: '5px'
    },
    planPrice: {
        fontSize: '1rem',
        fontWeight: 400,
        color: theme.palette.text.secondary
    },
    chip: {
        height: '35px',
        '& .MuiChip-label': {
            fontSize: '0.875rem',
            color: theme.palette.text.primary,
            background: 'unset',
            WebkitTextFillColor: 'unset'
        }
    }
}));



const Plans = (): React.ReactElement => {
    const { t } = useTranslation('translations');
    const theme = useTheme() as Theme;
    const classes = useStyles(theme);
    const smallScreen = useMediaQuery('(max-width:600px)');

    const { user } = useSelector((state: RootState) => state.user);

    const [selectedPlan, setSelectedPlan] = useState<AvailablePlan>(user.plan?.availablePlans.filter(plan => plan.userLevelId === user?.plan?.currentUserLevel.id)[0] || null);
    const [overview, setOverview] = useState<boolean>(true);
    const [openPaymentForm, setOpenPaymentForm] = useState<boolean>(false);

    const isCurrent = selectedPlan.userLevelId === user?.plan?.currentUserLevel.id;
    const nextPlan = user.plan?.nextPlan;
    const isNextPlan = !_.isEmpty(nextPlan) && nextPlan.userLevelId === selectedPlan.userLevelId;

    const closePaymentForm = () => {
        // dispatch(closeForm());
        setOpenPaymentForm(false);
    };

    const handleOnPlanClick = (p: AvailablePlan) => {
        setSelectedPlan(p);
        if (smallScreen) {
            setOverview(false);
        }
    };

    return (<Grid container>
        <PlanPaymentForm newPlan={selectedPlan} open={openPaymentForm} onClose={closePaymentForm} />
        <Grid item xs={12} alignItems='center' mb='30px' >
            <MuiHeader>{t('plans.v3.title')}</MuiHeader>
        </Grid>

        {user.plan?.availablePlans
            ?
            <>
                {((smallScreen && overview) || !smallScreen) && <Grid container item xs={12} md={3} alignContent='flex-start' rowGap={2}>
                    {user.plan?.availablePlans.map((p: AvailablePlan) => (
                        <PlanCard
                            onClick={() => handleOnPlanClick(p)}
                            key={p.userLevelId}
                            plan={p}
                            currentPlan={p.userLevelId === user.plan?.currentUserLevel.id}
                            selected={p.userLevelId === selectedPlan.userLevelId}
                            nextPlan={user.plan?.nextPlan}
                        />
                    ))}
                </Grid>}
                {((smallScreen && !overview) || !smallScreen) && <Grid container item xs={12} md={9} justifyContent='space-between'>
                    {smallScreen && <Grid item xs={12} onClick={() => setOverview(true)} sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <IconButton sx={{ pl: 0, pr: 0.5 }} size='small' >
                            <BackIcon className="stroke" sx={{ stroke: theme.palette.text.secondary }} />
                        </IconButton>
                        <MuiHeader fontSize='1rem' color='text.secondary' fontWeight={500}> {t('cards.businessCards.backToOverview')}
                        </MuiHeader>
                    </Grid>}
                    <Grid item container xs={12} className={classes.form} mb={2}>
                        <Grid item xs={8}>
                            <MuiTextCaption className={classes.planName}>{selectedPlan.name}</MuiTextCaption>
                            <MuiTextCaption className={classes.planPrice}>{selectedPlan.price === null || selectedPlan.price === 0 ? t('plans.v3.free') :
                                <NumberFormat
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandsGroupStyle='thousand'
                                    thousandSeparator={true}
                                    value={selectedPlan.price}
                                    prefix={getCurrencySign(selectedPlan.ccy)}
                                    suffix={t('plans.v3.mo')}
                                />}
                            </MuiTextCaption>
                        </Grid>

                        <Grid container item xs={4} >
                            <Grid item xs={12} justifyContent='flex-end' display='flex'>
                                {isCurrent
                                    ?
                                    <Chip label={t('plans.v3.currentPlan')} color="primary" className={classes.chip} />
                                    :
                                    <Button variant='contained' sx={{ height: '35px' }} disabled={isNextPlan} onClick={() => setOpenPaymentForm(true)}>{t('plans.v3.change')}</Button>}
                            </Grid>
                            <Grid item xs={12} justifyContent='flex-end' display='flex'>
                                {user?.plan?.nextPlan && user?.plan?.nextPlan.userLevelId === selectedPlan.userLevelId &&
                                    <MuiTextCaption>{t('plans.v3.nextPlan', { date: formatDateWithPattern(user?.plan?.nextPlan.startTime, 'dd-MM-yy') })}</MuiTextCaption>}
                            </Grid>
                        </Grid>

                        <Grid item xs={12} mt='65px'>
                            <MyFees userLevelId={selectedPlan.userLevelId} />
                        </Grid>
                    </Grid>
                </Grid>}
            </>
            : <Grid item xs={12} display='flex' justifyContent='center' mt='40px'>{t('plans.v3.noPlans')}</Grid>}


    </Grid>);

};

export default Plans;
