import { BankAccount } from '@/features/account/types';
import { Contact } from '@/features/contacts/types';
import { SystemType } from '@helpers/globalTypes';

export enum PaymentTargetType {
	IBAN = 'IBAN', SORT_CODE = 'SORT_CODE'
}


export interface PaymentPayloadType {
	proc?: string,
	account?: BankAccount,
	amount?: number,
	fee?: number,
	youpay?: number,
	to?: string,
	contact?: Contact,
	beneficiaryData?: any,
	iban?: string,
	country?: string,
	reference?: string,
	identification?: string,
	addToContacts?: boolean,
	selectedFile?: any,
	isToBusiness?: boolean,
	sortCode?: string,
	accountNumber?: string,
	paymentTarget?: PaymentTargetType,
	paymentType?: SystemType,
	isUsSwift?: boolean,
	usSwiftCountry?: { label: string, code: string },
	mandatoryAttachementAmount?: number
}

export const defaultPaymentPayload: PaymentPayloadType =
{
	proc: null,
	account: null,
	amount: null,
	fee: null,
	youpay: 0,
	to: null,
	contact: null,
	beneficiaryData: null,
	iban: null,
	country: null,
	reference: '',
	identification: '',
	addToContacts: false,
	selectedFile: null,
	isToBusiness: false,
	sortCode: null,
	accountNumber: null,
	paymentTarget: PaymentTargetType.IBAN,
	paymentType: SystemType.SWIFT,
	isUsSwift: false,
	usSwiftCountry: null
};

export interface ValidationPayloadType {
	name: string,
	business: boolean,
	sortCode: string,
	accountNumber: string,
	identification: string,
}

export interface UkPaymentValidationType {
	requestReference: string;
	matched: boolean;
	expirationTimestamp: string;
	falseMatchDetails: {
		code: string;
		description: string;
		name: string;
	};
}
