
import ErrorBoundary from '@/componentsMui/ErrorBoundary';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import React from 'react';
import LoadingPageMui from '@/pages/LoadingPageMui';
import ChooseAddressCardStep from './ChoooseAddressCardStep';
import { Address, PotentialAddress } from '@/features/card/types';
import { Grid } from '@mui/material';

interface Props {
    integration: string,
    title?: string,
    potentialAddresses: PotentialAddress[],
    deliveryAddress: Address,
    setDeliveryAddress: (value: Address) => void,
    onPrev: () => void,
    onNext: () => void
}
const CardAddress = ({ integration, title, deliveryAddress, potentialAddresses, setDeliveryAddress, onPrev, onNext }: Props) => {

    const render = (status) => {
        switch (status) {
            case Status.LOADING:
                return <LoadingPageMui />;
            case Status.FAILURE:
                return <ErrorBoundary />;
            case Status.SUCCESS:
                return <ChooseAddressCardStep
                    prevStep={onPrev}
                    nextStep={onNext}
                    integration={integration}
                    addresses={potentialAddresses}
                    title={title}
                    deliveryAddress={deliveryAddress}
                    setDeliveryAddress={setDeliveryAddress}
                />;
        }
    };


    return (
        <Grid container flex={1}>
            <Wrapper apiKey={process.env.REACT_APP_GOOGLE_API_KEY} render={render} key={'map'} libraries={['maps', 'marker', 'core']} />
        </Grid>);
};

export default CardAddress;
