import * as React from 'react';

import { Dialog, Grid, IconButton, ListItemText, MenuList } from '@mui/material';
import StyledMenuItemModal from '@/componentsMui/Shared/Widgets/MenuItem/StyledMenuItemModal';

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { useDispatch, useSelector } from 'react-redux';
import { openForm } from '@/features/forms/slice';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import StyledListItemIcon from '@/componentsMui/Shared/Widgets/StyledListItemIcon';


import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PhoneIcon from '@mui/icons-material/Phone';
import NotesIcon from '@mui/icons-material/Notes';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import BlockIcon from '@mui/icons-material/Block';
import FreezeCardStep from '@/componentsMui/Dashboard/Create/Card/Steps/FreezeCardStep';
import CloseCardStep from '@/componentsMui/Dashboard/Create/Card/Steps/CloseCardStep';
import ChangePhoneNumberStep from '@/componentsMui/Dashboard/Create/Card/Steps/ChangePhoneNumberStep';
import RenameCardStep from '@/componentsMui/Dashboard/Create/Card/Steps/RenameCardStep';
import FormPaperLargeFixed from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeFixed';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import FormCloseButton from '@/componentsMui/Wallet/Balances/Deposit/Components/FormCloseButton';
import { hasSubprocessWithType } from '@/features/operations/slice';
import { CardSubProcesses } from '@/features/card/types';
import { RootState } from '@/rootReducer';
import ShowCardFeeStep from '@/componentsMui/Dashboard/Create/Card/Steps/ShowCardFeeStep';

enum Step {
	FREEZE = 'FREEZE', CLOSE = 'CLOSE', RENAME = 'RENAME', CHANGE_PHONE = 'CHANGE_PHONE', SHOW_FEES = 'SHOW_FEES'
}


const CardSettingsForm = () => {
	const { t } = useTranslation('translations');
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const [cardId] = useState<string>(searchParams.get('card'));
	const dispatch = useDispatch();
	const [step, setStep] = React.useState<Step>(Step.FREEZE);
	const [chooser, setChooser] = useState<boolean>(true);
	const closeModal = useCloseDialogNoEvent();

	const allCards = useSelector((state: RootState) => state.cards.list);
	const selectedCard = allCards.find(p => `${p.cardId}` === cardId);

	const closeCardEnabled = hasSubprocessWithType(
		CardSubProcesses.CARD_BLOCK_AND_PULL_OUT,
		selectedCard?.process.subProcesses
	) || hasSubprocessWithType(
		CardSubProcesses.CARD_BLOCK,
		selectedCard?.process.subProcesses
	);


	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);

	return (
		<Dialog
			id="topUpForm"
			open={true}
			PaperComponent={FormPaperLargeFixed}
		>
			{chooser &&
				<Grid item container>
					<Grid item container justifyContent='space-between'>
						<Grid item>
							<MuiHeader> {t('cards.cardSettings.settings')}</MuiHeader>
						</Grid>
						<Grid item>
							<IconButton onClick={closeModal}  >
								<MuiCloseIcon />
							</IconButton>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<MenuList role='menu' className='form-menu__list'>
							<StyledMenuItemModal
								unsetMarginBottom={true}
								id={'freezeCard'}
								onClick={() => { setChooser(false); setStep(Step.FREEZE); }}
							> 	<StyledListItemIcon> <AccessTimeIcon /> </StyledListItemIcon>
								<ListItemText className='money-form-list-item-text'>{t('cards.cardSettings.menu.freezeCard')}</ListItemText>
							</StyledMenuItemModal>
							{closeCardEnabled &&
								<StyledMenuItemModal
									unsetMarginBottom={true}
									id={'closeCard'}
									onClick={() => { setChooser(false); setStep(Step.CLOSE); }}
								> 	<StyledListItemIcon>  <BlockIcon />  </StyledListItemIcon>
									<ListItemText className='money-form-list-item-text'>{t('cards.cardSettings.menu.closeCard')}</ListItemText>
								</StyledMenuItemModal>
							}
							<StyledMenuItemModal
								unsetMarginBottom={true}
								key={'renameCard'}
								onClick={() => { setChooser(false); setStep(Step.RENAME); }}
							>
								<StyledListItemIcon> <DriveFileRenameOutlineIcon /> </StyledListItemIcon>
								<ListItemText className='money-form-list-item-text'>{t('cards.cardSettings.menu.renameCard')}</ListItemText>
							</StyledMenuItemModal>
							<StyledMenuItemModal
								unsetMarginBottom={true}
								key={'changePhoen'}
								onClick={() => { setChooser(false); setStep(Step.CHANGE_PHONE); }}
							>
								<StyledListItemIcon> <PhoneIcon /> </StyledListItemIcon>
								<ListItemText className='money-form-list-item-text'>{t('cards.cardSettings.menu.changePhoneNumber')}</ListItemText>
							</StyledMenuItemModal>
							{selectedCard?.currency &&
								selectedCard?.process?.proc &&
								<StyledMenuItemModal
									unsetMarginBottom={true}
									key={'showFees'}
									onClick={() => { setChooser(false); setStep(Step.SHOW_FEES); }}
								//onClick={() => { history.push(`/wallet/account?tab=feeLimits&section=${FeeSection.CARD}&product=${selectedCard.currency}-${selectedCard.process.proc}`); }}
								>
									<StyledListItemIcon> <NotesIcon /> </StyledListItemIcon>
									<ListItemText className='money-form-list-item-text'>{t('cards.cardSettings.menu.showFees')}</ListItemText>
								</StyledMenuItemModal>
							}
						</MenuList>
					</Grid>
					<FormCloseButton onClose={closeModal} />
				</Grid>
			}

			{!chooser && step === Step.FREEZE && <FreezeCardStep cardId={parseInt(cardId)} onBack={() => setChooser(true)} onClose={closeModal} />}
			{!chooser && step === Step.CLOSE && <CloseCardStep cardId={parseInt(cardId)} onBack={() => setChooser(true)} onClose={closeModal} />}
			{!chooser && step === Step.CHANGE_PHONE && <ChangePhoneNumberStep onBack={() => setChooser(true)} onClose={closeModal} />}
			{!chooser && step === Step.RENAME && <RenameCardStep cardId={parseInt(cardId)} onBack={() => setChooser(true)} onClose={closeModal} />}
			{!chooser && step === Step.SHOW_FEES && <ShowCardFeeStep currency={selectedCard?.currency} process={selectedCard?.process?.proc} onBack={() => setChooser(true)} onClose={closeModal} />}

		</Dialog >
	);
};


export default CardSettingsForm; 
