import { Button, Dialog, Grid, IconButton, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import './ExportStatementForm.css';
import LoadingPopup from '@/componentsMui/Shared/Widgets/LoadingPopup';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import useTransactionDownload from '@/helpers/customHook/useTransactionDownload';
import { ExportType } from '../TransactionStatements.spec';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { endOfMonth, getTime, startOfMonth } from 'date-fns';
import { AccountType } from '@/features/operations/types';
import CurrencySwitch from '@/componentsMui/Wallet/Components/CurrencySwitch';
import { WalletTransactionType } from '@features/walletHistory/types';
import PdfViewerFullScreen from '@/componentsMui/Shared/Components/PdfViewerFullScreen';

interface Props {
	type: AccountType,
	open: boolean,
	onClose: () => void,
	walletTransactionType: WalletTransactionType,
}

const WalletExportStatementForm = ({ type, open, onClose, walletTransactionType }: Props) => {
	const { t } = useTranslation('translations');
	const [exportType, setExportType] = useState<ExportType>(ExportType.EXCEl);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [walletCurrency, setWalletCurrency] = React.useState<string>('EUR');
	const from = getTime(startOfMonth(startDate));
	const to = getTime(endOfMonth(endDate));
	const [openPreview, setOpenPreview] = useState(false);

	const getLast24Month = () => {
		const d = new Date();
		d.setMonth(d.getMonth() - 24);
		return new Date(d.getFullYear(), d.getMonth(), 1);
	};
	const searchPayload = {
		type: type,
		from: from.toString(),
		to: to.toString(),
		search: '',
		ccy: type === AccountType.WALLET && walletTransactionType === WalletTransactionType.TRANSACTIONS && exportType === ExportType.PDF ? walletCurrency : undefined,
		walletTransactionType,
	};

	const { loading, previewFile, getPdfStatementPreview, downloadExcelTrx, fileName } = useTransactionDownload(exportType, searchPayload);

	const handleTypeChange = (event: React.MouseEvent<HTMLElement>, value: ExportType,) => {
		if (value) {
			setExportType(value);
		}
	};

	const handlePreviewPdf = async () => {
		try {
			await getPdfStatementPreview();
			setOpenPreview(true);
		}
		catch (e) { setOpenPreview(false); }
	};

	const handleDownloadTransactions = async () => {

		if (exportType === ExportType.PDF) {
			await handlePreviewPdf();
		}
		else {
			await downloadExcelTrx();
		}
	};

	return (
		<LocalizationProvider dateAdapter={DateAdapter}>
			<PdfViewerFullScreen
				file={previewFile}
				savedFileName={fileName}
				isOpen={openPreview}
				onClose={() => setOpenPreview(false)} />
			<Dialog
				id="statement-download-form"
				open={open}
				fullWidth
				maxWidth="sm"
			>
				<LoadingPopup open={loading} messsage={t('transactiondetails.message.preprare')} />
				<Grid container className='download-type' sx={{ p: { xs: '1rem', md: '2rem' } }}>
					<Grid container justifyContent="space-between" alignItems="center" pb={6}>
						<Grid item xs={10}>
							<MuiHeader>{t('transaction.header.exportbutton')}</MuiHeader>
						</Grid>
						<Grid item xs={2} justifyContent='flex-end' display='flex'>
							<IconButton onClick={onClose}  >
								<MuiCloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					{walletTransactionType === WalletTransactionType.TRANSACTIONS && <Grid item xs={12} pb={2} >
						<ToggleButtonGroup
							className='type-btn-group'
							value={exportType}
							exclusive
							fullWidth
							onChange={handleTypeChange}
						>
							<ToggleButton className='type-btn' value={ExportType.PDF}>{t('transaction.export.pdf')}</ToggleButton>
							<ToggleButton className='type-btn' value={ExportType.EXCEl}>{t('transaction.export.excel')}</ToggleButton>
						</ToggleButtonGroup>
					</Grid>}
					{(walletTransactionType === WalletTransactionType.TRANSACTIONS && exportType === ExportType.PDF) && <Grid item xs={12} pb={2} >
						<CurrencySwitch label={t('transaction.export.ccy')} variant={'outlined'} setCurrency={(value) => setWalletCurrency(value)} defaultCurrency={walletCurrency} />
					</Grid>}
					<Grid item xs={12} pb={4}>
						<Grid item container className='month-selection' spacing={2}>
							<Grid item xs={12} sm={6} >
								<DatePicker
									disableFuture={true}
									label={t('transaction.labels.starton')}
									views={['year', 'month']}
									minDate={getLast24Month()}
									maxDate={endDate ?? new Date()}
									value={startDate}
									onChange={(date) => {
										setStartDate(date);
									}}
									renderInput={(params) => <TextField {...params} helperText={null} />}
								/>
							</Grid>
							<Grid item xs={12} sm={6} >
								<DatePicker
									disableFuture={true}
									label={t('transaction.labels.endon')}
									views={['year', 'month']}
									minDate={startDate ?? getLast24Month()}
									maxDate={new Date()}
									value={endDate}
									onChange={(date) => {
										setEndDate(date);
									}}
									renderInput={(params) => <TextField {...params} helperText={null} />}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} alignSelf='flex-end'  >
						<Button
							className='month-btn'
							type="submit"
							variant="contained"
							color='primary'
							fullWidth
							onClick={handleDownloadTransactions}
						>
							{t('form.buttons.export', { type: exportType })}
						</Button>
					</Grid>


				</Grid>

			</Dialog >
		</LocalizationProvider>);
};

export default WalletExportStatementForm;
