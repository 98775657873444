import { Box as MuiBox, Box, Grid, Theme } from '@mui/material';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import { timeSince } from '@/helpers/date';
import { TransactionStatus } from '@/features/transactions/types';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import './TradeHistory.css';
import { useTranslation } from 'react-i18next';
import TransactionStatusBox from '@/componentsMui/Shared/Widgets/Box/TransactionStatusBox';
import { WalletTransaction } from '@/features/walletHistory/types';
import MuiTextTrx from '@shared/Widgets/MuiText/MuiTextTrx';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import MuiTextTrxSmall from '@shared/Widgets/MuiText/MuiTextTrxSmall';
import { isFiat } from '@/helpers/currency';

interface Props {
	item: WalletTransaction,
	onClick?: (trx: WalletTransaction) => void,
}
const TradeHistoryLineMobile = ({ item, onClick }: Props) => {
	const theme = useTheme() as Theme;
	const { t } = useTranslation('translations');
	const time = item?.date && new Date(item.date);

	const isSell = item.amount < 0;
	const pair = item.isBaseCurrency ? `${item.currency}-${item.conversionCurrency}` : `${item.conversionCurrency}-${item.currency}`;
	
	return (
		<Box onClick={() => onClick(item)}
			 pb='1rem'
			 // sx={{ backgroundColor: theme.bgSecondaryButton }}
			 borderRadius='10px'
		>

			 <Grid item xs={12} container alignItems='center' columnSpacing={1} wrap='nowrap'>
				<Grid item justifyContent='flex-start' alignItems='center'>
					<MuiBox sx={{
						borderRadius: theme.mainBorderRadius,
						background: theme.form.bgDefault,
					}}
							className='transaction-icon'
					>
						{isSell
							? <ArrowUpward sx={{ fill: theme.palette.text.primary }} />
							: <ArrowDownward sx={{ fill: theme.palette.text.primary }} />
						}
					</MuiBox >
				</Grid>
				<Grid item
					  display='flex'
					  direction='column'
					  justifyContent='space-evenly'
					  maxWidth={'100%'}
				>
					<Grid item xs={12}>
						<MuiTextCaption variant='gradient' fontWeight='400'>
							{pair}
						</MuiTextCaption>
					</Grid>
					<Grid item xs={12} justifyContent={'flex-end'}>
						<MuiTextTrxSmall color='text.secondary'>
							{timeSince(time)}
						</MuiTextTrxSmall>
					</Grid>
				</Grid>
				<Grid item
					  ml='auto'
					  display='flex'
					  direction='column'
					  justifyContent='space-evenly'
				>
					<Grid>
						<MuiTextTrx textAlign='right' fontWeight={700}>
							<NumberFormat displayType={'text'}
										  decimalScale={isFiat(isSell ? item.currency : item.conversionCurrency) ? 2 : 5}
										  fixedDecimalScale={false}
										  thousandsGroupStyle='thousand'
										  thousandSeparator={true}
										  value={isSell ? Math.abs(item.amount) : Math.abs(item.conversionAmount)}
										  suffix={isSell ? ` ${item.currency}` : ` ${item.conversionCurrency}`} />
						</MuiTextTrx>
					</Grid>
					<Grid container justifyContent='flex-end'>
						<TransactionStatusBox
							status={item.status as TransactionStatus}>
							{t('status.' + item.status)}
						</TransactionStatusBox>
					</Grid>
				</Grid>
			</Grid>
		</Box>);
};

export default TradeHistoryLineMobile;
