import { BulkPaymentsUploadStatus } from '@/features/bulkpayments/bulkPaymentsTypes';
import { Theme, Typography, useTheme, Box as MuiBox } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';

const UploadStatusCell = (params: GridRenderCellParams) => {
    const { t } = useTranslation('translations');
    const theme = useTheme() as Theme;
    if (params.value === null || params.value === undefined) {
        return '';
    }

    const status = params.value as BulkPaymentsUploadStatus;

    const statusColor = () => {
        let statusColor: string;
        switch (status) {
            case BulkPaymentsUploadStatus.ERROR:
                statusColor = theme.statusColor.lightRed;
                break;
            case BulkPaymentsUploadStatus.COMPLETED:
            case BulkPaymentsUploadStatus.PARTIALLY_COMPLETED:
                statusColor = theme.statusColor.success;
                break;
            case BulkPaymentsUploadStatus.VALID:
            case BulkPaymentsUploadStatus.PROCESSING:
            default:
                statusColor = theme.statusColor.warning;
                break;
        }
        return statusColor;
    };



    return (<MuiBox
        sx={{
            borderRadius: '10px',
            background: `${statusColor}14`,
            padding: '0px 10px',
            width: 'fit-content',
            minWidth: '4rem',
            height: '1.5rem',
            cursor: 'default',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}
    >
        <Typography
            fontWeight={700}
            fontSize={'0.563rem'}
            sx={{ color: statusColor }} >
            {t(`bulkpayments.statuses.${params.value}`)}
        </Typography>
    </MuiBox>);
};

export default UploadStatusCell;
