import * as React from 'react';
import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { SystemType } from '@/helpers/globalTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import useCloseDialog from '@/helpers/customHook/useCloseDialog';
import { openForm } from '@/features/forms/slice';
import DialogWithMenu from '@/componentsMui/Shared/Widgets/Dialog/DialogWithMenu';
import { hasSubprocessWithType } from '@/features/operations/slice';
import UserPaymentSteps from '../../../Shared/Components/SendMoney/PaymentToOtherUser/UserPaymentSteps';
import NewPaymentSteps from '@shared/Components/SendMoney/NewPayment/NewPaymentSteps';
import FormPaperLargeFixed from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeFixed';
import { ContactPaymentTypes } from '@/features/contacts/types';
import { useTranslation } from 'react-i18next';
import { CoinType } from '@features/coins/types';
import { BankAccount } from '@features/account/types';
import Spinner from '@shared/Widgets/Spinner/Spinner';
import { allTokensList } from '@features/coins/slice';
import Withdraw from '@/componentsMui/Wallet/Balances/Send/Withdraw';


const SendMoneyToContactForm = () => {
	const location = useLocation();
	const { t } = useTranslation('translations');

	const searchParams = new URLSearchParams(location.search);
	const [contactId] = useState<string>(searchParams.get('contact'));
	const [contactType] = useState<string>(searchParams.get('type'));
	const [loading, setLoading] = useState<boolean>(true);
	const [defaultAccount, setDefaultAccount] = useState<BankAccount>(null);
	const [defaultCoin, setDefaultCoin] = useState<CoinType>(null);

	const dispatch = useDispatch();

	const bankAccounts = useSelector((state: RootState) => state.accounts.list);
	const trustAccounts = useSelector((state: RootState) => state.trust.accounts);
	const allAccounts = bankAccounts.concat(trustAccounts);

	const allContacts = useSelector((state: RootState) => state.contacts.contacts);
	const defaultContact = allContacts.find(c => c.beneficiaryAccountId === parseInt(contactId));

	const [step, setStep] = React.useState<number>(1);
	const nextStep = React.useCallback(() => setStep(previousState => (previousState + 1)), []);
	const prevStep = React.useCallback(() => setStep(previousState => (previousState - 1)), []);

	const paymentAccountList = allAccounts.filter(accounts => hasSubprocessWithType('TRANSFER', accounts.subProcesses)) || [];


	const closeModal = useCloseDialog();

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);

	useEffect(() => {
		const fetchWalletData = async () => {
			if (contactType === ContactPaymentTypes.LOCAL && defaultContact.clientId) {
				// lookup for account data by selected contact wallet ID
				// const accountData = await lookupContact(defaultContact.walletId);

				// // if account type is WALLET, TOKEN or CRYPTO, then take account data from token list
				// if (accountData.accountType === CoinTypeEnum.WALLET || accountData.accountType === CoinTypeEnum.TOKEN) {
				// 	const tokenList = await allTokensList();
				// 	const account = tokenList.list.find(p => p?.account?.currency === accountData.currency);
				// 	const coinAccount = account?.account ? { ...account.account, subProcesses: account.subProcesses, currencyLogo: account.info.logo } : undefined;
				// 	setDefaultAccount(coinAccount);
				// 	setLoading(false);
				// } else {
				// 	const account = paymentAccountList.find(p => p.currency === accountData.currency && p.type === defaultContact.type);
				// 	setDefaultAccount(account);
				// 	setLoading(false);
				// }

				setDefaultAccount(bankAccounts[0]);
				setLoading(false);

			} else if (contactType === ContactPaymentTypes.CRYPTO) {
				const tokenList = await allTokensList();
				const account = tokenList.list.find(p => p?.account?.currency === defaultContact.ccy);
				setDefaultCoin(account);
				setLoading(false);
			} else {
				setLoading(false);
			}
		};
		fetchWalletData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<DialogWithMenu
			id="sendMoneyForm"
			open={true}
			onClose={closeModal}
			PaperComponent={FormPaperLargeFixed}
		>
			{loading ?
				<Spinner /> : <>
					{contactType !== ContactPaymentTypes.LOCAL &&
						contactType !== ContactPaymentTypes.CRYPTO &&
						<NewPaymentSteps
							step={step}
							paymentName={t('fees.myFees.paymentTypes.' + contactType)}
							accountList={paymentAccountList}
							defaultContact={defaultContact}
							paymentType={contactType as SystemType}
							nextStep={nextStep}
							prevStep={prevStep}
						/>}
					{contactType === ContactPaymentTypes.LOCAL && step === 1 && <>
						<UserPaymentSteps
							defaultAccount={defaultAccount}
							step={1}
							defaultContact={defaultContact}
						/>
					</>}
					{contactType === ContactPaymentTypes.CRYPTO && step === 1 && <>
						<Withdraw defaultCoin={defaultCoin}
							defaultContact={defaultContact}
						/>
					</>}
				</>
			}
		</DialogWithMenu >
	);
};
export default SendMoneyToContactForm;

