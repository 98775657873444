import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import KYCOnfido from './KYCOnfido';
import { getToken, setToken } from '@/features/kyc/slice';
import { ActionsStatus } from './Onboarding';
import { allowToStartKYC } from '@/helpers/kyc';
import MuiFormText from '../Shared/Widgets/MuiText/MuiFormText';
import KYCSumsub from './KYCSumsub';
import { setLandingTitle } from '@/features/landingStep/slice';
import MinimalLayout from '../Layout/MinimalLayout';
import { skipKyc } from '@/features/user/userSlice';
import { useHistory } from 'react-router-dom';
import { HOME } from '@/pages/navigationConstants';
import MobileHeader from './components/MobileHeader';
import { ReactComponent as MuiUserFailed } from '@/assets/MuiUserFailed.svg';
import BackButtonWebLanding from './components/BackButtonWebLanding';
import Spinner from '../Shared/Widgets/Spinner/Spinner';
import SimpleTicketForm from '../Dashboard/FAQ/SimpleTicketForm';
import { connectChatUnreadCount, disconnectChatUnreadCount, subscribeChatUnreadCount, unsubscribeChatUnreadCount } from '@/features/supportChat/socketUnread';

interface Props {
	stepStatus: ActionsStatus,
	poaStatus: ActionsStatus,
	paddingTop?: number,
	kycStatus: string,
	skipAllowed?: boolean,
	allowToGoBack?: boolean,
	onContactSupport: () => void,
	next: () => void,
	goBack: () => void,
}

const DocumentVerification = ({ kycStatus, stepStatus, paddingTop, poaStatus, skipAllowed, allowToGoBack, onContactSupport, next, goBack }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const history = useHistory();
	const { unreadCount } = useSelector((state: RootState) => state.supportChat);
	const { token, loading } = useSelector((state: RootState) => state.kyc);
	const { user, status } = useSelector((state: RootState) => state.user);
	const [openTicket, setOpenTicket] = useState(false);
	const { isLegacySupport } = useSelector((state: RootState) => state.status);

	const kycService = status?.kycProvider;
	const kycAllowed = allowToStartKYC(user, kycService) && (kycService === 'onfido' || kycService === 'sumsub');
	const retryKyc = kycAllowed && kycStatus === ActionsStatus.FAILED;
	const kycFailed = kycStatus === ActionsStatus.FAILED;

	const title = t('landing.documentStep.title');

	useEffect(() => {
		dispatch(setLandingTitle(title));
	}, [dispatch, title]);

	useEffect(() => {
		if (kycAllowed || (kycService === 'sumsub' && stepStatus === ActionsStatus.NEW)) {
			dispatch(getToken(kycService));

		}
	}, [kycAllowed, dispatch, kycService, stepStatus]);

	useEffect(() => {

		if (stepStatus === ActionsStatus.COMPLETED) {
			if (poaStatus === ActionsStatus.COMPLETED) {
				history.push(HOME);
			}
			else {
				next();
			}
		}
	}, [next, stepStatus, history, poaStatus]);

	useEffect(() => {
		connectChatUnreadCount();
		dispatch(subscribeChatUnreadCount());
		return () => {
			unsubscribeChatUnreadCount();
			disconnectChatUnreadCount();
		};
	}, [dispatch]);



	const handleSkipKyc = useCallback(async () => {
		await skipKyc(true);
		history.push(HOME);
	}, [history]);

	const handleRetry = useCallback(() => {
		if (kycAllowed) {
			dispatch(getToken(kycService));
		}
	}, [dispatch, kycAllowed, kycService]);


	const handleOnfidoExist = useCallback(() => {
		dispatch(setToken(null));
	}, [dispatch]);


	const handleUserExist = useCallback(() => {
		goBack();
	}, [goBack]);

	const handleContactSupport = useCallback(() => {
		isLegacySupport ? setOpenTicket(true) : onContactSupport();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLegacySupport]);

	const Kyc = useCallback(() =>
		<Grid item xs={12} container direction='column' height='fit-content'>
			{kycService === 'onfido' && <KYCOnfido token={token} setCompleted={handleOnfidoExist} paddingTop={paddingTop} onUserCancel={handleUserExist} />}
			{kycService === 'sumsub' && <KYCSumsub token={token} onConctactSupport={handleContactSupport} />}
		</Grid>, [kycService, token, handleOnfidoExist, paddingTop, handleUserExist, handleContactSupport]);

	const FailedRetry = useCallback(() => <Grid container height='fit-content'>
		<Grid item xs={12} mt='3rem'> <MuiUserFailed /> </Grid>
		<Grid item xs={12} mt='31px'>
			<MuiFormText>
				{t('landing.documentStatus.failedTitle')}
			</MuiFormText>
		</Grid>

		<Grid item xs={12} mt='31px' columnGap={10} container height='fit-content' justifyContent='center' >
			{retryKyc ? <> <Button
				onClick={handleRetry}
				variant='contained' >
				{t('form.buttons.retry')}
			</Button>
				<Button
					onClick={handleSkipKyc}
					variant='customOutlined' >
					{t('form.buttons.close')}
				</Button>
			</> :
				<Button
					onClick={handleSkipKyc}
					variant='customOutlined' >
					{t('form.buttons.close')}
				</Button>
			}
		</Grid>
	</ Grid>, [t, retryKyc, handleRetry, handleSkipKyc]);

	const DefaultRetry = useCallback(() => <Grid container>
		<Grid item xs={12} mt='3rem'> <MuiUserFailed /> </Grid>
		<Grid item xs={12} mt='31px'>
			<MuiFormText>
				{t('errors.SOMETHING_WENT_WRONG_WITHOT_MESSAGE')}
			</MuiFormText>
		</Grid>
		<Grid item xs={12} mt='31px' columnGap={10} container height='fit-content' justifyContent='center' >
			{kycAllowed &&
				<Button
					onClick={handleRetry}
					variant='contained' >
					{t('form.buttons.retry')}
				</Button>
			}
		</Grid>
	</Grid>, [handleRetry, kycAllowed, t]);

	const showKyc = token && stepStatus !== ActionsStatus.INPROGRESS;
	const showVerifying = kycStatus === ActionsStatus.INPROGRESS;
	const showRetry = retryKyc || kycFailed;

	return (
		<MinimalLayout
			allowGoHome={false}
			paddingTop={paddingTop}
			leftPanel={((!showKyc && !showVerifying && skipAllowed) || kycService === 'sumsub') && allowToGoBack && <BackButtonWebLanding onClick={goBack} />}
			rightPanel={kycService === 'sumsub' &&
				<Grid width='100%'
					display={{ xs: 'none', md: 'flex' }}
					height='fit-content'
					justifyContent='flex-end'
					paddingRight='40px'>
					<Badge badgeContent={unreadCount > 0 ? unreadCount : undefined} color="primary">
						<Button
							onClick={handleContactSupport}
							variant='contained' >
							{t('home.contactSupport')}
						</Button>
					</Badge>
				</Grid>}
		>
			<SimpleTicketForm open={openTicket} showContactEmail={true} onClose={() => setOpenTicket(false)} />
			<Grid container id='document-step' flexDirection='column' minHeight='100%' height='100%'
				textAlign='center'
			>
				<Grid item width='100%' >
					<MobileHeader text={title} />
				</Grid>

				<Grid container item width='100%' >
					{loading && <Spinner />}
					{showKyc && <Kyc />}
					{/* {showVerifying && <Verifying />} */}
					{retryKyc || kycFailed && <FailedRetry />}
					{!showKyc && !showVerifying && !showRetry && !loading && <DefaultRetry />}
				</Grid>
				{/* {kycService === 'sumsub' &&
					<Grid item xs={12} width='100%'>
						<Button
							onClick={() => setOpenTicket(true)}
							variant='contained' >
							{t('home.contactSupport')}
						</Button>
					</Grid>
				} */}
			</Grid>
			{/* </Grid > */}
		</MinimalLayout>);
};

export default DocumentVerification;
