import { Dialog, DialogContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';

interface Props {
	open: boolean,
	messsage?: string
}

const LoadingPopup = ({ open, messsage }: Props) => {
	const { t } = useTranslation('translations');

	return (
		<Dialog
			id="loading-popup"
			open={open}
			fullWidth
			maxWidth="xs"
			PaperProps={{ sx: { bgcolor: 'rgba(33, 37, 42, 0.16)' } }}
		>
			<DialogContent>
				<Grid container height='232px' justifyContent='center'>
					<Grid item xs={12} mt='3.75rem'>
						<Spinner />
					</Grid>
					<Grid item>
						<Typography
							className='invert-at-form'
							textAlign='center'
							fontSize='0.875rem'
							lineHeight='1.5rem'
							fontWeight='600'
							color='text.primary'>
							{t('form.wait')}
						</Typography>
						{messsage &&
							<Grid item >
								<Typography
									className='invert-at-form'
									fontSize='0.875rem'
									lineHeight='1.5rem'
									fontWeight='600'
									color='text.primary'>
									{messsage}
								</Typography>
							</Grid>}
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog >);
};

export default LoadingPopup;
