import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, } from '@mui/material';
import { User, UserType } from '@/features/user/types';
import MuiTextAlert from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAlert';
import MuiTextConfirmationLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextConfirmationLabel';
import InputBox from '@/componentsMui/Shared/Widgets/Box/InputBox';
import LightDivider from '@/componentsMui/Shared/Widgets/LightDivider';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import ChangeEmailPhone from './ChangeEmailPhone';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';

interface Props {
	user: User
}

const UserDetails = ({ user }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [openForm, setOpenForm] = useState<boolean>(false);
	const [type, setType] = useState<'email' | 'phone'>('email');


	const handleChange = (value: 'email' | 'phone') => {
		setType(value);
		setOpenForm(true);
	};

	return (
		<InputBox sx={{ p: { xs: '1rem', md: '1.875rem' } }}>
			<ChangeEmailPhone
				open={openForm}
				onClose={() => setOpenForm(false)}
				type={type}
			/>
			<Grid container alignItems='center'>
				<Grid item xs={12} sm={3}>
					<MuiTextConfirmationLabel color='text.secondary'> {t('plans.plan')}</MuiTextConfirmationLabel>
				</Grid>
				<Grid item xs={12} sm={9}>
					<MuiTextAlert variant='gradient'> {user?.plan?.currentUserLevel?.name}</MuiTextAlert>
				</Grid>
				<Grid item xs={12}  >
					<LightDivider />
				</Grid>
				<Grid item xs={12} sm={3}>
					<MuiTextConfirmationLabel color='text.secondary'> {t('profile.data.email')}</MuiTextConfirmationLabel>
				</Grid>
				{user.type !== UserType.BUSINESS ? <Grid item xs={12} sm={9} display='flex' justifyContent='space-between'>
					<MuiTextCaption>{user?.email}</MuiTextCaption>
					<BorderColorOutlinedIcon sx={(theme) => ({ color: theme.palette.text.primary, width: '14px', height: '14px', cursor: 'pointer' })} onClick={() => handleChange('email')} />

				</Grid> : <MuiTextCaption>{user?.email}</MuiTextCaption>}
				<Grid item xs={12}  >
					<LightDivider />
				</Grid>
				<Grid item xs={12} sm={3}>
					<MuiTextConfirmationLabel color='text.secondary'> {t('profile.data.phone')}</MuiTextConfirmationLabel>
				</Grid>
				{user.type !== UserType.BUSINESS ? <Grid item xs={12} sm={9} display='flex' justifyContent='space-between'>
					<MuiTextCaption>{user?.mobileNumber}</MuiTextCaption>
					<BorderColorOutlinedIcon sx={(theme) => ({ color: theme.palette.text.primary, width: '14px', height: '14px', cursor: 'pointer' })} onClick={() => handleChange('phone')} />
				</Grid> : <MuiTextCaption>{user?.mobileNumber}</MuiTextCaption>}
			</Grid>
		</InputBox>
	);
};

export default UserDetails;

