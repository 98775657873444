import React from 'react';
import { User, UserType, V3SwitchOption } from '@/features/user/types';
import './SwitchUserDropdown.css';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useUserSwitch from '@/helpers/customHook/useUserSwitch';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SwitchUserForm from './SwitchUserForm';
import { useDispatch } from 'react-redux';
import { closeForm, openForm } from '@/features/forms/slice';
import UserAvatar from '../../Widgets/Avatars/UserAvatar';
import { capitalCaseString } from '@/helpers/normalizeString';

type Props = {
	user: User,
	onlyAvatar?: boolean,
	drawer?: boolean,
}

const SwitchUserDropdown = ({ user, onlyAvatar, drawer }: Props): React.ReactElement => {

	const { t } = useTranslation('translations');
	const [openSwitchForm, setOpenSwitchForm] = React.useState(false);
	const { handleSwitchUser, handleOnBehalfOfSwitch, unsetOnBehalfOfSwitch } = useUserSwitch();
	const dispatch = useDispatch();

	const handleSwitchForm = () => {
		dispatch(openForm());
		setOpenSwitchForm(true);
	};

	const closeSwitchForm = () => {
		dispatch(closeForm());
		setOpenSwitchForm(false);
	};

	const handleUserChange = (usr: V3SwitchOption) => {
		if (usr.clientId) {
			handleSwitchUser(usr.clientId);
		} else {
			if (parseInt(user.onBehalfOf) === usr.businessUserId) {
				unsetOnBehalfOfSwitch();
			} else {
				handleOnBehalfOfSwitch(usr.businessUserId);
			}
		}
		closeSwitchForm();
	};

	const userOptions = user?.v3SwitchOptions || [];

	return (
		<div id='switch-user' style={{ width: '100%' }} >
			<SwitchUserForm
				open={openSwitchForm}
				onClose={closeSwitchForm}
				options={userOptions}
				onChooseUser={handleUserChange}
				user={user}
			/>
			<Grid item xs={12}
				container
				width='100%'
				alignItems='center'
				padding={drawer ? '0px' : '6px'}
				justifyContent={onlyAvatar ? 'center' : 'flex-start'}
				onClick={userOptions.length > 0 ? handleSwitchForm : undefined} sx={{ cursor: 'pointer' }}>
				{onlyAvatar ? <UserAvatar user={user} sx={{ width: '40px', height: '40px', borderRadius: '7px', fontSize: '13px' }} /> :
					<>
						<Grid item xs={2}>
							<UserAvatar user={user} sx={{ width: '30px', height: '30px', borderRadius: '7px', fontSize: '11px' }} />
						</Grid>
						<Grid item xs={drawer ? 10 : 8} pl={1}>
							<Grid item xs={12}>
								<Typography
									fontSize='13px'
									lineHeight='15px'
									fontWeight='700'
									color='text.primary'
									noWrap
									sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}
								>{user.type === UserType.BUSINESS ? user.companyName : capitalCaseString(`${user.firstName} ${user.lastName}`)}
								</Typography>
							</Grid>
							{user && user.onBehalfOf !== null &&
								<Grid item xs={12}>
									<Typography
										fontSize='11px'
										fontWeight='700'
										lineHeight='15px'
										noWrap
										sx={(theme) => ({
											wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis',
											background: theme.selectedItemBg.default,
											'WebkitBackgroundClip': 'text',
											'WebkitTextFillColor': 'transparent'
										})}
									>
										{t('business.behalfOf.youWorkingOBO', { company: user.onBehalfOfCompany })}
									</Typography>
								</Grid>}
						</Grid>
						{!drawer && <Grid item xs={2} container alignItems='center' justifyContent='flex-end'>
							<KeyboardArrowDownIcon sx={(theme) => ({ color: theme.palette.text.primary, width: '15px' })} />
						</Grid>}
					</>}
			</Grid>
		</div>
	);
};

export default SwitchUserDropdown;
