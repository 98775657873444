import { setOnBehalfOf, unsetOnBehalfOf } from '@/features/employee/slice';
import { showErrorNotification } from '@/features/swal/slice';
import { DeviceInfo, SwitchBody } from '@/features/user/types';
import { setUserOnBehalfOf, switchUser } from '@/features/user/userSlice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getDeviceInfo } from '../browser';
const SWITCH_REPRESENTATION_ACTION = {
	type: 'SWITCH_REPRESENTATION'
};

const useUserSwitch = () => {

	const dispatch = useDispatch();
	const history = useHistory();

	const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>(null);

	useEffect(() => {
		let mounted = true;
		async function getBrowserInfo() {
			const result = await getDeviceInfo();
			if (mounted) {
				setDeviceInfo(result);
			}
		}
		getBrowserInfo();
		return () => { mounted = false; };
	}, []);

	const handleSwitchUser = async (newClientId: string) => {
		try {
			const payload: SwitchBody = { clientId: newClientId, deviceInfo: deviceInfo };

			await dispatch(switchUser(payload));
			await dispatch(SWITCH_REPRESENTATION_ACTION);
			history.push('/wallet/home');
		}
		catch (e) {
			showErrorNotification(e);
		}
	};

	const handleOnBehalfOfSwitch = async (businessUserId: number) => {
		try {
			await setOnBehalfOf(businessUserId);
			dispatch(setUserOnBehalfOf(businessUserId));
			dispatch(SWITCH_REPRESENTATION_ACTION);
			history.push('/wallet/home');
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const unsetOnBehalfOfSwitch = async () => {
		try {
			await unsetOnBehalfOf();
			dispatch(setUserOnBehalfOf(null));
			dispatch(SWITCH_REPRESENTATION_ACTION);
			history.push('/wallet/home');
		} catch (e) {
			showErrorNotification(e);
		}
	};


	return { handleSwitchUser, handleOnBehalfOfSwitch, unsetOnBehalfOfSwitch };
};

export default useUserSwitch;
