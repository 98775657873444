import { generateEmployeeCode } from '@/features/employee/slice';
import { RootState } from '@/rootReducer';
import * as React from 'react';
import { useSelector } from 'react-redux';
import AdminInviteFirstStep from './AdminInviteFirstStep';
import AdminInviteSecondStep from './AdminInviteSecondStep';

interface Props {
	onBack: () => void
}

const AdminInviteStep = ({ onBack }: Props) => {

	const [stepLocal, setStepLocal] = React.useState<number>(1);
	const [adminCode, setAdminCode] = React.useState<string>('');
	const nextStepLocal = () => setStepLocal(previousState => (previousState + 1));
	const prevStepLocal = () => setStepLocal(previousState => (previousState - 1));

	const { user } = useSelector(
		(state: RootState) => state.user
	);



	const handleNextStep = async () => {
		const { onBehalfOfCompany, companyName } = user;
		const response = await generateEmployeeCode('ACCOUNTANT', onBehalfOfCompany??companyName);
		setAdminCode(response.referenceCode);
		nextStepLocal();
	};

	return (<>
		{stepLocal === 1 && <AdminInviteFirstStep onBack={onBack} onGenerate={handleNextStep} />}
		{stepLocal === 2 && <AdminInviteSecondStep onBack={prevStepLocal} adminCode={adminCode}/>}
	</>);
};

export default AdminInviteStep;
