import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import NumberFormat from 'react-number-format';

interface Props {
    title: string,
    amount: number,
    prefix?: string,
}

const TooltipLine = ({ title, amount, prefix }: Props) => {
    const theme = useTheme();

    return <Grid item xs={12} container justifyContent='space-between'>
        <Typography color={theme.form.textSecondary} fontSize='0.688rem'> {title}</Typography>
        <Typography fontSize='0.688rem'>
            <NumberFormat
                displayType={'text'}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandsGroupStyle='thousand'
                thousandSeparator={true}
                value={amount}
                prefix={prefix ? prefix : '€ '} />

        </Typography>

    </Grid>;
};

export default TooltipLine;
