import { GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import MuiTextCaption from '@shared/Widgets/MuiText/MuiTextCaption';


const InvoiceTextCell = (params: GridRenderCellParams) => {

    return (
        <MuiTextCaption fontWeight={400}>
            {params.value ?? ''}
        </MuiTextCaption>
    );
};

export default InvoiceTextCell;
