import React, { useState } from 'react';
import { Grid, IconButton, ListItemIcon, ListItemText, MenuList, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import StyledMenuItemModal from '@/componentsMui/Shared/Widgets/MenuItem/StyledMenuItemModal';
import { findProcessByType, hasSubprocessWithType } from '@/features/operations/slice';
import { isEmpty } from 'lodash';
import '@/componentsMui/Shared/Shared.css';
import { ReactComponent as MuiWallet } from '@/assets/MuiWallet.svg';
import StyledListItemIcon from '@/componentsMui/Shared/Widgets/StyledListItemIcon';
import { useTranslation } from 'react-i18next';
import { CoinType, CoinTypeEnum } from '@/features/coins/types';
import FormHeader from '../Deposit/Components/FormHeader';
import { RootState } from '@/rootReducer';
import { AccountType } from '@/features/operations/types';
import { ReactComponent as MuiBigSync } from '@assets/MuiBigSync.svg';
import DepositExternally from './Crypto/DepositExternally';
import DepositToOtherUser from './Crypto/DepositToOtherUser';
import { ReactComponent as MuiBigContacts } from '@assets/MuiBigContacts.svg';
import FiatDepositFlow from '@/componentsMui/Wallet/Balances/Deposit/Fiat/FiatDepositFlow';
import { WalletDepositAction } from '@/features/coins/types';
import BetweenAccountsSteps from '@shared/Components/SendMoney/BetweenAccounts/BetweenAccountsSteps';
import MaintenanceChip from '@/componentsMui/Shared/Widgets/MaintenanceChip';

interface Props {
	coin: CoinType,
	onBack: () => void

}
const AssetSendOptions = ({ coin, onBack }: Props): React.ReactElement => {

	const closeModal = useCloseDialogNoEvent();
	const { t } = useTranslation('translations');
	const allAccounts = useSelector((state: RootState) => state.accounts.list);
	const [action, setAction] = useState<WalletDepositAction>(WalletDepositAction.TRANSFER);
	const [stepLocal, setStepLocal] = React.useState<number>(1);
	const nextStepLocal = () => {
		setStepLocal(previousState => (previousState + 1));
	};
	// const prevStepLocal = () => {
	// 	setStepLocal(previousState => (previousState - 1));
	// };

	const { agentName } = useSelector(
		(state: RootState) => state.status
	);
	const { user } = useSelector(
		(state: RootState) => state.user
	);


	const assetSymbol = coin?.info?.symbol;


	const coinAccount = coin?.account ? { ...coin.account, subProcesses: coin.subProcesses, currencyLogo: coin.info.logo } : undefined;
	const defaultFromAccount = allAccounts.filter(p => p.type === AccountType.BANK || p.type === AccountType.BANK_AND_CARD).find(accounts => hasSubprocessWithType('OWN_TRANSFER', accounts.subProcesses) && accounts.currency === coinAccount.currency) ?? undefined;

	const walletAccounts = allAccounts.filter(p => p.type === AccountType.WALLET);
	const toAccount = walletAccounts.find(accounts => hasSubprocessWithType('OWN_TRANSFER', accounts.subProcesses)) || [];

	const transferProc = findProcessByType(coin, 'TRANSFER');
	const ownTransferProc = findProcessByType(coin, 'OWN_TRANSFER'); 


	const isCryptoDepositEnabled = coin?.type === CoinTypeEnum.CRYPTO && coin.account.walletId && !user?.onlySkipKycUsers;
	const isCryptoExternalDepositEnabled = coin?.type === CoinTypeEnum.CRYPTO && coin.account.accountAddresses;

	const isTransferEnabled = coin?.type === CoinTypeEnum.WALLET && coin?.subProcesses && !isEmpty(ownTransferProc) && toAccount && defaultFromAccount;
	const isTransferToOtherUserEnabled = (coin?.type === CoinTypeEnum.WALLET && !isEmpty(transferProc));

	const isEurPolling = coin.account.providerType === 'POOLING' && coin.account.currency === 'EUR';
	const isUsdPolling = coin.account.providerType === 'POOLING' && coin.account.currency === 'USD';
	const isGbpPolling = coin.account.providerType === 'POOLING' && coin.account.currency === 'GBP';

	const transferDown = transferProc?.maintenance;
	const ownTransferDown = ownTransferProc?.maintenance;

	const handleMenuClick = (action: WalletDepositAction) => {
		setAction(action);
		nextStepLocal();
	};

	const renderMenuLine = (title: string, currency: string) => {
		return (
			<ListItemText className='money-form-list-item-text'>
				<Grid container alignItems='baseline' className='money-form-list-item-text'>{title}
					<Typography className='currency'> {currency ?? ''} </Typography>
				</Grid>
			</ListItemText>);
	};

	if (stepLocal === 1)
		return (<>
			<Grid item container justifyContent='space-between'>
				<Grid item>
					<FormHeader title={t('wallet.deposit.deposit')} onBack={onBack}
						showBack={true} />
				</Grid>
				<Grid item>
					<IconButton onClick={closeModal}  >
						<MuiCloseIcon />
					</IconButton>
				</Grid>
			</Grid>

			<Grid item >
				<MenuList role='menu' className='form-menu__list'>
					{isTransferEnabled && <StyledMenuItemModal
						unsetMarginBottom={true}
						id='deposit-transfer'
						disabled={ownTransferDown}
						onClick={() => handleMenuClick(WalletDepositAction.TRANSFER)}
					> 	<StyledListItemIcon>  <MuiBigSync />  </StyledListItemIcon>
						<ListItemText className='money-form-list-item-text'>{t('wallet.deposit.transer')}</ListItemText>
						{ownTransferDown && <MaintenanceChip />}
					</StyledMenuItemModal>}
					{isEurPolling &&
						<StyledMenuItemModal
							unsetMarginBottom={true}
							id='deposit-sepa'
							onClick={() => handleMenuClick(WalletDepositAction.SEPA)}
						> 	<ListItemIcon sx={{ mr: '1.25rem' }}> <img className='img-icon-size' src={coin?.info?.logo} /> </ListItemIcon>
							<ListItemText className='money-form-list-item-text'>{t('wallet.deposit.sepa')}</ListItemText>
						</StyledMenuItemModal>
					}
					{isUsdPolling &&
						<StyledMenuItemModal
							unsetMarginBottom={true}
							id='deposit-swift'
							onClick={() => handleMenuClick(WalletDepositAction.SWIFT)}
						> 	<ListItemIcon sx={{ mr: '1.25rem' }}> <img className='img-icon-size' src={coin?.info?.logo} /> </ListItemIcon>
							<ListItemText className='money-form-list-item-text'>{t('wallet.deposit.swift')}</ListItemText>
						</StyledMenuItemModal>
					}
					{isGbpPolling &&
						<StyledMenuItemModal
							unsetMarginBottom={true}
							id='deposit-chaps'
							onClick={() => handleMenuClick(WalletDepositAction.CHAPS)}
						> 	<ListItemIcon sx={{ mr: '1.25rem' }}> <img className='img-icon-size' src={coin?.info?.logo} /> </ListItemIcon>
							<ListItemText className='money-form-list-item-text'>{t('wallet.deposit.chaps')}</ListItemText>
						</StyledMenuItemModal>
					}
					{isCryptoDepositEnabled &&
						<StyledMenuItemModal
							unsetMarginBottom={true}
							key='withdraw'
							onClick={() => { handleMenuClick(WalletDepositAction.CRYPTO); }}
						>
							<StyledListItemIcon className='menu-icon-stroke-size'>
								<MuiBigContacts stroke='#959799' strokeWidth={3} />
							</StyledListItemIcon>
							{renderMenuLine(t('wallet.deposit.cryptoFromUser', { agent: agentName }), assetSymbol)}
						</StyledMenuItemModal>
					}
					{isCryptoExternalDepositEnabled &&
						< StyledMenuItemModal
							unsetMarginBottom={true}
							key='withdraw1'
							onClick={() => { handleMenuClick(WalletDepositAction.CRYPTO_EXTERNAL); }}
						>
							<StyledListItemIcon>
								<MuiWallet stroke='transparent' />
							</StyledListItemIcon>
							{renderMenuLine(t('wallet.deposit.cryptoFromExternal'), assetSymbol)}
						</StyledMenuItemModal>
					}
					{isTransferToOtherUserEnabled &&
						<StyledMenuItemModal
							unsetMarginBottom={true}
							key='withdraw2'
							disabled={transferDown}
							onClick={() => { handleMenuClick(WalletDepositAction.USER_TRANSFER); }}
						>
							<StyledListItemIcon className='menu-icon-stroke-size'> <MuiBigContacts stroke='#959799' strokeWidth={3} />  </StyledListItemIcon>
							{renderMenuLine(t('wallet.deposit.cryptoFromUser', { agent: agentName }), assetSymbol)}
							{transferDown && <MaintenanceChip />}
						</StyledMenuItemModal>
					}
				</MenuList>
			</Grid >
		</>);

	if (stepLocal === 2)
		return (
			<>
				{(action === WalletDepositAction.CRYPTO || action === WalletDepositAction.USER_TRANSFER) &&
					<DepositToOtherUser
						token={coin}
						onBack={() => setStepLocal(1)}
					/>
				}
				{action === WalletDepositAction.CRYPTO_EXTERNAL &&
					<DepositExternally
						token={coin}
						onBack={() => setStepLocal(1)}
					/>
				}
				{(action === WalletDepositAction.SEPA || action === WalletDepositAction.SEPA_INSTANT || action === WalletDepositAction.SWIFT || action === WalletDepositAction.CHAPS) &&
					<FiatDepositFlow account={coin.account} depositType={action} onBack={() => setStepLocal(1)} title={t(`wallet.deposit.${action.toLowerCase()}`)} />
				}
				{action === WalletDepositAction.TRANSFER &&
					<BetweenAccountsSteps
						step={1}
						title={t('wallet.deposit.transer')}
						fromDefaultAccount={defaultFromAccount}
						toDefaultAccount={coinAccount}
						noBackground={true}
						prevStep={() => setStepLocal(1)}
					/>
				}
			</ >
		);
	return (<></>);
};

export default AssetSendOptions;
