import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import BaseBox from '@/componentsMui/Shared/Widgets/Box/BaseBox';
import MuiTextAlert from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAlert';
import { Link } from 'react-router-dom';

interface Props {
	allowMultipleBusinessRegistration: boolean
}

const BusinessPlaceholder = ({ allowMultipleBusinessRegistration }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');

	return (
		<BaseBox textAlign='center' height='14rem' alignItems='center' display='flex'>
			<Grid id='business-placeholder' container>
				{allowMultipleBusinessRegistration &&
					<Grid item xs={12}>
						<MuiTextAlert>{t('business.business.info1')}</MuiTextAlert>
					</Grid>
				}
				<Grid item xs={12}>
					<MuiTextAlert className='highlight-strong' pt={2} >
						<Trans i18nKey='business.business.info2'
							t={t}
							components={{
								businesslink: <Link to='/wallet/business/create' />
							}}
						/>
					</MuiTextAlert>

				</Grid>
			</Grid>
		</BaseBox>
	);
};

export default BusinessPlaceholder;
