import { deletePaymentLine, executePayments, getPaymentsList } from '@/features/bulkpayments/bulkPaymentsSlice';
import { PaymentLine } from '@/features/bulkpayments/bulkPaymentsTypes';
import { RowActionType } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import { DataGrid, GridActionsCellItem, GridColDef, GridPaginationModel, GridRenderCellParams, GridRowParams, useGridApiRef } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PaymentLineCell from './PaymentLineCell';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useConfirm } from 'material-ui-confirm';
import EditPaymentLineForm from './EditPaymentLineForm';
import PaymentLineStatusCell from './PaymentLineStatusCell';
import PaymentLineAccountCell from './PaymentLineAccountCell';
import { t } from '@/helpers/translate';
import { Button, FormControlLabel, Grid, Typography } from '@mui/material';
import IOSSwitch from '@/componentsMui/Shared/Widgets/IOSSwitch';

interface Props {
    sessionId: string;
}

const BulkPaymentLinesGrid = ({ sessionId }: Props) => {
    const dispatch = useDispatch();
    const confirm = useConfirm();
    const { paymentsCount, paymentsList, paymentsLoading, paymentsListExecutable } = useSelector((state: RootState) => state.bulkPayments);
    const apiRef = useGridApiRef();
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
    const [selectedPaymentLine, setSelectedPaymentLine] = useState<PaymentLine | null>(null);
    const [showOnlyInvalid, setShowOnlyInvalid] = useState<boolean>(false);
    const filter = 'status=INVALID';

    const fetch = useCallback(async (skip: number) => {
        await dispatch(getPaymentsList(sessionId, skip, showOnlyInvalid ? filter : undefined));
    }, [dispatch, sessionId, showOnlyInvalid]);

    useEffect(() => {
        fetch(0);
    }, [fetch, sessionId, showOnlyInvalid]);

    const onPaginationModelChange = async (model: GridPaginationModel) => {
        try {
            //call page from grid model 
            fetch(model.page);
        }
        catch (e) {
            console.log(e);
        }
    };

    const handleDelete = async (paymentLine: PaymentLine) => {
        // const shouldNavigateBack = paymentsList.length === 1;
        try {
            confirm({
                description: t('bulkpayments.confirmation.onDelete.description'),
                title: t('bulkpayments.confirmation.onDelete.title'),
                confirmationText: t('bulkpayments.confirmation.confirm'),
                cancellationText: t('bulkpayments.confirmation.cancel'),
            }).then(() => {
                dispatch(deletePaymentLine(paymentLine.sessionId, paymentLine.id));
            });
            // if (shouldNavigateBack) {
            //     onBack();
            // }
        } catch (e) {
            //show error message
        }
    };
    const handleExecute = async () => {
        try {
            confirm({
                description: t('bulkpayments.confirmation.onExecute.description'),
                title: t('bulkpayments.confirmation.onExecute.title'),
                confirmationText: t('bulkpayments.confirmation.confirm'),
                cancellationText: t('bulkpayments.confirmation.cancel'),
            }).then(() => {
                dispatch(executePayments(sessionId));
            });
        } catch (e) {
            //show error message
        }
    };

    const handleEditClose = () => {
        setSelectedPaymentLine(null);
        setOpenEditDialog(false);
    };

    const columns = useMemo(() => {
        const actionItems = [
            {
                icon: <ModeEditIcon />,
                onClick: (row: PaymentLine) => { setSelectedPaymentLine(row); setOpenEditDialog(true); },
                key: 'edit'
            },
            {
                icon: <DeleteIcon />,
                onClick: (row: PaymentLine) => handleDelete(row),
                key: 'delete'
            },
        ];

        return getColumns(actionItems);
        // eslint-disable-next-line
    }, []);

    return (<>
        {openEditDialog && selectedPaymentLine && <EditPaymentLineForm
            open={openEditDialog}
            paymentLine={selectedPaymentLine}
            onClose={handleEditClose}
        />}
        <Grid container>
            <Grid item xs={12} display='flex' justifyContent='space-between'>
                <FormControlLabel
                    control={<IOSSwitch checked={showOnlyInvalid} onChange={(e, checked: boolean) => setShowOnlyInvalid(checked)} />}
                    label={<Typography fontSize='0.813rem' variant='body2'>{t('bulkpayments.fields.showOnlyInvalid')}</Typography>}
                />
                
                <Button
                    variant='contained'
                    onClick={handleExecute}
                    disabled={!paymentsListExecutable}
                >{t('bulkpayments.buttons.execute')}</Button>
            </Grid>
            <Grid item xs={12} >
                <DataGrid
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 10, page: 0 },
                        },
                    }}
                    autoPageSize={false}
                    pagination
                    autoHeight={true}
                    pageSizeOptions={[10]}
                    apiRef={apiRef}
                    rows={paymentsList}
                    rowCount={paymentsCount}
                    loading={paymentsLoading}
                    getRowId={(row) => `${row.id}`}
                    onPaginationModelChange={onPaginationModelChange}
                    paginationMode="server"
                    filterMode="server"
                    sortingMode="server"
                    disableRowSelectionOnClick={true}
                    columns={columns}
                    sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                        },
                    }}
                />
            </Grid>
        </Grid>
    </>);
};


export const getColumns = (actionItems: Array<RowActionType<PaymentLine>>): GridColDef[] => {
    const columns: GridColDef[] = [
        {
            field: 'sourceAccount',
            headerName: t('bulkpayments.fields.sourceAccount'),
            flex: 0.5,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => PaymentLineCell(params, 'sourceAccount'),
        },
        {
            field: 'amount',
            headerName: t('bulkpayments.fields.amount'),
            flex: 0.5,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => PaymentLineCell(params, 'amount'),
        },
        {
            field: 'ccy',
            headerName: t('bulkpayments.fields.currency'),
            flex: 0.5,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => PaymentLineCell(params, 'ccy'),
        },
        {
            field: 'paymentType',
            headerName: t('bulkpayments.fields.type'),
            flex: 0.5,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => PaymentLineCell(params, 'paymentType'),
        },
        {
            field: 'beneficiaryName',
            headerName: t('bulkpayments.fields.beneName'),
            flex: 1,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => PaymentLineCell(params, 'beneficiaryName'),
        },
        {
            field: 'beneficiaryIban',
            headerName: t('bulkpayments.fields.ibanAccNr'),
            flex: 1,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => PaymentLineAccountCell(params),
        },
        {
            field: 'paymentReason',
            headerName: t('bulkpayments.fields.details'),
            flex: 0.5,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => PaymentLineCell(params, 'paymentReason'),
        },
        {
            field: 'status',
            headerName: t('bulkpayments.fields.status'),
            flex: 0.5,
            sortable: false,
            headerAlign: 'right',
            align: 'right',
            renderCell: (params: GridRenderCellParams) => PaymentLineStatusCell(params),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            headerAlign: 'right',
            align: 'right',
            flex: 0.5,
            getActions: (params: GridRowParams) => actionItems.map(item => {
                let disabled = true;
                if (['VALID', 'INVALID'].includes(params.row.status)) {
                    disabled = false;
                }
                return <GridActionsCellItem
                    onClick={() => item.onClick(params.row)}
                    label=""
                    key={item.key}
                    icon={item.icon}
                    disabled={disabled}
                    sx={{
                        '&.Mui-disabled': {
                            opacity: 0.5
                        }
                    }}
                />;
            })
        }
    ];

    return columns;
};


export default BulkPaymentLinesGrid;
