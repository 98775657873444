export enum ChatStatusEnum {
  NEW = 'NEW',
  SOLVED = 'SOLVED',
  PENDING_ON_CLIENT = 'PENDING_ON_CLIENT',
}

export type Attachment = {
  id: string;
  chatId: string;
  chatMessageId: string;
  name: string;
  size: string;
  mimetype: string;
  key: string;
  createdDate: string;
};

export type Message = {
  id: string;
  chatId: string;
  message: string | null;
  authorId: string;
  readByClient: boolean;
  clientMessage: boolean;
  createdDate: string;
  updatedDate: string;
  attachments: Attachment[];
};

export type Chat = {
  id: string;
  userId: string;
  wlpId: string;
  status: ChatStatusEnum;
  deleted: boolean;
  createdDate: string;
  updatedDate: string;
};

export type ChatType = {
  chat: Chat | null;
  messages: Message[];
  count: number;
};

export type SendMessagePayload = {
  message?: string | null;
  files?: File | null;
  chatId?: string | null;
};

export type GetSupportChatPayload = {
  skip?: number;
  take?: number;
  sort?: string;
  ignorePagination?: boolean;
}

export type SupportChatStore = {
  data: ChatType,
  unreadCount: number,
}

