import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './BusinessPayment.css';
import MuiCopyToClipboard from '@/componentsMui/Shared/Widgets/MuiCopyToClipboard';
import TopBox from '@/componentsMui/Shared/Widgets/Box/TopBox';
import MuiFormLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiFormLabel';
import MuiTextConfirmation from '@/componentsMui/Shared/Widgets/MuiText/MuiFormField';
import NumberFormat from 'react-number-format';
import { getCurrencySign } from '@/features/fees/slice';

interface Props {
	amount: string
	ccy: string
}

const BusinessPaymentAmount = ({ amount, ccy }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	return (
		<TopBox className='account-details-container'>
			<Grid container className='account-identifier-line'>
				<Grid item xs={4} >
					<MuiFormLabel color='text.secondary'>  {t('createbusiness.waitingForPayment.amount')}  </MuiFormLabel>
				</Grid>
				<Grid item xs={7}>
					<MuiTextConfirmation> <NumberFormat displayType={'text'} decimalScale={2}
						fixedDecimalScale={true} thousandsGroupStyle='thousand'
						thousandSeparator={true} value={parseInt(amount)} prefix={`${getCurrencySign(ccy)} `} /> </MuiTextConfirmation>
				</Grid>
				<Grid item xs={1} container justifyContent='flex-end'>
					<MuiCopyToClipboard value={parseInt(amount).toFixed(2)} />
				</Grid>
			</Grid>
		</TopBox>
	);
};

export default BusinessPaymentAmount;
