import React, { useState } from 'react';
import { Alert, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { DocumentsPayloadType, DocumentsType, SectionStatus, SectionType } from '@/features/business/types';
import FileUpload, { FileStatusEnum } from '@/componentsMui/Shared/Components/FileUpload/FileUpload';
import { postFile } from '@/features/kyb/slice';
import { postDocuments } from '@/features/business/slice';
import { showException } from '@/features/swal/slice';
import MuiTextAlert from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAlert';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';

interface Props {
	clientId: string,
	section?: SectionType,
	onNext: () => void,
	onBack: () => void
}

const Documents = ({ clientId, section, onNext, onBack }: Props): React.ReactElement => {

	const values = section?.values as DocumentsType;
	const { t } = useTranslation('translations');
	const [submitting, setSubmitting] = useState(false);
	const [certStatus, setCertStatus] = useState(values?.certificateOfIncorporation ? FileStatusEnum.SUCCESS : '');
	const [certFile, setCertFile] = useState();
	const [uboStatus, setUboStatus] = useState(values?.ubo ? FileStatusEnum.SUCCESS : '');
	const [uboFile, setUboFile] = useState();
	const [directorsStatus, setDirectorsStatus] = useState(values?.directors ? FileStatusEnum.SUCCESS : '');
	const [directorsFile, setDirectorsFile] = useState('');
	const [poaStatus, setPoaStatus] = useState(values?.poaBusiness ? FileStatusEnum.SUCCESS : '');
	const [poaFile, setPoaFile] = useState('');



	const disableContinue = certStatus !== FileStatusEnum.SUCCESS
		|| uboStatus !== FileStatusEnum.SUCCESS
		|| directorsStatus !== FileStatusEnum.SUCCESS
		|| poaStatus !== FileStatusEnum.SUCCESS;

	const submit = async () => {
		setSubmitting(true);
		try {
			const uploadedFiles = [{
				name: 'certificateOfIncorporation',
				file: certFile
			}, {
				name: 'ubo',
				file: uboFile
			}, {
				name: 'directors',
				file: directorsFile
			}, {
				name: 'poaBusiness',
				file: poaFile
			},];

			const promises = uploadedFiles.filter(p => !!p.file).map(async value => {

				const payload = new FormData();
				payload.append('file', value.file);

				const response = await postFile(payload);
				return {
					key: response?.key,
					name: value.name
				};

			});

			const keys = await Promise.all(promises);

			if (keys?.length > 0) {
				const payload: DocumentsPayloadType = {
					certificateOfIncorporation: keys.find(p => p.name === 'certificateOfIncorporation')?.key ?? values?.certificateOfIncorporation,
					ubo: keys.find(p => p.name === 'ubo')?.key ?? values?.ubo,
					directors: keys.find(p => p.name === 'directors')?.key ?? values?.directors,
					poaBusiness: keys.find(p => p.name === 'poaBusiness')?.key ?? values?.poaBusiness,
					clientId: clientId
				};
				await postDocuments(payload);
			}

		} catch (err) {
			showException(err);
		}
		finally {
			setSubmitting(false);
		}
	};

	const next = async () => {
		try {
			await submit();
			onNext && onNext();
		}
		catch (e) {
			showException(e);
		}
	};

	const doNotAllowUpdate = section?.status === SectionStatus.APPROVED;

	return (
		<div id='business-documents' className='form-content_main-area-nopadding'>
			<Grid container rowGap={2} >
				{doNotAllowUpdate &&
					<Grid item xs={12} >
						<Alert severity="success">
							<MuiTextAlert> {t('createbusiness.information.approved')}</MuiTextAlert>
						</Alert>
					</Grid>
				}
				{section?.status === SectionStatus.REJECTED && section?.rejectionReason &&
					<Grid item xs={12} >
						<Alert severity="warning">
							<MuiTextAlert>{section?.rejectionReason}</MuiTextAlert>
						</Alert>
					</Grid>
				}
				<Grid item xs={12}>
					<MuiHeader className='business-header'>   {t('createbusiness.documents.title')}</MuiHeader>
				</Grid>
				<Grid item xs={12}>
					<FileUpload
						disabled={submitting || doNotAllowUpdate}
						completed={!!values?.certificateOfIncorporation}
						label={t('createbusiness.documents.certificateOfIncorporation')}
						setStatus={(value) => setCertStatus(value)}
						setFile={(value) => setCertFile(value)} 
						acceptedFileFormats='image/jpg, image/png, image/jpeg, .pdf'
						/>
				</Grid>
				<Grid item xs={12}>
					<FileUpload
						disabled={submitting || doNotAllowUpdate}
						completed={!!values?.ubo}
						label={t('createbusiness.documents.ubo')}
						setStatus={(value) => setUboStatus(value)}
						setFile={(value) => setUboFile(value)} 
						acceptedFileFormats='image/jpg, image/png, image/jpeg, .pdf'
						/>
				</Grid>
				<Grid item xs={12}>
					<FileUpload
						disabled={submitting || doNotAllowUpdate}
						completed={!!values?.directors}
						label={t('createbusiness.documents.directors')}
						setStatus={(value) => setDirectorsStatus(value)}
						setFile={(value) => setDirectorsFile(value)} 
						acceptedFileFormats='image/jpg, image/png, image/jpeg, .pdf'
						/>
				</Grid>
				<Grid item xs={12}>
					<FileUpload
						disabled={submitting || doNotAllowUpdate}
						completed={!!values?.poaBusiness}
						label={t('createbusiness.documents.poaBusiness')}
						setStatus={(value) => setPoaStatus(value)}
						setFile={(value) => setPoaFile(value)} 
						acceptedFileFormats='image/jpg, image/png, image/jpeg, .pdf'
						/>
				</Grid>
			</Grid>
			<Grid container className='form-content_botton-area'>
				{submitting &&
					<Grid item xs={12}>
						< Spinner />
					</Grid>}
				<Grid item xs={6}>
					<Button
						variant='customOutlined'
						color='secondary'
						onClick={onBack}
					>  {t('form.buttons.back')}
					</Button>
				</Grid>
				<Grid item xs={6} container justifyContent='flex-end' columnGap={3}>
					{doNotAllowUpdate ? <Button
						variant='contained'
						type="button"
						onClick={() => onNext()}
					>
						{t('form.buttons.continue')}
					</Button> : <Button disabled={disableContinue || submitting}
						variant='contained'
						color='primary'
						onClick={next}
					>
						{t('form.buttons.continue')}
					</Button>}
				</Grid>
			</Grid>
		</div>
	);
};

export default Documents;
