import React, { useEffect, useState } from 'react';
import { Button, Grid, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './FileUploadLink.css';
import InputBox from '../../Widgets/Box/InputBox';
import FileStatus from '../../Widgets/FileStatus';
import MuiTextCaption from '../../Widgets/MuiText/MuiTextCaption';
import { roundTwo } from '@/helpers/round';
import { useDropzone } from 'react-dropzone';
import { SxProps } from '@mui/system';

export enum FileStatusEnum {
	SUCCESS = 'SUCCESS',
	NEW = 'NEW',
	WARNING = 'WARNING'
}

interface Props {
	completed?: boolean,
	acceptedFileFormats?: string,
	buttonLabel?: string,
	label?: string,
	maxFileSizeKb?: number,
	disabled?: boolean,
	file?: any,
	setStatus?: (value: any) => void,
	setFile?: (value: any) => void,
	sx?: SxProps<Theme>,
}
export const defaultAcceptedFiles = 'image/jpg, image/png, image/jpeg, .pdf, .doc, .docx';

const fileSize = (size: number) => {
	return roundTwo(((size ?? 0) * 0.000001));
};

const FileUpload = ({ sx, disabled, completed, acceptedFileFormats, buttonLabel, label, maxFileSizeKb = 5000, file, setStatus, setFile }: Props) => {

	const { t } = useTranslation('translations');
	const [selectedFile, setSelectedFile] = useState<any>(file);
	const [selectedFileStatus, setSelectedFileStatus] = useState<any>(completed ? FileStatusEnum.SUCCESS : FileStatusEnum.NEW);

	const fileStatus = (files: any) => {
		if (files?.length > 0) {
			if (files[0]?.size > (maxFileSizeKb * 1000)) {
				return FileStatusEnum.WARNING;
			}
			return FileStatusEnum.SUCCESS;
		}
		else { return FileStatusEnum.NEW; }
	};

	useEffect(() => {
		if (file) {
			setSelectedFile(file);
			setSelectedFileStatus(FileStatusEnum.SUCCESS);
		}
	}, [file]);

	const onDrop = (acceptedFiles) => {
		if (acceptedFiles.length === 0 || acceptedFiles.length > 1) {
			return;
		}

		const file = acceptedFiles[0];
		const status = fileStatus(acceptedFiles);
		setSelectedFileStatus(status);
		setSelectedFile(file);
		setStatus && setStatus(status);
		setFile && setFile(file);
	};

	const { getRootProps, getInputProps, open } = useDropzone({
		onDrop,
		accept: acceptedFileFormats ?? defaultAcceptedFiles,
		noClick: true,
		noKeyboard: true
	});

	return (
		<InputBox className='file-drop-box' width='100%' p='20px' sx={sx}>
			<div {...getRootProps()}    >
				<input {...getInputProps()} />
				<Grid container justifyContent='space-between' alignContent='center'>
					<Grid item xs={12} md={8} container sx={{ display: { xs: 'none', md: 'flex' } }}>
						<Grid item pr='1rem' xs={2}>
							<FileStatus status={selectedFileStatus} />
						</Grid>
						<Grid item xs={10} alignSelf='center'>
							{label && <MuiTextCaption>{label}</MuiTextCaption>}
							{selectedFile &&
								<Grid container key='file.name'  >
									<MuiTextCaption fontWeight={400} pr='0.5rem'>
										{selectedFile.name} • {fileSize(selectedFile.size)} MB
									</MuiTextCaption>
									{selectedFile.size > (maxFileSizeKb * 1000) &&
										<MuiTextCaption fontWeight={400} variant='gradient'>
											{t('form.validator.fileTooBig')}
										</MuiTextCaption>}
								</Grid>
							}
							{selectedFileStatus === FileStatusEnum.NEW &&
								<MuiTextCaption fontWeight={400} variant='gradient'>
									{t('form.validator.fileNotUpload')}
								</MuiTextCaption>
							}
						</Grid>
					</Grid>
					<Grid item xs={12} md={4} container justifyContent='flex-end'>
						<Button
							sx={{ width: { xs: '100%', sm: 'unset' } }}
							onClick={open}
							variant='customOutlined'
							disabled={disabled}>
							<MuiTextCaption>{
								buttonLabel ?? t('form.buttons.browse')}
							</MuiTextCaption>
						</Button>
					</Grid>
					<Grid item xs={12} sx={{ display: { xs: 'flex', sm: 'none' } }} >
						{selectedFile &&
							<Grid container key='file.name'>
								<MuiTextCaption fontWeight={400} pt='1rem'>
									{selectedFile.name} • {fileSize(selectedFile.size)} MB
								</MuiTextCaption>
								{selectedFile.size > (maxFileSizeKb * 1000) &&
									<MuiTextCaption fontWeight={400} variant='gradient'>
										{t('form.validator.fileTooBig')}
									</MuiTextCaption>}
							</Grid>}
						{selectedFileStatus === FileStatusEnum.NEW &&
							<MuiTextCaption fontWeight={400} pt='1rem' variant='gradient'>
								{t('form.validator.fileNotUpload')}
							</MuiTextCaption>}
					</Grid>
				</Grid>
			</div>
		</InputBox>);
};

export default FileUpload;
