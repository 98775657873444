import TrxDateCell from '@/componentsMui/TransactionsGrid/Components/TrxDateCell';
import { getUploadsList } from '@/features/bulkpayments/bulkPaymentsSlice';
import { BulkPaymentsUpload } from '@/features/bulkpayments/bulkPaymentsTypes';
import { RowActionType } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import { DataGrid, GridActionsCellItem, GridColDef, GridPaginationModel, GridRenderCellParams, GridRowParams, useGridApiRef } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import UploadStatusCell from './UploadStatusCell';

interface Props {
    onSelect: (sessionId: string) => void;
}

const BulkPaymentsUploadsGrid = ({ onSelect }: Props) => {
    const dispatch = useDispatch();
    const { uploadsCount, uploadsList, uploadsLoading } = useSelector((state: RootState) => state.bulkPayments);
    const apiRef = useGridApiRef();


    const fetch = useCallback(async (skip: number) => {
        await dispatch(getUploadsList(skip));
    }, [dispatch]);

    useEffect(() => {
        fetch(0);
    }, [fetch]);

    const onPaginationModelChange = async (model: GridPaginationModel) => {
        try {
            //call page from grid model 
            fetch(model.page);
        }
        catch (e) {
            console.log(e);
        }
    };

    const columns = useMemo(() => {
        const actionItems = [
            {
                icon: <RemoveRedEyeIcon />,
                onClick: (row: BulkPaymentsUpload) => onSelect(row.sessionId),
                key: 'view'
            },
        ];

        return getColumns(actionItems);
    }, [onSelect]);

    return (<>
        <DataGrid
            initialState={{
                pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                },
            }}
            autoPageSize={false}
            pagination
            autoHeight={true}
            apiRef={apiRef}
            rows={uploadsList}
            pageSizeOptions={[10]}
            rowCount={uploadsCount}
            loading={uploadsLoading}
            getRowId={(row) => `${row.sessionId}`}
            onPaginationModelChange={onPaginationModelChange}
            paginationMode="server"
            filterMode="server"
            sortingMode="server"
            disableRowSelectionOnClick={true}
            columns={columns}
            sx={{
                // disable cell selection style
                '.MuiDataGrid-cell:focus': {
                    outline: 'none'
                },
            }}
        />
    </>);
};


export const getColumns = (actionItems: Array<RowActionType<BulkPaymentsUpload>>): GridColDef[] => {
    const columns: GridColDef[] = [
        {
            field: 'uploadDate',
            headerName: 'Upload Date',
            flex: 1,
            renderCell: (params: GridRenderCellParams) => TrxDateCell(params),
            sortable: false,
        },
        {
            field: 'sessionId',
            headerName: 'ID',
            flex: 1,
            sortable: false,
        },
        {
            field: 'fileName',
            headerName: 'File Name',
            flex: 1,
            sortable: false,
        },
        {
            field: 'executionDate',
            headerName: 'Execution date',
            flex: 1,
            renderCell: (params: GridRenderCellParams) => TrxDateCell(params),
            sortable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            headerAlign: 'right',
            align: 'right',
            sortable: false,
            renderCell: (params: GridRenderCellParams) => UploadStatusCell(params),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            headerAlign: 'right',
            align: 'right',
            flex: 0.5,
            getActions: (params: GridRowParams) => actionItems.map(item => {
                return <GridActionsCellItem
                    onClick={() => item.onClick(params.row)}
                    label=""
                    key={item.key}
                    icon={item.icon}
                />;
            })
        }
    ];

    return columns;
};


export default BulkPaymentsUploadsGrid;
