import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import request from '@services/request';
import { logout } from '@features/user/userSlice';
import { close2FAModal, open2FAModal, request2FA, set2FAMessage, nextMobile, open2FAMobileConfirm, openCodeConfirm, set2FALoading, setErrorCode, rerunRequest2FA } from '@features/2fa/slice';
import { useTranslation } from 'react-i18next';
import { dismissAllNotifications } from '@/features/swal/slice';
import { CANCEL_2FA, CHANGE_2FA, ConfirmType } from '@/features/2fa/types';

const Interceptors = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const history = useHistory();


	const getStatus = (err) => {
		const response = err?.response;

		if (response) {
			//const { status } = response;
			return response?.status;
		} else {
			//const { status } = err;
			return err?.status;
		}
	};

	const getData = (err) => {
		const response = err?.response;
		if (response) {
			const { data } = response;
			return data;
		} else {
			const { data } = err;
			return data;
		}
	};


	useEffect(() => {
		// Register intersectors
		request.interceptors.request.use(
			(config) => {
				// Get token and add it to header "Authorization"
				const credentialsKey = process.env.REACT_APP_CREDENTIALS_KEY;
				try {
					const credentials = JSON.parse(sessionStorage.getItem(credentialsKey));
					const isAwsDownload = config?.params?.isAwsDownload ?? false;
					if (isAwsDownload) {
						config.params = {};
						delete config.headers.Authorization;
						return config;
					}
					// If user not authorized just forward the request further
					if (!credentials) {
						return config;
					}
					config.headers.Authorization = credentials.token;
				}
				catch (e) {
					console.log(e);
				}
				config.headers['Content-Type'] = 'application/json';
				return config;
			},
			(error) => Promise.reject(error)
		);
		request.interceptors.response.use(undefined, (err) => {
			try {
				/* const {
					config,
					response
				} = err; */
				const config = err?.config;
				const response = err?.response;
				const status = getStatus(err);
				const data = getData(err);

				const originalRequest = config;

				if (err?.message === CHANGE_2FA) {
					return new Promise((resolve, reject) => {
						rerunRequest2FA(resolve, reject, response);
					});
				}

				if (err?.message === CANCEL_2FA) {
					return Promise.reject(CANCEL_2FA);
				}

				if (data && data.errors && data.errors.length &&
					(data.errors[0].error === 'required' &&
						data.errors[0].error_param === 'authenticatorCode')) {
					dispatch(set2FALoading(false));
					dispatch(open2FAModal());
					return new Promise((resolve, reject) => {
						request2FA(originalRequest, resolve, reject, response);
					});
				}

				else if (data?.errors?.length && (data.errors[0]?.error === 'userCode' || data.errors[0]?.error === 'userCode_2faAuth')) {

					const userCode = data.errors[0].error_param;

					if (userCode) {
						dispatch(set2FAMessage(data.errors[0].error_param));
						dispatch(open2FAMobileConfirm());
						dispatch(setErrorCode(data.errors[0].error));
						return new Promise((resolve, reject) => {
							request2FA(originalRequest, resolve, reject, response);
							dispatch(nextMobile(userCode, originalRequest));
						});
					}
				}
				/* else if (data && data.error === 'required' && data.error_param === 'authenticatorCode') {
					console.log('authenticatorCode !!!!!!!!', originalRequest.url);
					dispatch(set2FALoading(false));
					dispatch(set2FAMessage(t('form.twofa.mandatory')));
					dispatch(open2FAModal());
					return new Promise((resolve, reject) => {
						request2FA(originalRequest, resolve, reject, response);
					});
				} */
				else if (data && data.errors && data.errors[0].error === 'invalid' && data.errors[0].error_param === 'authenticatorCode' || data && data.message === 'wrong two factor code') {

					setTimeout(() => dispatch(set2FALoading(false)), 1000);
					dispatch(set2FAMessage(t('form.twofa.invalid')));
					dispatch(open2FAModal());
					return new Promise((resolve, reject) => {
						request2FA(originalRequest, resolve, reject, response);
					});
				}
				else if (data && data.errors && data.errors.length &&
					data.errors[0].error === 'required' &&
					(data.errors[0].error_param === 'smsCode' || data.errors[0].error_param === 'emailOtpCode')

				) {
					const phone = JSON.parse(originalRequest?.data).phoneNumber;
					const countryCode = JSON.parse(originalRequest?.data)?.countryCode;
					const fullNumber = countryCode ? countryCode + '' + phone : phone;

					if (data.errors[0].error_param === 'smsCode') {
						dispatch(openCodeConfirm({ type: ConfirmType.SMS, phoneNumber: fullNumber ?? null, email: JSON.parse(originalRequest?.data).email ?? null, alert: originalRequest?.url === '/api/v3/users/me/profile/change-phone' ? t('login.logoutAlert') : null }));
					}
					if (data.errors[0].error_param === 'emailOtpCode') {
						dispatch(openCodeConfirm({ type: ConfirmType.EMAIL, phoneNumber: fullNumber ?? null, email: JSON.parse(originalRequest?.data).email ?? null, alert: originalRequest?.url === '/api/v3/users/me/change-email' ? t('login.logoutAlert') : null }));
					}
					return new Promise((resolve, reject) => {
						request2FA(originalRequest, resolve, reject, response);
					});
				}

				if (data && data.errors && data.errors.length &&
					data.errors[0].error === 'invalid' &&
					(data.errors[0].error_param === 'smsCode' || data.errors[0].error_param === 'emailOtpCode')
				) {
					dispatch(set2FAMessage(t('login.invalidCode')));
					return new Promise((resolve, reject) => {
						request2FA(originalRequest, resolve, reject, response);
					});
				}

				if ((data && data.errors && data.errors.length && data.errors[0]?.error !== 'notApproved')) {
					if (data && data.errors && data.errors.length && (data.errors[0]?.message !== 'wrong two factor code')) {
						dispatch(close2FAModal());
					} else if (data && data?.message !== 'wrong two factor code') {
						dispatch(close2FAModal());
					}
				}


				if (originalRequest?.url.includes('/api/cards-tds/verify')) {
					if (status === 401 || status === 503) {
						return Promise.reject(response);
					}
				}

				if (status === 401) {
					dismissAllNotifications();
					history.push('/auth');
					dispatch(logout());
				}

				if (status === 503) {
					dismissAllNotifications();
					history.push('/maintenance');
				}
				return Promise.reject(response);
			} catch (e) {
				return Promise.reject(e);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [dispatch, history]);

	return (
		<div id="interceptors"></div>
	);
};

export default Interceptors;
