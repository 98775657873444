import React, { FC } from 'react';
import { MenuItem, Select, Theme } from '@mui/material';
import { SelectProps } from '@mui/material/Select';
import { makeStyles, useTheme } from '@mui/styles';
import { ReactComponent as MuiChevronSmall } from '@/assets/MuiChevronSmall.svg';
import './StyledSelect.css';
import { useTranslation } from 'react-i18next';


/* Dropdown for forms */
const useStyles = makeStyles((theme: Theme) => ({
	//select box
	select: {
		borderRadius: '16px 16px 16px 16px',
		//	borderRadius: theme.inputField.borderRadius,
		color: theme.palette.text.primary,
		//backgroundColor: 'transparent',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: theme.palette.background.paper,
		paddingBottom: 10,
		paddingTop: 10,
		'&:focus': { color: theme.palette.text.primary, borderRadius: '16px 16px 16px 16px', }
	},
	//drop down menu style
	menuPaper: {
		background: theme.palette.background.paper,
		borderRadius: '16px 16px 16px 16px',
		//remove scroll bar
		'-ms-overflow-style': 'none',
		'scrollbar-width': 'none',
		'&::-webkit-scrollbar': {
			display: 'none'
		},
		//style elements 
		'& .MuiMenuItem-root': {
			color: theme.palette.text.secondary,
			'&:hover': {
				background: theme.palette.background.paper,
				opacity: 1,
				'& .select-primary-Item': {
					color: theme.palette.text.primary
				}
			},
			'&.Mui-selected': {
				backgroundColor: 'transparent'
			}
		},
	}
}));


const StyledSelect: FC<SelectProps> = (props: SelectProps) => {
	const { t } = useTranslation('translations');
	const theme = useTheme() as Theme;
	const classes = useStyles(theme);
	const defaultPlaceholder = t('form.placeholder.select');
	return (<Select id='styledSelect'
		className='styledSelect'
		renderValue={props.value !== '' ? undefined : () => props.placeholder ?? defaultPlaceholder}
		IconComponent={props => (
			<MuiChevronSmall {...props} style={{
				transform: 'translate(0, -20%)'
			}} />
		)}
		classes={{ select: classes.select }}
		variant='outlined'
		MenuProps={{
			classes: {
				paper: classes.menuPaper
			},
			anchorOrigin: {
				vertical: 'bottom',
				horizontal: 'left'
			},
			transformOrigin: {
				vertical: 'top',
				horizontal: 'left'
			},
		}}
		{...props}>
		{props.children ?
			props.children :
			<MenuItem value=''>
				<em>None</em>
			</MenuItem>}
	</Select>);
};

export default StyledSelect;
