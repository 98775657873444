import * as React from 'react';

import '@/componentsMui/Shared/Shared.css';

import {
	Button,
	Grid,
	InputAdornment
} from '@mui/material';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import * as Yup from 'yup';
import { RootState } from '@/rootReducer';

import { BankAccount } from '@/features/account/types';
import { Contact, ContactPaymentTypes, ContactUser } from '@/features/contacts/types';
import { lookupClientIdContact } from '@/features/contacts/slice';
import { getErrorWithParams } from '@/features/swal/slice';
import useCloseDialog from '@/helpers/customHook/useCloseDialog';
import { maxTwoDecimals } from '@/helpers/number';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import FormContentBox from '@/componentsMui/Shared/Widgets/Box/FormContentBox';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import ContactDropdown from '@/componentsMui/Shared/FormikComponents/ContactDropdown';
import AccountDropdown from '@/componentsMui/Shared/FormikComponents/AccountDropdown';
import NumberFormat from 'react-number-format';
import { getCurrencySign } from '@/features/fees/slice';
import { AccountType } from '@/features/operations/types';
import { hasSubprocessWithType } from '@/features/operations/slice';
import { NEW_LINE_REGEX } from '@/helpers/customHook/useValidations';

export interface RequestMoneyPayloadType {
	toAccount: BankAccount,
	fromContact: Contact,
	fromWalletId: string,
	reference?: string,
	amount: string,
	contactUser: ContactUser
}

export const initRequestMoneyPayload: RequestMoneyPayloadType =
{
	toAccount: null,
	fromContact: null,
	fromWalletId: null,
	reference: '',
	amount: null,
	contactUser: null
};


type Props = {
	accountId?: number,
	contactId?: number,
	payload: RequestMoneyPayloadType,
	setPayload: (value: RequestMoneyPayloadType) => void,
	nextStep: () => void
}

const RequestMoneyDetails = ({ accountId, contactId, payload, setPayload, nextStep }: Props) => {

	const { t } = useTranslation('translations');
	const onClose = useCloseDialog();
	const allContacts = useSelector((state: RootState) => state.contacts.contacts);
	const allAccounts = useSelector((state: RootState) => state.accounts.list);
	const transferAccounts = allAccounts
		.filter(p => p.type === AccountType.BANK || p.type === AccountType.BANK_AND_CARD)
		.filter(accounts => {
			return hasSubprocessWithType('TRANSFER', accounts.subProcesses);
		}) || [];


	const defaultAccount = transferAccounts.find(p => p.accountId === accountId);

	const [selectedAccount, setSelectedAccount] = useState<BankAccount>(payload.toAccount ?? defaultAccount);

	const internalContacts = allContacts.filter(contact => contact.paymentType === ContactPaymentTypes.LOCAL);
	const defaultContacts = internalContacts.find(p => p.beneficiaryAccountId === contactId);
	const [fromContact, setFromContact] = useState<Contact>(payload.fromContact ?? defaultContacts);
	const [amount, setAmount] = useState<string>(payload?.amount?.toString() ?? '');


	const initialValues = {
		toAccount: null,
		fromWalletId: payload.fromWalletId ?? fromContact?.clientId ?? '',
		amount: payload.amount ?? '',
		reference: payload.reference ?? ''
	};

	const validationSchema = Yup.object({
		fromWalletId: Yup.string().required(t('form.validator.required')),
		amount: Yup.number().typeError(t('form.validator.required')).required(t('form.validator.required')).nullable()
			.label(t('sendmoneyTranslation.data.transferamount'))
			.min(0.01)
			.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value)),
		reference: Yup.string().required(t('form.validator.required')).trim()
			.matches(/^([a-zA-Z0-9-./\s])+$/, t('form.validator.latinOnlyReference'))
			.min(6, t('form.validator.minChars', { field: t('sendmoneyTranslation.data.reference'), chars: '6' }))
			.max(140, t('sendmoneyTranslation.data.canNotExceedHundred'))
			.test('reference', t('form.validator.newLine'), (value) => value ? value.search(NEW_LINE_REGEX) === -1 : true)
			.test('reference', t('form.validator.minChars', { field: t('sendmoneyTranslation.data.reference'), chars: '6' }), (value) => value ? value.replace(/ /g, '').length >= 6 : true),
	});

	const submit = async (formData, formikProps) => {

		const { setSubmitting, setFieldError } = formikProps;
		setSubmitting(true);

		let contactUser = null;
		const { reference, amount } = formData;

		const fromWalletId = formData?.fromWalletId?.toUpperCase();
		try {
			contactUser = await lookupClientIdContact(fromWalletId, selectedAccount?.accountId);
			const _payload: RequestMoneyPayloadType = {
				...{ fromWalletId, toAccount: selectedAccount, amount, reference, fromContact, contactUser }
			};
			setPayload(_payload);
			nextStep();
		}
		catch (e) {
			const err = getErrorWithParams(e);
			if (err && err.error === 'notFound' && err.error_param === 'clientId') {
				setFieldError('fromWalletId', t('sendmoneyTranslation.data.userNotFound'));
			} else if (err && err.error === 'your' && err.error_param === 'clientId') {
				setFieldError('fromWalletId', t('sendmoneyTranslation.data.belongsToYou'));
			} else {
				setFieldError('fromWalletId', t('errors.SOMETHING_WENT_WRONG_WITHOT_MESSAGE'));
			}
		}
		finally {
			setSubmitting(false);
		}

	};


	const handleAccountChange = (value: BankAccount) => {
		setSelectedAccount(value);
	};

	const handleSelectFromContacts = (value: Contact, setFieldValue) => {
		const id = value.beneficiaryAccountId;
		const contact = internalContacts.find(p => p.beneficiaryAccountId === id);
		setFromContact(contact);
		setFieldValue('fromWalletId', contact.clientId);
	};

	const handleWalletIdChange = (event: any, setFieldValue) => {
		const walletId = event.target.value;
		setFieldValue('fromWalletId', walletId);
		const matchingContact = internalContacts.find((c) => c.clientId?.toLocaleUpperCase() === walletId?.toUpperCase());
		if (matchingContact) {
			setFromContact(matchingContact);
		}
		else { setFromContact(null); }
	};

	const handleAmountChange = (event: React.ChangeEvent<any>, setFieldValue, setFieldTouched) => {
		setFieldValue('amount', event.target.value);
		setAmount(event.target.value);
		setTimeout(() => setFieldTouched('amount', true));
	};


	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={submit}

		>
			{({ errors, isSubmitting, setFieldValue, setFieldTouched }) => {
				return (
					<Form className='form-content__wrap__bb' >
						<FormContentBox noBackground={true} className='form-content_main-area wp' >
							<Grid item container spacing={3} alignSelf='flex-start'>
								<Grid item xs={12}>
									<FormHeader title={t('banking.data.requestmoneybutton')} showBack={false} />
								</Grid>
								<Grid item xs={6}>
									<ContactDropdown
										fieldName='contact'
										contactList={internalContacts}
										selectedContact={fromContact}
										fieldlabel={t('sendmoneyTranslation.data.contact')}
										onContactChange={(value: Contact) => handleSelectFromContacts(value, setFieldValue)}
									/>
								</Grid>
								<Grid item xs={6}>
									<MuiTextField
										name="fromWalletId"
										fullWidth
										label={t('login.clienId')}
										onChange={(event) => handleWalletIdChange(event, setFieldValue)}
									/>
								</Grid>
								<Grid item xs={6}>
									<AccountDropdown
										fieldlabel={t('sendmoneyTranslation.data.to')}
										fieldName='toAccount'
										accountList={transferAccounts}
										selectedAccount={selectedAccount}
										onAccountChange={(value: BankAccount) => handleAccountChange(value)} />
								</Grid>
								<Grid item xs={6}>
									<NumberFormat
										name="amount"
										decimalScale={2}
										customInput={MuiTextField}
										onChange={(event) => handleAmountChange(event, setFieldValue, setFieldTouched)}
										value={amount}
										allowNegative={false}
										displayType={'input'}
										placeholder={'0.00'}
										fullWidth
										label={t('sendmoneyTranslation.data.transferamount')}
										inputProps={{ autoComplete: 'off' }}
										InputProps={{
											startAdornment: <InputAdornment position="start">{getCurrencySign(selectedAccount?.currency ?? '')}</InputAdornment>,
										}}
									/>
								</Grid>
								<Grid item xs={12} >
									<StyledDivider />
								</Grid>
								<Grid item xs={12}>
									<MuiTextField
										name="reference"
										multiline
										fullWidth
										inputProps={{ autoComplete: 'off', maxLength: 140 }}
										label={t('sendmoneyTranslation.data.reference')}
										rows={2}
									/>
								</Grid>
							</Grid>
							<Grid container mt='auto' pt='2rem' columnSpacing={2}>
								<Grid item xs={6}>
									<Button
										variant='customOutlined'
										color='secondary'
										onClick={onClose}
										sx={{ width: { xs: '100%', md: 'auto' } }}
									>  {t('form.buttons.cancel')}
									</Button>
								</Grid>
								<Grid item xs={6} container justifyContent='flex-end'>
									<Button disabled={isSubmitting || !isEmpty(errors) || !selectedAccount}
										variant='contained'
										color='primary'
										type="submit"
										sx={{ width: { xs: '100%', md: 'auto' } }}
									>
										{t('form.buttons.next')}
									</Button>
								</Grid>
							</Grid>
						</FormContentBox>
					</Form>
				);
			}}
		</Formik >);
};

export default RequestMoneyDetails;
