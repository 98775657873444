
import * as React from 'react';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import { UserType } from '@/features/user/types';
import { RootState } from '@/rootReducer';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


const AccountBeingCreated = () => {
	const { t } = useTranslation('translations');

	const { user } = useSelector(
		(state: RootState) => state.user
	);

	return (
		<>
			<MuiHeader textAlign='center' p={4}> {t('banking.data.ordered.title')}</ MuiHeader >
			<MuiTextCaption variant='body2' color='text.primary' lineHeight='19px' textAlign='center' p={4}>
				{user.type === UserType.BUSINESS ? t('banking.data.ordered.textBusiness') : t('banking.data.ordered.text')}
			</MuiTextCaption>
		</>
	);
};

export default AccountBeingCreated;
