import { Alert, Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import FormHeader from '../Components/FormHeader';
import FormFooterButtons, { FormFooterButtonsProps } from '../Components/FormFooterButtons';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { CoinType } from '@/features/coins/types';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import TokenAvatar, { TokenAvatarSizeEnum } from '@shared/Widgets/Avatars/TokenAvatar';
import FormLabel from '@shared/Components/SendMoney/PaymentForm/FormLabel';
import MuiFormLabel from '@shared/Widgets/MuiText/MuiFormLabel';
import MuiCopyToClipboard from '@shared/Widgets/MuiCopyToClipboard';

interface Props {
	onBack: () => void,
	token: CoinType
}

const DepositToOtherUser = ({ token, onBack }: Props): React.ReactElement => {

	const closeModal = useCloseDialogNoEvent();
	const { t } = useTranslation('translations');

	const { agent } = useSelector(
		(state: RootState) => state.status
	);

	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const footerBtn: FormFooterButtonsProps[] = [
		{
			title: t('form.buttons.close'),
			variant: 'customOutlined',
			onClick: closeModal
		},
		{
			title: t('wallet.deposit.goToWallet'),
			variant: 'contained',
			onClick: closeModal
		}];

	return (
		<>
			<Grid pb='1rem'>
				<Grid item>
					<FormHeader onBack={onBack} title={t('wallet.deposit.cryptoFromUser')} />
				</Grid>
				<Grid container item xs={12} justifyContent='center' mb={3}>
					<Grid item xs={11}>
						<MuiFormText textAlign={'center'} dangerouslySetInnerHTML={{ __html: `${t('wallet.deposit.cryptoFromUserInstructions.1', { displayName: token.info.displayName, ccy: token.info.symbol, agent })}` }} />
					</Grid>
				</Grid>
				<Grid container item display={'flex'} justifyContent={'center'} alignItems="center" flexWrap='nowrap' my={4}>
					<TokenAvatar symbol={token.info.symbol} logoSource={token.info.logo} variant={TokenAvatarSizeEnum.large} />
					<Typography ml={2} color='text.primary' sx={{ fontSize: '1.225rem', fontWeight: '700' }}>
						{token.info.displayName}
					</Typography>
					<Typography ml={1} color='text.secondary' sx={{ fontSize: '1.225rem' }}>
						{token.info.symbol}
					</Typography>
				</Grid>
				<Grid item container justifyContent='space-around' alignItems='center' >
					<Grid item xs={12} mb={1}>
						<FormLabel textAlign='center'>{t('login.clienId')}</FormLabel>
					</Grid>
					<Grid item xs={9} container display={'flex'} justifyContent={'center'}>
						<Box sx={(theme) => ({
							background: theme.palette.background.paper,
							borderRadius: theme.mainBorderRadius,
							p: 1,
						})}>
							<Grid container
								alignItems='center'>
								<MuiFormLabel>
									{user?.tag ?? user.clientId}
								</MuiFormLabel>
								<MuiCopyToClipboard value={user?.tag ?? user.clientId} />
							</Grid>
						</Box>
					</Grid>
				</Grid>
				<Grid container item xs={12} justifyContent='center' mt={3}>
					<Grid item xs={12}>
						<Alert severity="warning"  >
							<MuiFormText dangerouslySetInnerHTML={{ __html: `${t('wallet.deposit.cryptoFromUserInstructions.2', { displayName: token.info.displayName, ccy: token.info.symbol, agent })}` }} />
						</Alert>
					</Grid>
				</Grid>
			</Grid>
			<FormFooterButtons buttons={footerBtn} />
		</>
	);
};

export default DepositToOtherUser;
