import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import './AssetList.css';
import TokenAvatar, { TokenAvatarSizeEnum } from '@/componentsMui/Shared/Widgets/Avatars/TokenAvatar';
import { CoinType } from '@/features/coins/types';
import bigDecimal from 'js-big-decimal';
import { hiddenBabalance } from '@/helpers/placeholders';
import MuiTextTrx from '@shared/Widgets/MuiText/MuiTextTrx';
import { getFiatValue } from '@helpers/getFiatValue';
import { getCurrencySign } from '@features/fees/slice';
import MuiTextCaption from '@shared/Widgets/MuiText/MuiTextCaption';
interface Props {
	token: CoinType,
	currency: string,
	hideValues: boolean,
	hideConvert: boolean,
}

const CryptoAssetLineMobile = ({ token, currency, hideValues, hideConvert }: Props) => {
	const history = useHistory();
	const isFiatLine = token?.type === 'WALLET';

	const openDetails = () => {
		if (isFiatLine || hideConvert) return;
		history.push(`/wallet/home/wallets/details?token=${token.type}${token.info.symbol}`);
	};

	const precision = token?.account?.precision ?? 5;

	const availableBalance = bigDecimal.compareTo(token?.account?.availableBalance, 0) === 0 ? 0 : new bigDecimal(token?.account?.availableBalance ?? 0).round(precision, bigDecimal.RoundingModes.DOWN).getValue();

	const isAvailableBalanceLessThanShow = bigDecimal.compareTo(token?.account?.availableBalance, 0) && bigDecimal.compareTo(availableBalance, 0) === 0;

	return (
		<Box onClick={openDetails}
			pb='1rem'
		>
			<Grid item xs={12} container alignItems='center' columnSpacing={1} wrap='nowrap'>
				<Grid item justifyContent='flex-start' alignItems='center'>
					<TokenAvatar symbol={token.info.symbol}
						logoSource={token.info.logo}
						variant={TokenAvatarSizeEnum.regular} />
				</Grid>
				<Grid item
					display='flex'
					direction='column'
					justifyContent='space-evenly'
					minWidth='0'
				>
					<Grid item xs={12}>
						<Typography
							color="text.primary"
							fontWeight={700}
							noWrap sx={{
								wordWrap: 'break-word',
								overflow: 'hidden',
								textOverflow: 'ellipsis'
							}}>
							{token.info.displayName}
						</Typography>
					</Grid>
				</Grid>
				<Grid item
					ml='auto'
					display='flex'
					direction='column'
					justifyContent='space-evenly'>
					<Grid>
						<MuiTextTrx textAlign='right' sx={{ whiteSpace: 'nowrap' }}>
							{hideValues
								? hiddenBabalance
								: <NumberFormat
									displayType={'text'}
									decimalScale={isAvailableBalanceLessThanShow ? precision + 1 : precision}
									fixedDecimalScale={false}
									thousandsGroupStyle='thousand'
									thousandSeparator={true}
									prefix={`${token.info.symbol} `}
									value={isAvailableBalanceLessThanShow ? `${availableBalance}1` : availableBalance}
								/>}
						</MuiTextTrx>
					</Grid>
					<Grid item xs={12} justifyContent={'flex-end'}>
						<MuiTextCaption textAlign='right' color='text.secondary'>
							{hideValues
								? hiddenBabalance
								: <NumberFormat
									displayType={'text'}
									fixedDecimalScale={true}
									decimalScale={2}
									thousandsGroupStyle='thousand'
									thousandSeparator={true}
									value={getFiatValue(token, currency)}
									prefix={`${getCurrencySign(currency)} `} />}
						</MuiTextCaption>
					</Grid>
				</Grid>
			</Grid>
		</Box>);
};

export default CryptoAssetLineMobile;

