export interface Status {
	loggedIn: boolean,
	permissions: Array<string>,
	restricted: Array<string>,
	roles: Array<string>,
	source: string,
	sumsubUrl?: string,
	iSumsubFlow?: string
	bSumsubFlow?: string
	twoFARequired?: boolean,
	kycProvider?: 'sumsub' | 'onfido' | 'advisionary',
	kybProvider?: 'sumsub' | 'onfido' | 'advisionary',
	autoAccountCreation?: boolean,
	confirmPhoneRequired?: boolean,
	skipKycAllowed?: boolean,
	custodyAccountSettings?: {
		autoCreate?: boolean,
		name?: string,
		ccy?: string
	},
	bankProvider?: string,
	collectAddionalDataForOAM?: boolean,
}

export interface MaintenanceMode {
	mode: boolean, reason: string
}

export enum LoginTypeEnum {
	EMAIL = 'EMAIL',
	PHONE = 'PHONE',
	BOTH = 'BOTH'
}


export enum KycStatus {
	CREATED = 'CREATED',
	PENDING = 'PENDING',
	VERIFIED = 'VERIFIED',
	IN_PROGRESS = 'IN_PROGRESS',
	REJECTED = 'REJECTED',
	PAID = 'PAID'
}

export class KycTier {
	tier: number;
	status: KycStatus;
	createdDate: Date;
	updatedDate: Date;
	rejectionReason: string;
	final: boolean;
	checkCount: number;
	expiredInMonth: boolean;
}

export class BankAccount {
	id?: number;
	refNumber?: string;
	status?: string;
	accountNumber?: string;
	iban?: string;
	bankName?: string;
	bankAddress?: string;
	sortCode?: string;
	swiftBicCode?: string;
	createdDate?: Date;
	updatedDate?: Date;
}

export enum UserType {
	INDIVIDUAL = 'INDIVIDUAL',
	BUSINESS = 'BUSINESS',
	EMPLOYEE = 'EMPLOYEE'
}

export interface User {
	id: number;
	email: string;
	kycVerificationStatus?: number;
	emailConfirmed?: boolean;
	emailConfirmationResent?: boolean;
	twoFactorAuthEnabled?: boolean;
	kycTier?: number;
	previousKycTier?: number;
	recoveryEmail?: string;
	recoveryEmailConfirmed?: boolean;
	recoveryNumber?: string;
	recoveryNumberConfirmed?: boolean;
	hideValues?: boolean;
	whitelistEnabled?: boolean;
	detained?: boolean;
	detainedReason?: string;
	disabled?: boolean;
	ipSettings?: string;
	createdDate?: string;
	previousLoginDate?: string;
	// User profile data
	avatar?: any;
	username?: string;
	firstName?: string;
	lastName?: string;
	birthDate?: string;
	address?: string;
	address2?: string;
	city?: string;
	country?: string;
	state?: string;
	taxCountry?: string;
	taxState?: string;
	taxIdNumber?: string;
	postalCode?: string;
	mobileNumber?: string;
	kyc?: KycTier[];
	hasBusiness?: boolean;
	clientId?: string;
	type?: UserType;
	bankAccount?: BankAccount;
	companyName?: string;
	companyRegNumber?: string;
	companyAddress?: string;
	companyCountry?: string;
	userLevelId?: number;
	accountSettings?: AccountSettings;
	onBehalfOf: string;
	onBehalfOfCompany: string;
	poa?: PoaStatus,
	plan?: Plan,
	confirmedMobileNumber?: string,
	switchOptions: SwitchOption[]
	v3SwitchOptions: V3SwitchOption[],
	passwordSetted: boolean,
	addressCountry?: string,
	mobilePinEnabled: boolean,
	preferredLanguage?: string,
	skipKycVerification?: boolean
	occupationCode?: boolean,
	tabs?: {
		showReferrals?: boolean
		hidePlans?: boolean
	},
	vaultTermsLink?: string;
	hideMainAccountSection?: boolean;
	risksVerified?: boolean;
	kycIdentityRequiredFieldsPresent: boolean;
	preferredDocType?: string
	tag?: string;
	onlySkipKycUsers?: boolean;
	tradesDisabled?: boolean;
	hideVaultAutoExtend?: boolean;
	cryptoTransferDisabled?: boolean;
}

export interface Plan {
	upgradeAllowed: boolean,
	currentUserLevel: {
		name: string;
		id: number;
		priority: number;
		endTime: string;
		startTime?: string;
	},
	nextPlan: NextPlan;
	availablePlans: Array<AvailablePlan>
}

export interface NextPlan {
	autoUpdate: boolean;
	defaultLevel: boolean;
	description: string;
	endTime: string;
	name: string;
	price: number;
	priority: number;
	startTime: string;
	userLevelId: number;
	paymentAccountId: number;
}

export interface AvailablePlan {
	name: string,
	ccy: string,
	defaultLevel: boolean,
	description: string,
	currencySymbol: string,
	price: number,
	priority: number,
	userLevelId: number,
}

export interface PoaStatus {
	required: boolean,
	status: string,
	rejectionReason: string
}

export interface AccountSettings {
	maxCount: number,
	physicalCardLimitCount?: number,
	virtualCardLimitCount?: number,
	unlimited: boolean
}

export interface UserError {
	error: string,
	error_param: string
}

export interface UserStore {
	user: User,
	status: Status,
	error: UserError,
	maintenanceMode: MaintenanceMode,
	twoFactorCode: string,
	backupKey: string,
	kycProvider?: 'sumsub' | 'onfido',
	kybProvider?: 'sumsub' | 'onfido',
	clientId?: string,
	onBehalfOf: number,
	passwordSetted?: boolean
}

export interface DeviceInfo {
	browser: string,
	platform: string,
	hash: string
}

export interface LoginBody {
	email: string,
	password: string,
	deviceInfo: DeviceInfo,
	recaptchaToken: string,
}

export interface V3LoginBody {
	identifier: string,
	password: string,
	deviceInfo: DeviceInfo
	phoneNumber?: string,
	email?: string,
	recaptchaToken: string,
	grantType?: string,
}

export interface ForgotBody {
	email: string,
	recaptchaToken: string,
}

export interface ResetBody {
	code: string,
	password: string,
	recaptchaToken: string,
}

export interface ValidateCodeBody {
	code: string
}

export interface V3SignupBody {
	type: LoginTypeEnum,
	politicalExposed: boolean,
	//usResident: boolean,
	email?: string,
	country?: string,
	password: string,
	phoneNumber?: string,
	countryCode?: string,
	deviceInfo: DeviceInfo,
	referalCode?: string,
	freelancerCode?: string,
	freelancer: boolean,
	recaptchaToken: string,
	firstName?: string,
	lastName?: string,
	invitationCode?: string,
}

export enum TokenGrantTypeEnum {
	PORTAL = 'portal',
	OAUTH = 'oauth2'
}

export interface Credentials {
	//email: string,
	token: string,
	tokenGrantType?: TokenGrantTypeEnum | undefined,
}

export interface SwitchBody {
	clientId: string,
	deviceInfo: DeviceInfo
}


export interface SwitchOption {
	clientId: string,
	name: string,
	identityPassed?: boolean,
	type?: UserType
}

export interface V3SwitchOption {
	clientId: string,
	businessUserId: number,
	name: string,
	identityPassed?: boolean,
	type?: UserType,
	disabled?: boolean,
}



export interface DocumentTypes {
	type: string,
	logo: string
}

export interface UserDocumentTypes {
	country: string,
	docTypes: DocumentTypes[]
}

export interface DocumentInformationPayload {
	preferredDocType: string,
	country: string, 
	userProvidedGender?: string,
}
