import { useState, useRef, useEffect } from 'react';
import { Typography, TypographyProps, Tooltip } from '@mui/material';
import React from 'react';

const TypographyWithEllipsis = ({ children, ...props }: TypographyProps) => {
    const textRef = useRef<HTMLSpanElement>(null);
    const [isOverflown, setIsOverflown] = useState(false);

    const checkOverflow = () => {
        if (textRef.current) {
            const { scrollWidth, clientWidth } = textRef.current;
            setIsOverflown(scrollWidth > clientWidth);
        }
    };

    // Effect to check overflow on mount and on every change to children
    useEffect(() => {
        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, [children]);

    return (
        <Tooltip title={children || ''} disableHoverListener={!isOverflown}
            PopperProps={{
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -10],
                        },
                    },
                ],
            }}>
            <Typography {...props} ref={textRef} noWrap>
                {children}
            </Typography>
        </Tooltip >
    );
};

export default TypographyWithEllipsis;
