import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { Grid } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import React from 'react';

const TrxDateCell = (params: GridRenderCellParams) => {

    if (params.value === null || params.value === undefined) {
        return '';
    }

    const date = new Date(params.value);
    return (<Grid container flexWrap='wrap'>
        <MuiFormText pr='5px'>
            {format(date, 'dd/MM/yyyy')}
        </MuiFormText>
        <MuiFormText color='text.secondary'>
            {format(date, 'HH:mm:ss')}
        </MuiFormText>
    </ Grid>);
};

export default TrxDateCell;
