import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setLandingTitle } from '@/features/landingStep/slice';
import MinimalLayout from '../Layout/MinimalLayout';
import { Button, Grid } from '@mui/material';
import MobileHeader from './components/MobileHeader';
import { Form, Formik } from 'formik';
import MuiTextField from '../Shared/FormikComponents/MuiTextField';
import MuiFormText from '../Shared/Widgets/MuiText/MuiFormText';
import * as Yup from 'yup';
import { showException } from '@/features/swal/slice';
import { isEmpty } from 'lodash';
import FormikAutocomplete, { FormikAutocompleteOptionType } from '../Shared/FormikComponents/FormikAutocomplete';
import { getLookupValues } from '@/features/fnd/fndSlice';
import { calculateScore, saveIdentityDetails } from '@/features/identity/slice';
import { refreshUser } from '@/features/user/userSlice';
import CountryCodesAlpha3 from '../Shared/FormikComponents/CountryCodesAlpha3';

interface Props {
	paddingTop?: number,
	next: () => void
}

export enum QAEnum {
	RISK_EMPLOYMENT_STATUS = 'RISK_EMPLOYMENT_STATUS',
	OCCUPATION = 'OCCUPATION',
	TURNOVER = 'TURNOVER',
	INCOME_SOURCE = 'INCOME_SOURCE',
	RISK_WEALTH_TOTAL = 'RISK_WEALTH_TOTAL',
	RISK_WEALTH_SOURCE = 'RISK_WEALTH_SOURCE',
	RISK_BY_SERVICE = 'RISK_BY_SERVICE',
}

const ProfileDetailsModulR = ({ paddingTop, next }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const [employmentStatusList, setEmploymentStatusList] = useState<FormikAutocompleteOptionType[]>([]);
	const [occupationList, setOccupationList] = useState<FormikAutocompleteOptionType[]>([]);
	const [turnoverList, setTurnoverList] = useState<FormikAutocompleteOptionType[]>([]);
	const [incomeSourceList, setIncomeSourceList] = useState<FormikAutocompleteOptionType[]>([]);
	const [wealthTotalList, setWealthTotal] = useState<FormikAutocompleteOptionType[]>([]);
	const [wealthSourceList, setWealthSourceList] = useState<FormikAutocompleteOptionType[]>([]);
	const [serviceList, setServiceList] = useState<FormikAutocompleteOptionType[]>([]);
	const title = t('landing.profileDetails.title');

	useEffect(() => {
		dispatch(setLandingTitle(title));
	}, [dispatch, title]);

	const getLov = useCallback(async (name: QAEnum) => {
		try {
			const result = await getLookupValues(name);
			const options = result?.map(p => ({
				code: p.lookupCode,
				label: p.description
			})) || [];
			if (name === QAEnum.RISK_EMPLOYMENT_STATUS) {
				setEmploymentStatusList(options);
			}
			else if (name === QAEnum.OCCUPATION) {
				setOccupationList(options);
			}
			else if (name === QAEnum.TURNOVER) {
				setTurnoverList(options);
			}
			else if (name === QAEnum.INCOME_SOURCE) {
				setIncomeSourceList(options);
			}
			else if (name === QAEnum.RISK_WEALTH_TOTAL) {
				setWealthTotal(options);
			}
			else if (name === QAEnum.RISK_WEALTH_SOURCE) {
				setWealthSourceList(options);
			}
			else if (name === QAEnum.RISK_BY_SERVICE) {
				setServiceList(options);
			}
		}
		catch (e) {
			console.log(e);
		}
	}, []);

	useEffect(() => {
		getLov(QAEnum.RISK_EMPLOYMENT_STATUS);
	}, [getLov]);

	useEffect(() => {
		getLov(QAEnum.OCCUPATION);
	}, [getLov]);

	useEffect(() => {
		getLov(QAEnum.TURNOVER);
	}, [getLov]);

	useEffect(() => {
		getLov(QAEnum.INCOME_SOURCE);
	}, [getLov]);

	useEffect(() => {
		getLov(QAEnum.RISK_WEALTH_TOTAL);
	}, [getLov]);

	useEffect(() => {
		getLov(QAEnum.RISK_WEALTH_SOURCE);
	}, [getLov]);

	useEffect(() => {
		getLov(QAEnum.RISK_BY_SERVICE);
	}, [getLov]);



	const initialValues = {
		employmentStatus: { code: '', label: '' },
		occupation: { code: '', label: '' },
		turnover: { code: '', label: '' },
		incomeSource: { code: '', label: '' },
		wealthTotal: { code: '', label: '' },
		wealthSource: { code: '', label: '' },
		wealthSourceOther: '',
		service: { code: '', label: '' },
		otherReason: '',
		countryOfBussinessActivity: '',
	};

	const validationSchema = Yup.object({
		employmentStatus: Yup.object().shape({
			code: Yup.string().required(t('form.validator.required')),
		}),
		countryOfBussinessActivity: Yup.string().when('employmentStatus', {
			is: (employmentStatus) => { return ['1', '2'].includes(employmentStatus?.code); },
			then: Yup.string().required(t('form.validator.required')),
			otherwise: Yup.string().notRequired()
		}),
		/*  countryOfBussinessActivity: Yup.string()
			 .test(
				 'countryOfBussinessActivity',
				 t('form.validator.required'),
				 (value) => {
					 return ((['1', '2'].includes(employmentStatusCode) && !!value) ||
						 !['1', '2'].includes(employmentStatusCode));
				 }
			 ), */
		occupation: Yup.object().shape({
			code: Yup.string().required(t('form.validator.required')),
		}),
		turnover: Yup.object().shape({
			code: Yup.string().required(t('form.validator.required')),
		}),
		incomeSource: Yup.object().shape({
			code: Yup.string().required(t('form.validator.required')),
		}),
		wealthTotal: Yup.object().shape({
			code: Yup.string().required(t('form.validator.required')),
		}),
		wealthSource: Yup.object().shape({
			code: Yup.string().required(t('form.validator.required')),
		}),
		wealthSourceOther: Yup.string().test(
			'wealthSourceOther',
			t('form.validator.required'),
			(value, ctx) =>
				(ctx.parent.wealthSource.code === '99' && !!value) ||
				ctx.parent.wealthSource.code !== '99'
		),
		service: Yup.object().shape({
			code: Yup.string().required(t('form.validator.required')),
		}),
		otherReason: Yup.string().test(
			'otherReason',
			t('form.validator.required'),
			(value, ctx) =>
				(ctx.parent.service.code === '99' && !!value) ||
				ctx.parent.service.code !== '99'
		),
	});

	const submit = async (formData) => {

		try {

			const { employmentStatus,
				occupation,
				turnover,
				incomeSource,
				wealthTotal,
				wealthSource,
				wealthSourceOther,
				service,
				otherReason,
				countryOfBussinessActivity, } = formData;

			const isWorking = ['1', '2'].includes(employmentStatus.code);

			const payload = {
				employmentStatus: employmentStatus.code,
				occupationCode: occupation.code,
				wealthTotal: wealthTotal.code,
				sourceOfIncomeCode: incomeSource.code,
				projectedDepositCode: turnover.code,
				subscriptionReasonCode: service.code,
				subscriptionReason: otherReason,
				wealthSource: wealthSource.code,
				wealthSourceOther: wealthSourceOther,
				countryOfBussinessActivity: isWorking ? countryOfBussinessActivity : undefined,
			};


			await saveIdentityDetails(payload);
			await calculateScore();
			await dispatch(refreshUser());
			next();
		} catch (e) {
			showException(e);
		}

	};

	useEffect(() => {
		dispatch(setLandingTitle(title));
	}, [dispatch, title]);


	return (
		<MinimalLayout paddingTop={paddingTop} allowGoHome={false}>
			<Grid container id='poa-step' >
				<Grid item >
					<MobileHeader text={title} />
					<Formik
						initialValues={initialValues}
						enableReinitialize
						validationSchema={validationSchema}
						onSubmit={submit}
					>
						{({ values, errors, isSubmitting }) => {
							return (
								<Form>
									<Grid container>
										<Grid item xs={12} mb={{ xs: '20px', sm: '40px' }} >
											<MuiFormText>
												{t('landing.profileDetails.details')}
											</MuiFormText>
										</Grid>
										<Grid item xs={12} container rowGap={{ xs: 0, sm: '20px' }}>
											<Grid item xs={12}>
												<MuiFormText fontWeight='bold'>{t('landing.profileDetails.employmentStatus')}</MuiFormText>
												<FormikAutocomplete
													fieldName='employmentStatus'
													options={employmentStatusList} />
											</Grid>
											{['1', '2'].includes(values.employmentStatus?.code) &&
												<Grid item xs={12}>
													<CountryCodesAlpha3 fieldName='countryOfBussinessActivity' fieldlabel={t('landing.profileDetails.country')} />
												</Grid>
											}
											<Grid item xs={12}>
												<MuiFormText dangerouslySetInnerHTML={{ __html: t('landing.profileDetails.occupation') }} />
												<FormikAutocomplete fieldName='occupation'
													options={occupationList}
												/>
											</Grid>
											<Grid item xs={12}>
												<MuiFormText dangerouslySetInnerHTML={{ __html: t('landing.profileDetails.amount') }} />
												<FormikAutocomplete fieldName='turnover'
													options={turnoverList}
												/>
											</Grid>
											<Grid item xs={12}>
												<MuiFormText fontWeight='bold' dangerouslySetInnerHTML={{ __html: t('landing.profileDetails.monthlySource') }} />
												<FormikAutocomplete fieldName='incomeSource'
													options={incomeSourceList}
												/>
											</Grid>
											<Grid item xs={12}>
												<MuiFormText fontWeight='bold'>{t('landing.profileDetails.wealthTotal')}</MuiFormText>
												<FormikAutocomplete fieldName='wealthTotal'
													options={wealthTotalList}
												/>
											</Grid>
											<Grid item xs={12}>
												<MuiFormText fontWeight='bold'>{t('landing.profileDetails.wealthSource')}</MuiFormText>
												<FormikAutocomplete fieldName='wealthSource'
													options={wealthSourceList}
												/>
											</Grid>
											{values.wealthSource?.code === '99' &&
												<Grid item xs={12}>
													<MuiTextField
														name="wealthSourceOther"
														label={t('landing.profileDetails.wealthSourceOther')}
														fullWidth
														inputProps={{ maxLength: 100 }}
													/>
												</Grid>
											}
											<Grid item xs={12}>
												<MuiFormText dangerouslySetInnerHTML={{ __html: t('landing.profileDetails.reason') }} />
												<FormikAutocomplete fieldName='service'
													options={serviceList}
												/>
											</Grid>
											{values.service?.code === '99' &&
												<Grid item xs={12}>
													<MuiTextField
														name="otherReason"
														label={t('landing.profileDetails.otherReason')}
														fullWidth
														inputProps={{ maxLength: 100 }}
													/>
												</Grid>
											}

											<Grid item xs={12} mb={3}>
												<Button
													variant='contained'
													type="submit"
													fullWidth
													disabled={isSubmitting || !isEmpty(errors)}
												>
													{t('form.buttons.next')}
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Form>);
						}}
					</Formik>
				</Grid>
			</Grid>

		</MinimalLayout >);
};

export default ProfileDetailsModulR;
