import * as React from 'react';

import { Dialog } from '@mui/material';
import HelpTopicSteps from './HelpTopicSteps';
import FormPaperLargeFixed from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeFixed';
import { HelpTopicsEnum } from '../Components/HelpTopics';
import MessageSteps from './MessageSteps';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { openForm } from '@/features/forms/slice';
import { useDispatch } from 'react-redux';



const NewTicketForm = () => {

	const [topic, setTopic] = React.useState<HelpTopicsEnum>(null);
	const [step, setStep] = React.useState<number>(0);
	const nextStep = React.useCallback(() => setStep(previousState => (previousState + 1)), []);
	const prevStep = React.useCallback(() => setStep(previousState => (previousState - 1)), []);
	const closeModal = useCloseDialogNoEvent();
	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);


	const onSelect = (value: HelpTopicsEnum) => {
		setTopic(value);
		nextStep();
	};
	const handleClose = () => {
		setStep(0);
		setTopic(null);
		closeModal();
	};

	return (
		<Dialog
			id="new-ticket-form"
			open={true}
			onClose={handleClose}
			PaperComponent={FormPaperLargeFixed}
		>
			{step === 0 && <HelpTopicSteps onClose={handleClose} onSelect={onSelect} />}
			{step === 1 && <MessageSteps topic={topic} onBack={prevStep} />}
		</Dialog >
	);
};

export default NewTicketForm; 
