import * as React from 'react';

import { Button, Dialog, DialogActions, Grid, InputLabel, MenuItem } from '@mui/material';
import DialogContentNoScrollbar from '@/componentsMui/Shared/Widgets/Dialog/DialogContentNoScrollbar';
import { useTranslation } from 'react-i18next';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import { useCallback, useEffect, useState } from 'react';
import { getPlanChangeOptions, postChangePlan } from '@/features/earn/depositsSlice';
import StyledSelect from '@/componentsMui/Shared/Widgets/StyledSelect';
import { showException, showSuccess } from '@/features/swal/slice';
import { useDispatch } from 'react-redux';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';

interface Props {
	open: boolean,
	onClose: () => void,
	allocation: any,
}

const ChangePlanForm = ({ open, onClose, allocation }: Props) => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const [loadingOptions, setLoadingOptions] = useState<boolean>(false);
	const [submitLoading, setSubmitLoading] = useState<boolean>(false);
	const [options, setOptions] = useState<any[]>([]);
	const [selectedPlan, setSelectedPlan] = useState<any>();

	const fetchData = useCallback(() => {
		if (!allocation) { return; }
		const get = async () => {
			try {
				setLoadingOptions(true);
				const response = await getPlanChangeOptions(allocation.accountId);
				const _options = response.map(option => {
					return {
						key: option.process.proc,
						value: option.process.name,
					};
				});
				setOptions(_options);
			} catch (e) {
				setOptions([]);
			} finally {
				setLoadingOptions(false);
			}
		};
		get();
	}, [allocation]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const handleChange = (event: any) => {
		const newValue = event.target.value;
		setSelectedPlan(newValue);
	};

	const handleSubmit = async () => {
		try {
			setSubmitLoading(true);
			dispatch(postChangePlan(allocation.accountId, selectedPlan));
			showSuccess({ title: 'Request submitted succesfully', mui: true });
		} catch (err) {
			showException(err);
		} finally {
			setSubmitLoading(false);
			onClose();
		}
	};

	return (
		<Dialog
			id="change-plan-form"
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
			PaperProps={{
				style: {
					padding: '20px'
				}
			}}
		// PaperComponent={FormPaperFit}
		>
			<DialogContentNoScrollbar>
				<Grid item xs={12}>
					<FormHeader title={t('vaults.planChange.formTitle')} showBack={false} mb='0' />
				</Grid>
				{loadingOptions ?
					<Grid item xs={12} mb={4} mt={4} display='flex' justifyContent='center'>
						<Spinner />
					</Grid>
					: <>
						{options.length === 0 ? <Grid item xs={12} mb={4} mt={4}>
							{t('vaults.planChange.noOptions')}
						</Grid>
							: <Grid item xs={12} mb={4} mt={4}>
								<InputLabel>{t('vaults.planChange.selectLbl')}</InputLabel>
								<StyledSelect
									name='plan'
									value={selectedPlan}
									displayEmpty
									onChange={handleChange}
									fullWidth
								>
									{options.length > 0 &&
										options.map((item) => (
											<MenuItem key={item.key} value={item.key}>
												<div className='select-primary-Item'>{item.value} </div>
											</MenuItem>
										))}
								</StyledSelect>
							</Grid>}
					</>}
			</DialogContentNoScrollbar>
			<DialogActions>
				<Grid item xs={6}>
					<Button
						variant='customOutlined'
						color='secondary'
						onClick={onClose}
						sx={{ width: { xs: '100%', md: 'auto' } }}
					>  {t('form.buttons.close')}
					</Button>
				</Grid>
				<Grid item xs={6} container justifyContent='flex-end'>
					<Button
						variant='contained'
						color='primary'
						onClick={handleSubmit}
						disabled={!selectedPlan || submitLoading}
						sx={{ width: { xs: '100%', md: 'auto' } }}
					>
						{t('form.buttons.change')}
					</Button>
				</Grid>
			</DialogActions>
		</Dialog >
	);
};

export default ChangePlanForm; 
