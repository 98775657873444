import * as React from 'react';
import { SvgIcon } from '@mui/material';

const MenuHelpIcon = (props) => (
    <SvgIcon {...props} >
        <svg width="22" height="22" viewBox="0 0 22 22" fill="current" stroke="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 1.833A9.167 9.167 0 001.833 11 9.167 9.167 0 0011 20.167 9.167 9.167 0 0020.167 11 9.167 9.167 0 0011 1.833zM0 11C0 4.925 4.925 0 11 0s11 4.925 11 11-4.925 11-11 11S0 17.075 0 11z"
                fill="current"
            />
            <path
                d="M11.611 16.5a.611.611 0 11-1.222 0 .611.611 0 011.222 0z"
                fill="current"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 16.256a.244.244 0 100 .489.244.244 0 000-.49zm-.978.244a.978.978 0 111.956 0 .978.978 0 01-1.956 0zM10.974 6.172a1.93 1.93 0 00-1.93 1.93.917.917 0 11-1.833 0 3.763 3.763 0 117.526 0c0 1.53-1.053 2.318-1.724 2.819l-.066.05c-.758.568-1.056.848-1.056 1.4v1.424a.917.917 0 01-1.834 0v-1.423c0-1.53 1.054-2.318 1.724-2.82l.066-.048c.759-.57 1.057-.849 1.057-1.402a1.93 1.93 0 00-1.93-1.93z"
                fill="current"
            />
        </svg>
    </SvgIcon>
);

export default MenuHelpIcon;
