import { alpha3ToAlpha2, getNames, isValid } from 'i18n-iso-countries';
import request from '../../services/request';
import { CountryPhoneCodesType } from './fndTypes';
import { sortBy } from 'lodash';

export const getSmsCountries = async (): Promise<Array<CountryPhoneCodesType>> => {
    const countriesName = getNames('en', { select: 'official' });
    const date = new Date();

    const response = await request.get('/api/fnd/smscountries');
    if (response?.data) {
        const codes = response.data
            .filter(p => p.enabled && new Date(p.endDateActive) >= date && new Date(p.startDateActive) <= date)
            .filter(p => isValid(p.lookupCode))
            .map(p => {
                return {
                    countryCode: p.lookupCode,
                    countryCodeAlpha2: alpha3ToAlpha2(p.lookupCode),
                    countryName: countriesName[alpha3ToAlpha2(p.lookupCode)],
                    phoneCode: p.attribute1
                };
            }) || [];

        const finalCodes = codes.filter(p => p.phoneCode !== 'ERROR') || [];

        return sortBy(finalCodes, 'countryName');

    }
    else return [];
};

export const getCardSmsCountries = async (): Promise<Array<CountryPhoneCodesType>> => {
    const countriesName = getNames('en', { select: 'official' });
    const date = new Date();

    const response = await request.get('/api/fnd/cardcountries');
    if (response?.data) {
        const codes = response.data
            .filter(p => p.enabled && new Date(p.endDateActive) >= date && new Date(p.startDateActive) <= date)
            .filter(p => isValid(p.lookupCode))
            .map(p => {
                return {
                    countryCode: p.lookupCode,
                    countryCodeAlpha2: alpha3ToAlpha2(p.lookupCode),
                    countryName: countriesName[alpha3ToAlpha2(p.lookupCode)],
                    phoneCode: p.attribute1
                };
            }) || [];

        const finalCodes = codes.filter(p => p.phoneCode !== 'ERROR') || [];

        return sortBy(finalCodes, 'countryName');

    }
    else return [];
};



export const getLookupValues = async (type: string, lang?: string, sortColumn?: string) => {
    const response = await request.get(`/api/fnd/active/${type}/${lang ? lang : 'EN'}`);

    if (response?.data) {
        return sortBy(response.data, sortColumn ? sortColumn : 'attribute2');

    }
    else return [];
};

