import { Divider } from '@mui/material';
import { withStyles } from '@mui/styles';


const StyledDivider = withStyles((theme) => ({
	root: {
		borderColor: (theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.primary.main)
	}
}))(Divider);

export default StyledDivider;
