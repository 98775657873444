import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import request from '../../services/request';
import { ApproveRejectEmployee, Card, Company, Employee, EmployeeStore } from './types';
import { SocketService } from '@/services/socketService';
import { Subscription } from 'rxjs/internal/Subscription';

const initialState: EmployeeStore = {
	loading: true,
	pendingEmployees: [],
	activeEmployees: [],
	myCompanies: [],
	error: null
};

const slice = createSlice({
	name: 'employee',
	initialState,
	reducers: {
		setPendingEmployee(state, action: PayloadAction<Array<Employee>>) {
			state.pendingEmployees = action.payload;
		},
		setActiveEmployee(state, action: PayloadAction<Array<Employee>>) {
			state.activeEmployees = action.payload;
		},
		setMyCompanies(state, action: PayloadAction<Array<Company>>) {
			state.myCompanies = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		}
	}
});

export const { setPendingEmployee, setActiveEmployee, setLoading, setError, setMyCompanies } = slice.actions;



export const getActiveEmployees = (): AppThunk => {
	return async dispatch => {
		try {
			const response = await request.get('/api/business/employees');
			const { data } = response;
			dispatch(setActiveEmployee(data));
		} catch (e) {
			console.log(e);
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const getPendingEmployees = (): AppThunk => {
	return async dispatch => {
		try {
			const response = await request.get('/api/business/inbox');
			const { data } = response;
			dispatch(setPendingEmployee(data));
		} catch (e) {
			console.log(e);
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const getMyAdminCompanies = (): AppThunk => {
	return async dispatch => {
		try {
			const response = await request.get('/api/business/my-companies');
			const { data } = response;
			if(data.length > 0) {
				const list = data.filter(c => c.role === 'ACCOUNTANT');
				dispatch(setMyCompanies(list));
			} else {
				dispatch(setMyCompanies([]));
			}
		} catch (e) {
			console.log(e);
		} finally {
			dispatch(setLoading(false));
		}
	};
};


export const getEmployeeInitials = (employee: Employee): string => {
	return employee.firstName.charAt(0).toUpperCase() + employee.lastName.charAt(0).toUpperCase();
};

export const approveEmployee = async (employee: Employee) => {
	const body: ApproveRejectEmployee = {
		employeeId: employee.employeeId,
		role: employee.role,
		employeeNumber: employee.employeeNumber
	};

	const response = await request.put(`/api/business/code/${employee.referenceCode}/approve`,
		body
	);
	const { data } = response;
	return data;
};

export const rejectEmployee = async (employee: Employee) => {
	const body: ApproveRejectEmployee = {
		employeeId: employee.employeeId,
	};

	const response = await request.put(`/api/business/code/${employee.referenceCode}/reject`,
		body
	);
	const { data } = response;
	return data;
};

export const generateEmployeeCode = async (role: string, company: string, email: string = null) => {
	const response = await request.put(`/api/business/code/${role}`, {company, email});
	const { data } = response;
	return data;
};

export const v3GenerateEmployeeCode = async (role: string, company: string, email: string = null) => {
	const response = await request.put(`/api/business/v3/code/${role}`, {company, email});
	const { data } = response;
	return data;
};

export const unlinkEmployee = async (employee: Employee) => {
	const response = await request.put(`/api/business/employees/${employee.employeeId}/unlink`);
	const { data } = response;
	return data;
};

export const changeRole = async (employee: Employee, role: string) => {
	const response = await request.put(`/api/business/employees/${employee.employeeId}/role`, {role});
	const { data } = response;
	return data;
};

export const addEmployeeCode = async (code: string) => {
	const response = await request.put(`/api/business/code/${code}/accept`);
	const { data } = response;
	return data;
};

export const registerFreelancerCode = async (code: string, userId: string | number) => {
	const response = await request.put(`/api/business/code/${code}/accept/${userId}`);
	const { data } = response;
	return data;
};

export const getAvailableCards = async (): Promise<Card[]> => {
	const response = await request.get('/api/business/cards/available');
	const { data } = response;
	return data;
};

export const linkCard = async (employeeId: number, cardId: number) => {
	const response = await request.put(`/api/business/cards/${cardId}/link`, {employeeId});
	const { data } = response;
	return data;
};

export const unlinkCard = async (employeeId: number, cardId: number) => {
	const response = await request.put(`/api/business/cards/${cardId}/unlink`, {employeeId});
	const { data } = response;
	return data;
};

export const getMyCompanies = async () => {
	const response = await request.get('/api/business/my-companies');
	const { data } = response;
	return data;
};

export const getMyAccountantCompanies = async () => {
	const response = await request.get('/api/business/my-companies/accountant');
	const { data } = response;
	return data;
};

export const setOnBehalfOf = async (businessId: number) => {
	const response = await request.put(`/api/business/on-behalf-of/set/${businessId}`);
	const { data } = response;
	return data;
};

export const unsetOnBehalfOf = async () => {
	const response = await request.put('/api/business/on-behalf-of/unset');
	const { data } = response;
	return data;
};

let socketService: SocketService;
let pendingSubscriber: Subscription;
let activeSubscriber: Subscription;

export const connect = (): void => {
	if (!socketService) {
		socketService = new SocketService('employee');
	}
};

export const subscribe = (): AppThunk => {
	return async dispatch => {
		try {
			pendingSubscriber = socketService.listen('employees.pending', {}).subscribe(() => {
				dispatch(getPendingEmployees());
			});
			activeSubscriber = socketService.listen('employees.active', {}).subscribe(() => {
				dispatch(getActiveEmployees());
			});
		} catch (e) {
			console.log(e);
			dispatch(setError(e));
		}
	};
};

export const unsubscribe = (): void => {
	pendingSubscriber.unsubscribe();
	activeSubscriber.unsubscribe();
};


export default slice.reducer;
