import * as React from 'react';
import { Dialog } from '@mui/material';
import { useEffect } from 'react';

import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { openForm } from '@/features/forms/slice';
import { useDispatch } from 'react-redux';
import Benefits from '@/componentsMui/Dashboard/MyAccount/Benefits/Benefits';
import FormPaperLargeScrollable from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeScrollable';
import FormCloseButton from '@/componentsMui/Wallet/Balances/Deposit/Components/FormCloseButton';

const CardBenefitsForm = () => {
	const dispatch = useDispatch();
	const closeModal = useCloseDialogNoEvent();

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);

	return (
		<Dialog
			open={true}
			onClose={closeModal}
			PaperComponent={FormPaperLargeScrollable}
		>
			<Benefits />
			<FormCloseButton onClose={closeModal} />

		</Dialog >
	);
};


export default CardBenefitsForm; 
