import AmountDifference from '@/componentsMui/Dashboard/HomeSummary/Components/AmountDifference';
import TooltipBox from '@/componentsMui/Shared/Widgets/Chart/TooltipBox';
import TooltipLine from '@/componentsMui/Shared/Widgets/Chart/TooltipLine';
import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

const WalletChartTooltip = (tdata: any): React.ReactElement => {

    const { t } = useTranslation('translations');
    const theme = useTheme();

    const payload = tdata?.payload?.length > 0 ? tdata?.payload[0]?.payload : null;

    if (!payload) {
        return <></>;
    } 

    const cryptoTitle = t('home.crypto');

    return (<TooltipBox>
        <Grid item xs={12} container justifyContent='space-between' mb='21px'>
            <Grid item  >
                <Typography fontSize='0.75rem' fontWeight={700}>
                    <NumberFormat
                        displayType={'text'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandsGroupStyle='thousand'
                        thousandSeparator={true}
                        value={payload.total}
                        prefix='€ ' />

                </Typography>
                <AmountDifference totalDiff={payload.totalDiff} totalDiffPerc={payload.totalDiffPerc} />
            </Grid>
            <Grid item  >
                <Typography fontSize='0.688rem' color={theme.palette.text.secondary}> {payload.xLabel}</Typography>
            </Grid>
        </Grid>
        <TooltipLine title={cryptoTitle} amount={payload.walletAmount} />
    </TooltipBox>
    );
};

export default WalletChartTooltip;
