export enum PolicyType {
	AML_POLICY = 'AML_POLICY',
	PRIVACY_POLICY = 'PRIVACY_POLICY',
	TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
	COOKIES_POLICY = 'COOKIES_POLICY',
	TERMS_OF_USE = 'TERMS_OF_USE',
	VAULT = 'VAULTS_TERMS_OF_USE',
	CRYPTO = 'CRYPTO_TERMS_OF_USE',
	CONTACTS = 'CONTACTS',
	COMPLAINTS = 'COMPLAINTS',
	TOP_UP_CARD_POLICY = 'TOP_UP_CARD_POLICY',
	CLUB_MEMBERSHIP_APPLICATION = 'CLUB_MEMBERSHIP_APPLICATION'
}
