
import * as React from 'react';
import { useTheme } from '@mui/styles';


import { Dialog, DialogProps, Theme } from '@mui/material';
import { FC } from 'react';

/* Placed on base background*/
const DialogWithMenu: FC<DialogProps> = (props: DialogProps) => {

	const theme = useTheme() as Theme;

	//	const { sx, ...other } = props;

	return (<Dialog
		{...props}
		PaperProps={{ sx: { bgcolor: theme.form.formWithMenu } }}
	>{props.children}
	</Dialog>);
};


export default DialogWithMenu;
