import React, { useEffect, useState } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { RootState } from './rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { logout, status, refreshToken, connect, subscribe, unsubscribe, clearUser, disconnect } from '@features/user/userSlice';
import { getOperations } from '@features/operations/slice';
import { SocketService } from '@services/socketService';
import { connect as connectTicket, disconnect as disconnectTicket, subscribe as subscribeTicket, unsubscribe as unsubscribeTicket } from '@features/tickets/slice';
import { connect as connectContacts, disconnect as disconnectContact, subscribe as subscribeContacts, unsubscribe as unsubscribeContacts } from '@features/contacts/slice';
import InactiveModal from './componentsMui/Shared/InactiveModal';
import LoadingPageMui from './pages/LoadingPageMui';
import { TokenGrantTypeEnum } from './features/user/types';
import { connectChatUnreadCount, disconnectChatUnreadCount, subscribeChatUnreadCount, unsubscribeChatUnreadCount } from './features/supportChat/socketUnread';


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line react/prop-types
const ProtectedRoute = ({ component: Component, ...rest }): React.ReactElement => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(true);
	const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
	const [openInactiveModal, setOpenInactiveModal] = useState<boolean>(false);
	const history = useHistory();
	const { token, tokenGrantType } = useSelector(
		(state: RootState) => state.credentials
	);


	const { clientId, onBehalfOf } = useSelector(
		(state: RootState) => state.user
	);

	useEffect(() => {
		if (!token || tokenGrantType === TokenGrantTypeEnum.OAUTH) {
			setIsAuthorized(false);
			return setLoading(false);
		}

		async function getAuthorizationData() {
			try {
				await dispatch(refreshToken());
				const authStatus = await dispatch(status());
				const loggedIn = authStatus['loggedIn'];
				if (!loggedIn) {
					await dispatch(logout());
					setIsAuthorized(false);
					return setLoading(false);
				}
				setIsAuthorized(true);
				return setLoading(false);
			} catch (e) {
				await dispatch(logout());
				setIsAuthorized(false);
				return setLoading(false);
			}
		}

		getAuthorizationData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		let refreshTimeout;
		if (!isAuthorized) return;

		const getRefreshedToken = () => {
			refreshTimeout && clearTimeout(refreshTimeout);
			refreshTimeout = setTimeout(() => {
				dispatch(refreshToken());
				getRefreshedToken();
			}, 60 * 1000);
		};
		getRefreshedToken();
		connect();
		dispatch(subscribe());
		dispatch(getOperations());
		return () => {
			unsubscribe();
			dispatch(clearUser());
			disconnect();
			SocketService.disconnect();
			refreshTimeout && clearTimeout(refreshTimeout);
		};
	}, [dispatch, isAuthorized, clientId, onBehalfOf]);

	useEffect(() => {
		if (!isAuthorized) return;
		connectTicket();
		dispatch(subscribeTicket());

		connectChatUnreadCount();
		dispatch(subscribeChatUnreadCount(true));
		return () => {
			unsubscribeTicket();
			disconnectTicket();
			unsubscribeChatUnreadCount();
			disconnectChatUnreadCount();
		};
	}, [dispatch, isAuthorized, clientId, onBehalfOf]);



	useEffect(() => {
		if (!isAuthorized) return;
		connectContacts();
		dispatch(subscribeContacts());
		return () => {
			unsubscribeContacts();
			disconnectContact();
		};
	}, [dispatch, isAuthorized, clientId, onBehalfOf]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars 
	const handleOnIdle = async (event) => {
		setOpenInactiveModal(true);
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars 
	const handleOnActive = event => {
		//console.log('user is active again', event);

	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars 
	const handleOnAction = event => {
		//console.log('user did something', event);
	};
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { getRemainingTime, getLastActiveTime } = useIdleTimer({
		timeout: 1000 * 60 * 15,
		onIdle: handleOnIdle,
		onActive: handleOnActive,
		onAction: handleOnAction,
		debounce: 500,
		capture: false
	});

	// useEffect(() => {
	// 	console.log('getRemainingTime', getRemainingTime);
	// 	console.log('getLastActiveTime', getLastActiveTime);
	// }, [getLastActiveTime, getRemainingTime]);

	const handleInactiveModalClose = async (co?: boolean) => {
		setOpenInactiveModal(false);

		if (co) {
			await dispatch(refreshToken());
		} else {
			dispatch(logout());
			return history.push('/auth?mode=INACTIVE');
		}
	};


	if (loading) {
		return <LoadingPageMui />;
	}
	return (
		<>
			<InactiveModal
				open={openInactiveModal}
				onClose={handleInactiveModalClose}
			/>
			<Route
				{...rest}
				render={props =>
					isAuthorized ? (
						<Component {...props} />
					) : (
							<Redirect
								to={{
									pathname: '/auth',
									// eslint-disable-next-line react/prop-types
									state: { from: props.location }
								}}
							/>
						)
				}
			/>
		</>

	);
};

export default ProtectedRoute;
