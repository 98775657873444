import React, { useEffect } from 'react';
import { Alert, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DirectorsArrayType, DirectorsPayloadType, DirectorType, SectionStatus, SectionType } from '@/features/business/types';
import BusinessDirectorItem from './BusinessDirectorItem';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { postDirectors } from '@/features/business/slice';
import { showException } from '@/features/swal/slice';
import { useFormikForms } from '../../formHooks';
import MuiTextAlert from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAlert';
import { formatDateWithPattern } from '@/helpers/date';

const initDirector: DirectorType = {
	directorFirstName: '',
	directorLastName: '',
	directorBirthDate: '',
	directorPep: true,
	directorUsCitizen: true
};


interface Props {
	clientId: string,
	section?: SectionType,
	onNext: () => void,
	onBack: () => void
}

const BusinessDirectors = ({ clientId, section, onNext, onBack }: Props): React.ReactElement => {

	const { t } = useTranslation('translations');

	const sectionValues = section?.values as DirectorsArrayType;
	const { forms, allowPostData, disableSubmit, handleNext, nextStep, save, setSave, addForm, updateForm, removeForm } =
		useFormikForms(sectionValues?.directors, initDirector);

	useEffect(() => {
		const postData = async () => {
			let error = false;
			if (allowPostData) {
				try {
					const payload: DirectorsPayloadType = {
						clientId: clientId,
						directors: forms.map(f => {
							return {
								directorFirstName: f.values.directorFirstName,
								directorLastName: f.values.directorLastName,
								directorBirthDate: f.values.directorBirthDate ? formatDateWithPattern(f.values.directorBirthDate, 'dd/MM/yyyy') : f.values.directorBirthDate,
								directorPep: f.values.directorPep,
								directorUsCitizen: f.values.directorUsCitizen,
							};
						})
					};

					await postDirectors(payload);
				}
				catch (e) {
					error = true;
					console.log('error', e);
					showException(e);
				}
			}
			if (nextStep && !error) { onNext && onNext(); }

		};
		postData();
		setSave(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allowPostData, clientId, save, nextStep]);

	const doNotAllowUpdate = section?.status === SectionStatus.APPROVED;

	return (
		<div id='business-directors' className='form-content_main-area-nopadding'>
			<Grid container rowGap={4}>
				{doNotAllowUpdate &&
					<Grid item xs={12} >
						<Alert severity="success">
							<MuiTextAlert> {t('createbusiness.information.approved')}</MuiTextAlert>
						</Alert>
					</Grid>
				}
				{section?.status === SectionStatus.REJECTED && section?.rejectionReason &&
					<Grid item xs={12} >
						<Alert severity="warning">
							<MuiTextAlert>{section?.rejectionReason}</MuiTextAlert>
						</Alert>
					</Grid>
				}
				<Grid item xs={12}>
					<MuiHeader className='business-header'> {t('createbusiness.directors.title')}</MuiHeader>
				</Grid>
				{forms?.length > 0 && forms?.map((p, idx) =>
					<Grid key={p.id} item xs={12}>
						<BusinessDirectorItem id={p.id}
							allowRemove={idx > 0 && !doNotAllowUpdate}
							director={p.values}
							updateForm={updateForm}
							removeForm={removeForm}
							doNotAllowUpdate={doNotAllowUpdate} />
					</Grid>)}
				<Grid item xs={12}>
					<Button
						variant='customOutlined'
						onClick={addForm}
						fullWidth
						disabled={doNotAllowUpdate}
					>  {t('createbusiness.directors.add')}
					</Button>
				</Grid>
			</Grid>
			<Grid container className='form-content_botton-area'>
				<Grid item xs={6}>
					<Button
						variant='customOutlined'
						onClick={onBack}
					>  {t('form.buttons.back')}
					</Button>
				</Grid>
				<Grid item xs={6} container justifyContent='flex-end' columnGap={3}>
					<Button disabled={disableSubmit}
						variant='contained'
						onClick={handleNext}
					>
						{t('form.buttons.continue')}
					</Button>
				</Grid>
			</Grid>
		</div>
	);
};

export default BusinessDirectors;
