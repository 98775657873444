import componentOverides from './default/componentOverides'; 
const brand = process.env.REACT_APP_WHITE_LABEL;

const paletteDark = require('./' + brand + '/palleteDark');
const paletteLight = require('./' + brand + '/palleteLight');
 
const darkTheme = componentOverides(paletteDark.default);
const lightTheme = componentOverides(paletteLight.default);

export { darkTheme, lightTheme };
