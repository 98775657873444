import * as React from 'react';

import '@/componentsMui/Shared/Shared.css';
import '@/componentsMui/Shared/SharedFormLayout.css';

import { Grid, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { Form, Formik, FormikProps, FormikValues, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import CountryList from '@/componentsMui/Dashboard/Create/Card/CountryList';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { getName, getNames } from 'i18n-iso-countries';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { Address } from '@/features/card/types';
import { useDeliveryAddress } from '@/helpers/customHook/useValidations';

interface Props {
    integration: string,
    deliveryAddress: Address,
    setDeliveryAddress: (value: Address) => void,
    validationMessage?: string,
    missingComponents?: Array<string>
}

const AutoSubmit = ({ setDeliveryAddress }) => {
    // Grab values and submitForm from context
    const { values, submitForm, errors } = useFormikContext();
     React.useEffect(() => {
        if (isEmpty(errors)) {
            submitForm();
        } else {
            submitForm();
            setDeliveryAddress(null);
        }
    }, [values, submitForm, errors, setDeliveryAddress]);
    return null;
};

const DeliveryAddressScreen = ({ integration, deliveryAddress, setDeliveryAddress, validationMessage, missingComponents }: Props) => {

    const { t } = useTranslation(['translations']);
    const { user } = useSelector((state: RootState) => state.user);

    const countries = getNames('en');
    const countryOptions = Object.keys(countries)?.map(p => countries[p]);
    const formRef = React.useRef<FormikProps<FormikValues>>(null);
    const validationSchema = useDeliveryAddress(countryOptions);

    const submit = (formData) => {
        const payload: Address = {
            country: formData.country,
            city: formData.city,
            address: formData.address,
            postalCode: formData.postalCode
        };
        setDeliveryAddress(payload);
    };


    const getDefaultFormValues = (payload: any) => ({
        address: payload?.address ?? '',
        postalCode: payload?.postalCode ?? '',
        city: payload?.city ?? '',
        country: payload?.country ?? null
    });

    const getUserDefaultFormValues = () => ({
        address: user?.address ?? '',
        postalCode: user?.postalCode ?? '',
        city: user?.city ?? '',
        country: getName(user.addressCountry, 'en'),
    });

    const initialValues = deliveryAddress ? getDefaultFormValues(deliveryAddress) : getUserDefaultFormValues();
    const resetAddress = (setFieldValue) => {
        //const initialValues = deliveryAddress ? getDefaultFormValues(deliveryAddress) : getUserDefaultFormValues();
        const initialValues = getUserDefaultFormValues();
        setFieldValue('address', initialValues.address ?? '');
        setFieldValue('postalCode', initialValues.postalCode ?? '');
        setFieldValue('city', initialValues.city ?? '');
        setFieldValue('country', initialValues.country ?? null);
    };

    React.useEffect(() => {
        if (formRef.current) {
            formRef.current.setStatus(validationMessage);
        }
    }, [validationMessage]);

    return (
        <React.Fragment>
            <Grid id='form-card-security' className='form-template portal-dialog-form-template' container>
                <Formik
                  //  enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={submit}
                    innerRef={formRef}
                >
                    {({ setFieldValue, status }) => (
                        <Form style={{ width: '100%' }}>
                            <Grid container columnSpacing={2} height='100%' alignContent='start' position='relative'>
                                <Grid item xs={12} >
                                    <MuiHeader>{t('cards.cardActivation.deliveryLabel')}</MuiHeader>
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        name='address'
                                        label={t('form.fields.justAddress')}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <MuiTextField
                                        name='city'
                                        label={t('form.fields.town')}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CountryList
                                        integration={integration}
                                        name='country' />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <MuiTextField
                                        name='postalCode'
                                        label={t('form.fields.postalcode')}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{ py: { xs: 1, md: 0 } }}>
                                    <Link onClick={() => resetAddress(setFieldValue)}> {t('cards.newCard.delivery.defaultBTN')}	</Link >
                                </Grid>
                            </Grid>
                            {status != '' && missingComponents.length == 0 ?
                                <MuiFormText>{validationMessage}</MuiFormText> : null}
                            <Grid>
                                {missingComponents.map((component) => {
                                    return <MuiFormText key={component}>{t('cards.missing') + ' ' + t(`cards.missingComponents.${component}`)}</MuiFormText>;
                                })}
                            </Grid>
                            <AutoSubmit setDeliveryAddress={setDeliveryAddress} />
                        </Form>
                    )
                    }
                </Formik >
            </Grid >
        </React.Fragment >
    );
};



export default DeliveryAddressScreen;

