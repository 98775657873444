import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import request from '../../services/request';
import { CheckDataSharingResponse, Neuralid, NeuralidStore, NeuralLoginTokenPayload, NeuralRequestDataResponse } from './type';


const initialState: NeuralidStore = {
	loading: true,
	neuralid: null,
	error: null
};

const slice = createSlice({
	name: 'userNeuralid',
	initialState,
	reducers: {
		setNeuralid(state, action: PayloadAction<Neuralid>) {
			state.neuralid = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
	}
});

export const { setNeuralid, setLoading, setError } = slice.actions;

export const getNeuralId = (): AppThunk => {
	return async (dispatch) => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/neuralid/');
			if (response?.data) {
				dispatch(setNeuralid(response.data));
			}
		} catch (e) {
			dispatch(setError('Failed to get neural id'));
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const getNeuralQRCode = async (): Promise<{ qrCodeImage: string }> => {
	const response = await request.post('/api/neuralid/qr/generate');
	return response?.data;
};

export const getNeuralLoginToken = async (payload: NeuralLoginTokenPayload): Promise<any> => {
	const response = await request.post('/api/neuralid/login', payload);
	return response.data;
};

export const requestDataSharing = async (): Promise<NeuralRequestDataResponse> => {
	const response = await request.post('/api/neuralid/request');
	return response.data;
};


export const checkDataSharing = async (internalId: string): Promise<CheckDataSharingResponse> => {
	const response = await request.post('/api/neuralid/request/check', { internalId: internalId });
	return response.data;
};

export const sycnNeuralid = async (internalId: string): Promise<any> => {
	const response = await request.post('/api/neuralid/sync', { internalId: internalId });
	return response.data;
};

export default slice.reducer;
