import * as qrcode from 'qrcode';

export const getQRData = async (data: string) => {

	let qrCode = null;


	qrCode = await qrcode.toDataURL(data, {
		width: 200,
		errorCorrectionLevel: 'L'
	});
	return qrCode;
};
