
import * as React from 'react';
import { useTheme } from '@mui/styles';


import { Box as MuiBox, BoxProps, Theme } from '@mui/material';
import { FC } from 'react';

/* Placed on on top of Base Block*/
const TopBox: FC<BoxProps> = (props: BoxProps) => {

	const theme = useTheme() as Theme;
	const { sx, ...other } = props;

	return (<MuiBox id='top-box'
		sx={{
			...sx,
			color: theme.palette.text.secondary,
			borderRadius: theme.mainBorderRadius,
			background: theme.backgroundBoxLevel1,
			border: theme.palette.mode === 'dark' ? 'unset' : theme.inputField.border,
		}}
		{...other}>{props.children}
	</MuiBox>);
};


export default TopBox;
