import * as React from 'react';

import { Button, Dialog, Grid, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCloseDialogGoCards } from '@/helpers/customHook/useCloseDialog';
import DialogContentNoScrollbar from '@/componentsMui/Shared/Widgets/Dialog/DialogContentNoScrollbar';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { isEmpty } from 'lodash';
import { runOperation } from '@/features/operations/slice';
import { showException, showSuccess } from '@/features/swal/slice';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import VisibilityIcon from '@mui/icons-material/Visibility'; 
import MuiFormLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiFormLabel';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import { getCardCvvDetailsIC } from '@/features/card/slice';

interface Props {
	cardId: string,
	isVirtual: boolean,
	operation: string,
}

const InactiveCvvForm = ({ cardId, isVirtual, operation }: Props) => {

	const { t } = useTranslation('translations');
	const closeModal = useCloseDialogGoCards();

	const defaultCvv = '••••';
	const [cvv, setCvv] = React.useState(defaultCvv);
	const [running, setRunning] = React.useState(false);

	const [code, setCode] = React.useState<string>('');
	const initialValues = { activationCode: '' };
	const validationSchema = Yup.object({
		activationCode: Yup.string().trim().min(3, t('form.validator.exactDigits', { number: 3 })).max(3, t('form.validator.exactDigits', { number: 3 }))
	});



	const executeCardCvv = async () => {
		try {
			setRunning(true);
			const result = await getCardCvvDetailsIC(cardId);
			setCvv(result.cvv);

		}
		catch (e) {
			await showException(e);
		}
		finally {
			setRunning(false);
		}
	};

	const activate = async (activationCode: string) => {
		try {
			const payload = { cardId, cvv: activationCode };
			await runOperation(operation, payload);
			await showSuccess({
				title: 'cards.cardActivation.activated', mui: true
			});
		} catch (e) {
			await showException(e, null, t('form.buttons.close'));
		}
		finally { setCvv(defaultCvv); closeModal(); }
	};


	const onSubmit = (formData) => {
		const { activationCode } = formData;
		activate(activationCode);
	};

	const handleChange = (e, setFieldValue) => {
		const newValue: string = e.target.value;
		const re = /^[0-9]+$/;
		if (newValue === '' || re.test(newValue)) {
			setCode(newValue);
			setFieldValue('activationCode', newValue);
		}
	};

	return (
		<Dialog
			id="inactive-card-form"
			open={true}
			onClose={closeModal}
			fullWidth
			maxWidth='sm'>
			<DialogContentNoScrollbar>
				<Formik
					enableReinitialize={true}
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
				>
					{({ errors, isSubmitting, dirty, setFieldValue, setFieldTouched }) => {
						return (
							<Form>
								<Grid
									container
									padding='2.5rem'
									spacing={3}
								>
									<Grid item xs={12} container justifyContent='space-between' alignItems='center' >
										<Grid item>
											<MuiHeader pb={2}> {t('cards.inactive.modal.header')}</MuiHeader>
										</Grid>
										<Grid item>
											<IconButton onClick={closeModal}  >
												<MuiCloseIcon />
											</IconButton>
										</Grid>
									</Grid>
									<Grid item xs={12} >
										<MuiFormText display='inline'>
											{t(isVirtual ?
												'cards.inactive.modal.activateVirtualCVV' :
												'cards.inactive.modal.activatePhysicalCVV')}
										</MuiFormText>
									</Grid>
									<Grid item xs={12}>
										{isVirtual &&
											<Grid item container xs={12} rowGap={1}>
												<Grid item xs={12}>
													<MuiFormLabel> {t('cards.cardInfo.getCvv')} </MuiFormLabel>
												</Grid>
												<Grid item xs={12} alignItems='center' container>
													<MuiTextCaption fontSize='0.875rem' fontWeight={400} lineHeight='1.375rem' >{cvv}</MuiTextCaption>
													<IconButton onClick={executeCardCvv} sx={{ padding: '0 0.5rem' }} disabled={running}>
														<VisibilityIcon fontSize='small' />
													</IconButton>
												</Grid>
											</Grid>
										}
									</Grid>
									<Grid item xs={12}>
										<MuiTextField
											name="activationCode"
											inputProps={{ autoComplete: 'off', maxLength: 6 }}
											label={t('cards.inactive.modal.activationCode')}
											fullWidth
											onChange={(e) => { handleChange(e, setFieldValue); setFieldTouched('activationCode'); }}
											value={code}
										/>
									</Grid>
									<Grid item xs={12}>
										<Button
											fullWidth
											key='activate'
											type='submit'
											disabled={isSubmitting || !isEmpty(errors) || !dirty}
											variant='contained'> 	{t('form.buttons.submit')}
										</Button>
									</Grid>
								</Grid>
							</Form>
						);
					}}
				</Formik >
			</DialogContentNoScrollbar>
		</Dialog >
	);
};


export default InactiveCvvForm; 
 
