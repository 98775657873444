import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';

const NoRowsOverlay = () => {

    const { t } = useTranslation('translations');

    return (
        <Stack height='100%' alignItems='center' justifyContent='center'>
            {t('transaction.table.noTransactions')}
        </Stack>);
};
 
export default NoRowsOverlay;
