import { ActionsStatus } from '@/componentsMui/Onboarding/Onboarding';
import { KycStatus, LoginTypeEnum, User, UserType } from '@/features/user/types';
import { getKycTier } from './kyc';
import usStates from '@shared/FormikComponents/usstates.json';

export const getPoaOnboardingStatus = (user) => {


	if (!user) return ActionsStatus.NEW;

	if (user.type === UserType.EMPLOYEE)
		return ActionsStatus.COMPLETED;

	if (user?.poa?.required) {
		if (user?.poa?.status === 'APPROVED')
			return ActionsStatus.COMPLETED;
		if (user?.poa?.status === 'PENDING')
			return ActionsStatus.COMPLETED;
		if (user?.poa?.status === 'REJECTED')
			return ActionsStatus.FAILED;

		return ActionsStatus.NEW;
	}
	else {

		if (user.addressCountry && user.address && user.city && user.postalCode) {
			return ActionsStatus.COMPLETED;
		}
		else { return ActionsStatus.NEW; }
	}
};

export const getPoaStatus = (user) => {

	if (!user) return ActionsStatus.NEW;
	if (user?.poa?.required) {
		if (user?.poa?.status === 'APPROVED')
			return ActionsStatus.COMPLETED;
		if (user?.poa?.status === 'PENDING')
			return ActionsStatus.INPROGRESS;
		if (user?.poa?.status === 'REJECTED')
			return ActionsStatus.FAILED;

		return ActionsStatus.NEW;
	}
	else {

		if (user.addressCountry && user.address && user.city && user.postalCode) {
			return ActionsStatus.COMPLETED;
		}
		else { return ActionsStatus.NEW; }
	}
};

export const getKycStatus = (user): ActionsStatus => {
	let status = ActionsStatus.NEW;
	if (!user) return status;
	const tier = getKycTier(user);
	if (tier?.status === KycStatus.REJECTED) { status = ActionsStatus.FAILED; }
	if (tier?.status === KycStatus.VERIFIED) { status = ActionsStatus.COMPLETED; }
	if (tier?.status === KycStatus.IN_PROGRESS) { status = ActionsStatus.INPROGRESS; }
	return status;
};


export const getIdentityStatus = (user, kycProvider) => {

	if (kycProvider === 'sumsub') {
		return (user?.preferredDocType || user?.type === UserType.EMPLOYEE) ? ActionsStatus.COMPLETED : ActionsStatus.NEW;
	}
	else {
		return (user?.lastName || user?.type === UserType.EMPLOYEE) ? ActionsStatus.COMPLETED : ActionsStatus.NEW;
	}
};

export const getKycOnboardingStatus = (user) => {
	if (!user) return ActionsStatus.NEW;
	if (user.type === UserType.EMPLOYEE)
		return ActionsStatus.COMPLETED;

	const tier = getKycTier(user);
	if (tier?.status === KycStatus.REJECTED)
		return ActionsStatus.FAILED;
	if (tier?.status === KycStatus.VERIFIED)
		return ActionsStatus.COMPLETED;
	if (tier?.status === KycStatus.IN_PROGRESS)
		return ActionsStatus.COMPLETED;
/* 	if (tier?.status === KycStatus.CREATED && kycService === 'sumsub')
		return ActionsStatus.COMPLETED;
 */	return ActionsStatus.NEW;
};

export const get2FAStatus = (user, status) => {

	if (!user || !status) return ActionsStatus.NEW;

	if (user.type === UserType.EMPLOYEE && !status.twoFARequired)
		return ActionsStatus.COMPLETED;

	if (user?.twoFactorAuthEnabled || user?.mobilePinEnabled)
		return ActionsStatus.COMPLETED;

	if (status.twoFARequired && !user?.twoFactorAuthEnabled)
		return ActionsStatus.NEW;

	return ActionsStatus.NEW;

};

export const getChooseToVerify = (kycStatus: ActionsStatus, poaStatus: ActionsStatus, userType: string) => {

	if (userType === UserType.EMPLOYEE)
		return ActionsStatus.COMPLETED;


	if (kycStatus !== ActionsStatus.COMPLETED || poaStatus !== ActionsStatus.COMPLETED)
		return ActionsStatus.NEW;
	else return ActionsStatus.COMPLETED;



};

export const isKycAndPoaCompleted = (user) => {
	if (!user) return ActionsStatus.NEW;
	return getKycStatus(user) === ActionsStatus.COMPLETED && getPoaStatus(user) === ActionsStatus.COMPLETED;
};

export const userState = (stateCode) => {
	return usStates?.find(p => p.abbreviation === stateCode);
};


export const isAccountConfirmed = (user, accountType: LoginTypeEnum) => {

	if (!user) return ActionsStatus.NEW;

	if (accountType === LoginTypeEnum.EMAIL) {
		return user.emailConfirmed ? ActionsStatus.COMPLETED : ActionsStatus.NEW;
	}
	else { return user.confirmedMobileNumber ? ActionsStatus.COMPLETED : ActionsStatus.NEW; }
};

export const isPhoneCompleted = (user: User) => {

	if (!user) return ActionsStatus.NEW;

	if (user?.mobileNumber) {
		return user.confirmedMobileNumber ? ActionsStatus.COMPLETED : ActionsStatus.NEW;
	}
	else {
		return ActionsStatus.COMPLETED;
	}
};
