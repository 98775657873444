import * as React from 'react';
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { showException, showSuccess } from '@/features/swal/slice';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { Address } from '@/features/card/types';
import { CardType } from '@/features/card/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import ConfirmationScreen from '../Components/ConfirmationScreen';
import DiplayBigNumber from '@/componentsMui/Shared/Components/DiplayBigNumber';
import { getCurrencySign, sumFees } from '@/features/fees/slice';
import { getAlpha2Code, getAlpha3Code } from 'i18n-iso-countries';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import { useState } from 'react';
import { FeeType, Process } from '@/features/fees/types';
import PaymentAccount from '../Components/PaymentAccount';
import { BankAccount } from '@/features/account/types';
import _ from 'lodash';
import StyledSelect from '@/componentsMui/Shared/Widgets/StyledSelect';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import { getCards, getShipmentOptions } from '@/features/card/slice';
import { runOperation } from '@/features/operations/slice';

export interface Props {
	activeAccount: BankAccount,
	accountList: BankAccount[],
	cardType: CardType,
	deliveryAddress: Address,
	option: Process,
	montlyFee: number,
	creationFee: number,
	replacedCardId?: number,
	onAccountChange: (newAccount: BankAccount) => void,
	prevStep: () => void
}

const CardConfirmation = ({ activeAccount, accountList, onAccountChange, cardType, deliveryAddress, montlyFee, creationFee, replacedCardId, option, prevStep }: Props) => {
	const { t } = useTranslation('translations');
	const closeModal = useCloseDialogNoEvent();
	const { user } = useSelector((state: RootState) => state.user);
	const [deliveryOptions, setDeliveryOptions] = useState([]);
	const [selectedDelivery, setSelectedDelivery] = useState<any>();
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const dispatch = useDispatch();
	const shipmentByCountry = option?.config?.validateAddress === true;
	const countryIso2 = deliveryAddress?.country ? getAlpha2Code(deliveryAddress.country, 'en') : undefined;


	React.useEffect(() => {

		const get = async () => {
			try {
				setLoading(true);
				if (countryIso2) {
					const result = await getShipmentOptions(option.integration, countryIso2, `${deliveryAddress?.fullAddress} ${deliveryAddress?.address} ${deliveryAddress?.address2}  ${deliveryAddress?.city}  ${deliveryAddress?.country} ${deliveryAddress?.postalCode}`);
					const options = result.map(p => { return { ...p, fee: sumFees(p.fees, 0, FeeType.FIXED) }; }) || [];
					setDeliveryOptions(options);
					options?.length > 0 && setSelectedDelivery(options[0]);

				}
				setLoading(false);
			}
			catch (e) {
				setLoading(false);
				console.log(e);
			}
		};
		if (shipmentByCountry) {
			get();

		}
		else {

			const options = _.values(option?.subProcesses).map(p => { return { ...p, fee: sumFees(p.fees, 0, FeeType.FIXED) }; }) || [];
			setDeliveryOptions(options);
			options?.length > 0 && setSelectedDelivery(options[0]);

		}
	}, [countryIso2, deliveryAddress?.address, deliveryAddress?.address2, deliveryAddress?.city, deliveryAddress?.country, deliveryAddress?.fullAddress, deliveryAddress?.postalCode, option.integration, option?.subProcesses, shipmentByCountry]);

	const deliveryFee = parseFloat(selectedDelivery?.fee ?? 0);
	const totalPrice = montlyFee + creationFee + deliveryFee;

	const insuficientFunds = activeAccount?.availableBalance < totalPrice;

	const cardCurrency = option.processProperties.ccy;
	const cardCurrencySymbol = getCurrencySign(cardCurrency);

	const accountIssue = insuficientFunds || !activeAccount;
	const deliveryIssue = !selectedDelivery?.proc;

	const addressIssue =
		!deliveryAddress?.address ||
		!deliveryAddress?.city ||
		!deliveryAddress?.country ||
		!deliveryAddress?.postalCode;

	const adressError = cardType === CardType.PHYSICAL && addressIssue ? t('cards.invalidAddress') : undefined;


	const disabled = cardType === CardType.VIRTUAL ?
		accountIssue :
		accountIssue || addressIssue || deliveryIssue;


	const handleDeliveryChange = (event: any) => {
		setSelectedDelivery(deliveryOptions.find(p => p.proc === event.target.value));
	};

	const send = async () => {
		setSubmitting(true);
		const payload: any = {
			feeAccountId: activeAccount.accountId,
			smsPhoneNumber: user?.mobileNumber,
			...(replacedCardId && { replacedCardId: replacedCardId })
		};

		const add = `${deliveryAddress?.address ?? ''}`;
		if (cardType === CardType.PHYSICAL) {
			payload.deliveryAddress = { city: deliveryAddress.city, country: getAlpha3Code(deliveryAddress.country, 'en'), address: add.trim(), zip: deliveryAddress.postalCode };
			payload.shipProc = selectedDelivery.proc;
		}

		if (option.integration === 'SH_FINANCE') {
			payload.desingCode = 'add';
		}

		try {
			await runOperation(option.proc, payload);
			await dispatch(getCards());
			showSuccess({
				title: 'cards.newCard.successHeader', text: t('cards.newCard.successBody'), mui: true
			});
			setSubmitting(false);
			closeModal();
		} catch (e) {
			setSubmitting(false);
			await showException(e);
			closeModal();
		}
	};

	const handleConfirm = () => {
		send();
	};


	return (
		<Grid container alignContent='space-between' flex={1}>
			<Grid item container spacing={1}>
				<Grid item xs={12}>
					<FormHeader title={option?.name} showBack={!!prevStep} onBack={prevStep} />
				</Grid>
				<Grid item xs={12}>
					<DiplayBigNumber
						prefix={<Typography variant='highlight' fontSize='1.5rem'> {cardCurrencySymbol} </Typography>}
						amount={creationFee}
						alwaysGradient={true}
					/>
				</Grid>
				<Grid item xs={12} container>
					<Grid item
						xs={12} md={cardType === CardType.PHYSICAL ? 5 : 12}
						paddingRight={1} >
						<PaymentAccount
							accountList={accountList}
							insuficientFunds={insuficientFunds}
							activeAccount={activeAccount}
							onAccountChange={onAccountChange} />
					</Grid>
					{cardType === CardType.PHYSICAL &&
						<Grid item xs={12} md={7}  >
							<FormControl fullWidth >
								<InputLabel>{t('cards.newCard.delivery.delivery')}</InputLabel>
								<StyledSelect
									id="delivery"
									name="delivery"
									value={selectedDelivery?.proc ?? ''}
									onChange={handleDeliveryChange}
									renderValue={() => <div> {`${selectedDelivery?.name} (${cardCurrencySymbol}${selectedDelivery.fee})`}</div>}
								>
									{deliveryOptions.map((p, idx) => {
										return (
											<MenuItem
												key={idx}
												value={p.proc}>
												<div>
													<div className='select-primary-Item'>{p.name} </div>
													<div className='select-hide-Item'>{cardCurrencySymbol} {p.fee} </div>
												</div>
											</MenuItem>);
									})}
								</StyledSelect>
							</FormControl>
							{(loading === false && (deliveryOptions.length === 0 || !deliveryOptions)) && <FormHelperText>{t('cards.noShipment')}</FormHelperText >}
						</Grid>}
				</Grid>
				<Grid item xs={12}>
					<StyledDivider />
				</Grid>
				<Grid item xs={12}>
					<ConfirmationScreen option={option} deliveryAddress={deliveryAddress} totalPrice={totalPrice} cardType={cardType} error={adressError} />
				</Grid>
			</Grid>

			<Grid item container xs={12}>
				<Grid item xs={6}>
					<Button
						variant='customOutlined'
						onClick={closeModal}
					>   	{t('form.buttons.close')}
					</Button>
				</Grid>
				<Grid item xs={6} display='flex' justifyContent='flex-end'>
					<Button
						disabled={submitting || disabled}
						key="buttonNext"
						variant='contained'
						onClick={handleConfirm}
						autoFocus> 	{t('cards.newCard.createBTN')}
					</Button>
				</Grid>
			</Grid>

		</Grid>

	);
};

export default CardConfirmation;
