
import { getLoginToken, getQRCode } from '@/features/2fa/slice';
import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QrcodeDecoder from 'qrcode-decoder';
import { LoginTokenPayload, QrDataPayload, TokenErrorType } from '@/features/2fa/types';
import { getErrorWithParams } from '@/features/swal/slice';
import { Credentials, DeviceInfo, TokenGrantTypeEnum } from '@/features/user/types';
import { setCreditials } from '@/features/user/credentialsSice';
import { useDispatch } from 'react-redux';
import { logout } from '@/features/user/userSlice';
import { useHistory } from 'react-router-dom';
import './Login.css';
import QrMediaCard from '@/componentsMui/Shared/Widgets/QrMediaCard';

interface Props {
	deviceInfo: DeviceInfo,
	isOauthLogin?: boolean,
}

const CALL_LIMIT = 20;

const LoginQR = ({ deviceInfo, isOauthLogin = false }: Props) => {
	const { t } = useTranslation(['translations']);
	const dispatch = useDispatch();
	const history = useHistory();
	const appName = process.env.REACT_APP_TITLE;
	const [qrImage, setQRImage] = useState<string>('');
	const [qrData, setQrdata] = useState<QrDataPayload>();
	const [tokenError, setTokenError] = useState<TokenErrorType>(null);
	const [token, setToken] = useState<string>('');
	const code = qrData?.code;
	const [callCount, setCallCount] = React.useState(0);


	useEffect(() => {
		let isMounted = true;
		const endDate = qrData?.expired && new Date(qrData.expired);

		const getToken = async () => {
			if (code) {
				try {
					const payload: LoginTokenPayload = {
						code: code,
						grantType: isOauthLogin ? TokenGrantTypeEnum.OAUTH : undefined,
						deviceInfo: {
							browser: deviceInfo.browser,
							platform: deviceInfo.platform,
							hash: deviceInfo.hash
						}
					};
					const result = await getLoginToken(payload);
					if (isMounted) {
						setTokenError(null);
						if (result?.token) {
							await dispatch(logout());
							if (isMounted) {
								setToken(result?.token);
								const credentialsPayload: Credentials = { token: result?.token, tokenGrantType: isOauthLogin ? TokenGrantTypeEnum.OAUTH : TokenGrantTypeEnum.PORTAL };
								await dispatch(setCreditials(credentialsPayload));
								if (isMounted && !isOauthLogin) history.push('/wallet/home');
							}
						}
					}
				}
				catch (e) {
					const authError = getErrorWithParams(e);
					isMounted && setTokenError(authError?.error);
				}
			}
			isMounted && setCallCount(callCount => callCount + 1);
		};
		const interval = setTimeout(() => {
			if (endDate > new Date() && isMounted) {
				getToken();
			}
			else {
				if (isMounted) setTokenError(TokenErrorType.EXPIRED);
			}
		}, 2000);

		if (callCount >= CALL_LIMIT) {
			clearTimeout(interval);
			setQRImage('');
			setQrdata(null);
		}
		return () => { clearTimeout(interval); isMounted = false; };
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [qrData?.expired, callCount]);

	useEffect(() => {
		let isMounted = true;
		const fetchQrCode = async () => {
			try {
				const result = await getQRCode();
				if (result?.qrCodeImage && isMounted) {

					setQRImage(result.qrCodeImage);
					const qr = new QrcodeDecoder();
					const data = await qr.decodeFromImage(result.qrCodeImage);
					isMounted && setQrdata(JSON.parse(data.data) as QrDataPayload);
				}
			}
			catch (e) {
				isMounted && setQRImage('');
				isMounted && setQrdata(null);
			}
		};

		if (tokenError !== TokenErrorType.NOT_APPROVED && !(token)) {
			fetchQrCode();
		}
		return () => { isMounted = false; };
	}, [token, tokenError]);


	return (
		<Grid container>
			<Grid item xs={12}>
				<Grid item container textAlign='center'>
					<Grid item xs={12} >
						<QrMediaCard qrImage={qrImage} onButtonClick={() => setCallCount(0)} />
					</Grid>
					<Grid item xs={12} mt='30px'>
						<Typography
							fontSize='1rem'
							fontWeight={700}
							whiteSpace='pre-line'
							color='text.primary'>{t('login.qrLogin')}
						</Typography>
					</Grid>
					<Grid item xs={12} mt='10px'>
						<Typography
							maxWidth='12.5rem'
							margin='auto'
							fontSize='0.813rem'
							whiteSpace='pre-line'
							color='text.secondary'>{t('login.loginInstruction', { appName: appName })}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid >
	);
};


export default LoginQR;
