import * as React from 'react';

import '@shared/Shared.css';
import '@shared/SharedFormLayout.css';

import {
	Box as MuiBox,
	Button, Grid,
	Theme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect} from 'react';

import { BankAccount } from '@features/account/types';
import { showInfoNotification } from '@features/swal/slice';
import { Contact } from '@features/contacts/types';
import { SystemType } from '@helpers/globalTypes';
import useFileUpload from '@helpers/customHook/useFileUpload';
import MuiFormText from '@shared/Widgets/MuiText/MuiFormText';
import { PaymentPayloadType } from '@/componentsMui/Type/PaymentPayloadType';
import useCloseDialog from '@helpers/customHook/useCloseDialog';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import { useTheme } from '@mui/styles';
import UploadBox from '@shared/Components/SendMoney/PaymentForm/UploadBox';


type Props = {
	paymentType: SystemType,
	accountList: BankAccount[], 
	defaultAccount?: BankAccount,
	defaultContact?: Contact,
	paymentPayload: PaymentPayloadType,
	paymentName: string,
	setPaymentPayload: (value: PaymentPayloadType) => void,
	nextStep?: () => void,
	prevStep?: () => void,
	prevStepMain?: () => void
	nextStepMain?: () => void
}

const AttachmentAmount = ({
	paymentName, 
	paymentPayload,
	setPaymentPayload, 
	prevStepMain,
	nextStepMain,
}: Props) => {
	const { t } = useTranslation('translations');
	const theme = useTheme() as Theme;

	const fileUpload = useFileUpload(1, paymentPayload.mandatoryAttachementAmount);

	const closeModal = useCloseDialog();

	useEffect(() => {
		if (paymentPayload.selectedFile && fileUpload.selectedFiles.length === 0) {
			fileUpload.setSelectedFiles([paymentPayload.selectedFile]);
		}
	}, [paymentPayload.selectedFile, fileUpload]);



	const submit = async () => {
		try {
			if (!fileUpload.isValidFiles(paymentPayload.amount)) {
				showInfoNotification('sendmoneyTranslation.data.documentRequired');
				return;
			}
			const payload: PaymentPayloadType = {
				...paymentPayload,
				...{
					selectedFile: fileUpload.selectedFiles[0]
				},
			};

			setPaymentPayload(payload);
			nextStepMain();
		}
		catch (e) {
			console.log(e);
		}
	};


	return (
		<>
			<Grid container rowSpacing={2}>
				<Grid item xs={12}>
					<FormHeader title={paymentName} onBack={prevStepMain} showBack={!!prevStepMain} />
				</Grid>
				<Grid item xs={12}>
					<MuiBox
						sx={{
							borderRadius: theme.inputField.borderRadius,
							background: theme.palette.background.paper,
							padding: '16px',
							margin: '5px 0px'
						}}
					>
						<MuiFormText>{t('sendmoney.attachment.info')}</MuiFormText>
					</MuiBox>
				</Grid>
				<Grid item xs={12}>
					<MuiFormText>{t('sendmoney.attachment.uploadSteps')}</MuiFormText>
				</Grid>
				<Grid item xs={12}>
					<UploadBox
						selectedFiles={fileUpload.selectedFiles}
						uploadFiles={fileUpload.uploadFiles}
						deleteAttachment={fileUpload.deleteAttachment} />
				</Grid>
			</Grid>
			<Grid container mt='auto' pt='2rem' columnSpacing={2}>
				<Grid item xs={6}>
					<Button
						variant='customOutlined'
						color='secondary'
						onClick={closeModal}
						sx={{ width: { xs: '100%', md: 'auto' } }}
					>  {t('form.buttons.close')}
					</Button>
				</Grid>
				<Grid item xs={6} container justifyContent='flex-end'>
					<Button onClick={() => submit()}
							variant='contained'
							color='primary'
							type="submit"
							sx={{ width: { xs: '100%', md: 'auto' } }}
					>
						{t('form.buttons.next')}
					</Button>
				</Grid>
			</Grid>
		</>
	);
};

export default AttachmentAmount;
