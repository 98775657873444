import { ThemeOptions } from '@mui/material/styles/createTheme';

const defaultLightTheme: ThemeOptions = {
	palette: {
		mode: 'light',
		primary: {
			main: '#21252A',
			contrastText: '#FFFFFF',
		},
		background: {
			default: '#F6F7FB',
			paper: '#FFFFFF'

		},
		error: { main: '#21252A' },
		info: { main: 'rgba(255, 255, 255, 0.04)' },
		//typography takes these colors
		text: {
			primary: '#21252A',
			secondary: '#AFAFAF',
			disabled: '#21252A66'
		},
		action: {
			disabled: '#AFAFAF',
			disabledBackground: 'linear-gradient(0deg, #2A2E33, #2A2E33), rgba(255, 255, 255, 0.04)',
		}
	},
	auth: {
		background: '#F6F7FB',
		welcomeCardBg: 'linear-gradient(112.26deg, #FF2091 10.81%, #0184FD 160.09%), rgba(255, 255, 255, 0.04)',
	},
	statusColor: {
		error: '#DC0452',
		lightRed: '#F0626D', 
		warning: '#FFD600',
		success: '#41DC1A',
		completed: 'linear-gradient(112.26deg, #01DDB9 10.81%, #0184FD 160.09%)',
	},
	selectedItemBg: {
		default: 'linear-gradient(112.26deg, #FF2091 10.81%, #0184FD 160.09%)'
	},
	warningBg: 'linear-gradient(112.26deg, rgba(255, 32, 145, 0.1) 10.81%, rgba(1, 132, 253, 0.1) 160.09%)',
	successBg: 'linear-gradient(to right, #17BB24 0%, #BCEBC0 100%)',
	iconGradient: {
		stopColor1: '#FF2091',
		stopColor2: '#0184FD'
	},
	scrollBarColor: '#959799',
	bgSecondaryButton: '#E2E2E2',
	warnButton: 'rgba(250, 28, 108, 0.1)',
	backgroundBoxLevel1: 'transparent',
	backgroundBoxLevel2: 'transparent',
	profileAvatar: {
		bg: 'rgba(33, 37, 42, 0.04)',
		color: '#959799',
	},
	form: {
		bgDefault: '#FFFFFFE5',
		bgLight: '#FFFFFFE5',
		textPrimary: '#FFFFFF',
		textSecondary: '#959799',
		formWithMenu: {
			bgMenu: 'rgba(255, 255, 255, 0.24)',
			bgContent: 'rgba(255, 255, 255, 0.12)'
		}
	},
	bgIconWrap: 'rgba(255, 255, 255, 0.08)',
	highlight: '#EE5EA7',
	mainBackdropFilter: 'blur(90px)',
	shadowMain: '0px 16px 20px rgba(0, 0, 0, 0.08)',
	mainBorderRadius: '10px',
	inputField: {
		border: '0.5px solid #E2E2E2',
		borderDisabled: '0.5px solid rgba(226, 226, 226, 0.4)',
		bgDisabled: '#FFFFFF14',
		borderRadius: '10px',
	},
	carousel: {
		productLeftScrollShadow: 'linear-gradient(90deg, #F6F7FB 22.5%, rgba(246, 247, 251, 0) 94.5%)',
		productRightScrollShadow: 'linear-gradient(270deg, #F6F7FB 22.5%, rgba(246, 247, 251, 0) 94.5%)',
		accountLeftScrollShadow: 'linear-gradient(90deg, #ADABAD 22.5%, rgba(182, 182, 184, 0) 94.5%)',
		accountRightScrollShadow: 'linear-gradient(270deg, #ADABAD 22.5%, rgba(182, 182, 183, 0) 94.5%)',
		iconShadow: '0px 1px 15px 2px rgba(217, 14, 232, 0.41)'
	},
	covers: {
		productCover: {
			baseBg: '#FFFFFF',
			bg: [{
				firstBg: 'linear-gradient(329.09deg, #6943FF -3.41%, #5447D6 91.05%)',
				secondBg: 'linear-gradient(329.09deg, #FF43AD -3.41%, #D64769 91.05%)',
			},
			//wallet
			{
				firstBg: 'linear-gradient(329.09deg, #0500F4 91.04%, #FAFF00 91.05%)',
				secondBg: 'linear-gradient(329.09deg, #0500F4 91.04%, #FAFF00 91.05%)',
			}]
		},
		paymentMethodCover: {
			baseBg: 'rgba(255, 255, 255, 0.12)',
			bg: [{
				firstBg: 'linear-gradient(329.09deg, #6943FF -3.41%, #5447D6 91.05%)',
				secondBg: 'linear-gradient(329.09deg, #6943FF -3.41%, #5447D6 91.05%)',

			},
			{
				firstBg: 'linear-gradient(329.09deg, #FF43AD -3.41%, #D64769 91.05%)',
				secondBg: 'linear-gradient(329.09deg, #FF43AD -3.41%, #D64769 91.05%)',
			}],
		},
		newAccountCover: {
			baseBg: 'rgba(255, 255, 255, 0.1)',
			bg: [{
				firstBg: '#3588EA',
				secondBg: '#3588EA',

			},
			{
				firstBg: '#000000',
				secondBg: '#000000',
			},
			{
				firstBg: '#33CCCC',
				secondBg: '#33CCCC',
			},
			{
				firstBg: '#6B3051',
				secondBg: '#6B3051',
			},
			{
				firstBg: '#F0B323',
				secondBg: '#F0B323',
			},
			{
				firstBg: '#BCD4E6',
				secondBg: '#BCD4E6',
			},
			]
		},
		newCardCover: {
			baseBg: 'rgba(255, 255, 255, 0.1)',
			bg: [
				{
					firstBg: '#000000',
					secondBg: '#000000',
				},
				{
					firstBg: '#3588EA',
					secondBg: '#3588EA',
				},
				{
					firstBg: '#FFFFFF',
					secondBg: '#FFFFFF',
				},
				{
					firstBg: '#33CCCC',
					secondBg: '#33CCCC',
				},
				{
					firstBg: '#6B3051',
					secondBg: '#6B3051',
				},
				{
					firstBg: '#F0B323',
					secondBg: '#F0B323',
				},
				{
					firstBg: '#BCD4E6',
					secondBg: '#BCD4E6',
				},
			]
		}
	},
	typography: {
		fontFamily: [
			'CoFoSans',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
	}
};

export default defaultLightTheme;
