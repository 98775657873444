import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useField } from 'formik';

type Props = { hideError?: boolean, name: string } & TextFieldProps;

const MuiTextField = ({
	name,
	hideError,
	...otherProps
}: Props) => {
	const [field, meta] = useField(name);

	const configTextfield = {
		...field,
		...otherProps,
	};



	if (meta && meta.touched && meta.error) {
		configTextfield.error = true;
		configTextfield.helperText = meta.error;
	}

	if (hideError) {
		configTextfield.error = false;
		configTextfield.helperText = null;
	}


	return (
		<TextField {...configTextfield} />
	);
};

export default MuiTextField;
