import * as React from 'react';
import { Button, Grid, IconButton } from '@mui/material';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import { useTranslation } from 'react-i18next';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import CardDetailsLine from './CardDetailsLine';
import { Card } from '@/features/card/types';
import { format, isValid, parse } from 'date-fns';
import BaseBox from '@/componentsMui/Shared/Widgets/Box/BaseBox';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';
import useCardCvvPan from '@/helpers/customHook/useCardCvvPan';
import useCardPin from '@/helpers/customHook/useCardPin';
import { useEffect } from 'react';
import DialogWithBg from '@/componentsMui/Shared/Widgets/Dialog/DialogWithBg';
import FormPaperCardDetailsFixed from '@/componentsMui/Shared/Widgets/Dialog/FormPaperCardDetailsFixed';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { requestVfCode } from '@/features/card/slice';
import { getErrorWithParams, showException } from '@/features/swal/slice';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { isEmpty } from 'lodash';
import MuiTextLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextLabel';
interface Props {
	open: boolean,
	card: Card,
	onClose: () => void,
	iframeLink?: string
}

const CardDetailsForm = ({ card, onClose, open, iframeLink }: Props) => {
	const { t } = useTranslation('translations');

	const { fetchCardCvvPan, cvv, cardNumber, showGetCVV, showGetPan, defaultCvvCode, cardInfoIframe, defaultCardNumber, loading: loadingCvv, isSH_Finance } = useCardCvvPan(card);
	const { fetchCardPin, pin, showGetPin, defaultPinCode, loading: loadingPin, isPinShown } = useCardPin(card);
	const loading = loadingCvv || loadingPin;
	const vfCodeRequired = isSH_Finance && showGetPin && !isPinShown;

	const handleClose = () => { onClose && onClose(); };
	const handlePanCVV = async () => { await fetchCardCvvPan(); };
	const handlePin = async () => { if (!isSH_Finance) { await fetchCardPin(); } };


	const getExpirationDate = () => {
		const date = parse(card.expirationDate, 'yyyy-MM-dd', new Date());
		if (isValid(date)) {
			return format(date, 'MM/yy');
		} else {
			return '••/••';
		}
	};

	useEffect(() => {
		if (open && isSH_Finance && showGetCVV)
			fetchCardCvvPan();
	}, [fetchCardCvvPan, isSH_Finance, open, pin, showGetCVV]);



	const renderContent = () => {

		if (vfCodeRequired) return <VfCode cardId={`${card?.cardId}`} onContinue={fetchCardPin} />;

		if (cardInfoIframe != null || iframeLink != null) {
			if (isSH_Finance) {
				return <>
					<iframe src={cardInfoIframe ?? iframeLink} width='100%' height='350px' style={
						{
							'border': 'none',
							'overflow': 'hidden',
							'display': 'block',
						}
					}></iframe>
				</>;
			}
			else {
				return <img src={cardInfoIframe ?? iframeLink} width='100%' height='auto' style={{ objectFit: 'contain' }} />;
			}
		}

		if (cardInfoIframe == null && iframeLink == null && open) {
			return (
				<BaseBox width='100%' >
					<Grid container borderRadius='10px' width='100%' p='1.25rem 1.875rem' rowGap='1.5rem'
						position='relative' >
						<CardDetailsLine
							letterSpacing={true}
							label={t('cards.cardDetailsInfo.cardNumber')}
							value={cardNumber}
							onClick={handlePanCVV}
							showButton={showGetPan && cardNumber === defaultCardNumber}
							disableButton={loading}
						/>
						<CardDetailsLine label={t('cards.cardDetailsInfo.cardholder')} value={card?.cardholder} />
						<CardDetailsLine label={t('cards.cardDetailsInfo.expiryDate')} value={getExpirationDate()} />
						<CardDetailsLine letterSpacing={true} label={t('cards.cardDetailsInfo.cvv')} value={cvv}
							showButton={showGetCVV && cvv === defaultCvvCode} onClick={handlePanCVV} disableButton={loading} />
						{(showGetPin) &&
							<CardDetailsLine
								letterSpacing={true}
								label={t('cards.cardDetailsInfo.pin')}
								value={pin}
								showButton={pin === defaultPinCode && !isSH_Finance}
								onClick={handlePin}
								disableButton={loading} />}

					</Grid>
				</BaseBox>);
		}
	};

	return (
		<DialogWithBg
			open={open}
			onClose={handleClose}
			PaperComponent={FormPaperCardDetailsFixed}
		>
			{
				loading && <Grid container
					justifyContent='center' alignItems='center'
					flexDirection='column'
					width='100%'
					height='100%'
					position='absolute'
					top={0} left={0}>
					<Spinner />
				</Grid>
			}
			<Grid container justifyContent="space-between" alignItems="center" >
				<Grid item >
					<MuiHeader>{t('cards.cardDetailsHeader')}</MuiHeader>
				</Grid>
				<Grid item >
					<IconButton onClick={handleClose}  >
						<MuiCloseIcon />
					</IconButton>
				</Grid>
			</Grid>
			<Grid container flex={1}>
				{renderContent()}
			</Grid>
		</DialogWithBg >
	);
};



const VfCode = ({ cardId, onContinue }: { cardId: string, onContinue: (vfCode: string, onError: (e: any) => void) => void }) => {
	const { t } = useTranslation('translations');
	const initialValues = { activationCode: '' };
	const [sendCode, setSendCode] = React.useState<boolean>(true);
	const WAIT_TIME = 10;
	const [countDown, setCountDown] = React.useState(WAIT_TIME);
	const countDownRef = React.useRef(countDown);

	const validationSchema =
		Yup.object({
			activationCode: Yup.string().required(t('form.validator.required')),
		});


	useEffect(() => {
		const send = async () => {
			try {
				await requestVfCode(cardId);
			}
			catch (e) {
				await showException(e);
			}
		};
		send();
	}, [cardId]);


	useEffect(() => {

		const timerId = setInterval(() => {
			if (!sendCode) { return; }
			countDownRef.current -= 1;
			if (countDownRef.current <= 0) {
				clearInterval(timerId);
				setSendCode(false);

			}
			else {
				setCountDown(countDownRef.current);
			}
		}, 1000);
		return () => { clearInterval(timerId); };
	}, [sendCode]);


	const executeCardVfCode = async () => {
		try {
			await requestVfCode(cardId);
			countDownRef.current = WAIT_TIME;
			setCountDown(WAIT_TIME);
			setSendCode(true);
		}
		catch (e) {
			await showException(e);
		}
	};


	const onSubmit = async (formData, formikProps) => {
		const { activationCode } = formData;
		const { setFieldError } = formikProps;

		const handleCodeError = (e) => {
			const err = getErrorWithParams(e);
			if (err && err.error === 'invalid' && err.error_param === 'verificationCode') {
				setFieldError('activationCode', t('errors.VERIFICATION_CODE'));
				return;
			}
			showException(e);
		};
		await onContinue(activationCode, handleCodeError);
	};

	return (
		<Formik
			enableReinitialize={true}
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
		>
			{({ errors, isSubmitting, dirty }) => {
				return (
					<Form style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
						<Grid container rowGap={3}  >
							<Grid item xs={12} >
								<MuiFormText display='inline'>
									{t('cards.inactive.modal.activationCodeDesc')}
								</MuiFormText>
							</Grid>
							<Grid item xs={12}>
								<MuiTextField
									name="activationCode"
									inputProps={{ autoComplete: 'off', maxLength: 6 }}
									label={t('cards.inactive.modal.verificationCode')}
									fullWidth
								/>
							</Grid>

							<Grid item xs={12} container>
								<Grid item xs={12}>
									<MuiFormText >{t('cards.inactive.modal.activateVfCodeInfo')}</MuiFormText>
								</Grid>
								<Grid item xs={12} sx={{ marginTop: 2 }} container columnGap={3} alignItems='center'>
									<Button
										onClick={executeCardVfCode}
										variant='contained'
										disabled={sendCode}>{t('cards.cardInfo.getVfCode')}
									</Button>
									{sendCode && <MuiTextLabel>{t('cards.resendVfCode', { sec: countDown })}</MuiTextLabel>}
								</Grid>

							</Grid>

						</Grid>
						<Grid container flex={1} />
						<Grid container>
							<Button
								fullWidth
								key='activate'
								type='submit'
								disabled={isSubmitting || !isEmpty(errors) || !dirty}
								variant='contained'
							>
								{t('form.buttons.next')}
							</Button>
						</Grid>
					</Form>
				);
			}}
		</Formik >
	);
};

export default CardDetailsForm; 
