import React, { useEffect } from 'react';
import { Dialog, Grid, IconButton, Paper } from '@mui/material';
import { useDispatch } from 'react-redux';
import { closeForm, openForm } from '@/features/forms/slice'; 
import './GoogleAuthForm.css';
import { withStyles } from '@mui/styles';
import { ReactComponent as MuiCloseIcon } from '@assets/MuiCloseIcon.svg';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { useTranslation } from 'react-i18next';
import GoogleAuthForms from './GoogleAuthForms';


interface Props {
	open: boolean,
	onClose: () => void
}

const FormPaper = withStyles(() => ({
	root: {
		maxHeight: '700px',
		minHeight: '700px',
		minWidth: '48rem',
		maxWidth: '48rem',
		width: '48rem',
		padding: 0,
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		//remove scroll bar
		'-ms-overflow-style': 'none',
		'scrollbar-width': 'none',
		'&::-webkit-scrollbar': {
			display: 'none'
		},
		['@media only screen and (max-height: 700px)']: {
			maxHeight: 'unset',
			minHeight: 'unset',
			minWidth: 'unset',
			maxWidth: '100%',
			width: '100%',
			height: '100%',
			padding: 0,
			margin: 0,
			borderRadius: 0,
		},
		['@media only screen and (max-width:600px)']: {
			maxHeight: 'unset',
			minHeight: 'unset',
			minWidth: 'unset',
			maxWidth: '100%',
			width: '100%',
			height: '100%',
			padding: 0,
			margin: 0,
			borderRadius: 0,
		},
	}
}))(Paper);


const GoogleAuthForm = ({ open, onClose }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { t } = useTranslation('translations');

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);


	const closeModal = () => {
		dispatch(closeForm());
		onClose();
	};

	return (
		<Dialog
			id="google-auth-form"
			open={open}
			maxWidth='md'
			PaperComponent={FormPaper}
		>
			<Grid container sx={{p: {xs: 0, sm: '20px'}}}>
				<Grid container justifyContent='flex-end'>
					<Grid item>
						<IconButton onClick={closeModal}  >
							<MuiCloseIcon />
						</IconButton>
					</Grid>
				</Grid>
				<Grid item xs={12} container rowGap={5} sx={{p: {xs: '1rem', sm: '42px'}}}>
					<Grid item xs={12} textAlign='center' sx={{display: {xs: 'none', sm: 'block'}}}>
						<MuiHeader>{t('security.options.googoleAuth')}
						</MuiHeader>
					</Grid>
					<Grid item xs={12}>
						<GoogleAuthForms next={closeModal} />
					</Grid>
				</Grid>
			</Grid>

		</Dialog >
	);
};

export default GoogleAuthForm;

