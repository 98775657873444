import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BankAccount } from '@/features/account/types';
import MuiTextTrx from '../Shared/Widgets/MuiText/MuiTextTrx';
import CardTransactionForm from '../Banking/Card/CardDetails/CardTransactionForm';
import CardTransactionGridShort from './CardTransactionGridShort';

interface Props {
    account: BankAccount
}

const CardTransactions = ({ account }: Props) => {

    const { t } = useTranslation('translations');
    const [openTransactionForm, setOpenTransactionForm] = useState<boolean>(false);

    const handleSeeAll = () => {
        setOpenTransactionForm(true);
    };

    const handleCollapse = () => {
        setOpenTransactionForm(false);
    };

    return (<>
        <CardTransactionForm
            account={account}
            open={openTransactionForm}
            onClose={handleCollapse}
        />
        <Grid container
            alignItems='center'
            p={{ sx: '0px', md: '30px' }}
        >
            <Grid item xs={12} container justifyContent='space-between'>
                <Grid item xs>
                    <MuiTextTrx
                        color='text.secondary'
                        textAlign='right'
                        sx={{ cursor: 'pointer' }}
                        onClick={handleSeeAll}
                        pr='10px'
                    >
                        {t('transaction.labels.seeAll')}</MuiTextTrx>
                </Grid>
            </Grid>
            {account?.accountId && <CardTransactionGridShort account={account} key={account.accountId} />}
        </Grid >

    </>);

};

export default CardTransactions;

