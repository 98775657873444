/* eslint-disable react/prop-types */
import React from 'react';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Step, StepIconProps, StepLabel, Stepper } from '@mui/material';


const useStyles = makeStyles(() => ({
    stepLabel: {
        fontSize: '0.688rem',
        marginTop: '4px !important'
    }
}));

const StyledStepper = (props) => {

    const classes = useStyles();
    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10
        },
        [`&.${stepConnectorClasses.root}`]: {
            left: 'calc(-50%)',
            right: 'calc(50%)'
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage: theme.selectedItemBg.default
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage: theme.selectedItemBg.default
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor: theme.palette.text.secondary,
            borderRadius: 1,

        },
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            fontSize: '0.688rem',
            marginTop: '4px'
        },
    }));

    const ColorlibStepIconRoot = styled('div')<{
        ownerState: { completed?: boolean; active?: boolean };
    }>(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.text.secondary,
        zIndex: 1,
        color: '#fff',
        width: 20,
        height: 20,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundImage: theme.selectedItemBg.default
        }),
        ...(ownerState.completed && {
            backgroundImage: theme.selectedItemBg.default
        })
    }));

    const ColorlibStepIcon = (props: StepIconProps) => {
        const { active, completed, className } = props;

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}></ColorlibStepIconRoot>
        );
    };

    return (

        <Stepper
            connector={<ColorlibConnector />}
            alternativeLabel
            sx={{ width: '100%' }}
            {...props}
        >
            {props.steps.map((step, index) =>
                <Step key={index}>
                    <StepLabel classes={{ alternativeLabel: classes.stepLabel }} StepIconComponent={ColorlibStepIcon} >{step}</StepLabel>
                </Step>
            )}
        </Stepper>

    );


};

export default StyledStepper;
