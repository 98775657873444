import * as React from 'react';
import { SvgIcon } from '@mui/material';

const MenuCardsIcon = (props) => (
    <SvgIcon {...props} >
        <svg width="22" height="22" viewBox="0 0 22 22" fill="current" stroke="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.183 6.587l-.267.097a4.43 4.43 0 00-2.648 5.678l1.962 5.39A4.43 4.43 0 007.91 20.4l7.656-2.787h-4.857L7.34 18.84a2.769 2.769 0 01-3.549-1.655l-1.961-5.39a2.77 2.77 0 011.353-3.42V6.588z"
                fill="current"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.582 2.661H7.614a2.769 2.769 0 00-2.77 2.77v7.752c0 1.53 1.24 2.769 2.77 2.769h9.968c1.529 0 2.768-1.24 2.768-2.769V5.43c0-1.529-1.24-2.769-2.768-2.769zM7.614 1a4.43 4.43 0 00-4.43 4.43v7.753a4.43 4.43 0 004.43 4.43h9.968a4.43 4.43 0 004.43-4.43V5.43A4.43 4.43 0 0017.582 1H7.614z"
                fill="current"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.552 7.645a1.108 1.108 0 100-2.215H8.229a1.108 1.108 0 100 2.215h3.323z"
                fill="current"
            />
        </svg>
    </SvgIcon>
);

export default MenuCardsIcon;
