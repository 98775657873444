import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './BusinessPayment.css';
import MuiCopyToClipboard from '@/componentsMui/Shared/Widgets/MuiCopyToClipboard';
import MuiTextFieldInfo from '@/componentsMui/Shared/Widgets/MuiText/MuiTextFieldInfo';
import TopBox from '@/componentsMui/Shared/Widgets/Box/TopBox';
import MuiFormLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiFormLabel'; 
import MuiTextConfirmation from '@/componentsMui/Shared/Widgets/MuiText/MuiFormField';

interface Props {
	reference: string
}

const BusinessPaymentIdentifier = ({ reference }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	return (
		<TopBox  className='account-details-container'>
			<Grid container className='account-identifier-line'>
				<Grid item xs={4} >
					<MuiFormLabel color='text.secondary'>  {t('createbusiness.waitingForPayment.reference')}  </MuiFormLabel>
				</Grid>
				<Grid item xs={7}>
					<MuiTextConfirmation> {reference} </MuiTextConfirmation>
					<MuiTextFieldInfo>{t('createbusiness.waitingForPayment.referenceHelpertText')}</MuiTextFieldInfo>
				</Grid>
				<Grid item xs={1} container justifyContent='flex-end'>
					<MuiCopyToClipboard value={reference} />
				</Grid>
			</Grid>
		</TopBox>
	);
};

export default BusinessPaymentIdentifier;
