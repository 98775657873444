import React, { useEffect } from 'react';
import { Button, Grid, Theme, useTheme, Tab, Tabs } from '@mui/material';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MuiPlusIcon } from '@/assets/MuiPlusIcon.svg';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import ActiveEmployees from './ActiveEmployees';
import PendingEmployees from './PendingEmployees';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { connect, subscribe, unsubscribe } from '@/features/employee/slice';

enum EmployeeTab {
	ACTIVE = 'ACTIVE',
	PENDING = 'PENDING'
}


const Employees = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [tab, setTab] = React.useState(EmployeeTab.ACTIVE);
	const history = useHistory();
	const dispatch = useDispatch();

	const theme = useTheme() as Theme;

	useEffect(() => {
		connect();
		dispatch(subscribe());
		return () => {
			unsubscribe();
		};
	}, [dispatch]);


	const handleAddEmployee = () => {
		history.push('/wallet/employees/create');
	};

	const handleTabChange = (event: React.SyntheticEvent, newValue: EmployeeTab) => {
		setTab(newValue);
	};
	return (
		<Grid id='emplpoyees-view' container rowGap={4}>
			<Grid item xs={4} display='flex' alignItems='center'>
				<MuiHeader>{t('navbar.employees')}</MuiHeader>
			</Grid>
			<Grid item xs={8} display='flex' justifyContent='flex-end'>
				<Button variant='contained' onClick={handleAddEmployee}>
					<MuiPlusIcon style={{ fill: theme.palette.primary.contrastText, width: '1.5rem', height: '1.5rem' }} />
					<MuiTextCaption color={theme.palette.primary.contrastText} pl='0.5rem'>{t('employee.create.mainCreateBtn')}</MuiTextCaption>
				</Button>
			</Grid>
			<Grid item mt='1rem' xs={12}>
				<Tabs
					value={tab}
					onChange={handleTabChange}
				>
					<Tab value={EmployeeTab.ACTIVE} label={t('employee.activeEmployee')} className='employee-tabs' />
					<Tab value={EmployeeTab.PENDING} label={t('employee.pendingEmployee')} className='employee-tabs' />
				</Tabs>
			</Grid>
			<Grid item pt={2} xs={12}>
				{tab === EmployeeTab.ACTIVE && <ActiveEmployees />}
				{tab === EmployeeTab.PENDING && <PendingEmployees />}
			</Grid>
		</Grid>
	);
};

export default Employees;
