import { Typography, TypographyProps } from '@mui/material';
import React, { FC } from 'react';



const MuiBigHeader: FC<TypographyProps> = (props: TypographyProps) => {
	return (
		<Typography
			fontSize='2rem'
			fontWeight='700'
			lineHeight='2.25rem' 
			color='text.primary'
			{...props}>
			{props.children}
		</Typography>
	);
};

export default MuiBigHeader;
