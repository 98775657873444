import React from 'react';
import { Box, Button, Dialog, Grid, Theme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { RootState } from '@/rootReducer';
import '@/componentsMui/Shared/Shared.css';
import FormPaperLarge from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLarge';
import FormContentBox from '@shared/Widgets/Box/FormContentBox';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import MuiFormText from '@shared/Widgets/MuiText/MuiFormText';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/styles';
import BaseBox from '@shared/Widgets/Box/BaseBox';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ReceiveForm = (): React.ReactElement => {
	const theme = useTheme() as Theme;
	const closeModal = useCloseDialogNoEvent();

	const { user } = useSelector((state: RootState) => state.user);

	const { t } = useTranslation('translations');

	const { agentName } = useSelector(
		(state: RootState) => state.status
	);


	return (
		<Dialog
			id="wallet-send-form"
			open={true}
			PaperComponent={FormPaperLarge}
		>
			<div className='form-content__wrap' >
				<FormContentBox noBackground={true} className='form-content_main-area wp' >
					< Grid id='sepa-confirm-step'
						container>
						<Grid item xs={12} >
							<FormHeader title={t('tokens.receive.title')} showBack={true} onBack={closeModal} />
						</Grid>
						<Grid item xs={12}>
							<Box sx={{
								background: theme.warningBg,
								borderRadius: theme.mainBorderRadius,
								color: theme.palette.text.primary,
								p: '1.25rem',
							}}>
								<MuiFormText>
									{t('tokens.receive.body', { agent: agentName })}
								</MuiFormText>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Grid item xs={12} mt={2} mb={1}>
								<MuiFormText>
									{t('tokens.receive.accountId')}
								</MuiFormText>
							</Grid>
							<BaseBox padding='0.625rem 1.25rem'>
								<Grid container justifyContent='space-between'>
									<Grid item xs={8} display='flex' alignItems='center' >
										<MuiFormText>{user?.tag ?? user.clientId}</MuiFormText>
									</Grid>
									<Grid item >
										<CopyToClipboard text={user?.tag ?? user.clientId}>
											<Button variant='contained'
												color='secondary'> {t('form.buttons.copy')}
											</Button>
										</CopyToClipboard >
									</Grid>
								</Grid>
							</BaseBox>
						</Grid>
					</Grid>
					<Grid container pt='2rem' mt='auto'>
						<Grid item xs={6}>
							<Button
								className='button-left__wm'
								variant='customOutlined'
								color='secondary'
								onClick={closeModal}
							>  {t('form.buttons.close')}
							</Button>
						</Grid>
					</Grid>
				</FormContentBox>
			</div>
		</Dialog >
	);
};

export default ReceiveForm;
