import { Grid, Theme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Portfolio.css';
import WalletHistory from './WalletHistory/WalletHistory';
import BaseBox from '../Shared/Widgets/Box/BaseBox';
import '@/componentsMui/Shared/Shared.css';
import CryptoHistory from './TradeHistory/CryptoHistory';
import { useTheme } from '@mui/styles';
import { WalletTransactionType, WalletType } from '@features/walletHistory/types';
import CrytoBalance from './Balances/CrytoBalance';
import WalletAnnouncement from './WalletAnnouncement';

export enum WalletTab {
	BALANCES = 'balances',
	DEPOSITS = 'deposits',
	WITHDRAWALS = 'withdrawals',
	TRADES = 'trades',
	TRADE_HISTORY = 'tradeHistory',
	TRANSACTIONS = 'transactions'
}

const Portfolio = (): React.ReactElement => {
	const walletType = WalletType.CRYPTO;
	const theme = useTheme() as Theme;
	const { t } = useTranslation('translations');
	const walletCurrency = 'EUR';

	const searchParams = new URLSearchParams(location.search);
	const tab = searchParams.get('tab');

	const currentTab = tab ? tab : WalletTab.BALANCES;
	const pageScroll = currentTab === WalletTab.BALANCES;

	return (
		<Grid id='wallet-portfolio' container className={pageScroll ? '' : 'scroll-grid-container'} style={{minHeight:'100%'}} > 
				<WalletAnnouncement />
				{currentTab === WalletTab.BALANCES
					? <CrytoBalance currency={walletCurrency} />
					: <BaseBox sx={{
						background: {
							xs: 'none',
							sm: theme.palette.mode === 'dark' ? theme.backgroundBoxLevel1 : theme.palette.background.paper
						},
						p: { xs: 0, sm: '1.5rem' },
						width: '100%',
						mb: { xs: 3, md: 0 },
						mt: { xs: 0, md: 2 },
					}} className='scroll-section'>
						{currentTab === WalletTab.TRADES && <CryptoHistory
							walletTransactionType={WalletTransactionType[`${walletType}_TRADES`]}
							title={t('tokens.historyTitles.trades')} />}
						{currentTab === WalletTab.DEPOSITS && <CryptoHistory
							walletTransactionType={WalletTransactionType[`${walletType}_DEPOSITS`]}
							title={t('tokens.historyTitles.deposit')} />}
						{currentTab === WalletTab.WITHDRAWALS && <CryptoHistory
							walletTransactionType={WalletTransactionType[`${walletType}_WITHDRAWALS`]}
							title={t('tokens.historyTitles.withdrawal')} />}
						{currentTab === WalletTab.TRANSACTIONS && <WalletHistory walletTransactionType={WalletTransactionType.TRANSACTIONS} />}
					</BaseBox>
				} 
		</Grid>);
};

export default Portfolio;
