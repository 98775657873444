import * as React from 'react';
import '@/componentsMui/Shared/SharedFormLayout.css';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { postFile } from '@/features/files/slice';
import { runOperation } from '@/features/operations/slice';
import { showException, showSuccess } from '@/features/swal/slice';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { TrustPayloadType } from './TrustPaymentDetails';
import FormContentBox from '@/componentsMui/Shared/Widgets/Box/FormContentBox';
import { getCurrencySign } from '@/features/fees/slice';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import MuiFormField from '@/componentsMui/Shared/Widgets/MuiText/MuiFormField';
import ConfirmationBox from '@/componentsMui/Shared/Components/ConfirmationRow';

export interface Props {
	trustPayload: TrustPayloadType,
	prevStep: () => void
}

const TrustPaymentConfirmation = ({ trustPayload, prevStep }: Props) => {

	const { t } = useTranslation('translations');
	const closeModal = useCloseDialogNoEvent();
	const curr = getCurrencySign(trustPayload.account.currency);

	const send = async () => {

		try {
			const attachments: Map<string, string> = new Map<string, string>();
			if (trustPayload.selectedFile) {
				const formData = new FormData();
				formData.append('file', trustPayload.selectedFile);
				const fileRecord = await postFile(formData);
				const { key } = fileRecord;
				attachments.set(key, trustPayload.selectedFile.name);
			}
			await runOperation(trustPayload.processProcedure, {
				accountFromId: `${trustPayload.account.accountId}`,
				accountToId: `${trustPayload.user.accountId}`,
				amount: trustPayload.amount,
				attachments: Object.fromEntries(attachments)
			});

			showSuccess({
				title: 'paymentsubmitted.data.header', text: t('paymentsubmitted.data.sentamount', {
					amount: curr + '' + trustPayload?.amount,
					account: trustPayload?.walletId
				}), mui: true
			});

		} catch (e) {
			await showException(e);
		}
		finally { closeModal(); }
	};

	const handleConfirm = () => {
		send();
	};


	return (
		<div className='form-content__wrap__bb'>
			<FormContentBox noBackground={true} className='form-content_main-area wp'>
				<Grid container spacing={1} >
					<Grid item xs={12} pb={1}>
						<FormHeader title={t('sendmoney.data.confirmheader')} showBack={false} />
					</Grid>
					<Grid item xs={12}>
						<ConfirmationBox label={t('form.fields.from')}>
							<MuiFormField> {trustPayload.account.name}</MuiFormField>
						</ConfirmationBox>
					</Grid>
					<Grid item xs={12}>
						<ConfirmationBox label={t('sendmoney.data.transferamount')}>
							<MuiFormField>
								<NumberFormat
									displayType={'text'}
									decimalScale={2}
									fixedDecimalScale={true}
									thousandsGroupStyle='thousand'
									thousandSeparator={true}
									value={trustPayload.amount}
									prefix={`${curr} `} />
							</MuiFormField>
						</ConfirmationBox>
					</Grid>
					<Grid item xs={12}>
						<ConfirmationBox label={t('sendmoney.data.transferfee')}>
							<MuiFormField>
								<NumberFormat
									displayType={'text'}
									decimalScale={2}
									fixedDecimalScale={true}
									thousandsGroupStyle='thousand'
									thousandSeparator={true}
									value={trustPayload.fee}
									prefix={`${curr} `} />
							</MuiFormField>
						</ConfirmationBox>
					</Grid>
					<Grid item xs={12}>
						<ConfirmationBox label={t('sendmoney.data.youpay')}>
							<MuiFormField>
								<NumberFormat
									displayType={'text'}
									decimalScale={2}
									fixedDecimalScale={true}
									thousandsGroupStyle='thousand'
									thousandSeparator={true}
									value={trustPayload.youpay}
									prefix={`${curr} `} />
							</MuiFormField>
						</ConfirmationBox>
					</Grid>
					<Grid item xs={12}>
						<ConfirmationBox label={t('sendmoney.data.cospayuser')}>
							<MuiFormField>{`${trustPayload.user.firstName} ${trustPayload.user.lastName}`}</MuiFormField>
						</ConfirmationBox>
					</Grid>
					<Grid item xs={12}>
						<ConfirmationBox label={t('sendmoney.data.walletid')}>
							<MuiFormField>{trustPayload.walletId}</MuiFormField>
						</ConfirmationBox>
					</Grid>
					{trustPayload.selectedFile &&
						<Grid item xs={12}>
							<ConfirmationBox label={t('sendmoney.data.document')}>
								<MuiFormField>{trustPayload.selectedFile.name}</MuiFormField>
							</ConfirmationBox>
						</Grid>
					}
				</Grid>
				<Grid container mt='auto' pt='2rem' columnSpacing={2}>
					<Grid item xs={6}>
						<Button
							variant='customOutlined'
							color='secondary'
							onClick={prevStep}
							sx={{width: {xs: '100%', md: 'auto'}}}
						>  {t('form.buttons.back')}
						</Button>
					</Grid>
					<Grid item xs={6} container justifyContent='flex-end'>
						<Button
							variant="contained"
							color="primary"
							sx={{width: {xs: '100%', md: 'auto'}}}
							onClick={handleConfirm}
						>
							{t('createaccounttab.btn')}
						</Button>
					</Grid>
				</Grid>
			</FormContentBox>
		</div>);
};

export default TrustPaymentConfirmation;
