import * as React from 'react';
import { SvgIcon } from '@mui/material';

const MenuBusinessIcon = (props) => (
    <SvgIcon {...props} >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="current" xmlns="http://www.w3.org/2000/svg">
            <path
                 d="M16.889 6.5C16.889 3.462 14.7 1 12 1S7.111 3.462 7.111 6.5M23 10.9v7.7c0 1.54 0 2.31-.266 2.898a2.618 2.618 0 01-1.069 1.202c-.523.3-1.207.3-2.576.3H4.911c-1.369 0-2.054 0-2.577-.3A2.617 2.617 0 011.266 21.5C1 20.91 1 20.14 1 18.599v-7.7c0-1.54 0-2.31.266-2.897A2.618 2.618 0 012.334 6.8c.523-.3 1.208-.3 2.577-.3H19.09c1.37 0 2.053 0 2.576.3.46.263.835.684 1.069 1.202C23 8.59 23 9.36 23 10.9z"
                 stroke="current"
                 strokeWidth={1.5}
                 strokeLinecap="round"
                 strokeLinejoin="round"
            />
        </svg>
    </SvgIcon>
);

export default MenuBusinessIcon;
