import * as React from 'react';
import { useEffect, useState } from 'react';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import SearchField from '@/componentsMui/Shared/Widgets/SearchField';
import { Box, Button, Grid, Theme, useMediaQuery } from '@mui/material';
import { getTime } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { useTranslation } from 'react-i18next';
import WalletHistoryHeaders from './WalletHistoryHeaders';
import WalletHistoryLine from './WalletHistoryLine';
import WalletHistoryLineMobile from './WalletHistoryLineMobile';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';
import ExpandButton from '@/componentsMui/Shared/Widgets/ExpandButton';
import WalletHistoryForm from './WalletHistoryForm';
import StyledDividerThin from '@/componentsMui/Shared/Widgets/StyledDividerThin';
import '@/componentsMui/Shared/Shared.css';
import { WalletHistoryPayload, WalletTransactionType } from '@/features/walletHistory/types';
import { getWalletHistory, setSearch, setSkip, setTransactions, setType } from '@/features/walletHistory/slice';
import { Transaction } from '@/componentsMui/Transactions/TransactionStatements.spec';
import { transactionDetailsEnabled } from '@helpers/transactionUtils';
import { closeForm, openForm } from '@features/forms/slice';
import TransactionDetailsForm from '@/componentsMui/Transactions/Details/TransactionDetailsForm';
import DownloadButton from '@shared/Widgets/DownloadButton';
import WalletExportStatementForm from '@/componentsMui/Transactions/Download/WalletExportStatementForm';
import { AccountType } from '@features/operations/types';
import { useTheme } from '@mui/styles';
import StyledDivider from '@shared/Widgets/StyledDivider';
import TrxGenericError from '@/componentsMui/TransactionsGrid/Components/TrxGenericError';

interface Props {
	hideExpand?: boolean,
	title?: string,
	walletTransactionType: WalletTransactionType,
}


const WalletHistory = ({ hideExpand, title, walletTransactionType }: Props) => {
	const theme = useTheme() as Theme;
	const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const { user } = useSelector((state: RootState) => state.user);
	const [loading, setLoading] = useState(false);
	const [showLoadMore, setShowLoadMore] = useState<boolean>(true);
	const lastTransactionRef = React.useRef(null);
	const [openTransactionForm, setOpenTransactionForm] = React.useState<boolean>(false);
	const [selectedTrx, setSelectedTrx] = React.useState<Transaction>();
	const [openTransactionDetails, setOpenTransactionDetails] = React.useState<boolean>(false);
	const [openDownloadForm, setOpenDownloadForm] = React.useState<boolean>(false);
	const [error, setError] = useState(false);
	const [time] = useState<number>(new Date().getTime() + 86400000);

	const { skip, take, transactions, search } = useSelector(
		(state: RootState) => state.walletHistory
	);

	const from = getTime(new Date(user.createdDate));

	const handleSearchRequest = (e) => {
		const regex = /^[ A-Za-z0-9-.,&/_?!]*$/;
		if (regex.test(e.target.value)) {
			dispatch(setSkip(0));
			dispatch(setTransactions([]));
			dispatch(setSearch(e.target.value));
		}
	};

	const fetchData = React.useCallback(() => {
		const get = async () => {
			setError(false);
			setLoading(true);
			setShowLoadMore(true);
			try {

				const payload: WalletHistoryPayload = {
					search: search,
					skip: 0,
					take: take,
					from: `${from}`,
					to: `${time}`
				};

				const result = await getWalletHistory(walletTransactionType, payload);
				if (result.list.length === result.count) {
					setShowLoadMore(false);
				}
				dispatch(setTransactions(result.list));
			} catch (e) {
				setError(true);
				dispatch(setTransactions([]));
			} finally {
				setLoading(false);
			}

			dispatch(setSkip(take));
		};

		get();
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [walletTransactionType, search, dispatch]);



	useEffect(() => {
		if (walletTransactionType) {
			dispatch(setType(walletTransactionType));
		}
	}, [dispatch, walletTransactionType]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const loadMore = async () => {
		try {
			const payload: WalletHistoryPayload = {
				search: search,
				skip: skip,
				take: take,
				from: `${from}`,
				to: `${time}`,
			};

			const result = await getWalletHistory(walletTransactionType, payload);

			const newTrxArray = [...transactions, ...result.list];
			dispatch(setTransactions(newTrxArray));
			dispatch(setSkip(skip + take));
			if (result.count === newTrxArray.length) {
				setShowLoadMore(false);
			}

			lastTransactionRef.current?.scrollIntoView({ behavior: 'smooth' });
		} catch (e) {
			dispatch(setTransactions([]));
			setError(true);
		} finally {
			setLoading(false);
		}

	};

	const openTransactionDetailsForm = (trx: Transaction) => {
		if (transactionDetailsEnabled(trx.activity)) {
			dispatch(openForm());
			setOpenTransactionDetails(true);
			setSelectedTrx(trx);
		}
	};

	const enablePointer = (trx: Transaction) => {
		return transactionDetailsEnabled(trx.activity);
	};

	const closeTransactionDetailsForm = React.useCallback(() => {
		setOpenTransactionDetails(false);
		dispatch(closeForm());
	}, [dispatch]);



	const handleExpand = () => {
		setOpenTransactionForm(true);
	};

	const handleCollapse = () => {
		setOpenTransactionForm(false);
	};

	return (
		<div id='wallet-history' className='scroll-container'>
			<WalletHistoryForm
				open={openTransactionForm}
				walletTransactionType={walletTransactionType}
				onClose={handleCollapse} />
			<TransactionDetailsForm open={openTransactionDetails}
				onClose={closeTransactionDetailsForm}
				transaction={selectedTrx}
				type={AccountType.WALLET}
				setLoading={(value: boolean) => setLoading(value)} />
			<WalletExportStatementForm
				open={openDownloadForm}
				type={AccountType.WALLET}
				walletTransactionType={walletTransactionType}
				onClose={() => setOpenDownloadForm(false)} />
			<Grid container pb={2}>
				<Grid item xs={12} sm={4} sx={{ mb: { xs: '1rem' } }}>
					<MuiHeader >{title ?? t('wallet.tabs.transactions')}</MuiHeader>
				</Grid>
				<Grid item xs={12} sm={8} container justifyContent='flex-end' spacing={1}>
					{(!hideExpand && !smallScreen) &&
						<Grid item>
							<ExpandButton onClick={handleExpand} />
						</Grid>
					}
					<Grid item>
						<DownloadButton onClick={() => setOpenDownloadForm(true)} />
					</Grid>
					<Grid item xs={smallScreen}>
						<SearchField value={search}
							style={{ width: '100%' }}
							onChange={(e) => handleSearchRequest(e)} />
					</Grid>
				</Grid>
			</Grid>
			{loading && <Grid container justifyContent='center' mt={2}><Spinner /></Grid >}
			{error && <TrxGenericError onButtonClick={() => fetchData()} />}
			{(!transactions || transactions.length === 0) && !error && !loading &&
				<div style={{
					display: 'flex',
					justifyContent: 'space-around'
				}}>{t('transaction.table.noTransactions')}</div>}
			{transactions?.length > 0 &&
				<>
					{smallScreen ?
						<div className='scrollable-content'>
							{transactions.length > 0 && transactions?.map((item, index) => (
								<Box key={`${item.transactionNumber}${index}`} ref={transactions.length === index + 1 ? lastTransactionRef : null} sx={{ py: '0.5rem' }}>
									<WalletHistoryLineMobile item={item as unknown as Transaction} openTransactionDetailsForm={openTransactionDetailsForm} enablePointer={enablePointer} />
									{index + 1 < transactions.length && <StyledDivider />}
								</Box>
							))}
						</div>
						:
						<>
							<div className='scroll-section'>
								<div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
									<WalletHistoryHeaders />
								</div>
								<div className='scrollable-content' style={{ paddingRight: '1rem' }}>
									{transactions.length > 0 && transactions?.map((item, index) => {
										return (
											<div key={item.transactionNumber} ref={transactions.length === index + 1 ? lastTransactionRef : null}>
												<StyledDividerThin />
												<WalletHistoryLine item={item as unknown as Transaction} openTransactionDetailsForm={openTransactionDetailsForm} enablePointer={enablePointer} />
											</div>);
									})
									}
								</div>
							</div>
						</>}
					{showLoadMore &&
						<Grid container >
							<Grid item xs={12} justifyContent='center' display='flex' pt={2}>
								<Button onClick={loadMore}>{t('transaction.table.loadMore')}</Button>
							</Grid>
						</Grid>
					}
				</>
			}
		</div>);
};

export default WalletHistory;
