import * as React from 'react';

import '@/componentsMui/Shared/Shared.css';

import {
	Button, Grid,
	InputAdornment,
	Link
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useState } from 'react';
import { BankAccount } from '@/features/account/types';
import useCloseDialog from '@/helpers/customHook/useCloseDialog';
import FormContentBox from '@/componentsMui/Shared/Widgets/Box/FormContentBox';
import { maxTwoDecimals } from '@/helpers/number';
import NumberFormat from 'react-number-format';
import { Form, Formik } from 'formik';
import AccountDropdown from '@/componentsMui/Shared/FormikComponents/AccountDropdown';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { getCurrencySign } from '@/features/fees/slice';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { findProcessByType, runOperation } from '@/features/operations/slice';
import { showException } from '@/features/swal/slice';
import CardTransferRedirect from './CardTransferRedirect';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import StyledCheckbox from '@/componentsMui/Shared/Widgets/StyledCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import { refreshToken5MinuteLong } from '@/features/user/userSlice';
import { RootState } from '@/rootReducer';
import { Agents } from '@/helpers/globalTypes';
import { ReactComponent as MasterCard_SecureCode } from '@/assets/MasterCard_SecureCode.svg';
import { ReactComponent as Verified_by_Visa } from '@/assets/Verified_by_Visa.svg';

interface CardTopUpResponse {
	userId?: number,
	properties: {
		process: string,
		activityId: string,
		workflowId: string,
	},
	value: {
		REDIRECT_URL: string
	}
}

interface CardTransferPayload {
	successUrl: string,
	cancelUrl: string,
	errorUrl: string,
	targetAccountId: number,
	amount: number,
	ccy: string
}

type Props = {
	toAccountList: BankAccount[],
	toDefaultAccount?: BankAccount,
	title?: string,
	prevStep?: () => void,
}

const CardTransferDetails = ({
	toAccountList,
	toDefaultAccount,
	title,
	prevStep }: Props) => {


	const closeModal = useCloseDialog();

	const { t } = useTranslation('translations');
	const [toAccount, setToAccount] = useState<BankAccount>(toDefaultAccount ?? null);
	const [amount, setAmount] = useState<string>('');
	const [confirm, setConfirm] = useState<boolean>(false);
	const [redirectUrl, setRedirectUrl] = useState<string>('');
	const { path } = useRouteMatch();
	const process = findProcessByType(toAccount, 'DEPOSIT_FROM_CARD');
	const dispatch = useDispatch();
	const history = useHistory();
	const { agent } = useSelector(
		(state: RootState) => state.status
	);

	const pathStart = window.location.href.split(path)[0];

	const initialValues = {
		amount: ''
	};

	const maxAmount = process?.config?.maxTransactionAmount ?? 0;
	const minAmount = process?.config?.minTransactionAmount ?? 0;

	const validationSchema = Yup.object({
		amount: Yup.number()
			.typeError(t('form.validator.required'))
			.required(t('form.validator.required')).nullable()
			.min(minAmount, t('form.validator.minNumber', { field: t('sendmoneyTranslation.data.transferamount'), amount: minAmount }))
			.max(maxAmount, t('form.validator.maxNumber', { field: t('sendmoneyTranslation.data.transferamount'), amount: maxAmount }))
			.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value))
	});

	const submit = async (formData, formikProps) => {
		const { amount } = formData;
		const { setSubmitting } = formikProps;
		setSubmitting(true);
		try {
			const payload: CardTransferPayload = {
				successUrl: `${pathStart}/wallet/home/accounts/addmoneycard/success`,
				errorUrl: `${pathStart}/wallet/home/accounts/addmoneycard/error`,
				cancelUrl: `${pathStart}/wallet/home/accounts/addmoneycard/cancel`,
				targetAccountId: toAccount.accountId,
				amount: amount,
				ccy: toAccount.currency
			};
			dispatch(refreshToken5MinuteLong());
			const result: CardTopUpResponse = await runOperation(process.proc, payload);
			setRedirectUrl(result.value.REDIRECT_URL);
		}
		catch (e) {
			showException(e);
		}
		finally {
			setSubmitting(false);
		}

	};


	const handleToAccountChange = (newValue: BankAccount) => {
		setToAccount(newValue);
	};

	const handleAmountChange = (event: React.ChangeEvent<any>, setFieldValue, setFieldTouched) => {
		setFieldValue('amount', event.target.value);
		setAmount(event.target.value);
		setTimeout(() => setFieldTouched('amount', true));
	};

	const goToPolicy = () => {
		history.push('/wallet/cardtopup');
	};

	return (
		<>
			{redirectUrl ?
				<CardTransferRedirect url={redirectUrl} /> :
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={submit}

				>
					{({ errors, isSubmitting, setFieldValue, setFieldTouched }) => {
						return (
							<Form className='form-content__wrap__bb' id='card-transfer-details' >
								<FormContentBox noBackground={true} className='form-content_main-area wp' >
									<Grid container columnSpacing={3} rowSpacing={1}>
										<Grid item xs={12}>
											<FormHeader title={title ?? t('banking.addMoney.transfer')} onBack={prevStep} showBack={!!prevStep} />
										</Grid>
										<Grid item xs={12} sm={6}>
											<AccountDropdown
												fieldlabel={t('form.fields.to')}
												fieldName='to'
												accountList={toAccountList}
												selectedAccount={toAccount}
												onAccountChange={(value: BankAccount) => handleToAccountChange(value)} />
										</Grid>
										<Grid item xs={12} sm={6}>
											<NumberFormat
												name="amount"
												customInput={MuiTextField}
												decimalScale={2}
												onChange={(event) => handleAmountChange(event, setFieldValue, setFieldTouched)}
												allowNegative={false}
												value={amount}
												displayType={'input'}
												placeholder={'0.00'}
												fullWidth
												label={t('sendmoneyTranslation.data.transferamount')}
												inputProps={{ autoComplete: 'off' }}
												InputProps={{
													startAdornment:
														<InputAdornment position="start">
															{getCurrencySign(toAccount?.currency)}
														</InputAdornment>,
												}}
											/>
										</Grid>
										<Grid item xs={12} container mt={4}>
											<Grid item xs={2} md={1}>
												<StyledCheckbox
													checked={confirm}
													onChange={() => {
														setConfirm(!confirm);
													}}
												/>
											</Grid>
											{agent !== Agents.COSPAY ?
												<Grid item xs={10} md={11}>
													<MuiFormText>{t('banking.addMoney.messages.confirmTopUp')}</MuiFormText>
												</Grid> :
												<Grid container item xs={10} md={11} alignItems={'center'}>
													<MuiFormText>
														{t('banking.addMoney.messages.confirmTopUp2')}
														<Link variant='gradient' fontSize='0.875rem'
															onClick={goToPolicy} >
															{t('banking.addMoney.policy')}
														</Link>
													</MuiFormText>
												</Grid>}
										</Grid>
										{agent === Agents.COSPAY ?
											<Grid item xs={12} container justifyContent={'flex-start'}>
												<MasterCard_SecureCode height={50} />
												<Verified_by_Visa height={50} />
											</Grid> : null}
									</Grid >
									<Grid container mt='auto' pt='2rem' columnSpacing={2}>
										<Grid item xs={6}>
											<Button
												variant='customOutlined'
												color='secondary'
												onClick={closeModal}
												sx={{ width: { xs: '100%', md: 'auto' } }}
											>  {t('form.buttons.close')}
											</Button>
										</Grid>
										<Grid item xs={6} container justifyContent='flex-end'>
											<Button disabled={isSubmitting || !isEmpty(errors) || toAccount === null || !confirm}
												variant='contained'
												color='primary'
												type="submit"
												sx={{ width: { xs: '100%', md: 'auto' } }}
											>
												{t('form.buttons.next')}
											</Button>
										</Grid>
									</Grid>
								</FormContentBox>
							</Form>
						);
					}}
				</Formik >}
		</>
	);
};

export default CardTransferDetails;
