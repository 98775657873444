import { BankAccount } from '@/features/account/types';
import { AccountType } from '@/features/operations/types';
import { CoinTypeEnum } from '@features/coins/types';

export const getAccountName = (t: any, bankAccount: BankAccount) => {
	/*return bankAccount?.providerType === 'POOLING' && bankAccount.parentWalletId === null ?
		t('pooling.dtsLabel') :
		bankAccount?.name;
		*/

	if (!bankAccount) return ('');
	if (bankAccount.type === CoinTypeEnum.TOKEN) {
		// return t('wallet.deposit.wallet');
		return bankAccount?.currency;
	}
	if (bankAccount.type === AccountType.BANK || bankAccount.type === AccountType.BANK_AND_CARD) {
		return t('banking.data.accountName', { ccy: bankAccount.currency });
	}
	return bankAccount?.name;
};
