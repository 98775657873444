import React, { FC } from 'react';
import { IconButton, IconButtonProps, styled } from '@mui/material';

/*
Give  background  and size to icon
*/


const StyledIconButtonSmall: FC<IconButtonProps> =
	styled((props: IconButtonProps) => (<IconButton
		{...props}
	/>)
	)(({ theme }) => ({
		width: '2.5rem',
		height: '2.5rem',
		padding: '10px',
		borderRadius: '10px',
		background: theme.palette.mode === 'dark' ? theme.palette.background.paper : 'transparent',
		border: theme.palette.mode === 'dark' ? 'unset' : theme.inputField.border
	}));

export default StyledIconButtonSmall;
