import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useUserOperations from '@/helpers/customHook/useUserOperations';
import SummaryBox from './SummaryBox';
import MuiTextAccountBoxHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAccountBoxHeader';
import { connectStatistics, getStatistics, subscribeStatistics, unsubscribeStatistics } from '@/features/statistics/statisticsSlice';
import bigDecimal from 'js-big-decimal';

const MyAccountsSummary = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const history = useHistory();
	const dispatch = useDispatch();
	const mainCcy = 'EUR';

	const { statistics } = useSelector((state: RootState) => state.statistics);

	const operations = useUserOperations();
	const showAccounts = operations.find(p => p.path === 'accounts')?.allowed ?? false;
	const showCards = operations.find(p => p.path === 'cards')?.allowed ?? false;
	const showWallet = operations.find(p => p.path === 'wallets')?.allowed ?? false;

	const fundingAssets = statistics?.fundingAccountAssets?.amountString ?? '0'; 
	const cryptoAssets = statistics?.cryptoAssets?.amountString ?? '0';
	const cryptoWallet = statistics?.cryptoWallet?.amountString ?? '0';
	const cardsBalance = statistics?.cardAvailableBalance?.amountString ?? '0';
	const totalCrypto = bigDecimal.add(cryptoWallet, cryptoAssets);

	useEffect(() => {
		connectStatistics();
		dispatch(subscribeStatistics());
		return () => {
			unsubscribeStatistics();
		};
	}, [dispatch]);


	useEffect(() => {
		dispatch(getStatistics(mainCcy));
	}, [dispatch]);


	if (!showAccounts && !showCards && !showWallet) {
		return <></>;
	}

	return (
		<Grid container>
			<Grid item xs={12} mb='20px'>
				<MuiTextAccountBoxHeader>{t('home.myAccounts.title')}</MuiTextAccountBoxHeader>
			</Grid>
			<Grid item xs={12} container spacing='20px'>
				{showAccounts &&
					<Grid item xs={6} >
						<SummaryBox iconType='CARD' amount={fundingAssets} currency='€'
							text={t('home.myAccounts.funding')}
							onClick={() => history.push('/wallet/home/accounts?tab=accounts')} />
					</Grid>
				}
				{showWallet &&
					<Grid item xs={6} >
						<SummaryBox iconType='CRYPTO' amount={totalCrypto} currency='€'
							text={t('home.myAccounts.crypto')}
							onClick={() => history.push('/wallet/home/wallets?tab=balances')} />
					</Grid>
				}
				{showCards &&
					<Grid item xs={6} >
						<SummaryBox iconType='CARD' amount={cardsBalance} currency='€'
							text={t('home.myAccounts.cards')}
							onClick={() => history.push('/wallet/home/cards')} />
					</Grid>
				}
			</Grid>
		</Grid>);
};

export default MyAccountsSummary;
