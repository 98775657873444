import React from 'react';
import {
	Avatar,
	Button,
	Chip,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Contact, ContactPaymentTypes } from '@features/contacts/types';
import { ReactComponent as MuiBinIcon } from '@/assets/MuiBinIcon.svg';
// import ArrowRightIcon from '@mui/icons-material/East';
import ArrowLeftIcon from '@mui/icons-material/West';

interface Props {
	contact: Contact,
	onDelete: (id: number) => void,
	onSend: (id: number, type: string) => void,
	onRequest: (id: number) => void,
	onEdit: (id: number) => void,
}

const ContactLine = ({ contact, onDelete, onRequest }: Props) => {
	const { t } = useTranslation('translations');
	const brandName = t('brandName.brand');
	const nameArray = contact.name.split(' ');
	const initials = nameArray.length > 1 ?
		`${nameArray[0].charAt(0).toUpperCase()}${nameArray[1].charAt(0).toUpperCase()}` :
		nameArray[0].charAt(0).toUpperCase();

	const ukAccount = contact?.accountNumber ? [contact?.accountNumber, contact?.sortCode].join(' ') : undefined;
	const contactNumber = contact.paymentType === ContactPaymentTypes.LOCAL ? contact.clientId ?? contact.walletId : ukAccount ?? contact.account ?? contact.walletId;

	return (
		<Grid
			container
			my={{ xs: '20px', md: '10px' }}
			rowGap={1}
		>
			<Grid item
				xs={12}
				sm={6}
				container
			>
				<Grid item xs={12} md={7} container wrap='nowrap'>
					<Grid item justifyContent='flex-start' alignItems='center' pr='20px'>
						<Avatar sx={(theme) => ({ height: '42px', width: '42px', bgcolor: theme.bgSecondaryButton })}>{initials}</Avatar>
					</Grid>
					<Grid item
						sx={{
							display: 'flex',
							flexDirection: 'column',
						}}
						justifyContent='space-evenly'
						maxWidth='100%'
						minWidth='0'
						xs={false}
						md={10}
					>
						<Grid item xs={12}>
							<Typography fontSize='0.813rem' fontWeight='700' color='text.primary' noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}> {contact.name} </Typography>
							<Typography fontSize='0.875rem' lineHeight='1.375rem' fontWeight='400' color='text.secondary' noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}> {contactNumber} </Typography>

						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={4} sx={{ display: { xs: 'none', md: 'flex' } }} justifyContent='center' alignItems='center'>
					{contact.paymentType === ContactPaymentTypes.LOCAL &&
						<Chip label={`${brandName} ${t('contacts.user')}`} color="primary" />
					}
					{contact.paymentType === ContactPaymentTypes.CRYPTO && <Typography fontSize='0.875rem' lineHeight='1.375rem' fontWeight='400' color='text.secondary' noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}>
						{contact.cryptoNetwork}
					</Typography>}
				</Grid>
			</Grid>
			<Grid item
				xs={12}
				sm={6}
				container
				justifyContent='flex-end'
				flexWrap='wrap'
				alignItems="center"
				rowGap={1}
				columnGap={1}
			>
				{/* <Grid item xs md='auto'>
					<Button
						fullWidth
						onClick={() => onSend(contact.beneficiaryAccountId, contact.paymentType)}
						variant="transparent"
						startIcon={<ArrowRightIcon />}
						disabled={contact.paymentType === ContactPaymentTypes.CRYPTO && !contact.ccy}
					>
						{t('banking.data.send')}
					</Button>
				</Grid> */}
				<Grid item xs md='auto'>
					<Button
						fullWidth
						onClick={() => onRequest(contact.beneficiaryAccountId)}
						disabled={contact.paymentType !== ContactPaymentTypes.LOCAL}
						variant="transparent"
						startIcon={<ArrowLeftIcon />}
					>
						{t('banking.data.request')}
					</Button>
				</Grid>
				{/* <Grid item xs md='auto'>
					<Button
						fullWidth
						onClick={() => onEdit(contact.beneficiaryAccountId)}
						variant="transparent"
						startIcon={<BorderColorOutlinedIcon />}
					>
						{t('form.buttons.edit')}
					</Button>
				</Grid> */}
				<Grid item xs md='auto' textAlign='center'>
					<IconButton onClick={() => onDelete(contact.beneficiaryAccountId)} >
						<MuiBinIcon />
					</IconButton>

				</Grid>
			</Grid>
		</Grid>
	);
};

export default ContactLine;
