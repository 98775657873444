import * as React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import './BusinessCards.css';

interface Props {
	shCards?: boolean;
}

const BusinessCardsHeader = ({ shCards }: Props) => {
	const { t } = useTranslation('translations');
	return (
		<Grid container alignItems='center' mb={2} >
			<Grid item className='business-cards-list__name'>
				<MuiTextCaption color='text.secondary'>
					{t('cards.businessCards.listHeaders.name')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='business-cards-list__card-holder'>
				<MuiTextCaption color='text.secondary'>
					{t('cards.businessCards.listHeaders.cardHolder')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='business-cards-list__balance'>
				<MuiTextCaption color='text.secondary'>
					{shCards ? t('cards.cardDetailsInfo.cardNumber') : t('cards.businessCards.listHeaders.balance')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='business-cards-list__balance-on-hold'>
				<MuiTextCaption color='text.secondary'>
					{shCards ? t('cards.cardDetailsInfo.expiryDate') : t('cards.businessCards.listHeaders.balanceOnHold')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='business-cards-list__status'>
				<MuiTextCaption color='text.secondary'>
					{t('cards.businessCards.listHeaders.status')}
				</MuiTextCaption>
			</Grid>
		</Grid>);
};

export default BusinessCardsHeader;
