import { RootState } from '@/rootReducer';
import React from 'react';
import { useSelector } from 'react-redux';

import MuiFormLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiFormLabel';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const RegistrationDisclaimer = () => {
    const { agent } = useSelector((state: RootState) => state.status);
    const { t } = useTranslation(['translations']);
    if (agent === 'ESCROWFY') return (
        <Grid item xs={12} mt={1}>
            <MuiFormLabel> {t('login.v3.DisclaimerEscrowfy')} </MuiFormLabel>
        </Grid>);


    if (agent === 'NEURALID') return (
        <Grid item xs={12} mt={1}>
            <MuiFormLabel> {t('login.v3.neuraldIdDisclaimer')} </MuiFormLabel>
        </Grid>);

    if (agent === 'IDEALOOP') return (
        <Grid item xs={12} mt={1}>
            <MuiFormLabel> {t('login.v3.neuraldIdDisclaimer')} </MuiFormLabel>
        </Grid>);

    if (agent === 'SLP') return (
        <Grid item xs={12} mt={1}>
            <MuiFormLabel textAlign='justify'> {t('login.v3.slpDisclaimer')} </MuiFormLabel>
        </Grid>);

    return (<></>);

};


export default RegistrationDisclaimer;
