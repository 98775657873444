import { Alert, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MuiFormText from './Shared/Widgets/MuiText/MuiFormText';

const ErrorFallback = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	return (
		<Grid container justifyContent='center' alignItems='center'>
			<Grid item>
				<Alert severity="warning"  >
					<MuiFormText > {t('errors.SOMETHING_WENT_WRONG_WITHOT_MESSAGE')}</MuiFormText >
				</Alert>
			</Grid>
		</Grid>
	);
};

export default ErrorFallback;
