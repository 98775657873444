import { Button, Grid } from '@mui/material';
import { ButtonPropsVariantOverrides } from '@mui/material/Button';
import { OverridableStringUnion } from '@mui/types';
import React from 'react';

export interface FormFooterButtonsProps {
	title: string,
	variant?: OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>,
	disabled?: boolean,
	autoFocus?: boolean,
	onClick?: () => void,
}

interface Props {
	buttons: FormFooterButtonsProps[]
}

const FormFooterButtons = ({ buttons }: Props): React.ReactElement => {
	return (
		<Grid container flex={1} alignItems='flex-end' justifyContent='space-between' mt='auto'>
			{buttons.map(b =>
				<Button
					key={b.title}
					variant={b.variant ?? 'customOutlined'}
					onClick={b?.onClick}
					disabled={b.disabled}
				>
					{b.title}
				</Button>
			)}
		</Grid>
	);
};

export default FormFooterButtons;
