import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';

/*
Accounts used for platform service payments, e.g. pay for create card, plan upgrade
*/
const usePayAccounts = (currency?: string) => {

	const { loading: accountsLoading, list, error: accountListError } = useSelector((state: RootState) => state.accounts);

	const initialList = list.filter(p => currency ? p.currency === currency : true);
	const accountList = initialList.filter(acc => acc.status != 'BLOCKED');
	return { accountsLoading, accountList, accountListError };
};

export default usePayAccounts;
