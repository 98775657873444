import React from 'react';
import { Avatar, Button, Grid, Typography } from '@mui/material';
import BaseBox from '@/componentsMui/Shared/Widgets/Box/BaseBox'; 
import { useTranslation } from 'react-i18next';
import MuiTextAccountBoxHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAccountBoxHeader';
import { Company } from '@/features/employee/types';
import { RootState } from '@/rootReducer';
import useUserSwitch from '@/helpers/customHook/useUserSwitch';
import { useSelector } from 'react-redux';

interface Props {
	business: Company
}

const OnBehalfOfLine = ({ business }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	
	const { handleOnBehalfOfSwitch, unsetOnBehalfOfSwitch } = useUserSwitch();

	const handleSwitchOnBehalfOf = () => {
		if(parseInt(user?.onBehalfOf) === business.userId) {
			unsetOnBehalfOfSwitch();
		} else {
			handleOnBehalfOfSwitch(business.userId);
		}
	};

	const { user } = useSelector(
		(state: RootState) => state.user
	);

	return (
		<>
			<BaseBox
				sx={{ my: '0.5rem', p: '1rem' }}
				borderRadius='10px'
				width='100%'
			>
				<Grid
					item
					xs={12}
					container
					alignItems="center"
					columnSpacing={1}
					wrap='nowrap'
				>
					<Grid item justifyContent='flex-start' alignItems='center' sx={{mr: {xs: 0, sm: '1rem'}}}>
						<Avatar>
							{business.company.charAt(0)}
						</Avatar>
					</Grid>
					<Grid item
						  sx={{
							  display: 'flex',
							  flexDirection: 'column',
						  }}
						  justifyContent='space-evenly'
						  maxWidth='100%'
						  minWidth='0'
						  xs={false}
						  md={3}
					>
						<Grid item xs={12}>
							<Grid container alignItems="baseline" flexWrap='nowrap'>
								<Typography fontSize='1.25rem' fontWeight={700} pr='1rem' variant='body1' color='text.primary' noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}>
									{business.company}
								</Typography>
							</Grid>
							<Grid container alignItems="baseline" flexWrap='nowrap'>
								<Typography fontSize='0.875rem' color='text.secondary' noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}>
									{business.approved ? t('business.behalfOf.adminApproved') : t('business.behalfOf.adminPending')}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item ml='auto' pl={6}
						  justifyContent='space-evenly'
						  alignItems="center"
					>
						<Grid item>
							<Button
								onClick={handleSwitchOnBehalfOf}
								variant="statusActive"
								sx={{minWidth: {xs: '90px', md: '120px'}}}
								disabled={!business.approved}
							>
								<MuiTextAccountBoxHeader variant='inherit'>
									{parseInt(user?.onBehalfOf) === business.userId ? t('business.business.logout') : t('registration.links.login')}
								</MuiTextAccountBoxHeader>
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</BaseBox>
		</>
	);
};

export default OnBehalfOfLine;
