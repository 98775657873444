import React from 'react';
import { Alert } from '@mui/material';
import MuiHeader from './MuiText/MuiHeader';
import { useTranslation } from 'react-i18next';
import MuiTextAlert from './MuiText/MuiTextAlert';


const ScammerCard = () => {
    const { t } = useTranslation('translations');


    return (
        <Alert severity="error" >
            <MuiHeader>{t('scam.title')}</MuiHeader>
            <MuiTextAlert>
                <p>{t('scam.p1')}</p>
                <p>{t('scam.p2')}</p>
                <ul>
                    <li>compliance@dtsmoney.com</li>
                    <li>onboarding@dtsmoney.com</li>
                    <li>help@dtsmoney.com</li>
                </ul>
                <p>{t('scam.p3')}</p>
                <p>{t('scam.p4')}</p>
            </MuiTextAlert>
        </Alert>
    );
};

export default ScammerCard;
