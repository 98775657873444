import * as React from 'react';
import { Alert, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { runOperation, tranformSubProcessByType } from '@/features/operations/slice';
import { CardIntegrationEnum, CardSubProcesses } from '@/features/card/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { getCards } from '@/features/card/slice';
import { showException, showInfo, showSuccess } from '@/features/swal/slice';
import MuiTextAlert from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAlert';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';


type Props = {
	cardId?: number,
	onBack?: () => void,
	onClose?: () => void,
}

const CloseCardStep = ({ cardId, onBack, onClose }: Props) => {
	const { t } = useTranslation('translations');

	const dispatch = useDispatch();
	const allCards = useSelector((state: RootState) => state.cards.list);
	const selectedCard = allCards.find(p => p.cardId === cardId);
	const { list } = useSelector(
		(state: RootState) => state.accounts
	);

	const cardProcesses = tranformSubProcessByType(selectedCard.process.subProcesses);
	const isSH_Finance = selectedCard?.process?.integration === CardIntegrationEnum.SH_FINANCE;
	const operationCloseCard = cardProcesses[isSH_Finance ? CardSubProcesses.CARD_BLOCK : CardSubProcesses.CARD_BLOCK_AND_PULL_OUT]?.proc;


	const closeCard = async () => {

		const account = list.find(account => account.currency === selectedCard?.currency);
		if (!account) showException('can not find account by card currency');
		const payload: any = {
			cardId: selectedCard?.cardId,
			accountToId: account.accountId,
			details: `Card balance pullout to ${account.walletId} account.`,
			accountFromId: selectedCard?.accountId
		};
		const isNegative = selectedCard?.balanceOnHold < 0 || selectedCard?.availableBalance < 0;
		try {
			if (selectedCard?.balanceOnHold > 0 || isNegative) {
				await showInfo({ title: t('swal.INFO'), text: isNegative ? `${t('cards.terminateNegative')} ${selectedCard?.currency || ''}` : t('cards.terminateOnHold') });
				return;
			}
			const account = list?.length > 0 && list.find(account => account.currency === selectedCard?.currency);

			if (!account) throw new Error(t('cards.terminateCard.currencyError') + selectedCard?.currency);
			payload.accountToId = account.accountId;

			const result = await runOperation(operationCloseCard, payload);

			if (result?.value?.status === 'BLOCKED_BY_PARTNER') {
				await showSuccess({ text: t('cards.terminateCard.terminateApproval'), confirmBTNText: t('form.buttons.close') });
			}
			dispatch(getCards());
		}
		catch (e) {
			await showException(e);
		} finally {
			onClose();
		}
	};


	return (
		<Grid container alignContent='space-between' flex={1}>
			<Grid item container xs={12}>

				<Grid item xs={12} pb={4}>
					<FormHeader title={t('cards.cardSettings.menu.closeCard')} showBack={!!onBack} onBack={onBack} />
				</Grid>
				<Grid item xs={12} className='sepa-warning '>
					<Alert severity="warning"  >
						<MuiTextAlert  >
							{t('cards.cardSettingForm.closeCardWarning')}
						</MuiTextAlert>
					</Alert>
				</Grid>
				{isSH_Finance && <Grid item container xs={12} mt={3}>
					<Typography
						variant='highlight'
						fontSize='0.813rem'
						fontWeight='400'>{t('cards.terminateCard.terminateSH')}</Typography>
				</Grid>}
			</Grid>

			<Grid item container xs={12}>
				<Grid item xs={6}>
					<Button
						variant='customOutlined'
						onClick={onClose}
					>
						{t('form.buttons.close')}
					</Button>
				</Grid>
				{selectedCard &&
					<Grid item xs={6} display='flex' justifyContent='flex-end'>
						<Button
							variant='contained'
							color='primary'
							onClick={closeCard}
							disabled={!(operationCloseCard)}
						>
							{t('cards.cardSettingForm.closeCard')}
						</Button>
					</Grid>
				}

			</Grid>


		</Grid>
	);

};

export default CloseCardStep;
