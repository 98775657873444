import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@/store';
import request from '@services/request';
import {
	AssetType,
	CryptoChartPeriodEnum,
	CoinOhlcType,
	CoinPayinfo,
	CoinPayinfoPayload,
	CoinStore,
	CoinTradePayload,
	CoinType,
	CoinWithdrawalPayinfo,
	CoinWithdrawalPayinfoPayload,
	TradeSummary24h,
	WithdrawalMinAmount
} from './types';
import {
	TradeHistoryFilterParams,
	TradeHistoryType,
} from '../transactions/types';
import { tranformSubProcessByType } from '../operations/slice';
import { SocketService } from '@/services/socketService';
import { Subscription } from 'rxjs';
import { updateWalletTransactionsWithInitial } from '../walletHistory/slice';
import { isEmpty } from 'lodash';
import bigDecimal from 'js-big-decimal';
import { WalletTransactionType } from '@features/walletHistory/types';

const initialState: CoinStore = {
	portfolioBalance: 0,
	portfolioBalanceUsd: 0,
	portfolioBalanceGbp: 0,
	list: [],
	loading: false,
	error: null,
	fromAsset: null,
	toAsset: null,
	cryptoTradeUpdate: null,
	swaped: false
};

const coinsSlice = createSlice({
	name: 'coins',
	initialState,
	reducers: {

		setCoins(state, action: PayloadAction<CoinType[]>) {
			state.list = action.payload;
		},
		setPortfolioBalance(state, action: PayloadAction<number>) {
			state.portfolioBalance = action.payload;
		},
		setPortfolioBalanceUsd(state, action: PayloadAction<number>) {
			state.portfolioBalanceUsd = action.payload;
		},
		setPortfolioBalanceGbp(state, action: PayloadAction<number>) {
			state.portfolioBalanceGbp = action.payload;
		},
		setCoinLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<any>) => {
			state.error = payload;
		},
		setFromAsset(state, action: PayloadAction<AssetType>) {
			state.fromAsset = action.payload;
		},
		setToAsset(state, action: PayloadAction<AssetType>) {
			state.toAsset = action.payload;
		},
		setCryptoTradeUpdate(state, action: PayloadAction<any>) {
			state.cryptoTradeUpdate = action.payload;
		},
		setTradeSwapped(state, action: PayloadAction<boolean>) {
			state.swaped = action.payload;
		},
	}
});

export const { setCoins,
	setCoinLoading,
	setError,
	setFromAsset,
	setToAsset,
	setPortfolioBalance,
	setPortfolioBalanceUsd,
	setPortfolioBalanceGbp,
	setCryptoTradeUpdate,
	setTradeSwapped
} = coinsSlice.actions;


export const getCoins = (silentReload = false): AppThunk => {
	return async (dispatch) => {
		//const { coins } = state();
		//const { list } = coins;
		setError(null);
		if (!silentReload) {
			dispatch(setCoinLoading(true));
		}
		//const currentCoins = JSON.parse(JSON.stringify(list));

		try {
			const response = await request.get('/api/v3/tokens/v2/crypto');

			if (response === null) {
				setError('coin loading error');
			} else {
				const { data } = response;
				dispatch(setCoins(data.list));
				dispatch(setPortfolioBalance(data?.portfolioBalance ?? 0));
				dispatch(setPortfolioBalanceUsd(data?.portfolioBalanceUsd ?? 0));
				dispatch(setPortfolioBalanceGbp(data?.portfolioBalanceGbp ?? 0));
				return data;
			}
		} catch (e) {
			setError('coin loading error');
			console.log(e);
		} finally {
			dispatch(setCoinLoading(false));
		}
	};
};

export const updateCoins = (ids: any): AppThunk => {
	return async (dispatch, state) => {
		if (!ids || isEmpty(ids) || ids.toString() === '') return;

		const { coins } = state();
		const { list, fromAsset, toAsset } = coins;
		setError(null);
		const currentCoins = JSON.parse(JSON.stringify(list));
		const idArray = ids.split(';');
		const numArray = idArray.map(id => { return parseInt(id); });

		try {
			const response = await request.get('/api/v3/tokens/v2/crypto');

			const { data } = response;
			numArray.map(id => {
				const coin = data.list.find(c => c.account?.accountId === id);
				const coinIndex = list.findIndex(c => c.account?.accountId === id);

				if (coin && coinIndex >= 0) {
					currentCoins[coinIndex] = coin;
				}
			});


			data.list.forEach(coin => {
				const id = list.findIndex(p => p.type.concat(p.info.symbol) === coin.type.concat(coin.info.symbol));
				if (id < 0) {
					currentCoins.push(coin);
				}

				if (fromAsset && coin.info.symbol === fromAsset?.symbol && coin.type === fromAsset?.type) {
					const assetList = fromAsset?.type === 'WALLET' ? transformAccountToAssets([coin]) : transformTokensToAssets([coin]);
					const asset = assetList?.find(p => p.symbol === fromAsset?.symbol) || null;
					if (asset) {
						dispatch(setFromAsset(asset));
					}
				}

				if (toAsset && coin.info.symbol === toAsset?.symbol && coin.type === toAsset?.type) {
					const assetList = toAsset?.type === 'WALLET' ? transformAccountToAssets([coin]) : transformTokensToAssets([coin]);

					const asset = assetList?.find(p => p.symbol === toAsset?.symbol) || null;
					if (asset) {
						dispatch(setToAsset(asset));
					}
				}

			});
			dispatch(setCoins(currentCoins));
			dispatch(setPortfolioBalance(data?.portfolioBalance ?? 0));
			dispatch(setPortfolioBalanceUsd(data?.portfolioBalanceUsd ?? 0));
			dispatch(setPortfolioBalanceGbp(data?.portfolioBalanceGbp ?? 0));
			return data;
		} catch (e) {
			setError('coin loading error');
			console.log(e);
		} finally {
			dispatch(setCoinLoading(false));
		}
	};
};


export const postCryptoOrder = async (payload: CoinTradePayload) => {
	const response = await request.post('/api/v3/tokens/trade', payload);
	const { data } = response;
	return data;
};

export const allTokensList = async () => {
	const response = await request.get('/api/v3/tokens');
	const { data } = response;
	return data;
};

export const postCryptoPayinfo = async (payload: CoinPayinfoPayload): Promise<CoinPayinfo> => {
	const response = await request.post('/api/v3/tokens/pay-info', payload);
	const { data } = response;
	return data;
};

export const postCoinWithdrawalPayinfo = async (payload: CoinWithdrawalPayinfoPayload): Promise<CoinWithdrawalPayinfo> => {
	const response = await request.post('/api/tokens/v3/withdrawal/payinfo', payload);
	const { data } = response;
	return data;
};

export const validateWithdrawAddress = async (beneficiaryCryptoAddress: string, beneficiaryCryptoNetwork: string) => {
	const response = await request.get(`/api/v3/tokens/wallet-address/lookup?beneficiaryCryptoAddress=${beneficiaryCryptoAddress}&beneficiaryCryptoNetwork=${beneficiaryCryptoNetwork}`);
	const { data } = response;
	return data;
};


export const getTradeHistory = async (payload: TradeHistoryFilterParams): Promise<TradeHistoryType> => {
	const response = await request.get('/api/documents', { params: payload });
	const { data } = response;
	return data;
};

export const getWithdrawalMinAmount = async (ccy: string): Promise<WithdrawalMinAmount> => {
	const response = await request.get(`/api/v3/tokens/withdrawal/min-amount/${ccy}`);
	const { data } = response;
	return data;
};

export const transformTokensToAssets = (coinHoldings: CoinType[]): AssetType[] => {
	const coinAssets = coinHoldings?.map(i => {
		return ({
			id: i?.type.concat(i.info.symbol),
			type: i?.type,
			availableBalance: new bigDecimal(i?.account?.availableBalance ?? 0).round(i?.account?.precision ?? 5, bigDecimal.RoundingModes.DOWN).getValue(),
			name: i?.info.displayName,
			logo: i?.info.logo,
			symbol: i?.info.symbol,
			supportedCcy: [
				...(i?.info?.supportedCcy?.length > 0 ? i?.info?.supportedCcy : []),
			],
			sellEnabled: i?.info?.sellEnabled,
			buyEnabled: i?.info?.buyEnabled,
			precision: i?.account?.precision,
			accountId: i?.account?.accountId,
			networks: i?.type === 'TOKEN' ? i?.networksArray : i?.account?.accountAddresses.map(p => p.network),
			transferProc: tranformSubProcessByType(i?.subProcesses)['OWN_TRANSFER']?.proc || null,
			transferProcess: tranformSubProcessByType(i?.subProcesses)['OWN_TRANSFER'] || null,
			paymentProc: tranformSubProcessByType(i?.subProcesses)['PAYMENT']?.proc || null,
			paymentProcess: tranformSubProcessByType(i?.subProcesses)['PAYMENT'] || null,
			miniumTradeAmount: i?.info?.minimumTradeAmount ?? '0',
			exchange: i?.info?.exchange,
			displayName: i?.info?.displayName,
			minSide: i?.info?.minSide,
			defiToken: i?.account?.type === 'CRYPTO_TOKEN',
		} as AssetType);
	});

	return coinAssets;
};



export const transformAccountToAssets = (coinHoldings: CoinType[]): AssetType[] => {

	const coinAssets = coinHoldings?.map(i => {
		return ({
			id: i?.account?.accountId?.toString(),
			type: i.type,
			availableBalance: new bigDecimal(i?.account?.availableBalance ?? 0).round(i?.account?.precision ?? 5, bigDecimal.RoundingModes.DOWN).getValue(),
			name: i.info.displayName,
			logo: i.info.logo,
			symbol: i.info.symbol,
			supportedCcy: [
				...(i?.info?.supportedCcy?.length > 0 ? i?.info?.supportedCcy : []),
			],
			sellEnabled: true,
			buyEnabled: true,
			precision: 2,
			accountId: i?.account?.accountId,
			networks: [],
			miniumTradeAmount: i?.info?.minimumTradeAmount ?? '0',
			transferProc: tranformSubProcessByType(i?.subProcesses)['OWN_TRANSFER']?.proc || null,
			transferProcess: tranformSubProcessByType(i?.subProcesses)['OWN_TRANSFER'] || null,
			paymentProc: tranformSubProcessByType(i?.subProcesses)['PAYMENT']?.proc || null,
			paymentProcess: tranformSubProcessByType(i?.subProcesses)['PAYMENT'] || null,
			exchange: null,
			displayName: i.info.displayName,
			minSide: i.info.minSide
		} as AssetType);
	});
	return coinAssets;
};


export const getPairOhlc = async (
	pair: string,
	period: CryptoChartPeriodEnum
): Promise<CoinOhlcType[]> => {
	const response = await request.get(
		`/api/v3/tokens/price/ohlc/${pair}/${period}`
	);
	const { data } = response;
	return data;
};

export const getPairSummary = async (pair: string): Promise<TradeSummary24h> => {
	const response = await request.get(
		`/api/v3/tokens/price/summary/${pair}`
	);
	const { data } = response;
	return data;
};

let socketService: SocketService;
let walletsSubscriber: Subscription;
let tradeSubscriber: Subscription;
let depositSubscriber: Subscription;
let transactionsSubscriber: Subscription;
let withdrawalSubscriber: Subscription;


export const connectCryptoSocket = (): void => {
	if (!socketService) {
		socketService = new SocketService('wallet');
	}
};

export const subscribeCryptoSocket = (): AppThunk => {
	return async dispatch => {
		try {
			walletsSubscriber = socketService.listen('wallet.accountsId', {}).subscribe((data) => {
				dispatch(updateCoins(data));
				dispatch(setCryptoTradeUpdate(data));
			});
			tradeSubscriber = socketService.listen('wallet.transactions', {}).subscribe((data) => {
				if (data === 'updated') {
					dispatch(updateWalletTransactionsWithInitial(WalletTransactionType.TRANSACTIONS));
				}
			});
			depositSubscriber = socketService.listen('wallet.deposit', {}).subscribe((data) => {
				if (data === 'updated') {
					dispatch(updateWalletTransactionsWithInitial(WalletTransactionType.CRYPTO_DEPOSITS));
				}
			});
			transactionsSubscriber = socketService.listen('wallet.trade', {}).subscribe((data) => {
				if (data === 'updated') {
					dispatch(updateWalletTransactionsWithInitial(WalletTransactionType.CRYPTO_TRADES));
				}
			});
			withdrawalSubscriber = socketService.listen('wallet.withdrawal', {}).subscribe((data) => {
				if (data === 'updated') {
					dispatch(updateWalletTransactionsWithInitial(WalletTransactionType.CRYPTO_WITHDRAWALS));
				}
			});
		} catch (e) {
			console.log(e);
			dispatch(setError(e));
		}
	};
};

export const unsubscribeCryptoSocket = (): void => {
	walletsSubscriber.unsubscribe();
	tradeSubscriber.unsubscribe();
	depositSubscriber.unsubscribe();
	transactionsSubscriber.unsubscribe();
	withdrawalSubscriber.unsubscribe();
};


export default coinsSlice.reducer;


