import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import MuiFormLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiFormLabel';
import EnableNeuralForm from './EnableNeuralForm';

const EnableNeuralSettings = (): React.ReactElement => {

	const { t } = useTranslation('translations');
	const [open, setOpen] = useState(false);
	//const { neuralid } = useSelector((state: RootState) => state.userNeuralid);

	const closeModal = () => {
		setOpen(false);
	};

	const openModal = () => {
		setOpen(true);
	};

	return (
		<>
			{open && <EnableNeuralForm open={open} onClose={closeModal} />}
			<Grid container alignItems='center' rowGap={2} sx={{ backgroundColor: '#1D2024', padding: '30px', borderRadius: '10px' }}>
				<Grid item xs={12} sm={8}>
					<MuiTextCaption fontSize='1.125rem'>{t('security.options.enableNeural')}</MuiTextCaption>
					<MuiFormLabel color='text.secondary' fontSize='0.875rem'>{t('security.options.enableNeural')}</MuiFormLabel>
				</Grid>
				<Grid item xs={12} sm={4} container sx={{ justifyContent: { xs: 'flex-start', sm: 'flex-end' } }}>
					<Button variant='login'
						sx={{ minWidth: '12rem' }}
						//	disabled={!!neuralid}
						onClick={openModal}>{t('security.options.verificationAction')}</Button>
				</Grid> 
			</Grid>
		</>
	);
};

export default EnableNeuralSettings;
