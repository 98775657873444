
import * as React from 'react';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import { Card } from '@/features/card/types';
import { Button, Grid, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CardImage from '../CardImage';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { alpha3ToAlpha2, getNames } from 'i18n-iso-countries';
import StyledStepper from '@/componentsMui/Shared/Widgets/StyledStepper';
import { activateCard, isReplaceable } from '@/componentsMui/Banking/utils';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

interface Props {
    card: Card,
    activateProc: string
}

const CardInactive = ({ card, activateProc }: Props) => {
    const { t } = useTranslation('translations');
    const history = useHistory();
    const countries = getNames('en');

    const isSh = card.process.integration === 'SH_FINANCE';
    const isReplaceEnabled = isReplaceable(card);

    const handleActivate = async () => {
        const needVfCode = (isSh && card.status === 'INACTIVE');
        await activateCard(card?.cardId,  activateProc, card?.virtual, history, needVfCode, card.status);
    };

    const handleReplace = (event) => {
        event.stopPropagation();
        history.push(`/wallet/home/cards/replace?card=${card.cardId}`);
    };

    return (
        <Grid container sx={{ width: { xs: '100%', sm: '50%', md: '30%' } }} rowSpacing={2} marginLeft='auto' marginRight='auto'>

            {!card.virtual && <Grid container>
                {!isSh &&
                    <Grid item xs={12} pb={3} pt={3}>
                        <StyledStepper steps={[t('cards.cardInactive.ordered'), t('cards.cardInactive.onTheWay'), t('cards.cardInactive.delivered')]} activeStep={1} />
                    </Grid>
                }
                {card.deliveryAddress && <>
                    <Grid item xs={12} justifyContent='center' display='flex'>
                        <MuiTextCaption fontWeight='400'>{t('cards.cardInactive.deliveryAddress')}</MuiTextCaption>
                    </Grid>
                    <Grid item xs={12} justifyContent='center' display='flex' pb={3}>
                        <MuiTextCaption fontWeight='400' >{`${card.deliveryAddress.address}, ${card.deliveryAddress.city}, ${card.deliveryAddress.zip}, ${countries[alpha3ToAlpha2(card.deliveryAddress.country)]}`}</MuiTextCaption>
                    </Grid>
                </>}

                {card.status == 'INACTIVE' && card?.trackingNumber && <>
                    <Grid item xs={12} justifyContent='center' display='flex'>
                        <MuiTextCaption fontWeight='400'>{t('cards.cardInactive.trackingCode')}</MuiTextCaption>
                    </Grid>
                    <Grid item xs={12} justifyContent='center' display='flex' pb={3}>
                        <Link href={`https://parcelsapp.com/en/tracking/${card.trackingNumber}`}
                            target="_blank" rel="noopener noreferrer"
                            width='100%'
                            sx={{ textDecorationLine: 'underline' }} >
                            <MuiTextCaption fontWeight='400' textAlign='center'>{`${card.trackingNumber}`}</MuiTextCaption>
                        </Link>
                    </Grid>
                </>}
            </Grid>}

            <Grid item xs={12}>
                <CardImage card={card} padding='7px' borderRadius='10px' border={true} />
            </Grid>
            <Grid item xs={12}>
                <Button
                    fullWidth
                    variant='contained'
                    onClick={handleActivate}
                    startIcon={<CreditCardIcon />}

                >
                    {t('cards.cardActivation.activateBTN')}
                </Button>
            </Grid>
            {isReplaceEnabled &&
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        variant='simple'
                        onClick={handleReplace}
                        startIcon={<RestartAltIcon />}
                    >
                        {t('cards.replace.replace')}
                    </Button>
                </Grid>}


        </Grid>


    );
};

export default CardInactive;

