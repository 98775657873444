import * as React from 'react';
import { Box, SvgIcon } from '@mui/material';

const RoundChekmark = (props) => (
	<Box
		sx={() => ({
			background: '#40DC1A4D',
			borderRadius: '50%',
			width: '42px',
			height: '42px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		})}
	>
		<SvgIcon {...props} >
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M4 12.0001L8.94975 16.9499L19.5563 6.34326"
					stroke="#40DC1A" strokeWidth="2"
					opacity={1}
					strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		</SvgIcon>
	</Box>
);

export default RoundChekmark;
