import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { connectCryptoSocket, getCoins, subscribeCryptoSocket, unsubscribeCryptoSocket } from '@features/coins/slice';
import { connect, subscribe, unsubscribe } from '@features/account/slice';
import Portfolio from '@/componentsMui/Wallet/Portfolio';
import LoadingPageMui from '@/pages/LoadingPageMui';
import AccountBeingCreated from '../Banking/Account/AccountBeingCreated';
import { AccountType } from '@/features/operations/types';
import EmptyProductCard from '../Shared/Widgets/EmptyProductCard';
import { useTranslation } from 'react-i18next';

const Crypto = (): React.ReactElement => {

	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const { loading, list, error } = useSelector(
		(state: RootState) => state.coins
	);
	const allAccounts = useSelector((state: RootState) => state.accounts.list);
	const walletAccount = allAccounts.find(p => p.type === AccountType.WALLET);

	useEffect(() => {
		dispatch(getCoins());
	}, [dispatch]);

	//subscribe to wallet socket
	useEffect(() => {
		connectCryptoSocket();
		dispatch(subscribeCryptoSocket());
		return () => {
			unsubscribeCryptoSocket();
		};
	}, [dispatch]);

	useEffect(() => {
		connect();
		dispatch(subscribe());
		return () => {
			unsubscribe();
		};
	}, [dispatch]);

	if (error) {
		return (<EmptyProductCard
			title={t('errors.SERVICE_UNAVAILABLE')}
			subTitle={t('errors.SERVICE_UNAVAILABLE_SUBTITLE')}
		/>);
	}

	if (loading) {
		return <LoadingPageMui />;
	}

	if ([list]?.length === 0 && !loading && !walletAccount) {
		return <AccountBeingCreated />;
	}

	return (
		<div className="scroll-container" >
			<Portfolio />
		</div>
	);
};

export default Crypto;
