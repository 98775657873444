import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { connectTokenSocket, getTokens, subscribeTokensSocket, unsubscribeTokensSocket } from '@features/tokens/slice';
import { connect, subscribe, unsubscribe } from '@features/account/slice';
import TokenList from '@/componentsMui/Wallet/Tokens/TokenList';
import LoadingPageMui from '@/pages/LoadingPageMui';
import { Grid } from '@mui/material';
import PDFViewer from '@shared/Components/PDFViewer';
import { AccountType } from '@features/operations/types';
import TransactionGridFull from '@/componentsMui/TransactionsGrid/TransactionGridFull';

export enum TokenTab {
	LIST = 'list',
	TRANSACTIONS = 'transactions',
	TERMS = 'terms',
}

const Tokens = (): React.ReactElement => {

	const dispatch = useDispatch();
	const searchParams = new URLSearchParams(location.search);
	const tab = searchParams.get('tab');
	const currentTab = tab ? tab : TokenTab.LIST;
	const pageScroll = currentTab === TokenTab.LIST || currentTab === TokenTab.TERMS;
	const walletCurrency = 'EUR';

	const { loading } = useSelector(
		(state: RootState) => state.tokens
	);

	useEffect(() => {
		dispatch(getTokens());
	}, [dispatch]);

	useEffect(() => {
		connectTokenSocket();
		dispatch(subscribeTokensSocket());
		return () => {
			unsubscribeTokensSocket();
		};
	}, [dispatch]);

	useEffect(() => {
		connect();
		dispatch(subscribe());
		return () => {
			unsubscribe();
		};
	}, [dispatch]);




	if (loading) {
		return <LoadingPageMui />;
	}

	return (
		<Grid id='wallet-portfolio' container className={pageScroll ? '' : 'scroll-grid-container'}>
			<Grid container className={pageScroll ? '' : 'scroll-grid-container'}>
				{currentTab === TokenTab.LIST && <TokenList currency={walletCurrency} />}
				{currentTab === TokenTab.TERMS && <PDFViewer file='https://abcmob-public2.s3.eu-central-1.amazonaws.com/ctest/sample.pdf' />}
				{currentTab === TokenTab.TRANSACTIONS && <TransactionGridFull type={AccountType.TOKEN} />}
			</Grid>
		</Grid>
	);
};

export default Tokens;
