import React from 'react';
import IntroCard from '@/assets/IntroCard.png';
import {Box, useTheme} from '@mui/material';
import './WelcomeCardThemed.css';


const WelcomeCardThemed = (props) => {
	const theme = useTheme();
	return <Box className="welcome-card" sx={{'&::after': {
		background: theme.auth.welcomeCardBg
	}}}>
		<img alt="Welcome card"
			src={IntroCard}
			{...props}
		/>
	</Box>;
};


/*
return (theme.palette.mode === 'dark' ? <object type="image/svg+xml" data={logoDark}  {...props} /> :
		<object type="image/svg+xml" data={logoLight}  {...props} />
	);
*/
export default WelcomeCardThemed;
