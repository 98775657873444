import { Box, Grid } from '@mui/material';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import { formatDate } from '@/helpers/date';
import { TransactionStatus } from '@/features/transactions/types';
import { isFiat } from '@/helpers/currency';
import { useTranslation } from 'react-i18next';
import { roundFlexible } from '@/helpers/round';
import TransactionStatusBox from '@/componentsMui/Shared/Widgets/Box/TransactionStatusBox';
import { getCurrencySign } from '@/features/fees/slice';
import { Transaction } from '@/componentsMui/Transactions/TransactionStatements.spec';
import MuiTextTrxSmall from '@/componentsMui/Shared/Widgets/MuiText/MuiTextTrxSmall';

interface Props {
	item: Transaction,
	openTransactionDetailsForm: (trx: Transaction) => void,
	enablePointer: (trx: Transaction) => boolean
}
const WalletHistoryLine = ({ item, openTransactionDetailsForm, enablePointer }: Props) => {
	const { t } = useTranslation('translations');
	const isFiatAsset = isFiat(item.asset);
	return (
		<Box
			onClick={() => openTransactionDetailsForm(item)}
			style={{ cursor: enablePointer(item) ? 'pointer' : 'default' }}
			pb='1rem' pt='1rem'
		>
			<Grid container alignItems='center'>
				<Grid item xs={3} >
					<MuiTextTrxSmall>
						{item?.messageCode ? t('activity.' + item.messageCode) :
							t('activity.' + item.activity)}
					</MuiTextTrxSmall>
				</Grid>
				<Grid item xs={3}>
					<MuiTextTrxSmall color='text.secondary'>
						{formatDate(item.date, 'T')}
					</MuiTextTrxSmall>
				</Grid>
				<Grid item xs={3}>
					<MuiTextTrxSmall textAlign='right'
						sx={theme => ({ color: item.amount < 0 ? theme.statusColor.error : theme.statusColor.success })}>
						{item.amount && (item.amount < 0 ? '- ' + (item?.currencySymbol ?? getCurrencySign(item.asset)) : item?.currencySymbol ?? getCurrencySign(item.asset) + ' ')}

						{isFiatAsset ?
							<NumberFormat displayType={'text'}
								decimalScale={2}
								fixedDecimalScale={true}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								value={Math.abs(item.amount)} /> :
							roundFlexible(Math.abs(item.amount))
						}

					</MuiTextTrxSmall>
				</Grid>
				<Grid item xs={3} display='flex' justifyContent='flex-end'>
					<TransactionStatusBox
						status={item.status as TransactionStatus}>
						{t('status.' + item.status)}
					</TransactionStatusBox>
				</Grid>
			</Grid >
		</Box>
	);
};

export default WalletHistoryLine;
