import FormContentBox from '@/componentsMui/Shared/Widgets/Box/FormContentBox';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import MuiSubHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiSubHeader';
import MuiTextAlert from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAlert';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import { BankAccount } from '@/features/account/types';
import useCloseDialog from '@/helpers/customHook/useCloseDialog';
import { Alert, Button, Grid } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './SepaTransfer.css';
import { WalletDepositAction } from '@/features/coins/types';
import AccountDetails from '../../Account/AccountDetails';
import TrustAccountDetailsEUR from '../../Trust/Components/TrustAccountDetailsEUR';
import TrustAccountDetailsUSD from '../../Trust/Components/TrustAccountDetailsUSD';
import TrustAccountDetailsGBP from '../../Trust/Components/TrustAccountDetailsGBP';

interface Props {
	account: BankAccount,
	type: string,
	prevStep?: () => void,
}

const SepaTransfer = ({ account, type, prevStep }: Props) => {
	const { t } = useTranslation('translations');
	const closeModal = useCloseDialog();

	const depositType = type?.toUpperCase();
	return (
		<div className='form-content__wrap' >
			<FormContentBox noBackground={true} className='form-content_main-area wp' >
				< Grid id='sepa-confirm-step'
					container>
					<Grid item xs={12} >
						<FormHeader title={t('wallet.deposit.' + type)} showBack={!!prevStep} onBack={prevStep} />
						<MuiFormText>  {t('banking.addMoney.sepaForm.title', { type: t(`banking.paymentType.${depositType}`) })}  </MuiFormText>
					</Grid>
					<Grid item xs={12} className='sepa-section'>
						<MuiSubHeader>  {t('banking.addMoney.sepaForm.toTitle')}  </MuiSubHeader>
					</Grid>
					<Grid item xs={12}>
						{(account?.type === 'BANK' || account?.type === 'BANK_AND_CARD') && <AccountDetails account={account} />}
						{account?.type === 'TRUST' && depositType === WalletDepositAction.SEPA && <TrustAccountDetailsEUR account={account} />}
						{account?.type === 'TRUST' && depositType === WalletDepositAction.SWIFT && <TrustAccountDetailsUSD account={account} />}
						{account?.type === 'TRUST' && depositType === WalletDepositAction.CHAPS && <TrustAccountDetailsGBP account={account} />}
					</Grid>
					<Grid item xs={12} className='sepa-warning '>
						<Alert severity="warning"  >
							<MuiTextAlert  >
								{t('banking.addMoney.sepaForm.accountWarning')}
							</MuiTextAlert>
						</Alert>
					</Grid>
					<Grid item xs={12} className='sepa-warning '>
						<Alert severity="warning"  >
							<MuiTextAlert  >
								{t('banking.addMoney.sepaForm.euroBankWaring', { currency: account?.currency })}
							</MuiTextAlert>
						</Alert>
					</Grid>
				</Grid>
				<Grid container pt='2rem' mt='auto'>
					<Grid item xs={6}>
						<Button
							className='button-left__wm'
							variant='customOutlined'
							color='secondary'
							onClick={closeModal}
						>  {t('form.buttons.close')}
						</Button>
					</Grid>
				</Grid>
			</FormContentBox>
		</div>
	);
};

export default SepaTransfer;
