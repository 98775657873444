
import { getWithdrawalMinAmount } from '@/features/coins/slice';
import { WithdrawalMinAmount } from '@/features/coins/types';
import { useCallback, useEffect, useState } from 'react';

const useWithdrawalMinAmount = (currency: string | null | undefined) => {

  const [minAmount, setMinAmount] = useState<WithdrawalMinAmount>();
  const [loadingMinAmount, setLoadingMinAmount] = useState<boolean>(true);

  const isMinAmountAvailable = minAmount !== undefined && minAmount !== null;

  const fetchMinAmount = useCallback(() => {
    const get = async () => {
      setLoadingMinAmount(true);
      try {
        if (currency) {
          const data = await getWithdrawalMinAmount(currency);
          setMinAmount(data);
        } else {
          setMinAmount(undefined);
        }
      } catch (e) {
        setMinAmount(undefined);
      }
      finally { setLoadingMinAmount(false); }
    };
    get();
  }, [currency]);

  useEffect(() => {
    fetchMinAmount();
  }, [fetchMinAmount]);




  return {
    minAmount,
    loadingMinAmount,
    isMinAmountAvailable
  };

};

export default useWithdrawalMinAmount;
