import * as React from 'react';
import { Autocomplete } from '@mui/material'; 
import { useTranslation } from 'react-i18next';
import MuiTextField from './MuiTextField';
import { useField } from 'formik';
import StyledPaper from '@/componentsMui/Shared/Widgets/StyledPaper';
import usStates from './usstates.json';

interface Props {
	name?: string
	fieldLabel?: string,
	onDropdownChange?: (value: any) => void,
}

const UsaStateList = ({ name, fieldLabel, onDropdownChange }: Props) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField(name);
	const { setValue } = helpers;
	const { value } = meta;
	const [stateValue, setStateValue] = React.useState<string | null>(value);

	const { t } = useTranslation(['translations']);
	const stateOptions = usStates?.map(p => { return ({ label: p.name, code: p.abbreviation }); });


	const handleStateChange = (event: any, newValue: string | null) => {
		setStateValue(newValue);
		onDropdownChange && onDropdownChange(newValue);
	};

	const handleOnBlur = () => {
		setValue(stateValue);
	};
	return (<Autocomplete
		id='state-list'
		options={stateOptions}
		value={value}
		onBlur={handleOnBlur}
		onChange={handleStateChange}
		disableClearable
		PaperComponent={({ children }) => (
			<StyledPaper>{children}</StyledPaper>
		)}
		renderInput={(params) => <MuiTextField name={name} {...params} label={fieldLabel ?? t('form.fields.state')}
		/>}

	/>);
};


export default UsaStateList;
