import * as React from 'react';
import { Autocomplete } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiTextField from '../../../Shared/FormikComponents/MuiTextField';
import { useField } from 'formik';
import StyledPaper from '@/componentsMui/Shared/Widgets/StyledPaper';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';

interface Props {
	integration: string,
	name?: string
	disabled?: boolean
}

const CountryList = ({ integration, name, disabled }: Props) => {
	const { t } = useTranslation(['translations']);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField(name);
	const { setValue } = helpers;
	const { value } = meta;
	const cardShipmentCountry = useSelector((state: RootState) => state.allowedCountries.cardShipmentCountry);
	const integrationCountries = cardShipmentCountry.filter(p => p.integration === (integration ?? ''));
	const countryOptions = integrationCountries.map(p => p.name);

	const handleCountryChange = (event: any, newValue: string | null) => {
		setValue(newValue);
	};

	React.useEffect(() => {
		if (value && !countryOptions.includes(value)) {
			setValue('');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [integration]);

	return (<Autocomplete
		id='country-list'
		disabled={disabled}
		isOptionEqualToValue={(option, value) => option === value}
		options={countryOptions}
		value={value}
		onChange={handleCountryChange}
		disableClearable
		PaperComponent={({ children }) => (
			<StyledPaper>{children}</StyledPaper>
		)}
		renderInput={(params) => <MuiTextField name={name} {...params} label={t('form.fields.country')}
		/>}

	/>);
};


export default CountryList;
