import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Credentials, TokenGrantTypeEnum } from './types';
const credentialsKey = process.env.REACT_APP_CREDENTIALS_KEY;

const savedCredentials = sessionStorage.getItem(process.env.REACT_APP_CREDENTIALS_KEY);

const initialState: Credentials = { //email: null,
	token: null,
	tokenGrantType: undefined,
};
if (savedCredentials) {
	try {

		const credentials = JSON.parse(savedCredentials);
		//initialState.email = credentials.email;
		initialState.token = credentials.token;
		initialState.tokenGrantType = credentials.tokenGrantType;
	}
	catch (e) {
		console.log(e);
	}
}

const credentialsSlice = createSlice({
	name: 'credentials',
	initialState,
	reducers: {
		setCreditials(state, action: PayloadAction<Credentials>) {
			//state.email = action.payload.email;
			state.token = action.payload.token;
			state.tokenGrantType = action.payload.tokenGrantType || TokenGrantTypeEnum.PORTAL;
			sessionStorage.setItem(credentialsKey, JSON.stringify({
				//email: action.payload.email, 
				token: action.payload.token,
				tokenGrantType: action.payload.tokenGrantType,
			}));
		},
		clearCreditials(state,) {
			//state.email = null;
			state.token = null;
			state.tokenGrantType = undefined;
			sessionStorage.removeItem(credentialsKey);
		}
	}
});

export const { setCreditials, clearCreditials } = credentialsSlice.actions;

export const credentialsSelector = (state: { credentialsStore: Credentials }) =>
	state.credentialsStore;

export default credentialsSlice.reducer;
