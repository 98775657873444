import React from 'react';
import { Card, CardContent, Grid, Theme, Typography } from '@mui/material';
import MuiFormField from '@/componentsMui/Shared/Widgets/MuiText/MuiFormField';
import { useTranslation } from 'react-i18next';
import { HelpTopicsEnum } from './HelpTopics';
import { makeStyles, useTheme } from '@mui/styles';

import CardIcon from '@/componentsMui/Shared/CustomIcons/CardIcon';
import CryptoIcon from '@customIcons/CryptoIcon';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import AccountIcon from '@/componentsMui/Shared/CustomIcons/AccountIcon';
import BitcoinIcon from '@customIcons/BitcoinIcon';

interface Props {
	topic: HelpTopicsEnum,
	content?: string,
	iconWidth?: string,
	onSelect?: (value: HelpTopicsEnum) => void
}

const useStyles = props => makeStyles((theme: Theme) => ({
	iconWithStoke: {
		stroke: 'url(#svgIconGradient) ' + theme.highlight,
		fill: 'url(#svgIconGradient) ' + theme.highlight,
		width: props.iconWidth ?? '4rem',
		height: 'auto',
		strokeWidth: '1.4'
	},
	iconNoStroke: {
		fill: 'url(#svgIconGradient) ' + theme.highlight,
		width: props.iconWidth ?? '4rem',
		height: 'auto',
		strokeWidth: '1.4'
	},
}));


const getTopicIcon = (iconClasses: any, topic: HelpTopicsEnum) => {

	switch (topic) {
		case HelpTopicsEnum.ACCOUNT:
			return <AccountIcon classes={{ root: iconClasses.iconWithStoke }} />;
		case HelpTopicsEnum.CARD:
			return <CardIcon classes={{ root: iconClasses.iconWithStoke }} />;
		case HelpTopicsEnum.CRYPTO:
			return <BitcoinIcon classes={{ root: iconClasses.iconNoStroke }} />;
		case HelpTopicsEnum.ADD_MONEY:
			return <CryptoIcon classes={{ root: iconClasses.iconNoStroke }} />;
		case HelpTopicsEnum.SECURITY:
			return <GppGoodOutlinedIcon classes={{ root: iconClasses.iconNoStroke }} />;
		case HelpTopicsEnum.TRANSFERS:
			return <SyncAltIcon classes={{ root: iconClasses.iconNoStroke }} />;
		default:
			return <AccountIcon classes={{ root: iconClasses.iconWithStoke }} />;
	}
};

const TopicCard = ({ topic, content, iconWidth, onSelect }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const theme = useTheme() as Theme;
	const iconClasses = useStyles({ theme, iconWidth })();

	const handleSelect = () => {
		onSelect && onSelect(topic);
	};

	return (
		<Card
		className='help-topic-card'
		sx={theme => ({ background: theme.backgroundBoxLevel1, height: '100%', cursor: 'pointer' })}
			onClick={handleSelect}>
			<CardContent className='help-topic-card-content' sx={{ padding: '42px' }}>
				<Grid container height='100%'>
					<Grid item xs={12}>
						<Typography className='help-topic-icon' >{getTopicIcon(iconClasses, topic)}</Typography>
					</Grid>
					<Grid item xs={12} className='help-topic-title-container'>
						<Typography mt={2} mb={2}
							className='help-topic-title'
							fontSize='1rem'
							fontWeight='700'
							color='text.primary'
							whiteSpace='pre-line'>
							{t('faq.subjects.' + topic)}
						</Typography>
					</Grid>
					{content &&
						<Grid item xs={12}>
							<MuiFormField fontWeight={400}>
								{content}
							</MuiFormField>
						</Grid>}
				</Grid>
			</CardContent>
		</Card>
	);
};

export default TopicCard;
