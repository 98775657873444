
import * as React from 'react';
import { Box as MuiBox, BoxProps, Theme, Typography } from '@mui/material';
import { FC } from 'react';
import { getThemeStatusColor } from '@/helpers/transactionUtils';
import { TransactionStatus } from '@/features/transactions/types';
import { useTheme } from '@mui/styles';

interface Props extends BoxProps {
	sumbitting?: boolean,
	isFxCancel?: boolean,
	status?: TransactionStatus
}


const TransactionStatusBox: FC<Props> = (props: Props) => {

	const { sumbitting, isFxCancel, status, fontSize, ...others } = props;
	const theme = useTheme() as Theme;

	const statusColor = sumbitting ? theme.palette.text.disabled : (isFxCancel ? theme.statusColor.error : getThemeStatusColor(status, theme));

	return (<MuiBox
		sx={{
			borderRadius: '10px',
			background: `${statusColor}14`,
			padding: '0px 10px',
			width: 'fit-content',
			minWidth: '4rem',
			height: '1.5rem',
			cursor: isFxCancel ? 'pointer' : 'default',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		}}
		{...others}>
		<Typography
			fontWeight={others?.fontWeight ? others.fontWeight : 700}
			fontSize={fontSize ? fontSize : '0.563rem'}
			sx={{ color: statusColor }} >
			{props.children}
		</Typography>
	</MuiBox>);
};


export default TransactionStatusBox;
