import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiSubHeader from '../Shared/Widgets/MuiText/MuiSubHeader';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getDectaCardTransactionsOnhold } from '@/features/transactions/slice';
import TrxGenericError from './Components/TrxGenericError';
import { BankAccount } from '@/features/account/types';

interface Props {
    account: BankAccount,
    columns: GridColDef<any>[],
    handleOnCellClick: GridEventListener<'cellClick'>
}

const CardTransactionsOnhold = ({ account, columns, handleOnCellClick }: Props) => {

    const { t } = useTranslation('translations');
    const [onHoldTransactions, setOnHoldTransactions] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState<boolean>(false);
    const accountId = account?.accountId;

    const fetchOnHold = useCallback(async () => {
        try {
            if (!accountId) return;
            setLoadingError(false);
            setLoading(true);
            const result = await (getDectaCardTransactionsOnhold(accountId));
            setOnHoldTransactions(result?.list);
        }
        catch (e) {
            setLoadingError(true);
            setOnHoldTransactions([]);
        }
        finally { setLoading(false); }

    }, [accountId]);


    useEffect(() => {
        fetchOnHold();
    }, [fetchOnHold]);

    if (!loadingError && onHoldTransactions.length === 0) return <></>;

    return (
        <Accordion sx={{ pt: 2, pb: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}
                sx={theme => ({ background: theme.backgroundBoxLevel2 })}>
                <MuiSubHeader>{t('transaction.header.onHold')}</MuiSubHeader>
            </AccordionSummary>
            <AccordionDetails sx={{
                padding: 0, mb: 1, boxShadow: 'unset'
            }
            }>
                {loadingError ?
                    <TrxGenericError onButtonClick={fetchOnHold} /> :
                    <DataGrid
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 5, page: 0 },
                            },
                        }}
                        hideFooter={onHoldTransactions?.length <= 5}
                        autoHeight={true}
                        loading={loading}
                        rows={onHoldTransactions}
                        rowCount={onHoldTransactions?.length}
                        getRowId={(row) => `${row.transactionNumber}`}
                        onCellClick={handleOnCellClick}
                        disableRowSelectionOnClick={true}
                        columns={columns}
                        sx={{
                            // pointer cursor on ALL rows
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer'
                            }
                        }}
                    />}
            </AccordionDetails>
        </Accordion>);
};

export default CardTransactionsOnhold;

