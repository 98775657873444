import React from 'react';

import { Grid } from '@mui/material';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';


interface Props {
	text: string
}

const MobileHeader = ({ text }: Props) => {
	return (
		<Grid item xs={12} mt={{ xs: 1, sm: 2 }} mb={{ xs: 2, sm: 3 }} sx={{ display: { xs: 'block', sm: 'none' } }}
			textAlign='center'>
			<MuiHeader>{text}</MuiHeader>
		</Grid>
	);
};

export default MobileHeader;
