import { Button, Dialog, DialogContent, Grid, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg'; 
import MuiFormText from '../../Shared/Widgets/MuiText/MuiFormText';
import { postFile, postTransactionAttachments } from '@/features/files/slice';
import { showException, showSuccess } from '@/features/swal/slice';
import FileDrop from '@/componentsMui/Shared/Components/FileUpload/FileDrop';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader'; 
import { Transaction } from '../TransactionStatements.spec';

interface Props {
	open: boolean,
	transaction: Transaction,
	onClose: () => void,
	refreshTransactions: React.Dispatch<React.SetStateAction<Date>>
}

const AddReceiptForm = ({ open, transaction, onClose, refreshTransactions }: Props) => {
	const { t } = useTranslation('translations');
	const [receipt, setReceipt] = useState(null);

	const onSave = () => {
		const post = async () => {
			try {
				const attachments: Map<string, string> = new Map<string, string>();
				if (receipt) {
					const formData = new FormData();
					formData.append('file', receipt);
					const fileRecord = await postFile(formData);
					const { key } = fileRecord;
					attachments.set(key, receipt.path);
				}
				const payload = {
					attachments: Object.fromEntries(attachments)
				};
				await postTransactionAttachments(payload, transaction.transactionNumber);
				showSuccess({ title: t('swal.SUCCESS'), mui: true, confirmBTNText: t('form.buttons.close') });
				refreshTransactions(new Date());
			} catch (e) {
				showException(e);
			} finally {
				onClose();
			}
		};
		post();
	};

	return (

		<Dialog
			id="send-trx-details"
			open={open}
			fullWidth
			maxWidth="xs"
		>
			<DialogContent>
				<Grid container padding='40px'>
					<Grid container justifyContent="space-between" alignItems="center" >
						<Grid item>
							<MuiHeader>{t('transactiondetails.BTN.addReceipt')}</MuiHeader>
						</Grid>
						<Grid item>
							<IconButton onClick={onClose}  >
								<MuiCloseIcon />
							</IconButton>
						</Grid>
						<Grid item xs={12} mt={1}>
							<MuiFormText>{t('transactiondetails.message.addReceipt')}</MuiFormText></Grid>
					</Grid>
					<Grid container justifyContent="center" >
						<FileDrop setFile={setReceipt} fileTypes="application/pdf" /> 
						<Grid item xs={10} mt={7} >
							<Button
								type="submit"
								variant="contained"
								color='primary'
								fullWidth
								onClick={onSave}
							>
								{t('form.buttons.confirm')}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog >);
};

export default AddReceiptForm;
