import * as React from 'react';
import { useEffect, useState } from 'react';

import { Dialog, Grid, IconButton, ListItemText, MenuList  } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import useCloseDialog from '@/helpers/customHook/useCloseDialog';
import { openForm } from '@/features/forms/slice';
import MuiHeader from '../../../Shared/Widgets/MuiText/MuiHeader';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { ContactPaymentTypes } from '@/features/contacts/types';
import StyledMenuItemModal from '@/componentsMui/Shared/Widgets/MenuItem/StyledMenuItemModal';
import StyledListItemIcon from '@/componentsMui/Shared/Widgets/StyledListItemIcon';
import { ReactComponent as MuiBigContacts } from '@/assets/MuiBigContacts.svg';
import FormPaperLargeFixed from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeFixed';
import { ReactComponent as MuiBigWallet } from '@/assets/MuiBigWallet.svg';
import { ReactComponent as EurIcon } from '@/assets/CurrencyIcons/Eur.svg';
import { ReactComponent as GbpIcon } from '@/assets/CurrencyIcons/Gbp.svg';
import CreateInternalContact from './CreateInternalContact';
import CreateSepaContact from './CreateSepaContact';
import CreateUkContact from './CreateUkContact';
import CreateCryptoContact from './CreateCryptoContact';

const CreateContactForm = () => {
    const { t } = useTranslation('translations');
    const [contactType, setContactType] = useState(null);

    const { operations } = useSelector(
        (state: RootState) => state.operations
    );

    const bankAccountOperation = ['CREATE_WALLET', 'CREATE_ACCOUNT'];
    const isCryptoContacts = operations.find(operation => operation === 'CREATE_CRYPTO_ACCOUNT');
    const isBankAccountsContacts = operations.find(operation => bankAccountOperation.includes(operation));

    const closeModal = useCloseDialog();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(openForm());
    }, [dispatch]);

    return (
        <Dialog
            id='createContactForm'
            open={true}
            PaperComponent={FormPaperLargeFixed}
        >
            <Grid container justifyContent='space-between' alignItems='center' >
                <Grid item>
                    <MuiHeader pb={2}>{t('contacts.create.title')}</MuiHeader>
                </Grid>
                <Grid item>
                    <IconButton onClick={closeModal}  >
                        <MuiCloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            {contactType === null && <>
                <Grid item >
                    <MuiFormText>{t('contacts.create.subTitle')}</MuiFormText>
                </Grid>
                <Grid item>
                    <MenuList role='menu' className='form-menu__list'>
                        <StyledMenuItemModal
                            unsetMarginBottom={true}
                            key='local'
                            onClick={() => { setContactType(ContactPaymentTypes.LOCAL); }}
                        >
                            <StyledListItemIcon> <MuiBigContacts />  </StyledListItemIcon>
                            <ListItemText className='money-form-list-item-text'>{t('contacts.create.types.INTERNAL')}</ListItemText>
                        </StyledMenuItemModal>

                        {isBankAccountsContacts && <>
                            <StyledMenuItemModal
                                unsetMarginBottom={true}
                                key='sepa'
                                onClick={() => { setContactType(ContactPaymentTypes.SEPA); }}
                            >
                                <StyledListItemIcon> <EurIcon />  </StyledListItemIcon>
                                <ListItemText className='money-form-list-item-text'>{t('contacts.create.types.SEPA')}</ListItemText>
                            </StyledMenuItemModal>
                            <StyledMenuItemModal
                                unsetMarginBottom={true}
                                key='chaps'
                                onClick={() => { setContactType(ContactPaymentTypes.UK_CHAPS); }}
                            >
                                <StyledListItemIcon> <GbpIcon />  </StyledListItemIcon>
                                <ListItemText className='money-form-list-item-text'>{t('contacts.create.types.UK')}</ListItemText>
                            </StyledMenuItemModal>
                        </>}

                        {isCryptoContacts &&
                            <StyledMenuItemModal
                                unsetMarginBottom={true}
                                key='crypto'
                                onClick={() => { setContactType(ContactPaymentTypes.CRYPTO); }}
                            >
                                <StyledListItemIcon> <MuiBigWallet />  </StyledListItemIcon>
                                <ListItemText className='money-form-list-item-text'>{t('contacts.create.types.CRYPTO')}</ListItemText>
                            </StyledMenuItemModal>}
                    </MenuList>
                </Grid>

            </>}

            {contactType === ContactPaymentTypes.LOCAL && <CreateInternalContact onBack={() => setContactType(null)} />}
            {contactType === ContactPaymentTypes.SEPA && <CreateSepaContact onBack={() => setContactType(null)} />}
            {contactType === ContactPaymentTypes.UK_CHAPS && <CreateUkContact onBack={() => setContactType(null)} />}
            {contactType === ContactPaymentTypes.CRYPTO && <CreateCryptoContact onBack={() => setContactType(null)} />}
        </Dialog >
    );
};


export default CreateContactForm; 
