import * as React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import './TradeHistory.css';
import { WalletTransactionType } from '@features/walletHistory/types';

interface Props {
	type: WalletTransactionType
}

const WalletHistoryHeader = ({type}: Props) => {
	const { t } = useTranslation('translations');

	return (
		<Grid container alignItems='center' >
			<Grid item className='history-line__time'>
				<MuiTextCaption color='text.secondary'>
				{t('tokens.cryptoHistory.time')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='history-line__coin'>
				<MuiTextCaption color='text.secondary'>
				{t('tokens.cryptoHistory.coin')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='history-line__type'>
				<MuiTextCaption color='text.secondary'>
				{t('tokens.cryptoHistory.type')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='history-line__amount'>
				<MuiTextCaption color='text.secondary'>
				{t('tokens.cryptoHistory.amount')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='history-line__destination'>
				<MuiTextCaption color='text.secondary'>
					{(type === WalletTransactionType.CRYPTO_WITHDRAWALS || type === WalletTransactionType.TOKEN_WITHDRAWALS) ? t('tokens.cryptoHistory.destination') : t('tokens.cryptoHistory.source')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='history-line__trxId'>
				<MuiTextCaption color='text.secondary'>
				{t('tokens.cryptoHistory.viewOnExplorer')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='history-line__status'>
				<MuiTextCaption color='text.secondary'>
				{t('tokens.cryptoHistory.status')}
				</MuiTextCaption>
			</Grid>
		</Grid>);
};

export default WalletHistoryHeader;
