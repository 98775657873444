import { CoinType } from '@/features/coins/types';
import * as React from 'react';
import { useState } from 'react';
import AssetDepositStep from './AssetDepositStep';
import AssetSelectStep from './AssetSelectStep';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';

interface Props {
	symbol?: string,
	includeWallet?: boolean,
	onBack?: () => void
}

const CryptoDepositFlow = ({ symbol, includeWallet, onBack }: Props) => {

	const coins = useSelector((state: RootState) => state.coins.list);
	const [stepLocal, setStepLocal] = React.useState<number>(1);
	const nextStepLocal = () => setStepLocal(previousState => (previousState + 1));
	const prevStepLocal = () => setStepLocal(previousState => (previousState - 1));

	const defaultToken = symbol ? coins.find(p => p.info.symbol === symbol) || null : null;


	const [token, setToken] = useState<CoinType>(defaultToken);

	const handleSelectToken = (value: CoinType) => {
		nextStepLocal();
		setToken(value);
	};

	if (token) {
		return (<>
			<AssetDepositStep onBack={onBack} token={token} />
		</>);
	}

	return (<>
		{stepLocal === 1 && <AssetSelectStep onNext={handleSelectToken} symbol={symbol} includeWallet={includeWallet} onBack={onBack} />}
		{stepLocal === 2 && <AssetDepositStep onBack={prevStepLocal} token={token} />}
	</>);
};

export default CryptoDepositFlow;
