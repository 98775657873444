import * as React from 'react';
import { useEffect, useState } from 'react';

import { Dialog, Grid, IconButton, Theme, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import NewInternalContact from './NewInternalContact';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import useCloseDialog from '@/helpers/customHook/useCloseDialog';
import { openForm } from '@/features/forms/slice';
import DialogContentNoScrollbar from '../../../Shared/Widgets/Dialog/DialogContentNoScrollbar';
import MuiHeader from '../../../Shared/Widgets/MuiText/MuiHeader';
import { useTheme } from '@mui/styles';
import { ContactPaymentTypes } from '@/features/contacts/types';
import EditPaymentContact from './EditPaymentContact';
import NewSepaContact from './NewSepaContact';
import EditCryptoContact from './EditCryptoContact';

const EditContactForm = () => {
	const theme = useTheme() as Theme;
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const { t } = useTranslation('translations');
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const [editContact] = useState<string>(searchParams.get('contact'));
	const editContactId = parseInt(editContact);

	const { contacts } = useSelector((state: RootState) => state.contacts);
	const contactToEdit = contacts.find(c => c.beneficiaryAccountId === editContactId);

	const contactType = contactToEdit?.paymentType;
	const closeModal = useCloseDialog();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);

	return (
		<Dialog
			id='createContactForm'
			open={true}
			onClose={closeModal}
			fullWidth
			maxWidth='xs'
			fullScreen={fullScreen}
		>
			<DialogContentNoScrollbar >
				<Grid
					container
					direction='row'
					padding='1.5rem'
					justifyContent='center'
					height='100%'
				>
					<Grid item xs={12} height='100%' >
						<Grid item xs={12} >
							<Grid container justifyContent='space-between' alignItems='center' >
								<Grid item>
									<MuiHeader pb={2}>{t('contacts.my.editBtn')}</MuiHeader>
								</Grid>
								<Grid item>
									<IconButton onClick={closeModal}  >
										<MuiCloseIcon />
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
						{contactToEdit ?
							<Grid item pt='1.25rem' height={contactType === ContactPaymentTypes.LOCAL ? '85%' : '90%'} >
								{contactType === ContactPaymentTypes.CRYPTO && <EditCryptoContact contactToEdit={contactToEdit} />}
								{contactType === ContactPaymentTypes.LOCAL && <NewInternalContact contactToEdit={contactToEdit} />}
								{(contactType === ContactPaymentTypes.SEPA || contactType === ContactPaymentTypes.SEPA_INSTANT) && <NewSepaContact contacts={contacts} contactToEdit={contactToEdit} />}
								{contactType !== ContactPaymentTypes.LOCAL
									&& contactType !== ContactPaymentTypes.SEPA
									&& contactType !== ContactPaymentTypes.CRYPTO
									&& contactType !== ContactPaymentTypes.SEPA_INSTANT && <EditPaymentContact contactToEdit={contactToEdit} />}

							</Grid> :
							<></>
						}
					</Grid>
				</Grid>
			</DialogContentNoScrollbar>
		</Dialog >
	);
};


export default EditContactForm; 
