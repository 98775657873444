import { Typography, TypographyProps } from '@mui/material';
import React, { FC } from 'react';

const MuiTextConfirmationLabel: FC<TypographyProps> = (props: TypographyProps) => {
	return (
		<Typography 
			fontSize='0.813rem' 
			fontWeight='400'
			//lineHeight='1.188rem'
			textAlign='start' 
			{...props}>
			{props.children}
		</Typography>
	);
};

export default MuiTextConfirmationLabel;
