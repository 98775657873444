import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Link } from '@mui/material';
import MuiFormText from '../../Shared/Widgets/MuiText/MuiFormText'; 
import MuiAppStore from '@shared/Widgets/Box/AppStore';
import MuiGoogleStore from '@shared/Widgets/Box/GoogleStore';
import MobileHeader from '../components/MobileHeader';

interface Props {
	next: () => void
	skip2FA?: () => void
}

const AuthAppDownload = ({ next, skip2FA }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');

	return (
		<Grid container id='download-step'>
			<Grid item>
				<MobileHeader text={t('landing.authStep.title')} />
				<Grid item xs={12} container rowGap='42px'>
					<Grid item xs={12}>
						<MuiFormText >
							{t('landing.authStep.info1')}
						</MuiFormText>
					</Grid>
					<Grid item xs={12} container rowGap='22px'>
						<Grid item xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
							<MuiFormText >
								{t('landing.authStep.info2')}
							</MuiFormText>
						</Grid>
						<Grid item xs={12} container>
							<Link href='https://apps.apple.com/us/app/google-authenticator/id388497605'
								target="_blank" rel="noopener noreferrer"
								width='100%'>
								<MuiAppStore />
							</Link>
						</Grid>
						<Grid item xs={12} container>
							<Link href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
								target="_blank" rel="noopener noreferrer"
								width='100%'>
								<MuiGoogleStore />
							</Link>
						</Grid>
					</Grid>
					<Grid item xs={12} sx={{ display: { xs: 'none', sm: 'block' } }}>
						<MuiFormText >
							{t('landing.authStep.info2')}
						</MuiFormText>
					</Grid>
					{skip2FA ? <Grid container spacing={2}>
						<Grid item xs={6}  >
							<Button
								onClick={skip2FA}
								variant='customOutlined'
								fullWidth
							>
								{t('form.buttons.skip')}
							</Button>
						</Grid>
						<Grid item xs={6}  >
							<Button
								onClick={next}
								variant='contained'
								fullWidth
							>
								{t('form.buttons.continue')}
							</Button>
						</Grid>
					</Grid> :
						<Grid item xs={12}  >
							<Button
								onClick={next}
								variant='contained'
								fullWidth
							>
								{t('form.buttons.continue')}
							</Button>
						</Grid>}

				</Grid >
			</Grid>
		</Grid >);
};

export default AuthAppDownload;
