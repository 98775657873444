import React from 'react';
import { Dialog, DialogContent, Grid, IconButton, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import TransactionStatements from './TransactionStatements';
import { withStyles } from '@mui/styles';
import '@/componentsMui/Shared/Shared.css';
import { AccountType } from '@/features/operations/types';

const TransactionFormPaper = withStyles(() => ({
	root: {
		maxHeight: '90vh',
		minHeight: '90vh',
		padding: '1rem'
	}
}))(Paper);

interface Props {
	type?: AccountType,
	accountId?: string,
	account: any,
	open: boolean,
	onClose: () => void
}

const TransactionForm = ({ type, account, open, onClose }: Props): React.ReactElement => {

	const { t } = useTranslation('translations');

	return (
		<Dialog
			id="transaction-form"
			open={open}
			fullWidth
			PaperComponent={TransactionFormPaper}
			maxWidth='lg'
		>
			<DialogContent className='scroll-container' style={{ height: '100vh' }}>
				<Grid container justifyContent='flex-end'>
					<Grid item>
						<IconButton onClick={onClose}  >
							<MuiCloseIcon />
						</IconButton>
					</Grid>
				</Grid>
				<div className='scroll-section'>
					<TransactionStatements
						type={type}
						account={account}
						hideExpand={true}
						title={t('transaction.header.expanded')}
					/>
				</div>
			</DialogContent>
		</Dialog >
	);
};

export default TransactionForm;
