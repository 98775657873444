import React from 'react';

import {
	Switch,
	Route,
	useRouteMatch,
} from 'react-router-dom'; 
import Login from '@/componentsMui/Auth/login/Login';
import Register from '@/componentsMui/Auth/Register';
import ForgotPassword from '@/componentsMui/Auth/ForgotPassword';
import ResetPassword from '@/componentsMui/Auth/ResetPassword';
function Auth(): React.ReactElement {

	//const location = window.location.href;
	//const demoEnv = location.includes('demo');
	const { path } = useRouteMatch();
	return (<>
		<Switch>
			<Route path={`${path}/register/freelancer`}  >
				<Register isFreeLancer={true} />
			</Route>
			<Route path={`${path}/register/user`}  >
				<Register isFreeLancer={false} />
			</Route>
			<Route path={`${path}/forgot-password`} component={ForgotPassword} />
			<Route path={`${path}/reset-password/:code`} component={ResetPassword} />
			<Route component={Login} />
			{/* 	<Route component={demoEnv ? DemoLogin : Login} /> */}
		</Switch>
	</>
	);
}

export default Auth;
