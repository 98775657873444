
import * as React from 'react';
import { useTheme } from '@mui/styles';
import { MenuItem, MenuItemProps, Theme } from '@mui/material';
import { FC } from 'react';

interface IMenuItemProps extends MenuItemProps {
	fill?: boolean,
	unsetMarginBottom?: boolean,
}

/* Menu item for Forms */
const StyledMenuItemModal: FC<IMenuItemProps> = (props: IMenuItemProps) => {

	const theme = useTheme() as Theme;
	const { unsetMarginBottom, ...others } = props;

	return (<MenuItem id='menuItemModel'
		sx={{
			height:  unsetMarginBottom ? '72px' : '75px',
			display: 'flex',
			borderRadius: theme.mainBorderRadius,
			marginBottom:  unsetMarginBottom ? '1rem' : '1.25rem',
			backgroundColor:  unsetMarginBottom ? 'rgba(255, 255, 255, 0.09)' : 'transparent',
			'&:last-child': {
				marginBottom:  unsetMarginBottom ? '0' : '1.25rem',
			},
			'&:hover': {
				backgroundColor: theme.palette.mode === 'dark' ? 'rgba(126, 126, 126, 0.16)' : 'transparent'
			},
			'&.Mui-disabled': {
				opacity: 1,
			},
			'&.Mui-selected':
			{
				border: theme.palette.mode === 'dark' ? 'unset' : theme.inputField.border,
				//	boxShadow: theme.palette.mode === 'dark' ? 'unset' : theme.shadowMain,
				backgroundColor: theme.palette.mode === 'dark' ? 'rgba(126, 126, 126, 0.16)' : 'transparent'
			},
			'&.Mui-disabled.Mui-disabled .MuiListItemText-root': {
				opacity: 0.3,
			},
			'&.Mui-disabled.Mui-selected .MuiListItemText-root': {
				opacity: 1,
			},
			/* ...(!props.skipSelected && {
				'&.Mui-selected svg':
				{
					fill: props.fill ? theme.highlight : 'none',
					stroke: theme.highlight,
				}
			}
			) */
		}}
		{...others}> {others.children}
	</MenuItem >);
};

export default StyledMenuItemModal;
