import * as React from 'react';
import { format, isAfter, isToday } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Message as MessageType } from '@/features/supportChat/types';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { Grid } from '@mui/material';
import MessageItem from './MessageItem';

interface Props {
  message: MessageType,
  prevCreatedDate: string,
  anonymous?: boolean;
}

const getDateWithoutTime = (date: string) => {
  const msgDate = new Date(date);
  return new Date(msgDate.getFullYear(), msgDate.getMonth(), msgDate.getDate());
};

const Message = ({ message, prevCreatedDate, anonymous }: Props) => {
  const { t } = useTranslation('translations');
  const msgDateNoTime = getDateWithoutTime(message.createdDate);
  const prevDateNoTime = prevCreatedDate ? getDateWithoutTime(prevCreatedDate) : null;
  const showDateLine = prevDateNoTime ? isAfter(msgDateNoTime, prevDateNoTime) : true;
  const msgDate = new Date(message.createdDate);
  const dateHeader = isToday(msgDate) ? `${t('form.today')}` : format(msgDate, 'dd/MM/yyyy');

  return (
    <>
      {showDateLine &&
        <Grid container justifyContent='center' my='10px'>
          <MuiFormText color='text.secondary'>
            {dateHeader}
          </MuiFormText>
        </Grid>}
      <Grid container flexDirection={'column'} alignItems={!message.clientMessage ? 'flex-start' : 'flex-end'}>
        {message.message !== null
          ? <MessageItem
            message={message}
            clientMessage={message.clientMessage}
            msgDate={msgDate}
          />
          : null
        }
        {message.attachments.length > 0
          ? (message.attachments.map((attachment, index) => {
            return (
              <MessageItem
                key={index}
                attachment={attachment}
                clientMessage={message.clientMessage}
                msgDate={msgDate}
                anonymous={anonymous}
              />
            );
          }))
          : null
        }
      </Grid>
    </>
  );
};

export default Message;
