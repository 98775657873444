import { Card, Grid, IconButton, Typography } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import React, { useEffect, useMemo, useState } from 'react';
import { PaymentTypes } from '@features/invoices/invoicesTypes';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useTranslation } from 'react-i18next';
import ChoosePaymentOptionsDialog from './ChoosePaymentOptionsDialog';
import PaymentIcon from '@mui/icons-material/Payment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { getAvailablePaymentOptions } from '@/features/invoices/invoicesSlice';

interface Props {
    selectedPayments: PaymentTypes[];
    onChange?: (value: PaymentTypes[]) => void;
}

const PaymentOptionsBox = ({ selectedPayments, onChange }: Props) => {
    const { t } = useTranslation('translations');
    const dispatch = useDispatch();
    const { bankTransferAvailable, cryptoAddressesAvailable } = useSelector((state: RootState) => state.invoices.options);
    const [openChooseDialog, setOpenChooseDialog] = useState<boolean>(false);
    const editable = !!onChange;
    
    useEffect(() => {
        dispatch(getAvailablePaymentOptions());
    }, [dispatch]);
    
    const options = useMemo(() => {
        return [
            ...cryptoAddressesAvailable ? [{
                value: PaymentTypes.CRYPTO_ONE_TIME_ADDRESSES,
                icon: <PaymentIcon />,
                title: t('invoice.paymentOptions.oneTimeAddress'),
                subtitle: t('invoice.paymentOptions.oneTimeAddressSubTitle'),
            }] : [],
            ...bankTransferAvailable ? [{
                value: PaymentTypes.BANK_TRANSFER,
                icon: <AccountBalanceIcon />,
                title: t('invoice.paymentOptions.bankTransfer'),
                subtitle: t('invoice.paymentOptions.bankTransferSubTitle'),
            }] : [],
        ];
    }, [cryptoAddressesAvailable, bankTransferAvailable, t]); 


    return (
        <Card
            sx={theme => ({
                background: theme.palette.background.paper,
                padding: '20px',
                borderRadius: '16px',
                height: '100%',
            })}
        >
            {editable && openChooseDialog && <ChoosePaymentOptionsDialog
                onClose={() => setOpenChooseDialog(false)}
                onSave={(values: PaymentTypes[]) => { onChange(values); setOpenChooseDialog(false); }}
                open={openChooseDialog}
                seletedPaymentOptions={selectedPayments}
                options={options}
            />}
            <Grid container>
                <Grid item xs={12} container justifyContent='space-between' alignItems='center'>
                    <Typography variant="h5">{t('invoice.paymentOptions.title')}</Typography>
                    {editable && <IconButton onClick={() => setOpenChooseDialog(true)}>
                        <ModeEditIcon />
                    </IconButton>}
                </Grid>
                <Grid item xs={12} container>
                    {selectedPayments.length === 0 ? <Typography variant="body2" textAlign='center' my={2}>{t('invoice.paymentOptions.noPaymentOptions')}</Typography> :
                        <>
                            {selectedPayments.map(payment => {
                                const option = options.find(o => o.value === payment);

                                return <Grid item xs={12} container alignItems="center" spacing={1} my={1} key={payment}
                                    sx={theme => ({
                                        background: theme.palette.background.default,
                                        padding: '5px 10px',
                                        borderRadius: '16px',
                                    })}>
                                    <Grid item>{option?.icon}</Grid>
                                    <Grid item xs>
                                        <Typography variant="body1">{option?.title}</Typography>
                                        <Typography variant="subtitle1" color="text.secondary">
                                            {option?.subtitle}
                                        </Typography>
                                    </Grid>
                                </Grid>;
                            })}
                        </>}
                </Grid>
            </Grid>
        </Card>
    );
};

export default PaymentOptionsBox;
