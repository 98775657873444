import { Paper } from '@mui/material';
import { withStyles } from '@mui/styles';

/* Fixed width, scroll inside form */
const FormPaperFullScreenMobile = withStyles(() => ({
	root: {
		['@media only screen and (max-height: 700px)']: {
			overflow: 'hidden auto',
			maxHeight: 'unset',
			minHeight: 'unset',
			minWidth: 'unset',
			maxWidth: '100%',
			width: '100%',
			height: '100%',
			padding: '1rem',
			margin: 0,
			borderRadius: 0,
			'& .MuiDialogContent-root': {
				padding: 0,
			}
		},
		['@media only screen and (max-width:600px)']: {
			overflow: 'hidden auto',
			maxHeight: 'unset',
			minHeight: 'unset',
			minWidth: 'unset',
			maxWidth: '100%',
			width: '100%',
			height: '100%',
			padding: '1rem',
			margin: 0,
			borderRadius: 0,
			'& .MuiDialogContent-root': {
				padding: 0,
			}
		},
	}
}))(Paper);

export default FormPaperFullScreenMobile;
