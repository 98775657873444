import { Button, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	show: boolean,
	onClick: () => void
}

const More = ({ show, onClick }: Props) => {
	const { t } = useTranslation('translations');

	return (
		show ? <Grid item xs={12} justifyContent='center' display='flex' pt={2}>
			<Button onClick={onClick}>{t('transaction.table.loadMore')}</Button>
		</Grid> : <></>
	);
};

export default More;
