import * as React from 'react';

import '@/componentsMui/Shared/Shared.css';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { addContact, getCryptoMetadata } from '@/features/contacts/slice';
import { useDispatch } from 'react-redux';
import { showException, showSuccess } from '@/features/swal/slice';
import { ContactPaymentTypes } from '@/features/contacts/types';
import { isEmpty } from 'lodash';
import MuiTextTrx from '@/componentsMui/Shared/Widgets/MuiText/MuiTextTrx';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import FormContentBox from '@/componentsMui/Shared/Widgets/Box/FormContentBox';
import Dropdown from '@/componentsMui/Shared/FormikComponents/Dropdown';
import { useEffect, useState } from 'react';
import { validateWithdrawAddress } from '@/features/coins/slice';

interface Props {
    onBack: () => void;
}

const CreateCryptoContact = ({ onBack }: Props) => {

    const { t } = useTranslation('translations');
    const dispatch = useDispatch();
    const closeModal = useCloseDialogNoEvent();
    const [networkOptions, setNetworkOptions] = useState([]);
    // const [ccyOptions, setCcyOptions] = useState([{ key: 'MULTI_CURRENCY', value: 'Multi-currency' }]);


    useEffect(() => {
        const get = async () => {
            try {
                const response = await getCryptoMetadata();
                setNetworkOptions(response.networks.map((item) => { return { key: item, value: item }; }));
                // setCcyOptions([...[{ key: 'MULTI_CURRENCY', value: 'Multi-currency' }], ...(Object.keys(response.currencies)?.map((key) => { return { key, value: `${response.currencies[key]} (${key})` }; }))]);
            } catch (err) {
                // setNetworkOptions();
            }
        };
        get();
    }, []);

    const validationSchema = Yup.object({
        name: Yup.string().required(t('form.validator.required')),
        address: Yup.string().required(t('form.validator.required')),
        network: Yup.mixed().required(t('form.validator.required')),
        // ccy: Yup.mixed().required(t('form.validator.required'))
    });

    const submit = async (formData, formikProps) => {
        const { setSubmitting, setFieldError } = formikProps;
        const { name, network, address } = formData;
        try {

            try {
                await validateWithdrawAddress(address, network);
            } catch (err) {
                setFieldError('address', t('form.validator.invalidAddress'));
                return;
            }

            await dispatch(addContact({
                name,
                paymentType: ContactPaymentTypes.CRYPTO,
                account: address,
                cryptoNetwork: network,
                // ccy: ccy === 'MULTI_CURRENCY' ? null : ccy
            }));
            showSuccess({ title: 'contacts.create.success', mui: true });
            // dispatch(getContacts());
            closeModal();
        } catch (e) {
            showException(e);
            closeModal();
        }
        finally {
            setSubmitting(false);
        }
    };

    const initialValues = {
        name: '',
        address: '',
        ccy: '',
        network: '',
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submit}

        >{({ errors, isSubmitting, dirty }) => {
            return (
                <Form className='form-content__wrap__bb'>
                    <FormContentBox noBackground={true} className='form-content_main-area wp'>
                        <Grid container sx={{ height: '100%' }} >
                            <Grid id="contentWrap"
                                item
                                spacing={2}
                                container
                                alignSelf="flex-start" >
                                <Grid item xs={12}  >
                                    <MuiTextTrx marginTop="0.5rem" >{t('contacts.create.subTitles.CRYPTO')}</MuiTextTrx>
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        name="name"
                                        fullWidth
                                        label={t('contacts.create.name')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        name="address"
                                        fullWidth
                                        label={t('contacts.create.address')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Dropdown
                                        options={networkOptions}
                                        fieldLabel={t('contacts.create.network')}
                                        fieldName='network'
                                    />
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <Dropdown
                                        options={ccyOptions}
                                        fieldLabel={t('sendmoneyTranslation.data.currency')}
                                        fieldName='ccy'
                                    />
                                </Grid> */}
                            </Grid>
                            <Grid container mt='auto' pt='2rem' columnSpacing={2}>
                                <Grid item xs={6} >
                                    <Button
                                        variant='customOutlined'
                                        color='secondary'
                                        onClick={onBack}
                                        sx={{ width: { xs: '100%', md: 'auto' } }}
                                    >  {t('form.buttons.back')}
                                    </Button>
                                </Grid>
                                <Grid item xs={6} container justifyContent='flex-end'>
                                    <Button disabled={isSubmitting || !isEmpty(errors || !dirty)}
                                        key="buttonNext"
                                        variant='contained'
                                        color='primary'
                                        type="submit" >
                                        {t('contacts.create.save')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </FormContentBox>
                </Form>
            );
        }}
        </Formik >
    );
};

export default CreateCryptoContact;
