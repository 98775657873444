import * as React from 'react';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormLayout from '@/componentsMui/Shared/Widgets/Dialog/FormLayout';
import { showException, showSuccess } from '@/features/swal/slice';
import { RequestMoneyPayloadType } from './RequestMoneyDetails';
import { postPaymentRequest } from '@/features/tickets/slice';
import useCloseDialog from '@/helpers/customHook/useCloseDialog';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import ConfirmationBox from '@/componentsMui/Shared/Components/ConfirmationRow';
import MuiFormField from '@/componentsMui/Shared/Widgets/MuiText/MuiFormField';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';

export interface Props {
	payload: RequestMoneyPayloadType,
	prevStep: () => void
}

const RequestMoneyConfirmation = ({ payload, prevStep }: Props) => {

	const { t } = useTranslation('translations');
	const closeModal = useCloseDialog();

	const { user } = useSelector(
		(state: RootState) => state.user
	); 
 
	const send = async () => {

		try {
			const formData = new FormData();
			formData.append('toUserId', payload.contactUser.userId.toString());
			formData.append('reference', payload.reference);
			formData.append('requestMeta', JSON.stringify(
				{
					user: {
						id: user.id,
						firstName: user.firstName,
						lastName: user.lastName
					},
					amount: payload.amount,
					account: {
						accountId: payload.toAccount.accountId,
						currency: payload.toAccount.currency
					}
				}));

			await postPaymentRequest(formData);

			const contactName = payload?.fromContact?.name ||payload?.contactUser?.companyName||`${payload?.contactUser?.firstName} ${payload?.contactUser?.lastName}`;
			await showSuccess({
				title: 'paymentsubmitted.data.header_request', text: t('paymentsubmitted.data.requestamount', {
					amount: payload.toAccount.currencySymbol + '' + payload.amount,
					account: contactName
				}), mui: true
			});

		} catch (e) {
			await showException(e);
		}

	};

	const handleConfirm = (e: any) => {
		closeModal(e);
		send();
	};

	const mainContent =
		<Grid container spacing={1}>
			<Grid item xs={12} pb={1}>
				<FormHeader title={t('sendmoney.data.confirmheader')} showBack={false} />
			</Grid>
			<Grid item xs={12}>
				<ConfirmationBox label={t('sendmoneyTranslation.data.to')}>
					<MuiFormField> {payload.toAccount.name} ({payload.toAccount.currencySymbol}{payload.toAccount.availableBalance})</MuiFormField>
				</ConfirmationBox>
			</Grid>
			<Grid item xs={12}>
				<ConfirmationBox label={t('sendmoney.data.transferamount')}>
					<MuiFormField> {payload.toAccount.currencySymbol}{payload.amount}</MuiFormField>
				</ConfirmationBox>
			</Grid>
			<Grid item xs={12}>
				<ConfirmationBox label={t('login.clienId')}>
					<MuiFormField> {payload.fromWalletId}</MuiFormField>
				</ConfirmationBox>
			</Grid>
			<Grid item xs={12}>
				<ConfirmationBox label={t('sendmoney.data.reference')}>
					<MuiFormField> {payload.reference}</MuiFormField>
				</ConfirmationBox>
			</Grid>
		</Grid>;

	const buttonContent = [
		<Button
			key="buttonCancel"
			className='customDialogButton'
			variant='contained'
			color='secondary'
			onClick={prevStep}
		>   {t('form.buttons.back')}
		</Button>,
		<Button
			key="buttonNext"
			className='customDialogButton'
			variant="contained"
			color="primary"
			onClick={handleConfirm}
		>
			{t('createaccounttab.btn')}
		</Button>];

	return (
		<FormLayout
			contentPart={mainContent}
			buttonPart={buttonContent}
		/>);
};

export default RequestMoneyConfirmation;
