import React from 'react';
import { InvoiceRecipient } from '@features/invoices/invoicesTypes';
import { Dialog, DialogContent, DialogTitle, Grid, InputAdornment } from '@mui/material';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import LoadingButton from '@/componentsMui/Shared/Components/LoadingButton';
import { checkAllValuesIsNull, transformAEmptyValuesToNull } from '@/helpers/normalizeString';
import CountryCodesAlpha3 from '@/componentsMui/Shared/FormikComponents/CountryCodesAlpha3';
import { useEmailOptional, usePhoneOptional } from '@/helpers/customHook/useValidations';

interface Props {
    open: boolean;
    onClose: () => void;
    recipient: InvoiceRecipient | null;
    onSave: (recipient: InvoiceRecipient | null) => void;
}

const EditRecipientDialog = ({ open, onClose, onSave, recipient }: Props) => {
    const { t } = useTranslation('translations');

    const emailValidation = useEmailOptional();
    const phoneValidation = usePhoneOptional();

    const initialValues = {
        recipientName: recipient?.recipientName ?? '',
        recipientEmail: recipient?.recipientEmail ?? '',
        recipientAddress: recipient?.recipientAddress ?? '',
        recipientPhone: recipient?.recipientPhone ?? '',
        recipientPostalCode: recipient?.recipientPostalCode ?? '',
        recipientCountry: recipient?.recipientCountry ?? '',
        recipientCity: recipient?.recipientCity ?? '',
    };

    const validationScheme = Yup.object({
        recipientEmail: emailValidation,
        phoneValidation: phoneValidation,
    });


    const submit = (formData: any, formikProps: any) => {

        const { setSubmitting } = formikProps;
        const { recipientName, recipientEmail, recipientAddress, recipientPhone, recipientPostalCode, recipientCountry, recipientCity } = formData;

        setSubmitting(true);
        let payload: InvoiceRecipient = {
            recipientAddress,
            recipientCity,
            recipientCountry,
            recipientEmail,
            recipientName,
            recipientPhone,
            recipientPostalCode
        };

        payload = transformAEmptyValuesToNull(payload as Record<string, string | null>);
        const isAnonRecipient = checkAllValuesIsNull(payload as Record<string, string | null>);
        onSave(isAnonRecipient ? null : payload);
        setSubmitting(false);
    };

    return (
        <Dialog
            open={open}
            maxWidth='xs'
        >
            <DialogTitle>
                <FormHeader
                    title={t('invoice.billTo')}
                    onBack={onClose}
                />
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationScheme}
                    onSubmit={submit}
                    enableReinitialize
                >
                    {({ isSubmitting, errors }) => (
                        <Form>
                            <Grid container gap={2}>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        name='recipientName'
                                        label={t('invoice.recipientBox.name')}
                                        fullWidth
                                        inputProps={{ maxLength: 100 }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        name='recipientAddress'
                                        label={t('invoice.recipientBox.address')}
                                        fullWidth
                                        inputProps={{ maxLength: 100 }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        name='recipientCity'
                                        label={t('invoice.recipientBox.city')}
                                        fullWidth
                                        inputProps={{ maxLength: 100 }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        name='recipientPostalCode'
                                        label={t('invoice.recipientBox.postalCode')}
                                        fullWidth
                                        inputProps={{ maxLength: 100 }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CountryCodesAlpha3
                                        fieldName="recipientCountry"
                                        fieldlabel={t('invoice.recipientBox.country')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        name="recipientEmail"
                                        type="email"
                                        fullWidth
                                        label={t('invoice.recipientBox.email')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        name='recipientPhone'
                                        label={t('invoice.recipientBox.phone')}
                                        fullWidth
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">+</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} mt={2}>
                                    <LoadingButton
                                        disabled={isSubmitting || !isEmpty(errors)}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting}
                                    >
                                        {t('invoice.save')}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default EditRecipientDialog;
