
import * as React from 'react';
import { Box, BoxProps, Theme } from '@mui/material';
import { FC } from 'react';
import './CoverBox.css';
import useTheme from '@mui/styles/useTheme';
import Cover from './Covers/Cover';

interface Props extends BoxProps {
	selected?: boolean,
	bgIndex: number,
	coverName: string,
	mainClassName?: string,
	itemClassName?: string
}

/* Unselected items are in oriignal color with opacity.  */

const MultiBgBox: FC<Props> = (props: Props) => {


	const theme = useTheme() as Theme;

	const { selected, sx, ...others } = props;

	return (
		<div id='option-cover-box' className={props.mainClassName}>
			<Cover baseBg={theme.covers[props.coverName].baseBg}
				firstBg={theme.covers[props.coverName].bg[props.bgIndex].firstBg}
				secondBg={theme.covers[props.coverName].bg[props.bgIndex].secondBg}
			>
				<Box
					sx={{
						...sx,
						borderRadius: theme.mainBorderRadius,
						justifyContent: 'space-between',
						opacity: selected ? 1 : 0.5,
						height: 'inherit'
					}}
					{...others}>
					<div
						className={'option-cover-box__item-wrap ' + props.itemClassName}
					>
						{props.children}
					</div>
				</Box>
			</Cover>

		</div>);
};


export default MultiBgBox;


