import * as React from 'react';

import {
    Box,
    Button,
    Grid,
    MenuItem,
    Select, Theme,
    Tooltip,
    useMediaQuery
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { openForm } from '@/features/forms/slice';
import { useCallback, useEffect, useState } from 'react';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import VaultAllDepositsHeader from './VaultAllDepositsHeader';
import { AllocationsPayload } from '@/features/earn/types';
import { getAllocations, setAllocations, setFilter, setLoading, setSkip } from '@/features/earn/depositsSlice';
import { showErrorNotification } from '@/features/swal/slice';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import VaultAllDepositsLine from './VaultAllDepositsLine';
import VaultAllDepositsLineMobile from './VaultAllDepositsLineMobile';
import { useTheme } from '@mui/styles';
import VaultDepositDetailsForm from './VaultDepositDetailsForm';
import TokenAvatar, { TokenAvatarSizeEnum } from '@shared/Widgets/Avatars/TokenAvatar';
import { allTokensList } from '@features/coins/slice';
import DownloadButton from '@/componentsMui/Shared/Widgets/DownloadButton';
import VaultExportForm from './VaultExportForm';


const VaultUserDeposits = () => {
    const theme = useTheme() as Theme;
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation('translations');
    const dispatch = useDispatch();
    const { loading, list } = useSelector((state: RootState) => state.vaults);
    const [showLoadMore, setShowLoadMore] = useState<boolean>(true);
    const [openVaultDepositDetailsForm, setOpenVaultDepositDetailsForm] = React.useState<boolean>(false);
    const [selectedVault, setSelectedVault] = React.useState<any>();
    const [openDownloadForm, setOpenDownloadForm] = React.useState<boolean>(false);
    const [tokenListLoading, setTokenListLoading] = useState(true);
    const [tokenList, setTokenList] = useState([]);

    const hideVaultAutoExtend = useSelector((state: RootState) => state.user.user.hideVaultAutoExtend);
    const { skip, take, allActiveDeposits, depositsLoading, count, filter } = useSelector(
        (state: RootState) => state.allVaultDeposits
    );

    const [filteredPlan, setFilteredPlan] = useState<any>('ALL');

    useEffect(() => {
        dispatch(openForm());
    }, [dispatch]);

    useEffect(() => {
        const get = async () => {
            setTokenListLoading(true);
            try {
                const response = await allTokensList();
                setTokenList(response.list);
            } catch (e) {
                // setError(e);
            } finally {
                setTokenListLoading(false);
            }
        };
        get();
    }, []);

    const fetchData = useCallback(() => {
        const get = async () => {
            setShowLoadMore(true);
            dispatch(setLoading(true));
            try {

                const payload: AllocationsPayload = {
                    skip: 0,
                    take: take
                };

                if (filteredPlan !== 'ALL') {
                    payload.filter = `proc=${filteredPlan}`;
                    dispatch(setFilter(payload.filter));
                }

                dispatch(setSkip(0));
                dispatch(setAllocations([]));
                dispatch(getAllocations(payload));
            } catch (e) {
                showErrorNotification(e);
            }
        };

        get();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [dispatch, filteredPlan]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    useEffect(() => {
        if (allActiveDeposits.length === count) {
            setShowLoadMore(false);
        } else {
            setShowLoadMore(true);
        }
    }, [allActiveDeposits, count]);

    const loadMore = async () => {
        try {
            setShowLoadMore(true);
            const payload: AllocationsPayload = {
                skip: skip,
                take: take,
                filter: filter
            };

            dispatch(getAllocations(payload));
        } catch (e) {
            showErrorNotification(e);
        }

    };

    const handlePlanChange = (event: any) => {
        const val = event.target.value;
        setFilteredPlan(val);
    };


    return (
        <Grid container id="earn-deposit" >
            <VaultDepositDetailsForm
                open={openVaultDepositDetailsForm}
                onClose={() => setOpenVaultDepositDetailsForm(false)}
                allocation={selectedVault}
                hideAutoExtend={hideVaultAutoExtend}
            />
            <VaultExportForm open={openDownloadForm} onClose={() => setOpenDownloadForm(false)} />
            <Grid container justifyContent='space-between' alignItems='flex-start'>
                <Grid  >
                    <MuiHeader>{t('vaults.statistics.yourDeposits')}</MuiHeader>
                </Grid>
                <Grid item xs='auto' container >
                    <Grid   >
                        <Tooltip title={t('vaults.vaultStatement')}>
                            <span>
                                <DownloadButton
                                    sx={{ height: '2rem !important', marginTop: '0.5rem' }}
                                    onClick={() => setOpenDownloadForm(true)}
                                />
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid pl={2}>
                        <Select
                            variant='outlined'
                            disableUnderline
                            value={filteredPlan}
                            onChange={handlePlanChange}
                            sx={{ width: '100%', marginTop: '0.5rem', height: '2rem !important' }}
                        >
                            <MenuItem
                                key='ALL'
                                value='ALL'
                            >{t('vaults.plans.allPlans')}</MenuItem>
                            {list.map(v => {
                                return <MenuItem
                                    key={v.proc}
                                    value={v.proc}>
                                    <Grid item flexWrap='nowrap' display='flex'>
                                        <Grid item sx={{ mr: '1rem' }}>
                                            <TokenAvatar symbol={v?.processProperties?.ccy}
                                                logoSource={v?.processProperties.logoLink}
                                                variant={TokenAvatarSizeEnum.medium}
                                                additionalClassName='vault'
                                            />
                                        </Grid>
                                        {v.name}
                                    </Grid>
                                </MenuItem>;
                            })}
                        </Select>
                    </Grid>
                </Grid>
            </Grid>

            {(loading || tokenListLoading) ? <Spinner />
                :
                <div className='scroll-container'>
                    {depositsLoading ? <Spinner /> : <>
                        {(!allActiveDeposits || allActiveDeposits?.length === 0) ?
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>{t('vaults.allocation.allocationNoFound')}</div>
                            :
                            <div className='scroll-section'>
                                {smallScreen ?
                                    <div className='scrollable-content'>
                                        {allActiveDeposits?.length > 0 && allActiveDeposits.map((allocation, idx) => (
                                            <div key={idx}>
                                                <Box sx={{ py: '0.5rem' }}>
                                                    <VaultAllDepositsLineMobile list={tokenList} allocation={allocation} onClick={() => { setOpenVaultDepositDetailsForm(true); setSelectedVault(allocation); }} />
                                                    {idx + 1 < allActiveDeposits.length && <StyledDivider />}
                                                </Box>
                                            </div>
                                        )
                                        )}
                                        {showLoadMore &&
                                            <Grid container>
                                                <Grid item xs={12} justifyContent='center' display='flex' pt={2}>
                                                    <Button onClick={loadMore}
                                                        disabled={loading}
                                                    >{t('transaction.table.loadMore')}</Button>
                                                </Grid>
                                            </Grid>}
                                    </div>
                                    :
                                    <>
                                        <div style={{ paddingTop: '1rem', paddingBottom: '1rem', paddingRight: '1rem' }}>
                                            <VaultAllDepositsHeader hideAutoExtend={hideVaultAutoExtend} />
                                        </div>
                                        <div className='scrollable-content' style={{ paddingRight: '1rem' }}>
                                            {allActiveDeposits?.length > 0 && allActiveDeposits.map((allocation, idx) => (
                                                <div key={idx}>
                                                    <VaultAllDepositsLine allocation={allocation} hideAutoExtend={hideVaultAutoExtend} />
                                                    {idx + 1 < allActiveDeposits.length && <StyledDivider />}
                                                </div>
                                            ))}
                                        </div>

                                        {showLoadMore &&
                                            <Grid container>
                                                <Grid item xs={12} justifyContent='center' display='flex' pt={2}>
                                                    <Button onClick={loadMore}>{t('transaction.table.loadMore')}</Button>
                                                </Grid>
                                            </Grid>
                                        }
                                    </>
                                }
                            </div>
                        }
                    </>}
                </div>
            }

        </Grid >
    );
};

export default VaultUserDeposits;
