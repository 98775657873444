import { Button, Grid, GridProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import MuiSubHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiSubHeader';

interface Props extends GridProps {
    onButtonClick: () => void
}

const TrxGenericError = (props: Props) => {
    const { onButtonClick, ...others } = props;

    const { t } = useTranslation('translations');


    return (
        <Grid container justifyContent='center' mt='30px'{...others}>
            <Grid container maxWidth='313px' rowGap='30px'>
                <Grid item xs={12} >
                    <MuiSubHeader fontWeight={400}> {t('transaction.error')}</MuiSubHeader>
                </Grid>
                <Grid item xs={12}  >
                    <Button variant='customOutlined' onClick={onButtonClick} fullWidth>
                        {t('form.buttons.reloadPage')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>);
};

export default TrxGenericError;
