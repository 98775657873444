import { ThemeOptions } from '@mui/material/styles/createTheme';
import { createTheme } from '@mui/material/styles';

import defaultDarkTheme from '../default/defaultDarkTheme';
//import _ from 'lodash'; 
/* const newTheme: ThemeOptions = _.cloneDeep(defaultDarkTheme); 
newTheme.selectedItemBg.default = 'linear-gradient(96.99deg, #01DDB9 19.1%, #0184FD 71.05%)';
newTheme.iconGradient.stopColor1 = '#01DDB9';
newTheme.highlight= '#01DDB9';
newTheme.highlight= '#01DDB9'; */

const newTheme: ThemeOptions = {
	...defaultDarkTheme,
	selectedItemBg: {
		default: 'linear-gradient(96.99deg, #ECDE2A 21%, #E3B330 84%)'
	},
	iconGradient: {
		...defaultDarkTheme.iconGradient,
		stopColor1: '#ECDE2A',
		stopColor2: '#E3B330'
	},
	highlight: '#ECDE2A',
	carousel: {
		...defaultDarkTheme.carousel,
		iconShadow: '0px 1px 15px 2px rgba(1, 221, 185, 0.41)'
	},
	covers: {
		...defaultDarkTheme.covers,
		productCover: {
			...defaultDarkTheme.covers.productCover,
			bg: [
				//accounts, cards, eran products
				{
					firstBg: 'linear-gradient(329.09deg, #ECDE2A 91.04%, #E3B330 91.05%)',
					secondBg: 'linear-gradient(0deg, #ECDE2A, #E3B330)',
				},
				//wallet
				{
					firstBg: 'linear-gradient(329.09deg, #0500F4 91.04%, #FAFF00 91.05%)',
					secondBg: 'linear-gradient(329.09deg, #0500F4 91.04%, #FAFF00 91.05%)',
				}]

		},
		paymentMethodCover: {
			...defaultDarkTheme.covers.paymentMethodCover,
			bg: [{
				firstBg: 'linear-gradient(96.99deg, #ECDE2A 21%, #E3B330 84%)',
				secondBg: 'linear-gradient(96.99deg, #ECDE2A 21%, #E3B330 84%)',
			},
			{
				firstBg: 'linear-gradient(0deg, #0190DA, #0190DA)',
				secondBg: 'linear-gradient(0deg, #0190DA, #0190DA)',
			}],
		}
	},
	warningBg:  'linear-gradient(112.26deg, #ECDE2A1a 10.81%, rgba(1, 132, 253, 0.1) 160.09%)',
	auth: {
		welcomeCardBg: 'linear-gradient(180deg, #ECDE2A 0%, #E3B330 100%)',
	},
};

const paletteDark = createTheme(newTheme);

export default paletteDark;
