import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SwalPayload, ErrorTranslate } from './types';
import Swal, { SweetAlertResult, SweetAlertOptions } from 'sweetalert2';
import i18n from '../../i18n';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MuiSuccessSvg } from '@/componentsMui/Shared/Widgets/SwalSvg/MuiSuccessSvg';
import { MuiErrorSvg } from '@/componentsMui/Shared/Widgets/SwalSvg/MuiErrorSvg';
import { MuiInfoSvg } from '@/componentsMui/Shared/Widgets/SwalSvg/MuiInfoSvg';
import { CANCEL_2FA } from '../2fa/types';

const t = (value, params?) => {
	return params ? i18n.t(`translations:${value}`, params) : i18n.t(`translations:${value}`);
};

const initialState = {
	header: '',
	content: '',
	open: false
};

const swalSlice = createSlice({
	name: 'swal',
	initialState,
	reducers: {
		openModal(state, action: PayloadAction<SwalPayload>) {
			state.header = action.payload.header;
			state.content = action.payload.content;
			state.open = true;
		},
		closeModal(state) {
			state.header = '';
			state.content = '';
			state.open = false;
		}
	}
});

export const { openModal, closeModal } = swalSlice.actions;

export const swalSelector = (state: { statusStore: SwalPayload }) =>
	state.statusStore;

export const showSuccess = ({ title = 'swal.SUCCESS', titleParams = null, text = null, textParams = null,
	toast = false, html = null, htmlParams = null, confirmBTNText = null }:
	{
		title?: string, titleParams?: any, text?: string, textParams?: any,
		timer?: number, toast?: boolean, html?: string, htmlParams?: any,
		confirmBTNText?: string, mui?: boolean
	} = {}): Promise<SweetAlertResult> => {


	const customIcon = `${MuiSuccessSvg}`;

	const confirmText = confirmBTNText ?? t('swal.RETURN');

	return Swal.fire({
		position: toast ? 'top' : 'center',
		icon: 'success',
		iconHtml: customIcon,
		title: t(title, titleParams),
		text: text ? t(text, textParams) : null,
		html: html ? t(html, htmlParams) : null,
		timer: null,  //timer,
		showConfirmButton: true, //timer == null,
		toast: toast,
		heightAuto: false,
		confirmButtonText: confirmText,
	});
};



export const muiShowSuccess = (title: string, highlightText: string, text: string, subtitle?: string) => {
	showSuccess({
		title: title,
		html: `${subtitle ?? ''} <div class="stressed-text"> ${highlightText}</div> ${text}`
	});
};


export const showException = (err: any, customErrorParams?: ErrorTranslate | ErrorTranslate[], buttonText?: string): Promise<SweetAlertResult> => {

	if (err === CANCEL_2FA) return;
	const customIcon = `${MuiErrorSvg}`;
	const confirmText = (buttonText ?? t('swal.RETURN'));

	if (err?.status === 417) {
		const errorCode: string = getErrorCode(err);
		const errorParam: string = err.data ? (err.data.error_param ?? err?.data?.errors[0]?.error_param) : null;
		const errorValue: string = err.data ? (err.data.param_value ?? err?.data?.errors[0]?.param_value) : null;
		let errorMessage: string = null;

		if (err?.data && err?.data?.errors && err?.data?.errors[0]?.error === 'rejectedBy') {
			return;
		}
		if (errorCode === 'required' && errorParam === 'authenticatorCode' || err?.data && err?.data?.message === 'wrong two factor code') {
			return;
		}

		//this is handled in 2FA form
		if (errorCode === 'invalid' && errorParam === 'authenticatorCode') {
			return;
		}

		if (errorCode === 'required' && (errorParam === 'smsCode' || errorParam === 'emailOtpCode')) {
			return;
		}

		if (errorCode === 'expired' && errorParam === 'code') {
			return;
		}

		if (errorCode === 'userCode') {
			return;
		}

		if (errorCode === 'rejectedBy') {
			return;
		}


		if (errorParam === 'CUSTOM_MESSAGE') {
			const options: SweetAlertOptions = {
				position: 'center',
				title: t('swal.' + errorCode.toUpperCase()),
				icon: 'error',
				text: errorValue,
				heightAuto: false,
				iconHtml: customIcon,
				confirmButtonText: confirmText,
			};
			return Swal.fire(options);
		}
		if (customErrorParams) {
			let customErrors: ErrorTranslate[];
			let params: any = {};

			if (!Array.isArray(customErrorParams)) {
				customErrors = [customErrorParams];
			} else {
				customErrors = customErrorParams;
			}

			for (const customError of customErrors) {
				if (errorCode !== customError.key) {
					continue;
				}
				if (customError.keyParam && errorParam !== customError.keyParam) {
					continue;
				}

				customError.errorParams = customError.errorParams || {};
				params = customError.params || {};

				for (const errorParamKey of Object.keys(customError.errorParams)) {
					if (errorParam === errorParamKey) {
						params.param = t(customError.errorParams[errorParamKey]);
					}
				}

				if (errorValue) {
					params.param_value = errorValue;
				}

				return Swal.fire({
					position: 'center',
					icon: 'error',
					title: t(customError.title || 'errors.SERVER_ERROR'),
					text: t(customError.message, params),
					heightAuto: false,
					iconHtml: customIcon,
					confirmButtonText: confirmText,
				});
			}
		}
		if (errorCode === 'user_error') {
			// show only certain BE returned error
			if (['INVALID_PAYMENT_REFERENCE'].includes(errorParam)) {
				return Swal.fire({
					position: 'center',
					icon: 'error',
					text: errorValue || t('errors.SOMETHING_WENT_WRONG_WITHOT_MESSAGE'),
					heightAuto: false,
					iconHtml: customIcon,
					confirmButtonText: confirmText,
				});
			}
			else if (['USER_INV_VERIFICATION_CODE', 'USER_ACTIVATION_NOT_ALLOWED', 'USER_CARD_ACTIVE','USER_ALREADY_REGISTERED'].includes(errorParam)) {
				return Swal.fire({
					position: 'center',
					icon: 'error',
					text: t(`errors.${errorParam}`),
					heightAuto: false,
					iconHtml: customIcon,
					confirmButtonText: confirmText,
				});
			}
			else {
				return Swal.fire({
					position: 'center',
					icon: 'error',
					text: t('errors.SOMETHING_WENT_WRONG_WITHOT_MESSAGE'),
					heightAuto: false,
					iconHtml: customIcon,
					confirmButtonText: confirmText,
				});
			}
		}
		if (errorCode && errorParam) {
			if (errorCode === 'duplicate') {
				const translationsCode = 'form.fields.' + errorParam;
				const fieldName = t(translationsCode);
				errorMessage = t('errors.ALREADY_EXISTS', { field: fieldName === translationsCode ? errorParam : fieldName });
			}
			if (errorCode === 'invalid' && errorParam === 'verificationCode') {
				errorMessage = t('errors.VERIFICATION_CODE');
			}
			if (errorCode === 'invalid' && errorParam === 'nameOfCard') {
				errorMessage = t('errors.NAME_OF_CARD');
			}
			if (errorCode === 'notFound' && errorParam === 'referralUser') {
				errorMessage = t('errors.REFERRAL_NOT_FOUND');
			}
			if (errorCode === 'invalid' && errorParam === 'invitationCode') {
				errorMessage = t('errors.INVITATION_CODE_NOT_FOUND');
			}
			if (errorCode === 'invalid' && errorParam === 'email') {
				errorMessage = t('errors.INVALID_EMAIL');
			}
			if (errorCode === 'required' && (errorParam === 'referalCode')) {
				errorMessage = t('errors.REFERRAL_REQUIRED');
			}
			else {
				errorMessage = t('errors.FIELD_ERROR', { field: errorParam, message: errorCode });
			}
		}
		else if (errorCode === 'error' && errorParam) {
			t('errors.' + errorParam.toUpperCase());
		}
		else {
			errorMessage = t('errors.SOMETHING_WENT_WRONG', { message: err.message });
		}

		return Swal.fire({
			position: 'center',
			icon: 'error',
			title: t('errors.SERVER_ERROR'),
			text: errorMessage,
			heightAuto: false,
			iconHtml: customIcon,
			confirmButtonText: confirmText,
		});
	}

	if (err?.status === 403 && err.data.message === 'two factor auth is disabled') {
		return Swal.fire({
			position: 'center',
			html: `<span>${t('errors.2FA_REQUIRED_TEXT')} </span><a href="/wallet/security">Click here to move to security settings</a>`,
			icon: 'error',
			title: t('errors.2FA_REQUIRED'),
			heightAuto: false,
			iconHtml: customIcon,
			confirmButtonText: confirmText,
		});
	}

	if (err?.data && err?.data?.message) {
		return Swal.fire({
			position: 'center',
			icon: 'error',
			title: t('errors.SERVER_ERROR'),
			text: err?.data?.message ?? '',
			heightAuto: false,
			iconHtml: customIcon,
			confirmButtonText: confirmText,
		});
	}

	if (err?.message) {
		return Swal.fire({
			position: 'center',
			icon: 'error',
			title: t('errors.SERVER_ERROR'),
			text: err.message,
			heightAuto: false,
			iconHtml: customIcon,
			confirmButtonText: confirmText,
		});
	}
	if (typeof err === 'string') {
		return Swal.fire({
			position: 'center',
			icon: 'error',
			title: err, // t('errors.SERVER_ERROR'),
			//	text: err,
			heightAuto: false,
			iconHtml: customIcon,
			confirmButtonText: confirmText,
		});
	}

	return Swal.fire({
		position: 'center',
		icon: 'error',
		title: t('errors.SERVER_ERROR'),
		text: err?.statusText ? t('errors.SOMETHING_WENT_WRONG', { message: err.statusText }) : t('errors.SOMETHING_WENT_WRONG_WITHOT_MESSAGE'),
		heightAuto: false,
		iconHtml: customIcon,
		confirmButtonText: confirmText,
	});
};


const getErrorCode = (error) => {
	const { data } = error;
	if (data) {
		const { error, errors } = data;
		if (error) {
			return error;
		}
		if (errors) {
			const validationInfo = errors[0];
			const { error } = validationInfo;
			return error;
		}
	}
	return null;
};

export const getErrorWithParams = (error) => {
	try {
		const { data } = error;
		if (data) {
			const { error, errors } = data;
			if (error) {
				return error;
			}
			if (errors) {
				const validationInfo = errors[0];
				const { error, error_param } = validationInfo;
				return { error, error_param };
			}
		}
		return null;
	}
	catch (e) { return null; }
};

export const getErrorWithParamsValue = (error) => {
	try {
		const { data } = error;
		if (data) {
			const { error, errors } = data;
			if (error) {
				return error;
			}
			if (errors) {
				const validationInfo = errors[0];
				const { error, error_param, param_value } = validationInfo;
				return { error, error_param, param_value };
			}
		}
		return null;
	}
	catch (e) { return null; }
};

export const showInfo = ({ title = 'swal.SUCCESS', titleParams = null, text = null, textParams = null, timer = 3000, toast = false }: { title?: string, titleParams?: any, text?: string, textParams?: any, timer?: number, toast?: boolean } = {}): Promise<SweetAlertResult> => {

	const customIcon = `${MuiInfoSvg}`;

	return Swal.fire({
		position: toast ? 'top' : 'center',
		icon: 'info',
		title: t(title, titleParams),
		text: text ? t(text, textParams) : null,
		timer,
		showConfirmButton: timer == null,
		iconHtml: customIcon,
		toast: toast,
		heightAuto: false,
	});
};


export const showSuccessNotification = (content: string) => {
	showSuccess({ title: content });
	//showSuccess({ title: t('tokens.tokenRequestCreated') });
	//toast.success(content);
};

export const showErrorNotification = (err: any, content = t('errors.SOMETHING_WENT_WRONG_WITHOT_MESSAGE'), buttonText?: string) => {

	if (err === CANCEL_2FA) return;
	if (err?.status === 417) {
		const errorCode: string = getErrorCode(err);
		const errorParam: string = err.data ? err.data.error_param : null;

		const errWithParams = getErrorWithParams(err);

		if ((errorCode === 'required' && errorParam === 'authenticatorCode') ||
			(errWithParams && errWithParams?.error === 'required' && errWithParams?.error_param === 'authenticatorCode') ||
			(err.data && err.data.message === 'wrong two factor code')) {
			return;
		}

		if (errorCode === 'userCode') {
			return;
		}
	}
	showException(content, null, buttonText);
	//toast.error(t(content));
};

export const showWarningNotification = (content: string) => {
	toast.warn(t(content));
};

export const showInfoNotification = (content: string) => {
	showInfo({ title: t(content) });


};

export const dismissAllNotifications = () => {
	try {
		toast.dismiss();
	} catch (e) {
		console.log('Error dismissing notifications');
	}

	try {
		Swal.clickCancel();
	} catch (e) {
		console.log('Error dismissing notifications');
	}
};

export const skipError = (e: any) => {
	try {
		const data = e?.data;
		if (data?.errors?.length > 0) {
			const error_param = data.errors[0].error_param;
			const error = data.errors[0].error;
			if (error === 'required' && error_param === 'authenticatorCode') {
				return true;
			} else if (error === 'invalid' && error_param === 'authenticatorCode') {
				return true;
			} else if (error === 'invalid' && error_param === 'smsCode') {
				return true;
			} else if (error === 'required' && error_param === 'smsCode') {
				return true;
			} else if (error === 'invalid' && error_param === 'emailOtpCode') {
				return true;
			} else if (error === 'required' && error_param === 'emailOtpCode') {
				return true;
			}
			else {
				return false;
			}
		}
		else {
			return false;
		}
	}
	catch (e) { return false; }

};


export const showErrornMessage = (errorCode) => {
	return Swal.fire({
		position: 'center',
		icon: 'error',
		title: t('swal.ERROR'),
		text: t('errors.' + errorCode),
		heightAuto: false,
	});
};

export const showValidationErrorMessage = (text: string) => {
	return Swal.fire({
		position: 'center',
		icon: 'error',
		text: text,
		heightAuto: false,
	});
};

export default swalSlice.reducer;
