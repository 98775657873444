import { RootState } from '@/rootReducer';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Token from '@/componentsMui/Wallet/Tokens/Token';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { useTranslation } from 'react-i18next';
import { Box, Button, ButtonGroup, Grid, IconButton, Theme } from '@mui/material';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';
import { useEffect, useState } from 'react';
import { setCoin } from '@features/tabs/slice';
import IndividualTokenDetails from './IndividualTokenDetails';
import { CoinType } from '@features/coins/types';
import AddIcon from '@mui/icons-material/Add';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useHistory } from 'react-router-dom';
import BackIcon from '@customIcons/BackIcon';
import { useTheme } from '@mui/styles';
import StatusCard from '@/componentsMui/Dashboard/HomeSummary/Components/StatusCard';

interface Props {
	currency: string,
}

const TokenList = ({ currency }: Props) => {
	const theme = useTheme() as Theme;
	const { t } = useTranslation('translations');
	const history = useHistory();

	const { list, loading } = useSelector((state: RootState) => state.tokens);
	const { user } = useSelector((state: RootState) => state.user);
	const dispatch = useDispatch();
	const searchParams = new URLSearchParams(location.search);
	const [selectedCoinId] = useState<string>(searchParams.get('token'));
	const [overview, setOverview] = React.useState<boolean>(true);
	const [selectedToken, setSelectedToken] = useState(null);

	const sortedTokens = [...list.filter(t => t.type !== 'WALLET')];

	sortedTokens.sort((a, b) => {
		const aFiatValue = (a?.account?.availableBalance ?? 0);
		const bFiatValue = (b?.account?.availableBalance ?? 0);
		if (aFiatValue > bFiatValue) { return -1; }
		else { return 1; }
	});

	const handleBack = () => {
		dispatch(setCoin(null));
		setSelectedToken(null);
		setOverview(true);
	};

	const { currentCoin } = useSelector(
		(state: RootState) => state.tabs
	);

	const openReceiveForm = () => {
		history.push('/wallet/home/tokens/receive');
	};

	const openTransferForm = () => {
		history.push('/wallet/home/tokens/transfer');
	};

	const handleTrade = () => {
		history.push('/wallet/home/tokens/token/trade');
	};

	const onClickSetCoin = (coin) => {
		setOverview(false);
		setSelectedToken(coin);
		dispatch(setCoin(coin));
	};

	useEffect(() => {
		const coin = list.find(c => c?.info?.symbol === currentCoin?.info?.symbol);
		if (coin) {
			dispatch(setCoin(coin));
			setSelectedToken(coin);
		//	history.push('/wallet/home/tokens');
		}
	}, [dispatch, currentCoin?.info?.symbol, list, history, selectedCoinId]);

	useEffect(() => {
		const coin = list.find(c => c?.info?.symbol === selectedCoinId);
		if (coin) {
			setOverview(false);
			dispatch(setCoin(coin));
		}
	}, [dispatch, list, selectedCoinId]);

	return (
		loading
			? <Box padding={'2rem 1rem'} >
				<Spinner />
			</Box>
			: <>
				{overview && <Grid container justifyContent='space-between' mb='1rem'>
					<Grid item xs={12} container alignItems='center'>
						<Grid item xs={12} sm={4} >
							<MuiHeader>
								{t('wallet.labels.myTokens')}
							</MuiHeader>
						</Grid>
						<Grid item xs={8} sx={{ display: { xs: 'none', md: 'flex' } }} container alignItems='baseline' justifyContent='flex-end' columnSpacing={2} >
							<Grid item>
								<Button variant="simple" startIcon={<AddIcon fontSize='small' />} onClick={openReceiveForm}>{t('wallet.labels.receive')}</Button>
							</Grid>
							<Grid item>
								<Button variant="simple" disabled={user.detained} style={{ filter: user.detained ? 'blur(2px)' : null }} startIcon={<SyncAltIcon fontSize='small' />} onClick={openTransferForm}>{t('wallet.labels.transfer')}</Button>
							</Grid>
							<Grid item>
								<Button variant="simple" startIcon={<AutorenewIcon />} onClick={handleTrade}>{t('wallet.labels.convert')}</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid item container xs={12} alignItems='baseline' justifyContent='center'
						sx={{ display: { xs: 'flex', md: 'none' }, mt: '1rem' }}>
						<ButtonGroup variant="contained" fullWidth={true} aria-label="outlined primary button group" sx={{ height: '100%' }}>
							<Button variant="transparent" onClick={openReceiveForm}>
								{t('wallet.labels.receive')}
							</Button>
							<Button variant='transparent' disabled={user.detained} style={{ filter: user.detained ? 'blur(2px)' : null }}
								onClick={openTransferForm}>
								{t('wallet.labels.transfer')}
							</Button>
							<Button variant='transparent' onClick={handleTrade}>
								{t('wallet.labels.convert')}
							</Button>
						</ButtonGroup>
					</Grid>
				</Grid>}
				<Grid item xs={12} justifyContent='center' >
					<StatusCard />
				</Grid>
				{!overview && selectedToken && <Grid item xs={12} sm={4} mb={2}>
					{/* <FormHeader title={t('cards.businessCards.backToOverview')} showBack={!overview} onBack={handleBack} mb='0' /> */}

					<Grid id='form-header' container alignItems='center' flexWrap='nowrap' >
						{!overview && <Grid item>
							<IconButton onClick={handleBack} sx={{ pl: 0, pr: 0.5 }} size='small' >
								<BackIcon className="stroke" sx={{ stroke: theme.palette.text.secondary }} />
							</IconButton>
						</Grid>}
						<Grid item>
							<MuiHeader fontSize='1rem' color='text.secondary' fontWeight={500}> {t('cards.businessCards.backToOverview')}
							</MuiHeader>
						</Grid>
					</Grid>
				</Grid>}
				<Grid container>
					{overview && <Grid item xs={12}>
						{(!sortedTokens || sortedTokens.length === 0)
							? <div style={{
								display: 'flex',
								justifyContent: 'space-around'
							}}>{t('transaction.table.noTransactions')}</div>
							: <Grid container>
								<Grid item container spacing={4} className=''>
									{sortedTokens?.length > 0 && sortedTokens?.map(item => (
										<Grid item xs={12} sm={6} md={4} key={`${item.type}-${item.info.symbol}`}>
											<Token currency={currency} token={item} hideValues={user.hideValues} onClick={() => onClickSetCoin(item)} />
										</Grid>))}
								</Grid>
							</Grid>
						}
					</Grid>}
					{!overview && selectedToken && <Grid item xs={12}>
						<IndividualTokenDetails currency={currency} token={selectedToken as CoinType} list={sortedTokens} />
					</Grid>}
				</Grid >
			</>
	);
};

export default TokenList;
