import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@/store';
import request from '@services/request';
import { CryptoAccount } from '@features/crypto/types';

const initialState = {
	loading: false,
	cryptoAccounts: null,
	error: null
};


const cryptoSlice = createSlice({
	name: 'stocks',
	initialState,
	reducers: {
		setCryptoAccount(state, action: PayloadAction<CryptoAccount[]>) {
			state.cryptoAccounts = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		}
	}
});

export const { setCryptoAccount, setLoading } = cryptoSlice.actions;

export const getAccounts = (): AppThunk => {
	return async (dispatch) => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/accounts');
			const { data } = response; 
			const accounts = [];
			data.forEach(account => {
				if (account.providerType === 'CRYPTO') {
					accounts.push(account);
				}
			});
			dispatch(setCryptoAccount(accounts));
			return data;
		} catch (e) {
			console.log(e);
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export default cryptoSlice.reducer;
