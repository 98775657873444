import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../rootReducer';
import { closeModal } from '@features/swal/slice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const Swal = (): React.ReactElement => {
	const dispatch = useDispatch();
	const { open, header, content } = useSelector(
		(state: RootState) => state.swal
	);

	return (<Dialog
		maxWidth='sm'
		open={open}
		onClose={() => dispatch(closeModal())}>
		<DialogTitle>{header}</DialogTitle>
		<DialogContent>
			<p>{content}</p>
		</DialogContent>
		<DialogActions>
			<Button variant='contained' onClick={() => dispatch(closeModal())}>Close</Button>
		</DialogActions>
	</Dialog>);
};

export default Swal;
