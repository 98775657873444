import * as React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';

const TransactionListHeader = () => {
	const { t } = useTranslation('translations');
	return (
		<Grid container alignItems='center' >
			<Grid item xs={3} >
				<MuiTextCaption color='text.secondary'>
					{t('transaction.header.name')}
				</MuiTextCaption>
			</Grid>
			<Grid item xs={3}>
				<MuiTextCaption color='text.secondary'>
					{t('transaction.header.date')}
				</MuiTextCaption>
			</Grid>
			<Grid item xs={2}>
				<MuiTextCaption textAlign='right' color='text.secondary'>
					{t('transaction.header.total')}
				</MuiTextCaption>
			</Grid>
			<Grid item xs={2}>
				<MuiTextCaption textAlign='right' color='text.secondary'>
					{t('transaction.header.fee')}
				</MuiTextCaption>
			</Grid>
			<Grid item xs={2}>
				<MuiTextCaption pr={2} textAlign='right' color='text.secondary'>
					{t('transaction.header.status')}
				</MuiTextCaption>
			</Grid>
		</Grid>);
};

export default TransactionListHeader;
