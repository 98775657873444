import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Subscription } from 'rxjs';
import request from '../../services/request';
import { SocketService } from '@services/socketService';
import { AppThunk } from '@/store';
import { StockOrder, Ticket, TicketAttachment, TicketMarkType, TicketResponse, TicketNotification, TicketsStore } from './types';
import { User, UserType } from '../user/types';
import { isEmpty } from 'lodash';

const initialState: TicketsStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	totalPage: 0,
	pageSize: 0,
	unreadCount: 0,
	updateMessage: null,
	updateMessages: false,
	ticket: null
};


const slice = createSlice({
	name: 'tickets',
	initialState,
	reducers: {
		setTickets(state, action: PayloadAction<Array<TicketNotification>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPage = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		},
		setUnreadCount: (state, { payload }: PayloadAction<number>) => {
			state.unreadCount = payload;
		},
		setUpdateMessages: (state, { payload }: PayloadAction<boolean>) => {
			state.updateMessages = payload;
		},
		clearUpdateMessages: (state) => {
			state.updateMessages = false;
		},
		setUpdateMessage: (state, { payload }: PayloadAction<number>) => {
			state.updateMessage = payload;
		},
		clearUpdateMessage: (state) => {
			state.updateMessage = null;
		},
		setTicket: (state, { payload }: PayloadAction<Ticket>) => {
			state.ticket = payload;
		},
		clearTicket: (state) => {
			state.ticket = null;
		},
	}
});

export const { setTickets, setLoading, setError, setCount, setTotalPage, setPageSize, setUnreadCount, setUpdateMessages, clearUpdateMessages, setUpdateMessage, clearUpdateMessage, setTicket, clearTicket } = slice.actions;

export const getTicketsPerPage = (take: number, filter?: string): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			dispatch(setError(null));
			const response = await request.get('/api/v3/notifications/tickets', {
				params: {
					filter: filter ?? '',
					orderBy: 'createdDate',
					sort: 'DESC',
					skip: 0,
					take: take
				}
			});
			const { data } = response;
			dispatch(setTickets(data.list));
			dispatch(setCount(data.count));
			dispatch(setTotalPage(Math.ceil(data.count / initialState.pageSize)));
			dispatch(clearUpdateMessages());
			return data;
		} catch (e) {
			dispatch(setTickets([]));
			dispatch(setError('failed'));
		}
		finally { dispatch(setLoading(false)); }
	};
};

export const getNotificationsPerPage = (take: number): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			dispatch(setError(null));
			const response = await request.get('/api/v3/notifications', {
				params: {
					filter: 'status=PENDING_USER',
					orderBy: 'createdDate',
					sort: 'DESC',
					skip: 0,
					take: take
				}
			});
			const { data } = response;
			dispatch(setTickets(data.list));
			dispatch(setCount(data.count));
			dispatch(setTotalPage(Math.ceil(data.count / initialState.pageSize)));
			dispatch(clearUpdateMessages());
			return data;
		} catch (e) {
			dispatch(setTickets([]));
			dispatch(setError('failed'));
		}
		finally { dispatch(setLoading(false)); }
	};
};

export const getTicket = (id: number): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setError(null));
			dispatch(clearUpdateMessage());
			const response = await request.get(`/api/tickets/${id}`);
			const { data } = response;
			dispatch(setTicket(data));
			return data;
		} catch (e) {
			dispatch(setError('failed'));
		}
	};
};


export const markNotification = (type: TicketMarkType, ticketId: number): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setError(null));
			dispatch(clearUpdateMessage());
			await request.get(`/api/v3/notifications/mark/${type}/${ticketId}`);
		} catch (e) {
			dispatch(setError('failed'));
		}
	};
};



export const deleteTicket = (id: number): AppThunk => {
	return async dispatch => {
		dispatch(setTicket());
		const response = await request.delete(`/api/tickets/${id}`);
		const { data } = response;
		return data;
	};
};

export const getUnreadCount = (): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setError(null));
			const response = await request.get('/api/v3/notifications/unread-count');
			const { data } = response;
			dispatch(setUnreadCount(data.count));
			return data;
		} catch (e) {
			dispatch(setError('failed'));
		}
	};
};

/* export const markNotification = async (type: TicketMarkType, ticketId : number) => {
	const response = await request.get(`/api/v3/notifications/mark/${type}/${ticketId}`);
	const { data } = response;
	return data;
}; */

export const postTicket = async (payload): Promise<TicketResponse> => {
	const response = await request.post('/api/tickets', payload);
	const { data } = response;
	return data;
};

export const postSupportEmail = async (payload): Promise<TicketResponse> => {
	const response = await request.post('/api/tickets/support-email', payload);
	const { data } = response;
	return data;
};

export const replyTicket = async (payload) => {
	const response = await request.post('/api/tickets/replay', payload);
	const { data } = response;
	return data;
};

export const postPaymentRequest = async (payload) => {
	const response = await request.post('/api/tickets/payment-request', payload);
	const { data } = response;
	return data;
};

export const postStockOrder = async (payload: StockOrder) => {
	const response = await request.post('/api/tickets/stock-order', payload);
	const { data } = response;
	return data;
};

export const getAuthorInitials = (author: User): string => {
	if (author.type === UserType.BUSINESS) {
		return author.companyName.charAt(1).toUpperCase();
	} else {
		return author.firstName.charAt(0).toUpperCase() + author.lastName.charAt(0).toUpperCase();
	}
};

export const downloadAttachment = async (id: number, attachment: TicketAttachment) => {
	const response = await request.get(`/api/tickets/attachment/${id}/${attachment.key}`,
		{
			responseType: 'blob',
			params: {
				cacheBustTimestamp: Date.now(),
			},
		});
	const url = window.URL.createObjectURL(response.data);
	const link = document.createElement('a');
	link.download = attachment.name;
	link.href = url;
	link.className = 'hidden';
	document.body.appendChild(link);

	link.onclick = function () {
		requestAnimationFrame(function () {
			URL.revokeObjectURL(url);
			setTimeout(() => link.remove(), 300);
		});
	};

	link.click();
};

export const markNotificationsAsRead = async () => {
	try {
		await request.get('/api/v3/notifications/mark-chat-read');
	} catch (error) {
		console.log('markNotificationsAsRead error');
	}
};

let socketService: SocketService;
let supportSubscriber: Subscription;
let supportMessageSubscriber: Subscription;
let allReadSubscriber: Subscription;
let chatSubscriber: Subscription;

export const connect = (): void => {
	if (!socketService) {
		socketService = new SocketService('support');
	}
};

export const disconnect = (): void => {
	socketService = null;
};

export const subscribe = (): AppThunk => {
	return async dispatch => {
		try {
			supportSubscriber = socketService.listen('support.messages', {}).subscribe((data) => {
				if (data === 'updated' || data === 'new') {
					dispatch(setUpdateMessages(true));
					dispatch(getUnreadCount());
				}
			});
			supportMessageSubscriber = socketService.listen('support.message', {}).subscribe((data) => {
				dispatch(setUpdateMessage(data));
				dispatch(getUnreadCount());
			});
			allReadSubscriber = socketService.listen('notifications', {}).subscribe((data) => {
				if (data === 'read-all') {
					dispatch(getUnreadCount());
				}
			});
			chatSubscriber = socketService
				.listen('chat.message', {})
				.subscribe((data) => {
					if (isEmpty(data)) {
						return;
					}
					dispatch(getUnreadCount());
				});

		} catch (e) {
			console.log(e);
			dispatch(setError(e));
		}
	};
};

export const unsubscribe = (): void => {
	supportSubscriber.unsubscribe();
	supportMessageSubscriber.unsubscribe();
	allReadSubscriber.unsubscribe();
	chatSubscriber.unsubscribe();
};


export default slice.reducer;
