import request from '../../services/request';
import { TransactionsLazyPayload, TransactionsTypedPayload, TransactionsPayload, TransactionsResult, TransactionType, DectaCardTransactionsPayload, GeneralCardTransactionsPayload } from './types';
import { saveAs } from 'file-saver';


export const getExchangeDetails = async (docNumber: string): Promise<void> => {
	const response = await request.get(`/api/transactions/exchange/details/${docNumber}`);
	const { data } = response;
	return data;
};

export const getTransactionsLazy = async (payload: TransactionsLazyPayload): Promise<TransactionsResult> => {
	const response = await request.post('/api/transactions/v3/lazy', payload);
	const { data } = response;
	return data;
};

export const getTransactionsTyped = async (payload: TransactionsTypedPayload): Promise<TransactionsResult> => {
	const response = await request.post('/api/transactions/v3/lazy/typed', payload);
	const { data } = response;
	return data;
};

export const getExcel = async (payload: TransactionsPayload, filename: string): Promise<void> => {
	await request.post('/api/notifications/templates/excel',
		payload, { responseType: 'arraybuffer' }).then((response) => {
			const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			saveAs(blob, filename);
		});
};

export const getExtendedExcel = async (payload: TransactionsPayload, filename: string): Promise<void> => {
	await request.post('/api/notifications/statements/excel',
		payload, { responseType: 'arraybuffer' }).then((response) => {
			const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			saveAs(blob, filename);
		});
};

export const getExchangePdf = async (payload: any) => {
	const file = await request.post('/api/notifications/templates/pdf/exchange',
		payload, { responseType: 'arraybuffer' }).then((response) => {
			const blob = new Blob([response.data], { type: 'application/pdf' });
			return blob;
		});
	return file;
};


export const getStatementPdfContent = async (payload: any) => {
	const file = await request.post('/api/notifications/statements/pdf',
		payload,
		{ responseType: 'arraybuffer' }).then((response) => {
			const blob = new Blob([response.data], { type: 'application/pdf' });
			return blob;
		});

	return file;
};

export const getTransactionPdfContent = async (payload: any) => {
	const file = await request.post('/api/notifications/templates/pdf',
		payload, { responseType: 'arraybuffer' }).then((response) => {
			const blob = new Blob([response.data], { type: 'application/pdf' });
			return blob;
		});
	return file;
};


export const sendPdfByEmail = async (email: string, transaction: any, pdfPayload: any, transactionActivity?: TransactionType) => {
	const reponse = transactionActivity === TransactionType.FX_TRANSACTION ?
		await request.post('/api/notifications/templates/pdf/exchange', pdfPayload, { responseType: 'arraybuffer' }) :
		await request.post('/api/notifications/templates/pdf', pdfPayload, { responseType: 'arraybuffer' });
	const base64 = btoa(
		new Uint8Array(reponse.data)
			.reduce((data, byte) => data + String.fromCharCode(byte), '')
	);
	const payload = {
		identifier: 'user.transaction.details.sent',
		payload: {
			...transaction,
			email: email,
			file: base64
		}
	};

	await request.post('/api/notifications/trigger',
		payload);
};

export const getDectaCardTransactions = async ( payload: DectaCardTransactionsPayload | GeneralCardTransactionsPayload) => {

	const response = await getDectaCardTransactionsActualDecta(payload);

	const { data } = response;
	return data;
};


export const getDectaCardTransactionsOnhold = async (accountId: number) => {
	const response = await request.post('/api/transactions/cards/holds', { accountId });
	const { data } = response;
	return data;
};


export const getDectaCardTransactionsActualDecta = async (payload: DectaCardTransactionsPayload) => {
	/* const uri = `/api/transactions/card/${payload.accountId}?`;
	const filter = `filter=${payload?.filter ?? ''}`;
	const from = `from=${payload?.from ?? ''}`;
	const initial = `initial=${payload?.initial ?? ''}`;
	const skip = `skip=${payload?.skip ?? ''}`;
	const sort = `sort=${payload?.sort ?? ''}`;
	const sortBy = `sortBy=${payload?.sortBy ?? ''}`;
	const to = `to=${payload?.to ?? ''}`;
	const take = `take=${payload?.take ?? ''}`;

	const requestUrl = uri + [take, filter, from, initial, skip, sort, sortBy, to].join('&');
	const response = await request.get(requestUrl); */
	const response = await request.get(`/api/transactions/card/${payload.accountId}`, {
		params: {
		  isSystem: false,
		  filter: payload?.filter ? `details=${payload?.filter}` : '',
		  from: payload?.from ?? '',
		  initial: payload?.initial ?? '',
		  skip: payload?.skip ?? '',
		  sort: payload?.sort ?? '',
		  sortBy: payload?.sortBy ?? '',
		  to: payload?.to ?? '',
		  take: payload?.take ?? '',
		}
	  });
	return response;
};


export const getSHCardTopupTransactions = async (params: any) => {
	const response = await request.get('/api/cards/topup/transaction/list', { params });

	const { data } = response;
	return data;
};
