import request from '@/services/request';
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { alpha3ToAlpha2 } from 'i18n-iso-countries';
import { CardShipmentCountry, CountryCodeStore, ResidencyCountryCode } from './types';


const initialState: CountryCodeStore = {
	loading: true,
	list: [],
	error: null,
	residencyLoading: true,
	residencyList: [],
	residencyError: null,
	cardShipmentCountry: [],
};

const countrySlice = createSlice({
	name: 'allowedCountries',
	initialState,
	reducers: {
		setCountries(state, action: PayloadAction<Array<{ countryCode: string }>>) {
			state.list = action.payload.map(p => alpha3ToAlpha2(p.countryCode)) || [];
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload?.toString();
		},
		setResidencyCountries(state, action: PayloadAction<Array<{ countryCode: string }>>) {
			state.residencyList = action.payload.map(p => alpha3ToAlpha2(p.countryCode)) || [];
		},
		setResidencyLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.residencyLoading = payload;
		},
		setResidencyError: (state, { payload }: PayloadAction<string>) => {
			state.residencyError = payload?.toString();
		},
		setCardShipmentCountries(state, action: PayloadAction<Array<CardShipmentCountry>>) {
			state.cardShipmentCountry = action.payload;
		},
	}
});

export const { setCountries, setLoading, setError, setResidencyCountries, setResidencyLoading, setResidencyError, setCardShipmentCountries } = countrySlice.actions;

export const getAllowedCountries = (): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/auth/countries');
			const { data } = response;
			dispatch(setCountries(data));
			return data;
		} catch (e) {
			dispatch(setError(e?.toString()));
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const getResidencySumsubCountries = () => {
	return async dispatch => {
		try {
			dispatch(setResidencyLoading(true));
			const response = await request.get('/api/identity/residency/list');
			const { data } = response;
			dispatch(setResidencyCountries(data));
			return data;
		} catch (e) {
			dispatch(setResidencyError(e?.toString()));
		} finally {
			dispatch(setResidencyLoading(false));
		}
	};
};


export const getResidencyCountries = async (): Promise<ResidencyCountryCode[]> => {
	const response = await request.get('/api/auth/countries');

	if (response?.data) {
		return response?.data;

	}
	else return [];
};

export const getCardShipmentCountry = () => {
	return async dispatch => {
	  try {
		dispatch(setLoading(true));
		const response = await request.get('/api/cards/delivery/countries');
		const data = response?.data || [];
		dispatch(setCardShipmentCountries(data));
	  } catch (e) {
		dispatch(setCardShipmentCountries([]));
	  } finally {
		dispatch(setLoading(false));
	  }
	};
  };
  


export default countrySlice.reducer;
