import { CreateInvoicePayload, OrderDetails, PaymentTypes } from '@features/invoices/invoicesTypes';
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import React, { useMemo } from 'react';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import LoadingButton from '@/componentsMui/Shared/Components/LoadingButton';
import { updateInvoice } from '@/features/invoices/invoicesSlice';
import { showErrorNotification } from '@/features/swal/slice';
import FormDateField from '@/componentsMui/Shared/FormikComponents/FormDateField';
import FormikAutocompleteOnlyValue from '@/componentsMui/Shared/FormikComponents/FormikAutocompleteOnlyValue';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';

interface Props {
    open: boolean;
    onClose: () => void;
    order: OrderDetails;
    onSave: (response: any) => void;
}

const SubmitInvoiceDialog = ({ open, onClose, onSave, order }: Props) => {
    const { t } = useTranslation('translations');
    const isCryptoOneTimeAddress = order.paymentTypes.includes(PaymentTypes.CRYPTO_ONE_TIME_ADDRESSES);
    const { cryptoCurrencies } = useSelector((state: RootState) => state.invoices.options);

    const next30day = useMemo(() => {
        const d = new Date();
        d.setDate(d.getDate() + 30);
        return d;
    }, []);

    const initialValues = {
        description: '',
        userNotes: '',
        dueDate: '',
        cryptoCurrencyCode: '',
    };

    const validationScheme = Yup.object({
        dueDate: Yup.date()
            .required(t('form.validator.required'))
            .typeError(t('form.validator.invalidDateFormat')),
        description: Yup.string().required(t('form.validator.required')),
        cryptoCurrencyCode: Yup.string().test('cryptoCurrencyCode', t('form.validator.required'), (value) => {
            if (isCryptoOneTimeAddress && !value) {
                return false;
            } else {
                return true;
            }
        })
    });


    const submit = async (formData: any, formikProps: any) => {
        const { setSubmitting } = formikProps;
        const { description, userNotes, cryptoCurrencyCode, dueDate } = formData;

        setSubmitting(true);
        const _payload: CreateInvoicePayload = {
            dueDate,
            description,
            userNotes,
            paymentTypes: order.paymentTypes,
            products: order.products,
            ...(isCryptoOneTimeAddress && { cryptoCurrencyCode }),
            issue: true,
        };

        try {
            const orderResponse = await updateInvoice(order.id, _payload);
            onSave(orderResponse);
        } catch (err) {
            showErrorNotification(err);
            onClose();
        } finally {
            setSubmitting(false);
        }
    };




    return (
        <Dialog
            open={open}
            maxWidth='xs'
        >
            <DialogTitle>
                <FormHeader
                    title={t('invoice.submit')}
                    onBack={onClose}
                />
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationScheme}
                    onSubmit={submit}
                    enableReinitialize
                >
                    {({ isSubmitting, errors }) => (
                        <Form>
                            <Grid container gap={1}>
                                <Grid item xs={12}>
                                    <FormDateField
                                        name='dueDate'
                                        label={t('invoice.dueDate')}
                                        fullWidth
                                        disablePast
                                        maxDate={next30day}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        name='description'
                                        label={t('invoice.description')}
                                        fullWidth
                                        multiline
                                        maxRows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        name='userNotes'
                                        label={t('invoice.userNotes')}
                                        fullWidth
                                        multiline
                                        maxRows={3}
                                    />
                                </Grid>
                                {isCryptoOneTimeAddress && <Grid item xs={12}>
                                    <FormikAutocompleteOnlyValue
                                        fieldName='cryptoCurrencyCode'
                                        label={t('invoice.cryptoCurrencyCode')}
                                        options={cryptoCurrencies}
                                        fullWidth
                                    />
                                </Grid>}


                                <Grid item xs={12} mt={2}>
                                    <LoadingButton
                                        disabled={isSubmitting || !isEmpty(errors)}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting}
                                    >
                                        {t('invoice.submit')}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default SubmitInvoiceDialog;
