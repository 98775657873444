import { Box, FormLabel, Grid, Slider, Theme, useTheme } from '@mui/material';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Token.css';
import * as Yup from 'yup';
import { showException } from '@/features/swal/slice';
import { useDispatch, useSelector } from 'react-redux';
import '@/componentsMui/Shared/Shared.css';
import {
	getCoins,
	postCryptoOrder,
	setFromAsset,
	setToAsset,
	setTradeSwapped,
	transformAccountToAssets,
	transformTokensToAssets
} from '@/features/coins/slice';
import { AssetType, CoinPayinfoPayload, CoinTradePayload, CoinType, CoinTypeEnum } from '@/features/coins/types';
import { containsDecimals } from '@/helpers/number';
import useCoinFeeDebounce from '@/helpers/customHook/useCoinFeeDebounce';
import bigDecimal from 'js-big-decimal';
import { RootState } from '@/rootReducer';
import AssetSelectDialog from './AssetSelectDialog';
import AssetSelect from '@/componentsMui/Shared/FormikComponents/AssetSelect';
import { LoadingButton } from '@mui/lab';
import SwapButton from '@/componentsMui/Shared/Widgets/SwapButton';
import { WalletType } from '@features/walletHistory/types';
import MuiTextFieldResize from '@/componentsMui/Shared/FormikComponents/MuiTextFieldResize';
import { getTokens, postTokenOrder } from '@features/tokens/slice';
import MaintenanceChip from '@/componentsMui/Shared/Widgets/MaintenanceChip';
import useFetchWithDelay from '@/helpers/customHook/useFetchWithDelay';

interface Props {
	walletType: WalletType,
	coinHoldings: CoinType[],
	fiatHoldings: CoinType[],
	fromDetails?: boolean,
	onSuccess?: () => void,
	onFromChange?: (acountId: string) => void,
	onToChange?: (acountId: string) => void
}

const swapUiItems = (swapped: boolean) => {


	const fromDocuments = document.getElementsByClassName('from-asset-id');
	const fromLast = (fromDocuments?.length ?? 1) - 1;

	const toDocuments = document.getElementsByClassName('to-asset-id');
	const toLast = (toDocuments?.length ?? 1) - 1;


	if (!swapped) {

		fromDocuments[fromLast].classList.remove('move-up');
		fromDocuments[fromLast].classList.add('move-down');
		toDocuments[toLast].classList.remove('move-down');
		toDocuments[toLast].classList.add('move-up');
	}

	if (swapped) {


		fromDocuments[fromLast].classList.remove('move-down');
		fromDocuments[fromLast].classList.add('move-up');

		toDocuments[toLast].classList.remove('move-up');
		toDocuments[toLast].classList.add('move-down');

	}
};



const CryptoTrade = ({ coinHoldings, fiatHoldings, onSuccess, onFromChange, onToChange, walletType, fromDetails = false }: Props): React.ReactElement => {
	const theme = useTheme() as Theme;
	const { t } = useTranslation('translations');
	const { fetchWithDelay } = useFetchWithDelay();

	const dispatch = useDispatch();
	const { fromAsset, toAsset, swaped } = useSelector((state: RootState) => state.coins);
	//const [swaped, setSwaped] = useState<boolean>(false);

	const [openAssetDialog, setOpenAssetDialog] = useState<boolean>(false);

	const [sideType, setSideType] = useState<'FROM' | 'TO'>('FROM');
	const [assetList, setAssetList] = useState<AssetType[]>([]);

	const [assetAmount, setAssetAmount] = useState<number>(null);
	const { payInfo, initPayInfo, payInfoError, callGetPayInfo, setPayinfo, setPayinfoError, payInfoLoading } = useCoinFeeDebounce();
	const [max, setMax] = useState<boolean>(false);

	const coinAssets = transformTokensToAssets(coinHoldings);
	const fiatAssets = transformAccountToAssets(fiatHoldings);

	const fromAssetList = fiatAssets?.concat(coinAssets.filter(p => p.sellEnabled));

	const toAssetList = React.useMemo(() => {
		if (fromAsset?.symbol) {
			let newList = [];
			if (walletType === WalletType.TOKEN) {
				newList = fiatAssets?.concat(coinAssets).filter(p => p.symbol !== fromAsset.symbol && p.buyEnabled === true
					&& p?.supportedCcy?.length > 0 && (p?.supportedCcy?.map(a => a.ccy)?.includes(fromAsset.symbol) || fromAsset?.supportedCcy?.map(a => a.ccy)?.includes(p.symbol)));
			} else {
				newList = fiatAssets?.concat(coinAssets).filter(p => p.symbol !== fromAsset.symbol && p.buyEnabled === true
					&& p?.supportedCcy?.length > 0 && p?.supportedCcy?.map(a => a.ccy)?.includes(fromAsset.symbol));
			}
			const isToAssetInList = newList.find(p => p?.symbol === toAsset?.symbol);
			if (!isToAssetInList) { dispatch(setToAsset(newList[0] || null)); }

			return newList;
		}
		else {
			return [];
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [dispatch, fromAsset, fiatAssets, coinAssets]);

	/* Set initial value for from  asset */
	useEffect(() => {
		if (!fromAsset && fromAssetList[0] && !fromDetails) {
			dispatch(setFromAsset(fromAssetList && fromAssetList[0]));
		}
	}, [fromAsset, fromAssetList, dispatch, fromDetails]
	);

	/* default to Asset */
	useEffect(() => {
		if (!toAsset && toAssetList[0] && !fromDetails) {
			dispatch(setToAsset(toAssetList && toAssetList[0]));
		}
	}, [toAsset, toAssetList, dispatch, fromDetails]);


	const maxAmount = fromAsset?.availableBalance ?? 0;
	const currentCoin = fiatAssets?.concat(coinAssets).find(p => p.id === (fromAsset?.type === CoinTypeEnum.WALLET ? toAsset?.id : fromAsset?.id));
	const isMaintenance = currentCoin?.transferProcess?.maintenance;
	const isCryptoTokenSwap = toAsset?.defiToken || fromAsset?.defiToken;

	const initialValues = {
		fromAsset: '',
		toAsset: '',
		assetAmount: '',
		youReceive: ''
	};

	const minTradeAmount = useMemo(() => {
		if (!toAsset || !fromAsset) return { amount: 0, side: 'FROM' };

		const pair = fromAsset.supportedCcy.find(sccy => sccy.ccy === toAsset.symbol);

		if (!pair) return { amount: 0, side: 'FROM' };

		if (fromAsset.defiToken && !toAsset.defiToken) {
			return { amount: fromAsset.miniumTradeAmount, side: 'FROM' };
		} else if (!fromAsset.defiToken && toAsset.defiToken) {
			return { amount: toAsset.miniumTradeAmount, side: 'TO' };
		}

		if (pair.base) {
			if (fromAsset.minSide === 'BASE') {
				return { amount: fromAsset.miniumTradeAmount, side: 'FROM' };
			} else {
				return { amount: toAsset.miniumTradeAmount, side: 'TO' };
			}
		} else {
			if (fromAsset.minSide === 'BASE') {
				return { amount: toAsset.miniumTradeAmount, side: 'TO' };
			} else {
				return { amount: fromAsset.miniumTradeAmount, side: 'FROM' };
			}
		}

	}, [fromAsset, toAsset]);

	const validationSchema =
		Yup.object({
			youReceive: Yup.number()
				.test('fromAsset', t('tokens.validations.from'), () => !isEmpty(fromAsset))
				.test('toAsset', t('tokens.validations.to'), () => !isEmpty(toAsset))
				.test('toAsset', t('tokens.validations.buyingDisabled'), () => toAsset?.buyEnabled)
				.test('fromAsset', t('tokens.validations.sellDisabled'), () => fromAsset?.sellEnabled)
				.test('assetAmount', t('tokens.validations.amount'), () => assetAmount > 0)
				.test('assetAmount', t('form.error.insufficientFund'), () => bigDecimal.compareTo(assetAmount, fromAsset?.availableBalance) <= 0)
				.test('assetAmount', t('tokens.validations.wholeNumber'), () => ((!(fromAsset?.precision === 0 && containsDecimals(assetAmount))))),
		});



	const submit = async (formData, formikProps) => {
		const { setSubmitting, setFieldError } = formikProps;

		setSubmitting(true);
		try {

			if (!currentCoin?.transferProc) {
				setFieldError('youReceive', t('tokens.unavailable'));
				return;
			}

			if (minTradeAmount.side === 'TO') {
				if (bigDecimal.compareTo(payInfo?.toAmount, minTradeAmount.amount) < 0) {
					setFieldError('youReceive', t('tokens.validations.minAmountSymbol', { amount: parseFloat(minTradeAmount.amount.toString()), symbol: toAsset.symbol }));
					return;
				}
			} else {
				if (bigDecimal.compareTo(assetAmount, minTradeAmount.amount) < 0) {
					setFieldError('youReceive', t('tokens.validations.minAmountSymbol', { amount: parseFloat(minTradeAmount.amount.toString()), symbol: fromAsset.symbol }));
					return;
				}
			}

			const order: CoinTradePayload = {
				operation: currentCoin?.transferProc,
				fromAmount: assetAmount,
				fromCcy: fromAsset?.symbol,
				toCcy: toAsset?.symbol,
				accountFromId: fromAsset?.accountId,
				accountToId: toAsset?.accountId,
				max: max
			};

			if (bigDecimal.compareTo(assetAmount, maxAmount) === 0) {
				order.max = true;
			}
			if (walletType === WalletType.TOKEN) {
				await postTokenOrder(order);
				fetchWithDelay(getTokens(true), [3000, 5000, 10000]);
			} else {
				await postCryptoOrder(order);
				fetchWithDelay(getCoins(true), [3000, 5000, 10000]);
			}
			setAssetAmount(null);
			onSuccess && onSuccess();
		} catch ({ data }) {
			if ((data.errors.length > 0) && data.errors[0].error === 'insufficient' && data.errors[0].error_param === 'funds') {
				setFieldError('youReceive', t('form.error.insufficientFund'));
			} else {
				showException(data);
			}
		} finally {
			setSubmitting(false);
		}
	};

	const getPayInfoPayload = useCallback((amount) => {
		return {
			walletType,
			fromAccountId: fromAsset?.accountId,
			fromCcy: fromAsset?.symbol,
			toCcy: toAsset?.symbol,
			toAccountId: toAsset?.accountId,
			fromAmount: amount,
			proc: currentCoin?.transferProc,
			cryptoTokenSwap: isCryptoTokenSwap,
		} as CoinPayinfoPayload;
	}, [currentCoin?.transferProc, toAsset?.symbol, fromAsset?.symbol, fromAsset?.accountId, toAsset?.accountId, walletType, isCryptoTokenSwap]);

	const handleToAssetChange = (value: AssetType) => {
		dispatch(setToAsset(value));
		if (value && fromAsset && value.symbol === fromAsset.symbol) {
			dispatch(setFromAsset(null));
		}
		const payLoad = getPayInfoPayload(assetAmount);
		payLoad.toCcy = value?.symbol;
		payLoad.toAccountId = value?.accountId;
		payLoad.cryptoTokenSwap = value?.defiToken || fromAsset?.defiToken;
		payLoad.proc = value?.transferProc;
		callGetPayInfo(payLoad);
		setMax(false);
		onToChange && onToChange(value?.accountId?.toString());
	};

	const handleFromAssetChange = (value: AssetType) => {
		dispatch(setFromAsset(value));
		if (value && toAsset && value.symbol === toAsset.symbol) {
			dispatch(setToAsset(null));
		}
		setAssetAmount(null);
		setPayinfoError(false);
		setPayinfo(initPayInfo);
		setMax(false);
		onFromChange && onFromChange(value?.accountId?.toString());
	};

	const handleSwapAssets = (onSwap: () => void) => {
		const yourReceive = parseFloat(new bigDecimal(payInfo?.toAmount).round(toAsset?.precision, bigDecimal.RoundingModes.DOWN).getValue());
		setAssetAmount(null);
		setPayinfoError(false);
		const payLoad = getPayInfoPayload(yourReceive);
		payLoad.fromCcy = toAsset?.symbol;
		payLoad.toCcy = fromAsset?.symbol;
		dispatch(setToAsset(fromAsset));
		dispatch(setFromAsset(toAsset));
		onSwap && onSwap();
		dispatch(setTradeSwapped(!swaped));
		//setSwaped(prev => !prev);
		swapUiItems(swaped);
		setMax(false);

		//setTimeout(() => setFieldTouched('youReceive', true));
	};

	const handleSliderChange = (event: Event, newValue: number | number[]) => {
		const value = newValue as number;
		if (value === 100) {
			setMax(true);
		} else {
			setMax(false);
		}
		setAssetAmount(parseFloat((new bigDecimal(parseFloat(fromAsset?.availableBalance) / 100 * value).round(fromAsset.precision ?? 2, bigDecimal.RoundingModes.DOWN).getValue())));
	};

	const handleSliderChangeCommited = (event: any, newValue: any, setFieldValue, setFieldTouched) => {
		const value = newValue as number;
		const amount = parseFloat((new bigDecimal(parseFloat(fromAsset?.availableBalance) / 100 * value).round(fromAsset.precision ?? 2, bigDecimal.RoundingModes.DOWN).getValue()));
		if (value === 0) {
			setPayinfo(initPayInfo);
		}
		else {
			callGetPayInfo(getPayInfoPayload(amount));
		}
		setFieldValue('assetAmount', amount);
		setTimeout(() => setFieldTouched('youReceive', true));
	};

	const handleAmountChange = (event, setFieldValue, setFieldTouched, type?: 'FROM' | 'TO') => {

		const value = event.target.value?.replace(',', '.');

		if (type !== 'FROM') return;
		if (value === '.') return;

		const regex = /^[0-9.]*$/;

		if (value === '' || regex.test(value)) {

			const newAmount = value as number;

			if (bigDecimal.compareTo(newAmount, maxAmount) <= 0 && bigDecimal.compareTo(newAmount, 0) >= 0) {
				callGetPayInfo(getPayInfoPayload(newAmount));
				setAssetAmount(newAmount);
				setFieldValue('assetAmount', newAmount);
				setMax(false);
				setTimeout(() => setFieldTouched('youReceive', true));
			}
		}
	};

	const handleSelectAsset = (type: 'FROM' | 'TO') => {
		setSideType(type);
		if (type === 'FROM') {
			setAssetList(fromAssetList);
		} else {
			setAssetList(toAssetList);
		}
		setOpenAssetDialog(true);
	};

	const handleCloseAssetDialog = (asset?: AssetType, type?: 'FROM' | 'TO') => {
		setOpenAssetDialog(false);
		if (asset && type) {
			if (type === 'FROM') {
				handleFromAssetChange(asset);
			} else {
				handleToAssetChange(asset);
			}
		}
	};

	const youReceiveDisplayValue = (assetAmount === 0 || assetAmount === null) ? '' : new bigDecimal(payInfo?.toAmount).round(toAsset?.precision, bigDecimal.RoundingModes.DOWN).getValue() ?? '';
	const assetDisplayValue = assetAmount ?? '';
	const coinPlaceholder = new bigDecimal(0).round(currentCoin?.precision, bigDecimal.RoundingModes.DOWN).getValue();
	const isToAmountNegative = bigDecimal.compareTo(payInfo?.toAmount ?? 0, 0) < 0;


	return (
		<Box id='token-trade' sx={{ display: 'flex', flex: 1, maxWidth: { xs: '100%', md: '31.25rem' } }}>
			<AssetSelectDialog
				open={openAssetDialog}
				onClose={handleCloseAssetDialog}
				assets={assetList}
				type={sideType}
			/>
			<Formik
				//enableReinitialize={true}
				validateOnChange={true}
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting, setFieldValue, setFieldTouched, resetForm }) => {
					const formError = !isEmpty(errors) || payInfoError;
					return (
						<Form className='flex-container'>
							<Grid container className='trade-container'>
								<Grid container item xs={12} className='from-asset-id' >
									<Grid item container xs={12}
										sx={{
											border: formError && !swaped ? '1px solid ' + theme.statusColor.lightRed : 'unset',
											borderRadius: '10px'
										}}>
										<Grid item xs={6} className='asset-select' sx={{ background: theme.palette.background.paper, }}>
											<AssetSelect
												fieldName='fromAsset'
												selectedAsset={swaped ? toAsset : fromAsset}
												onClick={() => handleSelectAsset(swaped ? 'TO' : 'FROM')}
												className='straight-right-border  token-input'
											/>
										</Grid>
										<Grid item xs={6} className='asset-input' >
											<MuiTextFieldResize
												sx={{ input: { color: assetAmount === 0 || assetAmount === null ? 'text.secondary' : 'text.primary' } }}
												inputProps={{ autoComplete: 'off' }}
												name="assetAmount"
												placeholder={'0.00'}
												fullWidth
												value={swaped ? youReceiveDisplayValue : assetDisplayValue}
												onChange={(e) => handleAmountChange(e, setFieldValue, setFieldTouched, swaped ? 'TO' : 'FROM')}
												className='straight-left-border  token-value-input token-input'
											/>
										</Grid>
									</Grid>
								</Grid>
								<div className='trade-direction'>
									<SwapButton onClick={() => handleSwapAssets(resetForm)}
										disabled={payInfoLoading}
									/>
								</div>

								<Grid container item xs={12} className='to-asset-id'>
									<Grid item container xs={12}
										sx={{
											border: formError && swaped ? '1px solid ' + theme.statusColor.lightRed : 'unset',
											borderRadius: '10px'
										}}>
										<Grid item xs={6} display='flex' alignItems='center' className='asset-select' sx={{ background: theme.palette.background.paper }}>
											<AssetSelect
												fieldName='toAsset'
												selectedAsset={swaped ? fromAsset : toAsset}
												onClick={() => handleSelectAsset(swaped ? 'FROM' : 'TO')}
												className='straight-right-border  token-input'
											/>
										</Grid>
										<Grid item xs={6} className='asset-input'>
											<MuiTextFieldResize
												sx={{ input: { color: assetAmount === 0 || assetAmount === null ? 'text.secondary' : 'text.primary' } }}
												name="youReceiveFormik"
												onChange={(e) => handleAmountChange(e, setFieldValue, setFieldTouched, swaped ? 'FROM' : 'TO')}
												value={swaped ? assetDisplayValue : youReceiveDisplayValue}
												fullWidth
												placeholder={coinPlaceholder}
												inputProps={{ autoComplete: 'off' }}
												className='straight-left-border token-value-input token-input'
												helperText='' />

										</Grid>
									</Grid>
								</Grid>
							</Grid>
							{errors.youReceive && <Grid container justifyContent='flex-end'>
								<FormLabel sx={{ color: theme.statusColor.lightRed, fontSize: '0.625rem' }}>{errors.youReceive}</FormLabel>
							</Grid>}
							{payInfoError && <Grid container justifyContent='flex-end'>
								<FormLabel sx={{ color: theme.statusColor.lightRed, fontSize: '0.625rem' }}>{t('tokens.unavailable')}</FormLabel>
							</Grid>}
							<Grid container>

								<Grid item xs={12} pt={'16px'}>
									<Slider
										defaultValue={0}
										onChange={(event: Event, newValue: number | number[]) =>
											handleSliderChange(event, newValue)}
										onChangeCommitted={(event, value) =>
											handleSliderChangeCommited(event, value, setFieldValue, setFieldTouched)}
										value={assetAmount ? assetAmount / parseFloat(fromAsset?.availableBalance) * 100 : 0}
										marks={[{ value: 25 }, { value: 50 }, { value: 75 }]}
										step={1}
										max={100}
										valueLabelFormat={(value) => `${value.toFixed(0)}%`}
										min={0}
										size='small'
										valueLabelDisplay='auto'
									/>
								</Grid>
								<Grid item xs={12} pt='0.5rem' pb='0.5rem' textAlign='center'>
									{isMaintenance ?
										<MaintenanceChip /> :
										<LoadingButton
											fullWidth
											type='submit'
											loading={payInfoLoading}
											disabled={formError || isSubmitting || isToAmountNegative}
											variant='contained'
										> {t('wallet.labels.convert')}
										</LoadingButton>
									}
								</Grid>
							</Grid>
						</Form>
					);
				}}
			</Formik >
		</Box>
	);
};

export default CryptoTrade;
