
import { postCoinWithdrawalPayinfo } from '@/features/coins/slice';
import {  CoinWithdrawalPayinfo, CoinWithdrawalPayinfoPayload } from '@/features/coins/types';
import { debounce, isEmpty } from 'lodash';
import { useMemo, useState } from 'react';

const useCoinWithdrawalFeeDebounce = () => {

	const initPayInfo: CoinWithdrawalPayinfo = {
		calculatedFee: 0,
		calculatedFeeString: '0'
	};

	const [payInfo, setPayinfo] = useState<CoinWithdrawalPayinfo>(initPayInfo);
	const [payInfoError, setPayinfoError] = useState<boolean>(false);
	const [payInfoLoading, setPayinfoLoading] = useState<boolean>(false);

	const callGetPayInfo = useMemo(() => debounce(
		async (feePayload?: CoinWithdrawalPayinfoPayload) => {


			if (feePayload.fromAmount <= 0) {
				setPayinfo(initPayInfo);
				return;
			}
			setPayinfoLoading(true);
			try {
				if (!isEmpty(feePayload)) {
					const result = await postCoinWithdrawalPayinfo(feePayload);
					setPayinfo(result);
					setPayinfoError(false);
				}
			}
			catch (e) {
				setPayinfo(initPayInfo);
				setPayinfoError(true);
			}
			finally { setPayinfoLoading(false); }
		}, 500)
		// eslint-disable-next-line react-hooks/exhaustive-deps 
		, []
	);

	return { payInfo, initPayInfo, payInfoError, payInfoLoading, setPayinfoError, setPayinfo, callGetPayInfo };
};

export default useCoinWithdrawalFeeDebounce;
