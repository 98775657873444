import React from 'react';
import { Grid } from '@mui/material';
import { BankAccount } from '@/features/account/types';
import TrustCard from './TrustCard';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StatusCard from '@/componentsMui/Dashboard/HomeSummary/Components/StatusCard';

interface Props {
	accounts: BankAccount[]
}

const TrustList = ({ accounts }: Props): React.ReactElement => {
	const { user } = useSelector((state: RootState) => state.user);
	const { t } = useTranslation('translations');
	return (
		<Grid id="trust-account-list" container width='100%'>
			{user.detained
				? <Grid item xs={12} justifyContent='center' >
					<StatusCard />
				</Grid>
				: null}
			{accounts?.length > 0
				? <Grid item container xs={12} spacing={2}>
					{accounts?.map(a =>
						<Grid item key={a.accountId}>
							<TrustCard account={a} hideValues={user.hideValues} />
						</Grid>)}
				</Grid>
				: <Grid item width='100%' sx={{ minHeight: '80px', textAlign: 'center' }}>
					{t('transaction.table.noTransactions')}
				</Grid>}
		</Grid>
	);
};

export default TrustList;
