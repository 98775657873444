import * as React from 'react';

import '@/componentsMui/Shared/Shared.css';

import {
	Button,
	FormHelperText,
	Grid,
	InputAdornment, Theme, Typography
} from '@mui/material';
import { ClipboardEvent } from 'react';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import * as Yup from 'yup';

import { BankAccount } from '@/features/account/types';
import { showException } from '@/features/swal/slice';
import { Contact } from '@/features/contacts/types';
import FormContentBox from '@/componentsMui/Shared/Widgets/Box/FormContentBox';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { maxTwoDecimals } from '@/helpers/number';
import NumberFormat from 'react-number-format';
import { getCurrencySign, postTransferFee } from '@/features/fees/slice';
import useFeeDebounce from '@/helpers/customHook/useFeeDebounce';
import { findProcessByType } from '@/features/operations/slice';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import useCloseDialog from '@/helpers/customHook/useCloseDialog';
import { isFiat } from '@/helpers/currency';
import { UserTransferPayloadType } from './UserPaymentSteps';
import FormLabel from '@shared/Components/SendMoney/PaymentForm/FormLabel';
import AccountItem from '@/componentsMui/Banking/Account/AccountItem';
import { getAccountName } from '@helpers/getAccountName';
import StyledDivider from '@shared/Widgets/StyledDivider';
import { useTheme } from '@mui/styles';
import bigDecimal from 'js-big-decimal';
import useCurrencyLimits from '@/helpers/customHook/useCurrencyLimits';
import UserLimitMessage from '../../UserLimitMessage';
import LoadingPageMui from '@/pages/LoadingPageMui';
import { roundAny, toNumber } from '@/helpers/round';
import { postCoinWithdrawalPayinfo } from '@/features/coins/slice';

type Props = {
	fromAccountList?: BankAccount[],
	defaultAccount?: BankAccount,
	defaultContact?: Contact,
	userPaymentPayload: UserTransferPayloadType,
	setUserPaymentPayload: (value: UserTransferPayloadType) => void,
	nextStep: () => void,
	prevStepMain?: () => void
}

const UserPaymentAmountStep = ({
	defaultAccount,
	defaultContact,
	fromAccountList,
	userPaymentPayload,
	setUserPaymentPayload,
	nextStep, prevStepMain
}: Props) => {
	const theme = useTheme() as Theme;
	const { t } = useTranslation('translations');

	const [selectedAccount] = useState<BankAccount>(userPaymentPayload.account ?? defaultAccount ?? fromAccountList[0] ?? null);

	const [amount, setAmount] = useState<string>(userPaymentPayload?.amount?.toString() ?? '');
	const [amountforFee, setAmountForFee] = useState<number>(userPaymentPayload?.amount ?? 0);
	const [feeInAmount, setFeeInAmount] = useState<boolean>(userPaymentPayload?.feeInAmount ?? false);

	const process = findProcessByType(selectedAccount, 'TRANSFER');
	const { callGetFee, feeLoading, feeString, setFee, setFeeString } = useFeeDebounce(userPaymentPayload?.fee ?? 0);
	const youpay = bigDecimal.add(amountforFee, feeString);
	const currency = getCurrencySign(selectedAccount?.currency);
	const isMaintenance = process?.maintenance;

	const fiat = isFiat(selectedAccount?.currency);

	const precision = fiat ? 2 : (selectedAccount?.precision || 5);
	const amountInputPrecision = Math.min(precision, 5);


	const { userLimits,
		loadingLimits,
		isLimitSet,
		isLimitExceeded } = useCurrencyLimits(selectedAccount?.currency);

	// const precision = 5;

	const closeModal = useCloseDialog();
	const limitReached = isLimitExceeded(amount);

	const initialValues = {
		from: defaultAccount?.accountId ?? '',
		amount: userPaymentPayload.amount ?? '',
		youpay: userPaymentPayload.youpay.toFixed(2) ?? null,
		walletId: userPaymentPayload.walletId ?? defaultContact?.clientId ?? '',
		reference: userPaymentPayload.reference ?? '',
	};

	const validationSchema = Yup.object({
		youpay: Yup.number().required(t('form.validator.required')).nullable()
			.test('youpay', t('sendmoneyTranslation.data.insufficient'), () => bigDecimal.compareTo(youpay, (selectedAccount?.availableBalance ?? 0)) <= 0),
		amount: Yup.number().typeError(t('form.validator.required')).required(t('form.validator.required')).nullable()
			.moreThan(0, t('sendmoneyTranslation.data.moreThanZero'))
			.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => fiat ? maxTwoDecimals(value) : true)
			.test('amount', t('sendmoneyTranslation.data.insufficient'),
				() => bigDecimal.compareTo(youpay, selectedAccount?.availableBalance ?? 0) <= 0),

	});

	const getMaxFee = React.useCallback(async () => {
		if (process?.proc && selectedAccount?.accountId && selectedAccount?.availableBalance) {
			const feeResult = await postCoinWithdrawalPayinfo({
				fromAmount: selectedAccount.availableBalance,
				fromAccountId: selectedAccount.accountId,
				proc: process.proc
			});

			return { fee: feeResult.calculatedFee, feeString: feeResult.calculatedFeeString };
		}
	}, [process?.proc, selectedAccount?.accountId, selectedAccount?.availableBalance]);

	const submit = async (formData, formikProps) => {
		const { setFieldError } = formikProps;
		const { amount } = formData;

		if (process === null || process.proc === null) {
			setFieldError('from', t('sendmoneyTranslation.data.missingProcess'));
		}

		try {
			const paymentAmount = parseFloat(amount);
			const feeResult = feeInAmount ? await getMaxFee() : await postTransferFee({ accountId: selectedAccount.accountId, proc: process?.proc, amount: paymentAmount });
			const payload: UserTransferPayloadType = {
				...{
					amount: paymentAmount,
					fee: feeResult.fee,
					feeString: feeResult.feeString,
					youpay: feeResult.fee + paymentAmount,
					processProcedure: process.proc,
					account: selectedAccount,
					feeInAmount: feeInAmount,
				},
			};
			setUserPaymentPayload(payload);
			nextStep();

		}
		catch (e) {
			await showException(e);
		}
	};


	const amountChange = (value, setFieldValue, setFieldTouched, isSetToMax) => {
		const parsed = toNumber(value);
		const newAmount = fiat ? roundAny(parsed, 2) : parsed;
		setAmount(`${value === '' ? '' : newAmount}`);
		setFieldValue('amount', `${newAmount}`);
		setAmountForFee(newAmount);
		if (!isSetToMax) {
			callGetFee(selectedAccount.accountId, process?.proc, newAmount);
		}
		setTimeout(() => setFieldTouched('youpay', true));
		setTimeout(() => setFieldTouched('amount', true));
	};


	const handleAmountChange = (event: React.ChangeEvent<any>, setFieldValue, setFieldTouched) => {
		setFeeInAmount(false);
		amountChange(event.target.value, setFieldValue, setFieldTouched, false);
	};

	const handlePaste = (event: ClipboardEvent<HTMLInputElement>, setFieldValue, setFieldTouched) => {
		setFeeInAmount(false);
		const pastedText = event.clipboardData.getData('Text');
		if (pastedText.indexOf(',') !== -1) {
			event.preventDefault();
			amountChange(pastedText.replace(',', '.'), setFieldValue, setFieldTouched, false);
		}
	};


	const setAmountToMax = async (setFieldValue, setFieldTouched) => {
		const availableBalance = selectedAccount?.availableBalance;
		const feeResult = await getMaxFee();
		const amount = availableBalance - feeResult.fee < 0 ? 0 : availableBalance - feeResult.fee;
		amountChange(`${amount}`, setFieldValue, setFieldTouched, true);
		setFee(feeResult.fee);
		setFeeString(feeResult.feeString);
		setFeeInAmount(true);
	};


	if (loadingLimits) { return <LoadingPageMui />; }

	return (
		<Formik
			//	enableReinitialize={true}
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={submit}
		>
			{({ errors, isSubmitting, setFieldValue, setFieldTouched }) => {
				return (
					<Form className='form-content__wrap__bb'>
						<FormContentBox noBackground={true} className='form-content_main-area wp'>
							<Grid container columnSpacing={3} rowSpacing={1}>
								<Grid item xs={12}>
									<FormHeader title={t('sendmoneyTranslation.data.cospayuserheader')} onBack={prevStepMain} showBack={!!prevStepMain} showMaintenace={isMaintenance} />
								</Grid>
								<Grid item xs={12}>
									<FormLabel>{t('form.fields.from')}</FormLabel>
									<AccountItem currency={selectedAccount.currency} logoSource={selectedAccount.currencyLogo} name={getAccountName(t, selectedAccount)} balance={selectedAccount.availableBalance} decimalScale={2} />
								</Grid>
								<Grid item xs={12}>
									<Grid container item xs={12} my={1} justifyContent="space-between" alignItems="center">
										<Grid item>
											<FormLabel>{t('sendmoneyTranslation.data.amount')}</FormLabel>
										</Grid>
										{isLimitSet &&
											<Grid item xs={12}>
												<UserLimitMessage userLimits={userLimits} limitReached={limitReached} />
											</Grid>
										}
									</Grid>
									<NumberFormat
										name="amount"
										customInput={MuiTextField}
										className='large-number-input'
										onPaste={(event) => handlePaste(event, setFieldValue, setFieldTouched)}
										onChange={(event) => handleAmountChange(event, setFieldValue, setFieldTouched)}
										allowNegative={false}
										value={amount}
										displayType={'input'}
										placeholder={'0.00'}
										allowedDecimalSeparators={['.']}
										decimalScale={amountInputPrecision}
										fullWidth
										sx={{
											'& .MuiInputAdornment-root p': {
												fontSize: '1.3rem'
											},
											'& input::placeholder': {
												fontSize: '1.3rem',
											}
										}}
										inputProps={{ autoComplete: 'off', sx: { fontSize: '1.3rem' } }}
										InputProps={{
											style: { fontSize: '1.3rem' },
											sx: { background: theme.backgroundBoxLevel1, height: '74px!important', fontSize: '1.3rem' },
											startAdornment:
												<InputAdornment position="start">
													{currency}
												</InputAdornment>,
											endAdornment:
												<InputAdornment position='end'>
													<span style={{ height: '0.8rem', cursor: 'pointer', textDecoration: 'underline', fontSize: '0.8rem', lineHeight: '0.8rem' }}
														onClick={() => setAmountToMax(setFieldValue, setFieldTouched)}>MAX</span>
												</InputAdornment>,
										}}
									/>
								</Grid>
								<Grid item xs={12} mt='1rem'>
									<StyledDivider />
								</Grid>
								<Grid container item xs={12} justifyContent='space-between' alignItems='center'>
									<Grid item>
										<Typography color='text.primary' sx={{ fontSize: '1.225rem' }}>
											{t('sendmoney.data.youWillPay')}
										</Typography>
									</Grid>
									<Grid item>
										<Typography color='text.primary' fontWeight={700} sx={{ fontSize: '1.225rem' }}>
											<Typography variant='highlight'>
												{`${selectedAccount.currencySymbol ?? getCurrencySign(selectedAccount.currency)}`}
											</Typography>
											<NumberFormat
												id="youpay"
												name="youpay"
												displayType={'text'}
												decimalScale={precision}
												value={youpay}
												fixedDecimalScale={fiat ? true : false}
												thousandsGroupStyle='thousand'
												thousandSeparator={true}
												prefix={' '}
												placeholder={'0.00'}
											/>
										</Typography>
									</Grid>
								</Grid>
								<Grid item xs={12} container justifyContent='right' sx={{ p: '0!important' }}>
									<Typography color='text.secondary' sx={{ fontSize: '0.7rem' }}>
										{t('sendmoney.data.includedWithFee')}
									</Typography>
									<Typography color='text.primary' sx={{ fontSize: '0.7rem', ml: 0.5 }} noWrap>
										<NumberFormat
											id="fee"
											name="fee"
											displayType={'text'}
											decimalScale={precision}
											value={feeString ?? 0}
											fixedDecimalScale={fiat ? true : false}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											prefix={' '}
											placeholder={'0.00'}
										/>
									</Typography>
								</Grid>
								<Grid item xs={12} container justifyContent='right'>
									{errors.youpay && <FormHelperText >{t('sendmoneyTranslation.data.insufficient')}</FormHelperText>}
								</Grid>
							</Grid>
							<Grid container mt='auto' pt='2rem' columnSpacing={2}>
								<Grid item xs={6}>
									<Button
										variant='customOutlined'
										color='secondary'
										onClick={closeModal}
										sx={{ width: { xs: '100%', md: 'auto' } }}
									>  {t('form.buttons.close')}
									</Button>
								</Grid>
								<Grid item xs={6} container justifyContent='flex-end'>
									<Button
										disabled={isSubmitting || !isEmpty(errors) || feeLoading}
										variant="contained"
										color="primary"
										sx={{ width: { xs: '100%', md: 'auto' } }}
										type="submit"
									>
										{t('form.buttons.next')}
									</Button>
								</Grid>
							</Grid>
						</FormContentBox>
					</Form>
				);
			}}
		</Formik >);
};

export default UserPaymentAmountStep;
