import request from '@/services/request';
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTime } from 'date-fns';
import { WalletHistoryPayload, WalletHistoryStore, WalletTransaction, WalletTransactionType } from './types';


const initialState: WalletHistoryStore = {
	transactions: [],
	skip: 0,
	take: 10,
	search: '',
	type: null
};

const transactionLazyLoadSlice = createSlice({
	name: 'walletDeposits',
	initialState,
	reducers: {

		setTransactions(state, action: PayloadAction<WalletTransaction[]>) {
			state.transactions = action.payload;
		},
		setSkip: (state, { payload }: PayloadAction<number>) => {
			state.skip = payload;
		},
		setTake: (state, { payload }: PayloadAction<number>) => {
			state.take = payload;
		},
		setSearch: (state, { payload }: PayloadAction<string>) => {
			state.search = payload;
		},
		setType: (state, { payload }: PayloadAction<WalletTransactionType | null>) => {
			if (state.type !== payload) {
				state.search = '';
				state.skip = 0;
				state.transactions = [];
				state.type = payload;
			}
		},
	}
});

// export const getWalletHistory = async (type: 'TRADES' | 'WITHDRAWALS' | 'DEPOSITS' | 'TRANSACTIONS', payload: WalletHistoryPayload): Promise<any> => {
// 	const response = await request.post(`/api/v3/tokens/history/${type}`, payload);
// 	const { data } = response;
// 	return data;
// };

export const getWalletHistory = async (
	type: WalletTransactionType,
	payload: WalletHistoryPayload,
): Promise<any> => {
	const response = await request.post(`/api/v3/tokens/history/${type}`, payload);
	const { data } = response;
	return data;
};

export const getWalletHistoryPair = async (
	payload: WalletHistoryPayload,
): Promise<any> => {
	const response = await request.post('/api/v3/tokens/pair/history', payload);
	const { data } = response;
	return data;
};

export const updateWalletTransactionsWithInitial = (socketType: WalletTransactionType): AppThunk => {
	return async (dispatch, state) => {
		const { walletHistory, user } = state();

		if (walletHistory.type !== socketType) return;
		const from = getTime(new Date(user.user.createdDate));
		const to = new Date().getTime() + 86400000;

		const response = await request.post(`/api/v3/tokens/history/${walletHistory.type}`, { search: '', skip: 0, take: 10, from, to });
		const { data } = response;

		dispatch(setSearch(''));
		dispatch(setSkip(0));
		dispatch(setTake(10));
		dispatch(setTransactions(data.list));
	};
};

export const { setTransactions,
	setSkip,
	setSearch,
	setTake,
	setType } = transactionLazyLoadSlice.actions;

export default transactionLazyLoadSlice.reducer;
