import { useMediaQuery, useTheme } from '@mui/material';
import { basicAppbarHeight, basicAppbarHeightMobile } from '../uiConstants';



const useBasicAppBarSize = () => {
    const theme = useTheme();
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

    const appBarHeigh = matchDownSm ? basicAppbarHeightMobile : basicAppbarHeight;

    return appBarHeigh;
};


export default useBasicAppBarSize;
