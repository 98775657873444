import { format, isValid, parse } from 'date-fns';
import usLocal from 'date-fns/locale/en-US';
import itLocal from 'date-fns/locale/it';

const dateFormat = 'dd MMM yy HH:mm:ss';

const laguageMap = {
	us: usLocal,
	it: itLocal,
};

export const convertDate = (originalDate: Date, pattern: string): string => {
	const date = new Date(originalDate);
	return format(date, pattern);
};

export const convertDateLang = (originalDate: Date, pattern: string, lang: string): string => {
	const date = new Date(originalDate);
	return format(date, pattern, { locale: laguageMap[lang] });
};


export const formatDate = (originalDate: string, originalFormat?: string): string => {
	if (originalFormat) {
		return format(parse(originalDate, originalFormat, new Date()), dateFormat);
	}
	const date = new Date(originalDate);
	return format(date, dateFormat);
};

export const formatDateWithPattern = (originalDate: string, dateFormat: string, originalFormat?: string): string => {
	if (originalFormat) {
		return format(parse(originalDate, originalFormat, new Date()), dateFormat);
	}
	const date = new Date(originalDate);
	return format(date, dateFormat);
};

export const formatDateWithPatternIgnoreTimezone = (originalDate: string, dateFormat: string): string => {
	const date = new Date(originalDate);
	const timezoneOffset = date.getTimezoneOffset() * 60000;
	return format(new Date(date.getTime() + timezoneOffset), dateFormat);
};


//Formats to DD/MM/YYYY, HH:MM:SS ex: 06/10/2020, 15:55:00
export const formatSimpleDateTime = (originalDate: string): string => {
	const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' } as const;
	return new Date(originalDate).toLocaleTimeString('en-GB', dateOptions);
};

export const timeSince = (originalDate: string | Date): string => {
	const now = new Date();
	const date = new Date(originalDate);
	const diff = (now.valueOf() - date.valueOf()) / 1000;
	const seconds = Math.floor(diff);

	let interval = seconds / 31536000;

	if (interval > 1) {
		return Math.floor(interval) + ' years ago';
	}
	interval = seconds / 2592000;
	if (interval > 1) {
		return Math.floor(interval) + ' months ago';
	}
	interval = seconds / 86400;
	if (interval > 1) {
		return Math.floor(interval) + ' days ago';
	}
	interval = seconds / 3600;
	if (interval > 1) {
		return Math.floor(interval) + ' hours ago';
	}
	interval = seconds / 60;
	if (interval > 1) {
		return Math.floor(interval) + ' minutes ago';
	}
	return Math.floor(seconds) + ' seconds ago';
};

export const formatExpirationDate = (expirationDate?: string) => {
	if (expirationDate) {
		const date = parse(expirationDate, 'yyyy-MM-dd', new Date());
		if (isValid(date)) {
			return format(date, 'MM/yy');
		} else {
			return '••/••';
		}
	} else {
		return '';
	}
};
