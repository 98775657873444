import React, { useEffect, useState } from 'react';
import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, IconButton, Theme } from '@mui/material';
import { Card, CardIntegrationEnum, CardStatuses, CardSubProcesses, Process } from '@features/card/types';
import ServiceLoading from '../ServiceLoading';
import { setAccount } from '@/features/tabs/slice';
import './CardServiceTab.css';
import '@/componentsMui/Shared/Shared.css';
import { AccountType, Operations } from '@/features/operations/types';
import { useTheme } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import EmptyProductCard from '../Shared/Widgets/EmptyProductCard';
import { useTranslation } from 'react-i18next';
import MuiHeader from '../Shared/Widgets/MuiText/MuiHeader';
import CardLine from './Card/CardDetails/CardLine';
import { getAccount } from './utils';
import IndividualCardDetails from './Card/CardDetails/IndividualCardDetails';
import BackIcon from '../Shared/CustomIcons/BackIcon';
import { UserType } from '@/features/user/types';
import { getOperationsByType, hasSubprocessWithType } from '@/features/operations/slice';
import LoadingPageMui from '@/pages/LoadingPageMui';
import BigAmount from '../Wallet/Components/BigAmount';
import { getCurrencySign } from '@/features/fees/slice';
import CardTopupTransactionForm from './Card/CardDetails/CardTopupTransactionForm';

interface Props {
	loading: boolean,
	list: Array<Card>,
	error?: string
}

const CardServiceTab = ({ loading, list, error }: Props): React.ReactElement => {
	const theme = useTheme() as Theme;
	const searchParams = new URLSearchParams(location.search);
	const [selectedCardId] = useState<string>(searchParams.get('cardId'));
	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const [overview, setOverview] = React.useState<boolean>(true);
	const [selectedCard, setSelectedCard] = useState(null);
	const [options, setOptions] = useState<Process[]>([]);
	const [openTransactionForm, setOpenTransactionForm] = useState<boolean>(false);

	const { currentAccount } = useSelector(
		(state: RootState) => state.tabs
	);

	const { user } = useSelector((state: RootState) => state.user);

	const shCardsList = list.filter((item) => item.process.integration === CardIntegrationEnum.SH_FINANCE);
	const otherCardsList = list.filter((item) => item.process.integration !== CardIntegrationEnum.SH_FINANCE);

	const cardForShTopUp = shCardsList.length > 0 && shCardsList.filter(c => c.status === CardStatuses.ACTIVE)[0] || null;
	const isShTopUpAllowed = cardForShTopUp && hasSubprocessWithType(CardSubProcesses.CARD_TOP_UP, cardForShTopUp.process?.subProcesses);
	const shCardForBalance = shCardsList.find(c => [
		CardStatuses.ACTIVE.toString(),
		CardStatuses.REJECTED.toString(),
		CardStatuses.EXPIRED.toString(),
		CardStatuses.SUSPENDED.toString(),
		CardStatuses.LOST.toString(),
		CardStatuses.STOLEN.toString(),
	].includes(
		c.status
	)) || null;

	const createCardAllowed = user.type !== UserType.EMPLOYEE && (options?.length > 0);
	const mobileNotConfirmedConfirmed = user.confirmedMobileNumber === null || user.confirmedMobileNumber === undefined;


	const disableCreateBtn = mobileNotConfirmedConfirmed || !user.emailConfirmed;
	const history = useHistory();
	const createCard = () => { history.push('/wallet/home/cards/create'); };

	const handlePhoneVerification = () => {
		history.push('/wallet/home/cards/verify-phone');
	};

	const handleTopup = () => {
		history.push(`/wallet/home/cards/transfers/top-up?card=${cardForShTopUp.cardId}`);
	};

	const handleEmailVerification = () => {
		history.push('/wallet/home/cards/verify-email');
	};
	const onClickSetAccount = (account) => {
		setOverview(false);
		dispatch(setAccount(account));
	};

	useEffect(() => {
		const newAccount = getAccount(list, currentAccount?.accountId);
		dispatch(setAccount(newAccount));
		setSelectedCard(newAccount);
		//	history.push('/wallet/home/cards/'); //this causes loop sometimes as forces 
	}, [dispatch, currentAccount?.accountId, list, history]);

	useEffect(() => {
		const selectedCard = list.find(c => c.cardId === Number(selectedCardId));
		if (selectedCard) {
			setOverview(false);
			dispatch(setAccount(selectedCard));
		}
	}, [dispatch, list, selectedCardId]);

	const handleBack = () => {
		dispatch(setAccount(null));
		setSelectedCard(null);
		setOverview(true);
	};

	useEffect(() => {
		const get = async () => {

			try {
				const response = await getOperationsByType(Operations.CREATE_CARD);
				setOptions(response);

			} catch (e) {
				await setOptions([]);
			}

		};
		get();
	}, []);

	const handleCollapse = () => {
        setOpenTransactionForm(false);
    };


	if (mobileNotConfirmedConfirmed && !list.length) {
		return (<EmptyProductCard
			title={t('cards.verifyPhone.title')}
			subTitle={t('cards.verifyPhone.subTitle')}
			buttonTitle={t('cards.verifyPhone.buttonTitle')}
			onClick={handlePhoneVerification}
		/>);
	}

	if (!user.emailConfirmed && !list.length) {
		return (<EmptyProductCard
			title={t('cards.verifyEmail.title')}
			subTitle={t('cards.verifyEmail.subTitle')}
			buttonTitle={t('cards.verifyEmail.buttonTitle')}
			onClick={handleEmailVerification}
		/>);
	}

	if (loading && list?.length === 0) {
		return <LoadingPageMui />;
	}


	return (
		<ServiceLoading
			type={AccountType.CARD}
			//loading={loading}
			error={error}
			checkList={true}
			list={list}>
			<CardTopupTransactionForm 
				open={openTransactionForm}
				onClose={handleCollapse}
			/>
			<Grid
				id='card-tab-container'
				className='card-tab-scroll-container'
				container
			>
				{
					!user.emailConfirmed &&
					<Grid style={{ marginBottom: 20 }}>
						<EmptyProductCard
							title={t('cards.verifyEmail.title')}
							subTitle={t('cards.verifyEmail.subTitle')}
							buttonTitle={t('cards.verifyEmail.buttonTitle')}
							onClick={handleEmailVerification}
						/>
					</Grid>
				}
				{
					mobileNotConfirmedConfirmed &&
					<Grid style={{ marginBottom: 20 }}>
						<EmptyProductCard
							title={t('cards.verifyPhone.title')}
							subTitle={t('cards.verifyPhone.subTitle')}
							buttonTitle={t('cards.verifyPhone.buttonTitle')}
							onClick={handlePhoneVerification}
						/>
					</Grid>
				}
				{!list.length ?
					<Grid container spacing={2}>
						<Grid item xs={12}>
							{createCardAllowed ? <EmptyProductCard
								title={t('cards.emptyCards.title')}
								subTitle={t('cards.emptyCards.subTitle')}
								buttonTitle={t('cards.emptyCards.buttonTitle')}
								onClick={createCard}
							/> : <>
								{user.type === UserType.EMPLOYEE ?
									<EmptyProductCard
										title={t('cards.emptyCards.title')}
										subTitle={t('cards.emptyCards.subTitleEmployee')}
									/> :
									<EmptyProductCard
										title={t('cards.emptyCards.title')}
										subTitle={t('cards.emptyCards.subTitleIndividual')}
										buttonTitle={t('home.contactSupport')}
										onClick={() => history.push('/wallet/support')}
									/>
								}
							</>}
						</Grid>
					</Grid> :
					<>
						{!overview && selectedCard && <Grid item xs={12} sm={4} mb={2}>
							{/* <FormHeader title={t('cards.businessCards.backToOverview')} showBack={!overview} onBack={handleBack} mb='0' /> */}

							<Grid id='form-header' container alignItems='center' flexWrap='nowrap' >
								{!overview && <Grid item>
									<IconButton onClick={handleBack} sx={{ pl: 0, pr: 0.5 }} size='small' >
										<BackIcon className="stroke" sx={{ stroke: theme.palette.text.secondary }} />
									</IconButton>
								</Grid>}
								<Grid item onClick={handleBack} sx={{ cursor: 'pointer' }}>
									<MuiHeader fontSize='1rem' color='text.secondary' fontWeight={500}> {t('cards.businessCards.backToOverview')}
									</MuiHeader>
								</Grid>
							</Grid>
						</Grid>}
						{overview && list.length && <Grid item xs={12}>
							{shCardsList.length ?
								<>
									<Grid container mb={2} mt={1} justifyContent='space-between' alignItems='center'>
										<Grid item container xs={4} alignItems='center'>
											<MuiHeader mr={1}>{t('cards.list.visaCards')}</MuiHeader>
											<BigAmount
												currency={shCardForBalance?.currencySymbol ?? getCurrencySign('EUR')}
												value={shCardForBalance?.availableAmount ?? 0}
												fontSize='1.25rem'
												row
											/>
										</Grid>
										<Grid item spacing={2}>
											{createCardAllowed &&
												<Button
													variant='simple'
													onClick={createCard}
													disabled={!createCardAllowed || disableCreateBtn}
													sx={{ mr: 1 }}
												>
													{t('cards.businessCards.createCard')}
												</Button>}
											<Button
												variant='simple'
												onClick={handleTopup}
												disabled={!isShTopUpAllowed}
												sx={{ mr: 1 }}
											>
												{t('cards.topUpCard.topUp')}
											</Button>
											<Button
												variant='simple'
												onClick={() => setOpenTransactionForm(true)}
											>
												Top up history
											</Button>
										</Grid>
									</Grid>
									{shCardsList.map(card => <CardLine key={card.cardId} card={card} onClick={() => onClickSetAccount(card)} />)}
								</> : null
							}
							{otherCardsList.length ?
								<>
									<Grid container mb={2} mt={otherCardsList.length ? 4 : 1} justifyContent='space-between'  alignItems='center' minHeight='38px'>

										<MuiHeader >{t('cards.list.mastercardCards')}</MuiHeader>
										{shCardsList.length === 0 && createCardAllowed && <Button
											variant='simple'
											onClick={createCard}
											disabled={!createCardAllowed || disableCreateBtn}
										>
											{t('cards.businessCards.createCard')}
										</Button>}
									</Grid>
									{otherCardsList.map(card => <CardLine key={card.cardId} card={card} onClick={() => onClickSetAccount(card)} />)}
								</> : null
							}
						</Grid>}

						{!overview && selectedCard && <Grid item xs={12}>
							<IndividualCardDetails key={selectedCard?.cardId} card={selectedCard as Card} list={list} />
						</Grid>}
					</>}

			</Grid>
		</ServiceLoading >
	);
};

export default CardServiceTab;
