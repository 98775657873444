import * as React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import '../VaultList.css';
import { convertDate } from '@/helpers/date';
import TransactionStatusBox from '@/componentsMui/Shared/Widgets/Box/TransactionStatusBox';
import NumberFormat from 'react-number-format';
import { isFiat } from '@/helpers/currency';
import IOSSwitch from '@/componentsMui/Shared/Widgets/IOSSwitch';
import { useDispatch } from 'react-redux';
import { toggleDeposit } from '@/features/earn/depositsSlice';
import { useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { getVaultTransactionStatus } from '@helpers/transactionUtils';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChangePlanForm from './ChangePlanForm';

interface Props {
	allocation: any,
	hideAutoExtend?: boolean
}

const VaultAllDepositsLine = ({ allocation, hideAutoExtend }: Props) => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const time = allocation?.createdDate && new Date(allocation.createdDate);
	const [toggleLoading, setToggleLoading] = useState<boolean>(false);
	const confirm = useConfirm();
	const [openChangePlanDialog, setOpenChangePlanDialog] = useState<boolean>(false);

	const handleAutoExtend = () => {
		setToggleLoading(true);
		if (allocation.autoExtendStatus === 'ON') {
			confirm({
				description: t('vaults.allDeposits.confirmDescription'),
				title: t('vaults.allDeposits.confirmTitle'),
				confirmationText: t('vaults.allDeposits.confrimConfirmationText'),
				cancellationText: t('vaults.allDeposits.confirmCancellationText')
			})
				.then(() => {
					dispatch(toggleDeposit(allocation.vaultId, false));
				});
		} else {
			dispatch(toggleDeposit(allocation.vaultId, true));
		}
		setToggleLoading(false);

	};

	return (
		<Grid container mt={2} mb={2} alignItems='center' >
			{openChangePlanDialog && <ChangePlanForm
				open={openChangePlanDialog}
				onClose={() => setOpenChangePlanDialog(false)}
				allocation={allocation}
			/>}
			<Grid item className='vault-deposits-list__time'>
				<Typography fontSize='0.6rem' color='text.primary' lineHeight='1rem'>
					{convertDate(time, 'dd MMM yyyy')}
				</Typography>
				<Typography fontSize='0.6rem' color='text.secondary'>
					{convertDate(time, 'HH:mm:ss')}
				</Typography>
			</Grid>
			<Grid item className='vault-deposits-list__plan'>
				<MuiTextCaption variant='gradient' fontWeight='400'>
					{allocation.vaultName}
				</MuiTextCaption>
			</Grid>
			<Grid item className='vault-deposits-list__apr'>
				<MuiTextCaption>
					<MuiTextCaption>
						<NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} suffix={'%'} value={parseFloat(allocation.yearlyInterestRate) * 100} />
					</MuiTextCaption>
				</MuiTextCaption>
			</Grid>
			<Grid item className='vault-deposits-list__duration'>
				<MuiTextCaption>
					{`${parseInt(allocation.depositPeriodMonths) * 30} ${t('vaults.subsribe.days')}`}
				</MuiTextCaption>
			</Grid>
			<Grid item className='vault-deposits-list__asset'>
				<MuiTextCaption>
					{allocation.currency}
				</MuiTextCaption>
			</Grid>
			<Grid item className='vault-deposits-list__amount'>
				<MuiTextCaption>
					<NumberFormat
						displayType={'text'}
						decimalScale={isFiat(allocation.currency) ? 2 : 6}
						fixedDecimalScale={isFiat(allocation.currency) ? true : false}
						thousandsGroupStyle='thousand'
						thousandSeparator={true}
						value={allocation?.balance > 0 ? allocation.balance : allocation.vaultAmount} />
				</MuiTextCaption>
			</Grid>
			{!hideAutoExtend && <Grid item className='vault-deposits-list__autoSubscribe' display='flex' >
				{allocation?.autoExtendStatus === 'PENDING_OFF' ?
					// <MuiTextCaption>
					// 	{t('vaults.allDeposits.withdrawalRequested')}
					// </MuiTextCaption> 
					<AccessTimeIcon sx={{ marginLeft: '18px' }} />
					:
					<IOSSwitch checked={allocation.autoExtendStatus === 'ON'} onChange={handleAutoExtend} disabled={allocation.status !== 'ACTIVE' || toggleLoading || allocation?.allowAutoExtendSwitchOff === false || allocation.autoExtendStatus !== 'ON'} />}
			</Grid>}
			<Grid item className='vault-deposits-list__repayment'>
				<MuiTextCaption textAlign='right'>
					{convertDate(allocation.repaymentDate, 'dd MMM yyyy')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='vault-deposits-list__request' display='flex' justifyContent='flex-end'>
				<Button
					variant='customOutlined'
					sx={{
						padding: '8px',
						fontSize: '0.563rem'
					}}
					disabled={allocation.status !== 'ACTIVE' || allocation.planChangeRequested}
					onClick={() => setOpenChangePlanDialog(true)}
				>{allocation.planChangeRequested ? t('vaults.planChange.requested') : t('vaults.planChange.request')}</Button>
			</Grid>
			<Grid item className='vault-deposits-list__status' display='flex' justifyContent='flex-end'>
				<TransactionStatusBox
					status={getVaultTransactionStatus(allocation.status)}>
					{t('status.vaults.allDeposits.' + allocation.status)}
				</TransactionStatusBox>
			</Grid>
		</Grid>);
};

export default VaultAllDepositsLine;
