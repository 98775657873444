import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';

interface FormType {
	id: string,
	errors: any,
	handleSubmit?: () => void,
	validateForm?: () => any,
	isSubmitting: boolean,
	isDirty: boolean,
	values: any
}

export const useFormikForms = (sectionValues: any, emptyItem: any) => {
	const initForm: FormType = { id: uuid(), errors: {}, values: emptyItem, isSubmitting: false, isDirty: false };

	const initValues = sectionValues ? sectionValues.map(p => { return { ...initForm, id: uuid(), values: p }; }) : [initForm];

	const [forms, setForms] = useState<Array<FormType>>(initValues);
	const [save, setSave] = useState(false);
	const [nextStep, setNextStep] = useState(false);

	const errorsOnForm = forms.some(p => !isEmpty(p.errors));
	const formsAreSumbmitting = forms.some(p => p.isSubmitting);
	const fromsAreDirty = forms.some(p => p.isDirty);

	const allowPostData = fromsAreDirty && save && formsAreSumbmitting == false && errorsOnForm === false || forms?.length !== initValues?.length;
	const disableSubmit = errorsOnForm || formsAreSumbmitting;



	const addForm = () => {
		setForms([...forms, { id: uuid(), errors: {}, isSubmitting: false, values: emptyItem, isDirty: false }]);
	};

	const removeForm = (idx) => {
		setForms([...forms.filter(p => p.id !== idx)]);
	};

	const updateForm = (idx, handleSubmit, validateForm, errors, isSubmitting, isDirty, values) => {
		const getFroms = (forms) => forms.map(el => {
			if (el.id == idx)
				return Object.assign({}, el, { handleSubmit: handleSubmit, validateForm: validateForm, errors, isSubmitting: isSubmitting, isDirty: isDirty, values: values });
			return el;
		});

		setForms(prevState => getFroms(prevState));
	};


	const handleNext = () => {
		try {
			if(forms?.length > 0) {
				const validationArray = forms.map(function (p) {
					return new Promise(function (resolve) {
						p.validateForm().then((errors) => {
							resolve(errors);
						});
					});
				});
				Promise.all(validationArray).then(function (errors) {
					let empty = true;
					errors.map((error) => {
						if(!isEmpty(error)) {
							empty = false;
						}
					});
					if(empty) {
						setSave(true);
						setNextStep(true);
					} else {
						setSave(false);
						setNextStep(false);
					}
				});
			}
		}
		catch (e) {
			setNextStep(false);
			setSave(false);
		}
	};




	return { forms, save, setSave, errorsOnForm, formsAreSumbmitting, allowPostData, disableSubmit, nextStep, addForm, removeForm, updateForm, handleNext } as const;
};



