
import MuiFormLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiFormLabel';
import MuiSubHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiSubHeader';
import { markNotification, setPageSize, setTickets } from '@/features/tickets/slice';
import { TicketMarkType } from '@/features/tickets/types';
import { RootState } from '@/rootReducer';
import { Backdrop, Drawer, Grid, styled, useTheme } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NotificationList from './NotificationList';


interface Props {
	open?: boolean,
	onClose?: () => void
}
const Notifications = ({ open, onClose }: Props) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const { t } = useTranslation('translations');

	const { list } = useSelector((state: RootState) => state.tickets);

	const handleReadAll = useCallback(() => {
		list.forEach(c => dispatch(markNotification(TicketMarkType.READ, c.id)));
	}, [dispatch, list]);


	const handleClose = () => {
		dispatch(setPageSize(0));
		dispatch(setTickets([]));
		onClose();
	};


	const backdropStyle =
		styled(Backdrop, {
			name: 'MuiModal',
			slot: 'Backdrop',
			overridesResolver: (props, styles) => {
				return styles.backdrop;
			},
		})({
			background: 'transparent',
		});



	return (
		<Drawer
			id='notifications'
			anchor='right'
			open={open}
			onClose={handleClose}
			variant='temporary'
			sx={{ position: 'absolute', top: '90%', left: { xs: 0, sm: 'unset' }, width: '100%' }}
			SlideProps={{
				direction: 'down'

			}}
			PaperProps={{
				style: {
					// position: 'absolute',
					borderRadius: '10px',
					flex: 1,
					flexDirection: 'column',
					width: '24rem',
					maxWidth: '100%',
					height: '100%',
					minHeight: '70vh',
					paddingTop: '54px',
					background: theme.palette.background.paper
				}
			}}
			ModalProps={{
				container: document.getElementById('notification-container'),
				keepMounted: true,
				BackdropComponent: backdropStyle
			}}

		>
			<Grid container sx={{ p: '1rem' }}>
				<Grid item xs={12} container alignItems='center'>
					<Grid item xs={6}>
						<MuiSubHeader> {t('nofification.notifications')}</MuiSubHeader>
					</Grid>
					{list?.length > 0 &&
						<Grid item xs={6} container justifyContent='flex-end'>
							<MuiFormLabel onClick={handleReadAll} variant='gradient' style={{ cursor: 'pointer' }}>
								{t('nofification.markAsRead')}
							</MuiFormLabel>
						</Grid>
					}
				</Grid>
				<Grid item xs={12} pt='30px'>
					<NotificationList closeDrawer={handleClose} />
				</Grid>

			</Grid>
		</Drawer >
	);
};

export default Notifications;
