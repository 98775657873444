import * as React from 'react'; 
import { useTranslation } from 'react-i18next';
import { getCurrencySign, sumFees } from '@/features/fees/slice';
import { FeeType } from '@/features/fees/types';
import NumberFormat from 'react-number-format';
import MuiTextAccountBoxHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAccountBoxHeader';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import ProductOptionBox from '@/componentsMui/Shared/Widgets/ProductOptionBox/ProductOptionBox';
import { AccountType } from '@/helpers/globalTypes';

export interface AcccountOptionProps {
	selected: boolean,
	operation: any,
	type: AccountType,
	bgIndex: number,
	onClick?: () => void
}

const AccountOption = ({ selected, operation, type, bgIndex, onClick }: AcccountOptionProps) => {
	const { t } = useTranslation('translations');

	const headerText = type === AccountType.VIRTUAL ? 'Virtual Account' : 'Physical Account';
	const currency = getCurrencySign(operation?.processProperties?.ccy);
	const montlyFee = sumFees(operation?.fees, 0, FeeType.FIXED_MONTH);
	const creationFee = sumFees(operation?.fees, 0, FeeType.FIXED);
 
	return (<ProductOptionBox
		id='account-options'
		key={operation.proc}
		onClick={onClick}
		selected={selected}
		bgIndex={bgIndex}
		coverName='newAccountCover'
	>
		{< React.Fragment >
			<MuiHeader fontWeight='800'>
				{headerText} {operation.processProperties && operation.processProperties.ccy}
			</MuiHeader>
			<div>
				{montlyFee !== 0 &&
					<MuiTextAccountBoxHeader color='text.primary' className='invert-at-form'  >
						<br />
						<NumberFormat
							displayType={'text'}
							decimalScale={2}
							fixedDecimalScale={true}
							thousandsGroupStyle='thousand'
							thousandSeparator={true}
							prefix={currency}
							value={montlyFee} />
						{' ' + t('fees.monthly')}
					</MuiTextAccountBoxHeader>
				}
				{creationFee !== 0 &&
					<MuiTextAccountBoxHeader color='text.primary' className='invert-at-form'  >
						<NumberFormat
							displayType={'text'}
							decimalScale={2}
							fixedDecimalScale={true}
							thousandsGroupStyle='thousand'
							thousandSeparator={true}
							prefix={currency}
							value={creationFee} />
						{' ' + t('creation')}
					</MuiTextAccountBoxHeader>
				}
			</div>
		</React.Fragment >}
	</ProductOptionBox >);
};

export default AccountOption;
