import React from 'react';
import { getCurrencySign } from '@/features/fees/slice';
import { isFiat } from '@/helpers/currency';
import { roundFlexible } from '@/helpers/round';
import { GridRenderCellParams } from '@mui/x-data-grid';
import NumberFormat from 'react-number-format';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';

const TrxAmountCell = (params: GridRenderCellParams,
    type: string) => {
    const transaction = params.row;
    const isFiatAsset = isFiat(transaction.asset) || type === 'CARD';

    const getSign = transaction.amount < 0 ? '-' : transaction.amount > 0 ? '+' : '';
    const ccy =  type === 'CARD' ? transaction.currency : transaction.asset;

    return (<>
        <MuiFormText textAlign='right'
            fontWeight={500}
            sx={theme => ({ color: transaction.amount <= 0 ? theme.palette.text.primary : theme.statusColor.success })}>
            {`${getSign}  ${getCurrencySign(ccy)}`} {type === 'TOKEN' && !isFiat(ccy) && ' '}
            {isFiatAsset ?
                <NumberFormat displayType={'text'} decimalScale={2}
                    fixedDecimalScale={true} thousandsGroupStyle='thousand'
                    thousandSeparator={true} value={Math.abs(transaction.amount)} /> :
                roundFlexible(Math.abs(transaction.amount))
            }
        </MuiFormText>
    </>);
};

export default TrxAmountCell;
