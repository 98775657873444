import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BulkPaymentsUploadsGrid from './BulkPaymentsUploadsGrid';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import BulkPaymentLinesGrid from './BulkPaymentLinesGrid';



const BulkPayments = () => {
    const { t } = useTranslation('translations');
    const [selectedUpload, setSelectedUpload] = useState<string | null>(null);

    return (
        <Grid container
            alignItems='center'
            p={{ sx: '0px', md: '30px' }}
            bgcolor={{ sx: 'transparent', md: '#2A2E32' }}
            borderRadius='10px'>
            <Grid item xs={12} container justifyContent='space-between'>
                <Grid item xs>
                    <FormHeader showBack={!!selectedUpload} onBack={() => setSelectedUpload(null)} >{selectedUpload ?? t('bulkpayments.title')}</FormHeader>
                </Grid>
            </Grid>
            {selectedUpload === null ? <BulkPaymentsUploadsGrid onSelect={(val: string) => setSelectedUpload(val)} /> : <BulkPaymentLinesGrid sessionId={selectedUpload} />}
        </Grid>
    );
};

export default BulkPayments;
