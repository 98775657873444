import React from 'react';
import * as Yup from 'yup';
import { FormControlLabel, Grid, IconButton, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import StyledCheckbox from '@/componentsMui/Shared/Widgets/StyledCheckbox';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { DirectorType } from '@/features/business/types';
import MuiSubHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiSubHeader';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import { useDate, useName } from '@/helpers/customHook/useValidations';

interface Props {
	director?: DirectorType,
	id: string,
	allowRemove: boolean,
	updateForm?: (idx: string, handleSubmit: any, validateForm: any, errors: any, isSubmitting: boolean, isDirty: boolean, values: any) => void,
	removeForm?: (idx: string) => void,
	doNotAllowUpdate: boolean
}

const BusinessDirectorItem = ({ id, allowRemove, director, updateForm, removeForm, doNotAllowUpdate }: Props): React.ReactElement => {

	const values = director;
	const { t } = useTranslation('translations');
	const firstNameValidation = useName(t('createbusiness.directors.firstName'));
	const lastNameValidation = useName(t('createbusiness.directors.lastName'));
	const dateValidation = useDate(t('createbusiness.directors.birthDate'));

	// React.useEffect(() => {
	// 	if (formik) {
	// 		formik.validateForm();
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);


	const initialValues = {
		directorFirstName: values?.directorFirstName ?? '',
		directorLastName: values?.directorLastName ?? '',
		directorBirthDate: values?.directorBirthDate ?? '',
		directorPep: values?.directorPep ?? '',
		directorUsCitizen: values?.directorUsCitizen ?? ''
	};

	const validationSchema = Yup.object({
		directorFirstName: firstNameValidation,
		directorLastName: lastNameValidation,
		directorBirthDate: dateValidation,
		directorPep: Yup.boolean()
			.required(t('form.validator.required'))
			.test('directorPep', 'createbusiness.directors.pep',
				(value) => value === false),
		directorUsCitizen: Yup.boolean()
			.required(t('form.validator.required'))
			.test('directorPep', 'createbusiness.directors.pep',
				(value) => value === false)
	});


	const submit = () => {
		//do nothing, use effect handles actual submit 

	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: submit,
	});

	React.useEffect(() => {
		updateForm(id, formik.handleSubmit, formik.validateForm, formik.errors, formik.isSubmitting, formik.dirty, formik.values);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, formik.errors, formik.isSubmitting, formik.dirty, formik.values]);

	return (
		<form
			id="business-director"
			onSubmit={formik.handleSubmit}>
			<Grid container spacing={2}>
				{allowRemove &&
					<Grid item xs={12} mt='1rem' mb='1rem'>
						<StyledDivider />
					</Grid>}
				<Grid item xs={12} container justifyContent='space-between'>
					<MuiSubHeader>{t('createbusiness.directors.director')} - {formik.values.directorFirstName}  {formik.values.directorLastName}</MuiSubHeader>
					{allowRemove &&
						< IconButton onClick={() => removeForm(id)}  >
							<MuiCloseIcon />
						</IconButton>}
				</Grid>
				<Grid item xs={6}>
					<TextField
						id="directorFirstName"
						name="directorFirstName"
						fullWidth
						inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
						label={t('createbusiness.directors.firstName')}
						value={formik.values.directorFirstName}
						onChange={formik.handleChange}
						error={formik.errors.directorFirstName && Boolean(formik.touched.directorFirstName)}
						helperText={formik.errors.directorFirstName} />
				</Grid>
				<Grid item xs={6}>
					<TextField
						id="directorLastName"
						name="directorLastName"
						fullWidth
						inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
						label={t('createbusiness.directors.lastName')}
						value={formik.values.directorLastName}
						onChange={formik.handleChange}
						error={formik.errors.directorLastName && Boolean(formik.touched.directorLastName)}
						helperText={formik.errors.directorLastName} />
				</Grid>
				<Grid item xs={6}>
					<LocalizationProvider dateAdapter={DateAdapter}>
						<DesktopDatePicker
							onChange={(value) => formik.setFieldValue('directorBirthDate', value)}
							value={formik.values.directorBirthDate}
							label={t('createbusiness.directors.birthDate')}
							inputFormat="dd/MM/yyyy"
							disableFuture={true}
							minDate={new Date('1900-01-01')}
							openTo="year"
							views={['year', 'month', 'day']}
							renderInput={(params) => (
								<TextField
									name="directorBirthDate"
									label={t('createbusiness.directors.birthDate')}
									fullWidth
									placeholder='DD/MM/YYYY'
									{...params}
									inputProps={{
										...params.inputProps,
										autoComplete: 'off',
										readOnly: doNotAllowUpdate
									}}
									error={formik.errors.directorBirthDate && Boolean(formik.touched.directorBirthDate)}
									helperText={formik.errors.directorBirthDate}
								/>)}

						/>
					</LocalizationProvider>
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel
						name='directorPep'
						disabled={doNotAllowUpdate}
						control={<StyledCheckbox name='pep'
							onClick={() => { formik.setFieldValue('directorPep', !formik.values.directorPep); }}
							value={formik.values.directorPep}
							checked={formik.values.directorPep === false}
							size='small'
						/>}
						label={<MuiFormText>{t('createbusiness.directors.pep')}</MuiFormText>}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel
						name='directorUsCitizen'
						disabled={doNotAllowUpdate}
						control={<StyledCheckbox name='directorUsCitizen'
							onClick={() => { formik.setFieldValue('directorUsCitizen', !formik.values.directorUsCitizen); }}
							value={formik.values.directorUsCitizen}
							checked={formik.values.directorUsCitizen === false}
							size='small'
						/>}
						label={<MuiFormText>{t('createbusiness.directors.us')}</MuiFormText>}
					/>
				</Grid>
			</Grid>
		</form >
	);
};

export default BusinessDirectorItem;

