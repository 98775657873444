import request from '@services/request';
import { FileKey, FileStatus } from '@features/files/types';

export const postFile = async (payload: FormData): Promise<FileKey> => {
	const response = await request.post('/api/attachments', payload);
	const { data } = response;
	return data;
};

export const postEmployeeDocuments = async (payload: FormData): Promise<FileStatus> => {
	const response = await request.post('/api/users/employee-docs', payload);
	const { data } = response;
	return data;
};

export const postUserPoa = async (payload: FormData): Promise<FileStatus> => {
	const response = await request.post('/api/poa', payload);
	const { data } = response;
	return data;
};

export const postBusinessPaymentProof = async (clientId: string, payload: FormData): Promise<any> => {
	const response = await request.post(`/api/v3/business/payment/proof/${clientId}`, payload);
	const { data } = response;
	return data;
};

export const postTransactionAttachments = async (payload: any, id: string): Promise<any> => {
	const response = await request.post(`/api/transactions/${id}/attachments`, payload);
	const { data } = response;
	return data;
};

export const postBulkTopUp = async (payload: FormData): Promise<any> => {
	const response = await request.post('/api/cards/bulk-top-up', payload);
	const { data } = response;
	return data;
};

export const getUserAttachment = async (userId: number, key: string, name: string) => {
	const response = await request.get(`/api/users/attachment/${userId}/${key}`,
		{
			responseType: 'blob',
			params: {
				cacheBustTimestamp: Date.now(),
			},
		});
	const url = window.URL.createObjectURL(response.data);
	const link = document.createElement('a');
	link.download = name;
	link.href = url;
	link.className = 'hidden';
	document.body.appendChild(link);

	link.onclick = function () {
		requestAnimationFrame(function () {
			URL.revokeObjectURL(url);
			setTimeout(() => link.remove(), 300);
		});
	};

	link.click();
};

export const getUserAttachmentImage = async (userId: number, key: string) => {
	const response = await request.get(`/api/users/attachment/${userId}/${key}`,
		{
			responseType: 'blob',
			params: {
				cacheBustTimestamp: Date.now(),
			},
		});
	const url = window.URL.createObjectURL(response.data);
	return url;
};

export const getAttachmentImage = async (key: string) => {
	const response = await request.get(`/api/attachments/${key}`,
		{
			responseType: 'blob',
			params: {
				cacheBustTimestamp: Date.now(),
			},
		});
	const url = window.URL.createObjectURL(response.data);
	return url;
};
