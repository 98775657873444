import { InvoiceProduct } from '@features/invoices/invoicesTypes';
import { Dialog, DialogContent, DialogTitle, Grid, InputAdornment } from '@mui/material';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import React, { useState } from 'react';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import LoadingButton from '@/componentsMui/Shared/Components/LoadingButton';
import { getCurrencySign } from '@/helpers/currency';
import MuiAmountField from '@/componentsMui/Shared/FormikComponents/MuiAmountField';
import FormikAutocompleteOnlyValue from '@/componentsMui/Shared/FormikComponents/FormikAutocompleteOnlyValue';

interface Props {
    open: boolean;
    onClose: () => void;
    product: InvoiceProduct | null;
    onSave: (mode: 'ADD' | 'EDIT', product: InvoiceProduct) => void;
    unitOptions: any[];
}

const AddEditProductDialog = ({ open, onClose, onSave, product, unitOptions }: Props) => {
    const { t } = useTranslation('translations');
    const isEditMode = !!product;
    const [priceAmount, setPriceAmount] = useState<string>(product?.price.toString() ?? '0');

    const initialValues = {
        description: product?.description ?? '',
        price: product?.price.toString() ?? '',
        unit: product?.unit ?? '',
        quantity: product?.quantity ?? '',
        taxRate: product?.taxRate ? product.taxRate * 100 : '0',
    };

    const validationScheme = Yup.object({
        price: Yup.number().typeError(t('form.validator.required')).required(t('form.validator.required')).nullable()
            .moreThan(0, t('form.validator.moreThanZero')),
        quantity: Yup.number().typeError(t('form.validator.required')).required(t('form.validator.required')).nullable()
            .moreThan(0, t('form.validator.moreThanZero')),
        description: Yup.string().required(t('form.validator.required')),
        unit: Yup.string().required(t('form.validator.required')),
        taxRate: Yup.string().required(t('form.validator.required')),
    });


    const submit = (formData: any, formikProps: any) => {
        const { setSubmitting } = formikProps;

        setSubmitting(true);
        const { description, price, unit, quantity, taxRate } = formData;

        const _taxRate = taxRate / 100;

        const payload: InvoiceProduct = {
            description,
            price: parseFloat(price),
            unit,
            quantity,
            taxRate: _taxRate,
            uuid: isEditMode ? product.uuid : new Date().getTime().toString(),
            type: 'custom',
        };
        onSave(isEditMode ? 'EDIT' : 'ADD', payload);
        setPriceAmount('0');
        setSubmitting(false);
    };



    const handlePriceChange = (_price: string, setFieldValue: any, setFieldTouched: any) => {
        _price = _price.replaceAll(',', '');
        setPriceAmount(_price);
        setFieldValue('price', _price);
        setTimeout(() => setFieldTouched('price', true));
    };

    return (
        <Dialog
            open={open}
            maxWidth='xs'
        >
            <DialogTitle>
                <FormHeader
                    title={isEditMode ? t('invoice.editProduct') : t('invoice.addProduct')}
                    onBack={onClose}
                />
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationScheme}
                    onSubmit={submit}
                    enableReinitialize
                >
                    {({ isSubmitting, errors, setFieldValue, setFieldTouched }) => (
                        <Form>
                            <Grid container gap={2}>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        name='description'
                                        label={t('invoice.description')}
                                        fullWidth
                                        inputProps={{ maxLength: 100 }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiAmountField
                                        name='price'
                                        label={t('invoice.unitPrice')}
                                        currencySign={getCurrencySign('EUR')}
                                        onChange={val => handlePriceChange(val, setFieldValue, setFieldTouched)}
                                        value={priceAmount}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormikAutocompleteOnlyValue
                                        fieldName='unit'
                                        label={t('invoice.unit')}
                                        options={unitOptions}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        name='quantity'
                                        label={t('invoice.quantity')}
                                        fullWidth
                                        type="number"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        name='taxRate'
                                        label={t('invoice.taxRate')}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        }}
                                    />
                                </Grid>


                                <Grid item xs={12} mt={2}>
                                    <LoadingButton
                                        disabled={isSubmitting || !isEmpty(errors)}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting}
                                    >
                                        {isEditMode ? t('invoice.edit') : t('invoice.save')}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default AddEditProductDialog;
