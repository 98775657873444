import { Process } from '../card/types';

export interface AccountAddressesType {
	accountAddressId: number,
	address?: string,
	token?: string,
	network?: string,
	label?: string
}

export interface BankAccount {
	accountAddresses?: AccountAddressesType[],
	name: string,
	integration?: AccountIntegration,
	walletId: string,
	accountId: number,
	balance: number,
	balanceOnHold: number,
	availableBalance?: number,
	currency: string,
	currencySymbol?: string,
	currencyLogo?: string,
	status: string,
	iban?: string,
	bankName?: string,
	bankAddress?: string,
	swift?: string,
	detail?: any,
	providerType?: string,
	recipient?: string,
	subProcesses?: any,
	proc?: string,
	parentWalletId?: string,
	type?: string,
	bank?: string,
	bankCountry?: string,
	recipientAccount?: string,
	cardNumberMask?: string,
	bicCode?: string,
	sortCode?: string,
	ukSortCode?: string,
	ukAccountNumber?: string,
	usBankAddress?: string,
	usBankCountry?: string,
	ukBankAddress?: string,
	ukBankCountry?: string,
	immediateBankName?: string,
	balanceInEur: string,
	balanceInUsd: string,
	balanceInGbp: string,
	precision: number,
	tokenId?: string,
	process?: Process,
	userTag?: string,
}

export interface DepositInstructions {
	accountNumber?: string,
	bankPhone?: string,
	bankAddress?: string,
	beneficiaryAddress?: string,
	creditTo?: string,
	depositoryBankName?: string,
	reference?: string,
	routingNumber?: string,
	swiftCode?: string,
	notes?: string,
	intermediaryBankAccountNumber?: string,
	intermediaryBankAddress?: string,
	intermediaryBankName?: string,
	intermediaryBankSwiftBic?: string,
	intermediaryBsbCode?: string,
	intermediaryIban?: string,
	intermediarySortCode?: string,
}

export interface AccountLimits {
	actualAnnual: number,
	actualDaily: number,
	actualMonthly: number,
	annual: number,
	daily: number,
	maxAmount: number,
	minAmount: number,
	monthly: number,
	name: string,
	currencySymbol: string,
	ccy: string,
	availableBalanceNow: number
}


export interface BankAccountStore {
	loading: boolean,
	list: Array<BankAccount>,
	error: string
}

export interface AccountChartResponse {
	amount: number,
	amountString: string,
	date: string
}

export enum AccountIntegration {
	KRAKEN = 'KRAKEN',
	TRUST = 'TRUST',
	STOCKS = 'STOCKS',
	FIREBLOCKS = 'FIREBLOCKS',
	BANKINGCIRCLE = 'BANKINGCIRCLE',
	VAULT = 'VAULT',
	COINBASE = 'COINBASE',
	DECTA = 'DECTA',
	MODULR = 'MODULR',
	DUMMY_INTEGRATION = 'DUMMY_INTEGRATION',
	CLEARJUNCTION_POOL = 'CLEARJUNCTION_POOL',
	CLEARJUNCTION_WALLET = 'CLEARJUNCTION_WALLET',
	IXOPAY = 'IXOPAY',
	TOKENS = 'TOKENS',
	INTERCASH_MC = 'INTERCASH_MC',
	CAMBRIDGE = 'CAMBRIDGE',
	PRIMETRUST = 'PRIMETRUST',
	INTERCASH_VISA = 'INTERCASH_VISA',
	RAILSBANK = 'RAILSBANK',
}

export enum PeriodEnum {
	DAY = 'day',
	WEEK = 'week',
	MONTH = 'month'
}

export enum CharTypeEnum {
	WALLET = 'WALLET',
	BANK = 'BANK',
	CARD = 'CARD'
}
