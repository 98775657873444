import * as React from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Typography } from '@mui/material';
import { useField } from 'formik';
import { BankAccount } from '@/features/account/types';
import { isEmpty } from 'lodash';
import StyledSelect from '@/componentsMui/Shared/Widgets/StyledSelect';
import { useTranslation } from 'react-i18next';
import { getCurrencySign } from '@/features/fees/slice';
import { getAccountName } from '@/helpers/getAccountName';
import bigDecimal from 'js-big-decimal';
interface Props {
	fieldName: string,
	accountList: BankAccount[],
	fieldlabel?: string,
	className?: string,
	textToRight?: boolean,
	selectedAccount?: BankAccount,
	onAccountChange?: (value: BankAccount) => void,
	precision?: number
}

import MuiFormHelperText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormHelperText';

const formatBalance = (amount: number, precision?: number) => {
	return precision ?
		new bigDecimal(amount ?? 0).round(precision, bigDecimal.RoundingModes.DOWN).getValue() :
		new bigDecimal(amount).getValue();
};

const AccountDropdown = ({ fieldName, accountList, fieldlabel, selectedAccount, onAccountChange, className, textToRight, precision }: Props) => {
	const { t } = useTranslation('translations');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField(fieldName);
	const { setValue } = helpers;
	const { value } = meta;
	const externalState = !(onAccountChange === undefined);

	const handleChange = (event: any) => {
		const newAccountId = event.target.value;
		setValue(newAccountId);
		onAccountChange && onAccountChange(accountList.find(p => p.accountId === newAccountId));
	};

	const internalAccount = accountList.find(p => p.accountId === value);
	//${selectedAccount?.name} 

	const validationFailed = meta && meta.touched && meta.error;

	const selectedValue = (account: BankAccount) => {
		return (<Grid container columnGap={1} >
			<Typography
				fontWeight='400'
				fontSize='0.875rem'
				lineHeight='1.375rem' > {getAccountName(t, account)}</Typography>
			<Typography
				fontWeight='400'
				fontSize='0.875rem'
				lineHeight='1.375rem'>-</Typography>
			<Typography
				fontWeight='400'
				fontSize='0.875rem'
				lineHeight='1.375rem'
				variant='gradient'> {getCurrencySign(account?.currency)} {formatBalance(account.availableBalance, precision)}
			</Typography>
		</Grid>);
	};

	const textFieldValue = externalState ?
		(isEmpty(selectedAccount) ? t('banking.data.selectAccount') : selectedValue(selectedAccount)) :
		(!(value) ? t('banking.data.selectAccount') : selectedValue(internalAccount));


	return (
		<FormControl fullWidth className={className}>
			{fieldlabel && <InputLabel> {fieldlabel}</InputLabel>}
			<StyledSelect
				name={fieldName}
				value={value ?? ''}
				displayEmpty
				onChange={handleChange}
				renderValue={() => <div style={{ textAlign: textToRight ? 'right' : 'inherit' }}> <span>{textFieldValue}</span></div>}
			>
				{accountList.length > 0 &&
					accountList.map((account) => (
						<MenuItem
							key={account.accountId}
							value={account.accountId}>
							<div>
								<div className='select-primary-Item'>{getAccountName(t, account)} </div>
								<div className='select-hide-Item'>{getCurrencySign(account.currency)} {formatBalance(account.availableBalance, precision)} </div>
							</div>
						</MenuItem>
					))}
			</StyledSelect>
			{validationFailed && <MuiFormHelperText>{meta.error}</MuiFormHelperText>}
		</FormControl>
	);
};

export default AccountDropdown;
