import { BankAccount } from '@/features/account/types';
import { Contact } from '@/features/contacts/types';
import * as React from 'react';
import { useState } from 'react';
import TrustPaymentConfirmation from './TrustPaymentConfirmation';
import TrustPaymentDetails, { initTrustPayload, TrustPayloadType } from './TrustPaymentDetails';

export interface Props {
	step: number,
	defaultAccount?: BankAccount,
	defaultContact?: Contact,
	prevStep?: () => void,
}

const TrustPaymentSteps = ({ step, defaultAccount, defaultContact, prevStep }: Props) => {

	const [trustPayload, setTrustPayload] = useState<TrustPayloadType>(initTrustPayload);
	const [stepLocal, setStepLocal] = React.useState<number>(step);
	const nextStepLocal = () => setStepLocal(previousState => (previousState + 1));
	const prevStepLocal = () => setStepLocal(previousState => (previousState - 1));

	return (< >
		{stepLocal === 1 && <TrustPaymentDetails
			defaultAccount={defaultAccount}
			defaultContact={defaultContact}
			trustPayload={trustPayload}
			setTrustPayload={setTrustPayload}
			nextStep={nextStepLocal}
			prevStepMain={prevStep}
		/>
		}
		{stepLocal === 2 && <TrustPaymentConfirmation
			trustPayload={trustPayload}
			prevStep={prevStepLocal} />
		}
	</>);
};

export default TrustPaymentSteps;
