import React from 'react';

import {Grid, IconButton, Theme} from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiFormText from './MuiText/MuiFormText';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {useHistory} from 'react-router-dom';
import {SxProps} from '@mui/system';



interface Props {
	onClick?: () => void
	sx?: SxProps<Theme>;
}

const BackButton = ({ onClick, sx }: Props) => {
	const { t } = useTranslation('translations');
	const history = useHistory();
	return (
		<IconButton
			sx={{...sx, px: 0, py: 1}}
			onClick={() => onClick ? onClick() : history.goBack()}>
			<Grid container alignItems='center'>
				<ArrowBackIosNewIcon sx={{ fontSize: '12px' }}  />
				<MuiFormText pl='10px' fontWeight={600}>{t('form.buttons.back')}</MuiFormText>
			</Grid>
		</IconButton>
	);
};

export default BackButton;
