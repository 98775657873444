import MonoBgBox from '@/componentsMui/Shared/Widgets/Box/CoverBox/MonoBgBox';
import MuiCopyToClipboard from '@/componentsMui/Shared/Widgets/MuiCopyToClipboard';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import { getCurrencySign } from '@/features/fees/slice';
import { getPrograms, getStatistics } from '@/features/referrals/slice';
import { GridTab, Program, RewardPaymentType, RewardTypes, Statistics } from '@/features/referrals/types';
import { showException } from '@/features/swal/slice';
import LoadingPageMui from '@/pages/LoadingPageMui';
import { RootState } from '@/rootReducer';
import {
    Box,
    // Button,
    Divider,
    Grid,
    Tab,
    Tabs,
    Theme,
    Tooltip,
    useMediaQuery,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import bigDecimal from 'js-big-decimal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import MyReferralsGrid from './MyReferralsGrid';
import MyRewardGrid from './MyRewardGrid';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ClickableTooltip from '@/componentsMui/Shared/Widgets/ClickableTooltip';
import { t } from '@/helpers/translate';

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        backgroundColor: '#1D2024',
        padding: '30px',
        borderRadius: '10px',
    },
    dashboardTitle: {
        fontSize: '1rem',
        fontWeight: 700,
    },
    dashboardItemLabel: {
        fontSize: '0.875rem',
        color: theme.palette.text.secondary,
        fontWeight: 400,
    },
    dashboardItemValue: {
        fontSize: '1.625rem',
        fontWeight: 700,
    },
    dashboardItemChange: {
        fontSize: '0.813rem',
    },
    myReferralLabel: {
        fontSize: '0.875rem',
        color: theme.palette.text.secondary,
        fontWeight: 400,
    },
    myReferralValue: {
        fontSize: '0.875rem',
        fontWeight: 400,
    },
    myReferralCardLabel: {
        fontSize: '0.813rem',
        color: theme.palette.text.secondary,
        fontWeight: 400,
    },
    myReferralCardValue: {
        fontSize: '1.25rem',
        fontWeight: 700,
    },
    divider: {
        marginBottom: '30px',
        marginTop: '30px',
    },
    details: {
        fontSize: '0.813rem',
        fontWeight: 400,
        cursor: 'pointer',
    }
}));



const Referrals = (): React.ReactElement => {
    const smallScreen = useMediaQuery('(max-width:600px)');
    const { t } = useTranslation('translations');
    const theme = useTheme() as Theme;
    const classes = useStyles(theme);
    const [statistics, setStatistics] = useState<Statistics>(null);
    const [programs, setPrograms] = useState<Program[]>(null);
    const [loadingData, setLoadingData] = useState<boolean>(true);
    const [gridTab, setGridTab] = useState<GridTab>(GridTab.MY_REFERRALS);

    const { user } = useSelector((state: RootState) => state.user);
    const { v3PortalUrl } = useSelector((state: RootState) => state.status);



    useEffect(() => {
        const get = async () => {
            try {
                setLoadingData(true);
                const statisticsResponse = await getStatistics();
                const programsResponse = await getPrograms();
                setStatistics(statisticsResponse);
                setPrograms(programsResponse);
            } catch (e) {
                showException(e);
            } finally {
                setLoadingData(false);
            }

        };
        get();
    }, []);

    const portalUrl = v3PortalUrl.slice(-1) === '/' ? v3PortalUrl.slice(0, -1) : v3PortalUrl;
    const referralUrl = `${portalUrl}/auth/register/user?referralCode=${user?.tag ?? user.clientId}`;
    const referralUrlMask = `${referralUrl.slice(0, 15)}...${referralUrl.slice(-4)}`;
    const feeProgramm = programs?.find(program => (program.code === RewardTypes.FEES || program.code === RewardTypes.ADVANCED_FEES)) || null;
    const signUpBonusProgramm = programs?.find(program => program.code === RewardTypes.SIGN_UP_BONUS) || null;


    console.log(programs);
    if (loadingData) {
        return <LoadingPageMui />;
    }

    return (
        <Grid container>
            <Grid item xs={12} alignItems="center" mb="30px">
                <MuiHeader>{t('referral.title')}</MuiHeader>
            </Grid>

            <Grid container item xs={12} justifyContent="space-between">
                <Grid
                    container
                    item
                    xs={12}
                    md={7.8}
                    className={classes.form}
                    alignItems="flex-start"
                >
                    <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="space-between"
                        mb="25px"
                    >
                        <MuiTextCaption className={classes.dashboardTitle}>
                            {t('referral.dashboardTitle')}
                        </MuiTextCaption>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DashboardItem
                            ccy={statistics.totalEarnings.currency}
                            title={t('referral.yourErnings')}
                            value={statistics.totalEarnings.amount}
                            change={statistics.totalEarnings.changeAmount}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DashboardItem
                            title={t('referral.upcomingEarnings')}
                            value={statistics.plannedReferralsStatistic.amount}
                            change={statistics.plannedReferralsStatistic.count}
                            count={true}
                            ccy={statistics.plannedReferralsStatistic.currency}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DashboardItem
                            title={t('referral.firends')}
                            value={statistics.friends.count}
                            change={statistics.friends.countLastWeek}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <MuiTextCaption
                            className={classes.dashboardItemLabel}
                            mb="8px"
                        >
                            {t('referral.topReferrerBonus')}
                        </MuiTextCaption>
                        <MuiTextCaption
                            className={classes.dashboardItemValue}
                            mb="2px"
                        >
                            <NumberFormat
                                displayType={'text'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandsGroupStyle="thousand"
                                thousandSeparator={true}
                                value={statistics.topReferrer.amount ?? 0}
                                suffix={getCurrencySign(statistics.topReferrer.ccy ?? 'EUR')}
                            />
                        </MuiTextCaption>
                        {statistics.topReferrer.amount && <MuiTextCaption className={classes.dashboardItemChange}>
                            {statistics.topReferrer.userEmailMask ?? statistics.topReferrer.userPhoneMask}
                        </MuiTextCaption>}

                    </Grid>
                </Grid>

                <Grid container item xs={12} md={4} className={classes.form}>
                    <Grid item xs={12} display='flex' alignItems="center" mb="25px" justifyContent='space-between'>
                        <MuiTextCaption className={classes.dashboardTitle}>
                            {t('referral.myReferralTitle')}
                        </MuiTextCaption>
                        {/* <MuiTextCaption variant='gradient' className={classes.details}>
                            {t('referral.details')}
                        </MuiTextCaption> */}
                    </Grid>
                    <Grid item xs={12}>
                        <MonoBgBox
                            p="20px"
                            bgIndex={0}
                            selected={true}
                            mainClassName={'earnStatisticsCard'}
                        >
                            <Grid container>
                                <Grid item xs={6}>
                                    <Grid item display='flex' mb="8px">
                                        <MuiTextCaption
                                            className={classes.myReferralCardLabel}
                                        >
                                            {t('referral.youReceive')}
                                        </MuiTextCaption>
                                        <ClickableTooltip title={t('referral.youReceiveInfo')} placement="top-start">
                                            <InfoOutlinedIcon sx={theme => ({ fontSize: '0.813rem', fill: theme.palette.text.secondary, marginLeft: '0.25em' })} />
                                        </ClickableTooltip>
                                    </Grid>
                                    <MuiTextCaption
                                        className={classes.myReferralCardValue}
                                    >
                                        {feeProgramm ? <NumberFormat
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandsGroupStyle="thousand"
                                            thousandSeparator={true}
                                            value={feeProgramm ? feeProgramm.rewardAmount : 0}
                                            suffix={feeProgramm?.paymentType === RewardPaymentType.PERCENT ? '%' : getCurrencySign(feeProgramm?.rewardCcy)}
                                        /> : '0%'}
                                    </MuiTextCaption>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid item display='flex' mb="8px">
                                        <MuiTextCaption
                                            className={classes.myReferralCardLabel}
                                        >
                                            {t('referral.friendReceive')}
                                        </MuiTextCaption>
                                        <ClickableTooltip title={t('referral.friendReceiveInfo', { ccy: signUpBonusProgramm?.targetCcy ?? 'EUR', amount: signUpBonusProgramm?.targetAmount ?? '0' })} placement="top-start">
                                            <InfoOutlinedIcon sx={theme => ({ fontSize: '0.813rem', fill: theme.palette.text.secondary, marginLeft: '0.25em' })} />
                                        </ClickableTooltip>
                                    </Grid>

                                    <MuiTextCaption
                                        className={classes.myReferralCardValue}
                                    >
                                        {signUpBonusProgramm ? <NumberFormat
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandsGroupStyle="thousand"
                                            thousandSeparator={true}
                                            value={signUpBonusProgramm ? signUpBonusProgramm.rewardAmount : 0}
                                            suffix={signUpBonusProgramm?.paymentType === RewardPaymentType.PERCENT ? '%' : getCurrencySign(signUpBonusProgramm?.rewardCcy)}
                                        /> : '0EUR'}
                                    </MuiTextCaption>
                                </Grid>
                            </Grid>
                        </MonoBgBox>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        mb="10px"
                        alignItems="center"
                    >
                        <MuiTextCaption className={classes.myReferralLabel}>
                            {t('referral.referralID')}
                        </MuiTextCaption>
                        <Grid
                            item
                            alignItems="center"
                            display="flex"
                            flexDirection="row"
                        >
                            <MuiTextCaption className={classes.myReferralValue}>
                                {user?.tag ?? user.clientId}
                            </MuiTextCaption>
                            <MuiCopyToClipboard value={user?.tag ?? user.clientId} />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <MuiTextCaption className={classes.myReferralLabel}>
                            {t('referral.referralLink')}
                        </MuiTextCaption>
                        <Grid
                            item
                            alignItems="center"
                            display="flex"
                            flexDirection="row"
                        >
                            <MuiTextCaption className={classes.myReferralValue}>
                                {referralUrlMask}
                            </MuiTextCaption>
                            <MuiCopyToClipboard
                                value={referralUrl}
                            />
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} mt="30px">
                        <Button variant="contained" fullWidth>
                            {t('referral.invite')}
                        </Button>
                    </Grid> */}
                </Grid>
            </Grid>
            {!smallScreen && <Grid item xs={12} alignItems="center" mt="48px">
                <Grid item xs={12} mb={'30px'}>
                    <Tabs
                        value={gridTab}
                        onChange={(e, val) => setGridTab(val)}
                        variant="standard"
                        scrollButtons={false}
                    >
                        <Tab label={t('referral.myReferrals')} value={GridTab.MY_REFERRALS} />
                        <Tab label={t('referral.myRewards')} value={GridTab.MY_REWARDS} />
                    </Tabs>
                </Grid>
                <Grid item xs={12}>
                    <Box id='banking-account-view'
                        sx={(theme) => ({
                            background: {
                                xs: 'none', sm: '#1D2024'
                            },
                            borderRadius: theme.mainBorderRadius,
                            p: '20px',
                            width: '100%',
                            mb: { xs: 3, md: 0 },
                        })}>
                        {gridTab === GridTab.MY_REFERRALS ? <MyReferralsGrid /> : <MyRewardGrid />}
                    </Box>
                </Grid>
            </Grid>}
        </Grid>
    );
};

export default Referrals;


interface DashboardItemProps {
    title: string;
    value: number;
    ccy?: string;
    change: number;
    count?: boolean;
}

const DashboardItem = ({ title, value, ccy, change, count }: DashboardItemProps) => {
    const theme = useTheme() as Theme;
    const classes = useStyles(theme);

    const getChangeSign = () => {
        const changeCompareResult = bigDecimal.compareTo(change, 0);
        switch (changeCompareResult) {
            case 0:
                return '=';
            case 1:
                return '+';
            case -1:
                return '-';
        }
    };

    const getChangeColor = () => {
        if (count) {
            return theme.palette.text.secondary;
        }
        const changeCompareResult = bigDecimal.compareTo(change, 0);

        switch (changeCompareResult) {
            case 0:
                return theme.palette.text.secondary;
            case 1:
                return theme.statusColor.success;
            case -1:
                return theme.statusColor.error;
        }
    };

    return (
        <>
            <MuiTextCaption className={classes.dashboardItemLabel} mb="8px">
                {title}
            </MuiTextCaption>
            {ccy ? (
                <>
                    <MuiTextCaption
                        className={classes.dashboardItemValue}
                        mb="2px"
                    >
                        <NumberFormat
                            displayType={'text'}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandsGroupStyle="thousand"
                            thousandSeparator={true}
                            value={value}
                            suffix={getCurrencySign(ccy)}
                        />
                    </MuiTextCaption>
                    <Tooltip placement="top-start" title={count ? t('referral.transactionsCount') : t('referral.changesLastWeek')} >
                        <span>
                            <MuiTextCaption
                                className={classes.dashboardItemChange}
                                color={getChangeColor()}
                            >
                                {count ? change : <NumberFormat
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator={true}
                                    value={change}
                                    prefix={getChangeSign()}
                                    suffix={getCurrencySign(ccy)}
                                />}
                            </MuiTextCaption>
                        </span>
                    </Tooltip>
                </>
            ) : (
                    <>
                        <MuiTextCaption
                            className={classes.dashboardItemValue}
                            mb="2px"
                        >
                            {value}
                        </MuiTextCaption>
                        <Tooltip placement="top-start" title={t('referral.changesLastWeek')}>
                            <span>
                                <MuiTextCaption
                                    className={classes.dashboardItemChange}
                                    color={getChangeColor()}
                                >
                                    {getChangeSign()}{change}
                                </MuiTextCaption>
                            </span>
                        </Tooltip>
                    </>
                )
            }
        </>
    );
};
