import { startCase } from 'lodash';

/*
Changes values like PENDING_EXTERNALLY to Pending externally*/


export const normalizeString = (value: string) => {

    const v = value ? value.toString().toUpperCase().replaceAll('_', ' ') : '';
    return v.charAt(0).toUpperCase() + v.slice(1).toLowerCase();
};

/* Changes values like PENDING_EXTERNALLY to Pending Externally*/
export const capitalCaseString = (value: string) => {

    const v = value ? value.toString().toUpperCase().replaceAll('_', ' ') : '';
    return startCase(v.toLowerCase());
};



export const transformAEmptyValuesToNull = (obj: Record<string, string | null>) => {
    for (const key in obj) {
        if (obj[key] === '') {
            obj[key] = null;
        }
    }

    return obj;
};

export const checkAllValuesIsNull = (obj: Record<string, string | null>) => {
    return Object.values(obj).every(value => value === null);
};
