import { Typography, TypographyProps } from '@mui/material';
import React, { FC } from 'react';


const MuiTextAccountBoxMoney: FC<TypographyProps> = (props: TypographyProps) => {

	return (
		<Typography
			textAlign='start' fontSize='1.5rem' lineHeight='2rem' fontWeight='700'
			{...props}>
			{props.children}
		</Typography>
	);
};

export default MuiTextAccountBoxMoney;
