import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, InputAdornment, TextField } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import AttachmentList from '@/componentsMui/Shared/Widgets/AttachmentList';
import MuiFormHelperText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormHelperText';

interface Props {
	sendDisabled?: boolean,
	inputFieldRef: any,
	fileError: string;
	inputValue: string;
	file: File;
	submitMessage: () => void;
	setInputValue: (value: string) => void;
	handleFile: (file: File[]) => void;
}

const MessageInput = ({
	sendDisabled,
	inputFieldRef,
	inputValue,
	setInputValue,
	submitMessage,
	file,
	handleFile,
	fileError
}: Props) => {

	const { t } = useTranslation('translations');
	const { getRootProps, getInputProps, open } = useDropzone({
		onDrop: handleFile,
		accept: 'image/jpg, image/png, image/jpeg, .pdf',
		noClick: true,
		noKeyboard: true,
		maxFiles: 1,
		multiple: false,
	});

	const handleOpen = () => {
		open();
	};

	const onKeyPress = (e: any) => {
		if (e.key === 'Enter' && e.ctrlKey && !sendDisabled) {
			submitMessage();
		}
		else if (e.keyCode === 191 && e.ctrlKey) {
			open();
		}
	};

	return (
		<Grid container columnSpacing={2} sx={{ justifyContent: { xs: 'flex-start', md: 'space-evenly' } }} alignItems='center' ref={inputFieldRef}>
			<Grid item xs={9} >
				<div {...getRootProps()} >
					<input {...getInputProps()} />
					<TextField
						sx={{ '& .MuiOutlinedInput-root': { paddingTop: '8px', paddingBottom: '8px' } }}
						name="message"
						variant="outlined"
						value={inputValue}
						onChange={(e) => setInputValue(e.target.value)}
						onKeyDown={onKeyPress}
						multiline
						minRows={1}
						fullWidth
						placeholder={t('faq.placeholder')}
						InputProps={{
							endAdornment:
								<InputAdornment position='end' >
									<DescriptionOutlinedIcon
										onClick={handleOpen} style={{ height: '1.125rem', cursor: 'pointer' }} />
								</InputAdornment>
						}} />
				</div>
			</Grid>
			<Grid item xs={2} >
				<Button
					variant="contained"
					color='primary'
					onClick={() => {
						submitMessage();
					}}
					fullWidth
					disabled={sendDisabled}
				>
					{t('form.buttons.send')}
				</Button>
			</Grid>
			<Grid item xs={12} height={30}>
				{file &&
					<AttachmentList files={[file]} onDelete={() => handleFile(null)} />}
			</Grid>
			<Grid item xs={12} >
				{fileError && <MuiFormHelperText>{fileError}</MuiFormHelperText>}
			</Grid>
		</Grid >
	);
};

export default MessageInput;
