
import MuiTextLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextLabel';
import { hiddenBabalance } from '@/helpers/placeholders';
import { Tooltip, TypographyProps } from '@mui/material';
import bigDecimal from 'js-big-decimal';
import React from 'react';
import NumberFormat from 'react-number-format';
import InfoIcon from '@mui/icons-material/Info';

interface Props extends TypographyProps {
	amount: number | string,
	precision: number,
	currency: string,
	hideValues: boolean,
	showCurrencyInSuffix?: boolean
}

const AmountCell = (props: Props) => {
	const { amount, precision, hideValues, currency, showCurrencyInSuffix, ...others } = props;
	const balance = bigDecimal.compareTo(amount, 0) === 0 ? 0 : new bigDecimal(amount ?? 0).round(precision, bigDecimal.RoundingModes.DOWN).getValue();
	const isLessThanShow = bigDecimal.compareTo(amount, 0) && bigDecimal.compareTo(balance, 0) === 0;

	return (
		<MuiTextLabel  {...others}>
			{hideValues ? hiddenBabalance :
				<NumberFormat
					displayType={'text'}
					decimalScale={isLessThanShow ? precision + 1 : precision}
					fixedDecimalScale={false}
					thousandsGroupStyle='thousand'
					thousandSeparator={true}
					prefix={isLessThanShow ? '< ' : showCurrencyInSuffix ? '' : `${currency} `}
					suffix={showCurrencyInSuffix ? ` ${currency}` : ''}
					value={isLessThanShow ? `${balance}1` : balance}
				/>
			}
			{isLessThanShow === true && !hideValues &&
				<Tooltip placement="top" title={`${amount} ${currency}`}>
					<InfoIcon fontSize='inherit' sx={{ marginLeft: '0.25em' }} />
				</Tooltip>}
		</MuiTextLabel>
	);
};

export default AmountCell;




