import React from 'react';
import MuiFooter from '@/componentsMui/Footer/Footer';
import { Grid, Container, GridProps, MenuList, ListItemIcon, ListItemText } from '@mui/material';
import AppBarBasic from '@/componentsMui/AppBar/AppBarBasic';
import useBasicAppBarSize from '@/helpers/customHook/useBasicAppBarSize';
import { mobileLogoutHeight } from '@/helpers/uiConstants';
import { useHistory } from 'react-router-dom';
import { ONBORADING } from '@/pages/navigationConstants';
import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '@/features/user/userSlice';
import { showErrorNotification } from '@/features/swal/slice';
import NavbarMenuItemNavLink from '../Shared/Widgets/NavbarMenuItemNavLink';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useTranslation } from 'react-i18next';
import useFooterHeight from '@/helpers/customHook/useFooterHeight';


interface Props {
    children: string | JSX.Element | JSX.Element[],
    leftPanel?: React.ReactNode,
    rightPanel?: string | JSX.Element | JSX.Element[],
    leftPanelGridProps?: GridProps,
    rightPanelGridProps?: GridProps,
    width?: string,
    height?: string,
    padding?: string,
    authorized?: boolean,
    splitColors?: boolean,
    paddingTop?: number,
    hideLogout?: boolean,
    justifyTitle?: string,
    allowGoHome?: boolean,
}

function MinimalLayout({ children, width, height, padding, authorized, splitColors, leftPanel, justifyTitle, rightPanel, paddingTop, leftPanelGridProps, rightPanelGridProps, hideLogout = false, allowGoHome = true }: Props): React.ReactElement {
    const appBarH = useBasicAppBarSize();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation('translations');
    const paddingT = paddingTop ? `${paddingTop}px` : '0px';
    const footerHeight = useFooterHeight();

    const handleGoHome = () => {
        allowGoHome ? history.push(ONBORADING) : console.log('don othing');
    };

    const { token } = useSelector(
        (state: RootState) => state.credentials
    );

    const signout = async () => {
        try {
            dispatch(logout());
            return history.push('/auth');
        }
        catch (e) {
            showErrorNotification(e);
        }
    };
    return (
        <Grid container width='100%'>

            <AppBarBasic authorized={authorized} splitColors={splitColors}
                onLogoClick={handleGoHome}
                justifyTitle={justifyTitle} />
            <Container component='main'

                id='min-layout-container'
                disableGutters={true}
                sx={{
                    minHeight: `calc(100vh - ${appBarH + footerHeight}px)`,
                    width: '100%',
                    flexGrow: 1,
                    marginTop: `${appBarH}px`,
                    justifyContent: 'center',
                    display: 'flex',
                    padding: padding ? padding : '0'
                }}>
                <Grid id='min-layout-grid-item'
                    container
                    justifyContent='center'
                    alignContent='flex-start'
                    height='100%'
                >
                    <Grid id='min-layout-left-panel'
                        item container
                        xs
                        height='100%'
                        sx={{ display: { xs: 'none', sm: 'flex' } }}
                        flexDirection='column'
                        paddingTop={paddingT}
                        {...leftPanelGridProps}>
                        <Grid item width='100%'>
                            {leftPanel}
                        </Grid>
                        {!!token && !hideLogout &&
                            <Grid item
                                container
                                flex={1} width='100%'
                                alignItems='flex-end'>
                                <MenuList sx={{ paddingBottom: '0px' }}>
                                    <NavbarMenuItemNavLink onClick={() => signout()}
                                        sx={(theme) => ({ background: theme.warningBg })}  >
                                        <ListItemIcon>
                                            <LogoutOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText>{t('navbar.logout')}</ListItemText>
                                    </NavbarMenuItemNavLink>
                                </MenuList>
                            </Grid>
                        }

                    </Grid>
                    <Grid id='min-layout-center-panel'
                        item
                        container
                        sx={{
                            maxWidth: width ? width : '592px',
                            width: width ? width : '592px',
                            height: height ? height : '100%',
                            flexGrow: 1,
                            flexDirection: 'column',
                            mb: { xs: 3, sm: 0 },
                            paddingX: width ? '0px' : '14px',
                            paddingTop: paddingT
                        }}>
                        <Grid container width='100%'
                            flexDirection='column'
                            flex={1}>
                            {children}
                        </Grid>
                        {!!token && !hideLogout && <Grid container width='100%'
                            display={{ xs: 'flex', sm: 'none' }}
                            //   flexDirection='column' 
                            justifyContent='center'
                            height={mobileLogoutHeight} >

                            <NavbarMenuItemNavLink onClick={() => signout()}
                                sx={(theme) => ({ background: theme.warningBg })}  >
                                <ListItemIcon>
                                    <LogoutOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText>{t('navbar.logout')}</ListItemText>
                            </NavbarMenuItemNavLink>

                        </Grid>}
                    </Grid>

                    <Grid id='min-layout-right-panel'
                        item container
                        xs
                        paddingTop={paddingT}
                        {...rightPanelGridProps}>
                        {rightPanel}
                    </Grid>
                </Grid>
            </Container >
            <Grid item xs={12} minHeight={footerHeight} >
                <MuiFooter authorized={authorized} />
            </Grid>
        </Grid >
    );
}

export default MinimalLayout;
