import { AccountType } from '@features/operations/types';
import { WalletTransactionType } from '@features/walletHistory/types';

export interface TransactionsPayload {
	accountId?: string,
	from: string,
	to: string,
	search: string,
	type?: string,
	ccy?: string,
	walletTransactionType?: WalletTransactionType,
	templateIdentifier?: string,
}
 

export interface UserTransactionsFilterParams {
	accountTypes: string[],
	skip: number,
	take: number
	from?: number,
	to?: number,
	search?: string
}

export enum TradeHistoryTypes {
	PAYMENT_ORDER = 'PAYMENT_ORDER',
	TRANSFER_ORDER = 'TRANSFER_ORDER',
	CARD_ORDER = 'CARD_ORDER',
	CARD_TRANSACTION = 'CARD_TRANSACTION',
	INCOMING_PAYMENT = 'INCOMING_PAYMENT',
	REVERSAL = 'REVERSAL',
	MONTH_FEE_CALC = 'MONTH_FEE_CALC',
	USER_LEVEL_SUBSCRIPTION = 'USER_LEVEL_SUBSCRIPTION',
	SYSTEM_DEPOSIT_FEE = 'SYSTEM_DEPOSIT_FEE',
	DEACTIVATION = 'DEACTIVATION',
	VAULT_DEPOSIT = 'VAULT_DEPOSIT',
	FX_ORDER = 'FX_ORDER',
	TRADE = 'TRADE',
	CRYPTO_TRADE = 'CRYPTO_TRADE',
	TOKEN_TRADE = 'TOKEN_TRADE',
}


export interface TradeHistoryFilterParams {
	skip: number,
	take: number
	from?: number,
	to?: number,
	fromToCcy?: string,
	trades?: boolean,
	fromToAccountIds?: string,
	type?: TradeHistoryTypes
}

export interface TradeHistoryLineType {
	activity: string,
	amount: number,
	currency: string,
	currencySymbol?: string,
	date: number,
	documentId: number,
	docNumber: string,
	status: string,
	conversionCurrency?: string,
	conversionAmount?: number,
	orderType?: string,
	fee?: number
}

export interface TradeHistoryType {
	count: number,
	list: TradeHistoryLineType[]
}



export interface TransactionsLazyPayload {
	accountId?: string,
	from?: string,
	to?: string,
	search: string,
	skip?: number,
	take?: number,
	initial?: boolean,
	sortBy?: string,
}

export interface TransactionsTypedPayload {
	type?: AccountType,
	accountId?: string,
	from?: string,
	to?: string,
	search: string,
	skip?: number,
	take?: number,
}

export interface TransactionsResult {
	count: number,
	from?: number,
	to?: number,
	list: []
}

export interface GeneralCardTransactionsPayload {
	intercashSortBy?: string,
	intercashSkip?: number,
	intercashSort?: string,
	dectaNextId?: number,
	take: number,
	accountId: number,
}

export interface DectaCardTransactionsPayload {
	take: number,
	accountId: number,
	filter?: string,
	from?: string,
	initial?: boolean,
	skip?: number,
	sort?: string,
	sortBy?: string,
	to?: string
}

export interface ExportPayload {
	templateIdentifier?: string,
	transactionNumber: string
}


export interface TransactionDetail {
	activity: string,
	amount: number,
	asset?: string,
	attachments?: [{ fileId: string, filename: string }],
	beforeChange?: number,
	beneficiary: string,
	beneficiaryAccount?: string,
	beneficiaryBIC?: string,
	beneficiaryBank?: string,
	beneficiaryBankCountry?: string,
	beneficiaryId?: string,
	currency: string,
	currencySymbol?: string,
	date: string,
	details?: string,
	remitterAccount: string,
	remitterBIC?: string,
	remitterBank?: string,
	remitterBankCountry?: string,
	remitterId?: string,
	remitterName: string,
	status: string,
	transactionNumber: string,
}

export enum TransactionType {
	VAULT_DEPOSIT = 'VAULT_DEPOSIT',
	DEPOSIT_REPAYMENT = 'DEPOSIT_REPAYMENT',
	INTEREST_PAYMENT = 'INTEREST_PAYMENT',
	DEPOSIT = 'DEPOSIT',
	WITHDRAW = 'WITHDRAW',
	TRANSFER = 'TRANSFER',
	PAYMENT = 'PAYMENT',
	TOKEN_PAYMENT = 'TOKEN_PAYMENT',
	TRUST_PAYMENT = 'TRUST_PAYMENT',
	EXCHANGE = '',
	PURCHASE = 'PURCHASE',
	CURRENCY_FEE = 'CURRENCY_FEE',
	SYSTEM_FEE = 'SYSTEM_FEE',
	SERVICE_FEE = 'SERVICE_FEE',
	COMMISSION = 'COMMISSION',
	CHARGEBACK = 'CHARGEBACK',
	CARD_TOP_UP = 'CARD_TOP_UP',
	CARD_PULL_OUT = 'CARD_PULL_OUT',
	OWN_TRANSFER = 'OWN_TRANSFER',
	MONTHLY_FEE = 'MONTHLY_FEE',
	SUBSCRIPTION = 'SUBSCRIPTION',
	SYSTEM_DEPOSIT = 'SYSTEM_DEPOSIT',
	LOAN_REPAYMENT = 'LOAN_REPAYMENT',
	INTEREST = 'INTEREST',
	ERN_DEPOSIT = 'ERN_DEPOSIT',
	FX_TRANSACTION = 'FX_TRANSACTION',
	NP = 'NP',
	PROFIT_LOSS = 'PROFIT_LOSS',
	EXT_TRANSACTION_FEE = 'EXT_TRANSACTION_FEE',
	CARD_OPERATION_FEE = 'CARD_OPERATION_FEE',
	PAYMENT_RETURN = 'PAYMENT_RETURN',
	TRADE = 'TRADE',
}

export enum TransactionStatus {
	COMPLETED = 'COMPLETED',
	NEW = 'NEW',
	APPROVAL = 'APPROVAL',
	APPROVED_BY_RISK_MANAGER = 'APPROVED_BY_RISK_MANAGER',
	APPROVED_BY_COMPLIANCE_MANAGER = 'APPROVED_BY_COMPLIANCE_MANAGER',
	EMAIL_APPROVAL = 'EMAIL_APPROVAL',
	PENDING = 'PENDING',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
	CANCELED = 'CANCELED',
	ERROR = 'ERROR',
	FAILED = 'FAILED',
	REVERTED = 'REVERTED',
	FX_PENDING = 'FX_PENDING',
	PENDING_FX = 'PENDING_FX',
	PAID = 'PAID',
	UNPAID = 'UNPAID',
}

export enum Period {
	DAY = 'DAY',
	WEEK = 'WEEK',
	MONTH = 'MONTH',
	CUSTOM = 'CUSTOM',
	ALL = 'ALL'
}
