import { Box, Button, ButtonGroup, Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import SearchField from '@/componentsMui/Shared/Widgets/SearchField';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import WalletChart from '../Components/WalletChart';
import CryptoAssetLine from './Assets/CryptoAssetLine';
import CryptoAssetHeader from './Assets/CryptoAssetHeader';
import WalletBox from '@/componentsMui/Shared/Widgets/Box/WalletBox';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useHistory } from 'react-router-dom';
import CryptoAssetLineMobile from './Assets/CryptoAssetLineMobile';
import { findProcessByType } from '@/features/operations/slice';
import StatusCard from '@/componentsMui/Dashboard/HomeSummary/Components/StatusCard';

interface Props {
	currency: string,
}

const CrytoBalance = ({ currency }: Props): React.ReactElement => {
	const theme = useTheme();
	const history = useHistory();
	const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const { t } = useTranslation('translations');
	const coins = useSelector((state: RootState) => state.coins);
	const { list, loading, portfolioBalance } = coins;
	const { user } = useSelector((state: RootState) => state.user);

	const depositFromCardProcess = list.find(acc => { const p = findProcessByType(acc, 'DEPOSIT_FROM_CARD'); return !p?.maintenance; });
	const allowBuyCryptoByCard = list.filter(acc => acc?.type !== 'WALLET' && !!findProcessByType(acc, 'DEPOSIT_FROM_CARD')?.proc)?.length > 0 && !!depositFromCardProcess;

	const hideConvert = user?.tradesDisabled ?? false;

	const [search, setSearch] = React.useState<string>('');

	const sortedList = [...list];

	sortedList.sort((a, b) => {
		try {
			const aPrice = a?.account?.balanceInEur ?? '0';
			const bPrice = b?.account?.balanceInEur ?? '0';
			const aFiatValue = parseFloat(aPrice);
			const bFiatValue = parseFloat(bPrice);
			if (aFiatValue > bFiatValue) { return -1; }

			else if (aPrice === bPrice) {
				if (a?.info?.symbol < b?.info?.symbol) { return -1; }
			}
			else { return 1; }
		}
		catch (e) {
			return -1;
		}
	});

	const filredTokens = search === ''
		? sortedList
		: sortedList.filter(t => t?.info?.symbol?.toUpperCase().includes(search.toUpperCase()) || t?.info?.displayName?.toUpperCase().includes(search.toUpperCase()));

	const handleSearchRequest = (e) => {
		setSearch(e.target.value);
	};

	const openDepositForm = () => {
		history.push('/wallet/home/wallets/deposit');
	};

	const openSendForm = () => {
		history.push('/wallet/home/wallets/send');
	};

	const handleTrade = () => {
		history.push('/wallet/home/wallets/crypto/trade');
	};

	const handleByCrypto = () => {
		history.push('/wallet/home/wallets/card');
	};

	return (
		loading
			? <Box padding={'2rem 1rem'} >
				<Spinner />
			</Box>
			: <>
				<Grid container >
					<Grid item xs={12}>
						<StatusCard />
					</Grid>
				</Grid>
				<Grid container justifyContent='space-between' mb='1rem'>
					<Grid item xs={12} container alignItems='center'>
						<Grid item xs={12} sm={4} >
							<MuiHeader>
								{t('wallet.labels.cryptoBalance')}
							</MuiHeader>
						</Grid>
						<Grid item xs={8} sx={{ display: { xs: 'none', md: 'flex' } }} container alignItems='baseline' justifyContent='flex-end' columnSpacing={2} >
							{allowBuyCryptoByCard &&
								<Grid item>
									<Button variant="simple" startIcon={<AddIcon fontSize='small' />} onClick={handleByCrypto}>
										{t('wallet.labels.buyCrypto')}
									</Button>
								</Grid>}
							<Grid item>
								<Button variant="simple" startIcon={<AddIcon fontSize='small' />} onClick={openDepositForm}>
									{t('wallet.labels.deposit')}
								</Button>
							</Grid>
							<Grid item>
								<Button disabled={user.detained} style={{ filter: user.detained ? 'blur(2px)' : null }}
									variant="simple" startIcon={<SyncAltIcon fontSize='small' />} onClick={openSendForm}>
									{t('wallet.labels.transfer')}
								</Button>
							</Grid>
							{!hideConvert && <Grid item>
								<Button
									variant="simple"
									startIcon={<AutorenewIcon />}
									onClick={handleTrade}>
									{t('wallet.labels.convert')}
								</Button>
							</Grid>}
						</Grid>
					</Grid>
					<Grid item container xs={12} alignItems='baseline' justifyContent='center'
						sx={{ display: { xs: 'flex', md: 'none' }, mt: '1rem' }}>
						<ButtonGroup variant="contained" fullWidth={true} aria-label="outlined primary button group" sx={{ height: '100%' }}>
							{allowBuyCryptoByCard &&
								<Button
									variant='transparent'
									onClick={handleByCrypto}>
									{t('wallet.labels.buyCrypto')}
								</Button>}
							<Button
								variant="transparent"
								onClick={openDepositForm}>
								{t('wallet.labels.deposit')}
							</Button>
							<Button
								style={{ filter: user.detained ? 'blur(2px)' : null }}
								variant='transparent'
								onClick={openSendForm}
								disabled={user.detained}>
								{t('wallet.labels.transfer')}
							</Button>
							{!hideConvert
								? <Button variant='transparent' onClick={handleTrade}>
									{t('wallet.labels.convert')}
								</Button>
								: null}
						</ButtonGroup>
					</Grid>
				</Grid >
				<WalletChart totalBalance={portfolioBalance} />
				<WalletBox>
					<Grid container item
						justifyContent='space-between'
						alignItems='center'
						mb={{ xs: '10px', md: 0 }}>
						<MuiHeader mb={{ xs: '5px', md: 0 }}>
							{t('wallet.labels.myBalance')} </MuiHeader>
						<SearchField value={search}
							onChange={(e) => handleSearchRequest(e)} />
					</Grid>
					{(!filredTokens || filredTokens.length === 0)
						? <>
							{search === '' ? <div style={{
								display: 'flex',
								justifyContent: 'space-around'
							}}>{t('wallet.willBeCreated')}
							</div>
								: <div style={{
									display: 'flex',
									justifyContent: 'space-around'
								}}>{t('wallet.noAssetsFound')}
								</div>}
						</>
						: <Grid container >
							{smallScreen
								? filredTokens?.map((item, index) => (
									<Grid item xs={12} key={`${item.type}-${item.info.symbol}${index}`}>
										<Box sx={{ py: '0.5rem' }}>
											<CryptoAssetLineMobile
												token={item} currency={currency}
												hideValues={user.hideValues} hideConvert={hideConvert} />
											{index + 1 < filredTokens.length && <StyledDivider />}
										</Box>
									</Grid>))
								: <Grid container mt='14px'>
									<CryptoAssetHeader />
									{filredTokens?.map((item, index) => (
										<Grid item xs={12} key={`${item.type}-${item.info.symbol}`}>
											<CryptoAssetLine
												token={item}
												hideValues={user.hideValues}
												portfolioBalanceInEur={portfolioBalance}
												hideConvert={hideConvert}
											/>
											{index + 1 < filredTokens.length && <StyledDivider />}
										</Grid>))}
								</Grid>
							}
						</Grid>
					}
				</WalletBox >
			</>
	);
};

export default CrytoBalance;
