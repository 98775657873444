import React from 'react';
import { Grid, IconButton, LinearProgress } from '@mui/material';
import MuiFormLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiFormLabel';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import VisibilityIcon from '@mui/icons-material/Visibility'; 

interface Props {
    label: string,
    value: string,
    onClick?: () => void,
    showButton?: boolean,
    letterSpacing?: boolean,
    disableButton?: boolean,
    isLoading?: boolean
}

const CardDetailsLine = ({ label, value, onClick, showButton, letterSpacing, disableButton, isLoading = false }: Props): React.ReactElement => {

    return (
        <Grid item container flexDirection='column' >
            <Grid item>
                <MuiFormLabel color='text.secondary'> {label} </MuiFormLabel>
            </Grid>
            <Grid item container flexDirection='row' alignItems='center'>
                {isLoading ?
                    <Grid item  >
                        <LinearProgress />
                    </Grid> :
                    <MuiTextCaption fontSize='0.875rem' fontWeight={400} lineHeight='1.375rem' letterSpacing={letterSpacing ? '2px' : undefined}>{value}</MuiTextCaption>
                }
                {showButton && !isLoading && <IconButton onClick={onClick} sx={{ padding: '0 0.5rem' }} disabled={disableButton}>
                    <VisibilityIcon fontSize='small' />
                </IconButton>}
            </Grid>
        </Grid>
    );
};

export default CardDetailsLine;
