
import * as React from 'react';

import '@/componentsMui/Dashboard/Create/Create.css';

import { Button, Grid, Theme, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-material-ui-carousel';
import CardImage from '@/componentsMui/Banking/Card/CardDetails/CardImage';
import { useEffect } from 'react';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import BaseBox from '@/componentsMui/Shared/Widgets/Box/BaseBox';
import MuiFormLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiFormLabel';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';

import MaintenanceChip from '@/componentsMui/Shared/Widgets/MaintenanceChip';
import { CardOrderFees } from '@/componentsMui/Banking/utils';

interface PickStepProps {
	selectedOption: any,
	options: any,
	cardOrderFees: CardOrderFees,
	setSelectedOption: (value: any) => void,
	nextStep: () => void,
	closeModal: (e: any) => void,
	onBack?: () => void
}



const PickCardStep = ({ selectedOption, cardOrderFees, options, setSelectedOption, nextStep, closeModal, onBack }: PickStepProps) => {

	const { t } = useTranslation('translations');
	const theme = useTheme() as Theme;
	const [accountIndex, setAccountIndex] = React.useState(null);
	const handleAccountClick = (index: number,) => { setAccountIndex(index); };

	const freeText = t('fees.myFees.free');


	const isMaintenance = selectedOption?.maintenance;

	const items = [
		{
			id: 1,
			fee: cardOrderFees.creationFee,
			title: t('cards.pickCard.issuanceFee'),
			show: true
		},
		{
			id: 2,
			fee: cardOrderFees.montlyFee,
			title: t('cards.pickCard.monthlyFee'),
			show: true
		},
		{
			id: 3,
			fee: cardOrderFees.topUpFee,
			title: t('cards.pickCard.topupFee'),
			show: true
		},
		{
			id: 4,
			fee: cardOrderFees.paymentFee,
			title: t('cards.pickCard.payment'),
			show: true
		},
		{
			id: 5,
			fee: cardOrderFees.onlinePaymentFee,
			title: t('cards.pickCard.onlinePayment'),
			show: true
		},
		{
			id: 6,
			fee: cardOrderFees.atmWithrawalEu,
			title: t('cards.pickCard.atmWithdrawalEu'),
			show: !!cardOrderFees?.atmWithrawalEu
		}
		,
		{
			id: 7,
			fee: cardOrderFees.atmWithrawalWorld,
			title: t('cards.pickCard.atmWithdrawalWorld'),
			show: !!cardOrderFees?.atmWithrawalWorld
		}
	];

	const activeItems = items.filter(p => p.show) || [];

	useEffect(() => {
		if (!options || accountIndex) return;

		setSelectedOption(options[0]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options, accountIndex]);

	return (
		<Grid container alignContent='space-between' flex={1}>


			<Grid item container className="form-pick-product-list" xs={12}>
				{<>
					<Grid container rowSpacing={1}>
						<Grid item xs={12} >
							<FormHeader title={selectedOption?.name} showBack={!!onBack} onBack={onBack} />
						</Grid>
						<Grid item xs={12} sx={{ mx: 'auto' }} >
							{options && options.length > 1 && <Carousel
								height={196}
								autoPlay={false}
								navButtonsAlwaysVisible={true}
								navButtonsProps={{
									style: {
										background: theme.selectedItemBg.default,
										boxShadow: theme.carousel.iconShadow
									}
								}}
								onChange={(n) => { handleAccountClick(n); setSelectedOption(options[n]); }}
								IndicatorIcon={<PanoramaFishEyeIcon />}
								indicatorIconButtonProps={{
									style: {
										padding: '10px',
										color: theme.selectedItemBg.default,
										opacity: 0.5
									}
								}}
								activeIndicatorIconButtonProps={{
									style: {
										opacity: 1
									}
								}}
								animation='slide'
							>
								{
									options.map((option, idx) =>
										<Grid item key={idx} sx={{ width: { xs: '60%', sm: '60%' }, mx: 'auto' }}>
											<CardImage
												cardLogo={option.config.serviceProviderLogo}
												cardLogoTextColor={option.config.logoTextColor}
												virtualCard={option.processProperties.virtual}
												borderRadius='10px'
												border={true}
											/>
										</Grid>
									)
								}
							</Carousel>}



							{options && options.length === 1 && selectedOption && <Grid item sx={{ width: { xs: '100%', sm: '60%' }, mx: 'auto' }}>
								<CardImage
									cardLogo={selectedOption.config.serviceProviderLogo}
									cardLogoTextColor={selectedOption.config.logoTextColor}
									virtualCard={selectedOption.processProperties.virtual}
									borderRadius='10px'
									border={true}
								/>
							</Grid>
							}
						</Grid>
					</Grid>
				</>
				}

				{cardOrderFees.topUpFee && cardOrderFees.creationFee && cardOrderFees.montlyFee &&
					<Grid item xs={12} mt={2}>
						<BaseBox>
							<Grid container sx={{ p: { xs: '1rem', md: '2rem' } }} flexDirection='row' rowGap={1.5}>
								{
									activeItems.map(p => {
										return (
											<Grid key={p.id} container item xs={12}>
												<Grid item xs={6}><MuiFormLabel color='text.secondary'> {p.title} </MuiFormLabel></Grid>
												<Grid item xs={6}><MuiTextCaption fontWeight={400} color={p.fee === freeText ? theme.highlight : 'text.primary'}>{p.fee}</MuiTextCaption></Grid>
											</Grid>);
									})
								}
							</Grid>
						</BaseBox>
					</Grid>}
			</Grid >

			<Grid item container xs={12} py={2} mt='auto' columnSpacing={2}>
				<Grid item xs={6}>
					<Button
						variant='customOutlined'
						onClick={closeModal}
						sx={{ width: { xs: '100%', md: 'auto' } }}
					>   	{t('form.buttons.close')}
					</Button>
				</Grid>
				<Grid item xs={6} display='flex' justifyContent='flex-end'>
					{
						isMaintenance ? <MaintenanceChip /> :
							<Button
								key="buttonNext"
								variant='contained'
								onClick={nextStep}
								disabled={selectedOption === null}
								sx={{ width: { xs: '100%', md: 'auto' } }}
								autoFocus> 	{t('form.buttons.continue')}
							</Button>
					}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PickCardStep;

