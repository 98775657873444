import request from '@/services/request';
import { AppThunk } from '../../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllDepositsStore, AllocationsPayload } from './types';



const initialState: AllDepositsStore = {
    depositsLoading: true,
    allActiveDeposits: [],
    skip: 0,
    take: 10,
    count: 0,
    filter: null,
};

const slice = createSlice({
    name: 'vault-all-deposits',
    initialState,
    reducers: {

        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.depositsLoading = payload;
        },
        setSkip: (state, { payload }: PayloadAction<number>) => {
            state.skip = payload;
        },
        setTake: (state, { payload }: PayloadAction<number>) => {
            state.take = payload;
        },
        setCount: (state, { payload }: PayloadAction<number>) => {
            state.count = payload;
        },
        setAllocations: (state, { payload }: PayloadAction<Array<any>>) => {
            state.allActiveDeposits = payload;
        },
        setFilter: (state, { payload }: PayloadAction<string>) => {
            state.filter = payload;
        }

    }
});

export const { setAllocations, setLoading, setSkip, setTake, setCount, setFilter } = slice.actions;


export const getAllocations = (payload: AllocationsPayload): AppThunk => {
    return async (dispatch, state) => {
        const { allVaultDeposits } = state();

        try {
            const response = await request.post('/api/v3/vaults/deposits', payload);
            const { data } = response;
            dispatch(setCount(data.count));
            dispatch(setAllocations([...allVaultDeposits.allActiveDeposits, ...data.list]));
            dispatch(setSkip(allVaultDeposits.skip + 1));

            return data;
        } catch (e) {
            dispatch(setAllocations([]));
            dispatch(setCount(0));
            dispatch(setSkip(0));
            console.log(e);
        } finally {
            dispatch(setLoading(false));
        }
    };
};


export const toggleDeposit = (vaultId: number, autoExtend: boolean): AppThunk => {
    return async (dispatch, state) => {
        const { allVaultDeposits } = state();

        try {
            const response = await request.post('/api/v3/vaults/deposit/toggle', { vaultId, autoExtend });
            const { data } = response;

            const currentDeposits = JSON.parse(JSON.stringify(allVaultDeposits.allActiveDeposits));

            const idx = allVaultDeposits.allActiveDeposits.findIndex(t => t.vaultId === data.vaultId);

            if (idx >= 0) {
                currentDeposits[idx] = data;
                dispatch(setAllocations(currentDeposits));
            }

        } catch (e) {
            console.log(e);
        }

    };
};

export const getPlanChangeOptions = async (accountId: number): Promise<any> => {
    const response = await request.get(`/api/v3/vaults/change-plan/${accountId}`);
    const { data } = response;
    return data;
};

export const postChangePlan = (accountId: number, newPlan: string): AppThunk => {
    return async (dispatch, state) => {
        const { allVaultDeposits } = state();

        try {
            const response = await request.post('/api/v3/vaults/change-plan', { accountId, newPlan });
            const { data } = response;

            const currentDeposits = JSON.parse(JSON.stringify(allVaultDeposits.allActiveDeposits));

            const idx = allVaultDeposits.allActiveDeposits.findIndex(t => t.vaultId === data.vaultId);

            if (idx >= 0) {
                currentDeposits[idx] = data;
                dispatch(setAllocations(currentDeposits));
            }

        } catch (e) {
            console.log(e);
        }

    };
};


export default slice.reducer;
