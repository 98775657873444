import React from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { useField } from 'formik';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';

type Props = { name: string, fullWidth: boolean } & any;

const FormDateField = ({ name, fullWidth, ...otherProps }: Props) => {
	const [field, meta, helpers] = useField(name);

	const value = field.value === '' ? null : field.value;



	return (
		<LocalizationProvider dateAdapter={DateAdapter}>
			<DatePicker
				views={['year', 'month', 'day']}
				format={'dd-MMM-yyyy'}
				value={value}
				onChange={(value) => {
					helpers.setValue(value, true);
					helpers.setTouched(true, false);
				}}

				renderInput={(params) => <TextField {...params}
					fullWidth={fullWidth}
					variant='outlined'
					error={meta && meta.touched && Boolean(meta.error)}
					helperText={meta && meta.touched && meta.error as string}
				/>}
				{...otherProps}
			/>
		</LocalizationProvider>
	);
};

export default FormDateField;
