import * as React from 'react';
import '@/componentsMui/Shared/Shared.css';

import { Button, Grid, InputAdornment } from '@mui/material';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { RootState } from '@/rootReducer';

import { BankAccount } from '@/features/account/types';
import { getErrorWithParams, showException, showInfoNotification } from '@/features/swal/slice';
import { Contact, ContactUser } from '@/features/contacts/types';
import { lookupContact } from '@/features/contacts/slice';
import useCloseDialog from '@/helpers/customHook/useCloseDialog';
import _ from 'lodash';
import FormContentBox from '@/componentsMui/Shared/Widgets/Box/FormContentBox';
import { maxTwoDecimals } from '@/helpers/number';
import AccountDropdown from '@/componentsMui/Shared/FormikComponents/AccountDropdown';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import StyledCheckbox from '@/componentsMui/Shared/Widgets/StyledCheckbox';
import NumberFormat from 'react-number-format';
import { getCurrencySign, postTransferFee } from '@/features/fees/slice';
import useFeeDebounce from '@/helpers/customHook/useFeeDebounce';
import { findProcessByType } from '@/features/operations/slice';
import FileUpload, { FileStatusEnum } from '../../FileUpload/FileUpload';
import useCurrencyLimits from '@/helpers/customHook/useCurrencyLimits';
import UserLimitMessage from '../../UserLimitMessage';
import LoadingPageMui from '@/pages/LoadingPageMui';

export interface TrustPayloadType {
	account: BankAccount,
	amount: number,
	fee: number,
	youpay: number,
	walletId: string,
	selectedFile?: any,
	user?: ContactUser,
	processProcedure: string
}

export const initTrustPayload: TrustPayloadType =
{
	account: null,
	amount: null,
	fee: null,
	youpay: 0,
	walletId: null,
	selectedFile: null,
	user: null,
	processProcedure: null
};

type Props = {
	defaultAccount?: BankAccount,
	defaultContact?: Contact,
	trustPayload: TrustPayloadType,
	setTrustPayload: (value: TrustPayloadType) => void,
	nextStep: () => void,
	prevStepMain?: () => void
}

const TrustPaymentDetails = ({ defaultAccount,
	defaultContact,
	trustPayload,
	setTrustPayload,
	nextStep,
}: Props) => {

	const { t } = useTranslation('translations');
	const allAccounts = useSelector((state: RootState) => state.trust.accounts);
	const transferAccounts = defaultAccount ? [defaultAccount] : allAccounts.filter(accounts => _.keys(accounts.subProcesses).filter(key => accounts.subProcesses[key].type === 'TRANSFER').length > 0) || [];

	const [fileStatus, setFileStatus] = useState<FileStatusEnum>();
	const [file, setFile] = useState();


	const [selectedAccount, setSelectedAccount] = useState<BankAccount>(trustPayload.account ?? defaultAccount);
	const [amount, setAmount] = React.useState<any>(trustPayload?.amount?.toString() ?? '');
	const [amountforFee, setAmountForFee] = useState<number>(trustPayload?.amount ?? 0);
	const [confirmed, setConfirmed] = useState<boolean>(false);

	const process = findProcessByType(selectedAccount, 'TRANSFER');
	const { fee, callGetFee, feeLoading } = useFeeDebounce(trustPayload?.fee ?? 0);
	const youpay = amountforFee + fee;
	const currency = getCurrencySign(selectedAccount?.currency);

	const {
		userLimits,
		loadingLimits,
		isLimitSet,
		isLimitExceeded } = useCurrencyLimits(selectedAccount?.currency);

	const closeModal = useCloseDialog();
	const limitReached = isLimitExceeded(amount);


	useEffect(() => {
		if (trustPayload.selectedFile) {
			setFile(trustPayload.selectedFile);
			setFileStatus(FileStatusEnum.SUCCESS);
		}
	}, [trustPayload.selectedFile]);

	const initialValues = {
		from: defaultAccount?.accountId ?? '',
		amount: trustPayload.amount ?? '',
		youpay: isNaN(trustPayload?.youpay) ? '0' : trustPayload.youpay.toFixed(2),
		walletId: trustPayload.walletId ?? defaultContact?.clientId ?? ''
	};

	const validationSchema = Yup.object({
		youpay: Yup.number().required(t('form.validator.required')).nullable()
			.test('youpay', t('sendmoneyTranslation.data.insufficient'), () => youpay <= (selectedAccount?.availableBalance ?? 0)),
		from: Yup.string().required(t('form.validator.required')),
		amount: Yup.number().typeError(t('form.validator.required')).typeError(t('form.validator.required')).required(t('form.validator.required')).nullable()
			.min(0.1, t('sendmoneyTranslation.data.wrongAmount'))
			.moreThan(0, t('sendmoneyTranslation.data.moreThanZero'))
			.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value)),
		walletId: Yup.string().required(t('form.validator.required'))
	});

	const submit = async (formData, formikProps) => {
		const { setSubmitting, setFieldError } = formikProps;

		const { amount } = formData;

		if (process === null || process.proc === null) {
			setFieldError('from', t('sendmoneyTranslation.data.missingProcess'));
		}

		if (amount > 5000 && fileStatus !== FileStatusEnum.SUCCESS) {
			showInfoNotification('sendmoneyTranslation.data.documentRequired');
			setSubmitting(false);
			return;
		}

		const walletId = formData?.walletId?.toUpperCase();


		let user = null;
		try {
			user = await lookupContact(walletId);
			if ((selectedAccount.type !== user.accountType || selectedAccount.currency != user.currency)) {
				setFieldError('walletId', t('sendmoneyTranslation.data.wrongProcess'));
				return;
			}

			const paymentAmount = parseFloat(amount);
			const feeResult = await postTransferFee({ accountId: selectedAccount.accountId, proc: process?.proc, amount: paymentAmount });
			const payload: TrustPayloadType = {
				...{
					amount: paymentAmount,
					walletId,
					fee: feeResult.fee,
					youpay: feeResult.fee + paymentAmount,
					selectedFile: file,
					processProcedure: process.proc
				},
				...{
					account: selectedAccount,
					user
				}
			};

			setTrustPayload(payload);
			nextStep();

		} catch (e) {
			const err = getErrorWithParams(e);
			if (e && err.error === 'notFound' && err.error_param === 'walletId') {
				setFieldError('walletId', t('sendmoneyTranslation.data.userNotFound'));
			} else if (err && err.error === 'your' && err.error_param === 'walletId') {
				setFieldError('walletId', t('sendmoneyTranslation.data.belongsToYou'));
			} else {
				await showException(e);
			}
		}
	};


	const handleAmountChange = (event, setFieldValue, setFieldTouched) => {
		setFieldValue('amount', event.target.value);
		setAmount(event.target.value);
		const parsed = parseFloat(event.target.value);
		const newAmount = isNaN(parsed) ? 0 : parsed;
		setAmountForFee(newAmount);
		callGetFee(selectedAccount.accountId, process?.proc, newAmount);
		setTimeout(() => setFieldTouched('youpay', true));
		setTimeout(() => setFieldTouched('amount', true));
	};


	const handleAccountChange = (value: BankAccount, setFieldTouched) => {
		setSelectedAccount(value);
		setTimeout(() => setFieldTouched('amount', true));
		const newProcess = findProcessByType(value, 'TRANSFER');
		callGetFee(value.accountId, newProcess?.proc, amountforFee);
		setTimeout(() => setFieldTouched('youpay', true));
	};

	if (loadingLimits) { return <LoadingPageMui />; }


	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={submit}
		>
			{({ errors, isSubmitting, setFieldValue, setFieldTouched }) => {
				return (
					<Form className='form-content__wrap__bb' >
						<FormContentBox noBackground={true} className='form-content_main-area wp'>
							<Grid id='paymentDetails' container spacing={3}>
								{isLimitSet &&
									<Grid item xs={12}>
										<UserLimitMessage userLimits={userLimits} limitReached={limitReached} />
									</Grid>
								}
								<Grid item xs={12}>
									<AccountDropdown
										fieldlabel={t('form.fields.from')}
										fieldName='from'
										accountList={transferAccounts}
										selectedAccount={selectedAccount}
										onAccountChange={(value: BankAccount) => handleAccountChange(value, setFieldTouched)} />
								</Grid>
								<Grid item xs={6}>
									<NumberFormat
										name="amount"
										decimalScale={2}
										customInput={MuiTextField}
										onChange={(event) => handleAmountChange(event, setFieldValue, setFieldTouched)}
										allowNegative={false}
										value={amount}
										displayType={'input'}
										placeholder={'0.00'}
										allowedDecimalSeparators={['.']}
										fullWidth
										label={t('sendmoneyTranslation.data.transferamount')}
										inputProps={{ autoComplete: 'off' }}
										InputProps={{
											startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<NumberFormat
										name="youpay"
										displayType={'input'}
										customInput={MuiTextField}
										decimalScale={2}
										isAllowed={() => false}
										disabled
										fullWidth
										value={youpay}
										label={t('sendmoney.data.youpay')}
										placeholder={'0.00'}
										InputProps={{
											startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<MuiTextField
										name="walletId"
										fullWidth
										label={t('sendmoneyTranslation.data.walletid')}
									/>
								</Grid>
								<Grid item xs={12} container>
									<Grid item alignSelf='flex-start' lineHeight='16px'>
										<StyledCheckbox
											sx={{ padding: '0rem 1rem 0rem 0rem' }}
											checked={confirmed}
											onChange={() => { setConfirmed(!confirmed); }}
										/>
									</Grid>
									<Grid item xs={10}>
										<MuiFormText>{t('trusts.confirmTransfer')}</MuiFormText>

									</Grid>
								</Grid>
								<Grid item xs={12}>
									<FileUpload
										label={t('sendmoneyTranslation.data.addReceipt')}
										setStatus={(value) => setFileStatus(value)}
										setFile={(value) => setFile(value)}
										completed={fileStatus === FileStatusEnum.SUCCESS}
										file={file} />
								</Grid>
							</Grid >
							<Grid container mt='auto' pt='2rem' columnSpacing={2}>
								<Grid item xs={6}>
									<Button
										variant='customOutlined'
										color='secondary'
										onClick={closeModal}
										sx={{ width: { xs: '100%', md: 'auto' } }}
									>  {t('form.buttons.close')}
									</Button>
								</Grid>
								<Grid item xs={6} container justifyContent='flex-end'>
									<Button
										disabled={isSubmitting || !isEmpty(errors) || !confirmed || feeLoading}
										variant="contained"
										color="primary"
										sx={{ width: { xs: '100%', md: 'auto' } }}
										type="submit"
									>
										{t('form.buttons.next')}
									</Button>
								</Grid>
							</Grid>
						</FormContentBox>
					</Form>
				);
			}}
		</Formik >);
};

export default TrustPaymentDetails;
