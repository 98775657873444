import * as React from 'react';
import { Avatar, FormControl, FormHelperText, Grid, InputLabel, MenuItem } from '@mui/material';
import { useField } from 'formik';
import StyledSelect from '@/componentsMui/Shared/Widgets/StyledSelect';
import { useTranslation } from 'react-i18next';
import { Contact } from '@/features/contacts/types';
import { getName } from 'i18n-iso-countries';
import { isEmpty } from 'lodash';

interface Props {
	fieldName: string,
	contactList: Contact[],
	fieldlabel?: string,
	className?: string,
	selectedContact?: Contact,
	onContactChange?: (value: Contact) => void,
}

const ContactDropdown = ({ fieldName, contactList, fieldlabel, selectedContact, onContactChange, className }: Props) => {
	const { t } = useTranslation('translations');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField(fieldName);
	const { setValue } = helpers;
	const { value } = meta;
	const externalState = !(onContactChange === undefined);

	const handleChange = (event: any) => {
		const newId = event.target.value;
		setValue(newId);
		onContactChange && onContactChange(contactList.find(p => p.beneficiaryAccountId === newId));
	};

	const internal = contactList.find(p => p.beneficiaryAccountId === value);

	const validationFailed = meta && meta.touched && meta.error;
	const textFieldValue = externalState ?
		(isEmpty(selectedContact) ? t('form.placeholder.selectContact') : `${selectedContact?.name}`) :
		(!(value) ? t('form.placeholder.selectContact') : `${internal?.name}`);



	return (
		<FormControl fullWidth className={className}>
			{fieldlabel && <InputLabel> {fieldlabel}</InputLabel>}
			<StyledSelect
				name={fieldName}
				value={value ?? ''}
				displayEmpty
				onChange={handleChange}
				renderValue={() => <div> {textFieldValue}</div>}
			>
				{contactList.length > 0 &&
					contactList.map((contact) => (
						<MenuItem
							key={contact.beneficiaryAccountId}
							value={contact.beneficiaryAccountId} >
							<div>
								<Grid container spacing={1} alignItems="center">
									<Grid item>
										<Avatar className='select-hide-Item'>{contact.name.charAt(0)}</Avatar>
									</Grid>
									<Grid item >
										<div className='select-primary-Item'>{contact.name} </div>
										<div className='select-hide-Item'>{getName(contact.country, 'en')} </div>
									</Grid>
								</Grid>
							</div>
						</MenuItem>
					))}
			</StyledSelect>
			{validationFailed && <FormHelperText>{meta.error}</FormHelperText>}
		</FormControl>
	);
};

export default ContactDropdown;
