import * as React from 'react';
import { Button, Dialog, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ixopayComplete } from '@/features/operations/slice';
import { useEffect, useState } from 'react';
import { useCloseDialogGoToAccounts } from '@/helpers/customHook/useCloseDialog';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';

const CardTransferSuccess = () => {

	const { t } = useTranslation('translations');
	const closeModal = useCloseDialogGoToAccounts();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const [activityId] = useState<string>(searchParams.get('ActivityId'));
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [syncCompleted, setSyncCompleted] = useState(false);


	//NOTE: activity id is needed for ixopay. Once ixopay is dessuported, this useffect can be removes
	useEffect(() => {
		const get = async () => {
			try {
				setLoading(true);
				setError(false);
				await ixopayComplete(activityId);
			}
			catch (e) {
				setError(true);
			}
			finally {
				setLoading(false);
				setSyncCompleted(true);
			}

		};

		if (activityId) {
			get();
		}

	}, [activityId]);
	return (
		<Dialog
			id="card-transfer-success"
			open={true}
			onClose={closeModal}
			maxWidth='xs'
		>
			<Grid container rowGap={3} textAlign='center' py={4}>
				<Grid item xs={12}>
					<MuiHeader>{t(error ? 'banking.addMoney.messages.error' : 'banking.addMoney.messages.topupSuccess')}</MuiHeader>
				</Grid>
				{loading &&
					<Grid item xs={12} mt='10px' >
						<Spinner />
					</Grid>
				}
				<Grid item xs={12} mt={2}>
					<Button
						variant='customOutlined'
						color='secondary'
						disabled={loading || (syncCompleted === false && !!activityId)}
						onClick={closeModal}
					>   {t('form.buttons.close')}
					</Button>
				</Grid>
			</Grid>
		</Dialog >
	);
};

export default CardTransferSuccess;
