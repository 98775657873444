import { t } from '@helpers/translate';

export const maxTwoDecimals = (val: any) => !!(val?.toString().match(/^(\d*\.{0,1}\d{0,2}$)/));
export const containsDecimals = (val: any) => {
  const parsed = parseFloat(val ?? '0');
  return (Math.floor(parsed) !== parsed);
};

export const removeTrailingZeros = (value: string | number) => {
  const toTrail = typeof value === 'number' ? value.toString() : value;
  const resultAfterRegex = toTrail.replace(/0+$/, '');
  const finalValue = resultAfterRegex.endsWith('.') ? resultAfterRegex + '00' : resultAfterRegex;
  return finalValue;
};

/* export const limitNumberFormat = (limit: number) => {
  console.log(limit);
  if (limit !== undefined && limit !== null) return `${new Intl.NumberFormat('en-EN', { minimumFractionDigits: getFormattedValue(limit) }).format(limit)}`;
  else {
    return 'unlimited';
  }
}; */


export const limitNumberFormat = (limit?: number) => {
  if (limit !== undefined && limit !== null) return `${new Intl.NumberFormat('en-EN', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 8,
  }).format(limit)}`;
  else {
    return t('form.fields.unlimited');
  }
};


export const getFormattedValue = (value: number) => {
  if (value >= 1) { return 2; }
  if (value >= 0.01) { return 4; }
  if (value >= 0.0001) { return 6; }
  if (value >= 0.000001) { return 8; }
  if (value === 0) { return 2; }
  return 10;
};




export const formatDecimalFlex = (
  value: number,
  options?: Omit<Intl.NumberFormatOptions, 'currency' | 'style'>
) => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 6,
    ...options,
  }).format(value);
};
