import * as React from 'react';
import { SvgIcon } from '@mui/material';

const MenuEmployeeIcon = (props) => (
    <SvgIcon {...props} >
        <svg width="22" height="22" viewBox="0 0 22 22" fill="current" stroke="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.912 13.794c.131-.45.576-.7.995-.56C19.817 14.21 22 16.89 22 20.146c0 .472-.355.854-.794.854-.438 0-.794-.382-.794-.854 0-2.374-1.604-4.485-3.979-5.282-.418-.14-.651-.62-.52-1.07zM3.227 17.407c-1.09.781-1.64 1.767-1.64 2.74 0 .47-.355.853-.793.853C.355 21 0 20.618 0 20.146c0-1.72.973-3.175 2.346-4.16 1.38-.988 3.238-1.571 5.252-1.571s3.873.583 5.252 1.572c1.373.984 2.346 2.438 2.346 4.16 0 .47-.355.853-.794.853-.438 0-.794-.382-.794-.854 0-.973-.55-1.958-1.64-2.739-1.083-.777-2.626-1.285-4.37-1.285-1.744 0-3.287.508-4.371 1.285zM13.273 2.13c-.038-.47.284-.883.721-.925 2.62-.246 4.93 1.838 5.16 4.656.23 2.818-1.709 5.303-4.33 5.55-.436.04-.821-.307-.86-.777-.038-.47.285-.883.722-.924 1.747-.165 3.04-1.821 2.887-3.7-.153-1.878-1.693-3.268-3.44-3.104-.437.041-.822-.306-.86-.776zM7.598 2.707c-2.067 0-3.742 1.802-3.742 4.025 0 2.222 1.675 4.024 3.742 4.024s3.742-1.802 3.742-4.024c0-2.223-1.675-4.025-3.742-4.025zm-5.33 4.025C2.268 3.566 4.654 1 7.598 1s5.33 2.566 5.33 5.732c0 3.165-2.386 5.731-5.33 5.731s-5.33-2.566-5.33-5.731z"
                fill="current"
            />
        </svg>
    </SvgIcon>
);

export default MenuEmployeeIcon;
