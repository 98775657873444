import { getLookupValues } from '@features/fnd/fndSlice';
import { useCallback, useEffect, useState } from 'react';


const useUnitOptions = () => {
    const [unitOptions, setUnitOptions] = useState<any[]>([]);


    const getLov = useCallback(async () => {
        try {
            const result = await getLookupValues('MERCHANT_INVOICE_UNITS');
            const options = result?.map(p => ({
                code: p.lookupCode,
                label: p.description
            })) || [];
            setUnitOptions(options);
        }
        catch (e) {
            console.log(e);
        }
    }, []);

    useEffect(() => {
        getLov();
    }, [getLov]);

    return { unitOptions };
};

export default useUnitOptions;
