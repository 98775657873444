import { Typography, TypographyProps } from '@mui/material';
import React, { FC } from 'react';

//15px

const MuiTitle: FC<TypographyProps> = (props: TypographyProps) => {
	return (
		<Typography 
			fontSize="0.938rem"
			fontWeight='700'
			color='text.primary'
			{...props}>
			{props.children}
		</Typography >
	);
};

export default MuiTitle;
