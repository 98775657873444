import { Paper } from '@mui/material';
import { withStyles } from '@mui/styles';

const FormPaperLargeScrollable = withStyles(() => ({
	root: {
		height: '700px',
		maxHeight: '100vh',
		minHeight: '700px',
		minWidth: '600px',
		maxWidth: '600px',
		width: '600px',
		padding: '2rem',
		display: 'flex',
		flex: 1,
		flexDirection: 'column', 
		['@media only screen and (max-height: 400px)']: {
			maxHeight: 'unset',
			minHeight: 'unset',
			minWidth: 'unset',
			maxWidth: '100%',
			width: '100%',
			height: '100%',
			padding: '1rem',
			margin: 0,
			borderRadius: 0,
		},
		['@media only screen and (max-width:600px)']: {
			maxHeight: 'unset',
			minHeight: 'unset',
			minWidth: 'unset',
			maxWidth: '100%',
			width: '100%',
			height: '100%',
			padding: '1rem',
			margin: 0,
			borderRadius: 0,
		},
	}
}))(Paper);

export default FormPaperLargeScrollable;
