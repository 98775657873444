import {
	Alert, Box,
	Button,
	Container,
	Grid,
	Link,
	Theme,
	Typography,
	useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'is-empty';
import { resetPassword, logout, validateCode } from '@features/user/userSlice';
import { showSuccess, showErrorNotification, showException } from '@features/swal/slice';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import LogoThemed from '@shared/Widgets/LogoThemed';
import MuiTextAlert from '@shared/Widgets/MuiText/MuiTextAlert';
import TextField from '@shared/FormikComponents/MuiTextField';
import { RootState } from '@/rootReducer';
import BackMobileButton from '@shared/Widgets/BackMobileButton';
import { getRecaptureToken } from '@/helpers/recaptureUtils';
import { setLandingStepCount } from '@/features/landingStep/slice';
import MinimalLayout from '../Layout/MinimalLayout';
import { ErrorTranslate } from '@/features/swal/types';

interface ParamTypes {
	code: string
}

const ForgotPassword = () => {
	const { t } = useTranslation(['translations']);
	const theme = useTheme() as Theme;
	const history = useHistory();
	const dispatch = useDispatch();
	const { code } = useParams<ParamTypes>();
	const [loginError] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);

	const initialValues = {
		password: '',
		confirmPassword: ''
	};

	const validationSchema = Yup.object({
		password: Yup.string().trim()
			.min(8, t('registration.passwordvalidators.len'))
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/])(?=.*?[^\w\s]).{8,}$/,
				t('registration.passwordvalidators.spec')
			)
			.required(t('form.validator.required')),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref('password'), null], t('form.validator.confirmpassword'))
			.required(t('form.validator.required'))
	});


	useEffect(() => {
		async function validatePasswordResetCode() {
			const response = await dispatch(validateCode({code}));
			const valid = response['data'];
			setLoading(false);
			if(valid['errors']) {
				const errorParams: ErrorTranslate = {
					key: valid['errors'][0].error,
					message: t('password.passwordvalidators.expired'),
					title: t('password.passwordvalidators.linkExpired')
				};
				showException(response, errorParams);
				setTimeout(() => {
					history.push('/auth');
				}, 500);
				return;
			}
		}
		validatePasswordResetCode();
		dispatch(setLandingStepCount(0));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);


	const { error } = useSelector(
		(state: RootState) => state.user
	);

	// const { regionRestricted, registrationDisabledReason, registrationEnabled } = useSelector(
	// 	(state: RootState) => state.status
	// );
	//
	// // const onRegistrationClick = (e) => {
	// // 	if (!registrationEnabled) {
	// // 		e.preventDefault();
	// // 		showInfo({ title: 'swal.REGISTRATION_DISABLED_TITLE', text: 'swal.REGISTRATION_DISABLED_TEXT', textParams: { param_value: registrationDisabledReason, timer: null } });
	// // 	}
	// // 	if (regionRestricted) {
	// // 		e.preventDefault();
	// // 		showInfo({ title: 'swal.REGION_RESTRICTED_TITLE', text: 'swal.REGION_RESTRICTED_TEXT' });
	// // 	}
	// // };

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		try {
			const { password } = formData;
			const recaptchaToken = await getRecaptureToken();
			await dispatch(resetPassword({ password, code, recaptchaToken }));
			await dispatch(logout());
			showSuccess({ title: 'swal.PASSWORD_RESET', text: 'swal.PASSWORD_RESET_TEXT', confirmBTNText: t('form.buttons.close') });
			history.push('/auth');
		}
		catch (e) {
			setSubmitting(false);
			showErrorNotification(error);
		}
	};

	return (
		<MinimalLayout>
			<Box id='auth'>
				<Container component="main" sx={{
					justifyContent: { xs: 'flex-start', sm: 'space-around' },
					display: 'flex',
					flexDirection: 'column',
					maxWidth: { xs: '100', sm: '444px' },
					pt: { xs: 2, sm: 0 },
				}}>
					<Grid item sx={{ display: { xs: 'block', sm: 'none' } }}>
						<BackMobileButton
							onClick={() => history.push('/auth')}
						/>
					</Grid>
					<Grid container itemScope>
						<Grid item xs={12} sx={{ backgroundColor: { xs: 'none', sm: theme.bgSecondaryButton } }} borderRadius='10px' pb='1rem'>
							<Grid item container p={3} rowSpacing={2} alignContent='flex-start' textAlign='center'>
								<Grid item xs={12} sx={{ display: { xs: 'none', sm: 'block' } }}>
									<LogoThemed className='login-logo' onClick={() => history.push('/auth')} style={{ cursor: 'pointer' }} />
								</Grid>

								<Grid item xs={12}>
									<Typography
										textAlign='center'
										variant='body2'
										whiteSpace='pre-line'
										color='text.primary'>{t('resetPassword.title')}
									</Typography>
								</Grid>
								{loginError && <Grid item xs={12}>
									<Alert severity="warning"  >
										<MuiTextAlert  >
											{t('login.v3.signUpFields.loginError')}
										</MuiTextAlert>
									</Alert>
								</Grid>}

								<Grid container item xs={12}>
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										onSubmit={submit}
									>
										{({ errors, isSubmitting, touched }) => (
											<Form style={{ width: '100%' }} autoComplete='off'>
												<Grid
													xs={12}
													item
													container
													direction="column"
													display='flex'
													justifyContent="center">
													<Grid item xs={5.8}>
														<TextField
															className='registration-input password'
															name="password"
															label="Password"
															InputLabelProps={{ style: { color: theme.palette.text.primary, textAlign: 'left' } }}
															placeholder={t('form.placeholder.password')}
															type="password"
															InputProps={{
																autoComplete: 'new-password'
															}}
															fullWidth
															error={errors.password && touched.password}
														/>
													</Grid>
													<Grid item xs={5.8}>
														<TextField
															className='registration-input password'
															name="confirmPassword"
															label={t('form.fields.confirmpassword')}
															InputLabelProps={{ style: { color: theme.palette.text.primary, textAlign: 'left' } }}
															placeholder={t('form.placeholder.confirmpassword')}
															type="password"
															InputProps={{
																autoComplete: 'new-password'
															}}
															fullWidth
															error={errors.confirmPassword && touched.confirmPassword}
														/>
													</Grid>
													<Grid item xs={12} pt={4}>
														<Button
															sx={{ width: '230px' }}
															type="submit"
															variant="contained"
															disabled={!isEmpty(errors) || isSubmitting || loading}
														>
															{t('forgot-password.submit')}
														</Button>
													</Grid>
												</Grid>
											</Form>
										)}
									</Formik>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} display='flex' flexDirection='row' justifyContent='center' pt={2}>
							<Typography variant='body2'>{t('login.v3.dontHaveAccount')}</Typography>
							<Link ml={1} onClick={() => history.push('/auth')}>{t('login.v3.signup')}</Link>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</MinimalLayout>
	);
};

export default ForgotPassword;
