import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { useTranslation } from 'react-i18next';
import { refreshUser } from '@/features/user/userSlice';
import { Grid } from '@mui/material';
import StatusCard from './HomeSummary/Components/StatusCard';
import BackButton from '../Wallet/Components/BackButton';

const Suspended = (): React.ReactElement => {
  const { i18n } = useTranslation('translations');
  const preferredLang = useSelector((state: RootState) => state.user?.user?.preferredLanguage);
  const currentLang = i18n.language;
  const dispatch = useDispatch();

  useEffect(() => {
    if (preferredLang && preferredLang !== currentLang) {
      i18n.changeLanguage(preferredLang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferredLang]);

  useEffect(() => {
    const get = async () => {
      await dispatch(refreshUser());
    };
    get();
  }, [dispatch]);

  return (
    <Grid item xs={12} container justifyContent='center'>
      <Grid item xs={12}>
        <BackButton />
        <StatusCard />
      </Grid>
    </Grid>
  );
};

export default Suspended;
