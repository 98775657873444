import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Grid, SxProps, Theme, Typography } from '@mui/material';

const Flexiroam = () => {
	const { t } = useTranslation('translations');


	const bulletList = [
		{ title: t('benefits.flexiroamSection.l1') },
		{ title: t('benefits.flexiroamSection.l2') },
		{ title: t('benefits.flexiroamSection.l3') },
		{ title: t('benefits.flexiroamSection.l4') },
		{ title: t('benefits.flexiroamSection.l5') }];

	const stepList = [
		{ title: t('benefits.flexiroamSection.s2') },
		{ title: t('benefits.flexiroamSection.s3') },
		{ title: t('benefits.flexiroamSection.s4') },
		{ title: t('benefits.flexiroamSection.s5') },
		{ title: t('benefits.flexiroamSection.s6') }];

	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography variant='h5' sx={styles.header}>{t('benefits.flexiroamSection.title')}</Typography>
				<Typography sx={styles.p}>{t('benefits.flexiroamSection.p1')}</Typography>
				<Box sx={styles.p}>
					{bulletList.map((item, idx) => <Typography key={idx} sx={styles.listItem}>{'\u2022'} {item.title}</Typography>)}
				</Box>
				<Typography variant='h5' sx={styles.header}>{t('benefits.flexiroamSection.title2')}</Typography>
				<Typography sx={styles.p}>{t('benefits.flexiroamSection.p2')}</Typography>
				<Typography className='highlight-regular'>1.
					<Trans
						i18nKey="benefits.flexiroamSection.s1"
						t={t}
						components={{
							url: <a
								rel="noreferrer"
								target="_blank"
								href='https://cardaq.flexiroam.com' />,
						}}
					/>
				</Typography>
				{
					stepList.map((step, idx) => <Typography key={idx} sx={styles.p}>{idx + 1}. {step.title}</Typography>)
				}
			</Grid>
		</Grid>
	);
};

export default Flexiroam;

const styles: Record<string, SxProps<Theme>> = {
	header: {
		marginTop: 4,
		fontWeight: 'bold'
	},
	p: {
		marginTop: 2,
	},
	listItem: {
		marginLeft: 2,
	},
};
