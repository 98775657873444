
import * as React from 'react';
import { Grid } from '@mui/material';
import MuiFormField from '@/componentsMui/Shared/Widgets/MuiText/MuiFormField';
import MuiFormHelperText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormHelperText';
import MuiCopyToClipboard from '@/componentsMui/Shared/Widgets/MuiCopyToClipboard';
import MuiFormLabel from './MuiText/MuiFormLabel';
import StyledDivider from './StyledDivider';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ClickableTooltip from '@shared/Widgets/ClickableTooltip';

interface Props {
	label: string,
	value?: string,
	first?: boolean,
	helpText?: string
	warningTooltip?: string
	children?: React.ReactNode,
}


const DetailLine = ({ label, value, first, helpText, children, warningTooltip }: Props) => (
	<>
		{!first && <Grid item xs={12}> <StyledDivider /> </Grid>}
		<Grid container
			minHeight='1.2rem'
			py={0.3}
			alignItems='center'>
			<Grid item xs={value ? 11 : 12} container alignItems={'center'}>
				<Grid item xs={10} sm={5} container flexWrap='nowrap'>
					<MuiFormLabel color='text.secondary'>
						{label}
					</MuiFormLabel>
					{warningTooltip && <ClickableTooltip title={warningTooltip}>
						<InfoOutlinedIcon sx={theme => ({ fontSize: '1em', fill: theme.highlight, ml: '10px' })} />
					</ClickableTooltip>}
				</Grid>
				<Grid item xs={10} sm={7}>
					{children
						? children
						: <>
							<MuiFormField> {value} </MuiFormField>
							{helpText && <MuiFormHelperText>{helpText}</MuiFormHelperText>}
						</>
					}
				</Grid>
			</Grid>
			{value && <Grid item xs={1} justifyContent='flex-end' container>
				<MuiCopyToClipboard value={value} />
			</Grid>}
		</Grid>
	</>);


export default DetailLine;
