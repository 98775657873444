import { PaymentTypes } from '@features/invoices/invoicesTypes';
import { Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import LoadingButton from '@/componentsMui/Shared/Components/LoadingButton';

interface Props {
    open: boolean;
    onClose: () => void;
    seletedPaymentOptions: PaymentTypes[];
    onSave: (values: PaymentTypes[]) => void;
    options: any[]
}

const ChoosePaymentOptionsDialog = ({ open, onClose, onSave, seletedPaymentOptions, options }: Props) => {
    const { t } = useTranslation('translations');
    const [choosedPaymentOptions, setChoosedPaymentOptions] = useState<PaymentTypes[]>(seletedPaymentOptions);

    const handleSave = () => {
        onSave(choosedPaymentOptions);
    };

    return (
        <Dialog
            open={open}
            maxWidth='xs'
        >
            <DialogTitle>
                <FormHeader
                    title={t('invoice.paymentOptions.title')}
                    onBack={onClose}
                />
            </DialogTitle>
            <DialogContent>
                <Grid container gap={2}>
                    <Grid item xs={12}>
                        {options.map((option) => (
                            <FormControlLabel
                                key={option.value}
                                value={option.value}
                                checked={choosedPaymentOptions.includes(option.value)}
                                onChange={(e, c) => { c ? setChoosedPaymentOptions(prev => [...prev, option.value]) : setChoosedPaymentOptions(prev => prev.filter(o => option.value !== o)); }}
                                control={<Checkbox />}
                                label={
                                    <Grid container alignItems="center" spacing={1}>
                                        <Grid item>{option.icon}</Grid>
                                        <Grid item xs>
                                            <Typography variant="body1">{option.title}</Typography>
                                            <Typography variant="subtitle1" color="text.secondary">
                                                {option.subtitle}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                                sx={{ alignItems: 'center', padding: 1 }}
                            />
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            disabled={choosedPaymentOptions.length === 0}
                            fullWidth
                            size="large"
                            variant="contained"
                            loading={false}
                            onClick={handleSave}
                        >{t('invoice.choose')}</LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ChoosePaymentOptionsDialog;
