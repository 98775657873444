import React, { useState } from 'react';
import { Grid, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DeviceInfo, LoginTypeEnum } from '@/features/user/types';
import EmailPhoneLogin from './EmailPhoneLogin';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';

interface Props {
	deviceInfo: DeviceInfo,
	isOauthLogin?: boolean,
	onCancel?: () => void,
}


const LoginTabs = ({ deviceInfo, isOauthLogin = false, onCancel }: Props): React.ReactElement => {

	const { t } = useTranslation('translations');
	const [currentTab, setCurrentTab] = useState(LoginTypeEnum.EMAIL);
	const appName = process.env.REACT_APP_TITLE;
	const theme = useTheme();
	const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

	const handleChange = (event: React.SyntheticEvent, newValue: LoginTypeEnum) => {
		setCurrentTab(newValue);
	};


	return (
		<Grid container maxWidth='360px'>
			<Grid item xs={12} mt={{ xs: '20px', md: '0px' }}>
				{matchDownSm ?
					<MuiHeader textAlign='center'>{`${t('login.login')} ${appName}`}</MuiHeader> :
					<Typography textAlign='center' fontSize='1.625rem' fontWeight={700}>
						{`${t('login.login')} ${appName}`}
					</Typography>
				}
			</Grid>
			<Grid item xs={12} mt={{ xs: '20px', md: '40px' }}>
				<Tabs value={currentTab}
					onChange={handleChange}
					centered
				>
					<Tab value={LoginTypeEnum.EMAIL}
						label={t('form.fields.email')}
						sx={{ width: '50%', fontWeight: 400 }}
						test-id='email-tab'
					/>
					<Tab value={LoginTypeEnum.PHONE}
						label={t('form.fields.phone')}
						sx={{ width: '50%', fontWeight: 400 }}
						test-id='phone-tab'
					/>
				</Tabs>
			</Grid>
			<Grid item xs={12} container mt={{
				xs: '15px',
				md: '20px'
			}}>
				{currentTab === LoginTypeEnum.EMAIL && <EmailPhoneLogin deviceInfo={deviceInfo} type={currentTab} isOauthLogin={isOauthLogin} onCancel={onCancel} onError={onCancel} />}
				{currentTab === LoginTypeEnum.PHONE && <EmailPhoneLogin deviceInfo={deviceInfo} type={currentTab} isOauthLogin={isOauthLogin} onCancel={onCancel} onError={onCancel} />}
			</Grid>
		</Grid>
	);
};

export default LoginTabs;
