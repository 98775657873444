import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@/store';
import { Contact, ContactBody, ContactPaymentTypes, ContactStore, ContactTabEnum, ContactUpdateBody, ContactUser } from './types';
import { SocketService } from '@services/socketService';
import { Subscription } from 'rxjs';
import request from '@services/request';

const initialState: ContactStore = {
	loading: true,
	contacts: [],
	error: null,
	tab: ContactTabEnum.ALL
};

const slice = createSlice({
	name: 'contacts',
	initialState,
	reducers: {
		setContacts(state, action: PayloadAction<Array<Contact>>) {
			state.contacts = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setContactTab: (state, { payload }: PayloadAction<ContactTabEnum>) => {
			state.tab = payload;
		}
	}
});

export const { setContacts, setLoading, setError, setContactTab } = slice.actions;

export const contactsSelector = (state: { store: ContactStore }): ContactStore =>
	state.store;

let socketService: SocketService;
let updateSubscriber: Subscription;

export const connect = (): void => {
	if (!socketService) {
		socketService = new SocketService('contacts');
	}
};

export const disconnect = (): void => {
	socketService = null;
};

export const subscribe = (): AppThunk => {
	return async dispatch => {
		updateSubscriber = socketService.listen('contact', {}).subscribe(() => {
			dispatch(getContacts());
		});
	};
};

export const unsubscribe = (): void => {
	updateSubscriber.unsubscribe();
};

export const getContacts = (): AppThunk => {
	return async (dispatch) => {
		try {
			const response = await request.get('/api/beneficiary');
			const { data } = response;

			const notLocal = data.filter((value) => value.paymentType !== ContactPaymentTypes.LOCAL);
			const newArr = data.filter((value, index) => value.paymentType === ContactPaymentTypes.LOCAL && data.findIndex(item => item.clientId?.toString().toUpperCase() == value.clientId?.toString().toUpperCase()) === index);

			dispatch(setContacts([...notLocal, ...newArr]));
			return data;
		} catch (e) {
			dispatch(setError('Failed'));
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const addContact = (payload: ContactBody): AppThunk => {
	return async dispatch => {
		const response = request.post('/api/beneficiary',
			payload
		);
		await response;
		dispatch(setError(null));
	};
};

export const editContact = (id: number, payload: ContactUpdateBody): AppThunk => {
	return async dispatch => {
		const response = request.put('/api/beneficiary/' + id,
			payload
		);
		await response;
		dispatch(setError(null));
	};
};

export const deleteContact = (id: number): AppThunk => {
	return async dispatch => {
		const response = request.delete('/api/beneficiary/' + id
		);
		await response;
		dispatch(setError(null));
	};
};

export const lookupByUserTag = async (userTag: string) => {
	const { data } = await request.get(`/api/beneficiary/lookup-by-tag?userTag=${userTag}`);
	return data;
};

export const searchContact = async (text: string) => {
	const { data } = await request.get(`/api/beneficiary/lookup?text=${text}`
	);
	return data;
};

export const findContact = async (text: string, type: string) => {
	const { data } = await request.get(`/api/beneficiary/lookup/${type}?text=${text}`
	);
	return data;
};

export const getCryptoMetadata = async () => {
	const { data } = await request.get('/api/beneficiary/crypto/metadata'
	);
	return data;
};


export const lookupContact = async (walletId: string): Promise<ContactUser> => {
	const { data } = await request.get(`/api/accounts/lookup?walletId=${walletId}`);
	return data;
};

export const lookupClientIdContact = async (clientId: string, fromAccountId: number): Promise<ContactUser> => {
	const { data } = await request.get(`/api/accounts/client/lookup?clientId=${clientId}&fromAccountId=${fromAccountId}`
	);
	return data;
};

export const getDataByIban = async (iban: string) => {
	const { data } = await request.get(`/api/beneficiary/iban/${iban}`
	);
	return data;
};

export default slice.reducer;
