import * as React from 'react';
import { SvgIcon } from '@mui/material';

const HomeIcon = (props) => (
    <SvgIcon {...props} >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="current" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23 18.564V9.965c0-1.332-.66-2.593-1.797-3.436L15.093 2C13.292.667 10.706.667 8.907 2L2.797 6.53C1.66 7.371 1 8.632 1 9.964v8.599C1 21.014 3.189 23 5.889 23H18.11c2.7 0 4.889-1.986 4.889-4.436z"
                stroke="current"
                strokeWidth={1.5}
                strokeLinejoin="round"
            />
            <path
                d="M7 19h10"
                stroke="current"
                strokeWidth={1.5}
                strokeLinecap="round"
            />
        </svg>
    </SvgIcon>
);

export default HomeIcon;
