
import * as React from 'react';
import { Box, Button, Grid, Link, useTheme } from '@mui/material';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { Trans, useTranslation } from 'react-i18next';
import FormContentBox from '@/componentsMui/Shared/Widgets/Box/FormContentBox';
import { useState } from 'react';
import FileUpload, { FileStatusEnum } from '../../FileUpload/FileUpload';
import { downloadExampleFile, postBulkPaymnetFile } from '@/features/bulkpayments/bulkPaymentsSlice';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import MuiTextFieldInfo from '@/componentsMui/Shared/Widgets/MuiText/MuiTextFieldInfo';
import { showSuccess } from '@/features/swal/slice';

export interface Props {
	onBack?: () => void,
	proc: any
}

const BulkPaymentForm = ({
	onBack,
	proc
}: Props) => {
	const closeModal = useCloseDialogNoEvent();
	const theme = useTheme();
	const { t } = useTranslation('translations');

	const [file, setFile] = useState(null);
	const [fileStatus, setFileStatus] = useState('');
	const [uploading, setUploading] = useState<boolean>(null);
	const [uploadFailed, setUploadFailed] = useState<boolean>(false);

	const handleDownloadExample = async () => {
		if (!proc?.config?.exampleFile) { return; }
		try {
			await downloadExampleFile(proc.config.exampleFile, 'bulk_payment_example');
		} catch (err) {
			console.log(err);
		}
	};

	const submitFileUpload = async () => {
		try {
			setUploading(true);
			const response = await postBulkPaymnetFile(file);
			if (response.status === 'SUCCESS') {
				closeModal();
				showSuccess({
					title: 'File successfully uploaded',
					mui: true,
					confirmBTNText: t('form.buttons.close'),
					text: `You can find your upload under bulk payments page with id ${response.sessionId}`
				});


			} else {
				setUploadFailed(true);
			}
		} catch (err) {
			console.log(err);
			setUploadFailed(true);
		} finally {
			setUploading(false);
		}
	};

	return (
		<FormContentBox noBackground className='form-content_main-area wp' >
			<Grid id='paymentDetails' container columnSpacing={3} rowSpacing={1}>
				<Grid item xs={12}>
					<FormHeader title={t('bulkpayments.title')} showBack={!!onBack} onBack={onBack} />
				</Grid>
				<Grid item xs={12} mb={1} >
					<Box sx={{ backgroundColor: theme.palette.background.paper, padding: '20px', borderRadius: '10px' }}>
						<MuiFormText sx={{}}>{t('bulkpayments.form.info')}</MuiFormText>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<MuiFormText>{t('bulkpayments.form.subInfo')}</MuiFormText>
				</Grid>
				<Grid item xs={12}>
					<ul style={{
						listStyleType: 'decimal', paddingLeft: '16px', fontSize: '0.813rem',
						fontWeight: '700', lineHeight: '1.25rem', color: '#FFFFFF'
					}}>
						<li>
							<MuiTextCaption>{t('bulkpayments.form.steps.first.title')}</MuiTextCaption>
							<MuiTextCaption sx={{ fontWeight: '400' }}>
								<Trans i18nKey='bulkpayments.form.steps.first.step1'
									t={t}
									components={{
										exampleUrl: <Link sx={{
											fontWeight: '400 !important', fontSize: '0.813rem !important', lineHeight: '1.25rem !important'
										}} component="button" onClick={handleDownloadExample} />
									}}
								/>
							</MuiTextCaption>
						</li>
						<li>
							<MuiTextCaption>{t('bulkpayments.form.steps.second.title')}</MuiTextCaption>
							<ul>
								<li>
									<MuiTextCaption sx={{ fontWeight: '400' }}>{t('bulkpayments.form.steps.second.step1')}</MuiTextCaption>
								</li>
								<li>
									<MuiTextCaption sx={{ fontWeight: '400' }}>{t('bulkpayments.form.steps.second.step2')}</MuiTextCaption>
								</li>
								<li>
									<MuiTextCaption sx={{ fontWeight: '400' }}>{t('bulkpayments.form.steps.second.step3')}</MuiTextCaption>
								</li>
							</ul>
						</li>
						<li>
							<MuiTextCaption>{t('bulkpayments.form.steps.third.title')}</MuiTextCaption>
							<MuiTextCaption sx={{ fontWeight: '400' }}>{t('bulkpayments.form.steps.third.step1')}</MuiTextCaption>
						</li>
					</ul>

				</Grid>
				<Grid item xs={12}>
					<FileUpload
						disabled={uploading}
						sx={{
							background: { xs: 'transparent', sm: theme.palette.background.paper },
							padding: { xs: 0, md: '20px' }
						}}
						acceptedFileFormats='text/csv'
						setFile={(value) => { setFile(value); setUploadFailed(false); }}
						setStatus={(value) => setFileStatus(value)}
						maxFileSizeKb={5000}
					/>
					{uploadFailed && <MuiTextFieldInfo>{t('bulkpayments.form.uploadFailed')}</MuiTextFieldInfo>}
				</Grid>
			</Grid>
			<Grid container mt='auto' pt='2rem' columnSpacing={2}>
				<Grid item xs={6}>
					<Button
						className='button-left__wm'
						variant='customOutlined'
						color='secondary'
						onClick={closeModal}
						sx={{ width: { xs: '100%', md: 'auto' } }}
					>  {t('form.buttons.close')}
					</Button>
				</Grid>
				<Grid item xs={6} container justifyContent='flex-end'>
					<Button
						disabled={uploading || fileStatus !== FileStatusEnum.SUCCESS || uploadFailed}
						className='button-right__wm'
						variant="contained"
						color='primary'
						onClick={submitFileUpload}
						sx={{ width: { xs: '100%', md: 'auto' } }}
					>
						{t('form.buttons.submit')}
					</Button>
				</Grid>
			</Grid>
		</FormContentBox>);
};

export default BulkPaymentForm;
