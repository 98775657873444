import React, { useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { openForm } from '@/features/forms/slice';
import FormPaperLargeFixed from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeFixed';
import AssetSelectStep from '../Deposit/Crypto/AssetSelectStep';
import { CoinType } from '@/features/coins/types';
import { RootState } from '@/rootReducer';
import BuyAmountStep from './BuyAmountStep';
import { findProcessByType } from '@/features/operations/slice';

const BuyCryptoForm = (): React.ReactElement => {

	const searchParams = new URLSearchParams(location.search);
	const [symbol] = React.useState<string>(searchParams.get('symbol'));
	const dispatch = useDispatch();
	const [step, setStep] = useState<number>(symbol ? 1 : 0);
	const prevStep = () => setStep(step - 1);
	const nextStep = () => setStep(step + 1);

	const list = useSelector((state: RootState) => state.coins.list);
	const coins = list?.filter(p => p.account === null || p.type !== 'WALLET') || [];

	const defaultToken = symbol ? coins.find(p => p.info.symbol === symbol) || null : null;

	const [coin, setCoin] = useState<CoinType>(defaultToken);


	const handleSelectToken = (value: CoinType) => {
		nextStep();
		setCoin(value);
	};

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);


	return (
		<Dialog
			id="wallet-send-form"
			open={true}
			PaperComponent={FormPaperLargeFixed}
		>
			{step === 0 && <AssetSelectStep
				onNext={handleSelectToken}
				symbol={symbol}
				showOwnedOnly={false}
				includeWallet={false}
				filterFunction={(account: any) => account?.type !== 'WALLET'&&!!findProcessByType(account, 'DEPOSIT_FROM_CARD')?.proc} />}
			{step === 1 && <BuyAmountStep
				prevStep={prevStep}
				toAsset={coin}
			/>}
		</Dialog >
	);
};

export default BuyCryptoForm;
