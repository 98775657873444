import { RootState } from '@/rootReducer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MuiMyAccount from './MyAccount';
import MyContacts from '../MyContacts/MyContacts';
import MyFees from './MyFees/MyFees';
import Security from '../Security/Security';
import Referrals from './Referrals/Referrals';
import Plans from './Plans/Plans';
import Benefits from './Benefits/Benefits';
import { getCards } from '@/features/card/slice';
import LoadingPageMui from '@/pages/LoadingPageMui';


export enum MyAccountPageTab {
    MY_ACCOUNT = 'myAccount', PLANS = 'plans', SECURITY = 'security', CONTACTS = 'contacts', FEE_LIMITS = 'feeLimits', REFERRALS = 'referrals',
    BENEFITS = 'benefits'
}

const MyAccountPage = (): React.ReactElement => {

    const dispatch = useDispatch();
    const searchParams = new URLSearchParams(location.search);
    const currentTab = searchParams.get('tab');
    const [loading, setLoading] = useState(false);

    const { user } = useSelector((state: RootState) => state.user);
    useEffect(() => {
        setLoading(true);
        dispatch(getCards());
        setLoading(false);
    }, [dispatch]);

    if (loading) return (<LoadingPageMui />);
    return (
        <>
            {currentTab === MyAccountPageTab.MY_ACCOUNT && <MuiMyAccount user={user} />}
            {currentTab === MyAccountPageTab.PLANS && <Plans />}
            {currentTab === MyAccountPageTab.SECURITY && <Security />}
            {currentTab === MyAccountPageTab.CONTACTS && <MyContacts />}
            {currentTab === MyAccountPageTab.FEE_LIMITS && <MyFees />}
            {currentTab === MyAccountPageTab.REFERRALS && <Referrals />}
            {currentTab === MyAccountPageTab.BENEFITS && <Benefits />}
        </>
    );
};

export default MyAccountPage;

