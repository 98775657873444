import * as React from 'react';

import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import { Employee } from '@/features/employee/types';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { EmployeeDetailsStep } from './ActiveEmployeeForm';
import { unlinkEmployee } from '@/features/employee/slice';
import { showException } from '@/features/swal/slice';


interface Props {
	onClose: () => void,
	setStep: (step: EmployeeDetailsStep) => void,
	employee: Employee;
}

const ActiveEmployeeUnlinkStep = ({ onClose, employee, setStep }: Props) => {
	const { t } = useTranslation('translations');
	
	const unlink = async () => {
		try {
			await unlinkEmployee(employee);
			onClose();
		}
		catch (e) {
			showException(e);
		}
	};

	return (
		<>
			<Grid item container alignItems="center" pb={1}>
				<Grid item xs={12}>
					<FormHeader title={`${employee.firstName} ${employee.lastName}`} onBack={() => setStep(EmployeeDetailsStep.MAIN)} mb='0' />
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<MuiFormText >{t('employee.activeEmployeeForm.deleteEmployeeQuestion')}</ MuiFormText>
			</Grid>
			
			<Grid item xs={12} flex={1} alignItems='flex-end' justifyContent='space-between' mt={8} display='flex'>
				<Button
					onClick={onClose}
					variant='customOutlined'
				>{t('form.buttons.close')}</Button>
				<Button
					onClick={unlink}
					variant="contained"
				>
					{t('employee.activeEmployeeForm.deleteEmployeeBtn')}
				</Button>
			</Grid>
		</>
	);
};

export default ActiveEmployeeUnlinkStep; 
