import { createSlice } from '@reduxjs/toolkit';
import { FormStore } from './types';

/* Open modal form state is need to inform Darshborad when to apply blur. 
This is needed for firefox due to its limitation of dropfill support
*/


const initialState: FormStore = {
	isOpen: false,
};

const formsSlice = createSlice({
	name: 'modalForms',
	initialState,
	reducers: {
		openForm(state) {
			state.isOpen = true;
		},
		closeForm(state) {
			state.isOpen = false;

		},
	}
});

export const { openForm, closeForm } = formsSlice.actions;

export default formsSlice.reducer;
