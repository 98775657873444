import React from 'react';
import { Avatar, AvatarProps } from '@mui/material';
import { User, UserType } from '@/features/user/types';


interface Props extends AvatarProps {
	user: User
}

const UserAvatar = (props: Props): React.ReactElement => {

	const { user, sx, ...other } = props;

	const firstName = user?.firstName ? user.firstName.charAt(0) : '';
	const lastName = user?.lastName ? user.lastName.charAt(0) : '';
	const companyName = user?.companyName ? user.companyName.charAt(0) : '';

	const initials = user.type === UserType.BUSINESS ?
		companyName :
		firstName + lastName;

	return (
		initials ?
			<Avatar sx={{ width: '4rem', height: '4rem', ...sx }} {...other}>
				{initials}
			</Avatar> :
			<Avatar sx={{ width: '4rem', height: '4rem', ...sx }} {...other}
			/>);
};

export default UserAvatar;
