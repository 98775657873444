import React from 'react';

import NumberFormat from 'react-number-format';
import { Box, Grid } from '@mui/material';
import { getCurrencySign } from '@/features/fees/slice';
import MuiTextLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextLabel';
import TokenAvatar, { TokenAvatarSizeEnum } from '@/componentsMui/Shared/Widgets/Avatars/TokenAvatar';
import MuiTextCaption from '@shared/Widgets/MuiText/MuiTextCaption';
import { isFiat } from '@/helpers/currency';

interface Props {
	currency?: string,
	logoSource?: string,
	name?: string,
	balance?: number,
	decimalScale?: number,
}

const AccountItem = ({ currency, logoSource, name, balance }: Props) => {

	return (
		<>
			<Box sx={(theme) => ({
				background: theme.palette.background.paper,
				borderRadius: theme.mainBorderRadius,
				p: 2,
				width: '100%',
				mb: { xs: 3, md: 0 },
				mt: 1
			})}>
				<Grid container flexDirection='column' flex={1} id='account-box-details'>
					<Grid item container>
						<Grid item>
							<TokenAvatar symbol={currency} logoSource={logoSource} variant={TokenAvatarSizeEnum.regular} />
						</Grid>
						<Grid item pl={2} alignItems='start' justifyContent={'center'} display={'flex'} flexDirection={'column'}>
							<MuiTextCaption fontSize='1rem'>{name}</MuiTextCaption>
							<MuiTextLabel fontWeight='400' mt={0.5}>
								<NumberFormat displayType={'text'}
											  decimalScale={isFiat(currency) ? 2 : 5}
											  fixedDecimalScale={true}
											  thousandsGroupStyle='thousand'
											  thousandSeparator={true}
											  prefix={'Available: '}
											  suffix={` ${getCurrencySign(currency)}`}
											  value={Math.abs(balance)} />
							</MuiTextLabel>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default AccountItem;
