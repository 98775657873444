import React, { useEffect } from 'react';
import { Box, Dialog, Grid, IconButton, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { openForm } from '@/features/forms/slice';
import CryptoTrade from './CryptoTrade';
import { RootState } from '@/rootReducer';
import { CoinTypeEnum } from '@/features/coins/types';
import { setFromAsset, setToAsset, setTradeSwapped, transformAccountToAssets, transformTokensToAssets } from '@/features/coins/slice';
import CloseIcon from '@mui/icons-material/Close';
import LoadingPageMui from '@/pages/LoadingPageMui';
import { ReactComponent as MuiCloseIcon } from '@assets/MuiCloseIcon.svg';
import { WalletType } from '@/features/walletHistory/types';
import { withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';

const FormPaper = withStyles(() => ({
	root: {
		maxHeight: '560px',
		minHeight: 'fit-content',
		width: '600px',
		['@media only screen and (max-height: 700px)']: {
			maxHeight: 'unset',
			minHeight: 'unset',
			minWidth: 'unset',
			maxWidth: '100%',
			width: '100%',
			height: '100%',
			margin: 0,
			borderRadius: 0,
			'-ms-overflow-style': 'none',
			'scrollbar-width': 'none',
			'&::-webkit-scrollbar': {
				display: 'none'
			},
		},
		['@media only screen and (max-width:600px)']: {
			maxHeight: 'unset',
			minHeight: 'unset',
			minWidth: 'unset',
			maxWidth: '100%',
			width: '100%',
			height: '100%',
			margin: 0,
			borderRadius: 0,
			'-ms-overflow-style': 'none',
			'scrollbar-width': 'none',
			'&::-webkit-scrollbar': {
				display: 'none'
			},
		},
	},
}))(Paper);

interface Props {
	walletType: WalletType
}

const CryptoTradeForm = ({ walletType }: Props): React.ReactElement => {
	const dispatch = useDispatch();
	const closeModal = useCloseDialogNoEvent();
	const { t } = useTranslation('translations');
	const searchParams = new URLSearchParams(location.search);
	const [requestedToken] = React.useState<string>(searchParams.get('token'));
	const { list: tokensList, loading: tokensLoading } = useSelector((state: RootState) => state.tokens);
	const { list: cryptoList, loading: cryptoLoading } = useSelector((state: RootState) => state.coins);
	const list = walletType === WalletType.TOKEN ? tokensList : cryptoList;
	const loading = walletType === WalletType.TOKEN ? tokensLoading : cryptoLoading;
	const fiatAssets = list?.filter(p => p.type === 'WALLET' && p.account.status === 'ACTIVE') || [];
	const coinAssets = list?.filter(p => p.account === null || p.type !== 'WALLET') || [];
	const toToken = list.find(p => p.type.concat(p.info.symbol) === requestedToken);
	const fromToken = list?.find(p => p.type === CoinTypeEnum.WALLET && toToken?.info?.supportedCcy?.map(a => a.ccy).includes(p.account.currency)) || null;
	const defaultToAsset = transformTokensToAssets([toToken])[0] || null;
	const defaultFromAsset = transformTokensToAssets([fromToken])[0] || null;

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);

	const onClose = () => {
		closeModal();
		const asset = transformAccountToAssets([fiatAssets[0]])[0] || null;
		dispatch(setFromAsset(asset));
	};

	//default asset
	useEffect(() => {
		if (defaultToAsset?.id) {
			dispatch(setToAsset(defaultToAsset));
		}

		if (defaultFromAsset?.id) {
			dispatch(setFromAsset(defaultFromAsset));
		}

		return () => {
			dispatch(setToAsset(null));
			dispatch(setFromAsset(null));
			dispatch(setTradeSwapped(false));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [dispatch, defaultFromAsset?.id, defaultFromAsset?.id]);

	return (
		<Dialog
			open={true}
			onClose={onClose}
			PaperComponent={FormPaper}
			fullWidth>
			<Grid container padding='30px' justifyContent='center' >
				<Box width='31.25rem'>
					<Grid container justifyContent='space-between' alignItems='center' mb='20px' >
						<MuiHeader> {t('wallet.labels.convert')}</MuiHeader>
						<IconButton sx={{ display: { xs: 'none', md: 'ex' }, p: 0 }}
							onClick={closeModal}>
							<CloseIcon style={{ width: '20px', height: '20px' }} />
						</IconButton>
						<Grid item sx={{ display: { xs: 'flex', md: 'none' } }}>
							<IconButton onClick={closeModal}  >
								<MuiCloseIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Box>
				{loading
					? <LoadingPageMui />
					: <CryptoTrade
						key={requestedToken}
						walletType={walletType}
						coinHoldings={coinAssets}
						fiatHoldings={fiatAssets}
					/>
				}
			</Grid>
		</Dialog >
	);
};

export default CryptoTradeForm;
