import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setLandingTitle } from '@/features/landingStep/slice';
import { useDispatch } from 'react-redux';
import './Onboarding.css';
import MinimalLayout from '../Layout/MinimalLayout';
import BackButtonWebLanding from './components/BackButtonWebLanding';
import ShareNeuralId from '../Shared/Components/ShareNeuralId';
import { sycnNeuralid } from '@/features/userNeuralid/sliceUserNeuralid';

interface Props {
    paddingTop?: number,
    onSuccess: () => void,
    goBack: () => void,
}

const ShareNeuralIdStep = ({ paddingTop, goBack, onSuccess }: Props) => {

    const { t } = useTranslation('translations');
    const dispatch = useDispatch();
    const title = t('landing.neuralIdStep.title');

    useEffect(() => {
        dispatch(setLandingTitle(title));
    }, [dispatch, title]);


    const handleDataShare = async (internalId: string) => {
        await sycnNeuralid(internalId);
        onSuccess();
    };

    return (
        <MinimalLayout
            leftPanel={<BackButtonWebLanding onClick={goBack} />}
            height='fit-content'
            paddingTop={paddingTop}
            allowGoHome={false}>
            <ShareNeuralId onSuccess={handleDataShare} />
        </MinimalLayout>
    );
};

export default ShareNeuralIdStep;
