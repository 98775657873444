import React from 'react';
import * as Yup from 'yup';
import { Autocomplete, Grid, IconButton, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { PartnerOptions, PartnerType, TransactionNumberOptions, TransactionVolumneOptions } from '@/features/business/types';
import MuiSubHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiSubHeader';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import CountryCodesAlpha3 from '@/componentsMui/Shared/Widgets/CountryCodesAlpha3';
import { useCompanyName } from '../../../../../helpers/customHook/useValidations';
import StyledPaper from '@/componentsMui/Shared/Widgets/StyledPaper';

interface Props {
	partner?: PartnerType,
	id: string,
	allowRemove: boolean,
	updateForm?: (idx: string, handleSubmit: any, validateForm: any, errors: any, isSubmitting: boolean, isDirty: boolean, values: any) => void,
	removeForm?: (idx: string) => void,
	doNotAllowUpdate: boolean
}

const BusinessPartnerItem = ({ id, allowRemove, partner, updateForm, removeForm, doNotAllowUpdate }: Props): React.ReactElement => {

	const values = partner;
	const { t } = useTranslation('translations');

	const partnerCompanyNameValidation = useCompanyName(t('createbusiness.partners.companyName'));

	const partnerOptions = Object.keys(PartnerOptions)?.map(p => { return ({ code: p, label: t('createbusiness.partnerTypes.' + p) }); });
	const trxNumberOptions = Object.keys(TransactionNumberOptions)?.map(p => TransactionNumberOptions[p]);
	const trxVolumeOptions = Object.keys(TransactionVolumneOptions)?.map(p => TransactionVolumneOptions[p]);


	const initialValues = {
		partnerCompanyName: values?.partnerCompanyName ?? '',
		partnerIncorporationCountry: values?.partnerIncorporationCountry ?? '',
		plannedNumberOfMonthlyTransactions: values?.plannedNumberOfMonthlyTransactions ?? '',
		plannedVolumeOfMonthlyTransactons: values?.plannedVolumeOfMonthlyTransactons ?? '',
		partnerType: values?.partnerType ?? '',
		basisOfPartnership: values?.basisOfPartnership ?? '',
	};

	const validationSchema = Yup.object({
		partnerCompanyName: partnerCompanyNameValidation,
		partnerIncorporationCountry: Yup.string().required(t('form.validator.required')),
		plannedNumberOfMonthlyTransactions: Yup.string().required(t('form.validator.required')),
		plannedVolumeOfMonthlyTransactons: Yup.string().required(t('form.validator.required')),
		partnerType: Yup.string().required(t('form.validator.required')),
		basisOfPartnership: Yup.string().required(t('form.validator.required'))
	});

	const submit = () => {
		//do nothing, use effect handles actual submit
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: submit,
	});

	// React.useEffect(() => {
	// 	if (formik) {
	// 		formik.validateForm();
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);


	React.useEffect(() => {
		updateForm(id, formik.handleSubmit, formik.validateForm, formik.errors, formik.isSubmitting, formik.dirty, formik.values);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, formik.errors, formik.isSubmitting, formik.dirty, formik.values]);

	return (
		<form
			id="business-partner"
			onSubmit={formik.handleSubmit}>
			<Grid container spacing={2}>
				{allowRemove &&
					<Grid item xs={12} mt='1rem' mb='1rem'>
						<StyledDivider />
					</Grid>}
				<Grid item xs={12} container justifyContent='space-between'>
					<MuiSubHeader>{t('createbusiness.partners.partner')} - {formik.values.partnerCompanyName}</MuiSubHeader>
					{allowRemove &&
						< IconButton onClick={() => removeForm(id)}  >
							<MuiCloseIcon />
						</IconButton>}
				</Grid>
				<Grid item xs={6}>
					<TextField
						id="partnerCompanyName"
						name="partnerCompanyName"
						fullWidth
						inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
						label={t('createbusiness.partners.companyName')}
						value={formik.values.partnerCompanyName}
						onChange={formik.handleChange}
						error={formik.errors.partnerCompanyName && Boolean(formik.touched.partnerCompanyName)}
						helperText={formik.errors.partnerCompanyName} />
				</Grid>
				<Grid item xs={6}>
					<TextField
						id="basisOfPartnership"
						name="basisOfPartnership"
						fullWidth
						inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
						label={t('createbusiness.partners.basis')}
						value={formik.values.basisOfPartnership}
						onChange={formik.handleChange}
						error={formik.errors.basisOfPartnership && Boolean(formik.touched.basisOfPartnership)}
						helperText={formik.errors.basisOfPartnership} />
				</Grid>
				<Grid item xs={6}>
					<CountryCodesAlpha3
						readonly={doNotAllowUpdate}
						name="partnerIncorporationCountry"
						countryCode={formik.values.partnerIncorporationCountry}
						onChange={(value) => formik.setFieldValue('partnerIncorporationCountry', value)}
						error={formik.errors.partnerIncorporationCountry}
					/>
				</Grid>
				<Grid item xs={6}>
					<Autocomplete
						readOnly={doNotAllowUpdate}
						id='trx-number-list'
						options={trxNumberOptions}
						value={formik.values.plannedNumberOfMonthlyTransactions}
						onChange={(e, value) => formik.setFieldValue('plannedNumberOfMonthlyTransactions', value)}
						disableClearable
						PaperComponent={({ children }) => (
							<StyledPaper>{children}</StyledPaper>
						)}
						isOptionEqualToValue={(option, value) => value === undefined || value === '' || value === value}
						renderInput={(params) => <TextField
							name="plannedNumberOfMonthlyTransactions" {...params}
							label={t('createbusiness.partners.trx')}
							error={formik.errors.plannedNumberOfMonthlyTransactions && Boolean(formik.touched.plannedNumberOfMonthlyTransactions)}
							helperText={formik.errors.plannedNumberOfMonthlyTransactions}
						/>}
					/>
				</Grid>
				<Grid item xs={6}>
					<Autocomplete
						readOnly={doNotAllowUpdate}
						id='trx-column-list'
						options={trxVolumeOptions}
						onChange={(e, value) => formik.setFieldValue('plannedVolumeOfMonthlyTransactons', value)}
						value={formik.values.plannedVolumeOfMonthlyTransactons}
						disableClearable
						PaperComponent={({ children }) => (
							<StyledPaper>{children}</StyledPaper>
						)}
						isOptionEqualToValue={(option, value) => value === undefined || value === '' || value === value}
						renderInput={(params) => <TextField
							name="plannedVolumeOfMonthlyTransactons" {...params}
							label={t('createbusiness.partners.volume')}
							error={formik.errors.plannedVolumeOfMonthlyTransactons && Boolean(formik.touched.plannedVolumeOfMonthlyTransactons)}
							helperText={formik.errors.plannedVolumeOfMonthlyTransactons}
						/>}
					/>
				</Grid>
				<Grid item xs={6}>
					<Autocomplete
						readOnly={doNotAllowUpdate}
						id='partner-list'
						options={partnerOptions}
						value={partnerOptions.find(p => p.code === formik.values.partnerType)}
						onChange={(e, value) => formik.setFieldValue('partnerType', typeof value === 'object' ? value.code : '')}
						disableClearable
						PaperComponent={({ children }) => (
							<StyledPaper>{children}</StyledPaper>
						)}
						isOptionEqualToValue={(option, value) => value === undefined || option.code === value.code || value?.code === ''}
						renderInput={(params) => <TextField
							name="partnerType" {...params}
							label={t('createbusiness.partners.type')}
							error={formik.errors.partnerType && Boolean(formik.touched.partnerType)}
							helperText={formik.errors.partnerType}
						/>}
					/>
				</Grid>
			</Grid>
		</form >
	);
};

export default BusinessPartnerItem;
