import MuiTextAccountBoxMoney from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAccountBoxMoney';
import { getCurrencySign } from '@/features/fees/slice';
import { hiddenBabalance } from '@/helpers/placeholders';
import { RootState } from '@/rootReducer';
import { Grid } from '@mui/material';
import React from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

interface Props {
	currency: string,
	value: number | string,
	fontSize?: string,
	row?: boolean;
}

const BigAmount = ({ currency, value, fontSize, row = false }: Props): React.ReactElement => {

	const { user } = useSelector((state: RootState) => state.user);

	return (<>
		{row ?
			<Grid display='flex' flexDirection='row'>
				<MuiTextAccountBoxMoney variant='highlight' {...(fontSize ? { fontSize: fontSize } : {})}>
					{getCurrencySign(currency)}
				</MuiTextAccountBoxMoney>
				<MuiTextAccountBoxMoney pl={0.5}>
					{user.hideValues ? hiddenBabalance :
						<NumberFormat
							displayType={'text'}
							decimalScale={2}
							fixedDecimalScale={true}
							thousandsGroupStyle='thousand'
							thousandSeparator={true}
							isNumericString={typeof value === 'string'}
							value={value}
						/>}
				</MuiTextAccountBoxMoney>
			</Grid>
			: <Grid item container>
				<MuiTextAccountBoxMoney variant='highlight' {...(fontSize ? { fontSize: fontSize } : {})}>
					{getCurrencySign(currency)}
				</MuiTextAccountBoxMoney>
				<MuiTextAccountBoxMoney pl={0.5}>
					{user.hideValues ? hiddenBabalance :
						<NumberFormat
							displayType={'text'}
							decimalScale={2}
							fixedDecimalScale={true}
							thousandsGroupStyle='thousand'
							thousandSeparator={true}
							isNumericString={typeof value === 'string'}
							value={value}
						/>}
				</MuiTextAccountBoxMoney>
			</Grid>}
	</>);
};

export default BigAmount;
