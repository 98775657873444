import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { TransactionsTypedPayload } from '@/features/transactions/types';
import { getTime } from 'date-fns';
import { getTransactionsTyped } from '@/features/transactions/slice';
import { DataGrid, GridPaginationModel, useGridApiRef } from '@mui/x-data-grid';
import { Grid, useMediaQuery } from '@mui/material';
import { showErrorNotification } from '@/features/swal/slice';
import { useTranslation } from 'react-i18next';
import SearchField from '../Shared/Widgets/SearchField';
import TransactionDetailsForm from '../Transactions/Details/TransactionDetailsForm';
import ExportStatementForm from '../Transactions/Download/ExportStatementForm';
import { Transaction } from '../Transactions/TransactionStatements.spec';
import { transactionDetailsEnabled } from '@/helpers/transactionUtils';
import { closeForm, openForm } from '@/features/forms/slice';
import { AccountType } from '@/features/operations/types';
import MuiHeader from '../Shared/Widgets/MuiText/MuiHeader';
import DownloadButton from '../Shared/Widgets/DownloadButton';
import { BankAccount } from '@/features/account/types';
import { getMobileTrxGridColumns, getTrxGridColumns } from './Components/TransactionColumns';
import Spinner from '../Shared/Widgets/Spinner/Spinner';
import TrxGenericError from './Components/TrxGenericError';

interface Props {
    type: AccountType,
    account?: BankAccount
}

const TransactionGridFull = ({ type, account }: Props) => {
    const { t } = useTranslation('translations');
    const dispatch = useDispatch();
    const apiRef = useGridApiRef();
    const smallScreen = useMediaQuery('(max-width:600px)');

    const { user } = useSelector((state: RootState) => state.user);
    const [transactions, setTransactions] = useState<any[]>([]);
    const [search, setSearch] = useState<string>('');
    const [totalRows, setTotalRows] = useState<number>(0);
    const [loadingError, setLoadingError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingTrx, setLoadingTrx] = useState<boolean>(false);

    const [openTransactionDetails, setOpenTransactionDetails] = React.useState<boolean>(false);
    const [selectedTrx, setSelectedTrx] = React.useState<Transaction>();
    const [openDownloadForm, setOpenDownloadForm] = React.useState<boolean>(false);
    const [time] = useState<number>(new Date().getTime() + 86400000);

    const userCreationTime = getTime(new Date(user.createdDate));

    const payload: TransactionsTypedPayload = useMemo(() => {
        return {
            type,
            search: search,
            skip: 0,
            take: 50,
            from: `${userCreationTime}`,
            to: `${time}`,
            ...(account?.accountId && {
                accountId: `${account.accountId}`
            })
        };
    }, [type, search, userCreationTime, account?.accountId, time]);

    const columns = useMemo(() => smallScreen
        ? getMobileTrxGridColumns(type, account?.subProcesses)
        : getTrxGridColumns(type, account?.subProcesses), [type, account?.subProcesses, smallScreen]);


    const fetch = useCallback(async (newPayload: TransactionsTypedPayload) => {
        try {
            setLoadingError(false);
            setLoadingTrx(true);
            const result = await (getTransactionsTyped(newPayload));
            setTransactions(result?.list);
            setTotalRows(result.count);
        }
        catch (e) {
            setLoadingError(true);
            setTransactions([]);
        }
        finally { setLoadingTrx(false); }
    }, []);

    const onPaginationModelChange = async (model: GridPaginationModel) => {
        try {
            //call page from grid model 

            const newPayload = { ...payload, take: model.pageSize, skip: model.page * model.pageSize };
            fetch(newPayload);

        }
        catch (e) {
            showErrorNotification(e);
        }
    };

    const handleSearchRequest = (e) => {
        const regex = /^[ A-Za-z0-9-.,&/_?!]*$/;
        if (regex.test(e.target.value)) {
            setSearch(e.target.value);
            fetch({ ...payload, search: e.target.value });
        }
    };

    const closeTransactionDetailsForm = React.useCallback(() => {
        setOpenTransactionDetails(false);
        dispatch(closeForm());
    }, [dispatch]);

    const handleOnCellClick = (params) => {

        const trx = params.row;

        if (transactionDetailsEnabled(trx.activity) && params.field !== 'status') {
            dispatch(openForm());
            setOpenTransactionDetails(true);
            setSelectedTrx(trx);
        }

    };

    return (<>
        <TransactionDetailsForm open={openTransactionDetails}
            onClose={closeTransactionDetailsForm}
            transaction={selectedTrx}
            account={account}
            type={type}
            setLoading={(value: boolean) => setLoading(value)} />
        <ExportStatementForm
            accountId={account?.accountId}
            open={openDownloadForm}
            type={type}
            onClose={() => setOpenDownloadForm(false)} />
        {loading && <Grid container justifyContent='center' mt={2}><Spinner /></Grid >}
        <Grid container
            alignItems='center'
            p={{ sx: '0px', md: '30px' }}
            bgcolor={{ sx: 'transparent', md: '#2A2E32' }}
            borderRadius='10px'>
            <Grid item xs={12} container justifyContent='space-between'>
                <Grid item xs>
                    <MuiHeader>{t('transaction.header.transactions')}</MuiHeader>
                </Grid>
                <Grid item container xs={12} md='auto'
                    mt={{ xs: '10px', md: '0px' }}
                >
                    {type !== AccountType.CARD &&
                        <Grid item sx={{ width: { xs: '150px', md: 'auto' } }}>
                            <SearchField
                                id='search'
                                name='search'
                                value={search}
                                onChange={(e) => handleSearchRequest(e)}
                            />
                        </Grid>}
                    <Grid item ml='10px' >
                        <DownloadButton
                            sx={(theme) => ({ background: theme.backgroundBoxLevel1 })}
                            onClick={() => setOpenDownloadForm(true)} />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} my='10px'
                sx={{
                    height: 'calc(100vh - 190px)',
                    width: '100%'
                }}

            >
                {loadingError ? <TrxGenericError onButtonClick={() => fetch(payload)} /> :
                    <DataGrid
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 5, page: 0 },
                            },
                        }}
                        autoPageSize={true}
                        pagination
                        apiRef={apiRef}
                        rows={transactions}
                        rowCount={totalRows}
                        loading={loadingTrx}
                        getRowId={(row) => `${row.transactionNumber}`}
                        onPaginationModelChange={onPaginationModelChange}
                        onCellClick={handleOnCellClick}
                        paginationMode="server"
                        filterMode="server"
                        sortingMode="server"
                        disableRowSelectionOnClick={true}
                        columns={columns}
                        sx={{
                            // disable cell selection style
                            '.MuiDataGrid-cell:focus': {
                                outline: 'none'
                            },
                            // pointer cursor on ALL rows
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer'
                            },
                            '& .MuiDataGrid-cell[data-field="details"] > div > span': {
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            },
                            '& .MuiDataGrid-cell[data-field="details"] > div': {
                                overflow: 'hidden',
                            },
                        }}
                    />
                }
            </Grid>
        </Grid >

    </>);

};

export default TransactionGridFull;

