import * as React from 'react';

import '@/componentsMui/Shared/Shared.css';

import { Button, Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import AccountOption from './AccountOption'; 
import FormLayout from '@/componentsMui/Shared/Widgets/Dialog/FormLayout';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { AccountType } from './CreateAccountForm';

 
export interface CreateStepProps {
	accountType: AccountType,
	currentOperation: any
	initialValues: any,
	validationSchema: any,
	bgIndex: number,
	submit: (formData: any, formikProps: any) => Promise<void>,
	prevStep: () => void,
	//nextStep: () => void,
	closeModal: (e: any) => void,
}

const StepCreate = ({ accountType, initialValues, validationSchema, submit, currentOperation, bgIndex, prevStep, closeModal }: CreateStepProps) => {

	const { t } = useTranslation('translations');

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: submit,
	});

	const mainContent =
		<Grid container>
			<Grid item xs={12} mb={2}>
				<MuiHeader>{t('create.createAccount')}</MuiHeader>
			</Grid>
			<Grid item mb={4}>
				<AccountOption
					key="selectedOption"
					operation={currentOperation}
					type={accountType}
					selected={true}
					bgIndex={bgIndex} />
			</Grid>
			<Grid item xs={12}>
				<TextField
					id="name"
					name="name"
					InputLabelProps={{ shrink: true }}
					value={formik.values.name}
					onChange={formik.handleChange}
					label={t('createaccounttab.virtualaccount.name')}
					placeholder={t('createaccounttab.virtualaccount.nameplaceholder')}
					error={formik.errors.name && Boolean(formik.touched.name)}
					helperText={formik.errors.name}
				/>
			</Grid>{/* 
			<Grid item xs={12}>
				<Link onClick={nextStep}>Terms of Service</Link>
			</Grid> */}
		</Grid>;

	const buttonContent = [
		<Button
			key="buttonCancel"
			className='customDialogButton'
			variant='contained'
			color='secondary'
			onClick={prevStep}
		>  {t('form.buttons.back')}
		</Button>,
		<Button disabled={formik.isSubmitting || !isEmpty(formik.errors)}
			key="buttonNext"
			className='customDialogButton'
			type="submit"
			variant="contained" >
			{t('createaccounttab.btn')}
		</Button>];

	return (
		<React.Fragment>
			<form
				id="createAccountStep"
				className="portal-dialog-form-template"
				onSubmit={e => { formik.handleSubmit(e); closeModal(e); }}>
				<FormLayout
					contentPart={mainContent}
					buttonPart={buttonContent}
				/>
			</form>
		</React.Fragment >
	);
};

export default StepCreate;
