import * as React from 'react';
import '@/componentsMui/Dashboard/Create/Create.css';
import { Button, Dialog, FormControl, Grid, InputLabel, MenuItem } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { openForm } from '@/features/forms/slice';
import FormPaperLargeFixed from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeFixed';
import FormContentBox from '@/componentsMui/Shared/Widgets/Box/FormContentBox';
import { Form, Formik } from 'formik';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { getActiveEmployees, getAvailableCards, linkCard } from '@/features/employee/slice';
import { RootState } from '@/rootReducer';
import { showErrorNotification, showException, showSuccess } from '@/features/swal/slice';
import StyledSelect from '@/componentsMui/Shared/Widgets/StyledSelect';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';

const AssignCardForm = () => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const [availableCards, setAvailableCards] = useState<any[]>([{}]);
	const [card, setCard] = useState<any>(null);
	const [employee, setEmployee] = useState<any>(null);
	const [employeeLoading, setEmployeeLoading] = useState<boolean>(true);
	const [cardLoading, setEmployeeCardLoading] = useState<boolean>(true);
	const closeModal = useCloseDialogNoEvent();

	const { activeEmployees } = useSelector(
		(state: RootState) => state.employee
	);

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);

	useEffect(() => {
		const fetchActiveEmployees = async () => {
			try {
				await dispatch(getActiveEmployees());
			} catch (e) {
				showErrorNotification(e);
			}
			setEmployeeCardLoading(false);
		};
		fetchActiveEmployees();
	}, [dispatch]);

	useEffect(() => {
		const fetchAvalableCards = async () => {
			const available = await getAvailableCards();
			setAvailableCards(available);
			setEmployeeLoading(false);
		};
		fetchAvalableCards();
	}, []);

	const initialValues = {
		card: '',
		employee: '',
	};

	const validationSchema = Yup.object({});

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(true);
		try {
			await linkCard(employee.employeeId, card.cardId);
			await showSuccess({ title: t('cards.businessCards.assignCardForm.successMsgTitle'),
				text: t('cards.businessCards.assignCardForm.successMsgText',
					{
						card: card.name,
						cardNumber: card?.number ? card.number.slice(-7).replaceAll('*', '•') : '•• ••••',
						employee: `${employee.firstName} ${employee.lastName}`
					}),
					mui: true
				});
		} catch (e) {
			await showException(e);
		} finally {
			setSubmitting(false);
			closeModal();
		}

	};

	const handleCardChange = (event: any) => {
		setCard(availableCards.find(p => p.cardId === event.target.value));
	};

	const handleEmployeeChange = (event: any) => {
		setEmployee(activeEmployees.find(e => e.employeeId === event.target.value));
	};

	return (
		<Dialog
			id="assign-card-form"
			open={true}
			onClose={closeModal}
			PaperComponent={FormPaperLargeFixed}
		>
			{cardLoading && employeeLoading && <Spinner />}
			{!cardLoading && !employeeLoading && <Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting }) => {
					return (
						<Form className='form-content__wrap__bb'>
							<FormContentBox noBackground={true} className='form-content_main-area wp' >
								<Grid id='paymentDetails' container columnSpacing={3} rowSpacing={1}>
									<Grid item xs={12}>
										<FormHeader title={t('cards.businessCards.assignCardForm.header')} showBack={false} />
									</Grid>
									<Grid item xs={12}>


										<FormControl id="card" fullWidth>
											<InputLabel>{t('cards.businessCards.assignCardForm.card')}</InputLabel>
											<StyledSelect
												id="card"
												name="card"
												value={card?.cardId ?? ''}
												onChange={handleCardChange}
												renderValue={() => <div> {`${card.name} (${card?.number ? card.number.slice(-7).replaceAll('*', '•') : '•• ••••'})`}</div>}
											>
												{availableCards.length > 0 &&
													availableCards.map((c, idx) => {
														return (
															<MenuItem
																key={c.cardId + idx}
																value={c.cardId}>
																<div>
																	<div className='select-primary-Item'>{`${c.name} (${c?.number ? c.number.slice(-7).replaceAll('*', '•') : '•• ••••'})`} </div>
																</div>
															</MenuItem>
														);
													})}
											</StyledSelect>
										</FormControl>


									</Grid>
									<Grid item xs={12}>
										<FormControl id="employee" fullWidth>
											<InputLabel>{t('cards.businessCards.assignCardForm.employee')}</InputLabel>
											<StyledSelect
												id="employee"
												name="employee"
												value={employee?.employeeId ?? ''}
												onChange={handleEmployeeChange}
												renderValue={() => <div> {`${employee.firstName} ${employee.lastName}`}</div>}
											>
												{activeEmployees.length > 0 &&
													activeEmployees.map((emp, idx) => {
														return (
															<MenuItem
																key={emp.employeeId + idx}
																value={emp.employeeId}>
																<div>
																	<div className='select-primary-Item'>{`${emp.firstName} ${emp.lastName}`} </div>
																</div>
															</MenuItem>
														);
													})}
											</StyledSelect>
										</FormControl>
									</Grid>
								</Grid >
								<Grid container mt='auto' pt='2rem' columnSpacing={2}>
									<Grid item xs={6}>
										<Button
											className='button-left__wm'
											variant='customOutlined'
											color='secondary'
											onClick={closeModal}
											sx={{width: {xs: '100%', md: 'auto'}}}
										>  {t('form.buttons.close')}
										</Button>
									</Grid>
									<Grid item xs={6} container justifyContent='flex-end'>
										<Button
											disabled={isSubmitting || !isEmpty(errors) || employee === null || card === null}
											className='button-right__wm'
											variant='contained'
											color='primary'
											type='submit'
											sx={{width: {xs: '100%', md: 'auto'}}}
										>
											{t('cards.businessCards.assignCardForm.asignBtn')}
										</Button>
									</Grid>
								</Grid>
							</FormContentBox>
						</Form>
					);
				}}
			</Formik >}
		</Dialog >
	);
};

export default AssignCardForm;

