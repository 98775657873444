import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, SxProps, Theme, Typography } from '@mui/material';

const Concierge = () => {
	const { t } = useTranslation('translations');


	const bulletList = [
		{ title: t('benefits.conciergeSection.l1') },
		{ title: t('benefits.conciergeSection.l2') },
		{ title: t('benefits.conciergeSection.l3') },
		{ title: t('benefits.conciergeSection.l4') },
		{ title: t('benefits.conciergeSection.l5') },
		{ title: t('benefits.conciergeSection.l6') }];

	const stepList = [
		{
			title: t('benefits.conciergeSection.o1.title'),
			steps: [{
				title: t('benefits.conciergeSection.o1.l1'),
				details: t('benefits.conciergeSection.o1.l1Details')
			}]
		},
		{
			title: t('benefits.conciergeSection.o2.title'),
			steps: [{
				title: t('benefits.conciergeSection.o2.l1'),
				details: t('benefits.conciergeSection.o2.l1Details')
			},
			{
				title: t('benefits.conciergeSection.o2.l2'),
				details: t('benefits.conciergeSection.o2.l2Details')
			}]
		},
		{
			title: t('benefits.conciergeSection.o3.title'),
			steps: [{
				title: t('benefits.conciergeSection.o3.l1'),
				details: t('benefits.conciergeSection.o3.l1Details')
			},
			{
				title: t('benefits.conciergeSection.o3.l2'),
				details: t('benefits.conciergeSection.o3.l2Details')
			}]
		},
	];

	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography variant='h5' sx={styles.header}>{t('benefits.conciergeSection.title')}</Typography>
				<Typography sx={styles.p}>{t('benefits.conciergeSection.p1')}</Typography>
				<Box sx={styles.p}>
					{bulletList.map((item, idx) => <Typography key={idx} sx={styles.listItem}>{'\u2022'} {item.title}</Typography>)}
				</Box>
				<Typography variant='h5' sx={styles.header}>{t('benefits.conciergeSection.title2')}</Typography>
				{
					stepList.map((step, idx) => <Box key={idx}>
						<Typography fontWeight='bold' sx={styles.p}>{`${idx + 1} ${step.title}`}</Typography>
						{
							step.steps.map((s, i) => <Typography key={i} sx={styles.listItem}><strong>{'\u2022'} {s.title} </strong>{s.details}  </Typography>)
						}
					</Box>)
				}
				<Typography sx={styles.p}>{t('benefits.conciergeSection.p2')}</Typography>
			</Grid>
		</Grid>
	);
};

export default Concierge;

const styles: Record<string, SxProps<Theme>> = {
	header: {
		marginTop: 4,
		fontWeight: 'bold'
	},
	p: {
		marginTop: 2,
	},
	listItem: {
		marginLeft: 2,
	},
};
