import React from 'react'; 
import { Grid, IconButton  } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiFormText from '../../Shared/Widgets/MuiText/MuiFormText';
import { ReactComponent as MuiBack } from '@/assets/MuiBack.svg';
import { useHistory } from 'react-router-dom';


interface Props {
	onClick?: () => void
}

const BackButton = ({ onClick }: Props) => {
	const { t } = useTranslation('translations');
	const history = useHistory();
	return (
		<IconButton
			sx={{ padding: 0, display: { xs: 'none', sm: 'block' } }}

			onClick={() => onClick ? onClick() : history.goBack()}>
			<Grid container alignItems='center'>
				<Grid item xs={2}>
					<MuiBack height='1.188rem' />
				</Grid>
				<Grid item xs={10}>
					<MuiFormText pl='10px'>{t('form.buttons.back')}</MuiFormText>
				</Grid>
			</Grid>
		</IconButton>
	);
};

export default BackButton;
