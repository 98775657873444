import React, { useEffect } from 'react';
import { Button, Dialog, DialogContent, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { closeForm, openForm } from '@/features/forms/slice';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { isEmpty } from 'lodash';
import { disableAccount } from '@/features/security/slice';
import { showException } from '@/features/swal/slice';
import * as Yup from 'yup';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { useHistory } from 'react-router-dom';
import { logout } from '@/features/user/userSlice';
import FormPaperLarge from '@shared/Widgets/Dialog/FormPaperLarge';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';

interface Props {
	open: boolean,
	onClose: () => void
}

const LockAccountForm = ({ open, onClose }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const history = useHistory();

	const { t } = useTranslation('translations');

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);


	const closeModal = () => {
		dispatch(closeForm());
		onClose();
	};

	const initialValues = {
		password: ''
	};

	const validationSchema = Yup.object({
		password: Yup.string().required(t('form.validator.required'))
	});



	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		const { password } = formData;
		try {
			await disableAccount(password);
			closeModal();
			history.push('/auth');
			await dispatch(logout());
		} catch (e) {
			showException(e, [

				{
					key: 'invalid',
					title: 'swal.ERROR',
					message: 'swal.INVALID_OLD_PASSWORD'
				}
			], t('form.buttons.close'));
		} finally { setSubmitting(false); }
	};

	return (
		<Dialog
			id="lock-account-form"
			open={open}
			maxWidth='sm'
			PaperComponent={FormPaperLarge}
		>
			<DialogContent style={{ padding: 0, display: 'flex' }}>
				<Grid id='connect-business'
					container
					flex={1}
					direction='column'
					minHeight='0'
					flexWrap='nowrap'
					rowSpacing={2}>
					<Grid item>
						<FormHeader onBack={closeModal} title={t('security.options.closeAccountAction')} />
					</Grid>
					<Grid item>
						<MuiFormText>{t('security.options.closeAccountInfo')}</MuiFormText>
					</Grid>
					<Grid item flex={1}>
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={submit}
						>
							{({ errors, isSubmitting }) => (
								<Form style={{ width: '100%', height: '100%', display: 'flex' }}>
									<Grid container >
										<Grid item xs={12}>
											<MuiTextField
												fullWidth
												name="password"
												label={t('form.fields.password')}
												placeholder={t('form.fields.password')}
												type="password"
												InputProps={{
													autoComplete: 'off',
												}}
											/>
										</Grid>
										<Grid container item mt='auto' pt={8} columnSpacing={2} justifyContent='space-between'>
											<Grid item xs={6}>
												<Button variant="customOutlined"
													sx={{ width: { xs: '100%', md: 'auto' } }}
													onClick={closeModal}>
													{t('form.buttons.close')}
												</Button>
											</Grid>
											<Grid item xs={6} container justifyContent='flex-end'>
												<Button
													type="submit"
													variant="contained"
													sx={{ width: { xs: '100%', md: 'auto' } }}
													disabled={!isEmpty(errors) || isSubmitting}
												>
													{t('form.buttons.continue')}
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Form>
							)}
						</Formik>
					</Grid>
				</ Grid >
			</DialogContent>
		</Dialog >
	);
};

export default LockAccountForm;
