
import * as React from 'react';
import { useTheme } from '@mui/styles';
import { Box as MuiBox, Grid, Theme } from '@mui/material';
import MuiTextConfirmationLabel from '../Widgets/MuiText/MuiTextConfirmationLabel';

interface Props {
	label: string,
	children?: React.ReactNode,
}


const ConfirmationRow = (props: Props) => {

	const theme = useTheme() as Theme;

	return (<MuiBox
		sx={{
			borderRadius: theme.inputField.borderRadius,
			background: theme.palette.background.paper,
			padding: {xs: '1.25rem 1.25rem', sm: '1.25rem 2.625rem'},
			margin: '5px 0px'
		}}
	>
		<Grid container>
			<Grid item xs={12} sm={4}>
				<MuiTextConfirmationLabel> {props.label}</MuiTextConfirmationLabel>
			</Grid>
			<Grid item xs={12} sm={8}>
				{props.children}
			</Grid>
		</Grid>


	</MuiBox>);
};


export default ConfirmationRow;
