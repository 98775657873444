import './AppBarMain.css';
import { useDispatch, useSelector, } from 'react-redux';
import { AppBar, Badge, Grid, IconButton, Tab, TabProps, Tabs, Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import { styled } from '@mui/material/styles';
import React, { ElementType, useCallback, useEffect, useState } from 'react';
import { RootState } from '@/rootReducer';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getPathTabs } from '@helpers/tabs';
import { showErrorNotification } from '@/features/swal/slice';
import { getUnreadCount } from '@/features/tickets/slice';
import LanguageDropdown from '../LanguageDropdown/LanguageDropdown';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import './AppBarMain.css';
import Notifications from '../Dashboard/Notifications/Notifications';

const TabStyled = styled(Tab)<TabProps & { component: ElementType, to: string }>(({ theme }) => ({
	borderRadius: 0,
	'&.MuiButtonBase-root.Mui-selected': {
		backgroundColor: theme.palette.background.default,
		minHeight: '58px',
	},
	'&.MuiButtonBase-root': {
		minHeight: '54px',
	}
}));

const TabsStyled = styled(Tabs)({

	'& .MuiTabs-indicator': {
		display: 'none',
		borderRadius: 0,
		// height: '2px'
	}, '&.MuiTabs-root': {
		minHeight: '58px'
	},
});

interface Props {
	hideHeader: boolean,
}

const AppBarTabs = ({ hideHeader }: Props): React.ReactElement => {
	const theme = useTheme() as Theme;

	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const location = useLocation();
	const [openNotifications, setOpenNotifications] = useState<boolean>(false);
	const { unreadCount } = useSelector((state: RootState) => state.tickets);
	const { user } = useSelector((state: RootState) => state.user);
	const { list } = useSelector((state: RootState) => state.cards);
	const bankList = useSelector((state: RootState) => state.accounts.list);
	const searchParams = new URLSearchParams(location.search);
	const pathTab = searchParams.get('tab');

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getUnreadCount());
			} catch (e) {
				await showErrorNotification(e);
			}
		};
		get();
	}, [dispatch]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const tabsList = getPathTabs(location.pathname, t, user, list, bankList) || [];

	const currentTab = pathTab ? pathTab : (tabsList && tabsList[0]?.key || undefined);
	return (<>
		{!hideHeader && <AppBar
			position="static"
			sx={{ height: '54px', background: '#1D2024', boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)', }}
		>
			<Grid container alignItems='center' sx={{ height: '54px' }}>
				<Grid item xs={8}>
					{tabsList.length > 0 && currentTab &&
						<TabsStyled
							value={currentTab}
						>
							{tabsList.map(tab => (<TabStyled
								key={tab?.key}
								label={tab?.label}
								value={tab?.key}
								component={Link}
								disableRipple={true}
								to={tab.home + '?tab=' + tab.key}
							/>))}
						</TabsStyled>}
				</Grid>
				<Grid item container columnGap={2} xs={4} pr={2}
					alignItems="center"
					justifyContent="flex-end">
					<IconButton
						size="small"
						disableRipple={true}
						onClick={() => setOpenNotifications(!openNotifications)}
					>
						<Badge badgeContent={unreadCount} >
							<NotificationsOutlinedIcon sx={{ color: theme.palette.text.secondary }} />
						</Badge>
					</IconButton>
					<LanguageDropdown />
				</Grid>
			</Grid>
			<Grid id='notification-container'>
				{openNotifications &&
					<Notifications open={openNotifications} onClose={() => setOpenNotifications(false)} />}
			</Grid>
		</AppBar>}
	</>

	);
};
export default AppBarTabs;
