import * as React from 'react';

import { Dialog } from '@mui/material';

import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import RequestMoneyDetails, { initRequestMoneyPayload, RequestMoneyPayloadType } from './RequestMoneyDetails';
import RequestMoneyConfirmation from './RequestMoneyConfirmation';
import useCloseDialog from '@/helpers/customHook/useCloseDialog';
import { useDispatch } from 'react-redux';
import { openForm } from '@/features/forms/slice';
import FormPaperLargeFixed from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeFixed';

const RequestMoneyForm = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const [accountId] = useState<string>(searchParams.get('account'));
	const [contactId] = useState<string>(searchParams.get('contact'));
	const [requestMoneyPayload, setRequestMoneyPayload] = useState<RequestMoneyPayloadType>(initRequestMoneyPayload);
	const dispatch = useDispatch();

	const closeModal = useCloseDialog();

	const [step, setStep] = React.useState(1);
	const nextStep = React.useCallback(() => setStep(previousState => (previousState + 1)), []);
	const prevStep = React.useCallback(() => setStep(previousState => (previousState - 1)), []);

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);

	return (
		<Dialog
			id="requestMoneyForm"
			open={true}
			onClose={closeModal}
			PaperComponent={FormPaperLargeFixed}
		>
			{step === 1 && <RequestMoneyDetails
				accountId={parseInt(accountId)}
				contactId={parseInt(contactId)}
				payload={requestMoneyPayload}
				setPayload={setRequestMoneyPayload}
				nextStep={nextStep} />
			}
			{
				step === 2 &&
				<RequestMoneyConfirmation
					payload={requestMoneyPayload}
					prevStep={prevStep} />}
		</Dialog >
	);
};


export default RequestMoneyForm; 
