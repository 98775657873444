import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BankAccount } from '../account/types';
import { Card } from '../card/types';
import { Vault } from '../earn/types';
import { Token } from '../tokens/types';
import { CoinType } from '@features/coins/types';

interface State {
	currentAccount: BankAccount | Card,
	currentVault: Vault,
	currentWallet: Token,
	currentCoin: CoinType,
}

const initialState: State = {
	currentAccount: null,
	currentVault: null,
	currentWallet: null,
	currentCoin: null
};

const stateSlice = createSlice({
	name: 'tabs',
	initialState,
	reducers: {
		setAccount(state, action: PayloadAction<BankAccount | Card>) {
			state.currentAccount = action.payload;
		},
		setVault(state, action: PayloadAction<Vault>) {
			state.currentVault = action.payload;
		},
		setWallet(state, action: PayloadAction<Token>) {
			state.currentWallet = action.payload;
		},
		setCoin(state, action: PayloadAction<CoinType>) {
			state.currentCoin = action.payload;
		}
	}
});

export const { setAccount, setVault, setWallet, setCoin} = stateSlice.actions;

export const stateSelector = (state: { stateStore: State }) =>
	state.stateStore;

export default stateSlice.reducer;
