import { Divider } from '@mui/material';
import { withStyles } from '@mui/styles';


const LightDivider = withStyles((theme) => ({
	root: {
		borderColor: (theme.palette.text.secondary),
		marginTop: '1rem',
		marginBottom: '1rem',
		borderBottomWidth: '1px',
		boder: 'unset',
		opacity: 0.1
	}
}))(Divider);

export default LightDivider;
