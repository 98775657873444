import * as React from 'react';

import { Button, Dialog, Grid, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCloseDialogGoCards } from '@/helpers/customHook/useCloseDialog';
import DialogContentNoScrollbar from '@/componentsMui/Shared/Widgets/Dialog/DialogContentNoScrollbar';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { isEmpty } from 'lodash';
import { runOperation } from '@/features/operations/slice';
import { showException, showSuccess } from '@/features/swal/slice';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';


interface Props {
	cardId: string,
	isVirtual: boolean,
	operation: string
}
const InactiveCardDigitsForm = ({ cardId, isVirtual, operation }: Props) => {

	const { t } = useTranslation('translations');
	const closeModal = useCloseDialogGoCards();

	const [code, setCode] = React.useState<string>('');

	const initialValues = { panLast6digits: '' };
	const validationSchema = Yup.object({
		panLast6digits: Yup.string().trim().min(6, t('form.validator.codeSixDigits')).max(6, t('form.validator.codeSixDigits'))
	});


	const activate = async (panLast6digits: string) => {
		try {
			const payload = { cardId, panLast6digits };
			await runOperation(operation, payload);
			await showSuccess({
				title: 'cards.cardActivation.activated', mui: true
			});
		} catch (e) {
			await showException(e, null, t('form.buttons.close'));
		}
		finally { closeModal(); }
	};


	const onSubmit = (formData) => {
		const { panLast6digits } = formData;
		activate(panLast6digits);
	};

	const handleChange = (e, setFieldValue) => {
		const newValue: string = e.target.value;
		const re = /^[0-9]+$/;
		if (newValue === '' || re.test(newValue)) {
			setCode(newValue);
			setFieldValue('panLast6digits', newValue);
		}
	};

	return (
		<Dialog
			id="inactive-card-form"
			open={true}
			onClose={closeModal}
			fullWidth
			maxWidth='sm'>
			<DialogContentNoScrollbar>
				<Formik
					enableReinitialize={true}
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
				>
					{({ errors, isSubmitting, dirty, setFieldValue, setFieldTouched }) => {
						return (
							<Form>
								<Grid
									container
									padding='2.5rem'
									spacing={3}
								>
									<Grid item xs={12} container justifyContent='space-between' alignItems='center' >
										<Grid item>
											<MuiHeader pb={2}> {t('cards.inactive.modal.header')}</MuiHeader>
										</Grid>
										<Grid item>
											<IconButton onClick={closeModal}  >
												<MuiCloseIcon />
											</IconButton>
										</Grid>
									</Grid>
									<Grid item xs={12} >
										<MuiFormText display='inline'>
											{t(isVirtual ? 'cards.inactive.modal.activateVirtualInstruction' : 'cards.inactive.modal.activateInstruction')}
										</MuiFormText>
										{/* <MuiTextInfo display='inline' color='text.primary'> &nbsp;{user.mobileNumber}</MuiTextInfo>
										<MuiTextInfo display='inline'>. {t('cards.inactive.modal.activateInstructionEnd')}
										</MuiTextInfo> */}
									</Grid>
									<Grid item xs={12}>
										<MuiTextField
											name="panLast6digits"
											inputProps={{ autoComplete: 'off', maxLength: 6 }}
											label={t('cards.inactive.modal.activationCode')}
											fullWidth
											onChange={(e) => { handleChange(e, setFieldValue); setFieldTouched('panLast6digits'); }}
											value={code}
										/>
									</Grid>
									<Grid item xs={12}>
										<Button
											fullWidth
											key='activate'
											type='submit'
											disabled={isSubmitting || !isEmpty(errors) || !dirty}
											variant='contained'> 	{t('form.buttons.submit')}
										</Button>
									</Grid>
								</Grid>
							</Form>
						);
					}}
				</Formik >
			</DialogContentNoScrollbar>
		</Dialog >
	);
};


export default InactiveCardDigitsForm; 
