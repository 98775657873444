import React from 'react';
import { Grid, ListItem } from '@mui/material';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import { useTranslation } from 'react-i18next';
import './FileUploadLink.css';
import MuiTextFieldInfo from '../../Widgets/MuiText/MuiTextFieldInfo';
import GradientBorderBox from '../../Widgets/Box/GradientBorderBox'; 
import MuiFormText from '../../Widgets/MuiText/MuiFormText';

interface Props {
	acceptedFiles?: string,
	selectedFiles: any[],
	label?: string,
	uploadFiles: (fles: any) => void,
	deleteAttachment: (index: number) => void
}
export const inputAcceptedFiles = 'image/jpg, image/png, image/jpeg, .pdf, .doc, .docx';


const FileUploadBox = (props: Props) => {

	const { t } = useTranslation('translations');

	const hiddenFileInput = React.useRef(null);
	const handleUploadClick = () => {
		hiddenFileInput.current.click();
	};

	return (
		<Grid container
			alignItems="center"
			justifyContent='space-between' 
			className='file-drop-box' >
			<Grid item xs={12}>
				<GradientBorderBox
					onClick={handleUploadClick}
					height='2.625rem'
					display='flex'
					justifyContent='center'
					alignItems='center'>
					<MuiFormText >{props.label ?? t('form.fields.selectFile')} </MuiFormText>
				</GradientBorderBox>
			</Grid>
			<Grid item xs={6}>
				<input type='file'
					multiple
					accept={props.acceptedFiles ?? inputAcceptedFiles}
					name='files'
					ref={hiddenFileInput}
					onChange={e => {
						props.uploadFiles(e.currentTarget.files);
						e.currentTarget.value = '';
					}}
					style={{ display: 'none' }}
				/>
				{props.selectedFiles.length > 0 && (
					<Grid item>
						{props.selectedFiles.map((file, index) =>
							<ListItem key={index} className='fileList'>
								<MuiTextFieldInfo key={index} variant='highlight'> {file.name} </MuiTextFieldInfo>
								<MuiCloseIcon width='16px' height='16px'
									onClick={() => {
										props.deleteAttachment(index);
									}} />

							</ListItem>
						)}
					</Grid>
				)}
			</Grid>


		</Grid>);
};

export default FileUploadBox;
