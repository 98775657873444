import * as React from 'react';
import { useEffect } from 'react';
import { Dialog } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { closeForm, openForm } from '@/features/forms/slice';
import { hasSubprocessWithType } from '@/features/operations/slice';
import { TicketNotification } from '@/features/tickets/types';
import SendMoneyDetails, { initSendMoneyPayload, SendMoneyPayloadType } from './SendMoneyDetails';
import '@/componentsMui/Shared/Shared.css';
import SendMoneyConfirmation from './SendMoneyConfirmation';
import LoadingPageMui from '@/pages/LoadingPageMui';
import FormPaperLargeFixed from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeFixed';
import { getAccounts } from '@/features/account/slice';


interface Props {
	notification: TicketNotification,
	open: boolean,
	onClose: () => void
}

const SendMoneyReplyForm = ({ notification, open, onClose }: Props) => {

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAccounts());
	}, [dispatch]);

	const { list: allAccounts, loading } = useSelector((state: RootState) => state.accounts);
	const paymentAccountList = allAccounts ?
		allAccounts.filter(accounts => hasSubprocessWithType('TRANSFER', accounts.subProcesses))
		: [];

	const [payload, setPayload] = React.useState<SendMoneyPayloadType>(initSendMoneyPayload);

	const [step, setStep] = React.useState<number>(0);
	const nextStep = React.useCallback(() => setStep(previousState => (previousState + 1)), []);
	const prevStep = React.useCallback(() => setStep(previousState => (previousState - 1)), []);


	const closeModal = () => { dispatch(closeForm()); onClose(); };

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);

	return (
		<Dialog
			id="send-money-reply"
			open={open}
			onClose={closeModal}
			PaperComponent={FormPaperLargeFixed}
		>
			{loading ?
				<LoadingPageMui /> :
				<>
					{step === 0 &&
						<SendMoneyDetails
							paymentAccountList={paymentAccountList}
							sendMoneyPayload={payload}
							setSendMoneyPayload={setPayload}
							notification={notification}
							nextStep={nextStep}
							onCancel={closeModal}
						/>}
					{step === 1 &&
						<SendMoneyConfirmation
							payload={payload}
							prevStep={prevStep}
							onComplete={() => { closeModal(); setStep(0); }}
						/>}
				</>}

		</Dialog >
	);
};
export default SendMoneyReplyForm;

