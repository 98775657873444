import * as React from 'react';

import '@/componentsMui/Shared/Shared.css';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { addContact, lookupByUserTag } from '@/features/contacts/slice';
import { useDispatch } from 'react-redux';
import { getErrorWithParams, showException, showSuccess } from '@/features/swal/slice';
import { ContactPaymentTypes } from '@/features/contacts/types';
import { isEmpty } from 'lodash';
import MuiTextTrx from '@/componentsMui/Shared/Widgets/MuiText/MuiTextTrx';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import FormContentBox from '@/componentsMui/Shared/Widgets/Box/FormContentBox';

interface Props {
    onBack: () => void;
}

const CreateInternalContact = ({ onBack }: Props) => {

    const { t } = useTranslation('translations');
    const dispatch = useDispatch();
    const closeModal = useCloseDialogNoEvent();


    const validationSchema = Yup.object({
        name: Yup.string().required(t('form.validator.required')),
        userTag: Yup.string().required(t('form.validator.required'))
    });

    const submit = async (formData, formikProps) => {
        const { setSubmitting, setFieldError } = formikProps;
        const { name } = formData;
        const userTag = formData?.userTag?.toUpperCase().trim();
        try {

            try {
                await lookupByUserTag(userTag);
            } catch (e) {
                const err = getErrorWithParams(e);
                if (err && err.error === 'notFound' && err.error_param === 'userTag') {

                    setFieldError('userTag', t('sendmoneyTranslation.data.userNotFound'));
                } else if (err && err.error === 'your' && err.error_param === 'userTag') {
                    setFieldError('userTag', t('sendmoneyTranslation.data.belongsToYou'));
                } else {
                    setFieldError('userTag', t('errors.SOMETHING_WENT_WRONG_WITHOT_MESSAGE'));
                }
                return;
            }

            await dispatch(addContact({ name, clientId: userTag, paymentType: ContactPaymentTypes.LOCAL, userTag }));
            showSuccess({ title: 'contacts.create.success', mui: true });
            // dispatch(getContacts());
            closeModal();
        } catch (e) {
            showException(e);
            closeModal();
        }
        finally {
            setSubmitting(false);
        }
    };

    const initialValues = {
        name: '',
        tag: ''
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submit}

        >{({ errors, isSubmitting, dirty }) => {
            return (
                <Form className='form-content__wrap__bb'>
                    <FormContentBox noBackground={true} className='form-content_main-area wp'>
                        <Grid container sx={{ height: '100%' }} >
                            <Grid id="contentWrap"
                                item
                                spacing={2}
                                container
                                alignSelf="flex-start" >
                                <Grid item xs={12}  >
                                    <MuiTextTrx marginTop="0.5rem" >{t('contacts.create.walletText')}</MuiTextTrx>
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        name="name"
                                        fullWidth
                                        label={t('contacts.create.name')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        name="userTag"
                                        fullWidth
                                        label={t('contacts.create.userTag')}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container mt='auto' pt='2rem' columnSpacing={2}>
                                <Grid item xs={6} >
                                    <Button
                                        variant='customOutlined'
                                        color='secondary'
                                        onClick={onBack}
                                        sx={{ width: { xs: '100%', md: 'auto' } }}
                                    >  {t('form.buttons.back')}
                                    </Button>
                                </Grid>
                                <Grid item xs={6} container justifyContent='flex-end'>
                                    <Button disabled={isSubmitting || !isEmpty(errors || !dirty)}
                                        key="buttonNext"
                                        variant='contained'
                                        color='primary'
                                        type="submit" >
                                        {t('contacts.create.save')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </FormContentBox>
                </Form>
            );
        }}
        </Formik >
    );
};

export default CreateInternalContact;
