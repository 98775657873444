import { Card, CardFeeType, CardSubProcesses } from '@/features/card/types';
import { findProcessByType, runOperation } from '@/features/operations/slice';
import { showErrorNotification } from '@/features/swal/slice';
import { getFeeDetails, sumCreationFees, sumMonthlyFees } from '@/features/fees/slice';
import { t } from '@/helpers/translate';
import { FeesFimitsType } from '@/features/fees/types';

export const getAccount = (list: any, curentAccountId?: number) => {
	let newAccount = null;
	if (!list.length) {
		newAccount = null;
	}
	if (!curentAccountId) {
		newAccount = list[0];
		return newAccount;
	}
	const account = list.find(account => account.accountId === curentAccountId);

	if (!account && list.length > 0) {
		newAccount = list[0];
	}

	if (!account) return newAccount;

	newAccount = JSON.parse(JSON.stringify(account));
	return newAccount;

};


export const activateCard = async (cardId: number, activateProc: string, virtual: boolean, history: any, needVfCode: boolean, status: string) => {
	if (virtual === undefined) return;
	if ((virtual && !needVfCode) || status === 'SUSPENDED') {
		try {
			if (activateProc && cardId) {
				await runOperation(activateProc, { cardId: cardId });
			}
		}
		catch (e) {
			showErrorNotification(e);
		}
	}
	else {
		if (cardId) {
			history.push(`/wallet/home/cards/activate?card=${cardId}`);
		}
	}
};

export const isReplaceable = (card: Card) => {
	const cardReplaceProcess = findProcessByType(card?.process, CardSubProcesses.REPLACE_CARD);
	return cardReplaceProcess?.proc && card?.eligibleForReplacement;
};



export const getCardFee = (processFee: any, feeType: CardFeeType, t: any) => {

	if (processFee) {
		// eslint-disable-next-line no-prototype-builtins
		const purchase = processFee && processFee.hasOwnProperty(feeType) && processFee[feeType].length > 0 ?
			processFee[feeType][0] :
			null;

		const details = purchase ? getFeeDetails(purchase, t) : undefined;
		return details;
	}
	else {
		undefined;
	}
};


export interface CardOrderFees {
	montlyFee: string,
	creationFee: string,
	topUpFee: string,
	paymentFee: string,
	onlinePaymentFee: string,
	atmWithrawalEu?: string,
	atmWithrawalWorld?: string,
	montlyFeeAmount: number,
	creationFeeAmount: number,

}

export const getCardOrderFees = (cardProcess: string, allCardFees: FeesFimitsType[], isReplacement: boolean) => {

	const freeText = t('fees.myFees.free');
	const process = cardProcess;
	const processFee = process && allCardFees ? allCardFees[process] : null;
	const creationCardFee = getCardFee(processFee, isReplacement ? CardFeeType.REPLACE_CARD : CardFeeType.CREATE_CARD, t);
	const montlyFee = creationCardFee?.montlyText || freeText;
	const creationFee = creationCardFee?.feeText || freeText;
	const topUpFee = getCardFee(processFee, CardFeeType.CARD_TOP_UP, t)?.feeText || freeText;
	const paymentFee = getCardFee(processFee, CardFeeType.PURCHASE, t)?.feeText || freeText;
	const onlinePaymentFee = getCardFee(processFee, CardFeeType.PURCHASE_ONLINE, t)?.feeText || freeText;
	const atmWithrawalEu = getCardFee(processFee, CardFeeType.ATM_WITHDRAWAL_EU, t)?.feeText;
	const atmWithrawalWorld = getCardFee(processFee, CardFeeType.ATM_WITHDRAWAL_WORLD, t)?.feeText;

	const createProcessFees = processFee && processFee[CardFeeType.CREATE_CARD];
	const replaceProcessFees = processFee && processFee[CardFeeType.REPLACE_CARD];
	const montlyFeeAmount = createProcessFees ? sumMonthlyFees(createProcessFees) : 0;
	const creationFeeAmount = isReplacement ?
		(replaceProcessFees ? sumCreationFees(replaceProcessFees) : 0) :
		(createProcessFees ? sumCreationFees(createProcessFees) : 0);


	return {
		montlyFee, creationFee, topUpFee, onlinePaymentFee, paymentFee, atmWithrawalEu, atmWithrawalWorld,
		montlyFeeAmount, creationFeeAmount
	};

};
