import React from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Transaction } from '../TransactionStatements.spec';
import DetailLine from '@shared/Widgets/DetailLine';

interface Props {
	details: Transaction
}

const Transfer = ({ details }: Props) => {
	const { t } = useTranslation('translations');

	return (
		<Grid container>
			<Grid item xs={12}>
				<DetailLine
					label={t('transactiondetails.details.number')}
					value={details.transactionNumber}
					first
				/>
			</Grid>
		</Grid>
	);
};

export default Transfer;
