import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Button, FormHelperText, Grid, IconButton, useMediaQuery } from '@mui/material';
import { next, reject2FA, set2FALoading, set2FAMessage } from '@/features/2fa/slice';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import LogoThemed from './Shared/Widgets/LogoThemed';
import MuiBigHeader from './Shared/Widgets/MuiText/MuiBigHeader';
import { Form, Formik, useFormikContext } from 'formik';
import MuiTextField from './Shared/FormikComponents/MuiTextField';
import { isEmpty } from 'lodash';
import './ConfirmCodes.css';
import Spinner from './Shared/Widgets/Spinner/Spinner';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import MuiFormText from './Shared/Widgets/MuiText/MuiFormText';
import SecurityIcon from '@mui/icons-material/Security';
import DialogWithBg from './Shared/Widgets/Dialog/DialogWithBg';
import FormPaperFullScreenWide from './Shared/Widgets/Dialog/FormPaperFullScreenWide';

const AutoSubmit = () => {
	const { values, submitForm } = useFormikContext<{
		authenticatorCode: string
	}>();
	React.useEffect(() => {
		if (values?.authenticatorCode?.length === 6) {
			submitForm();
		}
	}, [values, submitForm]);
	return null;
};

const TwoFAWeb = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const [error, setError] = useState<string>();
	const { open, message, loading } = useSelector(
		(state: RootState) => state.twoFA
	);
	const veryShort = useMediaQuery('(max-height:500px)');
	const initialValues = {
		authenticatorCode: ''
	};

	const validationSchema = Yup.object({
		authenticatorCode: Yup.string().matches(/^\d{6}$/, t('errors.SIX_DIGITS_REQUIRED'))
	});

	const confirm = async (formData, formikProps) => {
		const { setSubmitting, resetForm } = formikProps;
		setError(null);
		try {
			const { authenticatorCode } = formData;
			dispatch(set2FAMessage(null));
			dispatch(set2FALoading(true));
			dispatch(next(authenticatorCode));
		}
		catch (e) {
			setSubmitting(false);
			setError(e.message);
		} finally {
			resetForm({});
		}
	};

	const handleCancel = () => {
		dispatch(reject2FA());
	};

	return (
		<DialogWithBg open={open} maxWidth='xs' className={`dialog ${loading ? 'loading' : ''}`}
			PaperComponent={FormPaperFullScreenWide}
		>
			{loading && <div className={'loading-spinner'}><Spinner dialog={false} /></div>}
			<Grid container justifyContent='center' position='relative' pt={1}>
				<LogoThemed style={{
					height: '3.5rem',
					width: 'auto',
					maxWidth: '120px',
					marginTop: '2rem'
				}} />
				<IconButton disabled={loading} onClick={handleCancel} className='closeIcon' >
					<MuiCloseIcon />
				</IconButton>
			</Grid>
			<Grid container
				spacing={2}
				alignItems='center'
				justifyContent='center'
				rowGap={3}
				p={{ xs: '0px', sm: '1rem 3rem 2rem 3rem' }}
			>{!veryShort &&
				<Grid item xs={12} >
					<MuiBigHeader textAlign='center' fontSize='1.625rem'>{t('form.twofa.header')} </MuiBigHeader>
				</Grid>
				}
				{!veryShort &&
					<Grid item xs={12} container justifyContent='center'>
						<SecurityIcon className='mainIcon' sx={{ color: 'text.primary' }} />
					</Grid>}
				<Grid item xs={12} >
					<MuiFormText textAlign='center'>{t('form.twofa.digitp')}</MuiFormText>
				</Grid>
				<Grid item xs={12}>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={confirm}
					>
						{({ errors }) => {
							return (
								<Form style={{ width: '100%' }} >
									<AutoSubmit />
									<Grid item xs={12} container rowGap={1}>
										<Grid item xs={12} sx={{ position: 'relative' }}>
											<MuiTextField
												name="authenticatorCode"
												fullWidth
												autoFocus={true}
												disabled={loading}
												inputProps={{ autoComplete: 'off' }}
												placeholder={t('form.twofa.placeholder')}
												label={<div className='labelWrap'><SecurityIcon className='labelIcon' sx={{ color: 'text.primary' }} />{t('form.twofa.label')}</div>} />
											<Grid item xs={12} container justifyContent='right' sx={{ p: 0 }}>
												{isEmpty(errors) && message &&
													<FormHelperText> {message} </FormHelperText>
												}
												{error && <FormHelperText> {error} </FormHelperText>}
											</Grid>
										</Grid>
									</Grid>
								</Form>);
						}}
					</Formik>
				</Grid>
				<Grid item xs={12}>
					<Button
						variant='customOutlined'
						fullWidth
						disabled={true}
						onClick={() => confirm}
					>
						{t('form.buttons.confirm')}
					</Button>
				</Grid>
			</Grid >
		</DialogWithBg >
	);
};

export default TwoFAWeb;

