import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppThunk } from '../../store';
import { Disclaimer, DisclaimerStore, UiSectionEnum } from './disclaimerTypes';

const initialState: DisclaimerStore = {
	list: [],
	loading: false
};

const slice = createSlice({
	name: 'disclaimer',
	initialState,
	reducers: {
		setDisclaimers(state, action: PayloadAction<Array<Disclaimer>>) {
			state.list = action.payload;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
	}
});

export const { setDisclaimers, setLoading } = slice.actions;

export const getAcceptedDisclaimers = (): AppThunk => {
	return async dispatch => {
		try {
			setLoading(true);
			const response = await request.get('/api/disclaimer');
			dispatch(setDisclaimers(response?.data || []));
		} catch (e) {
			dispatch(setDisclaimers([]));
		}
		finally {
			setLoading(false);
		}
	};
};

export const saveDisclaimerAcceptance = async (disclaimerCode: string, uiSection: UiSectionEnum) => {
	const response = await request.post('/api/disclaimer/accept', { disclaimerCode, uiSection });
	const { data } = response;
	return data;
};

export default slice.reducer;
