import React from 'react';

import { Grid } from '@mui/material';
import BackButton from './BackButton';


interface Props {
	onClick?: () => void
}

const BackButtonWebLanding = ({ onClick }: Props) => {
	return (<Grid container width='100%'
		justifyContent='flex-end'
		paddingRight='40px'>
		<BackButton onClick={onClick} />
	</Grid>);
};

export default BackButtonWebLanding;
