
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DectaCardTransactionsPayload } from '@/features/transactions/types';
import { getDectaCardTransactions } from '@/features/transactions/slice';
import { DataGrid, GridPaginationModel, useGridApiRef } from '@mui/x-data-grid';
import { Grid, useMediaQuery } from '@mui/material';
import { showErrorNotification } from '@/features/swal/slice';
import TransactionDetailsForm from '../Transactions/Details/TransactionDetailsForm';
import { Transaction } from '../Transactions/TransactionStatements.spec';
import { transactionDetailsEnabled } from '@/helpers/transactionUtils';
import { closeForm, openForm } from '@/features/forms/slice';
import Spinner from '../Shared/Widgets/Spinner/Spinner';
import { AccountType } from '@/features/operations/types';
import { BankAccount } from '@/features/account/types';
import { getMobileTrxGridColumns, getTrxGridColumns } from './Components/TransactionColumns';
import TrxGenericError from './Components/TrxGenericError';
import CardTransactionsOnhold from './CardTransactionsOnhold';
import MuiHeader from '../Shared/Widgets/MuiText/MuiHeader';
import { useTranslation } from 'react-i18next';
import { CardIntegrationEnum } from '@/features/card/types';
import SearchField from '../Shared/Widgets/SearchField';

interface Props {
    account: BankAccount
}

const CardTransactionGrid = ({ account }: Props) => {

    const { t } = useTranslation('translations');
    const type = AccountType.CARD;
    const dispatch = useDispatch();
    const apiRef = useGridApiRef();
    const smallScreen = useMediaQuery('(max-width:600px)');

    const [loadingError, setLoadingError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingTrx, setLoadingTrx] = useState<boolean>(false);
    const [openTransactionDetails, setOpenTransactionDetails] = useState<boolean>(false);
    const [selectedTrx, setSelectedTrx] = useState<Transaction>();
    const [totalRows, setTotalRows] = useState<number>(0);

    const [transactions, setTransactions] = useState<any[]>([]);
    const [search, setSearch] = useState<string>('');

    const hideDetails = account.process.integration === CardIntegrationEnum.SH_FINANCE;

    const dectaPayload: DectaCardTransactionsPayload = useMemo(() => {
        const params: DectaCardTransactionsPayload = {
            take: 50,
            accountId: account.accountId,
            initial: false,
            skip: 0,
        };
        return params;
    }, [account?.accountId]);

    const columns = useMemo(() => smallScreen ?
        getMobileTrxGridColumns(type, account?.subProcesses) :
        getTrxGridColumns(type, account?.subProcesses, hideDetails), [type, account?.subProcesses, smallScreen, hideDetails]);


    const handleSearchRequest = (e) => {

        const regex = /^[ A-Za-z0-9-.,&/_?!]*$/;
        if (regex.test(e.target.value)) {
            setSearch(e.target.value);
            const payload = { ...dectaPayload, filter: e.target.value };
            const currentPage = apiRef.current.state.pagination.paginationModel.page;

            if (currentPage === 0) {
                fetch(payload);
            } else {
                // This triggers call onPaginationModelChange
                setTimeout(() => apiRef.current.setPage(0));
            }

        }
    };



    const fetch = useCallback(async (newPayload: DectaCardTransactionsPayload) => {
        try {
            setLoadingError(false);
            setLoadingTrx(true);
            const result = await (getDectaCardTransactions(newPayload));
            setTransactions(result?.list);
            setTotalRows(result.count);
        }
        catch (e) {
            setLoadingError(true);
            setTransactions([]);
        }
        finally { setLoadingTrx(false); }
    }, []);


    const onPaginationModelChange = async (model: GridPaginationModel) => {
        try {
            //call page from grid model  
            const newPayload = { ...dectaPayload, filter: search, take: model.pageSize, skip: model.page };
            fetch(newPayload);

        }
        catch (e) {
            showErrorNotification(e);
        }
    };


    const handleOnCellClick = (params) => {
        const trx = params.row;
        if (transactionDetailsEnabled(trx.activity) && params.field !== 'status') {
            dispatch(openForm());
            setOpenTransactionDetails(true);
            setSelectedTrx(trx);
        }
    };

    const closeTransactionDetailsForm = React.useCallback(() => {
        setOpenTransactionDetails(false);
        dispatch(closeForm());
    }, [dispatch]);


    return (<>

        <TransactionDetailsForm open={openTransactionDetails}
            onClose={closeTransactionDetailsForm}
            transaction={selectedTrx}
            account={account}
            type={type}
            setLoading={(value: boolean) => setLoading(value)}
        />
        {loading && <Grid container justifyContent='center' mt={2}><Spinner /></Grid >}
        <Grid container>
            <Grid item xs={12}>
                <CardTransactionsOnhold account={account}
                    columns={columns}
                    handleOnCellClick={handleOnCellClick} />
            </Grid>
            <Grid item xs={12} container mt={2} gap={2} alignItems='center'>
                <Grid item xs>
                    <MuiHeader>{t('transaction.header.transactions')}</MuiHeader>
                </Grid>
                <Grid item xs='auto'>
                    <SearchField
                        id='search'
                        name='search'
                        value={search}
                        onChange={(e) => handleSearchRequest(e)}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}
                sx={{
                    height: '60vh',
                    width: '100%'
                }}
            >
                {loadingError ? <TrxGenericError onButtonClick={() => { fetch(dectaPayload); }}
                /> :
                    <DataGrid
                        autoPageSize={true}
                        pagination
                        apiRef={apiRef}
                        rows={transactions}
                        rowCount={totalRows}
                        loading={loadingTrx}
                        getRowId={(row) => `${row.transactionNumber}-${transactions.length}`}
                        onPaginationModelChange={onPaginationModelChange}
                        onCellClick={handleOnCellClick}
                        paginationMode="server"
                        filterMode="server"
                        sortingMode="server"
                        disableRowSelectionOnClick={true}
                        columns={columns}
                        /*   slots={{
                              pagination: GridPaginationArrows,
                          }} */
                        sx={{
                            // pointer cursor on ALL rows
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer'
                            }
                        }}
                    />
                }
            </Grid>

        </Grid>

    </>);

};

export default CardTransactionGrid;

