import * as React from 'react';
import { Autocomplete, Box, FormHelperText, InputAdornment, TextField } from '@mui/material';
import { alpha2ToAlpha3, alpha3ToAlpha2, getNames } from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';
import StyledPaper from '@/componentsMui/Shared/Widgets/StyledPaper';


export interface CountryCodeType {
	label: string,
	code: string,
}

interface Props {
	name: string,
	countryCode: string,
	error?: string,
	label?: string,
	onChange?: (value: any) => void,
	readonly?: boolean
}


const CountryCodesAlpha3 = ({ name, countryCode, label, error, onChange, readonly }: Props) => {
	const { t } = useTranslation(['translations']);
	const countries = getNames('en');
	const countryOptions = Object.keys(countries)?.map(p => { return ({ label: countries[p], code: alpha2ToAlpha3(p) } as CountryCodeType); });
	const handleCountryChange = (event: any, newValue: any) => {
		onChange(newValue.code);
	};

	const value = countryOptions.find(p => p.code === countryCode) ?? { label: '', code: '' };

	return (
		<Autocomplete
			readOnly={readonly}
			id='country-codes-alpha2'
			options={countryOptions}
			value={value}
			onChange={handleCountryChange}
			disableClearable
			fullWidth
			sx={{ width: '100%' }}
			isOptionEqualToValue={(option, value) => value === undefined || value.code === '' || option?.code === value?.code}
			PaperComponent={({ children }) => (
				<StyledPaper >{children}</StyledPaper>
			)}
			renderInput={(params) => <>
				<TextField fullWidth name={name} {...params} label={label ?? t('form.fields.country')}
					InputProps={{
						...params.InputProps,
						startAdornment: (value?.code ?
							<InputAdornment position="start" sx={{ height: '100%' }}>
								<img
									loading="lazy"
									src={`https://flagcdn.com/w20/${alpha3ToAlpha2(value.code).toLowerCase()}.png`}
									alt=""
								/>
							</InputAdornment> : <></>
						),
					}} />
				{error && <FormHelperText>{error}</FormHelperText>}
			</>}
			renderOption={(props, option) => (
				<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
					<img
						loading="lazy"
						width="20"
						src={`https://flagcdn.com/w20/${alpha3ToAlpha2(option.code).toLowerCase()}.png`}
						srcSet={`https://flagcdn.com/w40/${alpha3ToAlpha2(option.code).toLowerCase()}.png 2x`}
						alt=""
					/>
					{option.label}
				</Box>
			)}

		/>);
};


export default CountryCodesAlpha3;
