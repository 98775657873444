import React from 'react';
import { useTranslation } from 'react-i18next';
import { BankAccount } from '@/features/account/types'; 
import DetailsBox from '@/componentsMui/Shared/Widgets/DetailsBox';
import DetailLine from '@/componentsMui/Shared/Widgets/DetailLine';

interface Props {
	account: BankAccount
}

const AccountDetailsUSD = ({ account }: Props): React.ReactElement => {

	const { t } = useTranslation('translations');
	return (
		<DetailsBox>
			<DetailLine label={t('wallet.beneficiary.name')} value={account?.bankName} first />
			<DetailLine label={t('wallet.beneficiary.beneficiaryAddress')} value={account?.bankAddress} />
			<DetailLine label={t('wallet.beneficiary.accountNumber')} value={account?.recipientAccount} />
			<DetailLine label={t('wallet.beneficiary.bank')} value={account?.immediateBankName} /> 
			<DetailLine label={t('wallet.beneficiary.bankCountry')} value={account?.usBankCountry} />
			<DetailLine label={t('wallet.beneficiary.bankAddress')} value={account?.usBankAddress} />  
			<DetailLine label={t('wallet.beneficiary.swift')} value={account?.swift} />
			<DetailLine label={t('wallet.deposit.sepaConfirmation.paymentDetails')} value={(account.currency === 'USD' ?
				`${account.iban}, ${account.recipient}, ${account.walletId}`
				: account.walletId)} warningTooltip={t('wallet.deposit.fieldInfo.reference')} />
		</DetailsBox>
	);
};

export default AccountDetailsUSD;
