import React from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Transaction } from '../TransactionStatements.spec';
import DetailLine from '@shared/Widgets/DetailLine';
import { TransactionType } from '@/features/transactions/types';


interface Props {
	details: Transaction,
}

const TransferOutgoing = ({ details }: Props) => {
	const { t } = useTranslation('translations');

	return (
		<Grid container>
			<DetailLine
				label={t('transactiondetails.main.status')}
				value={t('status.' + details.status)}
			/>
			{(details?.remitterAccount || details?.remitterAccountNumber) &&
				<DetailLine
					label={t('transactiondetails.remitter.fromAccount')}
					value={`${details?.remitterAccount ?? details?.remitterAccountNumber}`}
				/>
			}
			<DetailLine
				label={t('transactiondetails.remitter.name')}
				value={`${details.remitterName}`}
			/>
			{
				details?.cardNumberMask &&
					TransactionType.PURCHASE !== details?.activity ? (
					<DetailLine
						label={t('transactiondetails.beneficiary.toCard')}
						value={`${details.cardNumberMask}`}
					/>
				) : (
					(details?.beneficiaryAccount || details?.beneficiaryAccountNumber) &&
					<DetailLine
						label={t('transactiondetails.beneficiary.toAccount')}
						value={`${details.beneficiaryAccount ?? details.beneficiaryAccountNumber}`}
					/>
				)
			}
			{details.details &&
				<DetailLine
					label={t('transactiondetails.details.reference')}
					value={`${details.details}`}
				/>
			}
		</Grid>
	);
};

export default TransferOutgoing;
