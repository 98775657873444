import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';

const useAllBankAccounts = (currency?: string) => {

	const { loading: accountsLoading, list, error: accountListError } = useSelector((state: RootState) => state.accounts);
	const accountList = currency ? list.filter(p => p.currency === currency) : list;
	const accountOptions = accountList.map(item => ({ value: item.accountId, key: item.accountId, text: `${item.name} (${item.currency} ${item.availableBalance})` }));

	return { accountsLoading, accountList, accountListError, accountOptions };
};

export default useAllBankAccounts;
