import { GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import MuiTextCaption from '@shared/Widgets/MuiText/MuiTextCaption';
import NumberFormat from 'react-number-format';
import { getCurrencySign } from '@/helpers/currency';


const InvoiceAmountCell = (params: GridRenderCellParams) => {

    return (
        <MuiTextCaption fontWeight={400}>
            <NumberFormat
                displayType={'text'}
                decimalScale={2}
                fixedDecimalScale
                thousandsGroupStyle='thousand'
                thousandSeparator
                prefix={getCurrencySign(params.row.currencyCode)}
                value={params.row.totalAmount} />
        </MuiTextCaption>
    );
};

export default InvoiceAmountCell;
