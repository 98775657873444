/* eslint-disable react/prop-types */
import { Typography, TypographyProps } from '@mui/material';
import React, { FC } from 'react';



const MuiHeader: FC<TypographyProps> = (props) => {
	return (
		<Typography
			fontSize='1.25rem'
			fontWeight='700'
			lineHeight='1.75rem'
			
			color='text.primary'
			whiteSpace='pre-line'
			{...props}>
			{props.children}
		</Typography>
	);
};

export default MuiHeader;
