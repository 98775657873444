import * as React from 'react';
import '@/componentsMui/Shared/Shared.css';

import { Grid } from '@mui/material';
import PanelContent from '@/componentsMui/Shared/Widgets/Dialog/PanelContent';
import ButtonContent from '@/componentsMui/Shared/Widgets/Dialog/ButtonContent';
import './FormLayout.css'; 

export interface FormTemplateProps {
	contentPart: JSX.Element | JSX.Element[];
	buttonPart?: JSX.Element | JSX.Element[];
}

/* Form Layout - poitions content and buttons  */
const FormLayout = ({ contentPart, buttonPart }: FormTemplateProps) => { 

	return (
		<Grid id="form-template-id" className="form-template portal-dialog-form-template" container >
			<Grid className="mainArea" item xs={12} sx={{height: buttonPart?'90%': '100%'}}>
				<PanelContent>
					{contentPart}
				</PanelContent>
			</Grid>
			{buttonPart && <Grid item className="buttonArea" xs={12} >
				<ButtonContent>
					{buttonPart}
				</ButtonContent>
			</Grid>}
		</Grid >
	);
};

export default FormLayout;



