import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Theme } from '@mui/material';
import LoadingPageMui from '@/pages/LoadingPageMui';
import { connect, subscribe, unsubscribe } from '@features/account/slice';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/styles';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { getFeesByUserId, getFeesByUserIdAnsUserLevel } from '@/features/fees/slice';
import { FeeDetails, FeesFimitsType, LimitDetails } from '@/features/fees/types';
import { showException } from '@/features/swal/slice';
import { RootProcessType } from '@/helpers/globalTypes';
import _ from 'lodash';
import FeeView from './FeeView';
import useUserOperations from '@/helpers/customHook/useUserOperations';
import { isFiat } from '@/helpers/currency';
import { getUserAccountsLimits, getUserAccountsLimitsByUserLevelId } from '@/features/limits/slice';
import { getOperationsByType } from '@/features/operations/slice';
import { Operations } from '@/features/operations/types';


export enum FeeSection {
	CARD = 'card',
	ACCOUNT = 'account',
	CRYPTO = 'crypto',
}

export interface ProductFeeType {
	rootProcess: string;
	proc: string;
	currency: string;
	fees: { [key: string]: FeeDetails[] };
	logo?: string;
	name: string;
	limits?: LimitDetails[];
	id: string
}


interface Props {
	userLevelId?: number;
	showFeeSection?: FeeSection,
	showOneProduct?: string,
}
const MyFees = ({ userLevelId, showFeeSection, showOneProduct }: Props): React.ReactElement => {

	const theme = useTheme() as Theme;
	const { t } = useTranslation('translations');
	const operations = useUserOperations();

	const showAccounts = operations.find(p => p.path === 'accounts')?.allowed ?? false;
	const showCards = operations.find(p => p.path === 'cards')?.allowed ?? false;
	const showWallet = operations.find(p => p.path === 'wallets')?.allowed ?? false;
	const [cardProducts, setCardProducts] = useState<any>([]);
	const [cardProductsLoading, setCardProductsLoading] = useState<any>([]);

	const btns = showOneProduct ? [] : [
		...(showAccounts ? [{ name: FeeSection.ACCOUNT }] : []),
		...(showCards ? [{ name: FeeSection.CARD }] : []),
		...(showWallet ? [{ name: FeeSection.CRYPTO }] : []),
	];

	const [feeSection, setFeeSection] = useState(showFeeSection || btns[0].name);
	const [fees, setFees] = useState<FeesFimitsType[] | []>([]);
	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();

	const getLimits = (ccy: string) => {
		if (userLevelId) {
			dispatch(getUserAccountsLimitsByUserLevelId(ccy, userLevelId));
		}
		else {
			dispatch(getUserAccountsLimits(ccy));
		}
	};

	useEffect(() => {
		connect();
		dispatch(subscribe());
		return () => {
			unsubscribe();
		};
	}, [dispatch]);

	useEffect(() => {
		const get = async () => {
			try {
				setLoading(true);
				let response = [];
				if (userLevelId) {
					response = await getFeesByUserIdAnsUserLevel(userLevelId);
				} else {
					response = await getFeesByUserId();
				}
				setFees(response);
			} catch (e) {
				showException(e);
			}
			finally {
				setLoading(false);
			}
		};
		get();
	}, [userLevelId]);

	useEffect(() => {
		const get = async () => {

			try {
				setCardProductsLoading(true);
				const response = await getOperationsByType(Operations.CREATE_CARD);
				setCardProducts(response);

			} catch (e) {
				await setCardProducts([]);
			}
			finally { setCardProductsLoading(false); }

		};
		get();
	}, []);


	const bank = fees.find(
		(p) =>
			p.type === RootProcessType.CREATE_ACCOUNT &&
			!p.integration.includes('WALLET')
	);
	const bankFees: ProductFeeType[] = useMemo(() => {
		return bank
			? _.chain(bank?.fees)
				.keys()
				.filter((key) => !key.includes('business'))
				.map((key) => {
					const feeArray = bank?.fees[key];
					const currency = RootProcessType.CREATE_ACCOUNT in feeArray
						? feeArray[RootProcessType.CREATE_ACCOUNT][0]?.ccy
						: '';
					return {
						rootProcess: RootProcessType.CREATE_ACCOUNT,
						proc: key,
						currency: currency,
						name: currency,
						fees: bank?.fees[key],
						limits: bank?.limits || [],
						id: `${currency}-${key}`
					};
				})
				.sortBy('currency')
				.value()
			: [];
	}, [bank]);

	const card = fees.find((p) => p.type === RootProcessType.CREATE_CARD);
	const cardFees: ProductFeeType[] = useMemo(() => {
		return card
			? _.chain(card?.fees)
				.keys()
				.map((key) => {
					const feeArray = card?.fees[key];
					const currency = RootProcessType.CREATE_CARD in feeArray
						? card?.fees[key][RootProcessType.CREATE_CARD][0]?.ccy
						: '';
					const productProcess = cardProducts.find(p => p?.proc === key);
					const limits = card?.limits || [];
					const ccyLimit = limits.find(p => p.ccy === currency);
					const cardLimits = _.merge(productProcess?.config, ccyLimit);
					return {
						rootProcess: RootProcessType.CREATE_CARD,
						proc: key,
						currency: currency,
						fees: card?.fees[key],
						name: card?.cards ? card?.cards[key]?.cardName : '',
						logo: card?.cards
							? card?.cards[key]?.serviceProviderLogo
							: undefined,
						limits: [cardLimits],
						id: `${currency}-${key}`
					};
				})
				.sortBy('currency')
				.value()
			: [];
	}, [card, cardProducts]);

	const token = fees.find(
		(p) => p.type === RootProcessType.CREATE_CRYPTO_ACCOUNT
	);

	useEffect(() => {
		if (!token?.tokens) return;

		///console.log('tokens', token?.tokens);
		//console.log('f tokens', token.tokens.filter((t) => !isFiat(t.symbol)));
	}, [token]);

	const tokenFees: ProductFeeType[] = useMemo(() => {
		return token?.tokens
			? token.tokens.filter((t) => !isFiat(t.symbol)).map((t) => {

				const feeObject = _.values(token?.fees).reduce(
					(accumulator, currentValue) => {
						const tmp = _.mapValues(currentValue, (o) => {
							return o.filter((p) => p.ccy === t.symbol);
						});
						return tmp;
					},
					{}
				);



				return {
					rootProcess: RootProcessType.CREATE_CRYPTO_ACCOUNT,
					proc: Object.keys(token.fees)[0] || 'undefined',
					currency: t.symbol,
					logo: t.logo,
					fees: feeObject,
					name: t.symbol,
					limits: [],
					id: `${t.symbol}-${Object.keys(token.fees)[0] || 'undefined'}`
				};
			})
			: [];
	}, [token]);

	const tradeFees: FeeDetails[] = useMemo(() => {
		const tokenProcKeys = _.keys(token?.fees);
		return token?.fees[tokenProcKeys[0]].OWN_TRANSFER || [];
	}, [token?.fees]);

	const wallet = fees.find(
		(p) =>
			p.type === RootProcessType.CREATE_ACCOUNT &&
			p.integration.includes('WALLET')
	);

	const walletFees: ProductFeeType[] = useMemo(() => {
		return wallet
			? _.chain(wallet?.fees)
				.keys()
				.map((key) => {
					//take currency from create account fee
					const feeArray = wallet?.fees[key];
					const currency = RootProcessType.CREATE_ACCOUNT in feeArray
						? wallet?.fees[key][RootProcessType.CREATE_ACCOUNT][0]?.ccy
						: '';

					const cryptoTradeFee = tradeFees.find((p) => p.ccy === currency);

					const objectFee = cryptoTradeFee
						? { ...wallet?.fees[key], TRADE: [cryptoTradeFee] }
						: wallet?.fees[key];

					return {
						rootProcess: RootProcessType.CREATE_ACCOUNT,
						proc: key,
						currency: currency,
						name: currency,
						fees: objectFee, //,wallet?.fees[key],
						limits: wallet?.limits || [],
						id: `${currency}-${key}`
					};
				})
				.sortBy('currency')
				.value()
			: [];
	}, [wallet, tradeFees]);

	const cryptoFees = walletFees.concat(tokenFees);

	const handleSectionChange = (event: any) => {
		const sectionName = event.target?.name as FeeSection;
		setFeeSection(sectionName);
	};

	const activeFees = useMemo(() => {
		if (feeSection === FeeSection.ACCOUNT) { return bankFees; }
		else if (feeSection === FeeSection.CARD) { return cardFees; }
		else if (feeSection === FeeSection.CRYPTO) { return cryptoFees; }
		else return null;
	}, [feeSection, bankFees, cardFees, cryptoFees]);

	return (loading || cardProductsLoading ? <LoadingPageMui /> :
		<Grid container rowGap={2}>
			{!userLevelId && <Grid item xs={12}>
				<MuiHeader>{t('tabs.myAccount.feeLimits')}</MuiHeader>
			</Grid>}
			<Grid item xs={12}>
				{
					btns.map(
						p => {
							return (
								<Button key={p.name}
									variant='filter'
									name={p.name}
									onClick={handleSectionChange}
									sx={{
										background: feeSection === p.name ? theme.backgroundBoxLevel1 : 'inherit',
										minWidth: '8.125rem',
										maxWidth: '8.125rem'
									}}>
									{t('fees.myFees.tabs.' + p.name)}
								</Button>);
						}
					)
				}
			</Grid>
			<Grid item xs={12}>
				<FeeView
					key={feeSection}
					title={t('fees.myFees.tabs.' + feeSection)}
					activeFees={activeFees}
					feeSection={feeSection}
					getLimits={getLimits}
					showOneProduct={showOneProduct}
				/>
			</Grid>
		</Grid>
	);
};

export default MyFees;
