import React from 'react';
import { Avatar, Grid } from '@mui/material';
import { Fiat } from '../../../Type/Fiat';
import MuiTextCaption from '../MuiText/MuiTextCaption';
import MuiTextLabel from '../MuiText/MuiTextLabel';
import './Avatars.css';



interface Props {
	fiat: Fiat,
	selected?: boolean,
	variant: 'small' | 'medium' | 'large' | 'regular',
	showOnlyAvatar?: boolean,
	logoSource?: string
}

const FiatAvatar = ({ fiat, selected, variant, showOnlyAvatar, logoSource }: Props): React.ReactElement => {
	return (
		<Grid id='fiat-avatar' container alignItems='center'>
			<Grid item>
				{logoSource ? <Avatar className={'wallet avatar-rounded asset-avatar ' + variant?.toString()} src={logoSource} />
				: <Avatar className={'wallet avatar-rounded asset-avatar ' + variant?.toString()}>{fiat?.logo} </Avatar>}
			</Grid>
			{!showOnlyAvatar && <Grid item pl='0.5rem' >
				<MuiTextCaption>{fiat?.currency} </MuiTextCaption>
				{!selected && <MuiTextLabel>{fiat?.description}</MuiTextLabel>}
			</Grid>}
		</Grid>);
};

export default FiatAvatar;
