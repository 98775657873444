
export interface InvoicesStore {
	list: Invoice[];
	loading: boolean;
	count: number;
	pagination: any;
	config: InvoiceConfig;
	options: InvoiceOptions;
}

export interface InvoiceOptions {
	bankTransferAvailable: boolean;
	cryptoAddressesAvailable: boolean;
	cryptoCurrencies: {
		code: string;
		label: string;
	}[];
}
export interface InvoiceConfig {
	minOrderAmount: number;
	allowManualProductEntering: boolean;
	allowBankTransferAsPaymentOption: boolean;
}
export interface Invoice {
	id: number;
	orderNumber: string;
	description: string;
	storeId: number;
	debtor: string;
	productsCount: number;
	totalAmount: number;
	currencyCode: string;
	issueDate: string;
	dueDate: string;
	status: InvoiceStatus,
	paymentsCount: number;
	paymentTypes: string[];
	recipient: any;
	createdDate: string;
	userNotes?: string;
}

export enum InvoiceStatus {
	DRAFT = 'DRAFT',
	ISSUED = 'ISSUED',
	PARTIALLY_PAID = 'PARTIALLY_PAID',
	PAID = 'PAID',
	CANCELLED = 'CANCELLED',
	MANUALLY_PAID = 'MANUALLY_PAID',
}

export enum PaymentTypes {
	BANK_TRANSFER = 'BANK_TRANSFER',
	CRYPTO_ONE_TIME_ADDRESSES = 'CRYPTO_ONE_TIME_ADDRESSES',
	BRIDGY_APP = 'BRIDGY_APP',
}

export interface InvoiceProduct {
	id?: number;
	productId?: number;
	description: string;
	price: number;
	unit: string;
	quantity: number;
	taxCode?: number;
	taxRate?: number;
	uuid?: string;
	type?: 'custom' | 'store';
	totalAmount?: number;
	totalTaxAmount?: number;
	totalAmountWithoutTax?: number;
}

export interface InvoiceRecipient {
	recipientName?: string;
	recipientEmail?: string;
	recipientAddress?: string;
	recipientCity?: string;
	recipientCountry?: string;
	recipientPostalCode?: string;
	recipientPhone?: string;
}
export interface CreateInvoicePayload {
	dueDate?: string;
	description?: string;
	userNotes?: string;
	products: InvoiceProduct[];
	paymentTypes: PaymentTypes[];
	recipient?: InvoiceRecipient | null;
	cryptoCurrencyCode?: string;
	issue?: boolean;
}

export interface OrderDetails {
	id: number;
	orderNumber: string;
	description: string;
	storeId: number;
	debtor: string;
	productsCount: number;
	totalAmount: number;
	currencyCode: string;
	issueDate: string;
	dueDate: string;
	status: InvoiceStatus,
	paymentsCount: number;
	paymentTypes: PaymentTypes[];
	recipient: InvoiceRecipient | null;
	createdDate: string;
	userNotes?: string;
	products: InvoiceProduct[];
	totalAmountWithoutTax: number;
}
