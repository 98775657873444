export const getGenerateKeyPair = async () => {
    const keyPair = await window.crypto.subtle.generateKey(
        {
            name: 'RSA-OAEP',
            modulusLength: 4096,
            publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
            hash: 'SHA-256',
        },
        true,
        ['encrypt', 'decrypt'],
    );

    const exported = await window.crypto.subtle.exportKey('spki', keyPair.publicKey);
    const exportedAsBase64 = Buffer.from(exported).toString('base64');

    return {
        privateKey: keyPair.privateKey,
        // publicKey: keyPair.publicKey,
        publicKey: exportedAsBase64
    };

};

export const decrypt = async (privateKey: CryptoKey, data: string) => {

    const base64Buffer = Buffer.from(data, 'base64');

    const decryptedBuffer = await window.crypto.subtle.decrypt(
        { name: 'RSA-OAEP' },
        privateKey,
        base64Buffer,
    );

    const decryptedString = (new TextDecoder().decode(decryptedBuffer));
    return JSON.parse(decryptedString);
};

//const fromBase64 = base64String => Uint8Array.from(atob(base64String), c => c.charCodeAt(0));
