import React from 'react';
import { Grid } from '@mui/material';
import { FeeSection, ProductFeeType } from './MyFees';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { RootProcessType } from '@/helpers/globalTypes';
import { FeeType } from '@/features/fees/types';
import FeeLine from './FeeLine';
import { normalizeString } from '@/helpers/normalizeString';
import { getFeeDetails } from '@/features/fees/slice';

interface Props {
	productFees: ProductFeeType;
	currency: string;
	feeSection: FeeSection;
}

const getFeeName = (
	feeName: string,
	feeSection: FeeSection,
	t: any,
	paymentType?: string,
	proc?: string,
	rootProcess?: string
) => {
	const tmpfeeName =
		rootProcess === RootProcessType.CREATE_CRYPTO_ACCOUNT &&
			feeName === 'OWN_TRANSFER'
			? 'TRADE'
			: feeName;

	const defaultName = normalizeString(tmpfeeName);
	let name =
		paymentType && feeSection
			? `${t('fees.myFees.type.' + feeSection + '.' + tmpfeeName, defaultName)} (${t(
				'fees.myFees.paymentTypes.' + paymentType, normalizeString(paymentType)
			)})`
			: t('fees.myFees.type.' + feeSection + '.' + tmpfeeName, defaultName);

	if (tmpfeeName === 'SHIPMENT') {
		let shipmentType = '';
		if (proc?.includes('express')) {
			shipmentType = t('fees.myFees.type.card.shipmentType.express');
		} else if (proc?.includes('standard')) {
			shipmentType = t('fees.myFees.type.card.shipmentType.standard');
		} else {
			shipmentType = '';
		}

		name = name + ' ' + shipmentType;
	}
	return name;
};



const FeeCard = ({ productFees, currency, feeSection }: Props): React.ReactElement => {

	const { t } = useTranslation('translations');

	const fee =
		_.chain(productFees.fees)
			.keys()
			.map((key) => {
				return {
					feeName: key,
					fee: productFees?.fees[key].filter((p) => p.ccy === currency),
				};
			})
			.filter((p) => p.feeName !== 'CARD_ACTIVATE')
			.sortBy('feeName')
			.value() || [];

//	const limit = productFees.limits?.find((p) => p.ccy === currency);


	if (!fee) return <></>;

	return (<>  {fee.map((p, idx) => {
		const title = getFeeName(
			p.feeName,
			feeSection,
			t,
			'',
			'',
			productFees.rootProcess
		);
		return (
			<Grid key={`${p.feeName}`} item xs={12}>
				{p.fee?.length > 0 ? (
					p.fee.map((f) => {
						const details = getFeeDetails(f, t);
						const titleWithPaymentType = getFeeName(
							p.feeName,
							feeSection,
							t,
							f.paymentType,
							f.proc,
							productFees.rootProcess
						);

						return (
							<Grid key={`${f.fee}-${f.proc}-${f.paymentType}`}>
								{p.feeName !== 'CREATE_ACCOUNT' &&
									<Grid>
										<FeeLine
											hideDivider={idx === 0}
											feeTitle={
												titleWithPaymentType ? titleWithPaymentType : title
											}
											feeDetails={details.feeText}
										//onClick={adjustScroll}
										/>
									</Grid>
								}
								{f.type === FeeType.FIXED_MONTH && feeSection !== FeeSection.CRYPTO &&
									(
										<Grid>
											<FeeLine
												feeTitle={t('fees.myFees.mountlyCommision')}
												feeDetails={details.montlyText}
											//onClick={adjustScroll}
											/>
										</Grid>
									)}

								{/* CWA-3339 Bank deposit has own fee DEPOSIT
								{f.type === FeeType.FIXED_MONTH && (
									<Grid >
										<FeeLine
											feeName={p.feeName}
											limit={limit}
											feeTitle={t('fees.myFees.bankDeposit')}
											feeDetails={details.depositText}
										//onClick={adjustScroll}
										/>
									</Grid>
								)} */}
							</Grid>
						);
					})
				) : (
						<Grid >
							<FeeLine
								feeTitle={title}
								feeDetails={t('fees.myFees.free')}
							/>
						</Grid>
					)}
			</Grid>
		);
	})}
	</>);
};

export default FeeCard;
