import React from 'react';
import { Link, List, ListItem } from '@mui/material';
import { TicketAttachment } from '@/features/tickets/types';
import { downloadAttachment } from '@/features/tickets/slice';
import { showException } from '@/features/swal/slice';
interface Props {
	ticketAttachments: TicketAttachment[],
	ticketId: number
}

const download = (id: number, attachment: TicketAttachment) => {
	const get = async () => {
		try {
			downloadAttachment(id, attachment);
		} catch (e) {
			showException(e);
		}
	};
	get();
};

const TicketFileList = ({ ticketAttachments, ticketId }: Props): React.ReactElement => {
	return (< List >
		{ticketAttachments.length > 0 &&
			ticketAttachments.map((attachment, idx) => (
				<ListItem key={idx} disablePadding={true}>
					<Link
						key={idx}
						variant='fileLink'
						sx={{ fontSize: '0.813rem!important' }}
						onClick={() => download(ticketId, attachment)}
					>
						{attachment.name}
					</Link>
				</ListItem>
			))}
	</ List>);
};

export default TicketFileList;
