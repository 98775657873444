import * as React from 'react';

import { Button, Dialog, FormLabel, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { openForm } from '@/features/forms/slice';
import { useDispatch } from 'react-redux';
import FormPaperLargeFixed from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeFixed';
import SmsCountryDropdown from '@/componentsMui/Onboarding/components/SmsCountryDropdown';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { isEmpty } from 'lodash';
import { CountryPhoneCodesType } from '@/features/fnd/fndTypes';
import { getCardSmsCountries } from '@/features/fnd/fndSlice';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import FormContentBox from '@/componentsMui/Shared/Widgets/Box/FormContentBox';
import { showErrorNotification } from '@/features/swal/slice';
import { verifyPhoneNumber } from '@/features/user/userSlice';
import { getRecaptureToken } from '@/helpers/recaptureUtils';

const VerifyPhoneNumber = () => {
    const { t } = useTranslation('translations');
    const [smsCountries, setSmsCountries] = useState<CountryPhoneCodesType[]>([]);
    const dispatch = useDispatch();

    const closeModal = useCloseDialogNoEvent();

    useEffect(() => {
        dispatch(openForm());
    }, [dispatch]);

    const initialValues = {
        phoneCode: '',
        phoneNumber: ''
    };

    useEffect(() => {
        const get = async () => {
            try {
                const countries = await getCardSmsCountries();
                setSmsCountries(countries);
            }
            catch (e) { setSmsCountries([]); }

        };
        get();
    }, []);

    const validatePhone = (phone: number | undefined) => {
        return Yup.number().integer().positive().test(
            (phone) => {
                return isValidPhoneNumber(`+${phone}`);
            }
        ).isValidSync(phone);
    };

    const validationSchema = Yup.object({
        phoneNumber: Yup.string()
            .required(t('form.validator.required'))
            .test('phoneNumber', t('form.validator.invalidValue'), (value, ctx) => {
                return validatePhone(parseInt(ctx.parent.phoneCode + '' + value ?? '0'));
            }),
    });

    const submit = async (formData, formikProps) => {

        const { setSubmitting, setFieldError } = formikProps;
        const { phoneNumber, phoneCode } = formData;

        try {
            setSubmitting(true);
            const recaptchaToken = await getRecaptureToken();
            await verifyPhoneNumber(phoneNumber, phoneCode, recaptchaToken);
            closeModal();
        } catch (err: any) {
            const data = err?.data;
            const duplicate = data?.errors?.find(error => error.error === 'duplicate' && error.error_param === 'phoneNumber') || false;

            if (duplicate) {
                setFieldError('phoneNumber', t('errors.DUPLICATE_PHONE_NUMBER'));
            } else {

                showErrorNotification(err);
            }
        } finally {
            setSubmitting(false);
        }
    };


    return (
        <Dialog
            id="verifyPhoneNumberForm"
            open={true}
            onClose={closeModal}
            PaperComponent={FormPaperLargeFixed}
        >
            <Grid
                container
                // direction="row"
                // justifyContent="center"
                flex={1}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={submit}
                    validateOnChange={true}
                    validateOnBlur={true}
                    enableReinitialize={true}
                >
                    {({ errors, isSubmitting }) => {
                        return (
                            <Form style={{ width: '100%' }} autoComplete='off' className='form-content__wrap__bb'>
                                <MuiHeader mb={4}>{t('cards.verifyPhone.formTitle')}</MuiHeader>
                                <FormLabel>	{t('cards.verifyPhone.formLabel')} </FormLabel>
                                <FormContentBox noBackground={true} className='form-content_main-area wp' >
                                    <Grid container rowSpacing={1}>
                                        <Grid item xs={3}>
                                            <SmsCountryDropdown name='phoneCode' smsCountries={smsCountries} />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <MuiTextField
                                                name="phoneNumber"
                                                fullWidth
                                                inputProps={{ maxLength: 255 }}
                                                InputProps={{
                                                    autoComplete: 'new-phone',
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className='form-content_botton-area' justifyContent='space-between'>
                                        <Button
                                            key="buttonCancel"
                                            className='customDialogButton'
                                            variant='customOutlined'
                                            onClick={closeModal}
                                        >   {t('form.buttons.close')}
                                        </Button>
                                        <Button
                                            key="buttonNext"
                                            className='customDialogButton'
                                            variant="contained"
                                            color="primary"
                                            disabled={isSubmitting || !isEmpty(errors)}
                                            type='submit'
                                        >
                                            {t('createaccounttab.btn')}
                                        </Button>
                                    </Grid>
                                </FormContentBox>
                            </Form>
                        );
                    }}
                </Formik>
            </Grid>
        </Dialog >
    );
};


export default VerifyPhoneNumber; 
