import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import { convertDate } from '@/helpers/date';
import { TransactionStatus } from '@/features/transactions/types';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import './TradeHistory.css';
import { useTranslation } from 'react-i18next';
import TransactionStatusBox from '@/componentsMui/Shared/Widgets/Box/TransactionStatusBox';
import { WalletTransaction } from '@/features/walletHistory/types';
import { isFiat } from '@/helpers/currency';
import { removeTrailingZeros } from '@/helpers/number';

interface Props {
	item: WalletTransaction
}


const TradeHistoryLineView = ({ item }: Props) => {

	const { t } = useTranslation('translations');
	const time = item?.date && new Date(item.date);
	const isSell = item.amount < 0;
	const fromAmount = isSell ? Math.abs(item.amount) : Math.abs(item.conversionAmount);
	const fromCcy = isSell ? item.currency : item.conversionCurrency;

	const toAmount = isSell ? Math.abs(item.conversionAmount) : Math.abs(item.amount);
	const toCcy = isSell ? item.conversionCurrency : item.currency;

	const pair = item.isBaseCurrency ? `${item.currency}-${item.conversionCurrency}` : `${item.conversionCurrency}-${item.currency}`;

	const feeAmountString = item?.systemFeeAmountString ? removeTrailingZeros(item?.systemFeeAmountString.replaceAll('-', '')) : null;

	return (
		<Grid container alignItems='center' height='4rem' width='100%' className='wallet-trade-line' >
			<Grid item className='trade-line__time'>
				<Typography fontSize='0.813rem' color='text.primary' lineHeight='1.188rem' pr='4px'>
					{convertDate(time, 'dd MMM yyyy')}
				</Typography>
				<Typography fontSize='0.813rem' color='text.secondary' lineHeight='1.188rem'>
					{convertDate(time, 'HH:mm:ss')}
				</Typography>
			</Grid>
			<Grid item className='trade-line__market'>
				<MuiTextCaption variant='gradient' fontWeight='400'>
					{pair}
				</MuiTextCaption>
			</Grid>
			<Grid item className='trade-line__type'>
				<MuiTextCaption fontWeight={400}>
					{t('tokens.tradeHistory.market')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='trade-line__from'>
				<MuiTextCaption fontWeight={400}>
					<NumberFormat displayType={'text'}
						decimalScale={isFiat(fromCcy) ? 2 : 5}
						fixedDecimalScale={false}
						thousandsGroupStyle='thousand'
						thousandSeparator={true}
						value={fromAmount}
						suffix={` ${fromCcy}`} />
				</MuiTextCaption>
			</Grid>
			<Grid item className='trade-line__to'>
				<MuiTextCaption fontWeight={400}>
					<NumberFormat displayType={'text'}
						decimalScale={isFiat(toCcy) ? 2 : 5}
						fixedDecimalScale={false}
						thousandsGroupStyle='thousand'
						thousandSeparator={true}
						value={toAmount}
						suffix={` ${toCcy}`} />
				</MuiTextCaption>
			</Grid>
			<Grid item className='trade-line__price'>
				<MuiTextCaption fontWeight={400}>
					<NumberFormat displayType={'text'}
						decimalScale={item.displayDecimals ?? 2}
						fixedDecimalScale={false}
						thousandsGroupStyle='thousand'
						thousandSeparator={true}
						value={Math.abs(item.price)}
						suffix={` ${item.isBaseCurrency ? item.conversionCurrency : item.currency}`} />
				</MuiTextCaption>
			</Grid>
			<Grid item className='trade-line__fee'>
				{feeAmountString &&
					<MuiTextCaption fontWeight={400}>
						<NumberFormat displayType={'text'}
							fixedDecimalScale={false}
							thousandsGroupStyle='thousand'
							thousandSeparator={true}
							isNumericString={true}
							value={feeAmountString}
							suffix={` ${item?.systemFeeCurrency ?? ''}`}
						/>
					</MuiTextCaption>
				}
			</Grid>
			<Grid item className='trade-line__status'>
				<TransactionStatusBox
					status={item.status as TransactionStatus}>
					{t('status.' + item.status)}
				</TransactionStatusBox>
			</Grid>
		</Grid >);
};

const TradeHistoryLine = ({ item }: Props) => {
	return (<>
		<TradeHistoryLineView item={item} />
	</>);

};

export default TradeHistoryLine;

