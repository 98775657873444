import { BulkPaymentLineStatus, PaymentLine } from '@/features/bulkpayments/bulkPaymentsTypes';
import { Theme, Typography, useTheme, Box as MuiBox, Grid, Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '@mui/icons-material/Error';

const PaymentLineStatusCell = (params: GridRenderCellParams) => {
    const { t } = useTranslation('translations');
    const theme = useTheme() as Theme;
    if (params.value === null || params.value === undefined) {
        return '';
    }

    const payment: PaymentLine = params.row;
    const errors = payment.errors?.filter(e => e.generalErrorCode !== null) || [];
    const hasErrors = errors.length > 0;

    const status = params.value as BulkPaymentLineStatus;

    const statusColor = () => {
        let statusColor: string;
        switch (status) {
            case BulkPaymentLineStatus.ERROR:
            case BulkPaymentLineStatus.REJECTED:
            case BulkPaymentLineStatus.INVALID:
                statusColor = theme.statusColor.lightRed;
                break;
            case BulkPaymentLineStatus.COMPLETED:
                statusColor = theme.statusColor.success;
                break;
            case BulkPaymentLineStatus.VALID:
            case BulkPaymentLineStatus.SUBMITTED:
            case BulkPaymentLineStatus.PROCESSING:
            default:
                statusColor = theme.statusColor.warning;
                break;
        }
        return statusColor;
    };

    const getErrorMessages = () => {
        const errorMessage = t(`bulkpayments.errors.${errors[0].generalErrorCode}`);

        return errors.length === 1 ? <div>{errorMessage}</div> : <ul style={{ paddingLeft: '8px' }}>
            {errors.map((error, i) => {
                const errorMessage = t(`bulkpayments.errors.${error.generalErrorCode}`);
                return (<li key={`${i}`}>{errorMessage}</li>);
            })}
        </ul>;
    };


    return (
        <Grid container flexWrap='nowrap' flexDirection='row' alignItems='center' justifyContent='flex-end'>
            <MuiBox
                sx={{
                    borderRadius: '10px',
                    background: `${statusColor}14`,
                    padding: '0px 10px',
                    width: 'fit-content',
                    minWidth: '4rem',
                    height: '1.5rem',
                    cursor: 'default',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography
                    fontWeight={700}
                    fontSize={'0.563rem'}
                    sx={{ color: statusColor }} >
                    {t(`bulkpayments.paymentLineStatuses.${params.value}`)}
                </Typography>
            </MuiBox>
            {hasErrors && <Tooltip placement="top-start" title={getErrorMessages()} ><ErrorIcon sx={{ color: theme.palette.error.dark, cursor: 'pointer' }} fontSize='small' /></Tooltip>}
        </Grid>);
};

export default PaymentLineStatusCell;
