import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, Grid, IconButton, Paper } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { openForm } from '@/features/forms/slice';
import { useTranslation } from 'react-i18next';
import '@/componentsMui/Shared/Shared.css';
import '@/componentsMui/Shared/SharedFormLayout.css';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import { withStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { getBusinessPaymentDetails } from '@/features/business/slice';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import MuiSubHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiSubHeader';
import { PaymentDetails } from '@/features/business/types';
import BusinessPaymentDetails from './Components/BusinessPaymentDetails';
import BusinessPaymentIdentifier from './Components/BusinessPaymentIdentifier';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';
import BusinessPaymentAmount from './Components/BusinessPaymentAmount';
import FileUploadBox from '@/componentsMui/Shared/Components/FileUpload/FileUploadBox';
import MuiTextFieldInfo from '@/componentsMui/Shared/Widgets/MuiText/MuiTextFieldInfo';
import useFileUpload from '@/helpers/customHook/useFileUpload';
import { postBusinessPaymentProof } from '@/features/files/slice';


const BusinessFormPaper = withStyles((theme) => ({
	root: {
		minWidth: '50rem',
		maxWidth: '50rem',
		padding: '21px',
		background: theme.form.bgLight
	}
}))(Paper);



const PaymentBusinessModal = (): React.ReactElement => {

	const closeModal = useCloseDialogNoEvent();
	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const [clientId] = useState<string>(searchParams.get('client'));
	const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>(null);
	const fileUpload = useFileUpload(1);

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);


	useEffect(() => {
		if (!clientId) return;
		const get = async () => {
			const data = await getBusinessPaymentDetails(clientId);
			setPaymentDetails(data);
		};
		get();
	}, [clientId]);


	const handleClose = () => {
		closeModal();
	};

	const handleComplete = async () => {
		try {
			if (fileUpload.selectedFiles.length > 0) {
				const file = new FormData();
				file.append('file', fileUpload.selectedFiles[0]);
				await postBusinessPaymentProof(clientId, file);
			}
			handleClose();
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<Dialog
			id="create-business-form"
			open={true}
			onClose={handleClose}
			PaperComponent={BusinessFormPaper}
			maxWidth="md"
		>
			<DialogActions >
				<IconButton onClick={handleClose}  >
					<MuiCloseIcon />
				</IconButton>
			</DialogActions>
			{!paymentDetails ? <Spinner /> : <Grid container p={2}>
				<Grid item xs={12} className='header-wrap' pb={4} pt={1} textAlign='center'>
					<MuiHeader >
						{t('createbusiness.waitingForPayment.header')}
					</MuiHeader>
				</Grid>
				<Grid item xs={12} className='sepa-section' pb={2}>
					<MuiFormText>  {t('createbusiness.waitingForPayment.helperText')}  </MuiFormText>
				</Grid>
				<Grid item xs={12} className='sepa-section' pb={1}>
					<MuiSubHeader>  {t('createbusiness.waitingForPayment.whereToSend')}    </MuiSubHeader>
				</Grid>
				<Grid item xs={12} pb={2}>
					<BusinessPaymentDetails paymentDetails={paymentDetails} />
				</Grid>
				<Grid item xs={12} className='sepa-section' pb={1}>
					<MuiSubHeader>  {t('createbusiness.waitingForPayment.reference')}   </MuiSubHeader>
				</Grid>
				<Grid item xs={12} pb={2}>
					<BusinessPaymentIdentifier reference={paymentDetails?.reference} />
				</Grid>

				<Grid item xs={12} className='sepa-section' pb={1}>
					<MuiSubHeader> {t('createbusiness.waitingForPayment.amount')}   </MuiSubHeader>
				</Grid>
				<Grid item xs={12} pb={4}>
					<BusinessPaymentAmount amount={paymentDetails?.amount} ccy={paymentDetails?.ccy} />
				</Grid>
				{paymentDetails?.proofOfPayment === null ? <>
					<Grid item xs={12} className='sepa-section' pb={1}>
						<MuiSubHeader fontSize='1rem'>  {t('createbusiness.waitingForPayment.proofOfPayment')}  </MuiSubHeader>
					</Grid>
					<Grid item xs={12}>
						<MuiFormText>  {t('createbusiness.waitingForPayment.proofOfPaymentHelper')}  </MuiFormText>
					</Grid>
					<Grid item xs={12} className='sepa-file-upload'>
						<FileUploadBox
							selectedFiles={fileUpload.selectedFiles}
							uploadFiles={fileUpload.uploadFiles}
							deleteAttachment={fileUpload.deleteAttachment} />
						{fileUpload.selectedFiles.length === 0 &&
							<MuiTextFieldInfo mt={1}>{t('createbusiness.waitingForPayment.proofOfPaymentUpload')}</MuiTextFieldInfo>
						}
					</Grid>
				</> : <Grid item xs={12} className='sepa-section' pb={1}>
					<MuiFormText>{t('createbusiness.waitingForPayment.proofOfPaymentUploaded')}</MuiFormText>
				</Grid>}

			</Grid>}
			<Grid container item xs={12} display='flex' flex={1} justifyContent='center' mt='auto'>
				<Button onClick={handleComplete}
					variant='contained'
					type="button"
					disabled={fileUpload.selectedFiles.length === 0 && paymentDetails?.proofOfPayment === null}
				>
					{paymentDetails?.proofOfPayment ? t('createbusiness.waitingForPayment.closeBtn') : t('createbusiness.waitingForPayment.uploadBtn')}
				</Button>
			</Grid>

		</Dialog >
	);
};

export default PaymentBusinessModal;
