import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@/store';
import { CANCEL_2FA, CHANGE_2FA, ConfirmType, LoginTokenPayload, TwoFAPayload } from './types';
import request from '../../services/request';
import { showInfo } from '../swal/slice';
import axios from 'axios';
import { logout } from '../user/userSlice';
import { getRecaptureToken } from '@/helpers/recaptureUtils';

const initialState = {
	open: false,
	loading: false,
	message: null,
	mobileConfimOpen: false,
	codeConfirm: ConfirmType.NONE,
	alert: null,
	phoneNumber: null,
	email: null,
	errorCode: null
};

let originalRequest = null;
let error = null;
let callbacks = [];
let cancelSource;


const twoFASlice = createSlice({
	name: 'swal',
	initialState,
	reducers: {
		open2FAModal(state) {
			state.open = true;
		},
		close2FAModal(state) {
			state.open = false;
			originalRequest = null;
			callbacks = [];
		},
		open2FAMobileConfirm(state) {
			state.mobileConfimOpen = true;
		},
		close2FAMobileConfirm(state) {
			state.mobileConfimOpen = false;
			originalRequest = null;
			callbacks = [];
		},
		changeToAuthentificator(state) {
			state.mobileConfimOpen = false;
			state.open = true;
			state.loading = false;
			state.message = null;
		},
		openCodeConfirm(state, action: PayloadAction<{ type: ConfirmType, alert?: string, phoneNumber?: string, email?: string }>) {
			state.codeConfirm = action.payload.type;
			state.alert = action.payload.alert;
			state.phoneNumber = action.payload.phoneNumber;
			state.email = action.payload.email;
		},
		closeCodeConfirm(state) {
			state.codeConfirm = ConfirmType.NONE;
			state.phoneNumber = null;
			state.email = null;
			originalRequest = null;
			callbacks = [];
			state.message = null;
		},
		set2FAMessage(state, action: PayloadAction<string>) {
			state.message = action.payload;
		},
		set2FALoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		setErrorCode(state, action: PayloadAction<string>) {
			state.errorCode = action.payload;
		}
	}
});

export const { open2FAModal,
	close2FAModal,
	set2FAMessage,
	open2FAMobileConfirm,
	changeToAuthentificator,
	close2FAMobileConfirm,
	openCodeConfirm,
	closeCodeConfirm,
	set2FALoading,
	setErrorCode } = twoFASlice.actions;


export const request2FA = (payload: any, resolve_cb: any, reject_cb: any, parent_error: any) => {
	originalRequest = payload;
	error = parent_error;

	if (callbacks.length > 0) {
		const previous_callbacks = callbacks.slice(-1);
		callbacks.push({
			reject_cb: previous_callbacks[0].reject_cb(reject_cb),
			resolve_cb: previous_callbacks[0].resolve_cb(resolve_cb)
		});
	} else {
		callbacks.push({
			reject_cb: reject_cb,
			resolve_cb: resolve_cb
		});
	}
};

export const rerunRequest2FA = (resolve_cb: any, reject_cb: any, parent_error: any) => {

	error = parent_error;
	const CancelToken = axios.CancelToken;
	cancelSource = CancelToken.source();
	originalRequest.cancelToken = cancelSource.token;

	callbacks = [{
		reject_cb: reject_cb,
		resolve_cb: resolve_cb
	}];
};

export const changeToGoogleAuth = (): AppThunk => {
	return async dispatch => {
		const payload = originalRequest;
		if (payload?.data) {
			const payloadData = JSON.parse(payload.data);
			payloadData.authProvider = '2FA_AUTHN';
			payload.data = JSON.stringify(payloadData);
			originalRequest = payload;
			dispatch(changeToAuthentificator());
			await cancelToken(CHANGE_2FA);
		}
		else {
			console.log('changeToGoogleAuth no data in payload');
		}
	};
};

const refreshRecaptchaToken = async (payloadData: any) => {
	if ('recaptchaToken' in payloadData) {
		const token = await getRecaptureToken();
		payloadData['recaptchaToken'] = token;
	}
	return payloadData;
};

export const next = (authenticatorCode: string): AppThunk => {
	return async dispatch => {
		const payload = originalRequest;
		const payloadData = JSON.parse(payload.data);

		payloadData.authenticatorCode = authenticatorCode;
		await refreshRecaptchaToken(payloadData);

		payload.data = JSON.stringify(payloadData);
		const cb = callbacks.slice(-1);
		const { resolve_cb, reject_cb } = cb[0];
		callbacks.pop();
		try {
			resolve_cb(await request(payload));
		} catch (e) {
			reject_cb(e);
		}
		setTimeout(() => dispatch(set2FALoading(false)), 2000);
		dispatch(close2FAModal());
	};
};

export const nextV3Confim = (code: string, type: ConfirmType, history: any, isLogoutNeeded = false): AppThunk => {
	return async dispatch => {
		try {
			const payload = originalRequest;
			const payloadData = JSON.parse(payload.data);
			if (type === ConfirmType.EMAIL) {
				payloadData.emailOtpCode = code;
			}
			if (type === ConfirmType.SMS) {
				payloadData.smsCode = code;
			}
			await refreshRecaptchaToken(payloadData);

			payload.data = JSON.stringify(payloadData);
			const cb = callbacks.slice(-1);
			if (cb && cb?.length > 0) {
				const { resolve_cb, reject_cb } = cb[0];
				callbacks.pop();
				try {
					resolve_cb(await request(payload));

					if (isLogoutNeeded) {
						dispatch(logout());
						history.push('/auth');
					}
				} catch (e) {
					reject_cb(e);

				}
			}
			await dispatch(closeCodeConfirm());

		}
		catch (e) {
			dispatch(closeCodeConfirm());
		}
	};
};

export const nextMobile = (userCode: string, payload: any): AppThunk => {

	return async dispatch => {
		const payloadData = JSON.parse(payload.data);
		payloadData.userCode = userCode;

		await refreshRecaptchaToken(payloadData);

		const CancelToken = axios.CancelToken;
		cancelSource = CancelToken.source();
		payload.cancelToken = cancelSource.token;
		payload.data = JSON.stringify(payloadData);

		const cb = callbacks.slice(-1);
		const { resolve_cb, reject_cb } = cb[0];
		callbacks.pop();

		try {
			resolve_cb(await request(payload));
		} catch (e: any) {
			if (e?.data && e?.data?.errors && e?.data?.errors[0]?.error === 'expired' && e?.data?.errors[0]?.error_param === 'code') {
				reject_cb(e);
				if (payload.url !== '/api/v3/auth/login') { showInfo({ title: 'errors.2FA_TOKEN_EXPIRED' }); }
			}
			else if (e?.data && e?.data?.errors && e?.data?.errors[0]?.error === 'rejectedBy') {
				//	if (['/api/v3/auth/login', '/api/cards/details/pin', '/api/cards/details/pan', '/api/cards/cvv', '/api/cards/details/pan_sh'].includes(payload.url)) {
				reject_cb(e);
				//	}
				await showInfo({ title: 'errors.2FA_TOKEN_REJECTED' });
			}
			else if (e || payload.url === '/api/v3/auth/login') {
				console.log('try to reject 1');
				reject_cb(e);
			}
			else {
				//cancelation of activitiy
				if (!e) return;
				try {
					console.log('try to reject');
					reject_cb(e);
				}
				catch (e) {
					console.log('nextMobile', e);
				}
			}

		}
		finally {
			dispatch(close2FAMobileConfirm());
			dispatch(closeCodeConfirm());
		}
	};
};

export const reject2FA = (): AppThunk => {
	return async dispatch => {
		const cb = callbacks.slice(-1);
		if (cb) {
			const { reject_cb } = cb[0];
			if (reject_cb) { reject_cb(error); }
		}
		dispatch(close2FAModal());
		dispatch(set2FAMessage(null));
	};
};

export const rejectCodeConfirm = (): AppThunk => {
	return async dispatch => {
		try {
			const cb = callbacks.slice(-1);
			if (cb) {
				const { reject_cb } = cb[0];
				reject_cb(error);
			}
			dispatch(closeCodeConfirm());
		}
		catch (e) {
			console.log('rejectCodeConfirm', e);
			dispatch(closeCodeConfirm());
		}
	};
};

export const twoFASelector = (state: { statusStore: TwoFAPayload }) =>
	state.statusStore;

export const getQRCode = async (): Promise<{ qrCodeImage: string }> => {
	const response = await request.post('/api/tfa/qr/generate');
	return response.data;
};

export const cancelTwoFAMobile = async (): Promise<{ qrCodeImage: string }> => {
	const response = await request.post('/api/tfa/cancel');
	return response.data;
};

export const getLoginToken = async (payload: LoginTokenPayload): Promise<any> => {
	const response = await request.post('/api/tfa/login/token', payload);
	return response.data;
};

export const cancelToken = (reason?: string) => {
	if (cancelSource && cancelSource.token) {
		cancelSource.cancel(reason ? reason : CANCEL_2FA);
	}
};

export default twoFASlice.reducer;
