import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { showException } from '@/features/swal/slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { getTicketsPerPage, setPageSize } from '@/features/tickets/slice';
import { TicketNotification } from '@/features/tickets/types';
import TicketsLine from './TicketsLine';
import InputBox from '@/componentsMui/Shared/Widgets/Box/InputBox';
import '@/componentsMui/Shared/Shared.css';
import { useTranslation } from 'react-i18next';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';
interface Props {
	onSelect: (ticket: TicketNotification) => void
}

const MyTicket = ({ onSelect }: Props) => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const ADD_ITEMS_ON_PAGE = 6;
	const [loading, setLoading] = useState(false);
	const lastTransactionRef = React.useRef(null);

	const { list, count, pageSize, updateMessages } = useSelector((state: RootState) => state.tickets);

	const itemsOnPage = pageSize + ADD_ITEMS_ON_PAGE;

	const fetchTicketList = useCallback((itemsToFetch) => {
		const get = async () => {

			try {
				const firstLoad = itemsOnPage === ADD_ITEMS_ON_PAGE;
				setLoading(true);
				await dispatch(getTicketsPerPage(itemsToFetch, 'incidenttype=INCIDENT'));

				if (!firstLoad && list?.length !== count) {
					lastTransactionRef.current?.scrollIntoView({ behavior: 'smooth' });
				}
			} catch (e) {
				showException(e);
			}
			finally {
				setLoading(false);
			}
		};
		get();
	}, [dispatch, list?.length, count, itemsOnPage]);

	useEffect(() => {
		fetchTicketList(itemsOnPage);
	}, [fetchTicketList, itemsOnPage]);

	useEffect(() => {
		if (updateMessages) {
			fetchTicketList(itemsOnPage);
		}
	}, [updateMessages, itemsOnPage, fetchTicketList, dispatch]);

	const handleMessageClick = (ticket: TicketNotification) => {
		onSelect(ticket);
	};

	const loadMore = () => {
		dispatch(setPageSize(list.length + itemsOnPage));
	};

	return (
		<Grid container className='scroll-grid-container' >
			<Grid item className='scrollable-content' >
				<InputBox id='my-tickets'>
					<Grid container  >
						{list.length > 0 && list.map((item, index) => {
							return (
								<Grid key={item.id} item xs={12} style={{ cursor: 'pointer' }}
									ref={list.length === index + 1 ? lastTransactionRef : null}>
									<TicketsLine
										ticket={item}
										onClick={() => handleMessageClick(item)}
									/>
								</Grid>
							);
						})}
					</Grid>
				</InputBox >
			</Grid>
			<Grid item mt='1.3rem'>
				{count > list.length && <Grid item xs={12} textAlign='center'  >
					{loading ?
						<Spinner /> :
						<Button onClick={loadMore}>
							<Typography
								sx={{ cursor: 'pointer' }}
								fontSize='0.813rem'
								fontWeight={700}
								variant={'gradient'}
								onClick={loadMore}>
								{t('transaction.table.loadMore')}
							</Typography>
						</Button>
					}
				</Grid>}
			</Grid>
		</Grid >
	);
};

export default MyTicket;

