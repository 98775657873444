import TrxActivityCell from './TrxActivityCell';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import TrxAmountCell from './TrxAmountCell';
import TrxDateCell from './TrxDateCell';
import { t } from '@/helpers/translate';
import TrxActivityCellMobile from './TrxActivityCellMobile';
import TrxDetailsCell from './TrxDetailsCell';
import TrxStatusCell from './TrxStatusCell';
import TrxAmountCellMobile from './TrxAmountCellMobile';
import { AccountType } from '@/features/operations/types';
import TrxFeeCell from './TrxFeeCell';

const widthV = 100;
export const getTrxGridColumns = (
    type: string,
    accountSubProcesses: any,
    hideDetails?: boolean,
    hideFeeColumn?: boolean): GridColDef[] => [
        {
            field: 'activity',
            headerName: t('transaction.header.name'),
            minWidth: widthV,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => TrxActivityCell(params),
            sortable: false,
        },
        ...(!hideDetails ?
            [{
                field: 'details',
                headerName: t('transaction.header.details'),
                minWidth: widthV,
                flex: 1,
                renderCell: (params: GridRenderCellParams) => TrxDetailsCell(params),
                sortable: false,
            } as GridColDef] :
            []),

        {
            field: 'date',
            headerName: t('transaction.header.date'),
            minWidth: widthV,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => TrxDateCell(params),
            sortable: false,
        },
        {
            field: 'amount',
            headerName: t('transaction.header.amount'),
            minWidth: widthV,
            flex: 1,
            headerAlign: 'right',
            align: 'right',
            renderCell: (params: GridRenderCellParams) => TrxAmountCell(params, type),
            sortable: false,
        },
        ...(type === AccountType.CARD && !hideFeeColumn ?
            [{
                field: 'fee',
                headerName: t('transaction.header.fee'),
                minWidth: widthV,
                flex: 1,
                headerAlign: 'right',
                align: 'right',
                renderCell: (params: GridRenderCellParams) => TrxFeeCell(params),
                sortable: false,
            } as GridColDef] :
            []),
        {
            field: 'status',
            headerName: t('transaction.header.status'),
            minWidth: widthV,
            flex: 1,
            headerAlign: 'right',
            align: 'right',
            renderCell: (params: GridRenderCellParams) => TrxStatusCell(params, accountSubProcesses),
            sortable: false,
        },
    ];



export const getMobileTrxGridColumns = (type: string, accountSubProcesses: any): GridColDef[] => [
    {
        field: 'activity',
        headerName: t('transaction.header.name'),
        flex: 1,
        renderCell: (params: GridRenderCellParams) => TrxActivityCellMobile(params),
        sortable: false,
    },
    {
        field: 'amount',
        headerName: t('transaction.header.amount'),
        flex: 1,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params: GridRenderCellParams) => TrxAmountCellMobile(params, type, accountSubProcesses),
        sortable: false,
    },
];

