import React, { useEffect } from 'react';
import { Button, Dialog, DialogContent, FormHelperText, Grid, IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { closeForm, openForm } from '@/features/forms/slice';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { Form, Formik } from 'formik';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { isEmpty } from 'lodash';
import { showException } from '@/features/swal/slice';
import * as Yup from 'yup';
import { usePasswordValidation } from './passwordHooks';
import { setPassword } from '@/features/user/userSlice';
import { getRecaptureToken } from '@/helpers/recaptureUtils';

interface Props {
	open: boolean,
	onClose: () => void
}

const NewPasswordForm = ({ open, onClose }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const [passwordErrors, validatePassword, setErrors] = usePasswordValidation();

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);


	const closeModal = () => {
		dispatch(closeForm());
		onClose();
	};

	const initialValues = {
		password: '',
		passwordConfirm: ''
	};

	const validationSchema = Yup.object({
		password:
			Yup.string()
				.required(t('form.validator.required')),
		passwordConfirm:
			Yup.string()
				.required(t('form.validator.required'))
				.oneOf([Yup.ref('password'), null], t('form.validator.confirmpassword'))
	});


	const handleOnBlur = async (e) => {
		await validatePassword(e.target.value);
	};


	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		const { password } = formData;
		try {
			if (passwordErrors.length > 0) { return; }
			const recaptchaToken= await getRecaptureToken();
			await setPassword(password, recaptchaToken);
			closeModal();
		} catch (e) {
			await showException(e, [
				{
					key: 'invalid',
					title: 'swal.ERROR',
					message: 'swal.INVALID_OLD_PASSWORD'
				}
			], t('form.buttons.close'));
		} finally { setSubmitting(false); }
	};

	return (
		<Dialog
			id="change-password-form"
			open={open}
			maxWidth='sm'
			fullWidth
		>
			<DialogContent>
				<Grid container flex={1} direction='column' p='1rem'>
					<Grid container justifyContent='flex-end'>
						<Grid item>
							<IconButton onClick={closeModal}  >
								<MuiCloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12} textAlign='center'>
							<MuiHeader>{t('security.options.setPassword')}
							</MuiHeader>
						</Grid>
						<Grid item xs={12} pt='2rem'>
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={submit}
							>
								{({ errors, isSubmitting }) => {
									return (
										<Form style={{ width: '100%' }}>
											<Grid container >
												<Grid item xs={12}>
													<MuiTextField
														fullWidth
														name="password"
														onFocus={() => setErrors([])}
														onBlur={handleOnBlur}
														label={t('form.fields.newpasswordlabel')}
														placeholder={t('form.fields.newpasswordlabel')}
														type="password"
														InputProps={{
															autoComplete: 'off',
														}}
													/>
													{passwordErrors.some(p => p === 'len') && <FormHelperText>{t('password.passwordvalidators.len')}</FormHelperText>}
													{passwordErrors.some(p => p === 'spec') && <FormHelperText>{t('password.passwordvalidators.spec')}</FormHelperText>}
													{passwordErrors.some(p => p === 'upper') && <FormHelperText>{t('password.passwordvalidators.upper')}</FormHelperText>}
												</Grid>

												<Grid item xs={12}>
													<MuiTextField
														fullWidth
														name="passwordConfirm"
														label={t('form.fields.confirmPasswordLabel')}
														placeholder={t('form.fields.confirmPasswordLabel')}
														type="password"
														InputProps={{
															autoComplete: 'off'
														}}
													/>
												</Grid>

												<Grid item xs={12} >
													<Grid container flex={1} justifyContent='space-between' mt={8}>
														<Grid item xs={6}>
															<Button variant="customOutlined"
																onClick={closeModal}>
																{t('form.buttons.close')}
															</Button>
														</Grid>
														<Grid item xs={6} container justifyContent='flex-end'>
															<Button
																type="submit"
																variant="contained"
																disabled={!isEmpty(errors) || isSubmitting || passwordErrors.length > 0}
															>
																{t('form.buttons.continue')}
															</Button>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Form>
									);
								}}
							</Formik>

						</Grid>
					</Grid>
				</Grid>

			</DialogContent>
		</Dialog >
	);
};

export default NewPasswordForm;
