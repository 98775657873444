import React from 'react';
import { useTranslation } from 'react-i18next';
import { BankAccount } from '@/features/account/types';
import DetailsBox from '@/componentsMui/Shared/Widgets/DetailsBox';
import DetailLine from '@/componentsMui/Shared/Widgets/DetailLine';

interface Props {
	account: BankAccount
}

const AccountDetailsEUR = ({ account }: Props): React.ReactElement => {

	const { t } = useTranslation('translations');
	return (
		<DetailsBox>
			<DetailLine label={t('wallet.beneficiary.name')} value={account?.recipient} first />
			<DetailLine label={t('wallet.beneficiary.iban')} value={account?.iban} />
			<DetailLine label={t('wallet.beneficiary.bank')} value={account?.bankName} />
			<DetailLine label={t('wallet.beneficiary.bankCountry')} value={account?.bankCountry} />
			<DetailLine label={t('wallet.beneficiary.bankAddress')} value={account?.bankAddress} />
			<DetailLine label={t('wallet.beneficiary.bicCode')} value={account?.bicCode} />
			<DetailLine label={t('wallet.deposit.sepaConfirmation.paymentDetails')} value={account?.walletId}
						warningTooltip={t('wallet.deposit.fieldInfo.reference')} />
		</DetailsBox>
	);
};

export default AccountDetailsEUR;
