import { ExportType } from '@/componentsMui/Transactions/TransactionStatements.spec';
import { showException } from '@/features/swal/slice';
import { getExcel, getExtendedExcel, getStatementPdfContent } from '@/features/transactions/slice';
import { TransactionsPayload } from '@/features/transactions/types';
import { useCallback, useState } from 'react';
import { AccountType } from '@/features/operations/types';
import { convertDate } from '@helpers/date';


const handleConvert = (time?: string) => {
	try {
		return time ? convertDate(new Date(Number(time)), 'dd-MM-yyyy') : undefined;
	}
	catch (e) { return undefined; }
};

const useTransactionDownload = (exportType: ExportType, searchPayload?: TransactionsPayload,) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [previewFile, setPreviewFile] = useState(null);


	const from = handleConvert(searchPayload?.from);
	const to = handleConvert(searchPayload?.to);

	const fileName = `transactions_${from}_${to}.${exportType === ExportType.PDF ? 'pdf' : 'xlsx'}`;


	const getPdfStatementPreview = async () => {
		setLoading(true);
		try {

			const result = await getStatementPdfContent(searchPayload);
			setPreviewFile(result);
			setLoading(false);
		}
		catch (e) {
			setLoading(false);
			setPreviewFile(null);
			showException(e);
			throw e;
		}
	};


	const downloadExcelTrx = useCallback(async () => {
		try {
			setLoading(true);
			await getExcel(searchPayload, fileName);
		}
		catch (e) {
			showException(e);
		} finally {
			setLoading(false);
		}


	}, [fileName,
		searchPayload
	]);


	const downloadExcelStatement = useCallback(async () => {

		const payload = searchPayload;

		if (
			searchPayload.type === AccountType.CARD
			|| searchPayload.type === AccountType.BANK
			|| searchPayload.type === AccountType.BANK_AND_CARD
			|| searchPayload.type === AccountType.TOKEN
		) {
			payload.templateIdentifier = 'transactions.sheet.template.extended';
		}

		try {
			setLoading(true);
			await getExtendedExcel(payload, fileName);
		}
		catch (e) {
			showException(e);
		} finally {
			setLoading(false);
		}
	}, [fileName,
		searchPayload
	]);


	return { loading, fileName, previewFile, downloadExcelStatement, downloadExcelTrx, getPdfStatementPreview } as const;

};

export default useTransactionDownload;
