import * as React from 'react';

import { Button, Dialog, Grid, IconButton, InputAdornment } from '@mui/material';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import DialogContentNoScrollbar from '@/componentsMui/Shared/Widgets/Dialog/DialogContentNoScrollbar';
import { useTranslation } from 'react-i18next';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { showErrorNotification } from '@/features/swal/slice';
import { PaymentLine, PaymentLinePayload } from '@/features/bulkpayments/bulkPaymentsTypes';
import FormPaperLargeFixed from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeFixed';
import AccountDropdown from '@/componentsMui/Shared/FormikComponents/AccountDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { useState } from 'react';
import { BankAccount } from '@/features/account/types';
import FormContentBox from '@/componentsMui/Shared/Widgets/Box/FormContentBox';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import NumberFormat from 'react-number-format';
import Dropdown from '@/componentsMui/Shared/FormikComponents/Dropdown';
import { maxTwoDecimals } from '@/helpers/number';
import { isValidIBAN } from 'ibantools';
import { putPaymentLine } from '@/features/bulkpayments/bulkPaymentsSlice';
import { NEW_LINE_REGEX } from '@/helpers/customHook/useValidations';

interface Props {
	open: boolean,
	onClose: () => void,
	paymentLine: PaymentLine
}

const EditPaymentLineForm = ({ open, onClose, paymentLine }: Props) => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();

	const { list: allAccounts } = useSelector((state: RootState) => state.accounts);
	const sourceAccountList = allAccounts ?
		allAccounts.filter(account => account.currency === 'EUR' || account.currency === 'GBP')
		: [];
	const [selectedAccount, setSelectedAccount] = useState<BankAccount>(sourceAccountList.find(a => a.walletId === paymentLine.sourceAccount) ?? null);
	const [amount, setAmount] = useState<string>(paymentLine?.amount?.toString() ?? '');

	const paymentTypeOptions = [
		{
			value: 'CHAPS',
			key: 'CHAPS'
		},
		{
			value: 'FPS',
			key: 'FPS',
		}
	];

	const initialValues = {
		sourceAccount: paymentLine?.sourceAccount ?? '',
		amount: paymentLine?.amount ?? '',
		currency: paymentLine?.ccy ?? '',
		type: paymentLine?.paymentType ?? '',
		beneficiaryName: paymentLine?.beneficiaryName ?? '',
		beneficiaryCompanyRegistrationNr: paymentLine?.beneficiaryCompanyRegistrationNr ?? '',
		beneficiaryIban: paymentLine?.beneficiaryIban ?? '',
		beneficiaryAccountNumber: paymentLine?.beneficiaryAccountNumber ?? '',
		beneficiarySortCode: paymentLine?.beneficiarySortCode ?? '',
		paymentReason: paymentLine?.paymentReason ?? '',
	};

	const validationSchema = Yup.object({
		amount: Yup.number().typeError(t('form.validator.required')).required(t('form.validator.required')).nullable()
			.moreThan(0, t('sendmoneyTranslation.data.moreThanZero'))
			.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value)),
		sourceAccount: Yup.string()
			.required(t('form.validator.required')),
		beneficiaryName: Yup.string()
			.required(t('form.validator.required')),
		type: Yup.string()
			.required(t('form.validator.required')),
		beneficiaryIban: Yup.string().when('currency', {
			is: 'EUR',
			then: Yup.string()
				.required(t('form.validator.required').toString()).test('beneficiaryIban',
					t('sendmoneyTranslation.data.invalidIban'),
					(val) => isValidIBAN(val)),
			otherwise: Yup.string().nullable().notRequired(),
		}),
		beneficiaryAccountNumber: Yup.string().when('currency', {
			is: 'GBP',
			then: Yup.string()
				.required(t('form.validator.required').toString()),
			otherwise: Yup.string().nullable().notRequired(),
		}),
		beneficiarySortCode: Yup.string().when('currency', {
			is: 'GBP',
			then: Yup.string()
				.required(t('form.validator.required').toString()),
			otherwise: Yup.string().nullable().notRequired(),
		}),
		paymentReason: Yup.string().trim().required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-./\s])+$/, t('form.validator.latinOnlyReference'))
			.min(6, t('form.validator.minChars', { field: t('sendmoneyTranslation.data.reference'), chars: '6' }))
			.max(140, t('sendmoneyTranslation.data.canNotExceedHundred'))
			.test('reference', t('form.validator.newLine'), (value) => value ? value.search(NEW_LINE_REGEX) === -1 : true)
			.test('reference', t('form.validator.minChars', { field: t('sendmoneyTranslation.data.reference'), chars: '6' }), (value) => value ? value.replace(/ /g, '').length >= 6 : true),
	});

	const handleAmountChange = (event: React.ChangeEvent<any>, setFieldValue, setFieldTouched) => {
		setFieldValue('amount', event.target.value);
		setAmount(event.target.value);
		setTimeout(() => setFieldTouched('amount', true));
	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		const { paymentReason, amount, currency, type, beneficiaryName, beneficiaryCompanyRegistrationNr, beneficiaryIban, beneficiaryAccountNumber, beneficiarySortCode } = formData;

		const payload: PaymentLinePayload = {
			id: paymentLine.id,
			sessionId: paymentLine.sessionId,
			sourceAccount: selectedAccount.walletId,
			paymentReason,
			amount,
			ccy: currency,
			beneficiaryName,
			beneficiaryCompanyRegistrationNr,
			beneficiaryIban,
			beneficiaryAccountNumber,
			paymentType: type,
			beneficiarySortCode,
		};

		try {
			setSubmitting(true);
			dispatch(putPaymentLine(payload));
		} catch (err) {
			console.log(err);
			showErrorNotification(err);
		} finally {
			setSubmitting(false);
			onClose();
		}
	};

	const handleAccountChange = (account: BankAccount, setFieldValue: any) => {
		setSelectedAccount(account);
		setFieldValue('currency', account.currency);
		if (account.currency === 'EUR') {
			setFieldValue('paymentType', 'SEPA');
		} else {
			setFieldValue('paymentType', '');
		}
	};

	return (
		<Dialog
			id="edit-payment-line-form"
			open={open}
			onClose={onClose}
			PaperComponent={FormPaperLargeFixed}
		>
			<DialogContentNoScrollbar>
				<Grid
					container
					sx={{ p: { xs: '1rem', md: '2rem' } }}
				>
					<Grid item container justifyContent="space-between" alignItems="center" pb={4}>
						<Grid item>
							<MuiHeader>{t('bulkpayments.fields.editPaymentLine')}</MuiHeader>
						</Grid>
						<Grid item>
							<IconButton onClick={onClose}  >
								<MuiCloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item container spacing={1}>
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={submit}
						>
							{({ errors, isSubmitting, setFieldValue, setFieldTouched }) => (
								<Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
									<FormContentBox noBackground={true} className='form-content_main-area wp'>
										<Grid container columnSpacing={3} rowSpacing={1} >
											<Grid item xs={12}>
												<AccountDropdown
													fieldlabel={t('bulkpayments.fields.sourceAccount')}
													fieldName='sourceAccount'
													accountList={sourceAccountList}
													selectedAccount={selectedAccount}
													onAccountChange={(value: BankAccount) => handleAccountChange(value, setFieldValue)} />
											</Grid>
											<Grid item xs={12}>
												<MuiTextField
													name='currency'
													label={t('bulkpayments.fields.currency')}
													value={selectedAccount?.currency ?? ''}
													disabled={true}
													fullWidth
												/>
											</Grid>
											{selectedAccount && selectedAccount.currency === 'EUR' ? <>
												<Grid item xs={12}>
													<MuiTextField
														name='type'
														label={t('bulkpayments.fields.type')}
														value='SEPA'
														disabled
														fullWidth
													/>
												</Grid>
											</> : <>
												<Grid item xs={12}>
													<Dropdown
														options={paymentTypeOptions}
														fieldLabel={t('bulkpayments.fields.type')}
														fieldName='type' />
												</Grid>
											</>}
											<Grid item xs={12}>
												<NumberFormat
													name="amount"
													decimalScale={2}
													customInput={MuiTextField}
													onChange={(event) => handleAmountChange(event, setFieldValue, setFieldTouched)}
													allowNegative={false}
													value={amount}
													displayType={'input'}
													placeholder={'0.00'}
													fullWidth
													label={t('bulkpayments.fields.amount')}
													inputProps={{ autoComplete: 'off' }}
													InputProps={{
														startAdornment: <InputAdornment position="start">{selectedAccount?.currency ?? ''}</InputAdornment>,
													}}
												/>
											</Grid>
											<Grid item xs={12}>
												<MuiTextField
													name='beneficiaryName'
													label={t('bulkpayments.fields.beneName')}
													fullWidth
												/>
											</Grid>
											<Grid item xs={12}>
												<MuiTextField
													name='beneficiaryCompanyRegistrationNr'
													label={t('bulkpayments.fields.beneId')}
													fullWidth
												/>
											</Grid>
											{selectedAccount && selectedAccount.currency === 'EUR' ? <>
												<Grid item xs={12}>
													<MuiTextField
														name='beneficiaryIban'
														label={t('bulkpayments.fields.iban')}
														fullWidth
													/>
												</Grid>
											</> : <>
												<Grid item xs={12}>
													<MuiTextField
														name='beneficiaryAccountNumber'
														label={t('bulkpayments.fields.accountNumber')}
														fullWidth
													/>
												</Grid>
												<Grid item xs={12}>
													<MuiTextField
														name='beneficiarySortCode'
														label={t('bulkpayments.fields.sortCode')}
														fullWidth
													/>
												</Grid>
											</>}
											<Grid item xs={12}>
												<MuiTextField
													name='paymentReason'
													label={t('bulkpayments.fields.paymentReason')}
													fullWidth
													multiline
												/>
											</Grid>
										</Grid>
										<Grid container mt='auto' pt='2rem' justifyContent='space-between' >
											<Button
												key='buttonCancel'
												variant='customOutlined'
												onClick={onClose}
											>   	{t('form.buttons.close')}
											</Button>
											<Button
												key='prieview'
												type='submit'
												disabled={!isEmpty(errors) || isSubmitting}
												variant='contained'> 	{t('form.buttons.edit')}
											</Button>
										</Grid>
									</FormContentBox>
								</Form>
							)}
						</Formik >
					</Grid>
				</Grid>
			</DialogContentNoScrollbar>
		</Dialog >
	);
};



export default EditPaymentLineForm; 
