import { RootState } from '@/rootReducer';
import { Box, Grid, IconButton } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SearchField from '@/componentsMui/Shared/Widgets/SearchField';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import FormHeader from '../Deposit/Components/FormHeader';
import FormCloseButton from '../Deposit/Components/FormCloseButton';
import TokenAvatarLine from '@/componentsMui/Shared/Widgets/Avatars/TokenAvatarLine';

import { ReactComponent as MuiChevronRight } from '@/assets/MuiChevronRight.svg';
import { CoinType, CoinTypeEnum } from '@/features/coins/types';
import _ from 'lodash';
import bigDecimal from 'js-big-decimal';

interface Props {
	onNext: (value: CoinType) => void,
	onBack?: () => void,
	symbol?: string,
	hideTitle?: boolean,
	showOwnedOnly?: boolean,
	includeWallet?: boolean,
	showOnlySendEnabled?: boolean
}

const TokenAssetSelectStep = ({ onBack, onNext, symbol, hideTitle, showOwnedOnly, includeWallet, showOnlySendEnabled }: Props): React.ReactElement => {

	const closeModal = useCloseDialogNoEvent();
	const { t } = useTranslation('translations');
	const coins = useSelector((state: RootState) => state.tokens.list);
	const [search, setSearch] = React.useState<string>(symbol ? symbol : '');
	const handleSearchRequest = (e) => {
		setSearch(e.target.value);
	};


	let tmp = showOwnedOnly ? coins.filter(p => bigDecimal.compareTo(p.account?.availableBalance, 0.00001) > 0) : coins;
	tmp = showOnlySendEnabled ? tmp.filter(p => p.info.sendEnabled) : tmp;
	const list = includeWallet ? tmp : tmp.filter(p => p.type !== CoinTypeEnum.WALLET);

	const sortedList = _.sortBy(list, p => p.info.symbol);

	const filredTokens = search === '' ? sortedList : sortedList.filter(t => t.info.symbol.toUpperCase().includes(search.toUpperCase()));

	return (
		< Grid id='deposit-select-asset'
			container
			flex={1}
			direction='column'
			minHeight='0'
			flexWrap='nowrap'
			rowSpacing={2}>
			{!hideTitle && <Grid item >
				<FormHeader title={t('wallet.deposit.selectAsset')} onBack={onBack} showBack={onBack ? true : false} />
			</Grid>
			}
			<Grid item>
				<SearchField value={search} fullWidth
					onChange={(e) => handleSearchRequest(e)} />
			</Grid>
			<Grid item overflow='auto'>
				<Box mr='1rem'>
					{filredTokens.length > 0 ? filredTokens.map((item) => (
						<Grid key={`${item.type}-${item.info.symbol}`}
							maxHeight='20rem'
							onClick={() => onNext(item)}
							alignItems='center'
							container
							justifyContent='space-between'
							sx={{ cursor: 'pointer' }}>
							<Grid item>
								<TokenAvatarLine logo={item?.info?.logo} displayName={item?.info?.displayName} symbol={item?.info?.symbol} availableBalance={item?.account?.availableBalance} precision={item?.account?.precision} />
							</Grid>
							<Grid item>
								<IconButton    >
									<MuiChevronRight />
								</IconButton>
							</Grid>
							<Grid item xs={12}>
								<StyledDivider />
							</Grid>
						</Grid>))
						: <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}> {t('wallet.deposit.noRecordsToShow')}</div>
					}
				</Box>
			</Grid>
			<FormCloseButton onClose={closeModal} />
		</ Grid >
	);
};

export default TokenAssetSelectStep;
