
export const loadChallangeRecapture = (callback) => {
	const id = 'recaptcha-challenge-key';

	const url = 'https://www.google.com/recaptcha/api.js?';
	const isScriptExist = document.getElementById(id);

	if (!isScriptExist) {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.src = url;
		script.id = id;
		script.onload = function () {
			if (callback) callback();
		};
		document.body.appendChild(script);
	}

	if (isScriptExist && callback) callback();
};

export const rederChallenge = async (callback, siteKey: string) => {
	const w = window as any;
	if (w.grecaptcha?.render) {
		w.grecaptcha.render('recaptcha-challenge-id', {
			'sitekey': siteKey,
			'theme': 'dark',
			'action': 'auth',
			'callback': callback
		});
	}
};
