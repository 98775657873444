import SummaryCardIcon from '@/componentsMui/Shared/CustomIcons/SummaryCardIcon';
import SummaryCryptoIcon from '@/componentsMui/Shared/CustomIcons/SummaryCryptoIcon';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { Grid, Theme, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import NumberFormat from 'react-number-format';
import { ChevronRight } from '@mui/icons-material';

interface Props {
    iconType: 'CARD' | 'CRYPTO' | 'ACCOUNT',
    amount: number | string,
    currency: string,
    text: string,
    onClick: () => void

}

const SummaryBox = ({ iconType, amount, currency, text, onClick }: Props) => {

    const theme = useTheme() as Theme;
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));


    return (<Grid
        container
        p='30px 20px'
        borderRadius={theme.mainBorderRadius}
        bgcolor={theme.bgSecondaryButton}
        alignItems='center'
        sx={{ cursor: 'pointer' }} onClick={onClick}
    >
        <Grid item xs={12}  md={2} minWidth='64px'>
            {iconType === 'CARD' ?
                <SummaryCardIcon height={64} width={64} /> :
                <SummaryCryptoIcon height={64} width={64} />}

        </Grid>
        <Grid item container xs={12} md>
            <Grid item xs={12}>
                <MuiHeader>
                    <NumberFormat
                        displayType={'text'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandsGroupStyle='thousand'
                        thousandSeparator={true}
                        isNumericString={typeof amount === 'string'}
                        value={amount}
                        prefix={`${currency} `} />

                </MuiHeader>
            </Grid>
            {!smallScreen && <Grid item xs={12} container alignItems='center'>
                <MuiFormText color={theme.form.textSecondary} pr='6px'>
                    {text}
                </MuiFormText>
                <ChevronRight sx={{ color: theme.form.textSecondary, fontSize: '0.875rem' }} />
            </Grid>
            }
        </Grid>

    </Grid>);
};

export default SummaryBox;
