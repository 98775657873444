import * as React from 'react';

import '@/componentsMui/Dashboard/Create/Create.css';

import { Box, Button, Grid } from '@mui/material'; 
import AccountOption from './AccountOption';
import FormLayout from '@/componentsMui/Shared/Widgets/Dialog/FormLayout';
import { useTranslation } from 'react-i18next';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { AccountType } from './CreateAccountForm';

export interface PickStepProps {
	currentOperation: any,
	operations: any,
	accountType: AccountType,
	setOperation: (value: any) => void,
	nextStep: () => void,
	closeModal: (e: any) => void,
	setBgIndex: (value: number) => void,
}

const PickStep = ({ currentOperation, accountType, operations, setOperation, nextStep, closeModal, setBgIndex }: PickStepProps) => {

	const { t } = useTranslation('translations');
	const [accountIndex, setAccountIndex] = React.useState(null);
	const handleAccountClick = (index: number,) => { setAccountIndex(index); };

	const mainContent = <div className="form-pick-product-list">
		{<>
			<Box mb={2}>
				<MuiHeader>{t('create.createAccount')}</MuiHeader>
			</Box>
			<Grid container spacing={2}>
				{
					operations.map((option, idx) => {
						return (
							<Grid key={idx} item >
								<AccountOption
									selected={idx === accountIndex}
									key={`${Math.random()}`}
									bgIndex={idx}
									operation={option}
									type={accountType}
									onClick={() => { handleAccountClick(idx), setOperation(option); setBgIndex(idx);}}
								/>
							</Grid>
						);
					})
				}
			</Grid>
		</>
		}
	</div >;

	const buttonContent = [
		<Button
			key="buttonCancel"
			className='customDialogButton'
			variant='contained'
			color='secondary'
			onClick={closeModal}
		>   	{t('form.buttons.cancel')}
		</Button>,
		<Button
			key="buttonNext"
			className='customDialogButton'
			variant='contained'
			onClick={nextStep}
			disabled={currentOperation === null}
			autoFocus> 	{t('form.buttons.next')}
		</Button>];

	return (<FormLayout
		contentPart={mainContent}
		buttonPart={buttonContent}
	/>
	);
};

export default PickStep;

