import { getCardPin } from '@/features/card/slice';
import { Card } from '@/features/card/types';
import { showException } from '@/features/swal/slice';
// import _ from 'lodash';
import { useCallback, useState } from 'react';

//const cardProcesses = tranformSubProcessByType(cardDetail.subProcesses);

// const getCardPin = !isEmpty(cardProcesses[CardSubProcesses.CARD_GET_PIN]);
// const getCardCVV = !isEmpty(cardProcesses[CardSubProcesses.CARD_GET_CVV]);
// const operationCardDetails = cardProcesses[CardSubProcesses.CARD_GET_CVV]?.proc;
// const operationGetPin = cardProcesses[CardSubProcesses.CARD_GET_PIN]?.proc;

const useCardPin = (card: Card) => {

    const defaultCode = '•••';
    const [pin, setPin] = useState(defaultCode);
    const [loading, setLoading] = useState(false);
    const showGetPin = !card.virtual;

    const fetchCardPin = useCallback(async (vfCode?: string, onError?: (e: any) => void) => {
        setLoading(true);
        try {
            const result = await getCardPin(card.cardId, vfCode);
            setPin(result.pin);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            onError ? onError(e) :
                await showException(e);
        }
    }, [card.cardId]);

    return { fetchCardPin, pin, loading, showGetPin, defaultPinCode: defaultCode, isPinShown: pin !== defaultCode };

};

export default useCardPin;
