import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Autocomplete, FormControlLabel, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import StyledCheckbox from '@/componentsMui/Shared/Widgets/StyledCheckbox';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterDateFns';
import MuiSubHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiSubHeader';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import FileUpload, { FileStatusEnum } from '@/componentsMui/Shared/Components/FileUpload/FileUpload';
import { ShareholderType } from '@/features/business/types';
import { useAddress, useCompanyName, useCompanyRegistration, useDate, useEmail, useName, usePhone } from '@/helpers/customHook/useValidations';
import StyledPaper from '@/componentsMui/Shared/Widgets/StyledPaper';
import CountryCodesAlpha3 from '@/componentsMui/Shared/Widgets/CountryCodesAlpha3';
import { getLookupValues } from '@/features/fnd/fndSlice';
import ButtonStyleTabs from '@/componentsMui/Shared/Widgets/Tab/ButtonStyleTabs';
import ButtonStyleTab from '@/componentsMui/Shared/Widgets/Tab/ButtonStyleTab';
import { BeneficiaryType } from '@/helpers/globalTypes';

interface Props {
	shareholder?: ShareholderType,
	id: string,
	allowRemove: boolean,
	updateForm?: (idx: string, handleSubmit: any, validateForm: any, errors: any, isSubmitting: boolean, isDirty: boolean, values: any) => void,
	removeForm?: (idx: string) => void,
	doNotAllowUpdate: boolean
}

const documentTypeOptions = [
	{ code: 'PASSPORT', label: 'Passport' },
	{ code: 'ID_CARD', label: 'ID card' }
];

const BusinessShareholderItem = ({ id, allowRemove, shareholder, updateForm, removeForm, doNotAllowUpdate }: Props): React.ReactElement => {
	const values = shareholder;
	const { t } = useTranslation('translations');
	const [companyTypeOptions, setCompanyTypeOptions] = useState([]);
	const [type, setType] = useState<string>(values?.shareHolderType ?? BeneficiaryType.INDIVIDUAL);

	const getLov = useCallback(async (name: string) => {
		try {
			const result = await getLookupValues(name);
			const options = result?.map(p => ({
				code: p.lookupCode,
				label: p.description
			})) || [];

			if (name === 'TYPE_OF_COMPANY') {
				setCompanyTypeOptions(options);
			}
		}
		catch (e) {
			console.log(e);
		}
	}, []);

	useEffect(() => {
		getLov('TYPE_OF_COMPANY');
	}, [getLov]);

	const firstNameValidation = useName(t('createbusiness.directors.firstName'));
	const lastNameValidation = useName(t('createbusiness.directors.lastName'));
	const dateValidation = useDate(t('createbusiness.directors.birthDate'));
	const addressValidation = useAddress(t('createbusiness.shareholders.shareHolderAddressLine1'));
	const emailValidation = useEmail();
	const phoneValidation = usePhone();
	const nameValidation = useCompanyName(t('createbusiness.information.name'));
	const registrationValidation = useCompanyRegistration(t('createbusiness.information.registration'));

	const initialValues = {
		shareHolderFirstName: values?.shareHolderFirstName ?? '',
		shareHolderLastName: values?.shareHolderLastName ?? '',
		shareHolderBirthDate: values?.shareHolderBirthDate ?? '',
		shareHolderPercentageOfOwnerShip: values?.shareHolderPercentageOfOwnerShip ?? '',
		shareHolderPOA: values?.shareHolderPOA ?? {},
		shareHolderPep: values?.shareHolderPep ?? '',
		shareHolderUsCitizen: values?.shareHolderUsCitizen ?? '',
		shareHolderDocumentType: values?.shareHolderDocumentType ?? '',
		shareHolderDocumentFrontSide: values?.shareHolderDocumentFrontSide ?? {},
		shareHolderDocumentBackSide: values?.shareHolderDocumentBackSide ?? {},
		shareHolderCountry: values?.shareHolderCountry ?? '',
		shareHolderAddress: values?.shareHolderAddress ?? '',
		shareHolderPostTown: values?.shareHolderCity ?? '',
		shareHolderPostCode: values?.shareHolderPostalCode ?? '',
		shareHolderPhoneNumber: values?.shareHolderPhonenumber ?? '',
		shareHolderEmail: values?.shareHolderEmail ?? '',
		shareHolderCompanyName: values?.shareHolderCompanyName ?? '',
		shareHolderRegistrationNumber: values?.shareHolderRegistrationNumber ?? '',
		shareHolderIncorporationDate: values?.shareHolderIncorporationDate ?? '',
		shareHolderCompanyType: values?.shareHolderCompanyType ?? '',
		shareHolderCertificateOfIncorporationDocument: values?.shareHolderCertificateOfIncorporationDocument ?? '',
		shareHolderDisclosingShareholdersDocument: values?.shareHolderDisclosingShareholdersDocument ?? '',
		shareHolderDisclosingDirectorsDocument: values?.shareHolderDisclosingDirectorsDocument ?? '',
		shareHolderIdOfDirectorsDocument: values?.shareHolderIdOfDirectorsDocument ?? '',
		shareHolderType: values?.shareHolderType ?? BeneficiaryType.INDIVIDUAL,
	};

	const [fileStatus, setFileStatus] = useState(values?.shareHolderPOA ? FileStatusEnum.SUCCESS : '');
	const [frontFileStatus, setFrontFileStatus] = useState(values?.shareHolderDocumentFrontSide ? FileStatusEnum.SUCCESS : '');
	const [backFileStatus, setBackFileStatus] = useState(values?.shareHolderDocumentBackSide ? FileStatusEnum.SUCCESS : '');
	const [certificateOfIncorporationFileStatus, setCertificateOfIncorporationFileStatus] = useState(values?.shareHolderCertificateOfIncorporationDocument ? FileStatusEnum.SUCCESS : '');
	const [disclosingShareholdersFileStatus, setDisclosingShareholdersFileStatus] = useState(values?.shareHolderDisclosingShareholdersDocument ? FileStatusEnum.SUCCESS : '');
	const [disclosingDirectorsFileStatus, setDisclosingDirectorsFileStatus] = useState(values?.shareHolderDisclosingDirectorsDocument ? FileStatusEnum.SUCCESS : '');
	const [idOfDirectorsFileStatus, setIdOfDirectorsFileStatus] = useState(values?.shareHolderIdOfDirectorsDocument ? FileStatusEnum.SUCCESS : '');
	const [documentType, setDocumentType] = useState(values?.shareHolderDocumentType);


	const validationSchema = Yup.object({
		...(type === BeneficiaryType.INDIVIDUAL ? {

			shareHolderPOA: Yup.object()
				.test('shareHolderPOA', fileStatus,
					() => { return (fileStatus === FileStatusEnum.SUCCESS); })
				.nullable(),

			shareHolderPep: Yup.boolean()
				.required(t('form.validator.required'))
				.test('directorPep', 'createbusiness.directors.pep',
					(value) => value === false),
			shareHolderUsCitizen: Yup.boolean()
				.required(t('form.validator.required'))
				.test('directorPep', 'createbusiness.directors.pep',
					(value) => value === false),

		} : {
			shareHolderFirstName: firstNameValidation,
			shareHolderLastName: lastNameValidation,
			shareHolderBirthDate: dateValidation,
			shareHolderCompanyName: nameValidation,
			shareHolderRegistrationNumber: registrationValidation,
			shareHolderIncorporationDate: dateValidation,
			shareHolderCompanyType: Yup.mixed()
				.required(t('form.validator.required')),
			shareHolderDisclosingDirectorsDocument: Yup.object()
				.test('shareHolderDisclosingDirectorsDocument', disclosingDirectorsFileStatus,
					() => { return (disclosingDirectorsFileStatus === FileStatusEnum.SUCCESS); })
				.nullable(),
			shareHolderCertificateOfIncorporationDocument: Yup.object()
				.test('shareHolderCertificateOfIncorporationDocument', certificateOfIncorporationFileStatus,
					() => { return (certificateOfIncorporationFileStatus === FileStatusEnum.SUCCESS); })
				.nullable(),
			shareHolderDisclosingShareholdersDocument: Yup.object()
				.test('shareHolderDisclosingShareholdersDocument', disclosingShareholdersFileStatus,
					() => { return (disclosingShareholdersFileStatus === FileStatusEnum.SUCCESS); })
				.nullable(),
			shareHolderIdOfDirectorsDocument: Yup.object()
				.test('shareHolderIdOfDirectorsDocument', idOfDirectorsFileStatus,
					() => { return (idOfDirectorsFileStatus === FileStatusEnum.SUCCESS); })
				.nullable(),
		}),
		shareHolderFirstName: firstNameValidation,
		shareHolderLastName: lastNameValidation,
		shareHolderBirthDate: dateValidation,
		shareHolderDocumentFrontSide: Yup.object()
			.test('shareHolderDocumentFrontSide', frontFileStatus,
				() => { return (frontFileStatus === FileStatusEnum.SUCCESS); })
			.nullable(),
		shareHolderDocumentBackSide: Yup.object()
			.test('shareHolderDocumentBackSide', backFileStatus,
				() => { return (documentType === 'PASSPORT' || backFileStatus === FileStatusEnum.SUCCESS); })
			.nullable(),
		shareHolderDocumentType: Yup.string()
			.required(t('form.validator.required')),
		shareHolderPercentageOfOwnerShip: Yup.number()
			.label(t('createbusiness.shareholders.ownerShip'))
			.min(0)
			.max(100)
			.required(t('form.validator.required')),
		shareHolderCountry: Yup.string()
			.required(t('form.validator.required')),
		shareHolderPostTown: Yup.string()
			.label(t('createbusiness.shareholders.shareHolderPostTown'))
			.required(t('form.validator.required'))
			.max(50),
		shareHolderPostCode: Yup.string().required(t('form.validator.required')),
		shareHolderAddress: addressValidation,
		shareHolderPhoneNumber: phoneValidation,
		shareHolderEmail: emailValidation,
	});

	const handleTypeChange = (
		newValue
	) => {
		const value = newValue;
		if (value != null) {
			setType(value);
			formik.setFieldValue('shareHolderType', value);
			formik.setFieldTouched('shareHolderType', true);
		}
	};


	// React.useEffect(() => {
	// 	if (formik) {
	// 		formik.validateForm();
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const submit = () => {
		//do nothing, use effect handles actual submit
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: submit,
	});

	useEffect(() => {
		updateForm(id, formik.handleSubmit, formik.validateForm, formik.errors, formik.isSubmitting, formik.dirty, formik.values);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, formik.errors, formik.isSubmitting, formik.dirty, formik.values]);

	return (
		<form
			id="business-shareholders"
			onSubmit={formik.handleSubmit}>
			<Grid container spacing={2}>
				{allowRemove &&
					<Grid item xs={12} mt='1rem' mb='1rem'>
						<StyledDivider />
					</Grid>}
				<Grid item xs={12} container justifyContent='space-between'>
					<MuiSubHeader>{t('createbusiness.shareholders.shareholder')} - {type === BeneficiaryType.BUSINESS ? formik.values.shareHolderCompanyName : `${formik.values.shareHolderFirstName}  ${formik.values.shareHolderLastName}`}</MuiSubHeader>
					{allowRemove &&
						< IconButton onClick={() => removeForm(id)}  >
							<MuiCloseIcon />
						</IconButton>}
				</Grid>
				<Grid item xs={12} justifyContent={'start'}>
					<ButtonStyleTabs
						value={type}
						onChange={(event, newValue) => handleTypeChange(newValue)}
					>
						<ButtonStyleTab value={BeneficiaryType.INDIVIDUAL} label={t('sendmoneyTranslation.data.individual')} />
						<ButtonStyleTab value={BeneficiaryType.BUSINESS} label={t('sendmoneyTranslation.data.business')} />
					</ButtonStyleTabs>
				</Grid>
				{type === BeneficiaryType.INDIVIDUAL ?
					<>
						<Grid item xs={6}>
							<TextField
								id="shareHolderFirstName"
								name="shareHolderFirstName"
								fullWidth
								inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
								label={t('createbusiness.shareholders.firstName')}
								value={formik.values.shareHolderFirstName}
								onChange={formik.handleChange}
								error={formik.errors.shareHolderFirstName && Boolean(formik.touched.shareHolderFirstName)}
								helperText={formik.errors.shareHolderFirstName} />
						</Grid>
						<Grid item xs={6}>
							<TextField
								id="shareHolderLastName"
								name="shareHolderLastName"
								fullWidth
								inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
								label={t('createbusiness.shareholders.lastName')}
								value={formik.values.shareHolderLastName}
								onChange={formik.handleChange}
								error={formik.errors.shareHolderLastName && Boolean(formik.touched.shareHolderLastName)}
								helperText={formik.errors.shareHolderLastName} />
						</Grid>
						<Grid item xs={6}>
							<LocalizationProvider dateAdapter={DateAdapter}>
								<DesktopDatePicker
									onChange={(value) => { formik.setFieldValue('shareHolderBirthDate', value); }}
									value={formik.values.shareHolderBirthDate}
									label={t('createbusiness.shareholders.birthDate')}
									inputFormat="dd/MM/yyyy"
									disableFuture={true}
									minDate={new Date('1900-01-01')}
									openTo="year"
									views={['year', 'month', 'day']}
									renderInput={(params) => (
										<TextField
											name="shareHolderBirthDate"
											label={t('createbusiness.shareholders.birthDate')}
											fullWidth
											placeholder='DD/MM/YYYY'
											{...params}
											inputProps={{
												...params.inputProps,
												autoComplete: 'off',
												readOnly: doNotAllowUpdate
											}}
											error={formik.errors.shareHolderBirthDate && Boolean(formik.touched.shareHolderBirthDate)}
											helperText={formik.errors.shareHolderBirthDate}
										/>)}

								/>
							</LocalizationProvider>
						</Grid>
					</> : null}
				{type === BeneficiaryType.BUSINESS ?
					<><Grid item xs={6}>
						<TextField
							id="shareHolderCompanyName"
							name="shareHolderCompanyName"
							fullWidth
							inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
							label={t('createbusiness.shareholders.companyName')}
							value={formik.values.shareHolderCompanyName}
							onChange={formik.handleChange}
							error={formik.errors.shareHolderCompanyName && Boolean(formik.touched.shareHolderCompanyName)}
							helperText={formik.errors.shareHolderCompanyName} />
					</Grid>
						<Grid item xs={6}>
							<TextField
								id="shareHolderRegistrationNumber"
								name="shareHolderRegistrationNumber"
								fullWidth
								inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
								label={t('createbusiness.shareholders.registrationNumber')}
								value={formik.values.shareHolderRegistrationNumber}
								onChange={formik.handleChange}
								error={formik.errors.shareHolderRegistrationNumber && Boolean(formik.touched.shareHolderRegistrationNumber)}
								helperText={formik.errors.shareHolderRegistrationNumber} />
						</Grid>
						<Grid item xs={6}>
							<LocalizationProvider dateAdapter={DateAdapter}>
								<DesktopDatePicker
									onChange={(value) => { formik.setFieldValue('shareHolderIncorporationDate', value); }}
									value={formik.values.shareHolderIncorporationDate}
									label={t('createbusiness.shareholders.incDate')}
									inputFormat="dd/MM/yyyy"
									disableFuture={true}
									minDate={new Date('1900-01-01')}
									openTo="year"
									views={['year', 'month', 'day']}
									renderInput={(params) => (
										<TextField
											name="shareHolderIncorporationDate"
											label="shareHolderIncorporationDate"
											fullWidth
											placeholder='DD/MM/YYYY'
											{...params}
											inputProps={{
												...params.inputProps,
												autoComplete: 'off',
												readOnly: doNotAllowUpdate
											}}
											error={formik.errors.shareHolderIncorporationDate && Boolean(formik.touched.shareHolderIncorporationDate)}
											helperText={formik.errors.shareHolderIncorporationDate}
										/>)}

								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={6}>
							<Autocomplete
								readOnly={doNotAllowUpdate}
								id='shareHolderCompanyType'
								options={companyTypeOptions}
								value={formik.values.shareHolderCompanyType}
								onChange={(e, value) => {
									formik.setFieldValue('shareHolderCompanyType', typeof value === 'object' ? value.label : '');
								}}
								disableClearable
								PaperComponent={({ children }) => (
									<StyledPaper>{children}</StyledPaper>
								)}
								isOptionEqualToValue={(option, value) => value === undefined || option.code === value.code || value?.code === ''}
								renderInput={(params) => <TextField
									name="shareHolderCompanyType" {...params}
									label={t('createbusiness.shareholders.companyType')}
									error={formik.errors.shareHolderCompanyType && Boolean(formik.touched.shareHolderCompanyType)}
									helperText={formik.errors.shareHolderCompanyType}
								/>}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								id="shareHolderFirstName"
								name="shareHolderFirstName"
								fullWidth
								inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
								label={t('createbusiness.shareholders.uboFirstName')}
								value={formik.values.shareHolderFirstName}
								onChange={formik.handleChange}
								error={formik.errors.shareHolderFirstName && Boolean(formik.touched.shareHolderFirstName)}
								helperText={formik.errors.shareHolderFirstName} />
						</Grid>
						<Grid item xs={6}>
							<TextField
								id="shareHolderLastName"
								name="shareHolderLastName"
								fullWidth
								inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
								label={t('createbusiness.shareholders.uboLastName')}
								value={formik.values.shareHolderLastName}
								onChange={formik.handleChange}
								error={formik.errors.shareHolderLastName && Boolean(formik.touched.shareHolderLastName)}
								helperText={formik.errors.shareHolderLastName} />
						</Grid>
						<Grid item xs={6}>
							<LocalizationProvider dateAdapter={DateAdapter}>
								<DesktopDatePicker
									onChange={(value) => { formik.setFieldValue('shareHolderBirthDate', value); }}
									value={formik.values.shareHolderBirthDate}
									label={t('createbusiness.shareholders.uboBirthDate')}
									inputFormat="dd/MM/yyyy"
									disableFuture={true}
									minDate={new Date('1900-01-01')}
									openTo="year"
									views={['year', 'month', 'day']}
									renderInput={(params) => (
										<TextField
											name="shareHolderBirthDate"
											label={t('createbusiness.shareholders.uboBirthDate')}
											fullWidth
											placeholder='DD/MM/YYYY'
											{...params}
											inputProps={{
												...params.inputProps,
												autoComplete: 'off',
												readOnly: doNotAllowUpdate
											}}
											error={formik.errors.shareHolderBirthDate && Boolean(formik.touched.shareHolderBirthDate)}
											helperText={formik.errors.shareHolderBirthDate}
										/>)}

								/>
							</LocalizationProvider>
						</Grid>
					</> : null}
				<Grid item xs={6}>
					<TextField
						id="shareHolderPercentageOfOwnerShip"
						name="shareHolderPercentageOfOwnerShip"
						fullWidth
						placeholder={'0.00'}
						type='number'
						inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
						label={t('createbusiness.shareholders.ownerShip')}
						value={formik.values.shareHolderPercentageOfOwnerShip}
						onChange={formik.handleChange}
						error={formik.errors.shareHolderPercentageOfOwnerShip && Boolean(formik.touched.shareHolderPercentageOfOwnerShip)}
						helperText={formik.errors.shareHolderPercentageOfOwnerShip} />
				</Grid>
				<Grid item xs={6}>
					<CountryCodesAlpha3
						readonly={doNotAllowUpdate}
						name={t('createbusiness.shareholders.country')}
						countryCode={formik.values.shareHolderCountry}
						onChange={(value) => formik.setFieldValue('shareHolderCountry', value)}
						error={formik.errors.shareHolderCountry}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						id="shareHolderPostTown"
						name="shareHolderPostTown"
						fullWidth
						inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
						label={t('createbusiness.shareholders.shareHolderPostTown')}
						value={formik.values.shareHolderPostTown}
						onChange={formik.handleChange}
						error={formik.errors.shareHolderPostTown && Boolean(formik.touched.shareHolderPostTown)}
						helperText={formik.errors.shareHolderPostTown} />
				</Grid>
				<Grid item xs={6}>
					<TextField
						id="shareHolderPostCode"
						name="shareHolderPostCode"
						fullWidth
						inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
						label={t('createbusiness.shareholders.postalCode')}
						value={formik.values.shareHolderPostCode}
						onChange={formik.handleChange}
						error={formik.errors.shareHolderPostCode && Boolean(formik.touched.shareHolderPostCode)}
						helperText={formik.errors.shareHolderPostCode} />
				</Grid>
				<Grid item xs={6}>
					<TextField
						id="shareHolderAddress"
						name="shareHolderAddress"
						fullWidth
						inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
						label={t('createbusiness.shareholders.shareHolderAddressLine1')}
						value={formik.values.shareHolderAddress}
						onChange={formik.handleChange}
						error={formik.errors.shareHolderAddress && Boolean(formik.touched.shareHolderAddress)}
						helperText={formik.errors.shareHolderAddress} />
				</Grid>
				<Grid item xs={6}>
					<TextField
						id="shareHolderPhoneNumber"
						name="shareHolderPhoneNumber"
						fullWidth
						inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
						label={t('createbusiness.shareholders.phone')}
						value={formik.values.shareHolderPhoneNumber}
						onChange={formik.handleChange}
						error={formik.errors.shareHolderPhoneNumber && Boolean(formik.touched.shareHolderPhoneNumber)}
						helperText={formik.errors.shareHolderPhoneNumber}
						InputProps={{
							autoComplete: 'off',
							startAdornment: <InputAdornment position="start">+</InputAdornment>,
						}} />

				</Grid>
				<Grid item xs={6}>
					<TextField
						id="shareHolderEmail"
						name="shareHolderEmail"
						fullWidth
						inputProps={{ autoComplete: 'off', readOnly: doNotAllowUpdate }}
						label={t('createbusiness.shareholders.email')}
						value={formik.values.shareHolderEmail}
						onChange={formik.handleChange}
						error={formik.errors.shareHolderEmail && Boolean(formik.touched.shareHolderEmail)}
						helperText={formik.errors.shareHolderEmail} />
				</Grid>


				{type === BeneficiaryType.INDIVIDUAL ?
					<>
						<Grid item xs={12} pb={3}>
							<Autocomplete
								readOnly={doNotAllowUpdate}
								id='shareHolderDocumentType'
								options={documentTypeOptions}
								value={documentTypeOptions.find(p => p.code === formik.values.shareHolderDocumentType)}
								onChange={(e, value) => {
									formik.setFieldValue('shareHolderDocumentType', typeof value === 'object' ? value.code : '');
									setDocumentType(typeof value === 'object' ? value.code : '');
								}}
								disableClearable
								PaperComponent={({ children }) => (
									<StyledPaper>{children}</StyledPaper>
								)}
								isOptionEqualToValue={(option, value) => value === undefined || option.code === value.code || value?.code === ''}
								renderInput={(params) => <TextField
									name="shareHolderDocumentType" {...params}
									label={t('createbusiness.shareholders.documentType')}
									error={formik.errors.shareHolderDocumentType && Boolean(formik.touched.shareHolderDocumentType)}
									helperText={formik.errors.shareHolderDocumentType}
								/>}
							/>
						</Grid>

						<Grid item xs={12}>
							<FileUpload
								completed={!!formik?.values?.shareHolderDocumentFrontSide}
								label={t('createbusiness.shareholders.frontSide')}
								setStatus={(value) => setFrontFileStatus(value)}
								setFile={(value) => { formik.setFieldValue('shareHolderDocumentFrontSide', value); }}
								disabled={doNotAllowUpdate}
								acceptedFileFormats='image/jpg, image/png, image/jpeg, .pdf'
								/>
						</Grid>
						{formik?.values?.shareHolderDocumentType === 'ID_CARD' && <Grid item xs={12}>
							<FileUpload
								completed={!!formik?.values?.shareHolderDocumentBackSide}
								label={t('createbusiness.shareholders.backSide')}
								setStatus={(value) => setBackFileStatus(value)}
								setFile={(value) => { formik.setFieldValue('shareHolderDocumentBackSide', value); }}
								disabled={doNotAllowUpdate} 
								acceptedFileFormats='image/jpg, image/png, image/jpeg, .pdf'
								/>
						</Grid>}

						<Grid item xs={12}>
							<FileUpload
								completed={!!formik?.values?.shareHolderPOA}
								label={t('createbusiness.shareholders.poa')}
								setStatus={(value) => setFileStatus(value)}
								setFile={(value) => { formik.setFieldValue('shareHolderPOA', value); }}
								disabled={doNotAllowUpdate} 
								acceptedFileFormats='image/jpg, image/png, image/jpeg, .pdf'
								/>
						</Grid>
						<Grid item xs={12}>
							<FormControlLabel
								name='shareHolderPep'
								disabled={doNotAllowUpdate}
								control={<StyledCheckbox name='pep'
									onClick={() => { formik.setFieldValue('shareHolderPep', !formik.values.shareHolderPep); }}
									value={formik.values.shareHolderPep}
									checked={formik.values.shareHolderPep === false}
									size='small'
								/>}
								label={<MuiFormText>{t('createbusiness.shareholders.pep')}</MuiFormText>}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControlLabel
								name='shareHolderUsCitizen'
								disabled={doNotAllowUpdate}
								control={<StyledCheckbox name='shareHolderUsCitizen'
									onClick={() => { formik.setFieldValue('shareHolderUsCitizen', !formik.values.shareHolderUsCitizen); }}
									value={formik.values.shareHolderUsCitizen}
									checked={formik.values.shareHolderUsCitizen === false}
									size='small'
								/>}
								label={<MuiFormText>{t('createbusiness.shareholders.us')}</MuiFormText>}
							/>
						</Grid>
					</> : <>
						<Grid item xs={12} pb={3}>
							<Autocomplete
								readOnly={doNotAllowUpdate}
								id='shareHolderDocumentType'
								options={documentTypeOptions}
								value={documentTypeOptions.find(p => p.code === formik.values.shareHolderDocumentType)}
								onChange={(e, value) => {
									formik.setFieldValue('shareHolderDocumentType', typeof value === 'object' ? value.code : '');
									setDocumentType(typeof value === 'object' ? value.code : '');
								}}
								disableClearable
								PaperComponent={({ children }) => (
									<StyledPaper>{children}</StyledPaper>
								)}
								isOptionEqualToValue={(option, value) => value === undefined || option.code === value.code || value?.code === ''}
								renderInput={(params) => <TextField
									name="shareHolderDocumentType" {...params}
									label={t('createbusiness.shareholders.uboDocumentType')}
									error={formik.errors.shareHolderDocumentType && Boolean(formik.touched.shareHolderDocumentType)}
									helperText={formik.errors.shareHolderDocumentType}
								/>}
							/>
						</Grid>

						<Grid item xs={12}>
							<FileUpload
								completed={!!formik?.values?.shareHolderDocumentFrontSide}
								label={t('createbusiness.shareholders.uboFrontSide')}
								setStatus={(value) => setFrontFileStatus(value)}
								setFile={(value) => { formik.setFieldValue('shareHolderDocumentFrontSide', value); }}
								disabled={doNotAllowUpdate} 
								acceptedFileFormats='image/jpg, image/png, image/jpeg, .pdf'
								/>
						</Grid>
						{formik?.values?.shareHolderDocumentType === 'ID_CARD' && <Grid item xs={12}>
							<FileUpload
								completed={!!formik?.values?.shareHolderDocumentBackSide}
								label={t('createbusiness.shareholders.uboBackSide')}
								setStatus={(value) => setBackFileStatus(value)}
								setFile={(value) => { formik.setFieldValue('shareHolderDocumentBackSide', value); }}
								disabled={doNotAllowUpdate} 
								acceptedFileFormats='image/jpg, image/png, image/jpeg, .pdf'
								/>
						</Grid>}
						<Grid item xs={12}>
							<FileUpload
								completed={!!formik?.values?.shareHolderCertificateOfIncorporationDocument}
								label={t('createbusiness.shareholders.certificateDocument')}
								setStatus={(value) => setCertificateOfIncorporationFileStatus(value)}
								setFile={(value) => { formik.setFieldValue('shareHolderCertificateOfIncorporationDocument', value); }}
								disabled={doNotAllowUpdate} 
								acceptedFileFormats='image/jpg, image/png, image/jpeg, .pdf'
								/>
						</Grid>
						<Grid item xs={12}>
							<FileUpload
								completed={!!formik?.values?.shareHolderDisclosingShareholdersDocument}
								label={t('createbusiness.shareholders.disclosingShareholdersDocument')}
								setStatus={(value) => setDisclosingShareholdersFileStatus(value)}
								setFile={(value) => { formik.setFieldValue('shareHolderDisclosingShareholdersDocument', value); }}
								disabled={doNotAllowUpdate} 
								acceptedFileFormats='image/jpg, image/png, image/jpeg, .pdf'
								/>
						</Grid>
						<Grid item xs={12}>
							<FileUpload
								completed={!!formik?.values?.shareHolderDisclosingDirectorsDocument}
								label={t('createbusiness.shareholders.disclosingDirectorsDocument')}
								setStatus={(value) => setDisclosingDirectorsFileStatus(value)}
								setFile={(value) => { formik.setFieldValue('shareHolderDisclosingDirectorsDocument', value); }}
								disabled={doNotAllowUpdate} 
								acceptedFileFormats='image/jpg, image/png, image/jpeg, .pdf'
								/>
						</Grid>
						<Grid item xs={12}>
							<FileUpload
								completed={!!formik?.values?.shareHolderIdOfDirectorsDocument}
								label={t('createbusiness.shareholders.idOfDirectorsDocument')}
								setStatus={(value) => setIdOfDirectorsFileStatus(value)}
								setFile={(value) => { formik.setFieldValue('shareHolderIdOfDirectorsDocument', value); }}
								disabled={doNotAllowUpdate} 
								acceptedFileFormats='image/jpg, image/png, image/jpeg, .pdf'
								/>
						</Grid>
					</>}
			</Grid>
		</form >
	);
};

export default BusinessShareholderItem;
