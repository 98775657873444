import StyledSelect from '@/componentsMui/Shared/Widgets/StyledSelect';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    value: string,
    onChange: (value: string) => void,
}

const GenderOptions = [
    {
        key: 'M',
        value: 'Male',
    },
    {
        key: 'F',
        value: 'Female',
    }];

const GenderSelect = ({ value, onChange }: Props) => {

    const { t } = useTranslation('translations');
    return (
        <FormControl fullWidth >
            <InputLabel>{t('landing.profileDetailsOam.gender')}</InputLabel>
            <StyledSelect
                value={value}
                displayEmpty
                onChange={(event: any) => { onChange(event.target.value); }}
                fullWidth
            >
                {GenderOptions.length > 0 &&
                    GenderOptions.map((item) => (
                        <MenuItem key={item.key} value={item.key}> {item.value}  </MenuItem>
                    ))}
            </StyledSelect>
        </FormControl>);
};



export default GenderSelect;
