import React from 'react';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const MaintenanceChip = () => {
    const { t } = useTranslation('translations');
    return <Chip label={t('dashboard.maintenance.maintenance')} color='primary'/>;
};

export default MaintenanceChip;
