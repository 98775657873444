import { Typography, TypographyProps } from '@mui/material';
import React, { FC } from 'react';

/*
Font size:  14px   
Font height: 24px    
Weight: 400
 */
const MuiTextTrx: FC<TypographyProps> = (props: TypographyProps) => {
	return (
		<Typography 
			fontSize="0.875rem"
			fontWeight='400'
			lineHeight='1.5rem' 
			color='text.primary'
			whiteSpace='pre-line' //this wrap text
			{...props}>
			{props.children}
		</Typography >
	);
};

export default MuiTextTrx;
