import { ThemeOptions } from '@mui/material/styles/createTheme';
import { createTheme } from '@mui/material/styles';

import defaultLighTheme from '../default/defaultLightTheme';

const newTheme: ThemeOptions = {
	...defaultLighTheme,
	palette: {
		...defaultLighTheme.palette,
		primary: {
			...defaultLighTheme.palette.primary,
			contrastText: '#21252A'
		},
		text: {
			...defaultLighTheme.palette.text,
			secondary: '#818181',
		},
		action: {
			...defaultLighTheme.palette.action,
			disabled: '#818181',
		},
	},
	selectedItemBg: {
		default: 'linear-gradient(0deg, rgba(102, 255, 166, 0.8), rgba(102, 255, 166, 0.8))',
	},
	iconGradient: {
		...defaultLighTheme.iconGradient,
		stopColor1: '#66FFA6',
		stopColor2: '#1D3A32'
	},
	highlight: '#66FFA6',
	warningBg:  'linear-gradient(112.26deg, #66CBB11a 10.81%, rgba(1, 132, 253, 0.1) 160.09%)',
	carousel: {
		...defaultLighTheme.carousel,
		iconShadow: 'linear-gradient(112.26deg, #66FFA6 10.81%, #FFCC0D 160.09%)'
	},
	covers: {
		...defaultLighTheme.covers,
		productCover: {
			...defaultLighTheme.covers.productCover,
			bg: [
				{
					firstBg: 'linear-gradient(0deg, #66FFA6, #66FFA6)',
					secondBg: 'linear-gradient(0deg, #66FFA6, #66FFA6)',
				},
				//wallet
				{
					firstBg: 'linear-gradient(0deg, #66FFA6, #66FFA6)',
					secondBg: 'linear-gradient(0deg, #66FFA6, #66FFA6)',
				}]

		},
		paymentMethodCover: {
			...defaultLighTheme.covers.paymentMethodCover,
			bg: [{
				firstBg: 'linear-gradient(329.09deg, #01DDB9 91.04%, #3D2DDD 91.05%)',
				secondBg: 'linear-gradient(329.09deg, #01DDB9 91.04%, #3D2DDD 91.05%)',
			},
			{
				firstBg: 'linear-gradient(0deg, #0190DA, #0190DA)',
				secondBg: 'linear-gradient(0deg, #0190DA, #0190DA)',
			}],
		},
		newAccountCover: {
			baseBg: 'rgba(255, 255, 255, 0.1)',
			bg: [{
				firstBg: '#3588EA',
				secondBg: '#3588EA',

			},
			{
				firstBg: '#000000',
				secondBg: '#000000',
			},
			{
				firstBg: '#33CCCC',
				secondBg: '#33CCCC',
			},
			{
				firstBg: '#6B3051',
				secondBg: '#6B3051',
			},
			{
				firstBg: '#F0B323',
				secondBg: '#F0B323',
			},
			{
				firstBg: '#BCD4E6',
				secondBg: '#BCD4E6',
			},
			]
		},
		newCardCover: {
			baseBg: 'rgba(255, 255, 255, 0.1)',
			bg: [
				{
					firstBg: 'rgba(102, 255, 166, 0.8)',
					secondBg: 'rgba(102, 255, 166, 0.8)',
				},
				{
					firstBg: '#3588EA',
					secondBg: '#3588EA',
				},
				{
					firstBg: '#FFFFFF',
					secondBg: '#FFFFFF',
				},
				{
					firstBg: '#33CCCC',
					secondBg: '#33CCCC',
				},
				{
					firstBg: '#6B3051',
					secondBg: '#6B3051',
				},
				{
					firstBg: '#F0B323',
					secondBg: '#F0B323',
				},
				{
					firstBg: '#BCD4E6',
					secondBg: '#BCD4E6',
				},
			]
		}
	},
	auth: {
		welcomeCardBg: '#68C0B5',
	},
};

const paletteDark = createTheme(newTheme);

export default paletteDark;

