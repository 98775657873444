import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, Box, useMediaQuery } from '@mui/material';

import { TransactionsLazyPayload, TransactionsTypedPayload } from '@/features/transactions/types';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import TransactionDetailsForm from './Details/TransactionDetailsForm';
import { RootState } from '@/rootReducer';
import { getMoreCardTrx, transactionDetailsEnabled } from '@/helpers/transactionUtils';
import { Transaction } from './TransactionStatements.spec';
import ExportStatementForm from './Download/ExportStatementForm';
import { getTransactionsLazy, getTransactionsTyped } from '@/features/transactions/slice';
import AddReceiptForm from './Details/AddReceiptForm';
import { showErrorNotification } from '@/features/swal/slice';
import { closeForm, openForm } from '@/features/forms/slice';
import MuiHeader from '../Shared/Widgets/MuiText/MuiHeader';
import TransactionListHeader from './Components/TransactionListHeader';
import TransactionListLine from './Components/TransactionListLine';
import StyledDivider from '../Shared/Widgets/StyledDivider';
import {
	setAccountNumber,
	setFromTo,
	setSearch,
	setSkip,
	setTake,
	setTransactions,
	setType
} from '@/features/transactionsLazyLoad/slice';
import Spinner from '../Shared/Widgets/Spinner/Spinner';
import DownloadButton from '../Shared/Widgets/DownloadButton';
import SearchField from '../Shared/Widgets/SearchField';
import ExpandButton from '../Shared/Widgets/ExpandButton';
import TransactionForm from './TransactionForm';
import '@/componentsMui/Shared/Shared.css';
import { getTime } from 'date-fns';
import { BankAccount } from '@/features/account/types';
import { AccountType } from '@/features/operations/types';
import TransactionMobileLine from '@/componentsMui/Transactions/Components/TransactionMobileLine';


interface TransactionStatements {
	type: AccountType,
	account: BankAccount,
	hideExpand?: boolean,
	title?: string
}

const TransactionStatements = ({
	type,
	account,
	hideExpand,
	title
}: TransactionStatements): React.ReactElement => {
	const smallScreen = useMediaQuery('(max-width:600px)');
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const [openTransactionDetails, setOpenTransactionDetails] = React.useState<boolean>(false);
	const [selectedTrx, setSelectedTrx] = React.useState<Transaction>();
	const [openDownloadForm, setOpenDownloadForm] = React.useState<boolean>(false);
	const [openTransactionForm, setOpenTransactionForm] = React.useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const [addReceipt, setAddReceipt] = useState<boolean>(false);
	const [showLoadMore, setShowLoadMore] = useState<boolean>(true);

	const lastTransactionRef = React.useRef(null);

	const { skip, take, transactions, accountNumber, search, from } = useSelector(
		(state: RootState) => state.transactionsLazyLoad
	);

	const { user } = useSelector((state: RootState) => state.user);
	const userCreationTime = getTime(new Date(user.createdDate));

	const openTransactionDetailsForm = (trx: Transaction) => {
		if (transactionDetailsEnabled(trx.activity)) {
			dispatch(openForm());
			setOpenTransactionDetails(true);
			setSelectedTrx(trx);
		}
	};

	const closeTransactionDetailsForm = React.useCallback(() => {
		setOpenTransactionDetails(false);
		dispatch(closeForm());
	}, [dispatch]);

	const handleSearchRequest = (e) => {
		const regex = /^[ A-Za-z0-9-.,&/_?!]*$/;
		if (regex.test(e.target.value)) {
			dispatch(setSkip(0));
			dispatch(setTransactions([]));
			dispatch(setSearch(e.target.value));
		}
	};

	const handleCloseSearch = () => {
		dispatch(setSkip(0));
		dispatch(setTransactions([]));
		dispatch(setSearch(''));
	};

	const closeAddReceiptForm = () => {
		setAddReceipt(false);
		dispatch(closeForm());
	};


	const fetchData = useCallback(() => {
		const get = async () => {
			setLoading(true);
			setShowLoadMore(true);
			const today = new Date();
			try {

				const payload: TransactionsTypedPayload = {
					type,
					search: search,
					skip: 0,
					take: take,
					from: `${userCreationTime}`,
					to: `${today.getTime() + 86400000}`,
					accountId: accountNumber.toString()
				};

				const result = await getTransactionsTyped(payload);
				if (result.list.length === result.count) {
					setShowLoadMore(false);
				}
				dispatch(setTransactions(result.list));
			} catch (e) {
				dispatch(setTransactions([]));
				showErrorNotification(e);
			} finally {
				setLoading(false);
			}

			dispatch(setSkip(take));
		};
		if (account.accountId !== accountNumber) return;
		get();
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [accountNumber, search, take, account?.availableBalance, account?.accountId, dispatch]);

	const fetchCardData = useCallback(() => {
		const get = async () => {
			setLoading(true);
			setShowLoadMore(true);
			try {

				const payload: TransactionsLazyPayload = {
					search: search,
					accountId: accountNumber.toString(),
					initial: true
				};

				const result = await getTransactionsLazy(payload);

				dispatch(setTransactions(result.list));
				dispatch(setFromTo({ from: result?.from, to: result?.to }));
			} catch (e) {
				dispatch(setTransactions([]));
				showErrorNotification(e);
			} finally {
				setLoading(false);
			}
		};
		if (account.accountId !== accountNumber) return;
		get();
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [accountNumber, search, account?.availableBalance, account?.accountId, dispatch]);

	useEffect(() => {
		if (!account?.accountId) {
			dispatch(setAccountNumber(null));
		} else {
			dispatch(setAccountNumber(account.accountId));
		}
		if (type) {
			dispatch(setType(type));
		}
	}, [account?.accountId, dispatch, type]);

	useEffect(() => {
		if (type === AccountType.CARD) {
			fetchCardData();
		}
		else {
			fetchData();
		}
	}, [fetchData, fetchCardData, type]);

	const loadMoreAccount = async () => {
		try {
			const today = new Date();
			const payload: TransactionsTypedPayload = {
				type,
				search: search,
				skip: skip,
				take: take,
				from: `${userCreationTime}`,
				to: `${today.getTime()}`,
				accountId: accountNumber.toString()
			};

			const result = await getTransactionsTyped(payload);

			const newTrxArray = [...transactions, ...result.list];
			dispatch(setTransactions(newTrxArray));
			dispatch(setSkip(skip + take));
			if (result.count === newTrxArray.length || result?.list?.length === 0) {
				setShowLoadMore(false);
			}

			lastTransactionRef.current?.scrollIntoView({ behavior: 'smooth' });
		} catch (e) {
			dispatch(setTransactions([]));
			showErrorNotification(e);
		} finally {
			setLoading(false);
		}

	};

	const loadMoreCard = async () => {
		try {
			setLoading(true);
			const result = await getMoreCardTrx(from, userCreationTime, search, accountNumber.toString());
			const newTrxArray = [...transactions, ...result.list];
			dispatch(setTransactions(newTrxArray));
			dispatch(setFromTo({ from: result?.from, to: result?.to }));
			lastTransactionRef.current?.scrollIntoView({ behavior: 'smooth' });

			if (userCreationTime > result?.from) { setShowLoadMore(false); }
		} catch (e) {
			dispatch(setTransactions([]));
			showErrorNotification(e);
		} finally {
			setLoading(false);
		}
	};

	const loadMore = type === AccountType.CARD ? loadMoreCard : loadMoreAccount;

	const handleExpand = () => {
		type !== AccountType.CARD && dispatch(setTake(40));
		setOpenTransactionForm(true);
	};

	const handleCollapse = () => {
		type !== AccountType.CARD && dispatch(setTake(10));
		setOpenTransactionForm(false);
	};

	return (
		<div id='transaction-statements' className='scroll-container'>
			<TransactionDetailsForm open={openTransactionDetails}
				onClose={closeTransactionDetailsForm}
				transaction={selectedTrx}
				type={type}
				account={account}
				setLoading={(value: boolean) => setLoading(value)} />
			<AddReceiptForm open={addReceipt} transaction={selectedTrx} onClose={closeAddReceiptForm} refreshTransactions={handleCloseSearch} />
			<ExportStatementForm
				accountId={account.accountId}
				open={openDownloadForm}
				type={type}
				onClose={() => setOpenDownloadForm(false)} />
			<TransactionForm
				type={type}
				account={account}
				open={openTransactionForm}
				onClose={handleCollapse} />
			<Grid container
				display='flex'
				flexDirection='row'
				alignItems='center'
				pb={2}>
				<MuiHeader>{title ?? t('transaction.header.transactions')}</MuiHeader>
				<Grid item xs container justifyContent='flex-end' spacing={1}>
					{(!hideExpand && !smallScreen) &&
						<Grid item >
							<ExpandButton onClick={handleExpand} />
						</Grid>
					}
					<Grid item  >
						<DownloadButton onClick={() => setOpenDownloadForm(true)} />
					</Grid>
					{type !== AccountType.CARD && <Grid item sx={{ width: { xs: '150px', md: 'auto' } }}>
						<SearchField
							id='search'
							name='search'
							value={search}
							onChange={(e) => handleSearchRequest(e)}
						/>
					</Grid>}
				</Grid>
			</Grid>
			{loading && <Grid container justifyContent='center' mt={2}><Spinner /></Grid >}
			{!transactions || transactions.length === 0 || accountNumber !== account.accountId ?
				<div style={{ display: 'flex', justifyContent: 'space-around' }}>{t('transaction.table.noTransactions')}</div>
				:
				<Box className='scroll-section' width='100%'>
					{smallScreen ?
						<div className='scrollable-content'>
							{transactions?.length > 0 && transactions?.map((trx, index) => (
								<div key={`${type}-${trx.transactionNumber}`} ref={transactions.length === index + 1 ? lastTransactionRef : null}>
									<Box sx={{ py: '0.5rem' }}>
										<TransactionMobileLine type={type} transaction={trx} openTransactionDetailsForm={openTransactionDetailsForm} fetchData={fetchData} />
										{index + 1 < transactions.length && <StyledDivider />}
									</Box>
								</div>
							)
							)}
							{showLoadMore &&
								<Grid container>
									<Grid item xs={12} justifyContent='center' display='flex' pt={2}>
										<Button onClick={loadMore}
											disabled={loading}
										>{t('transaction.table.loadMore')}</Button>
									</Grid>
								</Grid>}
						</div>
						:
						<>
							<div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
								<TransactionListHeader />
							</div>
							<div className='scrollable-content' style={{ paddingRight: '1rem' }}>
								{transactions?.length > 0 && transactions?.map((trx, index) => (
									<div key={`${type}-${trx.transactionNumber}`} ref={transactions.length === index + 1 ? lastTransactionRef : null}>
										<TransactionListLine type={type} transaction={trx} openTransactionDetailsForm={openTransactionDetailsForm} fetchData={fetchData} />
										{index + 1 < transactions.length && <StyledDivider />}
									</div>
								)
								)}
								{showLoadMore &&
									<Grid container>
										<Grid item xs={12} justifyContent='center' display='flex' pt={2}>
											<Button onClick={loadMore}
												disabled={loading}
											>{t('transaction.table.loadMore')}</Button>
										</Grid>
									</Grid>}
							</div>
						</>
					}
				</Box>}
		</div>
	);
};


export default TransactionStatements;
