export enum Agents {
	AZURE = 'AZURE',
	COSPAY = 'COSPAY',
	WALLEXCARD = 'WALLEXCARD',
	DTSCB = 'DTSCB',
	DTS = 'DTS',
	DTSUS = 'DTSUS',
	TYNCOFEX = 'TYNCOFEX',
	WZ = 'WZ',
	CUSTODYFY = 'CUSTODYFY',
	SALAZAR = 'SALAZAR',
	TUTUS = 'TUTUS',
	YESSY = 'YESSY'
}


export enum TransferType {
	EXTERNAL, CONTACT, INTERNAL, REQUEST, TRUST, BULK
}

export enum AddMoneyType {
	SEPA = 'SEPA',
	SEPA_INSTANT = 'SEPA_INSTANT',
	CARD = 'CARD',
	OWN = 'OWN',
	WIRE = 'WIRE',
	WIRE_INTL = 'WIRE_INTL',
	WIRE_ALT = 'WIRE_ALT',
}

export enum BeneficiaryType {
	INDIVIDUAL = 'INDIVIDUAL', BUSINESS = 'BUSINESS'
}

export enum SystemType {
	SEPA = 'SEPA_STEP2',
	SEPA_INSTANT = 'SEPA_INSTANT',
	SWIFT = 'SWIFT',
	UK_CHAPS = 'UK_CHAPS',
	UK_FPS = 'UK_FPS',
	US_WIRE = 'US_WIRE',
	INTERNATIONAL_WIRE = 'INTERNATIONAL_WIRE',
	ACH = 'ACH',
	SAME_DAY_ACH = 'SAME_DAY_ACH',
	SCAN = 'SCAN',
}

export const systemTypesUINames = {
	[SystemType.SEPA]: 'SEPA',
	[SystemType.SWIFT]: 'SWIFT',
	[SystemType.UK_CHAPS]: 'CHAPS',
	[SystemType.UK_FPS]: 'FPS',
	[SystemType.SEPA_INSTANT]: 'SEPA Instant',
	[SystemType.SCAN]: 'SCAN',
	[SystemType.ACH]: 'ACH',
	[SystemType.SAME_DAY_ACH]: 'Instant ACH',
	[SystemType.INTERNATIONAL_WIRE]: 'International WIRE',
};



export enum RootProcessType {
	CREATE_ACCOUNT = 'CREATE_ACCOUNT',
	CREATE_CARD = 'CREATE_CARD',
	CREATE_STOCK = 'CREATE_STOCK',
	CREATE_TRUST = 'CREATE_TRUST',
	CREATE_CRYPTO_ACCOUNT = 'CREATE_CRYPTO_ACCOUNT',
	CREATE_VAULT = 'CREATE_VAULT',
	CREATE_TOKEN_ACCOUNT = 'CREATE_TOKEN_ACCOUNT',
	CREATE_WALLET = 'CREATE_WALLET'
}

export enum LoginSteps {
	//QR = 'QR',
	LOGIN = 'LOGIN',
	REGISTRATION = 'REGISTRATION',
	START = 'START',
	MOBILE = 'MOBILE',
}



export enum AccountType {
	VIRTUAL = 'VIRTUAL',
	PHYSICAL = 'PHYSICAL'
}

export enum ACHAccountType {
	CHECKING = 'checking',
	SAVINGS = 'savings'
}

export interface RowActionType<T> {
    icon: JSX.Element;
    onClick: (row: T, event?: React.MouseEvent<HTMLElement>) => void;
    key?: string;
}
