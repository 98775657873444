import React from 'react';
import MaintenanceCardBackgroundImage from '@/assets/MaintenanceCardBackgroundImage.png';
import { Box, Button, Grid, useMediaQuery } from '@mui/material';
import MuiHeader from './MuiText/MuiHeader';
import { useTranslation } from 'react-i18next';
import { getMaintenance } from '@/features/maintenance/maintenanceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { MaintenanceStatus } from '@/features/maintenance/maintenanceType';
import MuiSubHeader from './MuiText/MuiSubHeader';
import { format } from 'date-fns';


const MaintenanceCard = () => {
    const { t } = useTranslation('translations');
    const dispatch = useDispatch();
    const { list, isPlanned, isPartiallyDown } = useSelector((state: RootState) => state.maintenance);

    const active = list.filter(p => p.status === MaintenanceStatus.ACTIVE) || [];
    const planned = list.filter(p => p.status === MaintenanceStatus.UPCOMMING) || [];
    const smallScreen = useMediaQuery('(max-width:600px)');
    const handleReload = () => {
        dispatch(getMaintenance());
    };

    return (
        <Grid style={{
            backgroundImage: `url(${MaintenanceCardBackgroundImage})`,
            borderRadius: '20px',
            backgroundSize: 'cover', padding: smallScreen ? '10px 10px' : '63px 90px',
            overflow: 'auto'
        }}>
            {isPartiallyDown && <>
                <MuiHeader> {t('dashboard.maintenance.appMaintenance')}</MuiHeader>
                <Box sx={{ marginBottom: '44px', marginTop: '13px' }}>
                    {active.map( (item, idx) =>
                        <MuiSubHeader key={idx} sx={{ fontWeight: '400' }}>
                            {item.description}
                        </MuiSubHeader>
                    )}
                </Box>
            </>
            }
            {isPlanned && <>
                <MuiHeader> {t('dashboard.maintenance.planned')}</MuiHeader>
                <Box sx={{ marginBottom: '44px', marginTop: '13px' }}>
                    {planned.map(item =>
                        <MuiSubHeader key={item.id} sx={{ fontWeight: '400' }}>
                            {item?.description}
                            from {format(new Date(item.startDate), 'dd MMM yyyy HH:mm')}
                            to {format(new Date(item.endDate), 'dd MMM yyyy HH:mm')}
                        </MuiSubHeader>
                    )}
                </Box>
            </>}
            <Button variant='customOutlined' onClick={handleReload}>
                {t('form.buttons.reloadPage')}
            </Button>

        </Grid>
    );
};

export default MaintenanceCard;
