import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton } from '@mui/material';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { getBusiness, submitForm } from '@/features/business/slice';
import { KybSectionEnum, KybStatus, SectionStatus } from '@/features/business/types';
import { getErrorWithParamsValue, showException } from '@/features/swal/slice';
import { useTranslation } from 'react-i18next';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import MuiTextConfirmation from '@/componentsMui/Shared/Widgets/MuiText/MuiFormField';
import MuiFormLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiFormLabel';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface Props {
	clientId?: string,
	onBack: () => void,
	setSection?: (section: KybSectionEnum) => void;
}


interface ErrorSubmitType {
	name: string,
	rejectionReason?: string,
	missing?: boolean,
	status?: string
}


const StartValidation = ({ clientId, setSection, onBack }: Props): React.ReactElement => {

	const { list } = useSelector((state: RootState) => state.business);
	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const closeModal = useCloseDialogNoEvent();
	const clientForm = list.find(p => p.clientId === clientId);
	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState<ErrorSubmitType[]>(null);

	const rejectedSections = clientForm?.form?.filter(p => p.status === SectionStatus.REJECTED);
	const missingSections = ['BUSINESS_INFORMATION',
		'DOCUMENTS',
		'DIRECTORS',
		'SHAREHOLDERS',
		'PARTNERS',
		'TRANSFERS'
	].filter(p => !clientForm?.form?.find(s => s.name === KybSectionEnum[p]));



	const allowToSubmit = clientId
		&& (clientForm.kybStatus === KybStatus.CREATED || clientForm.kybStatus === KybStatus.REJECTED)
		&& rejectedSections?.length === 0
		&& missingSections?.length === 0;

	useEffect(() => {
		dispatch(getBusiness());
	}, [dispatch]);


	const handleStartKyb = async () => {
		try {
			setError(null);
			setSubmitting(true);
			await submitForm({ clientId });
			dispatch(getBusiness());
			closeModal();
		}
		catch (e) {
			const err = getErrorWithParamsValue(e);
			if (err) { setError(err?.param_value); }
			else { showException(e, null, t('form.buttons.close')); }

		}
		finally { setSubmitting(false); }
	};

	return (
		<Grid id='business-start-validation' container justifyContent='center' height='100%' flex={1}>

			<>
				<Grid item container xs={12} rowGap={4} justifyContent='center' height='100%' >
					<Grid item xs={8}>
						<MuiHeader textAlign='center' className='business-header'>  {t((allowToSubmit && !error) ? 'createbusiness.submit.titleOk' : 'createbusiness.submit.titleNot')}</MuiHeader>
					</Grid>

					{(rejectedSections?.length > 0 ||
						missingSections?.length > 0 ||
						error?.length > 0
					) && <Grid container item xs={8}>
							<Box width='100%' p={3}>

								{rejectedSections?.length > 0 && rejectedSections?.map((p, i) =>
									<>
										<Grid container item xs={12} alignItems='center'>
											<Grid item xs={4} >
												<MuiFormLabel>  {t('createbusiness.tabs.' + p.name)}  </MuiFormLabel>
											</Grid>
											<Grid item xs={7}>
												<MuiTextConfirmation> {p.rejectionReason} </MuiTextConfirmation>
											</Grid>
											<Grid item xs={1} justifyContent='flex-end'>
												<IconButton
													onClick={() => setSection(KybSectionEnum[p.name])}>
													<OpenInNewIcon />
												</IconButton>
											</Grid>
										</Grid>
										{rejectedSections.length !== i + 1 && <Grid item xs={12}>
											<StyledDivider />
										</Grid>}
									</>
								)}



								{missingSections?.length > 0 && missingSections?.map((p, i) =>
									<>
										<Grid container item xs={12} alignItems='center'>
											<Grid item xs={4} >
												<MuiFormLabel>  {t('createbusiness.tabs.' + p)}  </MuiFormLabel>
											</Grid>
											<Grid item xs={7}>
												<MuiTextConfirmation> {t('createbusiness.submit.missing')} </MuiTextConfirmation>
											</Grid>
											<Grid item xs={1} justifyContent='flex-end'>
												<IconButton
													onClick={() => setSection(KybSectionEnum[p])}>
													<OpenInNewIcon />
												</IconButton>
											</Grid>
										</Grid>
										{missingSections.length !== i + 1 && <Grid item xs={12}>
											<StyledDivider />
										</Grid>}
									</>
								)}



								{error?.length > 0 && error?.map((p, i) =>
									<>
										<Grid container item xs={12} alignItems='center'>
											<Grid item xs={4} >
												<MuiFormLabel>  {t('createbusiness.tabs.' + p.name)}  </MuiFormLabel>
											</Grid>
											<Grid item xs={7}>
												<MuiTextConfirmation> {p.missing ? t('createbusiness.submit.missing') : p.rejectionReason} </MuiTextConfirmation>
											</Grid>
											<Grid item xs={1} justifyContent='flex-end'>
												<IconButton
													onClick={() => setSection(KybSectionEnum[p.name])}>
													<OpenInNewIcon />
												</IconButton>
											</Grid>
										</Grid>
										{error.length !== i + 1 && <Grid item xs={12}>
											<StyledDivider />
										</Grid>}
									</>
								)}

							</Box>
						</Grid>}
				</Grid>
				<Grid container className='form-content_botton-area'>
					<Grid item xs={6}>
						<Button
							variant='customOutlined'
							onClick={onBack}
						>  {t('form.buttons.back')}
						</Button>
					</Grid>
					<Grid item xs={6} container justifyContent='flex-end' columnGap={3}>
						<Button
							disabled={!allowToSubmit || submitting}
							variant='customOutlined'
							color='secondary'
							onClick={handleStartKyb}
						>  {t('createbusiness.submit.btn')}
						</Button>
					</Grid>
				</Grid>
			</>
		</Grid >
	);
};

export default StartValidation;
