import React, { useEffect } from 'react';
import { Button, Dialog, DialogContent, FormHelperText, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { closeForm, openForm } from '@/features/forms/slice';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { isEmpty } from 'lodash';
import { changePassword } from '@/features/security/slice';
import { showException } from '@/features/swal/slice';
import * as Yup from 'yup';
import { usePasswordValidation } from './passwordHooks';
import { getRecaptureToken } from '@/helpers/recaptureUtils';
import FormPaperLarge from '@shared/Widgets/Dialog/FormPaperLarge';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';

interface Props {
	open: boolean,
	onClose: () => void
}

const PasswordChangeForm = ({ open, onClose }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const [passwordErrors, validatePassword, setErrors] = usePasswordValidation();

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);


	const closeModal = () => {
		dispatch(closeForm());
		onClose();
	};

	const initialValues = {
		password: '',
		passwordConfirm: '',
		passwordOld: ''
	};

	const validationSchema = Yup.object({
		passwordOld: Yup.string()
			.required(t('form.validator.required')),
		password:
			Yup.string()
				.required(t('form.validator.required')),
		passwordConfirm:
			Yup.string()
				.required(t('form.validator.required'))
				.oneOf([Yup.ref('password'), null], t('form.validator.confirmpassword'))
	});


	const handleOnBlur = async (e) => {
		await validatePassword(e.target.value);
	};


	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		const { password, passwordOld } = formData;
		const recaptchaToken = await getRecaptureToken(); 
		try {
			if (passwordErrors.length > 0) { return; }
			await changePassword({ newPassword: password, oldPassword: passwordOld , recaptchaToken});
			closeModal();
		} catch (e) {
			await showException(e, [
				{
					key: 'unchanged',
					title: 'swal.ERROR',
					message: 'swal.NEW_PASSWORD_UNCHANGED'
				},
				{
					key: 'invalid',
					title: 'swal.ERROR',
					message: 'swal.INVALID_OLD_PASSWORD'
				}
			], t('form.buttons.close'));
		} finally { setSubmitting(false); }
	};

	return (
		<Dialog
			id="change-password-form"
			open={open}
			maxWidth='sm'
			PaperComponent={FormPaperLarge}
		>
			<DialogContent style={{padding: 0, display: 'flex'}}>
				<Grid container
					  flex={1}
					  direction='column'
					  minHeight='0'
					  flexWrap='nowrap'
					  rowSpacing={2}>
					<Grid item>
						<FormHeader onBack={closeModal} title={t('security.options.changePassword')} />
					</Grid>
					<Grid item flex={1}>
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={submit}
						>
							{({errors, isSubmitting }) => (
								<Form style={{ width: '100%', height: '100%', display: 'flex' }}>
									<Grid container direction='column'>
										<Grid item>
											<MuiTextField
												name="passwordOld"
												fullWidth
												label={t('form.validator.currentpassword')}
												placeholder={t('form.validator.currentpassword')}
												type="password"
												InputProps={{
													autoComplete: 'new-password'
												}}
											/>
										</Grid>

										<Grid item>
											<MuiTextField
												fullWidth
												name="password"
												onFocus={() => setErrors([])}
												onBlur={handleOnBlur}
												label={t('form.fields.newpasswordlabel')}
												placeholder={t('form.fields.newpasswordlabel')}
												type="password"
												InputProps={{
													autoComplete: 'new-password',
												}}
											/>
											{passwordErrors.some(p => p === 'len') && <FormHelperText>{t('password.passwordvalidators.len')}</FormHelperText>}
											{passwordErrors.some(p => p === 'spec') && <FormHelperText>{t('password.passwordvalidators.spec')}</FormHelperText>}
											{passwordErrors.some(p => p === 'upper') && <FormHelperText>{t('password.passwordvalidators.upper')}</FormHelperText>}
										</Grid>

										<Grid item>
											<MuiTextField
												fullWidth
												name="passwordConfirm"
												label={t('form.fields.confirmPasswordLabel')}
												placeholder={t('form.fields.confirmPasswordLabel')}
												type="password"
												InputProps={{
													autoComplete: 'new-password'
												}}
											/>
										</Grid>
										<Grid container item mt='auto' pt={8} columnSpacing={2} justifyContent='space-between'>
											<Grid item xs={6}>
												<Button variant="customOutlined"
														sx={{ width: { xs: '100%', md: 'auto' } }}
														onClick={closeModal}>
													{t('form.buttons.close')}
												</Button>
											</Grid>
											<Grid item xs={6} container justifyContent='flex-end'>
												<Button
													type="submit"
													variant="contained"
													sx={{ width: { xs: '100%', md: 'auto' } }}
													disabled={!isEmpty(errors) || isSubmitting || passwordErrors.length > 0}
												>
													{t('form.buttons.continue')}
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Form>
							)}
						</Formik>
					</Grid>
				</ Grid >
			</DialogContent>
			{/*<DialogContent>*/}
			{/*	<Grid container flex={1} direction='column'>*/}
			{/*		<Grid container justifyContent='flex-end'>*/}
			{/*			<Grid item>*/}
			{/*				<IconButton onClick={closeModal}  >*/}
			{/*					<MuiCloseIcon />*/}
			{/*				</IconButton>*/}
			{/*			</Grid>*/}
			{/*		</Grid>*/}
			{/*		<Grid container>*/}
			{/*			<Grid item xs={12} textAlign='center'>*/}
			{/*				<MuiHeader>{t('security.options.changePassword')}*/}
			{/*				</MuiHeader>*/}
			{/*			</Grid>*/}
			{/*			<Grid item xs={12} pt='2rem'>*/}
			{/*				<Formik*/}
			{/*					initialValues={initialValues}*/}
			{/*					validationSchema={validationSchema}*/}
			{/*					onSubmit={submit}*/}
			{/*				>*/}
			{/*					{({ errors, isSubmitting }) => {*/}
			{/*						return (*/}
			{/*							<Form style={{ width: '100%' }}>*/}
			{/*								<Grid container >*/}

			{/*									<Grid item xs={12}>*/}
			{/*										<MuiTextField*/}
			{/*											name="passwordOld"*/}
			{/*											fullWidth*/}
			{/*											label={t('form.validator.currentpassword')}*/}
			{/*											placeholder={t('form.validator.currentpassword')}*/}
			{/*											type="password"*/}
			{/*											InputProps={{*/}
			{/*												autoComplete: 'new-password'*/}
			{/*											}}*/}
			{/*										/>*/}
			{/*									</Grid>*/}

			{/*									<Grid item xs={12}>*/}
			{/*										<MuiTextField*/}
			{/*											fullWidth*/}
			{/*											name="password"*/}
			{/*											onFocus={() => setErrors([])}*/}
			{/*											onBlur={handleOnBlur}*/}
			{/*											label={t('form.fields.newpasswordlabel')}*/}
			{/*											placeholder={t('form.fields.newpasswordlabel')}*/}
			{/*											type="password"*/}
			{/*											InputProps={{*/}
			{/*												autoComplete: 'new-password',*/}
			{/*											}}*/}
			{/*										/>*/}
			{/*										{passwordErrors.some(p => p === 'len') && <FormHelperText>{t('password.passwordvalidators.len')}</FormHelperText>}*/}
			{/*										{passwordErrors.some(p => p === 'spec') && <FormHelperText>{t('password.passwordvalidators.spec')}</FormHelperText>}*/}
			{/*										{passwordErrors.some(p => p === 'upper') && <FormHelperText>{t('password.passwordvalidators.upper')}</FormHelperText>}*/}
			{/*									</Grid>*/}

			{/*									<Grid item xs={12}>*/}
			{/*										<MuiTextField*/}
			{/*											fullWidth*/}
			{/*											name="passwordConfirm"*/}
			{/*											label={t('form.fields.confirmPasswordLabel')}*/}
			{/*											placeholder={t('form.fields.confirmPasswordLabel')}*/}
			{/*											type="password"*/}
			{/*											InputProps={{*/}
			{/*												autoComplete: 'new-password'*/}
			{/*											}}*/}
			{/*										/>*/}
			{/*									</Grid>*/}

			{/*									<Grid item xs={12} >*/}
			{/*										<Grid container flex={1} justifyContent='space-between' mt={8}>*/}
			{/*											<Grid item xs={6}>*/}
			{/*												<Button variant="customOutlined"*/}
			{/*													onClick={closeModal}>*/}
			{/*													{t('form.buttons.close')}*/}
			{/*												</Button>*/}
			{/*											</Grid>*/}
			{/*											<Grid item xs={6} container justifyContent='flex-end'>*/}
			{/*												<Button*/}
			{/*													type="submit"*/}
			{/*													variant="contained"*/}
			{/*													disabled={!isEmpty(errors) || isSubmitting || passwordErrors.length > 0}*/}
			{/*												>*/}
			{/*													{t('form.buttons.continue')}*/}
			{/*												</Button>*/}
			{/*											</Grid>*/}
			{/*										</Grid>*/}
			{/*									</Grid>*/}
			{/*								</Grid>*/}
			{/*							</Form>*/}
			{/*						);*/}
			{/*					}}*/}
			{/*				</Formik>*/}

			{/*			</Grid>*/}
			{/*		</Grid>*/}
			{/*	</Grid>*/}

			{/*</DialogContent>*/}
		</Dialog >
	);
};

export default PasswordChangeForm;
