import { BankAccount } from '@/features/account/types';
import * as React from 'react';
import { WalletDepositAction } from '@/features/coins/types';
import { useTranslation } from 'react-i18next';
import { useCloseDialogNoEvent } from '@helpers/customHook/useCloseDialog';
import FormFooterButtons, { FormFooterButtonsProps } from '@/componentsMui/Wallet/Balances/Deposit/Components/FormFooterButtons';
import { Grid } from '@mui/material';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import AccountDetailsEUR from '@/componentsMui/Wallet/Balances/Deposit/Components/AccountDetailsEUR';
import AccountDetailsUSD from '@/componentsMui/Wallet/Balances/Deposit/Components/AccountDetailsUSD';
import AccountDetailsGBP from '@/componentsMui/Wallet/Balances/Deposit/Components/AccountDetailsGBP';

interface Props {
	title: string,
	account: BankAccount,
	depositType: WalletDepositAction,
	onBack: () => void
}

const FiatDepositFlow = ({ onBack, title, account, depositType }: Props) => {


	const { t } = useTranslation('translations');
	const closeModal = useCloseDialogNoEvent();

	const footerBtn: FormFooterButtonsProps[] = [
		{
			title: t('form.buttons.close'),
			variant: 'customOutlined',
			onClick: closeModal
		},
		{
			title: t('wallet.deposit.goToWallet'),
			variant: 'contained',
			onClick: closeModal
		}];

	return (
		<>
			<Grid id='fiat-deposit-confirm' container rowGap={1} >
				<Grid item xs={12} >
					<FormHeader title={title} onBack={onBack} />
				</Grid>
				<Grid item xs={12}>
					{(depositType === WalletDepositAction.SEPA || depositType === WalletDepositAction.SEPA_INSTANT) && <AccountDetailsEUR account={account} />}
					{depositType === WalletDepositAction.SWIFT && <AccountDetailsUSD account={account} />}
					{depositType === WalletDepositAction.CHAPS && <AccountDetailsGBP account={account} />}
				</Grid>
			</ Grid >
			<FormFooterButtons buttons={footerBtn} />
		</>
	);
};

export default FiatDepositFlow;
