import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Box, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { PaymentStatus } from '@/features/referrals/types';

const ReferralsRewardsStatusCell = (params: GridRenderCellParams) => {
    const { t } = useTranslation('translations');
    const theme = useTheme() as Theme;

    if (params.value === null || params.value === undefined) {
        return '';
    }

    const statusColor = params.value === PaymentStatus.COMPLETED ? theme.statusColor.success : theme.statusColor.warning;

    return (
        <>
            <Box
                sx={{
                    borderRadius: '10px',
                    background: `${statusColor}14`,
                    padding: '0px 10px',
                    width: 'fit-content',
                    minWidth: '4rem',
                    height: '1.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography
                    fontWeight={700}
                    fontSize={'0.563rem'}
                    sx={{ color: statusColor }} >
                     {t(`referral.paymentStatus.${params.value}`)}
                </Typography>
            </Box>
        </>
    );
};

export default ReferralsRewardsStatusCell;
