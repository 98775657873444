import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Tab, Tabs } from '@mui/material';
import Concierge from './Concierge';
import Flexiroam from './Flexiroam';
import Airport from './Airport';

enum BenefitTabEnum {
	CONCIERGE = 'CONCIERGE',
	FLEXIROAM = 'FLEXIROAM',
	AIRPORT = 'AIRPORT'
}

const Benefits = () => {
	const { t } = useTranslation('translations');
	const [activeTab, setActiveTab] = useState(BenefitTabEnum.CONCIERGE);

	const handleTabChange = (event: React.SyntheticEvent, value: BenefitTabEnum) => {
		setActiveTab(value);
	};

	return (
		<Grid container>
			<Grid item xs={12}  >
				<Tabs value={activeTab}
					onChange={handleTabChange}
				>
					<Tab value={BenefitTabEnum.CONCIERGE}
						label={t('benefits.concierge')}
						sx={{ width: '10.5rem' }}
					/>
					<Tab value={BenefitTabEnum.FLEXIROAM}
						label={t('benefits.flexiroam')}
						sx={{ width: '10.5rem' }}
					/>
					<Tab value={BenefitTabEnum.AIRPORT}
						label={t('benefits.airport')}
						sx={{ width: '10.5rem' }}
					/>
				</Tabs>
			</Grid>
			<Grid item xs={12} paddingBottom={3} maxWidth='800px' >
				{activeTab === BenefitTabEnum.CONCIERGE && <Concierge />}
				{activeTab === BenefitTabEnum.FLEXIROAM && <Flexiroam />}
				{activeTab === BenefitTabEnum.AIRPORT && <Airport />}
			</Grid>
		</Grid>
	);
};

export default Benefits;
