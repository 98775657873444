import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { useTranslation } from 'react-i18next';
import { refreshUser } from '@/features/user/userSlice';
import { Dialog, Grid } from '@mui/material';
import StatusCard from './HomeSummary/Components/StatusCard';
import { useHistory } from 'react-router-dom';

const DialogSuspended = (): React.ReactElement => {
  const { i18n } = useTranslation('translations');
  const preferredLang = useSelector((state: RootState) => state.user?.user?.preferredLanguage);
  const currentLang = i18n.language;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (preferredLang && preferredLang !== currentLang) {
      i18n.changeLanguage(preferredLang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferredLang]);

  useEffect(() => {
    const get = async () => {
      await dispatch(refreshUser());
    };
    get();
  }, [dispatch]);

  return (
    <Dialog
      id="user-suspended"
      open={true}
      onBackdropClick={() => history.goBack()}
    >
      <Grid item xs={12} container justifyContent='center'>
        <Grid item xs={12}>
          <StatusCard />
        </Grid>
      </Grid>
    </Dialog>);
};

export default DialogSuspended;
