import { ThemeOptions } from '@mui/material/styles/createTheme';
import { createTheme } from '@mui/material/styles';

import defaultLighTheme from '../default/defaultLightTheme'; 

const newTheme: ThemeOptions = {
	...defaultLighTheme,
	selectedItemBg: {
		default: 'linear-gradient(to bottom, #2E2E39 0%, #6F6761 100%)',
	},
	iconGradient: {
		...defaultLighTheme.iconGradient,
		stopColor1: '#2E2E39',
		stopColor2: '#E1DFDE'
	},
	highlight: '#2E2E39',
	carousel: {
		...defaultLighTheme.carousel,
		iconShadow: '0px 1px 15px 2px rgba(122, 86, 248, 0.4)'
	},
	covers: {
		...defaultLighTheme.covers,
		productCover: {
			...defaultLighTheme.covers.productCover,
			bg: [{
				firstBg: 'linear-gradient(to bottom, #2E2E39 0%, #6F6761 100%)',
				secondBg: 'linear-gradient(to bottom, #2E2E39 0%, #6F6761 100%)',
			},
			//wallet
			{
				firstBg: 'linear-gradient(329.09deg, #0500F4 91.04%, #FAFF00 91.05%)',
				secondBg: 'linear-gradient(329.09deg, #0500F4 91.04%, #FAFF00 91.05%)',
			}]

		},
		paymentMethodCover: {
			...defaultLighTheme.covers.paymentMethodCover,
			bg: [{
				firstBg: 'linear-gradient(to bottom, #2E2E39 0%, #6F6761 100%)',
				secondBg: 'linear-gradient(to bottom, #2E2E39 0%, #6F6761 100%)',
			},
			{
				firstBg: 'linear-gradient(0deg, #7A56F8, #7A56F8)',
				secondBg: 'linear-gradient(0deg, #7A56F8, #7A56F8)',
			}],
		}
	},
	warningBg:  'linear-gradient(112.26deg, #98928E1a 10.81%, rgba(1, 132, 253, 0.1) 160.09%)',
	auth: {
		welcomeCardBg: 'linear-gradient(2.88deg, #98928E -10.83%, #E1DFDE 106.36%)',
	},
};

const paletteDark = createTheme(newTheme);

export default paletteDark;

