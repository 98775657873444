import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setLandingTitle } from '@/features/landingStep/slice';
import MinimalLayout from '../Layout/MinimalLayout';
import { Button, Grid } from '@mui/material';
import MobileHeader from './components/MobileHeader';
import { Form, Formik } from 'formik';
import MuiTextField from '../Shared/FormikComponents/MuiTextField';
import MuiFormText from '../Shared/Widgets/MuiText/MuiFormText';
import * as Yup from 'yup';
import { showException } from '@/features/swal/slice';
import { isEmpty } from 'lodash';
import FormikAutocomplete from '../Shared/FormikComponents/FormikAutocomplete';
import { getLookupValues } from '@/features/fnd/fndSlice';
import { calculateScore, saveIdentityDetails } from '@/features/identity/slice';
import { refreshUser } from '@/features/user/userSlice';
import { RootState } from '@/rootReducer';

interface Props {
	paddingTop?: number,
	next: () => void
}

const ProfileDetails = ({ paddingTop, next }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const [turnover, setTurnover] = useState([]);
	const [occupation, setOccupation] = useState([]);
	const [service, setService] = useState([]);
	const [monthlyIncome, setMonthlyIncome] = useState([]);
	const [monthlySource, setMonthlySource] = useState([]);
	const [totalAssets, setTotalAssets] = useState([]);
	const title = t('landing.profileDetails.title');
	const { agent } = useSelector((state: RootState) => state.status);

	useEffect(() => {
		dispatch(setLandingTitle(title));
	}, [dispatch, title]);

	const getLov = useCallback(async (name: string) => {
		try {
			const result = await getLookupValues(name);
			const options = result?.map(p => ({
				code: p.lookupCode,
				label: p.description
			})) || [];
			if (name === 'TURNOVER')
				setTurnover(options);
			else if (name === 'OCCUPATION')
				setOccupation(options);
			else if (name === 'RISK_BY_SERVICE')
				setService(options);
			else if (name === 'INCOME_MONTHLY')
				setMonthlyIncome(options);
			else if (name === 'INCOME_SOURCE')
				setMonthlySource(options);
			else if (name === 'TOTAL_ASSETS')
				setTotalAssets(options);
		}
		catch (e) {
			console.log(e);
		}
	}, []);

	useEffect(() => {
		getLov('TURNOVER');
	}, [getLov]);

	useEffect(() => {
		getLov('OCCUPATION');
	}, [getLov]);


	useEffect(() => {
		getLov('RISK_BY_SERVICE');
	}, [getLov]);

	useEffect(() => {
		getLov('INCOME_MONTHLY');
	}, [getLov]);

	useEffect(() => {
		getLov('INCOME_SOURCE');
	}, [getLov]);

	useEffect(() => {
		getLov('TOTAL_ASSETS');
	}, [getLov]);


	const initialValues = {
		occupationCode: { code: '', label: '' },
		projectedDepositCode: { code: '', label: '' },
		serviceCode: { code: '', label: '' },
		monthlyIncomeCode: { code: '', label: '' },
		monthlySourceCode: { code: '', label: '' },
		totalAssets: { code: '', label: '' },
		otherReason: '',
		background_description: ''
	};

	const validationSchema = Yup.object({
		occupationCode: Yup.object().shape({
			code: Yup.string().required(t('form.validator.required')),
		}),
		projectedDepositCode: Yup.object().shape({
			code: Yup.string().required(t('form.validator.required')),
		}),
		serviceCode: Yup.object().when([], {
			is: () => agent !== 'SLP',
			then: Yup.object().shape({
				code: Yup.string().required(t('form.validator.required')),
			}),
			otherwise: Yup.object().notRequired()
		}),
		monthlyIncomeCode: Yup.object().shape({
			code: Yup.string().required(t('form.validator.required')),
		}),
		monthlySourceCode: Yup.object().shape({
			code: Yup.string().required(t('form.validator.required')),
		}),
		totalAssets: Yup.object().when([], {
			is: () => agent === 'ESCROWFY,',
			then: Yup.object().shape({
				code: Yup.string().required(t('form.validator.required')),
			}),
			otherwise: Yup.object().notRequired()
		}),
		otherReason: Yup.string().test('otherReason', t('form.validator.required'), (value, ctx) => (ctx.parent.serviceCode.code === '99' && !!value) || ctx.parent.serviceCode.code !== '99')
			.max(100, t('form.validator.maxNumber', { field: t('landing.profileDetails.otherReason'), chars: '100' })),
		background_description: Yup.string().test('background_description', t('form.validator.required'), (value) => (agent === 'ESCROWFY' && !!value) || agent !== 'ESCROWFY')
			.max(100, t('form.validator.maxNumber', { field: t('landing.profileDetails.otherReason'), chars: '100' }))
	});

	const submit = async (formData) => {

		try {

			const { occupationCode, projectedDepositCode, serviceCode, monthlyIncomeCode, monthlySourceCode, otherReason, totalAssetsCode, background_description } = formData;

			await saveIdentityDetails({
				occupationCode: occupationCode.code,
				projectedDepositCode: projectedDepositCode.code,
				monthlyIncomeCode: monthlyIncomeCode.code,
				sourceOfIncomeCode: monthlySourceCode.code,
				subscriptionReasonCode: agent === 'SLP' ? service.find(s => s.label === 'Investment')?.code ?? '3' : serviceCode.code,
				subscriptionReason: otherReason,
				totalAssetsCode: totalAssetsCode?.code,
				backgroundDetails: background_description
			});

			await calculateScore();
			await dispatch(refreshUser());
			next();
		} catch (e) {
			showException(e);
		}

	};

	useEffect(() => {
		dispatch(setLandingTitle(title));
	}, [dispatch, title]);


	return (
		<MinimalLayout paddingTop={paddingTop} allowGoHome={false}>
			<Grid container id='poa-step' >
				<Grid item >
					<MobileHeader text={title} />
					<Formik
						id='profile-detail-step'
						initialValues={initialValues}
						enableReinitialize
						validationSchema={validationSchema}
						onSubmit={submit}
					>
						{({ values, errors, isSubmitting }) => {
							return (
								<Form>
									<Grid container>
										<Grid item xs={12} mb={{ xs: '20px', sm: '40px' }} >
											<MuiFormText >
												{t('landing.profileDetails.details')}
											</MuiFormText>
										</Grid>
										<Grid item xs={12} container rowGap={{ xs: 0, sm: '20px' }}>
											<Grid item xs={12}>
												<MuiFormText dangerouslySetInnerHTML={{ __html: t('landing.profileDetails.occupation') }} />
												<FormikAutocomplete fieldName='occupationCode'
													options={occupation}
												/>

											</Grid>
											<Grid item xs={12}>
												<MuiFormText dangerouslySetInnerHTML={{ __html: t('landing.profileDetails.amount') }} />
												<FormikAutocomplete fieldName='projectedDepositCode'
													options={turnover}
												/>

											</Grid>
											<Grid item xs={12}>
												<MuiFormText dangerouslySetInnerHTML={{ __html: t('landing.profileDetails.monthlyIncome') }} />
												<FormikAutocomplete fieldName='monthlyIncomeCode'
													options={monthlyIncome}
												/>

											</Grid>
											<Grid item xs={12}>
												<MuiFormText dangerouslySetInnerHTML={{ __html: t('landing.profileDetails.monthlySource') }} />
												<FormikAutocomplete fieldName='monthlySourceCode'
													options={monthlySource}
												/>

											</Grid>
											{agent === 'ESCROWFY' ?
												<Grid item xs={12}>
													<MuiFormText dangerouslySetInnerHTML={{ __html: t('landing.profileDetails.totalAssets') }} />
													<FormikAutocomplete fieldName='totalAssetsCode'
														options={totalAssets}
													/>

												</Grid>
												: null}
											{agent !== 'SLP' ? <Grid item xs={12}>
												<MuiFormText dangerouslySetInnerHTML={{ __html: t('landing.profileDetails.reason') }} />
												<FormikAutocomplete fieldName='serviceCode'
													options={service}
												/>

											</Grid> : null}
											{values.serviceCode?.code === '99' &&
												<Grid item xs={12}>
													<MuiTextField
														name="otherReason"
														label={t('landing.profileDetails.otherReason')}
														fullWidth
														inputProps={{ maxLength: 100 }}
													/>
												</Grid>
											}
											{agent === 'ESCROWFY' &&
												<Grid item xs={12}>
													<MuiTextField
														name="background_description"
														label={t('landing.profileDetails.backgroundDetails')}
														fullWidth
														inputProps={{ maxLength: 100 }}
													/>
												</Grid>
											}
											<Grid item xs={12} mb={3}>
												<Button
													variant='contained'
													type="submit"
													fullWidth
													disabled={isSubmitting || !isEmpty(errors)}
												>
													{t('form.buttons.next')}
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Form>);
						}}
					</Formik>
				</Grid>
			</Grid>

		</MinimalLayout >);
};

export default ProfileDetails;
