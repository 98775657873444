import { Box, Button, Dialog, Grid, Theme } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AvailablePlan } from '@/features/user/types';
import AccountDropdown from '@/componentsMui/Shared/FormikComponents/AccountDropdown';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { chooseUserLevel } from '@/features/user/userSlice';
import { showErrorNotification } from '@/features/swal/slice';
import { isEmpty } from 'lodash';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import { AccountType } from '@/features/operations/types';
import PlanCard from '../../Plans/PlanCard';
import FormPaperLargeFixed from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeFixed';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import { makeStyles, useTheme } from '@mui/styles';
import NumberFormat from 'react-number-format';
import { getCurrencySign } from '@/features/fees/slice';
import ConfirmationBox from '@/componentsMui/Shared/Components/ConfirmationBox';
import MuiTextConfirmationLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextConfirmationLabel';
import MuiTextConfirmation from '@/componentsMui/Shared/Widgets/MuiText/MuiFormField';
import { Link } from 'react-router-dom';
import bigDecimal from 'js-big-decimal';


interface Props {
	newPlan: AvailablePlan,
	open: boolean,
	onClose: () => void,
}

enum Step {
	FORM = 'FORM', CONFIRMATION = 'CONFIRMATION'
}

const useStyles = makeStyles(() => ({
	priceBox: {
		padding: '20px',
		borderRadius: '10px',
		background: '#FFFFFF0F',
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '30px'
	},
	planPrice: {
		fontWeight: 700,
		fontSize: '1.25rem'
	}
}));

const PlanPaymentForm = ({ newPlan, open, onClose }: Props) => {
	const { t } = useTranslation('translations');
	const theme = useTheme() as Theme;
	const classes = useStyles(theme);

	const accountList = useSelector((state: RootState) => state.accounts.list);
	const filteredAccounts = accountList.filter(p => [AccountType.BANK.toString(), AccountType.BANK_AND_CARD.toString()].includes(p.type) && p.currency === newPlan.ccy);
	const [step, setStep] = useState<Step>(Step.FORM);
	const [accountFromId, setAccountFromId] = useState<number>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const price = newPlan.price || 0;
	const defaultAccount = filteredAccounts?.length > 0 ? filteredAccounts[0] : undefined;

	const showAccountSelect = price > 0 || !defaultAccount || bigDecimal.compareTo(defaultAccount?.availableBalance ?? 0, price) < 0;
	const initialValues = {
		accountFrom: defaultAccount?.accountId || '',
	};

	const validationSchema = Yup.object({
		accountFrom: Yup.string().required(t('form.validator.required'))
			.test('accountFrom', t('sendmoneyTranslation.data.insufficient'), (value) => bigDecimal.compareTo(filteredAccounts.find(a => a.accountId === parseInt(value))?.availableBalance ?? 0, price) >= 0)
	});

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(true);
		const { accountFrom } = formData;

		setAccountFromId(accountFrom);
		setStep(Step.CONFIRMATION);
	};

	const handleConfirm = async () => {
		try {
			setLoading(true);
			await chooseUserLevel(accountFromId, newPlan.userLevelId, null);
			onClose();
		} catch (e) {
			showErrorNotification(e);
		} finally {
			setLoading(false);
		}
	};



	return (
		<Dialog
			id="plan-payment-form"
			open={open}
			PaperComponent={FormPaperLargeFixed}
		>
			<>
				{step === Step.FORM && <>
					<Grid container justifyContent='space-between'>
						<Grid item>
							<FormHeader title={t('plans.v3.upgradePlan')} showBack={false} mb='1rem' />
						</Grid>
					</Grid>
					<Grid container flex={1} direction='column' >
						<Formik
							enableReinitialize={true}
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={submit}
						>
							{({ errors, isSubmitting }) => {
								return (
									<Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }} >
										<Grid container>
											<Grid container justifyContent="center" >
												<Grid item xs={10} md={6} mt='20px'>
													<PlanCard
														plan={newPlan}
														hideButton={true}
													/>
												</Grid>
												{showAccountSelect &&
													<>
														<Grid item xs={12}>
															<MuiTextCaption fontSize='1rem' fontWeight={700} mb='1.25rem' mt='2.5rem'>{t('plans.v3.paymentMethod')}</MuiTextCaption>
														</Grid>
														<Grid item xs={12}>
															<AccountDropdown
																fieldlabel={t('plans.v3.payWith')}
																fieldName='accountFrom'
																accountList={filteredAccounts}
																precision={2}
															/>
														</Grid>
													</>}
												<Grid item xs={12} mt={showAccountSelect ? 0 : 10}>
													<Box className={classes.priceBox}>
														<MuiTextCaption color='text.secondary' fontSize='0.813rem' fontWeight={400}>{t('plans.v3.total')}</MuiTextCaption>
														<MuiTextCaption className={classes.planPrice}>{price === 0 ? t('plans.v3.free') :
															<NumberFormat
																displayType={'text'}
																decimalScale={2}
																fixedDecimalScale={true}
																thousandsGroupStyle='thousand'
																thousandSeparator={true}
																value={price}
																prefix={`${getCurrencySign(newPlan.ccy)} `}
															/>}
														</MuiTextCaption>
													</Box>
												</Grid>
											</Grid>

										</Grid>
										<Grid container flex={1} alignItems='flex-end' justifyContent='space-between' pt={'3rem'}>
											<Button
												key='buttonCancel'
												variant='customOutlined'
												onClick={onClose}
											>   	{t('form.buttons.back')}
											</Button>
											<Button
												type="submit"
												variant="contained"
												color='primary'
												disabled={isSubmitting || !isEmpty(errors)}
											>
												{t('form.buttons.continue')}
											</Button>
										</Grid>
									</Form>
								);
							}}
						</Formik >
					</Grid>
				</>}


				{step === Step.CONFIRMATION && <>
					<Grid container justifyContent='space-between'>
						<Grid item>
							<FormHeader title={t('plans.v3.confirmation')} onBack={() => setStep(Step.FORM)} />
						</Grid>
					</Grid>
					<Grid container rowSpacing={2}>
						<Grid item xs={12}>
							<ConfirmationBox customPadding='1.25rem'>
								<Grid container className='confirmationInfo' pl={3} pr={3} rowSpacing={1}>
									<Grid item container xs={12}>
										<Grid item xs={4} pr={4} display='flex' alignItems='center'>
											<MuiTextConfirmationLabel variant='body2'>{t('plans.v3.plan')}</MuiTextConfirmationLabel>
										</Grid>
										<Grid item xs={8} display='flex' alignItems='center'>
											<MuiTextConfirmation> {newPlan.name} </MuiTextConfirmation>
										</Grid>
									</Grid>
									<Grid item container xs={12}>
										<Grid item xs={4} pr={4} display='flex' alignItems='center'>
											<MuiTextConfirmationLabel variant='body2'>{t('plans.v3.price')}</MuiTextConfirmationLabel>
										</Grid>
										<Grid item xs={8} display='flex' alignItems='center'>
											<MuiTextConfirmation>
												<NumberFormat
													displayType={'text'}
													decimalScale={2}
													fixedDecimalScale={true}
													thousandsGroupStyle='thousand'
													thousandSeparator={true}
													value={price}
													prefix={`${getCurrencySign(newPlan.ccy)} `}
													suffix={t('plans.v3.mo')}
												/>
											</MuiTextConfirmation>

										</Grid>
									</Grid>
									<Grid item container xs={12}>
										<Grid item xs={4} pr={4} display='flex' alignItems='center'>
											<MuiTextConfirmationLabel variant='body2'>{t('plans.v3.terms')}</MuiTextConfirmationLabel>
										</Grid>
										<Grid item xs={8} display='flex' alignItems='center'>
											<Link to='/legal/terms' style={{ textDecoration: 'none', fontSize: '0.813rem', fontWeight: 700 }} >{t('plans.v3.seeTerms')}</Link>
										</Grid>
									</Grid>
								</Grid>
							</ConfirmationBox>
						</Grid>

						<Grid item xs={12}>
							<ConfirmationBox customPadding='1.25rem'>
								<Grid container className='confirmationInfo' pl={3} pr={3} rowSpacing={1}>
									<Grid item container xs={12}>
										<Grid item xs={4} pr={4} display='flex' alignItems='center'>
											<MuiTextConfirmationLabel variant='body2'>{t('plans.v3.payment')}</MuiTextConfirmationLabel>
										</Grid>
										<Grid item xs={8} display='flex' alignItems='center'>
											<MuiTextConfirmation> {filteredAccounts.find(a => a.accountId === accountFromId).name} </MuiTextConfirmation>
											<MuiTextConfirmation color='text.secondary' ml='0.5em'> {filteredAccounts.find(a => a.accountId === accountFromId).iban} </MuiTextConfirmation>
										</Grid>
									</Grid>
								</Grid>
							</ConfirmationBox>
						</Grid>

						<Grid item xs={12}>
							<ConfirmationBox customPadding='1.25rem'>
								<Grid container className='confirmationInfo' pl={3} pr={3} rowSpacing={1}>
									<Grid item container xs={12}>
										<Grid item xs={4} pr={4} display='flex' alignItems='center'>
											<MuiTextConfirmationLabel variant='body2'>{t('plans.v3.total')}</MuiTextConfirmationLabel>
										</Grid>
										<Grid item xs={8} display='flex' alignItems='center'>
											<MuiTextConfirmation>
												<NumberFormat
													displayType={'text'}
													decimalScale={2}
													fixedDecimalScale={true}
													thousandsGroupStyle='thousand'
													thousandSeparator={true}
													value={price}
													prefix={`${getCurrencySign(newPlan.ccy)} `}
												/>
											</MuiTextConfirmation>
										</Grid>
									</Grid>
								</Grid>
							</ConfirmationBox>
						</Grid>


					</Grid>
					<Grid container flex={1} alignItems='flex-end' justifyContent='space-between' pt={'3rem'}>
						<Button
							key='buttonCancel'
							variant='customOutlined'
							onClick={() => setStep(Step.FORM)}
						>   	{t('plans.v3.back')}
						</Button>
						<Button
							disabled={loading}
							key='prieview'
							variant='contained'
							onClick={handleConfirm}>
							{t('form.buttons.confirm')}
						</Button>
					</Grid>
				</>}
			</>

		</Dialog >);
};

export default PlanPaymentForm;
