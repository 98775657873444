import * as React from 'react';
import { Grid, } from '@mui/material';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { Ticket, TicketMessage, TicketType } from '@/features/tickets/types';
import TicketFileList from './TicketFileList';
import { format, isAfter, isToday } from 'date-fns';
import { convertDate } from '@/helpers/date';
import { useTranslation } from 'react-i18next';


interface Props {
  ticket: Ticket
}

interface MessageProps {
  ticketId: number,
  idx: number,
  message: TicketMessage,
  indicentType: string,
  prevCreatedDate: string
}

export const isPlatformMessage = (indicentType: string) =>
  indicentType === TicketType.NOTIFICATION ||
  indicentType === TicketType.TRANSFER_REQUEST;

const getDateWithoutTime = (date: string) => {
  const msgDate = new Date(date);
  return new Date(msgDate.getFullYear(), msgDate.getMonth(), msgDate.getDate());
};

const LegacyMessages = ({ ticket }: Props) => {

  const { t } = useTranslation('translations');


  const ChatMessage = ({ idx, ticketId, message, indicentType, prevCreatedDate }: MessageProps) => {
    const authorYou = (idx === 0 && isPlatformMessage(indicentType)) ? false : message.authorYou;
    const msgDateNoTime = getDateWithoutTime(message.createdDate);
    const prevDateNoTime = prevCreatedDate ? getDateWithoutTime(prevCreatedDate) : null;
    const showDateLine = prevDateNoTime ? isAfter(msgDateNoTime, prevDateNoTime) : true;
    const msgDate = new Date(message.createdDate);

    const dateHeader = isToday(msgDate) ? `${t('form.today')} ${format(msgDate, 'h:mm a')}` : format(msgDate, 'dd MMM yyyy');

    return (<>
      {showDateLine &&
        <Grid container justifyContent='center' pt='48px' pb='10px'>
          <MuiFormText color='text.secondary'>
            {dateHeader}
          </MuiFormText>
        </Grid>
      }
      <Grid container justifyContent={authorYou ? 'flex-start' : 'flex-end'} pt='20px'>
        <Grid item container
          sx={(theme) => ({
            padding: '1.25rem',
            background: authorYou ? 'rgba(255, 255, 255, 0.1)' : theme.selectedItemBg.default,
            borderRadius: theme.mainBorderRadius,
            width: '80%'
          })}>
          <Grid item xs={12} display='flex'>
            {<MuiFormText dangerouslySetInnerHTML={{ __html: message.message }} />}
          </Grid>
          <Grid item xs={12} container justifyContent='flex-end'  >
            <MuiFormText sx={{ color: 'rgba(255, 255, 255, 0.4)' }}>
              {isToday(msgDate) ?
                convertDate(msgDate, 'h:mm a') :
                convertDate(msgDate, 'dd MMM yyyy h:mm a')}
            </MuiFormText>
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent={authorYou ? 'flex-start' : 'flex-end'} >
          <TicketFileList
            ticketAttachments={message.ticketAttachments}
            ticketId={ticketId} />
        </Grid>
      </Grid>
    </>
    );
  };

  return (
    <Grid container  >
      {ticket?.ticketMessages?.length > 0 && ticket.ticketMessages.map((item, idx) =>
        <Grid item xs={12} key={idx}>
          <ChatMessage
            idx={idx}
            ticketId={ticket.id}
            message={item}
            indicentType={ticket.incidentType}
            prevCreatedDate={ticket.ticketMessages[idx - 1]?.createdDate || null} />
        </Grid>)
      }
    </Grid>);
};

export default LegacyMessages;
