import { Divider } from '@mui/material';
import { withStyles } from '@mui/styles';


const StyledDividerThin = withStyles((theme) => ({
	root: {
		borderColor: (theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.primary.main),
		borderWidth: '1px 0 0 0',
	}
}))(Divider);

export default StyledDividerThin;
