import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Grid, SxProps, Theme, Typography } from '@mui/material';

const Airport = () => {
	const { t } = useTranslation('translations');

	const bulletList = [
		{ title: t('benefits.airpotSection.list1.l1') },
		{ title: t('benefits.airpotSection.list1.l2') },
		{ title: t('benefits.airpotSection.list1.l3') }];

	const stepList = [
		{
			title: t('benefits.airpotSection.list2.title'),
			steps: [
				{
					description: <Trans
						i18nKey="benefits.airpotSection.list2.l1"
						t={t}
						components={{
							url: <a rel="noreferrer" target="_blank" href='https://airport.mastercard.com/' />
						}} />,
				},
				{ description: t('benefits.airpotSection.list2.l2') },
				{ description: t('benefits.airpotSection.list2.l3') },
			],
		},
		{
			title: t('benefits.airpotSection.list3.title'),
			steps: [
				{
					description: <Trans
						i18nKey="benefits.airpotSection.list3.l1"
						t={t}
						components={{
							url: <a rel="noreferrer" target="_blank" href='https://airport.mastercard.com/login' />,
						}} />,
				},
				{ description: t('benefits.airpotSection.list3.l2') },
			],
		},
		{
			title: t('benefits.airpotSection.list4.title'),
			steps: [
				{ description: t('benefits.airpotSection.list4.l1') },
				{ description: t('benefits.airpotSection.list4.l2') },
			],
		},
		{
			title: t('benefits.airpotSection.list5.title'),
			steps: [{
				description: <Trans
					i18nKey="benefits.airpotSection.list5.l1"
					t={t}
					components={{
						url1: <a rel="noreferrer" target="_blank" href='https://apps.apple.com/' />,
						url2: <a rel="noreferrer" target="_blank" href='https://play.google.com/' />
					}} />,
			},
			{ description: t('benefits.airpotSection.list5.l2') },
			],
		},
		{
			title: t('benefits.airpotSection.list6.title'),
			steps: [
				{ description: t('benefits.airpotSection.list6.l1') },
				{ description: t('benefits.airpotSection.list6.l2') },
			],
		},
	];
	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography variant='h5' sx={styles.header}>{t('benefits.airpotSection.title')}</Typography>
				<Typography sx={styles.p}>{t('benefits.airpotSection.p1')}</Typography>
				<Typography fontWeight='bold'>{t('benefits.airpotSection.list1.title')}</Typography>
				<Box sx={styles.p}>
					{bulletList.map((item, idx) => <Typography key={idx} sx={styles.listItem}>{'\u2022'} {item.title}</Typography>)}
				</Box>
				<Typography variant='h5' sx={styles.header}>{t('benefits.airpotSection.title2')}</Typography>
				{
					stepList.map((step, idx) => <Box key={idx}>
						<Typography fontWeight='bold' sx={styles.p}>{`${idx + 1} ${step.title}`}</Typography>
						{
							step.steps.map((s, i) => <Typography key={i} sx={styles.listItem} className='highlight-regular'>{'\u2022'} {s.description} </Typography>)
						}
					</Box>)
				}
				<Typography className='highlight-regular' sx={styles.p}>
					<Trans
						i18nKey="benefits.airpotSection.p2"
						t={t}
						components={{
							url: <a rel="noreferrer" target="_blank" href='https://airport.mastercard.com/en/help/' />
						}} />
				</Typography>
			</Grid>
		</Grid>
	);
};

export default Airport;

const styles: Record<string, SxProps<Theme>> = {
	header: {
		marginTop: 4,
		fontWeight: 'bold'
	},
	p: {
		marginTop: 2,
	},
	listItem: {
		marginLeft: 2,
	},
};
