import request from '@/services/request';
import { AppThunk } from '../../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Earning, EarningsPayload, EarningsStore } from './types';



const initialState: EarningsStore = {
    loading: true,
    earnings: [],
    skip: 0,
    take: 20,
    count: 0
};

const slice = createSlice({
    name: 'earnings',
    initialState,
    reducers: {

        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setSkip: (state, { payload }: PayloadAction<number>) => {
            state.skip = payload;
        },
        setTake: (state, { payload }: PayloadAction<number>) => {
            state.take = payload;
        },
        setCount: (state, { payload }: PayloadAction<number>) => {
            state.count = payload;
        },
        setEarnings: (state, { payload }: PayloadAction<Array<Earning>>) => {
            state.earnings = payload;
        }

    }
});

export const { setEarnings, setLoading, setSkip, setTake, setCount } = slice.actions;


export const getFutureEarnings = (payload: EarningsPayload): AppThunk => {
    return async (dispatch, state) => {
        payload.filter = 'completed=false;transactionType=INTEREST_PAYMENT';
        const { earnings } = state();
        try {
            const response = await request.post('/api/v3/vaults/history', payload);
            const { data } = response;
            dispatch(setCount(data.count));
            dispatch(setEarnings([...earnings.earnings, ...data.list]));
            // dispatch(setTake(payload.take));
            dispatch(setSkip(earnings.skip + 1));

            return data;
        } catch (e) {
            dispatch(setEarnings([]));
            dispatch(setCount(0));
            dispatch(setSkip(0));
            console.log(e);
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const getEarningGraphData = async (ccy: 'EUR' | 'GBP' | 'USD', proc?: string) => {
    const response = await request.post('/api/v3/vaults/earnings/graph', { ccy, proc });
    const { data } = response;
    return data;
};


export default slice.reducer;
