import * as React from 'react';

import '@/componentsMui/Shared/Shared.css';

import {
	Button,
	Grid,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import * as Yup from 'yup';
import { RootState } from '@/rootReducer';

import { BankAccount } from '@/features/account/types';
import { showException } from '@/features/swal/slice';
import { Contact, ContactPaymentTypes } from '@/features/contacts/types';
import { lookupClientIdContact } from '@/features/contacts/slice';
import FormContentBox from '@/componentsMui/Shared/Widgets/Box/FormContentBox';
import ContactDropdown from '@/componentsMui/Shared/FormikComponents/ContactDropdown';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { findProcessByType } from '@/features/operations/slice';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import useCloseDialog from '@/helpers/customHook/useCloseDialog';
import { UserTransferPayloadType } from './UserPaymentSteps';
import StyledDivider from '@shared/Widgets/StyledDivider';
import { AccountType } from '@/features/operations/types';
import { isValidTokenWallet } from '@/features/tokens/slice';
import { NEW_LINE_REGEX } from '@/helpers/customHook/useValidations';
// import walletId from '@shared/Components/WalletId';

type Props = {
	fromAccountList?: BankAccount[],
	defaultAccount?: BankAccount,
	defaultContact?: Contact,
	userPaymentPayload: UserTransferPayloadType,
	setUserPaymentPayload: (value: UserTransferPayloadType) => void,
	nextStep: () => void,
	prevStepMain?: () => void
	prevStep?: () => void
}

const UserPaymentContactStep = ({
	defaultAccount,
	defaultContact,
	userPaymentPayload,
	setUserPaymentPayload,
	nextStep,
	prevStep
}: Props) => {

	const { t } = useTranslation('translations');

	const [selectedAccount] = useState<BankAccount>(userPaymentPayload.account);

	const [contact, setContact] = useState<Contact>(userPaymentPayload.contact ?? defaultContact);
	// const [walletId, setWalletId] = useState<string>(null);

	const allContacts = useSelector((state: RootState) => state.contacts.contacts);

	const contactsForPaymentType = allContacts.filter(contact => contact.supportedPayments.includes(ContactPaymentTypes.LOCAL));
	const process = findProcessByType(selectedAccount, 'TRANSFER');
	const clientUser = useSelector((state: RootState) => state.user.user);

	const closeModal = useCloseDialog();

	const initialValues = {
		from: defaultAccount?.accountId ?? '',

		walletId: userPaymentPayload.walletId ?? defaultContact?.clientId ?? '',
		reference: userPaymentPayload.reference ?? '',
	};

	const validationSchema = Yup.object({
		from: Yup.string().required(t('form.validator.required')),
		walletId: Yup.string().required(t('form.validator.required')),
		reference: Yup.string().trim()
			.required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-./\s])+$/, t('form.validator.latinOnlyReference'))
			.min(6, t('form.validator.minChars', { field: t('sendmoneyTranslation.data.reference'), chars: '6' }))
			.max(140, t('sendmoneyTranslation.data.canNotExceedHundred'))
			.test('reference', t('form.validator.newLine'), (value) => value ? value.search(NEW_LINE_REGEX) === -1 : true)
			.test('reference', t('form.validator.minChars', { field: t('sendmoneyTranslation.data.reference'), chars: '6' }), (value) => value ? value.replace(/ /g, '').length >= 6 : true),
	});

	const isCustomTokenTransfer = selectedAccount.type === AccountType.TOKEN;

	const submit = async (formData, formikProps) => {
		const { setFieldError } = formikProps;
		const { reference } = formData;
		const account = userPaymentPayload.account;
		const amount = userPaymentPayload.amount;
		const fee = userPaymentPayload.fee;
		const feeString = userPaymentPayload.feeString;
		const youpay = userPaymentPayload.youpay;
		const processProcedure = userPaymentPayload.processProcedure;

		if (process === null || process.proc === null) {
			setFieldError('from', t('sendmoneyTranslation.data.missingProcess'));
		}

		const walletId = formData?.walletId?.toUpperCase();
		try {
			let user = null;
			if (!isCustomTokenTransfer) {
				try {

					user = await lookupClientIdContact(walletId, account.accountId);
					if (user.proc !== null) {
						if (selectedAccount.type !== user.accountType || selectedAccount.currency != user.currency) {
							setFieldError('walletId', t('sendmoneyTranslation.data.wrongProcess'));
							return;
						}
					}
				} catch (e) {
					if (clientUser?.tag.toLowerCase() === walletId.trim().toLowerCase()) {
						setFieldError('walletId', t('sendmoneyTranslation.data.belongsToYou'));
					}
					else {
						setFieldError('walletId', t('sendmoneyTranslation.data.userNotFound'));
					}
					return;
				}
			} else {
				const walletIsValid = await isValidTokenWallet(walletId);
				if (!walletIsValid) {
					setFieldError('walletId', t('sendmoneyTranslation.data.userNotFound'));
					return;
				}
			}


			const payload: UserTransferPayloadType = {
				...{
					account,
					amount,
					fee,
					feeString,
					youpay,
					processProcedure
				},
				...{
					walletId,
					reference,
					user,
					contact: (contactsForPaymentType.find((c) => (c.walletId === walletId))),
					isCustomTokenTransfer
				}


			};

			setUserPaymentPayload(payload);
			nextStep();

		}
		catch (e) {
			await showException(e);
		}
	};

	const handleSelectFromContacts = (value: Contact, setFieldValue) => {
		const contact = contactsForPaymentType.find(p => p.beneficiaryAccountId === value.beneficiaryAccountId);
		setContact(contact);
		// setWalletId(contact.walletId);
		setFieldValue('walletId', contact.clientId);
	};

	const handleWalletIdChange = (event: any, setFieldValue) => {
		const walletId = event.target.value;
		// setWalletId(walletId);
		setContact(null);
		setFieldValue('walletId', walletId);
	};

	return (
		<Formik
			//	enableReinitialize={true}
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={submit}
		>
			{({ errors, isSubmitting, setFieldValue }) => {
				return (
					<Form className='form-content__wrap__bb'>
						<FormContentBox noBackground={true} className='form-content_main-area wp'>
							<Grid container columnSpacing={3} rowSpacing={1}>
								<Grid item xs={12}>
									<FormHeader title={t('sendmoneyTranslation.data.cospayuserheader')} showBack={!!prevStep} onBack={prevStep} />
								</Grid>
								<Grid item xs={12} sm={6}>
									<ContactDropdown
										fieldName='contact'
										contactList={contactsForPaymentType}
										selectedContact={contact}
										fieldlabel={t('sendmoneyTranslation.data.contact')}
										onContactChange={(value: Contact) => handleSelectFromContacts(value, setFieldValue)} />
								</Grid>
								<Grid item xs={12}>
									<StyledDivider />
								</Grid>
								<Grid item xs={12}>
									<MuiTextField
										name="walletId"
										fullWidth
										inputProps={{ autoComplete: 'off' }}
										onChange={(event) => handleWalletIdChange(event, setFieldValue)}
										label={t('login.clienId')}
									/>
								</Grid><Grid item xs={12}>
									<MuiTextField
										name="reference"
										multiline
										fullWidth
										inputProps={{ autoComplete: 'off', maxLength: 140 }}
										label={t('sendmoneyTranslation.data.reference')}
										rows={2} />
								</Grid>
							</Grid>
							<Grid container mt='auto' pt='2rem' columnSpacing={2}>
								<Grid item xs={6}>
									<Button
										variant='customOutlined'
										color='secondary'
										onClick={closeModal}
										sx={{ width: { xs: '100%', md: 'auto' } }}
									>  {t('form.buttons.close')}
									</Button>
								</Grid>
								<Grid item xs={6} container justifyContent='flex-end'>
									<Button
										disabled={isSubmitting || !isEmpty(errors)}
										variant="contained"
										color="primary"
										sx={{ width: { xs: '100%', md: 'auto' } }}
										type="submit"
									>
										{t('form.buttons.next')}
									</Button>
								</Grid>
							</Grid>
						</FormContentBox>
					</Form>
				);
			}}
		</Formik >);
};

export default UserPaymentContactStep;
