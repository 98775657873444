
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DectaCardTransactionsPayload } from '@/features/transactions/types';
import { getDectaCardTransactions } from '@/features/transactions/slice';
import { DataGrid } from '@mui/x-data-grid';
import { Grid, useMediaQuery } from '@mui/material';
import TransactionDetailsForm from '../Transactions/Details/TransactionDetailsForm';
import { Transaction } from '../Transactions/TransactionStatements.spec';
import { transactionDetailsEnabled } from '@/helpers/transactionUtils';
import { closeForm, openForm } from '@/features/forms/slice';
import Spinner from '../Shared/Widgets/Spinner/Spinner';
import { AccountType } from '@/features/operations/types';
import { BankAccount } from '@/features/account/types';
import { getMobileTrxGridColumns, getTrxGridColumns } from './Components/TransactionColumns';
import TrxGenericError from './Components/TrxGenericError';
import CardTransactionsOnhold from './CardTransactionsOnhold';
import MuiHeader from '../Shared/Widgets/MuiText/MuiHeader';
import { useTranslation } from 'react-i18next';
import GridPaginationArrows from './Components/GridPaginationArrows';
import { CardIntegrationEnum } from '@/features/card/types';

interface Props {
    account: BankAccount
}

const CardTransactionGridShort = ({ account }: Props) => {


    const defaultTake = 5;
    const { t } = useTranslation('translations');
    const type = AccountType.CARD;
    const dispatch = useDispatch();
    const smallScreen = useMediaQuery('(max-width:600px)');

    const [loadingError, setLoadingError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingTrx, setLoadingTrx] = useState<boolean>(false);
    const [openTransactionDetails, setOpenTransactionDetails] = useState<boolean>(false);
    const [selectedTrx, setSelectedTrx] = useState<Transaction>();

    const [transactions, setTransactions] = useState<any[]>([]);
    const hideDetails = account?.process?.integration === CardIntegrationEnum.SH_FINANCE;

    const columns = useMemo(() => smallScreen ?
        getMobileTrxGridColumns(type, account?.subProcesses) :
        getTrxGridColumns(type, account?.subProcesses, hideDetails), [type, account?.subProcesses, smallScreen, hideDetails]);


    const fetch = useCallback(async () => {
        try {
            setLoadingError(false);
            setLoadingTrx(true);
            let list = [];
            const dectaPayload: DectaCardTransactionsPayload = {
                take: defaultTake,
                accountId: account.accountId,
                initial: true
            };
            const ranageResults = await getDectaCardTransactions(dectaPayload);
            list = ranageResults?.list;
            setTransactions(list);
        }
        catch (e) {
            setLoadingError(true);
            setTransactions([]);
        }
        finally { setLoadingTrx(false); }
    }, [account?.accountId]);


    /*     const fetch = useCallback(async (newPayload: DectaCardTransactionsPayload) => {
            try {
                setLoadingError(false);
                setLoadingTrx(true);
                const result = await (getDectaCardTransactions(newPayload));
                setTransactions(result?.list);
            }
            catch (e) {
                setLoadingError(true);
                setTransactions([]);
            }
            finally { setLoadingTrx(false); }
        }, []); */


    useEffect(() => {

        fetch();
        return () => {
            setTransactions([]);
        };
    }, [fetch, account?.availableBalance]);


    const handleOnCellClick = (params) => {
        const trx = params.row;
        if (transactionDetailsEnabled(trx.activity) && params.field !== 'status') {
            dispatch(openForm());
            setOpenTransactionDetails(true);
            setSelectedTrx(trx);
        }
    };

    const closeTransactionDetailsForm = React.useCallback(() => {
        setOpenTransactionDetails(false);
        dispatch(closeForm());
    }, [dispatch]);

    return (<>

        <TransactionDetailsForm open={openTransactionDetails}
            onClose={closeTransactionDetailsForm}
            transaction={selectedTrx}
            account={account}
            type={type}
            setLoading={(value: boolean) => setLoading(value)}
        />
        {loading && <Grid container justifyContent='center' mt={2}><Spinner /></Grid >}
        <Grid container>
            <Grid item xs={12}>
                <CardTransactionsOnhold account={account}
                    columns={columns}
                    handleOnCellClick={handleOnCellClick} />
            </Grid>
            <Grid item xs={12}>
                <MuiHeader>{t('transaction.header.transactions')}</MuiHeader>
            </Grid>
            <Grid item xs={12} >
                {loadingError ? <TrxGenericError onButtonClick={() => { fetch(); }}
                /> :
                    <DataGrid
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: defaultTake, page: 0 },
                            },
                        }}
                        autoPageSize={false}
                        autoHeight={true}
                        pagination
                        rows={transactions}
                        loading={loadingTrx}
                        getRowId={(row) => `${row.transactionNumber}`}
                        onCellClick={handleOnCellClick}
                        disableRowSelectionOnClick={true}
                        columns={columns}
                        hideFooter={true}
                        slots={{
                            pagination: GridPaginationArrows,
                        }}
                        sx={{
                            // pointer cursor on ALL rows
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer'
                            }
                        }}
                    />
                }
            </Grid>

        </Grid>

    </>);

};

export default CardTransactionGridShort;

