import { TooltipProps, Grid, Tooltip } from '@mui/material';
import React, { FC } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';


interface Props extends TooltipProps {
	title: NonNullable<React.ReactNode>
}

const ClickableTooltip: FC<Props> = (props: Props) => {

	const { title, ...others } = props;

	const [open, setOpen] = React.useState(false);

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		setOpen(!open);
	};

	return (<ClickAwayListener onClickAway={handleTooltipClose}>
		<Grid alignItems='center' display='flex'>
			<Tooltip
				PopperProps={{
					disablePortal: true,
				}}
				onClose={handleTooltipClose}
				open={open}
				disableFocusListener
				disableHoverListener
				disableTouchListener
				title={title}
				componentsProps={{
					tooltip: {
						sx: {
							mt: '0!important',
							wordWrap: 'inherit',
							maxWidth: 'unset',
						},
					},
				}}
				{...others}
			>
				<Grid item display='flex' alignItems={'center'}
					  onClick={handleTooltipOpen}
					  onMouseEnter={handleTooltipOpen}
					  onMouseLeave={handleTooltipClose}
				>
					{props.children}
				</Grid>
			</Tooltip>
		</Grid>
	</ClickAwayListener>);
};

export default ClickableTooltip;
