import { TransactionStatus, TransactionType } from '@/features/transactions/types';

export interface Transaction {
	transactionNumber: string,
	activity: TransactionType,
	remitterName: string,
	asset: string,
	currencySymbol: string,
	date: string,
	status: TransactionStatus,
	amount: number,
	attachments?: [{ fileId: string, filename: string }],
	beneficiaryAccount?: string,
	beneficiaryAccountNumber?: string,
	beneficiarySortCode?: string,
	remitterAccount?: string,
	remitterAccountNumber?: string,
	remitterSortCode?: string,
	details?: string,
	beneficiary?: string,
	documentId?: number,
	currency?: string,
	conversionCurrency?: string,
	beneficiaryWalletId?: string,
	cardNumberMask?: string,
	docNumber: string,
	fees?: Array<{
		amount: number,
		ccy: string,
		details?: string,
		transactionType?: string
	}>,
	messageCode?: string,
	transactionCategoryCode?: string,
	totalFeeAmount?: number,
	totalFeeAmountString?: string
}


export enum ExportType {
	PDF = 'PDF', EXCEl = 'EXCEL'
}
