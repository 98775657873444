import BaseBox from '@/componentsMui/Shared/Widgets/Box/BaseBox';
import { Button } from '@mui/material';
import React from 'react';
import './../Onboarding.css';

interface Props {
    children?: React.ReactNode,
    btnLabel: string,
    outlined?: boolean,
    onClick: () => void

}
const VerificationBox = ({ children, onClick, btnLabel, outlined }: Props) => {

    return (<BaseBox width='250px'
        p='20px'
        height='fit-content'
        minHeight='312px'
        display='flex'
        flexDirection='column'
        justifyContent='space-between'>
        {children}
        <Button variant={outlined ? 'customOutlined' : 'contained'} onClick={onClick} sx={{ mt: '20px' }}> {btnLabel}</Button>

    </BaseBox>);
};

export default VerificationBox;

