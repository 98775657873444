import React from 'react';
import { createTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { ReactComponent as MuiInfoIcon } from '@/assets/MuiInfoIcon.svg';
import { ReactComponent as MuiWarningIcon } from '@/assets/MuiWarning.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NoRowsOverlay from '@/componentsMui/TransactionsGrid/Components/NoRowsOverlay';

const componentOverides = (theme: Theme) => createTheme(theme,
	{
		typography: {
			fileLink: {
				lineHeight: '1.25rem',
				fontSize: '0.875rem',
				fontWeight: 600,
			},
			plainLink: {
				lineHeight: '1.5rem',
				fontSize: '0.875rem',
				fontWeight: 500,
				'WebkitTextFillColor': theme.palette.text.secondary + ' !important',
				color: theme.palette.text.secondary,
				background: 'transparent !important'
			},
			gradient: {
				background: theme.selectedItemBg.default,
				'WebkitBackgroundClip': 'text',
				'WebkitTextFillColor': 'transparent'
			},
			completed: {
				background: theme.statusColor.completed,
				'WebkitBackgroundClip': 'text',
				'WebkitTextFillColor': 'transparent'
			},
			rejected: {
				color: theme.statusColor.error
			},
			highlight: {
				color: theme.highlight
			},
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					body: {
						scrollbarColor: theme.scrollBarColor + '' + theme.palette.background.default,
						scrollbarWidth: 'thin',
						'&::-webkit-scrollbar, & *::-webkit-scrollbar': {
							backgroundColor: theme.palette.background.default,
							width: 4
						},
						'&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
							borderRadius: 8,
							backgroundColor: theme.scrollBarColor,
						},
						'&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
							backgroundColor: theme.scrollBarColor,
						},
						'&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
							backgroundColor: theme.scrollBarColor,
						},
						'&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
							backgroundColor: theme.scrollBarColor,
						},
						'&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
							backgroundColor: theme.palette.background.default,
						},
					},
					//links
					'#policy a, .highlight-strong a, #onfido-mount a': {
						color: theme.highlight,
						cursor: 'pointer',
						fontWeight: 700,
						textDecoration: 'none'
					},
					' .highlight-regular  a': {
						color: theme.highlight,
						cursor: 'pointer',
						textDecoration: 'none'
					},
					' .primary-link  a': {
						color: theme.palette.text.primary
					},
					//svg icon stroke
					'.svg-icon-stroke path': {
						stroke: theme.palette.text.primary
					},
					'.svg-icon-stroke-secondary path': {
						stroke: theme.palette.text.secondary
					},
					/* '.invert-at-form .svg-icon-stroke path': {
						stroke: theme.palette.primary.contrastText
					}, */
					//highligh inside translated text
					'.highlight-strong strong': {
						background: theme.selectedItemBg.default,
						'WebkitBackgroundClip': 'text',
						'WebkitTextFillColor': 'transparent',
						fontWeight: 700,
					},
					'.highlight-regular regular': {
						background: theme.selectedItemBg.default,
						'WebkitBackgroundClip': 'text',
						'WebkitTextFillColor': 'transparent',
					},
					'.primary-highlight-regular regular': {
						background: theme.palette.text.primary,
						'WebkitBackgroundClip': 'text',
						'WebkitTextFillColor': 'transparent',
					},
					//onfido
					'#onfido-mount, .onfido-sdk-ui-Theme-portal': {
						'& .onfido-sdk-ui-CountrySelector-custom__menu': {
							background: theme.palette.background.paper,
							boxShadow: 'unset',
							border: 'unset',
							'& li:hover': {
								color: theme.palette.text.primary,
							}
						},
						'& a::before': {
							display: 'none'
						},
						'& button': {
							border: 'none',
							boxShadow: 'none',
							fontWeight: 700,
							fontSize: '0.875rem',
							'&.-action--primary': {
								background: theme.selectedItemBg.default
							},
							'&.-action--secondary': {
								background: theme.selectedItemBg.default,
								WebkitBackgroundClip: 'text',
								backgroundClip: 'text',
								WebkitTextFillColor: theme.palette.text.primary,
								color: 'transparent',
								borderRadius: theme.inputField.borderRadius,
								position: 'relative',
								zIndex: 0,
								textDecoration: 'none',
								'::before': {
									content: '""',
									position: 'absolute',
									zIndex: -1,
									inset: 0,
									border: '2px solid transparent',
									borderRadius: theme.inputField.borderRadius,
									background: 'inherit',
									backgroundOrigin: 'border-box',
									backgroundClip: 'border-box',
									'WebkitMask': ' linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
									'WebkitMaskComposite': 'xor',
									'MaskComposite': 'exclude',
									'WebkitMaskRepeat': 'no-repeat'
								},
							},
							'&.onfido-sdk-ui-DocumentSelector-option': {
								border: '1px solid ' + theme.palette.text.secondary,
							}
						},
					},
					div: {
						/*  --- swal message style -- */
						'&.swal2-container': { zIndex: 1400 },
						'&.swal2-popup': {
							borderRadius: theme.inputField.borderRadius,
							background: theme.form.bgDefault,
							backdropFilter: theme.mainBackdropFilter,
							width: '500px',
							display: 'flex',
							flexWwap: 'wrap',
							flexDirection: 'column',
							justifyContent: 'space-around',
							alignItems: 'center',
							//remove border from button
							'& .swal2-styled:focus': {
								boxShadow: 'none !important'

							},
							//style confirm button
							'& .swal2-actions': {
								'& .swal2-confirm': {
									border: 'none',
									background: theme.selectedItemBg.default,
									borderRadius: theme.inputField.borderRadius,
									width: '70%',
									fontSize: '1rem',
									height: '48px',
									fontWeight: 700,
									'&:hover': {
										background: theme.selectedItemBg.default,
									},
									[theme.breakpoints.down('sm')]: {
										width: '100%',
									},
								}
							},
							'& .swal2-header': {
								'& .swal2-icon': {
									border: 'none',
								},
								'& .swal2-icon-content .ellipse': {
									height: '5.25rem',
									width: '5.25rem',
									borderRadius: '50%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									boxShadow: '4px 4px 8px 0 rgba(255, 255, 255, 0.9) inset, -8px -8px 10px 0 rgba(0, 0, 0, .25) inset',
									backgroundColor: theme.backgroundBoxLevel2

								},
								'& .swal2-icon-content svg>path': {
									stroke: theme.selectedItemBg.default,
								},
								'& .swal2-title': {
									border: 'none',
									color: theme.palette.text.primary,
									fontSize: '1.25rem',
									fontWeight: 700,
								}
							},
							'& .swal2-content': {
								border: 'none',
								color: theme.palette.text.primary,
								fontSize: '0.813rem',
								lineHeight: '1.2rem',
								fontWeight: 400,
								paddingBottom: '1.7rem',
							},
							'& .swal2-content .stressed-text': {
								background: theme.selectedItemBg.default,
								'WebkitBackgroundClip': 'text',
								'WebkitTextFillColor': 'transparent',
								fontWeight: 700,
								fontSize: '1rem',
								paddingTop: '1rem',
								paddingBottom: '1.1rem',
							},
							'& .swal2-loader button': {
								[theme.breakpoints.down('sm')]: {
									width: '100%',
								},
							},
						},
						/*  --- carousel shadow -- */
						'& .product-carousel': {
							'& .slider-control-centerleft': {
								backgroundImage: theme.carousel.productLeftScrollShadow
							},
							'& .slider-control-centerright': {
								backgroundImage: theme.carousel.productRightScrollShadow
							}
						},
						'& .account-form-caurousel': {
							'& .slider-control-centerleft': {
								backgroundImage: theme.carousel.accountLeftScrollShadow
							},
							'& .slider-control-centerright': {
								backgroundImage: theme.carousel.accountRightScrollShadow
							}
						}
					},
					/*  --- Calendar to pick up date range -- */
					'& #date-range-form': {
						'& .rdrDateRangeWrapper,.rdrDateDisplayWrapper,.rdrDateDisplay,.rdrDateDisplayItem':
						{
							backgroundColor: theme.palette.background.default,
							borderRadius: '12px'

						},
						/* calendar dates */
						'& .rdrDays,.rdrDay,.rdrDayNumber span':
						{
							color: theme.palette.text.primary,
							fontWeight: 400,
							fontSize: '0.875rem'
						},
						/* underscore of today */
						'& .rdrDayToday .rdrDayNumber span:after':
						{
							background: theme.highlight
						},
						/*background of one day selection */
						'& .rdrDays .rdrDay .rdrStartEdge.rdrEndEdge':
						{
							background: theme.selectedItemBg.default
						},
						/*calendar month scroll */
						'& .rdrMonthAndYearWrapper':
						{
							'& .rdrNextPrevButton': {
								background: theme.selectedItemBg.default,
								borderRadius: '50%'
							},
							'& .rdrNextButton i': {
								borderColor: 'transparent transparent transparent ' + theme.palette.primary.contrastText

							},
							'& .rdrPprevButton i': {
								borderColor: 'transparent ' + theme.palette.primary.contrastText + ' transparent transparent '

							}
						}
					}
				},
			},
			MuiContainer: {
				styleOverrides: {
					maxWidthLg: {
						[theme.breakpoints.up('lg')]: {
							maxWidth: '1512px'
						},
					},
				}
			},
			MuiButton: {
				variants: [
					{
						props: { variant: 'transparent' },
						style: {
							fontSize: '0.875rem',
							lineHeight: '1rem',
							fontWeight: '500',
							background: theme.backgroundBoxLevel1,
							color: theme.palette.text.primary,
							borderRadius: theme.mainBorderRadius,
							border: theme.palette.mode === 'dark' ? 'unset' : theme.inputField.border,
							'&.Mui-disabled': {
								color: theme.palette.text.primary,
								opacity: 0.5,
							}
						},
					},
					{
						props: { variant: 'outlined' },
						style: {
							fontSize: '0.9rem',
							lineHeight: '1rem',
							fontWeight: '700',
							background: 'transparent',
							color: theme.palette.text.primary,
							borderRadius: theme.mainBorderRadius,
							border: '1px solid ' + theme.palette.text.primary,
							'&.Mui-disabled': {
								color: theme.palette.text.primary,
								opacity: 0.5,
							}
						},
					},
					{
						props: { variant: 'filter' },
						style: {
							fontSize: '0.938rem',
							lineHeight: '1.375rem',
							fontWeight: '600',
							borderRadius: theme.mainBorderRadius,
						},
					},
					{
						props: { variant: 'simple' },
						style: {
							fontSize: '0.875rem',
							lineHeight: '0.875rem',
							fontWeight: '700',
							borderRadius: '10px',
							boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.12)',
							background: theme.backgroundBoxLevel1,
							color: theme.palette.text.primary,
							'&.Mui-disabled': {
								color: theme.palette.text.primary,
								opacity: 0.5,
							}
						},
					},
					{
						props: { variant: 'simpleDark' },
						style: {
							fontSize: '0.875rem',
							lineHeight: '0.875rem',
							fontWeight: '700',
							borderRadius: '10px',
							boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.12)',
							background: theme.palette.background.paper,
							color: theme.palette.text.primary,
							'&.Mui-disabled': {
								color: theme.palette.text.primary,
								opacity: 0.5,
							}
						},
					},
					{
						props: { variant: 'login' },
						style: {
							fontSize: '0.875rem',
							lineHeight: '0.875rem',
							fontWeight: '700',
							borderRadius: '10px',
							background: theme.palette.background.paper,
							color: theme.palette.text.primary,
							height: '2.1rem',
							minWidth: '5.7rem',
							padding: '24px 14px',
							border: theme.palette.mode === 'dark' ? 'unset' : theme.inputField.border,
							'&.Mui-disabled': {
								opacity: 0.3,
							}
						},
					},
					{
						props: { variant: 'statusActive' },
						style: {
							fontSize: '0.875rem',
							lineHeight: '0.875rem',
							fontWeight: '700',
							borderRadius: '5px',
							background: theme.backgroundBoxLevel1,
							color: theme.palette.text.primary,
							padding: '22px 10px',
							width: 'fit-content',
							height: '34px',
							minWidth: '160px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							border: theme.palette.mode === 'dark' ? 'unset' : theme.inputField.border,
						},
					},
					{
						props: { variant: 'statusWarn' },
						style: {
							fontSize: '0.875rem',
							lineHeight: '0.875rem',
							fontWeight: '700',
							borderRadius: '5px',
							background: theme.warnButton,
							color: theme.statusColor.error,
							padding: '22px 10px',
							width: 'fit-content',
							height: '34px',
							minWidth: '160px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							border: theme.palette.mode === 'dark' ? 'unset' : theme.inputField.border,
							'&.Mui-disabled': {
								color: theme.statusColor.error,
							}
						},
					},
					{

						props: { variant: 'customOutlined' },
						style: {
							background: theme.selectedItemBg.default,
							WebkitBackgroundClip: 'text',
							backgroundClip: 'text',
							WebkitTextFillColor: theme.palette.text.primary,
							color: 'transparent',
							borderRadius: theme.inputField.borderRadius,
							position: 'relative',
							zIndex: 0,
							textDecoration: 'none',
							'::before': {
								content: '""',
								position: 'absolute',
								zIndex: -1,
								inset: 0,
								border: '1px solid transparent',
								borderRadius: theme.inputField.borderRadius,
								background: 'inherit',
								backgroundOrigin: 'border-box',
								backgroundClip: 'border-box',
								'WebkitMask': ' linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
								'WebkitMaskComposite': 'xor',
								'MaskComposite': 'exclude',
								'WebkitMaskRepeat': 'no-repeat'
							},
							'&.Mui-disabled': {
								color: theme.palette.text.secondary,
								background: 'inherit',
								border: '1px solid ' + theme.palette.text.secondary,
								opacity: 0.5
							}
						},

					},
				],
				styleOverrides: {
					root: {
						'& .MuiButton-startIcon': { fill: theme.palette.text.primary },

						textTransform: 'none', // removes uppercase transformation  
						border: 'none',
						padding: '0.75rem 1.5rem',
						fontWeight: 700,
						fontSize: '0.875rem',
						lineHeight: '0.875rem',
						borderRadius: theme.inputField.borderRadius,
					},
					containedPrimary: {
						background: theme.selectedItemBg.default,
						'&.Mui-disabled': {
							background: theme.palette.mode === 'dark' ? theme.backgroundBoxLevel1 : theme.bgSecondaryButton
						}
					},
					containedSecondary: {
						background: theme.bgSecondaryButton,
						color: theme.palette.text.primary,
						'&:hover': {
							background: 'unset',
						},
					},
				},
			},
			MuiToggleButtonGroup: {
				styleOverrides: {
					root: {
						background: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.backgroundBoxLevel1,
						borderRadius: theme.inputField.borderRadius,
						'& .MuiToggleButtonGroup-grouped': {
							'&:not(:first-of-type)': {
								borderRadius: theme.inputField.borderRadius,
								border: 0
							},
							'&:first-of-type': {
								borderRadius: theme.inputField.borderRadius,
							},
						},
					}
				}
			},
			MuiTextField: {
				defaultProps: {
					InputLabelProps: {
						shrink: true //this allows to show placeholder
					}
				},
				styleOverrides: {
					root: {
						'& input::placeholder': {
							fontSize: '0.938rem',
							lineHeight: '1.375rem',
							fontWeight: 400
						}
					}
				}
			},
			MuiToggleButton: {
				defaultProps: {
					disableRipple: true
				},
				styleOverrides: {
					root: {
						margin: '0.25rem',
						background: 'transparent',
						color: theme.palette.text.secondary,
						textTransform: 'none', // removes uppercase transformation   
						border: 'none',
						borderRadius: theme.inputField.borderRadius,
						'&.Mui-selected': {
							background: theme.selectedItemBg.default,
							color: theme.palette.primary.contrastText,
							borderRadius: theme.inputField.borderRadius,
							'&:hover': {
								background: theme.selectedItemBg.default
							},
						},
						'&:hover': {
							background: 'transparent'
						},
					},
				}
			},
			MuiDialog: {
				styleOverrides: {
					root: {
						'& .MuiBackdrop-root': {
							backdropFilter: 'blur(4px)'
						},
						/*   Menu item font in dialog form  */
						'& .MuiList-root .MuiTypography-root': {
							paddingLeft: '0.6rem',
							fontSize: '0.938rem',
							lineHeight: '1.375rem',
							fontWeight: 600,
							whiteSpace: 'pre-line',
							color: theme.palette.text.primary
						},
						'& .Mui-disabled .MuiInputAdornment-root': {
							'& .MuiTypography-root': {
								color: theme.palette.text.disabled
							}
						},
						'& .MuiDialog-paperFullScreen': {
							width: '100%',
							margin: 0,
							borderRadius: 0,
							minHeight: '100%',
						},
					},
					paper: {
						background: theme.form.bgDefault,
						//backgroundColor: theme.palette.background.default,
						backdropFilter: theme.mainBackdropFilter,
						borderRadius: theme.mainBorderRadius,
						margin: '1rem',
						width: 'calc(100% - 1rem)',
					},
				}
			},
			MuiLink: {
				styleOverrides: {
					root: {
						background: theme.selectedItemBg.default,
						'WebkitBackgroundClip': 'text',
						'WebkitTextFillColor': 'transparent',
						cursor: 'pointer',
						textDecorationLine: 'none',
						'&.MuiTypography-inherit': {
							fontSize: '0.875rem',
							fontWeight: 600,
							lineHeigh: '1.25rem'
						}
					},
				}
			},
			MuiCard: {
				styleOverrides: {
					root: {
						backgroundColor: 'transparent',
						backgroundImage: 'none',
						borderRadius: theme.mainBorderRadius,
					}
				}
			},
			MuiSvgIcon: {
				styleOverrides: {
					root: {
						color: theme.palette.text.primary,
					}
				}
			},
			MuiTab: {
				defaultProps: {
					disableRipple: true,
				},
				styleOverrides: {
					root: {
						textTransform: 'none', // removes uppercase transformation  
						borderRadius: theme.mainBorderRadius,
						color: theme.palette.text.secondary,
						'&.Mui-selected': {
							color: theme.palette.text.primary,
						}
					},
				},
			},
			MuiTabs: {
				styleOverrides: {
					indicator: {
						background: theme.selectedItemBg.default,
						borderRadius: '50px',
						height: '0.3rem'
					},

				}
			},
			MuiInputAdornment: {
				styleOverrides: {
					root: {
						height: 'inherit',
						//lor: theme.palette.text.disabled,
					}
				}
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						borderRadius: theme.inputField.borderRadius,
						background: theme.palette.background.paper,
						border: theme.palette.mode === 'dark' ? 'unset' : theme.inputField.border,
						boxSizing: 'border-box',
						//removes border of input field
						'& fieldset': {
							border: 0,
						},
						'&.Mui-disabled': {
							background: theme.inputField.bgDisabled,
						},
						'&:focus': {
							borderRadius: theme.inputField.borderRadius,
							background: theme.palette.background.paper,
						},
					}
				}
			},
			//formik error text color
			MuiFormHelperText: {
				styleOverrides: {
					root: {
						background: theme.selectedItemBg.default,
						'WebkitBackgroundClip': 'text',
						'WebkitTextFillColor': 'transparent',
						alignSelf: 'flex-end',
						cursor: 'default',
						// position: 'absolute',
						top: '100%'
					}
				}
			},
			// form input fields
			MuiInputBase: {
				styleOverrides: {
					root: {
						'&.MuiOutlinedInput-root': { //default height of input fields, except multile fiels
							':not(.MuiInputBase-multiline, .vault-input)':
							{
								height: '2.6rem'
							}
						},
						// remove arrows up/down on Number input
						'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
						{
							display: 'none',
						},
						'& input[type=number]': {
							MozAppearance: 'textfield',
						},
					},
					input: {
						'&:-webkit-autofill': {
							transitionDelay: '9999s',
							transitionProperty: 'background-color, color',
							'WebkitBoxShadow': '0 0 0 1000px transparent inset !important',
							//	'webkit-text-fill-color': theme.palette.background.paper

						},

					}
				}
			},
			//places label on top of an input field
			MuiInputLabel: {
				styleOverrides: {
					root: {
						position: 'static',
						transform: 'none',
						fontSize: '0.9rem',
						fontWeight: theme.palette.mode === 'dark' ? '400' : '700',
						paddingLeft: '0.2rem',
						paddingBottom: '0.1rem',
						paddingTop: '0.5rem',
						color: theme.palette.text.primary, //theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.contrastText,
						//	opacity: theme.palette.mode === 'dark' ? 1 : 0.4
						'&.Mui-focused': {
							color: theme.palette.text.primary
						},
						'&.Mui-disabled': {
							color: theme.palette.text.primary
						},
						'&.Mui-error': {
							color: theme.palette.text.primary
						}
					}
				}
			},
			MuiTableRow: {
				styleOverrides: {
					root: {
						background: theme.backgroundBoxLevel2,
						backdropFilter: theme.mainBackdropFilter
					}
				}
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						color: theme.palette.text.secondary
					}
				}
			},
			MuiDivider: {
				styleOverrides: {
					root: {
						height: 0,
						opacity: 0.4,
						border: '0.5px solid',
						borderColor: (theme.palette.mode === 'dark' ? theme.backgroundBoxLevel1 : theme.bgSecondaryButton)
					}
				}
			},
			MuiAppBar: {
				styleOverrides: {
					root: {
						background: 'unset',
						boxShadow: 'unset'

					}
				}
			},
			MuiBadge: {
				styleOverrides: {
					root: {
					},
					badge: {
						background: theme.selectedItemBg.default

					}
				}
			},
			MuiCheckbox: {
				styleOverrides: {
					root: {
						'& .MuiSvgIcon-root': {
							color: theme.palette.text.primary,
							//stroke: theme.highlight,
							//fill: theme.highlight, 
						},
					}
				},
			},
			MuiAvatar: {
				styleOverrides: {
					root: {
						borderRadius: '12px',
						background: theme.profileAvatar.bg,
						width: '3.25rem',
						height: '3.25rem',
						color: theme.profileAvatar.color,
						fontWeight: 700,
						fontSize: '1rem',
						'& .MuiAvatar-fallback': {
							color: theme.profileAvatar.color,
						}
					}
				}
			},
			MuiAccordion: {
				styleOverrides: {
					root: {
						color: theme.palette.text.secondary,
						background: 'transparent',
						marginBottom: '1rem',
						'&.MuiAccordion-root:before': {
							backgroundColor: 'transparent',
						}
					}
				}
			},
			MuiAccordionSummary: {
				defaultProps: {
					expandIcon: <ExpandMoreIcon />
				},
				styleOverrides: {
					root: {
						paddingLeft: '24px',
						paddingTop: '10px',
						paddingBottom: '10px',
						borderRadius: theme.mainBorderRadius
					}
				}
			},
			MuiAutocomplete: {
				defaultProps: {
					popupIcon: <KeyboardArrowDownOutlinedIcon />
				},
				styleOverrides: {
					root: {
						'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
							padding: '0 !important'
						}
					}
				}
			},
			MuiSlider: {
				styleOverrides: {
					root: {
						'& .MuiSlider-rail': {
							backgroundColor: theme.palette.background.paper,
							borderRadius: '40px',
							height: '0.25rem'
						},
						'& .MuiSlider-track': {
							background: theme.selectedItemBg.default,
							border: 'none'
						},
						'& .MuiSlider-mark': {
							display: 'none',
						},
						'& .MuiSlider-markLabel': {
							color: theme.palette.text.primary,
							fontWeight: 700,
							fontSize: '0.938rem'
						},
						// last marks do not go over slider
						'& span:nth-last-of-type(2)': {
							transform: 'translateX(-100%)'
						},
						'& .MuiSlider-thumb': {
							height: '0.75rem',
							width: '0.75rem',
							background: theme.selectedItemBg.default,
							boxShadow: theme.shadowMain,
							'&:focus, &:hover, &.Mui-active': {
								boxShadow: theme.shadowMain,
								// Reset on touch devices, it doesn't add specificity
								'@media (hover: none)': {
									boxShadow: theme.shadowMain,
								},
							},
						},
					}
				},
			},
			MuiAlert: {
				defaultProps: {
					iconMapping: {
						info: <MuiInfoIcon fontSize='inherit' />,
						warning: <MuiWarningIcon fontSize='inherit' />
					}
				},
				styleOverrides: {
					root: {
						borderRadius: theme.mainBorderRadius,
						flexDirection: 'row-reverse',
						'& .MuiSvgIcon-root': {
							color: theme.palette.text.primary,
							stroke: theme.palette.text.primary,
						}
					},
					standardWarning: {
						background: theme.warningBg,
						flexDirection: 'row',
						borderRadius: theme.mainBorderRadius,
						padding: '1.25rem',
						'& .MuiAlert-icon': {
							paddingRight: '0.5rem',
							'& svg path': {
								stroke: theme.palette.text.primary
							}
						}
					},
					standardSuccess: {
						background: theme.successBg,
						flexDirection: 'row',
						borderRadius: theme.mainBorderRadius,
						padding: '1.25rem',
						'& .MuiAlert-icon': {
							paddingRight: '0.5rem',
							'& svg path': {
								stroke: theme.palette.text.primary
							}
						}
					},
					standardInfo: {
						flexDirection: 'row',
						padding: 0,
					},
				}
			},
			MuiIconButton: {
				defaultProps: {
					disableRipple: true,
				}
			},
			MuiChip: {
				styleOverrides: {
					colorPrimary: {
						background: theme.warningBg,
					},
					label: {
						background: theme.selectedItemBg.default,
						'WebkitBackgroundClip': 'text',
						'WebkitTextFillColor': 'transparent',
						fontWeight: 700,
						fontSize: '0.688rem'
					}
				},
			},
			MuiFormLabel: {
				styleOverrides: {
					root: {
						fontSize: '0.9rem',
						fontWight: 400,
						color: theme.palette.text.primary
					}
				}
			},
			MuiDataGrid: {
				defaultProps: {
					showCellVerticalBorder: false,
					rowHeight: 62,
					disableColumnMenu: true,
					components: {
						NoRowsOverlay: NoRowsOverlay,
						NoResultsOverlay: NoRowsOverlay

					}
				},
				styleOverrides: {
					root: {
						fontSize: '0.875rem',
						fontWeight: 400,
						borderColor: 'transparent',
						'& .MuiDataGrid-columnHeaders': {
							color: theme.form.textSecondary,
							fontWeight: 500
						},
						'& .MuiDataGrid-footerContainer': {
							border: 'unset'
						},
						'& .MuiDataGrid-overlay': {
							backgroundColor: 'transparent'
						},
						'& .MuiDataGrid-withBorderColor': {
							borderColor: 'rgba(255, 255, 255, 0.06)'
						},

						'&  .MuiDataGrid-row:hover': {
							borderRadius: theme.mainBorderRadius
						},
						'&  .MuiDataGrid-cell:focus': {
							outline: 'none'
						},
					}
				}

			},
		}
	} as ThemeOptions);

export default componentOverides;
