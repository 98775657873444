import { ThemeOptions } from '@mui/material/styles/createTheme';
import { createTheme } from '@mui/material/styles';

import defaultLighTheme from '../default/defaultLightTheme'; 

const newTheme: ThemeOptions = {
	...defaultLighTheme,
	selectedItemBg: {
		default: 'linear-gradient(to bottom, #0084A0 0%, #1A3E46 100%)',
	},
	iconGradient: {
		...defaultLighTheme.iconGradient,
		stopColor1: '#0084A0',
		stopColor2: '#B6DBE3'
	},
	highlight: '#0084A0',
	carousel: {
		...defaultLighTheme.carousel,
		iconShadow: '0px 1px 15px 2px rgba(122, 86, 248, 0.4)'
	},
	covers: {
		...defaultLighTheme.covers,
		productCover: {
			...defaultLighTheme.covers.productCover,
			bg: [{
				firstBg: 'linear-gradient(to bottom, #0084A0 0%, #1A3E46 100%)',
				secondBg: 'linear-gradient(to bottom, #0084A0 0%, #1A3E46 100%)',
			},
			//wallet
			{
				firstBg: 'linear-gradient(329.09deg, #0500F4 91.04%, #FAFF00 91.05%)',
				secondBg: 'linear-gradient(329.09deg, #0500F4 91.04%, #FAFF00 91.05%)',
			}]

		},
		paymentMethodCover: {
			...defaultLighTheme.covers.paymentMethodCover,
			bg: [{
				firstBg: 'linear-gradient(to bottom, #0084A0 0%, #1A3E46 100%)',
				secondBg: 'linear-gradient(to bottom, #0084A0 0%, #1A3E46 100%)',
			},
			{
				firstBg: 'linear-gradient(0deg, #7A56F8, #7A56F8)',
				secondBg: 'linear-gradient(0deg, #7A56F8, #7A56F8)',
			}],
		}
	},
	warningBg:  'linear-gradient(112.26deg, #0084A01a 10.81%, rgba(1, 132, 253, 0.1) 160.09%)',
	auth: {
		welcomeCardBg: 'linear-gradient(91.8deg, #0084A0 -11.95%, rgba(182, 219, 227, 0.7) 132.51%)',
	},
};

const paletteDark = createTheme(newTheme);

export default paletteDark;

