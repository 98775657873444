import React from 'react';
import MinimalLayout from '../Layout/MinimalLayout';
import { Grid } from '@mui/material';
import FAQ from '../Dashboard/FAQ/FAQ';
import BackButtonWebLanding from './components/BackButtonWebLanding';

interface Props {
	paddingTop?: number,
	goBack: () => void
}

const OnboardingSupport = ({ paddingTop, goBack }: Props): React.ReactElement => {


	return (
		<MinimalLayout
			paddingTop={paddingTop}
			allowGoHome={false}
			leftPanel={<BackButtonWebLanding onClick={goBack} />}>
			<Grid container >
				<Grid item xs={12}>
					<FAQ />
				</Grid>
			</Grid>

		</MinimalLayout >);
};

export default OnboardingSupport;
