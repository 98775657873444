import { AccountIntegration, BankAccount } from '@features/account/types';
import { Contact } from '@features/contacts/types';
import * as React from 'react';
import { useState } from 'react';
import { SystemType } from '@helpers/globalTypes';
import NewPaymentConfirmation from './NewPaymentConfirmation';
import PaymentDetails from './PaymentDetails';
import { defaultPaymentPayload, PaymentPayloadType } from '@/componentsMui/Type/PaymentPayloadType';

export interface Props {
	step: number,
	paymentType: SystemType,
	accountList: BankAccount[],
	paymentName: string,
	defaultAccount?: BankAccount,
	defaultContact?: Contact,
	integration?: AccountIntegration,
	nextStep?: () => void,
	prevStep: () => void,
}


const NewPaymentSteps = ({ step,
	paymentType,
	defaultAccount,
	accountList,
	defaultContact, integration, paymentName, nextStep, prevStep }: Props) => {

	const [paymentPayload, setPaymentPayload] = useState<PaymentPayloadType>(defaultPaymentPayload);
	const [stepLocal, setStepLocal] = useState<number>(step);

	const nextStepLocal = () => {
		setStepLocal(previousState => (previousState + 1));
		nextStep && nextStep();
	};
	const prevStepLocal = () => {
		setStepLocal(previousState => (previousState - 1));
		prevStep && prevStep();
	};

	return (< >
		{stepLocal === 1 &&
            <PaymentDetails
                paymentName={paymentName}
                paymentType={paymentType}
                accountList={accountList} 
                defaultAccount={defaultAccount}
                defaultContact={defaultContact}
                paymentPayload={paymentPayload}
                setPaymentPayload={setPaymentPayload}
                nextStep={nextStepLocal}
                prevStepMain={prevStep} />
		}
		{stepLocal === 2 && <NewPaymentConfirmation
			paymentPayload={paymentPayload}
            paymentName={paymentName}
			integration={integration}
			prevStep={prevStepLocal} />
		}
	</>);
};

export default NewPaymentSteps;
