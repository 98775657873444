import { ThemeOptions } from '@mui/material/styles/createTheme';
import { createTheme } from '@mui/material/styles';

import defaultLighTheme from '../default/defaultLightTheme'; 

const newTheme: ThemeOptions = {
	...defaultLighTheme,
	selectedItemBg: {
		default: 'linear-gradient(to bottom, #32F1DD 0%, #221846 100%)',
	},
	iconGradient: {
		...defaultLighTheme.iconGradient,
		stopColor1: '#32F1DD',
		stopColor2: '#D9CEFD'
	},
	highlight: '#32F1DD',
	carousel: {
		...defaultLighTheme.carousel,
		iconShadow: '0px 1px 15px 2px rgba(122, 86, 248, 0.4)'
	},
	covers: {
		...defaultLighTheme.covers,
		productCover: {
			...defaultLighTheme.covers.productCover,
			bg: [{
				firstBg: 'linear-gradient(180deg, #32F1DD -0.72%, rgba(122, 86, 248, 0) 100%)',
				secondBg: 'linear-gradient(0deg, #32F1DD, #32F1DD)',
			},
			//wallet
			{
				firstBg: 'linear-gradient(329.09deg, #0500F4 91.04%, #FAFF00 91.05%)',
				secondBg: 'linear-gradient(329.09deg, #0500F4 91.04%, #FAFF00 91.05%)',
			}]

		},
		paymentMethodCover: {
			...defaultLighTheme.covers.paymentMethodCover,
			bg: [{
				firstBg: 'linear-gradient(180deg, #32F1DD -0.72%, rgba(122, 86, 248, 0) 100%)',
				secondBg: 'linear-gradient(180deg, #32F1DD -0.72%, rgba(122, 86, 248, 0) 100%)',
			},
			{
				firstBg: 'linear-gradient(0deg, #32F1DD, #32F1DD)',
				secondBg: 'linear-gradient(0deg, #32F1DD, #32F1DD)',
			}],
		}
	},
	warningBg:  'linear-gradient(112.26deg, #32F1DD1a 10.81%, rgba(1, 132, 253, 0.1) 160.09%)',
	auth: {
		welcomeCardBg: 'linear-gradient(2.88deg, #32F1DD -10.83%, #D9CEFD 106.36%)',
	},
};

const paletteDark = createTheme(newTheme);

export default paletteDark;

