import * as React from 'react';
import { Grid, Button, useTheme, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Card, CardIntegrationEnum, CardStatuses, CardSubProcesses } from '@/features/card/types';
import NumberFormat from 'react-number-format';
import { getCurrencySign } from '@/features/fees/slice';
import CardDetailsForm from './CardDetailsForm';
import { useState } from 'react';
import { findProcessByType, tranformSubProcessByType } from '@/features/operations/slice';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { BankAccount, UserType } from '@/features/user/types';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { hiddenBabalance } from '@/helpers/placeholders';
import CardImage from './CardImage';
import MuiTextLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextLabel';
import MuiTextAccountBoxMoney from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAccountBoxMoney';
import BigAmount from '@/componentsMui/Wallet/Components/BigAmount';
import { isIntegrationDown } from '@/features/maintenance/maintenanceSlice';
import MaintenanceChip from '@/componentsMui/Shared/Widgets/MaintenanceChip';
import { activateCard, isReplaceable } from '../../utils';
import { formatExpirationDate } from '@/helpers/date';

interface Props {
    card: Card,
    onClick?: (account: BankAccount) => void
}

const CardLine = ({ card, onClick }: Props) => {
    const { t } = useTranslation('translations');
    const theme = useTheme() as Theme;
    const history = useHistory();
    const [openCardDetails, setOpenCardDetails] = useState<boolean>(false);
    const cardProcesses = tranformSubProcessByType(card.process.subProcesses);
    const maintenanceList = useSelector((state: RootState) => state.maintenance.list);

    const integrationIsDown = isIntegrationDown(maintenanceList, card?.process?.integration);
    const { user } = useSelector((state: RootState) => state.user);


    const topupProcess = findProcessByType(card?.process, CardSubProcesses.CARD_TOP_UP);
    const activateProcess = findProcessByType(card?.process, CardSubProcesses.CARD_ACTIVATE);
    const topUpAllowed = !isEmpty(cardProcesses[CardSubProcesses.CARD_TOP_UP]) && !topupProcess?.maintenance;
    const activateAllowed = !isEmpty(cardProcesses[CardSubProcesses.CARD_ACTIVATE]) && !activateProcess?.maintenance;
    const isReplaceEnabled = isReplaceable(card);

    const isShCard = card.process.integration === CardIntegrationEnum.SH_FINANCE;

    const handleTopup = (event) => {
        event.stopPropagation();
        history.push(`/wallet/home/cards/transfers/top-up?card=${card.cardId}`);
    };

    const handleSeeDetails = () => {
        setOpenCardDetails(true);
    };

    const handleActivate = async (event) => {
        event.stopPropagation();
        const needVfCode = (card.process.integration === 'SH_FINANCE' && card.status === 'INACTIVE');
        await activateCard(card?.cardId, activateProcess?.proc, card?.virtual, history, needVfCode, card.status);
    };


    const handleReplace = (event) => {
        event.stopPropagation();
        history.push(`/wallet/home/cards/replace?card=${card.cardId}`);
    };

    const renderSubtitle = () => {
        if (
            [
                CardStatuses.PENDING,
                CardStatuses.ORDERED,
                CardStatuses.REQUESTED,
                //
                CardStatuses.ACTIVATING,
                CardStatuses.INACTIVE,
                //
                CardStatuses.FROZEN,
                //
                CardStatuses.SUSPENDED,
                //
                CardStatuses.EXPIRED,
            ].includes(card.status)
        ) {
            return t('cards.status.' + card.status);
        } else {
            const numberMask = card.cardNumberMask ? `•• ${card.cardNumberMask?.slice(-4)}` : '••••';
            return `${numberMask}, ${formatExpirationDate(card.expirationDate)}`;
        }
    };

    return (
        <Grid
            container
            alignItems='center'
            p='15px 20px'
            borderRadius={theme.mainBorderRadius}
            bgcolor={theme.bgSecondaryButton}
            sx={{ cursor: 'pointer' }}
            mb={1}
            onClick={() => !integrationIsDown && onClick && onClick(card)}
        >
            <CardDetailsForm
                open={openCardDetails}
                onClose={() => { setOpenCardDetails(false); }}
                card={card}
            />
            <Grid item xs={4} sm={2} md={1}>
                <CardImage card={card} hideText={true} borderRadius='5px' />
            </Grid>
            <Grid item xs={8} sm={4} md={3} sx={{ paddingLeft: '2rem' }}>
                {!isShCard ? <>
                    {!user.hideValues ? <>
                        <BigAmount currency={card.currencySymbol ?? getCurrencySign(card.currency)} value={card.availableBalance} fontSize='1.125rem' />
                        <MuiTextLabel fontWeight='400' fontSize='0.875rem'>
                            {t('cards.balanceOnHold')}
                            <NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${getCurrencySign(card.currency)} `} value={Math.abs(card.balanceOnHold)} />
                        </MuiTextLabel>
                    </> :
                        <>
                            <MuiTextAccountBoxMoney variant={'inherit'} fontSize='1.125rem'>{card.currency} {hiddenBabalance}</MuiTextAccountBoxMoney>
                            <MuiTextLabel fontSize='0.875rem' fontWeight='400'>{t('cards.balanceOnHold')} {card.currency} {hiddenBabalance}</MuiTextLabel>
                        </ >
                    }
                </> : <>
                        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'none', md: 'none' } }}>
                            <MuiTextAccountBoxMoney fontSize='1.125rem'>{card.virtual ? t('cards.businessCards.details.virtual') : t('cards.businessCards.details.physical')}</MuiTextAccountBoxMoney>
                            <MuiTextLabel fontSize='0.875rem' fontWeight='400'>{renderSubtitle()}</MuiTextLabel>
                        </Grid>
                    </>}
            </Grid>
            <Grid item xs={12} sm={3} md={2} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                <MuiTextAccountBoxMoney fontSize='1.125rem'>{card.virtual ? t('cards.businessCards.details.virtual') : t('cards.businessCards.details.physical')}</MuiTextAccountBoxMoney>
                <MuiTextLabel fontSize='0.875rem' fontWeight='400'>{renderSubtitle()}</MuiTextLabel>
            </Grid>
            <Grid container item xs={12} sm={6} md={6} sx={{ display: { xs: 'none', sm: 'flex' } }} flexDirection='row' justifyContent='flex-end' spacing={1} >
                {topUpAllowed && !isShCard && <Grid item>
                    <Button
                        variant='simple'
                        onClick={handleTopup}
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                    // startIcon={<AddIcon />}
                    >
                        {t('cards.businessCards.details.topUp')}
                    </Button>
                </Grid>}
                {isReplaceEnabled && <Grid item>
                    <Button
                        variant='simple'
                        onClick={handleReplace}
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                    >
                        {t('cards.replace.replace')}
                    </Button>
                </Grid>}
                {activateAllowed && <Grid item>
                    <Button
                        variant='contained'
                        onClick={handleActivate}
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                    >
                        {t('cards.businessCards.details.activate')}
                    </Button>
                </Grid>}
                {!integrationIsDown && user.type !== UserType.EMPLOYEE &&
                    <Grid item>
                        <Button
                            variant='simple'
                            onClick={handleSeeDetails}
                            disabled={!topUpAllowed}
                            sx={{ width: { xs: '100%', md: 'auto' } }}
                        >
                            {t('cards.businessCards.list.seeDetails')}
                        </Button>
                    </Grid>
                }
                {integrationIsDown &&
                    <Grid item >
                        <MaintenanceChip />
                    </Grid>
                }
            </Grid>
        </Grid >
    );
};

export default CardLine;
