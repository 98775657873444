import React, { useEffect, useState } from 'react';
import {
	Avatar,
	ListItemIcon,
	MenuItem,
} from '@mui/material';

import FlagUs from '@/assets/FlagUs.svg';
import FlagIt from '@/assets/FlagIt.svg';
import FlagFr from '@/assets/FlagFr.svg';
import './LanguageDropdown.css';
import StyledSelect from '@/componentsMui/Shared/Widgets/StyledSelect';
import { updateUserLanguage } from '@/features/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '@/rootReducer';

interface Props {
	authorized?: boolean,
}

const LanguageDropdown = ({authorized = true}: Props): React.ReactElement => {

	const { i18n } = useTranslation('translations');
	const [codes, setCodes] = useState<string[]>([]);
	const { languageCodes } = useSelector((state: RootState) => state.settings);

	const dispatch = useDispatch();
	const supportedLanguages = ['en', 'it', 'fr'];

	useEffect(() => {
		setCodes(languageCodes?.length > 0 ? languageCodes.filter(l => supportedLanguages.includes(l)) : ['en']);
	// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [languageCodes]);


	const getFlagName = (code: string): React.ReactElement => {
		switch (code) {
			case 'en':
				return <img
					src={FlagUs}
					style={{ height: 24, width: 24 }}
				/>;
			case 'it':
				return <img
					src={FlagIt}
					style={{ height: 24, width: 24 }}
				/>;
			case 'fr':
				return <img
					src={FlagFr}
					style={{ height: 24, width: 24 }}
				/>;
			default:
				return <Avatar
					sx={{ width: 24, height: 24, fontSize: 12 }}
				>{code.toUpperCase()}</Avatar>;
		}
	};

	const changeLanguage = (event: any) => {
		const lng = event.target.value;
		i18n.changeLanguage(lng);
		if (lng && authorized) {
			dispatch(updateUserLanguage(lng));
		}
	};

	const value = codes?.length > 0 ? i18n.language : '';
	return (
		codes?.length > 1 ? 
			<StyledSelect
				id="langSelect"
				variant="standard"
				disableUnderline
				onChange={changeLanguage}
				value={value}
				renderValue={() => <ListItemIcon id='flagIcon'>
					{getFlagName(value)}
				</ListItemIcon>}
			>
				{codes?.length > 0 && codes.map(code => {
					return <MenuItem
						id='langMenuItem'
						key={code}
						value={code}>
						<ListItemIcon id='flagIcon'>
							{getFlagName(code)}
						</ListItemIcon>
						{/* <Typography variant='body2' color="text.primary" pl='0.5rem'> {_.startCase(_.camelCase(code))}</Typography> */}
					</MenuItem>;
				})}
			</StyledSelect>
		: null);
};

export default LanguageDropdown;
