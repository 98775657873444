import React from 'react';
import NewCardBackgroundImage from '@/assets/NewCardBackgroundImage.png';
import { Box, Button } from '@mui/material';
import MuiHeader from './MuiText/MuiHeader';
import MuiTextCaption from './MuiText/MuiTextCaption';


interface Props {
    onClick?: () => void,
    title?: string,
    subTitle?: string,
    buttonTitle?: string
}

const EmptyProductCard = ({ onClick, title, subTitle, buttonTitle }: Props) => {
    return (
        <Box style={{ backgroundImage: `url(${NewCardBackgroundImage})`, borderRadius: '20px',  backgroundSize: 'cover', padding: '63px 90px' }}>
            {title && <MuiHeader>{title}</MuiHeader>}
            {subTitle && <MuiTextCaption sx={{ marginBottom: '44px', marginTop: '13px', fontSize: '1rem', fontWeight: '400' }}>{subTitle}</MuiTextCaption>}
            {buttonTitle && <Button
                variant='customOutlined'
                onClick={onClick}
                autoFocus
            >
                {buttonTitle}
            </Button>}
        </Box>
    );
};

export default EmptyProductCard;
