import React from 'react';
import './ProductCard.css';

import { Button, Grid } from '@mui/material';
import MonoBgBox from '../Box/CoverBox/MonoBgBox';

export enum ButtonPosition { LEFT = 'product-card__lefttBtn', RIGHT = 'product-card__rightBtn', SINGLE = 'product-card__singleBtn' }

export interface ButtonDetails {
	title: JSX.Element,
	position?: ButtonPosition,
	disabled: boolean,
	icon?: JSX.Element,
	buttonHandler?: () => void,
}

interface Props {
	title: JSX.Element,
	actionButtons: ButtonDetails[],
	selected?: boolean,
	bgIndex?: number,
	onClick?: (item: any) => void,
	sizesClass?: string,
	contentSpaceAround?: boolean
}

const ProductCard = ({ title, actionButtons, selected = true, bgIndex = 0, onClick, sizesClass, contentSpaceAround }: Props): React.ReactElement => {
	return (
		<MonoBgBox
			selected={selected}
			onClick={onClick}
			bgIndex={bgIndex}
			mainClassName={sizesClass ? `product-card ${sizesClass}` : 'product-card'}
			itemClassName='product-card__wrap'
			>
			<Grid container direction='column' justifyContent={contentSpaceAround ? 'space-around' : 'space-between'} height='100%'>
				<Grid item className='product-card__title' >
					{title}
				</Grid>
				<Grid item >
				{actionButtons.length > 0 && <Grid container justifyContent="space-around" >
						{actionButtons.length > 0 &&
							actionButtons.map((p, idx) => {
								const positonClass = actionButtons.length === 1 ? ButtonPosition.SINGLE : ((idx & 1) ? ButtonPosition.RIGHT : ButtonPosition.LEFT);
								return (<Grid item key={idx} flex={1}>
									<Button
										className={p.position ?? positonClass}
										variant="transparent"
										onClick={p.buttonHandler}
										startIcon={p.icon}
										disabled={p.disabled}
										sx={(theme) => ({ bgcolor: theme.palette.mode === 'dark' ? theme.backgroundBoxLevel1 : 'rgba(255, 255, 255, 0.6)' })}
									>
										{p.title}
									</Button>
								</Grid>);
							})
						}
					</Grid>}
				</Grid>
			</Grid>
		</MonoBgBox>
	);
};

export default ProductCard;
