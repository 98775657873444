import * as React from 'react';
import { SvgIcon } from '@mui/material';

const MainAccountIcon = (props) => (
    <SvgIcon {...props} >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="current" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.8 10.778H3.2m17.6 0c1.215 0 2.2 1.094 2.2 2.444v4.89c0 2.7-1.97 4.888-4.4 4.888H5.4C2.97 23 1 20.811 1 18.111v-4.889c0-1.35.985-2.444 2.2-2.444m17.6 0V8.333c0-1.35-.985-2.444-2.2-2.444M3.2 10.778V8.333c0-1.35.985-2.444 2.2-2.444m13.2 0H5.4m13.2 0V3.444C18.6 2.094 17.615 1 16.4 1H7.6C6.385 1 5.4 2.094 5.4 3.444V5.89m4.4 8.555h4.4"
                stroke="current"
                strokeWidth={1.5}
                strokeLinecap="round"
            />
        </svg>
    </SvgIcon>
);

export default MainAccountIcon;
