export interface Disclaimer {
    accepted: boolean, 
    disclaimerCode: string,
}

 

export interface DisclaimerStore { 
    loading: boolean,
    list: Array<Disclaimer> 
}


export enum UiSectionEnum  {
	EARN = 'EARN'
}
