import React, { useCallback, useEffect, useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useField } from 'formik';

type Props = {
	hideError?: boolean, name: string,
	minFontSizeInPx?: number,
	maxFontSizeInPx?: number,
} & TextFieldProps;

const CHANGE_STEP = 4;

const MuiTextFieldResize = ({
	name,
	hideError,
	minFontSizeInPx,
	maxFontSizeInPx,

	...otherProps
}: Props) => {


	const minSize = minFontSizeInPx ? minFontSizeInPx : 10;
	const maxSize = maxFontSizeInPx ? maxFontSizeInPx : 26;


	const ref = React.useRef<HTMLDivElement>(null);
	const [field, meta] = useField(name);
	const { value } = meta;
	const [prevValue, setPrevValue] = useState(null);

	const configTextfield = {
		...field,
		...otherProps,
	};


	const element = ref.current;
	const isOverflown = (element?.scrollWidth && element?.clientWidth) ?
		element?.scrollWidth > element?.clientWidth : false; 

	const fieldValue = value ?? otherProps?.value;

	const adjustFontSize = useCallback((isOverflown) => {
		const size = window
			.getComputedStyle(ref.current, null)
			.getPropertyValue('font-size');
		const sizeValue = parseFloat(size.replace('px', ''));

		if (isOverflown) {
			const newSize = sizeValue - CHANGE_STEP;
			if (newSize >= minSize) {
				//console.log('decrease', `${newSize}px`);
				ref.current.style.fontSize = `${newSize}px`;
			}
		}


		else {
			if (!fieldValue && sizeValue !== maxSize) {
				//console.log('restore max size', `${maxSize}px`);
				ref.current.style.fontSize = `${maxSize}px`;

			}
			else if (fieldValue && prevValue !== fieldValue) {
				const newSize = sizeValue + CHANGE_STEP;
				if (newSize <= maxSize) {
					//console.log('increase', `${newSize}px`);
					ref.current.style.fontSize = `${newSize}px`;
				}
			}

		}

		setPrevValue(fieldValue);

	}, [minSize, maxSize, fieldValue, prevValue]);


	useEffect(() => {
		adjustFontSize(isOverflown);
	}, [adjustFontSize, isOverflown]);


	if (meta && meta.touched && meta.error) {
		configTextfield.error = true;
		configTextfield.helperText = meta.error;
	}

	if (hideError) {
		configTextfield.error = false;
		configTextfield.helperText = null;
	}


	return (
		<TextField inputRef={ref}  {...configTextfield} />
	);
};

export default MuiTextFieldResize;
