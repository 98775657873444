import React from 'react';
 
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Transaction } from '../TransactionStatements.spec';
import DetailLine from '@shared/Widgets/DetailLine';


interface ChargeBackProps {
	details: Transaction,
}

const ChargeBack = ({ details }: ChargeBackProps) => {
	const { t } = useTranslation('translations');

	return (
		<Grid container>
			<DetailLine
				label={t('transactiondetails.main.status')}
				value={t('status.' + details.status)}
			/>

			{
				details?.cardNumberMask ? (
					<DetailLine
						label={t('transactiondetails.beneficiary.toCard')}
						value={`${details?.cardNumberMask}`}
					/>
				) : (
					details?.beneficiaryAccount &&
					<DetailLine
                        label={t('transactiondetails.beneficiary.toAccount')}
						value={`${details.beneficiaryAccount}`}
					/>
				)
			}

			<DetailLine
				label={t('transactiondetails.remitter.name')}
				value={details.remitterName}
			/>

			<DetailLine
				label={t('transactiondetails.remitter.account')}
				value={details.remitterAccount}
			/>

			{details.details && <DetailLine
                label={t('transactiondetails.details.reference')}
				value={details.details}
			/>}
		</Grid>
	);
};

export default ChargeBack;
