import * as React from 'react';
import { useTheme } from '@mui/styles';
import { Box as MuiBox, BoxProps, Theme } from '@mui/material';
import { FC } from 'react';

/* Placed on base background*/
const BaseBox: FC<BoxProps> = (props: BoxProps) => {

	const theme = useTheme() as Theme;

	const { sx, ...other } = props;

	return (<MuiBox id='base-box'
		sx={{
			color: theme.palette.text.secondary,
			borderRadius: theme.mainBorderRadius,
			background: theme.palette.mode === 'dark' ? theme.backgroundBoxLevel1 : theme.palette.background.paper,
			...sx,
		}}
		{...other}>{props.children}
	</MuiBox>);
};


export default BaseBox;
