import React from 'react';
import { GridPagination } from '@mui/x-data-grid';
 
const GridPaginationArrows = (props: any) => {
    return <GridPagination  {...props}
        sx={
            {
                '& .MuiTablePagination-displayedRows': {
                    display: 'none'
                },
            }}
    />;
};

export default GridPaginationArrows;
