import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, Theme, Typography, useTheme } from '@mui/material';
import { FeeSection, ProductFeeType } from './MyFees';
import FeeCard from './FeeCard';
import StyledSelect from '@/componentsMui/Shared/Widgets/StyledSelect';
import { isEmpty } from 'lodash';
import FiatAvatar from '@/componentsMui/Shared/Widgets/Avatars/FiatAvatar';
import { Fiats } from '@/componentsMui/Type/Fiat';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { RootProcessType } from '@/helpers/globalTypes';
import TokenAvatarStacked from '@/componentsMui/Shared/Widgets/Avatars/TokenAvatarStacked';
import TokenAvatar, { TokenAvatarSizeEnum } from '@/componentsMui/Shared/Widgets/Avatars/TokenAvatar';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import Limits from './Limits';
import LimitsCards from './LimitsCards';

export interface Props {
	title: string;
	feeSection: FeeSection;
	activeFees: ProductFeeType[];
	showOneProduct?: string,
	getLimits: (ccy: string) => void;
}

const FeeView = ({ title, feeSection, activeFees, showOneProduct, getLimits }: Props): React.ReactElement => {
	const { list: userLimitsList } = useSelector((state: RootState) => state.userLimits);

	const [value, setValue] = useState<string>(showOneProduct || activeFees[0]?.id);

	const theme = useTheme() as Theme;

	const product = activeFees.find(p => p.id === value);

	useEffect(() => {
		if (product) {
			getLimits(product.currency);
		}
	}, [getLimits, product]);

	const changeProduct = (event: any) => {
		setValue(event.target.value);
	};

	if (isEmpty(activeFees)) return <></>;


	return (
		<Grid container
			borderRadius={theme.mainBorderRadius}
			bgcolor={theme.backgroundBoxLevel1} >
			{!showOneProduct &&
				<Grid item container xs={12}
					px='20px'
					alignItems='center'
					height='4rem'>
					<Grid item xs={4}  >
						{title}
					</Grid>
					<Grid item xs={8} textAlign='right' container alignItems='center' justifyContent='flex-end'>
						<StyledSelect
							value={value}
							disabled={!!showOneProduct}
							sx={{
								maxWidth: '100%', bgcolor: theme.backgroundBoxLevel1,

							}}
							MenuProps={{
								sx: { '& .MuiMenu-paper': { backgroundColor: theme.bgSecondaryButton, borderRadius: '16px' } }
							}}
							onChange={changeProduct} >
							{activeFees.map((item) => {
								return (
									<MenuItem
										key={item.id}
										value={item.id}
									>
										<Grid container alignItems='center' >

											{item.rootProcess === RootProcessType.CREATE_ACCOUNT &&
												<>
													<Grid item>
														<FiatAvatar showOnlyAvatar={true}
															fiat={Fiats.find(f => f.currency === item?.currency)}
															variant='small' />
													</Grid>
													<Grid item>
														<MuiFormText pl={1} fontWeight={700} fontSize='0.875rem' lineHeight='0.875rem'>{item?.currency} </MuiFormText>
													</Grid>
												</>
											}
											{item.rootProcess === RootProcessType.CREATE_CARD &&
												<Grid item container alignItems='center'>
													<Grid item maxWidth='40px'>
														<TokenAvatar symbol={item.name} logoSource={item?.logo} variant={TokenAvatarSizeEnum.small} additionalClassName='card' />
													</Grid>
													<Grid item maxWidth='calc(100% - 40px)'>
														<Typography fontSize='0.813rem' fontWeight='700' noWrap pl={1}
															sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }} >
															{item.name}
														</Typography>
													</Grid>
												</Grid>
											}
											{item.rootProcess === RootProcessType.CREATE_CRYPTO_ACCOUNT &&
												<TokenAvatarStacked
													symbol={item.name}
													logo={item?.logo}
													variant={TokenAvatarSizeEnum.small} />
											}
										</Grid>
									</MenuItem>);
							})
							}
						</StyledSelect>
					</Grid>
				</Grid>
			}
			{product &&
				<Grid item xs={12} bgcolor='#1D2024' px='20px' pb={1}
					sx={{
						borderBottomLeftRadius: !showOneProduct && theme.mainBorderRadius,
						borderBottomRightRadius: !showOneProduct && theme.mainBorderRadius,
						borderRadius: showOneProduct && theme.mainBorderRadius,
					}}>
					<FeeCard
						feeSection={feeSection}
						productFees={product}
						currency={product.currency}
					/>
					{feeSection === FeeSection.CARD ?
						<LimitsCards limit={product?.limits && product?.limits[0]} currency={product.currency} /> :
						<Limits userLimits={userLimitsList} currency={product.currency} />
					}
				</Grid>
			}
		</Grid>
	);
};

export default FeeView;
