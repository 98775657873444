import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MyAccountsSummary from './Components/MyAccountsSummary';
import AccountChart from './Components/AccountChart';
import { getOperations } from '@/features/operations/slice';
import StatusCard from './Components/StatusCard';
import MaintenanceCard from '@/componentsMui/Shared/Widgets/MaintenanceCard';
import { RootState } from '@/rootReducer';
import { getMaintenance } from '@/features/maintenance/maintenanceSlice';
import ScammerCard from '@/componentsMui/Shared/Widgets/ScammerCard';
import { Agents } from '@/helpers/globalTypes';

const HomeSummary = (): React.ReactElement => {

	const { isPartiallyDown, isPlanned } = useSelector((state: RootState) => state.maintenance);
	const { agent } = useSelector(
		(state: RootState) => state.status
	);

	const showMaintenanceCard = isPartiallyDown || isPlanned;
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getOperations());
		dispatch(getMaintenance());
	}, [dispatch, agent]);


	return (
		<Grid container p={{ sx: '0px 23px', md: '0px 0px' }} rowGap='25px'>
			{showMaintenanceCard &&
				< Grid item xs={12}   >
					<MaintenanceCard />
				</Grid>
			}
			{!showMaintenanceCard && agent === Agents.DTS &&
				< Grid item xs={12}   >
					<ScammerCard />
				</Grid>
			}
			<Grid item xs={12}   >
				<StatusCard />
			</Grid>
			<Grid item xs={12}   >
				<AccountChart />
			</Grid>
			<Grid item xs={12} >
				<MyAccountsSummary />
			</Grid>
		</Grid >);
};

export default HomeSummary;
