
import * as React from 'react';
import { BankAccount } from '@features/account/types';
import { useState } from 'react';
import BetweenAccountsDetails from './BetweenAccountsDetails';
import { AccountTransferPayloadType, initAccountTransferPayload } from '@/componentsMui/Type/BetweenAccountsPayloadType';
import BetweenAccountsConfirmation from './BetweenAccountsConfirmation';

export interface Props {
	step: number,
	fromAccountList?: BankAccount[],
	toAccountList?: BankAccount[],
	fromDefaultAccount?: BankAccount,
	toDefaultAccount?: BankAccount,
	noBackground?: boolean,
	title?: string,
	nextStep?: () => void,
	prevStep?: () => void,
	onBack?: () => void
}

const BetweenAccountsSteps = ({ step,
	fromAccountList,
	toAccountList,
	fromDefaultAccount,
	toDefaultAccount,
	noBackground,
	title,
	nextStep,
	prevStep,
}: Props) => {

	const [accountTransferPayload, setAccountTransferPayload] = useState<AccountTransferPayloadType>(initAccountTransferPayload);
	const [stepLocal, setStepLocal] = React.useState<number>(step);
	const nextStepLocal = () => {
		setStepLocal(previousState => (previousState + 1));
		nextStep && nextStep();
	};
	const prevStepLocal = () => {
		setStepLocal(previousState => (previousState - 1));
		prevStep && prevStep();
	};

	return (< >
		{stepLocal === 1 && <BetweenAccountsDetails
			fromAccountList={fromAccountList}
			toDefaultAccount={toDefaultAccount}
			fromDefaultAccount={fromDefaultAccount}
			toAccountList={toAccountList}
			accountTransferPayload={accountTransferPayload}
			noBackground={noBackground}
			title={title}
			setAccountTransferPayload={setAccountTransferPayload}
			nextStep={nextStepLocal}
			onBack={prevStep} />
		}
		{stepLocal === 2 && <BetweenAccountsConfirmation
			noBackground={noBackground}
			accountTransferPayload={accountTransferPayload}
            title={title}
			prevStep={prevStepLocal}
            onBack={() => setStepLocal(previousState => (previousState - 1))}
		/>
		}
	</>);
};

export default BetweenAccountsSteps;
