
export interface TicketNotification {
	id: number,
	title: string,
	status: string,
	createddate: Date,
	modifieddate: Date,
	incidenttype: TicketType,
	isread: boolean,
	requestmeta?: string,
	message?: string,
	translationcode?: string,
	subtype?: string
}

export interface Ticket {
	id: number,
	userId: number,
	title: string,
	status: string,
	createdDate: Date,
	incidentType: TicketType,
	isRead: boolean,
	ticketMessages: TicketMessage[]
}

export interface TickerRequestMoney extends Ticket {
	incidentType: TicketType,
	requestMeta?: string
}

export interface TicketMessage {
	message: string,
	createdDate: string,
	author: string,
	authorYou: boolean,
	ticketAttachments: TicketAttachment[]
}

export interface TicketAttachment {
	name: string,
	size: string,
	mimeType: string,
	key: string
}


export interface TicketResponse {
	status: string,
	ticketId: number
}

export enum TicketType {
	TRANSFER_REQUEST = 'TRANSFER_REQUEST',
	NOTIFICATION = 'NOTIFICATION',
	INCIDENT = 'INCIDENT',
	STOCK_ORDER = 'STOCK_ORDER',
	ACCOUNT_SUSPENDED = 'ACCOUNT_SUSPENDED',
	CARD_FEE_DEDUCTION = 'CARD_FEE_DEDUCTION',
	CARD_SUSPENDED = 'CARD_SUSPENDED',
	KYC_REJECTED = 'KYC_REJECTED',
	KYC_APPROVED = 'KYC_APPROVED',
	KYB_FINAL_REJECTED = 'KYB_FINAL_REJECTED',
	KYB_APPROVED = 'KYB_APPROVED',
	KYB_REQUIRED_ACTION = 'KYB_REQUIRED_ACTION',
	KYB_WAITING_PAYMENT = 'KYB_WAITING_PAYMENT',
	NEW_PENDING_EMPLOYEE = 'NEW_PENDING_EMPLOYEE',
	VAULT_PLAN_CHANGE_APPROVED = 'VAULT_PLAN_CHANGE_APPROVED',
	VAULT_PLAN_CHANGE_REJECTED = 'VAULT_PLAN_CHANGE_REJECTED',
}

export enum TicketMarkType {
	DELETE = 'DELETE',
	READ = 'READ'
}

export interface PaginateParams {
	orderBy: string,
	sort: 'DESC' | 'ASC',
	skip: number,
	filter: string,
	take?: number
}

export interface PaymentRequest {
	toUserId: string,
	reference: string,
	requestMeta: string
}

export interface StockOrder {
	subject: string,
	description: string,
}

export interface TicketRequest {
	subject: string,
	description: string,
	subType: string,
	files?: any
}

export interface ReplyRequest {
	ticketId: string,
	message: string,
	files?: any
}

export interface TicketsStore {
	loading: boolean,
	list: Array<TicketNotification>,
	ticket: Ticket,
	error: string,
	count: number,
	totalPage: number,
	pageSize: number,
	unreadCount: number,
	updateMessages: boolean,
	updateMessage: number
}
