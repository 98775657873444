import * as React from 'react';
import { FormControl, InputLabel, MenuItem, useTheme } from '@mui/material';
import { isEmpty } from 'lodash';
import StyledSelect from '@/componentsMui/Shared/Widgets/StyledSelect';
import { TokenAvatarSizeEnum } from './Avatars/TokenAvatar';
import { CoinInfoType } from '@/features/coins/types';
import CoinAvatar from './Avatars/CoinAvatar';

interface Props {
	coinList: Array<CoinInfoType>,
	selectedCoin?: CoinInfoType,
	fieldlabel?: string,
	onCoinChange: (value: CoinInfoType) => void,
	variant?: 'standard' | 'outlined' | 'filled',
}

const CoinSelect = ({ coinList, selectedCoin, fieldlabel, onCoinChange, variant = 'standard' }: Props) => {

	const theme = useTheme();


	const handleChange = (event: any) => {
		const newSymbol = event.target.value;
		onCoinChange(coinList.find(p => p.symbol === newSymbol));
	};


	const textFieldValue = isEmpty(selectedCoin) ?
		<></> :
		<CoinAvatar coin={selectedCoin} variant={TokenAvatarSizeEnum.small} />;

	return (
		<FormControl fullWidth >
			{fieldlabel && <InputLabel> {fieldlabel}</InputLabel>}
			<StyledSelect
				value={selectedCoin?.symbol ?? ''}
				onChange={handleChange}
				variant={variant}
				disableUnderline
				MenuProps={{
					PaperProps: { sx: { background: theme.palette.mode === 'dark' ? theme.bgSecondaryButton : theme.palette.background.paper, borderRadius: '16px', } }
				}}
				renderValue={() => <> {textFieldValue}</>}
			>
				{coinList?.length > 0 &&
					coinList.map((item) => (
						<MenuItem
							style={{ opacity: 1 }}
							key={item.symbol}
							value={item.symbol}>
							<CoinAvatar coin={item} variant={TokenAvatarSizeEnum.medium} />
						</MenuItem>
					))}
			</StyledSelect>
		</FormControl >
	);
};

export default CoinSelect;
