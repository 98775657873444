import { BankAccount } from '@/features/account/types';

export interface AccountTransferPayloadType {
	fromAccount: BankAccount,
	toAccount: BankAccount,
	amount: number,
	processProcedure: string,
	fee: number,
	youpay: number,
}

export const initAccountTransferPayload: AccountTransferPayloadType =
{
	fromAccount: null,
	toAccount: null,
	amount: null,
	processProcedure: null,
	fee: null,
	youpay: 0
};
