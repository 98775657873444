
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './rootReducer';
import * as countries from 'i18n-iso-countries';
import DashboardMui from './pages/DashboardMui';
import Auth from './pages/Auth';
import NotFound from './pages/NotFound';
import Interceptors from './componentsMui/Interceptors';
import ProtectedRoute from './ProtectedRoute';
import { getStatus } from '@features/status/statusSlice';
import { useTranslation } from 'react-i18next';
import './App.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { BrowserRouter as Router, Redirect, Route, Switch, } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ThemeContext, ThemeModes } from './componentsMui/Shared/Widgets/ThemeToggle/themContext';
import { CssBaseline } from '@mui/material';
import TwoFAMobile from './componentsMui/TwoFAMobile';
import MuiLegal from './componentsMui/Legal/Legal';
import { darkTheme, lightTheme } from './componentsMui/Themes/brandThemeSelector';
import ConfirmCodes from './componentsMui/ConfirmCodes';
import TwoFAWeb from './componentsMui/TwoFAWeb';
import LoadingPageMui from './pages/LoadingPageMui';
import Swal from './componentsMui/Swal';
import PageTop from './componentsMui/Shared/Components/PageTop';
import { ConfirmProvider } from 'material-ui-confirm';
import { loadChallangeRecapture } from './helpers/recaptureChallengeUtils';
import { loadRecaptureScript } from './helpers/recaptureUtils';
import Onboarding from './componentsMui/Onboarding/Onboarding';
import { getLanguages } from './features/settings/slice';
import MaintenanceMode from './componentsMui/Shared/Widgets/MaintenanceMode';
import { getMaintenance } from './features/maintenance/maintenanceSlice';
import CardMobileRedirect from './pages/CardMobileRedirect';
import Authorize from './componentsMui/Oauth2/Authorize';
import AnonymousSupport from './componentsMui/AnonymousSupport/AnonymousSupport';


const App = (): React.ReactElement => {

	const { ready } = useTranslation('translations', { useSuspense: false });
	const dispatch = useDispatch();
	const { loading } = useSelector((state: RootState) => state.status);
	const { isFullyDown } = useSelector((state: RootState) => state.maintenance);
	const { agent } = useSelector(
		(state: RootState) => state.status
	);

	useEffect(() => {
		dispatch(getStatus());
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
	}, [dispatch]);

	useEffect(() => {
		loadRecaptureScript(() => {
			//console.log('Invisialbe reacture Script loaded!');
		});
		loadChallangeRecapture(() => {
			//console.log('Visiable reacture Script loaded!');
		});
	}, []);

	useEffect(() => {
		dispatch(getMaintenance());
	}, [dispatch, agent]);

	/* 	useEffect(() => {
			if (agent === 'DTS') {
				dispatch(setShowWalletDisclaimer(true));
			}
		}, [dispatch, agent]);
	 */
	const getLanguageCodes = useCallback(() => {
		const get = async () => {
			dispatch(getLanguages());
		};
		get();
	}, [dispatch]);

	useEffect(() => {
		getLanguageCodes();
	}, [getLanguageCodes]);

	const [mode, setMode] = React.useState<ThemeModes>('dark');

	const theme = React.useMemo(
		() =>
			createTheme(mode === 'light' ? lightTheme : darkTheme),
		[mode],
	);

	if (loading || !ready) return <LoadingPageMui />;

	const renderContent = () => {
		if (isFullyDown) return (<MaintenanceMode />);

		return (<Switch>
			<Redirect from='/reset-password/:code' to='/auth/reset-password/:code' />
			<Redirect from='/register/freelancer' to='/auth/register/freelancer' />
			<Route path='/oauth2/authorize'>
				<Authorize />
			</Route>
			<Route path='/support/:key'>
				<AnonymousSupport />
			</Route>
			<Route path='/auth'>
				<Auth />
			</Route>
			<Route path='/maintenance'>
				<MaintenanceMode />
			</Route>
			<Route path='/legal'>
				<MuiLegal />
			</Route>
			<Route path='/mobile/accounts/addmoneycard/success'>
				<CardMobileRedirect />
			</Route>
			<Route path='/mobile/accounts/addmoneycard/error'>
				<CardMobileRedirect />
			</Route>
			<Route path='/mobile/accounts/addmoneycard/cancel'>
				<CardMobileRedirect />
			</Route>
			<ProtectedRoute path='/onboarding' component={Onboarding} />
			<ProtectedRoute path='/wallet' component={DashboardMui} />
			<Redirect exact from='/' to='/wallet/home' />
			<Route component={NotFound} />
		</Switch>);
	};

	return (
		<ThemeContext.Provider value={{ mode, setMode }}>
			<ThemeProvider theme={theme}>
				<ConfirmProvider>
					<CssBaseline />
					<Router>
						<Interceptors />
						<Swal />
						<TwoFAMobile />
						<TwoFAWeb />
						<ConfirmCodes />
						<PageTop>
							{renderContent()}
						</PageTop>

					</Router>
					<svg aria-hidden='true' focusable='false' style={{ width: 0, height: 0, position: 'absolute' }}>
						<linearGradient id='svgIconGradient' x1='10.35%' y1='25.3%' x2='110.35%' y2='74.7%'>
							<stop offset='.108' stopColor={theme.iconGradient.stopColor1} />
							<stop offset='1.601' stopColor={theme.iconGradient.stopColor2} />
						</linearGradient>
					</svg>
				</ConfirmProvider>
			</ThemeProvider>
		</ThemeContext.Provider >
	);
};

export default App;

