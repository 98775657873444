import React, { useCallback, useEffect } from 'react';
import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { BankAccount } from '@/features/account/types';
import { Card } from '@features/card/types';
import ServiceLoading from '../ServiceLoading';
import { setAccount } from '@/features/tabs/slice';
import '@/componentsMui/Shared/Shared.css';
import { AccountType } from '@/features/operations/types';
import AccountView from './Account/AccountView';
import TransactionGridFull from '../TransactionsGrid/TransactionGridFull';
import BulkPayments from './BulkPayments/BulkPayments';

interface Props {
	list: Array<BankAccount | Card>,
	error?: string
}

const BankingTab = ({ list, error }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const searchParams = new URLSearchParams(location.search);
	const tab = searchParams.get('tab');
	const currentTab = tab ? tab : 'accounts' ;

	const { currentAccount } = useSelector(
		(state: RootState) => state.tabs
	);

	const getAccount = useCallback(() => {
		let newAccount = null;
		if (!currentAccount?.accountId) {
			if (list.filter(a => a.currency === 'EUR').length) {
				newAccount = list.filter(a => a.currency === 'EUR')[0];
			} else {
				newAccount = list[0];
			}
			return newAccount;
		}
		const account = list.find(account => account.accountId === currentAccount.accountId);
		if (!account && list.length > 0) {
			if (list.filter(a => a.currency === 'EUR').length) {
				newAccount = list.filter(a => a.currency === 'EUR')[0];
			} else {
				newAccount = list[0];
			}
		}
		if (!account) { return newAccount; }
		newAccount = JSON.parse(JSON.stringify(account));
		return newAccount;
	}, [currentAccount?.accountId, list]);

	useEffect(() => {
		const newAccount = getAccount();
		dispatch(setAccount(newAccount));

	}, [dispatch, getAccount]);

	const currentAccountCasted = currentAccount as BankAccount;

	const accountTypeIsValid = [AccountType.BANK.toString(), AccountType.BANK_AND_CARD.toString()].includes(currentAccountCasted?.type);
	return (
		<ServiceLoading
			type={AccountType.BANK}
			error={error}
			checkList={true}
			list={list}>
			{currentAccountCasted && currentTab === 'accounts' && <AccountView bankAccount={currentAccountCasted} accountTypeIsValid={accountTypeIsValid} />}
			{currentAccountCasted && currentTab === 'transactions' && accountTypeIsValid && <TransactionGridFull type={currentAccountCasted.type as AccountType} account={currentAccountCasted} />}
			{currentAccountCasted && currentTab === 'bulk-payments' && <BulkPayments />}
		</ServiceLoading >

	);
};

export default BankingTab;
