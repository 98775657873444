import * as React from 'react';
import { Dialog, Paper } from '@mui/material';
import TicketListStep from './TicketListSteps';
import MessageSteps from '../NewTicketForm/MessageSteps';
import { TicketNotification } from '@/features/tickets/types';
import '@/componentsMui/Shared/Shared.css';
import { withStyles } from '@mui/styles';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { HelpTopicsEnum } from '../Components/HelpTopics';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { openForm } from '@/features/forms/slice';
import { setPageSize, setTickets } from '@/features/tickets/slice';

const FormPaperLargeScrollable = withStyles(() => ({
	root: {
		maxHeight: '90vh',
		minHeight: '90vh',
		minWidth: '600px',
		maxWidth: '600px',
		width: '600px',
		padding: '42px',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		['@media only screen and (max-width:600px)']: {
			maxHeight: '100vh',
			minHeight: '100vh',
			minWidth: '100vw',
			maxWidth: '100vw',
			width: '100%',
			padding: '1rem',
			margin: 0,
			borderRadius: 0,
		},
	}
}))(Paper);



const MyTicketsForm = () => {
	const history = useHistory();
	const searchParams = new URLSearchParams(location.search);
	const step = (searchParams.get('step'));
	const [urlTicketId] = useState<string>(searchParams.get('ticketId'));
	const [selectedTicket, setSelectedTicket] = React.useState<TicketNotification>(null);
	const closeModal = useCloseDialogNoEvent();
	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);



	const onSelect = (ticket: TicketNotification) => {
		setSelectedTicket(ticket);
		history.push('/wallet/support/list?step=1');
	};

	const handleClose = () => {
		dispatch(setPageSize(0));
		dispatch(setTickets([]));
		setSelectedTicket(null);
		closeModal();
	};

	const onBack = () => {
		history.goBack();
	};

	const topic = Object.values(HelpTopicsEnum).includes(selectedTicket?.subtype as unknown as HelpTopicsEnum) ? selectedTicket?.subtype : null;
	return (
		<Dialog
			id="my-tickets-form"
			open={true}
			onClose={handleClose}
			PaperComponent={FormPaperLargeScrollable}>
			{step === '0' && <TicketListStep onClose={handleClose} onSelect={onSelect} />}
			{step === '1' && <MessageSteps
				onBack={onBack}
				ticketId={selectedTicket?.id ?? parseInt(urlTicketId)}
				topic={topic as unknown as HelpTopicsEnum} />}

		</Dialog >
	);
};

export default MyTicketsForm; 
