import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import MuiSubHeader from '../Shared/Widgets/MuiText/MuiSubHeader';
import { useTranslation } from 'react-i18next';
import { setLandingStep, setLandingTitle } from '@/features/landingStep/slice';
import { useDispatch, useSelector } from 'react-redux';
import { skipKyc } from '@/features/user/userSlice';
import { showErrorNotification } from '@/features/swal/slice';
import { useHistory } from 'react-router-dom';
import { HOME } from '@/pages/navigationConstants';
import './Onboarding.css';
import { RootState } from '@/rootReducer';
import VerificationBox from './components/VerificationBox';
import { LandingStepEnum } from '@/features/landingStep/type';

interface Props {
    next: () => void,
}

const OrComponent = () => {
    const { t } = useTranslation('translations');
    return (
        <Typography fontSize='1.625rem'
            fontWeight={700}
            marginY='auto'
            textAlign='center'
            width={{ xs: '100%', md: 'fit-content' }}>{t('landing.idnOptions.or')}
        </Typography>);
};

const ChooseIdentityVerification = ({ next }: Props) => {

    const { t } = useTranslation('translations');
    const dispatch = useDispatch();
    const history = useHistory();
    const brandName = t('brandName.brand');
    const title = t('landing.identityStep.title');
    const neuralIdEnabled = useSelector((state: RootState) => state.status.neuralIdEnabled);
    const skipAllowed = useSelector((state: RootState) => state.user.status.skipKycAllowed);

    const handleSkip = async () => {
        try {
            await skipKyc(true);
            history.push(HOME);
        }
        catch (e) {
            showErrorNotification(e);

        }
    };

    const handleComplete = () => {
        next();
    };

    const handleShareNeural = () => {
        dispatch(setLandingStep({ stepName: LandingStepEnum.NEURAL_ID, stepOrder: 9 }));
    };
    useEffect(() => {
        dispatch(setLandingTitle(title));
    }, [dispatch, title]);



    return (
        <Grid container minHeight='312px' mt={{ xs: '20px', md: '0px' }} rowGap={2} columnGap={2} justifyContent='center'>
            <VerificationBox onClick={handleComplete} btnLabel={t('landing.idnOptions.complete')} >
                <div className='landing-list'>
                    <MuiSubHeader mb='10px'> {t('landing.idnOptions.completeReasons')}</MuiSubHeader>
                    <li> {t('landing.idnOptions.complete1')}  </li>
                    <li> {t('landing.idnOptions.complete2')} </li>
                    <li> {t('landing.idnOptions.complete3')} </li>
                    <li> {t('landing.idnOptions.complete4', { brand: brandName })} </li>
                </div>
            </VerificationBox>
            {neuralIdEnabled && <>
                <OrComponent />
                <VerificationBox outlined={true} onClick={handleShareNeural} btnLabel={t('landing.idnOptions.completeWithNeural')} >
                    <div className='landing-list'>
                        <MuiSubHeader mb='10px'> {t('landing.idnOptions.completeReasons')}</MuiSubHeader>
                        <li> {t('landing.idnOptions.complete1')}  </li>
                        <li> {t('landing.idnOptions.complete2')} </li>
                        <li> {t('landing.idnOptions.complete3')} </li>
                        <li> {t('landing.idnOptions.complete4', { brand: brandName })} </li>
                    </div>
                </VerificationBox>
            </>}

            {skipAllowed &&
                <>
                    <OrComponent />
                    <VerificationBox outlined={true} onClick={handleSkip} btnLabel={t('landing.idnOptions.skip')} >
                        <div className='landing-list'>
                            <MuiSubHeader mb='10px'> {t('landing.idnOptions.skipReasons')}</MuiSubHeader>
                            <li> {t('landing.idnOptions.skip1')} </li>
                            <li> {t('landing.idnOptions.skip2', { brand: brandName })} </li>
                        </div>
                    </VerificationBox>

                </>}
        </Grid>
    );
};

export default ChooseIdentityVerification;
