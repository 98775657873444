import * as React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import './CryptoAsset.css';


const CryptoAssetHeader = () => {
	const { t } = useTranslation('translations');
	return (
		<Grid container className='cryto-asset crypto-line'>
			<Grid item className='crypto__asset'>
				<MuiTextCaption color='text.secondary'>
					{t('wallet.labels.asset')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='crypto__price'>
				<MuiTextCaption color='text.secondary'>
					{t('wallet.labels.currentPrice')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='crypto__balance'>
				<MuiTextCaption color='crypto__balance.secondary'>
					{t('wallet.labels.myBalance')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='crypto__portfolio'>
				<MuiTextCaption color='text.secondary'>
					{t('wallet.labels.portfolio')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='crypto__actions '>
				<MuiTextCaption color='text.secondary'>
					{t('wallet.labels.actions')}
				</MuiTextCaption>
			</Grid>
		</Grid>);
};

export default CryptoAssetHeader;
