
import * as React from 'react';
import { useTheme } from '@mui/styles';


import { Box as MuiBox, BoxProps, Theme } from '@mui/material';
import { FC } from 'react';

/* Secondary background, used to wrap Language, Notification icons */
const IconWrapBox: FC<BoxProps> = (props: BoxProps) => {

	const theme = useTheme() as Theme;
	const { sx, ...other } = props;

	return (<MuiBox
		sx={{
			height: '42px',
			display: 'flex', 
			justifyContent: 'center', 
			alignItems: 'center',
			borderRadius: '12px',
			background: theme.palette.background.paper,
			boxShadow: theme.shadowMain,
			...sx,
		}}
		{...other}>{props.children}
	</MuiBox>);
};


export default IconWrapBox;
