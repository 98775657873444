import * as React from 'react';

import '@/componentsMui/Shared/Shared.css';
import '@/componentsMui/Shared/SharedFormLayout.css';
import { Address, LatLongCoordinates, PotentialAddress } from '@/features/card/types';
import { Button, Grid, MenuItem, FormControlLabel, Typography, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { useEffect, useState } from 'react';
import StyledSelect from '@/componentsMui/Shared/Widgets/StyledSelect';
import StyledCheckbox from '@/componentsMui/Shared/Widgets/StyledCheckbox';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { getAlpha2Code } from 'i18n-iso-countries';
import { useDeliveryAddress } from '@/helpers/customHook/useValidations';
import { Form, Formik } from 'formik';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import CountryList from '@/componentsMui/Dashboard/Create/Card/CountryList';
import { isEmpty } from 'lodash';

interface Props {
	integration: string,
	addresses: Array<PotentialAddress>,
	deliveryAddress: Address,
	prevStep: () => void,
	nextStep: () => void,
	title: any,
	setDeliveryAddress: (value: Address) => void
}

const removeNonLatin = (value?: string) => {

	return value ? value.replace(/[\u0250-\ue007]/g, '').replace('/', '').replace(',', '').replace('.', '') : '';
};

const ChooseAddressCardStep = ({ integration, addresses, deliveryAddress, prevStep, nextStep, title, setDeliveryAddress }: Props) => {
	const { t } = useTranslation(['translations']);
	const closeModal = useCloseDialogNoEvent();
	const ref = React.useRef<HTMLDivElement | null>();
	const [chosenAddressIndex, setChosenAddressIndex] = useState<number>(null);
	const [confirmedAddressCheck, setConfirmedAddressCheck] = useState<boolean>(false);
	const [mapMarkers, setMapMarkers] = useState<Array<google.maps.Marker>>([]);
	const [addressMap, setAddressMap] = useState<google.maps.Map>(null);
	const formRef = React.useRef(null);

	const [googleAddress, setGoogleddress] = useState<Address>();
	const validationSchema = useDeliveryAddress();
	const initialValues = {
		address: removeNonLatin(googleAddress?.address),
		postalCode: removeNonLatin(googleAddress?.postalCode),
		city: removeNonLatin(googleAddress?.city),
		country: googleAddress?.country ?? null,
		fullAddress: removeNonLatin(googleAddress?.fullAddress),
	};

	function mergeAddresses(newAddress: Address): Address {
		const value: Address = {};
		value.postalCode = newAddress.postalCode;
		value.city = newAddress.city;
		value.address = newAddress.address;
		value.fullAddress = newAddress?.fullAddress;
		//verify code translation
		const code = newAddress?.country ? getAlpha2Code(newAddress.country, 'en') : undefined;
		if (newAddress.country == null || !code) {
			value.country = null;
		} else {
			value.country = newAddress.country;
		}
		return value;
	}


	const handleNextStep = (formData) => {
		const payload: Address = {
			country: formData.country,
			city: formData.city,
			address: formData.address,
			address2: formData.address2,
			postalCode: formData.postalCode,
			fullAddress: formData.fullAddress,

		};
		setDeliveryAddress(payload);
		nextStep();
	};

	const chooseAddress = React.useCallback(async (coordinates: LatLongCoordinates) => {
		for (let i = 0; i < mapMarkers.length; i++) {
			const element = mapMarkers[i];
			if (element.getPosition().lat() == coordinates.lat &&
				element.getPosition().lng() == coordinates.lng) {
				addressMap.setCenter(element.getPosition());
				const maxZoom = await new google.maps.MaxZoomService().getMaxZoomAtLatLng(element.getPosition());
				addressMap.setZoom(maxZoom.zoom);
				break;
			}
		}
	}, [addressMap, mapMarkers]);

	const handleAddressSelect = (event: any) => {
		const { target } = event;
		handleAddressChange(target.value);
	};

	const handleAddressChange = React.useCallback((index: number) => {

		setChosenAddressIndex(index);
		if (index === -1) {
			setGoogleddress(deliveryAddress);
		}
		else {
			chooseAddress(addresses[index].coordinates);
			const payload = addresses[index].address;
			setGoogleddress(mergeAddresses(payload));
		}
	}, [addresses, chooseAddress, deliveryAddress]);



	//set default values
	useEffect(() => {
		addresses?.length > 0 ? handleAddressChange(0) : handleAddressChange(-1);
	}, [addresses?.length, handleAddressChange]);

	useEffect(() => {
		const zoom = 15;
		const bounds = new google.maps.LatLngBounds();
		const map = new window.google.maps.Map(ref.current, {
			mapId: 'map',
			center: { lat: -34.397, lng: 150.644 },
			zoom: zoom,
			gestureHandling: 'none',
			zoomControl: true,
			mapTypeControl: false,
			streetViewControl: false
		});

		const polygonCoords = addresses.map((potAddr) => {
			const lat = potAddr.coordinates.lat;
			const lng = potAddr.coordinates.lng;

			return new google.maps.LatLng(lat, lng);
		});

		polygonCoords.forEach(element => {
			bounds.extend(element);
		});
		const markers: Array<google.maps.Marker> = [];
		addresses.forEach((potAddr) => {
			const position = potAddr.coordinates;
			const marker = new google.maps.Marker({
				position,
				map
			});
			markers.push(marker);
		});
		const centerOfMap = bounds.getCenter();
		map.setCenter(centerOfMap);
		setAddressMap(map);
		setMapMarkers(markers);
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [ref, addresses]);

	const generateOption = (addressOption: Address) => {
		let fullAddress = '';
		fullAddress = fullAddress + (addressOption.address ?? '') + (addressOption.address ? ', ' : '');
		fullAddress = fullAddress + (addressOption.city ?? '') + (addressOption.city ? ', ' : '');
		fullAddress = fullAddress + (addressOption.postalCode ?? '') + (addressOption.postalCode ? ', ' : '');
		fullAddress = fullAddress + (addressOption.country ?? '');
		return fullAddress;
	};

	return (
		<Formik
			innerRef={formRef}
			validateOnChange={true}
			//validateOnMount={true} 
			enableReinitialize={true}
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleNextStep}
		>{({ errors, isSubmitting }) => {
			return (
				<Form >
					<Grid container alignContent='space-between' style={{ height: '100%' }}>
						<Grid item container spacing={1} >
							<Grid item xs={12}>
								<FormHeader title={title} showBack={!!prevStep} onBack={prevStep} />
							</Grid>
							<Grid item xs={12}>
								<StyledSelect
									id="from"
									name="from"
									value={chosenAddressIndex ?? ''}
									onChange={handleAddressSelect}
									fullWidth
									renderValue={() => <Typography sx={{ textWrap: 'wrap' }}> {`${chosenAddressIndex === -1 ? t('cards.manualAddress') : addresses[chosenAddressIndex] ? generateOption(addresses[chosenAddressIndex].address) : t('cards.selectAddress')}`}</Typography>}
								>
									<MenuItem
										value={-1}>
										<div >{t('cards.manualAddress')} </div>
									</MenuItem>
									{addresses.length > 0 && addresses.map((potAddr, index) => {
										return (
											<MenuItem
												key={index}
												value={index}>
												<div >{generateOption(potAddr.address)} </div>
											</MenuItem>
										);
									})}

								</StyledSelect>
								<p>{process.env.GoogleApiKey}</p>
							</Grid>
							<Grid item xs={12}>
								<div ref={ref} style={{ width: '100%', height: '280px' }}></div>
							</Grid>
							<Grid item xs={12}>
								{googleAddress &&
									<Grid container>
										<Grid container columnSpacing={2} height='100%' alignContent='start' position='relative'>
											<Grid item xs={12}>
												<MuiTextField
													name='address'
													label={t('form.fields.justAddress')}
													fullWidth
													helperText={errors.address}
												/>
												<FormHelperText>{t('cards.apartmentAlert')}</FormHelperText>
											</Grid>
											<Grid item xs={6}>
												<CountryList
													name='country'
													integration={integration}
													disabled={true} />
											</Grid>
											<Grid item xs={6}>
												<MuiTextField
													name='city'
													label={t('form.fields.town')}
													fullWidth
													helperText={errors.city}
												/>
											</Grid>
											< Grid item xs={6}>
												<MuiTextField
													name='postalCode'
													label={t('form.fields.postalcode')}
													fullWidth
													helperText={errors.postalCode}
												/>
											</Grid>
											<Grid item xs={12} my={2}>
												<FormControlLabel
													name='confirmedAddress'
													control={<StyledCheckbox name='confirmedAddress'
														onClick={() => setConfirmedAddressCheck(!confirmedAddressCheck)}
														value={confirmedAddressCheck}
														size='small'
													/>}
													label={<MuiFormText>{t('cards.newCard.delivery.confirmationcheckboxtext')}</MuiFormText>}
												/>
											</Grid>
										</Grid>
									</Grid>}
							</Grid>
						</Grid>
						<Grid item xs={12} container justifyContent='space-between'
							paddingBottom={googleAddress ? '20px' : '0px'} flex={1}
						>
							<Grid item xs='auto'>
								<Button
									key='buttonClose'
									variant='customOutlined'
									onClick={closeModal}
								>   	{t('form.buttons.close')}
								</Button>
							</Grid>
							<Grid item xs='auto'>
								<Button
									disabled={chosenAddressIndex == null || !confirmedAddressCheck || isSubmitting || !isEmpty(errors)}
									variant='contained'
									type='submit'
									autoFocus>
									{t('form.buttons.continue')}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Form>);
		}}
		</Formik >
	);
};

export default ChooseAddressCardStep;
