import { Alert, Box, Button, Grid, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import FormHeader from '../Components/FormHeader';
import BaseBox from '@/componentsMui/Shared/Widgets/Box/BaseBox';
import FormFooterButtons, { FormFooterButtonsProps } from '../Components/FormFooterButtons';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { getQRData } from '@/helpers/getQRData';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CoinType } from '@/features/coins/types';
import { AccountAddressesType } from '@/features/account/types';

interface Props {
	onBack: () => void,
	token: CoinType
}

const AssetDepositStep = ({ token, onBack }: Props): React.ReactElement => {

	const closeModal = useCloseDialogNoEvent();
	const { t } = useTranslation('translations');
	const addressList = token?.account?.accountAddresses;

	const [activeTab, setActiveTab] = useState(addressList[0]);
	const [loadingQr, setLoadingQr] = useState(false);
	const [qrCode, setQrCode] = useState(null); 

	const handleTabChange = (event: React.SyntheticEvent, newValue: AccountAddressesType) => {
		setActiveTab(newValue);
	};

	const footerBtn: FormFooterButtonsProps[] = [
		{
			title: t('form.buttons.close'),
			variant: 'customOutlined',
			onClick: closeModal
		},
		{
			title: t('wallet.deposit.goToWallet'),
			variant: 'contained',
			onClick: closeModal
		}];



	useEffect(() => {
		const fetchQR = async () => {
			setLoadingQr(true);
			if (activeTab?.address) {
				try {
					const qr = await getQRData(activeTab.address);
					setQrCode(qr);
				}
				catch (e) {
					setQrCode(null);
				}
				finally {
					setLoadingQr(false);
				}
			}
			else { setQrCode(null); }
		};
		fetchQR();
	}, [activeTab?.address]);

	return (
		<>
			<Grid pb='1rem'>
				<Grid item>
					<FormHeader title={`${t('wallet.deposit.deposit')} ${token.info.symbol}`} onBack={onBack} />
				</Grid>
				{token?.account?.accountAddresses?.length > 0 &&
                    <Grid item sx={{pb: {xs: '1rem', md: '2rem'}}}>
                        <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                        >{addressList.map((item) => {
							return (
								<Tab key={item.accountAddressId} value={item} label={item.network} />
							);
						})
						}
                        </Tabs>
                    </Grid>
				}
				<Grid item mt='1rem' >
					<Alert severity="warning"  >
						<MuiFormText dangerouslySetInnerHTML={{ __html: `${t('wallet.deposit.tokenInstructions', { network: activeTab?.network })} ${t('wallet.deposit.tokenWarning', {network: activeTab.network })}` }}/>
					</Alert>
				</Grid>
				<Grid item container justifyContent='space-between' alignItems='center' sx={{mt: {xs: '2rem', md: '3rem'}, flexDirection: {xs: 'column-reverse', md: 'row'}}} columnGap={0.5}>
					<Grid item xs={12} md={8} container>
						<BaseBox padding='0.625rem 1rem' width='100%'>
							<Grid container justifyContent='space-between'>
								<Grid item xs={8} >
									<MuiFormText>{activeTab.address}</MuiFormText>
								</Grid>
								<Grid item >
									<CopyToClipboard text={activeTab.address}>
										<Button variant='contained'
												color='secondary'> {t('form.buttons.copy')}
										</Button>
									</CopyToClipboard >
								</Grid>
							</Grid>
						</BaseBox>
					</Grid>
					<Grid item xs={12} md={3} sx={{mb: {xs: '2rem', md: 0}}}>
						{!loadingQr &&
                            <Box sx={{ display: 'flex', mx: 'auto', borderRadius: '10px', height: {xs: '50%', md: '7.8rem'}, width: {xs: '75%', md: '7.8rem'} }}>
                                <img style={{borderRadius: '10px', height: '100%', width: '100%'}} alt='QR code' src={qrCode} />
                            </Box>
						}
					</Grid>
				</Grid>
			</Grid>
			<FormFooterButtons buttons={footerBtn} />
		</>
	);
};

export default AssetDepositStep;
