import React, { FC } from 'react';
import { Box, BoxProps, useTheme } from '@mui/material';
 

const TooltipBox: FC<BoxProps> = (props: BoxProps) => {

    const theme = useTheme();

    const { children, ...other } = props;

    return (<Box

        width='256px'
        bgcolor='#1D2024'
        p='14px'
        boxShadow='0px 1px 5px rgba(0, 0, 0, 0.35)'
        borderRadius={theme.mainBorderRadius}
        {...other}>
        {children}
    </Box >);
};


export default TooltipBox;

