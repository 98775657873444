import { InvoiceProduct } from '@features/invoices/invoicesTypes';
import { Button, Card, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';
import NumericFormat from 'react-number-format';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import useCustomBreakpoints from '@/helpers/customHook/useCustomBreakpoints';
import useUnitOptions from '@/helpers/customHook/useUnitOptions';
import { getCurrencySign } from '@/helpers/currency';
import AddEditProductDialog from './AddEditProductDialog';

interface Props {
    products: InvoiceProduct[];
    onAdd: (product: InvoiceProduct) => void;
    onRemove: (product: InvoiceProduct) => void;
    productsEnabled: boolean;
    onEdit?: (product: InvoiceProduct) => void;
}

const ProductsBox = ({ products, onAdd, onEdit, onRemove, productsEnabled }: Props) => {
    const { t } = useTranslation('translations');
    const [openAddProductDialog, setOpenAddProductDialog] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<InvoiceProduct | null>(null);
    const { unitOptions } = useUnitOptions();
    const { isDesktop } = useCustomBreakpoints();

    const isEditAllowed = !!onEdit;

    const handleSaveProduct = (mode: 'EDIT' | 'ADD', _product: InvoiceProduct) => {
        if (mode === 'EDIT') {
            onEdit(_product);
        } else if (mode === 'ADD') {
            onAdd(_product);
        }
        setSelectedProduct(null);
        setOpenAddProductDialog(false);
    };

    const handleEditProduct = (_product: InvoiceProduct) => {
        setSelectedProduct(_product);
        setOpenAddProductDialog(true);
    };

    return (
        <Card
            sx={theme => ({
                background: theme.palette.background.paper,
                padding: '20px',
                borderRadius: '16px',
            })}
        >
            {openAddProductDialog && <AddEditProductDialog
                open={openAddProductDialog}
                onClose={() => { setSelectedProduct(null); setOpenAddProductDialog(false); }}
                product={selectedProduct}
                onSave={handleSaveProduct}
                unitOptions={unitOptions}
            />}
            <Grid container>
                <Grid item xs={12} container justifyContent='space-between' alignItems='center'>
                    <Grid item>
                        <Typography variant="h5">{t('invoice.products')}</Typography>
                    </Grid>
                    {productsEnabled && <Grid item>
                        <Button variant="contained" onClick={() => setOpenAddProductDialog(true)}>{t('invoice.addPlus')}</Button>
                    </Grid>}
                </Grid>
                <Grid item xs={12} sx={{ overflowX: !isDesktop ? 'auto' : undefined }} mt={2}>
                    {products.length > 0 ?
                        <Table

                        >
                            <TableHead>
                                <TableRow sx={theme => ({
                                    '& .MuiTableCell-head': {
                                        color: theme.palette.text.primary,
                                        border: 'unset',
                                        borderBottom: '1px'
                                    }
                                })}>
                                    <TableCell>{t('invoice.description')}</TableCell>
                                    <TableCell>{t('invoice.quantity')}</TableCell>
                                    <TableCell>{t('invoice.unitPrice')}</TableCell>
                                    <TableCell>{t('invoice.amount')}</TableCell>
                                    <TableCell>{t('invoice.taxRate')}</TableCell>
                                    <TableCell>{t('invoice.taxAmount')}</TableCell>
                                    <TableCell>{t('invoice.totalAmount')}</TableCell>
                                    {productsEnabled && <TableCell></TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{
                                '& .MuiTableRow-root': {
                                    background: 'none !important',
                                    backdropFilter: 'none !important'
                                }
                            }}>
                                {products.map(product => <ProductItem
                                    key={product.uuid}
                                    onEditClick={() => handleEditProduct(product)}
                                    onRemoveClick={() => onRemove(product)}
                                    product={product}
                                    productsEnabled={productsEnabled}
                                    isEditAllowed={isEditAllowed}
                                />)}
                            </TableBody>
                        </Table>
                        : <Typography variant="body2" textAlign='center' my={2}>{t('invoice.noProductsYet')}</Typography>}
                </Grid>
            </Grid>
        </Card>
    );
};

interface ProductItemProps {
    onEditClick: () => void;
    onRemoveClick: () => void;
    product: InvoiceProduct;
    productsEnabled: boolean;
    isEditAllowed: boolean;
}

const ProductItem = ({ product, onEditClick, onRemoveClick, productsEnabled, isEditAllowed }: ProductItemProps) => {
    const taxAmount = product.taxRate ? (product.price * product.taxRate) * product.quantity : 0;
    const amount = product.price * product.quantity;
    const totalAmount = amount + taxAmount;

    return (<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>{product.description}</TableCell>
        <TableCell>{`${product.quantity} ${product.unit}`}</TableCell>
        <TableCell>
            <NumericFormat
                displayType={'text'}
                decimalScale={2}
                fixedDecimalScale
                thousandsGroupStyle='thousand'
                thousandSeparator
                prefix={getCurrencySign('EUR')}
                value={product.price} />
        </TableCell>
        <TableCell>
            <NumericFormat
                displayType={'text'}
                decimalScale={2}
                fixedDecimalScale
                thousandsGroupStyle='thousand'
                thousandSeparator
                prefix={getCurrencySign('EUR')}
                value={amount} />
        </TableCell>
        <TableCell>{`${product?.taxRate ? product.taxRate * 100 : 0}%`}</TableCell>
        <TableCell>
            <NumericFormat
                displayType={'text'}
                decimalScale={2}
                fixedDecimalScale
                thousandsGroupStyle='thousand'
                thousandSeparator
                prefix={getCurrencySign('EUR')}
                value={taxAmount} />
        </TableCell>
        <TableCell>
            <NumericFormat
                displayType={'text'}
                decimalScale={2}
                fixedDecimalScale
                thousandsGroupStyle='thousand'
                thousandSeparator
                prefix={getCurrencySign('EUR')}
                value={totalAmount} />
        </TableCell>
        {productsEnabled && <TableCell align='right'>
            {isEditAllowed && <IconButton onClick={onEditClick}>
                <EditIcon />
            </IconButton>}
            <IconButton onClick={onRemoveClick}>
                <DeleteIcon />
            </IconButton>
        </TableCell>}
    </TableRow>);
};

export default ProductsBox;
