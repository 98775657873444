import * as React from 'react';
import { SvgIcon } from '@mui/material';

const FileIcon = (props) => (
  <SvgIcon {...props}>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 8.25L9 8.25" stroke="white" strokeLinecap="round" />
      <path d="M6 12H12" stroke="white" strokeLinecap="round" />
      <path d="M15.75 6.75V12.5C15.75 14.7091 13.9591 16.5 11.75 16.5H6.25C4.04086 16.5 2.25 14.7091 2.25 12.5V5.5C2.25 3.29086 4.04086 1.5 6.25 1.5H10.5M15.75 6.75L10.5 1.5M15.75 6.75H14.5C12.2909 6.75 10.5 4.95914 10.5 2.75V1.5" stroke="white" strokeLinejoin="round" />
    </svg>
  </SvgIcon >
);

export default FileIcon;
