import { ThemeOptions } from '@mui/material/styles/createTheme';
import { createTheme } from '@mui/material/styles';

import defaultDarkTheme from '../default/defaultDarkTheme';
//import _ from 'lodash'; 
/* const newTheme: ThemeOptions = _.cloneDeep(defaultDarkTheme); 
newTheme.selectedItemBg.default = 'linear-gradient(96.99deg, #01DDB9 19.1%, #0184FD 71.05%)';
newTheme.iconGradient.stopColor1 = '#01DDB9';
newTheme.highlight= '#01DDB9';
newTheme.highlight= '#01DDB9'; */

const newTheme: ThemeOptions = {
	...defaultDarkTheme,
	selectedItemBg: {
		default: 'linear-gradient(151.26deg, #BF6452 66.46%, #FFFFFF 149.32%),rgba(255, 255, 255, 0.04)'
	},
	iconGradient: {
		...defaultDarkTheme.iconGradient,
		stopColor1: '#BF6452',
		stopColor2: '#FFFFFF'
	},
	highlight: '#BF6452',
	carousel: {
		...defaultDarkTheme.carousel,
		iconShadow: '0px 1px 15px 2px rgb(191, 100, 82, 0.41)'
	},
	covers: {
		...defaultDarkTheme.covers,
		productCover: {
			...defaultDarkTheme.covers.productCover,
			bg: [
				//accounts, cards, eran products
				{
					// firstBg: 'linear-gradient(329.09deg, #BF6452 91.04%, #3D2DDD 91.05%)',
					// secondBg: 'linear-gradient(0deg, #FFFFFF, #FFFFFF)',
					firstBg: 'linear-gradient(113.09deg, rgb(191, 100, 82) 10.81%, rgb(191, 100, 82) 11.09%), rgba(255, 255, 255, 0.04)',
					secondBg: 'linear-gradient(113.09deg, rgb(191, 100, 82) 10.81%, rgb(191, 100, 82) 11.09%), rgba(255, 255, 255, 0.04)',
				},
				//wallet
				{
					firstBg: 'linear-gradient(329.09deg, #0500F4 91.04%, #FAFF00 91.05%)',
					secondBg: 'linear-gradient(329.09deg, #0500F4 91.04%, #FAFF00 91.05%)',
				}]

		},
		paymentMethodCover: {
			...defaultDarkTheme.covers.paymentMethodCover,
			bg: [{
				// firstBg: 'linear-gradient(329.09deg, #EE9259 91.04%, #3D2DDD 91.05%)',
				// secondBg: 'linear-gradient(329.09deg, #EE9259 91.04%, #3D2DDD 91.05%)',
				firstBg: 'linear-gradient(151.26deg, #BF6452 66.46%, #FFFFFF 149.32%),rgba(255, 255, 255, 0.04)',
				secondBg: 'linear-gradient(151.26deg, #BF6452 66.46%, #FFFFFF 149.32%),rgba(255, 255, 255, 0.04)',
				
			},
			{
				firstBg: 'linear-gradient(0deg, #BF6452, #BF6452)',
				secondBg: 'linear-gradient(0deg, #BF6452, #BF6452)',
			}],
		}
	},
	warningBg:  'linear-gradient(112.26deg, #BF64521a 10.81%, rgba(1, 132, 253, 0.1) 160.09%)',
	auth: {
		welcomeCardBg: 'linear-gradient(91.8deg, #BF6452 -9.11%, rgba(196, 155, 147, 0.5) 114.58%)',
	},
};

const paletteDark = createTheme(newTheme);

export default paletteDark;
