import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Theme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import BankAccounDetails from '../Account/BankAccountDetails';
import BaseBox from '@/componentsMui/Shared/Widgets/Box/BaseBox';
import TransactionStatements from '@/componentsMui/Transactions/TransactionStatements';
import { connect as connectTrust, subscribe as subscribeTrust, unsubscribe as unsubscribeTrust } from '@features/trust/slice';
import '@/componentsMui/Shared/Shared.css';
import { AccountType } from '@/features/operations/types';
import { useTheme } from '@mui/styles';
import LoadingPageMui from '@/pages/LoadingPageMui';

const TrustAccountDetails = (): React.ReactElement => {
	const theme = useTheme() as Theme;
	const { t } = useTranslation('translations');
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const [accountId] = useState<string>(searchParams.get('id'));
	const accounts = useSelector((state: RootState) => state.trust.accounts);

	const currentAccount = accounts.find(l => `${l.accountId}` === accountId);

	useEffect(() => {

		connectTrust();
		dispatch(subscribeTrust());
		return () => {
			unsubscribeTrust();
		};

	}, [dispatch]);

	if (!currentAccount) {
		return <LoadingPageMui />;
	}

	return (
		<Grid id="trust-account-details" container className='scroll-grid-container'>
			<Grid item height='3rem'>
				<IconButton onClick={() => history.goBack()}><KeyboardBackspaceIcon />
					<MuiHeader color='text.secondary' pl='18px'> {t('trusts.selectTrust')}</MuiHeader>
				</IconButton>
			</Grid>
			<Grid item >
				<BankAccounDetails bankAccount={currentAccount} />
			</Grid>
			<Grid item className='scroll-section'>
				<BaseBox className='service-tab-wrap scroll-section' sx={{
					background: { xs: 'none', sm: theme.palette.mode === 'dark' ? theme.backgroundBoxLevel1 : theme.palette.background.paper },
					p: { xs: 0, sm: '1.5rem' },
					width: '100%',
					mb: { xs: 3, md: 0 },
				}}>
					<TransactionStatements
						type={AccountType.TRUST}
						account={currentAccount}
					/>
				</BaseBox>
			</Grid>
		</Grid>
	);
};

export default TrustAccountDetails;
