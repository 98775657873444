import { getCardCvvDetailsIC, getCardPanDecta, getCardPanSh_Finance } from '@/features/card/slice';
import { Card } from '@/features/card/types';
import { showException } from '@/features/swal/slice';
import { useCallback, useState } from 'react';

const useCardCvvPan = (card: Card) => {

    const isDecta = card?.process?.integration === 'DECTA';
    const isSH_Finance = card?.process?.integration === 'SH_FINANCE';
	const isIntercash = card?.process?.integration === 'INTERCASH_EU' || card?.process?.integration === 'INTERCASH_LATAM';
    const defaultCode = '•••';
    const defaultCardNumber = card?.number ? card.number.replaceAll('*', '•') : '•• •••• •••• ••••';

    const showGetCVV = card.virtual;
    const showGetPan = card.virtual;


    const [cvv, setCvv] = useState(defaultCode);
    const [cardNumber, setCardNumber] = useState(defaultCardNumber);
    const [loading, setLoading] = useState(false);
    const [cardInfoIframe, setCardInfoIframe] = useState(null);


    const fetchCardCvvPan = useCallback(async () => {
        setLoading(true);
        try {
            if (isDecta) {
                const result = await getCardPanDecta(card.cardId);
                setCvv(result.cvv);
                const pan = result.pan;
                const formatedPan = pan.match(/.{1,4}/g).join(' ');
                setCardNumber(formatedPan);
            } else if(isIntercash){
                const result = await getCardCvvDetailsIC(`${card.cardId}`);
                setCvv(result.cvv);
            } else if(isSH_Finance){
                const result = await getCardPanSh_Finance(card.cardId);
                setCardInfoIframe(result.panImageUrl);
            }
            setLoading(false);

        } catch (e) {
            setLoading(false);
            await showException(e);
        }
    }, [card.cardId, isDecta, isIntercash, isSH_Finance]);

    return { fetchCardCvvPan, cvv, cardNumber, loading, showGetCVV, showGetPan, defaultCvvCode: defaultCode, cardInfoIframe, defaultCardNumber ,isSH_Finance};

};

export default useCardCvvPan;
