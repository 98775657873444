import React from 'react';
import { Dialog, DialogContent, Grid, IconButton } from '@mui/material';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import PDFViewer from '@/componentsMui/Shared/Components/PDFViewer';
import { saveAs } from 'file-saver';
import DownloadButton from '../Widgets/DownloadButton';

interface Props {
    file: any;
    isOpen: boolean;
    savedFileName?: string,
    onClose: () => any;
}

const PdfViewerFullScreen = ({
    file,
    isOpen,
    savedFileName,
    onClose,
}: Props) => {

    const onSave = () => {
        if (savedFileName) {
            saveAs(file, savedFileName);
        }
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullScreen
        >
            <DialogContent  >
                <Grid container>

                    <Grid item xs={12} textAlign='right'>
                        {savedFileName && <DownloadButton onClick={onSave} sx={{ background: 'transparent' }} />}
                        <IconButton onClick={onClose}   >
                            <MuiCloseIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        < PDFViewer file={file} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default PdfViewerFullScreen;
