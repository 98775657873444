import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import NumberFormat from 'react-number-format';
import { isFiat } from '@/helpers/currency';
import { getCurrencySign } from '@/features/fees/slice';

const ReferralsAmountCell = (params: GridRenderCellParams) => {
    if (params.value === null || params.value === undefined) {
        return '';
    }
    
    const row = params.row;
    const ccy = row.currency;
    const precision = isFiat(ccy) ? 2 : 5;

    return (
        <MuiFormText>
            <NumberFormat displayType={'text'} decimalScale={precision}
                fixedDecimalScale={true} thousandsGroupStyle='thousand'
                thousandSeparator={true} value={params.value} suffix={getCurrencySign(ccy)} />
        </MuiFormText>
    );
};

export default ReferralsAmountCell;
