import request from '@services/request';
import { GridPayload, Payments, Program, Referees, Statistics } from '../referrals/types';



export const getPayments = async (payload: GridPayload): Promise<Payments> => {
	const response = await request.post('/api/referrals/payments', payload);
	const { data } = response;
	return data;
};

export const getReferees = async (payload: GridPayload): Promise<Referees> => {
	const response = await request.post('/api/referrals/referees', payload);
	const { data } = response;
	return data;
};

export const getStatistics = async (): Promise<Statistics> => {
	const response = await request.get('/api/referrals/statistics');
	const { data } = response;
	return data;
};

export const getPrograms = async (): Promise<Program[]> => {
	const response = await request.get('/api/referrals/programs');
	const { data } = response;
	return data;
};


