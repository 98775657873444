import * as React from 'react';
import { Grid, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import './BusinessCards.css';
import { Card, CardIntegrationEnum, CardSubProcesses } from '@/features/card/types';
import NumberFormat from 'react-number-format';
import { getCurrencySign } from '@/features/fees/slice';
import CardDetailsForm from './CardDetailsForm';
import { useState } from 'react';
import { tranformSubProcessByType } from '@/features/operations/slice';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { BankAccount } from '@/features/user/types';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { hiddenBabalance } from '@/helpers/placeholders';
import { formatExpirationDate } from '@/helpers/date';

interface Props {
	card: Card,
	onClick?: (account: BankAccount) => void
}

const BusinessCardsLine = ({ card, onClick }: Props) => {
	const { t } = useTranslation('translations');
	const history = useHistory();
	const [openCardDetails, setOpenCardDetails] = useState<boolean>(false);
	const cardProcesses = tranformSubProcessByType(card.process.subProcesses);

	const { user } = useSelector((state: RootState) => state.user);

	const topUpAllowed = !isEmpty(cardProcesses[CardSubProcesses.CARD_TOP_UP]);
	const isShCard = card.process.integration === CardIntegrationEnum.SH_FINANCE;
	const numberMask = card.cardNumberMask ? `•• ${card.cardNumberMask?.slice(-4)}` : '••••';

	const handleTopup = () => {
		history.push(`/wallet/home/cards/transfers/top-up?card=${card.cardId}`);
	};


	return (
		<Box id='business-card-line' style={{ cursor: 'pointer' }}>
			<CardDetailsForm
				open={openCardDetails}
				onClose={() => setOpenCardDetails(false)}
				card={card}
			/>
			<Grid container alignItems='center' mb='1.25rem' mt='1.25rem'  >
				<Grid item className='business-cards-list__name' onClick={() => onClick && onClick(card)}>
					<MuiTextCaption className='business-cards-list-value'>
						{card.name}
					</MuiTextCaption>
				</Grid>
				<Grid item className='business-cards-list__card-holder' onClick={() => onClick && onClick(card)}>
					<MuiTextCaption className='business-cards-list-value'>
						{card?.assignedTo ? `${card?.assignedTo.firstName} ${card?.assignedTo.lastName}` :
							t('cards.businessCards.notAssigned')}
					</MuiTextCaption>
				</Grid>
				<Grid item className='business-cards-list__balance' onClick={() => onClick && onClick(card)} >
					{!isShCard ? <MuiTextCaption variant='gradient' className='business-cards-list-value'>
						{user.hideValues ?
							<>{getCurrencySign(card.currency)} {hiddenBabalance}</>
							:
							<NumberFormat displayType={'text'}
								decimalScale={2}
								fixedDecimalScale={true}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								prefix={getCurrencySign(card.currency)}
								value={card.availableBalance}
							/>}
					</MuiTextCaption> : <MuiTextCaption className='business-cards-list-value'>
						{numberMask}
					</MuiTextCaption>}
				</Grid>
				<Grid item className='business-cards-list__balance-on-hold' onClick={() => onClick && onClick(card)} >
					{!isShCard ? <MuiTextCaption className='business-cards-list-value'>
						{user.hideValues ?
							<>{getCurrencySign(card.currency)} {hiddenBabalance}</>
							:
							<NumberFormat displayType={'text'}
								decimalScale={2}
								fixedDecimalScale={true}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								prefix={getCurrencySign(card.currency)}
								value={card.balanceOnHold}
							/>}
					</MuiTextCaption> : <MuiTextCaption className='business-cards-list-value'>
						{formatExpirationDate(card.expirationDate)}
					</MuiTextCaption>}
				</Grid>
				<Grid item className='business-cards-list__status' onClick={() => onClick && onClick(card)} >
					<MuiTextCaption className='business-cards-list-value'>
						{t('cards.status.' + card.status)}
					</MuiTextCaption>
				</Grid>
				<Grid item className='business-cards-list__actions' >
					<Grid container flexDirection='row' alignItems='center' columnGap={2} justifyContent='flex-end' >
						{!isShCard && <Button
							sx={{ padding: '0.25rem 1rem' }}
							variant='simple'
							onClick={handleTopup}
							disabled={!topUpAllowed}
						>
							<MuiTextCaption>{t('cards.businessCards.list.topUp')}</MuiTextCaption>
						</Button>}
					</Grid>

				</Grid>
			</Grid>
		</Box >
	);
};

export default BusinessCardsLine;
