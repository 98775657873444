import * as React from 'react';

import '@/componentsMui/Shared/Shared.css';

import {
	Button,
	Grid,
	InputAdornment, Theme, Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import * as Yup from 'yup';
import { showException } from '@/features/swal/slice';
import FormContentBox from '@/componentsMui/Shared/Widgets/Box/FormContentBox';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { maxTwoDecimals } from '@/helpers/number';
import NumberFormat from 'react-number-format';
import useFeeDebounce from '@/helpers/customHook/useFeeDebounce';
import { findProcessByType, runOperation } from '@/features/operations/slice';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import useCloseDialog from '@/helpers/customHook/useCloseDialog';
import { isFiat } from '@/helpers/currency';
import FormLabel from '@shared/Components/SendMoney/PaymentForm/FormLabel';
import AccountItem from '@/componentsMui/Banking/Account/AccountItem';
import { getAccountName } from '@helpers/getAccountName';
import StyledDivider from '@shared/Widgets/StyledDivider';
import bigDecimal from 'js-big-decimal';
import { useTheme } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { CoinType } from '@/features/coins/types';
import { useRouteMatch } from 'react-router-dom';
import CardTransferRedirect from '@/componentsMui/Shared/Components/SendMoney/CardTransfer/CardTransferRedirect';
import { CardaqBuyCryptoByCardRegisteredPayload, IntegrationEnum } from '@/features/operations/types';
import { refreshToken5MinuteLong } from '@/features/user/userSlice';



type Props = {
	toAsset?: CoinType,
	prevStep?: () => void
}

const BuyAmountStep = ({
	toAsset,
	prevStep
}: Props) => {
	const theme = useTheme() as Theme;
	const { t } = useTranslation('translations');
	const { path } = useRouteMatch();
	const pathStart = window.location.href.split(path)[0];
	const selectedAccount = toAsset.account;

	const [redirectUrl, setRedirectUrl] = useState<string>('');

	const [amount, setAmount] = useState<string>('');
	const [amountforFee, setAmountForFee] = useState<number>(0);
	const process = findProcessByType(toAsset, 'DEPOSIT_FROM_CARD');
	const dispatch = useDispatch();
	const proc = process?.proc;
	const disabled = !proc;
	const isMaintenance = process?.maintenance;

	const currency = 'EUR';
	const cryptoCcy = toAsset?.account?.currency ?? '';
	const precision = toAsset?.account?.precision ?? 4;
	const price = toAsset?.info?.summary24hInEur?.price;
	const amountInCrypto = amountforFee && price && new bigDecimal(amountforFee).divide(new bigDecimal(price), precision).getValue();

	const { fee, callGetFee, feeLoading } = useFeeDebounce(0);
 
	const closeModal = useCloseDialog();

	const maxAmount = process?.config?.maxTransactionAmount ?? 500;
	const minAmount = process?.config?.minTransactionAmount ?? 50;

	const initialValues = {
		to: selectedAccount?.accountId ?? '',
		amount: '',
	};

	const fiat = isFiat(selectedAccount?.currency);

	const validationSchema = Yup.object({
		amount: Yup.number().typeError(t('form.validator.required')).required(t('form.validator.required')).nullable()
			.min(minAmount, t('form.validator.minNumber', { field: t('sendmoneyTranslation.data.transferamount'), amount: minAmount }))
			.max(maxAmount, t('form.validator.maxNumber', { field: t('sendmoneyTranslation.data.transferamount'), amount: maxAmount }))

			.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => fiat ? maxTwoDecimals(value) : true),
	});

	const submit = async (formData, formikProps) => {
		const { setFieldError } = formikProps;
		const { amount } = formData;

		if (proc === null) {
			setFieldError('from', t('sendmoneyTranslation.data.missingProcess'));
		}



		try {
			const paymentAmount = parseFloat(amount);
			const payload: CardaqBuyCryptoByCardRegisteredPayload = {
				...{
					amount: paymentAmount,
					cardCcy: 'EUR',
					cardTargetIntegration: IntegrationEnum.CLEARJUNCTION_WALLET,
					targetAccountId: selectedAccount.accountId,
					successUrl: `${pathStart}/wallet/home/wallets/buycrypto/success`,
					errorUrl: `${pathStart}/wallet/home/wallets/buycrypto/error`,
					cancelUrl: `${pathStart}/wallet/home/wallets/buycrypto/cancel`,
				},
			};

			dispatch(refreshToken5MinuteLong());
			const result = await runOperation(proc, payload);
			setRedirectUrl(result.value.REDIRECT_URL);
		}
		catch (e) {
			await showException(e);
		}
	};


	const amountChange = (value, setFieldValue, setFieldTouched) => {
		setFieldValue('amount', value);
		setAmount(value);
		const parsed = parseFloat(value);
		const newAmount = isNaN(parsed) ? 0 : parsed;
		setAmountForFee(newAmount);
		callGetFee(selectedAccount.accountId, proc, newAmount);
		setTimeout(() => setFieldTouched('amount', true));
	};

	const handleAmountChange = (event: React.ChangeEvent<any>, setFieldValue, setFieldTouched) => {
		amountChange(event.target.value, setFieldValue, setFieldTouched);
	};


	if (redirectUrl) return (<CardTransferRedirect url={redirectUrl} />);
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={submit}
		>
			{({ errors, isSubmitting, setFieldValue, setFieldTouched }) => {
				return (
					<Form className='form-content__wrap__bb'>
						<FormContentBox noBackground={true} className='form-content_main-area wp'>
							<Grid container columnSpacing={3} rowSpacing={1}>
								<Grid item xs={12}>
									<FormHeader title={t('wallet.labels.buyCrypto')} onBack={prevStep} showBack={!!prevStep} showMaintenace={isMaintenance} />
								</Grid>
								<Grid item xs={12}>
									<AccountItem currency={selectedAccount.currency} logoSource={toAsset?.info?.logo} name={getAccountName(t, selectedAccount)} decimalScale={2} />
								</Grid>
								<Grid item xs={12}>
									<Grid container item xs={12} my={1} justifyContent="space-between" alignItems="center">
										<Grid item>
											<FormLabel>{t('sendmoneyTranslation.data.amount')}</FormLabel>
										</Grid>
									</Grid>
									<NumberFormat
										name="amount"
										customInput={MuiTextField}
										className='large-number-input'
										onChange={(event) => handleAmountChange(event, setFieldValue, setFieldTouched)}
										allowNegative={false}
										value={amount}
										displayType={'input'}
										placeholder={'0.00'}
										allowedDecimalSeparators={['.']}
										decimalScale={precision}
										fullWidth
										sx={{
											'& .MuiInputAdornment-root p': {
												fontSize: '1.3rem'
											},
											'& input::placeholder': {
												fontSize: '1.3rem',
											}
										}}
										inputProps={{ autoComplete: 'off', sx: { fontSize: '1.3rem' } }}
										InputProps={{
											style: { fontSize: '1.3rem' },
											sx: { background: theme.backgroundBoxLevel1, height: '74px!important', fontSize: '1.3rem' },
											startAdornment:
												<InputAdornment position="start">
													{currency}
												</InputAdornment>
										}}
									/>
								</Grid>
								<Grid item xs={12} mt='1rem'>
									<StyledDivider />
								</Grid>
								{!!price &&
									<Grid container item xs={12} justifyContent='space-between' alignItems='center'>
										<Grid item>
											<Typography color='text.primary' sx={{ fontSize: '1.225rem' }}>
												{t('wallet.labels.amountInCrypto')}
											</Typography>
										</Grid>
										<Grid item>
											<Typography color='text.primary' fontWeight={700} sx={{ fontSize: '1.225rem' }}>
												<Typography variant='highlight'>
													{cryptoCcy}
												</Typography>
												{` ${amountInCrypto}`}
											</Typography>
										</Grid>
									</Grid>
								}
								<Grid item xs={12} container justifyContent='right'>
									<Typography color='text.secondary' sx={{ fontSize: '0.7rem' }}>
										{t('sendmoney.data.includedWithFee')}
									</Typography>
									<Typography color='text.primary' sx={{ fontSize: '0.7rem', ml: 0.5 }} noWrap>
										<NumberFormat
											id="fee"
											name="fee"
											displayType={'text'}
											decimalScale={2}
											value={fee}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											prefix={'€ '}
											placeholder={'0.00'}
										/>
									</Typography>
								</Grid>
							</Grid>
							{/* 	<Grid item xs={12} mt={1}>
								<MuiTextField
									name="reference"
									multiline
									fullWidth
									inputProps={{ autoComplete: 'off', maxLength: 140 }}
									label={t('sendmoneyTranslation.data.reference')}
									rows={2} />
							</Grid> */}
							<Grid container mt='auto' pt='2rem' columnSpacing={2}>
								<Grid item xs={6}>
									<Button
										variant='customOutlined'
										color='secondary'
										onClick={closeModal}
										sx={{ width: { xs: '100%', md: 'auto' } }}
									>  {t('form.buttons.close')}
									</Button>
								</Grid>
								<Grid item xs={6} container justifyContent='flex-end'>
									<Button
										disabled={disabled || isSubmitting || !isEmpty(errors) || feeLoading || isMaintenance}
										variant="contained"
										color="primary"
										sx={{ width: { xs: '100%', md: 'auto' } }}
										type="submit"
									>
										{t('form.buttons.next')}
									</Button>
								</Grid>
							</Grid>
						</FormContentBox>
					</Form>
				);
			}}
		</Formik >);
};

export default BuyAmountStep;
