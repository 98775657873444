
import { postVaultDepositPayInfo } from '@/features/earn/slice';
import { showErrorNotification } from '@/features/swal/slice';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';

const useVaultDepositAmountDebounce = (initAmount: number) => {

	const [depositAmount, setDepositAmount] = useState<number>(initAmount);
	const [amountError, setAmountError] = useState<boolean>(false);
	const [amountLoading, setAmountLoading] = useState<boolean>(false);

	const callGetDepositAmount = useMemo(() => debounce(
		async (fromCcy: string, toCcy: string, amount: number) => {
			if (amount <= 0) {
				setDepositAmount(0);
				return;
			}

			setAmountLoading(true);
			const payload: any = { toCcy, fromCcy, amount };

			try {
				if ((fromCcy) && (amount) && (toCcy)) {
					const result = await postVaultDepositPayInfo(payload);
					setDepositAmount(result.amount);
					setAmountError(false);
				}
			}
			catch (e) {
				setAmountError(true);
				showErrorNotification(e);
			} finally {
				setAmountLoading(false);
			}
		}, 500)
		, []
	);

	return { depositAmount, setDepositAmount, amountError, amountLoading, callGetDepositAmount };
};

export default useVaultDepositAmountDebounce;
