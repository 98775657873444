import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';


export const validatePostalCode = (postalCode: string, countryCode: string): boolean => {
    if (postcodeValidatorExistsForCountry(countryCode)) {
        const original = postcodeValidator(postalCode, countryCode);
        const uppercase = postcodeValidator(postalCode.toUpperCase(), countryCode);
        const lowercase = postcodeValidator(postalCode.toLowerCase(), countryCode);

        return original || uppercase || lowercase;
    } else {
        return true;
    }
};


