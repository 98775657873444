
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import CardStatusBox from '../../../../Shared/Widgets/Box/CardStatusBox';


const CardSuspended = () => {
	const { t } = useTranslation('translations');

	return (
		<CardStatusBox id="cardFrozen" >
			<MuiTextCaption>{t('cards.cardStatus.suspended')}</MuiTextCaption>
			{/* <MuiTextLabel >{t('cards.cardStatus.suspendedMessage')}</MuiTextLabel> */}
		</CardStatusBox>);
};

export default CardSuspended;
