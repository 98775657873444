import { Typography, TypographyProps } from '@mui/material';
import React, { FC } from 'react';

const MuiFormField: FC<TypographyProps> = (props: TypographyProps) => {
	return (
		<Typography
			fontSize='0.813rem'
			fontWeight='600'
			lineHeight='1.375rem'
			color='text.primary'
			style={{ wordWrap: 'break-word' }}
			whiteSpace='pre-line' //this wrap text 
			{...props}>
			{props.children}
		</Typography >
	);
};

export default MuiFormField;
