import {
	Alert, Box,
	Button,
	Container,
	Grid,
	Link,
	Theme,
	useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'is-empty';
import { forgotPassword, logout } from '@features/user/userSlice';
import { showSuccess, showErrorNotification } from '@features/swal/slice';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import MuiTextAlert from '@shared/Widgets/MuiText/MuiTextAlert';
import TextField from '@shared/FormikComponents/MuiTextField';
import BackMobileButton from '@shared/Widgets/BackMobileButton';
import { getRecaptureToken } from '@/helpers/recaptureUtils';
import MuiBigHeader from '../Shared/Widgets/MuiText/MuiBigHeader';
import MuiFormText from '../Shared/Widgets/MuiText/MuiFormText';
import { REGISTER } from '@/pages/navigationConstants';
import { setLandingStepCount, setLandingTitle } from '@/features/landingStep/slice';
import MinimalLayout from '../Layout/MinimalLayout';
import { Agents } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';

const ForgotPassword = () => {
	const { t } = useTranslation(['translations']);
	const theme = useTheme() as Theme;
	const history = useHistory();
	const dispatch = useDispatch();
	const [loginError, setError] = useState<boolean>(false);

	const { agent } = useSelector(
		(state: RootState) => state.status
	);

	const initialValues = {
		email: ''
	};

	const validationSchema = Yup.object({
		email: Yup.string()
			.email(t('form.validator.email'))
			.required(t('form.validator.required'))
	});

	useEffect(() => {
		dispatch(setLandingStepCount(0));
		dispatch(setLandingTitle(''));
	}, [dispatch]);

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setError(false);
		try {
			const { email } = formData;
			const recaptchaToken = await getRecaptureToken();
			await dispatch(forgotPassword({ email, recaptchaToken }));
			await dispatch(logout());
			showSuccess({ title: 'swal.NEW_PASSWORD', text: 'swal.NEW_PASSWORD_TEXT', timer: null, confirmBTNText: t('form.buttons.close') });
			history.push('/auth');
		}
		catch (e: any) {
			setSubmitting(false);
			if (e?.data?.errors?.length > 0 && e?.data?.errors[0].error === 'notFound' && e?.data?.errors[0].error_param === 'email') {
				showErrorNotification(e, t('forgot-password.errors.emailNotFound'), t('form.buttons.close'));
			} else {
				showErrorNotification(e, null, t('form.buttons.close'));
			}
		}
	};

	const handleRegister = () => {
		if (agent === Agents.COSPAY) {
			window.location.replace('https://www.dtsmoney.com');
		} else {
			history.push(REGISTER);
		}
	};

	return (
		<MinimalLayout >
			<Box id='auth'>
				<Container component="main" sx={{
					justifyContent: 'flex-start',
					display: 'flex',
					flexDirection: 'column',
					maxWidth: { xs: '100', sm: '444px' },
					pt: { xs: 2, sm: 6 },
				}}>
					<Grid item sx={{ display: { xs: 'block', sm: 'none' } }}>
						<BackMobileButton
							onClick={() => history.push('/auth')}
						/>
					</Grid>
					<Grid container itemScope>
						<Grid item xs={12} >
							<Grid item container p={3} rowSpacing={2} alignContent='flex-start' rowGap={3}>
								<Grid item xs={12}>
									<MuiBigHeader
										fontSize='1.625rem'>{t('login.resetPassword')}
									</MuiBigHeader>
								</Grid>
								<Grid item xs={12}>
									<MuiFormText >{t('forgot-password.title')}
									</MuiFormText>
								</Grid>
								{loginError && <Grid item xs={12}>
									<Alert severity="warning"  >
										<MuiTextAlert  >
											{t('login.v3.signUpFields.loginError')}
										</MuiTextAlert>
									</Alert>
								</Grid>}

								<Grid container item xs={12}>
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										onSubmit={submit}
									>
										{({ errors, isSubmitting }) => (
											<Form style={{ width: '100%' }} autoComplete='off'>
												<Grid
													xs={12}
													item
													container
													direction="column" >
													<Grid item xs={7}>
														<TextField
															className='login-input'
															name="email"
															label={t('forgot-password.yourEmail')}
															InputLabelProps={{ style: { color: theme.palette.text.primary, textAlign: 'left' } }}
															fullWidth
															InputProps={{
																autoComplete: 'email-wao',
															}}
														/>
													</Grid>

													<Grid item xs={12} pt={5}>
														<Button
															type="submit"
															variant="contained"
															fullWidth
															disabled={!isEmpty(errors) || isSubmitting}
														>
															{t('forgot-password.submit')}
														</Button>
													</Grid>
												</Grid>
											</Form>
										)}
									</Formik>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} container justifyContent='center'>
							<MuiFormText >{t('login.v3.dontHaveAccount')}</MuiFormText>
							<Link ml={1} onClick={handleRegister}>{t('login.v3.signup')}</Link>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</MinimalLayout>
	);
};

export default ForgotPassword;
