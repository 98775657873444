
import { SocketService } from '@services/socketService';
import { Subscription } from 'rxjs';
import { isEmpty } from 'lodash';
import { AppThunk } from '@/store';
import { getChatUnreadCount } from './slice';
import { getUnreadCount } from '../tickets/slice';

let socketService: SocketService;
let messsageSubscriber: Subscription;

export const connectChatUnreadCount = (): void => {
    messsageSubscriber && messsageSubscriber.unsubscribe();
    if (socketService) {
        unsubscribeChatUnreadCount();
    }
    socketService = new SocketService('chat');
};

export const subscribeChatUnreadCount = (updateNotifications?: boolean): AppThunk => {
    return async dispatch => {
        try {
            messsageSubscriber = socketService
                .listen('chat.message', {})
                .subscribe((data) => {
                    if (isEmpty(data)) {
                        return;
                    }

                    updateNotifications ?
                        dispatch(getUnreadCount()) :
                        dispatch(getChatUnreadCount());

                });


            socketService.send('data');
            updateNotifications ?
                dispatch(getUnreadCount()) :
                dispatch(getChatUnreadCount());

        } catch (error) {
            console.log(error);
        }
    };
};

export const unsubscribeChatUnreadCount = (): void => {
    messsageSubscriber && messsageSubscriber.unsubscribe();
};

export const disconnectChatUnreadCount = (): void => {
    socketService = null;
};
