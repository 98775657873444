import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { useMemo } from 'react';
import MuiTextCaption from '@shared/Widgets/MuiText/MuiTextCaption';
import NumberFormat from 'react-number-format';
import { getCurrencySign } from '@/helpers/currency';
import { Grid, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { normalizeString } from '@/helpers/normalizeString';
import { InvoiceStatus } from '@/features/invoices/invoicesTypes';


const InvoiceAmountStatusCell = (params: GridRenderCellParams) => {
    const theme = useTheme();
    const { t } = useTranslation('translations');
    const statusValue = t('invoice.status.' + params.row.status, normalizeString(params.row.status));

    const color = useMemo(() => {
        switch (params.row.status) {
            case InvoiceStatus.PAID:
            case InvoiceStatus.MANUALLY_PAID:
                return theme.statusColor.success;
            case InvoiceStatus.DRAFT:
                return theme.palette.text.secondary;
            case InvoiceStatus.CANCELLED:
                return theme.statusColor.lightRed;
            case InvoiceStatus.ISSUED:
                return theme.palette.info.main;
            case InvoiceStatus.PARTIALLY_PAID:
                return theme.statusColor.warning;
            default:
                return theme.palette.text.secondary;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.row.status]);

    return (
        <Grid container alignItems='flex-end' flexDirection='column'>
            <Grid item xs={12}>
                <MuiTextCaption fontWeight={500}>
                    <NumberFormat
                        displayType={'text'}
                        decimalScale={2}
                        fixedDecimalScale
                        thousandsGroupStyle='thousand'
                        thousandSeparator
                        prefix={getCurrencySign(params.row.currencyCode)}
                        value={params.row.totalAmount} />
                </MuiTextCaption>
            </Grid>
            <Grid item xs={12}>
                <MuiTextCaption fontWeight={400} color={color}>{statusValue}</MuiTextCaption>
            </Grid>
        </Grid>

    );
};

export default InvoiceAmountStatusCell;
