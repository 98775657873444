import * as React from 'react';
import { useEffect, useState } from 'react';

import { Alert, Dialog, FormLabel, Grid, IconButton, ListItemIcon, ListItemText, MenuList } from '@mui/material';
import StyledMenuItemModal from '@/componentsMui/Shared/Widgets/MenuItem/StyledMenuItemModal';
import { ReactComponent as MuiBigSync } from '@/assets/MuiBigSync.svg';
import { ReactComponent as MuiBigCard } from '@/assets/MuiBigCard.svg';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AddMoneyType } from '@/helpers/globalTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { openForm } from '@/features/forms/slice';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import { primetrustAccountDepositDetails } from '@/features/account/slice';
import { findProcessByType, hasSubprocessWithType } from '@/features/operations/slice';
import { AccountIntegration } from '@/features/account/types';
import StyledListItemIcon from '@/componentsMui/Shared/Widgets/StyledListItemIcon';
import { AccountType } from '@/features/operations/types';
import SepaTransfer from './SepaTransfer';
import BetweenAccountsSteps from '@/componentsMui/Shared/Components/SendMoney/BetweenAccounts/BetweenAccountsSteps';
import CardTransferDetails from '@/componentsMui/Shared/Components/SendMoney/CardTransfer/CardTransferDetails';
import '@/componentsMui/Shared/Shared.css';
import FormCloseButton from '@/componentsMui/Wallet/Balances/Deposit/Components/FormCloseButton';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import FormPaperLargeFixed from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeFixed';
import Spinner from '@shared/Widgets/Spinner/Spinner';
import PrimetrustTransfer from '@/componentsMui/Banking/Transfer/AddMoney/Primetrust/PrimetrustTransfer';

const getOwnTransferType = (currency: string) => {
	switch (currency) {
		case 'EUR':
			return 'sepa';
		case 'USD':
			return 'swift';
		case 'GBP':
			return 'chaps';
		default: return '';
	}
};

const AddMoneyForm = () => {
	const { t } = useTranslation('translations');
	const location = useLocation();

	const searchParams = new URLSearchParams(location.search);
	const [accountId] = useState<string>(searchParams.get('account'));

	const dispatch = useDispatch();

	const accounts = useSelector((state: RootState) => state.accounts.list);
	const trustAccounts = useSelector((state: RootState) => state.trust.accounts);
	const bankAccounts = accounts.concat(trustAccounts);
	const currentAccount = bankAccounts.find(a => a.accountId === parseInt(accountId));
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [depositInstructions, setDepositInstructions] = useState(null);

	const initStep = 0;

	const [addMoneyType, setAddMoneyType] = useState<AddMoneyType>(AddMoneyType.SEPA);

	const [step, setStep] = React.useState<number>(initStep);
	const nextStep = React.useCallback(() => setStep(previousState => (previousState + 1)), []);
	const prevStep = React.useCallback(() => setStep(previousState => (previousState - 1)), []);

	const closeModal = useCloseDialogNoEvent();

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);

	const toAccountList = [currentAccount];
	const walletAccountList = bankAccounts
		.filter(a => a.type === AccountType.WALLET && a.currency === currentAccount?.currency)
		.filter(accounts => hasSubprocessWithType('OWN_TRANSFER', accounts.subProcesses)) || [];

	const topUpProcess = currentAccount?.subProcesses ? findProcessByType(currentAccount, 'DEPOSIT_FROM_CARD') : undefined;
	const isOwnTransferEnabled = currentAccount?.subProcesses && hasSubprocessWithType('OWN_TRANSFER', currentAccount.subProcesses) && walletAccountList.length > 0;
	const isTopupByCardEnabled = !!topUpProcess;

	// const paymentProcessList = useMemo(() => findPaymentTypes(currentAccount).filter(p => supportedSystemType.includes(p.processProperties?.paymentType)) || [], [currentAccount]);

	/* 	const systemTypeIsAllowed = (paymentType: string) => {
			const allowedList = findPaymentTypes(currentAccount).filter(p => {
				return paymentType === p.processProperties?.paymentType;
			});
			return allowedList && allowedList.length > 0;
		}; */

	const isPrimetrustProcessEnabled = (type: AddMoneyType) => {
		let processEnabled = false;
		if (type === AddMoneyType.WIRE) {
			processEnabled = !!depositInstructions?.wireInstructions;
		} else if (type === AddMoneyType.WIRE_INTL) {
			processEnabled = !!depositInstructions?.wireInstructionsIntl;
		} else if (type === AddMoneyType.WIRE_ALT) {
			processEnabled = !!depositInstructions?.altCurrencyWireInstructions[currentAccount?.currency?.toLowerCase()];
		}
		return processEnabled;
	};

	const integration = currentAccount?.integration;

	const ownTransferType = getOwnTransferType(currentAccount?.currency);

	useEffect(() => {
		const get = async () => {
			try {
				setLoading(true);
				setError(false);
				const details = await primetrustAccountDepositDetails(currentAccount.accountId);
				setDepositInstructions(details);
			}
			catch (e) {
				setError(true);
			}
			finally {
				setLoading(false);
			}

		};
		if (currentAccount && currentAccount?.integration === AccountIntegration.PRIMETRUST) {
			get();
		}

	}, [currentAccount, currentAccount?.integration]);

	if (loading) {
		return (
			<Dialog
				id="sendMoneyForm"
				open={true}
				PaperComponent={FormPaperLargeFixed}
			>
				<>
					<Grid item container justifyContent='space-between'>
						<Grid item>
							<MuiHeader> {t('banking.addMoneyForm.title')}
							</MuiHeader>
						</Grid>
						<Grid item>
							<IconButton onClick={closeModal}  >
								<MuiCloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					{error && <Grid item>
						<Alert severity="warning"  >
							<MuiFormText >{t('errors.SOMETHING_WENT_WRONG_WITHOT_MESSAGE')}</MuiFormText >
						</Alert>
					</Grid>
					}
					<Grid item>
						<Grid item xs={12} mt='10px' >
							<Spinner />
						</Grid>
					</Grid>
					<FormCloseButton onClose={closeModal} />
				</>
			</Dialog >
		);
	}

	return (
		<Dialog
			id="sendMoneyForm"
			open={true}
			PaperComponent={FormPaperLargeFixed}
		>
			{step === 0 &&
				<>
					<Grid item container justifyContent='space-between'>
						<Grid item>
							<MuiHeader> {t('banking.addMoneyForm.title')}
							</MuiHeader>
						</Grid>
						<Grid item>
							<IconButton onClick={closeModal}  >
								<MuiCloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item >
						<MuiFormText> {t('banking.addMoneyForm.subTitle')}</MuiFormText>
					</Grid>
					<Grid item>
						<MenuList role='menu' className='form-menu__list'>
							{integration === AccountIntegration.PRIMETRUST ?
								<>
									{isPrimetrustProcessEnabled(AddMoneyType.WIRE) && <StyledMenuItemModal
										unsetMarginBottom={true}
										id={'payment'}
										onClick={() => { setStep(1); setAddMoneyType(AddMoneyType.WIRE); }}
									> 	<ListItemIcon sx={{ mr: '1.25rem' }}> <img className='img-icon-size' alt='currency logo' src={currentAccount?.currencyLogo} /> </ListItemIcon>
										<ListItemText className='money-form-list-item-text'>{t('wallet.deposit.wire')}</ListItemText>
									</StyledMenuItemModal>}
									{isPrimetrustProcessEnabled(AddMoneyType.WIRE_INTL) && <StyledMenuItemModal
										unsetMarginBottom={true}
										id={'payment'}
										onClick={() => { setStep(1); setAddMoneyType(AddMoneyType.WIRE_INTL); }}
									> 	<ListItemIcon sx={{ mr: '1.25rem' }}> <img className='img-icon-size' alt='currency logo' src={currentAccount?.currencyLogo} /> </ListItemIcon>
										<ListItemText className='money-form-list-item-text'>{t('wallet.deposit.wireIntl')}</ListItemText>
									</StyledMenuItemModal>}
									{isPrimetrustProcessEnabled(AddMoneyType.WIRE_ALT) && <StyledMenuItemModal
										unsetMarginBottom={true}
										id={'payment'}
										onClick={() => { setStep(1); setAddMoneyType(AddMoneyType.WIRE_ALT); }}
									> 	<ListItemIcon sx={{ mr: '1.25rem' }}> <img className='img-icon-size' alt='currency logo' src={currentAccount?.currencyLogo} /> </ListItemIcon>
										<ListItemText className='money-form-list-item-text'>{t('wallet.deposit.wireAlt')}</ListItemText>
									</StyledMenuItemModal>}
								</>
								:
								<>
									<StyledMenuItemModal
										unsetMarginBottom={true}
										id={'payment'}
										onClick={() => { setStep(1); setAddMoneyType(AddMoneyType.SEPA); }}
									> 	<ListItemIcon sx={{ mr: '1.25rem' }}> <img className='img-icon-size' alt='currency logo' src={currentAccount?.currencyLogo} /> </ListItemIcon>
										<ListItemText className='money-form-list-item-text'>{t('wallet.deposit.' + ownTransferType)}</ListItemText>
									</StyledMenuItemModal>
									{/* {systemTypeIsAllowed(SystemType.SEPA_INSTANT) && <StyledMenuItemModal
										unsetMarginBottom={true}
										id={'payment'}
										onClick={() => { setStep(1); setAddMoneyType(AddMoneyType.SEPA_INSTANT); }}
									> 	<ListItemIcon sx={{ mr: '1.25rem' }}> <img className='img-icon-size' alt='currency logo' src={currentAccount?.currencyLogo} /> </ListItemIcon>
										<ListItemText className='money-form-list-item-text'>{t('wallet.deposit.sepa_instant')}</ListItemText>
									</StyledMenuItemModal>} */}
								</>
							}
							{isTopupByCardEnabled &&
								<StyledMenuItemModal
									unsetMarginBottom={true}
									id={'card'}
									onClick={() => { setStep(1); setAddMoneyType(AddMoneyType.CARD); }}
								> 	<StyledListItemIcon>  <MuiBigSync />  </StyledListItemIcon>
									<Grid>
										<ListItemText className='money-form-list-item-text'>{t('banking.addMoney.card')} ({currentAccount?.currency})</ListItemText>
										{topUpProcess?.config?.supportedCardProviders &&
											<FormLabel> {topUpProcess?.config?.supportedCardProviders || ''}</FormLabel>}
									</Grid>
								</StyledMenuItemModal>
							}
							{isOwnTransferEnabled &&
								<StyledMenuItemModal
									unsetMarginBottom={true}
									key={'ownAccount'}
									onClick={() => { setStep(1); setAddMoneyType(AddMoneyType.OWN); }}
								>
									<StyledListItemIcon> <MuiBigCard /> </StyledListItemIcon>
									<ListItemText className='money-form-list-item-text'>{t('banking.addMoney.transfer')} ({currentAccount?.currency})</ListItemText>
								</StyledMenuItemModal>
							}
						</MenuList>
					</Grid>
					<FormCloseButton onClose={closeModal} />
				</>

			}


			{addMoneyType === AddMoneyType.OWN && step !== 0 &&
				<BetweenAccountsSteps
					step={step}
					fromAccountList={walletAccountList}
					fromDefaultAccount={walletAccountList[0]}
					toAccountList={toAccountList}
					toDefaultAccount={currentAccount}
					nextStep={nextStep}
					prevStep={prevStep}
				/>
			}
			{addMoneyType === AddMoneyType.SEPA && step !== 0 &&
				<SepaTransfer
					account={currentAccount}
					type={ownTransferType}
					prevStep={prevStep}
				/>
			}
			{/* {addMoneyType === AddMoneyType.SEPA_INSTANT && step !== 0 &&
				<SepaTransfer
					account={currentAccount}
					type='sepa_instant'
					prevStep={prevStep}
				/>
			} */}
			{addMoneyType === AddMoneyType.CARD && step !== 0 &&
				<CardTransferDetails
					toDefaultAccount={currentAccount}
					title={t('banking.addMoney.card')}
					toAccountList={[currentAccount]}
					prevStep={prevStep}
				/>
			}
			{step !== 0 && (
				addMoneyType === AddMoneyType.WIRE
				|| addMoneyType === AddMoneyType.WIRE_INTL
				|| addMoneyType === AddMoneyType.WIRE_ALT
			) &&
				<PrimetrustTransfer
					account={currentAccount}
					depositDetails={depositInstructions}
					type={addMoneyType}
					prevStep={prevStep}
				/>
			}
		</Dialog >
	);
};
export default AddMoneyForm;

