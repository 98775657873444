import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setLandingTitle } from '@/features/landingStep/slice';
import MinimalLayout from '../Layout/MinimalLayout';
import { Button, FormHelperText, Grid } from '@mui/material';
import MobileHeader from './components/MobileHeader';
import MuiFormText from '../Shared/Widgets/MuiText/MuiFormText';
import { showException } from '@/features/swal/slice';
import ComuneSelect from './components/ComuneSelect';
import BackButtonWebLanding from './components/BackButtonWebLanding';
import { KycAddressPayload, KycAddressTypeEnum } from '@/features/kyc/types';
import { createKycAddress, getKycAddressList } from '@/features/kyc/slice';
import { RootState } from '@/rootReducer';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useComune } from '@/helpers/customHook/useValidations';
import { isEmpty } from 'lodash';
import CountryCodesAlpha3 from '../Shared/FormikComponents/CountryCodesAlpha3';

interface Props {
    paddingTop?: number,
    next: () => void,
    goBack: () => void,
}

const ProfileDetailsPlaceOfBirth = ({ paddingTop, next, goBack }: Props): React.ReactElement => {

    const dispatch = useDispatch();
    const { t } = useTranslation('translations');
    const title = t('landing.profileDetailsOam.placeOfBirth');

    useEffect(() => {
        dispatch(setLandingTitle(title));
    }, [dispatch, title]);

    const { comunes } = useSelector((state: RootState) => state.italyTerritory);


    const submit = async (formData) => {
        try {
            const province = comunes.find(p => p.codiceCatastale === formData.comune)?.siglaAutomobilistica || undefined;
            const payload: KycAddressPayload = {
                addressType: KycAddressTypeEnum.PLACE_OF_BIRTH,
                country: formData.country,
                municipality: formData.country === 'ITA' ? formData.comune : undefined,
                city: formData.country === 'ITA' ? undefined : formData.comune,
                province
            };

            await createKycAddress(payload);
            await dispatch(getKycAddressList());
            next();
        } catch (e) {
            showException(e);
        }
    };


    const handleCountryChange = (setFieldValue, setFieldTouched) => {
        setFieldValue('comune', '');
        setTimeout(() => setFieldTouched('comune', true));
    };

    const comuneValidation = useComune();
    const validationSchema = Yup.object({
        country: Yup.string().required(t('form.validator.required')),
        comune: comuneValidation,
    });

    const initialValues = {
        country: '',
        comune: '',
    };

    return (
        <MinimalLayout
            paddingTop={paddingTop}
            allowGoHome={false}
            leftPanel={<BackButtonWebLanding onClick={goBack} />}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submit}
            >{({ values, errors, setFieldValue, setFieldTouched, isSubmitting }) => {
                return (
                    <Form style={{ width: '100%' }}>
                        <Grid container  >
                            <Grid item >
                                <MobileHeader text={title} />
                            </Grid>
                            <Grid item xs={12} mb={{ xs: '20px', sm: '40px' }} >
                                <MuiFormText>
                                    {t('landing.profileDetailsOam.placeOfBirthDetails')}
                                </MuiFormText>
                            </Grid>
                            <Grid item xs={12} container >
                                <Grid item xs={12}>
                                    <CountryCodesAlpha3
                                        fieldName='country'
                                        fieldlabel={t('landing.profileDetailsOam.countryOfBirth')}
                                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        onChange={(value) => handleCountryChange(setFieldValue, setFieldTouched)} />

                                </Grid>
                                <Grid item xs={12}>
                                    <ComuneSelect
                                        countryIso3={values.country}
                                        value={values.comune}
                                        labelComune={t('landing.profileDetailsOam.comuneOfBirth')}
                                        labelCity={t('landing.profileDetailsOam.cityOfBirth')}
                                        onChange={(value) => setFieldValue('comune', value)} />
                                    {errors?.comune && <FormHelperText > {errors.comune} </FormHelperText>}
                                </Grid>

                                <Grid item xs={12} mt={'50px'} >
                                    <Button
                                        variant='contained'
                                        type="submit"
                                        fullWidth
                                        disabled={isSubmitting || !isEmpty(errors)}
                                    >
                                        {t('form.buttons.next')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>);
            }}
            </Formik>
        </MinimalLayout >);
};

export default ProfileDetailsPlaceOfBirth;
