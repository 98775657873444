import * as React from 'react';
import { BoxProps, Box } from '@mui/material';
import { FC } from 'react';
import {ReactComponent as MuiGoogleStore} from '@assets/MuiGoogleStore.svg';

/* Placed on base background*/
const BaseBox: FC<BoxProps> = (props: BoxProps) => {

	const { sx } = props;

	return <Box className='google-auth-download' sx={{
		...sx,
		width: '100%',
		height: '38px',
		background: '#e9eaea',
		borderRadius: '4px',
		padding: '5px',
	}}>
		<MuiGoogleStore style={{margin: 'auto', display: 'block'}} />
	</Box>;
};


export default BaseBox;

