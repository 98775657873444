import { Dialog, Grid, IconButton } from '@mui/material';
import React, { useCallback, useRef } from 'react';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import './ReChallange.css';
import { rederChallenge } from '@/helpers/recaptureChallengeUtils';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';

interface Props {
    open: boolean,
    onClose: (value: string) => void
}

const ReChallange = ({ open, onClose }: Props) => {

    const [refVisible, setRefVisible] = React.useState(false);
    const { grcsk } = useSelector(
		(state: RootState) => state.status
	);

    const ref = useRef(null);
    const captchaRef = useRef(null);
    const handleClose = () => {
        const token = captchaRef?.current?.getValue() || null;
        onClose(token);
    };
    const onChange = useCallback((value) => {
        if (value) {
            onClose(value);
        }
    }, [onClose]); 

    React.useEffect(() => {
        if (open && refVisible) { 

            rederChallenge(onChange, grcsk);
        }
   // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [open, refVisible, onChange]);


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth='xs'
        >

            <Grid ref={el => { ref.current = el; setRefVisible(!!el); }} container height='20vh'>
                <Grid item xs={12}>
                    <IconButton onClick={handleClose} className='closeIcon' >
                        <MuiCloseIcon />
                    </IconButton>
                </Grid>

                <Grid item xs={12} container justifyContent='center'>
                    <div id='recaptcha-challenge-id'></div>
                </Grid>
            </Grid>


        </Dialog>
    );
};

export default ReChallange;
