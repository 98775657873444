import * as React from 'react';
import '@/componentsMui/Dashboard/Create/Create.css';
import { Dialog } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Operations } from '@/features/operations/types';
import { useEffect, useState } from 'react';
import { RootState } from '@/rootReducer';
import useCloseDialog from '@/helpers/customHook/useCloseDialog';
import { CardType, PotentialAddress } from '@/features/card/types';
import PickCardStep from './Steps/PickCardStep';
import CardDetails from './Steps/CardDetails';
import CardConfirmation from './Steps/CardConfirmation';
import { Address } from '@/features/card/types';
import { openForm } from '@/features/forms/slice';
import { getFeesByCategory } from '@/features/fees/slice';
import { FeesFimitsType } from '@/features/fees/types';
import usePayAccounts from '@/helpers/customHook/accounts/usePayAccounts';
import { BankAccount } from '@/features/account/types';
import CardAddress from './Steps/CardAddress';
import FormPaperLargeScrollable from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeScrollable';
import { getCardShipmentCountry } from '@/features/countries/slice';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CardOrderFees, getCardOrderFees, isReplaceable } from '@/componentsMui/Banking/utils';

enum Step {
	PICK = 'PICK', DETAILS = 'DETAILS', ADDRESS = 'ADDRESS', CONFIRMATION = 'CONFIRMATION'
}


const ReplaceCardForm = () => {
	const closeModal = useCloseDialog();
	const dispatch = useDispatch();
	const location = useLocation();
	const { t } = useTranslation('translations');
	const searchParams = new URLSearchParams(location.search);
	const [cardId] = useState<string>(searchParams.get('card'));


	const [deliveryAddress, setDeliveryAddress] = useState<Address>(null);
	const [potentialAddresses, setPotentialAddresses] = useState<Array<PotentialAddress>>([]);
	const { list: cardList } = useSelector((state: RootState) => state.cards);

	const [fees, setFees] = useState<FeesFimitsType[]>(null);

	const cardToReplace = cardId ? cardList.find(p => `${p.cardId}` === `${cardId}`) : undefined;
	const isReplaceEnabled = isReplaceable(cardToReplace);

	const isVirtual = cardToReplace?.process?.processProperties?.virtual;
	const [selectedOption, setSelectedOption] = useState<any>(cardToReplace?.process);
	const [step, setStep] = React.useState<Step>(Step.PICK);

	const cardCurrency = selectedOption?.processProperties?.ccy;
	const { accountList: paymentAccounts } = usePayAccounts(cardCurrency);


	const [selectedAccount, setSelectedAccount] = useState<BankAccount>(paymentAccounts[0]);

	const cardOrderFees: CardOrderFees = getCardOrderFees(selectedOption?.proc, fees, isReplaceEnabled);

	useEffect(() => {
		if (potentialAddresses.length > 0) {
			setStep(Step.ADDRESS);
		}
	}, [potentialAddresses]);



	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);

	useEffect(() => {
		dispatch(getCardShipmentCountry());
	}, [dispatch]);



	useEffect(() => {
		const get = async () => {
			try {
				const response = await getFeesByCategory(Operations.CREATE_CARD);
				setFees(response[0].fees);
				//setFees(response);
			} catch (e) {
				console.log('failed to fetch fees', e);
			}
		};
		get();
	}, []);

	if (!cardToReplace || !isReplaceEnabled) return <>{t('cards.replace.notPossible')}</>;

	return (
		<Dialog
			id="create-card-form"
			open={true}
			onClose={closeModal}
			PaperComponent={FormPaperLargeScrollable}
		>

			{[
				step === Step.PICK &&
				<PickCardStep
					key="pick"
					cardOrderFees={cardOrderFees}
					options={[selectedOption]}
					selectedOption={selectedOption}
					setSelectedOption={setSelectedOption}
					nextStep={() => setStep(isVirtual ? Step.CONFIRMATION : Step.DETAILS)}
					closeModal={closeModal}
				/>,
				step === Step.DETAILS &&
				<CardDetails
					key="card-details"
					option={selectedOption}
					montlyFee={cardOrderFees.montlyFeeAmount}
					creationFee={cardOrderFees.creationFeeAmount}
					activeAccount={selectedAccount}
					deliveryAddress={deliveryAddress}
					accountList={paymentAccounts}
					confirmAddress={() => setStep(Step.ADDRESS)}
					setDeliveryAddress={setDeliveryAddress}
					setPotentialAddresses={setPotentialAddresses}
					onAccountChange={(newAccount: BankAccount) => setSelectedAccount(newAccount)}
					prevStep={() => setStep(Step.PICK)}
					nextStep={() => setStep(isVirtual ? undefined : Step.CONFIRMATION)}
				/>,
				step === Step.ADDRESS &&
				<CardAddress
					key="card-address"
					potentialAddresses={potentialAddresses}
					deliveryAddress={deliveryAddress}
					title={selectedOption?.name}
					integration={selectedOption?.integration || ''}
					setDeliveryAddress={setDeliveryAddress}
					onPrev={() => setStep(Step.DETAILS)}
					onNext={() => setStep(Step.CONFIRMATION)} />,
				step === Step.CONFIRMATION &&
				<CardConfirmation
					key="card-confirm"
					activeAccount={selectedAccount}
					accountList={paymentAccounts}
					cardType={isVirtual ? CardType.VIRTUAL : CardType.PHYSICAL}
					deliveryAddress={deliveryAddress}
					option={selectedOption}
					montlyFee={cardOrderFees.montlyFeeAmount}
					creationFee={cardOrderFees.creationFeeAmount}
					onAccountChange={(newAccount: BankAccount) => setSelectedAccount(newAccount)}
					replacedCardId={cardToReplace?.cardId}
					prevStep={() => setStep(isVirtual ? Step.PICK : Step.DETAILS)}
				/>]
			}

		</Dialog >
	);
};

export default ReplaceCardForm;

