export interface Fee {
	type: string,
	paymentType: string,
	processType: string,
	feeAmount: number,
	feeMonth: string,
	minFee?: number,
	feePercent?: number,
	ccy: string
}

export interface Process {
	name: string,
	type: string,
	proc: string,
	integration: string
	providerType: string,
	processProperties: {
		ccy?: string,
		isVirtual?: boolean,
		virtual?: boolean
	},
	config: {
		serviceProviderLogo?: string,
		logoTextColor?: string
		CardMaxCount?: number,
		validateAddress?: boolean
	}
	subProcesses: any,
	fees: Fee[],
	maintenace: boolean,
}

export interface FixedFee {
	feeValue: number,
	currency: string,
	currencySymbol?: string,
}

export enum FeeType {
	FIXED = 'FIXED',
	FIXED_MONTH = 'FIXED_MONTH',
	VOLUME = 'VOLUME',
	PAYMENT = 'PAYMENT'
}


export interface TransferFeePayloadType {
	accountId: number,
	proc: string,
	amount: number
}


export interface FeeInfo {
	fee: number,
	feeString: string,
}

export interface FeesFimitsType {
	type: string;
	integration: string;
	limits: LimitDetails[];
	fees: {
		[key: string]: {
			[key: string]: FeeDetails[]
		};
	},
	cards?: {
		[key: string]: CardDetails
	},
	tokens?: TokenDetails[]
}

export interface FeeDetails {
	fee: string;
	type: FeeType;
	proc: string;
	ccy: string;
	feeAmount: number;
	feeMonth: number;
	minFee: number;
	feePercent: number;
	paymentType?: string;
}

export interface LimitDetails {
	name: string;
	ccy: FeeType;
	currencySymbol: string;
	daily: number;
	monthly: number;
	annual: number;
	minAmount: number;
	maxAmount: number;
	actualDaily: number;
	actualMonthly: number;
	actualAnnual: number;
	availableBalanceNow: number;
	maxSingleLoad?: number,
	minSingleLoad?: number,
	maxDailyLoad?: number,
	maxDailyLoadCount?: number,
	maxMonthlyLoad?: number,
	maxSpendPerMonth?: number,
	maxPurchasePerTransaction?: number,
	maxAtmPerDay?: number,
	maxAtmPerDayCount?: number,
	maxAtmPerMonth?: number,
	maxAtmPerMonthCount?: number,
}


export interface CardDetails {
	logoTextColor: string,
	serviceProviderLogo: string,
	cardName: string
}

export interface TokenDetails {
	symbol: string,
	name: string,
	description: string,
	currency: string,
	logo: string
}

