
import { PeriodEnum } from '@/features/account/types';
import { Theme, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

interface Props {
    fontSize?: string,
    totalDiff: number,
    totalDiffPerc: number,
    period?: PeriodEnum
}

const AmountDifference = ({ fontSize, totalDiff, totalDiffPerc, period }: Props) => {
    const theme = useTheme() as Theme;
    const { t } = useTranslation('translations');

    return (<><Typography
        fontSize={fontSize ? fontSize : '0.688rem'}
        color={totalDiff >= 0 ? theme.statusColor.success : theme.statusColor.error}>
        {<NumberFormat
            displayType={'text'}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandsGroupStyle='thousand'
            thousandSeparator={true}
            value={Math.abs(totalDiffPerc)}
            prefix={`${totalDiffPerc < 0 ? '-' : '+'}`}
        />}%
        <NumberFormat
            displayType={'text'}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandsGroupStyle='thousand'
            thousandSeparator={true}
            value={Math.abs(totalDiff)}
            prefix={` (${totalDiff < 0 ? '-' : '+'}`}
            suffix=' EUR)' />
    </Typography>
        {period && <Typography fontSize={fontSize ? fontSize : '0.688rem'}>
            {t('form.fields.pastPeriod.' + period)}
        </Typography>}
    </>);
};

export default AmountDifference;
