import './AppBarMain.css';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { AppBar, Box, Container, Grid, Theme, Toolbar, Typography, IconButton } from '@mui/material';
import LogoThemed from '@/componentsMui/Shared/Widgets/LogoThemed';
import { useTheme } from '@mui/styles';
import LanguageDropdown from '../LanguageDropdown/LanguageDropdown';
import LineStepper from '../Shared/Widgets/LineStepper';
import MenuIcon from '@mui/icons-material/Menu';
import { openDrawer } from '@features/drawer/slice';
import useBasicAppBarSize from '@/helpers/customHook/useBasicAppBarSize';

interface Props {
	authorized: boolean,
	splitColors?: boolean,
	justifyTitle?: string,
	onLogoClick?: () => void
}

const AppBarBasic = ({ authorized, splitColors, justifyTitle, onLogoClick }: Props): React.ReactElement => {
	const theme = useTheme() as Theme;
	const appBarH = useBasicAppBarSize();

	const dispatch = useDispatch();
	const { stepCount, step, title } = useSelector((state: RootState) => state.landingStep);

	const handleOpenDrawer = () => {
		dispatch(openDrawer());
	};
	return (
		<AppBar
			id="portal-app-bar-id"
			position="fixed"
			sx={{ background: theme.palette.background.default }}
		>
			<Container disableGutters={true}>
				<Toolbar disableGutters sx={{ minHeight: appBarH + 'px!important' }} >
					<Grid container sx={{ minHeight: appBarH + 'px' }} >
						<Grid container sx={{ display: { xs: authorized ? 'none' : 'flex', md: 'flex' } }} alignItems='center'>
							<Grid item xs={6} md={title ? 3 : 6}
								container
								justifyContent='flex-start'
								alignContent='center'
								pl={{ xs: '10px', md: '40px' }} >
								<LogoThemed className='login-logo-small' onClick={onLogoClick} />
							</Grid>
							{title &&
								<Grid item xs='auto' md={6} container
									justifyContent={justifyTitle ? justifyTitle : 'center'}
									alignContent='center'
									sx={{ display: { xs: 'none', md: 'flex' } }}>
									<Typography fontSize='1.5rem' fontWeight={700}>{title}</Typography>
								</Grid>
							}
							<Grid item xs={6} md={title ? 3 : 6} container
								justifyContent='flex-end'
								alignContent='center'
								pr={{ xs: '0px', md: '30px' }}
								height='100%'
								sx={{ bgcolor: splitColors ? '#1D2024' : 'inherit' }} >
								<Box className='lang-drop-box app-bar-icon-size'>
									<LanguageDropdown authorized={authorized} />
								</Box>
							</Grid>
						</Grid>
						<Grid container sx={{ display: { xs: authorized ? 'flex' : 'none', md: 'none' } }}>
							<Grid item xs={9} display='flex' alignItems='center'>
								<LogoThemed className='login-logo-small' onClick={onLogoClick} />
							</Grid>
							<Grid item xs={3} container justifyContent='flex-end'>
								<Box>
									<IconButton
										size="large"
										aria-label="account of current user"
										aria-controls="menu-appbar"
										aria-haspopup="true"
										onClick={handleOpenDrawer}
										color="inherit"
									>
										<MenuIcon />
									</IconButton>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Toolbar>
			</Container>
			{stepCount > 0 && <LineStepper stepCount={stepCount} activeStep={step.stepOrder} />}
		</AppBar>
	);
};
export default AppBarBasic;
