import React, { useEffect, useState } from 'react';
import { Alert, Button, Dialog, Grid, InputLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { openForm } from '@/features/forms/slice';
import { useTranslation } from 'react-i18next';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { Form, Formik } from 'formik';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import Dropdown from '@/componentsMui/Shared/FormikComponents/Dropdown';
import { RootState } from '@/rootReducer';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import TextFieldHighlight from '@/componentsMui/Shared/Widgets/TextFieldHighlight';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import { muiShowSuccess, showException } from '@/features/swal/slice';
import AssetDropdown from '@/componentsMui/Shared/FormikComponents/AssetDropdown';
import { getCoins, transformTokensToAssets, validateWithdrawAddress } from '@/features/coins/slice';
import { runOperation } from '@/features/operations/slice';
import useCoinWithdrawalFeeDebounce from '@/helpers/customHook/useCoinWithdrawalFeeDebounce';
import FormHeader from '../Deposit/Components/FormHeader';
import ConfirmationBox from '@/componentsMui/Shared/Components/ConfirmationBox';
import MuiTextConfirmationLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextConfirmationLabel';
import MuiTextConfirmation from '@/componentsMui/Shared/Widgets/MuiText/MuiFormField';
import NumberFormat from 'react-number-format';
import MuiCopyToClipboard from '@/componentsMui/Shared/Widgets/MuiCopyToClipboard';
import bigDecimal from 'js-big-decimal';
import { AssetType } from '@/features/coins/types';
import FormPaperLargeFixed from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeFixed';
import MuiFormText from '@shared/Widgets/MuiText/MuiFormText';
import { WalletType } from '@features/walletHistory/types';
import { getTokens } from '@features/tokens/slice';
import MuiFormHelperText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormHelperText';
import { NEW_LINE_REGEX } from '@/helpers/customHook/useValidations';


enum WithdrawStep {
	FORM = 'FORM', CONFIRMATION = 'CONFIRMATION'
}

interface Props {
	walletType: WalletType,
}

const WithdrawForm = ({ walletType }: Props): React.ReactElement => {

	// Add user limits and validation for the amount
	// Add the limits messages from the withdrawal

	const closeModal = useCloseDialogNoEvent();
	const dispatch = useDispatch();
	const searchParams = new URLSearchParams(location.search);
	const [requestedToken] = React.useState<string>(searchParams.get('token'));

	const { t } = useTranslation('translations');
	const [step, setStep] = useState<WithdrawStep>(WithdrawStep.FORM);
	const [payload, setPayload] = useState<{ fee: number, body: any, selectedAsset: AssetType }>(null);

	const tokens = useSelector((state: RootState) => state.tokens.list);
	const crypto = useSelector((state: RootState) => state.coins.list);

	const coins = walletType === WalletType.TOKEN ? tokens : crypto;

	const tokensOwned = coins.filter(p => p.type !== 'WALLET' && bigDecimal.compareTo(p.account?.balance, 0) > 0);

	const defaultToken = tokensOwned.find(p => p.type.concat(p.info.symbol) === requestedToken);
	const defaultAsset = defaultToken ? transformTokensToAssets([defaultToken]) : undefined;

	const [selectedAsset, setSelectedAsset] = React.useState<AssetType>(defaultAsset?.length > 0 ? defaultAsset[0] : undefined);

	const networkOptions = selectedAsset?.networks?.map((item) => { return { key: item, value: item }; }) || [];
	const coinAssets = transformTokensToAssets(tokensOwned);
	const [loading, setLoading] = useState<boolean>(false);

	const { payInfo, initPayInfo, callGetPayInfo, setPayinfo, payInfoLoading } = useCoinWithdrawalFeeDebounce();

	const handleAssetChange = (value: AssetType) => {
		setSelectedAsset(value);
	};

	const handleCloseModal = () => {
		setStep(WithdrawStep.FORM);
		closeModal();
	};

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);

	const initialValues = {
		userToken: selectedAsset?.id ?? '',
		address: '',
		network: '',
		amount: '',
		name: '',
		reference: ''
	};

	const rawCurrentHoldings = selectedAsset?.availableBalance ?? '0';

	const validationSchema = Yup.object({
		network: Yup.string().required(t('form.validator.required')),
		address: Yup.string().required(t('form.validator.required')),
		amount: Yup.number().typeError(t('form.validator.required')).label(t('tokens.header.withdrawalAmount'))
			.required(t('form.validator.required'))
			.moreThan(0, t('sendmoneyTranslation.data.moreThanZero'))
			.min(0.00001)
			.test('amount', t('tokens.header.maxTokens', { amount: rawCurrentHoldings }), (value) => bigDecimal.compareTo(rawCurrentHoldings, value + payInfo.calculatedFee) >= 0),
		name: Yup.string().required(t('form.validator.required')),
		reference: Yup.string().trim().required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-./\s])+$/, t('form.validator.latinOnlyReference'))
			.min(6, t('form.validator.minChars', { field: t('sendmoneyTranslation.data.reference'), chars: '6' }))
			.max(140, t('sendmoneyTranslation.data.canNotExceedHundred'))
			.test('reference', t('form.validator.newLine'), (value) => value ? value.search(NEW_LINE_REGEX) === -1 : true)
			.test('reference', t('form.validator.minChars', { field: t('sendmoneyTranslation.data.reference'), chars: '6' }), (value) => value ? value.replace(/ /g, '').length >= 6 : true),
	});

	const handleAmountChange = (event, setFieldValue) => {
		const newAmount = event.target.value as number;
		if (newAmount && newAmount > 0) {
			callGetPayInfo({ fromAmount: newAmount, fromAccountId: selectedAsset.accountId, proc: selectedAsset.paymentProc });
		} else {
			setPayinfo(initPayInfo);
		}
		setFieldValue('amount', newAmount);
	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting, setFieldError } = formikProps;
		const { amount, address, network, name, reference } = formData;

		try {
			await validateWithdrawAddress(address, network);
		} catch (err) {
			setFieldError('address', t('form.validator.invalidAddress'));
			return;
		}

		const body = {
			accountId: selectedAsset.accountId,
			amount: amount,
			ccy: selectedAsset.symbol,
			beneficiaryCryptoAddress: address,
			beneficiaryCryptoNetwork: network,
			beneficiaryName: name,
			toBusiness: false,
			saveToContacts: false,
			feeInAmount: true,
			details: reference
		};

		setPayload({ fee: payInfo.calculatedFee, body, selectedAsset });
		setSubmitting(false);
		setStep(WithdrawStep.CONFIRMATION);
	};


	const handleConfirm = async () => {
		try {
			setLoading(true);
			await runOperation(selectedAsset.paymentProc, payload.body);
			setLoading(false);
			closeModal();
			if (walletType === WalletType.TOKEN) {
				dispatch(getTokens());
			} else {
				dispatch(getCoins());
			}
			muiShowSuccess('wallet.withdraw.withdrawConfirmation.registered', selectedAsset.symbol + ' ' + payload?.body.amount, t('wallet.withdraw.withdrawConfirmation.registeredText'));
		}
		catch (e) {
			setLoading(false);
			closeModal();
			showException(e);
		}

	};


	return (
		<Dialog
			id="withdraw-form"
			open={true}
			onClose={handleCloseModal}
			PaperComponent={FormPaperLargeFixed}
		>
			<>
				{step === WithdrawStep.FORM && <><Grid container justifyContent='space-between'>
					<Grid item>
						<FormHeader title={t('wallet.withdraw.withdraw')} showBack={false} mb='1rem' />
					</Grid>
				</Grid>
					<Grid container flex={1} direction='column' >
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={submit}
						>
							{({ errors, isSubmitting, setFieldValue }) => (
								<Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
									<Grid container spacing={1} >
										<Grid item xs={12}>
											<AssetDropdown
												fieldName='userToken'
												fieldlabel={t('tokens.withdrawal.token')}
												assetList={coinAssets}
												selectedAsset={selectedAsset}
												onAssetChange={(e) => { handleAssetChange(e); setFieldValue('network', ''); setFieldValue('amount', ''); }} />
										</Grid>
										{selectedAsset && selectedAsset.symbol === 'UMW'
											? <Grid item xs={12} mt={3}>
												<Alert severity="warning">
													<MuiFormText>{t('wallet.withdraw.cannotWithdraw')}</MuiFormText >
												</Alert>
											</Grid>
											: <>
												<Grid item xs={12}>
													<MuiTextField
														name="name"
														label={t('tokens.withdrawal.beneName')}
														fullWidth />
												</Grid>
												<Grid item xs={12}>
													<MuiTextField
														name="address"
														label={t('tokens.header.withdrawalAddress')}
														fullWidth />
												</Grid>
												<Grid item xs={12}>
													<Dropdown
														options={networkOptions}
														fieldLabel={t('tokens.header.network')}
														fieldName='network' />
												</Grid>
												<Grid item xs={12}>
													<TextFieldHighlight
														inputProps={{ autoComplete: 'off' }}
														name="amount"
														label={!selectedAsset ? t('tokens.header.withdrawalAmount') :
															<Grid container justifyContent='space-between'>
																<Grid item xs={6}><InputLabel shrink={false}>{t('tokens.header.withdrawalAmount')}</InputLabel></Grid>
																<Grid item xs={6} display='flex' justifyContent='flex-end'><InputLabel>{t('tokens.withdrawal.balance')}</InputLabel><MuiFormHelperText>{new bigDecimal(selectedAsset.availableBalance).round(selectedAsset.precision, bigDecimal.RoundingModes.DOWN).getValue()}</MuiFormHelperText></Grid>
															</Grid>
														}
														type="number"
														placeholder={'0.00 ' + (selectedAsset?.symbol ?? '')}
														fullWidth
														onChange={(e) => handleAmountChange(e, setFieldValue)}
													/>
												</Grid>
												<Grid item xs={12}>
													<MuiTextField
														name="reference"
														multiline
														fullWidth
														inputProps={{ autoComplete: 'off', maxLength: 140 }}
														label={t('sendmoneyTranslation.data.reference')}
														rows={2} />
												</Grid>
												<Grid item xs={12} mt='1.6rem'>
													<StyledDivider />
												</Grid>
												<Grid container item xs={12} justifyContent='space-between'>
													<Grid item>
														<MuiHeader>{t('tokens.withdrawal.estimatedFee')}</MuiHeader>
													</Grid>
													<Grid item>
														<MuiFormHelperText sx={{ fontWeight: '700', fontSize: '1.25rem', lineHeight: '1.75rem' }}>{payInfo.calculatedFee === 0 ? t('tokens.withdrawal.free') : <NumberFormat
															displayType={'text'}
															fixedDecimalScale={false}
															thousandsGroupStyle='thousand'
															prefix={`${selectedAsset.symbol} `}
															thousandSeparator={true}
															value={payInfo.calculatedFeeString} />}</MuiFormHelperText>
													</Grid>
												</Grid>
											</>
										}
									</Grid>
									<Grid container flex={1} alignItems='flex-end' justifyContent='space-between' pt={'3rem'}>
										<Button
											key='buttonCancel'
											variant='customOutlined'
											onClick={handleCloseModal}
										>   	{t('form.buttons.close')}
										</Button>
										<Button
											key='prieview'
											type='submit'
											disabled={!isEmpty(errors) || isSubmitting || !selectedAsset?.paymentProc || payInfoLoading || (selectedAsset && selectedAsset.symbol === 'UMW')}
											variant='contained'> 	{t('form.buttons.continue')}
										</Button>
									</Grid>
								</Form>
							)}
						</Formik >
					</Grid></>}
				{step === WithdrawStep.CONFIRMATION && <>
					<Grid container justifyContent='space-between'>
						<Grid item>
							<FormHeader title='Confirmation' onBack={() => setStep(WithdrawStep.FORM)} />
						</Grid>
					</Grid>

					<Grid container rowSpacing={2}>
						<Grid item xs={12}>
							<ConfirmationBox customPadding='1.25rem'>
								<Grid container className='confirmationInfo' pl={3} pr={3} rowSpacing={1}>
									<Grid item container xs={12}>
										<Grid item xs={3} pr={4} display='flex' alignItems='center'>
											<MuiTextConfirmationLabel variant='body2'>{t('tokens.withdrawal.from')}</MuiTextConfirmationLabel>
										</Grid>
										<Grid item xs={9} display='flex' alignItems='center'>
											<MuiTextConfirmation> {`${payload.selectedAsset.name} (${payload.selectedAsset.symbol})`} </MuiTextConfirmation>
										</Grid>
									</Grid>
									<Grid item container xs={12}>
										<Grid item xs={3} pr={4} display='flex' alignItems='center'>
											<MuiTextConfirmationLabel variant='body2'>{t('tokens.withdrawal.to')}</MuiTextConfirmationLabel>
										</Grid>
										<Grid item xs={9} display='flex' alignItems='center'>
											<MuiTextConfirmation sx={{ width: '100%', wordWrap: 'break-word' }}> {payload?.body.beneficiaryCryptoAddress} </MuiTextConfirmation>
											<MuiCopyToClipboard value={payload?.body.beneficiaryCryptoAddress} />
										</Grid>
									</Grid>
								</Grid>
							</ConfirmationBox>
						</Grid>
						<Grid item xs={12}>
							<ConfirmationBox customPadding='1.25rem'>
								<Grid container className='confirmationInfo' pl={3} pr={3} rowSpacing={1}>
									<Grid item container xs={12}>
										<Grid item xs={3} pr={4} display='flex' alignItems='center'>
											<MuiTextConfirmationLabel variant='body2'>{t('tokens.withdrawal.withdrawalAmount')}</MuiTextConfirmationLabel>
										</Grid>
										<Grid item xs={9} display='flex' alignItems='center'>
											<MuiFormHelperText sx={{ fontWeight: '700', fontSize: '0.813rem', lineHeight: '1.188rem' }}><NumberFormat
												displayType={'text'}
												decimalScale={5}
												fixedDecimalScale={true}
												thousandsGroupStyle='thousand'
												prefix={`${payload.selectedAsset.symbol} `}
												thousandSeparator={true}
												value={payload.body.amount} /></MuiFormHelperText>
										</Grid>
									</Grid>
								</Grid>
							</ConfirmationBox>
						</Grid>
						<Grid item xs={12}>
							<ConfirmationBox customPadding='1.25rem'>
								<Grid container className='confirmationInfo' pl={3} pr={3} rowSpacing={1}>
									<Grid item container xs={12}>
										<Grid item xs={3} pr={4} display='flex' alignItems='center'>
											<MuiTextConfirmationLabel variant='body2'>{t('tokens.withdrawal.estimatedFee')}</MuiTextConfirmationLabel>
										</Grid>
										<Grid item xs={9} display='flex' alignItems='center'>
											<MuiFormHelperText sx={{ fontWeight: '700', fontSize: '0.813rem', lineHeight: '1.188rem' }}>{payload?.fee === 0 ? t('tokens.withdrawal.free') : <NumberFormat
												displayType={'text'}
												decimalScale={5}
												fixedDecimalScale={true}
												thousandsGroupStyle='thousand'
												prefix={`${payload.selectedAsset.symbol} `}
												thousandSeparator={true}
												value={payload.fee} />} </MuiFormHelperText>
										</Grid>
									</Grid>
								</Grid>
							</ConfirmationBox>
						</Grid>
					</Grid>


					<Grid container flex={1} alignItems='flex-end' justifyContent='space-between' pt={'3rem'}>
						<Button
							key='buttonCancel'
							variant='customOutlined'
							onClick={handleCloseModal}
						>   	{t('form.buttons.close')}
						</Button>
						<Button
							disabled={loading}
							key='prieview'
							variant='contained'
							onClick={handleConfirm}>
							{t('form.buttons.confirm')}
						</Button>
					</Grid>
				</>}
			</>


		</Dialog >
	);
};

export default WithdrawForm;
