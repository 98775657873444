import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Chat, ChatStatusEnum, ChatType, GetSupportChatPayload, Message, SendMessagePayload, SupportChatStore } from './types';
import request from '@services/request';
import { SocketService } from '@services/socketService';
import { Subscription } from 'rxjs';
import { isEmpty } from 'lodash';
import { AppThunk } from '@/store';

const initialState: SupportChatStore = {
  data: {
    chat: null,
    messages: [],
    count: 0,
  },
  unreadCount: 0,
};

const supportChatSlice = createSlice({
  name: 'supportChat',
  initialState,
  reducers: {
    setData: (state, { payload }: PayloadAction<ChatType>) => {
      state.data = payload;
    },
    pushMessage: (state, { payload }: PayloadAction<Message>) => {
      const exists = state.data.messages?.some((existingMessage) => existingMessage.id === payload.id);
      if (!exists) {
        state.data = {
          ...state.data,
          messages: state.data.messages ? [...state.data.messages, payload] : [payload],
        };
      }
    },
    pushMessages: (state, { payload }: PayloadAction<ChatType>) => {
      state.data = {
        ...state.data,
        messages: [...state.data.messages, ...payload.messages],
      };
    },
    editMessage: (state, { payload }: PayloadAction<Message>) => {
      const index = state.data.messages.findIndex(message => message.id === payload.id);
      if (index !== -1) {
        state.data.messages[index] = payload;
      }
    },
    deleteMessage: (state, { payload }: PayloadAction<string>) => {
      state.data.messages = state.data.messages.filter(message => message.id !== payload);
    },
    setStatus: (state, { payload }: PayloadAction<ChatStatusEnum>) => {
      if (state.data.chat) {
        state.data.chat.status = payload;
      }
    },
    setChat: (state, { payload }: PayloadAction<Chat | null>) => {
      state.data = {
        ...state.data,
        chat: payload,
      };
    },
    deleteChat: (state, { payload }: PayloadAction<string>) => {
      if (state.data.chat?.id === payload) {
        state.data = initialState.data;
      }
    },
    setUnreadCount: (state, { payload }: PayloadAction<number>) => {
      state.unreadCount = payload;
    },
  },
});

export const getSupportChat = (payload?: GetSupportChatPayload): AppThunk => {
  return async dispatch => {
    try {
      const response = await request.get('/api/support', {
        params:
          payload,
      });
      const { data } = response;
      if (!payload?.skip) {
        dispatch(setData(data));
      } else {
        dispatch(pushMessages(data));
      }
    } catch (error) {
      // const errorMsg = i18n.t('errors.getMessage');
      // showSuccessMessage(i18n.t('errors.error'), errorMsg);
    }
  };
};

export const sendMessage = (payload: SendMessagePayload): AppThunk => {
  return async dispatch => {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        formData.append(key, value as string | Blob);
      }
    });
    try {
      const response = await request.post('/api/support/message', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const { message, chat } = response.data;
      dispatch(setChat(chat));
      dispatch(pushMessage(message));
    } catch (error) {
      // const errorMsg = i18n.t('errors.sendtMessege');
      // showSuccessMessage(i18n.t('errors.error'), errorMsg);
    }
  };
};

const markAsRead = async (payload: { chatId: number }) => {
  try {
    await request.post('/api/support/message/mark-as-read', payload);
  } catch (error) {
    console.log('markAsRead error');
  }
};

export const getAttachment = async (key: string, name: string) => {
  const response = await request.get(`/api/support/message/attachment/${key}`,
    {
      responseType: 'blob',
      params: {
        cacheBustTimestamp: Date.now(),
      },
    });
  const url = window.URL.createObjectURL(response.data);
  const link = document.createElement('a');
  link.download = name;
  link.href = url;
  link.className = 'hidden';
  document.body.appendChild(link);

  link.onclick = function () {
    requestAnimationFrame(function () {
      URL.revokeObjectURL(url);
      setTimeout(() => link.remove(), 300);
    });
  };
  link.click();
};

export const getChatUnreadCount = (): AppThunk => {
  return async dispatch => {
    try {
      const response = await request.get('/api/support/unread-count');
      const { data } = response;
      dispatch(setUnreadCount(data.count));
      return data;
    } catch (e) {
      dispatch(setUnreadCount(0));
    }
  };
};


let socketService: SocketService;
let messegeSubscriber: Subscription;
let deletedMsgSubscriber: Subscription;
let deletedChatSubscriber: Subscription;
let editMsgSubscriber: Subscription;
let statusSubscriber: Subscription;

export const connectChat = (): void => {
  if (socketService) {
    unsubscribeChat();
  }
  socketService = new SocketService('chat');
};

export const subscribeChat = (): AppThunk => {
  return async dispatch => {
    try {
      messegeSubscriber = socketService
        .listen('chat.message', {})
        .subscribe((data) => {
          if (isEmpty(data)) {
            return;
          }
          dispatch(pushMessage(data.message));
          markAsRead({ chatId: Number(data.message.chatId) });
        });
      statusSubscriber = socketService
        .listen('chat.status.solve', {})
        .subscribe((data) => {
          if (isEmpty(data)) {
            return;
          }
          dispatch(setStatus(ChatStatusEnum.SOLVED));
        });
      editMsgSubscriber = socketService
        .listen('chat.message.edit', {})
        .subscribe((data) => {
          if (isEmpty(data)) {
            return;
          }
          dispatch(editMessage(data));
        });
      deletedMsgSubscriber = socketService
        .listen('chat.message.deleted', {})
        .subscribe((data) => {
          if (isEmpty(data)) {
            return;
          }
          dispatch(deleteMessage(data.messageId));
        });
      deletedChatSubscriber = socketService
        .listen('chat.deleted', {})
        .subscribe((data) => {
          if (isEmpty(data)) {
            return;
          }
          dispatch(deleteChat(data.chatId));
        });
      socketService.send('data');
    } catch (error) {
      // const errorMsg = i18n.t('errors.chatConnect');
      // showSuccessMessage(i18n.t('errors.error'), errorMsg);
    }
  };
};

export const unsubscribeChat = (): void => {
  deletedChatSubscriber.unsubscribe();
  deletedMsgSubscriber.unsubscribe();
  messegeSubscriber.unsubscribe();
  editMsgSubscriber.unsubscribe();
  statusSubscriber.unsubscribe();
};

export const { setData, pushMessage, setChat, deleteMessage, deleteChat, editMessage, pushMessages, setStatus, setUnreadCount } = supportChatSlice.actions;

export default supportChatSlice.reducer;



