import MuiFormLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiFormLabel';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import { AccountType } from '@/features/operations/types';
import { Box, Button, Grid, Theme, useMediaQuery } from '@mui/material';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TokenChooser from './TokenChooser';
import { getCurrencySign } from '@/features/fees/slice';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { useTheme } from '@mui/styles';
import NumberFormat from 'react-number-format';
import { CoinType } from '@features/coins/types';
import bigDecimal from 'js-big-decimal';
import MuiCopyToClipboard from '@shared/Widgets/MuiCopyToClipboard';
import TokenAvatar, { TokenAvatarSizeEnum } from '@shared/Widgets/Avatars/TokenAvatar';
import MuiTextLabel from '@shared/Widgets/MuiText/MuiTextLabel';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { getFiatValue } from '@helpers/getFiatValue';
import TransactionGridShort from '@/componentsMui/TransactionsGrid/TransactionGridShort';
import InfoIcon from '@mui/icons-material/Info';
import { hasSubprocessWithType } from '@features/operations/slice';
import { formatDecimalFlex } from '@/helpers/number';

interface Props {
    token: CoinType,
    list: CoinType[],
    currency: string,
}

export const TOKEN_PRICE_PRECISION = 4;

const IndividualTokenDetails = ({ token, list, currency }: Props) => {
    const theme = useTheme() as Theme;
    const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation('translations');
    const history = useHistory();

    const { user } = useSelector((state: RootState) => state.user);

    const precision = token?.account?.precision ?? 5;

    const balance = bigDecimal.compareTo(token?.account?.availableBalance, 0) === 0 ? 0 : new bigDecimal(token?.account?.availableBalance ?? 0).round(precision, bigDecimal.RoundingModes.DOWN).getValue();
    const isBalanceLessThanShow = bigDecimal.compareTo(token?.account?.availableBalance, 0) && bigDecimal.compareTo(balance, 0) === 0;

    const isConvertible = token?.info?.sellEnabled || token?.info.buyEnabled;
    const isTransferable = hasSubprocessWithType('TRANSFER', token?.subProcesses) && bigDecimal.compareTo(token?.account?.availableBalance ?? 0, 0) > 0 && token?.info?.sendEnabled;

    const openTransferForm = () => {
        history.push(`/wallet/home/tokens/transfer?symbol=${token.info.symbol}`);
    };

    const handleTrade = () => {
        history.push(`/wallet/home/tokens/token/trade?token=${token.type}${token.info.symbol}`);
    };

    const openReceiveForm = () => {
        history.push('/wallet/home/tokens/receive');
    };

    return (
        <Grid container columnGap={2}>
            <Grid item xs={2.3} maxHeight='75vh' height='75vh' overflow='auto' sx={{ display: { xs: 'none', sm: 'none', lg: 'block' } }}>
                <TokenChooser tokens={list} />
            </Grid>
            <Grid item sm={12} lg={9.5}>
                <Grid container>
                    <Grid container item xs={12} alignContent='space-between'>
                        <Grid container item xs={12} justifyContent='space-between' {...(mediumScreen ? { alignItems: 'start', flexDirection: 'row' } : {})} sx={{ borderWidth: 1, borderColor: 'red', mb: 2 }}>
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                <MuiFormLabel color='text.secondary' mb={1}>{t('tokens.labels.token')}</MuiFormLabel>
                                <Grid item container flexDirection='row' alignItems='center'>
                                    <Grid item>
                                        <TokenAvatar symbol={token.info.symbol} logoSource={token.info.logo} variant={TokenAvatarSizeEnum.medium} />
                                    </Grid>
                                    <Grid item pl={1} alignItems='center' justifyContent={'center'} display={'flex'} flexDirection={'row'}>
                                        <MuiTextCaption fontSize='1rem'>{token.info.symbol}</MuiTextCaption>
                                        <MuiTextLabel fontWeight='400' ml={1}>{token.info.displayName}</MuiTextLabel>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={2}>
                                <MuiFormLabel color='text.secondary' mb={1}>{t('tokens.labels.balance')}</MuiFormLabel>
                                <Grid item container flexDirection='row' alignItems='center'>
                                    <MuiTextCaption fontSize='0.875rem'>
                                        <NumberFormat displayType={'text'}
                                            decimalScale={isBalanceLessThanShow ? precision + 1 : precision}
                                            fixedDecimalScale={false}
                                            thousandsGroupStyle='thousand'
                                            thousandSeparator={true}
                                            prefix={isBalanceLessThanShow ? '< ' : ' '}
                                            value={isBalanceLessThanShow ? `${balance}1` : balance}
                                        />
                                    </MuiTextCaption>
                                    <MuiTextCaption pl='0.2em' variant='gradient' fontSize='0.875rem'>
                                        {`${getCurrencySign(token.info.symbol)}`}
                                    </MuiTextCaption>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ mt: { xs: '1rem', md: 0 } }} xs={12} md={6} lg={2}>
                                <MuiFormLabel color='text.secondary' mb={1}>{t('tokens.labels.currentPrice')}</MuiFormLabel>
                                <Grid item container flexDirection='row' alignItems='center'>
                                    <MuiTextCaption fontSize='0.875rem'>
                                        {formatDecimalFlex(token.info.price ?? 0, { maximumFractionDigits: TOKEN_PRICE_PRECISION })}
                                    </MuiTextCaption>
                                    <MuiTextCaption pl='0.2em' fontSize='0.875rem'>
                                        {`${getCurrencySign(currency)}`}
                                    </MuiTextCaption>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ mt: { xs: '1rem', md: 0 } }} xs={12} md={6} lg={2}>
                                <MuiFormLabel color='text.secondary' mb={1}>{t('tokens.labels.value')}</MuiFormLabel>
                                <Grid item container flexDirection='row' alignItems='center'>
                                    <MuiTextCaption fontSize='0.875rem'>
                                        <NumberFormat
                                            // style={{fontSize: '1rem', whiteSpace: 'nowrap'}}
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandsGroupStyle='thousand'
                                            thousandSeparator={true}
                                            prefix=' '
                                            value={(getFiatValue(token, currency) ?? 0)} />
                                    </MuiTextCaption>
                                    <MuiTextCaption pl='0.2em' fontSize='0.875rem'>
                                        {`${getCurrencySign(currency)}`}
                                    </MuiTextCaption>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ mt: { xs: '1rem', md: 0 } }} xs={12} md={6} lg={2}>
                                <Grid item alignItems='center' justifyContent={'start'} display={'flex'} flexDirection={'row'} mb={1}>
                                    <MuiFormLabel color='text.secondary'>
                                        {t('tokens.labels.accountId')}
                                    </MuiFormLabel>
                                    <InfoIcon fontSize='small' sx={{ marginLeft: '0.25em' }} onClick={openReceiveForm} />
                                </Grid>
                                <Grid item container flexDirection='row' alignItems='center'>
                                    <MuiTextCaption fontSize='0.875rem' variant='gradient'>{user?.tag ?? user.clientId}</MuiTextCaption>
                                    <MuiCopyToClipboard value={user?.tag ?? user.clientId} sx={{ p: 0, ml: 1 }} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container >
                            <Grid container item xs={12} md={6} flexDirection='row' sx={{ pt: { xs: '1rem', md: 0 } }} spacing={1} justifyContent='flex-start' mb={1}>
                                {isTransferable && <Grid item xs={mediumScreen}>
                                    <Button variant='transparent' onClick={openTransferForm} sx={{ width: { xs: '100%', md: 'auto' } }} startIcon={<SyncAltIcon fontSize='small' />}>{t('wallet.labels.transfer')}</Button>
                                </Grid>}
                                {isConvertible && <Grid item xs={mediumScreen}>
                                    <Button variant='transparent' onClick={handleTrade} sx={{ width: { xs: '100%', md: 'auto' } }} startIcon={<AutorenewIcon />}>{t('wallet.labels.convert')}</Button>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sx={{ my: { xs: '2rem', sm: 0 } }}>
                        <Box
                            sx={(theme) => ({
                                background: {
                                    xs: 'none', sm: theme.backgroundBoxLevel1
                                },
                                borderRadius: theme.mainBorderRadius,
                                p: '20px',
                                width: '100%',
                                mb: { xs: 3, md: 0 },
                                mt: 1
                            })}>
                            {token.account
                                ? <TransactionGridShort account={token.account} type={AccountType.TOKEN} seeAllInModal={true} />
                                : <div style={{ display: 'flex', justifyContent: 'space-around' }}>{t('transaction.table.noTransactions')}</div>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
};

export default IndividualTokenDetails;


