
import * as React from 'react';
import './ProductOptionBox.css';
import { BoxProps } from '@mui/material';
import { FC } from 'react'; 
import MultiBgBox from '../Box/CoverBox/MultiBgBox';

interface Props extends BoxProps {
	selected: boolean,
	bgIndex: number,
	coverName: string
}

/*  selected item in product scroll, product card
Defines box width, heigth and content aligment*/
const ProductOptionBox: FC<Props> = (props: Props) => {

	const { selected, bgIndex, coverName, sx, ...others } = props;


	return (
		<MultiBgBox selected={selected}
			bgIndex={bgIndex}
			coverName={coverName} 
			mainClassName='product-option-box'
			itemClassName='product-option-box__item-wrap'
			sx={{...sx, cursor:'pointer'}}
			{...others}>
			{props.children}
		</MultiBgBox>);


}; 

export default ProductOptionBox;


