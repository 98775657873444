import * as React from 'react';
import { SvgIcon } from '@mui/material';

const MenuEarnIcon = (props) => (
    <SvgIcon {...props} >
        <svg width="22" height="22" viewBox="0 0 22 22" fill="current" stroke="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.324 0a.943.943 0 000 1.886h2.463l-6.012 6.012-4.066-4.066a.943.943 0 00-1.334 0l-7.099 7.1a.943.943 0 001.334 1.332l6.432-6.432 4.066 4.066a.943.943 0 001.333 0l6.673-6.672v2.45a.943.943 0 101.886 0V.978A.95.95 0 0021.056 0h-4.733zm4.42 9.742a.943.943 0 10-1.886 0v11.315a.943.943 0 101.886 0V9.743zM9.01 13.514a.943.943 0 00-1.886 0v7.543a.943.943 0 101.886 0v-7.543zm-5.867 2.515a.943.943 0 10-1.886 0v5.028a.943.943 0 001.886 0v-5.028zm10.79-2.2c.521 0 .944.422.944.943v6.285a.943.943 0 11-1.886 0v-6.285c0-.521.422-.943.943-.943z"
                fill="current"
            />
        </svg>
    </SvgIcon>
);

export default MenuEarnIcon;
