import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserLimitType } from '@/features/limits/types';
import LimitLineWithActual from './LimitLineWithActual';

interface Props {
  userLimits: UserLimitType[];
  currency: string
}

const Limits = ({ userLimits, currency }: Props) => {
  const { t } = useTranslation('translations');

  if (!userLimits || userLimits.length === 0) return <></>;

  return (
    <Grid item xs={12}>
      <LimitLineWithActual
        limitTitle={t('fees.myFees.limits.single')}
        limitValue={userLimits[0].maxAmountCcy}
        currency={currency} />
      <LimitLineWithActual
        limitTitle={t('fees.myFees.limits.daily')}
        limitValue={userLimits[0].dayLimitCcy}
        currency={currency} />
      <LimitLineWithActual
        limitTitle={t('fees.myFees.limits.monthly')}
        limitValue={userLimits[0].monthLimitCcy}
        currency={currency} />
    </Grid>
  );
};

export default Limits;
