
import BaseBox from '@/componentsMui/Shared/Widgets/Box/BaseBox';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader'; 
import * as React from 'react';
import { useTranslation } from 'react-i18next';


const KYBNotCompleted = () => {
	const { t } = useTranslation('translations');

	return (
		<BaseBox>
			<MuiHeader textAlign='center' p={4}> {t('createbusiness.information.KYBNotCompleted')}</ MuiHeader >
		</BaseBox>
	);
};

export default KYBNotCompleted;
