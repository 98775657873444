import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './BusinessPayment.css';
import MuiCopyToClipboard from '@/componentsMui/Shared/Widgets/MuiCopyToClipboard';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import TopBox from '@/componentsMui/Shared/Widgets/Box/TopBox';
import MuiFormLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiFormLabel';
import MuiTextConfirmation from '@/componentsMui/Shared/Widgets/MuiText/MuiFormField';
import { PaymentDetails } from '@/features/business/types';

interface Props {
	paymentDetails: PaymentDetails
}

const BusinessPaymentDetails = ({ paymentDetails }: Props): React.ReactElement => {

	const { t } = useTranslation('translations');
	return (
		<TopBox className='account-details-container' >
			<Grid container>
				<Grid container item xs={12} className='account-line'>
					<Grid item xs={4} >
						<MuiFormLabel color='text.secondary'>  {t('createbusiness.waitingForPayment.beneName')}  </MuiFormLabel>
					</Grid>
					<Grid item xs={7}>
						<MuiTextConfirmation> {paymentDetails.beneficiaryName} </MuiTextConfirmation>
					</Grid>
					<Grid item xs={1} justifyContent='flex-end' container>
						<MuiCopyToClipboard value={paymentDetails.beneficiaryName} />
					</Grid>
				</Grid>
				<Grid item xs={12} >
					<StyledDivider />
				</Grid>

				<Grid container item xs={12} className='account-line'>
					<Grid item xs={4} >
						<MuiFormLabel color='text.secondary'> {t('createbusiness.waitingForPayment.iban')}  </MuiFormLabel>
					</Grid>
					<Grid item xs={7}>
						<MuiTextConfirmation> {paymentDetails.iban} </MuiTextConfirmation>
					</Grid>
					<Grid item xs={1} justifyContent='flex-end' container>
						<MuiCopyToClipboard value={paymentDetails.iban} />
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<StyledDivider />
				</Grid>

				<Grid container item xs={12} className='account-line'>
					<Grid item xs={4} >
						<MuiFormLabel color='text.secondary'>  {t('createbusiness.waitingForPayment.bankName')}  </MuiFormLabel>
					</Grid>
					<Grid item xs={7}>
						<MuiTextConfirmation> {paymentDetails.bankName} </MuiTextConfirmation>
					</Grid>
					<Grid item xs={1} justifyContent='flex-end' container>
						<MuiCopyToClipboard value={paymentDetails.bankName} />
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<StyledDivider />
				</Grid>

				<Grid container item xs={12} className='account-line'>
					<Grid item xs={4} >
						<MuiFormLabel color='text.secondary'>  {t('createbusiness.waitingForPayment.bankAddress')}  </MuiFormLabel>
					</Grid>
					<Grid item xs={7}>
						<MuiTextConfirmation> {paymentDetails.bankAddress} </MuiTextConfirmation>
					</Grid>
					<Grid item xs={1} justifyContent='flex-end' container>
						<MuiCopyToClipboard value={paymentDetails.bankAddress} />
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<StyledDivider />
				</Grid>



				<Grid container item xs={12} className='account-line'>
					<Grid item xs={4} >
						<MuiFormLabel color='text.secondary'>  {t('createbusiness.waitingForPayment.swift')}  </MuiFormLabel>
					</Grid>
					<Grid item xs={7}>
						<MuiTextConfirmation> {paymentDetails.swift} </MuiTextConfirmation>
					</Grid>
					<Grid item xs={1} justifyContent='flex-end' container>
						<MuiCopyToClipboard value={paymentDetails.swift} />
					</Grid>
				</Grid>

			</Grid>
		</TopBox>
	);
};

export default BusinessPaymentDetails;
