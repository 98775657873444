import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, Theme, useMediaQuery } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';

import { showErrorNotification } from '@/features/swal/slice';
import '@/componentsMui/Shared/Shared.css';
import { getTime } from 'date-fns';
import { WalletHistoryPayload, WalletTransaction, WalletTransactionType } from '@/features/walletHistory/types';
import { getWalletHistory, setSearch, setSkip, setTransactions, setType } from '@/features/walletHistory/slice';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import SearchField from '@/componentsMui/Shared/Widgets/SearchField';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import WalletHistoryHeader from './WalletHistoryHeader';
import WalletHistoryLine from './WalletHistoryLine';
import WalletHistoryLineMobile from './WalletHistoryLineMobile';
import TradeHistoryLineMobile from './TradeHistoryLineMobile';
import WalletTrxDetailsForm from './WalletTrxDetailsForm';
import TradeDetailsForm from './TradeDetailsForm';
import TradeHistoryHeaders from './TradeHistoryHeaders';
import TradeHistoryLine from './TradeHistoryLine';
import DownloadButton from '@shared/Widgets/DownloadButton';
import { AccountType } from '@features/operations/types';
import WalletExportStatementForm from '@/componentsMui/Transactions/Download/WalletExportStatementForm';
import { useTheme } from '@mui/styles';
import TrxGenericError from '@/componentsMui/TransactionsGrid/Components/TrxGenericError';

interface CryptoHistoryProps {
	walletTransactionType: WalletTransactionType,
	title: string
}

const CryptoHistory = ({ walletTransactionType, title }: CryptoHistoryProps): React.ReactElement => {
	const theme = useTheme() as Theme;
	const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const [today] = useState<Date>(new Date());
	const [loading, setLoading] = useState<boolean>(false);
	const [showLoadMore, setShowLoadMore] = useState<boolean>(true);
	const [openDownloadForm, setOpenDownloadForm] = React.useState<boolean>(false);
	const [openWalletTrxDetailsForm, setOpenWalletTrxDetailsForm] = React.useState<boolean>(false);
	const [openTradeDetailsForm, setOpenTradeDetailsForm] = React.useState<boolean>(false);
	const [selectedTrx, setSelectedTrx] = React.useState<WalletTransaction>();
	const [error, setError] = useState(false);

	const lastTransactionRef = React.useRef(null);

	const { skip, take, transactions, search } = useSelector(
		(state: RootState) => state.walletHistory
	);

	const { user } = useSelector((state: RootState) => state.user);
	const from = getTime(new Date(user.createdDate));

	const handleSearchRequest = (e) => {
		const regex = /^[ A-Za-z0-9-.,&/_?!]*$/;
		if (regex.test(e.target.value)) {
			dispatch(setSkip(0));
			dispatch(setTransactions([]));
			dispatch(setSearch(e.target.value));
		}

	};


	const fetchData = useCallback(() => {
		const get = async () => {
			setError(false);
			setLoading(true);
			setShowLoadMore(true);
			try {

				const payload: WalletHistoryPayload = {
					search: search,
					skip: 0,
					take: take,
					from: `${from}`,
					to: `${today.getTime()}`
				};

				const result = await getWalletHistory(walletTransactionType, payload);
				if (result.list.length === result.count) {
					setShowLoadMore(false);
				}
				dispatch(setTransactions(result.list));
			} catch (e) {
				setError(true);
				dispatch(setTransactions([]));
			} finally {
				setLoading(false);
			}

			dispatch(setSkip(take));
		};

		get();
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [walletTransactionType, search, dispatch]);

	useEffect(() => {
		if (walletTransactionType) {
			dispatch(setType(walletTransactionType));
		}
	}, [dispatch, walletTransactionType]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const loadMore = async () => {
		try {
			const payload: WalletHistoryPayload = {
				search: search,
				skip: skip,
				take: take,
				from: `${from}`,
				to: `${today.getTime()}`,
			};

			const result = await getWalletHistory(walletTransactionType, payload);

			const newTrxArray = [...transactions, ...result.list];
			dispatch(setTransactions(newTrxArray));
			dispatch(setSkip(skip + take));
			if (result.count === newTrxArray.length) {
				setShowLoadMore(false);
			}

			lastTransactionRef.current?.scrollIntoView({ behavior: 'smooth' });
		} catch (e) {
			dispatch(setTransactions([]));
			showErrorNotification(e);
		} finally {
			setLoading(false);
		}

	};


	return (
		<div id='transaction-statements' className='scroll-container'>
			<WalletExportStatementForm
				open={openDownloadForm}
				type={AccountType.WALLET}
				walletTransactionType={walletTransactionType}
				onClose={() => setOpenDownloadForm(false)} />
			<WalletTrxDetailsForm
				open={openWalletTrxDetailsForm}
				onClose={() => setOpenWalletTrxDetailsForm(false)}
				item={selectedTrx}
				walletTransactionType={walletTransactionType}
			/>
			<TradeDetailsForm
				open={openTradeDetailsForm}
				onClose={() => setOpenTradeDetailsForm(false)}
				item={selectedTrx}
			/>
			<Grid container
				display='flex'
				flexDirection='row'
				alignItems='center'
				pb={2}>
				<Grid item xs={12} sm={4} sx={{ mb: { xs: '1rem' } }}>
					<MuiHeader >{title}</MuiHeader>
				</Grid>
				<Grid item xs={12} sm={8} container justifyContent='flex-end' spacing={1}>
					<Grid item>
						<DownloadButton onClick={() => setOpenDownloadForm(true)} />
					</Grid>
					<Grid item xs={smallScreen}>
						<SearchField
							id='search'
							name='search'
							value={search}
							style={{ width: '100%' }}
							onChange={(e) => handleSearchRequest(e)}
						/>
					</Grid>
				</Grid>
			</Grid>
			{loading && <Grid container justifyContent='center' mt={2}><Spinner /></Grid >}
			{error && <TrxGenericError onButtonClick={fetchData} />}
			{(!transactions || transactions.length === 0) && !loading && !error && <div style={{ display: 'flex', justifyContent: 'space-around' }}>{t('transaction.table.noTransactions')}</div>}
			{!loading && transactions?.length > 0 &&
				<>
					<div className='scroll-section'>
						{smallScreen ?
							<div className='scrollable-content'>
								{transactions?.length > 0 && transactions?.map((trx, index) => (
									<div key={trx.transactionNumber} ref={transactions.length === index + 1 ? lastTransactionRef : null}>
										<Box sx={{ py: '0.5rem' }}>
											{(walletTransactionType === WalletTransactionType.CRYPTO_DEPOSITS || walletTransactionType === WalletTransactionType.TOKEN_DEPOSITS || walletTransactionType === WalletTransactionType.CRYPTO_WITHDRAWALS || walletTransactionType === WalletTransactionType.TOKEN_WITHDRAWALS) && <WalletHistoryLineMobile item={trx} onClick={() => { setOpenWalletTrxDetailsForm(true); setSelectedTrx(trx); }} />}
											{(walletTransactionType === WalletTransactionType.CRYPTO_TRADES || walletTransactionType === WalletTransactionType.TOKEN_TRADES) && <TradeHistoryLineMobile item={trx} onClick={() => { setOpenTradeDetailsForm(true); setSelectedTrx(trx); }} />}
											{index + 1 < transactions.length && <StyledDivider />}
										</Box>
									</div>
								)
								)}
							</div>
							:
							<>
								<div style={{ paddingTop: '1rem', paddingBottom: '1rem', paddingRight: '1rem' }}>
									{(walletTransactionType === WalletTransactionType.CRYPTO_DEPOSITS || walletTransactionType === WalletTransactionType.TOKEN_DEPOSITS || walletTransactionType === WalletTransactionType.CRYPTO_WITHDRAWALS || walletTransactionType === WalletTransactionType.TOKEN_WITHDRAWALS) && <WalletHistoryHeader type={walletTransactionType} />}
									{(walletTransactionType === WalletTransactionType.CRYPTO_TRADES || walletTransactionType === WalletTransactionType.TOKEN_TRADES) && <TradeHistoryHeaders />}
								</div>
								<div className='scrollable-content' style={{ paddingRight: '1rem' }}>
									{transactions?.length > 0 && transactions?.map((trx, index) => (
										<div key={trx.transactionNumber} ref={transactions.length === index + 1 ? lastTransactionRef : null}>
											{(walletTransactionType === WalletTransactionType.CRYPTO_DEPOSITS || walletTransactionType === WalletTransactionType.TOKEN_DEPOSITS || walletTransactionType === WalletTransactionType.CRYPTO_WITHDRAWALS || walletTransactionType === WalletTransactionType.TOKEN_WITHDRAWALS) && <WalletHistoryLine type={walletTransactionType} item={trx} />}
											{(walletTransactionType === WalletTransactionType.CRYPTO_TRADES || walletTransactionType === WalletTransactionType.TOKEN_TRADES) && <TradeHistoryLine item={trx} />}
											{index + 1 < transactions.length && <StyledDivider />}
										</div>
									)
									)}
								</div>
							</>
						}
					</div>
					{showLoadMore &&
						<Grid container>
							<Grid item xs={12} justifyContent='center' display='flex' pt={2}>
								<Button onClick={loadMore}>{t('transaction.table.loadMore')}</Button>
							</Grid>
						</Grid>
					}
				</>}
		</div>
	);
};


export default CryptoHistory;


