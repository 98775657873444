import * as React from 'react';

import './TransactionDetailsForm.css';
import { Button, Dialog, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import DialogContentNoScrollbar from '@/componentsMui/Shared/Widgets/Dialog/DialogContentNoScrollbar';
import { useTranslation } from 'react-i18next';
import { TransactionType } from '@/features/transactions/types';
import { formatDateWithPattern } from '@/helpers/date';
import NumberFormat from 'react-number-format';
import ChargeBack from './ChargeBack';
import CashWithdrawal from './CashWithdrawal';
import OwnAccounts from './OwnAccounts';
import TransferOutgoing from './TransferOutgoing';
import TransferIncoming from './TransferIncoming';
import Attachments from './Attachments';
import TransactionNumber from './TransactionNumber';
import CardTopUp from './CardTopUp';
import TransactionIcon from './TransactionIcon';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { Transaction } from '../TransactionStatements.spec';
import { getCurrencySign } from '@/features/fees/slice';
import FormContentBox from '@/componentsMui/Shared/Widgets/Box/FormContentBox';
import CurrencyExchange from './CurrencyExchange';
import { BankAccount } from '@features/account/types';
import { AccountProviderType, AccountType } from '@/features/operations/types';
import { TransactionStatus } from '@/features/transactions/types';
import DetailLine from '@shared/Widgets/DetailLine';
import { withStyles } from '@mui/styles';
import SendForm from './SendForm';
import { getExchangePdf, getTransactionPdfContent } from '@/features/transactions/slice';
import { showException } from '@/features/swal/slice';
import { useState } from 'react';
import LoadingPopup from '@/componentsMui/Shared/Widgets/LoadingPopup';
import PdfViewerFullScreen from '@/componentsMui/Shared/Components/PdfViewerFullScreen';

interface Props {
	transaction: Transaction,
	type: AccountType,
	open: boolean,
	onClose: () => void,
	account?: BankAccount,
	setLoading: (value: boolean) => void,
}

const FormPaperTransaction = withStyles(() => ({
	root: {
		maxWidth: '670px',
		['@media only screen and (max-width:600px)']: {
			maxHeight: '100vh',
			minHeight: '100vh',
			minWidth: '100vw',
			maxWidth: '100vw',
			width: '100%',
			padding: 0,
			margin: 0,
			borderRadius: 0,
		},
	}
}))(Paper);

const TransactionDetailsForm = ({ transaction, open, type, account, setLoading, onClose }: Props) => {
	const { t } = useTranslation('translations');
	const [openSendTrx, setOpenSendTrx] = React.useState<boolean>(false);
	const [previewFile, setPreview] = useState(null);
	const [openPreview, setOpenPreview] = useState(false);
	const [previewLoading, setPreviewLoading] = useState(false);

	let transactionData: Transaction;

	const downloadTrxPdf = React.useCallback(async (transaction: Transaction) => {
		try {
			setPreviewLoading(true);

			let walletId;
			let payload;
			let result;

			if (type === AccountType.WALLET || type === AccountType.CARD) {
				walletId = account?.walletId;
			}

			if (transaction.activity === TransactionType.FX_TRANSACTION
				|| transaction.activity === TransactionType.TRADE) {
				payload = {
					transactionNumber: transaction.docNumber,
					accountType: type,
					walletId: walletId,
				};

				result = await getExchangePdf(payload);
			} else {
				payload = {
					transactionNumber: transaction.transactionNumber,
					accountType: type,
					walletId: walletId,
				};

				result = await getTransactionPdfContent(payload);

			}

			setPreview(result);
			setOpenPreview(true);
		}
		catch (e) {
			showException(e);
		}
		finally {
			setPreviewLoading(false);
		}

	}, [setPreviewLoading, account, type]);

	if (transaction) {
		transactionData = { ...transaction };
	}

	if (transaction && account?.providerType === AccountProviderType.CARD) {
		transactionData.cardNumberMask = account?.cardNumberMask;
	}

	const isFeeTrx = TransactionType.SYSTEM_FEE === transactionData?.activity ||
		TransactionType.CURRENCY_FEE === transactionData?.activity ||
		TransactionType.MONTHLY_FEE === transactionData?.activity ||
		TransactionType.SERVICE_FEE === transactionData?.activity ||
		TransactionType.COMMISSION === transactionData?.activity ||
		TransactionType.CARD_OPERATION_FEE === transactionData?.activity ||
		TransactionType.EXT_TRANSACTION_FEE === transactionData?.activity;

	const canExport = transactionData?.status !== TransactionStatus.CANCELED
		&& transactionData?.status !== TransactionStatus.FAILED
		&& transactionData?.status !== TransactionStatus.PENDING
		&& transactionData?.status !== TransactionStatus.FX_PENDING
		&& transactionData?.status !== TransactionStatus.REJECTED
		&& transactionData?.status !== TransactionStatus.REVERTED
		&& transactionData?.status !== TransactionStatus.PENDING_FX;


	const showTooltip = ['PENDING', 'CANCELED', 'FAILED', 'REJECTED', 'REVERTED'].includes(transaction?.status);

	const resolveActivityName = (): string => {
		if (transactionData?.messageCode) {
			return t('activity.' + transaction.messageCode);
		}

		switch (transactionData?.activity) {
			case TransactionType.TRANSFER:
				if (transactionData?.amount > 0) {
					return t('transaction.type.DEPOSIT');
				} else {
					return t('transaction.type.TRANSFER');
				}
			default: return t('transaction.type.' + transactionData?.activity);
		}
	};


	const openSendTrxForm = () => {
		setOpenSendTrx(true);
	};

	const onSendEmailStart = () => {
		setOpenSendTrx(false);
		setLoading(true);
		onClose();

	};
	const onSendEmailEnd = () => {
		setOpenSendTrx(false);
		setLoading(false);
	};
	return (<>
		<SendForm open={openSendTrx}
			transaction={transaction}
			type={type}
			walletId={account?.walletId}
			onSendStart={onSendEmailStart}
			onSendEnd={onSendEmailEnd}
		/>

		<PdfViewerFullScreen
			file={previewFile}
			savedFileName={transaction?.transactionNumber ?? 'transaction'}
			isOpen={openPreview}
			onClose={() => setOpenPreview(false)} />
		<Dialog
			id="transaction-details-form"
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
			PaperComponent={FormPaperTransaction}
		>
			<LoadingPopup open={previewLoading} messsage={t('transactiondetails.message.preprare')} />
			<DialogContentNoScrollbar>
				<Grid container sx={{ p: { xs: '1rem 1rem 2rem 1rem', md: '2rem' } }}>
					<Grid container justifyContent="space-between" alignItems="center" >
						<Grid item>
							<MuiHeader>{resolveActivityName()}</MuiHeader>
						</Grid>
						<Grid item>
							<IconButton onClick={onClose}  >
								<MuiCloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					{((transactionData?.activity === TransactionType.FX_TRANSACTION || transactionData?.activity === TransactionType.TRADE) && transactionData.status === 'COMPLETED') ?
						<CurrencyExchange transactionData={transactionData} />
						: transactionData &&
						<Grid container spacing={2} >
							<Grid item xs={12} container mt={'1rem'} display='flex' justifyContent='space-between'>
								<Grid item flexDirection='column' justifyContent='space-between'>
									<Grid>
										<Typography
											sx={{ fontSize: { xs: '1.2rem', md: '2.25rem', whiteSpace: 'nowrap' } }}
											fontWeight='800'
										>
											{transactionData?.amount >= 0 && '+'}
											<NumberFormat displayType={'text'}
												decimalScale={2}
												fixedDecimalScale={true}
												thousandsGroupStyle='thousand'
												thousandSeparator={true}
												value={transactionData.amount}
												prefix={` ${getCurrencySign(transactionData?.asset ?? transactionData?.currency)}`}
											/>
										</Typography>
									</Grid>
									<Grid>
										<Typography variant='caption' color='text.secondary'>
											{formatDateWithPattern(transactionData.date, 'dd/MM/yyyy HH:mm:ss')}
										</Typography>
									</Grid>
								</Grid>
								<Grid item xs container justifyContent="flex-end">
									{transactionData?.activity &&
										<TransactionIcon activity={transactionData.activity} beneficiaryName={transactionData.beneficiary} />
									}
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<FormContentBox className='transaction-form' sx={{ p: { xs: '0.5rem 1rem', sm: '0.5rem 2.625rem' } }}>
									<Grid container>
										{transactionData?.transactionNumber &&
											<Grid item xs={12} >
												<TransactionNumber details={transactionData} />
											</Grid>
										}
										{transactionData?.transactionCategoryCode &&
											<DetailLine
												label={t('transactiondetails.main.category')}
												value={t(`merchantCodes.${transactionData.transactionCategoryCode}`, transactionData.transactionCategoryCode)}
											/>
										}
										{TransactionType.CHARGEBACK === transactionData?.activity &&
											<Grid item xs={12} >
												<ChargeBack details={transactionData} />
											</Grid>
										}
										{(TransactionType.WITHDRAW === transactionData?.activity ||
											isFeeTrx) &&
											<Grid item xs={12} >
												<CashWithdrawal details={transactionData} isFeeTrx={isFeeTrx}/>
											</Grid>
										}
										{TransactionType.OWN_TRANSFER === transactionData?.activity &&
											<Grid item xs={12} >
												<OwnAccounts details={transactionData} />
											</Grid>
										}
										{(TransactionType.PAYMENT === transactionData?.activity ||
											TransactionType.PURCHASE === transactionData?.activity ||
											(
												TransactionType.TRANSFER === transactionData?.activity
												&& transactionData?.amount < 0
											)
										) &&
											<Grid item xs={12} >
												<TransferOutgoing details={transactionData} />
											</Grid>
										}
										{(
											TransactionType.VAULT_DEPOSIT === transactionData?.activity ||
											TransactionType.DEPOSIT === transactionData?.activity || (
												TransactionType.TRANSFER === transactionData?.activity
												&& transactionData?.amount > 0
											)) &&
											<Grid item xs={12} >
												<TransferIncoming details={transactionData} />
											</Grid>
										}
										{TransactionType.CARD_TOP_UP === transactionData?.activity &&
											<Grid item xs={12} >
												<CardTopUp details={transactionData} />
											</Grid>
										}
										{TransactionType.TRADE === transactionData?.activity &&
											<Grid item xs={12} >
												<CardTopUp details={transactionData} />
											</Grid>
										}
										{transactionData?.fees?.length > 0 &&
											<DetailLine
												label={t('transaction.type.CARD_OPERATION_FEE')}
												value={`${transactionData?.currency ? getCurrencySign(transactionData.currency) : ''}${transactionData.totalFeeAmount}`}
											/>
										}
										{transactionData?.attachments?.length > 0 &&
											<Grid item xs={12} >
												<Attachments transaction={transactionData} />
											</Grid>
										}
									</Grid>
								</FormContentBox>
							</Grid>
						</Grid>
					}
					{!isFeeTrx &&
						<Grid container spacing={2} mt='2.25rem'>
							<Grid item xs={6}>
								<Tooltip title={canExport || !showTooltip ? '' : t(`transactiondetails.BTN.disabled.share.${transaction.status}`)}>
									<span>
										<Button
											type="submit"
											variant="customOutlined"
											color='secondary'
											disabled={!canExport}
											onClick={openSendTrxForm}
										>
											{t('transactiondetails.BTN.share')}
										</Button>
									</span>
								</Tooltip>
							</Grid>
							<Grid item xs={6} container justifyContent="flex-end">
								<Tooltip title={canExport || !showTooltip ? '' : t(`transactiondetails.BTN.disabled.export.${transaction.status}`)}>
									<span>
										<Button
											type="submit"
											variant="contained"
											color='primary'
											disabled={!canExport}
											onClick={() => downloadTrxPdf(transactionData)}
										>
											{t('transactiondetails.BTN.export')}
										</Button>
									</span>
								</Tooltip>
							</Grid>
						</Grid>}
				</Grid>
			</DialogContentNoScrollbar>
		</Dialog >
	</>
	);
};

export default TransactionDetailsForm; 
