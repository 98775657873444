


import * as React from 'react';
import { SvgIcon, useTheme } from '@mui/material';

const SummaryCardIcon = (props: any) => {
    const theme = useTheme();

    return (
        <SvgIcon
            sx={{
                width: 64,
                height: 64,

            }}
            {...props}>
            <svg
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M14.9336 9.55469L17.636 7.90768L19.1552 5.33307L20.8022 7.90768L23.3768 9.55469L20.8022 11.0739L19.1552 13.7763L17.636 11.0739L14.9336 9.55469Z"
                    fill={theme.highlight} />
                <path d="M52.8516 14.9688L54.5406 13.9394L55.4901 12.3302L56.5195 13.9394L58.1286 14.9688L56.5195 15.9183L55.4901 17.6073L54.5406 15.9183L52.8516 14.9688Z"
                    fill={theme.highlight} />
                <path d="M6.23047 16.0234L7.58168 15.1999L8.34128 13.9126L9.16478 15.1999L10.4521 16.0234L9.16478 16.783L8.34128 18.1342L7.58168 16.783L6.23047 16.0234Z"
                    fill={theme.highlight}  />
                <path fillRule="evenodd" clipRule="evenodd" d="M12.8017 30.25C9.25214 32.4125 7.59862 36.8443 9.07476 40.8999L13.0193 51.7376C14.7019 56.3603 19.8133 58.7438 24.436 57.0613L33.9693 53.5914H23.8424C17.7448 53.5914 12.8017 48.6483 12.8017 42.5507V30.25Z"
                   fill={theme.highlight} />
                <path fillRule="evenodd" clipRule="evenodd" d="M14.9336 26.9621C14.9336 22.0427 18.9216 18.0547 23.841 18.0547H43.8826C48.802 18.0547 52.79 22.0427 52.79 26.9621V42.55C52.79 47.4694 48.802 51.4574 43.8826 51.4574H23.841C18.9216 51.4574 14.9336 47.4694 14.9336 42.55V26.9621ZM33.9876 29.189C33.9876 30.4188 32.9906 31.4158 31.7608 31.4158H25.0802C23.8504 31.4158 22.8534 30.4188 22.8534 29.189C22.8534 27.9591 23.8504 26.9621 25.0802 26.9621H31.7608C32.9906 26.9621 33.9876 27.9591 33.9876 29.189Z"
                    fill="url(#paint0_linear_8521_171988)" />
                <defs>
                    <linearGradient id="paint0_linear_8521_171988" x1="24.0573" y1="10.8174" x2="89.598" y2="41.2149" gradientUnits="userSpaceOnUse">
                        <stop stopColor={theme.iconGradient.stopColor1} />
                        <stop offset="1" stopColor={theme.iconGradient.stopColor2} />
                    </linearGradient>
                </defs>
            </svg>
        </SvgIcon>
    );
};
export default SummaryCardIcon;
