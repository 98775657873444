import React from 'react';
import { Grid } from '@mui/material';
import MuiTextCaption from '../MuiText/MuiTextCaption';
import MuiTextLabel from '../MuiText/MuiTextLabel';
import './Avatars.css';
import TokenAvatar, { TokenAvatarSizeEnum } from './TokenAvatar';

interface Props {
	symbol: string,
	logo: string,
	name?: string,
	currency?: string,
	withCurrency?: boolean,
	withName?: boolean,
	variant: TokenAvatarSizeEnum,
	symbolProps?: any,
	nameProps?: any,
	additionalAvatarClassName?: string,
}

const TokenAvatarStacked = ({ symbol, logo, name, currency, withCurrency, withName, variant, symbolProps, nameProps, additionalAvatarClassName  }: Props): React.ReactElement => {

	return (
		<Grid container alignItems='center' wrap='nowrap'>
			<Grid item>
				<TokenAvatar symbol={symbol} logoSource={logo} variant={variant} additionalClassName={additionalAvatarClassName}/>
			</Grid>
			<Grid item pl='0.5rem' >
				<MuiTextCaption {...symbolProps}>{symbol} </MuiTextCaption>
				{withCurrency && <MuiTextLabel >{`(${name} ${currency})`}</MuiTextLabel>}
				{withName && <MuiTextLabel {...nameProps} >{name}</MuiTextLabel>}
			</Grid>
		</Grid>);
};

export default TokenAvatarStacked;
