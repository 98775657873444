import React from 'react'; 
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Transaction } from '../TransactionStatements.spec';
import DetailLine from '@shared/Widgets/DetailLine';


interface Props {
	details: Transaction,
	cardNumberMask?: string,
}

const CardTopUp = ({ details }: Props) => {
	const { t } = useTranslation('translations');

	return (
		<Grid container>
			<DetailLine
				label={t('transactiondetails.main.status')}
				value={t('status.' + details.status)}
			/>
			{details?.remitterAccount &&
				<DetailLine
                    label={t('transactiondetails.remitter.fromAccount')}
					value={`${details.remitterAccount}`}
				/>
			}
			{
				details?.cardNumberMask ? (
					<DetailLine
						label={t('transactiondetails.beneficiary.toCard')}
						value={`${details.cardNumberMask}`}
					/>
				) : (
					details?.beneficiaryAccount &&
					<DetailLine
                        label={t('transactiondetails.beneficiary.toAccount')}
						value={`${details.beneficiaryAccount}`}
					/>
				)
			}
		</Grid>
	);
};

export default CardTopUp;
