import IOSSwitch from '@/componentsMui/Shared/Widgets/IOSSwitch';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hideBalance } from '@/features/security/slice';
import { showErrorNotification } from '@/features/swal/slice';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import MuiFormLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiFormLabel';
import { User } from '@/features/user/types';

interface Props {
	user: User
}
const AccountBalance = ({ user }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [disabled, setDisabled] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);


	useEffect(() => {
		if (user) {
			setDisabled(!user.hideValues);
		}
	}, [user]);

	const toggle = async () => {
		setLoading(true);
		try {
			await hideBalance({ hideValues: disabled });
		} catch (e) {
			showErrorNotification(e);
		} finally { setLoading(false); }
	};

	return (
		<Grid container alignItems='center' rowGap={2} sx={{ backgroundColor: '#1D2024',  padding: '30px', borderRadius: '10px' }}>
			<Grid item xs={8} sm={8}>
				<MuiTextCaption fontSize='1.125rem'>{t('balance.data.header')}</MuiTextCaption>
				<MuiFormLabel color='text.secondary' fontSize='0.875rem'>{t('balance.data.header')}</MuiFormLabel>
			</Grid>
			<Grid item xs={4} container justifyContent='flex-end'>
				<IOSSwitch checked={!disabled} onChange={toggle} disabled={loading} />
			</Grid>
		</Grid>

	);
};
export default AccountBalance;
