import { connectVaultSocket, getVaults, subscribeVaultSocket, unsubscribeVaultSocket } from '@/features/earn/slice';
import { AccountType } from '@/features/operations/types';
import { RootState } from '@/rootReducer';
import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ServiceLoading from '../ServiceLoading';
import VaultListNew from './Components/VaultListNew';
import VaultUserDeposits from './Components/Statistics/VaultUserDeposits';
import InterestEarnings from './Components/InterestEarnings';
import PDFViewer from '../Shared/Components/PDFViewer';
import EarnFooter from '../Footer/EarnFooter';
import { getAcceptedDisclaimers } from '@/features/disclaimer/disclaimerSlice';
import StatusCard from '../Dashboard/HomeSummary/Components/StatusCard';


const Earn = (): React.ReactElement => {
	const dispatch = useDispatch();
	const { list, loading } = useSelector((state: RootState) => state.vaults);
	const searchParams = new URLSearchParams(location.search);
	const currentTab = searchParams.get('tab');
	const { user } = useSelector((state: RootState) => state.user);
	const { agent } = useSelector((state: RootState) => state.status);
	const showPolicy = ['IDEALOOP','CUSTODYFY', 'SALAZAR', 'CACTUS', 'DTS'].includes(agent);

	useEffect(() => {
		dispatch(getVaults());
	}, [dispatch]);

	useEffect(() => {
		dispatch(getAcceptedDisclaimers());

	}, [dispatch]);

	useEffect(() => {
		connectVaultSocket();
		dispatch(subscribeVaultSocket());
		return () => {
			unsubscribeVaultSocket();
		};
	}, [dispatch]);



	return (
		<Grid container
			width='100%'
			flexDirection='column'
			flex={1} >
			<Grid item xs>
				<Grid item xs={12} justifyContent='center' >
					<StatusCard />
				</Grid>
				<ServiceLoading
					type={AccountType.VAULT}
					loading={loading}>
					<Grid id="vault-service"
						container
						width='100%'
						height='100%'
						alignContent='space-between'>
						<Grid container item xs={12}>
							{currentTab === 'earn' && <VaultListNew vaults={list} />}
							{currentTab === 'deposits' && <VaultUserDeposits />}
							{currentTab === 'earnings' && <InterestEarnings />}
							{currentTab === 'terms' && <PDFViewer file={user.vaultTermsLink} />}
						</Grid>
					</Grid>
				</ServiceLoading>
			</Grid>
			{showPolicy &&
				<Grid item xs='auto'   >
					<EarnFooter />
				</Grid>
			}
		</Grid >
	);
};

export default Earn;
