import { Grid, IconButton, Typography } from '@mui/material';
import * as React from 'react';
import { convertDate } from '@/helpers/date';
import { TransactionStatus } from '@/features/transactions/types';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import './TradeHistory.css';
import { useTranslation } from 'react-i18next';
import TransactionStatusBox from '@/componentsMui/Shared/Widgets/Box/TransactionStatusBox';
import { WalletTransaction, WalletTransactionType } from '@/features/walletHistory/types';
import MuiCopyToClipboard from '@/componentsMui/Shared/Widgets/MuiCopyToClipboard';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ClickableTooltip from '@shared/Widgets/ClickableTooltip';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { formatDecimalFlex } from '@/helpers/number';

interface Props {
	item: WalletTransaction,
	type: WalletTransactionType
}
const WalletHistoryLine = ({ item, type }: Props) => {

	const { t } = useTranslation('translations');

	const { agentName } = useSelector(
		(state: RootState) => state.status
	);

	const time = item?.date && new Date(item.date);
	const fullDestination = type === WalletTransactionType.CRYPTO_WITHDRAWALS || type === WalletTransactionType.TOKEN_WITHDRAWALS ? item.beneficiaryAccount : item.details;
	const shortDestination = fullDestination && fullDestination.length > 10 ? `${fullDestination.substr(0, 5)}...${fullDestination.substr(-5)}` : fullDestination;

	const isWithdrawal = type === WalletTransactionType.CRYPTO_WITHDRAWALS || type === WalletTransactionType.TOKEN_WITHDRAWALS;
	const isInternalTransfer = item.activity === 'TRANSFER';
	const isVaultTransfer = item.activity === 'INTEREST_PAYMENT' || item.activity === 'DEPOSIT_REPAYMENT';

	//const decimalScale = item?.decimals || 5;

	return (
		<Grid container alignItems='center' height='4rem' width='100%' className='wallet-history-line' >
			<Grid item className='history-line__time'>
				<Typography fontSize='0.6rem' color='text.primary' lineHeight='1rem'>
					{convertDate(time, 'dd MMM yyyy')}
				</Typography>
				<Typography fontSize='0.6rem' color='text.secondary'>
					{convertDate(time, 'HH:mm:ss')}
				</Typography>
			</Grid>
			<Grid item className='history-line__coin'>
				<MuiTextCaption>
					{item.asset}
				</MuiTextCaption>
			</Grid>
			<Grid item className='history-line__type'>
				<MuiTextCaption>
					{t(`tokens.activityTypes.${item.activity}`)}
				</MuiTextCaption>
			</Grid>
			<Grid item className='history-line__amount'>
				<MuiTextCaption>
					{formatDecimalFlex(item.amount, { maximumFractionDigits: item.decimals })} {item.asset}

					{/* <NumberFormat displayType={'text'}
						decimalScale={decimalScale}
						fixedDecimalScale={true}
						thousandsGroupStyle='thousand'
						thousandSeparator={true}
						suffix={` ${item.asset}`}
						isNumericString
						value={item.amountString} /> */}
				</MuiTextCaption>
			</Grid>
			<Grid item className='history-line__destination'>
				{isInternalTransfer ?
					<MuiTextCaption variant='gradient' fontWeight='400'>
						{isWithdrawal ? item.beneficiary : item.remitterName}
					</MuiTextCaption>
					: <>
						{isVaultTransfer ? <MuiTextCaption variant='gradient' fontWeight='400'>
							{agentName}
						</MuiTextCaption> : <Grid display={'flex'} flexWrap='nowrap'>
							<ClickableTooltip title={fullDestination ? fullDestination : ''}
								placement='top'
								style={{ display: 'inline' }}
								arrow
							>
								<MuiTextCaption variant='gradient' fontWeight='400'>{shortDestination}</MuiTextCaption>
							</ClickableTooltip>
							<MuiCopyToClipboard value={fullDestination} />
						</Grid>}
					</>}
			</Grid>
			<Grid item className='history-line__trxId'>
				{item.scannerUrl && item.txHash && <a style={{ textDecoration: 'unset' }} href={`${item.scannerUrl}${item.txHash}`} target='_blank' rel='noreferrer'>
					<MuiTextCaption variant='gradient' fontWeight='400'>
						{t('tokens.cryptoHistory.viewOnExplorer')}
						<IconButton>
							<OpenInNewIcon style={{ width: '1rem', height: '1rem' }} />
						</IconButton>
					</MuiTextCaption>
				</a>}
			</Grid>
			<Grid item className='history-line__status'>
				<TransactionStatusBox
					status={item.status as TransactionStatus}>
					{t('status.' + item.status)}
				</TransactionStatusBox>
			</Grid>
		</Grid >);
};

export default WalletHistoryLine;
