
import * as React from 'react';
import '@/componentsMui/Shared/Shared.css';
import { Button, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { editContact } from '@/features/contacts/slice';
import { useDispatch } from 'react-redux';
import { showException, showSuccess } from '@/features/swal/slice';
import { Contact, ContactPaymentTypes } from '@/features/contacts/types';
import { isEmpty } from 'lodash';
import MuiSubHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiSubHeader';
import MuiTextTrx from '@/componentsMui/Shared/Widgets/MuiText/MuiTextTrx';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';


interface Props {
	contactToEdit: Contact
}

const EditCryptoContact = ({ contactToEdit }: Props) => {

	const { t } = useTranslation('translations');
	const closeModal = useCloseDialogNoEvent();

	const dispatch = useDispatch();

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(true);

		try {
			const { name } = formData;

			const payload = {
				beneficiaryAccountId: contactToEdit.beneficiaryAccountId,
				name,
				bic: contactToEdit.cryptoNetwork,
				paymentType: ContactPaymentTypes.CRYPTO,
			};

			await dispatch(editContact(contactToEdit.beneficiaryAccountId, { ...contactToEdit, ...payload }));
			showSuccess({ title: 'contacts.update.success', mui: true });

		} catch (e) {
			showException(e);
		}
		finally {
			setSubmitting(false);
			closeModal();
		}
	};

	const validationSchema = Yup.object({
		name: Yup.string().required(t('form.validator.required'))
	});


	const initialValues = {
		name: contactToEdit?.name ?? '',
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={submit}

		>
			{({ errors, isSubmitting, dirty }) => {
				return (
					<Form className='portal-dialog-form-template'>
						<Grid container sx={{ height: '100%' }} >
							<Grid id="contentWrap"
								item
								spacing={2}
								container
								alignSelf="flex-start" >
								<Grid item xs={12}  >
									<MuiSubHeader>Crypto</MuiSubHeader>
									<MuiTextTrx marginTop="0.5rem" >{t('tokens.header.externalWallet')}</MuiTextTrx>
								</Grid>
								<Grid item xs={12}>
									<MuiTextField
										id="name"
										name="name"
										label={t('contacts.create.sepaName')}
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										label={t('tokens.header.withdrawalAddress')}
										value={contactToEdit?.account ?? ''}
										disabled
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										label={t('tokens.cryptoHistory.coin')}
										value={contactToEdit?.ccy ?? ''}
										disabled
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										label={t('tokens.header.network')}
										value={contactToEdit?.cryptoNetwork ?? ''}
										disabled
										fullWidth
									/>
								</Grid>
							</Grid>
							<Grid item container mt='2rem' alignSelf="flex-end" justifyContent="center">
								<Grid item>
									<Button disabled={isSubmitting || !isEmpty(errors) || !dirty}
										key="buttonNext"
										fullWidth
										variant='contained'
										color='primary'
										type="submit" >
										{t('contacts.create.save')}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Form>
				);
			}}
		</Formik >);
};

export default EditCryptoContact;
