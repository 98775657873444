import React, { useState } from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DeviceInfo, LoginTypeEnum } from '@/features/user/types';
import EmailPhoneLogin from './EmailPhoneLogin';
import LoginQR from './LoginQR';

interface Props {
	deviceInfo: DeviceInfo,
	isOauthLogin?: boolean,
	onCancel?: () => void,
}


export enum IframeLoginTypeEnum {
	EMAIL = 'EMAIL',
	PHONE = 'PHONE',
	QR = 'QR',
}


const LoginIframe = ({ deviceInfo, isOauthLogin = false, onCancel }: Props): React.ReactElement => {

	const { t } = useTranslation('translations');
	const [currentTab, setCurrentTab] = useState(IframeLoginTypeEnum.EMAIL);
	const handleChange = (event: React.SyntheticEvent, newValue: IframeLoginTypeEnum) => {
		setCurrentTab(newValue);
	};


	return (
		<Grid container maxWidth='360px'>
			<Grid item xs={12} >
				<Tabs value={currentTab}
					onChange={handleChange}
					centered
				>
					<Tab value={IframeLoginTypeEnum.EMAIL}
						label={t('form.fields.email')}
						sx={{ width: '30%', fontWeight: 400 }}
						test-id='email-tab'
					/>
					<Tab value={IframeLoginTypeEnum.PHONE}
						label={t('form.fields.phone')}
						sx={{ width: '30%', fontWeight: 400 }}
						test-id='phone-tab'
					/>
					<Tab value={IframeLoginTypeEnum.QR}
						label='QR'
						sx={{ width: '30%', fontWeight: 400 }}
						test-id='QR-tab'
					/>
				</Tabs>
			</Grid>
			<Grid item xs={12} container mt={{ xs: '10px' }}>
				{currentTab === IframeLoginTypeEnum.EMAIL && <EmailPhoneLogin deviceInfo={deviceInfo} type={LoginTypeEnum.EMAIL} isOauthLogin={isOauthLogin} onCancel={onCancel} onError={onCancel} />}
				{currentTab === IframeLoginTypeEnum.PHONE && <EmailPhoneLogin deviceInfo={deviceInfo} type={LoginTypeEnum.PHONE} isOauthLogin={isOauthLogin} onCancel={onCancel} onError={onCancel} />}
				{currentTab === IframeLoginTypeEnum.QR && <LoginQR deviceInfo={deviceInfo} isOauthLogin={isOauthLogin} />}
			</Grid>
		</Grid>
	);
};

export default LoginIframe;
