import React from 'react';
import { Avatar, Grid } from '@mui/material';
import MuiTextCaption from '../MuiText/MuiTextCaption';
import './Avatars.css';
import { TokenAvatarSizeEnum } from './TokenAvatar';
import { CoinInfoType } from '@/features/coins/types';



interface Props {
	coin: CoinInfoType,
	variant: TokenAvatarSizeEnum
}

const CoinAvatar = ({ coin, variant }: Props): React.ReactElement => {

	return (
		<Grid id='fiat-avatar' container alignItems='center'>
			<>
				<Grid item>
					<Avatar className={'wallet avatar-rounded asset-avatar ' + variant?.toString()} src={coin.logo} />
				</Grid>
				<Grid item pl='0.5rem' >
					<MuiTextCaption>{coin?.symbol} </MuiTextCaption>
				</Grid>
			</>
		</Grid>);
};

export default CoinAvatar;
