import { Divider, Grid } from '@mui/material';
import React, { } from 'react';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';

interface Props {
    feeTitle: string;
    feeDetails: string;
    hideDivider?: boolean;
}

const FeeLine = ({
    feeTitle,
    feeDetails,
    hideDivider,
}: Props) => {

    return (
        <>
            {!hideDivider && < Divider />}
            <Grid container
            >
                <Grid item xs={12} minHeight='4rem' container alignItems='center'>
                    <Grid item xs={5} sm={4} >
                        <MuiFormText>{feeTitle}</MuiFormText>
                    </Grid>
                    <Grid item xs={5} sm={4}>
                        <MuiFormText>{feeDetails}</MuiFormText>
                    </Grid>
                </Grid>
            </Grid >
        </>
    );
};
export default FeeLine;
