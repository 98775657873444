import { useTheme } from '@mui/material';
import React from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis, YAxisProps } from 'recharts';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';


export const emptyChartData =
    [{
        totalDiff: 0,
        totalDiffPerc: 0,
        total: 0,
        xLabel: '',
        date: ''

    },
    {
        totalDiff: 0,
        totalDiffPerc: 0,
        total: 0,
        xLabel: '',
        date: ''

    }];

interface Props {
    chartData: any[],
    loading: boolean,
    dataKey?: string,
    strokeWidth?: number,
    yAxisProps?: YAxisProps,
    chartTooltip: (tdata: any, ...others) => React.ReactElement
}

const TotalAreaChart = ({ chartData, loading, dataKey, yAxisProps, strokeWidth, chartTooltip }: Props): React.ReactElement => {

    const theme = useTheme();

    if (loading) return <Spinner />;

    const data = !loading && chartData.length < 2 ? emptyChartData : chartData;

    return (
        <ResponsiveContainer width='99%'  >
            <AreaChart
                width={730}
                height={250}
                data={data}
                stackOffset="none"
                margin={{
                    top: 3,
                    right: 20,
                    left: 30,
                    bottom: 0,
                }}
            >
                <defs>
                    <linearGradient id='line-gradient' x1='.5' x2='.5' y2='1'>
                        <stop stopColor={theme.highlight} />
                        <stop offset=".483" stopColor={theme.highlight} stopOpacity={0.4} />
                        <stop offset="1.034" stopColor="black" stopOpacity={0} />
                    </linearGradient>
                </defs>

                <XAxis hide={true} />
                <YAxis hide={true} {...yAxisProps}
                />
                <Tooltip content={chartTooltip}
                    cursor={{ stroke: '#959799', strokeWidth: 1, strokeDasharray: '5,5' }}

                />
                <Area
                    dataKey={dataKey ? dataKey : 'total'}
                    type='monotone'
                    strokeWidth={strokeWidth ? strokeWidth : 3}
                    stroke={theme.highlight}
                    fill='url(#line-gradient)'
                >
                </Area>
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default TotalAreaChart;
