
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';
import './Cover.css';

interface Props {
	baseBg: string,
	firstBg: string,
	secondBg: string,
	children?: React.ReactNode
}

const Cover = (props: Props) => {
	const theme = useTheme() as Theme;

	return (
		<div className='cover-main-wrap'>
			<div className='cover-container' style={{ backgroundColor: props.baseBg, borderRadius: theme.mainBorderRadius }} >
				<div className='eclipse1' style={{ background: props.firstBg }} />
				<div className='eclipse2' style={{ background: props.secondBg }} />

			</div >
			<div className='cover-child-wrap' >  {props.children}</div>
		</div>);
};

export default Cover;
