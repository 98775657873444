
import React from 'react';
import { ToggleButton, ToggleButtonGroupProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StyledToggleButtonGroup from '@/componentsMui/Shared/Widgets/StyledToggleButtonGroup';
import { CryptoChartPeriodEnum } from '@/features/coins/types';

const PeriodButtonGroupCrypto = ({ sx = {}, ...others }: ToggleButtonGroupProps) => {
    const { t } = useTranslation('translations');

    return (
        <StyledToggleButtonGroup
            exclusive
            sx={{
                ...sx
            }}
            {...others} >
            <ToggleButton value={CryptoChartPeriodEnum.DAY}>{t('form.buttons.period.day')}</ToggleButton>
            <ToggleButton value={CryptoChartPeriodEnum.WEEK}>{t('form.buttons.period.week')}</ToggleButton>
            <ToggleButton value={CryptoChartPeriodEnum.MONTH}>{t('form.buttons.period.month')}</ToggleButton>
            <ToggleButton value={CryptoChartPeriodEnum.YEAR}>{t('form.buttons.period.year')}</ToggleButton>
        </StyledToggleButtonGroup>
    );
};

export default PeriodButtonGroupCrypto;
