import { TFunction } from 'react-i18next';
import { WalletTab } from '@/componentsMui/Wallet/Portfolio';
import { MyAccountPageTab } from '@/componentsMui/Dashboard/MyAccount/MyAccountPage';
import { User, UserType } from '@/features/user/types';
import { Card } from '@/features/card/types';
import { BankAccount } from '@/features/account/types';
import { hasSubprocessWithType } from '@/features/operations/slice';

export interface TabType {
	key: string;
	label: string;
	resetTabHome?: boolean;
	home?: string
}

export interface TabList {
	[key: string]: TabType[];
}

export const tabList = (t: TFunction<'translations'>, type: UserType, user: User, showCardBenefits: boolean, showBulkPayments: boolean) => {
	const tablist = {
		'/wallet/home/tokens': [
			{
				key: 'list',
				label: t('tabs.tokens.tokens'),
			},
			{
				key: 'transactions',
				label: t('tabs.tokens.history'),
			}
		],
		'/wallet/home/earn': [
			{
				key: 'earn',
				label: t('tabs.earn.earn'),
			},
			{
				key: 'deposits',
				label: t('tabs.earn.deposits'),
			},
			{
				key: 'earnings',
				label: t('tabs.earn.earnings'),
			},
			{
				key: 'terms',
				label: t('tabs.earn.terms'),
			}
		],
		'/wallet/home/accounts': [
			{
				key: 'accounts',
				label: t('tabs.accounts.accounts'),
			},
			{
				key: 'transactions',
				label: t('tabs.accounts.transactions'),
			},
			...(showBulkPayments ? [{
				key: 'bulk-payments',
				label: 'Bulk payments',
			}] : []),
		],
		'/wallet/home/wallets': [
			{
				key: WalletTab.BALANCES,
				label: t('wallet.tabs.' + WalletTab.BALANCES),
				resetTabHome: true,
			},
			{
				key: WalletTab.TRADES,
				label: t('wallet.tabs.' + WalletTab.TRADES),
				resetTabHome: true,
			},
			{
				key: WalletTab.DEPOSITS,
				label: t('wallet.tabs.' + WalletTab.DEPOSITS),
				resetTabHome: true,
			},
			{
				key: WalletTab.WITHDRAWALS,
				label: t('wallet.tabs.' + WalletTab.WITHDRAWALS),
				resetTabHome: true,
			},
			{
				key: WalletTab.TRANSACTIONS,
				label: t('wallet.tabs.' + WalletTab.TRANSACTIONS),
				resetTabHome: true,
			},
		],
		'/wallet/account': [
			{
				key: MyAccountPageTab.MY_ACCOUNT,
				label: t('tabs.myAccount.' + MyAccountPageTab.MY_ACCOUNT),
				resetTabHome: true,
			},
			{
				key: MyAccountPageTab.PLANS,
				resetTabHome: true,
				label: t('tabs.myAccount.' + MyAccountPageTab.PLANS),
			},
			{
				key: MyAccountPageTab.SECURITY,
				label: t('tabs.myAccount.' + MyAccountPageTab.SECURITY),
				resetTabHome: true,
			},
			{
				key: MyAccountPageTab.CONTACTS,
				label: t('tabs.myAccount.' + MyAccountPageTab.CONTACTS),
				resetTabHome: true,
			},
			{
				key: MyAccountPageTab.FEE_LIMITS,
				label: t('tabs.myAccount.' + MyAccountPageTab.FEE_LIMITS),
				resetTabHome: true,
			},
			{
				key: MyAccountPageTab.REFERRALS,
				label: t('tabs.myAccount.' + MyAccountPageTab.REFERRALS),
				resetTabHome: true,
			},
			...(showCardBenefits ? [{
				key: MyAccountPageTab.BENEFITS,
				label: t('tabs.myAccount.' + MyAccountPageTab.BENEFITS),
				resetTabHome: true,
			}] : []),
		]
	};

	if (type === UserType.EMPLOYEE) {
		tablist['/wallet/account'] = tablist['/wallet/account'].filter(obj => obj.key !== MyAccountPageTab.PLANS
			&& obj.key !== MyAccountPageTab.REFERRALS
			&& obj.key !== MyAccountPageTab.CONTACTS);
	}

	if (user?.onlySkipKycUsers || user?.tabs?.hidePlans) {
		tablist['/wallet/account'] = tablist['/wallet/account'].filter(obj => obj.key !== MyAccountPageTab.PLANS);
	}

	if (user?.tradesDisabled) {
		tablist['/wallet/home/wallets'] = tablist['/wallet/home/wallets'].filter(obj => obj.key !== WalletTab.TRADES && obj.key !== WalletTab.TRANSACTIONS);
	}

	if (!user.tabs.showReferrals) {
		tablist['/wallet/account'] = tablist['/wallet/account'].filter(obj => obj.key !== MyAccountPageTab.REFERRALS);
	}

	if (!user.vaultTermsLink) {
		tablist['/wallet/home/earn'] = tablist['/wallet/home/earn'].filter(obj => obj.key !== 'terms');
	}

	return tablist;
};

export const getPathTabs = (pathName: string, t: TFunction<'translations'>, user: User, cardList: Array<Card>, bankList: Array<BankAccount>): Array<TabType> => {
	const showCardBenefits = cardList ? cardList.some(c => c?.process?.config?.business === false) : false;
	const showBulkPayments = bankList ? bankList.some(a => hasSubprocessWithType('BULK_PAYMENT', a?.subProcesses)) : false;

	const barTabs = tabList(t, user.type, user, showCardBenefits, showBulkPayments);

	Object.keys(barTabs).filter(key => pathName.includes(key));
	const filteredTabs = Object.keys(barTabs)
		.filter(key => pathName.includes(key))
		.reduce((obj: TabList, key) => {
			obj[key] = barTabs[key].map(p => ({ ...p, home: key }));
			return obj;
		}, {});

	return Object.values(filteredTabs)[0];
};
