import { Typography, TypographyProps } from '@mui/material';
import React, { FC } from 'react';



const MuiTextLabel: FC<TypographyProps> = (props: TypographyProps) => {
	return (
		<Typography
			fontSize='0.75rem'
			fontWeight='700'
			lineHeight='1rem' 
			color='text.secondary'
			{...props} >
			{props.children}
		</Typography >
	);
};

export default MuiTextLabel;
