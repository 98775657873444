import React from 'react';


import { Vault } from '@/features/earn/types';

import { Grid } from '@mui/material';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import SearchField from '@/componentsMui/Shared/Widgets/SearchField';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import { useTranslation } from 'react-i18next';
import VaultListCard from './VaultListCard';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import CustodifyErnAnnouncement from '@/pages/CustodifyErnAnnouncement';


interface Props {
	vaults: Vault[],
}


const VaultListNew = ({ vaults }: Props): React.ReactElement => {
	const [search, setSearch] = React.useState<string>('');
	const { t } = useTranslation('translations');

	const { agent } = useSelector((state: RootState) => state.status);

	const handleSearchRequest = (e) => {
		setSearch(e.target.value);
	};

	const filteredVaults = search === '' ? vaults : vaults.filter(v => v?.name.toUpperCase().includes(search.toUpperCase()));


	return (
		<Grid container pr={0.5}>
			{['CUSTODYFY', 'SALAZAR', 'CACTUS', 'DTS'].includes(agent) && <CustodifyErnAnnouncement />}
			<Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center' >
				<MuiHeader>{t('vaults.vaultListTitle')}</MuiHeader>
				<SearchField value={search}
					onChange={(e) => handleSearchRequest(e)} />
			</Grid>

			<Grid item container xs={12} style={{ overflowY: 'auto' }} spacing='10px' mt='1rem'>
				{filteredVaults.length > 0 && filteredVaults?.map((item) => (
					<Grid key={`${item?.proc}`} item xs={12} md={6} >
						<VaultListCard vault={item} />
					</Grid>))}
				{filteredVaults.length === 0 && <Grid item xs={12} display='flex' justifyContent='center'>
					<MuiTextCaption color='text.secondary' textAlign='center' pt={2} >{t('vaults.plans.nothingFound')}</MuiTextCaption>
				</Grid>}
			</Grid>
		</Grid >
	);
};

export default VaultListNew;

