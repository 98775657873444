import { Add, Remove, Autorenew } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { TransactionType } from '@features/transactions/types';


interface Props {
    amount: number,
    activity: TransactionType,
}

const SignIcon = ({ amount, activity }: Props) => {


    return (< Box sx={{
        width: '1.875rem',
        minWidth: '1.875rem', 
        height: '1.875rem', 
        background: 'rgba(255, 255, 255, 0.3)',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        {activity === TransactionType.TRADE ?
            <Autorenew sx={{ color: '#000000', fontSize: 17 }} />
        : amount < 0 ?
            <Remove sx={{ color: '#000000', fontSize: 12 }} /> :
            <Add sx={{ color: '#000000', fontSize: 12 }} />}
    </ Box>);
};

export default SignIcon;
