import React, { useState } from 'react';

import { Autocomplete, Box, InputAdornment, Popper, TextField, createFilterOptions, useTheme } from '@mui/material';
import { CountryPhoneCodesType } from '@/features/fnd/fndTypes';
import CountryImage from './CountryImage';
import { useField } from 'formik';


interface Props {
	name: string,
	smsCountries: CountryPhoneCodesType[]
}

const PopperCountry = function (props) {
	return (<Popper {...props} style={{ width: 250 }} placement='bottom-start' />);
};

const SmsCountryDropdown = ({ name, smsCountries }: Props) => {
	const theme = useTheme();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField(name);
	const { setValue } = helpers;
	const [countryImageCode, setCountryImageCode] = useState('');

	const countryOptions = smsCountries.map(p => ({
		key: p.countryName,
		code: p.phoneCode,
		countryCodeAlpha2: p.countryCodeAlpha2,
		label: `${p.phoneCode}`,
		longLabel: `${p.phoneCode} ${p.countryName}`,
	}));

	const filterOptions = createFilterOptions({
		stringify: (option: any) => option?.longLabel,
	});

	return (
		<Autocomplete
			id="country-autocomplete"
			PopperComponent={PopperCountry}
			options={countryOptions}
			onChange={(e, value) => {
				setValue(typeof value === 'object' ? value.code : '');
				setCountryImageCode(typeof value === 'object' ? value.countryCodeAlpha2 : '');
			}}
			disableClearable
			fullWidth
			sx={{ width: '100%', display: 'flex', alignContent: 'center', pr: '10px' }}
			isOptionEqualToValue={(option, value) => value === undefined || option.code === value.code}
			renderOption={(props, option) => (
				<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.key}>
					<CountryImage countryCodeAlpha2={option?.countryCodeAlpha2} />
					{option.longLabel}
				</Box>
			)}
			filterOptions={filterOptions}
			renderInput={(params) => (
				<TextField
					className='registration-input country-selection'
					fullWidth
					name={name}
					{...params}
					inputProps={{
						...params.inputProps,
						autoComplete: 'off',
						background: 'red',
					}}
					test-id='phone-code'
					InputLabelProps={{ style: { color: theme.palette.text.primary, textAlign: 'left' } }}
					//error={errors.phoneCode && touched.phoneCode}
					InputProps={{
						...params.InputProps,
						startAdornment:
							<InputAdornment position='start' >
								<CountryImage countryCodeAlpha2={countryImageCode} />
							</InputAdornment>

					}}
					sx={(theme) => ({ '& .MuiOutlinedInput-root': { background: theme.backgroundBoxLevel1 } })}
				/>
			)}

		/>
	);
};

export default SmsCountryDropdown;
