import React, { useEffect } from 'react';
import { Box, Button, Grid } from '@mui/material';
import MuiHeader from '../Shared/Widgets/MuiText/MuiHeader';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import InvoiceList from './components/InvoiceList';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { getAvailablePaymentOptions } from '@/features/invoices/invoicesSlice';

const Invoices = () => {
    const { t } = useTranslation('translations');
    const dispatch = useDispatch();
    const { bankTransferAvailable, cryptoAddressesAvailable } = useSelector((state: RootState) => state.invoices.options);
    const history = useHistory();

    useEffect(() => {
        dispatch(getAvailablePaymentOptions());
    }, [dispatch]);


    const handleCreateInvoice = () => {
        history.push('/wallet/home/invoices/create');
    };

    return (<Grid container gap={2}>
        <Grid item xs={12} container justifyContent='space-between'>
            <MuiHeader>
                {t('invoice.title')}
            </MuiHeader>
            <Button variant="simple" startIcon={<AddIcon fontSize='small' />} onClick={handleCreateInvoice} disabled={!bankTransferAvailable && !cryptoAddressesAvailable}>
                {t('invoice.createBtn')}
            </Button>
        </Grid>
        <Grid item xs={12}>
            <Box
                sx={(theme) => ({
                    background: {
                        xs: 'none', sm: theme.backgroundBoxLevel1
                    },
                    borderRadius: theme.mainBorderRadius,
                    p: '20px',
                    width: '100%',
                    mb: { xs: 3, md: 0 },
                    mt: 1
                })}>
                <InvoiceList />
            </Box>
        </Grid>
    </Grid>);
};

export default Invoices;
