import React from 'react';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { FC } from 'react';
import './TextFieldHighlight.css';

const TextFieldHighlight: FC<any> = (props: any) => {

	const { sx, ...other } = props;
	return (
		<MuiTextField
			id='text-field-highlight'
			sx={theme => ({
				...sx,
				input: {
					color: theme.highlight
				}
			})}
			{...other}
		/>);
};
export default TextFieldHighlight;
