import { getAccountChartData } from '@/features/account/slice';
import { AccountChartResponse, CharTypeEnum, PeriodEnum } from '@/features/account/types';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { roundAny, roundTwo } from '@/helpers/round';
import BigAmount from '@/componentsMui/Wallet/Components/BigAmount';
import TotalAreaChart from '@/componentsMui/Shared/Components/TotalAreaChart';
import AmountDifference from '@/componentsMui/Dashboard/HomeSummary/Components/AmountDifference';
import PeriodButtonGroup from '@/componentsMui/Shared/Widgets/Chart/PeriodButtonGroup';
import WalletChartTooltip from './WalletChartTooltip';
import { format } from 'date-fns';
import axios from 'axios';


export interface WalletChartDataType {
	totalDiff: number,
	totalDiffPerc: number,
	walletAmount: number,
	total: number,
	xLabel: string,
	date: string,
}


const WalletChart = ({ totalBalance }: { totalBalance: number }): React.ReactElement => {
	const [loading, setLoading] = useState(false);
	const [walletData, setWalletData] = useState<AccountChartResponse[]>([]);
	const [period, setPeriod] = useState(PeriodEnum.MONTH);

	useEffect(() => {
		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();

		const getChartData = async () => {
			if (period) {
				try {
					const walletData = await getAccountChartData(CharTypeEnum.WALLET, period, source);
					if (walletData?.length > 0) {
						walletData[walletData?.length - 1].amount = totalBalance;
					}
					setWalletData(walletData);
					setLoading(false);
				}
				catch (e) {
					if (e) {
						setWalletData([]);
					}
				}
				finally { setLoading(false); }
			}
		};

		getChartData();
		return () => {
			if (source) {
				source.cancel('Operation cancelled');
			}
		};
	}, [period, totalBalance]);


	const handlePeriodChange = async (
		event: React.MouseEvent<HTMLElement>,
		value: string,
	) => {
		if (value) {
			const newPeriod = value as PeriodEnum;
			setPeriod(newPeriod);
		}
	};




	const chartFullData = walletData.map((p) => {

		const wamount = roundTwo(p?.amount || 0);

		let periodName = '';
		const periodDate = new Date(p.date+'Z');
		if (period === PeriodEnum.DAY) { periodName = format(periodDate, 'HH:mm'); }
		if (period === PeriodEnum.WEEK) { periodName = format(periodDate, 'dd MMM yyyy'); }
		if (period === PeriodEnum.MONTH) { periodName = format(periodDate, 'dd MMM yyyy'); }

		return {
			walletAmount: wamount,
			total: wamount,
			xLabel: periodName,
			date: p.date,

		};
	}).sort().reverse();

	// get one point per period name (e.g. month 1 point per day, week: 7 points, day: 24 points)
	const uniquePeriodData = _.uniqBy(chartFullData, (d) => {
		return d.xLabel;
	}).reverse();

	const chartData: WalletChartDataType[] = uniquePeriodData.map((p, index, array) => {

		const wamount = roundTwo(p?.walletAmount || 0);
		const prevTotal = index - 1 >= 0 ? array[index - 1].walletAmount : p.walletAmount;
		const totalDiff = roundTwo(p.walletAmount - prevTotal);

		return {
			...p,
			totalDiff: totalDiff,
			totalDiffPerc: roundAny((totalDiff / wamount * 100), 0),

		};
	});

	const lastPeriodData = chartData.length > 1 ? chartData[chartData.length - 1] : null;

	return (
		<Grid container id='wallet-summary-chart'>
			<Grid item xs={12} container
				justifyContent='space-between'
				alignItems='center'
				mb='40px'>
				<Grid item xs container rowGap='6px'>
					<BigAmount currency='€' value={totalBalance ?? 0} />
					<AmountDifference
						fontSize='0.875rem'
						totalDiff={lastPeriodData?.totalDiff ?? 0}
						totalDiffPerc={lastPeriodData?.totalDiffPerc ?? 0} />
				</Grid>
				<PeriodButtonGroup
					value={period}
					onChange={handlePeriodChange} />
			</Grid>
			<Grid item xs={12} container width='100%' height={200}  >
				<TotalAreaChart chartData={chartData} loading={loading} chartTooltip={WalletChartTooltip} />
			</Grid>
		</Grid>);
};

export default WalletChart;
