import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import BaseBox from '@/componentsMui/Shared/Widgets/Box/BaseBox';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Agents } from '@/helpers/globalTypes';
import MessageInput from './Components/MessageInput';
import { connectChat, getChatUnreadCount, getSupportChat, sendMessage, subscribeChat, unsubscribeChat } from '@/features/supportChat/slice';
import Message from './Components/Message';
import MuiTextTrx from '@/componentsMui/Shared/Widgets/MuiText/MuiTextTrx';
import { isEmpty } from 'lodash';
import { ChatStatusEnum } from '@/features/supportChat/types';
import { useTranslation } from 'react-i18next';
import { showValidationErrorMessage } from '@/features/swal/slice';
import { markNotificationsAsRead } from '@/features/tickets/slice';

const SIZE_LIMIT = 5 * 1024 * 1024;

const FAQ = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const theme = useTheme();
	const chatData = useSelector((state: RootState) => state.supportChat.data);
	const messagesEndRef = useRef<null | HTMLDivElement>(null);
	const inputFieldRef = React.useRef<HTMLInputElement>(null);
	const [file, setFile] = useState<File>(null);
	const [fileError] = useState<string>(null);
	const [inputValue, setInputValue] = useState<string>('');
	const { user } = useSelector((state: RootState) => state.user);

	const { status } = useSelector(
		(state: RootState) => state.user
	);

	const isDisabled = isEmpty(inputValue.trim()) && file === null;
	const isDts = status.source === Agents.DTSCB || status.source === Agents.DTS || status.source === Agents.DTSUS;

	useEffect(() => { if (isDts) { window.open('https://dtcirclesupport.com', '_blank'); } }, [isDts]);

	const handleFile = (files: File[] | null) => {
		if (files === null) {
			setFile(null);
			return;
		}
		if (files.length < 1) {
			showValidationErrorMessage(t('form.validator.fileTooBig'));
			return;
		}
		if (!(files[0].size <= SIZE_LIMIT)) {
			showValidationErrorMessage(t('form.validator.fileFormat'));
			return;
		}
		setFile(files[0]);
	};

	const scrollToBottom = () => {
		setTimeout(() => {
			messagesEndRef.current?.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
				inline: 'end'
			});
		}, 500);
	};

	const submitMessage = () => {
		try {
			const messagePayloadObj = {
				...inputValue !== '' && { message: inputValue.trim() },
				...chatData.chat?.id && { chatId: chatData.chat.id },
				...file && { files: file },
			};
			dispatch(sendMessage(messagePayloadObj));
			setFile(null);
			setInputValue('');
			scrollToBottom();
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		scrollToBottom();
	}, [chatData?.messages?.length]);

	const markNotifications = useCallback(async () => {
		await markNotificationsAsRead(); await dispatch(getChatUnreadCount());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		dispatch(getSupportChat({ sort: 'DESC' }));
		markNotifications();

	}, [dispatch, markNotifications]);

	useEffect(() => {
		connectChat();
		dispatch(subscribeChat());
		return () => {
			unsubscribeChat();
			markNotifications();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (isDts
		? <React.Fragment />
		: <BaseBox
			id="my-account"
			sx={{
				overflow: 'hidden',
				background: { xs: 'none', md: '#1D2024' },
				p: { xs: 0, md: '1.5rem' },
				height: 'fit-content',
				width: '100%',
			}}>
			<Grid container alignItems="center" sx={{ mb: { xs: '2rem', sm: '3rem' } }}>
				<Grid item xs={12} sm={4} sx={{ mb: { xs: '1rem', sm: 0 } }}>
					<MuiHeader>{t('faq.supportChat')}</MuiHeader>
				</Grid>
			</Grid>
			<Box px={2} py={1} height={'60vh'} overflow={'scroll'}>
				{chatData.messages.map((message, index) => (
					<Grid item key={index} container sx={{ justifyContent: { xs: 'space-between', sm: 'flex-end' } }}>
						<Message
							message={message}
							prevCreatedDate={chatData.messages[index - 1]?.createdDate || null} />
					</Grid>
				))}
				{chatData.chat?.status === ChatStatusEnum.SOLVED && !user.detained
					? <BaseBox
						border={`1px solid ${theme.backgroundBoxLevel1}`}
						sx={{
							background: theme.backgroundBoxLevel2,
							p: { xs: 2, md: '1.5rem' },
							mb: { xs: 2, md: 0 },
							mt: { xs: 0, md: 2 },
						}}>
						<MuiTextTrx>
							{t('faq.chatSolved')}
						</MuiTextTrx>
					</BaseBox>
					: null}
				<div ref={messagesEndRef} />
			</Box>
			<Grid container justifyContent='flex-end' mt='30px'>
				<MessageInput
					inputValue={inputValue}
					setInputValue={setInputValue}
					file={file}
					fileError={fileError}
					handleFile={handleFile}
					submitMessage={submitMessage}
					inputFieldRef={inputFieldRef}
					sendDisabled={isDisabled} />
			</Grid>
		</BaseBox >
	);
};

export default FAQ;
