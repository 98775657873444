import * as React from 'react';

import { Dialog, Grid, IconButton } from '@mui/material';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import DialogContentNoScrollbar from '@/componentsMui/Shared/Widgets/Dialog/DialogContentNoScrollbar';
import { useTranslation } from 'react-i18next';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import DetailLine from '@shared/Widgets/DetailLine';
import DetailsBox from '@shared/Widgets/DetailsBox';
import { WalletTransaction } from '@features/walletHistory/types';
import { convertDate } from '@helpers/date';
import NumberFormat from 'react-number-format';
import MuiTextCaption from '@shared/Widgets/MuiText/MuiTextCaption';
import { TransactionStatus } from '@features/transactions/types';
import TransactionStatusBox from '@shared/Widgets/Box/TransactionStatusBox';
import MuiFormLabel from '@shared/Widgets/MuiText/MuiFormLabel';
import StyledDivider from '@shared/Widgets/StyledDivider';
import { isFiat } from '@/helpers/currency';

interface Props {
	open: boolean,
	onClose: () => void,
	item: WalletTransaction,
}

const TradeDetailsForm = ({ open, onClose, item }: Props) => {
	const { t } = useTranslation('translations');

	if (!item) {
		return <></>;
	}

	const isSell = item.amount < 0;
	const fromAmount = isSell ? Math.abs(item.amount) : Math.abs(item.conversionAmount);
	const fromCcy = isSell ? item.currency : item.conversionCurrency;

	const toAmount = isSell ? Math.abs(item.conversionAmount) : Math.abs(item.amount);
	const toCcy = isSell ? item.conversionCurrency : item.currency;

	//const pair = item.isBaseCurrency ? `${item.currency}-${item.conversionCurrency}` : `${item.conversionCurrency}-${item.currency}`;

	return (
		<Dialog
			id="account-details-form"
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
		>
			<DialogContentNoScrollbar>
				<Grid
					container
					sx={{ p: { xs: '1rem', md: '2rem' } }}
				>
					<Grid item container justifyContent="space-between" alignItems="center" pb={6}>
						<Grid item>
							<MuiHeader>{t('tokens.header.details')}</MuiHeader>
						</Grid>
						<Grid item>
							<IconButton onClick={onClose}  >
								<MuiCloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item container alignItems='center' spacing={2}>
						<Grid item xs={12} >
							<DetailsBox sx={{ p: { xs: '0.5rem 1rem', sm: '0.5rem 2.625rem' } }}>
								<DetailLine label={t('tokens.tradeHistory.time')} value={convertDate(item.date, 'dd/MM/yyyy HH:mm:ss')} first />
								<DetailLine label={t('tokens.tradeHistory.market')} value={`${item.amount}`}>
									<MuiTextCaption variant='gradient' fontWeight='400'>
										{isSell ? `${item.currency}-${item.conversionCurrency}` : `${item.conversionCurrency}-${item.currency}`}
									</MuiTextCaption>
								</DetailLine>
								<DetailLine label={t('tokens.tradeHistory.orderType')} value={t('tokens.tradeHistory.market')} />
								<DetailLine label={t('tokens.tradeHistory.from')} value={`${fromAmount} ${fromCcy}`}>
									<MuiTextCaption>
										<NumberFormat displayType={'text'}
											decimalScale={isFiat(fromCcy) ? 2 : 5}
											fixedDecimalScale={false}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											value={fromAmount}
											suffix={` ${fromCcy}`} />
									</MuiTextCaption>
								</DetailLine>
								<DetailLine label={t('tokens.tradeHistory.to')} value={`${toAmount} ${toCcy}`}>
									<MuiTextCaption>
										<NumberFormat displayType={'text'}
											decimalScale={2}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											value={toAmount}
											suffix={` ${toCcy}`} />
									</MuiTextCaption>
								</DetailLine>
								<DetailLine label={t('tokens.tradeHistory.price')} value={`${item.price}`}>
									<MuiTextCaption>
										<NumberFormat displayType={'text'}
											decimalScale={2}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											value={item.price}
											suffix={` ${item.isBaseCurrency ? item.conversionCurrency : item.currency}`} />
									</MuiTextCaption>
								</DetailLine>



								<Grid item xs={12}> <StyledDivider /> </Grid>

								<Grid container
									minHeight='3.2rem'
									py={1}
									alignItems='center'>
									<Grid item xs={12} container>
										<Grid item xs={4}>
											<MuiFormLabel color='text.secondary'>
												{t('tokens.cryptoHistory.status')}
											</MuiFormLabel>
										</Grid>
										<Grid item container xs={8} justifyContent='flex-end'>
											<TransactionStatusBox
												status={item.status as TransactionStatus}>
												{t('status.' + item.status)}
											</TransactionStatusBox>
										</Grid>
									</Grid>
								</Grid>
							</DetailsBox>
						</Grid>
					</Grid>
				</Grid>
			</DialogContentNoScrollbar>
		</Dialog >
	);
};

export default TradeDetailsForm;
