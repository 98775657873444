import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showErrorNotification } from '@/features/swal/slice';
import { useTranslation } from 'react-i18next';
import { RootState } from '@/rootReducer';
import snsWebSdk from '@sumsub/websdk';
import { getRefreshedToken, requestFinalizeReviewStatus } from '@/features/kyc/slice';
import Spinner from '../Shared/Widgets/Spinner/Spinner';
import { refreshUser } from '@/features/user/userSlice';
import { Box, Button } from '@mui/material';
// import './KYCSumsub.css';

interface Props {
	token: string,
	onConctactSupport: () => void
}

const KYCSumsub = ({ token, onConctactSupport }: Props): React.ReactElement => {
	const { i18n } = useTranslation('translations');
	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const [loading, setLoading] = useState(false);

	const { status, user } = useSelector(
		(state: RootState) => state.user
	);

	useEffect(() => {
		setLoading(true);
		const callKycForm = async () => {
			const snsWebSdkInstance = snsWebSdk.init(token, () => Promise.resolve(getRefreshedToken()))
				.withConf({
					lang: i18n.language,
					documentDefinitions: {
						IDENTITY: {
							idDocType: user.preferredDocType,
							country: user.country,
						},
					}
				})
				.onMessage(async ($type, $payload) => {
					switch ($type) {
						case 'idCheck.applicantStatus':
							if (($payload as any).reviewStatus === 'pending') {
								try {
									await requestFinalizeReviewStatus();
									await dispatch(refreshUser());
								} catch (e) {
									showErrorNotification(e);
								}
							}
							break;
					}
				})
				.on('idCheck.onError', (error) => {
					console.log('onError', error);
				})
				// .on('idCheck.onInitialized', (e) => {
				// 	console.log('onInitialized', e);
				// 	const sumsumbContainer = document.getElementById('sumsub-websdk-container');
				// 	const sumsumbIframe = sumsumbContainer.getElementsByTagName('iframe')[0];
				// 	// sumsumbIframe.removeAttribute('scrolling');
				// })
				.withBaseUrl(status.sumsubUrl)
				.build();

			snsWebSdkInstance.launch('#sumsub-websdk-container');
		};
		callKycForm();
		setLoading(false);
	}, [dispatch, i18n.language, status.sumsubUrl, token, user.country, user.preferredDocType]);

	if (!token) {
		return <Spinner />;
	}

	return (
		<>
			{loading ? <Spinner /> : <div id="sumsub-websdk-container" style={{ backgroundColor: 'white' }}></div>}

			<Box pt={4} display={{ xs: 'box', md: 'none' }}>
				<Button
					onClick={onConctactSupport}
					variant='contained' >
					{t('home.contactSupport')}
				</Button>
			</Box>
		</>
	);
};

export default KYCSumsub;
