import { Button, Dialog, DialogContent, FormHelperText, Grid, IconButton, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import { showException } from '@/features/swal/slice';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import React, { useState } from 'react';
import LoadingPopup from '@/componentsMui/Shared/Widgets/LoadingPopup';
import PdfViewerFullScreen from '@/componentsMui/Shared/Components/PdfViewerFullScreen';
import { getVaultEarningStatementPdf } from '@/features/earn/slice';
import { VaultEarningStatementPayload } from '@/features/earn/types';
import { endOfMonth, format, startOfMonth } from 'date-fns';

interface Props {
	open: boolean,
	onClose: () => void
}


const formatDate = (date: Date) => {
	try {
		return format(date, 'yyyy-MM-dd');
	}
	catch (e) {
		return undefined;
	}

};

const VaultExportForm = ({ open, onClose }: Props) => {
	const { t } = useTranslation('translations');


	const defaultStart = startOfMonth(new Date());
	const defaultEndDate = endOfMonth(new Date());


	const [startDate, setStartDate] = useState(defaultStart);
	const [endDate, setEndDate] = useState(defaultEndDate);
	const [loading, setLoading] = useState(false);
	const [openPreview, setOpenPreview] = useState(false);
	const from = formatDate(startDate);
	const to = formatDate(endDate);
	const [previewFile, setPreviewFile] = useState<any>(null);
	const fileName = `tax_summary_${from}_${to}.pdf`;


	const submit = async () => {
		try {
			setLoading(true);
			const payload: VaultEarningStatementPayload = {
				from: from.toString(),
				to: to.toString(),
			};
			const result = await getVaultEarningStatementPdf(payload);
			setPreviewFile(result);
			setOpenPreview(true);

			setLoading(false);
		} catch (error) {

			setLoading(false);
			setPreviewFile(null);
			showException(error);
			setOpenPreview(false);
			onClose();
		}

	};

	return (
		<LocalizationProvider dateAdapter={DateAdapter}>
			<PdfViewerFullScreen
				file={previewFile}
				savedFileName={fileName}
				isOpen={openPreview}
				onClose={() => setOpenPreview(false)} />
			<Dialog
				open={open}
				fullWidth
				maxWidth="xs"
			>
				<LoadingPopup open={loading} messsage={t('transactiondetails.message.preprare')} />
				<DialogContent>
					<Grid container padding='10px' rowGap={2}>
						<Grid item container justifyContent="space-between" alignItems="center" >
							<Grid item>
								<MuiHeader>{t('sharetransaction.modal.header')}</MuiHeader>
							</Grid>
							<Grid item>
								<IconButton onClick={onClose}  >
									<MuiCloseIcon />
								</IconButton>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<LocalizationProvider dateAdapter={DateAdapter}>
								<DatePicker
									disableFuture={true}
									label={t('transaction.labels.starton')}
									views={['year', 'month']}
									maxDate={endDate ?? new Date()}
									value={startDate}
									inputFormat={'MM-yyyy'}
									onChange={(date) => {
										setStartDate(date);
									}}
									renderInput={(params) => <TextField {...params} helperText={null} fullWidth />}
								/>
								{!from && <FormHelperText>{t('form.validator.invalidDateFormat')}</FormHelperText>}
							</LocalizationProvider>
						</Grid>
						<Grid item xs={12} >
							<LocalizationProvider dateAdapter={DateAdapter}>
								<DatePicker
									disableFuture={true}
									label={t('transaction.labels.endon')}
									views={['year', 'month']}
									minDate={startDate}
									maxDate={new Date()}
									value={endDate}
									inputFormat={'MM-yyyy'}
									onChange={(date) => {
										const lastDateOfMonth = endOfMonth(date);
										setEndDate(lastDateOfMonth);
									}}
									renderInput={(params) => <TextField {...params} helperText={null} fullWidth />}
								/>
							</LocalizationProvider>
						</Grid>
						{!to && <FormHelperText>{t('form.validator.invalidDateFormat')}</FormHelperText>}
						<Grid item xs={12} mt={2}>
							<Button
								type="submit"
								variant="contained"
								color='primary'
								disabled={!from || !to}
								fullWidth
								onClick={submit}
							>
								{t('form.buttons.export', { type: 'PDF' })}
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog >
		</LocalizationProvider>);
};

export default VaultExportForm;
