import React from 'react';
import { getCurrencySign } from '@/features/fees/slice';
import { isFiat } from '@/helpers/currency';
import { roundFlexible } from '@/helpers/round';
import { GridRenderCellParams } from '@mui/x-data-grid';
import NumberFormat from 'react-number-format';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import TrxStatus from './TrxStatus';
import { Grid } from '@mui/material';
import { TransactionType } from '@/features/transactions/types';

const TrxAmountCellMobile = (params: GridRenderCellParams,
    type: string,
    accountSubProcesses: any) => {
    const transaction = params.row;
    const isFiatAsset = isFiat(transaction.asset) || type === 'CARD';

    const getSign = transaction.amount < 0 ? '-' : transaction.amount > 0 ? '+' : '';

    const hideStatus = (!transaction?.status || (!transaction?.documentId && transaction?.activity === TransactionType.FX_TRANSACTION) || !transaction?.activity);
    const ccy =  type === 'CARD' ? transaction.currency : transaction.asset;

    return (<Grid container>
        <Grid item xs={12}>
            <MuiFormText textAlign='right'
                fontWeight={500}
                sx={theme => ({ color: transaction.amount <= 0 ? theme.palette.text.primary : theme.statusColor.success })}>
                {`${getSign}  ${getCurrencySign(ccy)}`}
                {isFiatAsset ?
                    <NumberFormat displayType={'text'} decimalScale={2}
                        fixedDecimalScale={true} thousandsGroupStyle='thousand'
                        thousandSeparator={true} value={Math.abs(transaction.amount)} /> :
                    roundFlexible(Math.abs(transaction.amount))
                }
            </MuiFormText>
        </Grid>
        {!hideStatus &&
            <Grid item xs={12} textAlign='right'>
                <TrxStatus
                    transactionStatus={transaction.status as string}
                    documentId={transaction.documentId as number}
                    activity={transaction.activity as string}
                    accountSubProcesses={accountSubProcesses} />
            </Grid>
        } 
    </Grid>);
};

export default TrxAmountCellMobile;
