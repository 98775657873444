import { postCryptoPayinfo } from '@/features/coins/slice';
import { postTokenPayinfo } from '@/features/tokens/slice';
import { CoinPayinfo, CoinPayinfoPayload } from '@/features/coins/types';
import { debounce, isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { WalletType } from '@features/walletHistory/types';

const useCoinFeeDebounce = () => {

	const initPayInfo: CoinPayinfo = {
		toAmount: '0',
		price: '0',
		fee: '0'
	};
	const [payInfo, setPayinfo] = useState<CoinPayinfo>(initPayInfo);
	const [payInfoError, setPayinfoError] = useState<boolean>(false);
	const [payInfoLoading, setPayinfoLoading] = useState<boolean>(false);

	const callGetPayInfo = useMemo(() => debounce(
		async (feePayload?: CoinPayinfoPayload) => {


			if (feePayload.fromAmount <= 0) {
				setPayinfo(initPayInfo);
				return;
			}
			setPayinfoLoading(true);
			try {
				if (!isEmpty(feePayload)) {
					let result = null;
					if (feePayload.walletType === WalletType.CRYPTO) {
						result = await postCryptoPayinfo(feePayload);
					} else if (feePayload.walletType === WalletType.TOKEN) {
						result = await postTokenPayinfo(feePayload);
					} else {
						setPayinfoError(true);
					}
					setPayinfo(result);
					setPayinfoError(false);
				}
			}
			catch (e) {
				setPayinfo(initPayInfo);
				setPayinfoError(true);
			}
			finally { setPayinfoLoading(false); }
		}, 500)
		// eslint-disable-next-line react-hooks/exhaustive-deps 
		, []
	);

	return { payInfo, initPayInfo, payInfoError, payInfoLoading, setPayinfoError, setPayinfo, callGetPayInfo };
};

export default useCoinFeeDebounce;
