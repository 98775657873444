import React, { useEffect, useState } from 'react';
import './BarChart.css';
import { Grid, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { getCurrencySign } from '@/features/fees/slice';
import NumberFormat from 'react-number-format';
import ClickableTooltip from '@shared/Widgets/ClickableTooltip';


interface BarItemProps {
    dataset?: any,
    currency?: string
}

const BarChart = ({ dataset, currency }: BarItemProps) => {

    const theme = useTheme() as Theme;
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));


    const [start, setStart] = useState<number>(0);
    const [count, setCount] = useState<number>(12);
    const [data, setData] = useState<any[]>([]);
    const [ticks, setTicks] = useState<string[]>([]);
    const [max, setMax] = useState<number>(0);

    useEffect(() => {
        if (smallScreen) {
            setCount(4);
        } else {
            setCount(12);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [smallScreen]);

    // const ticks = ['200', '160', '120', '80', '40', '0'];

    useEffect(() => {
        const arr = [...dataset];
        if (arr.length <= count) {
            setStart(0);
            setData(arr);
        } else {
            const s = Math.ceil(arr.length / 2 - count / 2);
            setStart(s);
            setData(arr.slice(s, s + count));
        }
    }, [count, dataset]);

    useEffect(() => {
        if (data.length <= 0) return;
        const m = Math.ceil(Math.max(...data.map(o => o.amount)));
        setMax(m < 10 ? 10 : m);
    }, [data]);

    useEffect(() => {
        if (data.length <= 0) return;

        const arr = [];
        if (max === 0 ) {
            setTicks(['10', '8', '6', '4', '2', '0']);
        } else {
            const maxRounded = Math.ceil(max);
            const tickStep = maxRounded / 5;
            arr.push(maxRounded);

            for (let i = 1; i < 6; i++) {
                arr.push(Math.round(maxRounded - (tickStep * i)));
            }
            setTicks(arr);
        }

    }, [data, max]);


    const nextMonth = () => {
        const arr = [...dataset];
        const ns = start + 1;
        setStart(ns);
        setData(arr.slice(ns, ns + count));
    };

    const prevMonth = () => {
        const arr = [...dataset];
        const ns = start - 1;
        setStart(ns);
        setData(arr.slice(ns, ns + count));
    };

    const calculatePercentage = (amount) => {
        return amount / max * 100;
    };

    return (<>
        <table className="graph">
            <tbody>
                {data.map((bar, i) => (
                    <ClickableTooltip key={i} title={<div style={{ padding: '16px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Typography variant='highlight' fontSize='14px' mr='0.5em'>
                                {getCurrencySign(currency)}
                            </Typography>
                            <Typography fontSize='14px'>
                                <NumberFormat
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandsGroupStyle='thousand'
                                    thousandSeparator={true}
                                    value={bar.amount} />
                            </Typography>
                        </div>
                        <Typography fontSize='14px'>{bar.tooltipSecondary}</Typography>
                    </div>}
                      placement='top'
                      arrow
                    >
                        <tr style={{ height: `${calculatePercentage(bar.amount)}%` }}>
                            <td style={{ background: theme.selectedItemBg.default }}><Typography fontSize='13px'>{bar.legend}</Typography></td>
                        </tr>
                    </ClickableTooltip>
                ))}
            </tbody>
        </table>

        <div className='ticks'>
            {ticks.map((t, i) => (
                <p key={i} className='tick'>
                    <Typography variant='highlight' fontSize='10px' mr='0.25em'>
                        {getCurrencySign(currency)}
                    </Typography>{t}
                </p>
            ))}
        </div>
        <Grid container pt={4} columnGap={2} justifyContent='center'  >
            <div
                className={`chart-scroll-icon scroll-left ${start === 0 ? 'disabled' : ''}`}
                style={{
                    background: theme.selectedItemBg.default,
                    boxShadow: theme.carousel.iconShadow
                }}
                onClick={start === 0 ? undefined : prevMonth}
                draggable='false'
            />
            <div
                className={`chart-scroll-icon scroll-right ${(start + count === dataset.length || dataset.length < count) ? 'disabled' : ''}`}
                style={{
                    background: theme.selectedItemBg.default,
                    boxShadow: theme.carousel.iconShadow
                }}
                onClick={(start + count === dataset.length || dataset.length < count) ? undefined : nextMonth}
                draggable='false'
            />
        </Grid>
    </>
    );
};

export default BarChart;
