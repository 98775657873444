import * as React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem } from '@mui/material';
import { useField } from 'formik';
import { isEmpty } from 'lodash';
import StyledSelect from '@/componentsMui/Shared/Widgets/StyledSelect';
import { useTranslation } from 'react-i18next';
import AssetAvatar from '../Widgets/Avatars/AssetAvatar';
import { TokenAvatarSizeEnum } from '../Widgets/Avatars/TokenAvatar'; 
import { AssetType } from '@/features/coins/types';

interface Props {
	fieldName: string,
	assetList: Array<AssetType>,
	fieldlabel?: string,
	className?: string,
	textToRight?: boolean,
	selectedAsset?: AssetType,
	onAssetChange?: (value: AssetType) => void,
}

const AssetDropdown = ({ fieldName, assetList, fieldlabel, selectedAsset, onAssetChange, className, textToRight }: Props) => {
	const { t } = useTranslation('translations');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField(fieldName);
	const { setValue } = helpers;
	const { value } = meta;

	const handleChange = (event: any) => {
		const newId = event.target.value;
		setValue(newId);
		onAssetChange && onAssetChange(assetList.find(p => p.id === newId));
	};


	const validationFailed = meta && meta.touched && meta.error;
	const textFieldValue = isEmpty(selectedAsset) ?
		t('form.placeholder.select') :
		<AssetAvatar asset={selectedAsset} variant={TokenAvatarSizeEnum.small} />;

	return (
		<FormControl fullWidth className={className}>
			{fieldlabel && <InputLabel> {fieldlabel}</InputLabel>}
			<StyledSelect
				name={fieldName}
				value={value ?? ''}
				displayEmpty
				onChange={handleChange}
				renderValue={() => <div style={{ textAlign: textToRight ? 'right' : 'inherit' }}> {textFieldValue}</div>}
			>
				{assetList?.length > 0 &&
					assetList.map((item) => (
						<MenuItem
							style={{ opacity: 1 }}
							key={item.id}
							value={item.id}>
							<AssetAvatar asset={item} variant={TokenAvatarSizeEnum.medium} />
						</MenuItem>
					))}
			</StyledSelect>
			{validationFailed && <FormHelperText>{meta.error}</FormHelperText>}
		</FormControl>
	);
};

export default AssetDropdown;
