import * as React from 'react';
import { FormControl, FormHelperText, Grid, InputLabel } from '@mui/material';
import { useField } from 'formik';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import AssetAvatar from '../Widgets/Avatars/AssetAvatar';
import { TokenAvatarSizeEnum } from '../Widgets/Avatars/TokenAvatar';
import { AssetType } from '@/features/coins/types';
import './AssetSelect.css';
import MuiTextLabel from '../Widgets/MuiText/MuiTextLabel';
import bigDecimal from 'js-big-decimal';
import { ReactComponent as MuiChevronSmall } from '@/assets/MuiChevronSmall.svg';

interface Props {
	fieldName: string,
	fieldlabel?: string,
	className?: string,
	textToRight?: boolean,
	selectedAsset?: AssetType,
	onClick?: () => void
}

const AssetSelect = ({ fieldName, fieldlabel, selectedAsset, onClick, className, textToRight }: Props) => {
	const { t } = useTranslation('translations');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField(fieldName);




	const validationFailed = meta && meta.touched && meta.error;
	const textFieldValue = isEmpty(selectedAsset) ?
		t('form.placeholder.select') :
		<AssetAvatar asset={selectedAsset} variant={TokenAvatarSizeEnum.small} />;

	return (
		<FormControl fullWidth className={className} onClick={onClick} id='asset-select' sx={{ p: '1rem', cursor: 'pointer' }}>
			{fieldlabel && <InputLabel > {fieldlabel}</InputLabel>}
			<Grid container alignItems='center'>
				<div style={{ textAlign: textToRight ? 'right' : 'inherit' }} className='asset-label-style'> {textFieldValue} </div>
				<MuiChevronSmall />
			</Grid>
			<MuiTextLabel color='text.secondary' fontWeight='400' fontSize='0.688rem' lineHeight='0.688rem' mt='9px' >
				{t('tokens.withdrawal.balance')} {new bigDecimal(selectedAsset?.availableBalance).round(selectedAsset?.precision, bigDecimal.RoundingModes.DOWN).getValue() ?? 0}
			</MuiTextLabel>


			{validationFailed && <FormHelperText>{meta.error}</FormHelperText>}
		</FormControl>
	);
};

export default AssetSelect;
