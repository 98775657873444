import { Theme, Drawer as MuiDrawer, IconButton, DrawerProps, Grid, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { memo, useState, } from 'react';
import { styled, CSSObject, useTheme } from '@mui/material/styles';
// import { appBarHeight, appCollapsedDrawerWidth, appMobileDrawerWidth, drawerWidth, sidebarLargeIconSize } from '@/utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import LogoThemed from '../Shared/Widgets/LogoThemed';
import useUserOperations from '@/helpers/customHook/useUserOperations';
import NavbarMenuItemNavLink from '../Shared/Widgets/NavbarMenuItemNavLink';
import { NavLink, useHistory } from 'react-router-dom';
import { RootState } from '@/rootReducer';
import { useTranslation } from 'react-i18next';
import IconWrapBox from '../Shared/Widgets/Box/IconWrapBox';
import SwitchUserDropdown from '../Shared/Components/SwitchUserDropdown/SwitchUserDropdown';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { logout } from '@/features/user/userSlice';
import { showErrorNotification } from '@/features/swal/slice';
import { UserType } from '@/features/user/types';
import KeyboardTabOutlinedIcon from '@mui/icons-material/KeyboardTabOutlined';
import { isKycTierVerified } from '@/helpers/kyc';
import HomeIcon from '../Shared/CustomIcons/HomeIcon';
import MainAccountIcon from '../Shared/CustomIcons/MainAccountIcon';
import MenuCardsIcon from '../Shared/CustomIcons/MenuCardsIcon';
import MenuWalletIcon from '../Shared/CustomIcons/MenuWalletIcon';
import MenuEarnIcon from '../Shared/CustomIcons/MenuEarnIcon';
import MenuTokenIcon from '../Shared/CustomIcons/MenuTokenIcon';
import MenuTrustIcon from '../Shared/CustomIcons/MenuTrustIcon';
import MenuMyAccount from '../Shared/CustomIcons/MenuMyAccount';
import MenuHelpIcon from '../Shared/CustomIcons/MenuHelpIcon';
import MenuBusinessIcon from '../Shared/CustomIcons/MenuBusinessIcon';
import MenuEmployeeIcon from '../Shared/CustomIcons/MenuEmployeeIcon';
import { makeStyles } from '@mui/styles';
import { MyAccountPageTab } from '../Dashboard/MyAccount/MyAccountPage';
import MenuInvoiceIcon from '../Shared/CustomIcons/MenuInvoiceIcon';

const sidebarLargeIconSize = 20;
const drawerWidth = 220;
const appCollapsedDrawerWidth = sidebarLargeIconSize + 40;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter,
    }),
    overflowX: 'hidden',
    width: `${appCollapsedDrawerWidth}px`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    height: '54px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const DrawerStyled = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        background: '#1D2024',
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        '& .MuiPaper-root.MuiDrawer-paper': {
            backgroundColor: '#1D2024',
            borderRight: 'unset',
            position: 'unset'
        },
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const DesktopDrawer = (props: DrawerProps) => {
    const { t } = useTranslation('translations');
    const dispatch = useDispatch();
    const history = useHistory();
    const operations = useUserOperations();
    const theme = useTheme() as Theme;
    const { user } = useSelector(
        (state: RootState) => state.user
    );
    const kycTierVerified = isKycTierVerified(user);

    const { businessEnabled } = useSelector((state: RootState) => state.status);
    const [drawerOpen, setDrawerOpen] = useState(true);

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const signout = async (key?: UserType) => {
        try {
            dispatch(logout());
            return history.push('/auth' + (key ? `?mode=${key}` : ''));
        }
        catch (e) {
            showErrorNotification(e);
        }
    };

    if (!user) {
        return <></>;
    }

    const showAccounts = operations.find(p => p.path === 'accounts')?.allowed ?? false;
    const showCards = operations.find(p => p.path === 'cards')?.allowed ?? false;
    const showTrust = operations.find(p => p.path === 'trust')?.allowed ?? false;
    const showEarn = operations.find(p => p.path === 'earn')?.allowed ?? false;
    const showWallet = operations.find(p => p.path === 'wallets')?.allowed ?? false;
    const showTokens = operations.find(p => p.path === 'tokens')?.allowed ?? false;
    const showInvoices = operations.find(p => p.path === 'invoices')?.allowed ?? false;

    return (
        <DrawerStyled {...props}
            variant="permanent"
            anchor="left"
            open={drawerOpen}
            onClose={handleDrawerClose}
            color="inherit"
            transitionDuration={{ enter: 500, appear: 500 }}
            test-id='menu'
        >
            <DrawerHeader style={{ minHeight: '54px' }}>
                {drawerOpen ?
                    <Grid container flexDirection='row' alignItems='center'>
                        <NavLink to="/wallet/home" style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                            <LogoThemed className="navbar-logo" style={{ marginLeft: 0 }} />
                        </NavLink>
                    </Grid>
                    : <div></div>}
                <IconButton onClick={drawerOpen ? handleDrawerClose : handleDrawerOpen} size='small' sx={{ transform: drawerOpen ? 'rotate(180deg)' : undefined }}>
                    <KeyboardTabOutlinedIcon style={{ color: theme.palette.text.secondary }} />
                </IconButton>
            </DrawerHeader>


            <Grid item sx={{ paddingBottom: '1rem', paddingTop: '1rem', paddingLeft: drawerOpen ? '1rem' : 0, paddingRight: drawerOpen ? '1rem' : 0 }} justifyContent='center'>
                {kycTierVerified && <IconWrapBox sx={{ minWidth: drawerOpen ? '200px' : `${appCollapsedDrawerWidth}px`, background: 'transparent', boxShadow: 'unset' }}>
                    <SwitchUserDropdown user={user} onlyAvatar={!drawerOpen} drawer={true} />
                </IconWrapBox>
                }
            </Grid>


            <DrawerListItem testId='summary' open={drawerOpen} to='/wallet/home/summary' icon={<HomeIcon className="stroke" />} title={t('pages.home')} />
            {showAccounts && <DrawerListItem testId='accounts' open={drawerOpen} to='/wallet/home/accounts?tab=accounts' icon={<MainAccountIcon className="stroke" />} title={t('pages.banking')} />}
            {showWallet && <DrawerListItem testId='wallets' open={drawerOpen} to='/wallet/home/wallets?tab=balances' icon={<MenuWalletIcon />} title={t('pages.wallet')} />}
            {showTokens && <DrawerListItem testId='tokens' open={drawerOpen} to='/wallet/home/tokens?tab=list' icon={<MenuTokenIcon />} title={t('pages.tokens')} />}

            {showCards && <DrawerListItem testId='cards' open={drawerOpen} to='/wallet/home/cards' icon={<MenuCardsIcon />} title={t('pages.cards')} />}
            {showEarn && <DrawerListItem testId='earn' open={drawerOpen} to='/wallet/home/earn?tab=earn' icon={<MenuEarnIcon />} title={t('pages.earn')} />}
            {showTrust && <DrawerListItem testId='trust' open={drawerOpen} to='/wallet/home/trust' icon={<MenuTrustIcon />} title={t('pages.trust')} />}
            {showInvoices && <DrawerListItem testId='invoices' open={drawerOpen} to='/wallet/home/invoices' icon={<MenuInvoiceIcon />} title={t('pages.invoices')} />}


            <DrawerListItem open={drawerOpen} testId='profile' to={`/wallet/account?tab=${MyAccountPageTab.MY_ACCOUNT}`} icon={<MenuMyAccount />} title={t('navbar.account.title')} />
            <DrawerListItem open={drawerOpen} testId='support' to='/wallet/support' icon={<MenuHelpIcon />} title={t('navbar.help')} />
            {/* <DrawerListItem open={drawerOpen} to='/wallet/security' icon={<MenuSettingsIcon />} title={t('pages.settings')} /> */}
            {businessEnabled && kycTierVerified && user?.type !== UserType.EMPLOYEE && <DrawerListItem testId='business' open={drawerOpen} to='/wallet/business' icon={<MenuBusinessIcon className="stroke" />} title={t('navbar.business')} />}
            {(user?.type === UserType.BUSINESS || user?.onBehalfOf !== null) && <DrawerListItem testId='employees' open={drawerOpen} to='/wallet/employees' icon={<MenuEmployeeIcon />} title={t('navbar.employees')} />}




            <Grid item justifyContent='flex-end' flex={1} container direction='column'>
                <Grid item sx={{ paddingBottom: '0.5rem' }}>
                    <NavbarMenuItemNavLink test-id='logout' onClick={() => signout()} desktopDrawer={true}
                        sx={{ background: theme.warningBg }}>
                        <Tooltip disableFocusListener={drawerOpen} disableHoverListener={drawerOpen} title={t('navbar.logout')} placement="right">
                            <ListItemIcon>
                                <LogoutOutlinedIcon />
                            </ListItemIcon>
                        </Tooltip>
                        {drawerOpen && <ListItemText>{t('navbar.logout')}</ListItemText>}
                    </NavbarMenuItemNavLink>
                </Grid>
            </Grid>
        </DrawerStyled >
    );
};

interface DrawerListItemProps {
    open: boolean;
    icon: any;
    title: string;
    to: string;
    testId?: string;
}

const useStyles = makeStyles({
    item: {
        '& span': {
            fontSize: '16px !important',
            padding: '8px 16px !important'
        }
    }
});

const DrawerListItem = ({ open, icon, title, to, testId }: DrawerListItemProps) => {
    const classes = useStyles();

    return (
        <NavbarMenuItemNavLink test-id={testId} component={NavLink} to={to} desktopDrawer={true} >
            <Tooltip disableFocusListener={open} disableHoverListener={open} title={title} placement="right">
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
            </Tooltip>
            {open && <ListItemText className={classes.item} >{title}</ListItemText>}
        </NavbarMenuItemNavLink>
    );
};

export default memo(DesktopDrawer);
