import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import MuiFormLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiFormLabel';
import LockAccountForm from './LockAccountForm';

const LockAccount = (): React.ReactElement => {

	const { t } = useTranslation('translations');
	const [open, setOpen] = useState(false);

	return (
		<Grid container alignItems='center' rowGap={2} sx={{ backgroundColor: '#1D2024',  padding: '30px', borderRadius: '10px' }}>
			<LockAccountForm open={open} onClose={() => setOpen(false)} />
			<Grid item xs={12} sm={8}>
				<MuiTextCaption fontSize='1.125rem'>{t('security.options.closeAccount')}</MuiTextCaption>
				<MuiFormLabel color='text.secondary' fontSize='0.875rem'>{t('security.options.closeAccountDetails')}</MuiFormLabel>
			</Grid>
			<Grid item xs={12} sm={4} container sx={{justifyContent: { xs: 'flex-start', sm: 'flex-end' }}}>
				<Button variant='login' sx={{ minWidth: '12rem' }}
					onClick={() => setOpen(true)}>{t('security.options.closeAccountAction')}</Button>
			</Grid>
		</Grid>
	);
};
export default LockAccount;
