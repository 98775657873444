import * as React from 'react';
import { Button, Grid } from '@mui/material';
import BaseBox from './BaseBox';
import MuiFormText from '../MuiText/MuiFormText';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

interface Props {
	value: string
}
const CopyBox = ({ value }: Props) => {

	const { t } = useTranslation('translations');

	return (<BaseBox padding='0.625rem 1.25rem'>
		<Grid container justifyContent='space-between' alignItems='center'>
			<Grid item xs={8} >
				<MuiFormText>{value}</MuiFormText>
			</Grid>
			<Grid item >
				<CopyToClipboard text={value}>
					<Button variant='contained'
						color='secondary'> {t('form.buttons.copy')}
					</Button>
				</CopyToClipboard >
			</Grid>
		</Grid>
	</BaseBox>);
}; 

export default CopyBox;
