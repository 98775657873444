import * as io from 'socket.io-client';
import { BehaviorSubject } from 'rxjs';

interface HashTable<T> {
	[key: string]: T;
}

export class RoomSocketService {
	private socket: SocketIOClient.Socket = null;
	private namespace: string;
	private room: string;
	static connections: HashTable<RoomSocketService> = {};
	private channelList: HashTable<BehaviorSubject<any>> = {};
	// eslint-disable-next-line @typescript-eslint/ban-types
	private handleList: HashTable<Function> = {};

	constructor(namespace: string, room: string) {
		this.namespace = namespace;
		this.room = room;
		this.connect();
	}

	private connect(): RoomSocketService {
		if (RoomSocketService.connections[this.namespace]) {
			this.socket = RoomSocketService.connections[this.namespace].socket;
			return RoomSocketService.connections[this.namespace];
		}
		this.socket = io.connect(`${process.env.REACT_APP_WS_URL || ''}/${this.namespace}`, { path: '/socket.io', transports: ['websocket'], query: { room: this.room } });
		RoomSocketService.connections[this.namespace] = this;
		return this;
	}

	public send(type: string, message?: any): void {
		this.socket.emit(type, message);
	}

	public listen(type: string, init: any): BehaviorSubject<any> {
		if (this.channelList[type]) {
			return this.channelList[type];
		}
		this.channelList[type] = new BehaviorSubject(init);
		this.handleList[type] = (data) => {
			this.channelList[type].next(data);
		};
		this.socket.on(type, this.handleList[type]);
		return this.channelList[type];
	}

	public static disconnect(): void {
		RoomSocketService.connections = {};
		for (const conn in RoomSocketService.connections) {
			RoomSocketService.connections[conn].socket.disconnect();
			delete RoomSocketService.connections[conn];
		}
	}
}
