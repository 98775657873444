 
const supportedCurrencies =['EUR', 'GBP', 'USD'];

export const isFiat = (currency: string): boolean => { 
    return supportedCurrencies.includes(currency);
};

export const getCurrencySign = (currency: string): string => {
	switch (currency) {
		case 'EUR': return '€';
		case 'GBP': return '£';
		case 'USD': return '$';
		default: return currency;
	}
};

