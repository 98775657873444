import React from 'react';
import { TicketMarkType, TicketNotification, TicketType } from '@/features/tickets/types';
import { convertDateLang } from '@/helpers/date';
import { Box, Grid, Typography } from '@mui/material';
import { markNotification } from '@/features/tickets/slice';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import UnreadStatus from './UnreadStatus';
import { useTranslation } from 'react-i18next';


interface Proos {
	ticket: TicketNotification,
	onClick?: (ticket: TicketNotification) => void,
	disabled?: boolean,
}

const NotificationItem = ({ ticket, disabled, onClick }: Proos) => {

	const dispatch = useDispatch();
	const { t, i18n } = useTranslation('translations');

	const handleDeleteTicket = (event: any, id: number) => {
		event.stopPropagation();
		dispatch(markNotification(TicketMarkType.DELETE, id));
	};

	const meta = JSON.parse(ticket.requestmeta) || null;

	const getMessage = () => {

		const code = ticket?.translationcode;


		if (!code) { return ticket?.message; }

		switch (code) {
			case 'INCIDENT':
			case 'CHAT_MESSAGE':
			case 'CHAT_MESSAGE_COMPLIANCE':
				return t(`nofification.incidienttype.${code}`);
			case 'TRANSFER_REQUEST':
				return t(`nofification.incidienttype.${code}`, {
					amount: `${meta?.account?.currency ?? ''}  ${meta?.amount ?? ''}`,
					name: (meta?.user?.firstName ?? '' + ' ' + meta?.user?.lastName ?? ''),
					reference: meta?.reference ?? ''
				});
			case 'ACCOUNT_SUSPENDED':
				return t(`nofification.incidienttype.${code}`, {
					currency: meta?.currency ?? ''
				});
			case 'CARD_FEE_DEDUCTION':
				return t(`nofification.incidienttype.${code}`, {
					mask: meta?.mask ?? '',
					currency: meta?.currency ?? '',
					amount: meta?.amount ?? ''
				});
			case 'CARD_SUSPENDED':
				return t(`nofification.incidienttype.${code}`, {
					mask: meta?.mask ?? ''
				});
			case 'KYB_APPROVED':
				return t(`nofification.incidienttype.${code}`, {
					company: meta?.company ?? ''
				});
			case 'KYB_REQUIRED_ACTION':
				return t(`nofification.incidienttype.${code}`, {
					company: meta?.company ?? ''
				});
			case 'KYB_WAITING_PAYMENT':
				return t(`nofification.incidienttype.${code}`, {
					company: meta?.company ?? ''
				});
			case 'KYB_FINAL_REJECTED':
				return t(`nofification.incidienttype.${code}`, {
					company: meta?.company ?? ''
				});
			case 'INCOMING_DEPOSIT':
				return t(`nofification.incidienttype.${code}`, {
					amount: meta?.amount ?? '',
					currency: meta?.currency ?? ''
				});
			case 'INCOMING_DEPOSIT_WALLET':
				return t('nofification.incidienttype.INCOMING_DEPOSIT', {
					amount: meta?.amount ?? '',
					currency: meta?.currency ?? ''
				});
			case 'CARD_PAYMENT':
				return t(`nofification.incidienttype.${code}`, {
					amount: meta?.amount ?? '',
					currency: meta?.currency ?? '',
					beneficiary: meta?.beneficiary ?? ''
				});
			case 'OUTGOING_TRANSFER':
				return t(`nofification.incidienttype.${code}`, {
					amount: meta?.amount ?? '',
					currency: meta?.currency ?? '',
					recipient: meta?.recipient ?? ''
				});
			case 'SWAP_SUCCESSFUL':
				return t(`nofification.incidienttype.${code}`, {
					currencyFrom: meta?.currencyFrom ?? '',
					currencyTo: meta?.currencyTo ?? '',
				});
			default: return i18n.exists(`translations:nofification.incidienttype.${code}`) ? t(`nofification.incidienttype.${code}`) : ticket?.message;
		}
	};





	return (
		<Box
			id='notification-item'
			p='14px'
			onClick={() => !disabled && onClick && onClick(ticket)}
			sx={theme => ({
				cursor: disabled || !onClick ? 'default' : 'pointer',
				borderRadius: theme.mainBorderRadius,
				background: theme.palette.mode === 'dark' ? theme.backgroundBoxLevel1 : theme.palette.background.paper// theme.backgroundMessageSelected //theme.backgroundBoxLevel1, 
			})}
		>
			<Grid container>
				<Grid item xs={12} container alignItems='center' >
					<Grid item xs={10}>
						{ticket.isread === false && <UnreadStatus />}
					</Grid>
					<Grid item xs={2} container justifyContent='flex-end'>
						{ticket.incidenttype !== TicketType.INCIDENT &&
							<CloseIcon
								style={{ fontSize: '14px', cursor: 'pointer' }}
								onClick={(event) => handleDeleteTicket(event, ticket.id)}
							/>}
					</Grid>
				</Grid>
				<Grid item xs={10} container mt={1} rowGap={0.5}>
					<Grid item xs={12}>
						<Typography
							fontSize='12px'
							dangerouslySetInnerHTML={{ __html: getMessage() }}>
						</Typography >
					</Grid>
					<Grid item xs={12}>
						<Typography
							fontSize='12px'
							fontWeight='400'
							color='text.secondary'>
							{convertDateLang(ticket.createddate, 'E dd MMM k:mm', i18n.language)}
						</Typography >
					</Grid>
				</Grid>

			</Grid>
		</Box>
	);
};

export default React.memo(NotificationItem);
