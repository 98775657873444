import { Button, Grid, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import './VaultList.css';
import { Vault, VaultInterestType } from '@/features/earn/types';
import MuiTextAccountBoxHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAccountBoxHeader';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setVault } from '@/features/tabs/slice';
import { useTranslation } from 'react-i18next';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import { isFiat } from '@/helpers/currency';
import { TokenAvatarSizeEnum } from '@/componentsMui/Shared/Widgets/Avatars/TokenAvatar';
import TokenAvatarStacked from '@/componentsMui/Shared/Widgets/Avatars/TokenAvatarStacked';
import bigDecimal from 'js-big-decimal';
import InfoIcon from '@mui/icons-material/Info';
import { useState } from 'react';
import PayoutHistoryForm from './PayoutHistoryForm';
interface Props {
	vault: Vault
}
const VaultListCard = ({ vault }: Props) => {

	const theme = useTheme() as Theme;

	const { t } = useTranslation('translations');
	const history = useHistory();
	const dispatch = useDispatch();

	const [openPayoutDialog, setOpenPayoutDialog] = useState<boolean>(false);

	const handleDeposit = () => {
		dispatch(setVault(vault));
		history.push('/wallet/home/earn/deposit');
	};

	const ccy = vault.processProperties.depositTrade ? 'UNITS' : vault.processProperties.ccy;
	const decimalScale = isFiat(vault.processProperties.ccy) ? 2 : 5;
	const depositDisabbled = vault.processProperties.depositDisabled;

	const isDynamicVault = vault.processProperties.dynamicVault || vault.processProperties?.interestType === VaultInterestType.DYNAMIC;

	const handlePayoutHistoryFormClose = () => {
		setOpenPayoutDialog(false);
	};

	return (
		<Grid
			container
			p='30px'
			borderRadius={theme.mainBorderRadius}
			bgcolor={theme.bgSecondaryButton}
			alignItems='center'
			sx={{ cursor: 'pointer' }}
		>
			{openPayoutDialog && isDynamicVault && <PayoutHistoryForm
				open={openPayoutDialog}
				onClose={handlePayoutHistoryFormClose}
				proc={vault.proc}
			/>}
			<Grid container item xs={12} justifyContent='space-between'>
				<Grid item >
					<TokenAvatarStacked
						symbol={vault.name}
						logo={vault.processProperties.logoLink}
						name={ccy}
						variant={TokenAvatarSizeEnum.regular}
						withName
						symbolProps={{ fontSize: '1.25rem' }}
						nameProps={{ fontSize: '0.75rem', fontWeight: '400', marginTop: '0.125rem' }}
						additionalAvatarClassName='vault'
					/>
					{/* <Grid item>
						<Typography fontSize='1.25rem' fontWeight={700} pr='1rem' variant='body1' color='text.primary' noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}>
							{vault.name}
						</Typography>
						<Typography fontSize='0.875rem' color='text.secondary' noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}> {ccy} </Typography>
					</Grid> */}
				</Grid>
				<Grid item>
					<Button
						onClick={handleDeposit}
						variant='simple'
						disabled={depositDisabbled}
					>
						<MuiTextAccountBoxHeader variant='inherit'>
							{t('vaults.plans.subscribeBtn')}
						</MuiTextAccountBoxHeader>
					</Button>
				</Grid>
			</Grid>
			<Grid item xs={12} mt='1em' mb='1em'>
				<StyledDivider color={theme.palette.text.primary} />
			</Grid>
			<Grid container item xs={12}>
				<Grid item md={3} sm={3} xs={6}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<MuiTextCaption fontSize='0.875rem'>
							{isDynamicVault ? t('vaults.v3.vaultCard.dynamic') : <Grid  item container flexWrap='wrap' >
								<NumberFormat
									displayType={'text'}
									decimalScale={2}
									fixedDecimalScale={true}
									thousandsGroupStyle='thousand'
									thousandSeparator={true}
									suffix={'%'}
									value={parseFloat(vault.processProperties.yearlyInterestRate) * 100} /> 
							{vault.processProperties?.maxDynamicInterestRate&&
								<NumberFormat
									displayType={'text'}
									decimalScale={2}
									fixedDecimalScale={true}
									thousandsGroupStyle='thousand'
									thousandSeparator={true}
									suffix={'%'}
									prefix={'-'}
									value={vault.processProperties.maxDynamicInterestRate} />}
							</Grid>
							}
						</MuiTextCaption>
						{isDynamicVault && <Tooltip placement="top" title={t('vaults.v3.vaultCard.infoText')}>
							<InfoIcon fontSize='inherit' sx={{ marginLeft: '0.25em' }} />
						</Tooltip>}
					</div>
					<Typography fontSize='0.75rem' color='text.secondary' noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}> {isDynamicVault ? t('vaults.v3.vaultCard.interest') : t('vaults.v3.vaultCard.yearlyInterest')} </Typography>
				</Grid>
				<Grid item md={3} sm={3} xs={6} sx={{ textAlign: { xs: 'right', sm: 'left' } }} >
					<MuiTextCaption fontSize='0.875rem'>
						{`${vault.processProperties.depositPeriodMonths} ${bigDecimal.compareTo(vault.processProperties.depositPeriodMonths, 1) === 0 ? t('vaults.subsribe.month') : t('vaults.subsribe.months')}`}
					</MuiTextCaption>
					<Typography fontSize='0.75rem' color='text.secondary' noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}>{t('vaults.v3.vaultCard.depositPeriod')}</Typography>
				</Grid>
				<Grid item md={3} sm={3} xs={6}>
					{vault.processProperties.interestPaymentPeriod && !isDynamicVault && <><MuiTextCaption fontSize='0.875rem'>
						{t(`vaults.subsribe.interestPaymentPeriod.${vault.processProperties.interestPaymentPeriod}`)}
					</MuiTextCaption>
						<Typography fontSize='0.75rem' color='text.secondary' noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}>{t('vaults.subsribe.interestPaymentPeriodLbl')}</Typography></>}
					{isDynamicVault && <><MuiTextCaption fontSize='0.875rem' sx={{ textDecoration: 'underline' }} onClick={() => setOpenPayoutDialog(true)} >
						{t('vaults.v3.vaultCard.view')}
					</MuiTextCaption>
						<Typography fontSize='0.75rem' color='text.secondary' noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}>{t('vaults.v3.vaultCard.payoutHistory')}</Typography></>}
				</Grid>
				<Grid item md={3} sm={3} xs={6}>
					<Grid item container flexDirection='row' alignItems='center' justifyContent='flex-end'>
						<MuiTextCaption fontSize='0.875rem' variant='gradient' pr='0.25em'> {ccy} </MuiTextCaption>
						<MuiTextCaption fontSize='0.875rem'>
							<NumberFormat displayType={'text'}
								decimalScale={decimalScale ?? 2}
								fixedDecimalScale={true}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								value={vault.totalBalance}
							/>
						</MuiTextCaption>
					</Grid>

					<Typography fontSize='0.75rem' color='text.secondary' noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'right' }}>{t('vaults.v3.vaultCard.yourDeposit')}</Typography>
				</Grid>

			</Grid>
		</Grid>
	);
};

export default VaultListCard;
