import * as React from 'react';
import { Button, Dialog, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCloseDialogGoToAccounts } from '@/helpers/customHook/useCloseDialog';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';

const CardTransferCancel = () => {

	const { t } = useTranslation('translations');
	const closeModal = useCloseDialogGoToAccounts();
	return (
		<Dialog
			id="card-transfer-cancelled"
			open={true}
			onClose={closeModal}
			maxWidth='xs'
		>
			<Grid container rowGap={3} textAlign='center' py={4} >
				<Grid item xs={12}>
					<MuiHeader>{t('banking.addMoney.messages.topupCancelled')}</MuiHeader>
				</Grid>
				<Grid item xs={12} mt={2}>
					<Button
						variant='customOutlined'
						color='secondary'
						onClick={closeModal}
					>   {t('form.buttons.close')}
					</Button>
				</Grid>
			</Grid>
		</Dialog >
	);
};

export default CardTransferCancel;
