
import React, { useEffect } from 'react';
import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { connect, subscribe, unsubscribe } from '@features/card/slice';
import CardServiceTab from '../CardServiceTab';
import { connect as connectAccount, subscribe as subscribeAccount, unsubscribe as unsubscribeAccount } from '@features/account/slice';
import { UserType } from '@/features/user/types';
import BusinessCardServiceTab from '../BusinessCardServiceTab';
import { Grid } from '@mui/material';
import StatusCard from '@/componentsMui/Dashboard/HomeSummary/Components/StatusCard';

const Cards = (): React.ReactElement => {
	const { loading, list, error } = useSelector((state: RootState) => state.cards);
	const dispatch = useDispatch();
	const { user } = useSelector(
		(state: RootState) => state.user
	);

	useEffect(() => {
		connect();
		dispatch(subscribe());
		return () => {
			unsubscribe();
		};
	}, [dispatch]);
 

	useEffect(() => {
		connectAccount();
		dispatch(subscribeAccount());
		return () => {
			unsubscribeAccount();
		};
	}, [dispatch]);


	useEffect(() => {
		connectAccount();
		dispatch(subscribeAccount());
		return () => {
			unsubscribeAccount();
		};
	}, [dispatch]);

	if (user && (user.type === UserType.BUSINESS || user.onBehalfOf !== null)) {
		return (<BusinessCardServiceTab loading={loading} list={list} error={error} />);
	}

	return (
		<Grid container
			width='100%'
			flexDirection='column'
			flex={1} >
			<Grid item xs>
				<Grid item xs={12} justifyContent='center' >
					<StatusCard />
				</Grid>
				<CardServiceTab loading={loading} list={list} error={error} />
			</Grid>
		</Grid>
	);
};

export default Cards;
