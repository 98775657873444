import request from '@/services/request';
import { AppThunk } from '../../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllocationsPayload, AllocationsStore } from './types';



const initialState: AllocationsStore = {
    loading: true,
    allocations: [],
    skip: 0,
    take: 5,
    count: 0
};

const slice = createSlice({
    name: 'allocations',
    initialState,
    reducers: {

        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setSkip: (state, { payload }: PayloadAction<number>) => {
            state.skip = payload;
        },
        setTake: (state, { payload }: PayloadAction<number>) => {
            state.take = payload;
        },
        setCount: (state, { payload }: PayloadAction<number>) => {
            state.count = payload;
        },
        setAllocations: (state, { payload }: PayloadAction<Array<any>>) => {
            state.allocations = payload;
        }

    }
});

export const { setAllocations, setLoading, setSkip, setTake, setCount } = slice.actions;


export const getAllocations = (payload: AllocationsPayload): AppThunk => {
    return async (dispatch, state) => {
        const { allocations } = state();

        try {
            const response = await request.post('/api/v3/vaults/deposits', payload);
            const { data } = response;
            dispatch(setCount(data.count));
            dispatch(setAllocations([...allocations.allocations, ...data.list]));
            // dispatch(setTake(payload.take));
            dispatch(setSkip(allocations.skip+1));

            return data;
        } catch (e) {
            dispatch(setAllocations([]));
            dispatch(setCount(0));
            dispatch(setSkip(0));
            console.log(e);
        } finally {
            dispatch(setLoading(false));
        }
    };
};




export default slice.reducer;
