import { ThemeOptions } from '@mui/material/styles/createTheme';
import { createTheme } from '@mui/material/styles';

import defaultDarkTheme from '../default/defaultDarkTheme';
//import _ from 'lodash'; 
/* const newTheme: ThemeOptions = _.cloneDeep(defaultDarkTheme); 
newTheme.selectedItemBg.default = 'linear-gradient(96.99deg, #01DDB9 19.1%, #0184FD 71.05%)';
newTheme.iconGradient.stopColor1 = '#01DDB9';
newTheme.highlight= '#01DDB9';
newTheme.highlight= '#01DDB9'; */

const newTheme: ThemeOptions = {
	...defaultDarkTheme,
	selectedItemBg: {
		default: 'linear-gradient(to bottom, #66CBB1 90%, #1D3A32 100%)',
	},
	iconGradient: {
		...defaultDarkTheme.iconGradient,
		stopColor1: '#66CBB1',
		stopColor2: '#1D3A32'
	},
	highlight: '#66CBB1',
	carousel: {
		...defaultDarkTheme.carousel,
		iconShadow: 'inear-gradient(112.26deg, #66CBB1 10.81%, #FFCC0D 160.09%)'
	},
	warningBg:  'linear-gradient(112.26deg, #66CBB11a 10.81%, rgba(1, 132, 253, 0.1) 160.09%)',
	covers: {
		...defaultDarkTheme.covers,
		productCover: {
			...defaultDarkTheme.covers.productCover,
			bg: [
				//accounts, cards, eran products
				{
					firstBg: 'linear-gradient(0deg, #66CBB1, #66CBB1)',
					secondBg: 'linear-gradient(0deg, #66CBB1, #66CBB1)',
				},
				//wallet
				{
					firstBg: 'linear-gradient(0deg, #66CBB1, #66CBB1)',
					secondBg: 'linear-gradient(0deg, #66CBB1, #66CBB1)',
				}]

		},
		paymentMethodCover: {
			...defaultDarkTheme.covers.paymentMethodCover,
			bg: [{
				firstBg: 'linear-gradient(329.09deg, #01DDB9 91.04%, #3D2DDD 91.05%)',
				secondBg: 'linear-gradient(329.09deg, #01DDB9 91.04%, #3D2DDD 91.05%)',
			},
			{
				firstBg: 'linear-gradient(0deg, #0190DA, #0190DA)',
				secondBg: 'linear-gradient(0deg, #0190DA, #0190DA)',
			}],
		},
		newAccountCover: {
			baseBg: '#282828',
			bg: [{
				firstBg: '#3588EA',
				secondBg: '#3588EA',

			},
			{
				firstBg: '#000000',
				secondBg: '#000000',
			},
			{
				firstBg: '#33CCCC',
				secondBg: '#33CCCC',
			},
			{
				firstBg: '#6B3051',
				secondBg: '#6B3051',
			},
			{
				firstBg: '#F0B323',
				secondBg: '#F0B323',
			},
			{
				firstBg: '#BCD4E6',
				secondBg: '#BCD4E6',
			},
			]
		},
		newCardCover: {
			baseBg: 'rgba(255, 255, 255, 0.12)',
			bg: [
				{
					firstBg: 'rgba(102, 255, 166, 0.8)',
					secondBg: 'rgba(102, 255, 166, 0.8)',
				},
				{
					firstBg: '#3588EA',
					secondBg: '#3588EA',
				},
				{
					firstBg: '#FFFFFF',
					secondBg: '#FFFFFF',
				},
				{
					firstBg: '#33CCCC',
					secondBg: '#33CCCC',
				},
				{
					firstBg: '#6B3051',
					secondBg: '#6B3051',
				},
				{
					firstBg: '#F0B323',
					secondBg: '#F0B323',
				},
				{
					firstBg: '#BCD4E6',
					secondBg: '#BCD4E6',
				},
			]
		}
	},
	auth: {
		welcomeCardBg: '#68C0B5',
	},
};

const paletteDark = createTheme(newTheme);

export default paletteDark;
