
import * as React from 'react';

import '@/componentsMui/Shared/Shared.css';

import { Button, FormControl, Grid, InputLabel, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addContact, editContact, getDataByIban } from '@/features/contacts/slice';
import { useDispatch } from 'react-redux';
import { showException, showSuccess } from '@/features/swal/slice';
import { Contact, ContactPaymentTypes } from '@/features/contacts/types';
import { isEmpty } from 'lodash';
import StyledSelect from '@/componentsMui/Shared/Widgets/StyledSelect';
import { BeneficiaryType } from '@/helpers/globalTypes';
import IOSSwitch from '@/componentsMui/Shared/Widgets/IOSSwitch';
import { useEffect, useState } from 'react';
import { electronicFormatIBAN, isValidIBAN } from 'ibantools';
import { getAlpha3Code, getNames } from 'i18n-iso-countries';
import MuiSubHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiSubHeader';
import MuiTextTrx from '@/componentsMui/Shared/Widgets/MuiText/MuiTextTrx';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';


interface Props {
	contacts: Contact[],
	contactToEdit: Contact
}

const getBeneficiaryType = (isBusiness: any) => isBusiness ? BeneficiaryType.BUSINESS : BeneficiaryType.INDIVIDUAL;

const NewSepaContact = ({ contacts, contactToEdit }: Props) => {

	const { t } = useTranslation('translations');
	const closeModal = useCloseDialogNoEvent();

	const dispatch = useDispatch();
	const [beneficiaryType, setBeneficiaryType] = useState<BeneficiaryType>(contactToEdit ? getBeneficiaryType(contactToEdit.business) : BeneficiaryType.INDIVIDUAL);
	const [showRegistrationNo, setShowRegistrationNo] = useState<boolean>(contactToEdit ? (contactToEdit.business || !isEmpty(contactToEdit.identification)) : false);
	const [iban, setIban] = useState<string>(contactToEdit?.account ?? '');
	const [beneficiaryData, setBenificiaryData] = useState<any>();
	const [countries] = useState(getNames('en'));
	const [ibanIsValid, setIbanIsValid] = useState<boolean>(false);


	useEffect(() => {
		if (iban) {
			const getIbanData = async () => {
				const beneficiaryData = await getDataByIban(iban);
				setBenificiaryData(beneficiaryData);
			};
			getIbanData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, []);



	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(true);

		if (!ibanIsValid) { return; }
		try {
			const { name, identification } = formData;
			const payload = {
				name,
				paymentType: ContactPaymentTypes.SEPA,
				account: iban,
				bank: beneficiaryData.bank,
				address: beneficiaryData.address,
				country: getAlpha3Code(beneficiaryData.country, 'en'),
				identification,
				bic: beneficiaryData.bic,
				isBusiness: beneficiaryType === BeneficiaryType.BUSINESS,
				business: beneficiaryType === BeneficiaryType.BUSINESS
			};

			if (contactToEdit) {
				await dispatch(editContact(contactToEdit.beneficiaryAccountId, { ...contactToEdit, ...payload }));

				showSuccess({ title: 'contacts.update.success', mui: true });
			}
			else {
				await dispatch(addContact(payload));
				showSuccess({ title: 'contacts.create.success', mui: true });
			}

		} catch (e) {
			showException(e);
		}
		finally {
			setSubmitting(false);
			closeModal();
		}
	};

	const isNotInContacts = (account: string) => {
		const alreadyInContacts = contactToEdit ?
			contacts.filter(entry => entry.paymentType === ContactPaymentTypes.SEPA && entry.account === account && entry.beneficiaryAccountId !== contactToEdit.beneficiaryAccountId)
			: contacts.filter(entry => entry.paymentType === ContactPaymentTypes.SEPA && entry.account === account);

		return alreadyInContacts.length <= 0;
	};

	const isValidId = (value: string) => {
		return !(beneficiaryType === BeneficiaryType.BUSINESS && (isEmpty(value) || value === undefined));
	};

	const validationSchema = Yup.object({
		name: Yup.string().required(t('form.validator.required')),
		iban: Yup.string().required(t('form.validator.required'))
			.test('iban',
				t('sendmoneyTranslation.data.invalidIban'),
				() => ibanIsValid)
			.test('already',
				t('sendmoneyTranslation.data.alreadyInContacts'),
				(value) => isNotInContacts(value)),
		identification: Yup.string().test('identification',
			t('form.validator.required'),
			(value) => isValidId(value))
	});

	const initialValues = {
		name: contactToEdit?.name ?? '',
		iban: contactToEdit?.account ?? '',
		bank: '',
		bic: '',
		country: contactToEdit?.country ?? '',
		address: '',
		identification: contactToEdit?.identification ?? ''
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: submit,
	});


	const handlesBeneficiaryType = (event: any) => {
		setBeneficiaryType(event.target.value);
		setTimeout(() => formik.setFieldTouched('identification', true));

		if (event.target.value === BeneficiaryType.BUSINESS) { setShowRegistrationNo(true); }
	};

	const handleSetRegistrationNo = () => {
		if (showRegistrationNo) {
			formik.setFieldValue('identification', '');
		}
		setShowRegistrationNo(!showRegistrationNo);

	};

	const handleIbanChange = (event: React.ChangeEvent<any>) => {
		const iban = electronicFormatIBAN(event.target.value).replaceAll(' ', '');
		setIban(iban);
		formik.setFieldValue('iban', iban);
	};

	const handleIbanOnBlur = async () => {
		if (!isValidIBAN(iban)) {
			setIbanIsValid(false);
			setBenificiaryData(null);
		} else {
			setIbanIsValid(true);
			const beneficiaryData = await getDataByIban(
				iban.replace(/\s/g, '')
			);
			setBenificiaryData(beneficiaryData);
		}
		setTimeout(() => formik.setFieldTouched('iban', true));
	};

	return (
		<form
			id="newWalletContact"
			className="portal-dialog-form-template"
			onSubmit={formik.handleSubmit}
		>
			<Grid container sx={{ height: '100%' }} >
				<Grid id="contentWrap"
					item
					spacing={2}
					container
					alignSelf="flex-start" >
					<Grid item xs={12}  >
						<MuiSubHeader >{t('contacts.create.sepa')}</MuiSubHeader>
						<MuiTextTrx marginTop="0.5rem" >{t('contacts.create.sepaText')}</MuiTextTrx>
					</Grid>
					<Grid item xs={12} container>
						<Grid item xs={12} sm={6}>
							<FormControl fullWidth >
								<InputLabel>{t('contacts.create.beneficiaryType')}</InputLabel>
								<StyledSelect
									id="beneficiaryType"
									name="beneficiaryType"
									value={beneficiaryType}
									onChange={handlesBeneficiaryType} >
									<MenuItem
										value={BeneficiaryType.INDIVIDUAL}>
										<div className='select-primary-Item'>{t('sendmoneyTranslation.data.individual')} </div>

									</MenuItem>
									<MenuItem
										value={BeneficiaryType.BUSINESS}>
										<div className='select-primary-Item'>{t('sendmoneyTranslation.data.business')} </div>
									</MenuItem>
								</StyledSelect>
							</FormControl>
						</Grid>
						<Grid item container xs={12} sm={6} justifyContent="space-between" alignItems='center' pt='1.5rem' pl='0.5rem'>
							<MuiTextCaption fontWeight='400' fontSize='0.875rem'> {t('contacts.create.beneficiaryId')}</MuiTextCaption>
							<IOSSwitch
								checked={showRegistrationNo}
								disabled={beneficiaryType === BeneficiaryType.BUSINESS}
								onChange={handleSetRegistrationNo} />
						</Grid>
						<Grid item xs={12}>
							{showRegistrationNo && <TextField
								id="identification"
								name="identification"
								fullWidth
								value={formik.values.identification}
								onChange={formik.handleChange}
								label={beneficiaryType === BeneficiaryType.BUSINESS ? t('contacts.create.businessRegistrationNo') : t('contacts.create.contact')}
								error={formik.errors.identification && Boolean(formik.touched.identification)}
								helperText={formik.errors.identification}
							/>}
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="name"
							name="name"
							label={t('contacts.create.sepaName')}
							fullWidth
							value={formik.values.name}
							onChange={formik.handleChange}
							error={formik.errors.name && Boolean(formik.touched.name)}
							helperText={formik.errors.name}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="iban"
							name="iban"
							label={t('contacts.create.iban')}
							fullWidth
							value={iban}
							onChange={handleIbanChange}
							onBlur={handleIbanOnBlur}
							error={formik.errors.iban && Boolean(formik.touched.iban)}
							helperText={formik.errors.iban}
						/>
					</Grid>
					<Grid item xs={12} container spacing={3}>

						<Grid item xs={12} sm={6}>
							<TextField
								id="bank"
								name="bank"
								label={t('contacts.create.bank')}
								fullWidth
								value={beneficiaryData?.bank ? beneficiaryData.bank : ''}
								disabled
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								id="bic"
								name="bic"
								label={t('contacts.create.bicSwift')}
								fullWidth
								value={beneficiaryData?.bic ? beneficiaryData.bic : ''}
								disabled
							/>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<TextField
							id="country"
							name="country"
							label={t('contacts.create.country')}
							fullWidth
							value={beneficiaryData?.country ? countries[beneficiaryData.country] : ''}
							disabled
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="address"
							name="address"
							label={t('contacts.create.address')}
							fullWidth
							value={beneficiaryData?.address ? beneficiaryData.address : ''}
							disabled
						/>
					</Grid>
				</Grid>
				<Grid item container mt='2rem' alignSelf="flex-end" justifyContent="center">
					<Grid item>
						<Button disabled={formik.isSubmitting || !isEmpty(formik.errors || !formik.dirty)}
							key="buttonNext"
							fullWidth
							variant='contained'
							color='primary'
							type="submit" >
							{t('contacts.create.save')}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</form >
	);
};

export default NewSepaContact;
