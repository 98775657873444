import { Button, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	onClose: () => void
}

const FormCloseButton = ({ onClose }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	return (
		<Grid container flex={1} alignItems='flex-end' justifyContent='space-between' mt='auto' pt='32px'>
			<Button
				variant='customOutlined'
				onClick={onClose}
			>
				{t('form.buttons.close')}
			</Button>
		</Grid>
	);
};

export default FormCloseButton;
