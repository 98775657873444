import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { AccountType } from '@/features/operations/types';
import BankingTab from '../BankingTab';
import { connect, subscribe, unsubscribe } from '@features/account/slice';
import { Grid } from '@mui/material';
import BaseBox from '@/componentsMui/Shared/Widgets/Box/BaseBox';
import AccountBeingCreated from './AccountBeingCreated';
import StatusCard from '@/componentsMui/Dashboard/HomeSummary/Components/StatusCard';
import { isUserVerified } from '@/helpers/kyc';

const Accounts = (): React.ReactElement => {
	const { loading, list, error } = useSelector((state: RootState) => state.accounts);
	const accounts = list.filter(p => p.type === AccountType.BANK || p.type === AccountType.BANK_AND_CARD);
	const { user, status } = useSelector((state: RootState) => state.user);

	const kycAddressList = useSelector((state: RootState) => state.kyc.kycAddressList);
	const dispatch = useDispatch();

	useEffect(() => {
		connect();
		dispatch(subscribe());
		return () => {
			unsubscribe();
		};
	}, [dispatch]);


	const userVerified = isUserVerified(user, status, kycAddressList);

	return (
		<div className="scroll-container">
			<Grid container >
				<Grid item xs={12}>
					<StatusCard />
				</Grid>
				{userVerified && accounts?.length < 1 && !loading &&
					<Grid item xs={12}>
						<BaseBox id="selected-item-view">
							<AccountBeingCreated />
						</BaseBox>
					</Grid>}
				{userVerified && <Grid item xs={12}>
					<BankingTab list={accounts} error={error} />
				</Grid>}
			</Grid>

		</div>
	);
};

export default Accounts;
