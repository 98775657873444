import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';

const useTrustAccounts = (currency?: string) => {

	const { loading: accountsLoading, accounts, error: accountListError } = useSelector((state: RootState) => state.trust); 
	const accountList = currency ? accounts.filter(p => p.currency === currency) : accounts;  

	return { accountsLoading, accountList, accountListError };
};

export default useTrustAccounts;
