import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PartnersPayloadType, PartnerType, SectionStatus, SectionType } from '@/features/business/types';
import { postPartners } from '@/features/business/slice';
import { Alert, Button, Grid } from '@mui/material';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import BusinessPartnerItem from './BusinessPartnerItem';
import { showException } from '@/features/swal/slice';
import { useFormikForms } from '../../formHooks';
import MuiTextAlert from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAlert';

const initPartner: PartnerType = {
	partnerCompanyName: '',
	partnerIncorporationCountry: '',
	plannedNumberOfMonthlyTransactions: null,
	plannedVolumeOfMonthlyTransactons: null,
	partnerType: ''
};

interface Props {
	clientId: string,
	section?: SectionType,
	onNext: () => void,
	onBack: () => void
}

const BusinessPartners = ({ clientId, section, onNext, onBack }: Props): React.ReactElement => {

	const { t } = useTranslation('translations');
	const sectionValues = section?.values as PartnersPayloadType;

	const { forms, allowPostData, disableSubmit, handleNext, nextStep, save, setSave, addForm, updateForm, removeForm } =
		useFormikForms(sectionValues?.partners, initPartner);


	useEffect(() => {
		const postData = async () => {
			let error = false;
			if (allowPostData) {
				try {

					const payload: PartnersPayloadType = {
						clientId: clientId,
						partners: forms.map(p => p.values)
					};
					await postPartners(payload);
				}
				catch (e) {
					error = true;
					showException(e);
				}
			}
			if (nextStep && !error) { onNext && onNext(); }

		};
		postData();
		setSave(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allowPostData, clientId, save, nextStep]);

	const doNotAllowUpdate = section?.status === SectionStatus.APPROVED;
	return (<div id='business-partners' className='form-content_main-area-nopadding'>
		<Grid container rowGap={4}>
			{doNotAllowUpdate &&
				<Grid item xs={12} >
					<Alert severity="success">
						<MuiTextAlert> {t('createbusiness.information.approved')}</MuiTextAlert>
					</Alert>
				</Grid>
			}
			{section?.status === SectionStatus.REJECTED && section?.rejectionReason &&
				<Grid item xs={12} >
					<Alert severity="warning">
						<MuiTextAlert>{section?.rejectionReason}</MuiTextAlert>
					</Alert>
				</Grid>
			}
			<Grid item xs={12}>
				<MuiHeader className='business-header'> {t('createbusiness.partners.title')}</MuiHeader>
			</Grid>
			{forms?.length > 0 && forms?.map((p, idx) =>
				<Grid key={p.id} item xs={12}>
					<BusinessPartnerItem id={p.id}
						allowRemove={idx > 0 && !doNotAllowUpdate}
						partner={p.values}
						updateForm={updateForm}
						removeForm={removeForm}
						doNotAllowUpdate={doNotAllowUpdate} />
				</Grid>)}
			<Grid item xs={12}>
				<Button
					variant='customOutlined'
					onClick={addForm}
					fullWidth
					disabled={doNotAllowUpdate}
				>  {t('createbusiness.partners.add')}
				</Button>
			</Grid>
		</Grid>
		<Grid container className='form-content_botton-area'>
			<Grid item xs={6}>
				<Button
					variant='customOutlined'
					onClick={onBack}
				>  {t('form.buttons.back')}
				</Button>
			</Grid>
			<Grid item xs={6} container justifyContent='flex-end' columnGap={3}>
				<Button disabled={disableSubmit}
					variant='contained'
					onClick={handleNext}
				>
					{t('form.buttons.continue')}
				</Button>
			</Grid>
		</Grid>
	</div >
	);
};

export default BusinessPartners;
