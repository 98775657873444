import * as React from 'react';

import { Button, Dialog, Grid, IconButton, InputLabel, Link, ListItem } from '@mui/material';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import DialogContentNoScrollbar from '@/componentsMui/Shared/Widgets/Dialog/DialogContentNoScrollbar';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { isEmpty } from 'lodash';
import { postTicket } from '@/features/tickets/slice';
import { showException, showSuccess } from '@/features/swal/slice';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import MuiTextLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextLabel';
import { useEmail } from '@/helpers/customHook/useValidations';

interface Props {
	open: boolean,
	showContactEmail?: boolean,
	onClose: () => void
}

const fileTypes = [
	'image/jpg',
	'image/jpeg',
	'image/png',
	'application/pdf',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

const SimpleTicketForm = ({ open, showContactEmail, onClose }: Props) => {
	const { t } = useTranslation('translations');
	const emailValidation = useEmail();
	const [selectedFiles, setSelectedFiles] = useState([]);
	const hiddenFileInput = React.useRef(null);

	const validationSchema = Yup.object({
		subject: Yup.string().required(t('form.validator.required')),
		description: Yup.string().required(t('form.validator.required')),
		contactEmail: emailValidation,
	});

	const initialValues = {
		subject: '',
		description: '',
		contactEmail: ''
	};


	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const uploadFiles = (files: any) => {
		if (files.length === 0 || files.length > 5) {
			return;
		}

		for (let i = 0; i < files.length; i++) {
			if (files[i].size > 5000000) {
				showSuccess({ title: t('swal.FILE_SIZE_TOO_LARGE', { fileName: files[i].name }), mui: true, confirmBTNText: t('form.buttons.close') });
				continue;
			}
			if (fileTypes.indexOf(files[i].type) != -1 && selectedFiles.length < 5) {
				selectedFiles.push(files[i]);
				setSelectedFiles(selectedFiles);
			}
		}
	};

	const handleUploadClick = () => {
		hiddenFileInput.current.click();
	};

	const deleteAttachment = (index: number) => {
		selectedFiles.splice(index, 1);
		setSelectedFiles(selectedFiles);
	};

	const onTicketSend = (value?) => {
		const get = async () => {
			try {
				if (value.description === undefined || value.subject === undefined) {
					return;
				}
				const formData = new FormData();
				formData.append('description', value.description);
				formData.append('subject', value.subject);
				//	formData.append('subType', 'Others');
				formData.append('email', value.contactEmail);

				if (value.files) {
					value.files.map(file => {
						formData.append('files', file);
					});
				}
				await postTicket(formData);
				showSuccess({ title: t('swal.SUPPORT_TICKET_SENT'), mui: true, confirmBTNText: t('form.buttons.close') });
			} catch (e) {
				showException(e);
			}
		};
		onClose();
		get();
	};

	return (
		<Dialog
			id="FAQForm"
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
		>
			<DialogContentNoScrollbar>
				<Grid
					container
					sx={{ p: { xs: '1rem', md: '2rem' } }}
					justifyContent="center"
				>
					<Grid container justifyContent="space-between" alignItems="center" >
						<Grid item>
							<MuiHeader>{t('faq.submitTicket')}</MuiHeader>
						</Grid>
						<Grid item>
							<IconButton onClick={onClose}  >
								<MuiCloseIcon />
							</IconButton>
						</Grid>
					</Grid>

					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onTicketSend}
					>
						{({ setFieldValue, isSubmitting, errors, dirty, touched }) => {
							return (
								<Form style={{ width: '100%' }}>
									<Grid container rowSpacing={2} pt={'28px'}>
										<Grid item xs={12} >
											<MuiTextField
												name="subject"
												label={t('inbox.subject')}
												variant="outlined"
												fullWidth
												error={errors.subject && touched.subject}
											/>
										</Grid>
										{/* <Grid item xs={12}>
											<FormControl id="ticketType" fullWidth  >
												<InputLabel>{t('inbox.ticketSubType')}</InputLabel>
												<StyledSelect
													name="subtype"
													displayEmpty
													value={values.subtype}
													onChange={(e) => { setFieldValue('subtype', e.target.value); }}
												>
													{
														subtypeoption.map((item, idx) => (
															<MenuItem
																key={idx}
																value={item.key} >
																<div>
																	<Grid container spacing={1} alignItems="center">
																		<Grid item >
																			<div className='select-primary-Item'>{item.text} </div>
																		</Grid>
																	</Grid>
																</div>
															</MenuItem>
														))}
												</StyledSelect>
												{errors.subtype && touched.subtype && <MuiFormHelperText>{t('form.validator.required')}</FormHelperText>}
											</FormControl>
										</Grid> */}

										<Grid item xs={12}>
											<MuiTextField
												name="description"
												label={t('inbox.description')}
												variant="outlined"
												multiline
												minRows={2}
												maxRows={4}
												fullWidth
												error={errors.description && touched.description}
											/>
										</Grid>
										{showContactEmail &&
											<Grid item xs={12}>
												<MuiTextField
													name="contactEmail"
													label='E-mail'
													fullWidth
													error={errors.contactEmail && touched.contactEmail}
												/>
											</Grid>
										}
										<Grid item xs={12}>
											<Grid container alignItems="center" justifyContent="space-between">
												<InputLabel>{t('inbox.fileSize')}</InputLabel>
												<Link onClick={handleUploadClick}>{t('inbox.uploadButton')} </Link>
											</Grid>
											<input type="file"
												multiple
												accept="image/jpg, image/png, image/jpeg, .pdf, .doc, .docx"
												name="files"
												ref={hiddenFileInput}
												onChange={e => {
													uploadFiles(e.currentTarget.files);
													setFieldValue('files', selectedFiles);
												}}
												style={{ display: 'none' }}
											/>
											{selectedFiles.length > 0 && (
												<Grid item>
													{selectedFiles.map((file, index) =>
														<ListItem key={index} sx={{ pb: '1px', pl: 0 }}>
															<MuiTextLabel key={index} > {file.name} </MuiTextLabel>
															<MuiCloseIcon width="16px" height="16px"
																onClick={() => {
																	deleteAttachment(index);
																	setFieldValue('files', selectedFiles);
																}} />

														</ListItem>
													)}
												</Grid>
											)}
										</Grid>
										<Grid item xs={12}>
											<Button
												type="submit"
												variant="contained"
												color='primary'
												fullWidth
												disabled={isSubmitting || !isEmpty(errors) || !dirty}
											>
												{t('form.buttons.send')}
											</Button>
										</Grid>
									</Grid>
								</Form>
							);
						}}
					</Formik>
				</Grid>
			</DialogContentNoScrollbar>
		</Dialog >
	);
};

export default SimpleTicketForm; 
