import React from 'react';
import { Dialog, DialogContent, Grid, IconButton } from '@mui/material';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import CardTransactionGrid from '@/componentsMui/TransactionsGrid/CardTransactionGrid';
import ExportStatementForm from '@/componentsMui/Transactions/Download/ExportStatementForm';
import { AccountType } from '@/features/operations/types';
import DownloadButton from '@/componentsMui/Shared/Widgets/DownloadButton';
import FormPaperFullScreenMobile from '@/componentsMui/Shared/Widgets/Dialog/FormPaperFullScreenMobile';


interface Props {
	account: any,
	open: boolean,
	onClose: () => void
}

const CardTransactionForm = ({ account, open, onClose }: Props): React.ReactElement => {

	const [openDownloadForm, setOpenDownloadForm] = React.useState<boolean>(false);


	return (<>
		<ExportStatementForm
			accountId={account?.accountId}
			open={openDownloadForm}
			type={AccountType.CARD}
			onClose={() => setOpenDownloadForm(false)} />

		<Dialog
			id="transaction-form"
			open={open}
			fullWidth
			PaperComponent={FormPaperFullScreenMobile}
			maxWidth='lg'
		>
			<DialogContent style={{ height: '100vh' }}>
				<Grid container direction='column' height='100%' flexWrap='nowrap'>
					<Grid item width='100%' textAlign='right'>
						<DownloadButton
							sx={{ background: 'transparent', mr: 2 }}
							onClick={() => setOpenDownloadForm(true)} />
						<IconButton onClick={onClose}  >
							<MuiCloseIcon />
						</IconButton>
					</Grid>
					<Grid item xs={11} >
						<CardTransactionGrid account={account} />
					</Grid>
				</Grid>

			</DialogContent>
		</Dialog >
	</>
	);
};

export default CardTransactionForm;
