import * as React from 'react';
import { ClipboardEvent } from 'react';
import '@shared/Shared.css';
import '@shared/SharedFormLayout.css';

import {
	Button,
	Grid,
	InputAdornment,
	Theme, Typography
} from '@mui/material';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { BankAccount } from '@features/account/types';
import { Contact } from '@features/contacts/types';
import { SystemType } from '@helpers/globalTypes';
import FormContentBox from '@shared/Widgets/Box/FormContentBox';
import MuiTextField from '@shared/FormikComponents/MuiTextField';
import { maxTwoDecimals } from '@helpers/number';
import { getCurrencySign, postTransferFee } from '@features/fees/slice';
import NumberFormat from 'react-number-format';
import StyledDivider from '@shared/Widgets/StyledDivider';
import _ from 'lodash';
import useFeeDebounce from '@helpers/customHook/useFeeDebounce';
import { PaymentPayloadType } from '@/componentsMui/Type/PaymentPayloadType';
import useCloseDialog from '@helpers/customHook/useCloseDialog';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import FormLabel from '@/componentsMui/Shared/Components/SendMoney/PaymentForm/FormLabel';
import AccountItem from '@/componentsMui/Banking/Account/AccountItem';
import { useTheme } from '@mui/styles';
import { getAccountName } from '@helpers/getAccountName';
import bigDecimal from 'js-big-decimal';
import { isFiat } from '@/helpers/currency';
import useCurrencyLimits from '@/helpers/customHook/useCurrencyLimits';
import LoadingPageMui from '@/pages/LoadingPageMui';
import UserLimitMessage from '../../../UserLimitMessage';
import { roundAny, toNumber } from '@/helpers/round';

type Props = {
	paymentType: SystemType,
	accountList: BankAccount[],
	defaultAccount?: BankAccount,
	defaultContact?: Contact,
	paymentPayload: PaymentPayloadType,
	paymentName: string,
	setPaymentPayload: (value: PaymentPayloadType) => void,
	nextStep?: () => void,
	prevStep?: () => void,
	prevStepMain?: () => void
}

const AmountStep = ({ paymentName,
	accountList,
	paymentType,
	defaultAccount,
	paymentPayload,
	setPaymentPayload,
	nextStep,
	prevStepMain }: Props) => {

	const theme = useTheme() as Theme;
	const procType = 'PAYMENT';
	const { t } = useTranslation('translations');

	//accounts with same payment type
	const paymentAccounts = accountList.filter(acc => {
		if (acc.subProcesses) {


			const account = _.keys(acc.subProcesses).filter(k => acc?.subProcesses[k]?.processProperties?.paymentType === paymentType).length > 0;
			return account;

		}
		else {
			return false;
		}
	});


	const initAccount = paymentPayload.account ?? defaultAccount ?? paymentAccounts[0] ?? null;

	const [selectedAccount] = useState<BankAccount>(initAccount);
	const [amount, setAmount] = useState<string>(paymentPayload?.amount?.toString() ?? '');
	const [amountforFee, setAmountForFee] = useState<number>(paymentPayload?.amount ?? 0);

	const systems = selectedAccount && Object.keys(selectedAccount?.subProcesses).filter(key => selectedAccount?.subProcesses[key].type === procType).map(key => selectedAccount?.subProcesses[key]);
	const process = systems?.find(s => s?.processProperties?.paymentType === paymentType);
	const processIsDown = process?.maintenance;

	const { fee, callGetFee, feeLoading } = useFeeDebounce(paymentPayload?.fee ?? 0);
	const youpay = amountforFee + fee;
	const currency = getCurrencySign(selectedAccount?.currency ?? '');
	const precision = selectedAccount?.precision ?? 2;

	const closeModal = useCloseDialog();

	const formRef = React.useRef<FormikProps<FormikValues>>(null);

	const [submitLocked, setSubmitLocked] = useState<boolean>(false);

	const {
		userLimits,
		loadingLimits,
		isLimitSet,
		isLimitExceeded } = useCurrencyLimits(selectedAccount?.currency);

	const limitReached = isLimitExceeded(amount);

	useEffect(() => {
		if (formRef.current) {
			formRef.current.validateForm();
			setSubmitLocked(false);
		}
	}, [fee, feeLoading]);

	const initialValues = {
		from: defaultAccount ? defaultAccount.accountId : '',
		amount: paymentPayload.amount ?? '',
		//youpay: isNaN(paymentPayload?.youpay) ? '0' : paymentPayload?.youpay?.toFixed(2),
	};


	const validationSchema = Yup.object({
		amount: Yup.number().typeError(t('form.validator.required')).required(t('form.validator.required')).nullable()
			.moreThan(0, t('sendmoneyTranslation.data.moreThanZero'))
			.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value))
			.test('amount', t('sendmoneyTranslation.data.insufficient'),
				() => bigDecimal.compareTo(youpay, selectedAccount?.availableBalance ?? 0) <= 0),
	});



	const submit = async (formData, formikProps) => {
		const { setFieldError } = formikProps;
		try {
			const { amount } = formData;
			if (!process?.proc) {
				setFieldError('from', t('sendmoneyTranslation.data.missingProcess'));
				return;
			}

			const paymentAmount = parseFloat(amount);
			const feeResult = await postTransferFee({ accountId: selectedAccount.accountId, proc: process?.proc, amount: paymentAmount });

			const payload: PaymentPayloadType = {
				...{
					amount: paymentAmount,
					fee: feeResult.fee,
					youpay: feeResult.fee + paymentAmount,
					proc: process?.proc,
					account: selectedAccount,
					mandatoryAttachementAmount: process?.config?.maxWithoutDocument ?? 5000
				}
			};

			setPaymentPayload(payload);
			nextStep();
		}
		catch (e) {
			console.log(e);
		}
	};

	const amountChange = (value, setFieldValue, setFieldTouched) => {

		setSubmitLocked(true);
		const parsed = toNumber(value);
		const newAmount = roundAny(parsed, 2);
		//setAmount(`${newAmount}`);
		setAmount(`${value === '' ? '' : newAmount}`);
		setFieldValue('amount', `${newAmount}`);
		setAmountForFee(newAmount);
		callGetFee(selectedAccount.accountId, process?.proc, newAmount);
		setTimeout(() => setFieldTouched('youpay', true));
		setTimeout(() => setFieldTouched('amount', true));
	};


	const handleAmountChange = (event: React.ChangeEvent<any>, setFieldValue, setFieldTouched) => {
		amountChange(event.target.value, setFieldValue, setFieldTouched);
	};

	const handlePaste = (event: ClipboardEvent<HTMLInputElement>, setFieldValue, setFieldTouched) => {
		const pastedText = event.clipboardData.getData('Text');
		if (pastedText.indexOf(',') !== -1) {
			event.preventDefault();
			amountChange(pastedText.replace(',', '.'), setFieldValue, setFieldTouched);
		}
	};

	const setAmountToMax = async (setFieldValue, setFieldTouched) => {
		const availableBalance = selectedAccount?.availableBalance;
		const feeResult = await postTransferFee({ accountId: selectedAccount.accountId, proc: process?.proc, amount: availableBalance });
		const amount = availableBalance - feeResult.fee < 0 ? 0 : availableBalance - feeResult.fee;
		amountChange(`${amount}`, setFieldValue, setFieldTouched);
	};

	if (loadingLimits) { return <LoadingPageMui />; }

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={submit}
			innerRef={formRef}
		>
			{/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
			{({ errors, isSubmitting, setFieldValue, setFieldTouched }) => {
				return (
					<Form className='form-content__wrap__bb'>
						<FormContentBox noBackground={true} className='form-content_main-area wp'>
							<Grid container columnSpacing={3} rowSpacing={1}>
								<Grid item xs={12} container>
									<FormHeader title={paymentName} onBack={prevStepMain} showBack={!!prevStepMain} showMaintenace={processIsDown} />
								</Grid>
								<Grid item xs={12}>
									<FormLabel>{t('form.fields.from')}</FormLabel>
									<AccountItem currency={selectedAccount.currency} logoSource={selectedAccount.currencyLogo} name={getAccountName(t, selectedAccount)} balance={selectedAccount.availableBalance} decimalScale={isFiat(selectedAccount.currency) ? 2 : 5} />
								</Grid>
								<Grid item xs={12}>
									<Grid container item xs={12} my={1} justifyContent="space-between" alignItems="center">
										<Grid item>
											<FormLabel>{t('sendmoneyTranslation.data.amount')}</FormLabel>
										</Grid>
										{isLimitSet &&
											<Grid item xs={12}>
												<UserLimitMessage userLimits={userLimits} limitReached={limitReached} />
											</Grid>
										}
									</Grid>
									<NumberFormat
										name="amount"
										customInput={MuiTextField}
										className='large-number-input'
										onPaste={(event) => handlePaste(event, setFieldValue, setFieldTouched)}
										onChange={(event) => handleAmountChange(event, setFieldValue, setFieldTouched)}
										value={amount}
										displayType={'input'}
										placeholder={'0.00'}
										decimalScale={precision}
										allowedDecimalSeparators={['.']}
										allowNegative={false}
										fullWidth
										sx={{
											'& .MuiInputAdornment-root p': {
												fontSize: '1.3rem'
											},
											'& input::placeholder': {
												fontSize: '1.3rem',
											}
										}}
										inputProps={{ autoComplete: 'off', maxLength: 300, sx: { fontSize: '1.3rem' } }}
										InputProps={{
											style: { fontSize: '1.3rem' },
											sx: { background: theme.backgroundBoxLevel1, height: '74px!important', fontSize: '1.3rem' },
											startAdornment:
												<InputAdornment position="start">
													{currency}
												</InputAdornment>,
											endAdornment:
												<InputAdornment position='end'>
													<span style={{ height: '0.8rem', cursor: 'pointer', textDecoration: 'underline', fontSize: '0.8rem', lineHeight: '0.8rem' }}
														onClick={() => setAmountToMax(setFieldValue, setFieldTouched)}>MAX</span>
												</InputAdornment>,
										}}
									/>
								</Grid>
								<Grid item xs={12} mt='1rem'>
									<StyledDivider />
								</Grid>
								<Grid container item xs={12} justifyContent='space-between' alignItems='center'>
									<Grid item>
										<Typography color='text.primary' sx={{ fontSize: '1.225rem' }}>
											{t('sendmoney.data.youWillPay')}
										</Typography>
									</Grid>
									<Grid item>
										<Typography color='text.primary' fontWeight={700} sx={{ fontSize: '1.225rem' }}>
											<Typography variant='highlight'>
												{`${selectedAccount.currencySymbol ?? getCurrencySign(selectedAccount.currency)}`}
											</Typography>
											<NumberFormat
												id="youpay"
												name="youpay"
												displayType={'text'}
												decimalScale={2}
												value={youpay}
												fixedDecimalScale={true}
												thousandsGroupStyle='thousand'
												thousandSeparator={true}
												prefix={' '}
												placeholder={'0.00'}
											/>
										</Typography>
									</Grid>
								</Grid>
								<Grid item xs={12} container justifyContent='right' sx={{ p: '0!important' }}>
									<Typography color='text.secondary' sx={{ fontSize: '0.7rem' }}>
										{t('sendmoney.data.includedWithFee')}
									</Typography>
									<Typography color='text.primary' sx={{ fontSize: '0.7rem', ml: 0.5 }} noWrap>
										<NumberFormat
											id="fee"
											name="fee"
											displayType={'text'}
											decimalScale={precision}
											value={new bigDecimal(fee).round(precision, bigDecimal.RoundingModes.DOWN).getValue() ?? 0}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											prefix={' '}
											placeholder={'0.00'}
										/>
									</Typography>
								</Grid>
							</Grid>
							<Grid container mt='auto' pt='2rem' columnSpacing={2}>
								<Grid item xs={6}>
									<Button
										variant='customOutlined'
										color='secondary'
										onClick={closeModal}
										sx={{ width: { xs: '100%', md: 'auto' } }}
									>  {t('form.buttons.close')}
									</Button>
								</Grid>
								<Grid item xs={6} container justifyContent='flex-end'>
									<Button disabled={isSubmitting || !isEmpty(errors) || feeLoading || processIsDown || submitLocked}
										variant='contained'
										color='primary'
										type="submit"
										sx={{ width: { xs: '100%', md: 'auto' } }}
									>
										{t('form.buttons.next')}
									</Button>
								</Grid>
							</Grid>
						</FormContentBox>
					</Form>
				);
			}}
		</Formik >);
};

export default AmountStep;
