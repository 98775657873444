import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Policy from './Policy';
import '@/componentsMui/Shared/Shared.css';
import { PolicyType } from '@/features/settings/type';
import MinimalLayout from '../Layout/MinimalLayout';
import { Theme, useMediaQuery, useTheme } from '@mui/material';


export const showPolicy = (agent: string, policyType: PolicyType) => {

	switch (policyType) {
		case PolicyType.AML_POLICY:
			return [].includes(agent);
		case PolicyType.COOKIES_POLICY:
			return false;
		case PolicyType.TERMS_OF_USE:
			return ['IDEALOOP', 'OLAMIT', 'WZ', 'ARONWIRE', 'CNDA', 'HAWEX', 'VOOLA', 'MONETAL', 'REW', 'CARDAQ'].includes(agent);
		case PolicyType.TERMS_AND_CONDITIONS:
			return ['XWARD', 'DTS', 'COSPAY', 'CACTUS', 'YESSY', 'OLAMIT', 'WZ', 'SALAZAR', 'ARONWIRE', 'CNDA', 'HAWEX', 'VOOLA', 'MONETAL', 'LUIDORE', 'NEURAL', 'NEURALNET', 'REW', 'NEURALID', 'CUSTODYFY', 'CARDAQ', 'ESCROWFY'].includes(agent);
		case PolicyType.PRIVACY_POLICY:
			return ['XWARD', 'DTS', 'ARONWIRE', 'COSPAY', 'CACTUS', 'YESSY', 'OLAMIT', 'WZ', 'SALAZAR', 'ARONWIRE', 'CNDA', 'HAWEX', 'VOOLA', 'MONETAL', 'LUIDORE', 'NEURAL', 'NEURALNET', 'REW', 'NEURALID', 'CUSTODYFY', 'CARDAQ', 'ESCROWFY'].includes(agent);
		case PolicyType.CRYPTO:
			return ['CUSTODYFY', 'SALAZAR', 'CACTUS', 'DTS', 'LUIDORE', 'YESSY'].includes(agent);
		case PolicyType.CONTACTS:
			return ['CARDAQ'].includes(agent);
		case PolicyType.COMPLAINTS:
			return ['CARDAQ'].includes(agent);
		case PolicyType.TOP_UP_CARD_POLICY:
			return ['COSPAY'].includes(agent);
		case PolicyType.CLUB_MEMBERSHIP_APPLICATION:
			return ['SLP'].includes(agent);
		default:
			return true;
	}
};

const MuiLegal = (): React.ReactElement => {
	const { path } = useRouteMatch();
	const theme = useTheme() as Theme;
	const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<MinimalLayout
			width={smallScreen ? '100%' : '70%'}
			rightPanelGridProps={{
				display: {
					xs: 'none', sm: 'flex'
				}
			}}>
			<Switch>
				<Route path={`${path}/amlpolicy`}>
					<Policy type={PolicyType.AML_POLICY} />
				</Route>
				<Route path={`${path}/privacypolicy`}>
					<Policy type={PolicyType.PRIVACY_POLICY} />
				</Route>
				<Route path={`${path}/terms`}>
					<Policy type={PolicyType.TERMS_AND_CONDITIONS} />
				</Route>
				<Route path={`${path}/cookiespolicy`}>
					<Policy type={PolicyType.COOKIES_POLICY} />
				</Route>
				<Route path={`${path}/termsofuse`}>
					<Policy type={PolicyType.TERMS_OF_USE} />
				</Route>
				<Route path={`${path}/termsofcrypto`}>
					<Policy type={PolicyType.CRYPTO} />
				</Route>
				<Route path={`${path}/termsofvault`}>
					<Policy type={PolicyType.VAULT} />
				</Route>
				<Route path={`${path}/contacts`}>
					<Policy type={PolicyType.CONTACTS} />
				</Route>
				<Route path={`${path}/complaints`}>
					<Policy type={PolicyType.COMPLAINTS} />
				</Route>
				<Route path={`${path}/cardtopup`}>
					<Policy type={PolicyType.TOP_UP_CARD_POLICY} />
				</Route>
				<Route path={`${path}/clubmembership`}>
					<Policy type={PolicyType.CLUB_MEMBERSHIP_APPLICATION} />
				</Route>
			</Switch>
		</MinimalLayout >

	);
};

{/* <Container disableGutters={true}>
			<div className='wrapDashboard'>
				<AppBarCustom authorized={false} />
				<Grid className='portal-container'>
					<div className={`${smallScreen ? 'policy-content-mobile' : 'policy-content'}`}>
						<Switch>
							<Route path={`${path}/amlpolicy`}>
								<Policy type={PolicyType.AML_POLICY} />
							</Route>
							<Route path={`${path}/privacypolicy`}>
								<Policy type={PolicyType.PRIVACY_POLICY} />
							</Route>
							<Route path={`${path}/terms`}>
								<Policy type={PolicyType.TERMS_AND_CONDITIONS} />
							</Route>
							<Route path={`${path}/cookiespolicy`}>
								<Policy type={PolicyType.COOKIES_POLICY} />
							</Route>
							<Route path={`${path}/termsofuse`}>
								<Policy type={PolicyType.TERMS_OF_USE} />
							</Route>
						</Switch>
						<MuiFooter authorized={false} />
					</div>
				</Grid>
			</div>
		</Container > */}

export default MuiLegal;
