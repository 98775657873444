import React from 'react';
import { Avatar, Grid } from '@mui/material';
import MuiTextCaption from '../MuiText/MuiTextCaption';
import './Avatars.css';
import { TokenAvatarSizeEnum } from './TokenAvatar'; 
import { AssetType } from '@/features/coins/types';



interface Props {
	asset: AssetType,
	variant: TokenAvatarSizeEnum
}

/* Both FIAT and Coins */
const AssetAvatar = ({ asset, variant }: Props): React.ReactElement => {

	return (
		<Grid id='fiat-avatar' container alignItems='center'>
				<>
					<Grid item>
						<Avatar className={'wallet avatar-rounded asset-avatar ' + variant?.toString()} src={asset.logo} />
					</Grid>
					<Grid item pl='0.5rem' >
						<MuiTextCaption>{asset?.symbol} </MuiTextCaption>
					</Grid>
				</> 
		</Grid>);
};

export default AssetAvatar;
