import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { setLandingTitle } from '@/features/landingStep/slice';
import { useTranslation } from 'react-i18next';
import AuthAppDownload from '@/componentsMui/Onboarding/GoogleAuth/AuthAppDownload';
import AuthCodeScan from '@/componentsMui/Onboarding/GoogleAuth/AuthCodeScan';
import AuthEnable from '@/componentsMui/Onboarding/GoogleAuth/AuthEnable';

interface Props {
	next: () => void
}

const GoogleAuthForms = ({ next }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const title = t('landing.authStep.title');

	const [step, setStep] = useState<number>(0);
	const { status } = useSelector(
		(state: RootState) => state.user
	);

	const nextAuth = () => setStep(p => p + 1);
	const previousAuth = () => setStep(p => p - 1);



	useEffect(() => {
		dispatch(setLandingTitle(title));
	}, [dispatch, title]);


	const skip2FA = status?.twoFARequired ? undefined : next;


	return (
		<>
			{step === 0 &&
				<AuthAppDownload next={nextAuth} skip2FA={skip2FA} />}
			{step === 1 &&
				<AuthCodeScan
					next={nextAuth}
					previous={previousAuth} />
			}
			{step === 2 &&
				<AuthEnable next={next} previous={previousAuth} />
			} 

		</>);
};

export default GoogleAuthForms;
