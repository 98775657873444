import * as React from 'react';

import { Button, Dialog, Grid, InputAdornment, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { useDispatch, useSelector } from 'react-redux';
import { openForm } from '@/features/forms/slice';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import FormContentBox from '@/componentsMui/Shared/Widgets/Box/FormContentBox';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import AccountDropdown from '@/componentsMui/Shared/FormikComponents/AccountDropdown';
import NumberFormat from 'react-number-format';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { BankAccount } from '@/features/account/types';
import { maxTwoDecimals } from '@/helpers/number';
import { RootState } from '@/rootReducer';
import { hasSubprocessWithType, runOperation, tranformSubProcessByType } from '@/features/operations/slice';
import { getCurrencySign } from '@/features/fees/slice';
import ExchangeNote from './ExchangeNote';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import { muiShowSuccess, showException } from '@/features/swal/slice';
import { getAccountName } from '@/helpers/getAccountName';
import FormPaperLargeFixed from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeFixed';
import useFeeDebounce from '@/helpers/customHook/useFeeDebounce';
import bigDecimal from 'js-big-decimal';

const allowedCurrencies = ['EUR', 'GBP'];

const ForeignExchangeForm = () => {

	const { t } = useTranslation('translations');
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const [accountId] = useState<string>(searchParams.get('account'));
	const dispatch = useDispatch();
	const closeModal = useCloseDialogNoEvent();

	const { list } = useSelector((state: RootState) => state.accounts);
	const { user } = useSelector((state: RootState) => state.user);

	const exchangeAccounts = list.filter(accounts => allowedCurrencies.includes(accounts.currency) && hasSubprocessWithType('FX_ORDER', accounts.subProcesses)) || [];
	const defaultAccount = exchangeAccounts.find(l => `${l.accountId}` === accountId);
	const [fromAccount, setFromAccount] = useState<BankAccount>(defaultAccount);
	const [toAccount, setToAccount] = useState<BankAccount>(null);
	const [amount, setAmount] = useState<string>('');
	const toAccountList = exchangeAccounts.filter(i => i.currency !== fromAccount?.currency && i.integration === fromAccount?.integration) || [];
	const subProc = tranformSubProcessByType(fromAccount?.subProcesses)['FX_ORDER'];
	const processIsDown = subProc?.maintenance;
	const [submitLocked, setSubmitLocked] = useState<boolean>(false);


	const formRef = React.useRef<FormikProps<FormikValues>>(null);

	const { fee, callGetFee, feeLoading } = useFeeDebounce(0);

	const minAmount = subProc?.config?.minTransactionAmount ?? 100;
	const maxAmount = subProc?.config?.maxTransactionAmount ?? 200000;
	const isAutomated = subProc?.processProperties?.processType === 'AUTOMATED';
	const youpay = parseFloat(amount) + fee;

	const initialValues = {
		amount: '',
		toAccount: '',
		fromAccount: ''
	};

	const validationSchema = Yup.object({
		amount: Yup.number().typeError(t('form.validator.required')).required(t('form.validator.required')).
			min(minAmount, t('form.validator.minNumber', { field: t('sendmoneyTranslation.data.exchangeAmount'), amount: minAmount.toString() }))
			.max(maxAmount, t('form.validator.maxNumber', { field: t('sendmoneyTranslation.data.exchangeAmount'), amount: maxAmount.toString() }))
			.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value))
			.test('amount', t('sendmoneyTranslation.data.insufficient'),
				(value) => bigDecimal.compareTo(value + fee, fromAccount?.availableBalance ?? 0) <= 0),
	});

	useEffect(() => {
		if (formRef.current) {
			formRef.current.validateForm();
			setSubmitLocked(false);
		}
	}, [fee, feeLoading]);

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);

	const handleFromAccountChange = (newValue: BankAccount, setFieldTouched) => {
		setFromAccount(newValue);
		if (newValue.currency === toAccount?.currency) {
			setToAccount(null);
		}
		//force revalidate amount field
		setTimeout(() => setFieldTouched('amount', true));
	};

	const handleToAccountChange = (newValue: BankAccount) => {
		setToAccount(newValue);
	};

	const handleAmountChange = (event: React.ChangeEvent<any>, setFieldValue, setFieldTouched) => {
		setTimeout(() => setFieldTouched('amount', true));
		setFieldValue('amount', event.target.value);
		setAmount(event.target.value);
		if (isAutomated) {
			setSubmitLocked(true);
			const parsed = parseFloat(event.target.value);
			const newAmount = isNaN(parsed) ? 0 : parsed;
			callGetFee(fromAccount?.accountId, subProc?.proc, newAmount);
		}
		setTimeout(() => setFieldTouched('youpay', true));
		setTimeout(() => setFieldTouched('amount', true));
	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(true);
		try {

			const body = isAutomated ? {
				fromAccountId: fromAccount.accountId,
				toAccountId: toAccount.accountId,
				changeAmount: amount
			} : {
					docStatus: 'NEW',
					fromAccountId: fromAccount.accountId,
					toAccountId: toAccount.accountId,
					changeAmount: amount,
					updatedBy: user.id
				};

			await runOperation(subProc?.proc, body);

			await muiShowSuccess('paymentsubmitted.data.headerTransaction',
				getCurrencySign(fromAccount?.currency) + ' ' + amount,
				t('paymentsubmitted.data.toaccount', { account: getAccountName(t, toAccount) }),
				t('paymentsubmitted.data.yousent'));

		} catch (e) {
			await showException(e);
		}
		finally {
			setSubmitting(false);
			closeModal();
		}
	};

	return (
		<Dialog
			id="currency-exchange-form"
			open={true}
			PaperComponent={FormPaperLargeFixed}
		>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
				innerRef={formRef}
			>
				{({ errors, isSubmitting, setFieldTouched, setFieldValue }) => {
					return (
						<Form className='form-content__wrap__bb'>
							<FormContentBox noBackground={true} className='form-content_main-area wp' >
								<Grid id='paymentDetails' container columnSpacing={3} rowSpacing={1}>
									<Grid item xs={12}>
										<FormHeader title={t('banking.data.foreignExchangebutton')} showBack={false} showMaintenace={processIsDown} />
									</Grid>
									<Grid item xs={12} sm={6}>
										<AccountDropdown
											fieldlabel={t('form.fields.from')}
											fieldName='from'
											accountList={exchangeAccounts}
											selectedAccount={fromAccount}
											onAccountChange={(value: BankAccount) => handleFromAccountChange(value, setFieldTouched)}
											precision={2} />
									</Grid>
									<Grid item xs={12} sm={6}>
										<AccountDropdown
											fieldlabel={t('form.fields.to')}
											fieldName='to'
											accountList={toAccountList}
											selectedAccount={toAccount}
											onAccountChange={(value: BankAccount) => handleToAccountChange(value)}
											precision={2} />
									</Grid>
									<Grid item xs={12} mt='0.5rem'>
										<NumberFormat
											name="amount"
											customInput={MuiTextField}
											decimalScale={2}
											onChange={(event) => handleAmountChange(event, setFieldValue, setFieldTouched)}
											value={amount}
											allowNegative={false}
											displayType={'input'}
											placeholder={'0.00'}
											fullWidth
											label={t('sendmoneyTranslation.data.transferamount')}
											inputProps={{ autoComplete: 'off' }}
											InputProps={{
												startAdornment:
													<InputAdornment position="start">
														{getCurrencySign(fromAccount?.currency)}
													</InputAdornment>,
											}}
										/>
									</Grid>
									<Grid item xs={12} mt='2.625rem' mb={isAutomated ? '0.5em' : '2.625rem'}>
										<StyledDivider />
									</Grid>
									{isAutomated && <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
										<Grid item>
											<Typography color='text.primary' sx={{ fontSize: '1.225rem' }}>
												{t('sendmoney.data.youWillPay')}
											</Typography>
										</Grid>
										<Grid item>
											<Typography color='text.primary' fontWeight={700} sx={{ fontSize: '1.225rem' }}>
												<Typography variant='highlight'>
													{`${fromAccount.currencySymbol ?? getCurrencySign(fromAccount.currency)}`}
												</Typography>
												<NumberFormat
													id="youpay"
													name="youpay"
													displayType={'text'}
													decimalScale={2}
													value={youpay}
													fixedDecimalScale={true}
													thousandsGroupStyle='thousand'
													thousandSeparator={true}
													prefix={' '}
													placeholder={'0.00'}
												/>
											</Typography>
										</Grid>
									</Grid>}
									<Grid item xs={12}>
										<ExchangeNote isAutomated={isAutomated} />
									</Grid>
								</Grid >
								<Grid container mt='auto' pt='2rem' columnSpacing={2}>
									<Grid item xs={6}>
										<Button
											variant='customOutlined'
											color='secondary'
											onClick={closeModal}
											sx={{ width: { xs: '100%', md: 'auto' } }}
										>  {t('form.buttons.close')}
										</Button>
									</Grid>
									<Grid item xs={6} container justifyContent='flex-end'>
										<Button disabled={isSubmitting || !isEmpty(errors) || fromAccount === null || toAccount === null || submitLocked}
											variant='contained'
											color='primary'
											type="submit"
											sx={{ width: { xs: '100%', md: 'auto' } }}
										>
											{t('form.buttons.exchange')}
										</Button>
									</Grid>
								</Grid>
							</FormContentBox>
						</Form>
					);
				}}
			</Formik >
		</Dialog >
	);
};


export default ForeignExchangeForm; 
