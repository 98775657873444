import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../VaultList.css';
import { convertDate } from '@/helpers/date';
import TransactionStatusBox from '@/componentsMui/Shared/Widgets/Box/TransactionStatusBox';
import NumberFormat from 'react-number-format';
import { getVaultTransactionStatus } from '@helpers/transactionUtils';
import MuiTextTrxSmall from '@shared/Widgets/MuiText/MuiTextTrxSmall';
import MuiTextTrx from '@shared/Widgets/MuiText/MuiTextTrx';
import TokenAvatar, { TokenAvatarSizeEnum } from '@shared/Widgets/Avatars/TokenAvatar';
import { CoinType } from '@features/coins/types';

interface Props {
	allocation: any
	onClick?: (allocation: any) => void,
	list: Array<CoinType>,
}

const VaultAllDepositsLineMobile = ({ allocation, onClick, list }: Props) => {
	const { t } = useTranslation('translations');

	const coin = list.find(listItem => listItem.info.symbol === allocation.currency);

	if (!allocation) {
		return <></>;
	}

	return (
		<Box onClick={() => onClick(allocation)}
			 pb='1rem'
			 // sx={{ background: theme.palette.mode==='dark'? theme.backgroundBoxLevel1 : theme.palette.background.paper }}
			 borderRadius='10px'
		>

			<Grid item xs={12} container alignItems='center' columnSpacing={1} wrap='nowrap'>
				<Grid item justifyContent='flex-start' alignItems='center'>
					<TokenAvatar symbol={allocation.currency}
								 logoSource={coin?.info?.logo}
								 variant={TokenAvatarSizeEnum.regular} />
				</Grid>
				<Grid item
					  display='flex'
					  direction='column'
					  container
					  justifyContent='space-evenly'
					  maxWidth='100%'
					  minWidth='0'
				>
					<Grid item xs={12}>
						<Typography color="text.primary" fontWeight={700} noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
							{allocation.vaultName}
						</Typography>
					</Grid>
					<Grid item xs={12} justifyContent={'flex-end'}>
						<MuiTextTrxSmall color='text.secondary' sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
							Ends on: {convertDate(allocation.repaymentDate, 'dd MMM yyyy')}
						</MuiTextTrxSmall>
					</Grid>
				</Grid>
				<Grid item
					  ml='auto'
					  display='flex'
					  direction='column'
					  justifyContent='space-evenly'
				>
					<Grid>
						<MuiTextTrx textAlign='right'>
							<NumberFormat
								displayType={'text'}
								decimalScale={5}
								fixedDecimalScale={false}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								suffix={` ${allocation.currency}`}
								value={allocation.amount}
							/>
						</MuiTextTrx>
					</Grid>
					<Grid container justifyContent='flex-end'>
						<TransactionStatusBox
							status={getVaultTransactionStatus(allocation.status)}>
							{t('status.vaults.allDeposits.' + allocation.status)}
						</TransactionStatusBox>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default VaultAllDepositsLineMobile;
