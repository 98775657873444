import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, InputAdornment, TextField } from '@mui/material';
import { HelpTopicsEnum } from './HelpTopics';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { isEmpty } from 'lodash';
import { showException } from '@/features/swal/slice';
import { postTicket, replyTicket } from '@/features/tickets/slice';
import AttachmentList from '@/componentsMui/Shared/Widgets/AttachmentList';
import MuiFormHelperText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormHelperText';

const addFiles = (formData: FormData, files: File[]) => {
  if (files?.length > 0) {
    files.map(file => {
      formData.append('files', file);
    });
  }
};

interface Props {
  topic: HelpTopicsEnum,
  ticketId: number,
  disabled?: boolean,
  onNewTicket: (tikectId: number) => void,
  inputFieldRef: any
}


const LegacyMessageInput = ({ topic, ticketId, disabled, inputFieldRef, onNewTicket }: Props) => {
  const { t } = useTranslation('translations');

  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [sending, setSending] = useState<boolean>(false);
  const [fileSizeError, setFileSizeError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const maxFileSizeKb = 5000;
  const disableSend = sending || disabled || isEmpty(message.trim());

  const onDrop = (acceptedFiles: File[]) => {

    setFileSizeError(false);

    if (acceptedFiles?.length < 1) {
      setSelectedFiles([]);
    }
    else {
      const allowedFiles = acceptedFiles.filter(p => p.size <= (maxFileSizeKb * 1000)) || [];
      setSelectedFiles(allowedFiles);
      setFileSizeError(allowedFiles.length !== acceptedFiles.length);
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: 'image/jpg, image/png, image/jpeg, .pdf, .doc, .docx',
    noClick: true,
    noKeyboard: true,
    maxFiles: 4,
  });


  const hanleOpen = () => {
    if (disabled || sending) { return; }
    open();
  };


  const createTicket = async () => {
    const newMessage = message.trim();
    try {
      if (isEmpty(newMessage)) {
        return;
      }
      setMessage('');
      const formData = new FormData();
      formData.append('description', newMessage);
      formData.append('subject', t('faq.subjects.' + topic));
      formData.append('subType', topic);

      addFiles(formData, selectedFiles);

      const result = await postTicket(formData);
      onNewTicket(result.ticketId);
    } catch (e) {
      setMessage(newMessage);
      showException(e, null, t('form.buttons.close'));
    }
  };


  const sentMessage = async () => {
    setSending(true);
    const newMessage = message.trim();

    try {
      if (ticketId) {

        if (isEmpty(newMessage)) {
          return;
        }
        setMessage('');
        const formData = new FormData();
        formData.append('ticketId', `${ticketId}`);
        formData.append('message', newMessage);

        addFiles(formData, selectedFiles);

        await replyTicket(formData);

        setSelectedFiles(null);
      }
    } catch (e) {
      setMessage(newMessage);
      showException(e, null, t('form.buttons.close'));
    }
    finally { setSending(false); }
  };


  const sendMessage = () => {
    if (!ticketId) {
      createTicket();
    }
    else {
      sentMessage();
    }
  };


  const onKeyPress = (e: any) => {
    if (e.which == 13 && e.shiftKey == false && !disableSend) {
      sendMessage();
    }
  };


  const deleteAttachment = (index: number) => {
    setSelectedFiles(prev => prev?.filter((_, i) => i !== index));
  };



  return (
    <Grid container columnSpacing={2} alignItems='center' ref={inputFieldRef}>
      <Grid item xs={10}>
        <div {...getRootProps()}    >
          <input {...getInputProps()} />
          <TextField
            sx={{ '& .MuiOutlinedInput-root': { paddingTop: '8px', paddingBottom: '8px' } }}
            name="message"
            variant="outlined"
            value={message}
            disabled={sending || disabled}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={onKeyPress}
            multiline
            minRows={1}
            fullWidth
            InputProps={{
              endAdornment:
                <InputAdornment position='end' >
                  <DescriptionOutlinedIcon
                    onClick={hanleOpen} style={{ height: '1.125rem', cursor: 'pointer' }} />
                </InputAdornment>,
            }}
          />
        </div>
      </Grid>
      <Grid item xs={2}  >
        <Button
          variant="transparent"
          color='primary'
          onClick={sendMessage}
          fullWidth
          disabled={disableSend}
        >
          {t('form.buttons.send')}
        </Button>
      </Grid>
      <Grid item xs={12} >
        {selectedFiles?.length > 0 &&
          <AttachmentList files={selectedFiles} onDelete={deleteAttachment} />
        }
      </Grid>
      <Grid item xs={12} >
        {fileSizeError && <MuiFormHelperText>{t('form.validator.fileTooBig')}</MuiFormHelperText>}
      </Grid>
    </Grid >
  );
};

export default LegacyMessageInput;
