import * as React from 'react';
import * as Yup from 'yup';

import '@/componentsMui/Dashboard/Create/Create.css';
import StepPick from '@/componentsMui/Dashboard/Create/CreateAccount/StepPick'; 
import StepCreate from './StepCreate';
import { DialogActions, Grid, IconButton, ListItemIcon, ListItemText, MenuList } from '@mui/material';
import StyledMenuItemModal from '@/componentsMui/Shared/Widgets/MenuItem/StyledMenuItemModal';

import { ReactComponent as MuiCardIcon } from '@/assets/MuiCardIcon.svg';
import { ReactComponent as MuiMobileIcon } from '@/assets/MuiMobileIcon.svg';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { AccountProviderType,  Operations } from '@/features/operations/types';
import { useEffect, useState } from 'react';
import { RootState } from '@/rootReducer';
import { getOperationsByType, runOperation } from '@/features/operations/slice';
import { showException, showSuccess } from '@/features/swal/slice';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import DialogContentNoScrollbar from '@/componentsMui/Shared/Widgets/Dialog/DialogContentNoScrollbar';
import useCloseDialog from '@/helpers/customHook/useCloseDialog';
import DialogWithMenu from '@/componentsMui/Shared/Widgets/Dialog/DialogWithMenu';
import { openForm } from '@/features/forms/slice';

export enum AccountType {
	VIRTUAL = 'VIRTUAL',
	PHYSICAL = 'PHYSICAL'
}

const CreateAccountForm = () => {

	const { t } = useTranslation('translations');
	const closeModal = useCloseDialog();
	const dispatch = useDispatch();

	const [accountType, setAccountType] = useState<AccountType>();
	const [options, setOptions] = useState<any>([]);
	const [operation, setOperation] = useState<any>(null);
	const [bgIndex, setBgIndex] = useState<number>(0);

	const [step, setStep] = React.useState(1);
	const nextStep = () => setStep(step + 1);
	const prevStep = () => setStep(step - 1);

	const { operations } = useSelector(
		(state: RootState) => state.operations
	);

	const validationSchema = Yup.object({
		name: Yup.string().required(t('form.validator.required'))
	});

	const initialValues = {
		name: ''
	};

	const getAccountType = (operation) => {
		if ([AccountProviderType.IBAN, AccountProviderType.POOLING_IBAN].indexOf(operation.providerType) !== -1) {
			return AccountType.PHYSICAL;
		}
		if ([AccountProviderType.POOLING].indexOf(operation.providerType) !== -1) {
			return AccountType.VIRTUAL;
		}
		return null;
	};

	const virtualOptions = (options.filter((option) => AccountType.VIRTUAL === getAccountType(option)));
	const physicalOptions = (options.filter((option) => AccountType.PHYSICAL === getAccountType(option))); 

	const selectType = (type: AccountType) => {
		setAccountType(type);
		setOperation(null);
	};

	const submit = async (formData) => {
		const { name } = formData;
		try {
			await runOperation(operation.proc, { name });
			showSuccess({ title: 'createaccounttab.created', mui: true });
		}
		catch (e) {
			await showException(e);
		}
	};

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);


	useEffect(() => {
		const get = async () => {
			if (operations.indexOf(Operations.CREATE_ACCOUNT) !== -1) {
				try {
					const response = await getOperationsByType(Operations.CREATE_ACCOUNT);
					setOptions(response);

					const virtualPresent = response.filter((item) => AccountType.VIRTUAL === getAccountType(item)).length > 0;
					(virtualPresent) ? setAccountType(AccountType.VIRTUAL) : setAccountType(AccountType.PHYSICAL);

				} catch (e) {
					await showException(e);
				}
			}
		};
		get();
	}, [operations]);


	return (
		<DialogWithMenu
			id="create-account-form"
			open={true}
			onClose={closeModal}
			fullWidth
			maxWidth="xl" >
			<DialogActions >
				<IconButton onClick={closeModal}  >
					<MuiCloseIcon />
				</IconButton>
			</DialogActions>
			<DialogContentNoScrollbar>
				<Grid container className='form-container__content'>
					<Grid item xs>
						<MenuList role='menu' className='form-menu__left'>
							{virtualOptions.length > 0 && <StyledMenuItemModal
								key={'virtual'}
								onClick={() => { selectType(AccountType.VIRTUAL); }}
								selected={accountType === AccountType.VIRTUAL}
								disabled={step !== 1} >
								<ListItemIcon> <MuiMobileIcon fill='white' /> </ListItemIcon>
								<ListItemText>{t('createaccounttab.virtualaccount.header')}</ListItemText>
							</StyledMenuItemModal>
							}
							{physicalOptions.length > 0 && <StyledMenuItemModal
								key={'private'}
								onClick={() => { selectType(AccountType.PHYSICAL); }}
								selected={accountType === AccountType.PHYSICAL}
								disabled={step !== 1} >
								<ListItemIcon> <MuiCardIcon fill='white' /> </ListItemIcon>
								<ListItemText>{t('createaccounttab.physicalaccount.header')}</ListItemText>
							</StyledMenuItemModal>
							}
						</MenuList>
					</Grid>
					<Grid item xs={8} id="contentColumn"  >
						{[
							step === 1 &&
							<StepPick
								key="pick"
								accountType={accountType}
								currentOperation={operation}
								operations={accountType === AccountType.VIRTUAL ? virtualOptions : physicalOptions}
								setOperation={setOperation}
								nextStep={nextStep}
								closeModal={closeModal}
								setBgIndex={setBgIndex} />,
							step === 2 &&
							<StepCreate
								key="create"
								accountType={accountType}
								currentOperation={operation}
								initialValues={initialValues}
								validationSchema={validationSchema}
								bgIndex={bgIndex}
								submit={submit}
								prevStep={prevStep}
								//nextStep={nextStep}
								closeModal={closeModal}
							/>/* ,
						step === 3 &&
						<StepTermsOfService
							key="terms"
							currentOperation={operation}
							submit={submit}
							prevStep={prevStep}
						/> */]
						}
					</Grid>
				</Grid>
			</DialogContentNoScrollbar>
		</DialogWithMenu >
	);
};

export default CreateAccountForm;
