import * as React from 'react';

import '@shared/Shared.css';
import '@shared/SharedFormLayout.css';
import { useState } from 'react';

import { BankAccount } from '@features/account/types';
import { Contact } from '@features/contacts/types';
import { SystemType } from '@helpers/globalTypes';
import { PaymentPayloadType } from '@/componentsMui/Type/PaymentPayloadType';
import AmountStep from '@shared/Components/SendMoney/NewPayment/PaymentSteps/AmountStep';
import DetailsStep from '@shared/Components/SendMoney/NewPayment/PaymentSteps/DetailsStep';
import AttachmentAmount from '@shared/Components/SendMoney/NewPayment/PaymentSteps/AttachmentAmount';

type Props = {
      paymentType: SystemType,
      accountList: BankAccount[],
      defaultAccount?: BankAccount,
      defaultContact?: Contact,
      paymentPayload: PaymentPayloadType,
      paymentName: string,
      setPaymentPayload: (value: PaymentPayloadType) => void,
      nextStep?: () => void,
      prevStepMain?: () => void
}

const PaymentDetails = ({ paymentName,
      accountList,
      paymentType,
      defaultAccount,
      defaultContact,
      paymentPayload,
      setPaymentPayload,
      nextStep,
      prevStepMain }: Props) => {

      const [stepLocal, setStepLocal] = useState<number>(1);

      const nextStepLocal = () => {
            setStepLocal(previousState => (previousState + 1));
      };
      const prevStepLocal = () => {
            setStepLocal(previousState => (previousState - 1));
      };

      return (<>
            {stepLocal === 1 &&
                  <AmountStep
                        paymentName={paymentName}
                        paymentType={paymentType}
                        accountList={accountList}
                        defaultAccount={defaultAccount}
                        defaultContact={defaultContact}
                        paymentPayload={paymentPayload}
                        setPaymentPayload={setPaymentPayload}
                        nextStep={nextStepLocal}
                        prevStep={prevStepLocal}
                        prevStepMain={prevStepMain} />
            }
            {stepLocal === 2 && <DetailsStep
                  paymentName={paymentName}
                  paymentType={paymentType}
                  accountList={accountList}
                  defaultAccount={defaultAccount}
                  defaultContact={defaultContact}
                  paymentPayload={paymentPayload}
                  setPaymentPayload={setPaymentPayload}
                  nextStep={nextStepLocal}
                  prevStep={prevStepLocal}
                  nextStepMain={nextStep}
                  prevStepMain={prevStepMain} />
            }
            {stepLocal === 3 && <AttachmentAmount
                  paymentName={paymentName}
                  paymentType={paymentType}
                  accountList={accountList}
                  defaultAccount={defaultAccount}
                  defaultContact={defaultContact}
                  paymentPayload={paymentPayload}
                  setPaymentPayload={setPaymentPayload}
                  prevStep={prevStepLocal}
                  nextStep={nextStepLocal}
                  nextStepMain={nextStep}
                  prevStepMain={prevStepMain} />
            }
      </>);
};

export default PaymentDetails;
