
import * as React from 'react';
import '@/componentsMui/Shared/Shared.css';
import { Button, FormControl, Grid, InputLabel, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { editContact } from '@/features/contacts/slice';
import { useDispatch } from 'react-redux';
import { showException, showSuccess } from '@/features/swal/slice';
import { Contact, ContactPaymentTypes } from '@/features/contacts/types';
import { isEmpty } from 'lodash';
import StyledSelect from '@/componentsMui/Shared/Widgets/StyledSelect';
import { ACHAccountType, BeneficiaryType } from '@/helpers/globalTypes';
import IOSSwitch from '@/componentsMui/Shared/Widgets/IOSSwitch';
import { useState } from 'react';
import MuiSubHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiSubHeader';
import MuiTextTrx from '@/componentsMui/Shared/Widgets/MuiText/MuiTextTrx';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import CountryCodes from '@/componentsMui/Shared/FormikComponents/CountryCodes';
import UsaStateList from '@/componentsMui/Shared/FormikComponents/UsaStateList';
import Dropdown from '@/componentsMui/Shared/FormikComponents/Dropdown';
import { alpha2ToAlpha3, alpha3ToAlpha2, getNames } from 'i18n-iso-countries';


interface Props {
	contactToEdit: Contact
}

const getBeneficiaryType = (isBusiness: any) => isBusiness ? BeneficiaryType.BUSINESS : BeneficiaryType.INDIVIDUAL;

const EditPaymentContact = ({ contactToEdit }: Props) => {

	const { t } = useTranslation('translations');
	const closeModal = useCloseDialogNoEvent();
	const contriesList = getNames('en');

	const dispatch = useDispatch();
	const [beneficiaryType, setBeneficiaryType] = useState<BeneficiaryType>(contactToEdit ? getBeneficiaryType(contactToEdit.business) : BeneficiaryType.INDIVIDUAL);
	const [showRegistrationNo, setShowRegistrationNo] = useState<boolean>(contactToEdit ? (contactToEdit.business || !isEmpty(contactToEdit.identification)) : false);
	const paymentType = contactToEdit?.paymentType;

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(true);

		try {
			const { name, account, accountNumber, sortCode, accountType, identification, bic, bank, country, city, street, region, postalCode, routingNumber } = formData;

			const payload = {
				beneficiaryAccountId: contactToEdit.beneficiaryAccountId,
				name,
				identification,
				business: beneficiaryType === BeneficiaryType.BUSINESS,
				paymentType: paymentType,
				...(paymentType === ContactPaymentTypes.SWIFT && {
					account,
					bic,
					country: alpha2ToAlpha3(country.code),
				}),
				...(paymentType === ContactPaymentTypes.UK_CHAPS && {
					accountNumber,
					sortCode,
				}),
				...(paymentType === ContactPaymentTypes.US_WIRE && {
					country: country.code,
					city,
					street,
					routingNumber,
					postalCode,
					region: region?.code,
				}),
				...(paymentType === ContactPaymentTypes.INTERNATIONAL_WIRE && {
					country: alpha2ToAlpha3(country.code),
					city,
					street,
					bank,
					bic,
				}),
				...((paymentType === ContactPaymentTypes.ACH || paymentType === ContactPaymentTypes.SAME_DAY_ACH) && {
					accountType,
					routingNumber,
				}),
			};

			await dispatch(editContact(contactToEdit.beneficiaryAccountId, { ...contactToEdit, ...payload }));
			showSuccess({ title: 'contacts.update.success', mui: true });

		} catch (e) {
			showException(e);
		}
		finally {
			setSubmitting(false);
			closeModal();
		}
	};

	const isValidId = (value: string) => {
		return !(beneficiaryType === BeneficiaryType.BUSINESS && (isEmpty(value) || value === undefined));
	};

	const validationSchema = Yup.object({
		name: Yup.string().required(t('form.validator.required')),
		identification: Yup.string().test('identification',
			t('form.validator.required'),
			(value) => isValidId(value)),
		...(paymentType === ContactPaymentTypes.SWIFT && {
			account: Yup.string().required(t('form.validator.required')),
			bic: Yup.string().required(t('form.validator.required')),
		}),
		...(paymentType === ContactPaymentTypes.UK_CHAPS && {
			accountNumber: Yup.string().required(t('form.validator.required')),
			sortCode: Yup.string().required(t('form.validator.required')),
		}),
		...((paymentType === ContactPaymentTypes.US_WIRE || paymentType === ContactPaymentTypes.INTERNATIONAL_WIRE) && {
			country: Yup.object().nullable().test('country', t('form.validator.required'), (value) => !!value),
			city: Yup.string().required(t('form.validator.required'))
				.matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin')),
			street: Yup.string().required(t('form.validator.required'))
				.matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin')),
		}),
		...(paymentType === ContactPaymentTypes.US_WIRE && {
			routingNumber: Yup.string().required(t('form.validator.required')),
			postalCode: Yup.string().required(t('form.validator.required'))
				.matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin')),
			region: Yup.object().nullable().when('country', {
				is: 'US',
				then: Yup.object().nullable().test('country', t('form.validator.required'), (value) => !!value),
				otherwise: Yup.object().nullable().notRequired(),
			}),
		}),
		...(paymentType === ContactPaymentTypes.INTERNATIONAL_WIRE && {
			bank: Yup.string().required(t('form.validator.required')),
			bic: Yup.string().required(t('form.validator.required'))
		}),
		...((paymentType === ContactPaymentTypes.ACH || paymentType === ContactPaymentTypes.SAME_DAY_ACH) && {
			accountType: Yup.string().required(t('form.validator.required')),
			routingNumber: Yup.string().required(t('form.validator.required')),
		}),
	});



	const alpa2Code = alpha3ToAlpha2(contactToEdit.country);
	const initialValues = {
		name: contactToEdit?.name ?? '',
		account: contactToEdit?.account ?? '',
		accountNumber: contactToEdit?.accountNumber ?? '',
		sortCode: contactToEdit?.sortCode ?? '',
		bic: contactToEdit?.bic ?? '',
		bank: contactToEdit?.bank ?? '',
		country: contactToEdit?.country ? { label: contriesList[alpa2Code], code: alpa2Code } : {},
		city: contactToEdit?.city ?? '',
		street: contactToEdit?.street ?? '',
		region: contactToEdit?.region ?? '',
		postalCode: contactToEdit?.postalCode ?? '',
		identification: contactToEdit?.identification ?? '',
		routingNumber: contactToEdit?.routingNumber ?? '',
	};


	const handlesBeneficiaryType = (event: any, setFieldTouched) => {
		setBeneficiaryType(event.target.value);
		setTimeout(() => setFieldTouched('identification', true));

		if (event.target.value === BeneficiaryType.BUSINESS) { setShowRegistrationNo(true); }
	};

	const handleSetRegistrationNo = (setFieldValue) => {
		if (showRegistrationNo) {
			setFieldValue('identification', '');
		}
		setShowRegistrationNo(!showRegistrationNo);

	};

	const accountTypeOptions = [
		{
			value: t('sendmoneyTranslation.data.accountType.' + ACHAccountType.CHECKING),
			key: ACHAccountType.CHECKING
		},
		{
			value: t('sendmoneyTranslation.data.accountType.' + ACHAccountType.SAVINGS),
			key: ACHAccountType.SAVINGS,
		}
	];

	return (


		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={submit}

		>
			{({ errors, isSubmitting, values, dirty, setFieldValue, setFieldTouched }) => {
				return (
					<Form className='portal-dialog-form-template'>

						<Grid container sx={{ height: '100%' }} >
							<Grid id="contentWrap"
								item
								spacing={2}
								container
								alignSelf="flex-start" >
								<Grid item xs={12}  >
									<MuiSubHeader>{t('fees.myFees.paymentTypes.' + paymentType)}</MuiSubHeader>
									<MuiTextTrx marginTop="0.5rem" >{t('contacts.create.externalPaymentsText')}</MuiTextTrx>
								</Grid>
								<Grid item xs={12} container>
									<Grid item xs={12} sm={6}>
										<FormControl fullWidth >
											<InputLabel>{t('contacts.create.beneficiaryType')}</InputLabel>
											<StyledSelect
												id="beneficiaryType"
												name="beneficiaryType"
												value={beneficiaryType}
												onChange={(e) => handlesBeneficiaryType(e, setFieldTouched)} >
												<MenuItem
													value={BeneficiaryType.INDIVIDUAL}>
													<div className='select-primary-Item'>{t('sendmoneyTranslation.data.individual')} </div>

												</MenuItem>
												<MenuItem
													value={BeneficiaryType.BUSINESS}>
													<div className='select-primary-Item'>{t('sendmoneyTranslation.data.business')} </div>
												</MenuItem>
											</StyledSelect>
										</FormControl>
									</Grid>
									<Grid item container xs={12} sm={6} justifyContent="space-between" alignItems='center' pt='1.5rem' pl='0.5rem'>
										<MuiTextCaption fontWeight='400' fontSize='0.875rem'> {t('contacts.create.beneficiaryId')}</MuiTextCaption>
										<IOSSwitch
											checked={showRegistrationNo}
											disabled={beneficiaryType === BeneficiaryType.BUSINESS}
											onChange={() => handleSetRegistrationNo(setFieldValue)} />
									</Grid>
									<Grid item xs={12}>
										{showRegistrationNo &&
											<MuiTextField
												id="identification"
												name="identification"
												fullWidth
												label={beneficiaryType === BeneficiaryType.BUSINESS ? t('contacts.create.businessRegistrationNo') : t('contacts.create.contact')}

											/>}
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<MuiTextField
										id="name"
										name="name"
										label={t('contacts.create.sepaName')}
										fullWidth
									/>
								</Grid>
								{paymentType === ContactPaymentTypes.SWIFT &&
									<>
										<Grid item xs={12}>
											<MuiTextField
												id="account"
												name="account"
												label={t('sendmoneyTranslation.data.accountNumber')}
												fullWidth
											/>
										</Grid>
										<Grid item xs={12}>
											<MuiTextField
												id="bic"
												name="bic"
												label={t('contacts.create.bicSwift')}
												fullWidth
											/>
										</Grid>
										<Grid item xs={12}>
											<CountryCodes fieldName='country' fieldlabel={t('form.fields.beneficiaryCountry')} />
										</Grid>
									</>}

								{paymentType === ContactPaymentTypes.UK_CHAPS &&
									<>
										<Grid item xs={12}>
											<MuiTextField
												id="accountNumber"
												name="accountNumber"
												label={t('sendmoneyTranslation.data.accountNumber')}
												fullWidth
											/>
										</Grid>
										<Grid item xs={12}>
											<MuiTextField
												id="sortCode"
												name="sortCode"
												label={t('sendmoneyTranslation.data.sortCode')}
												fullWidth
											/>
										</Grid>
									</>}
								{(paymentType === ContactPaymentTypes.US_WIRE || paymentType === ContactPaymentTypes.INTERNATIONAL_WIRE) &&
									<>
										<Grid item xs={12} sm={6}>
											<CountryCodes fieldName='country' fieldlabel={t('form.fields.beneficiaryCountry')} />
										</Grid>
										{paymentType === ContactPaymentTypes.US_WIRE &&
											<>
												{values.country.code === 'US' &&
													<Grid item xs={12} sm={6}>
														<UsaStateList name='region' fieldLabel={t('form.fields.beneficiaryRegion')} />
													</Grid>
												}

												<Grid item xs={12} sm={6}>
													<MuiTextField
														name="postalCode"
														fullWidth
														label={t('form.fields.beneficiaryPostalCode')}
														inputProps={{ maxLength: 16 }} />
												</Grid>
												<Grid item xs={12} sm={6}>
													<MuiTextField
														name="routingNumber"
														fullWidth
														label={t('form.fields.beneficiaryRoutingNumber')} />
												</Grid>
											</>
										}
										<Grid item xs={12} sm={6}>
											<MuiTextField
												name="city"
												fullWidth
												label={t('form.fields.beneficiaryCity')} />
										</Grid>
										<Grid item xs={12} sm={6}>
											<MuiTextField
												name="street"
												fullWidth
												label={t('form.fields.beneficiaryStreet')} />
										</Grid>
									</>
								}
								{paymentType === ContactPaymentTypes.INTERNATIONAL_WIRE &&
									<>
										<Grid item xs={12} sm={6}>
											<MuiTextField
												name="bank"
												fullWidth
												label={t('form.fields.beneficiaryBankName')} />
										</Grid>
										<Grid item xs={12} sm={6}>
											<MuiTextField
												name="bic"
												fullWidth
												label={t('form.fields.beneficiaryBicSwift')} />
										</Grid>
									</>
								}
								{(paymentType === ContactPaymentTypes.ACH || paymentType === ContactPaymentTypes.SAME_DAY_ACH) &&
									<>
										<Grid item xs={12} sm={6}>
											<MuiTextField
												name="routingNumber"
												fullWidth
												label={t('form.fields.beneficiaryRoutingNumber')} />
										</Grid>
										<Grid item xs={12} sm={6}>
											<Dropdown
												options={accountTypeOptions}
												fieldLabel={t('form.fields.beneficiaryAccountType')}
												fieldName='accountType' />
										</Grid>
									</>
								}
							</Grid>
							<Grid item container mt='2rem' alignSelf="flex-end" justifyContent="center">
								<Grid item>
									<Button disabled={isSubmitting || !isEmpty(errors) || !dirty}
										key="buttonNext"
										fullWidth
										variant='contained'
										color='primary'
										type="submit" >
										{t('contacts.create.save')}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Form>
				);
			}}
		</Formik >);
};

export default EditPaymentContact;
