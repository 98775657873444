import { Alert, Button, FormLabel, Grid, InputLabel, Link, Theme, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v3Login } from '@/features/user/userSlice';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { isValidPhoneNumber } from 'react-phone-number-input';
import MuiTextAlert from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAlert';
import { DeviceInfo, LoginTypeEnum, TokenGrantTypeEnum } from '@/features/user/types';
import { getRecaptureToken } from '@/helpers/recaptureUtils';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { getErrorWithParams, skipError } from '@/features/swal/slice';
import { HOME, REGISTER, REST_PASSWORD } from '@/pages/navigationConstants';
import SmsCountryDropdown from '@/componentsMui/Onboarding/components/SmsCountryDropdown';
import { alpha2ToAlpha3, getNames } from 'i18n-iso-countries';
import { CountryCode, getCountryCallingCode, isSupportedCountry } from 'libphonenumber-js';
import { sortBy } from 'lodash';
import { Agents } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import ContactSupportForm from './ContactSupportForm';

interface Props {
	deviceInfo: DeviceInfo,
	type: LoginTypeEnum,
	isOauthLogin?: boolean, //23ds
	onCancel?: () => void,
	onError?: () => void,
}


const EmailPhoneLogin = ({ deviceInfo, type, isOauthLogin = false, onCancel, onError }: Props) => {
	const { t } = useTranslation(['translations']);
	const theme = useTheme() as Theme;
	const history = useHistory();
	const dispatch = useDispatch();
	const [loginError, setError] = useState<string>('');
	const countries = getNames('en');
	const [openContactSupportForm, setOpenContactSupportForm] = useState<boolean>(false);

	const { agent, registrationEnabled } = useSelector(
		(state: RootState) => state.status
	);

	const smsCountryOptions = Object.keys(countries)?.map(p => ({
		countryCode: alpha2ToAlpha3(p),
		countryCodeAlpha2: p,
		countryName: countries[p],
		phoneCode: `+${isSupportedCountry(p) ? getCountryCallingCode(p as CountryCode) : ''}`,
	}));

	const filteredSmsCountryOptions = sortBy(smsCountryOptions.filter(p => p.phoneCode !== ''), 'countryName');

	const userNameLabel = type === 'EMAIL' ? t('form.fields.email') : t('form.fields.phone');
	const fieldName = 'value_' + type;

	const initialValues = {
		[fieldName]: '',
		password: '',
		phoneCode: ''
	};

	const handleForgotPassword = () => history.push(REST_PASSWORD);
	const handleRegister = () => {
		if (agent === Agents.COSPAY) {
			window.location.replace('https://www.dtsmoney.com');
		} else {
			history.push(REGISTER);
		}
	};

	const handleSupport = () => {
		setOpenContactSupportForm(true);
	};

	const validateEmail = (email: string | undefined) => {
		return Yup.string().email().isValidSync(email);
	};

	const validatePhone = (phone: number | undefined) => {
		return Yup.number().integer().positive().test(
			(phone) => {
				return isValidPhoneNumber(`+${phone}`);
			}
		).isValidSync(phone);
	};

	const validationSchema = Yup.object({
		password: Yup.string().required(t('form.validator.required')),
		[fieldName]: Yup.string()
			.required(t('form.validator.required'))
			.test('userName', t('form.validator.invalidValue'), (value, ctx) => {
				return type === LoginTypeEnum.EMAIL ? validateEmail(value) : validatePhone(parseInt(ctx.parent.phoneCode + '' + value ?? '0'));
			}),
	});



	const submit = async (formData) => {

		const recaptchaToken = await getRecaptureToken();
		setError('');
		try {
			const { password, phoneCode } = formData;
			const userName = formData?.[fieldName];
			await dispatch(v3Login({
				recaptchaToken,
				identifier: phoneCode + '' + userName,
				phoneNumber: type === LoginTypeEnum.PHONE ? phoneCode + '' + userName : null,
				email: type === LoginTypeEnum.EMAIL ? userName : null,
				password,
				deviceInfo,
				grantType: isOauthLogin ? TokenGrantTypeEnum.OAUTH : undefined,
			}, isOauthLogin ? TokenGrantTypeEnum.OAUTH : TokenGrantTypeEnum.PORTAL));
			!isOauthLogin && history.push(HOME);
		}
		catch (e: any) {
			onError && onError();
			const skip = skipError(e);
			const { error, error_param } = getErrorWithParams(e);
			if (skip) {
				setError('');
			} else {
				if (error === 'user' && error_param === 'disabled') {
					setError(t('errors.ACCOUNT_DEACTIVATED'));
				} else {
					setError(type === 'EMAIL'
						? t('login.v3.signUpFields.loginErrorEmail') :
						t('login.v3.signUpFields.loginError'));
				}
			}
		}
	};

	return (
		<Grid >
			{openContactSupportForm && <ContactSupportForm
				open={openContactSupportForm}
				onClose={() => setOpenContactSupportForm(false)}
			/>}
			{loginError != '' && <Grid item xs={12} mb={2} test-id='login-error-alert' >
				<Alert severity="warning" sx={{ padding: '10px 20px' }} >
					<MuiTextAlert>
						{loginError}
					</MuiTextAlert>
				</Alert>
			</Grid>}
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
				enableReinitialize
			>
				{({ errors, isSubmitting, setFieldValue }) => {
					return (
						<Form style={{ width: '100%' }} autoComplete='off'>
							<Grid container >
								<Grid item xs={12}>
									<FormLabel>	 {userNameLabel}
									</FormLabel>
								</Grid>
								{type === LoginTypeEnum.PHONE &&
									<Grid item xs={4.4}>
										<SmsCountryDropdown name='phoneCode' smsCountries={filteredSmsCountryOptions} />
									</Grid>
								}
								<Grid item xs={type === LoginTypeEnum.PHONE ? 7.5 : 12}>
									<MuiTextField
										className='login-input'
										name={fieldName}
										InputLabelProps={{ style: { color: theme.palette.text.primary, textAlign: 'left' } }}
										fullWidth
										InputProps={{
											autoComplete: 'username',
										}}
										sx={{
											'&.login-input .MuiOutlinedInput-root.MuiInputBase-root': {
												border: errors?.[fieldName] ? '1px solid ' + theme.statusColor.error + '4D' : 'unset',
											}
										}}
										test-id='username'
										hideError={true}
										onChange={(e) => setFieldValue(fieldName, e.currentTarget.value.replace(/\s/g, ''), true)}
									/>
								</Grid>
								<Grid item xs={12} container mt={{ xs: '10px', md: '20px' }}>
									<Grid item xs={12} container alignItems='center' justifyContent='space-between'>
										<InputLabel >  {t('login.v3.signUpFields.yourPassword')}</InputLabel>
										<Link sx={{ fontWeight: '400!important', fontSize: '0.813rem!important' }}
											onClick={handleForgotPassword} test-id='forgot-password-link'>{t('login.resetPassword')}</Link>
									</Grid>
									<Grid item xs={12}>
										<MuiTextField
											className='login-input password'
											name="password"
											InputLabelProps={{ style: { color: theme.palette.text.primary, textAlign: 'left' } }}
											placeholder={t('form.placeholder.password')}
											type="password"
											test-id='password'
											InputProps={{
												autoComplete: 'current-password'
											}}
											fullWidth
											sx={{
												'&.login-input .MuiOxutlinedInput-root.MuiInputBase-root': {
													border: errors?.password ? '1px solid ' + theme.statusColor.error + '4D' : 'unset',
												}
											}}
											hideError={true}
										/>
									</Grid>
								</Grid>
								{isOauthLogin ?
									<Grid item xs={12} mt='40px' container gap={2}>
										<Grid item xs>
											<Button
												type="submit"
												fullWidth
												variant="contained"
												disabled={!isEmpty(errors) || isSubmitting}
											>
												{t('login.v3.signUpFields.login')}
											</Button>
										</Grid>
										<Grid item xs>
											<Button
												onClick={() => { onCancel && onCancel(); }}
												fullWidth
												variant="customOutlined"
											>
												{t('form.buttons.cancel')}
											</Button>
										</Grid>
									</Grid>
									:
									<Grid item xs={12} mt='40px'>
										<Button
											type="submit"
											fullWidth
											variant="contained"
											test-id='login'
											disabled={!isEmpty(errors) || isSubmitting}
										>
											{t('login.v3.signUpFields.login')}
										</Button>
									</Grid>
								}

								<Grid item xs={12} mt='20px' container justifyContent='center' test-id='dont-have-account'>
									{!isOauthLogin ?
										<>
											{registrationEnabled ?
												<>
													<Typography variant='body2' fontSize='0.813rem' >{t('login.v3.dontHaveAccount')}</Typography>
													<Link ml={1}
														sx={{ fontWeight: '400!important', fontSize: '0.813rem!important' }}
														onClick={handleRegister}
														test-id='signup-link'
													>{t('login.v3.signup')}
													</Link>
												</> :
												<Typography variant='body2' fontSize='0.813rem'>{t('login.v3.registrationDisabled')}</Typography>
											}
										</> :
										<Typography variant='body2' fontSize='0.813rem' >{t('login.v3.loginToApprove')}</Typography>
									}
								</Grid>
								<Grid item xs={12} container justifyContent='center'>
									<MuiFormText >{t('login.cantLogin')}</MuiFormText>
									<Link ml={1} sx={{ fontWeight: '400!important', fontSize: '0.813rem!important' }} onClick={handleSupport}>{t('login.contactSupport')}</Link>
								</Grid>
							</Grid>
						</Form>
					);
				}}
			</Formik>
		</Grid >
	);
};

export default EmailPhoneLogin;

