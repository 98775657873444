import * as React from 'react';
import { SvgIcon } from '@mui/material';

const MenuTrustIcon = (props) => (
    <SvgIcon {...props} >
        <svg width="22" height="22" viewBox="0 0 22 22" fill="current" stroke="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.5 0C2.462 0 0 2.318 0 5.176v9.06c0 2.411 1.753 4.438 4.125 5.013v1.78c0 .537.462.971 1.031.971.57 0 1.032-.434 1.032-.97v-1.618h9.625v1.617c0 .537.461.971 1.03.971.57 0 1.032-.434 1.032-.97v-1.781C20.247 18.674 22 16.647 22 14.235V5.176C22 2.318 19.538 0 16.5 0h-11zm11 1.941h-11c-1.898 0-3.438 1.449-3.438 3.235v9.06c0 1.786 1.54 3.235 3.438 3.235h11c1.898 0 3.438-1.449 3.438-3.236v-3.882h-2.132c-.318 1.477-1.706 2.588-3.369 2.588-1.898 0-3.437-1.448-3.437-3.235s1.539-3.235 3.438-3.235c1.662 0 3.05 1.111 3.368 2.588h2.131V5.176c0-1.786-1.539-3.235-3.437-3.235zm0 7.765c0-1.072-.923-1.941-2.063-1.941-1.139 0-2.062.869-2.062 1.94 0 1.073.923 1.942 2.063 1.942 1.139 0 2.062-.869 2.062-1.941z"
                fill="current"
            />
        </svg>
    </SvgIcon>
);

export default MenuTrustIcon;
