import React from 'react';
import TransactionStatusBox from '@/componentsMui/Shared/Widgets/Box/TransactionStatusBox';
import { TransactionStatus, TransactionType } from '@/features/transactions/types';
import { showErrorNotification } from '@/features/swal/slice';
import { useTranslation } from 'react-i18next';
import { cancelFxDocument } from '@/features/fxDocuments/slice';

interface Props {
    transactionStatus: string,
    documentId?: number,
    activity: string,
    accountSubProcesses: any
}
const TrxStatus = ({ transactionStatus, documentId, activity, accountSubProcesses }: Props) => {

    const { t } = useTranslation('translations');
    const [sumbitting, setSubmitting] = React.useState<boolean>(false);
    const isFxCancel = activity === TransactionType.FX_TRANSACTION && transactionStatus === TransactionStatus.PENDING;

    const cancelExchangeOrder = async (documentId: number) => {
        try {
            if (accountSubProcesses) {
                setSubmitting(true);
                await cancelFxDocument(documentId);
            }
        } catch (e) {
            showErrorNotification(e);
        }
        finally { setSubmitting(false); }
    };

    return (<>

        <TransactionStatusBox
            sumbitting={sumbitting}
            isFxCancel={isFxCancel}
            status={transactionStatus as TransactionStatus}
            fontSize='0.875rem'
            fontWeight={500}
            sx={{
                background: 'transparent',
                cursor: isFxCancel ? 'pointer' : 'auto'
            }}
            {...(isFxCancel && { onClick: () => !sumbitting && cancelExchangeOrder(documentId) })}
        >
            {t('status.' + (isFxCancel ? 'CANCEL' : transactionStatus))}
        </TransactionStatusBox>
    </>);
};

export default TrxStatus;
