import { GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react'; 
import SignIcon from './SignIcon';
import { Grid, Typography } from '@mui/material';
import MuiTextTrxSmall from '@/componentsMui/Shared/Widgets/MuiText/MuiTextTrxSmall';
import { timeSince } from '@/helpers/date';
import { getAccountTrxName } from './TrxActivityCell';

const TrxActivityCellMobile = (
    params: GridRenderCellParams
) => {
 
    const transaction = params.row;
    const name = getAccountTrxName(transaction);

    return (<Grid
        container
        flexWrap='nowrap'
        alignItems='center'
        maxWidth='100%'
        minWidth='0'
    >
        <Grid item xs='auto' mr='5px'>
            <SignIcon amount={transaction.amount} activity={transaction.activity} />
        </Grid>
        <Grid item xs={12}
            sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
            <Typography
                fontSize='0.875rem'
                fontWeight={500}
                noWrap
            >
                {name}
            </Typography>
            <MuiTextTrxSmall color='text.secondary' noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {timeSince(transaction.date)}
            </MuiTextTrxSmall>
        </Grid>
    </Grid>);
};

export default TrxActivityCellMobile;
