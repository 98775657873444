import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { limitNumberFormat } from '@/helpers/number';
import { Divider, Grid } from '@mui/material';
import _ from 'lodash';
import React from 'react';

interface Props {
    limitTitle: string;
    limitValue: number;
    currency: string;
}

const LimitLineWithActual = ({ limitTitle, limitValue, currency }: Props) => {

    return (
        <>
            < Divider />
            <Grid container>
                <Grid item xs={12} minHeight='4rem' container alignItems='center'>
                    <Grid item xs={5} sm={4} >
                        <MuiFormText>{limitTitle}</MuiFormText>
                    </Grid>
                    <Grid item xs={5} sm={4}>
                        <MuiFormText>
                            {[limitNumberFormat(limitValue), _.isNil(limitValue) ? undefined : currency].join(' ')}
                        </MuiFormText>
                    </Grid>
                </Grid>
            </Grid >
        </>
    );
};

export default LimitLineWithActual;
