import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@features/card/types';
import { Box, Card as CardMui, CardMedia, Grid } from '@mui/material';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import { format, isValid, parse } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { UserType } from '@/features/user/types';
import { capitalCaseString } from '@/helpers/normalizeString';

interface Props {
    card?: Card,
    virtualCard?: boolean,
    cardLogoTextColor?: string,
    cardLogo?: string,
    padding?: string,
    borderRadius?: string,
    hideText?: boolean,
    border?: boolean,
}


const CardImage = ({ card, virtualCard, cardLogo, cardLogoTextColor, padding, borderRadius, hideText, border }: Props): React.ReactElement => {
    const { t } = useTranslation('translations');
    const [virtual, setVirtual] = useState<boolean>(false);
    const [logo, setLogo] = useState<string>('');
    const [logoTextColor, setLogoTextColor] = useState<string>('');
    const { user } = useSelector((state: RootState) => state.user);

    useEffect(() => {
        if (card) {
            setVirtual(card.virtual);
        } else {
            setVirtual(virtualCard);
        }
    }, [card, virtualCard]);

    useEffect(() => {
        if (card) {
            setLogo(card.logo);
        } else {
            setLogo(cardLogo);
        }
    }, [card, cardLogo]);

    useEffect(() => {
        if (card) {
            setLogoTextColor(card.logoTextColor);
        } else {
            setLogoTextColor(cardLogoTextColor);
        }
    }, [card, cardLogoTextColor]);

    const getExpirationDate = () => {
        if (!card?.expirationDate) {
            return '••/••';
        }
        const date = parse(card.expirationDate, 'yyyy-MM-dd', new Date());
        if (isValid(date)) {
            return format(date, 'MM/yy');
        } else {
            return '••/••';
        }
    };


    return (

        <>
            <CardMui sx={{ boxShadow: 'unset' }}>
                <Box sx={{ position: 'relative', borderRadius: borderRadius ?? '15px', border: border ? '1px solid rgba(255, 255, 255, 0.2)' : '' }}>
                    <CardMedia image={logo} component="img" sx={{ borderRadius: borderRadius ?? '15px', borderWidth: '2px', borderColor: 'red' }} />
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            background: logoTextColor,
                            backgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            padding: padding ?? '12px 17px'
                            // padding: '7px'
                        }}
                    >
                        {!hideText && <Grid container pl={1}>
                            <Grid item xs={12} pb={1}>
                                <MuiTextCaption fontSize='0.688rem' fontWeight={700} lineHeight='1.375'> {card?.cardholder
                                    ? card.cardholder
                                    : user.type === UserType.BUSINESS ? user.companyName ?? '' : user.firstName && user.lastName ? capitalCaseString(`${user.firstName} ${user.lastName}`)
                                        : ''}</MuiTextCaption>
                                <MuiTextCaption fontSize='0.688rem' fontWeight={400} lineHeight='1.375'> {virtual ? t('cards.newCard.virt') : t('cards.newCard.phys')}</MuiTextCaption>
                            </Grid>
                        </Grid>}
                    </Box>

                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            width: '50%',
                            background: logoTextColor,
                            backgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            padding: padding ?? '12px 17px'
                            // padding: '7px'
                        }}
                    >
                        {!hideText && <Grid container pl={1}>
                            <Grid item xs={12}>
                                <div className='cardNumber' style={{ letterSpacing: '0.1em', fontSize: '0.688rem', fontWeight: '400' }}>{card?.number ? card.number.slice(-7).replaceAll('*', '•') : '•• ••••'}</div>
                            </Grid>
                        </Grid>}
                    </Box>
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            width: '50%',
                            background: logoTextColor,
                            backgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            padding: padding ?? '12px 17px',
                            textAlign: 'right',
                            // padding: '7px'
                        }}
                    >
                        {!hideText && <Grid container pr={1}>
                            <Grid item xs={12}>
                                <div className='expire' style={{ letterSpacing: '0.1em', fontSize: '0.688rem', fontWeight: '400' }}>{getExpirationDate()}</div>
                            </Grid>
                        </Grid>}
                    </Box>
                </Box>
            </CardMui>
        </>

    );
};

export default CardImage;

