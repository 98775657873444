import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';
import Swal from '@/componentsMui/Swal';
import { Box } from '@mui/material';
import React from 'react';  

const LoadingPageMui = (): React.ReactElement => 
	{
		return <Box id='loading-page-mui'
			display='flex'
			height='50vh'
			width='100%'
			alignItems='center'>
			<Swal />
			<Spinner />
		</Box>;
	};

export default LoadingPageMui;
