import React from 'react';
import { Avatar, Button, Chip, Grid, Theme, Typography, useTheme } from '@mui/material';
import BaseBox from '@/componentsMui/Shared/Widgets/Box/BaseBox';
import MuiCopyToClipboard from '@/componentsMui/Shared/Widgets/MuiCopyToClipboard';
import { ReactComponent as MuiArrowCircle } from '@/assets/MuiArrowCircle.svg';
import { useTranslation } from 'react-i18next';
import useUserSwitch from '@/helpers/customHook/useUserSwitch';
import { BusinessType, KybStatus } from '@/features/business/types';
import { useHistory } from 'react-router-dom';
import MuiTextAccountBoxHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAccountBoxHeader';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';

interface Props {
	business: BusinessType
}

const BusinessLine = ({ business }: Props): React.ReactElement => {
	const theme = useTheme() as Theme;
	const { t } = useTranslation('translations');
	const { handleSwitchUser } = useUserSwitch();
	const history = useHistory();
	const { user } = useSelector((state: RootState) => state.user);


	const allowToCreate = (business.kybStatus === KybStatus.CREATED ||
		business.kybStatus === KybStatus.REJECTED || business.kybStatus === KybStatus.WAITING_FOR_PAYMENT || business.disabled);
	const warn = (business.kybStatus === KybStatus.REJECTED && business.kybFinal) || business.disabled;

	const handleCreateBusiness = () => {
		if (business.kybStatus === KybStatus.REJECTED && business.kybFinal) {
			history.push(`/wallet/business/rejected-final?client=${business.clientId}`);
		} else if (business.kybStatus === KybStatus.WAITING_FOR_PAYMENT) {
			history.push(`/wallet/business/payment?client=${business.clientId}`);
		} else {
			history.push(`/wallet/business/create?client=${business.clientId}`);
		}
	};

	return (<BaseBox
		sx={{ my: '0.5rem', p: '1rem' }}
		borderRadius='10px'
		width='100%'
	>
		<Grid
			item
			xs={12}
			container
			alignItems="center"
			columnSpacing={1}
			wrap='nowrap'
		>
			<Grid item justifyContent='flex-start' alignItems='center' sx={{ mr: { xs: 0, sm: '1rem' }, display: { xs: 'none', sm: 'block' } }}>
				<Avatar>
					{business.name.charAt(0)}
				</Avatar>
			</Grid>
			<Grid item
				sx={{
					display: 'flex',
					flexDirection: 'column',
				}}
				justifyContent='space-evenly'
				maxWidth='100%'
				minWidth='0'
			>
				<Grid item xs={12}>
					<Grid container alignItems="baseline" flexWrap='nowrap'>
						<Typography fontSize='1.25rem' fontWeight={700} pr='1rem' variant='body1' color='text.primary' noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}>
							{business.name}
						</Typography>
						<Grid item >
							{business.disabled ? <Chip label={t('createbusiness.blocked')} color="primary" /> : <MuiArrowCircle style={{ fill: business.kybStatus === KybStatus.VERIFIED ? theme.statusColor.success : theme.statusColor.error }} />}
						</Grid>
					</Grid>
					<Grid container alignItems="baseline" flexWrap='nowrap'>
						<Typography fontSize='0.875rem' color='text.secondary' noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}> {business.clientId} </Typography>
						<Grid item >
							<MuiCopyToClipboard value={business.clientId} className='svg-icon-stroke-secondary' />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item ml='auto' pl={6}
				justifyContent='space-evenly'
				alignItems="center"
			>
				<Grid marginRight="5px">
					{business.kybStatus !== KybStatus.VERIFIED && <Button onClick={handleCreateBusiness} variant={warn ? 'statusWarn' : 'statusActive'}
						disabled={!allowToCreate}>
						<MuiTextAccountBoxHeader variant={!warn ? 'gradient' : 'inherit'}  >
							{business.kybStatus === KybStatus.REJECTED && business.kybFinal ? t('createbusiness.kybStatus.REJECTED_FINAL') : t('createbusiness.kybStatus.' + business.kybStatus)}
						</MuiTextAccountBoxHeader>
					</Button>}
				</Grid>
				<Grid item>
					{business.kybStatus === KybStatus.VERIFIED && <Button
						onClick={() => handleSwitchUser(business.clientId)}
						variant="transparent"
						sx={{ minWidth: { xs: '90px', md: '120px' } }}
						disabled={business.clientId === user.clientId || business.disabled}
					>
						<MuiTextAccountBoxHeader variant='inherit'>
							{business.clientId === user.clientId ? t('registration.links.loggedIn') : t('registration.links.login')}
						</MuiTextAccountBoxHeader>
					</Button>}
				</Grid>
			</Grid>
		</Grid>
	</BaseBox>);
};

export default BusinessLine;
