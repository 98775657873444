import * as React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { runOperation, tranformSubProcessByType } from '@/features/operations/slice';
import { CardStatuses, CardSubProcesses } from '@/features/card/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { useHistory } from 'react-router';
import { getCards } from '@/features/card/slice';
import { showException } from '@/features/swal/slice';
import IOSSwitch from '@/componentsMui/Shared/Widgets/IOSSwitch';
import { isEmpty } from 'lodash';
import MuiSubHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiSubHeader';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import FormCloseButton from '@/componentsMui/Wallet/Balances/Deposit/Components/FormCloseButton';
import { activateCard } from '@/componentsMui/Banking/utils';


type Props = {
    cardId?: number,
    onBack?: () => void,
    onClose?: () => void,
}

const FreezeCardStep = ({ cardId, onBack, onClose }: Props) => {
    const { t } = useTranslation('translations');
    const history = useHistory();
    const dispatch = useDispatch();
    const allCards = useSelector((state: RootState) => state.cards.list);
    const selectedCard = allCards.find(p => p.cardId === cardId);

    const cardProcesses = tranformSubProcessByType(selectedCard.process.subProcesses);
    const operationFreezeCard = cardProcesses[CardSubProcesses.CARD_SUSPEND]?.proc;
    const operationActivateCard = cardProcesses[CardSubProcesses.CARD_ACTIVATE]?.proc;
    const activateProc = cardProcesses[CardSubProcesses.CARD_ACTIVATE]?.proc;

    const freezeChange = async () => {
        if (selectedCard.status === CardStatuses.SUSPENDED) {
            const needVfCode = false;
            await activateCard(selectedCard?.cardId, activateProc, selectedCard?.virtual, history, needVfCode, selectedCard.status);
        }
        else {
            const payload: any = { cardId: selectedCard.cardId };
            try {
                await runOperation(operationFreezeCard, payload);
                dispatch(getCards());
            }
            catch (e) {
                await showException(e);

            }
        }
    };


    return (
        <Grid container flexDirection='column' flex={1}>
            <Grid id='paymentDetails' item container >
                <Grid item xs={12} pb={4}>
                    <FormHeader title={t('cards.cardSettings.menu.freezeCard')} showBack={!!onBack} onBack={onBack} />
                    <MuiFormText>{t('cards.cardSettingForm.freezeCardSubHeader')}</MuiFormText>
                </Grid>
                <Grid item xs={2} sm={1.4} >
                    <IOSSwitch
                        checked={selectedCard.status === CardStatuses.SUSPENDED}
                        onChange={freezeChange}
                        disabled={selectedCard.status === CardStatuses.SUSPENDED ? isEmpty(operationActivateCard) : isEmpty(operationFreezeCard)}
                    />

                </Grid>
                <Grid item xs={10} sm={10.6}>
                    <MuiSubHeader>{selectedCard.status === CardStatuses.SUSPENDED ? t('cards.cardSettingForm.unfreezeCard') : t('cards.cardSettings.menu.freezeCard')}</MuiSubHeader>
                </Grid>
            </Grid>
            <FormCloseButton onClose={onClose} />
        </Grid>
    );

};

export default FreezeCardStep;
