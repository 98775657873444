import { InvoiceRecipient } from '@features/invoices/invoicesTypes';
import { Card, Grid, IconButton, Typography } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditRecipientDialog from './EditRecipientDialog';

interface Props {
    recipient: InvoiceRecipient | null;
    onChange?: (recipient: InvoiceRecipient | null) => void;
}

const InvoiceRecipientBox = ({ recipient, onChange }: Props) => {
    const {t} = useTranslation('translations');
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
    const reciepientAddressInfo = recipient ? [recipient?.recipientCity, recipient?.recipientPostalCode, recipient?.recipientCountry].filter(value => value != null).join(', ') : null;

    const editable = !!onChange;


    return (
        <Card
            sx={theme => ({
                background: theme.palette.background.paper,
                padding: '20px',
                borderRadius: '16px',
                height: '100%',
            })}
        >
            {editable && openEditDialog && <EditRecipientDialog
                open={openEditDialog}
                onClose={() => setOpenEditDialog(false)}
                recipient={recipient}
                onSave={(rec) => { onChange(rec); setOpenEditDialog(false); }}
            />}
            <Grid container>
                <Grid item xs={12} container justifyContent='space-between' alignItems='center'>
                    <Typography variant="h5">{t('invoice.billTo')}</Typography>
                    {editable && <IconButton onClick={() => setOpenEditDialog(true)}>
                        <ModeEditIcon />
                    </IconButton>}
                </Grid>
                <Grid item xs={12} my={2}>
                    {recipient === null ? <Typography variant="body2">{t('invoice.anonRecipient')}</Typography> :
                        <>
                            {recipient.recipientName && <Typography variant="body2">{recipient.recipientName}</Typography>}
                            {recipient.recipientAddress && <Typography variant="body2">{recipient.recipientAddress}</Typography>}
                            {reciepientAddressInfo && <Typography variant="body2">{reciepientAddressInfo}</Typography>}
                            {recipient.recipientEmail && <Typography variant="body2">{recipient.recipientEmail}</Typography>}
                            {recipient.recipientPhone && <Typography variant="body2">{recipient.recipientPhone}</Typography>}
                        </>}
                </Grid>
            </Grid>
        </Card>
    );
};

export default InvoiceRecipientBox;
