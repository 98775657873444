import React from 'react';
import { useTranslation } from 'react-i18next';
import { BankAccount } from '@/features/account/types';
import DetailLine from '@/componentsMui/Shared/Widgets/DetailLine';
import DetailsBox from '@/componentsMui/Shared/Widgets/DetailsBox';

interface Props {
	account: BankAccount
}

const AccountDetails = ({ account }: Props): React.ReactElement => {

	const { t } = useTranslation('translations');
	return (
		<DetailsBox sx={{p: { xs: '0.5rem 1rem', sm: '0.5rem 2.625rem' }}}>
			{account?.recipient &&
				<DetailLine label={t('wallet.beneficiary.name')} value={account?.recipient} first/>
			}
			{account?.iban &&
                <DetailLine label={t('wallet.beneficiary.iban')} value={account?.iban} />
			}
			{account?.bankName &&
                <DetailLine label={t('wallet.beneficiary.bank')} value={account?.bankName} />
			}
			{account?.bankAddress &&
                <DetailLine label={t('wallet.beneficiary.bankAddress')} value={account?.bankAddress} />
			}
			{account?.swift &&
                <DetailLine label={t('wallet.beneficiary.swift')} value={account?.swift}/>
			}
			{(account.currency === 'GBP' && (account.providerType === 'POOLING_IBAN' || account.providerType === 'SCAN')) &&
				<DetailLine label={t('wallet.beneficiary.sortCode')} value={account?.ukSortCode}/>
			}
			{(account.currency === 'GBP' && (account.providerType === 'POOLING_IBAN' || account.providerType === 'SCAN')) &&
				<DetailLine label={t('sendmoneyTranslation.data.accountNumber')} value={account?.ukAccountNumber}/>
			}
		</DetailsBox>
	);
};

export default AccountDetails;
