import { KycAddress, KycAddressTypeEnum } from '@/features/kyc/types';
import { KycStatus, Status, User, UserType } from '@/features/user/types';

export const isKycTierVerified = (user: User) => {

	if (user) {
		const reqTier = user.type === UserType.INDIVIDUAL || user.type === UserType.EMPLOYEE ? 1 : 2;
		const kycTier = user.kycTier || null;
		//const isKycTierRevoked = (user.kycTier === 0) && (user.previousKycTier > 0);
		const kycTierVerified = (kycTier >= reqTier);// || isKycTierRevoked;

		return kycTierVerified;
	}
	else {
		return false;
	}
};


export const getKycTier = (user: User) => {
	if (user?.kyc && user?.kyc.length > 0) {
		return user.kyc.find(r => r.tier === (user.type === UserType.INDIVIDUAL ? 1 : 2));
	}
	else
		return null;
};


export const allowToStartKYC = (user: User, kycProvider: string) => {
	const tier = getKycTier(user);
	const checkCount = tier?.checkCount ?? 0;
	const finalReject = kycProvider === 'onfido' ? checkCount >= 2 : tier?.status === KycStatus.REJECTED && tier?.final;


	const allowToStartKFY = tier === null ? true : (!finalReject || tier?.expiredInMonth === true);
	return allowToStartKFY;
};

export const poaMissing = (user: User) => {
	return user?.poa?.required && user?.poa?.status !== 'APPROVED' && user?.poa?.status !== 'PENDING';
};



export const kycSumitted = (user: User) => {
	const tier = getKycTier(user);
	const allowedStatuses = [KycStatus.IN_PROGRESS, KycStatus.PENDING, KycStatus.VERIFIED];

	const sumitted = (allowedStatuses.includes(tier?.status));
	return sumitted;
};


export const verificationAnd2FASubmitted = (user: User, status: Status) => {
	const poaSummbitted = user?.poa?.required ? (user?.poa?.status === 'APPROVED' || user?.poa?.status === 'PENDING') : true;
	const twoFASet = status.twoFARequired ? (user?.twoFactorAuthEnabled || user.mobilePinEnabled) : true;
	const kycSubmitted = kycSumitted(user);


	switch (user?.type) {
		case UserType.INDIVIDUAL:
			return poaSummbitted && kycSubmitted && twoFASet;
		case UserType.BUSINESS:
			return !kycSumitted(user);
		case UserType.EMPLOYEE:
			return status.twoFARequired ? user?.twoFactorAuthEnabled : true;
	}

};


export const isOnboardingRequired = (user: User, status: Status) => {

	const skipAllowed = status.skipKycAllowed;


	if (user?.onlySkipKycUsers) {
		return !user?.twoFactorAuthEnabled;
	}

	if (!user) { return true; }
	if (user?.skipKycVerification && skipAllowed) { return false; }
	if (!user?.occupationCode) { return true; }

	return !verificationAnd2FASubmitted(user, status);

};


export const isUserVerified = (user: User, status: Status, kycAddressList: KycAddress[]) => {

	return (
		isKycTierVerified(user) &&
		!poaMissing(user) &&
		Boolean(user.risksVerified) &&
		user.kycIdentityRequiredFieldsPresent &&
		!isBirthInfoRequired(user, status, kycAddressList)
	);
};


export const canGoToPortal = (user: User, status: Status) => {

	if (user?.type === UserType.EMPLOYEE) {
		return true;
	}
	if (user?.type === UserType.BUSINESS) {
		const isUserVerified = user && isKycTierVerified(user);
		return isUserVerified;
	}

	const userOnboarded = !isOnboardingRequired(user, status);
	return userOnboarded;

};

export const isItalianOnboarding = (status: Status) => {
	return status?.collectAddionalDataForOAM;
};


export const isBirthInfoRequired = (user: User, status: Status, kycAddressList: KycAddress[]) => {

	if (user?.type !== UserType.INDIVIDUAL) { return false; }
	const isITA = isItalianOnboarding(status);

	if (!isITA) return false;

	const birthAddress = kycAddressList.find(p => p.addressType === KycAddressTypeEnum.PLACE_OF_BIRTH);
	return !birthAddress;
};
