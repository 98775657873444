import React, { FC } from 'react';
import { ListItemIcon, ListItemIconProps, styled } from '@mui/material';

/*
Give  bakcground  to icon
*/

const StyledListItemIcon: FC<ListItemIconProps> =
	styled((props: ListItemIconProps) => (<ListItemIcon {...props} />)
	)(({ theme }) => ({
		width: '40px',
		height: '40px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: theme.palette.text.primary,
		stroke: theme.palette.text.primary,
		fill: theme.palette.text.primary,
		marginRight: '1.25rem'
	}));

export default StyledListItemIcon;
