import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';


/* From state in redux is used to show blured bacground in Firefox and Safari when modal forms are open */
const useGetForm = () => {

	const formStore = useSelector((state: RootState) => state.forms);
	return formStore.isOpen;
};

export default useGetForm;
