import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';
import { RootState } from '@/rootReducer';
import { Box } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';

const CardMobileRedirect = () => {
	const path = window.location.pathname;
	const { v3PortalUrl } = useSelector((state: RootState) => state.status);
	const { agent } = useSelector((state: RootState) => state.status);
	const suffix = v3PortalUrl.includes('devdev') ? 'staging' : 'production';
	const mobileUrl = `${agent.toLowerCase()}-${suffix}:/${path}`;

	React.useEffect(() => {
		console.log('new ', mobileUrl);
		window.location.replace(mobileUrl);
	}, [mobileUrl]);

	return <Box mt='30px'><Spinner /></Box>;
};

export default CardMobileRedirect;
