import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppThunk } from '../../store';
import { UserStore, User, UserType, Status, LoginBody, UserError, ForgotBody, ResetBody, KycTier, BankAccount, MaintenanceMode, SwitchBody, V3LoginBody, V3SignupBody, LoginTypeEnum, Credentials, UserDocumentTypes, ValidateCodeBody, TokenGrantTypeEnum, NextPlan, DocumentInformationPayload } from './types';
import { SocketService } from '../../services/socketService';
import { setCreditials, clearCreditials } from './credentialsSice';
import isEmpty from 'is-empty';
import { Subscription } from 'rxjs';
import defaultAvatar from '../../assets/images/default-avatar.jpg';
import { dismissAllNotifications } from '../swal/slice';
import { getAcceptedDisclaimers } from '../disclaimer/disclaimerSlice';

const initialState: UserStore = {
	maintenanceMode: { mode: false, reason: null },
	user: null,
	status: null,
	error: null,
	twoFactorCode: null,
	backupKey: null,
	clientId: null,
	onBehalfOf: null,
	passwordSetted: false
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setStatus(state, action: PayloadAction<Status>) {
			state.status = action.payload;
			state.status.kybProvider = action.payload.kybProvider;
		},
		setUser(state, action: PayloadAction<User>) {
			state.user = action.payload;
		},
		setTwoFactorCode(state, action: PayloadAction<string>) {
			state.twoFactorCode = action.payload;
		},
		setBackupKey(state, action: PayloadAction<string>) {
			state.backupKey = action.payload;
		},
		updateUser(state, action: PayloadAction<Partial<User>>) {
			state.user = { ...state.user, ...action.payload };
		},
		updateKyc(state, action: PayloadAction<KycTier>) {
			const kyc = state.user.kyc || [];
			let existing = -1;
			if (kyc.length > 0) {
				existing = kyc.findIndex(r => r.tier === action.payload.tier);
			}
			if (existing === -1) {
				kyc.push(action.payload);
			}
			else {
				kyc[existing] = action.payload;
			}
			state.user.kyc = kyc;
		},
		insertKyc(state, action: PayloadAction<KycTier>) {
			const kyc = state.user.kyc || [];
			let existing = -1;
			if (kyc.length > 0) {
				existing = kyc.findIndex(r => r.tier === action.payload.tier);
			}
			if (existing === -1) {
				kyc.push(action.payload);
			}
			state.user.kyc = kyc;
		},
		deleteKyc(state, action: PayloadAction<KycTier>) {
			const index = state.user.kyc.findIndex(k => k.tier === action.payload.tier);
			if (index > -1) {
				state.user.kyc.splice(index, 1);
			}
		},
		updateBankAccount(state, action: PayloadAction<BankAccount>) {
			state.user.bankAccount = action.payload;
		},
		deleteBankAccount(state) {
			state.user.bankAccount = null;
		},
		updateMaintenance(state, action: PayloadAction<MaintenanceMode>) {
			state.maintenanceMode = action.payload;
		},
		clearUser(state) {
			state.user = null;
		},
		setError(state, action: PayloadAction<UserError>) {
			state.error = action.payload;
		},
		updateUserOnBehalfOf(state, action: PayloadAction<any>) {
			state.user.onBehalfOf = action.payload.onBehalfOf;
			state.user.onBehalfOfCompany = action.payload.onBehalfOfCompany;
		},
		updateSwitchOptions(state, action: PayloadAction<any>) {
			state.user.switchOptions = action.payload.switchOptions;
		},
		updateUserPoa(state, action: PayloadAction<any>) {
			state.user.poa.status = action.payload.status;
			state.user.poa.rejectionReason = action.payload.rejectionReason;
		},
		updateUserLevel(state, action: PayloadAction<any>) {
			state.user.plan.currentUserLevel.id = action.payload.id;
			state.user.plan.currentUserLevel.name = action.payload.name;
			state.user.plan.currentUserLevel.priority = action.payload.priority;
			state.user.plan.currentUserLevel.startTime = action.payload.startTime;
			state.user.plan.currentUserLevel.endTime = action.payload.endTime;
		},
		updateNextUserLevel(state, action: PayloadAction<NextPlan>) {
			state.user.plan.nextPlan = action.payload;
		},
		updateConfirmedPhoneNumber(state, action: PayloadAction<any>) {
			state.user.confirmedMobileNumber = action.payload.confirmedMobileNumber;
		},
		setClientId(state, action: PayloadAction<string>) {
			state.clientId = action.payload;
		},
		setUserOnBehalfOf(state, action: PayloadAction<number>) {
			state.onBehalfOf = action.payload;
		},
		setPasswordSetted(state, action: PayloadAction<boolean>) {
			state.passwordSetted = action.payload;
		},
		updateUserKycIdentityRequiredFields: (
			state,
			{ payload }: PayloadAction<any>
		) => {
			state.user.kycIdentityRequiredFieldsPresent =
				payload.kycIdentityRequiredFieldsPresent;
		},
	}
});

export const { updateMaintenance, setStatus, setUser, updateUser, updateKyc, insertKyc, deleteKyc, updateBankAccount, deleteBankAccount, clearUser, setError, setTwoFactorCode, setBackupKey, updateUserOnBehalfOf, updateUserPoa, updateUserLevel, updateConfirmedPhoneNumber, setClientId, updateSwitchOptions, setUserOnBehalfOf, setPasswordSetted, updateUserKycIdentityRequiredFields, updateNextUserLevel } = userSlice.actions;

export const userSelector = (state: { userStore: UserStore }) =>
	state.userStore;

let socketService: SocketService;
let dataSubscriber: Subscription;
let updateSubscriber: Subscription;
let updateKycSubscriber: Subscription;
let updateOnBehalfOfSubscriber: Subscription;
let insertKycSubscriber: Subscription;
let updateBankSubscriber: Subscription;
let deleteBankSubscriber: Subscription;
let deleteKycSubscriber: Subscription;
let maintenanceSubscriber: Subscription;
let hasBusinessSubscriber: Subscription;
let poaSubscriber: Subscription;
let userLevelSubscriber: Subscription;
let confirmedPhoneNumberSubscriber: Subscription;
let updateSwitchOptionsSubscriber: Subscription;
let passwordSettedSubscriber: Subscription;
let updateKycIdentitySubscriber: Subscription;


function dataAdapter(data: any): any {
	return data;
}

function updateAdapter(data: Partial<User>): Partial<User> {
	const userUpdate: Partial<User> = {};
	for (const key of Object.keys(data)) {
		if (isEmpty(data[key])) {
			continue;
		}
		userUpdate[key] = data[key];
	}
	if (isEmpty(userUpdate.avatar)) {
		userUpdate.avatar = defaultAvatar;
	}
	return userUpdate;
}

function kycUpdateAdapter(data: KycTier): KycTier {
	const updatedKyc = new KycTier();
	for (const key of Object.keys(data)) {
		if (isEmpty(data[key])) {
			continue;
		}
		if (key === 'final') {
			updatedKyc[key] = Boolean(data[key]);
		} else {
			updatedKyc[key] = data[key];
		}
	}
	return updatedKyc;
}

function kycInsertAdapter(data: KycTier): KycTier {
	return data;
}

function updateBankAdapter(data: Partial<BankAccount>): BankAccount {
	const bankAccount = new BankAccount();
	for (const key of Object.keys(data)) {
		if (isEmpty(data[key])) {
			continue;
		}
		bankAccount[key] = data[key];
	}
	return bankAccount;
}

export const connect = (): void => {
	if (!socketService) {
		socketService = new SocketService('user');
	}
};

export const disconnect = (): void => {
	socketService = null;
};

export const subscribe = (): AppThunk => {
	return async dispatch => {
		if (!socketService) return;
		try {
			dataSubscriber = socketService.listen('dataR', []).subscribe((data) => {
				if (isEmpty(data) || data.length === 0) return;
				dispatch(setUser(dataAdapter(data)));
				dispatch(updateUser(updateAdapter(data)));
			});
			updateSubscriber = socketService.listen('update', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(updateUser(updateAdapter(data)));
			});
			hasBusinessSubscriber = socketService.listen('has-business-update', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(updateUser(updateAdapter(data)));
			});
			poaSubscriber = socketService.listen('update-poa', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(updateUserPoa(data));
			});
			userLevelSubscriber = socketService.listen('update-plan', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(updateUserLevel(data));
			});
			userLevelSubscriber = socketService.listen('update-next-plan', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(updateNextUserLevel(data));
			});
			confirmedPhoneNumberSubscriber = socketService.listen('update-confirmed-number', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(updateConfirmedPhoneNumber(data));
			});
			updateKycSubscriber = socketService.listen('update-kyc', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(updateKyc(kycUpdateAdapter(data)));
			});
			insertKycSubscriber = socketService.listen('insert-kyc', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(insertKyc(kycInsertAdapter(data)));
			});
			updateBankSubscriber = socketService.listen('update-bank', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(updateBankAccount(updateBankAdapter(data)));
			});
			deleteBankSubscriber = socketService.listen('delete-bank', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(deleteBankAccount());
			});
			deleteKycSubscriber = socketService.listen('delete-kyc', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(deleteKyc());
			});
			maintenanceSubscriber = socketService.listen('maintenance', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(updateMaintenance(data));
			});
			updateOnBehalfOfSubscriber = socketService.listen('update-on-behalf-of', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(updateUserOnBehalfOf(JSON.parse(data)));
			});
			updateSwitchOptionsSubscriber = socketService.listen('update-switch-options', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(updateSwitchOptions(data));
			});
			passwordSettedSubscriber = socketService.listen('password-setted', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(setPasswordSetted(data));
			});
			updateKycIdentitySubscriber = socketService
				.listen('update-kyc-identity', {})
				.subscribe((data) => {
					if (isEmpty(data)) {
						return;
					}
					dispatch(updateUserKycIdentityRequiredFields(data));
				});

			socketService.send('data');
		} catch (error) {
			console.log(error);
			dispatch(setError(error));
		}
	};
};

export const unsubscribe = (): void => {
	dataSubscriber.unsubscribe();
	updateSubscriber.unsubscribe();
	updateKycSubscriber.unsubscribe();
	insertKycSubscriber.unsubscribe();
	updateBankSubscriber.unsubscribe();
	deleteBankSubscriber.unsubscribe();
	deleteKycSubscriber.unsubscribe();
	maintenanceSubscriber.unsubscribe();
	updateOnBehalfOfSubscriber.unsubscribe();
	hasBusinessSubscriber.unsubscribe();
	poaSubscriber.unsubscribe();
	userLevelSubscriber.unsubscribe();
	confirmedPhoneNumberSubscriber.unsubscribe();
	updateSwitchOptionsSubscriber.unsubscribe();
	passwordSettedSubscriber.unsubscribe();
	updateKycIdentitySubscriber.unsubscribe();
};

export const status = (): AppThunk => {
	return async dispatch => {
		const response = await request.get('/api/auth/status');
		const { data } = response;
		dispatch(setStatus(data));
		dispatch(setError(null));
		return data;
	};
};

export const login = (type: UserType = UserType.INDIVIDUAL, payload: LoginBody): AppThunk => {
	return async dispatch => {
		const response = request.post('/api/auth/login/' + type,
			payload
		);
		const { data } = await response;
		dispatch(setCreditials(data));
		dispatch(setError(null));
	};
};

export const v3Login = (payload: V3LoginBody, tokenGrantType: TokenGrantTypeEnum): AppThunk => {
	return async dispatch => {
		const response = request.post('/api/v3/auth/login',
			payload
		);
		const { data } = await response;
		dispatch(setCreditials({ ...data, tokenGrantType }));
		dispatch(setError(null));
		dispatch(getAcceptedDisclaimers());
	};
};

export const refreshToken = (): AppThunk => {
	return async dispatch => {
		const response = await request.post('/api/auth/refreshToken');
		const { data } = response;
		dispatch(setCreditials(data));
		dispatch(setError(null));
	};
};

export const refreshToken5MinuteLong = (): AppThunk => {
	return async dispatch => {
		const response = await request.post('/api/auth/refreshTokenLong');
		const { data } = response;
		dispatch(setCreditials(data));
		dispatch(setError(null));
	};
};

export const v3Signup = (type: LoginTypeEnum, payload: V3SignupBody): AppThunk => {
	return async dispatch => {
		const response = request.post(`/api/v3/auth/register/${type}`,
			{ ...payload, mobileApp: false }
		);
		const { data } = await response;
		const credentialsPayload: Credentials = {
			token: data?.token//, email: ''
		};
		dispatch(setCreditials(credentialsPayload));
		dispatch(setError(null));
	};
};


export const forgotPassword = (payload: ForgotBody): AppThunk => {
	return async dispatch => {
		await request.post('/api/auth/forgot-password', payload);
		dispatch(setError(null));
	};
};

export const resetPassword = (payload: ResetBody): AppThunk => {
	return async dispatch => {
		await request.post('/api/auth/reset-password', payload);
		dispatch(setError(null));
	};
};


export const validateCode = (payload: ValidateCodeBody): AppThunk => {
	return async dispatch => {
		try {
			const response = await request.post('/api/auth/validate-code', payload);
			dispatch(setError(null));
			return response;
		} catch (e: any) {
			dispatch(setError(e.data.errors));
			return e;
		}
	};
};

export const generate2FACode = (): AppThunk => {
	return async dispatch => {
		const response = await request.post('/api/auth/2fa/generate', {});
		const { data } = response;
		const { qrCodeImage, backupKey } = data;
		dispatch(setTwoFactorCode(qrCodeImage));
		dispatch(setBackupKey(backupKey));
		dispatch(setError(null));
	};
};

export const enable2FA = (authenticatorCode: string): AppThunk => {
	return async dispatch => {
		await request.post('/api/auth/2fa/enable', { authenticatorCode });
		dispatch(setError(null));
	};
};

export const enable2FAAPi = async (authenticatorCode: string) => {
	await request.post('/api/auth/2fa/enable', { authenticatorCode });
};

export const disable2FA = (): AppThunk => {
	return async dispatch => {
		await request.post('/api/auth/2fa/disable', {});
		dispatch(setError(null));
	};
};

export const setPassword = async (password: string, recaptchaToken: string) => {
	const response = await request.post('/api/v3/auth/set-password', { password, recaptchaToken });
	const { data } = await response;
	return data;
};

export const resendOtp = async (type: 'sms' | 'email', phoneNumber: string, email: string, clientId: string, recaptchaToken: string) => {
	const response = await request.post(`/api/v3/users/resend-otp/${type}`, { phoneNumber, email, clientId, recaptchaToken });
	const { data } = await response;
	return data;
};

export const chooseUserLevel = async (paymentAccountId: number, userLevelId: number, promoCode: string) => {
	const response = await request.post('/api/users/me/upgrade-plan', { paymentAccountId, userLevelId, promoCode });
	const { data } = await response;
	return data;
};


export const saveUserInformation = async (firstName: string, lastName: string, country: string) => {
	const response = await request.post('/api/v3/users/information', { firstName, lastName, country });
	return response?.data;
};

export const verifyPhoneNumber = async (phoneNumber: string, countryCode: string, recaptchaToken: string) => {
	const response = await request.post('/api/v3/users/me/confirm-phone', { phoneNumber, countryCode, recaptchaToken });
	return response?.data;
};


export const verifyEmail = async (email: string, recaptchaToken: string) => {
	const response = await request.post('/api/v3/users/me/confirm-email', { email, recaptchaToken });
	return response?.data;
};

export const skipKyc = async (skip: boolean) => {
	const response = await request.post('/api/v3/users/skip-kyc-verification', { skip });
	return response?.data;
};


const RESET_ACTION = {
	type: 'RESET_APP'
};

export const logout = (): AppThunk => {
	return async dispatch => {
		try {
			dismissAllNotifications();
			await request.get('/api/auth/logout');
			dispatch(clearCreditials());
			dispatch(clearUser());
			dispatch(RESET_ACTION);
		} catch (error) {
			//TODO handle error
			console.log(error);
		}
	};
};


export const switchUser = (payload: SwitchBody): AppThunk => {
	return async dispatch => {
		const response = request.post('/api/auth/switch', payload);
		const { data } = await response;
		dispatch(setCreditials(data));
		dispatch(setClientId(payload.clientId));
		dispatch(setError(null));
	};
};


export const switchUser2 = async (payload: SwitchBody) => {
	const response = request.post('/api/auth/switch', payload);
	const { data } = await response;
	return data;
};


export const updateUserLanguage = (language: string): AppThunk => {
	return async () => {
		const response = await request.post('/api/identity/language', { language });
		const { data } = response;
		return data;
	};
};



export const refreshUser = (): AppThunk => {
	return async dispatch => {
		const response = await request.get('/api/users/me');
		const data = response?.data;
		if (data) {
			dispatch(updateUser(data));
		}
	};
};

export const getUserDocumentTypesByCountry = async (country: string): Promise<UserDocumentTypes> => {
	const response = await request.get(`/api/identity/document/${country}`);
	return response.data;
};

export const updateUserInformation = async (preferredDocType: string, country: string) => {
	const response = await request.post('/api-mobile/verification/information', { country, preferredDocType });
	const { data } = response;
	return data;
};


export const updateDocumentUserInformation = async (payload: DocumentInformationPayload) => {
	const response = await request.post('/api/v3/users/document/information', payload);
	const { data } = response;
	return data;
};


export default userSlice.reducer;
