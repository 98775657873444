import { DeviceInfo, LoginTypeEnum } from '@/features/user/types';
import { getDeviceInfo } from '@/helpers/browser';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EmailPhoneRegistration from '../Onboarding/createUser/EmailPhoneRegistration';
import { getSmsCountries } from '@/features/fnd/fndSlice';
import { CountryPhoneCodesType } from '@/features/fnd/fndTypes';
import { setLandingStep, setLandingStepCount, setLandingTitle } from '@/features/landingStep/slice';
import MobileHeader from '../Onboarding/components/MobileHeader';
import BackButtonMobileLanding from '../Onboarding/components/BackButtonMobileLanding';
import BackButtonWebLanding from '../Onboarding/components/BackButtonWebLanding';
import { useHistory } from 'react-router-dom';
import { AUTH_HOME } from '@/pages/navigationConstants';
import MinimalLayout from '@/componentsMui/Layout/MinimalLayout';
import { LandingStepEnum } from '@/features/landingStep/type';
import { RootState } from '@/rootReducer';
import Registration from '../Onboarding/createUser/Registration';

interface Props {
    isFreeLancer: boolean
}

const Register = ({ isFreeLancer }: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation('translations');
    const { status } = useSelector((state: RootState) => state);

    const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>(null);
    const [currentTab, setCurrentTab] = useState(LoginTypeEnum.EMAIL);
    const [smsCountries, setSmsCountries] = useState<CountryPhoneCodesType[]>([]);
    const title = t('landing.accountStep.createAccount');
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const paddingTop = matchDownSm ? 10 : 60;

    const stepCount = Object.keys(LandingStepEnum).length;

    useEffect(() => {
        dispatch(setLandingStepCount(stepCount));
        dispatch(setLandingStep({ stepName: LandingStepEnum.CREATE_ACCOUNT, stepOrder: 1 }));
    }, [dispatch, stepCount]);


    useEffect(() => {
        async function getBrowserInfo() {
            setDeviceInfo(await getDeviceInfo());
        }
        getBrowserInfo();
    }, [dispatch]);

    useEffect(() => {
        const get = async () => {
            try {
                const countries = await getSmsCountries();
                setSmsCountries(countries);
            }
            catch (e) { setSmsCountries([]); }

        };
        get();
    }, []);

    useEffect(() => {
        dispatch(setLandingTitle(title));
    }, [dispatch, title]);


    const handleChange = (event: React.SyntheticEvent, newValue: LoginTypeEnum) => {
        setCurrentTab(newValue);
    };

    const goBack = () => history.push(AUTH_HOME);

    return (
        <MinimalLayout
            leftPanel={<BackButtonWebLanding onClick={goBack} />}
            paddingTop={paddingTop}
            hideLogout={true}>
            <Grid container >
                <BackButtonMobileLanding />
                <MobileHeader text={title} />
                {!status?.mobilePhoneRequired && !isFreeLancer &&
                    <Grid item xs={12}>
                        <Tabs value={currentTab}
                            onChange={handleChange}
                            centered
                        >
                            <Tab value={LoginTypeEnum.EMAIL}
                                label={t('form.fields.email')}
                                sx={{ width: '50%', fontWeight: 400 }}
                            />
                            <Tab value={LoginTypeEnum.PHONE}
                                label={t('form.fields.phone')}
                                sx={{ width: '50%', fontWeight: 400 }}
                            />
                        </Tabs>
                    </Grid>}
                <Grid item xs={12} mt={{ xs: '10px', sm: '40px' }}>
                    {status?.mobilePhoneRequired || isFreeLancer ?
                        <Registration deviceInfo={deviceInfo} isFreeLancer={isFreeLancer} smsCountries={smsCountries} /> :
                        <>
                            {currentTab === LoginTypeEnum.EMAIL && <EmailPhoneRegistration deviceInfo={deviceInfo} accountType={LoginTypeEnum.EMAIL} isFreeLancer={isFreeLancer} smsCountries={smsCountries} />}
                            {currentTab === LoginTypeEnum.PHONE && <EmailPhoneRegistration deviceInfo={deviceInfo} accountType={LoginTypeEnum.PHONE} isFreeLancer={isFreeLancer} smsCountries={smsCountries} />}
                        </>}
                </Grid>
            </Grid>
        </MinimalLayout >
    );
};

export default Register;
