import React from 'react';
import { Dialog, DialogContent, Grid, IconButton, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import { withStyles } from '@mui/styles';
import WalletHistory from './WalletHistory';
import '@/componentsMui/Shared/Shared.css';
import { WalletTransactionType } from '@features/walletHistory/types';

const WalletFormPaper = withStyles(() => ({
	root: {
		maxHeight: '90vh',
		minHeight: '90vh',
		padding: '1rem'
	}
}))(Paper);

interface Props {
	open: boolean,
	onClose: () => void,
	walletTransactionType: WalletTransactionType
}

const WalletHistoryForm = ({ open, onClose, walletTransactionType }: Props): React.ReactElement => {

	const { t } = useTranslation('translations');

	return (
		<Dialog
			id="wallet-history-form"
			open={open}
			fullWidth
			PaperComponent={WalletFormPaper}
			maxWidth='lg'
		>
			<DialogContent className='scroll-container' style={{ height: '100vh' }}>
				<Grid container justifyContent='flex-end'>
					<Grid item>
						<IconButton onClick={onClose}  >
							<MuiCloseIcon />
						</IconButton>
					</Grid>
				</Grid>
				<div className='scroll-section'>
					<WalletHistory
						hideExpand={true}
						title={t('transaction.header.expanded')}
						walletTransactionType={walletTransactionType}
					/>
				</div>
			</DialogContent>
		</Dialog >
	);
};

export default WalletHistoryForm;
