
import * as React from 'react';
import { DialogContent, DialogContentProps } from '@mui/material';
import { FC } from 'react';

import '@/componentsMui/Shared/Shared.css';

/* Scroll Bars removed*/
const DialogContentNoScrollbar: FC<DialogContentProps> = (props: DialogContentProps) => {

	const { className, ...other } = props;

	return (<DialogContent {...other}
		sx={{ padding: 0 }}
		className={className + ' portal-hide-scroll-bar'}>
		{props.children}
	</DialogContent>);
};

export default DialogContentNoScrollbar;
