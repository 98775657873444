import './AppBarMain.css';
import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { AppBar, Badge, Chip, Container, Grid, IconButton, ListItem, Theme, Toolbar } from '@mui/material';
import IconWrapBox from '../Shared/Widgets/Box/IconWrapBox';
import LogoThemed from '@/componentsMui/Shared/Widgets/LogoThemed';
import { useTheme } from '@mui/styles';
import LanguageDropdown from '../LanguageDropdown/LanguageDropdown';
import Notifications from '../Dashboard/Notifications/Notifications';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { getUnreadCount } from '@/features/tickets/slice';
import { showErrorNotification } from '@/features/swal/slice';
import SwitchUserDropdown from '../Shared/Components/SwitchUserDropdown/SwitchUserDropdown';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import { openDrawer } from '@features/drawer/slice';

interface Props {
	authorized: boolean
}

const AppBarMain = ({ authorized }: Props): React.ReactElement => {
	const theme = useTheme() as Theme;

	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const [openNotifications, setOpenNotifications] = useState<boolean>(false);
	const { unreadCount } = useSelector((state: RootState) => state.tickets);
	const { user } = useSelector((state: RootState) => state.user);



	const fetchData = useCallback(() => {
		const get = async () => {
			if (!authorized) return;
			try {
				await dispatch(getUnreadCount());
			} catch (e) {
				await showErrorNotification(e);
			}
		};
		get();
	}, [dispatch, authorized]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const handleOpenDrawer = () => {
		dispatch(openDrawer());
	};

	return (<Grid
		alignItems='center'
		height='70px'>
		<AppBar
			id="portal-app-bar-id"
			position="fixed"
			sx={{ background: theme.bgSecondaryButton, paddingRight: { xs: 0, sm: '42px' } }}
		>
			<Container disableGutters={false} >
				<Toolbar
					className="main-toolbar"
					disableGutters>
					<ListItem
						component={NavLink}
						to="/wallet/home"
						disableGutters>
						<LogoThemed className="navbar-logo" />
						{user?.type && <Chip label={t('navbar.userType.' + user?.type)} color="primary" sx={{ ml: { xs: 1, sm: 3 } }} />}
					</ListItem>
					<Grid container
						columnGap={2}
						alignItems="center"
						justifyContent="flex-end"
						sx={{ display: { xs: 'none', md: 'flex' } }}
					>
						{authorized &&
							<Grid item >
								<IconWrapBox sx={{ width: '42px' }} >
									<IconButton size="small" disableRipple={true}
										onClick={() => setOpenNotifications(!openNotifications)}
									>
										<Badge badgeContent={unreadCount} >
											<NotificationsOutlinedIcon sx={{ color: theme.palette.text.secondary }} />
										</Badge>
									</IconButton>
								</IconWrapBox>
							</Grid>
						}
						{authorized &&
							<Grid item>
								<IconWrapBox className='lang-drop-box'>
									<LanguageDropdown />
								</IconWrapBox>
							</Grid>}
						{authorized &&
							<Grid item>
								{user &&
									<IconWrapBox sx={{ minWidth: '192px' }}>
										<SwitchUserDropdown user={user} />
									</IconWrapBox>}
							</Grid>}
					</Grid>
					<Grid container
						alignItems="center"
						columnGap={1}
						justifyContent="flex-end"
						sx={{ display: { xs: 'flex', md: 'none' } }}>
						{authorized &&
							<Grid item >
								<IconWrapBox sx={{ width: '42px' }} >
									<IconButton size="small" disableRipple={true}
										onClick={() => setOpenNotifications(!openNotifications)}
									>
										<Badge badgeContent={unreadCount} >
											<NotificationsOutlinedIcon sx={{ color: theme.palette.text.secondary }} />
										</Badge>
									</IconButton>
								</IconWrapBox>
							</Grid>
						}
						{authorized && <Grid item>
							<IconWrapBox sx={{ width: '42px' }} >
								<IconButton
									size="large"
									aria-label="account of current user"
									aria-controls="menu-appbar"
									aria-haspopup="true"
									onClick={handleOpenDrawer}
									color="inherit"
									disableRipple={true}
								>
									<MenuIcon />
								</IconButton>
							</IconWrapBox>
						</Grid>}
					</Grid>
					<Grid id='notification-container'>
						{authorized && openNotifications &&
							<Notifications open={openNotifications} onClose={() => setOpenNotifications(false)} />}
					</Grid>
				</Toolbar>
			</Container>
		</AppBar>
	</Grid>

	);
};
export default AppBarMain;
