
import * as React from 'react'; 
import { useTranslation } from 'react-i18next';
import CardStatusBox from '../../../../Shared/Widgets/Box/CardStatusBox';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import MuiTextLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextLabel';

const CardExpired = () => {
	const { t } = useTranslation('translations');
	return (
		<CardStatusBox id="cardExpired">
			<MuiTextCaption variant='body2' color='text.primary' lineHeight='26px'>{t('cards.cardStatus.expired')}</MuiTextCaption>
			<MuiTextLabel variant='body2' lineHeight='16px'>{t('cards.cardStatus.expiredMessage')}</MuiTextLabel>
		</CardStatusBox>);
};

export default CardExpired;
