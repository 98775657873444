import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { createKycAddress, getKycAddressList, profileUpdate } from '@/features/kyc/slice';
import { postUserPoa } from '@/features/files/slice';
import { showException } from '@/features/swal/slice';
import { Alert, Autocomplete, Box, Button, Grid, TextField, Theme } from '@mui/material';
import UsaStateList from '../Shared/FormikComponents/UsaStateList';
import MuiTextField from '../Shared/FormikComponents/MuiTextField';
import MuiFormText from '../Shared/Widgets/MuiText/MuiFormText';
import CountryCodes from '../Shared/FormikComponents/CountryCodes';
import { isEmpty } from 'lodash';
import { alpha2ToAlpha3, alpha3ToAlpha2, getNames } from 'i18n-iso-countries';
import { ActionsStatus } from './Onboarding';
import FileUpload, { FileStatusEnum } from '@/componentsMui/Shared/Components/FileUpload/FileUpload';
import { useTheme } from '@mui/styles';
import { setLandingTitle } from '@/features/landingStep/slice';
import MinimalLayout from '../Layout/MinimalLayout';
import MobileHeader from './components/MobileHeader';
import BaseBox from '../Shared/Widgets/Box/BaseBox';
import MuiTextCaption from '../Shared/Widgets/MuiText/MuiTextCaption';
import MuiTextAlert from '../Shared/Widgets/MuiText/MuiTextAlert';
import './Onboarding.css';
import { calculateScore } from '@/features/identity/slice';
import { getResidencySumsubCountries } from '@/features/countries/slice';
import LoadingPageMui from '@/pages/LoadingPageMui';
import { validatePostalCode } from '@/helpers/postalCodeValidation';
import { isItalianOnboarding } from '@/helpers/kyc';
import ComuneSelect from './components/ComuneSelect';
import { KycAddressPayload, KycAddressTypeEnum } from '@/features/kyc/types';
import { useComune } from '@/helpers/customHook/useValidations';

interface Props {
	stepStatus: ActionsStatus,
	paddingTop?: number,
	onComplete: () => void
}

const Poa = ({ stepStatus, paddingTop, onComplete }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const { user, status } = useSelector((state: RootState) => state.user);
	const contriesList = getNames('en');
	const [poaFile, setPoaFile] = useState(null);
	const [poaFileStatus, setPoaFileStatus] = useState('');
	const [submittingPoa, setSubmittingPoa] = useState(false);
	const theme: Theme = useTheme();
	const { comunes } = useSelector((state: RootState) => state.italyTerritory);
	const title = t('landing.poaStep.title');

	const poaFileRequired = user?.poa?.required;
	const userTaxCountryCode = alpha3ToAlpha2(user?.taxCountry);
	const newPoa = stepStatus === ActionsStatus.NEW;
	const poaCheckOngoing = stepStatus === ActionsStatus.INPROGRESS;
	const alertText = poaCheckOngoing ?
		t('landing.documentStatus.inprogress') :
		`${t('landing.poaStep.poaFailed')} ${user?.poa?.rejectionReason ?? ''} `;

	const { residencyList, residencyLoading } = useSelector(
		(state: RootState) => state.allowedCountries
	);
	const isItaOnboarding = isItalianOnboarding(status) || false;

	const countriesList = Object.keys(contriesList).reduce((obj, key) => { if (residencyList.includes(key)) { obj[key] = contriesList[key]; } return obj; }, {});
	const countryOptions =
		Object.keys(countriesList)
			.map($code => ({
				code: $code,
				label: countriesList[$code],
			}));

	const comuneValidation = useComune();
	useEffect(() => {
		dispatch(setLandingTitle(title));
	}, [dispatch, title]);

	useEffect(() => {
		dispatch(getResidencySumsubCountries());
	}, [dispatch]);


	const initialValues = {
		country: user?.addressCountry ?? '',
		state: user?.state ?? '',
		street: user?.address ?? '',
		city: user?.city ?? '',
		postalCode: user?.postalCode ?? '',
		taxCountry: user?.taxCountry ? { label: contriesList[userTaxCountryCode], code: userTaxCountryCode } : {},
		taxState: user?.taxState ?? '',
		taxIdNumber: user?.taxIdNumber ?? '',
		comune: ''
	};


	const validationSchema = Yup.object({
		street: Yup.string().required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin')),
		city: Yup.string().required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin')),
		postalCode: Yup.string().required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin')),
		country: Yup.string()
			.required(t('form.validator.required')),
		state: Yup.object().nullable().when('country', {
			is: (country) => country === 'US',
			then: Yup.object().required().test('state', t('form.validator.required'), (value) => !!value),
			otherwise: Yup.object().nullable().notRequired(),
		}),
		taxCountry: Yup.object().nullable().test('taxCountry', t('form.validator.required'), (value) => !!value),
		taxState: Yup.object().nullable().when('country', {
			is: (country) => country === 'US',
			then: Yup.object().nullable().when('taxCountry', {
				is: (country) => country === 'US',
				then: Yup.object().required().test('taxState', t('form.validator.required'), (value) => !!value),
				otherwise: Yup.object().nullable().notRequired(),
			}),
			otherwise: Yup.object().nullable().notRequired(),
		}),
		taxIdNumber: Yup.string().nullable().when('country', {
			is: (country) => country === 'US',
			then: Yup.string().required().test('country', t('form.validator.required'), (value) => !!value),
			otherwise: Yup.string().nullable().notRequired(),
		}),
		comune: Yup.string().nullable().when('country', {
			is: (country) => country === 'IT' && isItaOnboarding,
			then: comuneValidation,
			otherwise: Yup.string().nullable().notRequired(),
		}),
	});


	const submitKycAddress = async (countryIso3: string, city: string, comune: string) => {


		const provice = comunes.find(p => p.codiceCatastale === comune)?.siglaAutomobilistica || undefined;
		const payload: KycAddressPayload = {
			addressType: KycAddressTypeEnum.RESIDENCE_ADDRESS,
			country: countryIso3,
			city: city,
			municipality: comune,
			province: provice,

		};
		await createKycAddress(payload);
		await dispatch(getKycAddressList());

	};

	const submit = async (formData, formikProps) => {
		const { setFieldError } = formikProps;
		try {
			setSubmittingPoa(true);
			const { country, street, city, postalCode, state, taxCountry, taxState, taxIdNumber, comune } = formData;


			if (!validatePostalCode(postalCode, country)) {
				setFieldError('postalCode', t('form.validator.invalidPostalCode'));
				return;
			}
			const countryIso3 = alpha2ToAlpha3(country);
			if (!countryIso3) {
				setFieldError('country', t('form.validator.required'));
				return;
			}

			if (isItaOnboarding) { await submitKycAddress(countryIso3, city, comune); }

			await dispatch(profileUpdate({
				country: alpha2ToAlpha3(country),
				street,
				town: city,
				postalCode,
				state: country === 'US' ? state.code : null,
				taxCountry: alpha2ToAlpha3(taxCountry.code),
				taxState: taxCountry === 'US' ? taxState.code : null,
				taxIdNumber: country === 'US' ? taxIdNumber : null,
			}));

			if (poaFileRequired) {
				const file = new FormData();
				file.append('file', poaFile);
				await postUserPoa(file);
			}

			await calculateScore();

			onComplete();
		} catch (e) {
			showException(e);
		}
		finally {
			setSubmittingPoa(false);
		}
	};

	const Info = () => {
		return (
			<Grid container width='100%' flexDirection='column' alignItems='center' mb={'20px'}>
				<Grid item width='250px' m='5px' >
					<BaseBox
						p='20px'
						display='flex'
						flexDirection='column'
						justifyContent='space-between'>
						<div className='landing-list'>
							<MuiTextCaption mb='10px'
								sx={{ color: '#50CD5D' }}
							> {t('landing.poaStep.weAccept')}</MuiTextCaption>
							<li> {t('landing.poaStep.weAccept1')}  </li>
							<li> {t('landing.poaStep.weAccept2')} </li>
							<li> {t('landing.poaStep.weAccept3')} </li>
							<li> {t('landing.poaStep.weAccept4')} </li>
							<li> {t('landing.poaStep.weAccept5')} </li>
						</div>
					</BaseBox>
				</Grid>
				<Grid item width='250px' m='5px' >
					<BaseBox
						p='20px'
						display='flex'
						flexDirection='column'
						justifyContent='space-between'>
						<div className='landing-list'>
							<MuiTextCaption mb='10px'
								sx={{ color: '#FA1C6C' }}> {t('landing.poaStep.dontAccept')}</MuiTextCaption>
							<li> {t('landing.poaStep.dontAccept1')} </li>
							<li> {t('landing.poaStep.dontAccept2')} </li>
							<li> {t('landing.poaStep.dontAccept3')} </li>
							<li> {t('landing.poaStep.dontAccept4')} </li>
							<li> {t('landing.poaStep.dontAccept5')} </li>
						</div>
					</BaseBox>
				</Grid>
			</Grid>);
	};

	if (residencyLoading) {
		return <LoadingPageMui />;
	}


	return (<MinimalLayout
		height='fit-content'
		paddingTop={paddingTop}
		rightPanel={<Info />}
		allowGoHome={false}>

		<Grid container id='poa-step' >
			<Grid item >
				<MobileHeader text={title} />
				<Formik
					id='poa-step'
					initialValues={initialValues}
					enableReinitialize
					validationSchema={validationSchema}
					onSubmit={submit}
				>
					{({ errors, isSubmitting, values, setFieldValue, setFieldTouched, touched }) => {
						return (
							<Form>
								<Grid container>
									<Grid item xs={12} mb={{ xs: '20px', sm: '40px' }} >
										<MuiFormText >
											{t('landing.poaStep.info1')}
										</MuiFormText>
									</Grid>
									{!newPoa && <Grid item xs={12}>
										<Alert severity="warning"  >
											<MuiTextAlert  >
												{alertText}
											</MuiTextAlert>
										</Alert>
									</Grid>}
									<Grid item xs={12} container rowGap={{ xs: 0, sm: '20px' }}>
										<Grid item xs={12}>
											{/* <CountryCodes fieldName='country' countryList={countriesList} /> */}
											<Autocomplete
												id="country-autocomplete"
												options={countryOptions}
												onChange={(e, value) => {
													setFieldValue('country', typeof value === 'object' ? value.code : '');
													setFieldValue('comune', '');
													setTimeout(() => setFieldTouched('comune', true));
												}}
												disableClearable
												fullWidth
												sx={{ width: '100%', display: 'flex', alignContent: 'center' }}
												isOptionEqualToValue={(option, value) => value === undefined || option.code === value.code}
												renderOption={(props, option) => (
													<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
														<img
															loading="lazy"
															width="20"
															src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
															srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
															alt=""
														/>
														{option.label}
													</Box>
												)}
												renderInput={(params) => (
													<TextField
														className='registration-input country-selection'
														fullWidth
														name="country"
														label={t('landing.identityStep.countryOfIssue')}
														{...params}
														inputProps={{
															...params.inputProps,
															autoComplete: 'off',
														}}
														InputLabelProps={{ style: { color: theme.palette.text.primary, textAlign: 'left' } }}
														error={errors.country && touched.country}
														helperText={errors.country}
													/>
												)}

											/>
										</Grid>
										{values.country === 'US' &&
											<Grid item xs={12}>
												<UsaStateList name='state' />
											</Grid>
										}
										<Grid item xs={12}>
											<MuiTextField
												name="street"
												fullWidth
												label={t('form.fields.justAddress')} />
										</Grid>
										<Grid item xs={12} container columnSpacing={2} rowGap={{ xs: 0, sm: '20px' }}>
											<Grid item xs={12} sm={6}>
												<MuiTextField
													name="city"
													fullWidth
													label={t('form.fields.city')} />
											</Grid>
											<Grid item xs={12} sm={6}  >
												<MuiTextField
													name="postalCode"
													fullWidth
													label={t('form.fields.postalcode')}
													inputProps={{ maxLength: 16 }} />
											</Grid>
										</Grid>
										{values.country === 'US' &&
											<>
												<Grid item xs={12}>
													<CountryCodes fieldName='taxCountry' fieldlabel={t('form.fields.taxCountry')} />
												</Grid>
												{values.taxCountry?.code === 'US' &&
													<Grid item xs={12}>
														<UsaStateList name='taxState' fieldLabel={t('form.fields.taxState')} />
													</Grid>
												}
												<Grid item xs={12}>
													<MuiTextField
														name="taxIdNumber"
														fullWidth
														label={t('form.fields.taxIdNumber')} />
												</Grid>
											</>
										}
										{values.country === 'IT' && isItaOnboarding &&
											<>
												<Grid item xs={12}>
													<ComuneSelect
														countryIso3={'ITA'}
														value={values.comune}
														labelComune={t('landing.profileDetailsOam.comuneOfResidency')}
														onChange={(value) => setFieldValue('comune', value)} />
												</Grid>
											</>
										}
									</Grid>
									<Grid item
										xs={12}
										container
										mt={{ xs: '20px', sm: '40px' }}
										rowGap={{ xs: '20px', sm: '40px' }}>
										{poaFileRequired && <Grid item xs={12}>
											<FileUpload
												disabled={poaCheckOngoing}
												sx={{
													background: { xs: 'transparent', sm: theme.palette.background.paper },
													padding: { xs: 0, md: '20px' }
												}}
												acceptedFileFormats='image/png,image/jpg,image/jpeg,application/pdf'
												setFile={(value) => setPoaFile(value)}
												setStatus={(value) => setPoaFileStatus(value)}
												maxFileSizeKb={5000}
											/>
										</Grid>
										}
										<Grid item xs={12} mb={3}>
											<Button
												variant='contained'
												type="submit"
												fullWidth
												disabled={isSubmitting || submittingPoa ||
													!isEmpty(errors) ||
													poaCheckOngoing ||
													(poaFileStatus !== FileStatusEnum.SUCCESS && poaFileRequired)}
												className='button-right'
											>
												{t('form.buttons.continue')}
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Form>);
					}}
				</Formik>
			</Grid>
		</Grid>
	</MinimalLayout >
	);
};

export default Poa;
