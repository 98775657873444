import * as React from 'react';
import '@/componentsMui/Dashboard/Create/Create.css';
import { Dialog, Grid, IconButton, ListItemIcon, ListItemText, MenuList, Typography } from '@mui/material';
import StyledMenuItemModal from '@/componentsMui/Shared/Widgets/MenuItem/StyledMenuItemModal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Operations } from '@/features/operations/types';
import { useEffect, useState } from 'react';
import { RootState } from '@/rootReducer';
import { getOperationsByType } from '@/features/operations/slice';
import { showException } from '@/features/swal/slice';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import useCloseDialog from '@/helpers/customHook/useCloseDialog';
import { CardType, PotentialAddress } from '@/features/card/types';
import PickCardStep from './Steps/PickCardStep';
import CardDetails from './Steps/CardDetails';
import CardConfirmation from './Steps/CardConfirmation';
import { Address } from '@/features/card/types';
import { openForm } from '@/features/forms/slice';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { getFeesByCategory } from '@/features/fees/slice';
import { FeesFimitsType, Process } from '@/features/fees/types';
import usePayAccounts from '@/helpers/customHook/accounts/usePayAccounts';
import { BankAccount } from '@/features/account/types';
import CardAddress from './Steps/CardAddress';
import FormPaperLargeScrollable from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeScrollable';
import { getCardShipmentCountry } from '@/features/countries/slice';
import { CardOrderFees, getCardOrderFees } from '@/componentsMui/Banking/utils';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';

enum Step {
	PICK = 'PICK', DETAILS = 'DETAILS', ADDRESS = 'ADDRESS', CONFIRMATION = 'CONFIRMATION'
}


const CreateCardForm = () => {
	const { t } = useTranslation('translations');
	const closeModal = useCloseDialog();
	const dispatch = useDispatch();

	const [cardType, setCardType] = useState<CardType>(CardType.VIRTUAL);
	const [options, setOptions] = useState<Process[]>([]);
	const [optionsLoading, setOptionsLoading] = useState<boolean>(false);
	const [selectedOption, setSelectedOption] = useState<any>(null);
	const [step, setStep] = React.useState<Step>(Step.PICK);
	const [deliveryAddress, setDeliveryAddress] = useState<Address>(null);
	const [potentialAddresses, setPotentialAddresses] = useState<Array<PotentialAddress>>([]);
	const { list: cardList } = useSelector((state: RootState) => state.cards);
	const [chooser, setChooser] = useState<boolean>(true);
	const [disableVirtualCard, setDisableVirtualCard] = useState<boolean>(true);
	const [disablePhysicalCard, setDisablePhysicalCard] = useState<boolean>(true);
	const [fees, setFees] = useState<FeesFimitsType[]>(null);
	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const getCardType = (operation: Process) => {
		return operation.processProperties.virtual ? CardType.VIRTUAL : CardType.PHYSICAL;
	};


	const cardCurrency = selectedOption?.processProperties?.ccy;
	const { accountList: paymentAccounts } = usePayAccounts(cardCurrency);


	const [selectedAccount, setSelectedAccount] = useState<BankAccount>(paymentAccounts[0]);

	const virtualOptions = options.filter((option) => CardType.VIRTUAL === getCardType(option)) || [];
	const physicalOptions = options.filter((option) => CardType.PHYSICAL === getCardType(option)) || [];

	const cardOrderFees: CardOrderFees = getCardOrderFees(selectedOption?.proc, fees, false);

	useEffect(() => {
		setDisableVirtualCard((virtualOptions?.length) === 0);
		setDisablePhysicalCard((physicalOptions?.length) === 0);
	}, [cardList, physicalOptions.length, user.accountSettings.physicalCardLimitCount, user.accountSettings.virtualCardLimitCount, virtualOptions.length]);

	useEffect(() => {
		if (potentialAddresses.length > 0) {
			setStep(Step.ADDRESS);
		}
	}, [potentialAddresses]);

	const { operations } = useSelector(
		(state: RootState) => state.operations
	);


	const selectType = (type: CardType) => {
		setCardType(type);
		setSelectedOption(null);
	};


	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);

	useEffect(() => {
		dispatch(getCardShipmentCountry());
	}, [dispatch]);

	useEffect(() => {
		const get = async () => {
			if (operations.indexOf(Operations.CREATE_CARD) !== -1) {
				try {
					setOptionsLoading(true);
					const response = await getOperationsByType(Operations.CREATE_CARD);
					setOptions(response);
					const virtualPresent = response.filter((item) => CardType.VIRTUAL === getCardType(item)).length > 0;
					if (virtualPresent && !disableVirtualCard) {
						setCardType(CardType.VIRTUAL);
					} else {
						if (!disablePhysicalCard) {
							setCardType(CardType.PHYSICAL);
						}
					}
					setOptionsLoading(false);
				} catch (e) {
					setOptionsLoading(false);
					await showException(e);
				}
			}
		};
		get();
	}, [operations, disableVirtualCard, disablePhysicalCard]);

	useEffect(() => {
		const get = async () => {
			try {
				const response = await getFeesByCategory(Operations.CREATE_CARD);
				setFees(response[0].fees);
				//setFees(response);
			} catch (e) {
				console.log('failed to fetch fees', e);
			}
		};
		get();
	}, []);



	return (
		<Dialog
			id="create-card-form"
			open={true}
			onClose={closeModal}
			PaperComponent={FormPaperLargeScrollable}
		>
			{chooser &&
				<Grid container>
					<Grid item container justifyContent='space-between'>
						<Grid item>
							<MuiHeader> {t('cards.create')} </MuiHeader>
						</Grid>
						<Grid item>
							<IconButton onClick={closeModal}  >
								<MuiCloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					{optionsLoading ? <Grid container
						justifyContent='center' alignItems='center'
						flexDirection='column'
						width='100%'
						height='100%'
						position='absolute'
						top={0} left={0}>
						<Spinner />
					</Grid> :
						<Grid item xs={12}>
							{virtualOptions.length === 0 && physicalOptions.length === 0 ?
								<Typography>{t('cards.newCard.cardLimitReached')}</Typography>
								:
								<MenuList
									role='menu'
									className='form-menu__list'>
									{virtualOptions.length > 0 && <StyledMenuItemModal
										onClick={() => { setChooser(false); selectType(CardType.VIRTUAL); }}
										selected={cardType === CardType.VIRTUAL}
										disabled={step !== Step.PICK} >
										<ListItemIcon> <CloudQueueIcon /></ListItemIcon>
										<ListItemText >{t('cards.newCard.virtual')}</ListItemText>
									</StyledMenuItemModal>
									}
									{physicalOptions.length > 0 && <StyledMenuItemModal
										onClick={() => { setChooser(false); selectType(CardType.PHYSICAL); }}
										selected={cardType === CardType.PHYSICAL}
										disabled={step !== Step.PICK} >
										<ListItemIcon> <CreditCardIcon /> </ListItemIcon>
										<ListItemText >{t('cards.newCard.physical')}</ListItemText>
									</StyledMenuItemModal>
									}
								</MenuList>
							}
						</Grid>
					}
				</Grid>
			}

			{!chooser && [
				step === Step.PICK &&
				<PickCardStep
					key="pick"
					options={cardType === CardType.VIRTUAL ? virtualOptions : physicalOptions}
					cardOrderFees={cardOrderFees}
					selectedOption={selectedOption}
					setSelectedOption={setSelectedOption}
					nextStep={() => setStep(cardType === CardType.VIRTUAL ? Step.CONFIRMATION : Step.DETAILS)}
					closeModal={closeModal}
					onBack={() => setChooser(true)}
				/>,
				step === Step.DETAILS &&
				<CardDetails
					key="card-details"
					option={selectedOption}
					montlyFee={cardOrderFees.montlyFeeAmount}
					creationFee={cardOrderFees.creationFeeAmount}
					activeAccount={selectedAccount}
					deliveryAddress={deliveryAddress}
					accountList={paymentAccounts}
					confirmAddress={() => setStep(Step.ADDRESS)}
					setDeliveryAddress={setDeliveryAddress}
					setPotentialAddresses={setPotentialAddresses}
					onAccountChange={(newAccount: BankAccount) => setSelectedAccount(newAccount)}
					prevStep={() => setStep(Step.PICK)}
					nextStep={() => setStep(cardType === CardType.PHYSICAL ? Step.CONFIRMATION : undefined)}
				/>,
				step === Step.ADDRESS &&
				<CardAddress
					key="card-address"
					potentialAddresses={potentialAddresses}
					deliveryAddress={deliveryAddress}
					title={selectedOption?.name}
					integration={selectedOption?.integration || ''}
					setDeliveryAddress={setDeliveryAddress}
					onPrev={() => setStep(Step.DETAILS)}
					onNext={() => setStep(Step.CONFIRMATION)} />,
				step === Step.CONFIRMATION &&
				<CardConfirmation
					key="card-confirm"
					activeAccount={selectedAccount}
					accountList={paymentAccounts}
					cardType={cardType}
					deliveryAddress={deliveryAddress}
					option={selectedOption}
					montlyFee={cardOrderFees.montlyFeeAmount}
					creationFee={cardOrderFees.creationFeeAmount}
					onAccountChange={(newAccount: BankAccount) => setSelectedAccount(newAccount)}
					prevStep={() => setStep(cardType === CardType.PHYSICAL ? Step.DETAILS : Step.PICK)}
				/>]
			}

		</Dialog >
	);
};

export default CreateCardForm;

