import * as React from 'react';

import '@/componentsMui/Shared/Shared.css';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { addContact, editContact, lookupContact } from '@/features/contacts/slice';
import { useDispatch } from 'react-redux';
import { getErrorWithParams, showException, showSuccess } from '@/features/swal/slice';
import { Contact, ContactPaymentTypes } from '@/features/contacts/types';
import { isEmpty } from 'lodash';
import MuiSubHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiSubHeader';
import MuiTextTrx from '@/componentsMui/Shared/Widgets/MuiText/MuiTextTrx';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';

interface Props {
    contactToEdit: Contact
}

const NewInternalContact = ({ contactToEdit }: Props) => {

    const { t } = useTranslation('translations');
    const dispatch = useDispatch();
    const closeModal = useCloseDialogNoEvent();
   

    const validationSchema = Yup.object({
        name: Yup.string().required(t('form.validator.required')),
        walletId: Yup.string().required(t('form.validator.required'))
    });

    const submit = async (formData, formikProps) => {
        const { setSubmitting, setFieldError } = formikProps;
        const { name } = formData;
        const walletId = formData?.walletId?.toUpperCase().trim();
        try {

            try {
                await lookupContact(walletId);
            } catch (e) {
                const err = getErrorWithParams(e);
                if (err && err.error === 'notFound' && err.error_param === 'walletId') {

                    setFieldError('walletId', t('sendmoneyTranslation.data.userNotFound'));
                } else {
                    setFieldError('walletId', t('errors.SOMETHING_WENT_WRONG_WITHOT_MESSAGE'));
                }
                return;
            }
            if (contactToEdit) {
                await dispatch(editContact(contactToEdit.beneficiaryAccountId, { ...contactToEdit, name, walletId, paymentType: ContactPaymentTypes.LOCAL }));
                showSuccess({ title: 'contacts.update.success', mui: true });

            }
            else {
                await dispatch(addContact({ name, walletId, paymentType: ContactPaymentTypes.LOCAL }));
                showSuccess({ title: 'contacts.create.success', mui: true });

            }
            closeModal();
        } catch (e) {
            showException(e);
            closeModal();
        }
        finally {
            setSubmitting(false);
        }
    };

    const initialValues = {
        name: contactToEdit?.name ?? '',
        walletId: contactToEdit?.walletId ?? ''
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submit}

        >{({ errors, isSubmitting, dirty }) => {
            return (
                <Form >
                    <Grid container sx={{ height: '100%' }} >
                        <Grid id="contentWrap"
                            item
                            spacing={2}
                            container
                            alignSelf="flex-start" >
                            <Grid item xs={12}  >
                                <MuiSubHeader >{t('contacts.create.id')}</MuiSubHeader>
                                <MuiTextTrx marginTop="0.5rem" >{t('contacts.create.walletText')}</MuiTextTrx>
                            </Grid>
                            <Grid item xs={12}>
                                <MuiTextField
                                    name="name"
                                    fullWidth
                                    label={t('contacts.create.name')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MuiTextField
                                    name="walletId"
                                    fullWidth
                                    label={t('contacts.create.id')}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container alignSelf="flex-end" justifyContent="center" >
                            <Grid item mt='2rem'>
                                <Button disabled={isSubmitting || !isEmpty(errors || !dirty)}
                                    key="buttonNext"
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    type="submit" >
                                    {t('contacts.create.save')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            );
        }}
        </Formik >
    );
};

export default NewInternalContact;
