import * as React from 'react';
import { ReactComponent as AvatarEUR } from '@/assets/CurrencyIcons/Eur.svg';
import { ReactComponent as AvatarUSD } from '@/assets/CurrencyIcons/Usd.svg';
import { ReactComponent as AvatarGBP } from '@/assets/CurrencyIcons/Gbp.svg';


export interface Fiat {
	currency: string,
	symbol: string,
	description: string,
	logo: JSX.Element
}

export const Fiats: Fiat[] = [
	{
		currency: 'EUR',
		symbol: '€',
		description: 'Euro',
		logo: <AvatarEUR />
	},
	{
		currency: 'GBP',
		symbol: '£',
		description: 'Pound',
		logo: <AvatarGBP />
	},
	{
		currency: 'USD',
		symbol: '$',
		description: 'United States Dollar',
		logo: <AvatarUSD />
	}
];


