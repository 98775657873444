import React, { useCallback, useEffect, useState } from 'react';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import { getFutureEarnings, setEarnings, setLoading, setSkip } from '@/features/earn/earningsSlice';
import { EarningsPayload } from '@/features/earn/types';
import { showErrorNotification } from '@/features/swal/slice';
import { RootState } from '@/rootReducer';
import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import '@/componentsMui/Shared/Shared.css';
import { TransactionStatus } from '@/features/transactions/types';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import TransactionStatusBox from '@/componentsMui/Shared/Widgets/Box/TransactionStatusBox';
import { convertDate } from '@/helpers/date';
import NumberFormat from 'react-number-format';
import { setCount } from '@/features/tickets/slice';
import { hiddenBabalance } from '@/helpers/placeholders';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';


const InterestEarnings = () => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const [showLoadMore, setShowLoadMore] = useState<boolean>(true);
	const { skip, take, earnings, count, loading } = useSelector(
		(state: RootState) => state.earnings
	);
	const { user } = useSelector((state: RootState) => state.user);

	const fetchData = useCallback(() => {
		const get = async () => {
			setShowLoadMore(true);
			try {
				dispatch(setLoading(true));
				const payload: EarningsPayload = {
					skip: 0,
					take: take
				};
				dispatch(setCount(0));
				dispatch(setSkip(0));
				dispatch(setEarnings([]));
				dispatch(getFutureEarnings(payload));
			} catch (e) {
				showErrorNotification(e);
			}
		};

		get();
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [dispatch]);

	useEffect(() => {
		if (!loading && earnings.length === count) {
			setShowLoadMore(false);
		}
	}, [earnings, count, loading]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const loadMore = async () => {
		try {
			setShowLoadMore(true);
			const payload: EarningsPayload = {
				skip: skip,
				take: take
			};

			dispatch(getFutureEarnings(payload));

		} catch (e) {
			showErrorNotification(e);
		}

	};

	if (loading) {
		return (<Grid container justifyContent='center' mt={2}><Spinner /></Grid >);
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				<MuiHeader>{t('vaults.interestEarning.title')}</MuiHeader>
			</Grid>
			<Grid container item xs={12} pt='1rem' pb='1rem' flexDirection='row' pr='0.5rem'>
				<Grid item xs={2}>
					<MuiTextCaption color='text.secondary'>
						{t('vaults.interestEarning.transferDate')}
					</MuiTextCaption>
				</Grid>
				<Grid item xs={6}>
					<MuiTextCaption color='text.secondary'>
						{t('vaults.interestEarning.plan')}
					</MuiTextCaption>
				</Grid>
				{/* <Grid item xs={2}>
					<MuiTextCaption color='text.secondary'>
						Asset
					</MuiTextCaption>
				</Grid> */}
				<Grid item xs={2}>
					<MuiTextCaption color='text.secondary'>
						{t('vaults.interestEarning.yourInterest')}
					</MuiTextCaption>
				</Grid>
				<Grid item xs={2} display='flex' justifyContent='flex-end'>
					<MuiTextCaption color='text.secondary'>
						{t('vaults.interestEarning.status')}
					</MuiTextCaption>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<div className='scroll-container'>
					{(!earnings || earnings?.length === 0) ?
						<div style={{ display: 'flex', justifyContent: 'space-around' }}>{t('vaults.allocation.earningsNoFound')}</div>
						: <Box className='scroll-section' sx={{ mb: { xs: '2rem', lg: '0' } }}>
							<div className='scrollable-content' style={{ paddingRight: '0.5rem' }}>
								{earnings?.length > 0 && earnings.map((earning, idx) => (
									<div key={idx} >
										<Grid container p='0.75rem 0'>
											<Grid item xs={2}>
												<MuiTextCaption >{convertDate(new Date(earning.transferDate), 'dd MMM yyyy')}</MuiTextCaption>
											</Grid>
											<Grid item xs={6}>
												<MuiTextCaption variant='gradient' >{earning.vaultName}</MuiTextCaption>
											</Grid>
											{/* <Grid item xs={2}>
												<MuiTextCaption>{earning.currency}</MuiTextCaption>
											</Grid> */}
											<Grid item xs={2} flexDirection='column' display='flex' >
												<MuiTextCaption>
													{user.hideValues ? hiddenBabalance :
														<NumberFormat
															displayType={'text'}
															decimalScale={2}
															fixedDecimalScale={true}
															thousandsGroupStyle='thousand'
															thousandSeparator={true}
															suffix={` ${earning.currency}`}
															value={earning.amount} />}
												</MuiTextCaption>
											</Grid>
											<Grid item xs={2} display='flex' justifyContent='flex-end' alignItems='center'>
												<TransactionStatusBox
													status={TransactionStatus.PENDING}>
													{t('vaults.allocation.status.pending')}
												</TransactionStatusBox>
											</Grid>
										</Grid>
										{idx + 1 < earnings.length && <StyledDivider />}
									</div>
								))}
							</div>
							{showLoadMore &&
								<Grid container>
									<Grid item xs={12} justifyContent='center' display='flex' pt={2}>
										<Button onClick={loadMore}>{t('transaction.table.loadMore')}</Button>
									</Grid>
								</Grid>}
						</Box>}
				</div>
			</Grid>
		</Grid>
	);
};

export default InterestEarnings;
