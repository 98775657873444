import React, { useEffect, useRef, useState } from 'react';
import {
    Alert,
    Box,
    Button,
    FormHelperText,
    Grid,
    TextField,
    useTheme
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MinimalLayout from '../Layout/MinimalLayout';
import { getSupportChat, sendMessage, generateOtpCode, connectChat, subscribeChat, unsubscribeChat } from '@/features/anonSupportChat/slice';
import { RootState } from '@/rootReducer';
import BaseBox from '../Shared/Widgets/Box/BaseBox';
import MuiHeader from '../Shared/Widgets/MuiText/MuiHeader';
import MuiTextTrx from '../Shared/Widgets/MuiText/MuiTextTrx';
import { ChatStatusEnum } from '@/features/anonSupportChat/types';
import MessageInput from '../Dashboard/FAQ/Components/MessageInput';
import { isEmpty } from 'lodash';
import Message from '../Dashboard/FAQ/Components/Message';
import LoadingPageMui from '@/pages/LoadingPageMui';
import MuiFormText from '../Shared/Widgets/MuiText/MuiFormText';
import { showValidationErrorMessage } from '@/features/swal/slice';

interface ParamTypes {
    key: string
}

const SIZE_LIMIT = 5 * 1024 * 1024;

const AnonymousSupport = () => {
    const { t } = useTranslation(['translations']);
    const theme = useTheme();
    const { key } = useParams<ParamTypes>();
    const dispatch = useDispatch();
    const messagesEndRef = useRef<null | HTMLDivElement>(null);
    const inputFieldRef = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<File>(null);
    const [fileError] = useState<string>(null);
    const [inputValue, setInputValue] = useState<string>('');
    const [otpCode, setOtpCode] = useState<string | null>(null);
    const [counter, setCounter] = useState<number>(60);


    const chatData = useSelector((state: RootState) => state.anonSupportChat.data);
    const isLoading = useSelector((state: RootState) => state.anonSupportChat.loading);
    const isExist = useSelector((state: RootState) => state.anonSupportChat.isExist);
    const isInvalidCode = useSelector((state: RootState) => state.anonSupportChat.isInvalidCode);
    const isDisabled = isEmpty(inputValue.trim()) && file === null;

    useEffect(() => {
        if (!otpCode || otpCode.length < 6) { return; }
        setOtpCode(null);
        dispatch(getSupportChat(key, otpCode, { sort: 'DESC' }));
    }, [dispatch, key, otpCode]);

    const handleFile = (files: File[] | null) => {
        if (files === null) {
            setFile(null);
            return;
        }
        if (files.length < 1) {
            showValidationErrorMessage(t('form.validator.onlyOneFileAllowed'));
            return;
        }
        if (!(files[0].size <= SIZE_LIMIT)) {
            showValidationErrorMessage(t('form.validator.fileTooBig'));
            return;
        }
        setFile(files[0]);
    };

    const scrollToBottom = () => {
        setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'end'
            });
        }, 500);
    };

    const submitMessage = () => {
        try {
            const messagePayloadObj = {
                ...inputValue !== '' && { message: inputValue.trim() },
                ...chatData.chat?.id && { chatId: chatData.chat.id },
                ...file && { files: file },
            };
            dispatch(sendMessage(messagePayloadObj));
            setFile(null);
            setInputValue('');
            scrollToBottom();
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        let timer;
        if (counter > 0) {
            timer = setTimeout(() => setCounter(counter - 1), 1000);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [counter]);

    useEffect(() => {
        scrollToBottom();
    }, [chatData?.messages?.length]);

    const generate = () => {
        dispatch(generateOtpCode(key));
    };

    const handleResendOtp = () => {
        dispatch(generateOtpCode(key));
        setCounter(60);
    };

    useEffect(() => {
        if (!key) return;
        generate();
        // eslint-disable-next-line
    }, [key]);

    useEffect(() => {
        if (!key) return;
        connectChat(key);
        dispatch(subscribeChat());
        return () => {
            unsubscribeChat();
        };
    }, [dispatch, key]);

    if (isLoading) {
        return <LoadingPageMui />;
    }

    if (((!otpCode || otpCode?.length !== 6) || isInvalidCode) && isExist === null) {
        return (<MinimalLayout width='1184px' height='600px' authorized={false} hideLogout>
            <Grid container justifyContent='center' alignItems='center' flexDirection='column'>
                <Grid item mt={4}>
                    <MuiFormText sx={{ textAlign: 'center' }} >{t('faq.unauthChat.otp')}</MuiFormText >
                </Grid>
                <Grid item mt={2} justifyContent='center'>
                    <TextField
                        fullWidth
                        autoFocus={true}
                        onChange={(e) => setOtpCode(e.target.value)}
                        value={otpCode ?? ''}
                        type='number'
                    />
                </Grid>
                {isInvalidCode && <Grid item>
                    <FormHelperText>
                        {t('faq.unauthChat.invalidOtp')}
                    </FormHelperText>
                </Grid>}
                <Grid item mt={2}>
                    <Button
                        variant='customOutlined'
                        fullWidth
                        disabled={counter > 0}
                        onClick={handleResendOtp}  >
                        {counter > 0 ? counter : t('form.confirmCodes.resendBtn')}
                    </Button>
                </Grid>
            </Grid>
        </MinimalLayout>);
    }

    if (isExist === false) {
        return <MinimalLayout width='1184px' height='600px' authorized={false} hideLogout>
            <Grid container justifyContent='center'>
                <Grid item mt={4}>
                    <Alert severity="warning"  >
                        <MuiFormText sx={{ textAlign: 'center' }} >{t('faq.unauthChat.chatNotExist')}</MuiFormText >
                    </Alert>
                </Grid>
            </Grid>
        </MinimalLayout>;
    }

    return (
        <MinimalLayout
            width='1184px'
            authorized={false} hideLogout
        >
            <BaseBox
                id="my-account"
                sx={{
                    overflow: 'hidden',
                    background: { xs: 'none', md: '#1D2024' },
                    p: { xs: 0, md: '1.5rem' },
                    height: 'fit-content',
                    width: '100%',
                }}>
                <Grid container alignItems="center" sx={{ mb: { xs: '2rem', sm: '3rem' } }}>
                    <Grid item xs={12} sm={4} sx={{ mb: { xs: '1rem', sm: 0 } }}>
                        <MuiHeader>{t('faq.supportChat')}</MuiHeader>
                    </Grid>
                </Grid>
                <Box px={2} py={1} height={'60vh'} overflow={'scroll'}>
                    {chatData.messages.map((message, index) => (
                        <Grid item key={index} container sx={{ justifyContent: { xs: 'space-between', sm: 'flex-end' } }}>
                            <Message
                                message={message}
                                prevCreatedDate={chatData.messages[index - 1]?.createdDate || null}
                                anonymous
                            />
                        </Grid>
                    ))}
                    {chatData.chat?.status === ChatStatusEnum.SOLVED
                        ? <BaseBox
                            border={`1px solid ${theme.backgroundBoxLevel1}`}
                            sx={{
                                background: theme.backgroundBoxLevel2,
                                p: { xs: 2, md: '1.5rem' },
                                mb: { xs: 2, md: 0 },
                                mt: { xs: 0, md: 2 },
                            }}>
                            <MuiTextTrx>
                                {t('faq.chatSolved')}
                            </MuiTextTrx>
                        </BaseBox>
                        : null}
                    <div ref={messagesEndRef} />
                </Box>
                <Grid container justifyContent='flex-end' mt='30px'>
                    <MessageInput
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        file={file}
                        fileError={fileError}
                        handleFile={handleFile}
                        submitMessage={submitMessage}
                        inputFieldRef={inputFieldRef}
                        sendDisabled={isDisabled} />
                </Grid>
            </BaseBox >
        </MinimalLayout>
    );
};

export default AnonymousSupport;
