

import { Button, CardMedia, Grid, Typography } from '@mui/material';
import imgFallback from '../../../assets/images/QrPLaceholder.png';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    qrImage: string,
    onButtonClick: () => void
}

const QrMediaCard = ({ qrImage, onButtonClick }: Props) => {
    const { t } = useTranslation(['translations']);

    const qrIsActive = qrImage ? true : false;

    return (
        <Grid container position='relative'>
            <CardMedia image={qrIsActive ? qrImage : imgFallback}
                component="img"
                sx={{
                    borderRadius: '16px',
                    width: '260px',
                    height: '260px',
                    opacity: qrIsActive ? 1 : 0.1,
                    margin: 'auto'
                }} />
            {!qrIsActive &&
                <Grid container
                    sx={{
                        position: 'absolute',
                        height: '100%',
                        alignContent: 'center',

                    }}  >
                    <Grid item xs={12} textAlign='center'>
                        <Typography
                            fontSize='1rem'
                            fontWeight={700}> {t('login.v3.qrExpired')}</Typography>
                    </Grid>
                    <Grid item xs={12} mt={2} textAlign='center'>
                        <Button variant="contained" onClick={onButtonClick}> {t('form.buttons.refresh')}</Button>
                    </Grid>
                </Grid>
            }
        </Grid>
    );

};
export default QrMediaCard;
