import React from 'react';
import { Avatar, Button, Grid, Theme, Typography, useTheme } from '@mui/material'; 
import BaseBox from '@/componentsMui/Shared/Widgets/Box/BaseBox'; 
import { ReactComponent as MuiArrowCircle } from '@/assets/MuiArrowCircle.svg';
import { useTranslation } from 'react-i18next';
import MuiTextAccountBoxHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAccountBoxHeader';
import { Employee } from '@/features/employee/types';
import { approveEmployee, getEmployeeInitials, rejectEmployee } from '@/features/employee/slice';
import _ from 'lodash';
import { showException } from '@/features/swal/slice';

interface Props {
	employee: Employee,
	fetchData: () => void,
}

const PendingEmployeeLine = ({ employee, fetchData }: Props): React.ReactElement => {
	const theme = useTheme() as Theme;
	const { t } = useTranslation('translations');

	const declinePendingEmployee = async () => {
		try {
			await rejectEmployee(employee);
			fetchData();
		}
		catch (e) {
			showException(e);
		}
	};

	const approvePendingEmployee = async () => {
		try {
			await approveEmployee(employee);
			fetchData();
		}
		catch (e) {
			showException(e);
		}
	};

	return (
		<BaseBox sx={{ my: '0.5rem', p: '1rem' }}
				 borderRadius='10px'
				 width='100%'>
			<Grid
				item
				xs={12}
				container
				alignItems="center"
				columnSpacing={1}
				wrap='nowrap'
			>
				<Grid item justifyContent='flex-start' alignItems='center' sx={{mr: {xs: 0, sm: '1rem'}, display: {xs: 'none', sm: 'block'}}}>
					<Avatar sx={{ width: '3rem', height: '3rem' }} >
						{getEmployeeInitials(employee)}
					</Avatar>
				</Grid>
				<Grid item
					  sx={{
						  display: 'flex',
						  flexDirection: 'column',
					  }}
					  justifyContent='space-evenly'
					  maxWidth='100%'
					  minWidth='0'
				>
					<Grid item xs={12}>
						<Grid container alignItems="baseline" flexWrap='nowrap'>
							<Typography fontSize='1.25rem' fontWeight={700} pr='1rem' variant='body1' color='text.primary' noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}>
								{`${_.capitalize(employee.firstName)} ${_.capitalize(employee.lastName)}`}
							</Typography>
							<Grid item >
								<MuiArrowCircle style={{ fill: theme.statusColor.error }} />
							</Grid>
						</Grid>
						<Grid container alignItems="baseline" flexWrap='nowrap'>
							<Typography fontSize='0.875rem' color='text.secondary' noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}>
								{employee.role === 'ACCOUNTANT' ? t('employee.active.admin') : t('employee.active.employee')}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item ml='auto' pl={6}
					  justifyContent='space-evenly'
					  alignItems="center"
					  flexDirection={'row'}
					  display={'flex'}
				>
					<Grid item xs={12} marginRight="5px">
						<Button
							onClick={approvePendingEmployee}
							variant='statusActive'
							sx={{minWidth: {xs: '90px', md: '120px'}}}
						>
							<MuiTextAccountBoxHeader variant='inherit'>
								{t('employee.pending.accept')}
							</MuiTextAccountBoxHeader>
						</Button>
					</Grid>
					<Grid item xs={12} marginRight="5px">
						<Button
							onClick={declinePendingEmployee}
							variant='statusWarn'
							sx={{minWidth: {xs: '90px', md: '120px'}}}
						>
							<MuiTextAccountBoxHeader variant='inherit'>
								{t('employee.pending.decline')}
							</MuiTextAccountBoxHeader>
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</BaseBox>
	);
};

export default PendingEmployeeLine;
