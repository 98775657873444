import * as React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import '../VaultList.css';

interface Props {
	hideAutoExtend?: boolean;
}

const VaultAllDepositsHeader = ({ hideAutoExtend }: Props) => {
	const { t } = useTranslation('translations');
	return (
		<Grid container mb={1} alignItems='center' >
			<Grid item className='vault-deposits-list__time'>
				<MuiTextCaption color='text.secondary'>
					{t('vaults.allDeposits.time')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='vault-deposits-list__plan'>
				<MuiTextCaption color='text.secondary'>
					{t('vaults.allDeposits.plan')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='vault-deposits-list__apr'>
				<MuiTextCaption color='text.secondary'>
					{t('vaults.allDeposits.apr')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='vault-deposits-list__duration'>
				<MuiTextCaption color='text.secondary'>
					{t('vaults.allDeposits.duration')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='vault-deposits-list__asset'>
				<MuiTextCaption color='text.secondary'>
					{t('vaults.allDeposits.asset')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='vault-deposits-list__amount'>
				<MuiTextCaption color='text.secondary'>
					{t('vaults.allDeposits.amount')}
				</MuiTextCaption>
			</Grid>
			{!hideAutoExtend && <Grid item className='vault-deposits-list__autoSubscribe'>
				<MuiTextCaption color='text.secondary'>
					{t('vaults.allDeposits.autoSubscribe')}
				</MuiTextCaption>
			</Grid>}
			<Grid item className='vault-deposits-list__repayment'>
				<MuiTextCaption textAlign='right' color='text.secondary'>
					{t('vaults.allDeposits.repaymentDate')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='vault-deposits-list__request'>
				<MuiTextCaption textAlign='right' color='text.secondary'>
					{t('vaults.allDeposits.planChange')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='vault-deposits-list__status'>
				<MuiTextCaption textAlign='right' color='text.secondary'>
					{t('vaults.allDeposits.status')}
				</MuiTextCaption>
			</Grid>
		</Grid>);
};

export default VaultAllDepositsHeader;
