import * as React from 'react';

import { Button, Dialog, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { openForm } from '@/features/forms/slice';
import { useDispatch } from 'react-redux';
import FormPaperLargeFixed from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeFixed';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import FormContentBox from '@/componentsMui/Shared/Widgets/Box/FormContentBox';
import { verifyEmail } from '@/features/user/userSlice';
import { getRecaptureToken } from '@/helpers/recaptureUtils';
import { useEmail } from '@/helpers/customHook/useValidations';

const VerifyEmail = () => {
    const { t } = useTranslation('translations');
    const dispatch = useDispatch();

    const closeModal = useCloseDialogNoEvent();

    useEffect(() => {
        dispatch(openForm());
    }, [dispatch]);

    const initialValues = {
        phoneCode: '',
        phoneNumber: ''
    };

    const emailValidation = useEmail();

    const validationSchema = Yup.object({
        email: emailValidation
    });

    const submit = async (formData, formikProps) => {

        const { setSubmitting, setErrors } = formikProps;
        const { email } = formData;

        try {
            setSubmitting(true);
            const recaptchaToken = await getRecaptureToken();
            await verifyEmail(email, recaptchaToken);
            setSubmitting(false);
            closeModal();
        } catch (err: any) {
            setSubmitting(false);
            const data = err?.data;
            const duplicateEmail = data?.errors?.find(error => error.error === 'duplicate' && error.error_param === 'email') || false;
            const invalidEmail = data?.errors?.find(error => error.error === 'invalid' && error.error_param === 'email') || false;

            if (duplicateEmail) {
                setErrors({ 'email': t('errors.DUPLICATE_EMAIL_NUMBER') });
            } else if (invalidEmail) {
                setErrors({ 'email': t('errors.INVALID_EMAIL') });
            }
        }
    };


    return (
        <Dialog
            open={true}
            onClose={closeModal}
            PaperComponent={FormPaperLargeFixed}
        >
            <Grid
                container
                flex={1}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={submit}
                    validateOnChange={true}
                    validateOnBlur={true}
                    enableReinitialize={true}
                >
                    {({ errors, isSubmitting }) => {
                        return (
                            <Form style={{ width: '100%' }} autoComplete='off' className='form-content__wrap__bb'>
                                <MuiHeader mb={4}>{t('cards.verifyEmail.formTitle')}</MuiHeader>
                                <FormContentBox noBackground={true} className='form-content_main-area wp' >
                                    <Grid container rowSpacing={1}>
                                        <MuiTextField
                                            name="email"
                                            fullWidth
                                            label={t('form.fields.email')}
                                            inputProps={{ maxLength: 255 }}
                                            InputProps={{
                                                autoComplete: 'new-email',
                                            }}
                                        />
                                    </Grid>
                                    <Grid container className='form-content_botton-area' justifyContent='space-between'>
                                        <Button
                                            key="buttonCancel"
                                            className='customDialogButton'
                                            variant='customOutlined'
                                            onClick={closeModal}
                                        >   {t('form.buttons.close')}
                                        </Button>
                                        <Button
                                            key="buttonNext"
                                            className='customDialogButton'
                                            variant="contained"
                                            color="primary"
                                            disabled={isSubmitting || !isEmpty(errors)}
                                            type='submit'
                                        >
                                            {t('createaccounttab.btn')}
                                        </Button>
                                    </Grid>
                                </FormContentBox>
                            </Form>
                        );
                    }}
                </Formik>
            </Grid>
        </Dialog >
    );
};


export default VerifyEmail; 
