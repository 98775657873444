import TokenAvatarLine from '@/componentsMui/Shared/Widgets/Avatars/TokenAvatarLine';
import FormPaperLarge from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLarge';
import SearchField from '@/componentsMui/Shared/Widgets/SearchField';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import { AssetType } from '@/features/coins/types';
import { Box, Dialog, Grid, IconButton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormCloseButton from '../Deposit/Components/FormCloseButton';
import FormHeader from '../Deposit/Components/FormHeader';
import { ReactComponent as MuiChevronRight } from '@/assets/MuiChevronRight.svg';

interface Props {
    assets: Array<AssetType>,
    open: boolean,
    onClose: (asset?: AssetType, type?: 'FROM' | 'TO') => void,
    type: 'FROM' | 'TO'
}


const AssetSelectDialog = ({ assets, open, onClose, type }: Props) => {
    const { t } = useTranslation('translations');
    const [search, setSearch] = React.useState<string>('');
    const handleSearchRequest = (e) => {
        setSearch(e.target.value);
    };

    const filteredTokens = search === '' ? assets : assets.filter(t => t.symbol.toUpperCase().includes(search.toUpperCase()) || t.displayName.toUpperCase().includes(search.toUpperCase()));

    return (
        <Dialog
            id="select-asset-form"
            open={open}
            PaperComponent={FormPaperLarge}
        >
            <Grid id='deposit-select-asset'
                container
                flex={1}
                direction='column'
                minHeight='0'
                flexWrap='nowrap'
                rowSpacing={2}>
                <Grid item >
                    <FormHeader title={t('wallet.deposit.selectAsset')} onBack={() => onClose()} />
                </Grid>
                <Grid item>
                    <SearchField value={search} fullWidth
                        onChange={(e) => handleSearchRequest(e)} />
                </Grid>
                {filteredTokens.length > 0 ? <>
                        <Grid item overflow='auto'>
                            <Box mr='1rem'>
                                {filteredTokens.map((item) => (
                                    <Grid key={`${item.type}-${item.symbol}`}
                                          maxHeight='20rem'
                                          onClick={() => onClose(item, type)}
                                          alignItems='center'
                                          container
                                          justifyContent='space-between'
                                          sx={{ cursor: 'pointer' }}>
                                        <Grid item>
                                            <TokenAvatarLine logo={item.logo} displayName={item.name} symbol={item.symbol} availableBalance={item.availableBalance} precision={item.precision} />
                                        </Grid>
                                        <Grid item>
                                            <IconButton    >
                                                <MuiChevronRight />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <StyledDivider />
                                        </Grid>
                                    </Grid>))
                                }
                            </Box>
                        </Grid>
                    </>
                    : <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}> {t('wallet.deposit.noRecordsToShow')}</div>
                }
                <FormCloseButton onClose={() => onClose()} />
            </ Grid >

        </Dialog>
    );
};

export default AssetSelectDialog;
