import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';

class ErrorBoundary extends React.Component {
    state = { error: null, errorInfo: null };

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        this.props.history.push('/wallet/error');
    };


    render() {
        /* if (this.state.errorInfo) {  
             return (<div>Something went wrong</div>); 
        }
 */
        return this.props.children;
    }
}


export default withRouter(ErrorBoundary);