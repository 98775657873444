import React from 'react';

import { Grid } from '@mui/material';
import BackMobileButton from '@/componentsMui/Shared/Widgets/BackMobileButton';


interface Props {
	onClick?: () => void
}

const BackButtonMobileLanding = ({ onClick }: Props) => {
	return (
		<Grid item sx={{ display: { xs: 'block', sm: 'none' } }} alignContent='flex-start'>
			<BackMobileButton
				onClick={onClick}
			/>
		</Grid>
	);
};

export default BackButtonMobileLanding;
