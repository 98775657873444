import React, { useMemo, useState } from 'react';
import { Grid, IconButton, ListItemIcon, ListItemText, MenuList, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import StyledMenuItemModal from '@/componentsMui/Shared/Widgets/MenuItem/StyledMenuItemModal';
import { findPaymentTypes, findProcessByType } from '@/features/operations/slice';
import { isEmpty } from 'lodash';
import '@/componentsMui/Shared/Shared.css';
import { ReactComponent as MuiWallet } from '@/assets/MuiWallet.svg';
import StyledListItemIcon from '@/componentsMui/Shared/Widgets/StyledListItemIcon';
import { useTranslation } from 'react-i18next';
import { CoinType, CoinTypeEnum } from '@/features/coins/types';
import { supportedSystemType } from '@/componentsMui/Banking/Transfer/SendMoney/SendMoneyForm';
import FormHeader from '../Deposit/Components/FormHeader';
import { ReactComponent as MuiAccountFolderCollection } from '@/assets/MuiAccountFolderCollection.svg';
import { ReactComponent as MuiBigContacts } from '@/assets/MuiBigContacts.svg';
import NewPaymentSteps from '@shared/Components/SendMoney/NewPayment/NewPaymentSteps';
import BetweenAccountsSteps from '@shared/Components/SendMoney/BetweenAccounts/BetweenAccountsSteps';
import { RootState } from '@/rootReducer';
import { AccountType } from '@/features/operations/types';
import UserPaymentSteps from '@/componentsMui/Shared/Components/SendMoney/PaymentToOtherUser/UserPaymentSteps';
import { BankAccount } from '@/features/account/types';
import Withdraw from './Withdraw';
import { Contact } from '@features/contacts/types';
import MaintenanceChip from '@/componentsMui/Shared/Widgets/MaintenanceChip';
import { systemTypesUINames } from '@/helpers/globalTypes';

enum AssetSentEnum {
	WITHDRAWAL = 'WITHDRAWAL',
	PAYMENT = 'PAYMENT',
	OWN_TRANSFER = 'OWN_TRANSFER',
	USER_TRANSFER = 'USER_TRANSFER',
}

interface Props {
	coin: CoinType,
	onBack: () => void
	defaultContact?: Contact,
}
const AssetSendOptions = ({ coin, onBack, defaultContact }: Props): React.ReactElement => {

	const closeModal = useCloseDialogNoEvent();
	const { t } = useTranslation('translations');
	const allAccounts = useSelector((state: RootState) => state.accounts.list); 

	const [subProcess, setSubProcess] = useState<any>(null);
	const [transferType, setTransferType] = useState<AssetSentEnum>(coin.type === CoinTypeEnum.WALLET ? null : AssetSentEnum.WITHDRAWAL);
	const [stepLocal, setStepLocal] = React.useState<number>(1);
	const nextStepLocal = () => {
		setStepLocal(previousState => (previousState + 1));
	};
	// const prevStepLocal = () => {
	// 	setStepLocal(previousState => (previousState - 1));
	// };
	const coinAccount = coin?.account ? { ...coin.account, subProcesses: coin.subProcesses, currencyLogo: coin.info.logo, tokenId: coin.info.id } : undefined;
	const fromAccountList = coinAccount ? [coinAccount] as unknown as BankAccount[] : [] as unknown as BankAccount[];

	const defaultFromAccount = coinAccount;
	const defaultToAccount = allAccounts?.filter(p => p.type === AccountType.BANK || p.type === AccountType.BANK_AND_CARD).find(accounts => accounts?.currency === coinAccount?.currency) ?? undefined;

	const paymentProc = findProcessByType(coin, 'PAYMENT');
	const transferProc = findProcessByType(coin, 'TRANSFER');
	const ownTransferProc = findProcessByType(coin, 'OWN_TRANSFER');

	const paymentDown = paymentProc?.maintenance;
	const transferDown = transferProc?.maintenance;
	const ownTransferDown = ownTransferProc?.maintenance;


	const isCryptoWithdrawalEnabled = coin.type === CoinTypeEnum.CRYPTO && !isEmpty(paymentProc);
	const isOwnTransferEnabled = coin.type === CoinTypeEnum.WALLET && coin?.subProcesses && !isEmpty(ownTransferProc) && defaultToAccount;
	const isTransferToOtherUserEnabled = !isEmpty(transferProc);

	const paymentProcessList = coin.type === CoinTypeEnum.WALLET ? findPaymentTypes(coin).filter(p => supportedSystemType.includes(p.processProperties?.paymentType)) || [] : [];
	const isPaymentEnabled = paymentProcessList?.length > 0;


	const handleMenuClick = (subProcesses: any, transferType: AssetSentEnum) => {
		setSubProcess(subProcesses);
		setTransferType(transferType);
		nextStepLocal();
	};

	const assetSymbol = coin.info.symbol;
	// const defaultFromAccount = fromAccountList[0] ? fromAccountList[0] : undefined;

	const ownTransfeToList = useMemo(
		() => allAccounts
			.filter(p => p.type === AccountType.BANK || p.type === AccountType.BANK_AND_CARD) || [], [allAccounts]);

	const renderMenuLine = (title: string, currency: string) => {
		return (
			<ListItemText className='money-form-list-item-text'>
				<Grid container alignItems='baseline' className='money-form-list-item-text'>{title}
					<Typography className='currency'> {currency ?? ''} </Typography>
				</Grid>
			</ListItemText>);
	};

	if (stepLocal === 1)
		return (<>
			<Grid item container justifyContent='space-between'>
				<Grid item>
					<FormHeader title={t('wallet.send.title')} onBack={onBack}
						showBack={true} />
				</Grid>
				<Grid item>
					<IconButton onClick={closeModal}  >
						<MuiCloseIcon />
					</IconButton>
				</Grid>
			</Grid>

			<Grid item >
				<MenuList role='menu' className='form-menu__list'>
					{isCryptoWithdrawalEnabled &&
						< StyledMenuItemModal
							unsetMarginBottom={true}
							key='withdraw'
							disabled={paymentProc?.maintenance}
							onClick={() => { handleMenuClick(paymentProc, AssetSentEnum.WITHDRAWAL); }}
						>  	<StyledListItemIcon>   <MuiWallet stroke='transparent' />
							</StyledListItemIcon>
							{renderMenuLine(t('wallet.send.withdraw'), assetSymbol)}
							{paymentDown && <MaintenanceChip />}
						</StyledMenuItemModal>
					}
					{isPaymentEnabled && paymentProcessList?.map(p => {

						const down = p?.maintenance;
						return (
							<StyledMenuItemModal
								unsetMarginBottom={true}
								id={p.proc}
								disabled={down}
								key={p.proc + '-' + p.processProperties?.paymentType}
								onClick={() => { handleMenuClick(p, AssetSentEnum.PAYMENT); }}
							> 	<ListItemIcon sx={{ mr: '1.25rem' }}> <img className='img-icon-size' alt='currency logo' src={coin.info.logo} /> </ListItemIcon>
								{renderMenuLine(`${systemTypesUINames[p.processProperties?.paymentType] ?? ''} ${t('sendmoneyTranslation.data.transfer')}`, assetSymbol)}
								{down && <MaintenanceChip />}
							</StyledMenuItemModal>);
					})
					}
					{isOwnTransferEnabled &&
						<StyledMenuItemModal
							unsetMarginBottom={true}
							key='ownAccount'
							disabled={ownTransferDown}
							onClick={() => { handleMenuClick(ownTransferProc, AssetSentEnum.OWN_TRANSFER); }}
						>
							<StyledListItemIcon><MuiAccountFolderCollection /></StyledListItemIcon>
							{renderMenuLine(t('sendmoneyTranslation.data.transferToMain'), assetSymbol)}
							{ownTransferDown && <MaintenanceChip />}
						</StyledMenuItemModal>
					}
					{isTransferToOtherUserEnabled &&
						<StyledMenuItemModal
							unsetMarginBottom={true}
							id='otherUser'
							disabled={transferDown}
							onClick={() => { handleMenuClick(transferProc, AssetSentEnum.USER_TRANSFER); }}
						>
							<StyledListItemIcon className='menu-icon-stroke-size'> <MuiBigContacts stroke='#959799' strokeWidth={3} />  </StyledListItemIcon>
							{renderMenuLine(t('sendmoneyTranslation.data.cospayuserheader'), assetSymbol)}
							{transferDown && <MaintenanceChip />}
						</StyledMenuItemModal>
					}
				</MenuList>
			</Grid >
		</>);

	if (stepLocal === 2)
		return (
			<>
				{transferType === AssetSentEnum.PAYMENT &&
					<NewPaymentSteps
						step={1}
						accountList={fromAccountList}
						defaultAccount={defaultFromAccount}
						paymentName={`${systemTypesUINames[subProcess?.processProperties?.paymentType] ?? ''} ${t('sendmoneyTranslation.data.transfer')}`}
						integration={subProcess?.integration}
						paymentType={subProcess?.processProperties?.paymentType}
						prevStep={() => setStepLocal(1)}
					/>
				}
				{transferType === AssetSentEnum.OWN_TRANSFER &&
					<BetweenAccountsSteps
						step={1}
						title={t('sendmoneyTranslation.data.transferToMain')}
						fromDefaultAccount={defaultFromAccount}
						toDefaultAccount={defaultToAccount}
						fromAccountList={fromAccountList}
						toAccountList={ownTransfeToList}
						noBackground={true}
						prevStep={() => setStepLocal(1)}
					/>}
				{transferType === AssetSentEnum.USER_TRANSFER &&
					<UserPaymentSteps
						step={1}
						fromAccountList={fromAccountList}
						defaultAccount={defaultFromAccount}
						defaultContact={defaultContact}
						prevStep={() => setStepLocal(1)}
					/>}
				{transferType === AssetSentEnum.WITHDRAWAL &&
					<Withdraw defaultCoin={coin} cointList={[coin]}
						onBack={() => setStepLocal(1)}
					/>}
			</ >
		);
	return (<></>);
};

export default AssetSendOptions;
