import { closeForm } from '@/features/forms/slice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useCloseDialog = () => {

	const history = useHistory();
	const dispatch = useDispatch();

	const closeModal = (e) => {
		
		//modalFormId used to  add blurred background in Dashboard for mozilla
		dispatch(closeForm());
		e.stopPropagation();
		history.goBack();
	};


	return closeModal;
};

export const useCloseDialogNoEvent = () => {

	const history = useHistory();
	const dispatch = useDispatch();

	const closeModal = () => {
		//modalFormId used to  add blurred background in Dashboard for mozilla
		dispatch(closeForm());
		history.goBack();
	};


	return closeModal;
};


export const useCloseDialogGoCards = () => {

	const history = useHistory();
	const dispatch = useDispatch();

	const closeModal = () => {
		//modalFormId used to  add blurred background in Dashboard for mozilla
		dispatch(closeForm()); 
		history.push('/wallet/home/cards/');
	};


	return closeModal;
};

export const useCloseDialogGoHome = () => {

	const history = useHistory();
	const dispatch = useDispatch();

	const closeModal = (e) => {
		dispatch(closeForm());
		e.stopPropagation();
		history.push('/wallet/home');
	};


	return closeModal;
};

export const useCloseDialogGoToAccounts = () => {

	const history = useHistory();
	const dispatch = useDispatch();

	const closeModal = (e) => {
		dispatch(closeForm());
		e.stopPropagation();
		history.push('/wallet/home/accounts?tab=accounts');
	};


	return closeModal;
};

export default useCloseDialog;
