export enum LandingStepEnum {
	CREATE_ACCOUNT = 'CREATE_ACCOUNT',
	TWOFA = 'TWOFA',
	CHOOSE_TO_VERIFY = 'CHOOSE_TO_VERIFY',
	IDENTITY = 'IDENTITY',
	KYC = 'KYC',
	PROFILE = 'PROFILE',
	POA = 'POA',
	NEURAL_ID = 'NEURAL_ID',
	PLACE_OF_BIRTH = 'PLACE_OF_BIRTH',
}

export interface LandingStepType {
	stepName: LandingStepEnum,
	stepOrder: number,
	status?: string,
}

export enum LoginStep {
	LOGIN = 1,
	TWOFA = 2
}
