export interface WalletHistoryStore {
	transactions: Array<WalletTransaction>;
	skip: number;
	take: number;
	search: string;
	type: WalletTransactionType | null;
}

export interface WalletHistoryPayload {
	from?: string;
	to?: string;
	search?: string;
	ccy?: string;
	skip?: number;
	take?: number;
	accountId?: string;
}

export interface WalletHistoryPairPayload {
	from?: string;
	to?: string;
	search?: string;
	ccy?: string;
	skip?: number;
	take?: number;
	accountId: string;
	toAccountId: string;
}

export interface WalletTransaction {
	transactionNumber: string;
	activity: string;
	amount: number;
	amountString: string;
	price: number;
	asset: string;
	currency: string;
	currencySymbol: string;
	date: Date;
	documentId: number;
	docNumber: string;
	status: string;
	txHash: string;
	scannerUrl: string;
	beneficiaryAccount: string;
	details: string;
	beforeChange: number;
	conversionCurrency: string;
	conversionAmount: number;
	fees?: number,
	// oppositeTransaction?: WalletTransaction,
	remitterName?: string,
	beneficiary?: string,
	// feeTransaction?: {
	// 	transactionNumber: string;
	// 	activity: string;
	// 	amount: number;
	// 	amountString: string,
	// 	asset: string,
	// 	currency: string;
	// 	currencySymbol: string;
	// 	date: Date;
	// 	documentId: number;
	// 	docNumber: string;
	// 	status: string;
	// 	details: string;
	// 	beforeChange: number;
	// 	beforeChangeString: string;
	// 	conversionCurrency: string;
	// 	conversionAmount: number;
	// 	conversionAmountString: string;
	// },
	systemFeeAmount?: number;
	systemFeeAmountString?: string;
	systemFeeCurrency?: string;
	isBaseCurrency?: boolean;
	displayDecimals?: number;
	decimals?: number;
}

export interface CoinWalletTransaction {
	activity: string;
	amount: number;
	currency: string;
	currencySymbol: string;
	date: Date;
	documentId: number;
	docNumber: string;
	status: string;
	orderType: string;
	conversionCurrency: string;
	conversionAmount: number;
}

export enum WalletTransactionType {
	TRANSACTIONS = 'TRANSACTIONS',
	CRYPTO_DEPOSITS = 'CRYPTO_DEPOSITS',
	CRYPTO_WITHDRAWALS = 'CRYPTO_WITHDRAWALS',
	CRYPTO_TRADES = 'CRYPTO_TRADES',
	TOKEN_DEPOSITS = 'TOKEN_DEPOSITS',
	TOKEN_WITHDRAWALS = 'TOKEN_WITHDRAWALS',
	TOKEN_TRADES = 'TOKEN_TRADES',
	TRADES = 'TRADES',
}
export enum WalletType {
	CRYPTO = 'CRYPTO',
	TOKEN = 'TOKEN',
}
