import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import SignIcon from './SignIcon';
import { Transaction } from '@/componentsMui/Transactions/TransactionStatements.spec';
import { TransactionType } from '@/features/transactions/types';
import { t } from '@/helpers/translate';

export const getAccountTrxName = (transaction: Transaction) => {
    const isOutgoing = transaction.amount < 0;

    if (!transaction?.activity) return '';

    switch (transaction.activity) {
        case TransactionType.PAYMENT:
        case TransactionType.PURCHASE:
        case TransactionType.TRANSFER:
            return isOutgoing ? transaction.beneficiary : transaction.remitterName;
        case TransactionType.OWN_TRANSFER:
            return transaction?.messageCode
                ? t('activity.' + transaction.messageCode)
                : t('activity.OWN_TRANSFER');
        default:
            return t('activity.' + transaction.activity);
    }
};

const TrxActivityCell = (params: GridRenderCellParams) => {
    const transaction = params.row;
    const name = getAccountTrxName(transaction);
    return (<>
        <SignIcon amount={transaction.amount} activity={transaction.activity} />
        <MuiFormText fontWeight={500} pl={2}>
            {name}
        </MuiFormText>

    </>);
};

export default TrxActivityCell;
