import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, Grid, IconButton, Paper, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { openForm } from '@/features/forms/slice';
import { useTranslation } from 'react-i18next';
import '@/componentsMui/Shared/Shared.css';
import '@/componentsMui/Shared/SharedFormLayout.css';
import { useLocation } from 'react-router-dom';
import { RootState } from '@/rootReducer';
import { KybSectionEnum, KybStatus, SectionStatus, SectionType } from '@/features/business/types';
import { getBusiness } from '@/features/business/slice';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import { withStyles } from '@mui/styles';
import './CreateBusinessForm.css';
import Documents from './Sections/Documents';
import BusinessDirectors from './Sections/BusinessDirectors';
import BusinessShareholders from './Sections/BusinessShareholders';
import BusinessTransfers from './Sections/BusinessTransfers';
import BusinessPartners from './Sections/BusinessPartners';
import StartValidation from './Sections/StartValidation';
import { ReactComponent as MuiArrowCircle } from '@/assets/MuiArrowCircle.svg';
import Information from './Sections/BusinessInformation';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';

const BusinessFormPaper = withStyles((theme) => ({
	root: {
		maxHeight: '90vh',
		minHeight: '90vh',
		minWidth: '50rem',
		maxWidth: '50rem',
		padding: '21px',
		background: theme.form.bgLight,
		['@media only screen and (max-height: 700px)']: {
			maxHeight: 'unset',
			minHeight: 'unset',
			minWidth: 'unset',
			maxWidth: '100%',
			width: '100%',
			height: '100%',
			padding: 0,
			margin: 0,
			borderRadius: 0,
		},
		['@media only screen and (max-width:800px)']: {
			maxHeight: 'unset',
			minHeight: 'unset',
			minWidth: 'unset',
			maxWidth: '100%',
			width: '100%',
			height: '100%',
			padding: 0,
			margin: 0,
			borderRadius: 0,
		},
	}
}))(Paper);


const CreateBusinessForm = (): React.ReactElement => {
	const smallWidth = useMediaQuery('(max-width:800px)');
	const smallHeight = useMediaQuery('(max-height: 600px)');
	const mobileView = smallWidth || smallHeight;
	const closeModal = useCloseDialogNoEvent();
	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const [section, setSection] = React.useState(KybSectionEnum.BUSINESS_INFORMATION);
	const location = useLocation();
	const theme = useTheme();
	const searchParams = new URLSearchParams(location.search);
	const [clientId, setClientId] = useState<string>(searchParams.get('client'));
	const { list, loading } = useSelector((state: RootState) => state.business);

	const currentBusiness = list.find(p => p.clientId === clientId) || null;
	const information = currentBusiness?.form?.find(p => p.name === KybSectionEnum.BUSINESS_INFORMATION) as SectionType;
	const documents = currentBusiness?.form?.find(p => p.name === KybSectionEnum.DOCUMENTS) as SectionType;
	const directors = currentBusiness?.form?.find(p => p.name === KybSectionEnum.DIRECTORS) as SectionType;
	const shareholders = currentBusiness?.form?.find(p => p.name === KybSectionEnum.SHAREHOLDERS) as SectionType;
	const partners = currentBusiness?.form?.find(p => p.name === KybSectionEnum.PARTNERS) as SectionType;
	const transfers = currentBusiness?.form?.find(p => p.name === KybSectionEnum.TRANSFERS) as SectionType;

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);

	useEffect(() => {
		if (section === KybSectionEnum.SUBMIT) return;
		dispatch(getBusiness());
	}, [dispatch, section]);



	const handleChange = (event: React.SyntheticEvent, newValue: KybSectionEnum) => {
		setSection(newValue);
	};

	const getStatusColor = (status: SectionStatus) => {
		if (status === SectionStatus.APPROVED) { return theme.statusColor.success; }
		if (status === SectionStatus.REJECTED) { return theme.statusColor.error; }
		return theme.statusColor.warning;
	};

	const getIcon = (section: any) => {
		return section?.status && section?.status !== SectionStatus.PENDING ? <MuiArrowCircle style={{ fill: getStatusColor(section?.status) }} /> : <></>;
	};

	const handleClose = () => {
		dispatch(getBusiness());
		closeModal();
	};


	return (
		<Dialog
			id="create-business-form"
			open={true}
			onClose={handleClose}
			PaperComponent={BusinessFormPaper}
			maxWidth="md"
		>
			<DialogActions >
				<IconButton onClick={handleClose}  >
					<MuiCloseIcon />
				</IconButton>
			</DialogActions>
			<Grid container>
				<Tabs value={section}
					onChange={handleChange}
					allowScrollButtonsMobile={mobileView}
					variant="scrollable"
					scrollButtons="auto">
					<Tab value={KybSectionEnum.BUSINESS_INFORMATION}
						label={t('createbusiness.tabs.' + KybSectionEnum.BUSINESS_INFORMATION)}
						icon={getIcon(information)}
						iconPosition='start'
					/>
					<Tab value={KybSectionEnum.DOCUMENTS}
						label={t('createbusiness.tabs.' + KybSectionEnum.DOCUMENTS)}
						icon={getIcon(documents)}
						iconPosition='start'
						disabled={!clientId || (!currentBusiness || currentBusiness?.kybStatus === KybStatus.CREATED)}
					/>
					<Tab value={KybSectionEnum.DIRECTORS}
						label={t('createbusiness.tabs.' + KybSectionEnum.DIRECTORS)}
						icon={getIcon(directors)}
						iconPosition='start'
						disabled={!clientId || (!currentBusiness || currentBusiness?.kybStatus === KybStatus.CREATED)} />
					<Tab value={KybSectionEnum.SHAREHOLDERS}
						label={t('createbusiness.tabs.' + KybSectionEnum.SHAREHOLDERS)}
						icon={getIcon(shareholders)}
						iconPosition='start'
						disabled={!clientId || (!currentBusiness || currentBusiness?.kybStatus === KybStatus.CREATED)} />
					<Tab value={KybSectionEnum.PARTNERS}
						label={t('createbusiness.tabs.' + KybSectionEnum.PARTNERS)}
						icon={getIcon(partners)}
						iconPosition='start'
						disabled={!clientId || (!currentBusiness || currentBusiness?.kybStatus === KybStatus.CREATED)} />
					<Tab value={KybSectionEnum.TRANSFERS}
						label={t('createbusiness.tabs.' + KybSectionEnum.TRANSFERS)}
						icon={getIcon(transfers)}
						iconPosition='start'
						disabled={!clientId || (!currentBusiness || currentBusiness?.kybStatus === KybStatus.CREATED)} />
					<Tab value={KybSectionEnum.SUBMIT}
						label={t('createbusiness.tabs.' + KybSectionEnum.SUBMIT)}
						disabled={!clientId || (!currentBusiness || currentBusiness?.kybStatus === KybStatus.CREATED)} />
				</Tabs>
			</Grid>
			<Grid container flexDirection='column' flexGrow={1} flexShrink={0} padding='21px'>
				{loading ? <Spinner /> :
					<>
						{section === KybSectionEnum.BUSINESS_INFORMATION &&
							<Information
								clientId={clientId}
								section={information}
								setClient={(value: string) => setClientId(value)}
								onNext={() => setSection(KybSectionEnum.DOCUMENTS)} />}
						{section === KybSectionEnum.DOCUMENTS &&
							<Documents
								clientId={clientId}
								section={documents}
								onNext={() => setSection(KybSectionEnum.DIRECTORS)}
								onBack={() => setSection(KybSectionEnum.BUSINESS_INFORMATION)}
							/>
						}
						{section === KybSectionEnum.DIRECTORS &&
							<BusinessDirectors
								clientId={clientId}
								section={directors}
								onNext={() => setSection(KybSectionEnum.SHAREHOLDERS)}
								onBack={() => setSection(KybSectionEnum.DOCUMENTS)}
							/>
						}
						{section === KybSectionEnum.SHAREHOLDERS &&
							<BusinessShareholders
								clientId={clientId}
								section={shareholders}
								onNext={() => setSection(KybSectionEnum.PARTNERS)}
								onBack={() => setSection(KybSectionEnum.DIRECTORS)}
							/>
						}
						{section === KybSectionEnum.PARTNERS &&
							<BusinessPartners
								clientId={clientId}
								section={partners}
								onNext={() => setSection(KybSectionEnum.TRANSFERS)}
								onBack={() => setSection(KybSectionEnum.SHAREHOLDERS)}
							/>
						}
						{section === KybSectionEnum.TRANSFERS &&
							<BusinessTransfers
								clientId={clientId}
								section={transfers}
								onNext={() => setSection(KybSectionEnum.SUBMIT)}
								onBack={() => setSection(KybSectionEnum.PARTNERS)}
							/>
						}
					</>
				}
				{section === KybSectionEnum.SUBMIT &&
					<StartValidation clientId={clientId} 
					setSection={setSection} 
					onBack={() => setSection(KybSectionEnum.TRANSFERS)}/>
				}
			</Grid>
		</Dialog >
	);
};

export default CreateBusinessForm;
