import React from 'react';
import MaintenanceBackgroundImage from '@/assets/MaintenanceBackgroundImage.png';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LogoThemed from './LogoThemed';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getMaintenance } from '@/features/maintenance/maintenanceSlice';  

const MaintenanceMode = () => {
    const { t } = useTranslation('translations');
    const history = useHistory();
    const dispatch = useDispatch();

    const handleReload = () => {
        dispatch(getMaintenance());
        history.push('/auth');
    };

    return (
        < Box
            style={{
                backgroundImage: `url(${MaintenanceBackgroundImage})`,
                width: '100vw',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPositionY: 'bottom',
                backgroundPositionX: 'center',
                textAlign: 'center',
            }
            }>
            <Grid container pt={{ sm: 8, xs: 4 }}>
                <Grid item xs={12} pb={{ sm: 12, xs: 4 }} >
                    <LogoThemed height='42px' />
                </Grid>
                <Grid item xs={12}>
                    <Typography fontSize={{ sm: '48px', xs: '26px' }} fontWeight={700} pb={4}>
                        {t('dashboard.maintenance.mode')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography fontSize={{ sm: '16px', xs: '14px' }} fontWeight={400} pb={{ sm: 8, xs: 2 }}  >
                        {t('dashboard.maintenance.soon')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button variant='customOutlined' onClick={handleReload}>
                        {t('form.buttons.reloadPage')}
                    </Button>
                </Grid>
            </Grid>
        </Box >
    );
};

export default MaintenanceMode;
