import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, } from '@mui/material';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import AccountBalance from './AccountBalance';
import ManagePassword from './ManagePassword';
import GoogleAuth from './ManageGoogleAuth';
import LockAccount from './LockAccount';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { getNeuralId } from '@/features/userNeuralid/sliceUserNeuralid';
import EnableNeuralSettings from './EnableNeuralSettings';

const MuiSecurity = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();

	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const neuralIdEnabled = useSelector((state: RootState) => state.status.neuralIdEnabled);

	useEffect(() => { dispatch(getNeuralId()); }, [dispatch]);

	return (
		<>
			<MuiHeader pb={'2.6rem'}>{t('security.header')}</MuiHeader>
			<Grid container alignItems='center' rowGap={2}>
				<Grid item xs={12}>
					<ManagePassword />
				</Grid>
				<Grid item xs={12}>
					<GoogleAuth user={user} />
				</Grid>
				<Grid item xs={12}>
					<AccountBalance user={user} />
				</Grid>
				<Grid item xs={12}>
					<LockAccount />
				</Grid>
				{neuralIdEnabled &&
					<Grid item xs={12}>
						<EnableNeuralSettings />
					</Grid>
				}
			</Grid>
		</>
	);
};

export default MuiSecurity;
