import * as React from 'react';
import { Autocomplete } from '@mui/material';
import { getNames } from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';
import MuiTextField from './MuiTextField';
import { useField } from 'formik';
import StyledPaper from '@/componentsMui/Shared/Widgets/StyledPaper';

interface Props {
	fieldName: string,
	fieldlabel?: string,
	onDropdownChange?: (value: any) => void,
	countryList?: any[] | any,
}


const CountryCodes = ({ fieldName, fieldlabel, onDropdownChange, countryList, ...otherProps }: Props) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField(fieldName);
	const { setValue } = helpers;
	const { value } = meta;


	const { t } = useTranslation(['translations']);
	const countries = getNames('en');
	const countryOptions = countryList?.length > 0 ? countryList.map(p => { return ({ label: countries[p], code: p }); }) : Object.keys(countries)?.map(p => { return ({ label: countries[p], code: p }); });


	const handleCountryChange = (event: any, newValue: any) => {
		setValue(newValue);
		onDropdownChange && onDropdownChange(newValue);
	};

	const configTextfield = {
		...field,
		...otherProps,
		error: false,
		helperText: null
	};


	if (meta && meta.touched && meta.error) {
		configTextfield.error = true;
		configTextfield.helperText = meta.error;
	}

	return (
		<Autocomplete
			id='country-list'
			options={countryOptions}
			value={value && Object.keys(value).length !== 0 ? value : ''}
			onChange={handleCountryChange}
			disableClearable
			fullWidth
			sx={{ width: '100%' }}
			isOptionEqualToValue={(option, value) => value === undefined || value === '' || option.code === value.code}
			PaperComponent={({ children }) => (
				<StyledPaper >{children}</StyledPaper>
			)}
			renderInput={(params) => <MuiTextField fullWidth {...configTextfield} name={fieldName} {...params} 
			label={fieldlabel ?? t('form.fields.country')}
			inputProps={{
				...params.inputProps,
				autoComplete: 'new-password', // disable autocomplete and autofill
			  }}
			/>}

		/>);
};


export default CountryCodes;
