import * as React from 'react';
import { useTheme } from '@mui/styles';
import Collapse from '@mui/material/Collapse';

import { ListItemIcon, ListItemText, MenuItem, Theme } from '@mui/material';
import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { closeDrawer } from '@features/drawer/slice';
import NavbarSubMenuItemNavLink from './NavbarSubMenuItemNavLink';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowDownIcon from
	'@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from
	'@mui/icons-material/KeyboardArrowUp';
import { getPathTabs } from '@helpers/tabs';
import { RootState } from '@/rootReducer';
interface Props {
	to: string,
	children: React.ReactNode,
}

/* Menu item for NavBar */
const CollapsibleMenuItemNavLink = ({ children, to }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const { pathname } = useLocation();
	const theme = useTheme() as Theme;
	const [open, setOpen] = useState(pathname.includes(to));
	const dispatch = useDispatch();
	const location = useLocation();
	const { user } = useSelector((state: RootState) => state.user);
	const { list } = useSelector((state: RootState) => state.cards);
	const bankList = useSelector((state: RootState) => state.accounts.list);

	const currentUrl = location.pathname + location.search;

	const tabs = getPathTabs(to, t, user, list, bankList) || [];

	return (
		<>
			<MenuItem onClick={() => setOpen(!open)} sx={{
				height: '35px',
				paddingTop: '0px',
				paddingBottom: '0px',
				margin: { xs: '2px 0', md: '2px 20px' },
				borderColor: 'transparent',
				borderRadius: theme.mainBorderRadius,
				'&:hover ': {
					'span': {
						color: theme.palette.text.primary,
					},
					'& .MuiSvgIcon-root': {
						fill: 'url(#svgIconGradient) ' + theme.highlight,
					},
					'& .MuiSvgIcon-root.stroke': {
						stroke: 'url(#svgIconGradient) ' + theme.highlight
					}
				},
				'&.active': {
					background: theme.backgroundBoxLevel1,
					'& .MuiSvgIcon-root': {
						fill: 'url(#svgIconGradient) ' + theme.highlight,
					},
					'& .MuiSvgIcon-root.stroke': {
						stroke: 'url(#svgIconGradient) ' + theme.highlight
					},
					'& .MuiTypography-root': {
						color: theme.palette.text.primary,
					},

				},
				'&.Mui-disabled': {
					opacity: 1,
				},
				'& .MuiSvgIcon-root': {
					color: theme.form.textSecondary,
					fontSize: '18px'
				},
				'& .MuiSvgIcon-root.stroke': {
					stroke: theme.form.textSecondary,
				},
				'& .MuiListItemIcon-root': {
					width: '18px',
					minWidth: '18px'
				},
				'& .MuiListItemText-root': {
					color: theme.form.textSecondary,
					'& .MuiTypography-root': {
						fontSize: '13px',
						fontWeight: 400,
						padding: '8px 11px'
					}
				},
			}} className={`${pathname.includes(to) ? 'active' : ''}`}>
				{children}
				<ListItemIcon>
					{open ? <KeyboardArrowUpIcon />
						: <KeyboardArrowDownIcon />}
				</ListItemIcon>
			</MenuItem>
			<Collapse in={open} timeout="auto"
				unmountOnExit>
				<div>
					{tabs?.length > 0 && tabs?.map((tab, key) => {
						const submenuUrl = `${to}?tab=${tab.key}`;
						return (
							<NavbarSubMenuItemNavLink
								isActive={() => currentUrl === submenuUrl}
								key={key} style={{ marginLeft: '30px' }}
								component={NavLink}
								to={submenuUrl} onClick={() => { dispatch(closeDrawer()); }
								}>
								<ListItemText>{tab.label}</ListItemText>
							</NavbarSubMenuItemNavLink>
						);
					})}
				</div>
			</Collapse >
		</>
	);
};

export default CollapsibleMenuItemNavLink;

