


import * as React from 'react';
import { SvgIcon, useTheme } from '@mui/material';

const SummaryCryptoIcon = (props: any) => {
    const theme = useTheme();

    return (
        <SvgIcon
            sx={{
                width: 64,
                height: 64,

            }}
            {...props}>
            <svg
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M14.9336 9.55469L17.636 7.90768L19.1552 5.33307L20.8022 7.90768L23.3768 9.55469L20.8022 11.0739L19.1552 13.7763L17.636 11.0739L14.9336 9.55469Z"
                    fill={theme.highlight} />
                <path d="M52.8516 14.9688L54.5406 13.9394L55.4901 12.3302L56.5195 13.9394L58.1286 14.9688L56.5195 15.9183L55.4901 17.6073L54.5406 15.9183L52.8516 14.9688Z"
                    fill={theme.highlight} />
                <path d="M6.23047 16.0234L7.58168 15.1999L8.34128 13.9126L9.16478 15.1999L10.4521 16.0234L9.16478 16.783L8.34128 18.1342L7.58168 16.783L6.23047 16.0234Z"
                    fill={theme.highlight} />
                <path fillRule="evenodd" clipRule="evenodd" d="M34.0329 15.8004C33.1047 15.2645 31.9611 15.2645 31.0329 15.8004L16.0195 24.4683C15.0913 25.0042 14.5195 25.9946 14.5195 27.0664V44.4023C14.5195 45.4741 15.0913 46.4645 16.0195 47.0004L31.0329 55.6683C31.9611 56.2042 33.1047 56.2042 34.0329 55.6683L49.0462 47.0004C49.9744 46.4645 50.5462 45.4741 50.5462 44.4023V27.0664C50.5462 25.9946 49.9744 25.0042 49.0462 24.4683L34.0329 15.8004ZM25.2804 34.3201C24.4994 35.1012 24.4994 36.3675 25.2804 37.1486L31.1133 42.9814C31.8943 43.7624 33.1606 43.7624 33.9417 42.9814L39.7745 37.1486C40.5556 36.3675 40.5556 35.1012 39.7745 34.3201L33.9417 28.4873C33.1606 27.7062 31.8943 27.7062 31.1133 28.4873L25.2804 34.3201Z"
                    fill="url(#paint0_linear_8521_171585)" />
                <defs>
                    <linearGradient id="paint0_linear_8521_171585" x1="23.2022" y1="6.58621" x2="90.1896" y2="30.8686" gradientUnits="userSpaceOnUse">
                        <stop stopColor={theme.iconGradient.stopColor1} />
                        <stop offset="1" stopColor={theme.iconGradient.stopColor2} />
                    </linearGradient>
                </defs>
            </svg>

        </SvgIcon>
    );
};
export default SummaryCryptoIcon;
