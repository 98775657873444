import * as React from 'react';

import { Alert, Button, Grid, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import { Employee, LinkedCards } from '@/features/employee/types';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { EmployeeDetailsStep } from './ActiveEmployeeForm';
import { unlinkCard } from '@/features/employee/slice';
import BaseBox from '@/componentsMui/Shared/Widgets/Box/BaseBox';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { getCurrencySign } from '@/features/fees/slice';
import { useHistory } from 'react-router-dom';
import { showException } from '@/features/swal/slice';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MuiFormHelperText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormHelperText';

interface Props {
	onClose: () => void,
	fetchData: () => void,
	setStep: (step: EmployeeDetailsStep) => void,
	employee: Employee;
	availableCards: any[],
}

const ActiveEmployeeMainStep = ({ onClose, employee, setStep, fetchData, availableCards }: Props) => {
	const { t } = useTranslation('translations');


	return (
		<>
			<Grid item container alignItems="center" pb={1}>
				<Grid item xs={12}>
					<FormHeader title={`${employee.firstName} ${employee.lastName}`} onBack={() => onClose()} mb='0' />
				</Grid>
			</Grid>
			<Grid item xs={12} pb={1}>
				<MuiFormHelperText sx={{ fontSize: '0.813rem', fontWeight: '700', lineHeight: '1.188rem' }}>{t('employee.activeEmployeeForm.mainHelperText')}</MuiFormHelperText>
			</Grid>
			{employee.linkedCards.length > 0 ?
				<Grid item xs={12}>
					{employee.linkedCards.map(card => <Card card={card} employee={employee} key={card.cardId} fetchData={fetchData} />)}
				</Grid> :
				<Grid item xs={12} mt='1rem' pb={1} >
					<Alert severity="warning"  >
						<MuiFormText >{t('employee.activeEmployeeForm.mainWarningText')}</ MuiFormText>
					</Alert>
				</Grid>
			}
			<Grid item xs={12}>
				<Button
					onClick={() => setStep(EmployeeDetailsStep.ADD_CARD)}
					variant='customOutlined'
					fullWidth
					disabled={availableCards.length === 0}
				>{t('employee.activeEmployeeForm.addNewCardBtn')}</Button>
			</Grid>
			<Grid item xs={12} flex={1} alignItems='flex-end' justifyContent='space-between' mt={8} display='flex'>
				<Button
					onClick={onClose}
					variant='customOutlined'
				>{t('form.buttons.close')}</Button>
				<Button
					onClick={() => setStep(EmployeeDetailsStep.UNLINK)}
					variant="contained"
				>
					{t('employee.activeEmployeeForm.deleteEmployeeBtn')}
				</Button>
			</Grid>
		</>
	);
};

interface CardProps {
	card: LinkedCards,
	employee: Employee,
	fetchData: () => void
}

const Card = ({ card, employee, fetchData }: CardProps) => {
	const history = useHistory();

	const onCardRemove = async () => {
		try {
			await unlinkCard(employee.employeeId, card.cardId);
			fetchData();
		} catch (e) {
			showException(e);
		}
	};

	return (
		<BaseBox padding='0.5rem' mb={1} >
			<Grid container alignItems='center'>
				<Grid item xs={2} display='flex' justifyContent='center' alignItems='center'><CreditCardIcon /></Grid>
				<Grid item xs={7}>
					<MuiFormText>{card.name}</MuiFormText>
					<MuiFormText>{`${card.number} (${getCurrencySign(card.currency)} ${card.balance})`}</MuiFormText></Grid>
				<Grid item xs={3} display='flex' justifyContent='flex-end'>
					<IconButton
						onClick={onCardRemove}
						sx={{ padding: '0' }}
					><DeleteIcon /></IconButton>
					<IconButton
						sx={{ padding: '0' }}
						onClick={() => history.push(`/wallet/home/cards?cardId=${card.cardId}`)}
					>
						<VisibilityIcon />
					</IconButton>

				</Grid>
			</Grid>
		</BaseBox>
	);
};

export default ActiveEmployeeMainStep; 
