import * as React from 'react';
import { tranformSubProcessByType } from '@/features/operations/slice';
import InactiveCardDigitsForm from './InactiveCardDigitsForm';
import InactiveCvvForm from './InactiveCvvForm';
import InactiveVfCodeForm from './InactiveVfCodeForm';
import { CardSubProcesses } from '@/features/card/types';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';

const InactiveForm = () => {
	const searchParams = new URLSearchParams(location.search);
	const [cardId] = React.useState<string>(searchParams.get('card'));
	const allCards = useSelector((state: RootState) => state.cards.list);
	const selectedCard = allCards.find(p => `${p.cardId}` === `${cardId}`);

	const cardDetail = selectedCard?.process;
	const cardProcesses = tranformSubProcessByType(cardDetail?.subProcesses);
	const activateProc = cardProcesses[CardSubProcesses.CARD_ACTIVATE]?.proc;
	const isDecta = cardDetail?.integration === 'DECTA';
	const isSH_Finance = cardDetail?.integration === 'SH_FINANCE';
	const isIntercash = cardDetail?.integration === 'INTERCASH_EU' || cardDetail?.integration === 'INTERCASH_LATAM';
	const isVirtual = cardDetail?.processProperties?.virtual === true;


	function getDecta() {
		return <InactiveCardDigitsForm cardId={cardId} isVirtual={isVirtual} operation={activateProc} />;
	}
	function getSH_Finance() {
		return <InactiveVfCodeForm cardId={cardId} isVirtual={isVirtual} operation={activateProc} cardStatus={selectedCard.status} />;
	}
	function getIntercash() {
		return <InactiveCvvForm cardId={cardId} isVirtual={isVirtual} operation={activateProc} />;
	}

	return (
		<>
			{isDecta ? getDecta() : null}
			{isSH_Finance ? getSH_Finance() : null}
			{isIntercash ? getIntercash() : null}
		</>
	);
};


export default InactiveForm; 
