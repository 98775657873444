import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import MuiFormLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiFormLabel';
import { useDispatch } from 'react-redux';
import { disable2FA } from '@/features/user/userSlice';
import GoogleAuthForm from './GoogleAuthForm';
import { User } from '@/features/user/types';

interface Props {
	user: User
}

const ManageGoogleAuth = ({ user }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();

	const [open, setOpen] = useState(false);

	const manage = async () => {
		if (user.twoFactorAuthEnabled) {
			dispatch(disable2FA());
		} else {
			setOpen(true);
		}
	};

	return (
		<Grid container alignItems='center' rowGap={2} sx={{ backgroundColor: '#1D2024',  padding: '30px', borderRadius: '10px' }}>
			<GoogleAuthForm open={open} onClose={() => setOpen(false)} />
			<Grid item xs={12} sm={8}>
				<MuiTextCaption fontSize='1.125rem'>{t('security.options.verification')}</MuiTextCaption>
				<MuiFormLabel color='text.secondary' fontSize='0.875rem'>{t('security.options.verificationDetails')}</MuiFormLabel>
			</Grid>
			<Grid item xs={12} sm={4} container sx={{justifyContent: { xs: 'flex-start', sm: 'flex-end' }}}>
				<Button variant='login' sx={{ minWidth: '12rem' }} onClick={manage} disableRipple>
					{t(user?.twoFactorAuthEnabled ? 'security.options.verificationDisable' : 'security.options.verificationAction')}
				</Button>
			</Grid>
		</Grid>

	);
};
export default ManageGoogleAuth;
