import React from 'react';
import { Avatar } from '@mui/material';
import './Avatars.css';

export enum TokenAvatarSizeEnum {
	small = 'small', medium = 'medium', large = 'large', regular = 'regular', normal = 'normal',
}


interface Props {
	logoSource: string,
	variant: TokenAvatarSizeEnum,
	symbol?: string,
	additionalClassName?: string
}

const TokenAvatar = ({ logoSource, variant, symbol, additionalClassName }: Props): React.ReactElement => {



	const stringAvatar = (name: string) => {
		return {
			children: name?.substring(0, 2).toUpperCase(),
		};
	};

	if (!logoSource) {
		return (<Avatar sx={{ bgcolor: 'text.secondary' }} className={`wallet avatar-rounded asset-avatar ${variant.toString()} ${additionalClassName??''}` } {...stringAvatar(symbol)} />);
	}

	return (
		<Avatar className={`wallet avatar-rounded asset-avatar ${variant.toString()} ${additionalClassName??''}`} src={logoSource} />
	);
};

export default TokenAvatar;
