import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, FormControlLabel, Grid } from '@mui/material';
import StyledCheckbox from '@shared/Widgets/StyledCheckbox';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { UiSectionEnum } from '@/features/disclaimer/disclaimerTypes';
import { getAcceptedDisclaimers, saveDisclaimerAcceptance } from '@/features/disclaimer/disclaimerSlice';



function CustodifyErnAnnouncement(): React.ReactElement {

	const code = 'CUSTODYFY_EARN';
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const [confirm1, setConfirm1] = useState(false);
	const [confirm2, setConfirm2] = useState(false);
	const { list, loading } = useSelector((state: RootState) => state.disclaimers);

	const accepted = list.some(p => p.disclaimerCode === code); 
 
	useEffect(() => {

		const save = async () => {
			 await saveDisclaimerAcceptance(code, UiSectionEnum.EARN);
			 dispatch(getAcceptedDisclaimers());
		};

		if (confirm1 && confirm2) {
			save(); 
		}

	}, [confirm1, confirm2, dispatch]);

	if (loading) return <></>;
	return (
		<Dialog
			open={!accepted}
			fullWidth
			maxWidth='md'
		>
			<DialogContent className='highlight-regular'>
				<h3>{t('announcement.earn.p1')}</h3>
				<p>{t('announcement.earn.p2')}</p>
				<Grid container rowGap={2} p={3}>
					<FormControlLabel
						control={<StyledCheckbox
							checked={confirm1}
							onChange={() => setConfirm1(!confirm1)} />}
						label={<MuiFormText>{t('announcement.earn.c1')} </MuiFormText>} />
					<FormControlLabel
						control={<StyledCheckbox
							checked={confirm2}
							onChange={() => setConfirm2(!confirm2)} />}
						label={<MuiFormText >{t('announcement.earn.c2')}</MuiFormText>} />
				</Grid>
			</DialogContent>
		</Dialog>
	);
}

export default CustodifyErnAnnouncement;
