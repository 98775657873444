/* eslint-disable react/prop-types */
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';

const SearchField: FC<TextFieldProps> = (props) => {
	const { t } = useTranslation('translations');
	return (
		<TextField
			placeholder={t('transaction.placeholder.search')}
			sx={{
				'.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-formControl': {
					height: '40px'
				}
			}}
			InputProps={{
				startAdornment: (
					<InputAdornment position='start'>
						<SearchIcon sx={{ fontSize: '17px' }} />
					</InputAdornment>
				),
			}}
			{...props}>
			{props.children}
		</TextField>
	);
};

export default SearchField;
