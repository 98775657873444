import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import './Login.css';
import { DeviceInfo } from '@/features/user/types';
import { useDispatch, useSelector } from 'react-redux';
import { LoginSteps } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import { getAllowedCountries } from '@/features/countries/slice';
import { getDeviceInfo } from '@/helpers/browser';
import { useTranslation } from 'react-i18next';
import '@/componentsMui/Shared/Shared.css';
import LoginMobile from './LoginMobile';
import LoginTabs from './LoginTabs';
import MinimalLayout from '@/componentsMui/Layout/MinimalLayout';
import { setLandingStepCount, setLandingTitle } from '@/features/landingStep/slice';
import LoginQRTabs from './LoginQRTabs';
import LoginIframe from './LoginIframe';

interface Props {

	isOauthLogin?: boolean,
	onCancel?: () => void
}

const Login = ({ isOauthLogin = false, onCancel }: Props) => {

	const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>(null);
	const dispatch = useDispatch();
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const { open, mobileConfimOpen } = useSelector(
		(state: RootState) => state.twoFA
	);
	const isIframe = typeof window !== 'undefined' && window.self !== window.top;
	const { v3PortalUrl } = useSelector((state: RootState) => state.status);

	useEffect(() => {
		dispatch(setLandingStepCount(0));
		dispatch(setLandingTitle(''));
	}, [dispatch]);

	useEffect(() => {
		async function getBrowserInfo() {
			setDeviceInfo(await getDeviceInfo());
		}
		getBrowserInfo();
	}, [dispatch]);

	const smallScreen = useMediaQuery('(max-width:600px)');
	const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

	const qrLoginDisabled = useSelector((state: RootState) => state.status.qrLoginDisabled);
	const [step, setLoginStep] = useState<LoginSteps>(null);


	const showQrLogin = !qrLoginDisabled && !smallScreen && !open && !mobileConfimOpen;

	const setStep = (newStep: LoginSteps) => {
		switch (newStep) {
			case LoginSteps.START:
				if (smallScreen) {
					!isIframe && !isOauthLogin && setLoginStep(LoginSteps.MOBILE);
				} else {
					setLoginStep(LoginSteps.LOGIN);
				}
				break;
			default:
				setLoginStep(newStep);
		}
	};

	// set intial step
	useEffect(() => {
		dispatch(getAllowedCountries());
		setStep(LoginSteps.START);
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [smallScreen]);

	if (step === LoginSteps.MOBILE) {
		return (
			<Box id='auth'>
				<Container component="main" sx={{
					justifyContent: 'space-around',
					display: 'flex',
					flexDirection: 'column',
					maxWidth: { xs: '100', sm: 'sm' },
					pt: { xs: 2, sm: 0 },
				}}>
					<Grid container itemScope>
						<LoginMobile deviceInfo={deviceInfo} setStep={setStep} />
					</Grid>
				</Container>
			</Box>
		);
	}

	if (isIframe && isOauthLogin) {
		return (<MinimalLayout width='100%' authorized={false} hideLogout={true}>
			<Grid container >
				<Box display='flex' padding='0px 16px' >
					<LoginIframe deviceInfo={deviceInfo} isOauthLogin={isOauthLogin} onCancel={onCancel} />
				</Box>
			</Grid>
		</MinimalLayout >);
	}


	return (<MinimalLayout width='100%' splitColors={showQrLogin} authorized={false} hideLogout={true}>
		<Grid container height='100%'>
			<Grid item width='100%' container
				p='2px'
				height='fit-content'
				sx={{
					background: '#31353D'
				}}
				py='10px'
				justifyContent='center' alignItems='center'>
				{smallScreen ? <>
					<Typography
						textAlign='center'
						fontWeight={500}
						fontSize='0.875rem'
						lineHeight='0.9rem'
					> {t('login.v3.urlVerification')}</Typography>

					<Typography
						textAlign='center'
						fontWeight={500}
						fontSize='0.875rem'
						lineHeight='0.9rem'
					>   {v3PortalUrl}</Typography>

				</> :
					<Typography
						textAlign='center'
						fontWeight={500}
						fontSize='0.875rem'
					> {t('login.v3.urlVerification')} {smallScreen && <br />} {v3PortalUrl}</Typography>
				}
			</Grid>
			<Grid item width={showQrLogin ? '50%' : '100%'} height='100%' container alignItems='center' justifyContent='center'>
				<Box
					height={matchDownSm ? 'inherit' : '575px'}
					display='flex'
					flexDirection='column'
					padding={matchDownSm ? '16px' : '0px'}
				>
					<LoginTabs deviceInfo={deviceInfo} isOauthLogin={isOauthLogin} onCancel={onCancel} />
				</Box>
			</Grid>
			{showQrLogin
				&&
				<Grid item width='50%' sx={{ bgcolor: '#1D2024' }} height='100%' container alignItems='center' justifyContent='center' >
					<Box
						height={matchDownSm ? 'inherit' : '575px'}
						display='flex'
						flexDirection='column'
					>
						<LoginQRTabs deviceInfo={deviceInfo} isOauthLogin={isOauthLogin} />
					</Box>
				</Grid>
			}
		</Grid>
	</MinimalLayout >);

};

export default Login;
