import { FormHelperText, FormHelperTextProps } from '@mui/material';
import React, { FC } from 'react';

const MuiFormField: FC<FormHelperTextProps> = (props: FormHelperTextProps) => {
	return (
		<FormHelperText
			style={{ position: 'relative', bottom: 'unset', top: 'unset' }}
			{...props}>
			{props.children}
		</FormHelperText >
	);
};

export default MuiFormField;
