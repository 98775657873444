import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { showException } from '@/features/swal/slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { getNotificationsPerPage, markNotification, setPageSize } from '@/features/tickets/slice';
import NotificationItem from './NotificationItem';
import MuiFormLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiFormLabel';
import { useTranslation } from 'react-i18next';
import { TicketMarkType, TicketNotification, TicketType } from '@/features/tickets/types';
import SendMoneyReplyForm from './SendMoney/SendMoneyReplyForm';
import { useHistory } from 'react-router-dom';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';
import { isKycTierVerified } from '@/helpers/kyc';

interface Props {
	closeDrawer?: () => void
}
const NotificationList = ({ closeDrawer }: Props) => {
	const ADD_ITEMS_ON_PAGE = 7;

	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const [isFetching, setIsFetching] = useState(false);
	const [openSendMoneyForm, setOpenSendMoneyForm] = useState<boolean>(false);
	const [notification, setNotification] = useState<TicketNotification>();
	const { list, count, pageSize, updateMessages, loading } = useSelector((state: RootState) => state.tickets);
	const { user } = useSelector((state: RootState) => state.user);
	const isVerified = isKycTierVerified(user);
	const itemsOnPage = pageSize + ADD_ITEMS_ON_PAGE;
	const ref = useRef(null);
	const history = useHistory();

	const fetchTicketList = useCallback((itemsToFetch) => {
		try {
			dispatch(getNotificationsPerPage(itemsToFetch));
		} catch (e) {
			showException(e);
		}
	}, [dispatch]);

	useEffect(() => {
		fetchTicketList(itemsOnPage);
	}, [fetchTicketList, itemsOnPage]);


	//update if new items
	useEffect(() => {
		if (updateMessages) {
			fetchTicketList(itemsOnPage);
		}
	}, [updateMessages, itemsOnPage, fetchTicketList, dispatch]);


	const handelMore = () => {
		if (isFetching) return;
		if (list.length < count) {
			dispatch(setPageSize(list.length + itemsOnPage));
		}
		setIsFetching(false);
	};

	const handleMessageClick = (ticket: TicketNotification) => {
		setNotification(ticket);
		dispatch(markNotification(TicketMarkType.READ, ticket.id));
		if (ticket.incidenttype === TicketType.TRANSFER_REQUEST) {
			setOpenSendMoneyForm(true);
		}
		if (ticket.incidenttype === TicketType.INCIDENT) {
			history.push('/wallet/support');
			history.push('/wallet/support/list?step=0');
			history.push(`/wallet/support/list?step=1&ticketId=${ticket.id}`);
			closeDrawer();
		}
		if (ticket.incidenttype === TicketType.NOTIFICATION && (ticket.translationcode === 'CHAT_MESSAGE_COMPLIANCE' || ticket.translationcode === 'CHAT_MESSAGE')) {
			history.push('/wallet/support');
			closeDrawer();
		}
	};

	const handleCloseSendMoney = () => { setOpenSendMoneyForm(false); closeDrawer(); };

	return (
		<Grid ref={ref} id='notification-list'>
			{notification &&
				<SendMoneyReplyForm
					notification={notification}
					onClose={handleCloseSendMoney}
					open={openSendMoneyForm} />
			}
			{list.length > 0 && list.map((item, index) => {
				const clickableItem = (item?.incidenttype === TicketType.TRANSFER_REQUEST || item?.incidenttype === TicketType.INCIDENT);

				return (
					<Grid key={item.id} item xs={12} style={{ cursor: clickableItem ? 'pointer' : '' }} sx={{ mb: list.length === index + 1 ? 0 : '12px' }}>
						<NotificationItem
							ticket={item}
							onClick={handleMessageClick}
							disabled={!isVerified}
						/>
					</Grid>

				);
			})}
			{count > list.length &&
				<Grid item xs={12} textAlign='center' onClick={handelMore} pt='1rem'>
					{loading ? <Spinner /> :
						<Button onClick={handelMore}>
							<MuiFormLabel variant='gradient'>{t('nofification.showOlder')}</MuiFormLabel>
						</Button>
					}
				</Grid>}
		</Grid>
	);
};

export default NotificationList;

