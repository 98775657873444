import React, { useEffect } from 'react';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import { CoinInfoType, CoinTypeEnum } from '@/features/coins/types';
import CoinSelect from '@/componentsMui/Shared/Widgets/CoinSelect';

interface Props {
	setCurrency?: (value: string) => void,
	defaultCurrency?: string,
	label?: string,
	variant?: 'standard' | 'outlined' | 'filled',
}


const TokenCurrencySwitch = ({ setCurrency, defaultCurrency, label, variant }: Props): React.ReactElement => {
	const list = useSelector((state: RootState) => state.tokens.list);

	const wallets = list.filter(p => p.type === CoinTypeEnum.TOKEN) || [];
	const fromAssetList = wallets?.map(p => p.info);
	const [fiat, setFiat] = React.useState<CoinInfoType>(defaultCurrency ? fromAssetList.find(p => p.symbol === defaultCurrency) : fromAssetList[0]);

	// fromAssetList.sort((a, b) => {
	// 	const c1 = (a.symbol === 'EUR' ? 1 : 0);
	// 	const c2 = (b.symbol === 'EUR' ? 1 : 0);
	// 	if (c1 > c2) { return -1; }
	// 	else { return 1; }
	// });

	useEffect(() => {
		setCurrency && setCurrency(fiat.symbol);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fiat]);


	const handleFiatChange = (value: CoinInfoType) => {
		setFiat(value);
		// setCurrency && setCurrency(value.symbol);
	};

	return (

		<CoinSelect
			fieldlabel={label}
			variant={variant}
			coinList={fromAssetList}
			selectedCoin={fiat}
			onCoinChange={(value: CoinInfoType) => handleFiatChange(value)}
		/>);
};

export default TokenCurrencySwitch;
