import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, } from '@mui/material';
import { ReactComponent as MuiUserInProgress } from '@/assets/MuiUserInProgress.svg';
import MuiFormText from './Shared/Widgets/MuiText/MuiFormText';


const AccountsKycPlaceholder = (): React.ReactElement => {
	const { t } = useTranslation('translations');

	return (
		<Grid container height='fit-content' textAlign='center'>
			<Grid item xs={12} mt='3rem'> <MuiUserInProgress /></Grid>
			<Grid item xs={12} mt='31px'>
				<MuiFormText>
					{t('landing.documentStatus.inprogress')}
				</MuiFormText>
			</Grid>
			<Grid item xs={12}>
				<MuiFormText>
					{t('landing.documentStatus.estimate')}
				</MuiFormText>
			</Grid>
		</Grid>
	);
};

export default AccountsKycPlaceholder;
