
import * as React from 'react';
import { useTheme } from '@mui/styles';
import { Box as MuiBox, Theme } from '@mui/material';

interface Props {
	children?: React.ReactNode,
	customPadding?: string
}


const ConfirmationBox = (props: Props) => {

	const theme = useTheme() as Theme;

	return (<MuiBox
		sx={{
			borderRadius: theme.inputField.borderRadius,
			background: theme.palette.background.paper,
			padding: {xs: props.customPadding??'1.25rem 1.25rem', sm: props.customPadding??'1.25rem 2.625rem'},
			margin: '5px 0px'
		}}
		{...props}
	>
		{props.children}
	</MuiBox>);
};


export default ConfirmationBox;
