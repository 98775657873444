import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Dialog, DialogContent, Grid, IconButton, Link } from '@mui/material';
import { cancelToken, cancelTwoFAMobile, close2FAMobileConfirm, changeToGoogleAuth } from '@/features/2fa/slice';
import { useTranslation } from 'react-i18next';
import LogoThemed from './Shared/Widgets/LogoThemed';
import MuiHeader from './Shared/Widgets/MuiText/MuiHeader';
import MuiFormText from './Shared/Widgets/MuiText/MuiFormText';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import FormPaperFullScreenMobile from './Shared/Widgets/Dialog/FormPaperFullScreenMobile';

const TwoFAMobile = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const { mobileConfimOpen, errorCode } = useSelector(
		(state: RootState) => state.twoFA
	);

	const brandName = t('brandName.brand');


	const { token } = useSelector(
		(state: RootState) => state.credentials
	);

	const handleCancel = async () => {
		try {
			dispatch(close2FAMobileConfirm());
			if (token) {
				await cancelTwoFAMobile();
			}
		}
		finally {
			cancelToken();
		}
	};

	const changeAuth = async () => {
		await dispatch(changeToGoogleAuth());
	};

	return (
		<Dialog
			open={mobileConfimOpen}
			maxWidth='xs'
			PaperComponent={FormPaperFullScreenMobile}
		>
			<Grid container justifyContent='flex-end' pt={1}  >
				<IconButton onClick={handleCancel}  >
					<MuiCloseIcon />
				</IconButton>
			</Grid>
			<DialogContent sx={{ padding: '0px 40px 40px 40px' }}>
				<Grid container justifyContent='center' spacing={3}>
					<Grid item xs={12} container justifyContent='center'>
						<LogoThemed style={{ height: '50px', width: 'auto' }} />
					</Grid>
					<Grid item xs={12} >
						<MuiHeader >
							{t('login.v3.codeConfirm.title', { brandName: brandName })}
						</MuiHeader>
					</Grid>
					<Grid item xs={12}>
						<MuiFormText lineHeight='1.375rem' >
							{t('login.v3.codeConfirm.details', { brandName: brandName })}
						</MuiFormText>
					</Grid>
					{errorCode === 'userCode_2faAuth' &&
						<Grid item xs={12} mt={{ xs: 1, sm: 5 }}>
							<Link variant='gradient' fontSize='0.875rem'
								onClick={changeAuth} >
								{t('login.v3.codeConfirm.linkText')}
							</Link>
						</Grid>
					}
				</Grid>
			</DialogContent>

		</Dialog>
	);
};

export default TwoFAMobile;
