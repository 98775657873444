import * as React from 'react';

import { Box, Dialog, Grid, IconButton } from '@mui/material';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import DialogContentNoScrollbar from '@/componentsMui/Shared/Widgets/Dialog/DialogContentNoScrollbar';
import { useTranslation } from 'react-i18next';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import DetailLine from '@shared/Widgets/DetailLine';
import DetailsBox from '@shared/Widgets/DetailsBox';
import { WalletTransaction, WalletTransactionType } from '@features/walletHistory/types';
import { convertDate } from '@helpers/date';
import NumberFormat from 'react-number-format';
import MuiTextCaption from '@shared/Widgets/MuiText/MuiTextCaption';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { TransactionStatus } from '@features/transactions/types';
import TransactionStatusBox from '@shared/Widgets/Box/TransactionStatusBox';
import StyledDivider from '@shared/Widgets/StyledDivider';
import MuiFormLabel from '@shared/Widgets/MuiText/MuiFormLabel';

interface Props {
	open: boolean,
	onClose: () => void,
	item: WalletTransaction,
	walletTransactionType: WalletTransactionType,
}

const WalletTrxDetailsForm = ({ open, onClose, item, walletTransactionType }: Props) => {
	const { t } = useTranslation('translations');

	if (!item) {
		return <></>;
	}

	const fullDestination = (walletTransactionType === WalletTransactionType.CRYPTO_WITHDRAWALS || walletTransactionType === WalletTransactionType.TOKEN_WITHDRAWALS) ? item.beneficiaryAccount : item.details;
	const shortDestination = fullDestination && fullDestination.length > 10 ? `${fullDestination.substr(0, 5)}...${fullDestination.substr(-5)}` : fullDestination;

	return (
		<Dialog
			id="account-details-form"
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
		>
			<DialogContentNoScrollbar>
				<Grid
					container
					sx={{ p: { xs: '1rem', md: '2rem' } }}
				>
					<Grid item container justifyContent="space-between" alignItems="center" pb={6}>
						<Grid item>
							<MuiHeader>{t('tokens.header.details')}</MuiHeader>
						</Grid>
						<Grid item>
							<IconButton onClick={onClose}  >
								<MuiCloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item container alignItems='center' spacing={2}>
						<Grid item xs={12} >
							<DetailsBox sx={{ p: { xs: '0.5rem 1rem', sm: '0.5rem 2.625rem' } }}>
								<DetailLine label={t('tokens.cryptoHistory.time')} value={convertDate(item.date, 'dd/MM/yyyy HH:mm:ss')} first />
								<DetailLine label={t('tokens.cryptoHistory.coin')} value={item.asset} />
								<DetailLine label={t('tokens.cryptoHistory.amount')} value={`${item.amount}`}>
									<MuiTextCaption>
										<NumberFormat displayType={'text'}
											decimalScale={5}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											suffix={` ${item.asset}`}
											value={item.amount} />
									</MuiTextCaption>
								</DetailLine>
								<DetailLine label={(walletTransactionType === WalletTransactionType.CRYPTO_WITHDRAWALS || walletTransactionType === WalletTransactionType.TOKEN_WITHDRAWALS) ? t('tokens.cryptoHistory.destination') : t('tokens.cryptoHistory.source')} value={fullDestination}>
									<Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
										<MuiTextCaption variant='gradient' fontWeight='400'>{shortDestination}</MuiTextCaption>
									</Box>
								</DetailLine>
								<DetailLine label={t('tokens.cryptoHistory.viewOnExplorer')}>
									{item.scannerUrl && item.txHash && <a style={{ textDecoration: 'unset' }} href={`${item.scannerUrl}${item.txHash}`} target='_blank' rel='noreferrer'>
										<MuiTextCaption variant='gradient' fontWeight='400'>
											{t('tokens.cryptoHistory.viewOnExplorer')}
											<IconButton>
												<OpenInNewIcon style={{ width: '1rem', height: '1rem' }} />
											</IconButton>
										</MuiTextCaption>
									</a>}
								</DetailLine>
								<Grid item xs={12}> <StyledDivider /> </Grid>

								<Grid container
									minHeight='3.2rem'
									py={1}
									alignItems='center'>
									<Grid item xs={12} container>
										<Grid item xs={4}>
											<MuiFormLabel color='text.secondary'>
												{t('tokens.cryptoHistory.status')}
											</MuiFormLabel>
										</Grid>
										<Grid container item xs={8} justifyContent='flex-end'>
											<TransactionStatusBox
												status={item.status as TransactionStatus}>
												{t('status.' + item.status)}
											</TransactionStatusBox>
										</Grid>
									</Grid>
								</Grid>
							</DetailsBox>
						</Grid>
					</Grid>
				</Grid>
			</DialogContentNoScrollbar>
		</Dialog >
	);
};

export default WalletTrxDetailsForm;
