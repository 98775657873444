import React from 'react';

import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import TokenAvatar, { TokenAvatarSizeEnum } from '@/componentsMui/Shared/Widgets/Avatars/TokenAvatar';

interface Props {
	symbol: string,
	logo: string,
	name: string,
}

const BigSymbol = ({ symbol, logo, name }: Props) => {

	const theme = useTheme();
	const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		smallScreen ?
			<Grid container alignItems='center' >
				<Grid item xs='auto' pr='0.5rem'>
					<TokenAvatar symbol={symbol} logoSource={logo} variant={TokenAvatarSizeEnum.small} />
				</Grid>
				<Grid item xs container alignItems='baseline'>
					<Typography pr='4px' fontSize='1rem' fontWeight={700} lineHeight='1.2rem'>{name} </Typography>
					<Typography pr='4px' fontSize='0.6rem' fontWeight={400} color='text.secondary' lineHeight='1.2rem'>{symbol}</Typography>
				</Grid>
			</Grid> :
			<Grid container alignItems='center' >
				<Grid item xs='auto' pr='0.5rem'>
					<TokenAvatar symbol={symbol} logoSource={logo} variant={TokenAvatarSizeEnum.regular} />
				</Grid>
				<Grid item xs container alignItems='baseline'>
					<Typography pr='4px' fontSize='1.5rem' fontWeight={700} lineHeight='1.75rem'>{name} </Typography>
					<Typography pr='4px' fontSize='0.875rem' fontWeight={400} color='text.secondary' lineHeight='1.75rem'>{symbol}</Typography>

				</Grid>
			</Grid>
	);
};

export default BigSymbol;
