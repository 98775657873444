import React from 'react';
import { InputAdornment, TextFieldProps } from '@mui/material';
import { useField } from 'formik';
import MuiTextField from './MuiTextField';
import NumberFormat from 'react-number-format';

type Props = {
	name: string;
	currencySign: string;
	label: string;
	onChange: (val: string) => void;
	value: string;
	disabled?: boolean;
} & TextFieldProps;

const MuiAmountField = ({
	name,
	currencySign,
	label,
	value,
	onChange,
	disabled,
	...otherProps
}: Props) => {
	const [field, meta] = useField(name);

	const configTextfield = {
		...field,
		...otherProps,
	};

	if (meta && meta.touched && meta.error) {
		configTextfield.error = true;
		configTextfield.helperText = meta.error;
	}


	return <NumberFormat
		name={name}
		onChange={event => {
			onChange(event.target.value as string);
		}}
		value={value}
		label={label}
		allowNegative={false}
		decimalScale={2}
		thousandsGroupStyle='thousand'
		thousandSeparator={true}
		InputProps={{
			startAdornment: <InputAdornment position="start">{currencySign}</InputAdornment>,
		}}
		helperText={meta && meta.touched && meta.error as string}
		error={meta && meta.touched && Boolean(meta.error)}
		customInput={MuiTextField}
		inputProps={{ autoComplete: 'off' }}
		displayType={'input'}
		fullWidth
		disabled={disabled}
	/>;
};

export default MuiAmountField;
