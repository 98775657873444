import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Theme, useMediaQuery } from '@mui/material';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import BaseBox from '@/componentsMui/Shared/Widgets/Box/BaseBox';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/styles';
import HelpTopics from './Components/HelpTopics';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Agents } from '@/helpers/globalTypes';

const LegacyFAQ = (): React.ReactElement => {
  const theme = useTheme() as Theme;
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('translations');
  const history = useHistory();

  const { status } = useSelector(
    (state: RootState) => state.user
  );

  const handleNewTicketOpen = () => {
    history.push('/wallet/support/new');
  };

  const handleMyTicketOpen = () => {
    history.push('/wallet/support/list?step=0');
  };

  const isDts = status.source === Agents.DTSCB || status.source === Agents.DTS || status.source === Agents.DTSUS;

  useEffect(() => { if (isDts) { window.open('https://dtcirclesupport.com', '_blank'); } }, [isDts]);

  return (isDts ?
    <> </> :
    <BaseBox id="my-account" sx={{
      background: { xs: 'none', md: theme.palette.mode === 'dark' ? theme.backgroundBoxLevel1 : theme.palette.background.paper },
      p: { xs: 0, md: '1.5rem' },
      width: '100%',
      mb: { xs: 3, md: 0 },
      mt: { xs: 0, md: 2 },
    }}>
      <Grid container>
        <Grid container item alignItems="center" sx={{ mb: { xs: '2rem', sm: '3rem' } }}>
          <Grid item xs={12} sm={4} sx={{ mb: { xs: '1rem', sm: 0 } }}>
            <MuiHeader>{t('faq.help')}</MuiHeader>
          </Grid>
          <Grid item xs={12} sm={8} container sx={{ justifyContent: { xs: 'space-between', sm: 'flex-end' } }}>
            <Button variant='transparent' sx={{ marginRight: '0.5rem' }}
              onClick={handleMyTicketOpen}>
              <MuiFormText fontWeight={700}>{t('faq.myTickets')}</MuiFormText>
            </Button>
            <Button variant='contained'
              onClick={handleNewTicketOpen}>
              <AddIcon style={{ width: '14px', height: 'auto' }}
                strokeWidth={1.5}
                stroke={theme.palette.primary.contrastText}
              />
              <MuiFormText fontWeight={700} pl='0.5rem'>{t('faq.submitTicket')}</MuiFormText>
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <HelpTopics onClick={handleNewTicketOpen} iconWidth={smallScreen ? '2rem' : undefined} />
        </Grid>
      </Grid>
    </BaseBox>
  );
};

export default LegacyFAQ;
