import * as React from 'react';

import { Avatar, Button, Chip, Dialog, Grid, IconButton } from '@mui/material';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import DialogContentNoScrollbar from '@/componentsMui/Shared/Widgets/Dialog/DialogContentNoScrollbar';
import { useTranslation } from 'react-i18next';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import MuiTextLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextLabel';
import { User, V3SwitchOption } from '@/features/user/types';
import { capitalCaseString } from '@/helpers/normalizeString';

interface Props {
	open: boolean,
	onClose: () => void,
	onChooseUser: (option: V3SwitchOption) => void,
	options: V3SwitchOption[];
	user: User
}

const SwitchUserForm = ({ open, onClose, onChooseUser, options, user }: Props) => {
	const { t } = useTranslation('translations');


	return (
		<Dialog
			id="switch-account-form"
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
		>
			<DialogContentNoScrollbar>
				<Grid
					container
					sx={{p: { xs: '1rem', md: '2rem' }}}
				>
					<Grid item container justifyContent="space-between" alignItems="center" pb={4}>
						<Grid item>
							<MuiHeader>{t('form.title.switch')}</MuiHeader>
						</Grid>
						<Grid item>
							<IconButton onClick={onClose}  >
								<MuiCloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item container spacing={2}>
						{options?.map((option, index) => (
							<Grid item key={index} xs={12} >
								<Button variant='simple' onClick={() => onChooseUser(option)} style={{ width: '100%' }}
										sx={{p: '1rem'}}
										disabled={option.disabled}
								>
									<Grid container alignItems='center'  wrap='nowrap'>
										<Grid item mr={2}>
											<Avatar sx={{ width: '3rem', height: '3rem' }}>
												{option.name.charAt(0)}
											</Avatar>
										</Grid>
										<Grid item mr={2}  minWidth='0'>
											{parseInt(user.onBehalfOf) === option.businessUserId
												?
												<MuiTextLabel noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }} color='text.primary'>{t('business.switch.private', { name: capitalCaseString(`${user.firstName} ${user.lastName}`) })}</MuiTextLabel>
												:
												<MuiTextLabel noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }} color='text.primary'>{option.clientId ? option.name : t('business.switch.admin', { name: option.name })}</MuiTextLabel>}
										</Grid>
										<Grid item ml='auto'>
											{parseInt(user.onBehalfOf) === option.businessUserId ?
												<Chip label={t('navbar.userType.' + user.type)} color="primary" />
												: <>
													{option?.type && <Chip label={t('navbar.userType.' + option?.type)} color="primary" />}
												</>}
										</Grid>
									</Grid>
								</Button>
							</Grid>
						))}
					</Grid>
				</Grid>
			</DialogContentNoScrollbar>
		</Dialog >
	);
};

export default SwitchUserForm; 
