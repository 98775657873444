import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import MobileDrawer from '../componentsMui/Navbar/MobileDrawer';
import Home from '../componentsMui/Dashboard/Home';
import './DashboardMui.css';
import '@/componentsMui/Shared/Shared.css';
import { Container, Grid, useMediaQuery } from '@mui/material';
import AppBarCustom from '@/componentsMui/AppBar/AppBarMain';
import useGetForm from '@/helpers/customHook/useGetForm';
import CreateAccountForm from '@/componentsMui/Dashboard/Create/CreateAccount/CreateAccountForm';
import SendMoneyForm from '@/componentsMui/Banking/Transfer/SendMoney/SendMoneyForm';
import TopUpForm from '@/componentsMui/Banking/Card/Topup/TopUpForm';
import CardSettingsForm from '@/componentsMui/Banking/Card/Settings/CardSettingsForm';
import RequestMoneyForm from '@/componentsMui/Banking/Transfer/RequestMoney/RequestMoneyForm';
import EditContactForm from '@/componentsMui/Dashboard/MyContacts/Edit/EditContactForm';
import CreateCardForm from '@/componentsMui/Dashboard/Create/Card/CreateCardForm';
import ReplaceCardForm from '@/componentsMui/Dashboard/Create/Card/ReplaceCardForm';
import InactiveForm from '@/componentsMui/Banking/Card/InactiveForm';
import DepositVaultForm from '@/componentsMui/Earn/Components/DepositVaultForm';
import { PolicyType } from '@/features/settings/type';
import Policy from '@/componentsMui/Legal/Policy';
import CryptoTradeForm from '@/componentsMui/Wallet/Balances/Trade/CryptoTradeForm';
import DepositForm from '@/componentsMui/Wallet/Balances/Deposit/DepositForm';
import WalletDepositForm from '@/componentsMui/Wallet/Balances/Deposit/WalletDepositForm';
import WithdrawForm from '@/componentsMui/Wallet/Balances/Withdraw/WithdrawForm';
import ReceiveForm from '@/componentsMui/Wallet/Tokens/ReceiveForm';
import AddMoneyForm from '@/componentsMui/Banking/Transfer/AddMoney/AddMoneyForm';
import SendMoneyToContactForm from '@/componentsMui/Banking/Transfer/SendMoney/SendMoneyToContactForm';
import ForeignExchangeForm from '@/componentsMui/Banking/Transfer/ForeignExchangeForm';
import CardTransferSuccess from '@/componentsMui/Shared/Components/SendMoney/CardTransfer/CardTransferSuccess';
import CardTransferError from '@/componentsMui/Shared/Components/SendMoney/CardTransfer/CardTransferError';
import CardTransferCancel from '@/componentsMui/Shared/Components/SendMoney/CardTransfer/CardTransferCancel';
import Business from '@/componentsMui/Dashboard/Business/Business';
import CreateBusinessForm from '@/componentsMui/Dashboard/Business/CreateBusiness/CreateBusinessForm';
import FinalRejectedBusinessModal from '@/componentsMui/Dashboard/Business/CreateBusiness/FinalRejectedBusinessModal';
import PaymentBusinessModal from '@/componentsMui/Dashboard/Business/CreateBusiness/PaymentBusinessModal';
import Employees from '@/componentsMui/Dashboard/Employees/Employees';
import { UserType } from '@/features/user/types';
import CreateEmployeeForm from '@/componentsMui/Dashboard/Employees/CreateEmployeeForm';
import ConnectBusinessForm from '@/componentsMui/Dashboard/Business/CreateBusiness/Components/ConnectBusinessForm';
import Security from '@/componentsMui/Dashboard/Security/Security';
import FAQ from '@/componentsMui/Dashboard/FAQ/FAQ';
import MyContacts from '@/componentsMui/Dashboard/MyContacts/MyContacts';
import LoadingPageMui from './LoadingPageMui';
import Accounts from '@/componentsMui/Banking/Account/Accounts';
import Earn from '@/componentsMui/Earn/Earn';
import Crypto from '@/componentsMui/Wallet/Crypto';
import Tokens from '@/componentsMui/Wallet/Tokens';
import Cards from '@/componentsMui/Banking/Card/Cards';
import TrustAccountDetails from '@/componentsMui/Banking/Trust/TrustAccountDetails';
import Trust from '@/componentsMui/Banking/Trust/Trust';
import VaultEarningsForm from '@/componentsMui/Earn/Components/Statistics/VaultEarningsForm';
import VaultDepositsForm from '@/componentsMui/Earn/Components/Statistics/VaultDepositsForm';
import MyTicketsForm from '@/componentsMui/Dashboard/FAQ/MyTicketsForm/MyTicketsForm';
import NewTicketForm from '@/componentsMui/Dashboard/FAQ/NewTicketForm/NewTicketForm';
import ErrorBoundary from '@/componentsMui/ErrorBoundary';
import ErrorFallback from '@/componentsMui/ErrorFallback';
import AssignCardForm from '@/componentsMui/Dashboard/Create/Card/AssignCardForm';
import { WalletType } from '@features/walletHistory/types';
import DesktopDrawer from '@/componentsMui/Navbar/DesktopDrawer';
import CospayAnnouncement from './CospayAnnouncement';
import { Agents } from '@/helpers/globalTypes';
import AppBarTabs from '@/componentsMui/AppBar/AppBarTabs';
import HomeSummary from '@/componentsMui/Dashboard/HomeSummary/HomeSummary';
import VerifyPhoneNumber from '@/componentsMui/Banking/Card/Settings/VerifyPhoneNumber';
import CryptoDetails from '@/componentsMui/Wallet/Balances/CryptoDetails';
import WalletSendAssetForm from '@/componentsMui/Wallet/Balances/Send/WalletSendAssetForm';
import MyAccountPage from '@/componentsMui/Dashboard/MyAccount/MyAccountPage';
import TokensSendAssetForm from '@/componentsMui/Wallet/Balances/Send/TokensSendAssetForm';
import BuyCryptoForm from '@/componentsMui/Wallet/Balances/BuyCrypo/BuyCryptoForm';
import MuiFooter from '@/componentsMui/Footer/Footer';
import VerifyEmail from '@/componentsMui/Banking/Card/Settings/VerifyEmail';
import DialogSuspended from '@/componentsMui/Dashboard/DialogSuspended';
import Suspended from '@/componentsMui/Dashboard/Suspended';
import LegacyFAQ from '@/componentsMui/Dashboard/FAQ/LegacyFAQ';
import useFooterHeight from '@/helpers/customHook/useFooterHeight';
import { canGoToPortal } from '@/helpers/kyc';
import { ONBORADING } from './navigationConstants';
import CardBenefitsForm from '@/componentsMui/Banking/Card/CardBenefitsForm';
import CreateContactForm from '@/componentsMui/Dashboard/MyContacts/Create/CreateContactForm';
import Invoices from '@/componentsMui/Invoices/Invoices';
import CreateInvoice from '@/componentsMui/Invoices/CreateInvoice';
import InvoiceDetails from '@/componentsMui/Invoices/InvoiceDetails';

//if user is not verified, then Home component redirects it to onborading flow.
const Dashboard = (): React.ReactElement => {
	const hideHeader = useMediaQuery('(max-width:899px)');
	const { path } = useRouteMatch();
	const { user, status } = useSelector((state: RootState) => state.user);
	const footerHeight = useFooterHeight();
	const {
		businessEnabled,
		businessRegistrationEnabled,
		agent,
		isLegacySupport
	} = useSelector((state: RootState) => state.status);
	const modelFormIsOpen = useGetForm();
	const isScreen = true;

	if (!user) {
		return <LoadingPageMui />;
	}

	const isSuspended = (component: ReactElement, fullScreen?: boolean) => {
		if (user.detained) {
			return fullScreen ? <Suspended /> : <DialogSuspended />;
		} else {
			return component;
		}
	};

	const goHomeAllowed = canGoToPortal(user, status);

	if (!goHomeAllowed && user?.type == UserType.INDIVIDUAL) {
		return <Redirect to={ONBORADING} />;
	}


	return (<>
		<Grid container style={{ flexWrap: 'nowrap' }}>
			<MobileDrawer />
			{!hideHeader &&
				<Grid style={{ width: 'unset' }} className='sidebar' sx={{ display: { xs: 'none', md: 'flex' } }}>
					<DesktopDrawer />
				</Grid>}
			<Grid style={{ width: 'unset', flex: '1' }} container item flexDirection='column'>
				<AppBarTabs hideHeader={hideHeader} />
				<Container disableGutters={true} className={`muiDashboard  ${modelFormIsOpen && 'blurred-window'} `}>
					<div className={agent === Agents.COSPAY ? 'wrapDashboard' : 'wrapDashboard wrapDashboardNormal'} >
						{(hideHeader) &&
							<Grid className='app-navbar'>
								<AppBarCustom authorized={true} />
							</Grid>}
						<Grid container className={'portal-container'}>
							<Grid id='content-container' item xs className={`content ${modelFormIsOpen && 'blurred-content'}  `}
								container direction='column' position='relative'>
								<ErrorBoundary>
									<Switch>
										{agent === Agents.COSPAY &&
											user?.kycTier > 0 &&
											<Route exact path={`${path}/home`}>
												<CospayAnnouncement />
											</Route>}
										<Route exact path={`${path}/home`}>
											{<Home />}
										</Route>
										<Route exact path={`${path}/home/summary`}>
											{<HomeSummary />}
										</Route>
										<Route path={`${path}/home/cards`}>
											{<Cards />}
										</Route>
										<Route path={`${path}/home/accounts`}>
											{<Accounts />}
										</Route>
										<Route path={`${path}/home/wallets/details`}>
											{<CryptoDetails />}
										</Route>
										<Route path={`${path}/home/wallets`}>
											{<Crypto />}
										</Route>
										<Route path={`${path}/home/tokens`}>
											{<Tokens />}
										</Route>
										<Route path={`${path}/home/earn`}>
											{<Earn />}
										</Route>
										<Route path={`${path}/home/trust/account`}>
											{<TrustAccountDetails />}
										</Route>
										<Route path={`${path}/home/trust`} excat>
											{<Trust />}
										</Route>
										<Route path={`${path}/support`}>
											{isLegacySupport
												? <LegacyFAQ />
												: <FAQ />}
										</Route>
										<Route path={`${path}/security`}>
											{isSuspended(<Security />, isScreen)}
										</Route>
										<Route path={`${path}/account`}>
											<MyAccountPage />
										</Route>
										<Route path={`${path}/contacts`}>
											<MyContacts />
										</Route>
										<Route path={`${path}/employees`}>
											{isSuspended(<Employees />, isScreen)}
										</Route>
										{businessEnabled &&
											<Route path={`${path}/business`}>
												<Business />
											</Route>}
										<Route path={`${path}/amlpolicy`}>
											<Policy type={PolicyType.AML_POLICY} />
										</Route>
										<Route path={`${path}/privacypolicy`}>
											<Policy type={PolicyType.PRIVACY_POLICY} />
										</Route>
										<Route path={`${path}/terms`}>
											<Policy type={PolicyType.TERMS_AND_CONDITIONS} />
										</Route>
										<Route path={`${path}/cookiespolicy`}>
											<Policy type={PolicyType.COOKIES_POLICY} />
										</Route>
										<Route path={`${path}/termsofuse`}>
											<Policy type={PolicyType.TERMS_OF_USE} />
										</Route>
										<Route path={`${path}/cardtopup`}>
											<Policy type={PolicyType.TOP_UP_CARD_POLICY} />
										</Route>
										{/* Invoices */}
										<Route path={`${path}/home/invoices/create`}>
											<CreateInvoice />
										</Route>
										<Route path={`${path}/home/invoices/details/:id`}>
											<InvoiceDetails />
										</Route>
										<Route path={`${path}/home/invoices`}>
											<Invoices />
										</Route>
										{/* Invoices */}
										<Route path={`${path}/error`}>
											<ErrorFallback />
										</Route>
									</Switch>
									{	/* Second switch added to render multiple component at same time:
				            e.g. Home and Pop-up forms. This allows po-up forms to be transparent 
							and how as blured background another component*/ }
									<Switch>
										<Route path={`${path}/home/accounts/create`} >
											{isSuspended(<CreateAccountForm />, isScreen)}
										</Route>
										<Route path={`${path}/home/cards/create`} >
											{isSuspended(<CreateCardForm />)}
										</Route>
										<Route path={`${path}/home/cards/replace`} >
											{isSuspended(<ReplaceCardForm />)}
										</Route>
										<Route path={`${path}/home/cards/assign`} >
											{isSuspended(<AssignCardForm />)}
										</Route>
										<Route path={`${path}/home/cards/activate`} >
											{isSuspended(<InactiveForm />)}
										</Route>
										<Route path={`${path}/home/accounts/transfers/send`} >
											{isSuspended(<SendMoneyForm />)}
										</Route>
										<Route path={`${path}/home/accounts/transfers/request`} >
											{isSuspended(<RequestMoneyForm />)}
										</Route>
										<Route path={`${path}/home/accounts/addmoney`} >
											{isSuspended(<AddMoneyForm />)}
										</Route>
										<Route path={`${path}/home/accounts/addmoneycard/success`} >
											{isSuspended(<CardTransferSuccess />)}
										</Route>
										<Route path={`${path}/home/accounts/addmoneycard/error`} >
											{isSuspended(<CardTransferError />)}
										</Route>
										<Route path={`${path}/home/accounts/addmoneycard/cancel`} >
											{isSuspended(<CardTransferCancel />)}
										</Route>
										<Route path={`${path}/home/wallets/buycrypto/success`} >
											{isSuspended(<CardTransferSuccess />)}
										</Route>
										<Route path={`${path}/home/wallets/buycrypto/error`} >
											{isSuspended(<CardTransferError />)}
										</Route>
										<Route path={`${path}/home/wallets/buycrypto/cancel`} >
											{isSuspended(<CardTransferCancel />)}
										</Route>
										<Route path={`${path}/home/accounts/exchange`} >
											{(<ForeignExchangeForm />)}
										</Route>
										<Route path={`${path}/home/cards/transfers/top-up`} >
											{isSuspended(<TopUpForm />)}
										</Route>
										<Route path={`${path}/home/cards/verify-phone`} >
											{isSuspended(<VerifyPhoneNumber />)}
										</Route>
										<Route path={`${path}/home/cards/verify-email`} >
											{isSuspended(<VerifyEmail />)}
										</Route>
										<Route path={`${path}/home/cards/transfers/settings`} >
											{isSuspended(<CardSettingsForm />)}
										</Route>
										<Route path={`${path}/home/cards/benefits`} >
											{isSuspended(<CardBenefitsForm />)}
										</Route>
										<Route path={`${path}/home/wallets/crypto/trade`} >
											{<CryptoTradeForm walletType={WalletType.CRYPTO} />}
										</Route>
										<Route path={`${path}/home/tokens/token/trade`} >
											{isSuspended(<CryptoTradeForm walletType={WalletType.TOKEN} />)}
										</Route>
										<Route path={`${path}/home/earn/deposit`} >
											{<DepositVaultForm />}
										</Route>
										<Route path={`${path}/home/wallets/deposit`} >
											{<WalletDepositForm />}
										</Route>
										<Route path={`${path}/home/wallets/card`} >
											{<BuyCryptoForm />}
										</Route>
										<Route path={`${path}/home/wallets/send`} >
											{isSuspended(<WalletSendAssetForm />)}
										</Route>
										<Route path={`${path}/home/tokens/deposit`} >
											{<DepositForm />}
										</Route>
										<Route path={`${path}/home/tokens/withdraw`} >
											{isSuspended(<WithdrawForm walletType={WalletType.TOKEN} />)}
										</Route>
										<Route path={`${path}/home/tokens/transfer`} >
											{isSuspended(<TokensSendAssetForm />)}
										</Route>
										<Route path={`${path}/home/tokens/receive`} >
											{(<ReceiveForm />)}
										</Route>
										<Route path={`${path}/home/earn/earnings`} >
											{isSuspended(<VaultEarningsForm />)}
										</Route>
										<Route path={`${path}/home/earn/deposits`} >
											{<VaultDepositsForm />}
										</Route>
										<Route path={`${path}/contacts/account/transfers/send`} >
											{isSuspended(<SendMoneyToContactForm />)}
										</Route>
										<Route path={`${path}/contacts/account/transfers/request`} >
											{(<RequestMoneyForm />)}
										</Route>
										<Route path={`${path}/contacts/account/edit`} >
											{(<EditContactForm />)}
										</Route>
										<Route path={`${path}/contacts/account/create`} >
											{(<CreateContactForm />)}
										</Route>
										{businessRegistrationEnabled &&
											<Route path={`${path}/business/create`}>
												{isSuspended(<CreateBusinessForm />)}
											</Route>
										}
										{businessEnabled &&
											<Route path={`${path}/business/connect`}>
												{isSuspended(<ConnectBusinessForm />)}
											</Route>
										}
										{(user?.type === UserType.BUSINESS || user?.onBehalfOf !== null) &&
											<Route path={`${path}/employees/create`}>
												{isSuspended(<CreateEmployeeForm />)}
											</Route>
										}
										<Route path={`${path}/business/rejected-final`}>
											{isSuspended(<FinalRejectedBusinessModal />)}
										</Route>
										<Route path={`${path}/business/payment`}>
											{isSuspended(<PaymentBusinessModal />)}
										</Route>
										<Route path={`${path}/support/new`}>
											<NewTicketForm />
										</Route>
										<Route path={`${path}/support/list`}>
											<MyTicketsForm />
										</Route>
									</Switch>
								</ErrorBoundary>
							</Grid>
						</Grid>
					</div>
				</Container>
			</Grid>
		</Grid>
		{agent === Agents.COSPAY ? <Grid item xs={12} minHeight={footerHeight} >
			<MuiFooter authorized={user != null} />
		</Grid> : null}
	</>
	);
};

export default Dashboard;
