
import * as React from 'react';
import { useTheme } from '@mui/styles';


import { Dialog, DialogProps, Theme } from '@mui/material';
import { FC } from 'react';

/* Placed on base background*/
const DialogWithBg: FC<DialogProps> = (props: DialogProps) => {

	const theme = useTheme() as Theme;

	const { PaperProps, ...other } = props;

	return (<Dialog
		{...other}
		PaperProps={{ ...PaperProps, sx: { bgcolor: theme.palette.background.default } }}
	>
		{props.children}
	</Dialog>);
};


export default DialogWithBg;
