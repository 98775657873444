import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Grid, Menu, MenuItem, Typography } from '@mui/material';
import { AccountIntegration, BankAccount } from '@/features/account/types';
import { useTranslation } from 'react-i18next';
import MuiTextLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextLabel';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import MuiCopyToClipboard from '@/componentsMui/Shared/Widgets/MuiCopyToClipboard';
import WalletId from '@/componentsMui/Shared/Components/WalletId';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { closeForm, openForm } from '@/features/forms/slice';
import AccountDetailsForm from './AccountDetailsForm';
import BaseBox from '@/componentsMui/Shared/Widgets/Box/BaseBox';
import { hasSubprocessWithType } from '@/features/operations/slice';
import { AccountType } from '@/features/operations/types';
import { ReactComponent as MuiChevronRight } from '@assets/MuiChevronRight.svg';
import { primetrustAccountDepositDetails } from '@features/account/slice';
import { RootState } from '@/rootReducer';
import { Agents } from '@/helpers/globalTypes';

interface Props {
	bankAccount: BankAccount
}

const AccountInfo = ({ bankAccount }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const history = useHistory();
	const dispatch = useDispatch();
	const [openAccountDetailsForm, setOpenAccountDetailsForm] = React.useState(false);
	const [showMoreOptions, setShowMoreOptions] = React.useState<null | HTMLElement>(null);
	const [primetrustAccountDetails, setPrimetrustAccountDetails] = useState(null);
	const openMoreOption = Boolean(showMoreOptions);
	const showSortCode = bankAccount?.integration === 'MODULR' && bankAccount?.currency === 'GBP';

	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const { agent } = useSelector(
		(state: RootState) => state.status
	);

	const handleSendMoney = () => {
		history.push(`/wallet/home/accounts/transfers/send?account=${bankAccount.accountId}`);
	};

	const handleAddMoney = () => {
		history.push(`/wallet/home/accounts/addmoney?account=${bankAccount.accountId}`);
	};

	const handleRequestMoney = () => {
		history.push(`/wallet/home/accounts/transfers/request?account=${bankAccount.accountId}`);
	};

	const handleCurrencyExchange = () => {
		history.push(`/wallet/home/accounts/exchange?account=${bankAccount.accountId}`);
	};

	const handleAccountDetailsForm = () => {
		dispatch(openForm());
		setOpenAccountDetailsForm(true);
	};

	const closeAccountDetailsForm = () => {
		dispatch(closeForm());
		setOpenAccountDetailsForm(false);
	};

	const isSendMoneyEnabled = () => {
		if (bankAccount?.subProcesses) {
			if (hasSubprocessWithType('PAYMENT', bankAccount.subProcesses)) return true;
			return hasSubprocessWithType('TRANSFER', bankAccount.subProcesses);
		}
		else return false;
	};

	const disableSend = !(!isSendMoneyEnabled() && user.detained) && (!isSendMoneyEnabled() || user.detained);

	const isExchangeAllowed = hasSubprocessWithType('FX_ORDER', bankAccount.subProcesses);

	useEffect(() => {
		const get = async () => {
			try {
				const details = await primetrustAccountDepositDetails(bankAccount.accountId);
				setPrimetrustAccountDetails(details?.wireInstructions);
			}
			catch (e) {
				// setError(true);
			}
			finally {
				// setLoading(false);
			}

		};
		if (bankAccount?.integration === AccountIntegration.PRIMETRUST) {
			get();
		}

	}, [bankAccount.accountId, bankAccount?.integration]);

	const trustOutgoingDisabled = agent === Agents.DTS && bankAccount?.type === AccountType.TRUST;

	return (
		<>
			<AccountDetailsForm
				open={openAccountDetailsForm}
				onClose={closeAccountDetailsForm}
				wireInstructions={primetrustAccountDetails}
				account={bankAccount}
			/>
			<Grid item container alignItems='baseline' justifyContent='flex-end' columnSpacing={2} pb='2rem'
				sx={{ display: { xs: 'none', lg: 'flex' } }}>
				<Grid item>
					<WalletId walletId={bankAccount.type === AccountType.TRUST ? bankAccount?.walletId : bankAccount?.userTag ?? user?.tag ?? user.clientId} title={bankAccount.type === AccountType.TRUST ? t('trusts.trustId') : t('login.clienId')} />
				</Grid>
				{!trustOutgoingDisabled && <Grid item>
					<Button variant="simple" onClick={handleAddMoney}>{t('banking.data.addMoneyButton')}</Button>
				</Grid>}
				<Grid item>
					<Button
						variant="simple"
						style={{ filter: user.detained ? 'blur(2px)' : null }}
						disabled={disableSend}
						onClick={handleSendMoney}>
						{t('banking.data.send')}
					</Button>
				</Grid>
				{bankAccount?.type !== AccountType.TRUST &&
					<Grid item>
						<Button
							variant="simple"
							onClick={(e) => setShowMoreOptions(e.currentTarget)}
							aria-controls={openMoreOption ? 'more-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={openMoreOption ? 'true' : undefined}
						>...</Button>
					</Grid>
				}
			</Grid>
			{bankAccount?.type !== AccountType.TRUST &&
				<Menu
					id="more-menu"
					open={openMoreOption}
					onClose={() => setShowMoreOptions(null)}
					anchorEl={showMoreOptions}
				>
					<MenuItem
						id='moreSelectMenuItem'
						onClick={() => { setShowMoreOptions(null); handleRequestMoney(); }}
					>
						<Typography variant='body2' color="text.primary"> {t('banking.data.requestmoneybutton')}</Typography>
					</MenuItem>
					{isExchangeAllowed && <MenuItem
						id='moreSelectMenuItemExchange'
						onClick={() => { setShowMoreOptions(null); handleCurrencyExchange(); }}
					>
						<Typography variant='body2' color="text.primary"> {t('banking.data.foreignExchangebutton')}</Typography>
					</MenuItem>}
				</Menu>
			}

			<Grid item container flex={1} columnSpacing={2} alignContent='space-between'>
				<Grid item container xs={12} sm={6} md={12} alignItems='baseline' justifyContent='center'
					sx={{ display: { xs: 'flex', lg: 'none' }, pb: { xs: '2rem', sm: 0, md: '2rem' } }}>
					<ButtonGroup variant="contained" fullWidth={true} aria-label="outlined primary button group" sx={{ height: '100%' }}>
						<Button variant="transparent" onClick={handleAddMoney} sx={{ p: '0.75rem 1rem' }}>
							{t('banking.data.addMoneyButton')}
						</Button>
						<Button variant='transparent' disabled={disableSend} onClick={handleSendMoney} sx={{ p: '0.75rem 1rem' }}>
							{t('banking.data.send')}
						</Button>
						{bankAccount?.type !== AccountType.TRUST &&
							<Button
								variant="transparent"
								onClick={(e) => setShowMoreOptions(e.currentTarget)}
								aria-controls={openMoreOption ? 'more-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={openMoreOption ? 'true' : undefined}
								sx={{ p: '0.75rem 1rem' }}
							>...</Button>
						}
					</ButtonGroup>
				</Grid>
				<Grid item xs={12} sm={6} md={12}>
					{!trustOutgoingDisabled ? <BaseBox id="accountInfo" >
						<Grid container item sx={{ display: { xs: 'none', lg: 'flex' }, p: '2rem' }}>
							<Grid container item>
								<Grid item container direction='row' spacing={2} alignItems='center'>
									<Grid item xs={4}>
										{bankAccount.recipient &&
											<><MuiTextLabel > {t('banking.data.name')}</MuiTextLabel>
												<Grid item container alignItems="center">
													<MuiTextCaption> {bankAccount.recipient} </MuiTextCaption>
													<MuiCopyToClipboard value={bankAccount.recipient} />
												</Grid>
											</>}
									</Grid>
									<Grid item xs={5}>{bankAccount?.iban && !showSortCode &&
										<>
											<MuiTextLabel> {t('banking.data.iban')}</MuiTextLabel>
											<Grid item container alignItems="center">
												<MuiTextCaption> {bankAccount.iban}</MuiTextCaption>
												<MuiCopyToClipboard value={bankAccount.iban} />
											</Grid>
										</>}
										{showSortCode &&
											<Grid container>
												<Grid item xs>
													<MuiTextLabel> {t('sendmoneyTranslation.data.sortCode')}</MuiTextLabel>
													<Grid item container alignItems="center">
														<MuiTextCaption> {bankAccount.ukSortCode}</MuiTextCaption>
														<MuiCopyToClipboard value={bankAccount.ukSortCode} />
													</Grid>
												</Grid>
												<Grid item xs>
													<MuiTextLabel> {t('sendmoneyTranslation.data.accountNumber')}</MuiTextLabel>
													<Grid item container alignItems="center">
														<MuiTextCaption> {bankAccount.ukAccountNumber}</MuiTextCaption>
														<MuiCopyToClipboard value={bankAccount.ukAccountNumber} />
													</Grid>
												</Grid>
											</Grid>}
									</Grid>
									<Grid item xs={3} justifyContent='center' display='flex'>
										<Button variant="simple" onClick={handleAccountDetailsForm}>{t('banking.data.showDetails')}</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid container p={2} onClick={handleAccountDetailsForm} sx={{ display: { xs: 'flex', lg: 'none' } }}>
							<Grid item container>
								<Grid item xs={10}>
									<Typography fontSize='0.9rem' fontWeight={700} color="text.primary"> {t('banking.data.requisites')}</Typography>
									<Typography fontSize='0.7rem' color="text.secondary">{`${bankAccount.type === AccountType.TRUST ? t('trusts.trustId') : t('tokens.header.walletId')}: ${bankAccount?.walletId}`}</Typography>
								</Grid>
								<Grid item xs={2} justifyContent='flex-end' alignItems='center' container>
									<MuiChevronRight className='svg-icon-stroke' />
								</Grid>
							</Grid>
						</Grid>
					</BaseBox> : <BaseBox id="accountInfo" >
							<Grid container item sx={{ display: { xs: 'none', lg: 'flex' }, p: '2rem' }}>
								<Typography fontSize='0.9rem' fontWeight={500} color="text.primary">Please note: As part of our latest platform update, new deposits into your Escrow Account are no longer supported. Existing balances can still be used for outgoing payments. We appreciate your understanding.</Typography>
							</Grid>
						</BaseBox>}
				</Grid>
			</Grid>
		</>
	);
};

export default AccountInfo;
