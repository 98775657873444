import request from '../../services/request';
import { ChangePasswordPayload, HideBalancePayload, CurrentIP, IpSetting } from './types';


export const changePhoneV3 = async (phoneNumber: string, recaptchaToken: string) => {
	const response = await request.post('/api/v3/users/me/profile/change-phone', { phoneNumber, smsCode: '', recaptchaToken });
	const { data } = await response;
	return data;
};


export const changeEmailV3 = async (email: string, recaptchaToken: string) => {
	const response = await request.post('/api/v3/users/me/change-email', { email, emailOtpCode: '', recaptchaToken });
	const { data } = await response;
	return data;
};


export const disableAccount = async (password: string) => {
	const response = await request.post('/api/users/me/closeaccount', { password });
	const { data } = await response;
	return data;
};

export const changePassword = async (payload: ChangePasswordPayload) => {
	const response = await request.post('/api/users/me/security/change-password', payload);
	const { data } = await response;
	return data;
};

export const hideBalance = async (payload: HideBalancePayload) => {
	const response = await request.post('/api/settings/security', payload);
	const { data } = await response;
	return data;
};



export const getRestrictions = async () => {
	const response = await request.get('/api/users/me/security');
	const { data } = await response;
	const currentIp: CurrentIP = { value: data.currentIp };
	const ipSettings: Array<IpSetting> = data.ipSettings.map(s => {
		const restriction: IpSetting = {
			id: s.id,
			name: s.name,
			type: s.type,
			ipAddress: s.ipAddress
		};
		return restriction;
	});
	return { currentIp, ipSettings };
};

export const upsertRestriction = async (payload: IpSetting) => {
	const response = await request.put('/api/users/me/security/ip', payload);
	const { data } = response;
	return data;
};

export const deleteRestriction = async (id: number) => {
	const response = await request.delete(`/api/users/me/security/ip/${id}`);
	const { data } = response;
	return data;
};

export const linkChatApp = async () => {
	const response = await request.post('/api/users/external-link/generate');
	const { data } = response;
	const { qrCodeImage } = data;
	return qrCodeImage;
};

