import React, { useEffect } from 'react';
import { Alert, Button, Dialog, Grid, Theme, useTheme, DialogContent } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { openForm } from '@/features/forms/slice';
import { useTranslation } from 'react-i18next';
import FormPaperLarge from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLarge';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import { Form, Formik } from 'formik';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { addEmployeeCode, getMyAdminCompanies } from '@/features/employee/slice';
import { getErrorWithParams, showException, showSuccessNotification } from '@/features/swal/slice';

const ConnectBusinessForm = (): React.ReactElement => {

	const closeModal = useCloseDialogNoEvent();
	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const theme = useTheme() as Theme;

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);

	const initialValues = {
		code: '',
	};

	const validationSchema = Yup.object({
		code: Yup.string()
			.required(t('form.validator.required')),
	});

	const submit = async (formData, formikProps) => {
		const { setSubmitting, setFieldError } = formikProps;
		const { code } = formData;

		setSubmitting(false);
		if (code) {
			try {
				await addEmployeeCode(code);
				await showSuccessNotification(t('employee.validEmployeeCode'));
			}
			catch (e) {
				const err = getErrorWithParams(e);
				if (err && err.error === 'invalid' && err.error_param === 'code') {
					setFieldError('code', t('employee.invalidEmployeeCode'));
				} else if (err && err.error === 'duplicate' && err.error_param === 'code') {
					setFieldError('code', t('employee.duplicateEmployeeCode'));
				} else {
					showException(e);
				}
				return;
			}
		}
		closeModal();
		dispatch(getMyAdminCompanies());
	};

	return (
		<Dialog
			id="connect-business-form"
			open={true}
			onClose={closeModal}
			PaperComponent={FormPaperLarge}
		>
			<DialogContent style={{padding: 0, display: 'flex'}}>
				<Grid id='connect-business'
					   container
					   flex={1}
					   direction='column'
					   minHeight='0'
					   flexWrap='nowrap'
					   rowSpacing={2}>
					<Grid item>
						<FormHeader onBack={closeModal} title={t('business.connect.title')} />
					</Grid>
					<Grid item mt='1rem' >
						<Alert severity="warning">
							<MuiFormText >{t('business.connect.warningText')}</ MuiFormText>
						</Alert>
					</Grid>
					<Grid item flex={1}>
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={submit}
						>
							{({ errors, isSubmitting, touched, dirty }) => (
								<Form style={{ width: '100%', height: '100%', display: 'flex' }}>
									<Grid container flex={1}>
										<Grid item xs={12}>
											<MuiTextField
												name="code"
												label={t('business.connect.employeeCode')}
												InputLabelProps={{ style: { color: theme.palette.text.primary, textAlign: 'left' } }}
												fullWidth
												placeholder={t('business.connect.employeeCode')}
												inputProps={{
													autoComplete: 'off',
												}}
												error={errors.code && touched.code}
											/>
										</Grid>
										<Grid container item mt='auto' pt={8} columnSpacing={2} justifyContent='space-between'>
											<Grid item xs={6}>
												<Button variant="customOutlined"
														sx={{ width: { xs: '100%', md: 'auto' } }}
														onClick={closeModal}>
													{t('form.buttons.close')}
												</Button>
											</Grid>
											<Grid item xs={6} container justifyContent='flex-end'>
												<Button
													type="submit"
													variant="contained"
													sx={{ width: { xs: '100%', md: 'auto' } }}
													disabled={!isEmpty(errors) || isSubmitting || !dirty}
												>
													{t('business.connect.connectBtn')}
												</Button>
											</Grid>
										</Grid>
									</Grid>

								</Form>
							)}
						</Formik>
					</Grid>
				</ Grid >
			</DialogContent>
		</Dialog >
	);
};

export default ConnectBusinessForm;
