import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@/store';
import { AccountChartResponse, BankAccount, BankAccountStore, CharTypeEnum, PeriodEnum } from './types';
import { SocketService } from '@services/socketService';
import { Subscription } from 'rxjs';
import request from '@services/request';
import { RootProcessType } from '@/helpers/globalTypes';
import { UkPaymentValidationType } from '@/componentsMui/Type/PaymentPayloadType';

const findAccountById = (accountId, list) => {
	return list.findIndex(account => account.accountId === accountId);
};

let cancelSource;

const initialState: BankAccountStore = {
	loading: false,
	list: [],
	error: null,
};

const accountSlice = createSlice({
	name: 'account',
	initialState,
	reducers: {
		setAccounts(state, action: PayloadAction<Array<BankAccount>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
	}
});

export const { setAccounts, setLoading, setError } = accountSlice.actions;

export const bankAccountsSelector = (state: { bankAccountStore: BankAccountStore }) =>
	state.bankAccountStore;

export const getAccounts = (): AppThunk => {
	return getAccountsV3();
};

export const getAccountsV2 = (): AppThunk => {
	return async (dispatch, state) => {
		const { accounts } = state();
		const { list } = accounts;
		const currentAccounts = JSON.parse(JSON.stringify(list));
		try {
			const response = await request.get('/api/accounts');
			const { data } = response;
			data.forEach(account => {
				if ((account.providerType === 'POOLING' || account.providerType === 'IBAN' || account.providerType === 'POOLING_IBAN' || account.providerType === 'WALLET')
					&& account.status !== 'TERMINATED') {
					const id = findAccountById(account.accountId, currentAccounts);
					if (id < 0) {
						currentAccounts.push(account);
					} else {
						currentAccounts[id] = account;
					}
				}
			});
			dispatch(setAccounts(currentAccounts));
			return data;
		} catch (e) {
			console.log(e);
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const getAccountsV3 = (): AppThunk => {
	return async (dispatch) => {
		try {
			dispatch(setLoading(true));
			const response = await request.get(`/api/accounts/type/${RootProcessType.CREATE_ACCOUNT}`);
			const { data } = response;
			const filteredData = data?.filter(account => account.status !== 'TERMINATED') || [];
			dispatch(setAccounts(filteredData));
			return filteredData;
		} catch (e) {
			console.log(e);
		} finally {
			dispatch(setLoading(false));
		}
	};
};


export const primetrustAccountDepositDetails = async (accountId: number) => {
	const response = await request.get(`/api/accounts/primetrust/deposit/details/${accountId}`);
	const { data } = response;
	return data;
};

export const clearAccounts = (): AppThunk => {
	return async dispatch => {
		dispatch(setAccounts([]));
	};
};

export const getAccount = async (id: number, source?: any) => {
	cancelSource = source ? source : { token: null };
	const response = await request.get('/api/accounts/' + id, {
		cancelToken: source ? source.token : null
	});
	const { data } = response;
	return data;
};


export const updateLabel = async (accountId: number, label: string) => {
	const response = await request.post('/api/accounts/label', {
		accountId, label
	});
	const { data } = response;
	return data;
};

export const cancelToken = async () => {
	if (cancelSource && cancelSource.token) cancelSource.cancel();
};

let socketService: SocketService;
let updateSubscriber: Subscription;

export const connect = (): void => {
	socketService = new SocketService('account');
	// if (!socketService) {
	// 	socketService = new SocketService('account');
	// }
};

export const subscribe = (): AppThunk => {
	return async dispatch => {
		updateSubscriber = socketService.listen('bank.account', {}).subscribe(() => {
			dispatch(getAccounts());
		});
	};
};

export const unsubscribe = (): void => {
	updateSubscriber.unsubscribe();
};

export const ukChapsValidation = async (sortCode: string, accountNumber: string) => {
	const { data } = await request.get(`/api/beneficiary/uk-chaps/${sortCode}/${accountNumber}`
	);
	return data;
};

export const getUkPaymentValidation = async (accountId: number, payload: any): Promise<UkPaymentValidationType> => {
	const { data } = await request.post(`/api/beneficiary/uk-payment/validate/${accountId}`, payload);
	return data;
};

export const getAccountChartData = async (type: CharTypeEnum,
	period: PeriodEnum, source?: any):
	Promise<AccountChartResponse[]> => {
	cancelSource = source ? source : { token: null };
	const response = await request.get(`/api/accounts/chart/${type}/EUR/${period}`, {
		cancelToken: source ? source.token : null
	});
	const { data } = response;
	return data;

};


export default accountSlice.reducer;
