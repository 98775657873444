import React from 'react';
import { Grid } from '@mui/material';
import MuiTextCaption from '../MuiText/MuiTextCaption';
import './Avatars.css';
import TokenAvatar, { TokenAvatarSizeEnum } from './TokenAvatar';
import bigDecimal from 'js-big-decimal';
import MuiTextLabel from '@shared/Widgets/MuiText/MuiTextLabel';
import { useTranslation } from 'react-i18next';

interface Props {
	logo?: string,
	displayName?: string,
	symbol?: string,
	availableBalance?: number | string,
	precision?: number,
}

const TokenAvatarLine = ({ logo, displayName, symbol, availableBalance, precision }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	return (
		<Grid container alignItems='center' height='5.1rem'>
			<Grid item>
				<TokenAvatar logoSource={logo} variant={TokenAvatarSizeEnum.regular} />
			</Grid>
			<Grid item pl='0.5rem'>
				<Grid container item pl='0.5rem' flexDirection='column'>
					<Grid item flexDirection='row' display='flex'>
						<MuiTextCaption>{displayName}</MuiTextCaption>
						<MuiTextCaption sx={{ pl: '0.2rem', fontWeight: '400' }}>{symbol}</MuiTextCaption>
					</Grid>
					<Grid item>
						<MuiTextLabel color='text.secondary' fontWeight='400' fontSize='0.688rem' lineHeight='0.688rem' mt='4px' >
							<span style={{ opacity: 0.7 }}>{t('tokens.withdrawal.balance')}</span> {new bigDecimal(availableBalance).round(precision, bigDecimal.RoundingModes.DOWN).getValue() ?? 0}
						</MuiTextLabel>
					</Grid>
				</Grid>
			</Grid>
		</Grid>);
};

export default TokenAvatarLine;
