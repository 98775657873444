import React from 'react';
import { useTranslation } from 'react-i18next'; 
import DetailsBox from '@/componentsMui/Shared/Widgets/DetailsBox';
import DetailLine from '@/componentsMui/Shared/Widgets/DetailLine';

interface Props {
	walletId: string,
	hideHelperText?: boolean,
}

const AccountIdentifier = ({ walletId, hideHelperText }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	return (
		<DetailsBox sx={{p: { xs: '0.5rem 1rem', sm: '0.5rem 2.625rem' }}}>
			<DetailLine label={t('wallet.beneficiary.accountIdentifier')}
				value={walletId}
				helpText={hideHelperText ? null : t('wallet.deposit.fieldInfo.reference')} 
				first/>
		</DetailsBox>
	);
};

export default AccountIdentifier;
