import React, { useEffect } from 'react';
import { ActionsStatus } from '@/componentsMui/Onboarding/Onboarding';
import MonoBgBox from '@/componentsMui/Shared/Widgets/Box/CoverBox/MonoBgBox';
import LogoThemed from '@/componentsMui/Shared/Widgets/LogoThemed';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { KycStatus, UserType } from '@/features/user/types';
import { allowToStartKYC, getKycTier, isUserVerified, kycSumitted, poaMissing } from '@/helpers/kyc';
import { getPoaStatus } from '@/helpers/userStatuses';
import { ONBORADING } from '@/pages/navigationConstants';
import { RootState } from '@/rootReducer';
import { Button, Grid, Theme, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getKycAddressList } from '@/features/kyc/slice';

const StatusCard = () => {
  const { t } = useTranslation('translations');
  const history = useHistory();
  const brandName = t('brandName.brand');
  const theme = useTheme() as Theme;
  const dispatch = useDispatch();

  const { user, status } = useSelector((state: RootState) => state.user);
  const {kycAddressList, kycAddressLoading} = useSelector((state: RootState) => state.kyc);

  useEffect(() => { dispatch(getKycAddressList()); }, [dispatch]);

  const handleVerify = () => {
    history.push(ONBORADING, { redo: 'Y' });
  };

  const getMessage = () => {
    /*  const result = {
      title: '',
      subTitle: '',
      color: theme.palette.text.primary,
      showVerificationButton: true,
      showSupportButton: false,
      showMessage: false,
      showRefresh: false,
      debugId: 0
    };
     **/
    const result = {
      title: t('home.status.notVerifiedTitle'),
      subTitle: t('home.status.inProcessSubtitle'),
      color: theme.palette.text.primary,
      showVerificationButton: true,
      showSupportButton: false,
      showMessage: user?.type === UserType.EMPLOYEE? false:  !isUserVerified(user, status, kycAddressList) && !user.onlySkipKycUsers,
      showRefresh: false,
      debugId: 0
    };

    if (user.detained) {
      result.title = t('home.status.isSuspendedTitle');
      result.subTitle = t('home.status.isSuspendedSubtitle', { brand: brandName });
      result.showMessage = true;
      result.showVerificationButton = false;
      result.showSupportButton = true;
      result.debugId = 1;
      return result;
    }

    const tier = getKycTier(user);
    const kycStatus = tier?.status;
    const poaStatus = getPoaStatus(user);
    const riskVerified = user.risksVerified;//&& user.kycIdentityRequiredFieldsPresent; 

    const allowRetyKyc = allowToStartKYC(user, status?.kycProvider);

    if (user?.onlySkipKycUsers) {
      result.debugId = 2;
      return result;
    }


    if (!allowRetyKyc && kycStatus === KycStatus.REJECTED) {
      result.title = t('home.status.inFailed');
      result.subTitle = t('home.status.inFailedSubtitle');
      result.showMessage = true;
      result.showVerificationButton = false;
      result.showSupportButton = true;
      result.debugId = 3;

      return result;
    }


    if ((kycStatus === KycStatus.REJECTED && allowRetyKyc) || poaStatus === ActionsStatus.FAILED) {
      result.title = t('home.status.inFailedRetry');
      result.subTitle = t('home.status.inFailedSubtitle');
      result.showMessage = true;
      result.showVerificationButton = true;
      result.debugId = 4;

      return result;
    }


    if (kycStatus === KycStatus.IN_PROGRESS || kycStatus === KycStatus.PENDING || poaStatus === ActionsStatus.INPROGRESS || (user?.occupationCode && !riskVerified && kycStatus !== KycStatus.CREATED)) {
      result.title = t('home.status.inProcess');
      result.subTitle = t('home.status.inProcessSubtitle');
      result.showMessage = true;
      result.showVerificationButton = false;
      result.showRefresh = true;
      result.color = '#FFDA44';
      result.debugId = 5;

      return result;
    }




    if ((user.skipKycVerification && (!kycSumitted(user) || poaMissing(user))) || kycStatus === KycStatus.CREATED) {
      result.title = t('home.status.notVerifiedTitle');
      result.subTitle = t('home.status.notVerifiedSubtitle', { brand: brandName });
      result.showMessage = true;
      result.showVerificationButton = true;
      result.debugId = 6;

      return result;
    }

    if (!user.kycIdentityRequiredFieldsPresent) {
      result.title = t('home.status.inProcess');
      result.subTitle = t('home.status.inProcessSubtitle');
      result.showMessage = true;
      result.showVerificationButton = false;
      result.showRefresh = true;
      result.color = '#FFDA44';
      result.debugId = 7;

      return result;
    }


    return result;
  };

  const message = getMessage();

  if (!message.showMessage) return <></>;

  const btnListAll = [
    {
      onClick: handleVerify,
      title: t('home.completeVerification'),
      show: message.showVerificationButton
    },
    {
      onClick: () => history.push('/wallet/support'),
      title: t('home.contactSupport'),
      show: message.showSupportButton
    },
    {
      onClick: () => history.push('/wallet/home'),
      title: t('form.buttons.refresh'),
      show: message.showRefresh
    }
  ];

  const btnList = btnListAll.filter(p => p.show);
  if (kycAddressLoading) return <></>;

  return (
    <Grid marginY={5}>
      <MonoBgBox p='30px'
        bgIndex={0}
        selected={true}
        mainClassName={'earnStatisticsCard'}
      >
        <Grid container alignItems='center' spacing={3} >
          <Grid item xs={12} lg='auto' textAlign={{ xs: 'center', lg: 'left' }}  >
            <LogoThemed height='46px' />
          </Grid>

          <Grid item xs={12} lg
            textAlign={{ xs: 'center', lg: btnList.length === 0 ? 'left' : 'center' }}
          >
            <MuiHeader sx={{ color: message.color }}>{message.title} </MuiHeader>
            <MuiFormText>{message.subTitle} </MuiFormText>
          </Grid>
          <Grid item xs={12} lg='auto' textAlign={{ xs: 'center', lg: 'right' }} rowSpacing={1} container>
            {
              btnList.filter(p => p.show).map((p, idx) =>
                <Grid key={idx} item xs={12}  >
                  <Button variant='contained' onClick={p.onClick}>    {p.title}  </Button>
                </Grid>)
            }
          </Grid>
        </Grid>
      </MonoBgBox>
    </Grid>);
};

export default StatusCard;
