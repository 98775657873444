import { PeriodEnum } from '@/features/account/types';
import React from 'react';
import { ToggleButton, ToggleButtonGroupProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StyledToggleButtonGroup from '@/componentsMui/Shared/Widgets/StyledToggleButtonGroup';

const PeriodButtonGroup = ({ sx = {}, ...others }: ToggleButtonGroupProps) => {
    const { t } = useTranslation('translations');

    return (
        <StyledToggleButtonGroup
            exclusive
            sx={{
                ...sx
            }}
            {...others} >
            <ToggleButton  value={PeriodEnum.DAY}>{t('form.buttons.period.day')}</ToggleButton>
            <ToggleButton value={PeriodEnum.WEEK}>{t('form.buttons.period.week')}</ToggleButton>
            <ToggleButton value={PeriodEnum.MONTH}>{t('form.buttons.period.month')}</ToggleButton>
        </StyledToggleButtonGroup>
    );
};

export default PeriodButtonGroup;
