import * as React from 'react';

import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import { Employee, LinkedCards } from '@/features/employee/types';
import { EmployeeDetailsStep } from './ActiveEmployeeForm';
import { linkCard } from '@/features/employee/slice';
import BaseBox from '@/componentsMui/Shared/Widgets/Box/BaseBox';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { getCurrencySign } from '@/features/fees/slice';
import { showException } from '@/features/swal/slice';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import MuiFormHelperText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormHelperText';

interface Props {
	onClose: () => void,
	fetchData: () => void,
	setStep: (step: EmployeeDetailsStep) => void,
	employee: Employee;
	availableCards: any[],
}

const ActiveEmployeeAddCardStep = ({ onClose, employee, setStep, fetchData, availableCards }: Props) => {
	const { t } = useTranslation('translations');


	return (
		<>
			<Grid item container alignItems="center" pb={1}>
				<Grid item xs={12}>
					<FormHeader title={`${employee.firstName} ${employee.lastName}`} onBack={() => setStep(EmployeeDetailsStep.MAIN)} mb='0' />
				</Grid>
			</Grid>
			<Grid item xs={12} pb={1}>
				<MuiFormHelperText sx={{ fontSize: '0.813rem', fontWeight: '700', lineHeight: '1.188rem' }}>{t('employee.activeEmployeeForm.chooseCardHelperText')}</MuiFormHelperText>
			</Grid>
			<Grid item xs={12}>
				{availableCards.map(card => <Card card={card} employee={employee} setStep={setStep} fetchData={fetchData} key={card.cardId} />)}
			</Grid>
			<Grid item xs={12} flex={1} alignItems='flex-end' justifyContent='space-between' mt={8} display='flex'>
				<Button
					onClick={onClose}
					variant='customOutlined'
				>{t('form.buttons.close')}</Button>
			</Grid>
		</>
	);
};

interface CardProps {
	card: LinkedCards,
	employee: Employee,
	fetchData: () => void,
	setStep: (step: EmployeeDetailsStep) => void,
}

const Card = ({ card, employee, fetchData, setStep }: CardProps) => {


	const onCardLink = () => {
		const link = async () => {
			try {
				await linkCard(employee.employeeId, card.cardId);
				fetchData();
				setStep(EmployeeDetailsStep.MAIN);
			} catch (e) {
				showException(e);
			}
		};
		link();
	};

	return (
		<BaseBox padding='0.5rem' mb={1} onClick={onCardLink} sx={{ cursor: 'pointer' }}>
			<Grid container>
				<Grid item xs={2} display='flex' justifyContent='center' alignItems='center'><CreditCardIcon /></Grid>
				<Grid item xs={10}>
					<MuiFormText>{card.name}</MuiFormText>
					<MuiFormText>{`${card.number} (${getCurrencySign(card.currency)} ${card.balance})`}</MuiFormText>
				</Grid>
			</Grid>
		</BaseBox>
	);
};

export default ActiveEmployeeAddCardStep; 
