import React from 'react';
import { useTheme } from '@mui/styles';

import { Theme, Box as MuiBox, Typography, } from '@mui/material';
import { TransactionType } from '@features/transactions/types';
import { ArrowDownward, ArrowUpward, CurrencyExchange, Percent } from '@mui/icons-material';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';

interface TransactionIconProps {
	activity: string,
	beneficiaryName?: string,
}

const TransactionLine = ({ activity, beneficiaryName }: TransactionIconProps) => {
	let icon;
	const theme = useTheme() as Theme;
	const nameArray = beneficiaryName?.split(' ') || [];
	const initials = `${nameArray[0] ? nameArray[0].charAt(0) : ''}${nameArray[1] ? nameArray[1].charAt(0) : ''}`;
	switch (activity) {
		case TransactionType.TRANSFER:
		case TransactionType.WITHDRAW:
		case TransactionType.PAYMENT:
		case TransactionType.PURCHASE:
			icon = <ArrowUpward sx={{ fill: theme.palette.text.primary, }} />;
			break;
		case TransactionType.DEPOSIT:
			icon = <ArrowDownward sx={{ fill: theme.palette.text.primary, }} />;
			break;
		case TransactionType.FX_TRANSACTION:
		case TransactionType.EXCHANGE:
			icon = <CurrencyExchange sx={{ fill: theme.palette.text.primary, }} />;
			break;
		case TransactionType.SYSTEM_FEE:
		case TransactionType.CURRENCY_FEE:
		case TransactionType.MONTHLY_FEE:
		case TransactionType.SERVICE_FEE:
		case TransactionType.COMMISSION:
		case TransactionType.CARD_OPERATION_FEE:
		case TransactionType.EXT_TRANSACTION_FEE:
			icon = <Percent sx={{ fill: theme.palette.text.primary, }} />;
			break;
		case TransactionType.CARD_TOP_UP:
			icon = <CreditCardOutlinedIcon sx={{ fill: theme.palette.text.primary, }} />;
			break;
		default:
			icon = initials ? <Typography color="text.primary" fontSize='1.5rem' fontWeight='700'>{initials}</Typography> : null;
	}

	return (
		<>
			{icon &&
				<MuiBox sx={{
					borderRadius: theme.mainBorderRadius,
					background: theme.form.bgDefault,
				}}
					className='transaction-icon'
				>
					{icon}
				</MuiBox >
			}
		</>
	);
};

export default TransactionLine;
