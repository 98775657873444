// import _ from 'lodash';
import { AppThunk } from '@/store';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';


const useFetchWithDelay = () => {
    const dispatch = useDispatch();

    const fetchWithDelay = useCallback(async (func: AppThunk, delays: number[]) => {


        delays.map(delay => {
            setTimeout(() => {
                dispatch(func);
            }, delay);
        });

    }, [dispatch]);

    return { fetchWithDelay };

};

export default useFetchWithDelay;
