import * as React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem } from '@mui/material';
import { useField } from 'formik';
import StyledSelect from '@/componentsMui/Shared/Widgets/StyledSelect';

interface Props {
	fieldName: string,
	options: { key: string, value: string }[],
	fieldLabel?: string,
	onDropdownChange?: (value: any) => void,
}

const Dropdown = ({ options, fieldName, fieldLabel, onDropdownChange }: Props) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField(fieldName);
	const { setValue } = helpers;
	const { value } = meta;

	const handleChange = (event: any) => {
		const newValue = event.target.value;
		setValue(newValue);
		onDropdownChange && onDropdownChange(newValue);
	};

	const validationFailed = meta && meta.touched && meta.error;

	return (
		<FormControl fullWidth>
			{fieldLabel && <InputLabel> {fieldLabel}</InputLabel>}
			<StyledSelect
				name={fieldName}
				value={value}
				displayEmpty
				onChange={handleChange}
			>
				{options.length > 0 &&
					options.map((item) => (
						<MenuItem key={item.key} value={item.key}>
							<div className='select-primary-Item'>{item.value} </div>
						</MenuItem>
					))}
			</StyledSelect>
			{validationFailed && <FormHelperText>{meta.error}</FormHelperText>}
		</FormControl>
	);
};

export default Dropdown;
