import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import { useTranslation } from 'react-i18next';
import MuiTextFieldInfo from '@shared/Widgets/MuiText/MuiTextFieldInfo';

interface Props {
	acceptedFiles?: string,
	selectedFiles: any[],
	label?: string,
	uploadFiles: (fles: any) => void,
	deleteAttachment: (index: number) => void
}
export const inputAcceptedFiles = 'image/jpg, image/png, image/jpeg, .pdf, .doc, .docx';


const FileUploadBox = (props: Props) => {

	const { t } = useTranslation('translations');

	const hiddenFileInput = React.useRef(null);
	const handleUploadClick = () => {
		hiddenFileInput.current.click();
	};

	return (
		<Box sx={(theme) => ({
			background: theme.backgroundBoxLevel1,
			borderRadius: theme.mainBorderRadius,
			p: 2,
			width: '100%',
			mb: { xs: 3, md: 0 },
			mt: 1
		})}>
			<Grid item container justifyContent="space-between" alignItems="center">
				<Grid item>
					<Typography fontSize='1rem' fontWeight={700}>
						{props.label ?? t('form.fields.selectFile')}
					</Typography>
					{props.selectedFiles.length > 0 ? (
						<Grid item mt={1}>
							{props.selectedFiles.map((file, index) =>
								<Box display={'flex'} alignItems='center' key={index} className='fileList'>
									<MuiTextFieldInfo key={index} variant='highlight'> {file.name} </MuiTextFieldInfo>
									<MuiCloseIcon width='16px' height='16px'
												  onClick={() => {
													  props.deleteAttachment(index);
												  }} />

								</Box>
							)}
						</Grid>
					) : <Typography fontSize='0.8rem' fontWeight={300}>
						{t('form.validator.fileNotUpload')}
					</Typography>}
				</Grid>
				<Grid item>
					<Button
						onClick={handleUploadClick}
						variant={'outlined'}
					>
						{props.label ?? t('form.fields.selectFile')}
					</Button>
					<input type='file'
						   multiple
						   accept={props.acceptedFiles ?? inputAcceptedFiles}
						   name='files'
						   ref={hiddenFileInput}
						   onChange={e => {
							   props.uploadFiles(e.currentTarget.files);
							   e.currentTarget.value = '';
						   }}
						   style={{ display: 'none' }}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default FileUploadBox;
