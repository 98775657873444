
import { postTransferFee } from '@/features/fees/slice';
import { TransferFeePayloadType } from '@/features/fees/types';
import { showErrorNotification } from '@/features/swal/slice';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';

const useFeeDebounce = (initFee: number) => {

	const [fee, setFee] = useState<number>(initFee);
	const [feeString, setFeeString] = useState<string>(initFee.toString());
	const [feeError, setFeeError] = useState<boolean>(false);
	const [feeLoading, setFeeLoading] = useState<boolean>(false);

	const callGetFee = useMemo(() => debounce(
		async (accountId: number, proc: string, amount: number) => {
			if (amount <= 0) {
				setFee(0);
				return;
			}
			
			setFeeLoading(true);
			const feePayload: TransferFeePayloadType = { accountId, proc, amount };

			try {
				if ((accountId) && (amount) && (proc)) {
					const result = await postTransferFee(feePayload);
					setFee(result.fee);
					setFeeString(result.feeString);
					setFeeError(false);
				}
			}
			catch (e) {
				setFeeError(true);
				showErrorNotification(e);
			} finally {
				setFeeLoading(false);
			}
		}, 500)
		, []
	);

	return { fee, setFee, setFeeString, feeError, callGetFee, feeLoading, feeString };
};

export default useFeeDebounce;
