import { Grid } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import './TradeHistory.css';



const TradeHistoryHeaders = () => {
	const { t } = useTranslation('translations');

	return (
		<Grid container alignItems='center' >
			<Grid item className='trade-line__time'>
				<MuiTextCaption color='text.secondary'>
					{t('tokens.tradeHistory.time')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='trade-line__market'>
				<MuiTextCaption color='text.secondary'>
					{t('tokens.tradeHistory.market')}
				</MuiTextCaption>
			</Grid> 
			<Grid item className='trade-line__type'>
				<MuiTextCaption color='text.secondary'>
					{t('tokens.tradeHistory.orderType')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='trade-line__from'>
				<MuiTextCaption color='text.secondary'>
					{t('tokens.tradeHistory.from')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='trade-line__to'>
				<MuiTextCaption color='text.secondary'>
					{t('tokens.tradeHistory.to')}
				</MuiTextCaption>
			</Grid> 
			<Grid item className='trade-line__price'>
				<MuiTextCaption color='text.secondary'>
					{t('tokens.tradeHistory.price')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='trade-line__fee'>
				<MuiTextCaption color='text.secondary'>
					{t('tokens.tradeHistory.fee')}
				</MuiTextCaption>
			</Grid>
			<Grid item className='trade-line__status' textAlign='right'>
				<MuiTextCaption color='text.secondary'>
					{t('tokens.tradeHistory.status')}
				</MuiTextCaption>
			</Grid>
		</Grid>);
};

export default TradeHistoryHeaders;
