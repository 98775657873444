import { Typography, TypographyProps } from '@mui/material';
import React, { FC } from 'react';

/*
Font size:  13px   
Font height: 19px    
Weight: 400
 */
const MuiTextTrxSmall: FC<TypographyProps> = (props: TypographyProps) => {
	return (
		<Typography 
			fontSize="0.813rem"
			fontWeight='400'
			lineHeight='1.188rem' 
			color='text.primary'
			whiteSpace='pre-line' //this wrap text
			{...props}>
			{props.children}
		</Typography >
	);
};

export default MuiTextTrxSmall;
