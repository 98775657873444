import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

interface Props {
	history: any,
	children?: any
}

const PageTop = ({ history, children }: Props) => {
	useEffect(() => {
		const unListen = history.listen(() => {
			window.scrollTo(0, 0);
		});
		return () => {
			unListen();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <>{children}</>;
};

export default withRouter(PageTop);
