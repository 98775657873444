import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogContent, Grid, IconButton, Paper } from '@mui/material';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { useTranslation } from 'react-i18next';
import { getPayoutsHistory, setCount, setLoading, setPayout, setSkip } from '@/features/earn/payoutSlice';
import { PayoutsPayload } from '@/features/earn/types';
import { showErrorNotification } from '@/features/swal/slice';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import TransactionStatusBox from '@/componentsMui/Shared/Widgets/Box/TransactionStatusBox';
import { convertDate } from '@/helpers/date';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import { hiddenBabalance } from '@/helpers/placeholders';
import NumberFormat from 'react-number-format';
import { getCurrencySign } from '@/features/fees/slice';

const TransactionFormPaper = withStyles(() => ({
	root: {
		maxHeight: '90vh',
		minHeight: '90vh',
		padding: '1rem'
	}
}))(Paper);

interface Props {
	proc: string,
	open: boolean,
	onClose: () => void
}

const PayoutHistoryForm = ({ proc, open, onClose }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const [showLoadMore, setShowLoadMore] = useState<boolean>(true);
	const { skip, take, list, count, loading } = useSelector(
		(state: RootState) => state.payouts
	);
	const { user } = useSelector((state: RootState) => state.user);

	const fetchData = useCallback(() => {
		const get = async () => {
			setShowLoadMore(true);
			try {
				dispatch(setLoading(true));
				const payload: PayoutsPayload = {
					skip: 0,
					take: take
				};
				dispatch(setCount(0));
				dispatch(setSkip(0));
				dispatch(setPayout([]));
				dispatch(getPayoutsHistory(proc, payload));
			} catch (e) {
				showErrorNotification(e);
			}
		};

		get();
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [dispatch]);

	useEffect(() => {
		if (!loading && list.length === count) {
			setShowLoadMore(false);
		}
	}, [list, count, loading]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const loadMore = async () => {
		try {
			setShowLoadMore(true);
			const payload: PayoutsPayload = {
				skip: skip,
				take: take
			};

			dispatch(getPayoutsHistory(proc, payload));

		} catch (e) {
			showErrorNotification(e);
		}

	};

	return (<>
		<Dialog
			id="payout-history-form"
			open={open}
			fullWidth
			PaperComponent={TransactionFormPaper}
			maxWidth='md'
		>	{loading ? <DialogContent style={{ height: '100vh' }}>
			<Grid container justifyContent='center' mt={2}><Spinner /></Grid >
		</DialogContent> :
			<DialogContent style={{ height: '100vh' }}>
				<Grid container direction='column' height='100%' flexWrap='nowrap'>
					<Grid item width='100%' textAlign='right'>
						<IconButton onClick={onClose}  >
							<MuiCloseIcon />
						</IconButton>
					</Grid>
					<Grid item xs={11} >
						<Grid container item xs={12} pt='1rem' pb='1rem' flexDirection='row' pr='0.5rem'>
							<Grid item xs={3}>
								<MuiTextCaption color='text.secondary'>
									{t('vaults.interestEarning.transferDate')}
								</MuiTextCaption>
							</Grid>
							<Grid item xs={3}>
								<MuiTextCaption color='text.secondary'>
									{t('vaults.interestEarning.yourInterest')}
								</MuiTextCaption>
							</Grid>
							<Grid item xs={3}>
								<MuiTextCaption color='text.secondary'>
									{t('vaults.interestEarning.monthlyRate')}
								</MuiTextCaption>
							</Grid>
							<Grid item xs={3} display='flex' justifyContent='flex-end'>
								<MuiTextCaption color='text.secondary'>
									{t('vaults.interestEarning.status')}
								</MuiTextCaption>
							</Grid>
						</Grid>


						<Grid item xs={12}>
							<div className='scroll-container'>
								{(!list || list?.length === 0) ?
									<div style={{ display: 'flex', justifyContent: 'space-around' }}>{t('vaults.allocation.earningsNoFound')}</div>
									: <Box className='scroll-section' sx={{ mb: { xs: '2rem', lg: '0' } }}>
										<div className='scrollable-content' style={{ paddingRight: '0.5rem' }}>
											{list?.length > 0 && list.map((earning, idx) => (
												<div key={earning.id + idx} >
													<Grid container p='0.75rem 0'>
														<Grid item xs={3}>
															<MuiTextCaption >{convertDate(new Date(earning.paymentDate), 'dd MMM yyyy')}</MuiTextCaption>
														</Grid>
														<Grid item xs={3} flexDirection='column' display='flex' >
															<MuiTextCaption>
																{user.hideValues ? hiddenBabalance :
																	<NumberFormat
																		displayType={'text'}
																		decimalScale={earning.ccyDisplayDecimals ?? 2}
																		fixedDecimalScale={true}
																		thousandsGroupStyle='thousand'
																		thousandSeparator={true}
																		suffix={` ${getCurrencySign(earning.ccy)}`}
																		value={earning.amountString}
																		isNumericString
																	/>}
															</MuiTextCaption>
														</Grid>
														<Grid item xs={3}>
															<NumberFormat
																displayType={'text'}
																decimalScale={2}
																fixedDecimalScale={false}
																thousandsGroupStyle='thousand'
																thousandSeparator={true}
																suffix={'%'}
																value={earning.monthlyInterestRate * 100} />
														</Grid>
														<Grid item xs={3} display='flex' justifyContent='flex-end' alignItems='center'>
															<TransactionStatusBox
																status={earning.status}
															>
																{t(`status.vaults.${earning.status}`)}
															</TransactionStatusBox>
														</Grid>
													</Grid>
													{idx + 1 < list.length && <StyledDivider />}
												</div>
											))}
										</div>
										{showLoadMore &&
											<Grid container>
												<Grid item xs={12} justifyContent='center' display='flex' pt={2}>
													<Button onClick={loadMore}>{t('transaction.table.loadMore')}</Button>
												</Grid>
											</Grid>}
									</Box>}
							</div>
						</Grid>


					</Grid>
				</Grid>
			</DialogContent>}
		</Dialog >
	</>
	);
};

export default PayoutHistoryForm;
