import React from 'react';

import { Grid, Link, ListItem } from '@mui/material';
import MuiFormLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiFormLabel';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import { Transaction } from '@/componentsMui/Transactions/TransactionStatements.spec';
import { useTranslation } from 'react-i18next';


interface AttachmentsProps {
	transaction: Transaction,
}

const Attachments = ({ transaction }: AttachmentsProps) => {
	const { t } = useTranslation('translations');

	return (
		<Grid container item xs={12} className='transaction-line'>
			<Grid item xs={12}>
				<StyledDivider />
			</Grid>
			<Grid item xs={4} >
				<MuiFormLabel color='text.secondary'>
					{t('transactiondetails.details.receipt')}
				</MuiFormLabel>
			</Grid>
			<Grid item xs={8}>
				<MuiFormLabel>
					{transaction?.attachments?.map((item, idx) => (
						<ListItem key={idx} disablePadding={true}>
							<Link variant='fileLink'>
								{item.filename}
							</Link>
						</ListItem>
					))}
				</MuiFormLabel>
			</Grid>
		</Grid>
	);
};

export default Attachments;
