import React from 'react';
import { Grid } from '@mui/material';
import { BankAccount } from '@/features/account/types';

import AccountInfo from './AccountInfo';
import AccountCard from './AccountCard';

interface BankAccounDetailsProps {
	bankAccount: BankAccount,
}

const BankAccounDetails = ({ bankAccount }: BankAccounDetailsProps): React.ReactElement => {

	return (
		<Grid id="bank-account-details"
			container
			spacing={4}
			pb={4}
		>
			<Grid item xs={12} md={6} lg={4}>
				<AccountCard bankAccount={bankAccount} />
			</Grid>
			<Grid item xs={12} md={6} lg={8} flex={1} justifyContent='space-between' flexDirection='column' display='flex'>
				<AccountInfo bankAccount={bankAccount} />
			</Grid>
		</Grid>
	);
};

export default BankAccounDetails;
