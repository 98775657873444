import { Button, Dialog, FormHelperText, Grid, IconButton, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MuiCloseIcon } from '@/assets/MuiCloseIcon.svg';
import './ExportStatementForm.css';
import LoadingPopup from '@/componentsMui/Shared/Widgets/LoadingPopup';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import useTransactionDownload from '@/helpers/customHook/useTransactionDownload';
import { ExportType } from '../TransactionStatements.spec';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { AccountType } from '@/features/operations/types';
import PdfViewerFullScreen from '../../Shared/Components/PdfViewerFullScreen';
import TokenCurrencySwitch from '@/componentsMui/Wallet/Components/TokenCurrencySwitch';

interface Props {
	accountId?: number
	type: AccountType,
	open: boolean,
	onClose: () => void
}



const formatStartTime = (date: Date) => {
	try {
		const utcDate = Date.UTC(date.getUTCFullYear(), (date.getUTCMonth()), date.getUTCDate());
		return utcDate;
	  }
	  catch (e) {
		return undefined;
	  }
};

const formatEndTime = (date: Date) => {
	try {
		const utcDate = Date.UTC(date.getUTCFullYear(), (date.getUTCMonth()), date.getUTCDate(), 23, 59, 59);
		return utcDate;
	  }
	  catch (e) {
		return undefined;
	  }
};

const ExportStatementForm = ({ type, accountId, open, onClose }: Props) => {

	const { t } = useTranslation('translations');
	const [exportType, setExportType] = useState<ExportType>(type ? ExportType.EXCEl : ExportType.PDF);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const from = formatStartTime(startDate);
	const to = formatEndTime(endDate);
	const [openPreview, setOpenPreview] = useState(false);
	const [currency, setCurrency] = React.useState<string | null>(null);

	const getLast24Month = () => {
		const d = new Date();
		d.setMonth(d.getMonth() - 24);
		return new Date(d.getFullYear(), d.getMonth(), 1);
	};
	const searchPayload = {
		type: type,
		accountId: accountId ? `${accountId}` : undefined,
		from: from?.toString() || '',
		to: to?.toString() || '',
		search: '',
		ccy: type === AccountType.TOKEN && exportType === ExportType.PDF ? currency : undefined,
	};


	const { loading, downloadExcelStatement, fileName, previewFile, getPdfStatementPreview } = useTransactionDownload(exportType, searchPayload);

	const handleTypeChange = (event: React.MouseEvent<HTMLElement>, value: ExportType,) => {
		if (value) {
			setExportType(value);
		}
	};

	const handlePreviewPdf = async () => {
		try {
			await getPdfStatementPreview();
			setOpenPreview(true);
		}
		catch (e) { setOpenPreview(false); }
	};

	const handleDownloadTransactions = async () => {

		if (exportType === ExportType.PDF) {
			await handlePreviewPdf();
		}
		else {
			await downloadExcelStatement();
		}
	};


	return (
		<LocalizationProvider dateAdapter={DateAdapter}>
			<PdfViewerFullScreen
				file={previewFile}
				savedFileName={fileName}
				isOpen={openPreview}
				onClose={() => setOpenPreview(false)} />
			<Dialog
				id="statement-download-form"
				open={open}
				fullWidth
				maxWidth="sm"
			>
				<LoadingPopup open={loading} messsage={t('transactiondetails.message.preprare')} />
				<Grid container className='download-type' sx={{ p: { xs: '1rem', md: '2rem' } }}>
					<Grid container justifyContent="space-between" alignItems="center" pb={3}>
						<Grid item xs={10}>
							<MuiHeader>{t('transaction.header.exportbutton')}</MuiHeader>
						</Grid>
						<Grid item xs={2} justifyContent='flex-end' display='flex'>
							<IconButton onClick={onClose}  >
								<MuiCloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item xs={12} pb={2} >
						<ToggleButtonGroup
							className='type-btn-group'
							value={exportType}
							exclusive
							fullWidth
							onChange={handleTypeChange}
						>
							{type !== AccountType.VAULT && <ToggleButton className='type-btn' value={ExportType.PDF}>{t('transaction.export.pdf')}</ToggleButton>}
							<ToggleButton className='type-btn' value={ExportType.EXCEl}>{t('transaction.export.excel')}</ToggleButton>
						</ToggleButtonGroup>

					</Grid>
					{(type === AccountType.TOKEN && exportType === ExportType.PDF) && <Grid item xs={12} pb={2} >
						<TokenCurrencySwitch label={t('transaction.export.ccy')} variant={'outlined'} setCurrency={(value) => setCurrency(value)} />
					</Grid>}
					<Grid item xs={12} pb={4}>
						<Grid item container className='month-selection' spacing={2}>
							<Grid item xs={12} sm={6} >
								<LocalizationProvider dateAdapter={DateAdapter}>
									<DatePicker
										disableFuture={true}
										label={t('transaction.labels.starton')}
										views={['year', 'month', 'day']}
										minDate={getLast24Month()}
										maxDate={endDate ?? new Date()}
										value={startDate}
										inputFormat={'dd-MMM-yyyy'}
										onChange={(date) => {
											setStartDate(date);
										}}
										renderInput={(params) => <TextField {...params} helperText={null} />}
									/>
								</LocalizationProvider>
								{!from && <FormHelperText>{t('form.validator.invalidDateFormat')}</FormHelperText>}
							</Grid>
							<Grid item xs={12} sm={6}>
								<LocalizationProvider dateAdapter={DateAdapter}>
									<DatePicker
										disableFuture={true}
										label={t('transaction.labels.endon')}
										views={['year', 'month', 'day']}
										minDate={startDate ?? getLast24Month()}
										maxDate={new Date()}
										value={endDate}
										inputFormat={'dd-MMM-yyyy'}
										onChange={(date) => {
											setEndDate(date);
										}}
										renderInput={(params) => <TextField {...params} helperText={null} />}
									/>
								</LocalizationProvider>
								{!to && <FormHelperText>{t('form.validator.invalidDateFormat')}</FormHelperText>}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} alignSelf='flex-end'>
						<Button
							className='month-btn'
							type="submit"
							variant="contained"
							color='primary'
							disabled={!from || !to}
							fullWidth
							onClick={handleDownloadTransactions}
						>
							{t('form.buttons.export', { type: exportType })}
						</Button>
					</Grid>


				</Grid>

			</Dialog >
		</LocalizationProvider>);
};

export default ExportStatementForm;

