import { Typography, TypographyProps } from '@mui/material';
import React, { FC } from 'react';

import { useTheme } from '@mui/styles';
import { Theme } from '@mui/material';

const MuiFormText: FC<TypographyProps> = (props: TypographyProps) => {
	const theme = useTheme() as Theme;

	return (
		<Typography
			fontSize='0.875rem'
			fontWeight='400'
			lineHeight='1.188rem'
			color={theme.palette.text.primary}
			style={{ wordWrap: 'break-word' }}
			whiteSpace='pre-line' //this wrap text 
			{...props}>
			{props.children}
		</Typography >
	);
};

export default MuiFormText;
