import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { CreateInvoicePayload, Invoice, InvoiceConfig, InvoiceOptions, InvoicesStore } from './invoicesTypes';
import { AppThunk } from '@/store';

export const initialInvoicesPagination = {
	search: '',
	skip: 0,
	take: 10,
	orderBy: 'createdDate',
	sort: 'DESC',
};

const initialState: InvoicesStore = {
	list: [],
	loading: false,
	count: 0,
	pagination: initialInvoicesPagination,
	config: {
		allowBankTransferAsPaymentOption: true,
		allowManualProductEntering: true,
		minOrderAmount: 10
	},
	options: {
		bankTransferAvailable: false,
		cryptoAddressesAvailable: false,
		cryptoCurrencies: []
	}
};

const slice = createSlice({
	name: 'invoices',
	initialState,
	reducers: {
		setList(state, action: PayloadAction<Invoice[]>) {
			state.list = action.payload;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		setCount(state, action: PayloadAction<number>) {
			state.count = action.payload;
		},
		setPagination(state, action: PayloadAction<any>) {
			state.pagination = action.payload;
		},
		setConfig(state, action: PayloadAction<InvoiceConfig>) {
			state.config = action.payload;
		},
		setOptions(state, action: PayloadAction<InvoiceOptions>) {
			state.options = action.payload;
		},
	}
});

export const { setList, setLoading, setCount, setPagination, setConfig, setOptions } = slice.actions;


export const getInvoicesList = (params: any): AppThunk => {
	return async (dispatch) => {
		try {
			dispatch(setLoading(true));
			dispatch(setPagination(params));
			const response = await request.get('/api/invoices/list', { params });

			const data = response?.data || null;
			dispatch(setList(data.list));
			dispatch(setCount(data.count));
		}
		catch (e) {
			dispatch(setList([]));
			dispatch(setCount(0));
		} finally {
			dispatch(setLoading(false));
		}
	};
};


export const getInvoicesConfig = (): AppThunk => {
	return async (dispatch) => {
		try {
			const response = await request.get('/api/invoices/config');
			const data = response?.data || null;
			if (data) {
				dispatch(setConfig(data));
			}
		}
		catch (e) {
			console.error(e);
		}
	};
};

export const getAvailablePaymentOptions = (): AppThunk => {
	return async (dispatch) => {
		try {
			const response = await request.get('/api/invoices/available-payment-options');
			const data = response?.data || null;
			if (data) {
				const options: InvoiceOptions = {
					bankTransferAvailable: data.bankTransferAvailable,
					cryptoAddressesAvailable: data.cryptoAddressesAvailable,
					cryptoCurrencies: data.cryptoCurrencies?.map(p => ({
						code: p.currencyCode,
						label: `${p.currencyName} (${p.currencyCode})`
					})) || []
				};
				dispatch(setOptions(options));
			}
		}
		catch (e) {
			console.error(e);
		}
	};
};

export const saveInvoice = async (type: 'draft' | 'issue', payload: CreateInvoicePayload) => {
	const response = await request.post(`/api/invoices/create/${type}`, payload);
	return response?.data;
};

export const updateInvoice = async (orderId: number, payload: CreateInvoicePayload) => {
	const response = await request.post(`/api/invoices/update/${orderId}`, payload);
	return response?.data;
};

export const getInvoiceDetails = async (orderId: string) => {
	const response = await request.get(`/api/invoices/details/${orderId}`);
	return response?.data;
};

export const addPropductToDraft = async (orderId: number, payload: any) => {
	const response = await request.put(`/api/invoices/invoice/product/${orderId}`, payload);
	return response?.data;
};

export const removePropductFromDraft = async (productId: number) => {
	const response = await request.delete(`/api/invoices/invoice/product/${productId}`);
	return response?.data;
};

export const issueOrder = async (orderId: number) => {
	const response = await request.post(`/api/invoices/invoice/submit/${orderId}`);
	return response?.data;
};

export const cancelOrder = async (orderId: number) => {
	const response = await request.post(`/api/invoices/invoice/cancel/${orderId}`);
	return response?.data;
};

export const markAsPaidOrder = async (orderId: number) => {
	const response = await request.post(`/api/invoices/invoice/mark-as-paid/${orderId}`);
	return response?.data;
};

export const sendInvoiceEmail = async (orderId: number, email: string) => {
	const response = await request.post(`/api/invoices/invoice/email/${orderId}`, { email });
	return response?.data;
};

export const getOrderPDF = async (
	orderId: number,
	name: string
) => {
	const response = await request.get(
		`/api/invoices/invoice/download/${orderId}`,
		{
			responseType: 'blob',
		}
	);
	const url = window.URL.createObjectURL(response.data);
	const link = document.createElement('a');
	link.download = name;
	link.href = url;
	link.className = 'hidden';
	document.body.appendChild(link);

	link.onclick = function () {
		requestAnimationFrame(function () {
			URL.revokeObjectURL(url);
			setTimeout(() => link.remove(), 300);
		});
	};

	link.click();
};

export default slice.reducer;
