import  { createContext, useContext } from 'react'; 

export type ThemeModes = 'light' | 'dark';

export type ThemeGlobalContextType = {
	mode: ThemeModes,
	setMode: (mode:ThemeModes)=>void 
};

export const ThemeContext = createContext<ThemeGlobalContextType>({
	mode: 'dark',
	setMode: () => {//change mode
},
});

export const useThemeContext = () => useContext(ThemeContext);
