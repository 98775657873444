import BaseBox from '@/componentsMui/Shared/Widgets/Box/BaseBox';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import { Card, CardIntegrationEnum, CardStatuses } from '@/features/card/types';
import { BankAccount } from '@/features/user/types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import BusinessCardsHeader from './BusinessCardsHeader';
import BusinessCardsLine from './BusinessCardsLine';
import { Button, Grid } from '@mui/material';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import BigAmount from '@/componentsMui/Wallet/Components/BigAmount';
import { getCurrencySign } from '@/features/fees/slice';
import { useHistory } from 'react-router-dom';

interface Props {
    list: Array<Card>,
    onCardSelect?: (account: BankAccount) => void
}

const BusinessCardsOverview = ({ list, onCardSelect }: Props) => {
    const { t } = useTranslation('translations');
    const history = useHistory();

    const shCards = list.filter(card => card.process.integration === CardIntegrationEnum.SH_FINANCE);
    const otherCards = list.filter(card => card.process.integration !== CardIntegrationEnum.SH_FINANCE);
    const cardForShTopUp = shCards.length > 0 && shCards.filter(c => c.status === CardStatuses.ACTIVE)[0] || null;
    const shCardForBalance = shCards.find(c => [
        CardStatuses.ACTIVE.toString(),
        CardStatuses.REJECTED.toString(),
        CardStatuses.EXPIRED.toString(),
        CardStatuses.SUSPENDED.toString(),
        CardStatuses.LOST.toString(),
        CardStatuses.STOLEN.toString(),
    ].includes(
        c.status
    )) || null;

    const handleTopup = () => {
        history.push(`/wallet/home/cards/transfers/top-up?card=${cardForShTopUp.cardId}`);
    };

    return (
        <BaseBox p='30px' sx={{ mb: { xs: 3, md: 0 }, p: { xs: '1rem', sm: '30px' } }}>
            {!list || list.length === 0 ?
                <div style={{ display: 'flex', justifyContent: 'space-around' }}> {t('cards.businessCards.noRecordsToShow')}</div>
                :
                <>
                    {shCards.length > 0 && <Grid mb={10}>
                        <Grid container alignItems='center' mb={2} mt={1} justifyContent='space-between'>
                            <Grid item>
                                <MuiHeader mr={1}>{t('cards.list.visaCards')}</MuiHeader>
                                <BigAmount
                                    currency={shCardForBalance?.currencySymbol ?? getCurrencySign('EUR')}
                                    value={shCardForBalance?.availableAmount ?? 0}
                                    fontSize='1.25rem'
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    variant='simple'
                                    onClick={handleTopup}
                                    disabled={!cardForShTopUp}
                                    sx={{ mr: 1 }}
                                >
                                    {t('cards.topUpCard.topUp')}
                                </Button>
                                <Button
                                    variant='simple'
                                    onClick={() => console.log('history')}
                                >
                                    Top up history
                                </Button>
                            </Grid>
                        </Grid>
                        <BusinessCardsHeader shCards={true} />
                        <StyledDivider />
                        {shCards.map((card, index) => (
                            <div key={card.cardId}>
                                <BusinessCardsLine card={card} onClick={onCardSelect} />
                                {index + 1 < shCards.length && <div className='cards-list'><StyledDivider /> </div>}
                            </div>
                        ))}
                    </Grid>}
                    {otherCards.length > 0 && <>
                        <Grid container alignItems='center' mb={2} mt={1} justifyContent='space-between'>
                            <MuiHeader>{t('cards.list.mastercardCards')}</MuiHeader>
                        </Grid>
                        <BusinessCardsHeader />
                        <StyledDivider />
                        {otherCards.map((card, index) => (
                            <div key={card.cardId}>
                                <BusinessCardsLine card={card} onClick={onCardSelect} />
                                {index + 1 < otherCards.length && <div className='cards-list'><StyledDivider /> </div>}
                            </div>
                        ))}
                    </>}
                </>}
        </BaseBox>
    );
};

export default BusinessCardsOverview;
