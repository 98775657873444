import React from 'react';
import { Button, Dialog, DialogContent } from '@mui/material';
import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setShowWalletDisclaimer } from '@/features/maintenance/maintenanceSlice';



const WalletAnnouncement = () => {

    const { showWalletDisclaimer } = useSelector(
        (state: RootState) => state.maintenance
    );
    const { t } = useTranslation('translations');
    const dispatch = useDispatch(); 

    const handleClose = () => {
        dispatch(setShowWalletDisclaimer(false));
    };

    return (
        <Dialog
            open={showWalletDisclaimer}
            fullWidth
            maxWidth='lg'
        >
            <DialogContent className='highlight-regular'>
                <h3>🚀 Important Update: New Wallet Addresses 🚀</h3>
                <h3>Dear Valued Users,</h3>
                <p>We are thrilled to announce the release of the new DTS Money Crypto Section! As part of our ongoing commitment
                    to providing you with the best experience, we have made significant improvements to our platform,
                    including updates to your cryptocurrency wallet addresses.</p>
                <h3>Whats<span>&#39;</span>s New?</h3>
                <p>We have implemented a series of enhancements which will ultimately result in much faster withdrawals and transactions.
                    The entire crypto infrastructure is now under our direct control, eliminating the need for third-party providers.
                    This means you<span>&#39;</span>ll enjoy enhanced reliability, reduced latency, and increased overall efficiency when managing your digital assets.</p>


                <h3>Attention Required - New Wallet Addresses</h3>
                <p>This major update includes the issuance of brand new wallet addresses for all supported cryptocurrencies.
                    To continue enjoying seamless trading and hassle-free transactions,
                    we kindly request that you update your records and start using the newly generated wallet addresses for all
                    your cryptocurrency deposits and withdrawals.
                    It<span>&#39;</span>s crucial to note that any funds sent to the old wallet addresses will unfortunately be irrecoverably lost.</p>

                <p>Should you have any questions or need assistance, our dedicated support team is ready to help.</p>
                <p>Thank you for choosing DTS Money as your cryptocurrency partner.
                    We look forward to providing you with an even more seamless,
                    secure, and efficient trading experience.</p>
                <br />
                <p>Best regards,
                    <br />DTS Money Team 🚀</p>
                <br />
                <Button variant='customOutlined' onClick={handleClose}>{t('form.buttons.close')}</Button>
            </DialogContent>
        </Dialog >
    );
};

export default WalletAnnouncement;
