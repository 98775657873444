import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { Grid, IconButton } from '@mui/material';
import React from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MaintenanceChip from '@/componentsMui/Shared/Widgets/MaintenanceChip';

interface Props {
	title?: string,
	onBack?: () => void,
	showBack?: boolean,
	mb?: string,
	showMaintenace?: boolean,
	children?: any,
}

const FormHeader = ({ title, onBack, showBack = true, mb, children, showMaintenace }: Props): React.ReactElement => {
	return (
		<Grid id='form-header' container alignItems='center'
			onClick={onBack}
			sx={{ mb: mb ?? '1rem', cursor: onBack ? 'pointer' : 'inherit' }} flexWrap='nowrap' >
			{showBack &&
				<Grid item pr='1rem' sx={{ pr: { xs: '0.5rem', md: '1rem' } }}>
					<IconButton sx={{ pl: 0, pr: 0.5 }} >
						<ArrowBackIosNewIcon className='svg-icon-stroke' />
					</IconButton>
				</Grid>}
			<Grid item>
				<MuiHeader> {title ?? children}
				</MuiHeader>
			</Grid>
			{showMaintenace &&
				<Grid item container justifyContent='flex-end'>
					<MaintenanceChip />
				</Grid>
			}
		</Grid>
	);
};

export default FormHeader;
