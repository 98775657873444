
import * as React from 'react';
import { useTheme } from '@mui/styles';


import { Box as MuiBox, BoxProps, Theme } from '@mui/material';
import { FC } from 'react';

/* Placed on on top of Base Block*/
const InputBox: FC<BoxProps> = (props: BoxProps) => {

	const theme = useTheme() as Theme;
	const { sx, ...other } = props;

	return (<MuiBox id='input-box'
		sx={{
			color: theme.palette.text.primary,
			borderRadius: theme.inputField.borderRadius,
			background: theme.palette.background.paper,
			border: theme.palette.mode === 'dark' ? 'unset' : theme.inputField.border,
			...sx,
			//boxShadow: theme.shadowMain
		}}
		{...other}>{props.children}
	</MuiBox>);
};


export default InputBox;
