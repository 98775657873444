import React, { memo } from 'react';
import logoLight from '@/assets/LogoLight.svg';
import logoDark from '@/assets/LogoDark.svg';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
//import { useHistory } from 'react-router-dom';


const LogoThemed = (props: any) => {

	const theme = useTheme() as Theme;
	//const history = useHistory();

	//const handleClick = window.history.state ? () => { props?.onClick && props?.onClick(); history.push('/wallet/home/summary'); } : undefined;


	const handleClick = window.history.state ? () => { props?.onClick && props?.onClick();} : undefined;

//	console.log(handleClick);

	//const handleClick = () => { props?.onClick && props?.onClick(); history.push('/wallet/home'); };
	return (theme.palette.mode === 'dark' ?
		<img src={logoDark}  {...props} onClick={handleClick} /> :
		<img src={logoLight}  {...props} onClick={handleClick} />
	);
};


/*
return (theme.palette.mode === 'dark' ? <object type="image/svg+xml" data={logoDark}  {...props} /> :
		<object type="image/svg+xml" data={logoLight}  {...props} />
	);
*/
export default memo(LogoThemed);
