/* eslint-disable react-hooks/exhaustive-deps */
import { RootState } from '@/rootReducer';
import Login from '../Auth/login/Login';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import FormHeader from '../Wallet/Balances/Deposit/Components/FormHeader';
import { confirmTransaction, rejectTransaction, verifyTransaction } from '@/features/openId/openIdSice';
import { useTranslation } from 'react-i18next';
import MinimalLayout from '../Layout/MinimalLayout';
import { AuthorizePayload } from '@/features/openId/openIdType';
import { logout } from '@/features/user/userSlice';
import LoadingPageMui from '@/pages/LoadingPageMui';
import * as Currency from 'currency-codes';
import { TokenGrantTypeEnum } from '@/features/user/types';
import { clearCreditials } from '@/features/user/credentialsSice';

export const formatCardNumber = (cardNumber?: string) => {
    if (!cardNumber) { return ''; }
    const lastDigits = cardNumber.substring(cardNumber.length - 4);
    return `•••• ${lastDigits}`;
};


const Authorize = () => {

    const useQuery = () => new URLSearchParams(useLocation().search);
    const dispatch = useDispatch();
    const { t } = useTranslation(['translations']);
    const theme = useTheme();
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const paddingTop = matchDownSm ? 10 : 60;

    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const query = useQuery();

    const redirect_uri = query.get('redirect_uri');
    const redirectUri = query.get('redirectUri');
    const clientCallbackUrl = redirect_uri || redirectUri;
    const nonce = query.get('nonce');
    const [verified, setVerified] = useState(false);
    const [cleaned, setCleaned] = useState(false);

    const [nouceData, setNouceData] = useState<any>({});
    const { token, tokenGrantType } = useSelector(
        (state: RootState) => state.credentials
    );

    const validTokenExits = cleaned && token && tokenGrantType === TokenGrantTypeEnum.OAUTH;

    const redirectError = useCallback(async (redirectUrl: string, error: string) => {
        const options = { error: error };
        const qs = new URLSearchParams(options).toString();
        setLoading(true);
        if (validTokenExits) {

            setLoading(true);
            await dispatch(logout());
        }
        window.location.replace(`${redirectUrl}?${qs}`);
        setLoading(false);
    }, [dispatch, validTokenExits]);

    //auto decline  after 10 min 
    useEffect(() => {
        const timer = setTimeout(() => {
            redirectError(clientCallbackUrl, 'server_error');
        }, 10 * 60 * 1000); //10 min
        return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //initi cleanup
    useEffect(() => {
        const clean = async () => {
            await dispatch(clearCreditials());
            setCleaned(true);
        };
        clean();
    }, [dispatch]);

    useEffect(() => {
        const verifyState = async (state: string) => {
            try {
                const result = await verifyTransaction(state);
                if (!result.valid) {
                    redirectError(clientCallbackUrl, 'server_error');
                }
                else {
                    setVerified(true);
                }
            } catch (e) {
                if (e?.status === 401) {
                    dispatch(clearCreditials());
                }
                else {
                    redirectError(clientCallbackUrl, 'server_error');
                }
            }
        };

        if (validTokenExits) {
            const state = query.get('state');
            verifyState(state);
        }
    }, [validTokenExits]);


    useEffect(() => {
        try {
            if (nonce) {
                const nounceString = Buffer.from(nonce, 'base64').toString('utf8');
                const nounceParsed = nounceString ? JSON.parse(nounceString) : '';
                setNouceData(nounceParsed);
            }
        }
        catch (e) {
            redirectError(clientCallbackUrl, 'server_error');
        }

    }, [clientCallbackUrl, nonce, redirectError]);


    const handleCancelApprove = async () => {
        try {
            const paylod: AuthorizePayload = {
                response_type: query.get('response_type'),
                redirect_uri: clientCallbackUrl,
                client_id: query.get('client_id'),
                scope: query.get('scope'),
                acr_values: query.get('acr_values'),
                state: query.get('state'),
                nonce: nonce,
            };
            await rejectTransaction(paylod);
        }
        catch (e) { console.log(e); }
        redirectError(clientCallbackUrl, 'login_required');
    };


    const handleConfirm = async () => {
        setSubmitting(true);
        try {
            const paylod: AuthorizePayload = {
                response_type: query.get('response_type'),
                redirect_uri: clientCallbackUrl,
                client_id: query.get('client_id'),
                scope: query.get('scope'),
                acr_values: query.get('acr_values'),
                state: query.get('state'),
                nonce: nonce,
            };
            const result = await confirmTransaction(paylod);
            //const result = { redirectUri: clientCallbackUrl, code: '1232', state: 'asdfasf', acr_values: 'qwer', scope: 'asdfsdf' };
            const redirect = `${result.redirectUri}?code=${result.code}&state=${result?.state}&acr_values=${result.acr_values}&scope=${result.scope}`;
            window.location.replace(redirect);
            setSubmitting(false);
        }
        catch (e) {
            setSubmitting(false);
            redirectError(clientCallbackUrl, 'server_error');
        }
    };



    const amount = nouceData?.purchaseAmount;
    const purchaseExponent = nouceData?.purchaseExponent;
    const purchaseCurrency = nouceData?.purchaseCurrency;
    const currency = purchaseCurrency ? Currency.number(purchaseCurrency) : undefined;
    const displayCurrency = currency?.code ? `${currency?.code} ` : '';
    const amountParsed = (amount && purchaseExponent) ? `${displayCurrency}${amount.slice(0, -2)}.${amount.slice(-2)}` : [];

    const items = [
        {
            title: t('login.external.amount'),
            value: amountParsed,
        },
        {
            title: t('login.external.marchant'),
            value: `${nouceData?.merchantName || ''}`
        },
        {
            title: t('login.external.cardNumber'),
            value: `${formatCardNumber(nouceData?.ppan)}`
        }
    ];

    const filteredItems = items.filter(p => !!p.value);

    if (loading||!cleaned) { return (<LoadingPageMui />); }


    if (validTokenExits && verified) return (
        <MinimalLayout paddingTop={paddingTop}>
            <Grid container justifyContent="center">
                <Grid item xs={12} >
                    <FormHeader title={t('login.external.confirmPayment')} showBack={false} />
                </Grid>
                {filteredItems.map((item, idx) => {
                    return (
                        <Grid key={idx} item xs={12} mt={3} >
                            <Grid item xs={12} >
                                <Typography
                                    fontSize='0.813rem'
                                    fontWeight='400'
                                    lineHeight='1.288rem'
                                    color={'text.secondary'}
                                > {item.title} </Typography>
                            </Grid>
                            <Grid item xs={12} >
                                <Typography
                                    fontSize='0.813rem'
                                    fontWeight='800'
                                    lineHeight='1.288rem'
                                    color={'text.primary'}
                                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                >   {item.value} </Typography>
                            </Grid >
                        </Grid>);
                })}
                <Grid item xs={12} container pt='2rem' columnSpacing={2}>
                    <Grid item xs={6}>
                        <Button
                            variant='customOutlined'
                            color='secondary'
                            onClick={handleCancelApprove}
                            sx={{ width: { xs: '100%' } }}
                        >  {t('login.external.deny')}
                        </Button>
                    </Grid>
                    <Grid item xs={6} container justifyContent='flex-end'>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleConfirm}
                            sx={{ width: { xs: '100%' } }}
                            disabled={submitting}
                        >
                            {t('login.external.authorize')}
                        </Button>
                    </Grid>
                </Grid>

            </Grid >
        </MinimalLayout >);

    if (!validTokenExits) { return (<Login isOauthLogin={true} onCancel={handleCancelApprove} />); }

    return <LoadingPageMui />;
};


export default Authorize;
