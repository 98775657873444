import React from 'react';
import { Dialog, DialogContent, Grid } from '@mui/material';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import { useTranslation } from 'react-i18next';
import FormPaperLarge from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLarge';
import ShareNeuralId from '@/componentsMui/Shared/Components/ShareNeuralId';
import { showSuccess } from '@/features/swal/slice';

interface Props {
	open: boolean,
	onClose: () => void
}

const EnableNeuralForm = ({ open, onClose }: Props): React.ReactElement => {

	const { t } = useTranslation('translations');


	const handleSuccess = () => {
		onClose();
		showSuccess(); 
	};
	
	return (
		<Dialog
			id="enable-neural-form"
			open={open}
			maxWidth='sm'
			PaperComponent={FormPaperLarge}
		>
			<DialogContent style={{ padding: 0, display: 'flex' }}>
				<Grid container>
					<Grid item xs={12}>
						<FormHeader onBack={onClose} title={t('security.options.enableNeural')} />
					</Grid>
					{open &&
						<Grid item xs={12}>
							<ShareNeuralId onSuccess={handleSuccess} />
						</ Grid >
					}
				</ Grid >
			</DialogContent>
		</Dialog >

	);
};

export default EnableNeuralForm;
