import React from 'react';

import { Grid, IconButton, Theme, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SxProps } from '@mui/system';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

interface Props {
	onClick?: () => void
	sx?: SxProps<Theme>;
}

const BackButton = ({ onClick, sx }: Props) => {
	const { t } = useTranslation('translations');
	const history = useHistory();
	const theme = useTheme();
	return (
		<IconButton
			sx={{ ...sx, px: 0, py: 1 }}
			onClick={() => onClick ? onClick() : history.goBack()}>
			<Grid container alignItems='center' color={theme.form.textSecondary}>
				<KeyboardBackspaceIcon sx={{ color: theme.form.textSecondary }} />
				<Typography pl='10px' fontWeight={700}  >{t('cards.newCard.cardMenu.back')}</Typography>
			</Grid>
		</IconButton>
	);
};

export default BackButton;
