import React, { useEffect } from 'react';
import {  Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';
import EmployeesPlaceholder from './EmployeesPlaceholder';
import { getPendingEmployees } from '@/features/employee/slice';
import PendingEmployeeLine from './Components/PendingEmployeeLine';

const PendingEmployees = (): React.ReactElement => {
    const { pendingEmployees, loading } = useSelector((state: RootState) => state.employee);
    const dispatch = useDispatch();

    useEffect(() => {
		dispatch(getPendingEmployees());
	}, [dispatch]);

    const fetchPendingEmployees = () => {
        dispatch(getPendingEmployees());
    };


    return (

        <>
            {loading ? <Spinner /> :
                <>
                    {pendingEmployees.length === 0 ?
                        <Grid item xs={12}>
                            <EmployeesPlaceholder active={false} />
                        </Grid> :
                        <Grid item xs={12}>
                            {pendingEmployees.map(employee => <PendingEmployeeLine employee={employee} key={employee.referenceCode} fetchData={fetchPendingEmployees} />)}
                        </Grid>
                    }
                </>
            }
        </>
    );
};

export default PendingEmployees;
