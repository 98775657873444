import { ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles'; 


const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '&.MuiToggleButtonGroup-root': {
    background: 'transparent'
  },
  '& .MuiToggleButtonGroup-grouped': {
    margin: 0,
    borderRadius: theme.mainBorderRadius,
    border: '1px solid rgba(255, 255, 255, 0.06)',
    width: '40px',
    height: '40px',
    color: theme.palette.text.primary,
    '&:not(:first-of-type)': {
      border: '1px solid rgba(255, 255, 255, 0.06)',
      marginLeft: '10px'
    },
    '&.Mui-selected, &.Mui-selected:hover': { 
      background: theme.selectedItemBg.default,
      WebkitBackgroundClip: 'text',
      backgroundClip: 'text',
      WebkitTextFillColor: theme.palette.text.primary,
      color: 'transparent',
      borderRadius: theme.inputField.borderRadius,
      position: 'relative',
      zIndex: 0,
      textDecoration: 'none',
      '::before': {
        content: '""',
        position: 'absolute',
        zIndex: -1,
        inset: 0,
        border: '1px solid transparent',
        borderRadius: theme.inputField.borderRadius,
        background: 'inherit',
        backgroundOrigin: 'border-box',
        backgroundClip: 'border-box',
        'WebkitMask': ' linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
        'WebkitMaskComposite': 'xor',
        'MaskComposite': 'exclude',
        'WebkitMaskRepeat': 'no-repeat'
      },
    }
  },
}));

export default StyledToggleButtonGroup;
