import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { logout } from '@features/user/userSlice';
import { UserType } from '@features/user/types';
import { showErrorNotification } from '@features/swal/slice';
import { MenuList, Grid, ListItemIcon, ListItemText, Theme, Divider, Box } from '@mui/material';
import NavbarMenuItemNavLink from '@/componentsMui/Shared/Widgets/NavbarMenuItemNavLink';
import CollapsibleMenuItemNavLink from '@/componentsMui/Shared/Widgets/CollapsibleMenuItemNavLink';
import { RootState } from '@/rootReducer';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { makeStyles, useTheme } from '@mui/styles';
import { openDrawer, closeDrawer } from '@features/drawer/slice';
import LanguageDropdown from '@/componentsMui/LanguageDropdown/LanguageDropdown';
import { SwipeableDrawer } from '@mui/material';
import IconWrapBox from '@shared/Widgets/Box/IconWrapBox';
import MuiTextLabel from '@shared/Widgets/MuiText/MuiTextLabel';
import useUserOperations from '@helpers/customHook/useUserOperations';
import SwitchUserDropdown from '@shared/Components/SwitchUserDropdown/SwitchUserDropdown';
import { isKycTierVerified } from '@/helpers/kyc';
import HomeIcon from '../Shared/CustomIcons/HomeIcon';
import MenuEmployeeIcon from '../Shared/CustomIcons/MenuEmployeeIcon';
import MenuBusinessIcon from '../Shared/CustomIcons/MenuBusinessIcon';
import MenuHelpIcon from '../Shared/CustomIcons/MenuHelpIcon';
import MenuMyAccount from '../Shared/CustomIcons/MenuMyAccount';
import MenuTrustIcon from '../Shared/CustomIcons/MenuTrustIcon';
import MenuEarnIcon from '../Shared/CustomIcons/MenuEarnIcon';
import MenuCardsIcon from '../Shared/CustomIcons/MenuCardsIcon';
import MenuTokenIcon from '../Shared/CustomIcons/MenuTokenIcon';
import MenuWalletIcon from '../Shared/CustomIcons/MenuWalletIcon';
import MainAccountIcon from '../Shared/CustomIcons/MainAccountIcon';
import MenuInvoiceIcon from '../Shared/CustomIcons/MenuInvoiceIcon';



const useStyles = makeStyles(() => ({
	divider: {
		margin: '20px 20px 10px 20px!important'
	}
}));

const MobileDrawer = (): React.ReactElement => {

	const { t } = useTranslation('translations');
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const theme = useTheme() as Theme;
	const operations = useUserOperations();

	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const { isOpen } = useSelector(
		(state: RootState) => state.drawer
	);

	const kycTierVerified = isKycTierVerified(user);

	//side navbar is fixed position, it can;t inherit parent width
	//const sideBar = document.querySelector('.sidebar')?.clientWidth ?? '20%';

	const { businessEnabled } = useSelector((state: RootState) => state.status);

	const signout = async (key?: UserType) => {
		try {
			dispatch(logout());
			return history.push('/auth' + (key ? `?mode=${key}` : ''));
		}
		catch (e) {
			showErrorNotification(e);
		}
	};


	if (!user) {
		return <></>;
	}

	const showAccounts = operations.find(p => p.path === 'accounts')?.allowed ?? false;
	const showCards = operations.find(p => p.path === 'cards')?.allowed ?? false;
	const showTrust = operations.find(p => p.path === 'trust')?.allowed ?? false;
	const showEarn = operations.find(p => p.path === 'earn')?.allowed ?? false;
	const showWallet = operations.find(p => p.path === 'wallets')?.allowed ?? false;
	const showTokens = operations.find(p => p.path === 'tokens')?.allowed ?? false;
	const showInvoices = operations.find(p => p.path === 'invoices')?.allowed ?? false;

	return (
		<SwipeableDrawer
			open={isOpen}
			onOpen={() => dispatch(openDrawer())}
			onClose={() => dispatch(closeDrawer())}
			PaperProps={{
				style: {
					minWidth: '300px',
					padding: '1rem',
				}
			}}
		>
			<Grid item container direction='column' flex={1}>
				<Grid item display='flex' justifyContent={kycTierVerified ? 'space-between' : 'flex-end'}>
					{kycTierVerified &&
						<IconWrapBox sx={{ minWidth: '192px' }}>
							<SwitchUserDropdown user={user} />
						</IconWrapBox>
					}
					<IconWrapBox className='lang-drop-box' sx={{ p: '0 0.5rem' }}>
						<LanguageDropdown />
					</IconWrapBox>
				</Grid>
				<Grid item >
					<Box>
						<NavbarMenuItemNavLink disabled>
							<MuiTextLabel>{t('navbar.menu')}</MuiTextLabel>
						</NavbarMenuItemNavLink>

						<NavbarMenuItemNavLink component={NavLink} to='/wallet/home/summary' onClick={() => dispatch(closeDrawer())}>
							<ListItemIcon>
								<HomeIcon className="stroke" />
							</ListItemIcon>
							<ListItemText>{t('pages.home')}</ListItemText>
						</NavbarMenuItemNavLink>

						{showAccounts &&
							<NavbarMenuItemNavLink component={NavLink} to='/wallet/home/accounts?tab=accounts' onClick={() => dispatch(closeDrawer())}>
								<ListItemIcon>
									<MainAccountIcon className="stroke" />
								</ListItemIcon>
								<ListItemText>{t('pages.banking')}</ListItemText>
							</NavbarMenuItemNavLink>
						}
						{showWallet &&
							<CollapsibleMenuItemNavLink to='/wallet/home/wallets'>
								<ListItemIcon>
									<MenuWalletIcon />
								</ListItemIcon>
								<ListItemText>{t('pages.wallet')}</ListItemText>
							</CollapsibleMenuItemNavLink>
						}
						{showTokens &&
							<>
								<CollapsibleMenuItemNavLink to='/wallet/home/tokens'>
									<ListItemIcon>
										<MenuTokenIcon />
									</ListItemIcon>
									<ListItemText>{t('pages.tokens')}</ListItemText>
								</CollapsibleMenuItemNavLink>
							</>
						}
						{showCards &&
							<NavbarMenuItemNavLink component={NavLink} to='/wallet/home/cards' onClick={() => dispatch(closeDrawer())}>
								<ListItemIcon>
									<MenuCardsIcon />
								</ListItemIcon>
								<ListItemText>{t('pages.cards')}</ListItemText>
							</NavbarMenuItemNavLink>
						}
						{showEarn &&
							<CollapsibleMenuItemNavLink to='/wallet/home/earn'>
								<ListItemIcon>
									<MenuEarnIcon />
								</ListItemIcon>
								<ListItemText>{t('pages.earn')}</ListItemText>
							</CollapsibleMenuItemNavLink>
						}
						{showTrust &&
							<NavbarMenuItemNavLink component={NavLink} to='/wallet/home/trust' onClick={() => dispatch(closeDrawer())}>
								<ListItemIcon>
									<MenuTrustIcon />
								</ListItemIcon>
								<ListItemText>{t('pages.trust')}</ListItemText>
							</NavbarMenuItemNavLink>
						}
						{showInvoices &&
							<NavbarMenuItemNavLink component={NavLink} to='/wallet/home/invoices' onClick={() => dispatch(closeDrawer())}>
								<ListItemIcon>
									<MenuInvoiceIcon />
								</ListItemIcon>
								<ListItemText>{t('pages.invoices')}</ListItemText>
							</NavbarMenuItemNavLink>
						}
						<Divider className={classes.divider} />
						<NavbarMenuItemNavLink disabled>
							<MuiTextLabel>{t('navbar.setting')}</MuiTextLabel>
						</NavbarMenuItemNavLink>
						<CollapsibleMenuItemNavLink to='/wallet/account'>
							<ListItemIcon>
								<MenuMyAccount />
							</ListItemIcon>
							<ListItemText>{t('navbar.account.title')}</ListItemText>
						</CollapsibleMenuItemNavLink>
						{/* {kycTierVerified &&
							<NavbarMenuItemNavLink component={NavLink} to='/wallet/contacts' onClick={() => dispatch(closeDrawer())}>
								<ListItemIcon>
									<PeopleOutlinedIcon />
								</ListItemIcon>
								< ListItemText>{t('navbar.contacts')}</ListItemText>
							</NavbarMenuItemNavLink>
						} */}
						<NavbarMenuItemNavLink component={NavLink} to='/wallet/support' onClick={() => dispatch(closeDrawer())}>
							<ListItemIcon>
								<MenuHelpIcon />
							</ListItemIcon>
							<ListItemText>{t('navbar.help')}</ListItemText>
						</NavbarMenuItemNavLink>
						{/* <NavbarMenuItemNavLink component={NavLink} to='/wallet/security' onClick={() => dispatch(closeDrawer())}>
							<ListItemIcon>
								<MenuSettingsIcon />
							</ListItemIcon>
							<ListItemText>{t('pages.settings')}</ListItemText>
						</NavbarMenuItemNavLink> */}
						{businessEnabled && kycTierVerified && user?.type !== UserType.EMPLOYEE &&
							<NavbarMenuItemNavLink component={NavLink} to='/wallet/business' onClick={() => dispatch(closeDrawer())}>
								<ListItemIcon>
									<MenuBusinessIcon className="stroke" />
								</ListItemIcon>
								<ListItemText >{t('navbar.business')}</ListItemText>
							</NavbarMenuItemNavLink>
						}
						{(user?.type === UserType.BUSINESS || user?.onBehalfOf !== null) &&
							<NavbarMenuItemNavLink component={NavLink} to='/wallet/employees' onClick={() => dispatch(closeDrawer())}>
								<ListItemIcon>
									<MenuEmployeeIcon />
								</ListItemIcon>
								<ListItemText >{t('navbar.employees')}</ListItemText>
							</NavbarMenuItemNavLink>
						}
					</Box>
				</Grid>

				<Grid item justifyContent='flex-end' flex={1} container direction='column'>
					<Grid item >
						<MenuList sx={{ paddingBottom: '0px' }}>
							<NavbarMenuItemNavLink onClick={() => signout()}
								sx={{ background: theme.warningBg }}  >
								<ListItemIcon>
									<LogoutOutlinedIcon />
								</ListItemIcon>
								<ListItemText>{t('navbar.logout')}</ListItemText>
							</NavbarMenuItemNavLink>
						</MenuList>
					</Grid>
				</Grid>
			</Grid>
		</SwipeableDrawer>
	);
};

export default MobileDrawer;
