import { getAccountChartData } from '@/features/account/slice';
import { AccountChartResponse, CharTypeEnum, PeriodEnum } from '@/features/account/types';
import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { format } from 'date-fns';
import { roundAny, roundTwo } from '@/helpers/round';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import { useTranslation } from 'react-i18next';
import BigAmount from '@/componentsMui/Wallet/Components/BigAmount';
import AmountDifference from './AmountDifference';
import AccountChartTooltip from './AccountChartTooltip';
import TotalAreaChart from '@/componentsMui/Shared/Components/TotalAreaChart';
import PeriodButtonGroup from '@/componentsMui/Shared/Widgets/Chart/PeriodButtonGroup';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';

export interface AccountSummaryChartDataType {
	totalDiff: number,
	totalDiffPerc: number,
	walletAmount: number,
	accountAmount: number,
	total: number,
	xLabel: string,
	date: string
}


const AccountChart = (): React.ReactElement => {

	const { t } = useTranslation('translations');
	const [loadingBank, setLoadingBank] = useState(false);
	const [loadingWallet, setLoadingWallet] = useState(false);
	const [accountsData, setAccountsData] = useState<AccountChartResponse[]>([]);
	const [walletData, setWalletData] = useState<AccountChartResponse[]>([]);
	const [period, setPeriod] = useState(PeriodEnum.MONTH);
	const { statistics } = useSelector((state: RootState) => state.statistics);

	const [addedLastBankTotal, setAddedLastBankTotal] = useState(false);
	const [addedLastWalletTotal, setAddedLastWalleTotal] = useState(false);

	const isStatisticsLoaded = statistics !== null;
	const fundingAssets = statistics?.fundingAccountAssets?.amount ?? 0;
	const cryptoAssets = statistics?.cryptoAssets?.amount ?? 0;
	const cryptoWallet = statistics?.cryptoWallet?.amount ?? 0;
	const totalCrypto = cryptoWallet + cryptoAssets;
	const totalAssets = statistics?.totalAssets?.amountString ?? '0';
 

	const getWalletData = useCallback(async () => {
		if (period && isStatisticsLoaded) {
			setLoadingWallet(true);
			const CancelToken = axios.CancelToken;
			const source = CancelToken.source();
			try { 
				const walletData = await getAccountChartData(CharTypeEnum.WALLET, period, source);
				setWalletData(walletData);
				setLoadingWallet(false);
			}
			catch (e) {
				console.log(e);
			}
			finally {
				setLoadingWallet(false);
			}
		}
	}, [period, isStatisticsLoaded]);


	const getBankData = useCallback(async () => {
		if (period && isStatisticsLoaded) {
			setLoadingBank(true);
			const CancelToken = axios.CancelToken;
			const source = CancelToken.source();
			try {
				const accountData = await getAccountChartData(CharTypeEnum.BANK, period, source);
				setAccountsData(accountData);
				setLoadingBank(false);
			}
			catch (e) {
				console.log(e);
			}
			finally {
				setLoadingBank(false);
			}
			return function () {
				source.cancel('Cancelling');
			};
		}
	}, [period, isStatisticsLoaded]);


	useEffect(() => {
		getWalletData();
	}, [getWalletData]);

	useEffect(() => {
		getBankData();
	}, [getBankData]);

	useEffect(() => {
		if (!addedLastWalletTotal && isStatisticsLoaded && walletData?.length > 0) {
			walletData[walletData?.length - 1].amount = totalCrypto;
			setWalletData(walletData);
			setAddedLastWalleTotal(true);
		}
	}, [addedLastWalletTotal, isStatisticsLoaded, totalCrypto, walletData]);

	useEffect(() => {
		if (!addedLastBankTotal && isStatisticsLoaded && accountsData?.length > 0) {
			accountsData[accountsData?.length - 1].amount = fundingAssets;
			setAccountsData(accountsData);
			setAddedLastBankTotal(true);
		}

	}, [accountsData, addedLastBankTotal, addedLastWalletTotal, fundingAssets, isStatisticsLoaded]);

	const handlePeriodChange = async (
		event: React.MouseEvent<HTMLElement>,
		value: string,
	) => {
		if (value) {
			const newPeriod = value as PeriodEnum;
			setPeriod(newPeriod);
		}
	};


	const timeArray = _.union(accountsData.map(p => p.date), walletData.map(p => p.date)).sort() || [];

	const chartFullData = timeArray.map((p) => {
		const wamount = roundTwo(walletData.find(a => a.date === p)?.amount || 0);
		const aamount = roundTwo(accountsData.find(a => a.date === p)?.amount || 0);
		const periodDate = new Date(p + 'Z');

		let periodName = '';
		if (period === PeriodEnum.DAY) { periodName = format(periodDate, 'HH:mm'); }
		if (period === PeriodEnum.WEEK) { periodName = format(periodDate, 'dd MMM yyyy'); }
		if (period === PeriodEnum.MONTH) { periodName = format(periodDate, 'dd MMM yyyy'); }
		return {
			walletAmount: wamount,
			accountAmount: aamount,
			total: wamount + aamount,
			xLabel: periodName,
			date: p
		};
	}).sort().reverse();

	const chartDataByPeriod = _.uniqBy(chartFullData, (d) => {
		return d.xLabel;
	}).reverse();


	const chartData: AccountSummaryChartDataType[] = chartDataByPeriod.map((p, index, array) => {
		const prevTotal = index - 1 >= 0 ? array[index - 1].total : p.total;
		const totalDiff = roundTwo(p.total - prevTotal);
		return {
			...p,
			totalDiff: totalDiff,
			totalDiffPerc: roundAny((totalDiff / p.total * 100), 0)
		};
	});


	const lastPeriodData = chartData.length > 1 ? chartData[chartData.length - 1] : null;


	return (
		<Grid container id='home-account-chart'>
			<Grid item xs={12} mb={2} >
				<MuiHeader> {t('home.totalAssets')}</MuiHeader>
			</Grid>
			<Grid item xs={12} container pl='20px'
				justifyContent='space-between'
				alignItems='center'
				mb='40px'>
				<Grid item xs container rowGap='6px'>
					<BigAmount currency='€' value={totalAssets} />
					<AmountDifference
						fontSize='0.875rem'
						totalDiff={lastPeriodData?.totalDiff ?? 0}
						totalDiffPerc={lastPeriodData?.totalDiffPerc ?? 0} />
				</Grid>
				<PeriodButtonGroup
					value={period}
					onChange={handlePeriodChange} />
			</Grid>
			<Grid item xs={12} container width='100%' height={200}  >
				<TotalAreaChart chartData={chartData} loading={loadingBank || loadingWallet || !isStatisticsLoaded} chartTooltip={AccountChartTooltip} />
			</Grid>
		</Grid>);
};

export default AccountChart;
