import React, { useEffect, useMemo, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import * as Onfido from 'onfido-sdk-ui';
import { showErrorNotification } from '@/features/swal/slice';
import { createOnfidoCheck } from '@/features/kyc/slice';
import './KYCOnfido.css';
import useBasicAppBarSize from '@/helpers/customHook/useBasicAppBarSize';
import { mobileLogoutHeight } from '@/helpers/uiConstants';
import { refreshUser } from '@/features/user/userSlice';
import useFooterHeight from '@/helpers/customHook/useFooterHeight';

interface Props {
	token: string,
	paddingTop?: number,
	setCompleted: () => void,
	setError?: React.Dispatch<any>,
	onUserCancel: () => void
}

const KYCOnfido = ({ token, paddingTop, setCompleted, setError, onUserCancel }: Props): React.ReactElement => {
	const dispatch = useDispatch();
	const appBarH = useBasicAppBarSize();
	const theme = useTheme();
	const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const footerHeight = useFooterHeight();
	const removeFromHeight = appBarH + footerHeight + paddingTop + (smallScreen ? (mobileLogoutHeight + 52) : 0);

	const [loading, setLoading] = useState(false);
	const [onfidoInstance, setOnfidoInstance] = useState<Onfido.SdkHandle | null>(null);

	const onfidoOptions: Onfido.SdkOptions = useMemo(() => {
		return ({
			useModal: false,
			token,
			onComplete: async () => {
				try {
					await dispatch(createOnfidoCheck());
					await dispatch(refreshUser());
				}
				catch (e) {
					await dispatch(refreshUser());
					setCompleted();
					showErrorNotification(e);

				}
			},
			onUserExit: (data) => { if (data === 'USER_CONSENT_DENIED') { setCompleted(); onUserCancel(); } },
			onError: (data) => {
				// this is Onfido error datatype
				setError({ header: 'Error:' + data.type, content: data.message });
			},
			customUI: {
				'colorBackgroundSurfaceModal': theme.palette.background.default,
				'borderWidthSurfaceModal': '0px',
				'fontFamilyTitle': 'BlinkMacSystemFont, sans-serif',
				'fontFamilySubtitle': 'BlinkMacSystemFont, sans-serif',
				'fontFamilyBody': 'BlinkMacSystemFont, sans-serif',
				'fontSizeTitle': '26px',
				'fontSizeSubtitle': '20px',
				'fontSizeBody': '14px',
				'fontWeightTitle': 700,
				'fontWeightSubtitle': 400,
				'fontWeightBody': 400,
				//links
				'colorContentTitle': theme.palette.text.primary,
				'colorContentSubtitle': theme.palette.text.primary,
				'colorContentBody': theme.palette.text.primary,
				'colorContentLinkTextHover': theme.palette.text.primary,
				'colorBorderLinkUnderline': 'transparent',
				'colorBackgroundLinkHover': 'transparent',
				'colorBackgroundLinkActive': 'transparent',
				//buttons
				'borderRadiusButton': theme.mainBorderRadius,
				'colorContentButtonSecondaryText': theme.palette.text.primary,
				'colorBorderButtonSecondary': theme.palette.text.secondary,
				'colorBackgroundButtonSecondaryHover': theme.palette.text.secondary,
				'colorBackgroundButtonSecondaryActive': theme.palette.text.secondary,
				'colorBackgroundButtonPrimary': theme.selectedItemBg.default,
				'colorBackgroundButtonPrimaryHover': theme.selectedItemBg.default,
				'colorBackgroundButtonPrimaryActive': theme.selectedItemBg.default,
				'colorBorderButtonPrimary': theme.highlight,
				//doc type button
				'colorContentDocTypeButton': theme.palette.text.primary,
				'colorBackgroundDocTypeButton': 'transparent',
				'colorBorderDocTypeButton': theme.palette.text.secondary,
				'colorBorderDocTypeButtonHover': theme.highlight,
				'colorBorderDocTypeButtonActive': theme.highlight,
				//different
				'colorContentAlertInfo': theme.palette.background.default,
				'colorBackgroundAlertInfo': theme.palette.text.primary,
				'colorContentAlertError': theme.palette.text.primary,
				'authTextColor': theme.palette.text.primary
			},
			language: 'en',
			steps: [
				'userConsent',
				{
					type: 'welcome',
				},
				{
					type: 'document',
					options: {
						documentTypes: {
							passport: true,
							national_identity_card: true,
						}
					}
				},
				'face',
				'complete',
			],
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, []);

	const initOnfido = async () => {
		try {
			setLoading(true);

			const instance = Onfido.init(onfidoOptions);

			setOnfidoInstance(instance);
			setLoading(false);
		} catch (e) {
			console.log('onfido init error', e);
			setLoading(false);
			showErrorNotification(e);
		}
	};

	useEffect(() => {
		initOnfido();
		return () => {
			onfidoInstance && onfidoInstance.tearDown();
			setCompleted();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, []);

	/* useEffect(() => {
		if (onfidoInstance) {
			onfidoOptions.language = lang;
			onfidoInstance.setOptions(onfidoOptions);
		}
	}, [lang, onfidoInstance, onfidoOptions]);
 */
	return (
		<Box sx={{ maxHeight: `calc(100vh - ${removeFromHeight}px)` }} id='onfido-mount'>{loading && <div>Loading...</div>}</Box>
	);
};

export default KYCOnfido;
