
import { Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QrcodeDecoder from 'qrcode-decoder';
import { Credentials, DeviceInfo } from '@/features/user/types';
import { setCreditials } from '@/features/user/credentialsSice';
import { useDispatch } from 'react-redux';
import { logout } from '@/features/user/userSlice';
import { useHistory } from 'react-router-dom';
import './Login.css';
import { NeuralLoginTokenPayload, NeuralQrDataPayload } from '@/features/userNeuralid/type';
import { getNeuralLoginToken, getNeuralQRCode } from '@/features/userNeuralid/sliceUserNeuralid';
import QrMediaCard from '@/componentsMui/Shared/Widgets/QrMediaCard';
import { getErrorWithParams } from '@/features/swal/slice';

interface Props {
	deviceInfo: DeviceInfo
}

const CHECK_CALL_LIMIT = 20;

const LoginQRNeural = ({ deviceInfo }: Props) => {
	const { t } = useTranslation(['translations']);
	const dispatch = useDispatch();
	const history = useHistory();
	const appName = process.env.REACT_APP_TITLE;
	const [qrImage, setQRImage] = useState<string>('');
	const [qrData, setQrdata] = useState<NeuralQrDataPayload>();
	const [callCount, setCallCount] = React.useState(0);
	const [loginError, setLoginError] = useState(false);

	useEffect(() => {
		let isMounted = true;

		const getToken = async () => {

			if (qrData?.login_details?.sessionToken) {
				try {
					const payload: NeuralLoginTokenPayload = {
						code: qrData.login_details.sessionToken,
						deviceInfo: {
							browser: deviceInfo.browser,
							platform: deviceInfo.platform,
							hash: deviceInfo.hash
						}
					};
					const result = await getNeuralLoginToken(payload);
					if (isMounted) {
						if (result?.token) {
							await dispatch(logout());
							if (isMounted) {
								const credentialsPyaload: Credentials = { token: result?.token };
								await dispatch(setCreditials(credentialsPyaload));
								if (isMounted) history.push('/wallet/home');
							}
						}
					}
				}
				catch (e) {

					const err = getErrorWithParams(e);
					if (err?.error !== 'notApproved') {

						clearTimeout(interval);
						setQRImage('');
						setQrdata(null);
						setLoginError(true);
					}
				}
			}
			isMounted && setCallCount(callCount => callCount + 1);
		};
		const interval = setTimeout(() => {
			getToken();
		}, 1000);

		if (callCount >= CHECK_CALL_LIMIT) {
			clearTimeout(interval);
			setQRImage('');
			setQrdata(null);
		}
		return () => { clearTimeout(interval); isMounted = false; };
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [callCount]);

	const fetchQrCode = useCallback(async () => {
		try {
			const result = await getNeuralQRCode();
			if (result?.qrCodeImage) {

				setQRImage(result.qrCodeImage);
				const qr = new QrcodeDecoder();
				const data = await qr.decodeFromImage(result.qrCodeImage);

				setQrdata(JSON.parse(data.data) as NeuralQrDataPayload);
			}
		}
		catch (e) {
			setQRImage('');
			setQrdata(null);
		}
	}, []);

	useEffect(() => {
		fetchQrCode();
	}, [fetchQrCode]);


	const handleRefresh = () => {
		setLoginError(false);
		fetchQrCode();
		setCallCount(0);
	};


	return (
		<Grid container>
			<Grid item xs={12}>
				<Grid item container textAlign='center'>
					<Grid item xs={12} >
						<QrMediaCard qrImage={qrImage} onButtonClick={handleRefresh} />
					</Grid>
					{loginError &&
						<Grid item xs={12} textAlign='center' mt={3}>
							<Typography
								fontSize='0.813rem'
								whiteSpace='pre-line'
								color='text.secondary'>{t('login.v3.neuralLoginGenericError', { appName: appName })}
							</Typography>
						</Grid>}
					<Grid item xs={12} mt='30px'>
						<Typography
							fontSize='1rem'
							fontWeight={700}
							whiteSpace='pre-line'
							color='text.primary'>{t('login.qrLogin')}
						</Typography>
					</Grid>
					<Grid item xs={12} mt='10px'>
						<Typography
							maxWidth='12.5rem'
							margin='auto'
							fontSize='0.813rem'
							whiteSpace='pre-line'
							color='text.secondary'>{t('login.loginInstructionNeural', { appName: appName })}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid >
	);
};


export default LoginQRNeural;
