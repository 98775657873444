import React from 'react';
import { TicketNotification } from '@/features/tickets/types';
import { useTranslation } from 'react-i18next';
import { convertDate } from '@/helpers/date';
import { Grid, Typography } from '@mui/material';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import MuiTextLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextLabel';
import ActiveMark from '@/componentsMui/Shared/Widgets/ActiveMark';
import RoundChekmark from '@/componentsMui/Shared/CustomIcons/RoundChekmark';
import RoundClock from '@/componentsMui/Shared/CustomIcons/RoundClock';
import { isToday } from 'date-fns';

interface Proos {
	ticket: TicketNotification,
	onClick?: (ticket: TicketNotification) => void
}

const TicketsLine = ({ ticket, onClick }: Proos) => {

	const { t } = useTranslation('translations');


	return (
		<Grid container
			alignItems='center'
			onClick={() => onClick(ticket)}
			padding='1.25rem'
			sx={{ cursor: 'pointer' }}>
			<Grid item xs={9} container>
				<Grid item pr='1.25rem'>
					{ticket.status === 'SOLVED' ? <RoundChekmark /> : <RoundClock />}
				</Grid>
				<Grid item >
					<Grid item xs={12} container alignItems='center'>
						<MuiTextCaption mr={1}>
							{t('inbox.ticket')} #{ticket.id}
						</MuiTextCaption>
						{ticket.isread === false && <ActiveMark />}
					</Grid>
					<Grid item xs={12}>
						<Typography
							fontSize='0.813rem'
							noWrap
							sx={(theme) => ({
								wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis',
								color: theme.palette.text.secondary,
								fontSize: '0.813rem'
							})}
						>{ticket.message}
						</Typography>
					</Grid>

				</Grid>
			</Grid>
			<Grid item xs={3} container justifyContent='flex-end'>
				<MuiTextLabel>
					{isToday(new Date(ticket.createddate)) ? convertDate(ticket.createddate, 'h:mm a') : convertDate(ticket.createddate, 'dd MMM yyyy')}
				</MuiTextLabel>
			</Grid>

		</Grid>
	);
};

export default React.memo(TicketsLine);
