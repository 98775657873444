import * as React from 'react';
import { ClipboardEvent } from 'react';
import '@shared/Shared.css';

import {
	Button, FormHelperText, Grid,
	InputAdornment, Theme, Typography
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useState } from 'react';
import { BankAccount } from '@features/account/types';
import FormContentBox from '@shared/Widgets/Box/FormContentBox';
import { maxTwoDecimals } from '@helpers/number';
import NumberFormat from 'react-number-format';
import { Form, Formik } from 'formik';
import MuiTextField from '@shared/FormikComponents/MuiTextField';
import { getCurrencySign, postTransferFee } from '@features/fees/slice';
import _ from 'lodash';
import { AccountTransferPayloadType } from '@/componentsMui/Type/BetweenAccountsPayloadType';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import StyledDivider from '@shared/Widgets/StyledDivider';
import useCloseDialog from '@helpers/customHook/useCloseDialog';
import useFeeDebounce from '@helpers/customHook/useFeeDebounce';
import AccountItem from '@/componentsMui/Banking/Account/AccountItem';
import MuiFormLabel from '@shared/Widgets/MuiText/MuiFormLabel';
import FormLabel from '@shared/Components/SendMoney/PaymentForm/FormLabel';
import { useTheme } from '@mui/styles';
import { getAccountName } from '@helpers/getAccountName';
import bigDecimal from 'js-big-decimal';
import { roundAny, toNumber } from '@/helpers/round';


type Props = {
	fromAccountList?: BankAccount[],
	toAccountList?: BankAccount[],
	fromDefaultAccount?: BankAccount,
	toDefaultAccount?: BankAccount,
	accountTransferPayload: AccountTransferPayloadType,
	noBackground?: boolean,
	title?: string,
	setAccountTransferPayload: (value: AccountTransferPayloadType) => void,
	nextStep: () => void,
	onBack?: () => void
}

const BetweenAccountsDetails = ({
	fromDefaultAccount,
	toDefaultAccount,
	accountTransferPayload,
	noBackground = true,
	title,
	setAccountTransferPayload, nextStep, onBack }: Props) => {

	const closeModal = useCloseDialog();

	const { t } = useTranslation('translations');
	const theme = useTheme() as Theme;
	const [fromAccount] = useState<BankAccount>(accountTransferPayload.fromAccount ?? fromDefaultAccount);
	const [toAccount] = useState<BankAccount>(accountTransferPayload.toAccount ?? toDefaultAccount);
	const [amount, setAmount] = useState<string>(accountTransferPayload?.amount?.toString() ?? '');
	const [amountforFee, setAmountForFee] = useState<number>(accountTransferPayload?.amount ?? 0);

	const precision = fromAccount?.precision ?? 2;

	const { fee, callGetFee, feeLoading } = useFeeDebounce(accountTransferPayload?.fee ?? 0);
	const youpay = bigDecimal.add(amountforFee, fee);

	//const allAccounts = useSelector((state: RootState) => state.accounts.list) || [];
	//const fromAccountList = allAccounts.filter(accounts => _.keys(accounts.subProcesses).filter(key => accounts.subProcesses[key].type === 'OWN_TRANSFER').length > 0) || [];
	const process = fromAccount?.subProcesses ? _.keys(fromAccount.subProcesses)
		.filter(key => fromAccount.subProcesses[key].type === 'OWN_TRANSFER')
		.map(key => fromAccount.subProcesses[key])[0] : null;

	// const toAccountListFiltered =
	// 	toAccountList.filter(p => p.currency === fromAccount?.currency || fromAccount?.accountId === undefined)
	// 		.filter(p => p.accountId !== fromAccount?.accountId || fromAccount?.accountId === undefined);

	const initialValues = {
		amount: accountTransferPayload.amount ?? '',
		youpay: accountTransferPayload.youpay.toFixed(2) ?? null,
	};

	const validationSchema = Yup.object({
		amount: Yup.number()
			.typeError(t('form.validator.required'))
			.required(t('form.validator.required')).nullable()
			.moreThan(0, t('sendmoneyTranslation.data.moreThanZero'))
			//.test('amount', t('sendmoneyTranslation.data.insufficient'),
			//				(value) => value <= (fromAccount?.availableBalance ?? 0))
			.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value)),
		youpay: Yup.number().required(t('form.validator.required')).nullable()
			.test('youpay', t('sendmoneyTranslation.data.insufficient'), () => bigDecimal.compareTo(youpay, (fromAccount?.availableBalance ?? 0)) <= 0),
	});


	const submit = async (formData) => {

		const { amount } = formData;
		const paymentAmount = parseFloat(amount);
		const feeResult = await postTransferFee({ accountId: fromAccount.accountId, proc: process?.proc, amount: paymentAmount });

		const payload: AccountTransferPayloadType = {
			amount: paymentAmount,
			fromAccount,
			toAccount,
			processProcedure: process.proc,
			fee: feeResult.fee,
			youpay: feeResult.fee + paymentAmount,
		};
		setAccountTransferPayload(payload);
		nextStep();
	};

	// const handleToAccountChange = (newValue: BankAccount) => {
	// 	setToAccount(newValue);
	// };

	const amountChange = (value, setFieldValue, setFieldTouched) => {
		const parsed = toNumber(value);
		const newAmount = roundAny(parsed, 2);
		setAmount(`${value === '' ? '' : newAmount}`);
		setFieldValue('amount', `${newAmount}`);
		setAmountForFee(newAmount);
		callGetFee(fromAccount.accountId, process?.proc, newAmount);
		setTimeout(() => setFieldTouched('youpay', true));
		setTimeout(() => setFieldTouched('amount', true));
	};

	const handleAmountChange = (event: React.ChangeEvent<any>, setFieldValue, setFieldTouched) => {
		amountChange(event.target.value, setFieldValue, setFieldTouched);
	};

	const handlePaste = (event: ClipboardEvent<HTMLInputElement>, setFieldValue, setFieldTouched) => {
		const pastedText = event.clipboardData.getData('Text');
		if (pastedText.indexOf(',') !== -1) {
			event.preventDefault();
			amountChange(pastedText.replace(',', '.'), setFieldValue, setFieldTouched);
		}

	};

	const setAmountToMax = async (setFieldValue, setFieldTouched) => {
		const availableBalance = fromAccount?.availableBalance;
		const feeResult = await postTransferFee({ accountId: fromAccount.accountId, proc: process?.proc, amount: availableBalance });
		const amount = availableBalance - feeResult.fee < 0 ? 0 : availableBalance - feeResult.fee;
		amountChange(`${amount}`, setFieldValue, setFieldTouched);
	};

	const currency = getCurrencySign(fromAccount?.currency);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={submit}

		>
			{({ errors, isSubmitting, setFieldValue, setFieldTouched }) => {
				return (
					<Form className='form-content__wrap__bb' >
						<FormContentBox noBackground={noBackground} className='form-content_main-area wp' >
							<Grid id='paymentDetails' container columnSpacing={3} rowSpacing={1}>
								<Grid item xs={12}>
									<FormHeader title={title ?? t('banking.addMoney.transfer')} showBack={!!onBack} onBack={onBack} />
								</Grid>
								<Grid item xs={12}>
									<MuiFormLabel>{t('form.fields.from')}</MuiFormLabel>
									<AccountItem currency={fromAccount.currency} logoSource={fromAccount.currencyLogo} name={getAccountName(t, fromAccount)} balance={fromAccount.availableBalance} decimalScale={2} />
								</Grid>
								<Grid item xs={12}>
									<MuiFormLabel>{t('form.fields.to')}</MuiFormLabel>
									<AccountItem currency={toAccount.currency} logoSource={toAccount.currencyLogo} name={getAccountName(t, toAccount)} balance={toAccount.availableBalance} decimalScale={2} />
								</Grid>
								<Grid item xs={12} height={110}
								>
									<Grid container item xs={12} my={1} justifyContent="space-between" alignItems="center">
										<Grid item>
											<FormLabel>{t('sendmoneyTranslation.data.amount')}</FormLabel>
										</Grid>
									</Grid>
									<NumberFormat
										name="amount"
										customInput={MuiTextField}
										className='large-number-input'
										onChange={(event) => handleAmountChange(event, setFieldValue, setFieldTouched)}
										onPaste={(event) => handlePaste(event, setFieldValue, setFieldTouched)}
										allowNegative={false}
										value={amount}
										displayType={'input'}
										placeholder={'0.00'}
										allowedDecimalSeparators={['.']}
										decimalScale={precision}
										fullWidth
										sx={{
											'& .MuiInputAdornment-root p': {
												fontSize: '1.3rem'
											},
											'& input::placeholder': {
												fontSize: '1.3rem',
											}
										}}
										inputProps={{ autoComplete: 'off', sx: { fontSize: '1.3rem' } }}
										InputProps={{
											style: { fontSize: '1.3rem' },
											sx: { background: theme.backgroundBoxLevel1, height: '74px!important', fontSize: '1.3rem' },
											startAdornment:
												<InputAdornment position="start">
													{currency}
												</InputAdornment>,
											endAdornment:
												<InputAdornment position='end'>
													<span style={{ height: '0.8rem', cursor: 'pointer', textDecoration: 'underline', fontSize: '0.8rem', lineHeight: '0.8rem' }}
														onClick={() => setAmountToMax(setFieldValue, setFieldTouched)}>MAX</span>
												</InputAdornment>,
										}}
									/>
								</Grid>
								<Grid item xs={12} mt='2rem'>
									<StyledDivider />
								</Grid>
								<Grid container item xs={12} justifyContent='space-between' alignItems='center'>
									<Grid item>
										<Typography color='text.primary' sx={{ fontSize: '1.225rem' }}>
											{t('sendmoney.data.youWillPay')}
										</Typography>
									</Grid>
									<Grid item>
										<Typography color='text.primary' fontWeight={700} sx={{ fontSize: '1.225rem' }}>
											<Typography variant='highlight'>
												{`${fromAccount.currencySymbol ?? getCurrencySign(fromAccount.currency)}`}
											</Typography>
											<NumberFormat
												id="youpay"
												name="youpay"
												displayType={'text'}
												decimalScale={2}
												value={youpay}
												fixedDecimalScale={true}
												thousandsGroupStyle='thousand'
												thousandSeparator={true}
												prefix={' '}
												placeholder={'0.00'}
											/>
										</Typography>
									</Grid>
								</Grid>
								<Grid item xs={12} container justifyContent='right' sx={{ p: '0!important' }}>
									<Typography color='text.secondary' sx={{ fontSize: '0.7rem' }}>
										{t('sendmoney.data.includedWithFee')}
									</Typography>
									<Typography color='text.primary' sx={{ fontSize: '0.7rem', ml: 0.5 }} noWrap>
										<NumberFormat
											id="fee"
											name="fee"
											displayType={'text'}
											decimalScale={precision}
											value={new bigDecimal(fee).round(precision, bigDecimal.RoundingModes.DOWN).getValue() ?? 0}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											prefix={' '}
											placeholder={'0.00'}
										/>
									</Typography>
								</Grid>
								<Grid item xs={12} container justifyContent='right'>
									{errors.youpay && <FormHelperText >{t('sendmoneyTranslation.data.insufficient')}</FormHelperText>}
								</Grid>
							</Grid>
							<Grid container mt='auto' pt='2rem' columnSpacing={2}>
								<Grid item xs={6}>
									<Button
										className='button-left__wm'
										variant='customOutlined'
										color='secondary'
										onClick={closeModal}
										sx={{ width: { xs: '100%', md: 'auto' } }}
									>  {t('form.buttons.close')}
									</Button>
								</Grid>
								<Grid item xs={6} container justifyContent='flex-end'>
									<Button
										disabled={isSubmitting || !isEmpty(errors) || feeLoading}
										className='button-right__wm'
										variant="contained"
										color='primary'
										type="submit"
										sx={{ width: { xs: '100%', md: 'auto' } }}
									>
										{t('form.buttons.next')}
									</Button>
								</Grid>
							</Grid>
						</FormContentBox>
					</Form>
				);
			}}
		</Formik >
	);
};

export default BetweenAccountsDetails;
