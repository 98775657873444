import { Typography, TypographyProps } from '@mui/material';
import React, { FC } from 'react';

const MuiTextCaption: FC<TypographyProps> = (props: TypographyProps) => {
	return (
		<Typography
			fontSize='0.813rem'
			fontWeight='700'
			lineHeight='1.25rem'
			color='text.primary'
			whiteSpace='pre-line' //this wrap text
			{...props}>
			{props.children}
		</Typography >
	);

};

export default MuiTextCaption;
