import * as React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useField } from 'formik';
import StyledPaper from '@/componentsMui/Shared/Widgets/StyledPaper';

export interface FormikAutocompleteOptionType {
	code: string,
	label: string
}

interface Props {
	fieldName?: string,
	label?: string,
	options: FormikAutocompleteOptionType[],
	[x: string]: any;
}

const FormikAutocomplete = ({ fieldName, label, options,  ...others }: Props) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField(fieldName);
	const { setValue } = helpers;
	const { value } = meta;

	const handleChange = (event: any, newValue: FormikAutocompleteOptionType | null) => {
		others?.onChange&&others.onChange(newValue);
		setValue(newValue);
	};

	const configTextfield = {
		...field,
		...others,
		error: false,
		helperText: null
	};

	if (meta && meta.touched && meta.error) {
		configTextfield.error = true;
		configTextfield.helperText = meta.error;
	}

	return (<Autocomplete
		options={options}
		value={value || null}
		onChange={handleChange}
		disableClearable
		PaperComponent={({ children }) => (
			<StyledPaper>{children}</StyledPaper>
		)}
		isOptionEqualToValue={(option, v) => v === undefined || option.code === v.code || v?.code === '' || option?.code === value}
		renderInput={(params) => <TextField
			{...configTextfield}
			name={fieldName}
			error={configTextfield.error}
			helperText={configTextfield.helperText?.code ?? ''}
			{...params}
			label={label ? label : ''}
		/>}

	/>);
};


export default FormikAutocomplete;
