import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@/store';
import request from '@services/request';
import { BusinessStore, BusinessType, DirectorsPayloadType, DocumentsPayloadType, InformationPayloadType, PartnersPayloadType, ShareholdersPayloadType, SubmitFormPayloadType, TransferTypePayloadType } from './types';


const initialState: BusinessStore = {
	list: [],
	loading: false,
	error: null
};

const slice = createSlice({
	name: 'business',
	initialState,
	reducers: {

		setBusiness(state, action: PayloadAction<BusinessType[]>) {
			state.list = action.payload;
		},
		setBusinessLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<any>) => {
			state.error = payload;
		}
	}
});

export const { setBusiness,
	setBusinessLoading,
	setError,
} = slice.actions;

export const getBusiness = (): AppThunk => {
	return async (dispatch) => {
		try {
			setError(null);
			dispatch(setBusinessLoading(true));
			const response = await request.get('/api/v3/business');
			const { data } = response;
			dispatch(setBusiness(data));
			return data;
		} catch (e) {
			setError('Business loading error');
		} finally {
			dispatch(setBusinessLoading(false));
		}
	};
};

export const postBusinessInformation = async (payload: InformationPayloadType) => {
	const response = await request.post('/api/v3/business/create/BUSINESS_INFORMATION', payload);
	const { data } = response;
	return data;
};

export const postDocuments = async (payload: DocumentsPayloadType) => {
	const response = await request.post('/api/v3/business/create/DOCUMENTS', payload);
	const { data } = response;
	return data;
};

export const postDirectors = async (payload: DirectorsPayloadType) => {
	const response = await request.post('/api/v3/business/create/DIRECTORS', payload);
	const { data } = response;
	return data;
};

export const postShareholders = async (payload: ShareholdersPayloadType) => {
	const response = await request.post('/api/v3/business/create/SHAREHOLDERS', payload);
	const { data } = response;
	return data;
};

export const postPartners = async (payload: PartnersPayloadType) => {
	const response = await request.post('/api/v3/business/create/PARTNERS', payload);
	const { data } = response;
	return data;
};


export const postTransfers = async (payload: TransferTypePayloadType) => {
	const response = await request.post('/api/v3/business/create/TRANSFERS', payload);
	const { data } = response;
	return data;
};

export const submitForm = async (payload: SubmitFormPayloadType) => {
	const response = await request.post('/api/v3/business/submit', payload);
	const { data } = response;
	return data;
};

export const getBusinessPaymentDetails = async (clientId: string) => {
	const response = await request.get(`/api/v3/business/payment/${clientId}`);
	const { data } = response;
	return data;
};

export default slice.reducer;
