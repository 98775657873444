import MuiFormLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiFormLabel';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import { Card, CardIntegrationEnum, CardStatuses, CardSubProcesses } from '@/features/card/types';
import { runOperation, tranformSubProcessByType } from '@/features/operations/slice';
import { Box, Button, CircularProgress, Grid, IconButton, Theme, useMediaQuery } from '@mui/material';
import { format, isValid, parse } from 'date-fns';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import CardImage from './CardImage';
import CardActivating from './CardStatusMessages/CardActivating';
import CardInactive from './CardStatusMessages/CardInactive';
import CardPending from './CardStatusMessages/CardPending';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';
import CardFrozen from './CardStatusMessages/CardFrozen';
import CardSuspended from './CardStatusMessages/CardSuspended';
import CardExpired from './CardStatusMessages/CardExpired';
import BusinessCardsChooser from './BusinessCardsChooser';
import { getCurrencySign } from '@/features/fees/slice';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { hiddenBabalance } from '@/helpers/placeholders';
import { useTheme } from '@mui/styles';
import NumberFormat from 'react-number-format';
import CardDetailsForm from './CardDetailsForm';
import CardTransactions from '@/componentsMui/TransactionsGrid/CardTransactions';
import useCardCvvPan from '@/helpers/customHook/useCardCvvPan';
import { activateCard, isReplaceable } from '../../utils';
import { UserType } from '@/features/user/types';
import { showException, showSuccessNotification } from '@/features/swal/slice';

interface Props {
    card: Card,
    list: Card[]
}

const IndividualCardDetails = ({ card, list }: Props) => {
    const theme = useTheme() as Theme;
    const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation('translations');
    const history = useHistory();
    const cardProcesses = tranformSubProcessByType(card?.process?.subProcesses);
    const [openCardDetails, setOpenCardDetails] = useState<boolean>(false);

    const isPending = ([CardStatuses.PENDING, CardStatuses.ORDERED, CardStatuses.REQUESTED].indexOf(card.status) !== -1);
    const isInactive = (card.status === CardStatuses.INACTIVE);
    const suspendedByUser = (card.status === CardStatuses.SUSPENDED);
    const suspendedBySystem = (card.status === CardStatuses.FROZEN);
    const expired = (card.status === CardStatuses.EXPIRED);
    const isActivating = (card.status === CardStatuses.ACTIVATING);
    const showBenefits = card?.process?.config?.addPremiumServices || false;

    const topUpAllowed = !isEmpty(cardProcesses[CardSubProcesses.CARD_TOP_UP]) && !suspendedBySystem && !isActivating && !isInactive && card.process.integration !== CardIntegrationEnum.SH_FINANCE;
    const settingsAllowed = !suspendedBySystem && !isActivating && !isInactive;
    const activateAllowed = !isEmpty(cardProcesses[CardSubProcesses.CARD_ACTIVATE]);
    const activateProc = cardProcesses[CardSubProcesses.CARD_ACTIVATE]?.proc;

    const operationActivateCard = cardProcesses[CardSubProcesses.CARD_ACTIVATE]?.proc;
    const operationFreezeCard = cardProcesses[CardSubProcesses.CARD_SUSPEND]?.proc;
    const isReplaceEnabled = isReplaceable(card);


    const { user } = useSelector((state: RootState) => state.user);
    const { fetchCardCvvPan, cvv, showGetCVV, cardNumber, loading: loadingCvv, defaultCvvCode, cardInfoIframe } = useCardCvvPan(card);


    const showGetCVVBtn = showGetCVV && defaultCvvCode === cvv;


    const handleTopup = () => {
        history.push(`/wallet/home/cards/transfers/top-up?card=${card.cardId}`);
    };

    const handleSettings = () => {
        history.push(`/wallet/home/cards/transfers/settings?card=${card.cardId}`);
    };

    const handleActivate = async () => {
        const needVfCode = (card.process.integration === 'SH_FINANCE' && card.status === 'INACTIVE');
        await activateCard(card?.cardId, activateProc, card?.virtual, history, needVfCode, card.status);
    };


    const handleReplace = (event) => {
        event.stopPropagation();
        history.push(`/wallet/home/cards/replace?card=${card.cardId}`);
    };

    const handleBenefits = () => {
        history.push('/wallet/home/cards/benefits');
    };

    const getExpirationDate = () => {
        const date = parse(card.expirationDate, 'yyyy-MM-dd', new Date());
        if (isValid(date)) {
            return format(date, 'MM/yy');
        } else {
            return '••/••';
        }
    };

    const handleGetDetails = async () => {
        if (card.process.integration === 'SH_FINANCE') {
            setOpenCardDetails(true);
        }
        else {
            await fetchCardCvvPan();
        }

    };

    const freezeUnfreeze = async () => {
        if (card?.status === CardStatuses.SUSPENDED) {
            const needVfCode = false;
            await activateCard(card?.cardId, activateProc, card?.virtual, history, needVfCode, card.status);
            showSuccessNotification(t('cards.cardSettingForm.successUnFreeze'));
        }
        else {
            const payload: any = { cardId: card?.cardId };
            try {
                await runOperation(operationFreezeCard, payload);
                showSuccessNotification(t('cards.cardSettingForm.successFreeze'));
                //dispatch(getCards());
            }
            catch (e) {
                await showException(e);

            }
        }
    };

    let cardInner =
        <Grid container>
            <CardDetailsForm
                open={openCardDetails}
                onClose={() => setOpenCardDetails(false)}
                card={card}
                iframeLink={cardInfoIframe}
            />
            <Grid container item xs={12} spacing={4}>
                <Grid item xs={12} sm={6} md={3.2}><CardImage card={card} padding='7px' borderRadius='10px' border={true} /></Grid>
                <Grid container item xs={12} sm={6} md={8.8} alignContent='space-between'>

                    <Grid container item xs={12} justifyContent='space-between' {...(mediumScreen ? { alignItems: 'start', flexDirection: 'row' } : {})} mt={1} sx={{ borderWidth: 1, borderColor: 'red' }}>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <MuiFormLabel color='text.secondary'>  {t('cards.businessCards.details.yourBalance')}</MuiFormLabel>
                            <Grid item container flexDirection='row' alignItems='center'>
                                {user.hideValues ?
                                    <MuiTextCaption>{getCurrencySign(card.currency)} {hiddenBabalance}</MuiTextCaption>
                                    :
                                    <>
                                        <MuiTextCaption variant='gradient' fontSize='0.875rem'>
                                            {card.currencySymbol ?? getCurrencySign(card.currency)}
                                        </MuiTextCaption>
                                        <MuiTextCaption pl='0.2em' fontSize='0.875rem'>
                                            <NumberFormat
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandsGroupStyle='thousand'
                                                thousandSeparator={true}
                                                value={card.availableBalance}
                                            />
                                        </MuiTextCaption>
                                    </>}
                            </Grid>
                        </Grid>
                        <Grid item sx={{ mt: { xs: '1rem', md: 0 } }} xs={12} sm={12} md={6} lg={4}>
                            <MuiFormLabel color='text.secondary'>  {t('cards.businessCards.details.numberOfCard')} </MuiFormLabel>
                            <Grid item container flexDirection='row' alignItems='center'>
                                <MuiTextCaption fontSize='0.875rem' fontWeight={400} lineHeight='1.375rem' letterSpacing={'2px'}>{cardNumber}</MuiTextCaption>
                                {showGetCVVBtn && <IconButton onClick={handleGetDetails} sx={{ padding: '0 0.5rem', fontSize: '0.875rem' }} >
                                    {loadingCvv ? <CircularProgress size={10} /> : <VisibilityIcon fontSize='inherit' />}
                                </IconButton>}
                            </Grid>
                        </Grid>
                        <Grid item sx={{ mt: { xs: '1rem', md: 0 } }} xs={12} sm={6} md={4} lg={2}>
                            <MuiFormLabel color='text.secondary'>  Expires on </MuiFormLabel>
                            <Grid item container flexDirection='row' alignItems='center'>
                                <MuiTextCaption fontSize='0.875rem' fontWeight={400} lineHeight='1.375rem'>{getExpirationDate()}</MuiTextCaption>
                            </Grid>
                        </Grid>
                        <Grid item sx={{ mt: { xs: '1rem', md: 0 } }} xs={12} sm={6} md={4} lg={2}>
                            <MuiFormLabel color='text.secondary'>  CVV </MuiFormLabel>
                            <Grid item container flexDirection='row' alignItems='center'>
                                <MuiTextCaption fontSize='0.875rem' fontWeight={400} lineHeight='1.375rem' letterSpacing={'2px'}>{cvv}</MuiTextCaption>
                                {showGetCVVBtn &&
                                    <IconButton
                                        onClick={handleGetDetails} sx={{ padding: '0 0.5rem', fontSize: '0.875rem' }}>
                                        {loadingCvv ? <CircularProgress size={10} /> : <VisibilityIcon fontSize='inherit' />}
                                    </IconButton>}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} container >
                        <Grid container item xs={12} md={8} flexDirection='row' sx={{ pt: { xs: '1rem', md: 0 } }} spacing={1} justifyContent='flex-start' mb={1}>
                            {isReplaceEnabled && <Grid item xs={mediumScreen}>
                                <Button
                                    variant='simple'
                                    onClick={handleReplace}
                                    sx={{ width: { xs: '100%', md: 'auto' } }}
                                >
                                    {t('cards.replace.replace')}
                                </Button>
                            </Grid>}
                            {topUpAllowed && <Grid item xs={mediumScreen}>
                                <Button
                                    variant='simple'
                                    onClick={handleTopup}
                                    sx={{ width: { xs: '100%', md: 'auto' } }}
                                >
                                    {t('cards.businessCards.details.topUp')}
                                </Button>
                            </Grid>}
                            {activateAllowed && user.type !== UserType.EMPLOYEE && <Grid item xs={mediumScreen}>
                                <Button
                                    variant='contained'
                                    onClick={handleActivate}
                                    sx={{ width: { xs: '100%', md: 'auto' } }}
                                >
                                    {t('cards.businessCards.details.activate')}
                                </Button>
                            </Grid>}
                            {user.type === UserType.EMPLOYEE ?
                                <Grid item xs={mediumScreen}>
                                    <Button
                                        variant='simple'
                                        onClick={freezeUnfreeze}
                                        disabled={card.status === CardStatuses.SUSPENDED ? isEmpty(operationActivateCard) : isEmpty(operationFreezeCard)}
                                        sx={{ width: { xs: '100%', md: 'auto' } }}
                                    >
                                        {card?.status === CardStatuses.SUSPENDED ? t('cards.cardActivation.activateBTN') : t('cards.freezeCard')}
                                    </Button>
                                </Grid>
                                :
                                <Grid item xs={mediumScreen}>
                                    <Button
                                        variant='simple'
                                        onClick={handleSettings}
                                        disabled={!settingsAllowed}
                                        sx={{ width: { xs: '100%', md: 'auto' } }}
                                    >
                                        {t('cards.businessCards.details.settings')}
                                    </Button>
                                </Grid>
                            }
                            <Grid item xs={mediumScreen}>
                                <Button
                                    variant='simple'
                                    onClick={() => setOpenCardDetails(true)}
                                    sx={{ width: { xs: '100%', md: 'auto' } }}
                                >
                                    {t('cards.detailsBtn')}
                                </Button>
                            </Grid>
                            {showBenefits && <Grid item xs={mediumScreen}>
                                <Button
                                    variant='simple'
                                    onClick={handleBenefits}
                                    sx={{ width: { xs: '100%', md: 'auto' } }}
                                >
                                    {t('benefits.header')}
                                </Button>
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {suspendedByUser &&
                <Grid item xs={12} > <CardFrozen /> </Grid>}
            {suspendedBySystem &&
                <Grid item xs={12} > <CardSuspended /> </Grid>}
            {expired &&
                <Grid item xs={12} > <CardExpired /> </Grid>}
            <Grid container item xs={12} sx={{ my: { xs: '2rem', sm: '1rem', } }}>
                <Box
                    sx={(theme) => ({
                        background: {
                            xs: 'none', sm: theme.backgroundBoxLevel1
                        },
                        borderRadius: theme.mainBorderRadius,
                        p: '20px',
                        width: '100%',
                        mb: { xs: 3, md: 0 },
                        mt: 1
                    })}>
                    <CardTransactions account={card} />
                </Box>
            </Grid>
        </Grid>;

    if (isPending) {
        cardInner = <CardPending />;
    }

    if (isInactive) {
        cardInner = <CardInactive card={card} activateProc={activateProc} />;
    }

    if (isActivating) {
        cardInner = <CardActivating card={card} />;
    }

    return (
        <Grid container columnSpacing={2}>
            <Grid item xs={2.3} maxHeight='75vh' height='75vh' overflow='auto' sx={{ display: { xs: 'none', sm: 'none', lg: 'block' } }}>
                <BusinessCardsChooser accounts={list} />
            </Grid>
            <Grid item sm={12} lg={9.5}>
                {cardInner}
            </Grid>

        </Grid>
    );
};

export default IndividualCardDetails;


