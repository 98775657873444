import React, { FC } from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import StyledIconButtonSmall from './StyledIconButtonSmall';
import { ButtonProps } from '@mui/material';

const DownloadButton: FC<ButtonProps> = (props: ButtonProps) => {

	return (
		<StyledIconButtonSmall
			{...props}>
			<FileDownloadOutlinedIcon sx={{ fontSize: '19px'}} />
		</StyledIconButtonSmall>
	);
};

export default DownloadButton;
