import { Box, Grid, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import TokenAvatar, { TokenAvatarSizeEnum } from '@/componentsMui/Shared/Widgets/Avatars/TokenAvatar';
import { getCurrencySign } from '@/features/fees/slice';
import { CoinType } from '@/features/coins/types';
import bigDecimal from 'js-big-decimal';
import InfoIcon from '@mui/icons-material/Info';
import { hiddenBabalance } from '@/helpers/placeholders';
import MuiTextLabel from '@shared/Widgets/MuiText/MuiTextLabel';
import BaseBox from '@shared/Widgets/Box/BaseBox';
import StyledDivider from '@shared/Widgets/StyledDivider';
import { useTranslation } from 'react-i18next';
import { getFiatValue } from '@helpers/getFiatValue';
import { formatDecimalFlex } from '@/helpers/number';
import { TOKEN_PRICE_PRECISION } from './IndividualTokenDetails';

interface Props {
	token: CoinType,
	currency: string,
	hideValues: boolean,
	onClick?: (coin: CoinType) => void
}
const TokenListLine = ({ token, hideValues, onClick, currency }: Props) => {
	const { t } = useTranslation('translations');
	// const history = useHistory();
	// const isFiatLine = token?.type === 'WALLET';

	// const handleTrade = () => {
	// 	if (!isFiatLine) {
	// 		history.push(`/wallet/home/tokens/token/trade?token=${token.type}${token.info.symbol}&accountid=${token?.account?.accountId ?? 0}`);
	// 	}
	// };
	const precision = token?.account?.precision ?? 5;

	const balance = bigDecimal.compareTo(token?.account?.availableBalance, 0) === 0 ? 0 : new bigDecimal(token?.account?.availableBalance ?? 0).round(precision, bigDecimal.RoundingModes.DOWN).getValue();
	const isBalanceLessThanShow = bigDecimal.compareTo(token?.account?.availableBalance, 0) && bigDecimal.compareTo(balance, 0) === 0;

	return (
		<Box
			style={{ height: '100%', cursor: 'pointer' }}
			onClick={() => onClick && onClick(token)}
		>
			<Grid item style={{ height: '100%' }}>
				<BaseBox sx={{ p: '2rem', width: '100%', height: '100%' }}>
					<Grid item container justifyContent='space-between'>
						<Grid item>
							<Grid item container>
								<Grid item>
									<TokenAvatar symbol={token.info.symbol} logoSource={token.info.logo} variant={TokenAvatarSizeEnum.regular} />
								</Grid>
								<Grid item pl={1} alignItems='start' justifyContent={'center'} display={'flex'} flexDirection={'column'}>
									<MuiTextCaption fontSize='1rem'>{token.info.symbol}</MuiTextCaption>
									<MuiTextLabel fontWeight='400'>{token.info.displayName}</MuiTextLabel>
								</Grid>
							</Grid>
						</Grid>
						<Grid item alignItems='end' justifyContent={'center'} display={'flex'} flexDirection={'column'}>
							<MuiTextCaption fontSize='1rem'>
								{hideValues ? hiddenBabalance :
									<>
										<Typography variant='gradient'>
											{`${getCurrencySign(token.info.symbol)}`}
										</Typography>
										<NumberFormat displayType={'text'}
													  decimalScale={isBalanceLessThanShow ? precision + 1 : precision}
													  fixedDecimalScale={false}
													  thousandsGroupStyle='thousand'
													  thousandSeparator={true}
													  prefix={isBalanceLessThanShow ? '< ' : ' '}
													  value={isBalanceLessThanShow ? `${balance}1` : balance}
										/>
									</>
								}
								{isBalanceLessThanShow == true && !hideValues &&
                                    <Tooltip placement="top" title={`${token?.account?.availableBalance} ${token?.account?.currency}`}>
                                        <InfoIcon fontSize='inherit' sx={{ marginLeft: '0.25em' }} />
                                    </Tooltip>}
							</MuiTextCaption>
							<MuiTextLabel fontWeight='400'>
								{t('tokens.yourBalance')}
							</MuiTextLabel>
						</Grid>
					</Grid>
					<Grid item xs={12} py='1rem'>
						<StyledDivider/>
					</Grid>
					<Grid item>
						<Grid item container justifyContent='space-between'>
							<Grid item>
								<MuiTextLabel fontWeight='400' fontSize='0.8rem'>{t('tokens.currentPrice')}</MuiTextLabel>
							</Grid>
							<Grid item>
								<MuiTextLabel fontSize='0.9rem' color='text.primary' fontWeight='400'>
									<Typography variant='gradient'>
										{`${getCurrencySign(currency)} `}
									</Typography>
									{hideValues ? hiddenBabalance : formatDecimalFlex(token.info.price ?? 0, { maximumFractionDigits: TOKEN_PRICE_PRECISION })}
								</MuiTextLabel>
							</Grid>
						</Grid>
						<Grid item container justifyContent='space-between' mt={2}>
							<Grid item>
								<MuiTextLabel fontWeight='400' fontSize='0.8rem'>{t('tokens.value')}</MuiTextLabel>
							</Grid>
							<Grid item>
								<MuiTextLabel fontSize='0.9rem' color='text.primary' fontWeight='400'>
									<Typography variant='gradient'>
										{`${getCurrencySign(currency)} `}
									</Typography>
									{hideValues ? hiddenBabalance :
										<NumberFormat
											// style={{fontSize: '1rem', whiteSpace: 'nowrap'}}
											displayType={'text'}
											decimalScale={2}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											prefix=' '
											value={(getFiatValue(token, currency) ?? 0)} />
									}
								</MuiTextLabel>
							</Grid>
						</Grid>
					</Grid>
				</BaseBox>
			</Grid>


		</Box>);
};

export default TokenListLine;
