import { ThemeOptions } from '@mui/material/styles/createTheme';
import { createTheme } from '@mui/material/styles';

import defaultLighTheme from '../default/defaultLightTheme'; 

const newTheme: ThemeOptions = {
	...defaultLighTheme,
	selectedItemBg: {
		default: 'linear-gradient(96.99deg, #01DDB9 19.1%, #0184FD 71.05%)',
	},
	iconGradient: {
		...defaultLighTheme.iconGradient,
		stopColor1: '#01DDB9',
		stopColor2: '#0184FD'
	},
	highlight: '#01DDB9',
	carousel: {
		...defaultLighTheme.carousel,
		iconShadow: '0px 1px 15px 2px rgba(1, 221, 185, 0.41)'
	},
	covers: {
		...defaultLighTheme.covers,
		productCover: {
			...defaultLighTheme.covers.productCover,
			bg: [{
				firstBg: 'linear-gradient(329.09deg, #01DDB9 91.04%, #3D2DDD 91.05%)',
				secondBg: 'linear-gradient(0deg, #0190DA, #0190DA)',
			},
			//wallet
			{
				firstBg: 'linear-gradient(329.09deg, #0500F4 91.04%, #FAFF00 91.05%)',
				secondBg: 'linear-gradient(329.09deg, #0500F4 91.04%, #FAFF00 91.05%)',
			}]

		},
		paymentMethodCover: {
			...defaultLighTheme.covers.paymentMethodCover,
			bg: [{
				firstBg: 'linear-gradient(329.09deg, #01DDB9 91.04%, #3D2DDD 91.05%)',
				secondBg: 'linear-gradient(329.09deg, #01DDB9 91.04%, #3D2DDD 91.05%)',
			},
			{
				firstBg: 'linear-gradient(0deg, #0190DA, #0190DA)',
				secondBg: 'linear-gradient(0deg, #0190DA, #0190DA)',
			}],
		}
	},
	warningBg:  'linear-gradient(112.26deg, #01DDB91a 10.81%, rgba(1, 132, 253, 0.1) 160.09%)',
	auth: {
		welcomeCardBg: 'linear-gradient(180deg, #01DDB9 0%, #0184FD 100%)',
	},
};

const paletteDark = createTheme(newTheme);

export default paletteDark;

