import { GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import MuiTextCaption from '@shared/Widgets/MuiText/MuiTextCaption';
import { Grid } from '@mui/material';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { format } from 'date-fns';


const InvoiceOrderDateCell = (params: GridRenderCellParams) => {

    const date = new Date(params.row.createdDate);

    return (
        <Grid container>
            <Grid item xs={12}>
                <MuiTextCaption fontWeight={400}>{params.row.orderNumber}</MuiTextCaption>
            </Grid>
            <Grid item xs={12}>
                <MuiFormText pr='5px'>
                    {format(date, 'dd/MM/yyyy')}
                </MuiFormText>
                <MuiFormText color='text.secondary'>
                    {format(date, 'HH:mm:ss')}
                </MuiFormText>
            </Grid>
        </Grid>

    );
};

export default InvoiceOrderDateCell;
