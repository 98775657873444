import { Box as MuiBox, Box, Grid, Theme, Typography } from '@mui/material';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import { timeSince } from '@/helpers/date';
import { TransactionStatus } from '@/features/transactions/types';
import './TradeHistory.css';
import { useTranslation } from 'react-i18next';
import TransactionStatusBox from '@/componentsMui/Shared/Widgets/Box/TransactionStatusBox';
import { WalletTransaction } from '@/features/walletHistory/types';
import TokenAvatar, { TokenAvatarSizeEnum } from '@shared/Widgets/Avatars/TokenAvatar';
import MuiTextTrx from '@shared/Widgets/MuiText/MuiTextTrx';
import { useTheme } from '@mui/styles';
import MuiTextTrxSmall from '@shared/Widgets/MuiText/MuiTextTrxSmall';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';

interface Props {
	item: WalletTransaction,
	onClick?: (trx: WalletTransaction) => void,
}
const WalletHistoryLineMobile = ({ item, onClick }: Props) => {
	const theme = useTheme() as Theme;
	const { t } = useTranslation('translations');
	const time = item?.date && new Date(item.date);

	const { list } = useSelector((state: RootState) => state.coins);
	const coin = list.find(listItem => listItem.info.symbol === item.asset);

	return (
		<Box onClick={() => onClick(item)}
			 pb='1rem'
			 // sx={{ backgroundColor: theme.bgSecondaryButton }}
			 borderRadius='10px'
		>

			 <Grid item xs={12} container alignItems='center' columnSpacing={1} wrap='nowrap'>
				<Grid item justifyContent='flex-start' alignItems='center'>
					<MuiBox sx={{
						borderRadius: theme.mainBorderRadius,
						background: theme.form.bgDefault,
					}}
							className='transaction-icon'
					>
						<TokenAvatar symbol={coin.info.symbol}
									 logoSource={coin.info.logo}
									 variant={TokenAvatarSizeEnum.regular} />
					</MuiBox >
				</Grid>
				<Grid item
					  display='flex'
					  direction='column'
					  justifyContent='space-evenly'
					  maxWidth={'100%'}
				>
					<Grid item xs={12}>
						<Typography color="text.primary" fontWeight={700} noWrap sx={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}>
							{item.asset}
						</Typography>
					</Grid>
					<Grid item xs={12} justifyContent={'flex-end'}>
						<MuiTextTrxSmall color='text.secondary'>
							{timeSince(time)}
						</MuiTextTrxSmall>
					</Grid>
				</Grid>
				<Grid item
					  ml='auto'
					  display='flex'
					  direction='column'
					  justifyContent='space-evenly'
				>
					<Grid>
						<MuiTextTrx textAlign='right'>
							<NumberFormat
								displayType={'text'}
								decimalScale={5}
								fixedDecimalScale={false}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								suffix={` ${item.asset}`}
								isNumericString
								value={item.amountString}
							/>
						</MuiTextTrx>
					</Grid>
					<Grid container justifyContent='flex-end'>
						<TransactionStatusBox
							status={item.status as TransactionStatus}>
							{t('status.' + item.status)}
						</TransactionStatusBox>
					</Grid>
				</Grid>
			</Grid>
		</Box>);
};

export default WalletHistoryLineMobile;
