import { ThemeOptions } from '@mui/material/styles/createTheme';
import { createTheme } from '@mui/material/styles';

import defaultDarkTheme from '../default/defaultDarkTheme';
//import _ from 'lodash'; 
/* const newTheme: ThemeOptions = _.cloneDeep(defaultDarkTheme); 
newTheme.selectedItemBg.default = 'linear-gradient(96.99deg, #01DDB9 19.1%, #0184FD 71.05%)';
newTheme.iconGradient.stopColor1 = '#01DDB9';
newTheme.highlight= '#01DDB9';
newTheme.highlight= '#01DDB9'; */

const newTheme: ThemeOptions = {
	...defaultDarkTheme,
	selectedItemBg: {
		default: 'linear-gradient(to bottom, #2C5FD7 0%, #6DB2F9 100%)'
	},
	iconGradient: {
		...defaultDarkTheme.iconGradient,
		stopColor1: '#2C5FD7',
		stopColor2: '#6DB2F9'
	},
	highlight: '#2C5FD7',
	carousel: {
		...defaultDarkTheme.carousel,
		iconShadow: '0px 1px 15px 2px rgba(122, 86, 248, 0.4)'
	},
	covers: {
		...defaultDarkTheme.covers,
		productCover: {
			...defaultDarkTheme.covers.productCover,
			bg: [
				//accounts, cards, eran products
				{
					firstBg: 'linear-gradient(to bottom, #2C5FD7 0%, #6DB2F9 100%)',
					secondBg: 'linear-gradient(0deg, #0190DA, #0190DA)',
				},
				//wallet
				{
					firstBg: 'linear-gradient(329.09deg, #0500F4 91.04%, #FAFF00 91.05%)',
					secondBg: 'linear-gradient(329.09deg, #0500F4 91.04%, #FAFF00 91.05%)',
				}]

		},
		paymentMethodCover: {
			...defaultDarkTheme.covers.paymentMethodCover,
			bg: [{
				firstBg: 'linear-gradient(180deg, #2C5FD7 -0.72%, rgba(122, 86, 248, 0) 100%)',
				secondBg: 'linear-gradient(180deg, #2C5FD7 -0.72%, rgba(122, 86, 248, 0) 100%)',
			},
			{
				firstBg: 'linear-gradient(0deg, #0190DA, #0190DA)',
				secondBg: 'linear-gradient(0deg, #0190DA, #0190DA)',
			}],
		}
	},
	warningBg:  'linear-gradient(112.26deg, #2C5FD71a 10.81%, rgba(1, 132, 253, 0.1) 160.09%)',
	auth: {
		welcomeCardBg: 'linear-gradient(2.88deg, #2C5FD7 -10.83%, #6DB2F9 106.36%)',
	},
};

const paletteDark = createTheme(newTheme);

export default paletteDark;
