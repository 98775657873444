import * as React from 'react';
import { SvgIcon } from '@mui/material';

const BackIcon = (props) => (
    <SvgIcon {...props} >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="current" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.5 12h-18M8.5 17l-5-5 5-5"
                stroke="current"
                strokeWidth={1.4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </SvgIcon>
);

export default BackIcon;
