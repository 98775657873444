import { Alert, Button, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';

interface Props {
	onBack: () => void,
	onGenerate: () => void,
}

const AdminInviteFirstStep = ({ onBack, onGenerate }: Props): React.ReactElement => {

	const closeModal = useCloseDialogNoEvent();
	const { t } = useTranslation('translations');


	return (
		< Grid id='deposit-select-asset'
			container
			flex={1}
			direction='column'
			minHeight='0'
			flexWrap='nowrap'
			rowSpacing={2}>
			<Grid item >
				<FormHeader title={t('employee.invite.adminTitle')} onBack={onBack} />
			</Grid>
			<Grid item xs={12} mt='1rem' >
				<Alert severity="warning"  >
					<MuiFormText >{t('employee.invite.adminWarningText')}</ MuiFormText>
				</Alert>
			</Grid>
			<Grid item xs={12} flex={1} alignItems='flex-end' justifyContent='space-between' mt={8} display='flex'>
				<Button
					onClick={closeModal}
					variant='customOutlined'
				>{t('form.buttons.close')}</Button>
				<Button
					onClick={onGenerate}
					variant="contained"
				>
					{t('employee.invite.adminBtn')}
				</Button>
			</Grid>

		</ Grid >
	);
};

export default AdminInviteFirstStep;
