import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { TransactionsTypedPayload } from '@/features/transactions/types';
import { getTime } from 'date-fns';
import { getTransactionsTyped } from '@/features/transactions/slice';
import { DataGrid } from '@mui/x-data-grid';
import { Grid, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TransactionDetailsForm from '../Transactions/Details/TransactionDetailsForm';
import ExportStatementForm from '../Transactions/Download/ExportStatementForm';
import { Transaction } from '../Transactions/TransactionStatements.spec';
import { transactionDetailsEnabled } from '@/helpers/transactionUtils';
import { closeForm, openForm } from '@/features/forms/slice';
import Spinner from '../Shared/Widgets/Spinner/Spinner';
import { AccountType } from '@/features/operations/types';
import MuiHeader from '../Shared/Widgets/MuiText/MuiHeader';
import { BankAccount } from '@/features/account/types';
import { getMobileTrxGridColumns, getTrxGridColumns } from './Components/TransactionColumns';
import MuiTextTrx from '../Shared/Widgets/MuiText/MuiTextTrx';
import TrxGenericError from './Components/TrxGenericError';
import TransactionForm from '@/componentsMui/Transactions/TransactionForm';
import { useHistory, useRouteMatch } from 'react-router-dom';

interface Props {
    type: AccountType,
    account?: BankAccount,
    seeAllInModal?: boolean,
}

const TransactionGridShort = ({ type, account, seeAllInModal }: Props) => {

    const ROW_COUNT = 5;
    const { t } = useTranslation('translations');
    const dispatch = useDispatch();
    const smallScreen = useMediaQuery('(max-width:600px)');
    const history = useHistory();
    const { path } = useRouteMatch();

    const { user } = useSelector((state: RootState) => state.user);
    const [transactions, setTransactions] = useState<any[]>([]);
    const [loadingError, setLoadingError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingTrx, setLoadingTrx] = useState<boolean>(false);
    const [openTransactionForm, setOpenTransactionForm] = useState<boolean>(false);

    const [openTransactionDetails, setOpenTransactionDetails] = React.useState<boolean>(false);
    const [selectedTrx, setSelectedTrx] = React.useState<Transaction>();
    const [openDownloadForm, setOpenDownloadForm] = React.useState<boolean>(false);

    const userCreationTime = getTime(new Date(user.createdDate));
    const [time] = useState<number>(new Date().getTime() + 86400000);

    const payload: TransactionsTypedPayload = useMemo(() => {
        return {
            type,
            search: '',
            skip: 0,
            take: ROW_COUNT,
            from: `${userCreationTime}`,
            to: `${time}`,
            ...(account?.accountId && {
                accountId: `${account.accountId}`
            })
        };
    }, [type, userCreationTime, account?.accountId, time]);

    const columns = useMemo(() => smallScreen ?
        getMobileTrxGridColumns(type, account?.subProcesses) :
        getTrxGridColumns(type, account?.subProcesses), [type, account?.subProcesses, smallScreen]);



    const handleSeeAll = () => {
        if (seeAllInModal === true) {
            setOpenTransactionForm(true);
        } else {
            history.push(`${path}?tab=transactions`);
        }
    };

    const fetch = useCallback(async (newPayload: TransactionsTypedPayload) => {
        try {
            setLoadingError(false);
            setLoadingTrx(true);
            const result = await (getTransactionsTyped(newPayload));
            setTransactions(result?.list);
        }
        catch (e) {
            setLoadingError(true);
            setTransactions([]);
        }
        finally { setLoadingTrx(false); }
    }, []);


    useEffect(() => {
        fetch(payload);
    }, [payload, fetch, account?.availableBalance]);


    const closeTransactionDetailsForm = React.useCallback(() => {
        setOpenTransactionDetails(false);
        dispatch(closeForm());
    }, [dispatch]);


    const handleCollapse = () => {
        setOpenTransactionForm(false);
    };

    const handleOnCellClick = (params) => {

        const trx = params.row;

        if (transactionDetailsEnabled(trx.activity) && params.field !== 'status') {
            dispatch(openForm());
            setOpenTransactionDetails(true);
            setSelectedTrx(trx);
        }

    };

    return (<>
        <TransactionDetailsForm open={openTransactionDetails}
            onClose={closeTransactionDetailsForm}
            transaction={selectedTrx}
            account={account}
            type={type}
            setLoading={(value: boolean) => setLoading(value)} />
        <TransactionForm
            type={type}
            account={account}
            open={openTransactionForm}
            onClose={handleCollapse}
        />
        <ExportStatementForm
            accountId={account?.accountId}
            open={openDownloadForm}
            type={type}
            onClose={() => setOpenDownloadForm(false)} />

        {loading && <Grid container justifyContent='center' mt={2}><Spinner /></Grid >}
        <Grid container
            alignItems='center'
            p={{ sx: '0px', md: '30px' }}
        >
            <Grid item xs={12} container justifyContent='space-between'>
                <Grid item xs>
                    <MuiHeader>{t('transaction.header.transactions')}</MuiHeader>
                </Grid>
                <Grid item xs>
                    <MuiTextTrx
                        color='text.secondary'
                        textAlign='right'
                        sx={{ cursor: 'pointer' }}
                        onClick={handleSeeAll}
                        pr='10px'
                    >
                        {t('transaction.labels.seeAll')}</MuiTextTrx>
                </Grid>
            </Grid>

            <Grid mt='10px'
                sx={{
                    maxHeight: '380px',
                    minHeight: '200px',
                    height: transactions.length === 0 ? '100px' : 'inherit',
                    width: '100%'
                }}
            >
                {loadingError ? <TrxGenericError onButtonClick={() => fetch(payload)} /> :
                    <DataGrid
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 5, page: 0 },
                            },
                        }}
                        rows={transactions}
                        loading={loadingTrx}
                        getRowId={(row) => `${row.transactionNumber}`}
                        disableRowSelectionOnClick={true}
                        columns={columns}
                        hideFooter={true}
                        onCellClick={handleOnCellClick}
                        sx={{
                            // disable cell selection style
                            '.MuiDataGrid-cell:focus': {
                                outline: 'none'
                            },
                            // pointer cursor on ALL rows
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer'
                            },
                            '& .MuiDataGrid-cell[data-field="details"] > div > span': {
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            },
                            '& .MuiDataGrid-cell[data-field="details"] > div': {
                                overflow: 'hidden',
                            },
                        }}
                    />
                }
            </Grid>
        </Grid >

    </>);

};

export default TransactionGridShort;

