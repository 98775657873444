
import React, { useCallback, useMemo, useState } from 'react';
import { getSHCardTopupTransactions } from '@/features/transactions/slice';
import { DataGrid, GridPaginationModel } from '@mui/x-data-grid';
import { Grid, useMediaQuery } from '@mui/material';
import { showErrorNotification } from '@/features/swal/slice';
import { AccountType } from '@/features/operations/types';
import { getMobileTrxGridColumns, getTrxGridColumns } from './Components/TransactionColumns';
import TrxGenericError from './Components/TrxGenericError';


const ShCardTopupTransactionGrid = () => {

    const type = AccountType.CARD;
    const smallScreen = useMediaQuery('(max-width:600px)');
    const defaultTake = 10;

    const [loadingError, setLoadingError] = useState<boolean>(false);
    const [loadingTrx, setLoadingTrx] = useState<boolean>(false);

    const [transactions, setTransactions] = useState<any[]>([]);
    const [count, setCount] = useState<number>(0);
    const [skipState, setSkipState] = useState<number>(null);


    const columns = useMemo(() => smallScreen ?
        getMobileTrxGridColumns(type, undefined) :
        getTrxGridColumns(type, undefined, true, true), [type, smallScreen]);


    const fetch = useCallback(async (skip: number) => {
        try {
            const isInitial = skip === 0 && transactions.length === 0;
            setSkipState(skip);
            setLoadingError(false);
            setLoadingTrx(true);
            const result = await getSHCardTopupTransactions({ skip: skip, take: defaultTake, initial: isInitial });
            setTransactions(result?.list);
            setCount(result?.count);
        }
        catch (e) {
            setLoadingError(true);
            setTransactions([]);
        }
        finally { setLoadingTrx(false); }
    }, [transactions.length]);


    const onPaginationModelChange = async (model: GridPaginationModel) => {
        try {
            if (skipState === model.page) { return; }
           
            fetch(model.page);
        }
        catch (e) {
            showErrorNotification(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };


    return (<>
        <Grid container>
            <Grid item xs={12} >
                {loadingError ? <TrxGenericError onButtonClick={() => { fetch(0); }}
                /> :
                    <DataGrid
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: defaultTake, page: 0 },
                            },
                        }}
                        // pageSizeOptions={[10]}
                        autoPageSize={true}
                        autoHeight={true}
                        pagination
                        rows={transactions}
                        rowCount={count}
                        loading={loadingTrx}
                        getRowId={(row) => `${row.transactionNumber}-${transactions.length}`}
                        onPaginationModelChange={onPaginationModelChange}
                        disableRowSelectionOnClick={true}
                        paginationMode="server"
                        filterMode="server"
                        sortingMode="server"
                        columns={columns}
                        sx={{
                            // pointer cursor on ALL rows
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer'
                            }
                        }}
                    />
                }
            </Grid>

        </Grid>

    </>);

};

export default ShCardTopupTransactionGrid;

