import React, { useEffect } from 'react';
import { Button, Grid, Theme, useTheme } from '@mui/material';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import BusinessPlaceholder from '@/componentsMui/Dashboard/Business/BusinessPlaceholder';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MuiPlusIcon } from '@/assets/MuiPlusIcon.svg';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import BusinessLine from './BusinessLine';
import { useHistory } from 'react-router-dom';
import { getBusiness } from '@/features/business/slice';
import { getMyAdminCompanies } from '@/features/employee/slice';
import OnBehalfOfLine from './OnBehalfOfLine';
import LoadingPageMui from '@/pages/LoadingPageMui';
import { UserType } from '@/features/user/types';

const Business = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const history = useHistory();
	const dispatch = useDispatch();
	const { list, loading } = useSelector((state: RootState) => state.business);
	const { myCompanies } = useSelector((state: RootState) => state.employee);
	const { businessRegistrationEnabled, allowMultipleBusinessRegistration } = useSelector((state: RootState) => state.status);
	const { user } = useSelector((state: RootState) => state.user);
	const allowToCreate = businessRegistrationEnabled && (allowMultipleBusinessRegistration || list.length === 0) && user.type === UserType.INDIVIDUAL;
	const allowToConnect = businessRegistrationEnabled && user.type === UserType.INDIVIDUAL;
	const theme = useTheme() as Theme;

	const handleCreateBusiness = () => {
		history.push('/wallet/business/create');
	};

	const handleConnectBusiness = () => {
		history.push('/wallet/business/connect');
	};

	useEffect(() => {
		dispatch(getBusiness());
		dispatch(getMyAdminCompanies());
	}, [dispatch]);

	return (
		<Grid id='business-view' container>
			{loading
				? <LoadingPageMui />
				: <>
					<Grid container item alignItems="center" sx={{ mb: '2rem' }}>
						<Grid item xs={12} sm={4} sx={{ mb: { xs: '1rem', sm: 0 } }}>
							<MuiHeader>{t('navbar.business')}</MuiHeader>
						</Grid>
						<Grid item xs={12} sm={8} flexWrap='nowrap' container sx={{ justifyContent: { xs: 'space-between', sm: 'flex-end' } }}>
							{allowToConnect &&
								<Button variant='customOutlined' onClick={handleConnectBusiness} sx={{ marginRight: '0.5rem' }}>
									<MuiTextCaption color={theme.palette.primary.contrastText}>{t('business.business.connect')}</MuiTextCaption>
								</Button>}
							{allowToCreate &&
								<Button variant='contained' onClick={handleCreateBusiness}>
									<MuiPlusIcon style={{ fill: theme.palette.primary.contrastText, width: '1.5rem', height: '1.5rem' }} />
									<MuiTextCaption color={theme.palette.primary.contrastText} pl='0.5rem'>{t('business.business.create')}</MuiTextCaption>
								</Button>}
						</Grid>
					</Grid>
					{list.length === 0 && businessRegistrationEnabled &&
						<Grid item xs={12}>
							<BusinessPlaceholder allowMultipleBusinessRegistration={allowMultipleBusinessRegistration} />
						</Grid>
					}
					<Grid item xs={12}>
						{list?.length > 0 &&
							list.map(p => <BusinessLine business={p} key={p.clientId} />)}
						{myCompanies?.length > 0 &&
							myCompanies.map(p => <OnBehalfOfLine business={p} key={p.userId} />)}
					</Grid>
				</>
			}
		</Grid>
	);
};

export default Business;
