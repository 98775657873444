import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import MuiFormText from '../../Shared/Widgets/MuiText/MuiFormText';
import { Form, Formik } from 'formik';
import { enable2FAAPi } from '@/features/user/userSlice';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { RootState } from '@/rootReducer';
import MobileHeader from '../components/MobileHeader';
import BackButtonMobileLanding from '../components/BackButtonMobileLanding';
import MuiFormHelperText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormHelperText';

interface Props {
	next: () => void,
	previous: () => void
}

const AuthEnable = ({ next, previous }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');

	const { message } = useSelector((state: RootState) => state.twoFA);

	const initialValues = {
		authenticatorCode: ''
	};
	const validationSchema = Yup.object({
		authenticatorCode: Yup.string().matches(/^\d{6}$/, t('errors.SIX_DIGITS_REQUIRED')).required(t('form.validator.required'))
	});
	const enable = async (formData) => {

		try {
			const { authenticatorCode } = formData;
			await enable2FAAPi(authenticatorCode);
			next();

		}  
		catch(e)
		{
			console.log('enable failed');
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={enable}
		>
			{({ errors, isSubmitting, dirty }) => {
				return (
					<Form>
						<Grid container id='twofa-enable-step'   >
							<BackButtonMobileLanding onClick={previous} />
							<MobileHeader text={t('landing.authStep.title')} />
							<Grid item xs={12} >
								<Grid item xs={12} container rowGap='42px'>
									<Grid item xs={12}>
										<MuiFormText >
											{t('landing.authCodeStep.info3')}
										</MuiFormText>
									</Grid>
									<Grid item xs={12}  >
										<MuiTextField
											name="authenticatorCode"
											fullWidth
											label={t('landing.authStep.title')}
											placeholder={t('landing.authCodeStep.6digitcode')} />
										<MuiFormHelperText>{message}</MuiFormHelperText>
									</Grid>
									<Grid item xs={12}  >
										<Button
											type="submit"
											variant='contained'
											fullWidth
											disabled={isSubmitting || !isEmpty(errors) || !dirty}
										>
											{t('form.buttons.continue')}
										</Button>
									</Grid>
								</Grid>
							</Grid >
						</Grid >
					</Form>);
			}}
		</Formik >
	);
};

export default AuthEnable;
