import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import { Card, CardCvvResponse, CardsStore, DectaCardPinResponse, DectaCardPanResponse, Address } from './types';
import { SocketService } from '../../services/socketService';
import { Subscription } from 'rxjs';
import request from '../../services/request';
import { isEmpty } from 'lodash';
import { decrypt, getGenerateKeyPair } from '@/helpers/encrypt';

const findCardById = (cardId, list) => {
	const id = list.findIndex(card => card.cardId === cardId);
	return id;
};

let cancelSource;

const initialState: CardsStore = {
	loading: true,
	list: [],
	error: null
};

const slice = createSlice({
	name: 'card',
	initialState,
	reducers: {
		setCards(state, action: PayloadAction<Array<Card>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		removeCard: (state, { payload }: PayloadAction<number>) => {
			const { list } = state;
			state.list = list.filter(card => card.cardId != payload);

		}
	}
});

export const { setCards, setLoading, setError, removeCard } = slice.actions;

export const bankAccountsSelector = (state: { bankAccountStore: CardsStore }) =>
	state.bankAccountStore;

export const getCards = (): AppThunk => {
	return async (dispatch, state) => {
		dispatch(setLoading(true));
		const { cards } = state();
		const { list } = cards;
		const currentCards = JSON.parse(JSON.stringify(list));
		try {
			const response = await request.get('/api/cards/mobile');
			const { data } = response;
			data.forEach(card => {
				const id = findCardById(card.cardId, currentCards);
				if (id < 0) {
					currentCards.push(card);
				} else {
					currentCards[id] = card;
				}
			});
			dispatch(setCards(currentCards));
			return data;
		} catch (e) {
			console.log(e);
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const clearAccounts = (): AppThunk => {
	return async dispatch => {
		dispatch(setCards([]));
	};
};


export const deleteBulkTopUpEntry = async (id: number, bulkId: number) => {
	const response = await request.delete(`/api/cards/bulk-top-up/${bulkId}/entries/${id}`);
	const { data } = response;
	return data;
};

export const editBulkTopUpEntry = async (id: number, bulkId: number, payload: any) => {
	const response = await request.put(`/api/cards/bulk-top-up/${bulkId}/entries/${id}`, payload);
	const { data } = response;
	return data;
};

export const confirmBulkTopUp = async (bulkId: number) => {
	const response = await request.post(`/api/cards/bulk-top-up/${bulkId}`);
	const { data } = response;
	return data;
};

export const getBulkTopUp = async (bulkId: number) => {
	const response = await request.get(`/api/cards/bulk-top-up/${bulkId}`);
	const { data } = response;
	return data;
};

export const cancelToken = async () => {
	if (cancelSource && cancelSource.token) cancelSource.cancel();
};

let socketService: SocketService;
let updateSubscriber: Subscription;
let removeSubscriber: Subscription;

export const connect = (): void => {
	socketService = new SocketService('cards');
	// if (!socketService) {
	// 	socketService = new SocketService('cards');
	// }
};

export const subscribe = (): AppThunk => {
	return async dispatch => {
		updateSubscriber = socketService.listen('card.data', {}).subscribe(() => {
			dispatch(getCards());
		});
		removeSubscriber = socketService.listen('card.removed', {}).subscribe((data) => {
			if (isEmpty(data)) return;
			dispatch(removeCard(data));
		});
	};
};


export const getCardCvvDetailsIC = async (id: string): Promise<CardCvvResponse> => {
	const response = await request.post('/api/cards/cvv/', { id });
	return response?.data;
};

export const requestVfCode = async (cardId: string, type = 'email') => {
	const payload = {
		cardId,
		type
	};
	const response = await request.post('/api/cards/vfcode', payload);
	return response?.data;
};

export const getCardPanSh_Finance = async (cardId: number) => {
	const keyPairs = await getGenerateKeyPair();
	const response = await request.post('/api/cards/details/pan_sh', { cardId, publicKey: keyPairs.publicKey });
	const data = response?.data;

	return data;
};

export const getCardPanDecta = async (id: number): Promise<DectaCardPanResponse> => {
	const keyPairs = await getGenerateKeyPair();
	const response = await request.post('/api/cards/details/pan', { cardId: id, publicKey: keyPairs.publicKey });
	const data = response?.data?.cardData;
	if (data) {
		return await decrypt(keyPairs.privateKey, data);
	}
	else {
		return undefined;
	}
};

export const getCardPin = async (id: number, vfCode?: string): Promise<DectaCardPinResponse> => {
	const keyPairs = await getGenerateKeyPair();
	const response = await request.post('/api/cards/details/pin', { cardId: id, publicKey: keyPairs.publicKey, verificationCode: vfCode });
	const data = response?.data?.cardData;
	if (data) {
		return await decrypt(keyPairs.privateKey, data);
	}
	else {
		return undefined;
	}

};


export const getShipmentOptions = async (integration: string, countryIso2: string, address: string) => {
	const response = await request.post('/api/cards/delivery-options', { integration, country: countryIso2, address });
	const { data } = response;
	return data;
};

export const unsubscribe = (): void => {
	updateSubscriber.unsubscribe();
	removeSubscriber.unsubscribe();
};



export const getCardPan = async (cardId: number): Promise<any> => {
	try {
		const keyPairs = await getGenerateKeyPair();
		const response = await request.post('/api/cards/pan/', { cardId: cardId, publicKey: keyPairs.publicKey });
		const data = response?.data?.cardData;
		if (data) {
			return await decrypt(keyPairs.privateKey, data);
		}
		else {
			return undefined;
		}
	}
	catch (e) {
		console.log(e);
		return undefined;
	}
};

export const validateCardCreationAddress = async (payload: Address): Promise<any> => {
	const response = await request.post('/api/cards/validateaddress', payload);
	const { data } = response;
	return data;
};

export default slice.reducer;
