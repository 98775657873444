import { Grid, InputLabel } from '@mui/material';
import React from 'react'; 
import MuiFormText from './MuiText/MuiFormText';

interface Props {
	label: string,
	children?: React.ReactNode,
}

const FormDisplayField = (props: Props) => {
	return (
		<>
			<InputLabel>{props.label}</InputLabel>
			<Grid height='3rem' container>
				<MuiFormText pl='0.2rem' alignSelf='center' variant='gradient' fontWeight='700'>
					{props.children}
				</MuiFormText>
			</Grid>
		</ >
	);
};

export default FormDisplayField;
