import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';

const ReferralsTextCell = (params: GridRenderCellParams) => {
    if (params.value === null || params.value === undefined) {
        return '';
    }
    
    return (
        <MuiFormText>
            {params.value}
        </MuiFormText>
    );
};

export default ReferralsTextCell;
