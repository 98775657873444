import React, { FC } from 'react';
import { ButtonProps, IconButton, Theme, useTheme } from '@mui/material';
import SouthIcon from '@mui/icons-material/South';

const SwapButton: FC<ButtonProps> = (props: ButtonProps) => {
	const theme = useTheme() as Theme;
	const { sx, ...other } = props;
	return (
		<IconButton
			{...other}
			sx={{
				...sx,
				cursor: 'default',
				background: theme.selectedItemBg.default,
				width: '1.875rem',
				height: '1.875rem',
				borderRadius: '50px',
				':disabled': {
					background: theme.bgSecondaryButton
				}

			}}   >
			<SouthIcon style={{ color: 'white', height: '1rem' }} />
		</IconButton>
	);
};

export default SwapButton;
