
import * as React from 'react';
import { Box as MuiBox, BoxProps } from '@mui/material';
import { FC } from 'react';

/* Used in file upload dropzone*/
const DocumentBox: FC<BoxProps> = (props: BoxProps) => {

	const { sx, ...others } = props;

	return (<MuiBox id='document-box'
		sx={{
			...sx,
			height: 200,
			width: 200,
			border: '1px dashed rgba(255, 255, 255, 0.08)',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: '16px',
			margin: '12px 0px',
			background: 'linear-gradient(135deg, rgba(245, 247, 250, 0.12) 0%, rgba(245, 247, 250, 0.06) 52.14%, rgba(245, 247, 250, 0.0001) 100%)',
			boxSizing: 'border-box',
		}
		}
		{...others}>
		{props.children}
	</MuiBox>);
};

export default DocumentBox; 
