
import * as React from 'react';
import { BoxProps } from '@mui/material';
import { FC } from 'react';
import TopBox from './Box/TopBox';

const DetailsBox: FC<BoxProps> = (props: BoxProps) => {

	const { sx, ...other } = props;

	return (<TopBox id='details-box'
		sx={{
			p: '0.5rem 2.625rem',
			width: '100%',
			...sx,
		}}
		{...other}>{props.children}
	</TopBox>);
};


export default DetailsBox;
