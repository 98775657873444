import FormContentBox from '@shared/Widgets/Box/FormContentBox';
import MuiFormText from '@shared/Widgets/MuiText/MuiFormText';
import MuiSubHeader from '@shared/Widgets/MuiText/MuiSubHeader';
import MuiTextAlert from '@shared/Widgets/MuiText/MuiTextAlert';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import { BankAccount } from '@features/account/types';
import useCloseDialog from '@helpers/customHook/useCloseDialog';
import { Alert, Button, Grid } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../SepaTransfer.css';
import DepositDetails from './DepositDetails';
import { AddMoneyType } from '@helpers/globalTypes';
import { DepositInstructions } from '@/features/account/types';

interface Props {
	account: BankAccount,
	depositDetails?: any,
	type: AddMoneyType,
	prevStep?: () => void,
}

const PrimetrustTransfer = ({ account, type, prevStep, depositDetails }: Props) => {
	const { t } = useTranslation('translations');
	const closeModal = useCloseDialog();
	const [depositInstructions, setDepositInstructions] = React.useState<DepositInstructions>();
	const [subtitle, setSubtitle] = React.useState<string>('');

	useEffect(() => {
		if (type === AddMoneyType.WIRE) {
			setDepositInstructions(depositDetails.wireInstructions);
			setSubtitle(t('wallet.deposit.title.' + type));
		} else if (type === AddMoneyType.WIRE_INTL) {
			setDepositInstructions(depositDetails.wireInstructionsIntl);
			setSubtitle(t('wallet.deposit.title.' + type));
		} else if (type === AddMoneyType.WIRE_ALT) {
			setDepositInstructions(depositDetails.altCurrencyWireInstructions[account?.currency?.toLowerCase()]);
			setSubtitle('SWIFT');
		}
	}, [account?.currency, depositDetails, t, type]);

	return (
		<div className='form-content__wrap' >
			<FormContentBox noBackground={true} className='form-content_main-area wp' >
				< Grid id='sepa-confirm-step'
					container>
					<Grid item xs={12} >
						<FormHeader title={t('wallet.deposit.title.' + type)} showBack={!!prevStep} onBack={prevStep} />
						<MuiFormText>  {t('banking.addMoney.wireForm.title', { type: subtitle })}  </MuiFormText>
					</Grid>
					<Grid item xs={12} className='sepa-section'>
						<MuiSubHeader>  {t('banking.addMoney.sepaForm.toTitle')}  </MuiSubHeader>
					</Grid>
					<Grid item xs={12}>
						<DepositDetails depositInstructions={depositInstructions} />
					</Grid>
					<Grid item xs={12} className='sepa-warning '>
						<Alert severity="warning"  >
							<MuiTextAlert  >
								{t('banking.addMoney.sepaForm.accountWarning')}
							</MuiTextAlert>
						</Alert>
					</Grid>
					<Grid item xs={12} className='sepa-warning '>
						<Alert severity="warning"  >
							<MuiTextAlert  >
								{t('banking.addMoney.sepaForm.euroBankWaring', { currency: account?.currency })}
							</MuiTextAlert>
						</Alert>
					</Grid>
				</Grid>
				<Grid container pt='2rem' mt='auto'>
					<Grid item xs={6}>
						<Button
							className='button-left__wm'
							variant='customOutlined'
							color='secondary'
							onClick={closeModal}
						>  {t('form.buttons.close')}
						</Button>
					</Grid>
				</Grid>
			</FormContentBox>
		</div>
	);
};

export default PrimetrustTransfer;
