import { WalletType } from '@features/walletHistory/types';
import { BankAccount } from '@features/account/types';

export enum CoinTypeEnum {
	TOKEN = 'TOKEN',
	CRYPTO = 'CRYPTO',
	WALLET = 'WALLET'
}

export enum CryptoChartPeriodEnum {
	DAY = '1D',
	WEEK = '1W',
	MONTH = '1M',
	YEAR = '1Y'
}

export enum WalletDepositAction {
	SEPA = 'SEPA',
	SEPA_INSTANT = 'SEPA_INSTANT',
	CRYPTO = 'CRYPTO',
	CRYPTO_EXTERNAL = 'CRYPTO_EXTERNAL',
	TRANSFER = 'TRANSFER',
	SWIFT = 'SWIFT',
	CHAPS = 'CHAPS',
	USER_TRANSFER = 'USER_TRANSFER',
}

export interface CoinStore {
	portfolioBalance: number,
	portfolioBalanceUsd: number,
	portfolioBalanceGbp: number,
	list: Array<CoinType>,
	loading: boolean,
	fromAsset: AssetType,
	toAsset: AssetType,
	error: any,
	cryptoTradeUpdate: any,
	swaped: boolean
}

export interface SupportedCcy {
	ccy: string,
	pair?: string,
	base?: boolean,
}

export interface TradeSummary24h {
	price: number,
	changeAbsolute24h: number,
	changePercent24h: number,
}


export interface CoinInfoType {
	symbol: string,
	displayName: string,
	logo: string,
	exchange?: string,
	sellEnabled?: boolean,
	sendEnabled?: boolean,
	buyEnabled?: boolean,
	supportedCcy?: SupportedCcy[],
	minimumTradeAmount?: string,
	price?: number,
	minSide?: 'QUOTE' | 'BASE',
	summary24hInEur?: TradeSummary24h | null,
	id?: string,
}

export interface AssetType {
	id: string,
	type: CoinTypeEnum,
	availableBalance: string,
	logo?: string,
	symbol?: string,
	name?: string,
	supportedCcy?: SupportedCcy[]
	buyEnabled?: boolean,
	sellEnabled: boolean,
	precision: number,
	accountId?: number,
	transferProc?: any,
	paymentProc?: any,
	paymentProcess?: any,
	transferProcess?: any,
	networks: string[],
	miniumTradeAmount: string,
	exchange?: string,
	displayName?: string,
	minSide: 'QUOTE' | 'BASE',
	defiToken?: boolean,
}


export interface CoinType {
	account: BankAccount,
	type: CoinTypeEnum,
	subProcesses: any,
	networksArray: string[],
	info: CoinInfoType
}

/*
export interface CoinAccount {
   accountAddresses?: AccountAddressesType[],
   accountId: number,
   balance: number,
   availableBalance: number,
   balanceOnHold: number,
   bankAddress?: string,
   bankName?: string,
   currency?: string
   currencySymbol?: string,
   iban?: string,
   name?: string,
   parentWalletId?: number,
   proc?: string,
   providerType?: string,
   recipient?: string,
   status?: string,
   subProcesses?: any,
   swift?: string,
   type?: string,
   walletId: string
}
*/
export interface CoinTradePayload {
	fromAmount: number,
	fromCcy: string,
	toCcy: string,
	operation: string,
	accountFromId: number,
	accountToId?: number,
	max?: boolean
}

export interface CoinPayinfoPayload {
	walletType: WalletType,
	fromAmount: number,
	fromCcy: string,
	toCcy: string,
	fromAccountId: number,
	toAccountId?: number,
	proc: string,
	cryptoTokenSwap?: boolean,
}

export interface CoinWithdrawalPayinfoPayload {
	fromAmount: number,
	fromAccountId: number,
	proc: string,
}


export interface CoinPayinfo {
	toAmount: string,
	price: string,
	fee: string
}

export interface CoinWithdrawalPayinfo {
	calculatedFee: number,
	calculatedFeeString: string,
}

export interface CoinRecentOrderType {
	transactionNumber: string,
	activity: string,
	amount: number,
	asset: string,
	currency: string,
	currencySymbol?: string,
	date: number,
	documentId: string,
	status: string,
	docNumber: string,
	remitterName?: string,
	remitterId?: string,
	remitterAccount?: string,
	remitterBank?: string,
	remitterBIC?: string,
	remitterBankCountry?: string,
	beneficiary?: string,
	beneficiaryId?: string,
	beneficiaryBank?: string,
	beneficiaryBankCountry?: string,
	beneficiaryBIC?: string,
	beneficiaryAccount?: string,
	details?: string,
	beforeChange?: number,
	conversionCurrency?: string,
	conversionAmount?: number,
	attachments: any[]
}


export interface CoinOhlcType {
	time: number,
	price: number
}

export interface WithdrawalMinAmount {
	cryptoCcy: number;
	cryptoCcyString: string;
	systemCcy: number;
	systemCcyString: string;
}
