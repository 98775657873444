import React from 'react';
import { Grid } from '@mui/material';
import { User, UserType } from '@/features/user/types';
import InputBox from '@/componentsMui/Shared/Widgets/Box/InputBox';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import MuiCopyToClipboard from '@/componentsMui/Shared/Widgets/MuiCopyToClipboard';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import UserAvatar from '@/componentsMui/Shared/Widgets/Avatars/UserAvatar';
import { capitalCaseString } from '@/helpers/normalizeString';

interface Props {
	user: User
}

const Profile = ({ user }: Props): React.ReactElement => {

	const getClientName = () => {
		if (user.type === UserType.BUSINESS) {
			return user.companyName ?? `User ${user.clientId}`;
		} else {
			return user.firstName && user.lastName ? capitalCaseString(`${user.firstName} ${user.lastName}`) : `User ${user.clientId}`;
		}
	};

	return (
		<InputBox sx={{ p: { xs: '1rem', md: '1.875rem' } }}>
			<Grid container>
				<Grid item xs={3} sm={2}>
					<UserAvatar user={user} />
				</Grid>
				<Grid item xs={9} sm={10} pl={2}>
					<Grid container alignItems="baseline">
						<MuiHeader fontSize='1rem'>
							{getClientName()}
						</MuiHeader>
					</Grid>
					<Grid container alignItems="baseline">
						<Grid item>
							<MuiFormText variant='gradient'> {user?.tag ?? user?.clientId} </MuiFormText>
						</Grid>
						<Grid item >
							<MuiCopyToClipboard value={user?.tag ?? user?.clientId} className='svg-icon-stroke-secondary' />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</InputBox>

	);
};

export default Profile;

