import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import Spinner from '@/componentsMui/Shared/Widgets/Spinner/Spinner';
import EmployeesPlaceholder from './EmployeesPlaceholder';
import { getActiveEmployees } from '@/features/employee/slice';
import ActiveEmployeeLine from './Components/ActiveEmployeeLine';

const ActiveEmployees = (): React.ReactElement => {
    const { activeEmployees, loading } = useSelector((state: RootState) => state.employee);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getActiveEmployees());
    }, [dispatch]);

    const fetchActiveEmployees = () => {
        dispatch(getActiveEmployees());
    };
    
    return (

        <>
            {loading ? <Spinner /> :
                <>
                    {activeEmployees.length === 0 ?
                        <Grid item xs={12}>
                            <EmployeesPlaceholder active={true} />
                        </Grid> :
                        <Grid item xs={12}>
                            {activeEmployees.map(employee => <ActiveEmployeeLine key={employee.referenceCode} employee={employee} fetchData={fetchActiveEmployees} />)}
                        </Grid>
                    }
                </>
            }
        </>
    );
};

export default ActiveEmployees;
