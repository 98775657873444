import * as React from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { getCards } from '@/features/card/slice';
import { showException } from '@/features/swal/slice';
import { updateLabel } from '@/features/account/slice';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import MuiFormHelperText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormHelperText';

type Props = {
    cardId?: number,
    onBack?: () => void,
    onClose?: () => void,
}

const RenameCardStep = ({ cardId, onBack, onClose }: Props) => {
    const { t } = useTranslation('translations');

    const dispatch = useDispatch();

    const allCards = useSelector((state: RootState) => state.cards.list);
    const selectedCard = allCards.find(p => p.cardId === cardId);
    const [cardName, setCardName] = useState<string>(null);
    const [cardNameError, setCardNameError] = useState<string>(null);


    const cardNameUpdate = async () => {
        if (cardName.length > 20) {
            setCardNameError(t('form.validator.maxChars', { chars: 20, field: t('cards.cardSettingForm.cardName') }));
            return;
        }

        if (cardName !== selectedCard.name) {
            try {
                await updateLabel(selectedCard.accountId, cardName);
                dispatch(getCards());
            }
            catch (e) {
                await showException(e);
            }
        }
    };



    return (
        <Grid container alignContent='space-between' flex={1} >
            <Grid item container xs={12} rowSpacing={2}>

                <Grid item xs={12} pb={2}>
                    <FormHeader title={t('cards.cardSettings.menu.renameCard')} showBack={!!onBack} onBack={onBack} />
                    <MuiFormText> {t('cards.cardSettingForm.renameCardSubHeader')} </MuiFormText>
                </Grid>


                <Grid item xs={12}>
                    <TextField
                        id='cardName'
                        name='cardName'
                        fullWidth
                        value={selectedCard?.name}
                        disabled
                        inputProps={{ autoComplete: 'off' }}
                        label={t('cards.cardSettingForm.cardName')}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        id='cardName'
                        fullWidth
                        name='cardName'
                        value={cardName}
                        placeholder={t('cards.cardSettingForm.newCardNamePlaceholder')}
                        onChange={(e) => { setCardNameError(null); setCardName(e.target.value); }}
                        inputProps={{ autoComplete: 'off' }}
                        label={t('cards.cardSettingForm.newCardName')}
                    />
                </Grid>
                {cardNameError && <MuiFormHelperText>{cardNameError}</MuiFormHelperText>}
            </Grid>

            <Grid item container xs={12}>
                <Grid item xs={6}>
                    <Button
                        variant='customOutlined'
                        onClick={onClose}
                    >
                        {t('form.buttons.close')}
                    </Button>
                </Grid>
                <Grid item xs={6} display='flex' justifyContent='flex-end'>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={cardNameUpdate}
                        disabled={selectedCard.name === cardName || isEmpty(cardName)}
                    >
                        {t('cards.cardSettingForm.renameCardChangeBtn')}
                    </Button>
                </Grid>

            </Grid>
        </Grid>
    );

};

export default RenameCardStep;
