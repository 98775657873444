
import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setLandingTitle } from '@/features/landingStep/slice';
import MuiTextCaption from '../Shared/Widgets/MuiText/MuiTextCaption';
import MuiFormLabel from '../Shared/Widgets/MuiText/MuiFormLabel';
import BaseBox from '../Shared/Widgets/Box/BaseBox';
import MinimalLayout from '../Layout/MinimalLayout';
import MobileHeader from './components/MobileHeader';
import BackButtonMobileLanding from './components/BackButtonMobileLanding';
import BackButtonWebLanding from './components/BackButtonWebLanding';
import IdentitySumsub from './IdentitySumsub';
import IdentityOnfido from './IdentityOnfido';

interface Props {
	kycService: string,
	paddingTop?: number,
	allowToGoBack?: boolean,
	onComplete: () => void,
	goBack: () => void,
}

const Identity = ({ kycService, paddingTop, allowToGoBack, onComplete, goBack }: Props) => {

	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const title = t('landing.identityStep.title');

	useEffect(() => {
		dispatch(setLandingTitle(t('landing.identityStep.title')));
	}, [dispatch, t]);


	const Info = () => {
		return (
			<Grid container width='100%' justifyContent='center' mb={'20px'}>
				<BaseBox p='20px' maxWidth='280px' minWidth='280px' height='fit-content'>
					<Grid container rowGap='10px'>
						<Grid item xs={12}>
							<MuiTextCaption>{t('landing.identityStep.verificationDocs')}</MuiTextCaption>
						</Grid>
						<Grid item xs={12}>
							<MuiFormLabel variant='gradient'> {t('landing.identityStep.personalIdent')}</MuiFormLabel>
							<MuiFormLabel color='text.secondary'> {t('landing.identityStep.personalIdentDesc')}</MuiFormLabel>
						</Grid>
						<Grid item xs={12}>
							<MuiFormLabel variant='gradient'> {t('landing.identityStep.personalPhoto')}</MuiFormLabel>
							<MuiFormLabel color='text.secondary'> {t('landing.identityStep.personalPhotoDesc')}</MuiFormLabel>
						</Grid>
						<Grid item xs={12}>
							<MuiFormLabel variant='gradient'> {t('landing.identityStep.poa')}</MuiFormLabel>
							<MuiFormLabel color='text.secondary'> {t('landing.identityStep.poaDesc')}</MuiFormLabel>
						</Grid>
					</Grid>
				</BaseBox >
			</Grid>);
	};

	return (
		<MinimalLayout
			allowGoHome={false}
			rightPanel={<Info />}
			leftPanel={allowToGoBack ? <BackButtonWebLanding onClick={goBack} /> : <></>}
			height='fit-content'
			paddingTop={paddingTop}>
			<Grid container rowSpacing={1}>
				<BackButtonMobileLanding onClick={goBack} />
				<MobileHeader text={title} />
				{kycService === 'sumsub' ?
					<IdentitySumsub
						onComplete={onComplete} /> :
					<IdentityOnfido
						onComplete={onComplete} />
				}
			</Grid >
		</MinimalLayout>
	);
};

export default Identity;
