import React, { useCallback, useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Transaction } from '../TransactionStatements.spec';
import DetailLine from '@shared/Widgets/DetailLine';
import NumberFormat from 'react-number-format';
import { getCurrencySign } from '@features/fees/slice';
import { formatDateWithPattern } from '@helpers/date';
import TransactionIcon from '@/componentsMui/Transactions/Details/TransactionIcon';
import FormContentBox from '@shared/Widgets/Box/FormContentBox';
import TransactionNumber from '@/componentsMui/Transactions/Details/TransactionNumber';
import Attachments from '@/componentsMui/Transactions/Details/Attachments';
import { getExchangeDetails } from '@features/transactions/slice';
import { showErrorNotification } from '@features/swal/slice';
import Spinner from '@shared/Widgets/Spinner/Spinner';
import MuiTextCaption from '@shared/Widgets/MuiText/MuiTextCaption';

interface Props {
	transactionData: Transaction,
}

const CurrencyExchange = ({ transactionData }: Props) => {
	const { t } = useTranslation('translations');

	const [loading, setLoading] = useState<boolean>(true);
	const [exchangeDetails, setExchangeDetails] = useState<any>(null);

	const fetchData = useCallback(() => {
		const get = async () => {
			setLoading(true);
			try {
				const exchangeData = await getExchangeDetails(transactionData.docNumber);
				setExchangeDetails(exchangeData);
				console.log('exchangeData', exchangeData);
			} catch (e) {
				await showErrorNotification(e);
			} finally {
				setLoading(false);
			}
		};
		get();
	}, [transactionData.docNumber]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	if (loading) {
		<Grid container spacing={2} >
			<Spinner />
		</Grid>;
	}

	return (
		<Grid container spacing={2} >
			<Grid item xs={12} container mt={'1rem'} display='flex' justifyContent='space-between'>
				<Grid item flexDirection='column' justifyContent='space-between'>
					<Grid container item flexDirection='column'>
						<Grid item>
							<Typography
								sx={{ fontSize: { xs: '1.2rem', md: '2.25rem', whiteSpace: 'nowrap' } }}
								fontWeight='800'
							>
								-
								<NumberFormat displayType={'text'}
									decimalScale={2}
									fixedDecimalScale={true}
									thousandsGroupStyle='thousand'
									thousandSeparator={true}
									value={exchangeDetails?.fromAmount}
									prefix={` ${getCurrencySign(exchangeDetails?.fromCurrencyCode)}`}
								/>
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								sx={{ fontSize: { xs: '1.2rem', md: '2.25rem', whiteSpace: 'nowrap' } }}
								fontWeight='800'
							>
								+
								<NumberFormat displayType={'text'}
									decimalScale={2}
									fixedDecimalScale={true}
									thousandsGroupStyle='thousand'
									thousandSeparator={true}
									value={exchangeDetails?.toAmount}
									prefix={` ${getCurrencySign(exchangeDetails?.toCurrencyCode)}`}
								/>
							</Typography>
						</Grid>
					</Grid>
					<Grid>
						<Typography variant='caption' color='text.secondary'>
							{formatDateWithPattern(transactionData.date, 'dd/MM/yyyy HH:mm:ss')}
						</Typography>
					</Grid>
				</Grid>
				<Grid item xs container justifyContent="flex-end">
					{transactionData?.activity &&
						<TransactionIcon activity={transactionData.activity} beneficiaryName={transactionData.beneficiary} />
					}
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<FormContentBox className='transaction-form' sx={{ p: { xs: '0.5rem 1rem', sm: '0.5rem 2.625rem' } }}>
					<Grid container>
						{transactionData?.transactionNumber &&
							<Grid item xs={12} >
								<TransactionNumber details={transactionData} />
							</Grid>
						}
						<Grid container>
							<DetailLine
								label={t('transactiondetails.main.status')}
								value={t('status.' + transactionData?.status)}
							/>

							<DetailLine
								label={t('transactiondetails.exchange.currency')}
								value={`${exchangeDetails?.fromCurrencyCode}/${exchangeDetails?.toCurrencyCode}`}
							/>

							<DetailLine
								label={t('transactiondetails.exchange.rate')}
								value={`1 ${exchangeDetails?.fromCurrencyCode} = ${exchangeDetails?.fxRate.toFixed(2)} ${exchangeDetails?.toCurrencyCode}`}
							>
								<MuiTextCaption>
									<NumberFormat displayType={'text'}
										decimalScale={exchangeDetails?.fxRateDisplayDecimals ?? 2}
										fixedDecimalScale={true}
										thousandsGroupStyle='thousand'
										thousandSeparator={true}
										value={exchangeDetails?.fxRateString ?? exchangeDetails?.fxRate}
										suffix={` ${exchangeDetails?.toCurrencyCode}`}
										prefix={`1 ${exchangeDetails?.fromCurrencyCode} = `}
									/>
								</MuiTextCaption>
							</DetailLine>

							<DetailLine label={t('transactiondetails.exchange.fee')} value={`${exchangeDetails?.fromFeeAmount}`}>
								<MuiTextCaption>
									<NumberFormat displayType={'text'}
										decimalScale={2}
										fixedDecimalScale={true}
										thousandsGroupStyle='thousand'
										thousandSeparator={true}
										value={exchangeDetails?.fromFeeAmount}
										suffix={` ${exchangeDetails?.fromCurrencyCode}`} />
								</MuiTextCaption>
							</DetailLine>

							{transactionData.details && <DetailLine
								label={t('transactiondetails.details.reference')}
								value={transactionData.details}
							/>}
						</Grid>
						{transactionData?.fees?.length > 0 &&
							<DetailLine
								label={t('transaction.type.CARD_OPERATION_FEE')}
								value={`${transactionData?.fees[0].amount}`}
							/>
						}
						{transactionData?.attachments?.length > 0 &&
							<Grid item xs={12} >
								<Attachments transaction={transactionData} />
							</Grid>
						}
					</Grid>
				</FormContentBox>
			</Grid>
		</Grid>
	);
};

export default CurrencyExchange;
