import { Box, Divider, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LimitDetails } from '@/features/fees/types';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';

interface Props {
  limit: LimitDetails;
  currency: string
}

export const formatLimitNumber = (limit: number | undefined, currency: string) => {
  if (limit === undefined || limit === null) {
    return '';
  }
  const limitText = `${limit} ${currency}`;
  return limitText;
};

const LimitsCards = ({ limit, currency }: Props) => {
  const { t } = useTranslation('translations');

  if (!limit) return <></>;


  const itemsAll = [
    {
      title: t('fees.myFees.limits.maxSingleLoad'),
      subTitle: formatLimitNumber(limit?.maxSingleLoad, currency),
      show: limit?.maxSingleLoad !== undefined,
    },
    {
      title: t('fees.myFees.limits.minSingleLoad'),
      subTitle: formatLimitNumber(limit?.minSingleLoad, currency),
      show: limit?.minSingleLoad !== undefined,
    },
    {
      title: t('fees.myFees.limits.maxDailyLoad'),
      subTitle: formatLimitNumber(limit?.maxDailyLoad, currency),
      show: limit?.maxDailyLoad !== undefined,
    },
    {
      title: t('fees.myFees.limits.maxDailyLoadCount'),
      subTitle: limit?.maxDailyLoadCount,
      show: limit?.maxDailyLoadCount !== undefined,
    },
    {
      title: t('fees.myFees.limits.maxMonthlyLoad'),
      subTitle: formatLimitNumber(limit?.maxMonthlyLoad, currency),
      show: limit?.maxMonthlyLoad !== undefined,
    },
    {
      title: t('fees.myFees.limits.maxSpendPerMonth'),
      subTitle: formatLimitNumber(limit?.maxSpendPerMonth, currency),
      show: limit?.maxSpendPerMonth !== undefined,
    },
    {
      title: t('fees.myFees.limits.maxPurchasePerTransaction'),
      subTitle: formatLimitNumber(limit?.maxPurchasePerTransaction, currency),
      show: limit?.maxPurchasePerTransaction !== undefined,
    },
    {
      title: t('fees.myFees.limits.maxAtmPerDay'),
      subTitle: formatLimitNumber(limit?.maxAtmPerDay, currency),
      show: limit?.maxAtmPerDay !== undefined,
    },
    {
      title: t('fees.myFees.limits.maxAtmPerDayCount'),
      subTitle: limit?.maxAtmPerDayCount,
      show: limit?.maxAtmPerDayCount !== undefined,
    },
    {
      title: t('fees.myFees.limits.maxAtmPerMonth'),
      subTitle: formatLimitNumber(limit?.maxAtmPerMonth, currency),
      show: limit?.maxAtmPerMonth !== undefined,
    },
    {
      title: t('fees.myFees.limits.maxAtmPerMonthCount'),
      subTitle: limit?.maxAtmPerMonthCount,
      show: limit?.maxAtmPerMonthCount !== undefined,
    },
  ];

  const items = itemsAll.filter(p => p.show) || [];

  return (
    <Grid item xs={12}>
      {items.map((p, idx) => <Box key={idx}>
        < Divider />
        <Grid container>
          <Grid item xs={12} minHeight='4rem' container alignItems='center'>
            <Grid item xs={5} sm={4} >
              <MuiFormText>{p.title}</MuiFormText>
            </Grid>
            <Grid item xs={5} sm={4}>
              <MuiFormText>{p.subTitle}</MuiFormText>
            </Grid>
          </Grid>
        </Grid >
      </Box>)}


    </Grid>
  );
};

export default LimitsCards;
