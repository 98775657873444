import React from 'react';
import { getCurrencySign } from '@/features/fees/slice';
import { GridRenderCellParams } from '@mui/x-data-grid';
import NumberFormat from 'react-number-format';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';

const TrxFeeCell = (params: GridRenderCellParams) => {
    const transaction = params.row; 

    if (!(transaction?.fees?.length > 0)) { return ''; }

    const ccy = transaction.currency;
    const currencyPrefix = getCurrencySign(ccy);
    const amount = transaction.totalFeeAmount;

    return (<>
        <MuiFormText textAlign='right'
            fontWeight={500}
            sx={theme => ({ color: transaction.amount <= 0 ? theme.palette.text.primary : theme.statusColor.success })}>

            <NumberFormat displayType={'text'}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandsGroupStyle='thousand'
                thousandSeparator={true}
                value={amount}
                prefix={`${currencyPrefix} `} />
        </MuiFormText>
    </>);
};

export default TrxFeeCell;
