import * as React from 'react';
import { useTranslation } from 'react-i18next';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import { Button, Grid } from '@mui/material';
import MyTicket from './Components/MyTicket';
import { TicketNotification } from '@/features/tickets/types';
import '@/componentsMui/Shared/Shared.css';
import MuiSubHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiSubHeader';
import InputBox from '@/componentsMui/Shared/Widgets/Box/InputBox';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import RoundChat from '@/componentsMui/Shared/CustomIcons/RoundChat';
import { useHistory } from 'react-router-dom';
interface Props {
	onClose: () => void,
	onSelect: (ticket: TicketNotification) => void
}

const TicketListStep = ({ onClose, onSelect }: Props) => {
	const { t } = useTranslation('translations');
	const history = useHistory();

	const handleNewTicketOpen = () => {
		history.replace('/wallet/support/new');
	};

	return (
		<Grid id='my-ticket-list' container className='scroll-grid-container' flex={1}>
			<Grid item xs={12}>
				<FormHeader title={t('faq.myTickets')} showBack={true} onBack={onClose} />
			</Grid>
			<Grid item xs={12} sx={{ cursor: 'pointer' }} onClick={handleNewTicketOpen}>
				<InputBox padding='1.25rem' display='flex' alignItems='center'>
					<RoundChat />
					<MuiTextCaption pl='1.25rem'>{t('faq.createNewTicket')}</MuiTextCaption>
				</InputBox>
			</Grid>
			<Grid item xs={12} pt='1.8rem'>
				<MuiSubHeader>
					{t('faq.tickets')}
				</MuiSubHeader>
			</Grid>
			<Grid item xs={12} className='scroll-section' mt='1.3rem'>
				<MyTicket onSelect={onSelect} />
			</Grid>
			<Grid item
				container
				justifyContent='flex-end'
				alignItems='flex-end'
				flex={1}
				height='100%'
			>
				<Button variant='customOutlined'
					onClick={onClose}>
					{t('form.buttons.close')}
				</Button>
			</Grid>
		</Grid>);
};

export default TicketListStep;
