import * as React from 'react';
import { Box, SvgIcon } from '@mui/material';

const RoundChat = (props) => (
	<Box
		sx={(theme) => ({
			background: theme.selectedItemBg.default,
			borderRadius: '50%',
			width: '42px',
			height: '42px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		})}
	>
		<SvgIcon {...props} >
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M3 12C3 16.9706 7.02944 21 12 21C13.7711 21 15.4226 20.4884 16.815 19.605L21 21L19.605 16.815C20.4884 15.4226 21 13.7711 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M8 12H7.998V12.002H8V12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M12 12H11.998V12.002H12V12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M16 12H15.998V12.002H16V12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			</svg>

		</SvgIcon>
	</Box>
);

export default RoundChat;
