
import * as React from 'react';
import { Box, Typography } from '@mui/material';

interface Props {
	label: string,
	children?: React.ReactNode,
}


const ConfirmationRow = (props: Props) => {

	return (<Box style={{width: '100%'}}>
		<Typography
			fontSize='0.813rem'
			fontWeight='800'
			lineHeight='1.188rem'
			color={'text.primary'}
			whiteSpace='pre-line'
			style={{display: 'inline', wordWrap: 'break-word'}}
		>
			{props.children}
		</Typography>
		<Typography
			fontSize='0.813rem'
			fontWeight='400'
			lineHeight='1.188rem'
			color={'text.secondary'}
		>
			{props.label}
		</Typography>
	</Box>);
};


export default ConfirmationRow;
