import * as React from 'react';

import { Avatar, Button, Dialog, FormControlLabel, Grid, Theme, Typography, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormContentBox from '@/componentsMui/Shared/Widgets/Box/FormContentBox';
import { isEmpty } from 'lodash';
import AccountDropdown from '@/componentsMui/Shared/FormikComponents/AccountDropdown';
import { BankAccount } from '@/features/account/types';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { showException, showSuccess } from '@/features/swal/slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import NumberFormat from 'react-number-format';
import { openForm } from '@/features/forms/slice';
import { useCallback, useEffect, useState } from 'react';
import { getDepositFromAccounts, postVaultDeposit } from '@/features/earn/slice';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import bigDecimal from 'js-big-decimal';
import FormPaperLargeFixed from '@/componentsMui/Shared/Widgets/Dialog/FormPaperLargeFixed';
import { getCurrencySign } from '@/features/fees/slice';
import StyledCheckbox from '@/componentsMui/Shared/Widgets/StyledCheckbox';
import { Link as RouterLink } from 'react-router-dom';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import MuiTextLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextLabel';
import { isFiat } from '@/helpers/currency';
import useVaultDepositAmountDebounce from '@/helpers/customHook/useVaultDepositAmountDebounce';
import MuiFormHelperText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormHelperText';
// import IOSSwitch from '@shared/Widgets/IOSSwitch';

const DepositVaultForm = () => {
	const theme = useTheme() as Theme;
	const { t } = useTranslation('translations');
	const closeModal = useCloseDialogNoEvent();
	const dispatch = useDispatch();

	const { currentVault } = useSelector((state: RootState) => state.tabs);
	const { agent } = useSelector((state: RootState) => state.status);
	// const { accountList } = usePoolingAccounts(currentVault?.processProperties?.ccy);

	const [amount, setAmount] = useState<any>('');
	const [selectedAccount, setSelectedAccount] = useState<BankAccount>();

	const [accounts, setAccounts] = useState<any>();
	const [terms, setTerms] = useState<boolean>(false);

	const ccy = currentVault?.processProperties.depositTrade ? 'UNITS' : currentVault?.processProperties.ccy;

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);

	const onSubmit = async (formData, formikProps) => {
		const { setSubmitting, setFieldError } = formikProps;

		if (bigDecimal.compareTo(youPay, minAmount) < 0) {
			setFieldError('amount', t('vaults.minDeposit', { ccy: ccy, amount: minAmount }));
			return;
		}

		try {
			setSubmitting(true);
			const { accountId, amount } = formData;

			const fromCcy = accountList.find(a => a.accountId === accountId).currency;
			const accountToId = accountList.find(a => a.currency === currentVault.processProperties.ccy).accountId;


			const payload: any = {
				amount: amount,
				fromCcy: fromCcy,
				toCcy: currentVault.processProperties.ccy,

				accountFromId: accountId,
				accountToId: accountToId,
				operation: currentVault.proc,
				autoExtend: true,
			};

			await postVaultDeposit(payload);
			showSuccess({ title: 'paymentsubmitted.data.header', mui: true });
		} catch (e) {
			showException(e);
		}
		finally {
			setSubmitting(false);
			closeModal();
		}
	};


	const fetchFromAccounts = useCallback(() => {
		const get = async () => {
			try {
				const data = await getDepositFromAccounts();
				setAccounts(data);
			} catch (e) {
				console.log(e);
			}
		};
		get();
	}, []);

	useEffect(() => {
		fetchFromAccounts();
	}, [fetchFromAccounts]);

	const getUnitList = (accounts: any) => {
		if (!accounts) return [];

		const baseAccount = accounts?.find(a => a.account.currency === currentVault?.processProperties.ccy);
		const newList: any[] = [];

		baseAccount?.supportedCcy.map((sc) => {
			const coin = accounts.find((c) => c.account.currency === sc.ccy);
			if (coin && bigDecimal.compareTo(coin.account.availableBalanceString, 0) > 0) {
				newList.push(coin.account);
			}
		});

		baseAccount?.account && newList.unshift(baseAccount.account);
		return newList;
	};

	const accountList = ccy === 'UNITS' ? getUnitList(accounts) : accounts?.filter(a => a.account.currency === ccy).map(a => { return a.account; });

	const initialValues = {
		accountId: '',
		amount: 0
	};

	const minAmount = currentVault?.config?.minDepositAmount ?? 500;

	const validationSchema = Yup.object({
		accountId: Yup.number().required(t('form.validator.required')),
		amount: Yup.number().typeError(t('form.validator.required')).required(t('form.validator.required'))
			.moreThan(0, t('sendmoneyTranslation.data.moreThanZero'))
			// .min(minAmount, t('vaults.minDeposit', { ccy: currentVault?.processProperties.currencySymbol, amount: minAmount }))
			// .test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value))
			.test('amount', t('sendmoneyTranslation.data.insufficient'),
				(amount) => {
					return (bigDecimal.compareTo(selectedAccount?.availableBalance, amount) >= 0);
				}),
	});

	const { depositAmount, callGetDepositAmount } = useVaultDepositAmountDebounce(0);

	const youPay = depositAmount;

	const handleAccountChange = (value: BankAccount, setFieldTouched) => {
		setSelectedAccount(value);
		setTimeout(() => setFieldTouched('amount', true));
		callGetDepositAmount(value.currency, currentVault?.processProperties.ccy, amount);
	};


	const handleAmountChange = (event, setFieldValue, setFieldTouched) => {
		const value = event.target.value;
		setAmount(value);
		setFieldValue('amount', value);
		const parsed = parseFloat(event.target.value);
		const newAmount = isNaN(parsed) ? 0 : parsed;
		if (selectedAccount?.accountId) {
			callGetDepositAmount(selectedAccount.currency, currentVault?.processProperties.ccy, newAmount);
		}
		setTimeout(() => setFieldTouched('iban', true));
	};

	const precision = isFiat(selectedAccount?.currency) ? 2 : 5;

	if (!currentVault || !accountList) {
		return <></>;
	}
	return (
		<Dialog
			id="earn-deposit-form"
			open={true}
			onClose={closeModal}
			PaperComponent={FormPaperLargeFixed}
		>

			<Formik
				enableReinitialize={true}
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({ errors, isSubmitting, setFieldValue, setFieldTouched, touched }) => {
					return (
						<Form className='form-content__wrap__bb'>
							<FormContentBox noBackground={true} className='form-content_main-area wp'>
								<Grid container spacing={1}>
									<Grid item xs={9} pb={1}>
										<MuiHeader>{t('vaults.subsribe.header', { plan: currentVault.name })}</MuiHeader>
									</Grid>
									<Grid item xs={12}>
										<AccountDropdown
											fieldlabel={t('vaults.depositFrom')}
											fieldName='accountId'
											accountList={accountList}
											selectedAccount={selectedAccount}
											onAccountChange={(value: BankAccount) => handleAccountChange(value, setFieldTouched)}
										/>
									</Grid>
									<Grid item xs={12} mt='1.6rem' mb='1.6rem'>
										<StyledDivider />
									</Grid>
									<Grid container item xs={12}>
										<Grid item xs={6} className='asset-select' sx={{ background: theme.palette.background.paper, borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}>
											{selectedAccount ?
												<Grid id='fiat-avatar' container alignItems='center' padding={'16.5px 14px'}>
													<Grid item>
														<Avatar sx={{ width: '23px', height: '23px' }} className={'wallet avatar-rounded asset-avatar'} src={selectedAccount?.currencyLogo} />
													</Grid>
													<Grid item pl='0.5rem' >
														<MuiTextCaption>{selectedAccount?.currency} </MuiTextCaption>
													</Grid>
													<Grid item xs={12}>
														<MuiTextLabel color='text.secondary' fontWeight='400' fontSize='0.625rem' lineHeight='0.625rem' mt='4px' >
															{t('tokens.withdrawal.balance')} {new bigDecimal(selectedAccount?.availableBalance).round(precision, bigDecimal.RoundingModes.DOWN).getValue() ?? 0}
														</MuiTextLabel>
													</Grid>
												</Grid> :
												// <Grid id='fiat-avatar' container alignItems='center' padding={'16.5px 14px'}>

												// </Grid>
												null}
										</Grid>
										<Grid item xs={6} className='asset-input' >
											<Grid item xs={12} className='asset-select' sx={{ background: theme.palette.background.paper, borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }} padding={'16.5px 14px'}>
												<NumberFormat
													customInput={MuiTextField}
													sx={{ input: { color: 'text.secondary', textAlign: 'right', padding: '0px' }, height: 'unset' }}
													inputProps={{ autoComplete: 'off', className: 'vault-input' }}
													InputProps={{ className: 'vault-input' }}
													name="amount"
													placeholder={'0.00'}
													fullWidth
													className='vault-input'
													hideError={true}
													allowedDecimalSeparators={['.']}
													onChange={(event) => handleAmountChange(event, setFieldValue, setFieldTouched)}
													allowNegative={false}
													value={amount}
													decimalScale={isFiat(ccy) ? 2 : undefined}
												/>
												<MuiTextLabel color='text.secondary' fontWeight='400' fontSize='0.625rem' lineHeight='0.625rem' mt='4px' textAlign='right' >
													{t('vaults.minDeposit', { ccy: getCurrencySign(ccy), amount: minAmount })}
												</MuiTextLabel>
											</Grid>
										</Grid>
										{errors.amount && touched.amount ?
											<Grid item xs={12} justifyContent='flex-end' display='flex'>
												<MuiFormHelperText>
													{errors.amount}
												</MuiFormHelperText>
											</Grid>
											: null}
									</Grid>
									<Grid item xs={12} mt='1.6rem'>
										<StyledDivider />
									</Grid>
									<Grid container item xs={12} justifyContent='space-between' alignItems='center'>
										<Grid item>
											<MuiHeader>{t('vaults.v3.youWillDeposit')}</MuiHeader>
										</Grid>
										<Grid item>
											<MuiFormHelperText sx={{ fontWeight: '700', fontSize: '1.25rem', lineHeight: '1.75rem' }}>
												<NumberFormat
													displayType={'text'}
													decimalScale={2}
													fixedDecimalScale={true}
													thousandsGroupStyle='thousand'
													prefix={`${getCurrencySign(ccy)} `}
													thousandSeparator={true}
													value={selectedAccount ? youPay : 0} />
											</MuiFormHelperText>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} container alignItems='flex-end'>
									<FormControlLabel
										name='terms'
										className='registration-input'
										control={<StyledCheckbox name='authorize'
											onClick={() => setTerms(!terms)}
											value={terms}
											size='small'
											checked={terms === true}
										/>}
										label={<Typography textAlign='left' fontSize='0.813rem' variant='body1' className='highlight-regular'>
											<Trans i18nKey={['CUSTODYFY', 'SALAZAR', 'CACTUS', 'DTS'].includes(agent)? 'vaults.termsCustodify' : 'vaults.terms'}
												t={t}
												components={{
													termlink: <RouterLink to='/legal/terms' target='_blank' />,
													policylink: <RouterLink to='/legal/privacypolicy' target='_blank' />,
													vaultlink: <RouterLink to='/legal/termsofvault' target='_blank' />
												}}
											/>
										</Typography>}
									/>

								</Grid>
								<Grid container mt='1rem' alignItems='flex-end' className='form-content_botton-area'>
									<Button
										className='button-left__wm'
										key='buttonCancel'
										variant='contained'
										color='secondary'
										onClick={closeModal}
									>   	{t('form.buttons.close')}
									</Button>
									<Button
										className='button-right__wm'
										key='prieview'
										type='submit'
										disabled={!isEmpty(errors) || isSubmitting || !terms}
										variant='contained'
										autoFocus> 	{t('form.buttons.confirm')}
									</Button>

								</Grid>
							</FormContentBox>
						</Form>
					);
				}}
			</Formik >
		</Dialog >
	);
};

export default DepositVaultForm;
