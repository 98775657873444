import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Transaction } from '../TransactionStatements.spec';
import DetailLine from '@shared/Widgets/DetailLine';


interface Props {
	details: Transaction
	isFeeTrx: boolean,
}

const CashWithdrawal = ({ details, isFeeTrx }: Props) => {
	const { t } = useTranslation('translations');

	const isFeeReturn = isFeeTrx && details?.amount > 0;
	return (
		<Grid container>
			<DetailLine
				label={t('transactiondetails.main.status')}
				value={t('status.' + details.status)}
			/>

			<DetailLine
				label={t(isFeeReturn ? 'transactiondetails.beneficiary.toAccount' : 'transactiondetails.remitter.fromAccount')}
				value={details.remitterAccount ?? details.remitterAccountNumber}
			/>

			{details.details && <DetailLine
				label={t('transactiondetails.details.reference')}
				value={details.details}
			/>}
		</Grid>
	);
};

export default CashWithdrawal;
