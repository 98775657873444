import * as React from 'react';
import { Box, SvgIcon } from '@mui/material';

const RoundClock = (props) => (
	<Box
		sx={() => ({
			background: '#DCA61A4D',
			borderRadius: '50%',
			width: '42px',
			height: '42px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		})}
	>
		<SvgIcon {...props} >
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M12 7V12H17M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z"
					stroke="#DCA61A"
					strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			</svg>

		</SvgIcon>
	</Box>
);

export default RoundClock;
