import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LandingStepEnum, LandingStepType } from './type';

interface State {
	stepCount: number,
	step: LandingStepType,
	title: string
}

const initialState: State = {
	stepCount: 0,
	step: { stepName: LandingStepEnum.CREATE_ACCOUNT, stepOrder: 1 },
	title: ''
};

const slice = createSlice({
	name: 'landingStep',
	initialState,
	reducers: {
		setLandingStep(state, action: PayloadAction<LandingStepType>) {
			state.step = action.payload;
		},
		setLandingStepCount(state, action: PayloadAction<number>) {
			state.stepCount = action.payload;
		},
		setLandingTitle(state, action: PayloadAction<string>) {
			state.title = action.payload;
		},

	}
});

export const { setLandingStep, setLandingStepCount, setLandingTitle } = slice.actions;

export const stateSelector = (state: { stateStore: State }) =>
	state.stateStore;

export default slice.reducer;
