import * as React from 'react';
import { useTranslation } from 'react-i18next';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import { Grid } from '@mui/material';
import MuiTextConfirmationLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextConfirmationLabel';
import { HelpTopicsEnum } from '../Components/HelpTopics';
import './MessageSteps.css';
import TopicCard from '../Components/TopicCard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { getTicket, setTicket } from '@/features/tickets/slice';
import { showException } from '@/features/swal/slice';
import LoadingPageMui from '@/pages/LoadingPageMui';
import LegacyMessageInput from '../Components/LegacyMessageInput';
import LegacyMessages from '../Components/LegacyMessages';
interface Props {
	onBack: () => void
	topic?: HelpTopicsEnum,
	ticketId?: number,
}

const MessageSteps = ({ onBack, topic, ticketId }: Props) => {
	const { t } = useTranslation('translations');
	const [currentId, setCurrentId] = React.useState<number>(ticketId);
	const [loading, setLoading] = React.useState<boolean>(false);
	const { updateMessage, ticket } = useSelector((state: RootState) => state.tickets);
	const inputFieldRef = React.useRef<HTMLInputElement>(null);
	const dispatch = useDispatch();

	const fetchTicket = React.useCallback((id: number, withLoading: boolean) => {
		const get = async () => {
			try {
				if (withLoading) {
					setLoading(true);
				}
				await dispatch(getTicket(id));
				inputFieldRef.current.scrollIntoView({ behavior: 'smooth' });
			} catch (e) {
				showException(e);
			}
			finally {
				if (withLoading) {
					setLoading(false);
				}
			}
		};
		get();
	}, [dispatch]);

	React.useEffect(() => {
		if (ticketId) {
			fetchTicket(ticketId, true);
		}

	}, [fetchTicket, ticketId]);

	React.useEffect(() => {
		if (currentId === updateMessage) {
			fetchTicket(currentId, false);
		}
		if (!currentId) { dispatch(setTicket(null)); }

	}, [updateMessage, dispatch, fetchTicket, currentId]);

	const onNewTicket = (ticketId: number) => {
		setCurrentId(ticketId);
		fetchTicket(ticketId, true);
	};

	return (
		<Grid id='faq-message-step' container direction='column' flex={1}>
			<Grid container>
				<Grid item xs={8} container alignContent='space-between'>
					<FormHeader title={t('faq.ticket', { tickerNr: currentId ? `#${currentId}` : '' })}
						showBack={true}
						onBack={onBack}
						mb='0rem' />
					{!currentId &&
						<MuiTextConfirmationLabel color='text.secondary'> {t('faq.info')}
						</MuiTextConfirmationLabel>
					}
				</Grid>
				{topic &&
					<Grid item xs={4} container justifyContent='flex-end'>
						<TopicCard topic={topic} iconWidth='1.4rem' />
					</Grid>
				}
			</Grid>
			<Grid container direction='column' flex={1} justifyContent='flex-end'>
				{loading
					? <LoadingPageMui />
					: <LegacyMessages ticket={ticket} />}
			</Grid>
			<Grid container justifyContent='flex-end' pt='30px'>
				<LegacyMessageInput topic={topic} ticketId={currentId} onNewTicket={onNewTicket} inputFieldRef={inputFieldRef} />
			</Grid>
		</Grid>);
};

export default MessageSteps;
