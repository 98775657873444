import React from 'react';

import './Spinner.css';

interface Props {
	qr?: boolean,
	dialog?:boolean
}

const Spinner = ({qr, dialog}: Props) => {

	return <div className={`portal-loading bar ${qr?'qr':''} ${dialog?'dialog':''}`}>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>;

};

export default Spinner;
