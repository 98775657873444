import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiCopyToClipboard from '../Widgets/MuiCopyToClipboard';

interface Props {
	walletId: string,
	title?: string
}

const WalletId = ({ walletId, title }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');

	return (
		<Grid container alignItems="baseline">
			<Grid item pr={1}>
				<Typography variant='highlight' fontSize='0.75rem' fontWeight='700'>
					{title ? title : t('tokens.header.walletId')}
				</Typography>
			</Grid>
			<Grid item>
				<Typography fontSize='0.75rem' fontWeight='700'> {walletId} </Typography>
			</Grid>
			<Grid item >
				<MuiCopyToClipboard value={walletId} />
			</Grid>
		</Grid>);
};

export default WalletId;
