import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import BaseBox from '@/componentsMui/Shared/Widgets/Box/BaseBox';
import MuiTextAlert from '@/componentsMui/Shared/Widgets/MuiText/MuiTextAlert';

interface Props {
	active: boolean
}

const EmployeesPlaceholder = ({ active }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');

	return (
		<BaseBox textAlign='center' height='14rem' alignItems='center' display='flex'>
			<Grid id='business-placeholder' container>
				<Grid item xs={12}>
					<MuiTextAlert className='highlight-strong' pt={2} >
						{active ? t('employee.employees.activePlaceholder') : t('employee.employees.pendingPlaceholder')}
					</MuiTextAlert>
				</Grid>
			</Grid>
		</BaseBox>
	);
};

export default EmployeesPlaceholder;
