import React from 'react';
import { Grid } from '@mui/material';
import { roundTwo } from '@/helpers/round';
import MuiFormHelperText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormHelperText';
import MuiFormText from './MuiText/MuiFormText';
import { useTranslation } from 'react-i18next';

interface Props {
	files: File[],
	onDelete?: (index: number) => void
}

const fileSize = (size: number) => {
	const x = roundTwo(((size ?? 0) * 0.000001));
	return x;
};

const AttachmentList = ({ files, onDelete }: Props) => {
	const { t } = useTranslation('translations');

	return (
		<Grid container p={1}>
			{files?.map((file, index) => {
				return (
					<Grid key={index} container alignItems='center'>
						<MuiFormText sx={{ color: 'rgba(255, 255, 255, 0.4)', }}>
							{file.name} {fileSize(file.size)} MB • {' '}
						</MuiFormText>
						<MuiFormHelperText style={{ marginLeft: 5 }} onClick={() => onDelete(index)}>
							{t('faq.remove').toUpperCase()}
						</MuiFormHelperText>
					</Grid>
				);
			})}
		</Grid>);
};

export default AttachmentList;
