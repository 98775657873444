import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import MuiFormLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiFormLabel';
import { checkDataSharing, requestDataSharing } from '@/features/userNeuralid/sliceUserNeuralid';
import QrMediaCard from '@/componentsMui/Shared/Widgets/QrMediaCard';
import { useTranslation } from 'react-i18next';
import MuiBigHeader from '../Widgets/MuiText/MuiBigHeader';


interface Props {
	onSuccess?: (internalId: string) => void
}

const ShareNeuralId = ({ onSuccess }: Props): React.ReactElement => {

	const { t } = useTranslation('translations');
	const [qrData, setQrdata] = useState<string>();
	const [internalId, setInternalId] = useState<string>();
	const [granted, setGranted] = useState<boolean>(false);
	const [callCount, setCallCount] = React.useState(0);

	const fetchQrCode = useCallback(async () => {
		const result = await requestDataSharing();
		setQrdata(result.qrCodeImage);
		setInternalId(result.internalId);
	}, []);

	useEffect(() => {

		const checkGranted = async () => {
			if (internalId) {
				try {
					await checkDataSharing(internalId);
					setGranted(true);
					onSuccess && onSuccess(internalId);
				} catch (e) {
					console.log('api error');
				}
			}

			setCallCount(callCount => callCount + 1);
		};


		const interval = setTimeout(() => {
			checkGranted();
		}, 1000);

		if (granted
		) {
			clearTimeout(interval);
		}

		return () => { clearTimeout(interval); };
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [callCount]);

	useEffect(() => {
		fetchQrCode();
	}, [fetchQrCode]);


	const handleRefresh = async () => {
		fetchQrCode();
	};

	return (
		<Grid container >
			{!granted &&
				<><Grid item xs={12}>
					<MuiFormLabel> {t('security.options.enableNeuralDescription')} </MuiFormLabel>
				</Grid>
					<Grid item xs={12} mt={4}>
						<QrMediaCard qrImage={qrData} onButtonClick={handleRefresh} />
					</Grid>
				</>
			}
			{granted &&
				<Grid item xs={12} textAlign='center' my={4}>
					<MuiBigHeader> Data shared </MuiBigHeader>
				</Grid>
			}
		</ Grid >

	);
};

export default ShareNeuralId;
