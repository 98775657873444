import { Alert, Box, Grid, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import FormHeader from '../Components/FormHeader';
import FormFooterButtons, { FormFooterButtonsProps } from '../Components/FormFooterButtons';
import MuiFormText from '@/componentsMui/Shared/Widgets/MuiText/MuiFormText';
import { getQRData } from '@/helpers/getQRData';
import { CoinType } from '@/features/coins/types';
import MuiFormLabel from '@shared/Widgets/MuiText/MuiFormLabel';
import MuiCopyToClipboard from '@shared/Widgets/MuiCopyToClipboard';
import FormLabel from '@shared/Components/SendMoney/PaymentForm/FormLabel';
import { AccountAddressesType } from '@/features/account/types';
import { findProcessByType } from '@/features/operations/slice';
import MaintenanceChip from '@/componentsMui/Shared/Widgets/MaintenanceChip';

interface Props {
	onBack: () => void,
	token: CoinType
}

const DepositExternally = ({ token, onBack }: Props): React.ReactElement => {

	const closeModal = useCloseDialogNoEvent();
	const { t } = useTranslation('translations');
	const addressList = token?.account?.accountAddresses;

	const [activeTab, setActiveTab] = useState(addressList[0]);
	const [loadingQr, setLoadingQr] = useState(false);
	const [qrCode, setQrCode] = useState(null);

	const handleTabChange = (event: React.SyntheticEvent, newValue: AccountAddressesType) => {
		setActiveTab(newValue);
	};

	const depositProc = findProcessByType(token, 'DEPOSIT');
	const externalDepositDown = depositProc?.maintenance;

	const footerBtn: FormFooterButtonsProps[] = [
		{
			title: t('form.buttons.close'),
			variant: 'customOutlined',
			onClick: closeModal
		},
		{
			title: t('wallet.deposit.goToWallet'),
			variant: 'contained',
			onClick: closeModal
		}];



	useEffect(() => {
		const fetchQR = async () => {
			setLoadingQr(true);
			if (activeTab?.address) {
				try {
					const qr = await getQRData(activeTab.address);
					setQrCode(qr);
				}
				catch (e) {
					setQrCode(null);
				}
				finally {
					setLoadingQr(false);
				}
			}
			else { setQrCode(null); }
		};
		fetchQR();
	}, [activeTab?.address]);

	return (
		<>
			<Grid pb='1rem'>
				<Grid item>
					<FormHeader onBack={onBack} title={t('wallet.deposit.cryptoFromExternal')} />
				</Grid>
				{externalDepositDown ?
					<Grid item xs={12} mt={6} >
						<MaintenanceChip />
					</Grid> :
					<>
						{addressList?.length > 0 &&
							<Grid item sx={{ pb: { xs: '1rem', md: '2rem' } }}>
								<Tabs
									value={activeTab}
									onChange={handleTabChange}
								>{addressList.map((item) => {
									return (
										<Tab key={item.accountAddressId} value={item} label={item.network} />
									);
								})
									}
								</Tabs>
							</Grid>
						}
						<Grid container item xs={12} justifyContent='center' mb={3}>
							<Grid item xs={9}>
								<MuiFormText textAlign={'center'} dangerouslySetInnerHTML={{ __html: `${t('wallet.deposit.cryptoFromExternalInstructions', { displayName: token.info.displayName, ccy: token.info.symbol })}` }} />
							</Grid>
						</Grid>
						<Grid item xs={12} sx={{ mb: '2rem' }}>
							{!loadingQr &&
								<Box sx={{ display: 'flex', mx: 'auto', borderRadius: '10px', height: { xs: 'auto', md: 'auto' }, width: { xs: '40%', md: '35%' } }}>
									<img style={{ borderRadius: '10px', height: '100%', width: '100%' }} alt='QR code' src={qrCode} />
								</Box>
							}
						</Grid>
						<Grid item container justifyContent='space-around' alignItems='center' >
							<Grid item xs={12} mb={1}>
								<FormLabel textAlign='center'>{`${token.info.displayName} ${t('wallet.deposit.walletAddress')}`}</FormLabel>
							</Grid>
							<Grid item xs={9} container display={'flex'} justifyContent={'center'}>
								<Box sx={(theme) => ({
									background: theme.palette.background.paper,
									borderRadius: theme.mainBorderRadius,
									p: 1,
								})}>
									<Grid container
										alignItems='center'>
										<MuiFormLabel>
											{activeTab.address}
										</MuiFormLabel>
										<MuiCopyToClipboard value={activeTab.address} />
									</Grid>
								</Box>
							</Grid>
						</Grid>
						<Grid item xs={12} mt={1}>
							<Alert severity="warning"  >
								<MuiFormText dangerouslySetInnerHTML={{ __html: `${t('wallet.deposit.withdrawalInstructions')}` }} />
							</Alert>
						</Grid>
					</>}
			</Grid>
			<FormFooterButtons buttons={footerBtn} />
		</>
	);
};

export default DepositExternally;
