import BaseBox from '@/componentsMui/Shared/Widgets/Box/BaseBox';
import MuiFormLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiFormLabel';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import { Card, CardIntegrationEnum, CardStatuses, CardSubProcesses } from '@/features/card/types';
import { tranformSubProcessByType } from '@/features/operations/slice';
import { Button, CircularProgress, Grid, IconButton, Theme, Typography, useMediaQuery } from '@mui/material';
import { format, isValid, parse } from 'date-fns';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import CardImage from './CardImage';
import CardActivating from './CardStatusMessages/CardActivating';
import CardInactive from './CardStatusMessages/CardInactive';
import CardPending from './CardStatusMessages/CardPending';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';
import CardFrozen from './CardStatusMessages/CardFrozen';
import CardSuspended from './CardStatusMessages/CardSuspended';
import CardExpired from './CardStatusMessages/CardExpired';
import BusinessCardsChooser from './BusinessCardsChooser';
import { getCurrencySign } from '@/features/fees/slice';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import DiplayBigNumber from '@/componentsMui/Shared/Components/DiplayBigNumber';
import { hiddenBabalance } from '@/helpers/placeholders';
import { useTheme } from '@mui/styles';
import CardTransactions from '@/componentsMui/TransactionsGrid/CardTransactions';
import useCardCvvPan from '@/helpers/customHook/useCardCvvPan';
import useCardPin from '@/helpers/customHook/useCardPin';
import { activateCard, isReplaceable } from '../../utils';

interface Props {
    card: Card,
    list: Card[]
}

const BusinessCardDetails = ({ card, list }: Props) => {
    const theme = useTheme() as Theme;
    const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation('translations');
    const history = useHistory();
    const cardProcesses = tranformSubProcessByType(card.process.subProcesses);

    const isPending = ([CardStatuses.PENDING, CardStatuses.ORDERED, CardStatuses.REQUESTED].indexOf(card.status) !== -1);
    const isInactive = (card.status === CardStatuses.INACTIVE);
    const suspendedByUser = (card.status === CardStatuses.FROZEN);
    const suspendedBySystem = (card.status === CardStatuses.SUSPENDED);
    const expired = (card.status === CardStatuses.EXPIRED);
    const isActivating = (card.status === CardStatuses.ACTIVATING);

    const isShCard = card.process.integration === CardIntegrationEnum.SH_FINANCE;
    const topUpAllowed = !isEmpty(cardProcesses[CardSubProcesses.CARD_TOP_UP]);
    const activateAllowed = !isEmpty(cardProcesses[CardSubProcesses.CARD_ACTIVATE]);
    const activateProc = cardProcesses[CardSubProcesses.CARD_ACTIVATE]?.proc;
    const { fetchCardCvvPan, cvv, showGetCVV, cardNumber, loading: loadingCvv } = useCardCvvPan(card);
    const { fetchCardPin, pin, showGetPin, loading: loadingPin } = useCardPin(card);
    const isReplaceEnabled = isReplaceable(card);
    const showBenefits = card?.process?.config?.addPremiumServices || false;

    const { user } = useSelector((state: RootState) => state.user);

    const handleTopup = () => {
        history.push(`/wallet/home/cards/transfers/top-up?card=${card.cardId}`);
    };

    const handleSettings = () => {
        history.push(`/wallet/home/cards/transfers/settings?card=${card.cardId}`);
    };

    const handleActivate = async () => {
        const needVfCode = (card.process.integration === 'SH_FINANCE' && card.status === 'INACTIVE');
        await activateCard(card?.cardId, activateProc, card?.virtual, history, needVfCode, card.status);
    };

    const getExpirationDate = () => {
        const date = parse(card.expirationDate, 'yyyy-MM-dd', new Date());
        if (isValid(date)) {
            return format(date, 'MM/yy');
        } else {
            return '••/••';
        }
    };

    const handleGetCvvPan = async () => {
        await fetchCardCvvPan();
    };

    const handleCardPin = async () => {
        if (showGetPin) {
            await fetchCardPin();
        }
    };

    const handleReplace = (event) => {
        event.stopPropagation();
        history.push(`/wallet/home/cards/replace?card=${card.cardId}`);
    };

    const handleBenefits = () => {
        history.push('/wallet/home/cards/benefits');
    };

    let cardInner =
        <Grid container>
            <BaseBox sx={{
                p: { xs: '2rem 1rem', sm: 0 },
                background: { xs: theme.palette.mode === 'dark' ? theme.backgroundBoxLevel1 : theme.palette.background.paper, sm: 'none' }
            }}>
                <Grid container item xs={12} spacing={4}>
                    <Grid item xs={12} sm={6} md={3.2}><CardImage card={card} padding='7px' /></Grid>
                    <Grid container item xs={12} sm={6} md={8.8} alignContent='space-between'>
                        <Grid item xs={12} container >
                            <Grid item xs={12} md={6} display='flex' flexDirection='column'>
                                {user.hideValues ?
                                    <MuiTextCaption>{getCurrencySign(card.currency)} {hiddenBabalance}</MuiTextCaption>
                                    : <Typography component='span' color='text.primary' >
                                        <DiplayBigNumber
                                            prefixFontSize='1.625rem'
                                            prefix={`${card.currencySymbol ?? getCurrencySign(card.currency)}`}
                                            amount={card.availableBalance}
                                        /></Typography>}
                                {card.assignedTo && <MuiTextCaption variant='gradient' fontSize='0.625rem' lineHeight='0.625rem'>{t('cards.businessCards.details.assignedTo', { employee: `${card.assignedTo.firstName} ${card.assignedTo.lastName}` })}</MuiTextCaption>}
                            </Grid>
                            <Grid container item xs={12} md={6} flexDirection='row' sx={{ pt: { xs: '1rem', md: 0 } }} spacing={0.5} justifyContent='flex-end'>
                                {isReplaceEnabled && <Grid item xs={mediumScreen}>
                                    <Button
                                        variant='simple'
                                        onClick={handleReplace}
                                        sx={{ width: { xs: '100%', md: 'auto' } }}
                                    >
                                        {t('cards.replace.replace')}
                                    </Button>
                                </Grid>}
                                {topUpAllowed && !isShCard && <Grid item xs={mediumScreen}>
                                    <Button
                                        variant='simple'
                                        onClick={handleTopup}
                                        sx={{ width: { xs: '100%', md: 'auto' } }}
                                    >
                                        {t('cards.businessCards.details.topUp')}
                                    </Button>
                                </Grid>}
                                {activateAllowed && <Grid item xs={mediumScreen}>
                                    <Button
                                        variant='contained'
                                        onClick={handleActivate}
                                        sx={{ width: { xs: '100%', md: 'auto' } }}
                                    >
                                        {t('cards.businessCards.details.activate')}
                                    </Button>
                                </Grid>}
                                <Grid item xs={mediumScreen}>
                                    <Button
                                        variant='simple'
                                        onClick={handleSettings}
                                        disabled={!topUpAllowed}
                                        sx={{ width: { xs: '100%', md: 'auto' } }}
                                    >
                                        {t('cards.businessCards.details.settings')}
                                    </Button>
                                </Grid>
                                {showBenefits && <Grid item xs={mediumScreen}>
                                    <Button
                                        variant='simple'
                                        onClick={handleBenefits}
                                        sx={{ width: { xs: '100%', md: 'auto' } }}
                                    >
                                        {t('benefits.header')}
                                    </Button>
                                </Grid>}
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} justifyContent='space-between' {...(mediumScreen ? { alignItems: 'start', flexDirection: 'column' } : {})}>
                            <Grid item sx={{ mt: { xs: '1rem', md: 0 } }}>
                                <MuiFormLabel color='text.secondary'>  {t('cards.businessCards.details.numberOfCard')} </MuiFormLabel>
                                <Grid item container flexDirection='row' alignItems='center'>
                                    <MuiTextCaption fontSize='0.875rem' fontWeight={400} lineHeight='1.375rem' letterSpacing={'2px'}>{cardNumber}</MuiTextCaption>
                                    {showGetCVV && <IconButton onClick={handleGetCvvPan} sx={{ padding: '0 0.5rem' }}>
                                        {loadingCvv ? <CircularProgress size={10} /> : <VisibilityIcon fontSize='inherit' />}
                                    </IconButton>}
                                </Grid>
                            </Grid>
                            <Grid item sx={{ mt: { xs: '1rem', md: 0 } }}>
                                <MuiFormLabel color='text.secondary'>  {t('cards.businessCards.details.expiry')} </MuiFormLabel>
                                <Grid item container flexDirection='row' alignItems='center'>
                                    <MuiTextCaption fontSize='0.875rem' fontWeight={400} lineHeight='1.375rem'>{getExpirationDate()}</MuiTextCaption>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ mt: { xs: '1rem', md: 0 } }}>
                                <MuiFormLabel color='text.secondary'>  {t('cards.businessCards.details.cvv')} </MuiFormLabel>
                                <Grid item container flexDirection='row' alignItems='center'>
                                    <MuiTextCaption fontSize='0.875rem' fontWeight={400} lineHeight='1.375rem' letterSpacing={'2px'}>{cvv}</MuiTextCaption>
                                    {showGetCVV && <IconButton onClick={handleGetCvvPan} sx={{ padding: '0 0.5rem' }}>
                                        {loadingCvv ? <CircularProgress size={10} /> : <VisibilityIcon fontSize='inherit' />}
                                    </IconButton>}
                                </Grid>
                            </Grid>
                            {showGetPin && <Grid item sx={{ mt: { xs: '1rem', md: 0 } }}>
                                <MuiFormLabel color='text.secondary'>  {t('cards.businessCards.details.pin')} </MuiFormLabel>
                                <Grid item container flexDirection='row' alignItems='center'>
                                    <MuiTextCaption fontSize='0.875rem' fontWeight={400} lineHeight='1.375rem' letterSpacing={'2px'}>{pin}</MuiTextCaption>
                                    {showGetPin && <IconButton onClick={handleCardPin} sx={{ padding: '0 0.5rem' }}>
                                        {loadingPin ? <CircularProgress size={10} /> : <VisibilityIcon fontSize='inherit' />}
                                    </IconButton>}
                                </Grid>
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
                {suspendedByUser &&
                    <Grid item xs={12} > <CardFrozen /> </Grid>}
                {suspendedBySystem &&
                    <Grid item xs={12} > <CardSuspended /> </Grid>}
                {expired &&
                    <Grid item xs={12} > <CardExpired /> </Grid>}
            </BaseBox>
            <Grid item xs={12} my='32px' sx={{ display: { xs: 'none', sm: 'block' } }}>
                <StyledDivider />
            </Grid>
            <Grid container item xs={12} sx={{ my: { xs: '2rem', sm: 0 } }}>
                <CardTransactions account={card} />
            </Grid>
        </Grid>;

    if (isPending) {
        cardInner = <CardPending />;
    }

    if (isInactive) {
        cardInner = <CardInactive card={card} activateProc={activateProc} />;
    }

    if (isActivating) {
        cardInner = <CardActivating card={card} />;
    }

    return (
        <Grid container columnSpacing={2}>
            <Grid item xs={2.5} maxHeight='75vh' height='75vh' overflow='auto' sx={{ display: { xs: 'none', sm: 'none', lg: 'block' } }}>
                <BusinessCardsChooser accounts={list} />
            </Grid>
            <Grid item sm={12} lg={9.3}>
                <BaseBox sx={{
                    p: { xs: '0', sm: '30px 42px' },
                    background: { xs: 'none', sm: theme.palette.mode === 'dark' ? theme.backgroundBoxLevel1 : theme.palette.background.paper }
                }}>
                    {cardInner}
                </BaseBox>
            </Grid>

        </Grid>
    );
};

export default BusinessCardDetails;


