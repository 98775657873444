import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Card, Chip, Divider, Grid, List, ListItem, ListItemText, Typography, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { InvoiceStatus, OrderDetails } from '@/features/invoices/invoicesTypes';
import { addPropductToDraft, cancelOrder, getInvoiceDetails, getInvoicesConfig, getOrderPDF, markAsPaidOrder, removePropductFromDraft } from '@/features/invoices/invoicesSlice';
import { showErrorNotification, showSuccess } from '@/features/swal/slice';
import BackButton from '../Shared/Widgets/BackMobileButton';
import InvoiceRecipientBox from './components/InvoiceRecipientBox';
import PaymentOptionsBox from './components/PaymentOptionsBox';
import ProductsBox from './components/ProductsBox';
import NumberFormat from 'react-number-format';
import { getCurrencySign } from '@/helpers/currency';
import LoadingPageMui from '@/pages/LoadingPageMui';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import MuiHeader from '../Shared/Widgets/MuiText/MuiHeader';
import LoadingButton from '../Shared/Components/LoadingButton';
import { useConfirm } from 'material-ui-confirm';
import EmailIcon from '@mui/icons-material/Email';
import DownloadIcon from '@mui/icons-material/Download';
import SubmitInvoiceDialog from './components/SubmitInvoiceDialog';
import SendInvoiceEmailDialog from './components/SendInvoiceEmailDialog';
import { normalizeString } from '@/helpers/normalizeString';

type RouteParams = {
    id: string;
};

const InvoiceDetails = () => {
    const { t } = useTranslation('translations');
    const dispatch = useDispatch();
    const confirm = useConfirm();
    const theme = useTheme();
    const { config } = useSelector((state: RootState) => state.invoices);
    const history = useHistory();
    const { id } = useParams<RouteParams>();
    const [orderDetails, setOrderDetails] = useState<OrderDetails>();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingPdf, setLoadingPdf] = useState<boolean>(false);
    const [openSendEmail, setOpenSendEmail] = useState<boolean>(false);
    const [openSaveDialog, setOpenSaveDialog] = useState<boolean>(false);


    const isDraft = orderDetails?.status === InvoiceStatus.DRAFT;
    const isIssued = orderDetails?.status === InvoiceStatus.ISSUED;
    const isCancelled = orderDetails?.status === InvoiceStatus.CANCELLED;
    const isDescriptionExist = !!orderDetails?.description;
    const isUserNotesExist = !!orderDetails?.userNotes;
    const isPaymentOptionsSelected = orderDetails?.paymentTypes.length > 0;
    const isProductAdded = orderDetails?.products.length > 0;


    const isTotalMoreThanMinOrderAmount = orderDetails?.totalAmount ?? 0 >= config?.minOrderAmount ?? 0;
    const isErrorsExist = !isPaymentOptionsSelected || !isProductAdded || !isTotalMoreThanMinOrderAmount;

    const getDetails = useCallback(async () => {
        if (!id) { return; }
        try {
            setLoading(true);
            const details = await getInvoiceDetails(id);
            setOrderDetails(details);
        } catch (err) {
            showErrorNotification(err);
            history.goBack();
        } finally {
            setLoading(false);
        }
    }, [history, id]);


    useEffect(() => {
        dispatch(getInvoicesConfig());
        getDetails();
    }, [dispatch, getDetails]);

    const handleCancelOrder = async () => {
        if (!orderDetails) return;

        confirm({
            description: t('invoice.cancelOrder'),
            confirmationText: t('invoice.yes'),
            cancellationText: t('invoice.no'),
        }).then(async () => {
            try {
                await cancelOrder(orderDetails.id);
                showSuccess({
                    text: t('invoice.orderCancelledText', { number: orderDetails.orderNumber }),
                    title: t('invoice.orderCancelled')
                });
                getDetails();
            } catch (err) {
                showErrorNotification(err);
            }
        }).catch(() => {
            return;
        });
    };

    const handleSubmit = async () => {
        if (!orderDetails) return;
        setOpenSaveDialog(true);
    };

    const handleMarksAsPaid = async () => {
        if (!orderDetails) return;

        confirm({
            description: t('invoice.matchOrder'),
            confirmationText: t('invoice.yes'),
            cancellationText: t('invoice.no'),
        }).then(async () => {
            try {
                await markAsPaidOrder(orderDetails.id);
                showSuccess({
                    text: t('invoice.orderMatchedText', { number: orderDetails.orderNumber }),
                    title: t('invoice.orderMatched')
                });
                getDetails();
            } catch (err) {
                showErrorNotification(err);
            }
        }).catch(() => {
            return;
        });
    };

    const handleGetPdf = async () => {
        if (!orderDetails) return;
        try {
            setLoadingPdf(true);
            await getOrderPDF(orderDetails?.id, `order_${orderDetails?.orderNumber}`);
        } catch (err) {
            showErrorNotification(err);
        } finally {
            setLoadingPdf(false);
        }
    };

    const onSave = async (response: any) => {
        setOpenSaveDialog(false);
        await showSuccess({
            title: 'invoice.orderSubmitted',
            text: t('invoice.orderSubmittedText', {
                number: response.orderNumber,
            }), confirmBTNText: t('invoice.ok'), mui: true
        });
        getDetails();
    };

    const statusValue = t('invoice.status.' + orderDetails?.status, normalizeString(orderDetails?.status));

    const color = useMemo(() => {
        switch (orderDetails?.status) {
            case InvoiceStatus.PAID:
            case InvoiceStatus.MANUALLY_PAID:
                return { backgroundColor: theme.statusColor.success };
            case InvoiceStatus.DRAFT:
                return {
                    backgroundColor: theme.palette.text.secondary
                };
            case InvoiceStatus.CANCELLED:
                return {
                    backgroundColor: theme.statusColor.lightRed
                };
            case InvoiceStatus.ISSUED:
                return {
                    backgroundColor: theme.palette.info.main,
                };
            case InvoiceStatus.PARTIALLY_PAID:
                return {
                    backgroundColor: theme.statusColor.warning,
                };
            default: return { backgroundColor: theme.palette.text.secondary };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderDetails?.status]);

    const handleAddProduct = async (product: any) => {
        if(!orderDetails) return;

        try {
            await addPropductToDraft(orderDetails.id, product);
            getDetails();
        } catch (err) {
            showErrorNotification(err);
        }
    };

    const handleRemoveProduct = async (product: any) => {
        confirm({
            description: t('invoice.removeProduct'),
            confirmationText: t('invoice.yes'),
            cancellationText: t('invoice.no'),
        }).then(async () => {
            try {
                await removePropductFromDraft(product.id);
                getDetails();
            } catch (err) {
                showErrorNotification(err);
            }
        }).catch(() => {
            return;
        });
        
        
        
    };

    if (loading || !orderDetails) {
        return <LoadingPageMui />;
    }

    return (<Grid container spacing={2}>
        {openSaveDialog && <SubmitInvoiceDialog
            open={openSaveDialog}
            onClose={() => setOpenSaveDialog(false)}
            onSave={onSave}
            order={orderDetails}
        />}
        {openSendEmail && <SendInvoiceEmailDialog
            open={openSendEmail}
            onClose={() => setOpenSendEmail(false)}
            recipientEmail={orderDetails.recipient?.recipientEmail ?? ''}
            orderId={orderDetails.id}
        />}
        <Grid item xs={12} container justifyContent='space-between' alignItems='center'>
            <Grid item>
                <BackButton />
            </Grid>
            <Grid item display='flex' flexDirection='row' alignItems='center'>
                <MuiHeader>{`#${orderDetails.orderNumber}`}</MuiHeader>
                <Chip
                    label={statusValue}
                    sx={{
                        marginLeft: '5px',
                        borderRadius: '10px',
                        minWidth: '70px',
                        minHeight: '24px',
                        '& .MuiChip-label:hover': {
                            color: 'inherit',

                        },
                        '& .MuiChip-label': {
                            background: 'unset',
                            color: theme.palette.text.primary,
                            'WebkitTextFillColor': theme.palette.text.primary,
                        },
                        ...color,
                    }}
                />
            </Grid>
        </Grid>
        <Grid item xs={12}>
            {isDraft && isErrorsExist ?
                <Grid item xs={12}>
                    <Alert severity="warning" icon={false} sx={{ flexDirection: 'row' }}>
                        <List disablePadding>
                            {!isPaymentOptionsSelected && <ListItem disablePadding>
                                <ListItemText primary={t('invoice.atLeastOnePaymentOptionSelected')} />
                            </ListItem>}
                            {!isProductAdded && <ListItem disablePadding>
                                <ListItemText primary={t('invoice.atLeastOneProductAdded')} />
                            </ListItem>}
                            {!isTotalMoreThanMinOrderAmount && <ListItem disablePadding>
                                <ListItemText primary={<Trans i18nKey='invoice.minOrderAmount'
                                    t={t}
                                    components={{
                                        amount: <NumberFormat
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale
                                            thousandsGroupStyle='thousand'
                                            thousandSeparator
                                            prefix={getCurrencySign('EUR')}
                                            value={config?.minOrderAmount ?? 0} />
                                    }}
                                />} />
                            </ListItem>}
                        </List>
                    </Alert>
                </Grid>
                :
                <Grid item display='flex' justifyContent='flex-end'>
                    {isDraft ? <>
                        <Button variant="contained" color='error' onClick={handleCancelOrder} sx={{ mr: 1 }}>{t('invoice.cancel')}</Button>
                        <Button variant="contained" color='primary' onClick={handleSubmit} disabled={isErrorsExist}>{t('invoice.submit')}</Button>
                    </> : <>
                        {isIssued && <Button variant="contained" color='success' sx={{ mr: 1 }} onClick={handleMarksAsPaid} >{t('invoice.markAsPaid')}</Button>}
                        {!isCancelled && <LoadingButton variant="outlined" color='primary' startIcon={<DownloadIcon />} onClick={handleGetPdf} loading={loadingPdf} >PDF</LoadingButton>}
                        {isIssued && <Button variant="contained" color='primary' sx={{ ml: 1 }} onClick={() => setOpenSendEmail(true)} startIcon={<EmailIcon />}>{t('invoice.send')}</Button>}
                    </>}
                </Grid>
            }
        </Grid>
        <Grid item xs={12} md={6}>
            <InvoiceRecipientBox
                recipient={orderDetails.recipient}
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <PaymentOptionsBox
                selectedPayments={orderDetails.paymentTypes}
            />
        </Grid>
        <Grid item xs={12}>
            <ProductsBox
                onAdd={(product) => handleAddProduct(product)}
                // onEdit={(product) => handleAddEditProduct(product)}
                onRemove={(product) => handleRemoveProduct(product)}
                products={orderDetails.products}
                productsEnabled={isDraft}
            />
        </Grid>
        {(isDescriptionExist || isUserNotesExist) && <Grid item xs={12}>
            <Card
                sx={theme => ({
                    background: theme.palette.background.paper,
                    padding: '20px',
                    borderRadius: '16px',
                })}
            >
                {isDescriptionExist && <Grid item xs={12}>
                    <Typography variant="body1">{t('invoice.commentForRecipient')}</Typography>
                    <Typography variant="body2" color='text.secondary'>{orderDetails.description}</Typography>
                </Grid>}
                {isUserNotesExist && <Grid item xs={12}>
                    <Typography variant="body1">{t('invoice.internalComment')}</Typography>
                    <Typography variant="body2" color='text.secondary'>{orderDetails.userNotes}</Typography>
                </Grid>}
            </Card>
        </Grid>}
        <Grid item xs={12} container sx={theme => ({
            position: 'sticky',
            bottom: 0,
            background: theme.palette.background.paper,
            padding: '20px',
            marginLeft: '16px',
            marginTop: '16px',
            borderRadius: '16px',
        })}
            gap={1}
        >
            <Grid item xs={12} container justifyContent='space-between'>
                <Typography variant="body2" mr={1}>{t('invoice.subTotal')}</Typography>
                <Typography variant="body2" color='text.secondary'>
                    <NumberFormat
                        displayType={'text'}
                        decimalScale={2}
                        fixedDecimalScale
                        thousandsGroupStyle='thousand'
                        thousandSeparator
                        prefix={getCurrencySign('EUR')}
                        value={orderDetails?.totalAmountWithoutTax} />
                </Typography>
            </Grid>
            <Grid item xs={12} container justifyContent='space-between'>
                <Typography variant="body2" mr={1}>{t('invoice.tax')}</Typography>
                <Typography variant="body2" color='text.secondary'>
                    <NumberFormat
                        displayType={'text'}
                        decimalScale={2}
                        fixedDecimalScale
                        thousandsGroupStyle='thousand'
                        thousandSeparator
                        prefix={getCurrencySign('EUR')}
                        value={orderDetails?.totalAmount - orderDetails?.totalAmountWithoutTax} />
                </Typography>
            </Grid>
            <Grid item xs={12}><Divider /></Grid>
            <Grid item xs={12} container justifyContent='space-between'>
                <Typography variant="h5" mr={1}>{t('invoice.totalAmount')}</Typography>
                <Typography variant="h5" color='primary'>
                    <NumberFormat
                        displayType={'text'}
                        decimalScale={2}
                        fixedDecimalScale
                        thousandsGroupStyle='thousand'
                        thousandSeparator
                        prefix={getCurrencySign('EUR')}
                        value={orderDetails?.totalAmount} />
                </Typography>
            </Grid>
        </Grid>
    </Grid>);
};

export default InvoiceDetails;
