
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import * as React from 'react';
import { useTranslation } from 'react-i18next'; 


const CardPending = () => {
	const { t } = useTranslation('translations'); 

	return (
		<MuiTextCaption  variant='body2' color='text.primary' lineHeight='19px' textAlign='center' p={4}>{t('cards.cardPending.message')}</MuiTextCaption>
	);
};

export default CardPending;
