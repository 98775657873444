import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MuiTextCaption from '@/componentsMui/Shared/Widgets/MuiText/MuiTextCaption';
import MuiFormLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiFormLabel';
import PasswordChangeForm from './PasswordChangeForm';
import NewPasswordForm from './NewPasswordForm';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';



const Password = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [changePassword, setChangePassword] = useState(false);
	const [newPassword, setNewPassword] = useState(false);

	const { passwordSetted, user } = useSelector((state: RootState) => state.user);

	const hasPassword = passwordSetted || user?.passwordSetted;

	const handlePassword = () => {
		hasPassword ? setChangePassword(true) : setNewPassword(true);

	};

	return (
		<Grid container alignItems='center' rowGap={2} sx={{ backgroundColor: '#1D2024',  padding: '30px', borderRadius: '10px' }}>
			<PasswordChangeForm open={changePassword} onClose={() => setChangePassword(false)} />
			<NewPasswordForm open={newPassword} onClose={() => setNewPassword(false)} />
			<Grid item xs={12} sm={8}>
				<MuiTextCaption fontSize='1.125rem'>{t('security.options.password')}</MuiTextCaption>
				<MuiFormLabel color='text.secondary' fontSize='0.875rem'>{t('security.options.passwordDetails')}</MuiFormLabel>
			</Grid>
			<Grid item xs={12} sm={4} container sx={{ justifyContent: { xs: 'flex-start', sm: 'flex-end' } }}>
				<Button variant='login'
					sx={{ minWidth: '12rem' }}
					onClick={handlePassword}>{t(hasPassword ? 'security.options.changePassword' : 'security.options.passwordAction')}</Button>
			</Grid>
		</Grid>

	);
};
export default Password;
